import React, { Component } from 'react';
import { Col, Form, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import PropTypes from 'prop-types';
import './style.scss';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import RichTextEditor from '../../../RichTextEditor/RichTextEditor';

class MultipleChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: ''
    };
  }
  componentDidMount() {
    if (this.props.answer && this.props.answer.answer_multiple_choice) {
      let checked = this.props.question.radio ? (
        this.props.answer.answer_multiple_choice.length > 0 ? this.props.answer.answer_multiple_choice[0].question_multiple_choice_choice_id : ''
      ) : this.props.answer.answer_multiple_choice.map(answer => answer.question_multiple_choice_choice_id);
      this.setState({
        checked
      });
    }
  }
  componentDidUpdate(prevProps) {
    if(prevProps.id !== this.props.id) {
      let checked = this.props.question.radio ? '' : [];
      
      if (this.props.answer && this.props.answer.answer_multiple_choice) {
        checked = this.props.question.radio ? (
          this.props.answer.answer_multiple_choice.length > 0 ? this.props.answer.answer_multiple_choice[0].question_multiple_choice_choice_id : ''
        ) : this.props.answer.answer_multiple_choice.map(answer => answer.question_multiple_choice_choice_id);
      }
      
      this.setState({
        checked
      });
    }
  }
  onChangeRadio = event => {
    if (event.target.checked) {
      let answer = event.target.value;
      this.setState({
        checked: answer
      }, () => {
        this.props.onChange({
          type: 'multipleChoice',
          questionID: this.props.id,
          answer
        });
      });
    }
  }
  onChangeCheck = event => {
    if (event.target.checked) {
      let answer = [...new Set([...this.state.checked, +event.target.value])];
      this.setState({
        checked: answer
      }, () => {
        this.props.onChange({
          type: 'multipleChoice',
          questionID: this.props.id,
          answer
        });
      });
    } else {
      let answer = [...this.state.checked].filter(checked => checked !== +event.target.value);
      this.setState({
        checked: answer
      }, () => {
        this.props.onChange({
          type: 'multipleChoice',
          questionID: this.props.id,
          answer
        });
      });
    }
  }
  handleFileDownload = fileID => {
    const { url, id } = this.props;

    axiosRequest('get', `${url}/${id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handlePreviewImages = (url, id, images, index) => {
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/${url}/${id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  render() {
    const { question, files, url, id, points } = this.props;
    const { checked } = this.state;

    const questionImages = files.filter(file => {
      return !!file.is_image;
    });

    const questionFiles = files.filter(file => {
      return !file.is_image;
    });

    return (
      <div>
        <RichTextEditor.Viewer body={question.description} className='px-2 px-md-3 py-1 user-select-none' />
        {/* <div className='px-2 px-md-3 py-1 user-select-none'>{question.description}</div> */}
        {
          files.length > 0 && (
            <div className='px-2 px-md-3'>
              {
                questionImages.length > 0 && (
                  <Row>
                  {
                    questionImages.map((image, index) => (
                      <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(url, id, questionImages, index)}>
                        <Image src={`${process.env['REACT_APP_API_BASE_URL']}/${url}/${id}/image/${image.id}`} thumbnail />
                      </Col>
                    ))
                  }
                  </Row>
                )
              }
              {
                questionFiles.length > 0 && (
                  <div className='p-1'>
                    {
                      questionFiles.map(file => (
                        <small key={file.id} className='d-flex mb-2'>
                          <div className='mr-2'>
                            <FontAwesomeIcon icon='file' className='text-primary' />
                          </div>
                          <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                            <span className='btn-link' onClick={() => this.handleFileDownload(file.id)}>
                              {file.file_title}
                            </span>
                          </div>
                        </small>
                      ))
                    }
                  </div>
                )
              }
            </div>
          )
        }
        <div className='px-2 px-md-3 text-muted'>
          ({points} point{points > 1 ? 's' : ''})
        </div>
        {
          question.radio ? (
            <div className='p-2 p-md-3'>
              {
                question.choices.map((choice, index) => (
                  <Form.Group key={index} controlId={`choice-${index}`}>
                    <Form.Check type='radio' label={choice.description} name='multipleChoice' value={choice.id} onChange={this.onChangeRadio} checked={+checked === choice.id} />
                  </Form.Group>
                ))
              }
            </div>
          ) : (
            <div className='p-2 p-md-3'>
              {
                question.choices.map((choice, index) => (
                  <Form.Group key={index} controlId={`choice-${index}`}>
                    <Form.Check type='checkbox' label={choice.description} name='multipleChoice' value={choice.id} onChange={this.onChangeCheck} checked={Array.isArray(checked) ? checked.indexOf(choice.id) !== -1 : false} />
                  </Form.Group>
                ))
              }
            </div>
          )
        }
      </div>
    );
  }
}

MultipleChoice.propTypes = {
  onChange: PropTypes.func.isRequired
};

MultipleChoice.defaultProps = {
  onChange: () => {}
};

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(MultipleChoice);