import React, { Component } from 'react';
import { Alert, Button, Col, Collapse, Form, Modal, ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Validator from 'validatorjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import axios from 'axios';
import LoadingIcon from '../../LoadingIcon/LoadingIcon';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';

export default class MultipleChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formInputs: {
        question: '',
        points: 1,
        choices: [
          {
            description: '',
            isCorrect: false
          },
          {
            description: '',
            isCorrect: false
          },
          {
            description: '',
            isCorrect: false
          },
          {
            description: '',
            isCorrect: false
          }
        ]
      },
      divisiblePoints: true,
      fileNames: [],
      loadingFiles: false,
      errorMessage: '',
      isLoading: false,
      showAttachments: false,
      deleteFiles: [],
      loadingDeleteFiles: []
    };

    this.files = [];
  }
  componentDidMount() {
    const { data } = this.props;

    if (data) {
      const choices = data.question.choices.map(choice => {
        return {
          id: choice.id,
          description: choice.description,
          isCorrect: choice.is_correct
        }
      });
      let correctChoices = choices.filter(c => c.isCorrect);
      let points = data.points;
      let divisiblePoints = false;
      if (points % correctChoices.length === 0) {
        points = points / correctChoices.length;
        divisiblePoints = true;
      }
      this.setState({
        ...this.state,
        formInputs: {
          question: data.question.description,
          points: points,
          choices
        },
        divisiblePoints
      });
    }
  }
  handleCancelDeleteFile = id => {
    let newDeleteFiles = [...this.state.deleteFiles].filter(fileID => fileID !== id);
    this.setState({
      ...this.state,
      deleteFiles: newDeleteFiles
    });
  }
  handleDeleteFile = id => {
    this.setState({
      ...this.state,
      deleteFiles: [...this.state.deleteFiles, id]
    });
  }
  handleShowAttachments = () => {
    this.setState({
      ...this.state,
      showAttachments: !this.state.showAttachments
    });
  }
  handleFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.files = [...this.files, ...files];
      this.setState({
        ...this.state,
        fileNames: [
          ...this.state.fileNames,
          ...filesState
        ]
      });
    }
  }
  handleRemoveUpload = id => {
    let newFileNames = [...this.state.fileNames];
    newFileNames.splice(id, 1);

    this.setState({
      ...this.state,
      fileNames: newFileNames
    }, () => {
      this.files.splice(id, 1);
    });
  }
  handleRemove = index => {
    let choices = [...this.state.formInputs.choices];
    
    if (choices.length > 2) {
      choices.splice(index, 1);
      this.setState({
        ...this.state,
        formInputs: {
          ...this.state.formInputs,
          choices
        }
      });
    }
  }
  handleChoiceInputChange = (event, index) => {
    let choices = [...this.state.formInputs.choices];
    choices[index].description = event.target.value;
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        choices
      }
    });
  }
  handleChoiceCheckbox = (event, index) => {
    let choices = [...this.state.formInputs.choices];
    choices[index].isCorrect = event.target.checked;
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        choices
      }
    });
  }
  handleAddChoice = () => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        choices: [
          ...this.state.formInputs.choices,
          {
            description: '',
            isCorrect: false
          }
        ]
      }
    });
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [event.target.name]: event.target.value
      }
    });
  }
  handleQuestionInputChange = question => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        question
      }
    });
  }
  handleCreate = () => {
    const { formInputs, divisiblePoints } = this.state;
    const { url } = this.props;
    
    let correctChoices = formInputs.choices.filter(c => c.isCorrect);
    let points = formInputs.points * correctChoices.length;
    if (!divisiblePoints) {
      points = formInputs.points;
    }

    axiosRequest('post', url, {
      ...formInputs,
      points,
      questionType: 'multipleChoice'
    }, ({ data: { data }}) => {
      data.question = data.question_multiple_choice;
      if (this.files.length > 0) {
        let fileRequests = [];
        for (let i = 0; i < this.files.length; i++) {
          fileRequests.push(this.uploadFile(i, url, data.id));
        }
        Promise.all(fileRequests).then(result => {
          data.files  = result;
          this.props.onSave(data);
        }).catch((error) => {
          this.setState({
            ...this.state,
            isLoading: false,
            errorMessage: error,
            loadingDeleteFiles: [],
            loadingFiles: false,
          });
        });
      } else {
        this.props.onSave(data);
      }
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        loadingDeleteFiles: [],
        loadingFiles: false,
      });
    }, this.props.history);
  }
  handleUpdate = () => {
    const { formInputs, deleteFiles, divisiblePoints } = this.state;
    const { data: questionData, url } = this.props;
    
    let correctChoices = formInputs.choices.filter(c => c.isCorrect);
    let points = formInputs.points * correctChoices.length;
    if (!divisiblePoints) {
      points = formInputs.points;
    }

    axiosRequest('patch', `${url}/${questionData.id}`, {
      ...formInputs,
      points,
      questionType: 'multipleChoice'
    }, ({ data: { data }}) => {
      data.question = data.question_multiple_choice;
      let uploadRequests = [];
      let deleteRequests = [];

      if (this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          uploadRequests.push(this.uploadFile(i, url, data.id));
        }
      }

      if (deleteFiles.length > 0) {
        for (let i = 0; i < deleteFiles.length; i++) {
          deleteRequests.push(this.deleteFile(deleteFiles[i], url, data.id));
        }
      }

      if (uploadRequests.length && deleteRequests.length) {
        Promise.all([Promise.all(uploadRequests), Promise.all(deleteRequests)]).then(result => {
          data.files = [...result[0], ...this.props.data.files];
          data.files = data.files.filter(file => result[1].indexOf(file.id) === -1);
          this.props.onSave(data);
        }).catch((error) => {
          this.setState({
            ...this.state,
            isLoading: false,
            errorMessage: error,
            loadingDeleteFiles: [],
            loadingFiles: false,
          });
        });
      } else if (uploadRequests.length) {
        Promise.all(uploadRequests).then(result => {
          data.files = [...result, ...this.props.data.files];
          this.props.onSave(data);
        }).catch((error) => {
          this.setState({
            ...this.state,
            isLoading: false,
            errorMessage: error,
            loadingDeleteFiles: [],
            loadingFiles: false,
          });
        });
      } else if (deleteRequests.length) {
        Promise.all(deleteRequests).then(result => {
          data.files = [...this.props.data.files];
          data.files = data.files.filter(file => result.indexOf(file.id) === -1);
          this.props.onSave(data);
        });
      } else {
        this.props.onSave(data);
      }
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        loadingDeleteFiles: [],
        loadingFiles: false,
      });
    }, this.props.history);
  }
  handleSave = event => {
    event.preventDefault();
    
    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: '',
      loadingFiles: true,
      loadingDeleteFiles: [...this.state.deleteFiles]
    }, () => {
      const { formInputs } = this.state;

      let validator = new Validator(formInputs, {
        question: 'required|min:3',
        points: 'required|numeric|min:1',
        choices: 'required|min:2',
        'choices.*.description': 'required',
        'choices.*.isCorrect': 'required|boolean'
      }, {
        'required.choices.*.description': 'The choice description is required.',
        'required.choices.*.isCorrect': 'The choice option is required.',
        'min.choices.*.description': 'The choice description must be at least 3 characters.',
        'boolean.choices.*.isCorrect': 'The choice option must be boolean.',
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: validator.errors.errors[firstKey][0],
          loadingDeleteFiles: [],
          loadingFiles: false,
        });
        return;
      }

      let hasCorrectAnswer = (formInputs.choices.filter(choice => choice.isCorrect)).length > 0;

      if (!hasCorrectAnswer) {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: 'A choice must be set as the correct answer.',
        });
        return;
      }

      if (this.props.data) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    });
  }
  uploadFile = (i, url, questionID) => {
    const formData = new window.FormData();
    formData.append('file', this.files[i], this.files[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/${url}/${questionID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { fileNames } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          fileNames
        });
      }
    }).then(({ data: { data }}) => {
      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        fileNames
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        fileNames
      });
      throw fileNames[i].isError;
    });
  }
  deleteFile = (id, url, questionID) => {
    return axios.delete(`${process.env['REACT_APP_API_BASE_URL']}/${url}/${questionID}/file/${id}`, {
      withCredentials: true,
    }).then(({ data: { data }}) => {
      let newLoadingDeleteFiles = [...this.state.loadingDeleteFiles].filter(fileID => fileID !== id);
      this.setState({
        ...this.state,
        loadingDeleteFiles: newLoadingDeleteFiles
      });
      return id;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }
    });
  }
  renderFileUpload = () => {
    const { fileNames, loadingFiles } = this.state;
    if (fileNames.length > 0) {
      return (
        <Form.Group>
          <Form.Label>File(s) to be uploaded</Form.Label>
          <small className='file-upload-display d-block rounded-top'>
          {
            fileNames.map((fileName, index) => (
              <div key={index} className='file-name d-flex'>
                <div>
                  {
                    fileName.isLoading || (loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                      <LoadingIcon />
                    ) : fileName.isError ? (
                      <FontAwesomeIcon icon='times-circle' className='text-danger' />
                    ) : fileName.isSuccess ? (
                      <FontAwesomeIcon icon='check-circle' className='text-green' />
                    ) : (
                      <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                    )
                  }
                </div>
                <div className='ml-2 flex-fill'>
                  <div>
                    {fileName.name}
                  </div>
                  {
                    fileName.isLoading && (
                      <div className='mt-1'>
                        <ProgressBar now={fileName.isLoading} />
                      </div>
                    )
                  }
                </div>
                {
                  (!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !loadingFiles) && (
                    <div className='ml-2 align-items-center d-flex'>
                      <Button
                        variant='link'
                        className='text-danger'
                        size='sm'
                        title='Remove'
                        onClick={(e) => this.handleRemoveUpload(index)}>
                        <FontAwesomeIcon icon='times' size='sm' />
                      </Button>
                    </div>
                  )
                }
              </div>
            ))
          }
          </small>
        </Form.Group>
      );
    }
  }
  renderAttachedFiles = () => {
    const { showAttachments, deleteFiles, loadingDeleteFiles } = this.state;
    const { data } = this.props;

    if (data && data.files && data.files.length > 0) {
      return (
        <>
          <div className={`text-right border rounded-top ${showAttachments ? 'border-bottom-0' : 'rounded-bottom'}`}>
            <Button variant='link' onClick={this.handleShowAttachments} block>
              <FontAwesomeIcon icon='paperclip' /> Show {data.files.length} attachment(s)
            </Button>
          </div>
          <Collapse in={showAttachments}>
            <div className='file-upload-display small'>
            {
              data.files.map(file => (
                <div className={`file-name d-flex ${deleteFiles.indexOf(file.id) !== -1 ? 'alert-light' : ''}`}>
                  {
                    loadingDeleteFiles.indexOf(file.id) !== -1 && (
                      <LoadingIcon />
                    )
                  }
                  <div className='ml-2 flex-fill'>
                    <div>
                      {file.file_title}
                    </div>
                    {
                      deleteFiles.indexOf(file.id) !== -1 && (
                        <div className='font-italic'>
                          To be deleted
                        </div>
                      )
                    }
                  </div>
                  <div className='ml-2 align-items-center d-flex'>
                    {
                      deleteFiles.indexOf(file.id) !== -1 ? (
                        <Button
                          variant='link'
                          className='text-green'
                          size='sm'
                          title='Cancel delete'
                          onClick={() => this.handleCancelDeleteFile(file.id)}>
                          <FontAwesomeIcon icon='ban' size='sm' />
                        </Button>
                      ) : (
                        <Button
                          variant='link'
                          className='text-danger'
                          size='sm'
                          title='Delete'
                          onClick={() => this.handleDeleteFile(file.id)}>
                          <FontAwesomeIcon icon='trash-alt' size='sm' />
                        </Button>
                      )
                    }
                  </div>
                </div>
              ))
            }
            </div>
          </Collapse>
        </>
      );
    }
  }
  render() {
    const { formInputs, isLoading, errorMessage, divisiblePoints } = this.state;
    const { show, data, index } = this.props;

    let correctChoices = formInputs.choices.filter(c => c.isCorrect);

    return (
      <Modal show={show} size='lg' backdrop='static' onHide={this.props.onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{data ? `Update question ${index}` : 'Create question'} <small className='font-italic text-muted small m-0'>&mdash; Multiple Choice</small></Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleSave} className='question-form'>
          <Modal.Body>
            {
              errorMessage && (
                <Alert variant='danger'>
                  {errorMessage}
                </Alert>
              )
            }
            <Form.Group>
              <Form.Label>Question</Form.Label>
              <RichTextEditor.Editor
                value={formInputs.question}
                onChange={this.handleQuestionInputChange} />
              {/* <Form.Control
                as='textarea'
                rows='4'
                style={{ resize: 'none' }}
                name='question'
                value={formInputs.question}
                onChange={this.handleInputChange} /> */}
            </Form.Group>
            <div className='choice-list px-3'>
              {
                formInputs.choices.map((choice, index) => (
                  <div className='d-flex' key={index}>
                    <div className='flex-fill'>
                      <Form.Group>
                        <Form.Label>Choice {index+1}</Form.Label>
                        <Form.Control type='text' name='description' value={choice.description} onChange={e => this.handleChoiceInputChange(e, index)} />
                        <Form.Group as='div' controlId={`choice${index}`}>
                          <Form.Check type='checkbox' label='Correct answer' checked={choice.isCorrect} onChange={e => this.handleChoiceCheckbox(e, index)} />
                        </Form.Group>
                      </Form.Group>
                    </div>
                    {
                      formInputs.choices.length > 2 && (
                        <div className='pl-3'>
                          <small className='close' title='Remove choice' onClick={() => this.handleRemove(index)}>
                            <FontAwesomeIcon icon='times' size='xs' />
                          </small>
                        </div>
                      )
                    }
                  </div>
                ))
              }
            </div>
            <div className='text-center mb-3'>
              <Button variant='info' size='sm' onClick={this.handleAddChoice}>
                <FontAwesomeIcon icon='plus' /> Add another choice
              </Button>
            </div>
            {
              divisiblePoints ? (
                <Form.Row>
                  <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>Points Per Choice</Form.Label>
                    <Form.Control type='number' name='points' min={1} value={formInputs.points} onChange={this.handleInputChange} />
                  </Form.Group>
                  <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>Total Points</Form.Label>
                    <Form.Control type='number' value={formInputs.points * correctChoices.length} readOnly />
                  </Form.Group>
                </Form.Row>
              ) : (
                <Form.Row>
                  <Form.Group as={Col} md={6} lg={4}>
                    <Form.Label>Total Points</Form.Label>
                    <Form.Control type='number' name='points' min={1} value={formInputs.points} onChange={this.handleInputChange} />
                  </Form.Group>
                </Form.Row>
              )
            }
            {this.renderFileUpload()}
            {this.renderAttachedFiles()}
          </Modal.Body>
          <Modal.Footer>
            <div className='mr-auto'>
              <label className={`btn btn-info m-0 ${isLoading ? 'disabled' : ''}`} htmlFor='add-file' title='File upload'>
                <FontAwesomeIcon icon='file-upload' />
              </label>
              <Form.File className='d-none' id='add-file' onChange={this.handleFileUpload} multiple disabled={isLoading} />
            </div>
            <Button variant='danger' className='mr-2' onClick={this.props.onCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button variant='green' type='submit' disabled={isLoading}>
              Save question
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

MultipleChoice.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

MultipleChoice.defaultProps = {
  onCancel: () => {},
  onSave: () => {}
}