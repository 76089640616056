import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginUser } from '../../../actions';
import { Container, Form, Image, Nav, Navbar, Card, Alert, Row, Col, Button } from 'react-bootstrap';
import Validator from 'validatorjs';
import axios from 'axios';
import axiosRequest from '../../../util/helpers/axiosRequest';
import { loadRule } from '../../../util';
import Header from '../../common/Header/Header';
import actsccLogo from '../../../resources/image/actscc-logo.png';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formInputs: {
        birthdate: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      isLoading: false,
      errorMessage: '',
      successMessage: ''
    };
  }
  componentDidMount() {
    loadRule('date');
  }
  handleLogout = () => {
    axios.delete(`${process.env['REACT_APP_API_BASE_URL']}/logout`, { withCredentials: true }).then(({ data: { message } }) => {
      this.props.history.replace('/login');
    }).catch((error) => {
      console.log(error);
    });
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [event.target.name]: event.target.value
      }
    });
  }
  handleReset = () => {
    this.setState({
      ...this.state,
      formInputs: {
        birthdate: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }
    });
  }
  handleCancel = () => {
    this.props.history.goBack();
  }
  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: '',
      successMessage: ''
    }, () => {
      const { formInputs } = this.state;
      const { currentUser } = this.props;
      
      let rules = {};
      let customAttributeNames = {};
      if (currentUser.is_first_login) {
        rules = {
          birthdate: 'required|date',
          newPassword: 'required|min:8|same:confirmPassword',
          confirmPassword: 'required|min:8|same:newPassword'
        };
      } else {
        rules = {
          currentPassword: 'required|min:8',
          newPassword: 'required|min:8|same:confirmPassword',
          confirmPassword: 'required|min:8|same:newPassword'
        };
        customAttributeNames = {
          currentPassword: 'current password'
        };
      }

      let validator = new Validator(formInputs, rules);
      validator.setAttributeNames({
        ...customAttributeNames,
        newPassword: 'new password',
        confirmPassword: 'confirm password'
      });
      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          errorMessage: validator.errors.errors[firstKey][0],
          isLoading: false
        });
        return;
      }

      axiosRequest('patch', 'change-password', formInputs, ({ data: { data, message }}) => {
        if (currentUser.is_first_login) {
          this.props.loginUser(data);
          this.props.history.push('/');
        } else {
          this.setState({
            ...this.state,
            isLoading: false,
            successMessage: message,
            formInputs: {
              birthdate: '',
              currentPassword: '',
              newPassword: '',
              confirmPassword: ''
            }
          });
        }
      }, (error) => {
        this.setState({
          ...this.state,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          isLoading: false
        })
      }, this.props.history);
    });
  }
  renderFirstLogin = () => {
    const { formInputs, isLoading, errorMessage } = this.state;
    return (
      <>
        <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
          <Container>
            <Navbar.Brand>
              <Image
                src={actsccLogo}
                width='30'
                height='30'
                className='d-inline-block align-top'
              />{' '}
              {process.env['REACT_APP_NAME']}
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
              <Nav className='ml-auto'>
                <Nav.Link onClick={this.handleLogout}>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container>
          <Row>
            <Col md={5} className='mx-auto'>
              <Card className='mt-3'>
                <Card.Body>
                  {
                    errorMessage ? (
                      <Alert variant='danger'>
                        {errorMessage}
                      </Alert>
                    ) : (
                      <Alert variant='warning'>
                        You must change your password before proceeding.
                      </Alert>
                    )
                  }
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                      <Form.Label>Birthdate</Form.Label>
                      <Form.Control type='date' name='birthdate' disabled={isLoading} value={formInputs.birthdate} onChange={this.handleInputChange} />
                      <Form.Text className='text-muted'>Birthdate is required to confirm the user's identity.</Form.Text>
                    </Form.Group>
                    <div className='dropdown-divider'></div>
                    <Form.Group>
                      <Form.Label>New Password</Form.Label>
                      <Form.Control type='password' name='newPassword' disabled={isLoading} value={formInputs.newPassword} onChange={this.handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control type='password' name='confirmPassword' disabled={isLoading} value={formInputs.confirmPassword} onChange={this.handleInputChange} />
                    </Form.Group>
                    <div className='text-right'>
                      {
                        isLoading && (
                          <LoadingIcon />
                        )
                      }
                      <Button variant='danger' className='mx-2' onClick={this.handleReset} disabled={isLoading}>
                        Reset
                      </Button>
                      <Button variant='green' type='submit' disabled={isLoading}>
                        Save
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
  renderChangePassword = () => {
    const { formInputs, isLoading, errorMessage, successMessage } = this.state;
    return (
      <>
        <Header active='Change password' />
        <Container>
          <Row>
            <Col md={5} className='mx-auto'>
              <Card className='mt-3'>
                <Card.Body>
                  {
                    errorMessage && (
                      <Alert variant='danger'>
                        {errorMessage}
                      </Alert>
                    )
                  }
                  {
                    successMessage && (
                      <Alert variant='green'>
                        {successMessage}
                      </Alert>
                    )
                  }
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control type='password' name='currentPassword' disabled={isLoading} value={formInputs.currentPassword} onChange={this.handleInputChange} />
                    </Form.Group>
                    <div className='dropdown-divider'></div>
                    <Form.Group>
                      <Form.Label>New Password</Form.Label>
                      <Form.Control type='password' name='newPassword' disabled={isLoading} value={formInputs.newPassword} onChange={this.handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control type='password' name='confirmPassword' disabled={isLoading} value={formInputs.confirmPassword} onChange={this.handleInputChange} />
                    </Form.Group>
                    <div className='text-right'>
                      {
                        isLoading && (
                          <LoadingIcon />
                        )
                      }
                      <Button variant='danger' className='mx-2' onClick={this.handleCancel} disabled={isLoading}>
                        Cancel
                      </Button>
                      <Button variant='green' type='submit' disabled={isLoading}>
                        Save
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
  render() {
    const { currentUser } = this.props;

    return currentUser.is_first_login ? this.renderFirstLogin() : this.renderChangePassword();
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

const mapDispatchToProps = dispatch => ({
  loginUser: user => dispatch(loginUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);