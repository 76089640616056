import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, Modal, Pagination, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Validator from 'validatorjs';
import { loadRule } from '../../../../util';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import AdminSettingsModal from '../../../modals/AdminSettingsModal/AdminSettingsModal';
import PromptDeleteModal from '../../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Semester extends Component {
  constructor(props) {
    super(props);
    this.state = {
      semesters: {
        data: []
      },
      isLoading: true,
      errorMessage: '',
      active: null,
      formInputs: {
        title: '',
        startDate: '',
        endDate: ''
      },
      isFormLoading: false,
      formError: '',
      activeModal: {
        show: false,
        data: {
          id: '',
          title: '',
          start_date: '',
          end_date: ''
        },
        isLoading: false,
        errorMessage: ''
      },
      editModal: {
        show: false,
        data: {
          id: '',
          title: '',
          startDate: '',
          endDate: ''
        },
        isLoading: false,
        errorMessage: ''
      },
      deleteModal: {
        show: false,
        data: {
          id: '',
          title: '',
          start_date: '',
          end_date: ''
        },
        isLoading: false,
        errorMessage: ''
      }
    };
  }
  componentDidMount() {
    axiosRequest('get', `admin/settings/semester`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);

    loadRule(['date', 'after_or_equal']);
  }
  showActiveModal = semester => {
    this.setState({
      ...this.state,
      activeModal: {
        show: true,
        data: {...semester},
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideActiveModal = () => {
    this.setState({
      ...this.state,
      activeModal: {
        show: false,
        data: {
          id: '',
          title: '',
          start_date: '',
          end_date: ''
        },
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleSetAsActive = () => {
    this.setState({
      ...this.state,
      activeModal: {
        ...this.state.activeModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { activeModal } = this.state;

      axiosRequest('get', `admin/settings/semester/${activeModal.data.id}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          active: data,
          activeModal: {
            show: false,
            data: {
              id: '',
              title: '',
              start_date: '',
              end_date: ''
            },
            isLoading: false,
            errorMessage: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          activeModal: {
            ...this.state.activeModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showEditModal = (id) => {
    const semester = [...this.state.semesters.data].find(s => s.id === id);
    if (semester) {
      this.setState({
        ...this.state,
        editModal: {
          show: true,
          data: {
            ...semester,
            startDate: semester.start_date,
            endDate: semester.end_date,
          },
          isLoading: false,
          errorMessage: ''
        }
      });
    }
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        data: {
          id: '',
          title: '',
          startDate: '',
          endDate: ''
        },
        isLoading: false,
        errorMessage: ''
      }
    })
  }
  handleEditInputChange = event => {
    const { editModal } = this.state;
    editModal.data[event.target.name] = event.target.value;
    this.setState({
      ...this.state,
      editModal
    });
  }
  handleEdit = event => {
    event.preventDefault();
    const editModal = {...this.state.editModal};

    let rules = {
      id: 'required|integer',
      title: 'required|max:255',
      startDate: 'required|date',
      endDate: 'required|date|after_or_equal:startDate'
    };
    
    let validation = new Validator(editModal.data, rules);

    if (validation.fails()) {
      const firstKey = Object.keys(validation.errors.errors)[0];
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          errorMessage: validation.errors.errors[firstKey][0]
        }
      });
      return;
    }

    axiosRequest('patch', `admin/settings/semester/${editModal.data.id}`, {
      title: editModal.data.title,
      startDate: editModal.data.startDate,
      endDate: editModal.data.endDate
    }, ({ data: { data } }) => {
      const semesters = [...this.state.semesters.data].map((p) => {
        if (p.id === editModal.data.id) {
          return data;
        }
        
        return p;
      });
      this.setState({
        ...this.state,
        semesters: {
          ...this.state.semesters,
          data: [
            ...semesters
          ]
        },
        editModal: {
          show: false,
          data: {
            id: '',
            title: '',
            startDate: '',
            endDate: ''
          },
          isLoading: false,
          errorMessage: ''
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  showDeleteModal = (id) => {
    const semester = [...this.state.semesters.data].find(s => s.id === id);
    if (semester) {
      this.setState({
        ...this.state,
        deleteModal: {
          show: true,
          data: {...semester},
          isLoading: false,
          errorMessage: ''
        }
      });
    }
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        data: {
          id: '',
          title: '',
          start_date: '',
          end_date: ''
        },
        isLoading: false,
        errorMessage: ''
      }
    })
  }
  handleDelete = () => {
    const deleteModal = {...this.state.deleteModal};

    axiosRequest('delete', `admin/settings/semester/${deleteModal.data.id}`, null, (res) => {
      const semesters = [...this.state.semesters.data].filter(s => {
        return s.id !== deleteModal.data.id;
      });
      this.setState({
        ...this.state,
        semesters: {
          ...this.state.semesters,
          data: [
            ...semesters
          ]
        },
        deleteModal: {
          show: false,
          data: {
            id: '',
            title: '',
            start_date: '',
            end_date: ''
          },
          isLoading: false,
          errorMessage: ''
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        deleteModal: {
          ...this.state.deleteModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [event.target.name]: event.target.value
      }
    });
  }
  handlePagination = url => {
    if (url) {
      this.setState({
        ...this.state,
        isLoading: true
      }, () => {
        const path = url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
        
        axiosRequest('get', path, null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            semesters: data,
            isLoading: false,
            errorMessage: ''
          });
        }, (error) => {
          this.setState({
            ...this.state,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    }
  }
  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: ''
    }, () => {
      const { formInputs } = this.state;

      let validator = new Validator(formInputs, {
        title: 'required',
        startDate: 'required|date',
        endDate: 'required|date|after_or_equal:startDate'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: validator.errors.errors[firstKey][0]
        });
        return;
      }

      axiosRequest('post', `admin/settings/semester`, formInputs, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          semesters: {
            ...this.state.semesters,
            data: [
              ...this.state.semesters.data,
              data
            ]
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  renderSemester = () => {
    const { isLoading, errorMessage, formInputs, isFormLoading, formError } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        {
          formError ? (
            <Alert variant='danger'>
              {formError}
            </Alert>
          ) : (
            <Alert variant='warning'>
              <div>Set the start and end date of the semester.</div>
              <div>Badges recognition will be awarded on and after the end date.</div>
            </Alert>
          )
        }
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>Start Date</Form.Label>
              <Form.Control type='date' name='startDate' value={formInputs.startDate} onChange={this.handleInputChange} />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>End Date</Form.Label>
              <Form.Control type='date' min={formInputs.startDate} name='endDate' value={formInputs.endDate} onChange={this.handleInputChange} />
            </Form.Group>
          </Form.Row>
          <div className='text-right'>
            <Button variant='danger' className='mr-2' onClick={this.handleReset} disabled={isFormLoading}>
              Reset
            </Button>
            <Button variant='green' type='submit' disabled={isFormLoading}>
              Save
            </Button>
          </div>
        </Form>
      </>
    );
  }
  renderTable = () => {
    const { isLoading, errorMessage, semesters, active } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        {
          semesters.data.length > 0 ? (
            <>
              <Table striped bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    semesters.data.map(semester => {
                      let currentActive = active && active.id && active.id === semester.id;

                      return (
                        <tr key={semester.id} className={currentActive ? 'table-green' : ''}>
                          <td>{semester.id}</td>
                          <td>{semester.title}</td>
                          <td>{semester.start_date}</td>
                          <td>{semester.end_date}</td>
                          <td className='text-center'>
                            {
                              !currentActive && (
                                <Button
                                  variant='primary'
                                  size='sm'
                                  title='Set as active'
                                  className='mx-1'
                                  onClick={() => this.showActiveModal(semester)}>
                                  <FontAwesomeIcon icon='check' />
                                </Button>
                              )
                            }
                            <Button
                              variant='info'
                              size='sm'
                              title='Edit'
                              className='mx-1'
                              onClick={(e) => {this.showEditModal(semester.id)}}>
                              <FontAwesomeIcon icon='pencil-alt' />
                            </Button>
                            {
                              !currentActive && (
                                <Button variant='danger' size='sm' title='Delete' className='mx-1' onClick={(e) => {this.showDeleteModal(semester.id)}}>
                                  <FontAwesomeIcon icon='trash-alt' />
                                </Button>
                              )
                            }
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </Table>
              <Pagination className='justify-content-end'>
                <Pagination.First onClick={() => this.handlePagination(semesters.first_page_url)} disabled={semesters.current_page === 1} />
                <Pagination.Prev onClick={() => this.handlePagination(semesters.prev_page_url)} disabled={semesters.current_page === 1} />
                <Pagination.Next onClick={() => this.handlePagination(semesters.next_page_url)} disabled={semesters.current_page === semesters.last_page} />
                <Pagination.Last onClick={() => this.handlePagination(semesters.last_page_url)} disabled={semesters.current_page === semesters.last_page} />
              </Pagination>
            </>
          ) : (
            <Alert variant='light'>
              Nothing to show
            </Alert>
          )
        }
      </>
    );
  }
  render() {
    const { formInputs, isFormLoading, formError, activeModal, editModal, deleteModal } = this.state;

    return (
      <Card>
        <Card.Body>
          {
            formError ? (
              <Alert variant='danger'>
                {formError}
              </Alert>
            ) : (
              <Alert variant='warning'>
                <div>Set the start and end date of the semester.</div>
                <div>Badges recognition will be awarded on and after the end date.</div>
              </Alert>
            )
          }
          <Form onSubmit={this.handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} lg={4}>
                <Form.Label>Title</Form.Label>
                <Form.Control type='text' name='title' value={formInputs.title} onChange={this.handleInputChange} placeholder='e.g. 1st sem S.Y. 2020-2021' />
              </Form.Group>
              <Form.Group as={Col} lg={3}>
                <Form.Label>Start Date</Form.Label>
                <Form.Control type='date' name='startDate' value={formInputs.startDate} onChange={this.handleInputChange} />
              </Form.Group>
              <Form.Group as={Col} lg={3}>
                <Form.Label>End Date</Form.Label>
                <Form.Control type='date' min={formInputs.startDate} name='endDate' value={formInputs.endDate} onChange={this.handleInputChange} />
              </Form.Group>
              <Form.Group as={Col} lg={2} className='d-flex align-items-end'>
                <Button variant='green' type='submit' disabled={isFormLoading} block>
                  Add
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
          <div className='dropdown-divider'></div>
          {this.renderTable()}
          
          <Modal show={activeModal.show} onHide={this.hideActiveModal}>
            <Modal.Header closeButton>
              <Modal.Title>Set semester as active</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                activeModal.errorMessage && (
                  <Alert variant='danger'>
                    {activeModal.errorMessage}
                  </Alert>
                )
              }
              <Card.Subtitle>Are you sure you want to set the semester as active?</Card.Subtitle>
              <Alert variant='green' className='mt-2'>
                <div>{activeModal.data.title}</div>
                <div>{activeModal.data.start_date}</div>
                <div>{activeModal.data.end_date}</div>
              </Alert>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='green' disabled={activeModal.isLoading} onClick={this.handleSetAsActive}>
                Set
              </Button>
              <Button variant='danger' onClick={this.hideActiveModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <AdminSettingsModal
            {...editModal}
            title='Edit semester'
            onHide={this.hideEditModal}
            onSubmit={this.handleEdit}>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type='text'
                placeholder='e.g. 1st sem S.Y. 2020-2021'
                name='title'
                value={editModal.data.title}
                onChange={this.handleEditInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type='date'
                name='startDate'
                value={editModal.data.startDate}
                onChange={this.handleEditInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type='date'
                name='endDate'
                value={editModal.data.endDate}
                onChange={this.handleEditInputChange} />
            </Form.Group>
          </AdminSettingsModal>
          <PromptDeleteModal
            {...deleteModal}
            title='Delete program'
            onHide={this.hideDeleteModal}
            onDelete={this.handleDelete}>
              <Card.Subtitle>Are you sure you want to delete the semester?</Card.Subtitle>
              <Alert variant='light'>
                <div>{deleteModal.data.title}</div>
                <div>{deleteModal.data.start_date}</div>
                <div>{deleteModal.data.end_date}</div>
              </Alert>
          </PromptDeleteModal>
        </Card.Body>
      </Card>
    );
  }
}