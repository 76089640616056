import React, { Component, createRef } from 'react';
import { Alert, Button, Col, Form, Image, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SignaturePad from 'react-signature-pad-wrapper';
import moment from 'moment';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import signatureRemoveBlank from '../../../../../util/helpers/signatureRemoveBlank';
import Validator from 'validatorjs';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

export default class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      emergencyContact: null,
      signature: '',
      id: null,
      approvalRequests: [],
      signatureModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        uploadSignature: false,
        signature: {
          preview: null,
          isLoading: false,
          fileName: ''
        }
      },
      contactModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        formInputs: {
          title: '',
          name: '',
          address: '',
          phone: ''
        }
      },
      idModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      }
    };

    this.signaturePad = createRef();

    this.signature = null;
  }
  componentDidMount() {
    const { profile } = this.props;
    axiosRequest('get', `profile-menu/${profile.id}/information`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.signaturePad && this.signaturePad.current && prevState.signatureModal.show !== this.state.signatureModal.show) {
      this.resizeCanvas();
    }
  }
  resizeCanvas = () => {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);
    this.signaturePad.current.signaturePad.canvas.width = this.signaturePad.current.signaturePad.canvas.offsetWidth * ratio;
    this.signaturePad.current.signaturePad.canvas.height = this.signaturePad.current.signaturePad.canvas.offsetHeight * ratio;
    this.signaturePad.current.signaturePad.canvas.getContext("2d").scale(ratio, ratio);
    this.signaturePad.current.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }
  showSignatureModal = () => {
    this.setState({
      ...this.state,
      signatureModal: {
        ...this.state.signatureModal,
        show: true
      }
    });
  }
  hideSignatureModal = () => {
    this.setState({
      ...this.state,
      signatureModal: {
        ...this.state.signatureModal,
        show: false,
        uploadSignature: false,
        signature: {
          preview: null,
          isLoading: false,
          fileName: ''
        }
      }
    }, () => {
      this.signature = null;
    });
  }
  handleClearSignature = e => {
    this.signaturePad.current.signaturePad.clear();
  }
  handleSignatureSwitch = e => {
    this.setState({
      ...this.state,
      signatureModal: {
        ...this.state.signatureModal,
        uploadSignature: !this.state.signatureModal.uploadSignature,
        signature: {
          signature: {
            ...this.state.signatureModal.signature,
            preview: null,
            isLoading: false,
            fileName: ''
          }
        }
      }
    }, () => {
      this.signature = null;
    });
  }
  handleSignatureChange = event => {
    const files = event.target.files;

    if (files.length > 0) {
      this.setState({
        ...this.state,
        signatureModal: {
          ...this.state.signatureModal,
          signature: {
            ...this.state.signatureModal.signature,
            isLoading: true,
            fileName: ''
          },
          errorMessage: ''
        }
      }, () => {
        const fileToBase64 = file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result));
            reader.addEventListener('error', error => reject(error));
            reader.readAsDataURL(file);
          });
        };
        const image = files[0];
        let validator = {
          passed: true,
          error: null
        };

        if (typeof image.name !== 'string') {
          validator.passed = false;
          validator.error = 'Invalid file.';
        } else if (image.type !== 'image/png') {
          validator.passed = false;
          validator.error = 'Invalid image format.';
        }
        
        if (validator.passed) {
          fileToBase64(image).then(imageSrc => {
            this.setState({
              ...this.state,
              signatureModal: {
                ...this.state.signatureModal,
                signature: {
                  ...this.state.signatureModal.signature,
                  preview: imageSrc,
                  isLoading: false,
                  fileName: image.name
                }
              }
            }, () => {
              this.signature = image;
            });
          }).catch(() => {
            this.setState({
              ...this.state,
              signatureModal: {
                ...this.state.signatureModal,
                signature: {
                  ...this.state.signatureModal.signature,
                  preview: null,
                  isLoading: false,
                  fileName: ''
                },
                errorMessage: 'Failed to read image.'
              }
            }, () => {
              this.signature = null;
            });
          });
        } else {
          this.setState({
            ...this.state,
            signatureModal: {
              ...this.state.signatureModal,
              signature: {
                ...this.state.signatureModal.signature,
                preview: null,
                isLoading: false,
                fileName: ''
              },
              errorMessage: validator.error
            }
          }, () => {
            this.signature = null;
          });
        }
      });
    }
  }
  removeSignature = () => {
    this.setState({
      ...this.state,
      signatureModal: {
        ...this.state.signatureModal,
        signature: {
          ...this.state.signatureModal.signature,
          preview: null,
          isLoading: false,
          fileName: ''
        }
      }
    }, () => {
      this.signature = null
    });
  }
  disableSignaturePad = (disable = true) => {
    if (this.signaturePad && this.signaturePad.current) {
      if (disable) {
        this.signaturePad.current.signaturePad.off();
      } else {
        this.signaturePad.current.signaturePad.on();
      }
    }
  }
  handleSignature = () => {
    this.disableSignaturePad(true);

    this.setState({
      ...this.state,
      signatureModal: {
        ...this.state.signatureModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { signatureModal } = this.state;
      const { profile } = this.props;

      const formData = new window.FormData();

      if (signatureModal.uploadSignature) {
        formData.append('signature', this.signature, this.signature.name);

        axiosRequest('post', `profile-menu/${profile.id}/upload-signature`, formData, ({ data: { data }}) => {
          let newApprovalRequests = [...this.state.approvalRequests].filter(ar => ar.type !== 'Signature');
          newApprovalRequests = [
            ...newApprovalRequests,
            data
          ];
          this.setState({
            ...this.state,
            signatureModal: {
              ...this.state.signatureModal,
              isLoading: false
            },
            approvalRequests: newApprovalRequests
          }, this.hideSignatureModal);
        }, error => {
          this.setState({
            ...this.state,
            signatureModal: {
              ...this.state.signatureModal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          }, () => {
            this.disableSignaturePad(false);
          });
        }, this.props.history);
      } else {
        if (!this.signaturePad || !this.signaturePad.current || this.signaturePad.current.signaturePad.isEmpty()) {
          this.setState({
            ...this.state,
            signatureModal: {
              ...this.state.signatureModal,
              isLoading: false,
              errorMessage: 'Signature is required.'
            }
          }, () => {
            this.disableSignaturePad(false);
          });
          return;
        }

        let processedCanvas = signatureRemoveBlank(this.signaturePad.current.signaturePad.canvas);
        processedCanvas.toBlob(signature => {
          formData.append('signature', signature, 'signature.png');

          axiosRequest('post', `profile-menu/${profile.id}/upload-signature`, formData, ({ data: { data }}) => {
            let newApprovalRequests = [...this.state.approvalRequests].filter(ar => ar.type !== 'Signature');
            newApprovalRequests = [
              ...newApprovalRequests,
              data
            ];
            this.setState({
              ...this.state,
              signatureModal: {
                ...this.state.signatureModal,
                isLoading: false
              },
              approvalRequests: newApprovalRequests
            }, this.hideSignatureModal);
          }, error => {
            this.setState({
              ...this.state,
              signatureModal: {
                ...this.state.signatureModal,
                isLoading: false,
                errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
              }
            }, () => {
              this.disableSignaturePad(false);
            });
          }, this.props.history);
        });

        return;
      }
    });
  }
  showContactModal = () => {
    const { emergencyContact } = this.state;

    let custom = {};
    if (emergencyContact) {
      custom = {
        formInputs: {
          ...emergencyContact
        }
      };
    }

    this.setState({
      ...this.state,
      contactModal: {
        ...this.state.contactModal,
        ...custom,
        show: true
      }
    });
  }
  hideContactModal = () => {
    this.setState({
      ...this.state,
      contactModal: {
        ...this.state.contactModal,
        show: false,
        formInputs: {
          title: '',
          name: '',
          address: '',
          phone: ''
        }
      }
    });
  }
  handleContactInputChange = e => {
    this.setState({
      ...this.state,
      contactModal: {
        ...this.state.contactModal,
        formInputs: {
          ...this.state.contactModal.formInputs,
          [e.target.name]: e.target.value
        }
      }
    });
  }
  handleContact = e => {
    e.preventDefault();

    if (this.hasNoChanges()) {
      return;
    }

    this.setState({
      ...this.state,
      contactModal: {
        ...this.state.contactModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { contactModal } = this.state;
      const { profile } = this.props;

      let validator = new Validator(contactModal.formInputs, {
        title: 'required|string',
        name: 'required|string|min:2',
        address: 'required|string',
        phone: 'required|regex:/^09[0-9]{9}$/',
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          contactModal: {
            ...this.state.contactModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', `profile-menu/${profile.id}/emergency-contact`, {
        title: contactModal.formInputs.title,
        name: contactModal.formInputs.name,
        address: contactModal.formInputs.address,
        phone: contactModal.formInputs.phone
      }, ({ data: { data }}) => {
        let newApprovalRequests = [...this.state.approvalRequests].filter(ar => ar.type !== 'Emergency Contact');
        newApprovalRequests = [
          ...newApprovalRequests,
          data
        ];
        this.setState({
          ...this.state,
          contactModal: {
            ...this.state.contactModal,
            isLoading: false
          },
          approvalRequests: newApprovalRequests
        }, this.hideContactModal);
      }, error => {
        this.setState({
          ...this.state,
          contactModal: {
            ...this.state.contactModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  hasNoChanges = () => {
    const { contactModal, emergencyContact } = this.state;

    if (!emergencyContact) {
      return false;
    }

    return contactModal.formInputs.title === emergencyContact.title &&
            contactModal.formInputs.name === emergencyContact.name &&
            contactModal.formInputs.address === emergencyContact.address &&
            contactModal.formInputs.phone === emergencyContact.phone;
  }
  showIdModal = () => {
    this.setState({
      ...this.state,
      idModal: {
        ...this.state.idModal,
        show: true
      }
    });
  }
  hideIdModal = () => {
    this.setState({
      ...this.state,
      idModal: {
        ...this.state.idModal,
        show: false
      }
    });
  }
  handleID = e => {
    const { profile } = this.props;

    this.setState({
      ...this.state,
      idModal: {
        ...this.state.idModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      axiosRequest('post', `profile-menu/${profile.id}/identification-card`, {}, ({ data: { data }}) => {
        let newApprovalRequests = [...this.state.approvalRequests].filter(ar => ar.type !== 'Identification Card');
        newApprovalRequests = [
          ...newApprovalRequests,
          data
        ];
        this.setState({
          ...this.state,
          idModal: {
            ...this.state.idModal,
            isLoading: false
          },
          approvalRequests: newApprovalRequests
        }, this.hideIdModal);
      }, error => {
        this.setState({
          ...this.state,
          idModal: {
            ...this.state.idModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  renderId = () => {
    const { id, idModal, approvalRequests, signature, emergencyContact } = this.state;
    const { currentUser, profile } = this.props;
    
    let idRequest = approvalRequests.find(ar => ar.type === 'Identification Card');

    return (
      <>
        <div className='d-flex mt-2'>
          <div className='flex-fill h5 mb-0 mt-2'>
            <FontAwesomeIcon icon='id-badge' /> Identification Card
          </div>
          {
            currentUser.id === profile.id && (
              <div>
                {
                  !idRequest ? (
                    <Button variant='green' size='sm' onClick={this.showIdModal} disabled={!signature || !emergencyContact}>
                      <FontAwesomeIcon icon='hand-point-up' /> <span className='d-none d-md-inline-block'>Request</span>
                    </Button>
                  ) : (idRequest.date_approved || idRequest.date_declined) ? (
                    <Button variant='green' size='sm' onClick={this.showIdModal} disabled={!signature || !emergencyContact}>
                      <FontAwesomeIcon icon='hand-point-up' /> <span className='d-none d-md-inline-block'>Request again</span>
                    </Button>
                  ) : null
                }
              </div>
            )
          }
        </div>
        <div className='dropdown-divider'></div>
        {
          idRequest ? (
            <>
              {
                (!idRequest.date_approved && !idRequest.date_declined) ? (
                  <Alert variant='warning'>
                    <div>
                      Request was <b>sent</b> on {moment(idRequest.created_at).format('MMM D, YYYY hh:mm A')}
                    </div>
                  </Alert>
                ) : idRequest.date_approved ? (
                  <Alert variant='green'>
                    <div>
                      Request was <b>approved</b> on {moment(idRequest.date_approved).format('MMM D, YYYY hh:mm A')}
                    </div>
                  </Alert>
                ) : (
                  <Alert variant='danger'>
                    <div>
                      Request was <b>declined</b> on {moment(idRequest.date_declined).format('MMM D, YYYY hh:mm A')}
                    </div>
                    {
                      idRequest.comment && (
                        <div className='d-flex'>
                          <div>
                            <FontAwesomeIcon icon='comment' className='fa-fw mr-1' />
                          </div>
                          <div className='font-italic'>
                            {idRequest.comment}
                          </div>
                        </div>
                      )
                    }
                  </Alert>
                )
              }
              {
                id && (
                  <Row>
                    <Col md={6}>
                      <div>Front</div>
                      <div style={{ height: '10rem', textAlign: 'center' }}>
                        {
                          id?.front ? (
                            <a href={id.front}>
                              <Image src={id.front} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                            </a>
                          ) : (
                            <div style={{ lineHeight: '10rem' }} className='px-1 border rounded border-danger'>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip>
                                    No image found
                                  </Tooltip>
                                }
                                trigger={['hover', 'focus']}>
                                <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
                              </OverlayTrigger>
                            </div>
                          )
                        }
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>Back</div>
                      <div style={{ height: '10rem', textAlign: 'center' }}>
                        {
                          id?.back ? (
                            <a href={id.back}>
                              <Image src={id.back} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                            </a>
                          ) : (
                            <div style={{ lineHeight: '10rem' }} className='px-1 border rounded border-danger'>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip>
                                    No image found
                                  </Tooltip>
                                }
                                trigger={['hover', 'focus']}>
                                <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
                              </OverlayTrigger>
                            </div>
                          )
                        }
                      </div>
                    </Col>
                  </Row>
                )
              }
            </>
          ) : id ? (
            <Row>
              <Col md={6}>
                <div>Front</div>
                <div style={{ height: '10rem', textAlign: 'center' }}>
                  {
                    id?.front ? (
                      <a href={id.front}>
                        <Image src={id.front} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                      </a>
                    ) : (
                      <div style={{ lineHeight: '10rem' }} className='px-1 border rounded border-danger'>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              No image found
                            </Tooltip>
                          }
                          trigger={['hover', 'focus']}>
                          <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
                        </OverlayTrigger>
                      </div>
                    )
                  }
                </div>
              </Col>
              <Col md={6}>
                <div>Back</div>
                <div style={{ height: '10rem', textAlign: 'center' }}>
                  {
                    id?.back ? (
                      <a href={id.back}>
                        <Image src={id.back} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                      </a>
                    ) : (
                      <div style={{ lineHeight: '10rem' }} className='px-1 border rounded border-danger'>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              No image found
                            </Tooltip>
                          }
                          trigger={['hover', 'focus']}>
                          <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
                        </OverlayTrigger>
                      </div>
                    )
                  }
                </div>
              </Col>
            </Row>
          ) : (!signature || !emergencyContact) ? (
            <Alert variant='warning'>
              Signature and emergency contact must be submitted and approved first.
            </Alert>
          ) : (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          )
        }
        <Modal show={idModal.show} onHide={this.hideIdModal}>
          <Modal.Header closeButton>
            <Modal.Title>Request Identification Card</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              idModal.errorMessage ? (
                <Alert variant='danger'>
                  {idModal.errorMessage}
                </Alert>
              ) : (
                <Alert variant='warning'>
                  Request a downloadable copy of your identification card.
                </Alert>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={this.hideIdModal} disabled={idModal.isLoading}>
              Cancel
            </Button>
            <Button variant='green' onClick={this.handleID} disabled={idModal.isLoading}>
              Request
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  renderEmergencyContact = () => {
    const { emergencyContact, contactModal, approvalRequests } = this.state;

    let contactRequest = approvalRequests.find(ar => ar.type === 'Emergency Contact');

    return (
      <>
        {
          contactRequest ? (
            <>
              {
                (!contactRequest.date_approved && !contactRequest.date_declined) ? (
                  <Alert variant='warning'>
                    <div>
                      Awaiting for the admin's approval
                    </div>
                  </Alert>
                ) : contactRequest.date_approved ? (
                  <Alert variant='green'>
                    <div>
                      Request was <b>approved</b> on {moment(contactRequest.date_approved).format('MMM D, YYYY hh:mm A')}
                    </div>
                  </Alert>
                ) : (
                  <Alert variant='danger'>
                    <div>
                      Request was <b>declined</b> on {moment(contactRequest.date_declined).format('MMM D, YYYY hh:mm A')}
                    </div>
                    {
                      contactRequest.comment && (
                        <div className='d-flex'>
                          <div>
                            <FontAwesomeIcon icon='comment' className='fa-fw mr-1' />
                          </div>
                          <div className='font-italic'>
                            {contactRequest.comment}
                          </div>
                        </div>
                      )
                    }
                  </Alert>
                )
              }
              {
                (emergencyContact && !contactRequest.date_approved) ? (
                  <Row className='mb-3'>
                    <Col md>
                      <div className='p-3 rounded border'>
                        <div>{`${emergencyContact.title} ${emergencyContact.name}`}</div>
                        <div>{emergencyContact.address}</div>
                        <div>{emergencyContact.phone}</div>
                      </div>
                    </Col>
                    <Col className='text-center' md={2}>
                      <div className='h-100 d-flex justify-content-center align-items-center'>
                        <div className='d-md-inline-block d-none' style={{ fontSize: '2rem' }}>
                          <FontAwesomeIcon icon='long-arrow-alt-right' />
                        </div>
                        <div className='d-md-none my-3' style={{ fontSize: '2rem' }}>
                          <FontAwesomeIcon icon='long-arrow-alt-down' />
                        </div>
                      </div>
                    </Col>
                    <Col md>
                      <div className='p-3 rounded border'>
                        <div>{`${contactRequest.data.title} ${contactRequest.data.name}`}</div>
                        <div>{contactRequest.data.address}</div>
                        <div>{contactRequest.data.phone}</div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <div className='px-3'>
                    <div>{`${contactRequest.data.title} ${contactRequest.data.name}`}</div>
                    <div>{contactRequest.data.address}</div>
                    <div>{contactRequest.data.phone}</div>
                  </div>
                )
              }
            </>
          ) : emergencyContact ? (
            <div className='px-3'>
              <div>{`${emergencyContact.title} ${emergencyContact.name}`}</div>
              <div>{emergencyContact.address}</div>
              <div>{emergencyContact.phone}</div>
            </div>
          ) : (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          )
        }
        <Modal show={contactModal.show} onHide={this.hideContactModal} backdrop='static'>
          <Modal.Header closeButton>
            <Modal.Title>Edit Emergency Contact</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleContact}>
            <Modal.Body>
              {
                contactModal.errorMessage && (
                  <Alert variant='danger'>
                    {contactModal.errorMessage}
                  </Alert>
                )
              }
              <Form.Row>
                <Form.Group as={Col} md={3}>
                  <Form.Label>Title</Form.Label>
                  <Form.Control name='title' placeholder='e.g. Mr.' value={contactModal.formInputs.title} disabled={contactModal.isLoading} onChange={this.handleContactInputChange} />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control name='name' placeholder='e.g. Juan dela Cruz' value={contactModal.formInputs.name} disabled={contactModal.isLoading} onChange={this.handleContactInputChange} />
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control as='textarea' name='address' placeholder='e.g. Brgy. Poblacion, Sta. Cruz, Laguna' value={contactModal.formInputs.address} disabled={contactModal.isLoading} onChange={this.handleContactInputChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control name='phone' placeholder='e.g. 09123456789' value={contactModal.formInputs.phone} disabled={contactModal.isLoading} onChange={this.handleContactInputChange} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' onClick={this.hideContactModal} disabled={contactModal.isLoading}>
                Cancel
              </Button>
              <Button type='submit' variant='info' disabled={contactModal.isLoading || this.hasNoChanges()}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
  renderSignature = () => {
    const { signature, signatureModal, approvalRequests } = this.state;

    let signatureRequest = approvalRequests.find(ar => ar.type === 'Signature');

    return (
      <>
        {
          signatureRequest ? (
            <>
              {
                (!signatureRequest.date_approved && !signatureRequest.date_declined) ? (
                  <Alert variant='warning'>
                    <div>
                      Awaiting for the admin's approval
                    </div>
                  </Alert>
                ) : signatureRequest.date_approved ? (
                  <Alert variant='green'>
                    <div>
                      Request was <b>approved</b> on {moment(signatureRequest.date_approved).format('MMM D, YYYY hh:mm A')}
                    </div>
                  </Alert>
                ) : (
                  <Alert variant='danger'>
                    <div>
                      Request was <b>declined</b> on {moment(signatureRequest.date_declined).format('MMM D, YYYY hh:mm A')}
                    </div>
                    {
                      signatureRequest.comment && (
                        <div className='d-flex'>
                          <div>
                            <FontAwesomeIcon icon='comment' className='fa-fw mr-1' />
                          </div>
                          <div className='font-italic'>
                            {signatureRequest.comment}
                          </div>
                        </div>
                      )
                    }
                  </Alert>
                )
              }
              {
                (signature && !signatureRequest.date_approved) ? (
                  <Row className='mb-3'>
                    <Col md>
                      <div style={{ height: '7.5rem', textAlign: 'center' }}>
                        <Image src={signature} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
                      </div>
                    </Col>
                    <Col className='text-center' md={2}>
                      <span className='d-md-inline-block d-none' style={{ lineHeight: '7.5rem', fontSize: '2rem' }}>
                        <FontAwesomeIcon icon='long-arrow-alt-right' />
                      </span>
                      <span className='d-md-none' style={{ lineHeight: '5rem', fontSize: '2rem' }}>
                        <FontAwesomeIcon icon='long-arrow-alt-down' />
                      </span>
                    </Col>
                    <Col md>
                      <div style={{ height: '7.5rem', textAlign: 'center' }}>
                        <Image src={signatureRequest.signature} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <div className='mb-3' style={{ height: '7.5rem', textAlign: 'center' }}>
                    <Image src={signatureRequest.signature} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
                  </div>
                )
              }
            </>
          ) : signature ? (
            <div className='my-3' style={{ height: '7.5rem', textAlign: 'center' }}>
              <Image src={signature} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
            </div>
          ) : (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          )
        }
        <Modal show={signatureModal.show} onHide={this.hideSignatureModal} backdrop='static'>
          <Modal.Header closeButton>
            <Modal.Title>Edit Signature</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              signatureModal.errorMessage ? (
                <Alert variant='danger'>
                  {signatureModal.errorMessage}
                </Alert>
              ) : (
                <Alert variant='warning'>
                  <b>Modifications</b> are subject to the <b>admin's approval</b>.
                </Alert>
              )
            }
            <Form.Label>Signature</Form.Label>
            <Form.Group>
              <Form.Check
                  type='switch'
                  id='uploadSignature'
                  label='Upload Signature'
                  checked={signatureModal.uploadSignature}
                  onChange={this.handleSignatureSwitch}
                  disabled={signatureModal.isLoading}
                />
            </Form.Group>
            <div className='px-3'>
              {
                signatureModal.uploadSignature ? (
                  <>
                    {
                      signatureModal.signature.isLoading ? (
                        <div style={{ height: '10rem', textAlign: 'center', marginBottom: '.5rem' }}>
                          <LoadingIcon />
                        </div>
                      ) : (signatureModal.signature.fileName && signatureModal.signature.preview) ? (
                        <div style={{ height: '10rem', textAlign: 'center', marginBottom: '.5rem' }}>
                          <Image src={signatureModal.signature.preview} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
                        </div>
                      ) : null
                    }
                    <Form.File
                      id='signature'
                      label={signatureModal.signature.fileName ? signatureModal.signature.fileName : 'No file chosen'}
                      accept='image/png'
                      onChange={this.handleSignatureChange}
                      onClick={e => { e.target.value = null }}
                      disabled={signatureModal.signature.isLoading || signatureModal.isLoading}
                      custom />
                  </>
                ) : (
                  <>
                    <div className='text-right mb-1'>
                      <Button variant='danger' size='sm' onClick={this.handleClearSignature} disabled={signatureModal.isLoading}>
                        <FontAwesomeIcon icon='times' /> Clear
                      </Button>
                    </div>
                    <div className='d-block position-relative border rounded'>
                      <SignaturePad redrawOnResize ref={this.signaturePad} height={200} />
                    </div>
                  </>
                )
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={this.hideSignatureModal} disabled={signatureModal.isLoading}>
              Cancel
            </Button>
            <Button variant='info' onClick={this.handleSignature} disabled={signatureModal.isLoading}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  render() {
    const { currentUser, profile } = this.props;
    const { isLoading, errorMessage } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <div className='d-flex'>
          <div className='flex-fill h5 mb-0 mt-2'>
            <FontAwesomeIcon icon='signature' /> Signature
          </div>
          {
            currentUser.id === profile.id && (
              <div>
                <Button variant='info' size='sm' onClick={this.showSignatureModal}>
                  <FontAwesomeIcon icon='pencil-alt' /> <span className='d-none d-md-inline-block'>Edit</span>
                </Button>
              </div>
            )
          }
        </div>
        <div className='dropdown-divider'></div>
        { this.renderSignature() }
        <div className='d-flex mt-2'>
          <div className='flex-fill h5 mb-0 mt-2'>
            <FontAwesomeIcon icon='id-card' /> Emergency Contact
          </div>
          {
            currentUser.id === profile.id && (
              <div>
                <Button variant='info' size='sm' onClick={this.showContactModal}>
                  <FontAwesomeIcon icon='pencil-alt' /> <span className='d-none d-md-inline-block'>Edit</span>
                </Button>
              </div>
            )
          }
        </div>
        <div className='dropdown-divider'></div>
        { this.renderEmergencyContact() }
        { this.renderId() }
      </div>
    )
  }
}