import React, { Component } from 'react';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import PropTypes from 'prop-types';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import RichTextEditor from '../../../RichTextEditor/RichTextEditor';

class Matching extends Component {
  constructor(props) {
    super(props);
    this.state = {
      svgBox: {
        width: 0,
        height: 0
      },
      svgPosition: {
        top: 0,
        left: 0
      },
      lines: [],
      tempLine: null,
      activeLineIndex: false,
      activeDots: {
        items: [],
        answers: []
      },
      viewLines: true
    };

    this.refItems = [];
    this.refAnswers = [];
  }
  componentDidMount() {
    if (this.props.answer && this.props.answer.answer_matching) {
      const answers = [...this.props.answer.answer_matching].map(answer => {
        return {
          itemID: answer.question_matching_item_id,
          answer: answer.answer
        };
      });
      this.updateSvg(answers);
    }
    window.addEventListener('resize', () => this.updateSvg());
  }
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      if (this.props.answer && this.props.answer.answer_matching) {
        const answers = [...this.props.answer.answer_matching].map(answer => {
          return {
            itemID: answer.question_matching_item_id,
            answer: answer.answer
          };
        });
        this.updateSvg(answers);
      } else {
        this.setState({
          svgBox: {
            width: 0,
            height: 0
          },
          svgPosition: {
            top: 0,
            left: 0
          },
          lines: [],
          tempLine: null,
          activeLineIndex: false,
          activeDots: {
            items: [],
            answers: []
          },
          viewLines: true
        });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateSvg());
  }
  updateSvg = (answerData = null) => {
    const { items, answers } = this.props.question;
    
    let activeDots = answerData ? {
      items: [],
      answers: []
    } : {...this.state.activeDots};
    const lines = answerData ? [...answerData].map(ans => {
      let itemIndex = items.findIndex(item => item.id === ans.itemID);
      let answerIndex = answers.findIndex(an => an === ans.answer);

      activeDots.items.push(itemIndex);
      activeDots.answers.push(answerIndex);

      return {
        start: {
          index: itemIndex
        },
        end: {
          index: answerIndex
        }
      }
    }) : [...this.state.lines];

    let first = this.refItems[0].getBoundingClientRect();
    let last = this.refAnswers[answers.length-1].getBoundingClientRect();
    let top = first.y + window.pageYOffset;
    let left = first.x;

    let newLines = lines.map(line => {
      let start = this.refItems[line.start.index].getBoundingClientRect();
      let end = this.refAnswers[line.end.index].getBoundingClientRect();
      return {
        start: {
          index: line.start.index,
          x: start.x-left+(start.width/2),
          y: start.y-top+(start.height/2)+window.pageYOffset
        },
        end: {
          index: line.end.index,
          x: end.x-left+(end.width/2),
          y: end.y-top+(end.height/2)+window.pageYOffset
        },
      };
    });
    
    this.setState({
      ...this.state,
      svgBox: {
        width: last.x - first.x + last.width,
        height: last.y - first.y + last.height
      },
      svgPosition: {
        top,
        left
      },
      lines: newLines,
      activeDots
    });
  }
  onItemClick = index => {
    const { answers } = this.props.question;
    const { lines } = this.state;

    let first = this.refItems[0].getBoundingClientRect();
    let last = this.refAnswers[answers.length-1].getBoundingClientRect();
    let top = first.y + window.pageYOffset;
    let left = first.x;

    let current = this.refItems[index].getBoundingClientRect();

    let newLines = [...lines].filter(line => line.start.index !== index);
    let newActiveDots = {
      items: [...lines.map(line => line.start.index), index],
      answers: newLines.map(line => line.end.index)
    };

    this.setState({
      ...this.state,
      svgBox: {
        width: last.x - first.x + last.width,
        height: last.y - first.y + last.height
      },
      svgPosition: {
        top,
        left
      },
      tempLine: {
        x1: current.x-left+(current.width/2),
        y1: current.y-top+(current.height/2)+window.pageYOffset,
        x2: current.x-left+(current.width/2),
        y2: current.y-top+(current.height/2)+window.pageYOffset,
      },
      activeLineIndex: index,
      lines: newLines,
      activeDots: newActiveDots
    }, this.onChange);
  }
  onMove = event => {
    const { svgPosition, activeLineIndex } = this.state;
    let newTempLine = {...this.state.tempLine};

    if (activeLineIndex !== false && newTempLine) {
      newTempLine.x2 = event.pageX-svgPosition.left;
      newTempLine.y2 = event.pageY-svgPosition.top;
      this.setState({
        ...this.state,
        tempLine: newTempLine
      });
    }
  }
  onMouseUp = () => {
    const { activeLineIndex, activeDots } = this.state;

    if (activeLineIndex !== false) {
      const activeDotsItems = [...activeDots.items].filter(itemIndex => itemIndex !== activeLineIndex);
      this.setState({
        ...this.state,
        tempLine: null,
        activeLineIndex: false,
        activeDots: {
          ...this.state.activeDots,
          items: activeDotsItems
        }
      });
    }
  }
  onAnswerClick = (event, index) => {
    event.stopPropagation();
    const { tempLine, activeLineIndex, svgPosition: { top, left }, lines } = this.state;

    if (activeLineIndex !== false) {
      let current = this.refAnswers[index].getBoundingClientRect();

      let newLines = [...lines].filter(line => line.end.index !== index);
      let newActiveDots = {
        items: [...newLines.map(line => line.start.index), activeLineIndex],
        answers: [...newLines.map(line => line.end.index), index]
      };

      let line = {
        start: {
          index: activeLineIndex,
          x: tempLine.x1,
          y: tempLine.y1
        },
        end: {
          index,
          x: current.x-left+(current.width/2),
          y: current.y-top+(current.height/2)+window.pageYOffset,
        }
      };

      this.setState({
        ...this.state,
        tempLine: null,
        activeLineIndex: false,
        lines: [...newLines, line],
        activeDots: newActiveDots
      }, this.onChange);
    }
  }
  onChange = () => {
    const { question } = this.props;
    const { lines } = this.state;

    const answer = lines.map(line => {
      return {
        itemID: question.items[line.start.index].id,
        answer: question.answers[line.end.index]
      };
    });

    this.props.onChange({
      type: 'matching',
      questionID: this.props.id,
      answer
    });
  }
  handleFileDownload = fileID => {
    const { url, id } = this.props;

    axiosRequest('get', `${url}/${id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handleChangeView = e => {
    this.setState({
      ...this.state,
      viewLines: !this.state.viewLines
    }, () => {
      const { viewLines } = this.state;

      if (viewLines) {
        this.updateSvg();
      }
    });
  }
  onSelect = (itemIndex, answerIndex) => {
    if (answerIndex !== '') {
      itemIndex = +itemIndex;
      answerIndex = +answerIndex;

      let newLines = [...this.state.lines].filter(l => !(l.start.index === itemIndex || l.end.index === answerIndex));
      let newActiveDots = {
        items: [...(newLines.map(l => l.start.index)), itemIndex],
        answers: [...(newLines.map(l => l.end.index)), answerIndex]
      };

      let line = {
        start: {
          index: itemIndex
        },
        end: {
          index: answerIndex
        }
      };
      this.setState({
        ...this.state,
        lines: [
          ...newLines,
          line
        ],
        activeDots: newActiveDots
      }, this.onChange);
    } else {
      let line = [...this.state.lines].find(line => line.start.index === +itemIndex);
      let newItems = [...this.state.activeDots.items].filter(item => item !== +itemIndex);

      let newAnswers = [...this.state.activeDots.answers].filter(answer => +answer !== +line.end.index);
      let newLines = [...this.state.lines].filter(l => !(l.start.index === +itemIndex && l.end.index === +line.end.index));

      this.setState({
        ...this.state,
        lines: newLines,
        activeDots: {
          items: newItems,
          answers: newAnswers,
        }
      }, this.onChange);
    }
  }
  handlePreviewImages = (url, id, images, index) => {
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/${url}/${id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  render() {
    const { question, files, url, id, points } = this.props;
    const { svgBox, svgPosition, lines, tempLine, activeDots, activeLineIndex, viewLines } = this.state;

    const questionImages = files.filter(file => {
      return !!file.is_image;
    });

    const questionFiles = files.filter(file => {
      return !file.is_image;
    });

    return (
      <div className='matching'>
        <RichTextEditor.Viewer body={question.description} className='px-2 px-md-3 py-1 user-select-none' />
        {/* <div className='px-2 px-md-3 py-1'>{question.description}</div> */}
        {
          files.length > 0 && (
            <div className='px-2 px-md-3'>
              {
                questionImages.length > 0 && (
                  <Row>
                  {
                    questionImages.map((image, index) => (
                      <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(url, id, questionImages, index)}>
                        <Image src={`${process.env['REACT_APP_API_BASE_URL']}/${url}/${id}/image/${image.id}`} thumbnail />
                      </Col>
                    ))
                  }
                  </Row>
                )
              }
              {
                questionFiles.length > 0 && (
                  <div className='p-1'>
                    {
                      questionFiles.map(file => (
                        <small key={file.id} className='d-flex mb-2'>
                          <div className='mr-2'>
                            <FontAwesomeIcon icon='file' className='text-primary' />
                          </div>
                          <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                            <span className='btn-link' onClick={() => this.handleFileDownload(file.id)}>
                              {file.file_title}
                            </span>
                          </div>
                        </small>
                      ))
                    }
                  </div>
                )
              }
            </div>
          )
        }
        <div className='px-2 px-md-3 text-muted'>
          ({points} point{points > 1 ? 's' : ''})
        </div>
        <div className='text-right'>
          <Button variant='warning' onClick={this.handleChangeView} size='sm'>
            <FontAwesomeIcon icon='eye' /> <span className='d-none d-md-inline-block'>Switch view</span>
          </Button>
        </div>
        {
          viewLines ? (
            <>
              <svg
                {...svgBox}
                style={{
                  ...svgPosition,
                  position: 'absolute',
                  cursor: (activeLineIndex !== false ? 'grabbing' : 'default')
                }}
                onMouseMove={this.onMove}
                onMouseUp={this.onMouseUp}>
                {
                  tempLine && (
                    <line x1={tempLine.x1} y1={tempLine.y1} x2={tempLine.x2} y2={tempLine.y2} style={{stroke: '#131b7f', strokeWidth: 2 }} />
                  )
                }
                {
                  lines.map(({ start, end }, index) => (
                    <line key={index} x1={start.x} y1={start.y} x2={end.x} y2={end.y} style={{stroke: '#131b7f', strokeWidth: 2 }} />
                  ))
                }
              </svg>
              <div className='p-2 p-md-3 matching-items'>
                {
                  question.items.map((item, index) => (
                    <Row key={index} className='py-3'>
                      <Col className='user-select-none'>
                        {item.description}
                      </Col>
                      <Col
                        className='px-2 px-md-3'
                        style={{
                          userSelect: 'none',
                          cursor: (activeLineIndex !== false ? 'grabbing' : 'default')
                        }}
                        onMouseMove={this.onMove}
                        onMouseUp={this.onMouseUp}>
                        <Row>
                          <Col>
                            <span
                              style={{ cursor: (activeLineIndex !== false ? 'grabbing' : 'grab') }}
                              className='dot-connect'
                              ref={ref => {
                                if (ref) {
                                  this.refItems[index] = ref;
                                }
                              }}
                              onMouseDown={() => this.onItemClick(index)}
                              onMouseUp={e => e.stopPropagation()}
                              onMouseMove={this.onMove}>
                              <span className="fa-layers fa-fw">
                                <FontAwesomeIcon icon={['far', 'circle']} className='text-info' />
                                {
                                  activeDots.items.indexOf(index) !== -1 && (
                                    <FontAwesomeIcon icon="circle" transform="shrink-6" className='text-info' />
                                  )
                                }
                              </span>
                            </span>
                          </Col>
                          <Col className='text-right'>
                            <span
                              className='dot-connect'
                              style={{ cursor: (activeLineIndex !== false ? 'grabbing' : 'default') }}
                              ref={ref => {
                                if (ref) {
                                  this.refAnswers[index] = ref;
                                }
                              }}
                              onMouseMove={this.onMove}
                              onMouseUp={e => this.onAnswerClick(e, index)}
                              onClick={e => this.onAnswerClick(e, index)}>
                              <span className="fa-layers fa-fw">
                                <FontAwesomeIcon icon={['far', 'circle']} className='text-info' />
                                {
                                  activeDots.answers.indexOf(index) !== -1 && (
                                    <FontAwesomeIcon icon="circle" transform="shrink-6" className='text-info' />
                                  )
                                }
                              </span>
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col className='user-select-none'>
                        {question?.answers[index]}
                      </Col>
                    </Row>
                  ))
                }
              </div>
            </>
          ) : (
            <div className='p-2 p-md-3 matching-items'>
              <Row className='p-3 border d-block rounded'>
                {
                  question.answers.map((answer, index) => (
                    <div key={index} className='d-flex'>
                      <div className='mr-1'>
                        <span className="fa-layers fa-fw">
                          <FontAwesomeIcon icon={['far', 'circle']} className='text-info' />
                          {
                            activeDots.answers.indexOf(index) !== -1 && (
                              <FontAwesomeIcon icon="circle" transform="shrink-6" className='text-info' />
                            )
                          }
                        </span>
                      </div>
                      <div className='font-weight-bold mr-2'>
                        {`${String.fromCharCode(index+65)}.`}
                      </div>
                      <div className='flex-fill user-select-none'>
                        {answer}
                      </div>
                    </div>
                  ))
                }
              </Row>
              {
                question.items.map((item, index) => {
                  return (
                    <Row key={index} className='py-3'>
                      <Col md={8} lg={9} className='user-select-none'>
                        {item.description}
                      </Col>
                      <Col md={4} lg={3}>
                        <Form.Control
                          as='select'
                          onChange={e => this.onSelect(index, e.target.value)}
                          value={lines.find(line => line.start.index === index) ? lines.find(line => line.start.index === index).end.index : ''}>
                          <option value='' className='text-muted font-italic'>(None)</option>
                          {
                            question.answers.map((answer, i) => (
                              <>
                                {
                                  activeDots.answers.indexOf(i) !== -1 ? (
                                    <option key={i} value={i} className='pl-1'>
                                      &#11044; {`${String.fromCharCode(i+65)}.`}
                                    </option>
                                  ) : (
                                    <option key={i} value={i}>
                                      &#9675; {`${String.fromCharCode(i+65)}.`}
                                    </option>
                                  )
                                }
                              </>
                            ))
                          }
                        </Form.Control>
                      </Col>
                    </Row>
                  );
                })
              }
            </div>
          )
        }
      </div>
    );
  }
}

Matching.propTypes = {
  onChange: PropTypes.func.isRequired
};

Matching.defaultProps = {
  onChange: () => {}
};

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Matching);