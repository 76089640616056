import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProgramList from './parts/ProgramList';
import CourseList from './parts/CourseList';
import YearLevelList from './parts/YearLevelList';
import SectionList from './parts/SectionList';
import ClassList from './parts/ClassList';
import Semester from './parts/Semester';
import UploadLimit from './parts/UploadLimit';
import SchoolLevelList from './parts/SchoolLevelList';
import ExamCategoryList from './parts/ExamCategoryList';
import TermList from './parts/TermList';

const AdminSettings = props => {
  return (
    <Switch>
      <Route exact path='/admin/settings/programs' component={ProgramList} />
      <Route exact path='/admin/settings/courses' component={CourseList} />
      <Route exact path='/admin/settings/year-levels' component={YearLevelList} />
      <Route exact path='/admin/settings/sections' component={SectionList} />
      <Route exact path='/admin/settings/classes' component={ClassList} />
      <Route exact path='/admin/settings/school-levels' component={SchoolLevelList} />
      <Route exact path='/admin/settings/exam-categories' component={ExamCategoryList} />
      <Route exact path='/admin/settings/terms' component={TermList} />
      <Route exact path='/admin/settings/semester' component={Semester} />
      <Route exact path='/admin/settings/upload-limit' component={UploadLimit} />
      <Redirect to={{ pathname: '/admin/settings/programs'}} />
    </Switch>
  );
};

export default AdminSettings;
