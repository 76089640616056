import React, { Component } from 'react';
import { Alert, Button, Col, Collapse, Form, InputGroup, Modal, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Validator from 'validatorjs';
import { loadRule } from '../../../../util';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import axios from 'axios';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../common/RichTextEditor/RichTextEditor';

export default class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      formInputs: {
        title: '',
        description: '',
        from: '',
        until: '',
        category: '',
        points: 1,
        isAsync: false
      },
      fileNames: [],
      loadingFiles: false,
      isFormLoading: false,
      formError: '',
      formSuccess: '',
      showAttachments: false,
      deleteFiles: [],
      loadingDeleteFiles: [],
      asyncDate: null,
    };

    this.files = [];
  }
  componentDidMount() {
    const { materialData, asyncSchedules } = this.props;

    let custom = {};
    if (asyncSchedules && asyncSchedules.length > 0) {
      let asyncDate = asyncSchedules.find(as => moment().isSameOrBefore(as.date));
      asyncDate = asyncDate ? {...asyncDate} : null;
      custom = {
        asyncDate
      };
    }

    if (materialData) {
      if (materialData.material_async_schedule) {
        let asyncDate = {
          date: materialData.material_async_schedule.schedule_date,
          start_time: materialData.material_async_schedule.start_time,
          end_time: materialData.material_async_schedule.end_time,
          schedule_from: materialData.material_async_schedule.schedule_from
        };
        this.setState({
          ...this.state,
          formInputs: {
            title: materialData.title,
            description: materialData.description,
            from: moment(materialData.from).format('YYYY-MM-DDTHH:mm'),
            until: moment(materialData.until).format('YYYY-MM-DDTHH:mm'),
            category: materialData.grade_category_id,
            points: materialData.points,
            isAsync: true
          },
          isLoading: false,
          asyncDate
        }, () => {
          this.setAsyncSchedule(asyncDate);
        });
      } else {
        this.setState({
          ...this.state,
          formInputs: {
            title: materialData.title,
            description: materialData.description,
            from: moment(materialData.from).format('YYYY-MM-DDTHH:mm'),
            until: moment(materialData.until).format('YYYY-MM-DDTHH:mm'),
            category: materialData.grade_category_id,
            points: materialData.points,
            isAsync: false
          },
          isLoading: false,
          ...custom
        });
      }
    } else {
      this.setState({
        ...this.state,
        isLoading: false,
        ...custom
      });
    }

    loadRule(['date', 'after_or_equal'], {
      after_or_equal: 'The due date must be equal or after the current date and time.'
    });
  }
  handleCancelDeleteFile = id => {
    let newDeleteFiles = [...this.state.deleteFiles].filter(fileID => fileID !== id);
    this.setState({
      ...this.state,
      deleteFiles: newDeleteFiles
    });
  }
  handleDeleteFile = id => {
    this.setState({
      ...this.state,
      deleteFiles: [...this.state.deleteFiles, id]
    });
  }
  handleShowAttachments = () => {
    this.setState({
      ...this.state,
      showAttachments: !this.state.showAttachments
    });
  }
  handleFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.files = [...this.files, ...files];
      this.setState({
        ...this.state,
        fileNames: [
          ...this.state.fileNames,
          ...filesState
        ]
      });
    }
  }
  handleRemoveUpload = id => {
    let newFileNames = [...this.state.fileNames];
    newFileNames.splice(id, 1);

    this.setState({
      ...this.state,
      fileNames: newFileNames
    }, () => {
      this.files.splice(id, 1);
    });
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [event.target.name]: event.target.value
      }
    });
  }
  handleDescriptionInputChange = description => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        description
      }
    });
  }
  setAsyncSchedule = (currentAsyncDate = null) => {
    this.setState({
      ...this.state,
      formError: '',
      isFormLoading: true,
      formInputs: {
        ...this.state.formInputs,
        isAsync: true
      }
    }, () => {
      const { classID } = this.props.match.params;
      axiosRequest('get', `faculty/class/${classID}/material/async-schedules${currentAsyncDate ? `/${currentAsyncDate.date}` : ''}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isFormLoading: false
        }, () => {
          this.props.setAsyncSchedules(data, currentAsyncDate, (isSuccess, returnData) => {
            if (isSuccess) {
              this.setState({
                ...this.state,
                asyncDate: returnData,
                formInputs: {
                  ...this.state.formInputs,
                  from: moment(`${returnData.date} ${returnData.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                  until: moment(`${returnData.date} ${returnData.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                }
              });
            } else {
              this.setState({
                ...this.state,
                formInputs: {
                  ...this.state.formInputs,
                  isAsync: false
                },
                formError: 'There are no schedules available.'
              });
            }
          });
        });
      }, error => {
        this.setState({
          ...this.state,
          formError: (error.response && error.response.data.message) ? error.response.data.message : error.message ? error.message : error,
          isFormLoading: false,
          formInputs: {
            ...this.state.formInputs,
            isAsync: false
          }
        });
      }, this.props.history);
    });
  }
  handleAsyncInputChange = event => {
    const isAsync = event.target.checked;
    if (isAsync && this.props.asyncSchedules.length === 0) {
      this.setAsyncSchedule();
      return;
    }

    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        isAsync
      }
    });
  }
  handlePrevAsync = () => {
    const { asyncDate } = this.state;
    const { asyncSchedules } = this.props;
    
    if (asyncDate) {
      this.setState({
        ...this.state,
        isFormLoading: true
      }, () => {
        let index = asyncSchedules.findIndex(as1 => as1.date === asyncDate.date && as1.start_time === asyncDate.start_time && as1.end_time === asyncDate.end_time && as1.schedule_from === asyncDate.schedule_from);
        if (index !== -1) {
          if (index === 0) {
            this.props.onPrevAsync(asyncDate, newAsyncDate => {
              this.setState({
                ...this.state,
                asyncDate: newAsyncDate,
                formInputs: {
                  ...this.state.formInputs,
                  from: moment(`${newAsyncDate.date} ${newAsyncDate.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                  until: moment(`${newAsyncDate.date} ${newAsyncDate.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                },
                isFormLoading: false
              });
            });
          } else {
            this.setState({
              ...this.state,
              asyncDate: {...asyncSchedules[index-1]},
              formInputs: {
                ...this.state.formInputs,
                from: moment(`${{...asyncSchedules[index-1]}.date} ${{...asyncSchedules[index-1]}.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                until: moment(`${{...asyncSchedules[index-1]}.date} ${{...asyncSchedules[index-1]}.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
              },
              isFormLoading: false
            });
          }
        }
      });
    }
  }
  handleNextAsync = () => {
    const { asyncDate } = this.state;
    const { asyncSchedules } = this.props;
    
    if (asyncDate) {
      this.setState({
        ...this.state,
        isFormLoading: true
      }, () => {
        let index = asyncSchedules.findIndex(as1 => as1.date === asyncDate.date && as1.start_time === asyncDate.start_time && as1.end_time === asyncDate.end_time && as1.schedule_from === asyncDate.schedule_from);
        if (index !== -1) {
          if (index === asyncSchedules.length-1) {
            this.props.onNextAsync(asyncDate, newAsyncDate => {
              this.setState({
                ...this.state,
                asyncDate: newAsyncDate,
                formInputs: {
                  ...this.state.formInputs,
                  from: moment(`${newAsyncDate.date} ${newAsyncDate.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                  until: moment(`${newAsyncDate.date} ${newAsyncDate.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                },
                isFormLoading: false
              });
            });
          } else {
            this.setState({
              ...this.state,
              asyncDate: {...asyncSchedules[index+1]},
              formInputs: {
                ...this.state.formInputs,
                from: moment(`${{...asyncSchedules[index+1]}.date} ${{...asyncSchedules[index+1]}.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                until: moment(`${{...asyncSchedules[index+1]}.date} ${{...asyncSchedules[index+1]}.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
              },
              isFormLoading: false
            });
          }
        }
      });
    }
  }
  handleCreate = inputs => {
    const { classID } = this.props.match.params;

    axiosRequest('post', `faculty/class/${classID}/material/assignment`, inputs, ({ data: { data, message }}) => {
      if (this.files.length > 0) {
        let fileRequests = [];
        for (let i = 0; i < this.files.length; i++) {
          fileRequests.push(this.uploadFile(i, classID, data.id));
        }
        Promise.all(fileRequests).then(result => {
          data.files  = result;
          this.setState({
            ...this.state,
            formSuccess: message
          }, () => {
            this.props.onSave(data);
          });
        }).catch((error) => {
          this.setState({
            ...this.state,
            formError: error,
            isFormLoading: false,
            loadingFiles: false
          });
        });
      } else {
        this.setState({
          ...this.state,
          formSuccess: message
        }, () => {
          this.props.onSave(data);
        });
      }
    }, (error) => {
      this.setState({
        ...this.state,
        formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        isFormLoading: false,
        loadingFiles: false
      });
    }, this.props.history);
  }
  handleUpdate = inputs => {
    const { deleteFiles } = this.state;

    const { classID } = this.props.match.params;
    axiosRequest('patch', `faculty/class/${classID}/material/assignment/${this.props.materialData.id}`, inputs, ({ data: { data, message }}) => {
      data = {...this.props.materialData, ...data};
      let uploadRequests = [];
      let deleteRequests = [];

      if (this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          uploadRequests.push(this.uploadFile(i, classID, this.props.materialData.id));
        }
      }

      if (deleteFiles.length > 0) {
        for (let i = 0; i < deleteFiles.length; i++) {
          deleteRequests.push(this.deleteFile(deleteFiles[i], classID, this.props.materialData.id));
        }
      }

      if (uploadRequests.length && deleteRequests.length) {
        Promise.all([Promise.all(uploadRequests), Promise.all(deleteRequests)]).then(result => {
          data.files = [...result[0], ...this.props.materialData.files];
          data.files = data.files.filter(file => result[1].indexOf(file.id) === -1);
          this.setState({
            ...this.state,
            formSuccess: message
          }, () => {
            this.props.onUpdate(data);
          });
        }).catch((error) => {
          this.setState({
            ...this.state,
            formError: error,
            isFormLoading: false
          });
        });
      } else if (uploadRequests.length) {
        Promise.all(uploadRequests).then(result => {
          data.files = [...result, ...this.props.materialData.files];
          this.setState({
            ...this.state,
            formSuccess: message
          }, () => {
            this.props.onUpdate(data);
          });
        }).catch((error) => {
          this.setState({
            ...this.state,
            formError: error,
            isFormLoading: false
          });
        });
      } else if (deleteRequests.length) {
        Promise.all(deleteRequests).then(result => {
          data.files = [...this.props.materialData.files];
          data.files = data.files.filter(file => result.indexOf(file.id) === -1);
          this.setState({
            ...this.state,
            formSuccess: message
          }, () => {
            this.props.onUpdate(data);
          });
        });
      } else {
        this.setState({
          ...this.state,
          formSuccess: message
        }, () => {
          this.props.onUpdate(data);
        });
      }
    }, (error) => {
      this.setState({
        ...this.state,
        formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        isFormLoading: false
      });
    }, this.props.history);
  }
  uploadFile = (i, classID, assignmentID) => {
    const formData = new window.FormData();
    formData.append('file', this.files[i], this.files[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/assignment/${assignmentID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { fileNames } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          fileNames
        });
      }
    }).then(({ data: { data }}) => {
      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        fileNames
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        fileNames
      });
      throw fileNames[i].isError;
    });
  }
  deleteFile = (id, classID, assignmentID) => {
    return axios.delete(`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/assignment/${assignmentID}/file/${id}`, {
      withCredentials: true,
    }).then(({ data: { data }}) => {
      let newLoadingDeleteFiles = [...this.state.loadingDeleteFiles].filter(fileID => fileID !== id);
      this.setState({
        ...this.state,
        loadingDeleteFiles: newLoadingDeleteFiles
      });
      return id;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }
    });
  }
  handleSave = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: '',
      loadingFiles: true,
      loadingDeleteFiles: [...this.state.deleteFiles]
    }, () => {
      const { formInputs, asyncDate } = this.state;
      
      let validator = new Validator(formInputs, {
        title: 'required|min:3',
        description: 'required',
        from: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`,
        until: 'required|date|after_or_equal:from',
        category: 'required|min:1',
        points: 'required|numeric|min:1',
        isAsync: 'required|boolean'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          formError: validator.errors.errors[firstKey][0],
          isFormLoading: false
        });
        return;
      }

      let custom = {};
      if (formInputs.isAsync) {
        validator = {
          passes: true,
          error: ''
        };

        if (asyncDate === null) {
          validator.passes = false;
          validator.error = 'Asynchronous schedule is required.';
        } else {
          let asyncEnd = moment(`${asyncDate.date} ${asyncDate.end_time}`, 'YYYY-MM-DD HH:mm');
          if (moment(formInputs.from).isSameOrAfter(asyncEnd)) {
            validator.passes = false;
            validator.error = 'The "from" field must be before the end of the asynchronous schedule.';
          }
        }

        if (!validator.passes) {
          this.setState({
            ...this.state,
            formError: validator.error,
            isFormLoading: false
          });
          return;
        }

        custom = {
          asyncDate: asyncDate.date,
          asyncStartTime: asyncDate.start_time,
          asyncEndTime: asyncDate.end_time,
          asyncFrom: asyncDate.schedule_from
        };
      }
      
      if (this.props.materialData) {
        this.handleUpdate({...formInputs, ...custom});
      } else {
        this.handleCreate({...formInputs, ...custom});
      }
    });
  }
  renderFileUpload = () => {
    const { fileNames, loadingFiles } = this.state;
    if (fileNames.length > 0) {
      return (
        <Form.Group>
          <Form.Label>File(s) to be uploaded</Form.Label>
          <small className='file-upload-display d-block rounded-top'>
          {
            fileNames.map((fileName, index) => (
              <div key={index} className='file-name d-flex'>
                <div>
                  {
                    fileName.isLoading || (loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                      <LoadingIcon />
                    ) : fileName.isError ? (
                      <FontAwesomeIcon icon='times-circle' className='text-danger' />
                    ) : fileName.isSuccess ? (
                      <FontAwesomeIcon icon='check-circle' className='text-green' />
                    ) : (
                      <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                    )
                  }
                </div>
                <div className='ml-2 flex-fill'>
                  <div>
                    {fileName.name}
                  </div>
                  {
                    fileName.isLoading && (
                      <div className='mt-1'>
                        <ProgressBar now={fileName.isLoading} />
                      </div>
                    )
                  }
                </div>
                {
                  (!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !loadingFiles) && (
                    <div className='ml-2 align-items-center d-flex'>
                      <Button
                        variant='link'
                        className='text-danger'
                        size='sm'
                        title='Remove'
                        onClick={(e) => this.handleRemoveUpload(index)}>
                        <FontAwesomeIcon icon='times' size='sm' />
                      </Button>
                    </div>
                  )
                }
              </div>
            ))
          }
          </small>
        </Form.Group>
      );
    }
  }
  renderAttachedFiles = () => {
    const { showAttachments, deleteFiles, loadingDeleteFiles, isFormLoading } = this.state;
    const { materialData } = this.props;

    if (materialData && materialData.files.length > 0) {
      return (
        <>
          <div className={`text-right border rounded-top ${showAttachments ? 'border-bottom-0' : 'rounded-bottom'}`}>
            <Button variant='link' onClick={this.handleShowAttachments} block>
              <FontAwesomeIcon icon='paperclip' /> Show {materialData.files.length} attachment(s)
            </Button>
          </div>
          <Collapse in={showAttachments}>
            <div className='file-upload-display small'>
            {
              materialData.files.map(file => (
                <div className={`file-name d-flex ${deleteFiles.indexOf(file.id) !== -1 ? 'alert-light' : ''}`}>
                  {
                    loadingDeleteFiles.indexOf(file.id) !== -1 && (
                      <LoadingIcon />
                    )
                  }
                  <div className='ml-2 flex-fill'>
                    <div>
                      {file.file_title}
                    </div>
                    {
                      deleteFiles.indexOf(file.id) !== -1 && (
                        <div className='font-italic'>
                          To be deleted
                        </div>
                      )
                    }
                  </div>
                  <div className='ml-2 align-items-center d-flex'>
                    {
                      deleteFiles.indexOf(file.id) !== -1 ? (
                        <Button
                          variant='link'
                          className='text-green'
                          size='sm'
                          title='Cancel delete'
                          onClick={() => this.handleCancelDeleteFile(file.id)}
                          disabled={isFormLoading}>
                          <FontAwesomeIcon icon='ban' size='sm' />
                        </Button>
                      ) : (
                        <Button
                          variant='link'
                          className='text-danger'
                          size='sm'
                          title='Delete'
                          onClick={() => this.handleDeleteFile(file.id)}
                          disabled={isFormLoading}>
                          <FontAwesomeIcon icon='trash-alt' size='sm' />
                        </Button>
                      )
                    }
                  </div>
                </div>
              ))
            }
            </div>
          </Collapse>
        </>
      );
    }
  }
  render() {
    const { isLoading, errorMessage, formInputs, isFormLoading, formError, formSuccess, asyncDate } = this.state;
    const { categories, materialData, asyncSchedules } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon className='m-3' />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger' className='m-3'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>
            {
              materialData ? 'Update assignment/activity' : 'Create an assignment/activity'
            }
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleSave} className='assignment-form'>
          <Modal.Body>
            {
              formError ? (
                <Alert variant='danger'>
                  {formError}
                </Alert>
              ) : formSuccess ? (
                <Alert variant='success'>
                  {formSuccess}
                </Alert>
              ) : null
            }
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type='text' name='title' value={formInputs.title} onChange={this.handleInputChange} disabled={isFormLoading} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <RichTextEditor.Editor
                value={formInputs.description}
                onChange={this.handleDescriptionInputChange}
                readOnly={isFormLoading} />
              {/* <Form.Control as='textarea' rows='4' style={{ resize: 'none' }} name='description' value={formInputs.description} onChange={this.handleInputChange} disabled={isFormLoading} /> */}
            </Form.Group>
            <Form.Group>
              <Form.Check id='isAsync' type='switch' label='Asynchronous' checked={formInputs.isAsync} onChange={this.handleAsyncInputChange} disabled={isFormLoading} />
              {
                (formInputs.isAsync && asyncSchedules && asyncSchedules.length > 0) && (
                  <div className='px-3 pt-2'>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <Button className='border' variant='light' onClick={this.handlePrevAsync} disabled={isFormLoading}>
                          <FontAwesomeIcon icon='chevron-left' />
                        </Button>
                      </InputGroup.Prepend>
                        <Form.Control
                          className='text-center'
                          value={asyncDate ? 
                            `${moment(asyncDate.date).format('MMMM D, YYYY (ddd)')} ${moment(asyncDate.start_time, 'HH:mm').format('hh:mm A')}-${moment(asyncDate.end_time, 'HH:mm').format('hh:mm A')}` : 
                            null}
                          type='text'
                          disabled={isFormLoading}
                          readOnly />
                      <InputGroup.Append>
                        <Button className='border' variant='light' onClick={this.handleNextAsync} disabled={isFormLoading}>
                          <FontAwesomeIcon icon='chevron-right' />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Text className='text-muted text-center'>Asynchronous schedule to which the material will be tagged to.</Form.Text>
                  </div>
                )
              }
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>From</Form.Label>
                <Form.Control type='datetime-local' name='from' min={moment().format('YYYY-MM-DDTHH:mm')} max={formInputs.until} value={formInputs.from} onChange={this.handleInputChange} disabled={isFormLoading} />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>Until</Form.Label>
                <Form.Control type='datetime-local' name='until' min={formInputs.from} value={formInputs.until} onChange={this.handleInputChange} disabled={isFormLoading} />
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Control as='select' name='category' value={formInputs.category} onChange={this.handleInputChange} disabled={isFormLoading}>
                <option hidden disabled value=''>Select category...</option>
                {
                  categories.map(category => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Points</Form.Label>
              <Form.Control type='number' name='points' value={formInputs.points} onChange={this.handleInputChange} disabled={isFormLoading} />
            </Form.Group>
            {this.renderFileUpload()}
            {this.renderAttachedFiles()}
          </Modal.Body>
          <Modal.Footer>
            {
              formSuccess ? (
                <Button variant='light' onClick={this.props.onCancel}>
                  Close
                </Button>
              ) : (
                <>
                  <div className='mr-auto'>
                    <label className={`btn btn-info ${isFormLoading ? 'disabled' : ''}`} htmlFor='add-file' title='File upload'>
                      <FontAwesomeIcon icon='file-upload' />
                    </label>
                    <Form.File className='d-none' id='add-file' onChange={this.handleFileUpload} multiple disabled={isFormLoading} />
                  </div>
                  <Button variant='danger mr-2' onClick={this.props.onCancel} disabled={isFormLoading}>
                    Cancel
                  </Button>
                  <Button variant='green' type='submit' disabled={isFormLoading}>
                    Save
                  </Button>
                </>
              )
            }
          </Modal.Footer>
        </Form>
      </>
    );
  }
}