import React, { Component } from 'react';
import { Alert, Breadcrumb, Nav } from 'react-bootstrap';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import Details from './parts/Details';
import Submissions from './parts/Submissions';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      assignment: null,
      activeNav: 'details'
    }
  }
  componentDidMount() {
    const { classID, materialID } = this.props.match.params;

    axiosRequest('get', `school-admin/class/${classID}/material/assignment/${materialID}`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        assignment: data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    });
  }
  handleMaterials = () => {
    const { classID } = this.props.match.params;
    this.props.history.push(`/school-admin/class/${classID}/materials`);
  }
  handleSelect = key => {
    this.setState({
      ...this.state,
      activeNav: key
    });
  }
  handleGrade = data => {
    const grades = [...this.state.assignment.grades];

    let hasRow = grades.find(grade => grade.student_id === data.student_id);
    let newGrades = grades;

    if (hasRow) {
      newGrades = grades.map(grade => {
        if (grade.student_id === data.student_id) {
          return data;
        }

        return grade;
      });
    } else {
      newGrades = [...newGrades, data];
    }

    this.setState({
      ...this.state,
      assignment: {
        ...this.state.assignment,
        grades: newGrades
      }
    });
  }
  renderContainer = () => {
    const { isLoading, errorMessage, activeNav, assignment } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.handleMaterials}>Materials</Breadcrumb.Item>
          <Breadcrumb.Item active>{assignment.title}</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <Nav variant='tabs bg-light rounded-top' className='flex-column flex-md-row' onSelect={this.handleSelect}>
            <Nav.Item>
              <Nav.Link eventKey='details' active={activeNav === 'details'}>Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='submissions' active={activeNav === 'submissions'}>Submissions</Nav.Link>
            </Nav.Item>
          </Nav>
          <div className='content border border-top-0 rounded-bottom p-3'>
          {
            activeNav !== 'details' ? (
              <Submissions {...this.props} assignment={assignment} onGrade={this.handleGrade} />
            ) : (
              <Details {...this.props} assignment={assignment} />
            )
          }
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className='border rounded p-4 my-3'>
        {this.renderContainer()}
      </div>
    );
  }
}