import React, { Component } from 'react';
import { Alert, Breadcrumb, Button, Card, Col, Form, InputGroup, ListGroup, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NameLink from '../../common/NameLink/NameLink';
import axiosRequest from '../../../util/helpers/axiosRequest';
import './style.scss';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

export default class DropCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      isNextPageLoading: false,
      nextPageError: '',
      prevStudents: {
        data: []
      },
      students: {
        show: false,
        search: '',
        isLoading: false,
        errorMessage: '',
        list: {
          data: []
        },
        isNextPageLoading: false,
        nextPageError: '',
        prevSearch: ''
      },
      subjects: {
        student: null,
        isLoading: false,
        errorMessage: '',
        backup: null,
        enrolled: [],
        dropped: [],
        hasChanged: false,
        isFormLoading: false,
        formError: '',
        modal: {
          show: false,
          warning1: false,
          warning2: false
        }
      }
    }
  }
  componentDidMount() {
    axiosRequest('get', 'admin/drop-courses/student', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        isLoading: false,
        prevStudents: data
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        search: event.target.value
      }
    });
  }
  handleSearch = e => {
    e.preventDefault();

    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        isLoading: true,
        errorMessage: '',
        show: true,
      }
    }, () => {
      const { students: { search } } = this.state;

      let prevSearch = search;
      if (!search) {
        this.setState({
          ...this.state,
          students: {
            ...this.state.students,
            show: false,
            isLoading: false,
            prevSearch
          }
        });
        return;
      }
      
      axiosRequest('get', `admin/drop-courses/student/search`, null, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          students: {
            ...this.state.students,
            isLoading: false,
            list: data,
            prevSearch
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          students: {
            ...this.state.students,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
            prevSearch
          }
        });
      }, this.props.history, {
        params: {
          searchQuery: search
        }
      });
    });
  }
  handleNextPagePrevStudents = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true,
      nextPageError: ''
    }, () => {
      const { prevStudents } = this.state;

      const path = prevStudents.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          prevStudents: {
            ...data,
            data: [
              ...prevStudents.data,
              ...data.data
            ]
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        isNextPageLoading: true,
        nextPageError: ''
      }
    }, () => {
      const { students: { list, prevSearch } } = this.state;

      const path = list.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          students: {
            ...this.state.students,
            isNextPageLoading: false,
            list: {
              ...data,
              data: [
                ...list.data,
                ...data.data
              ]
            }
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          students: {
            ...this.state.students,
            isNextPageLoading: false,
            nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history, {
        params: {
          searchQuery: prevSearch
        }
      });
    });
  }
  handleSelectStudent = student => {
    this.setState({
      ...this.state,
      subjects: {
        ...this.state.subjects,
        student,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      axiosRequest('get', `admin/drop-courses/student/${student.id}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          subjects: {
            ...this.state.subjects,
            ...data,
            backup: {...data},
            isLoading: false
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          subjects: {
            ...this.state.subjects,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleBack = e => {
    e.preventDefault();

    this.setState({
      ...this.state,
      subjects: {
        ...this.state.subjects,
        student: null,
        isLoading: true,
        errorMessage: ''
      }
    });
  }
  handleDrop = facultyLoad => {
    const newEnrolled = [...this.state.subjects.enrolled].filter(s => s.id !== facultyLoad.id);
    this.setState({
      ...this.state,
      subjects: {
        ...this.state.subjects,
        enrolled: newEnrolled,
        dropped: [
          ...this.state.subjects.dropped,
          facultyLoad
        ],
        hasChanged: true
      }
    });
  }
  handleDropAll = () => {
    this.setState({
      ...this.state,
      subjects: {
        ...this.state.subjects,
        enrolled: [],
        dropped: [
          ...this.state.subjects.enrolled,
        ],
        hasChanged: true
      }
    });
  }
  handleEnroll = facultyLoad => {
    const newDropped = [...this.state.subjects.dropped].filter(s => s.id !== facultyLoad.id);
    this.setState({
      ...this.state,
      subjects: {
        ...this.state.subjects,
        dropped: newDropped,
        enrolled: [
          ...this.state.subjects.enrolled,
          facultyLoad
        ],
        hasChanged: true
      }
    });
  }
  handleEnrollAll = () => {
    this.setState({
      ...this.state,
      subjects: {
        ...this.state.subjects,
        dropped: [],
        enrolled: [
          ...this.state.subjects.dropped,
        ],
        hasChanged: true
      }
    });
  }
  handleReset = () => {
    const { subjects: { backup } } = this.state;

    let custom = {};
    if (backup) {
      custom = {...backup};
    }
    this.setState({
      ...this.state,
      subjects: {
        ...this.state.subjects,
        ...custom,
        hasChanged: false
      }
    });
  }
  showModal = () => {
    const { subjects: { student, enrolled, dropped } } = this.state;

    let warning1 = false;
    if (student.student_class.school_class && dropped.length > 0) { // is REGULAR
      warning1 = true;
    }

    let warning2 = false;
    if (enrolled.length === 0) {
      warning2 = true;
    }

    this.setState({
      ...this.state,
      subjects: {
        ...this.state.subjects,
        formError: '',
        modal: {
          show: true,
          warning1,
          warning2
        }
      }
    });
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      subjects: {
        ...this.state.subjects,
        modal: {
          show: false,
          warning1: false,
          warning2: false
        }
      }
    });
  }
  handleSave = () => {
    this.setState({
      ...this.state,
      subjects: {
        ...this.state.subjects,
        isFormLoading: true,
        formError: ''
      }
    }, () => {
      const { subjects: { student, enrolled, dropped }, students: { list } } = this.state;

      let enrolledIDs = enrolled.map(e => e.id);
      let droppedIDs = dropped.map(d => d.id);

      axiosRequest('post', `admin/drop-courses/student/${student.id}`, {
        enrolled: enrolledIDs,
        dropped: droppedIDs
      }, ({ data: { data }}) => {
        let custom = {};
        let custom2 = {};
        if (student.student_class.school_class_id) {
          custom = {
            student: {
              ...student,
              profile: {
                ...student.profile,
                program_id: student.student_class.school_class.program_id,
                program: student.student_class.school_class.program,
                year_level_id: student.student_class.school_class.year_level_id,
                year_level: student.student_class.school_class.year_level
              },
              student_class: {
                ...student.student_class,
                school_class: null,
                school_class_id: null
              }
            }
          };

          let newData = [...list.data].map(s => {
            if (s.id === student.id) {
              return {
                ...custom.student
              };
            }

            return s;
          });
          custom2 = {
            students: {
              ...this.state.students,
              list: {
                ...list,
                data: [...newData]
              }
            }
          };
        }

        if (data.dropped.length > 0) {
          if (student.student_class.school_class_id) {
            custom2 = {
              ...custom2,
              prevStudents: {
                ...this.state.prevStudents,
                data: [
                  custom.student,
                  ...this.state.prevStudents.data
                ]
              }
            };
          } else {
            custom2 = {
              ...custom2,
              prevStudents: {
                ...this.state.prevStudents,
                data: [
                  student,
                  ...this.state.prevStudents.data
                ]
              }
            };
          }
        } else if (data.dropped.length === 0) {
          let newPrevStudents = [...this.state.prevStudents.data].filter(s => s.id !== student.id);
          custom2 = {
            ...custom2,
            prevStudents: {
              ...this.state.prevStudents,
              data: [...newPrevStudents]
            }
          };
        }

        let facultyLoads = [...enrolled, ...dropped];
        let newEnrolled = [];
        for (let i = 0; i < data.enrolled.length; i++) {
          let facultyLoad = facultyLoads.find(fl => fl.id === data.enrolled[i]);
          if (facultyLoad) {
            newEnrolled.push(facultyLoad);
          }
        }
        let newDropped = [];
        for (let i = 0; i < data.dropped.length; i++) {
          let facultyLoad = facultyLoads.find(fl => fl.id === data.dropped[i]);
          if (facultyLoad) {
            newDropped.push(facultyLoad);
          }
        }

        this.setState({
          ...this.state,
          ...custom2,
          subjects: {
            ...this.state.subjects,
            ...custom,
            backup: {
              enrolled: [...newEnrolled],
              dropped: [...newDropped]
            },
            enrolled: [...newEnrolled],
            dropped: [...newDropped],
            isFormLoading: false,
            formError: '',
            modal: {
              show: false,
              warning1: false,
              warning2: false
            }
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          subjects: {
            ...this.state.subjects,
            isFormLoading: false,
            formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  hasChanged = () => {
    const { subjects: { backup, enrolled, dropped, hasChanged } } = this.state;

    if (!hasChanged) {
      return false;
    }

    if (!backup || !backup.enrolled || !backup.dropped) {
      return true;
    }

    let newEnrolled = backup.enrolled.filter(be => enrolled.find(e => e.id === be.id));
    let newDropped = backup.dropped.filter(bd => dropped.find(d => d.id === bd.id));

    if (newEnrolled.length !== backup.enrolled.length || newDropped.length !== backup.dropped.length) {
      return true;
    }

    return false;
  }
  renderSubjects = () => {
    const {
      subjects: {
        student,
        isLoading,
        errorMessage,
        enrolled,
        dropped,
        isFormLoading,
        formError,
        modal
      }
    } = this.state;

    if (!student) {
      return null;
    }
    
    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }
    
    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (enrolled.length === 0 && dropped.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        {
          formError && (
            <Alert variant='danger'>
              {formError}
            </Alert>
          )
        }
        <Row>
          <Col lg={6}>
            <Card className='h-100'>
              <Card.Header>
                <div className='d-flex'>
                  <div className='flex-fill'>
                    <h5 className='m-0'>Enrolled</h5>
                  </div>
                  {
                    enrolled.length > 1 && (
                      <div>
                        <Button variant='danger' size='sm' disabled={isFormLoading} onClick={this.handleDropAll}>
                          Drop All
                        </Button>
                      </div>
                    )
                  }
                </div>
              </Card.Header>
              <Card.Body>
                <ListGroup>
                {
                  enrolled.map(s => (
                    <ListGroup.Item key={s.id}>
                      <div className='d-flex'>
                        <div className='flex-fill'>
                          <h6 className='m-0'>
                            {s.class_course.course.description} ({s.class_course.course.code})
                          </h6>
                          <div>
                            {s.profile.name}
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <Button variant='danger' size='sm' onClick={e => this.handleDrop(s)} disabled={isFormLoading}>
                            Drop <FontAwesomeIcon icon='angle-double-right' />
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))
                }
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className='h-100'>
              <Card.Header>
                <div className='d-flex'>
                  <div className='flex-fill'>
                    <h5 className='m-0'>Dropped</h5>
                  </div>
                  {
                    dropped.length > 1 && (
                      <div>
                        <Button variant='green' size='sm' disabled={isFormLoading} onClick={this.handleEnrollAll}>
                          Enroll All
                        </Button>
                      </div>
                    )
                  }
                </div>
              </Card.Header>
              <Card.Body>
                <ListGroup>
                {
                  dropped.map(s => (
                    <ListGroup.Item key={s.id}>
                      <div className='d-flex'>
                        <div className='flex-fill'>
                          <h6 className='m-0'>
                            {s.class_course.course.description} ({s.class_course.course.code})
                          </h6>
                          <div>
                            {s.profile.name}
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <Button variant='green' size='sm' onClick={e => this.handleEnroll(s)} disabled={isFormLoading}>
                            Enroll <FontAwesomeIcon icon='angle-double-left' />
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))
                }
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {
          this.hasChanged() && (
            <div className='text-right mt-3'>
              <Button variant='danger' onClick={this.handleReset} className='mr-2' disabled={isFormLoading}>
                Reset
              </Button>
              <Button variant='green' onClick={this.showModal} disabled={isFormLoading}>
                Save
              </Button>
            </div>
          )
        }
        <Modal show={modal.show} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>{student.profile.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              formError ? (
                <Alert variant='danger'>
                  {formError}
                </Alert>
              ) : (modal.warning1 || modal.warning2) ? (
                <Alert variant='warning'>
                  Proceeding will:
                  <ul>
                    {
                      modal.warning1 && (
                        <li>
                          change the student's status from <b>regular</b> to <b>irregular</b>.
                        </li>
                      )
                    }
                    {
                      modal.warning2 && (
                        <li>
                          <b>block</b> the student from accessing VLE.
                        </li>
                      )
                    }
                  </ul>
                </Alert>
              ) : null
            }
            Are you sure you want to save the changes?
            <Alert variant='light'>
              <div className='d-flex'>
                {
                  enrolled.length > 0 && (
                    <div className='flex-fill'>
                      <h6>Enrolled</h6>
                      <ul>
                        {
                          enrolled.map(s => (
                            <li key={s.id}>
                              {s.class_course.course.description} ({s.class_course.course.code})
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  )
                }
                {
                  dropped.length > 0 && (
                    <div className='flex-fill'>
                      <h6>Dropped</h6>
                      <ul>
                        {
                          dropped.map(s => (
                            <li key={s.id}>
                              {s.class_course.course.description} ({s.class_course.course.code})
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  )
                }
              </div>
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={this.hideModal} className='mr-2' disabled={isFormLoading}>
              Cancel
            </Button>
            <Button variant='green' onClick={this.handleSave} disabled={isFormLoading}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  renderPreviousStudents = () => {
    const { students: { show }, prevStudents, isLoading, errorMessage, isNextPageLoading, nextPageError } = this.state;

    if (show) {
      return null;
    }

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }
    
    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (prevStudents.data.length === 0) {
      return null;
    }

    return (
      <ListGroup>
        {
          prevStudents.data.map(st => (
            <ListGroup.Item key={st.id} className='drop-courses-student btn-light' onClick={e => this.handleSelectStudent(st)}>
              <div className='d-flex'>
                <div className='flex-fill'>
                  <div className='d-flex'>
                    <h5>
                      <NameLink id={st.id} name={st.profile.name} image={st.profile.image} />
                    </h5>
                  </div>
                  {
                    st.student_class.school_class ? (
                      <>
                        <div className='font-weight-bold'>Regular</div>
                        <div>{st.student_class.school_class.program.description}</div>
                        <div>{st.student_class.school_class.year_level.description}</div>
                        <div>Section {st.student_class.school_class.section.code}</div>
                      </>
                    ) : (
                      <>
                        <div className='font-weight-bold'>Irregular</div>
                        <div>{st.profile.program.description}</div>
                        <div>{st.profile.year_level.description}</div>
                      </>
                    )
                  }
                </div>
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon icon='chevron-right' size='lg' />
                </div>
              </div>
            </ListGroup.Item>
          ))
        }
        {
          prevStudents.next_page_url && (
            <ListGroup.Item className={`text-center btn-link ${isNextPageLoading ? 'disabled' : ''}`} onClick={this.handleNextPagePrevStudents}>
              {
                isNextPageLoading && (
                  <div>
                    <LoadingIcon />
                  </div>
                )
              }
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
              <div><h5>Show more...</h5></div>
            </ListGroup.Item>
          )
        }
      </ListGroup>
    );
  }
  renderStudents = () => {
    const { students: { isLoading, errorMessage, show, list, isNextPageLoading, nextPageError } } = this.state;

    if (!show) {
      return null;
    }

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }
    
    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (list.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <ListGroup>
        {
          list.data.map(st => (
            <ListGroup.Item key={st.id} className='drop-courses-student btn-light' onClick={e => this.handleSelectStudent(st)}>
              <div className='d-flex'>
                <div className='flex-fill'>
                  <div className='d-flex'>
                    <h5>
                      <NameLink id={st.id} name={st.profile.name} image={st.profile.image} />
                    </h5>
                  </div>
                  {
                    st.student_class.school_class ? (
                      <>
                        <div className='font-weight-bold'>Regular</div>
                        <div>{st.student_class.school_class.program.description}</div>
                        <div>{st.student_class.school_class.year_level.description}</div>
                        <div>Section {st.student_class.school_class.section.code}</div>
                      </>
                    ) : (
                      <>
                        <div className='font-weight-bold'>Irregular</div>
                        <div>{st.profile.program.description}</div>
                        <div>{st.profile.year_level.description}</div>
                      </>
                    )
                  }
                </div>
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon icon='chevron-right' size='lg' />
                </div>
              </div>
            </ListGroup.Item>
          ))
        }
        {
          list.next_page_url && (
            <ListGroup.Item className={`text-center btn-link ${isNextPageLoading ? 'disabled' : ''}`} onClick={this.handleNextPage}>
              {
                isNextPageLoading && (
                  <div>
                    <LoadingIcon />
                  </div>
                )
              }
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
              <div><h5>Show more...</h5></div>
            </ListGroup.Item>
          )
        }
      </ListGroup>
    );
  }
  render() {
    const { subjects: { student }, students: { isLoading, search } } = this.state;
    return (
      <Card>
        <Card.Body>
          {
            student ? (
              <>
                <Breadcrumb>
                  <Breadcrumb.Item href='#' onClick={this.handleBack}>
                    Search student
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{student.profile.name}</Breadcrumb.Item>
                </Breadcrumb>
                <div>
                  <div className='d-flex'>
                    <h5 className='m-0'>
                      <NameLink id={student.id} name={student.profile.name} image={student.profile.image} />
                    </h5>
                  </div>
                  {
                    student.student_class.school_class ? (
                      <>
                        <div className='font-weight-bold'>Regular</div>
                        <div>
                          {student.student_class.school_class.program.code} {student.student_class.school_class.year_level.code} - {student.student_class.school_class.section.code}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='font-weight-bold'>Irregular</div>
                        <div>{student.profile.program.code} {student.profile.year_level.code}</div>
                      </>
                    )
                  }
                </div>
                <div className='dropdown-divider'></div>
                {this.renderSubjects()}
              </>
            ) : (
              <>
                <Form onSubmit={this.handleSearch} disabled={isLoading}>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control type='search' placeholder='Search student...' value={search} onChange={this.handleInputChange} disabled={isLoading} />
                      <InputGroup.Append>
                        <Button type='submit' variant='green' disabled={isLoading}>
                          Search
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Form>
                { this.renderPreviousStudents() }
                { this.renderStudents() }
              </>
            )
          }
        </Card.Body>
      </Card>
    );
  }
}