import React, { Component } from 'react';
import { Alert, Card, Col } from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import { loginUser } from '../../../actions';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

class VerifyForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      successMessage: ''
    };
  }
  componentDidMount() {
    if (this.props.location.search) {
      axios.get(`${process.env['REACT_APP_API_BASE_URL']}/forgot-password/verify/${this.props.match.params.accountID}${this.props.location.search}`, { withCredentials: true }).then(({ data }) => {
        this.setState({
          isLoading: false,
          errorMessage:  '',
          successMessage: data.message
        }, () => {
          setTimeout(() => {
            this.props.loginUser(data.account);
            this.props.history.replace('/');
          }, 5000);
        });
        
      }).catch(error => {
        this.setState({
          ...this.state,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          isLoading: false
        });
      })
    } else {
      this.props.history.replace('/login');
    }
  }
  renderContent() {
    const { isLoading, errorMessage, successMessage } = this.state;
    
    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (successMessage) {
      return (
        <Alert variant='success'>
          <div>{successMessage}</div>
          <div>Logging in...</div>
        </Alert>
      );
    }
  }
  render() {
    return (
      <div className='bg-secondary d-flex flex-column align-items-center justify-content-center' style={{ minHeight: '100vh' }}>
        <Col lg={5}>
          <Card>
            <Card.Body>
              {this.renderContent()}
            </Card.Body>
          </Card>
        </Col>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  loginUser: user => dispatch(loginUser(user))
});

export default connect(null, mapDispatchToProps)(VerifyForgotPassword);