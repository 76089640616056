import React, { Component } from 'react';
import { Alert, Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import Validator from 'validatorjs';
import { loadRule } from '../../../../../util';
import PromptDeleteModal from '../../../../modals/PromptDeleteModal/PromptDeleteModal';
import './style.scss';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../../common/RichTextEditor/RichTextEditor';

export default class Links extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      createUpdateModal: {
        show: false,
        link: null,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        formInputs: {
          title: '',
          description: '',
          url: '',
          audience: []
        }
      },
      deleteModal: {
        show: false,
        link: null,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      },
      isNextPageLoading: false,
      nextPageError: ''
    };
  }
  componentDidMount() {
    if (this.props.data.data.length === 0) {
      axiosRequest('get', 'faculty/materials/links', null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isLoading: false
        }, () => {
          this.props.onFetch(data, 'links');
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    } else {
      this.setState({
        ...this.state,
        isLoading: false
      });
    }

    loadRule(['date', 'after_or_equal'], {
      after_or_equal: 'The due date must be equal or after the current date and time.'
    });
  }
  showCreateUpdateModal = (event, link = null) => {
    let formInputs = {
      title: '',
      description: '',
      url: '',
      audience: []
    };

    if (link) {
      let audience = [];
      for (let i = 0; i < link.audiences.length; i++) {
        audience = [
          ...audience,
          {
            facultyLoadId: link.audiences[i].faculty_load_id
          }
        ];
      }
      formInputs = {
        title: link.title,
        description: link.description,
        url: link.url,
        audience
      };
    }

    this.setState({
      ...this.state,
      createUpdateModal: {
        show: true,
        link,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        formInputs
      }
    });
  }
  hideCreateUpdateModal = () => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        show: false,
        link: null,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        formInputs: {
          title: '',
          description: '',
          url: '',
          audience: []
        }
      }
    });
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          [event.target.name]: event.target.value
        }
      }
    });
  }
  handleDescriptionInputChange = description => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          description
        }
      }
    });
  }
  handleAudienceChange = event => {
    const { createUpdateModal: { formInputs } } = this.state;

    let audience = [...formInputs.audience];
    if (event.target.checked) {
      audience = [
        ...audience,
        {
          facultyLoadId: +event.target.value
        }
      ];
    } else {
      audience = audience.filter(a => a.facultyLoadId !== +event.target.value);
    }

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          audience
        }
      }
    });
  }
  handleAudienceParamsChange = (event, facultyLoadID) => {
    let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
      if (a.facultyLoadId === facultyLoadID) {
        return {
          ...a,
          [event.target.name]: event.target.value
        };
      }

      return a;
    });

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          audience: newAudience
        }
      }
    });
  }
  handleCreate = () => {
    const { createUpdateModal: { formInputs } } = this.state;

    axiosRequest('post', `faculty/materials/links`, formInputs, ({ data: { data, message }}) => {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          successMessage: message
        }
      }, () => {
        this.props.onCreate(data, 'links');
      });
      // this.hideCreateUpdateModal();
    }, (error) => {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          isLoading: false,
        }
      });
    }, this.props.history);
  }
  handleUpdate = () => {
    const { createUpdateModal: { link, formInputs } } = this.state;

    axiosRequest('patch', `faculty/materials/links/${link.id}`, formInputs, ({ data: { data, message }}) => {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          successMessage: message
        }
      }, () => {
        this.props.onUpdate(data, 'links');
      });
      // this.hideCreateUpdateModal();
    }, (error) => {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          isLoading: false
        }
      });
    }, this.props.history);
  }
  handleSave = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { createUpdateModal: { formInputs, link } } = this.state;
      const { audiences } = this.props;
      
      let validator = new Validator(formInputs, {
        title: 'required|min:3',
        description: 'required',
        url: 'required|url',
        audience: 'required|array|min:1',
        'audience.*.facultyLoadId': 'required|integer|min:1'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            errorMessage: validator.errors.errors[firstKey][0],
            isLoading: false
          }
        });
        return;
      }

      for (let i = 0; i < formInputs.audience.length; i++) {
        let tempAudience = audiences.find(a => a.id === formInputs.audience[i].facultyLoadId);
        if (!tempAudience) {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: 'Classroom not found.',
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
          return;
        }
      }
      
      if (link) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    });
  }
  showDeleteModal = link => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        link,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        link: null,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      }
    });
  }
  handleDelete = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { deleteModal } = this.state;
      axiosRequest('delete', `faculty/materials/links/${deleteModal.link.id}`, null, ({ data: { message }}) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            successMessage: message
          }
        }, () => {
          this.props.onDelete(deleteModal.link.id, 'links');
        });
        // this.hideDeleteModal();
      }, (error) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { data } = this.props;

      const path = data.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false
        }, () => {
          this.props.onNextPage(data, 'links');
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  renderAudience = () => {
    const { createUpdateModal: { formInputs: { audience }, isLoading } } = this.state;
    const { audiences } = this.props;

    return (
      <Form.Group>
        <Form.Label>
          Audience
        </Form.Label>
        <div className='ml-3'>
        {
          audiences.map(a => (
            <Form.Group
              controlId={`audience${a.id}`}
              key={a.id}>
              <Form.Check
                type='checkbox'
                name='audience'
                label={`${a.class_course.school_class.program.code} ${a.class_course.school_class.year_level.code} - ${a.class_course.school_class.section.code} - ${a.class_course.course.code}`}
                value={a.id}
                checked={audience.find(a2 => a2.facultyLoadId === a.id)}
                onChange={this.handleAudienceChange}
                disabled={isLoading} />
            </Form.Group>
          ))
        }
        </div>
      </Form.Group>
    );
  }
  renderCreateUpdateModal = () => {
    const { show, link, formInputs, isLoading, errorMessage, successMessage } = this.state.createUpdateModal;

    return (
      <Modal show={show} backdrop='static' onHide={this.hideCreateUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {
              link ? 'Update link' : 'Create a link'
            }
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleSave}>
          <Modal.Body>
            {
              errorMessage ? (
                <Alert variant='danger'>
                  {errorMessage}
                </Alert>
              ) : successMessage ? (
                <Alert variant='success'>
                  {successMessage}
                </Alert>
              ) : null
            }
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type='text' name='title' value={formInputs.title} onChange={this.handleInputChange} disabled={isLoading} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <RichTextEditor.Editor
                value={formInputs.description}
                onChange={this.handleDescriptionInputChange}
                readOnly={isLoading} />
              {/* <Form.Control as='textarea' rows='4' style={{ resize: 'none' }} name='description' value={formInputs.description} onChange={this.handleInputChange} disabled={isLoading} /> */}
            </Form.Group>
            <Form.Group>
              <Form.Label>URL</Form.Label>
              <Form.Control type='url' name='url' value={formInputs.url} onChange={this.handleInputChange} disabled={isLoading} />
            </Form.Group>
            {this.renderAudience()}
          </Modal.Body>
          <Modal.Footer>
            {
              successMessage ? (
                <Button variant='light' onClick={this.hideCreateUpdateModal}>
                  Close
                </Button>
              ) : (
                <>
                  <Button variant='danger mr-2' onClick={this.hideCreateUpdateModal} disabled={isLoading}>
                    Cancel
                  </Button>
                  <Button variant='green' type='submit' disabled={isLoading}>
                    Save
                  </Button>
                </>
              )
            }
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
  renderDeleteModal = () => {
    const { deleteModal } = this.state;

    let renderAudience = audience => {
      const { audiences } = this.props;

      let tempAudience = audiences.find(a => a.id === audience.faculty_load_id);
      if (!tempAudience) {
        return null;
      }

      return (
        <div key={audience.id} className='mb-1'>
          {`${tempAudience.class_course.school_class.program.code} ${tempAudience.class_course.school_class.year_level.code}-${tempAudience.class_course.school_class.section.code} - ${tempAudience.class_course.course.code}`}
        </div>
      );
    };

    return (
      <PromptDeleteModal {...deleteModal} title='Delete link' onHide={this.hideDeleteModal} onDelete={this.handleDelete}>
        {
          !deleteModal.successMessage && (
            <div className='font-weight-bold'>
              <div>Are you sure you want to delete the link?</div>
            </div>
          )
        }
        {
          deleteModal.link && (
            <Alert variant='light'>
              <div>{deleteModal.link.title}</div>
              <div>{deleteModal.link.url}</div>
              <div className='d-md-flex'>
                <div className='mr-1'>
                  <FontAwesomeIcon icon='users' /> Audience(s):
                </div>
                <div>
                  {
                    deleteModal.link.audiences.map(renderAudience)
                  }
                </div>
              </div>
            </Alert>
          )
        }
      </PromptDeleteModal>
    );
  }
  renderAudiencesDisplay = audience => {
    const { audiences } = this.props;

    let tempAudience = audiences.find(a => a.id === audience.faculty_load_id);
    if (!tempAudience) {
      return null;
    }

    return (
      <div key={audience.id} className='mb-1 font-weight-bold'>
        {`${tempAudience.class_course.school_class.program.code} ${tempAudience.class_course.school_class.year_level.code}-${tempAudience.class_course.school_class.section.code} - ${tempAudience.class_course.course.code}`}
      </div>
    );
  }
  renderContent = () => {
    const { isNextPageLoading, nextPageError } = this.state;
    const { data } = this.props;

    if (data.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='material-list'>
          {
            data.data.map(d => (
              <div key={d.id}>
                <div className='d-flex'>
                  <div className='flex-fill'>
                    <div className='font-weight-bold'>
                      <a href={d.url} target='_blank' rel='noopener noreferrer'>
                        {d.title} <FontAwesomeIcon icon='external-link-alt' />
                      </a>
                    </div>
                    <RichTextEditor.Viewer body={d.description} className='text-muted' />
                    {/* <div className='text-muted'>{d.description}</div> */}
                  </div>
                  <div className='d-flex d-md-none'>
                    <Dropdown>
                      <Dropdown.Toggle as='span' className='text-green'>
                        <FontAwesomeIcon icon='cog' />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={e => this.showCreateUpdateModal(e, d)}>
                          <FontAwesomeIcon icon='pencil-alt' /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => this.showDeleteModal(d)}>
                          <FontAwesomeIcon icon='trash-alt' /> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className='d-none d-md-block' style={{ whiteSpace: 'nowrap' }}>
                    <Button variant='info' size='sm' className='mr-1' onClick={e => this.showCreateUpdateModal(e, d)}>
                      <FontAwesomeIcon icon='pencil-alt' />
                    </Button>
                    <Button variant='danger' size='sm' onClick={() => this.showDeleteModal(d)}>
                      <FontAwesomeIcon icon='trash-alt' />
                    </Button>
                  </div>
                </div>
                <div className='text-muted d-md-flex mt-1'>
                  <div className='mr-1'>
                    <FontAwesomeIcon icon='users' /> Audience(s):
                  </div>
                  <div>
                    {
                      d.audiences.map(this.renderAudiencesDisplay)
                    }
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        {
          data.next_page_url && (
            <div className='text-center mt-3'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                View more links
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          )
        }
      </>
    );
  }
  render() {
    const { isLoading, errorMessage } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <div className='text-right mb-3'>
          <Button variant='green' onClick={this.showCreateUpdateModal}>
            <FontAwesomeIcon icon='plus' /> Create a link
          </Button>
        </div>
        {this.renderContent()}
        {this.renderCreateUpdateModal()}
        {this.renderDeleteModal()}
      </div>
    );
  }
}