import React, { Component } from 'react';
import { Alert, Button, Form, InputGroup, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import './style.scss';
import NameLink from '../../../common/NameLink/NameLink';
import moment from 'moment';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Gradebook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materials: null,
      dates: [],
      gradeCategories: [],
      selectedDate: '',
      isLoading: true,
      errorMessage: ''
    };
  }
  componentDidMount() {
    const { classID } = this.props.match.params;

    axiosRequest('get', `faculty/class/${classID}/grade/material`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        isLoading: false,
        materials: data.materials,
        dates: data.dates,
        gradeCategories: data.gradeCategories,
        selectedDate: data.dates.length > 0 ? data.dates[data.dates.length-1] : '',
        errorMessage: ''
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleDateChange = event => {
    this.setState({
      ...this.state,
      selectedDate: event.target.value
    });
  }
  handlePrevDate = e => {
    const { selectedDate, dates } = this.state;

    let dateIndex = dates.indexOf(selectedDate);

    if (dateIndex !== -1 && dateIndex !== 0) {
      this.setState({
        ...this.state,
        selectedDate: dates[dateIndex-1]
      });
    }
  }
  handleNextDate = e => {
    const { selectedDate, dates } = this.state;

    let dateIndex = dates.indexOf(selectedDate);

    if (dateIndex !== -1 && dateIndex !== dates.length-1) {
      this.setState({
        ...this.state,
        selectedDate: dates[dateIndex+1]
      });
    }
  }
  renderGradeCategory = material => {
    const { gradeCategories } = this.state;

    let gradeCategory = gradeCategories.find(gc => gc.id === (material.faculty_load_id ? material.grade_category_id : material.audience.grade_category_id));

    if (gradeCategory) {
      return (
        <div className='text-muted font-weight-bold text-truncate' style={{ width: '100px' }} title={gradeCategory.name}>
          {gradeCategory.name}
        </div>
      );
    }

    return null;
  }
  renderGrade = (student, material) => {
    let gradeData = material.grades.find(grade => grade.student_id === student.id);

    if (gradeData) {
      return gradeData.grade;
    } else if (material.type === 'assignment') {
      if (material.submissions.find(submission => submission.student_id === student.id)) {
        return <small className='text-muted'>(Ungraded)</small>;
      }
    }

    return <span className='text-danger'>N/A</span>;
  }
  renderStudents = (gradeColumns, columnFiller = 0) => {
    const { students, classInfo } = this.props;

    if (classInfo.class_course.school_class.year_level.school_level.students_alphabetical) {
      return students.map(student => (
        <tr key={student.id}>
          <th>
            <div className='d-flex'>
              <NameLink
                id={student.id}
                name={student.formal_name}
                image={student.image}
                learningPlatform={student.student_learning_platform ? student.student_learning_platform.platform : null}
                rank={student.rank} />
            </div>
          </th>
          {
            gradeColumns.map(material => (
              <td key={`${material.type}-${material.id}`} className='text-center align-middle'>
                {this.renderGrade(student, material)}
              </td>
            ))
          }
        </tr>
      ));
    }

    let male = students.filter(s => s.gender === 'Male');
    let female = students.filter(s => s.gender === 'Female');

    return (
      <>
        {
          male.length > 0 && (
            <>
              <tr>
                <th className='text-center text-white bg-green'>
                  <div>
                    Male
                  </div>
                  <small className='font-italic'>
                    {male.length} student{male.length === 1 ? '' : 's'}
                  </small>
                </th>
                <th colSpan={gradeColumns.length} className='text-center text-white bg-green border-green'>
                </th>
              </tr>
              {
                male.map(student => (
                  <tr key={student.id}>
                    <th>
                      <div className='d-flex'>
                        <NameLink
                          id={student.id}
                          name={student.formal_name}
                          image={student.image}
                          learningPlatform={student.student_learning_platform ? student.student_learning_platform.platform : null}
                          rank={student.rank} />
                      </div>
                    </th>
                    {
                      gradeColumns.map(material => (
                        <td key={`${material.type}-${material.id}`} className='text-center align-middle'>
                          {this.renderGrade(student, material)}
                        </td>
                      ))
                    }
                  </tr>
                ))
              }
            </>
          )
        }
        {
          female.length > 0 && (
            <>
              <tr>
                <th className='text-center text-white bg-green'>
                  <div>
                    Female
                  </div>
                  <small className='font-italic'>
                    {female.length} student{female.length === 1 ? '' : 's'}
                  </small>
                </th>
                <th colSpan={gradeColumns.length} className='text-center text-white bg-green border-green'>
                </th>
              </tr>
              {
                female.map(student => (
                  <tr key={student.id}>
                    <th>
                      <div className='d-flex'>
                        <NameLink
                          id={student.id}
                          name={student.formal_name}
                          image={student.image}
                          learningPlatform={student.student_learning_platform ? student.student_learning_platform.platform : null}
                          rank={student.rank} />
                      </div>
                    </th>
                    {
                      gradeColumns.map(material => (
                        <td key={`${material.type}-${material.id}`} className='text-center align-middle'>
                          {this.renderGrade(student, material)}
                        </td>
                      ))
                    }
                  </tr>
                ))
              }
            </>
          )
        }
      </>
    );
  }
  renderTable = () => {
    const { materials, selectedDate } = this.state;

    if (!selectedDate) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let gradeColumns = materials[selectedDate];

    if (!gradeColumns) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <Table responsive bordered size='sm' className='mt-3'>
        <thead>
          <tr>
            <th style={{ minWidth: '200px' }}>Name</th>
            {
              gradeColumns.map(material => (
                <th key={`${material.type}-${material.id}`} style={{ width: '100px' }}>
                  <small>
                      <div className='text-truncate' style={{ width: '100px' }} title={material.title}>{material.title}</div>
                      { this.renderGradeCategory(material) }
                      <div className='font-weight-bold text-center'>
                        ({material.points} point{material.points > 1 ? 's' : ''})
                      </div>
                    </small>
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {this.renderStudents(gradeColumns)}
        </tbody>
      </Table>
    );
  }
  render() {
    const { isLoading, errorMessage, selectedDate, dates } = this.state;
    const { classID } = this.props.match.params;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (dates.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let disabledPrev = true;
    let disabledNext = true;

    let dateIndex = dates.indexOf(selectedDate);
    if (dateIndex !== -1) {
      disabledPrev = dateIndex === 0;
      disabledNext = dateIndex === dates.length-1;
    }

    return (
      <>
        <div className='d-flex'>
          <div className='flex-fill d-none d-md-block'>
            <InputGroup>
              <InputGroup.Prepend>
                <Button onClick={this.handlePrevDate} disabled={disabledPrev}>
                  <FontAwesomeIcon icon='chevron-left' />
                </Button>
              </InputGroup.Prepend>
              <Form.Control as='select' className='gradebook_date_select' style={{ textAlignLast: 'center' }} value={selectedDate} onChange={this.handleDateChange}>
                <option hidden disabled value=''>Select date...</option>
                {
                  dates.map((date, index) => (
                    <option key={index} value={date}>{moment(date).format('dddd, MMMM D, YYYY')}</option>
                  ))
                }
              </Form.Control>
              <InputGroup.Append>
                <Button onClick={this.handleNextDate} disabled={disabledNext}>
                  <FontAwesomeIcon icon='chevron-right' />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
          <div className='flex-fill d-md-none'>
            <InputGroup size='sm'>
              <InputGroup.Prepend>
                <Button onClick={this.handlePrevDate} disabled={disabledPrev}>
                  <FontAwesomeIcon icon='chevron-left' />
                </Button>
              </InputGroup.Prepend>
              <Form.Control as='select' className='gradebook_date_select' style={{ textAlignLast: 'center' }} value={selectedDate} onChange={this.handleDateChange}>
                <option hidden disabled value=''>Select date...</option>
                {
                  dates.map((date, index) => (
                    <option key={index} value={date}>{moment(date).format('ddd, MMM D, YYYY')}</option>
                  ))
                }
              </Form.Control>
              <InputGroup.Append>
                <Button onClick={this.handleNextDate} disabled={disabledNext}>
                  <FontAwesomeIcon icon='chevron-right' />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
          <div className='ml-3 d-none d-md-block'>
            <a href={`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/grade/export`} className='btn btn-green' title='Export grades'>
              <FontAwesomeIcon icon='file-export' /> Export grades
            </a>
          </div>
          <div className='ml-3 d-md-none'>
            <a href={`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/grade/export`} className='btn btn-green btn-sm' title='Export grades'>
              <FontAwesomeIcon icon='file-export' />
            </a>
          </div>
        </div>
        { this.renderTable() }
      </>
    );
  }
}