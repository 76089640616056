import React from 'react';
import PropTypes from 'prop-types';
import { Alert,  Button, Modal } from 'react-bootstrap';

const EditModal = props => {
  return (
    <Modal show={props.show} onHide={props.onHide} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          props.errorMessage && (
            <Alert variant='danger'>{props.errorMessage}</Alert>
          )
        }
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={props.onHide}>Cancel</Button>
        <Button variant='info' onClick={props.onEdit}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

EditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

EditModal.defaultProps = {
  show: false,
  title: '',
  errorMessage: '',
  onHide: () => {},
  onEdit: () => {}
};

export default EditModal;