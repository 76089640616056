import React, { useState } from 'react';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import profileDefault from '../../../resources/image/profile-default.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RankBadge from '../RankBadge/RankBadge';
import './style.scss';
import LoadingIcon from '../LoadingIcon/LoadingIcon';

const NameLink = props => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();

    history.push(`/profile/${props.id}`);
  }

  const name = (
    <div className='d-flex align-items-center'>
      {
        props.imageAlign !== 'right' && (
          <div className='mr-2'>
            <div style={{ height: '2.5rem', width: '2.5rem', position: 'relative', ...props.imageStyle }}>
              <div className={`image-placeholder ${isLoading ? '' : 'hide'}`}>
                <div className='img' style={{ backgroundImage: `url(${profileDefault})`}}></div>
                <div className='spinner'>
                  <LoadingIcon className='text-primary' />
                </div>
              </div>
              <Image
                className={isLoading ? 'd-none' : ''}
                style={{ height: '100%', width: '100%', objectFit: 'contain', backgroundColor: '#fff' }}
                src={props.image ? `${process.env['REACT_APP_API_BASE_URL']}/profile/${props.id}/${props.image}` : profileDefault}
                onLoad={e => setLoading(false)}
                roundedCircle />
              <RankBadge {...props} />
            </div>
          </div>
        )
      }
      <div className='flex-fill' style={{ ...props.nameStyle }}>
        <div>{props.name}</div>
        {
          (props.showPosition && props.position) && (
            <div className='font-italic font-weight-normal'>
              {props.position}
            </div>
          )
        }
      </div>
      {
        props.imageAlign === 'right' && (
          <div className='ml-2'>
            <div style={{ height: '2.5rem', width: '2.5rem', position: 'relative', ...props.imageStyle }}>
              <div className={`image-placeholder ${isLoading ? '' : 'hide'}`}>
                <div className='img' style={{ backgroundImage: `url(${profileDefault})`}}></div>
                <div className='spinner'>
                  <LoadingIcon className='text-primary' />
                </div>
              </div>
              <Image
                className={isLoading ? 'd-none' : ''}
                style={{ height: '100%', width: '100%', objectFit: 'contain', backgroundColor: '#fff' }}
                src={props.image ? `${process.env['REACT_APP_API_BASE_URL']}/profile/${props.id}/${props.image}` : profileDefault}
                onLoad={e => setLoading(false)}
                roundedCircle />
              <RankBadge {...props} />
            </div>
          </div>
        )
      }
    </div>
  );

  if (props.hideName) {
    if (props.enableTooltip) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              <div>
                <div>{props.name}</div>
                {
                  (props.showPosition && props.position) && (
                    <div className='font-italic font-weight-normal'>
                      {props.position}
                    </div>
                  )
                }
              </div>
            </Tooltip>
          }
          trigger={['hover', 'focus']}>
          {
            props.disableLink ? (
              <div style={{ height: '2.5rem', width: '2.5rem', position: 'relative', ...props.imageStyle }}>
                <div className={`image-placeholder ${isLoading ? '' : 'hide'}`}>
                  <div className='img' style={{ backgroundImage: `url(${profileDefault})`}}></div>
                  <div className='spinner'>
                    <LoadingIcon className='text-primary' />
                  </div>
                </div>
                <Image
                  className={isLoading ? 'd-none' : ''}
                  style={{ height: '100%', width: '100%', objectFit: 'contain', backgroundColor: '#fff' }}
                  src={props.image ? `${process.env['REACT_APP_API_BASE_URL']}/profile/${props.id}/${props.image}` : profileDefault}
                  onLoad={e => setLoading(false)}
                  roundedCircle />
                <RankBadge {...props} />
              </div>
            ) : (
              <a href={`/profile/${props.id}`} onClick={handleClick} tabIndex={props.tabIndex}>
                <div style={{ height: '2.5rem', width: '2.5rem', position: 'relative', ...props.imageStyle }}>
                  <div className={`image-placeholder ${isLoading ? '' : 'hide'}`}>
                    <div className='img' style={{ backgroundImage: `url(${profileDefault})`}}></div>
                    <div className='spinner'>
                      <LoadingIcon className='text-primary' />
                    </div>
                  </div>
                  <Image
                    className={isLoading ? 'd-none' : ''}
                    style={{ height: '100%', width: '100%', objectFit: 'contain', backgroundColor: '#fff' }}
                    src={props.image ? `${process.env['REACT_APP_API_BASE_URL']}/profile/${props.id}/${props.image}` : profileDefault}
                    onLoad={e => setLoading(false)}
                    roundedCircle />
                  <RankBadge {...props} />
                </div>
              </a>
            )
          }
        </OverlayTrigger>
      );
    }

    return (
      <>
        {
          props.disableLink ? (
            <div style={{ height: '2.5rem', width: '2.5rem', position: 'relative', ...props.imageStyle }}>
              <div className={`image-placeholder ${isLoading ? '' : 'hide'}`}>
                <div className='img' style={{ backgroundImage: `url(${profileDefault})`}}></div>
                <div className='spinner'>
                  <LoadingIcon className='text-primary' />
                </div>
              </div>
              <Image
                className={isLoading ? 'd-none' : ''}
                style={{ height: '100%', width: '100%', objectFit: 'contain', backgroundColor: '#fff' }}
                src={props.image ? `${process.env['REACT_APP_API_BASE_URL']}/profile/${props.id}/${props.image}` : profileDefault}
                onLoad={e => setLoading(false)}
                roundedCircle />
              <RankBadge {...props} />
            </div>
          ) : (
            <a href={`/profile/${props.id}`} onClick={handleClick} tabIndex={props.tabIndex}>
              <div style={{ height: '2.5rem', width: '2.5rem', position: 'relative', ...props.imageStyle }}>
                <div className={`image-placeholder ${isLoading ? '' : 'hide'}`}>
                  <div className='img' style={{ backgroundImage: `url(${profileDefault})`}}></div>
                  <div className='spinner'>
                    <LoadingIcon className='text-primary' />
                  </div>
                </div>
                <Image
                  className={isLoading ? 'd-none' : ''}
                  style={{ height: '100%', width: '100%', objectFit: 'contain', backgroundColor: '#fff' }}
                  src={props.image ? `${process.env['REACT_APP_API_BASE_URL']}/profile/${props.id}/${props.image}` : profileDefault}
                  onLoad={e => setLoading(false)}
                  roundedCircle />
                <RankBadge {...props} />
              </div>
            </a>
          )
        }
      </>
    );
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='flex-fill'>
        {
          props.disableLink ? name : (
            <a href={`/profile/${props.id}`} onClick={handleClick} title='View profile' tabIndex={props.tabIndex}>
              {name}
            </a>
          )
        }
      </div>
      {
        props.learningPlatform && (
          <div className='px-1'>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <div className='font-weight-bold'>Learning Platform</div>
                  <div>{props.learningPlatform}</div>
                </Tooltip>
              }
              trigger={['hover', 'focus']}>
                {
                  props.learningPlatform === 'Digital' ? (
                    <FontAwesomeIcon icon='laptop' className='text-primary' />
                  ) : props.learningPlatform === 'Digital-Modular' ? (
                    <div className='fa-layers text-primary mx-1'>
                      <FontAwesomeIcon icon='laptop' transform='shrink-3 left-5 up-2' />
                      <FontAwesomeIcon icon='book-open' transform='shrink-3 right-3 down-5' />
                    </div>
                  ) : props.learningPlatform === 'Modular-Printed' ? (
                    <FontAwesomeIcon icon='book-open' className='text-primary' />
                  ) : null
                }
            </OverlayTrigger>
          </div>
        )
      }
    </div>
  );
}

NameLink.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  learningPlatform: PropTypes.string,
  disableLink: PropTypes.bool,
  rank: PropTypes.object,
  imageStyle: PropTypes.object,
  rankStyle: PropTypes.object,
  hideName: PropTypes.bool,
  imageAlign: PropTypes.string,
  nameStyle: PropTypes.object,
  enableTooltip: PropTypes.bool,
  showPosition: PropTypes.bool,
  tabIndex: PropTypes.string
};

NameLink.defaultProps = {
  id: 0,
  name: '',
  image: '',
  learningPlatform: null,
  disableLink: false,
  rank: null,
  imageStyle: {},
  rankStyle: {},
  hideName: false,
  imageAlign: 'left',
  nameStyle: {},
  enableTooltip: true,
  showPosition: false,
  tabIndex: ''
};

export default NameLink;