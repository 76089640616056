import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Validator from 'validatorjs';
import { loadRule } from '../../../../util/';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import PromptDeleteModal from '../../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Date extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      schedules: [],
      formInputs: {
        date: '',
        start: '',
        end: '',
        noSchedule: false,
        type: 'sync'
      },
      isFormLoading: false,
      formError: '',
      deleteModal: {
        show: false,
        data: null,
        isLoading: false,
        errorMessage: ''
      }
    }
  }
  componentDidMount() {
    const { classID } = this.props.match.params;

    axiosRequest('get', `faculty/class/${classID}/schedule/date`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        schedules: data,
        isLoading: false,
        errorMessage: ''
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);

    loadRule(['date', 'time'], {
      time: 'The :attribute time must be a valid time.'
    });
  }
  handleInputChange = event => {
    const formInputs = {...this.state.formInputs};

    formInputs[event.target.name] = event.target.value;

    this.setState({
      ...this.state,
      formInputs
    });
  }
  handleCheck = event => {
    const formInputs = {...this.state.formInputs};

    formInputs.noSchedule = event.target.checked;

    this.setState({
      ...this.state,
      formInputs
    });
  }
  handleReset = () => {
    this.setState({
      ...this.state,
      formInputs: {
        date: '',
        start: '',
        end: '',
        noSchedule: false
      },
      isFormLoading: false,
      formError: ''
    });
  }
  handleSave = event => {
    event.preventDefault();
    
    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: ''
    }, () => {
      const { formInputs } = this.state;

      let validator = new Validator(formInputs, {
        date: 'required|date',
        start: [{required_if: ['noSchedule', false]}, 'time'],
        end: [{required_if: ['noSchedule', false]}, 'time'],
        type: [{required_if: ['noSchedule', false]}, 'in:sync,async,exam'],
        noSchedule: 'required|boolean',
      }, {
        'required_if.start': 'The start time is required if \'No Schedule\' is not checked.',
        'required_if.end': 'The end time is required if \'No Schedule\' is not checked.',
        'required_if.type': 'The type is required if \'No Schedule\' is not checked.',
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          formError: validator.errors.errors[firstKey][0],
          isFormLoading: false
        });
        return;
      }

      const { classID } = this.props.match.params;
      axiosRequest('post', `faculty/class/${classID}/schedule/date`, formInputs, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          schedules: [
            data,
            ...this.state.schedules
          ],
          isFormLoading: false
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showDeleteModal = id => {
    const schedule = [...this.state.schedules].filter(sched => {
      return sched.id === id;
    });

    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        data: {...schedule[0]},
        errorMessage: '',
        isLoading: false
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        data: null,
        errorMessage: '',
        isLoading: false
      }
    });
  }
  handleDelete = () => {
    const { deleteModal } = this.state;
    this.setState({
      ...this.state,
      deleteModal: {
        ...deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { classID } = this.props.match.params;
      axiosRequest('delete', `faculty/class/${classID}/schedule/date/${deleteModal.data.id}`, null, ({ data: { data }}) => {
        const newSchedules = [...this.state.schedules].filter(sched => {
          return sched.id !== deleteModal.data.id;
        });

        this.setState({
          ...this.state,
          schedules: newSchedules,
          deleteModal: {
            show: false,
            data: null,
            errorMessage: '',
            isLoading: false
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      });
    });
  }
  render() {
    const { isLoading, errorMessage, schedules, formInputs, isFormLoading, formError, deleteModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div className='date-schedule'>
        <Row>
          <Col md={6}>
            {
              formError && (
                <Alert variant='danger'>
                  {formError}
                </Alert>
              )
            }
            <Form onSubmit={this.handleSave}>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Date</Form.Label>
                  <Form.Control type='date' name='date' value={formInputs.date} onChange={this.handleInputChange} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Start time</Form.Label>
                  <Form.Control type='time' name='start' value={formInputs.start} onChange={this.handleInputChange} disabled={formInputs.noSchedule} />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>End time</Form.Label>
                  <Form.Control type='time' name='end' value={formInputs.end} onChange={this.handleInputChange} disabled={formInputs.noSchedule} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId='noSchedule'>
                  <Form.Check type='checkbox' label='No schedule' onChange={this.handleCheck} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    as='select'
                    name='type'
                    value={formInputs.type}
                    onChange={this.handleInputChange}
                    disabled={formInputs.noSchedule}>
                    <option value='sync'>Synchronous</option>
                    <option value='async'>Asynchronous</option>
                    <option value='exam'>Exam</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Row>
                <Col xs={6} md={{ span: 4, offset: 4}}>
                  <Button variant='danger' size='sm' block disabled={isFormLoading} onClick={this.handleReset}>Reset</Button>
                </Col>
                <Col xs={6} md={4}>
                  <Button variant='green' size='sm' block type='submit' disabled={isFormLoading}>Save</Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md={6} className='mt-2 mt-md-0'>
            <div className='mb-2'>
              Schedules
            </div>
            {
              schedules.length > 0 ? (
                <ListGroup>
                  {
                    schedules.map(schedule => (
                      <ListGroup.Item key={schedule.id} className='d-flex align-items-center'>
                        <div className='flex-fill'>
                          <div className='font-weight-bold'>{moment(schedule.schedule_date).format('MMMM D, YYYY')}</div>
                          {
                            (!schedule.start_time && !schedule.end_time) ? (
                              <div>No schedule for this day.</div>
                            ) : (
                              <div>
                                <div>{`${moment(schedule.start_time, 'HH:mm').format('hh:mm A')} - ${moment(schedule.end_time, 'HH:mm').format('hh:mm A')}`}</div>
                                <div>{schedule.type ? schedule.type : ''}</div>
                              </div>
                            )
                          }
                        </div>
                        <div>
                          <Button variant='danger' size='sm' title='Delete schedule' onClick={() => this.showDeleteModal(schedule.id)}>
                            <FontAwesomeIcon icon='trash-alt' />
                          </Button> 
                        </div>
                      </ListGroup.Item>
                    ))
                  }
                  <PromptDeleteModal
                    show={deleteModal.show}
                    title='Delete schedule'
                    errorMessage={deleteModal.errorMessage}
                    onHide={this.hideDeleteModal}
                    onDelete={this.handleDelete}
                  >
                    <Card.Subtitle>Are you sure you want to delete the schedule?</Card.Subtitle>
                    {
                      deleteModal.data && (
                        <Alert variant='light'>
                          <div>{moment(deleteModal.data.schedule_date).format('MMMM D, YYYY')}</div>
                          {
                            (!deleteModal.data.start_time && !deleteModal.data.end_time) ? (
                              <div>No schedule for this day.</div>
                            ) : (
                              <div>
                                <div>{`${moment(deleteModal.data.start_time, 'HH:mm').format('hh:mm A')} - ${moment(deleteModal.data.end_time, 'HH:mm').format('hh:mm A')}`}</div>
                                <div>{deleteModal.data.type ? deleteModal.data.type : ''}</div>
                              </div>
                            )
                          }
                        </Alert>
                      )
                    }
                  </PromptDeleteModal>
                </ListGroup>
              ) : (
                <Alert variant='light'>
                  Nothing to show.
                </Alert>
              )
            }
          </Col>
        </Row>
      </div>
    );
  }
}