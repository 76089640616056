import React, { Component } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import './style.scss';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import RichTextEditor from '../../../RichTextEditor/RichTextEditor';

class Ordering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.question.items
    };
  }
  componentDidMount() {
    let items = [...this.props.question.items];

    if (this.props.answer && this.props.answer.answer_ordering) {
      items = [...this.props.answer.answer_ordering].map(answer => {
        return items.find(item => item.id === answer.question_ordering_item_id);
      });
      
      this.setState({
        items
      });
    } else {
      this.onChange(items);
    }
  }
  componentDidUpdate(prevProps) {
    if(prevProps.id !== this.props.id) {
      let items = [...this.props.question.items];

      if (this.props.answer && this.props.answer.answer_ordering) {
        items = [...this.props.answer.answer_ordering].map(answer => {
          return items.find(item => item.id === answer.question_ordering_item_id);
        });

        this.setState({
          items
        });
      } else {
        this.onChange(items);
      }
    }
  }
  moveUp = index => {
    const { items } = this.state;

    if (index !== 0) {
      let newItems = [...items];
      let item = newItems.splice(index, 1)[0];
      newItems.splice(index-1, 0, item);
      this.onChange(newItems);
    }
  }
  moveDown = index => {
    const { items } = this.state;

    if (index !== items.length-1) {
      let newItems = [...items];
      let item = newItems.splice(index, 1)[0];
      newItems.splice(index+1, 0, item);
      this.onChange(newItems);
    }
  }
  onChange = items => {
    this.setState({
      items
    }, () => {
      this.props.onChange({
        type: 'ordering',
        questionID: this.props.id,
        answer: items.map(item => item.id)
      });
    });
  }
  handleFileDownload = fileID => {
    const { url, id } = this.props;

    axiosRequest('get', `${url}/${id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handlePreviewImages = (url, id, images, index) => {
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/${url}/${id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  render() {
    const { question, files, url, id, points } = this.props;
    const { items } = this.state;

    const questionImages = files.filter(file => {
      return !!file.is_image;
    });

    const questionFiles = files.filter(file => {
      return !file.is_image;
    });

    return (
      <div className='ordering'>
        <RichTextEditor.Viewer body={question.description} className='px-2 px-md-3 py-1 user-select-none' />
        {/* <div className='px-2 px-md-3 py-1 user-select-none'>{question.description}</div> */}
        {
          files.length > 0 && (
            <div className='px-2 px-md-3'>
              {
                questionImages.length > 0 && (
                  <Row>
                  {
                    questionImages.map((image, index) => (
                      <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(url, id, questionImages, index)}>
                        <Image src={`${process.env['REACT_APP_API_BASE_URL']}/${url}/${id}/image/${image.id}`} thumbnail />
                      </Col>
                    ))
                  }
                  </Row>
                )
              }
              {
                questionFiles.length > 0 && (
                  <div className='p-1'>
                    {
                      questionFiles.map(file => (
                        <small key={file.id} className='d-flex mb-2'>
                          <div className='mr-2'>
                            <FontAwesomeIcon icon='file' className='text-primary' />
                          </div>
                          <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                            <span className='btn-link' onClick={() => this.handleFileDownload(file.id)}>
                              {file.file_title}
                            </span>
                          </div>
                        </small>
                      ))
                    }
                  </div>
                )
              }
            </div>
          )
        }
        <div className='px-2 px-md-3 text-muted'>
          ({points} point{points > 1 ? 's' : ''})
        </div>
        <div className='p-2 p-md-3 ordering-items'>
          {
            items.map((item, index)=> (
              <div key={index} className='d-flex'>
                <div className='pr-3'>
                  <div className='my-1'>
                    <Button size='sm' title='Move up' onClick={() => this.moveUp(index)}>
                      <FontAwesomeIcon icon='chevron-up' />
                    </Button>
                  </div>
                  <div className='my-1'>
                    <Button size='sm' title='Move down' onClick={() => this.moveDown(index)}>
                      <FontAwesomeIcon icon='chevron-down' />
                    </Button>
                  </div>
                </div>
                <div className='flex-fill align-self-center user-select-none'>
                  {item.description}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

Ordering.propTypes = {
  onChange: PropTypes.func.isRequired
};

Ordering.defaultProps = {
  onChange: () => {}
};

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Ordering);