import React, { Component } from 'react';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import axiosRequest from '../../../util/helpers/axiosRequest';
import moment from 'moment';
import LoadingIcon from '../LoadingIcon/LoadingIcon';

const accountTypeMap = {
  0: 'admin',
  1: 'school-admin',
  2: 'faculty',
  3: 'staff',
  4: 'parent',
  5: 'student'
};

export default class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      notifications: []
    };
  }
  componentDidMount() {
    axiosRequest('get', 'notification', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        notifications: data,
        isLoading: false,
        errorMessage: ''
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleClass = (event, classID) => {
    event.stopPropagation();
    this.props.hideNotifications();
    this.props.history.push(`/${accountTypeMap[this.props.currentUser.account_type]}/class/${classID}`);
  }
  handleMaterial = notif => {
    const viewableMaterial = [
      'assignment',
      'quiz',
      'exam'
    ];
    let path = '';
    if (viewableMaterial.indexOf(notif.data.material.type) !== -1) {
      path = `/${accountTypeMap[this.props.currentUser.account_type]}/class/${notif.data.class.classID}/${notif.data.material.type}/${notif.data.material.id}`
    } else {
      path = `/${accountTypeMap[this.props.currentUser.account_type]}/class/${notif.data.class.classID}/materials`
    }
    this.props.hideNotifications();
    if (notif.data.subType === 'specialExamRequest') {
      this.props.history.push(path, { activeNav: 'request-special-exam' });
    } else {
      this.props.history.push(path);
    }
  }
  handleMaterialRequest = notif => {
    this.props.hideNotifications();

    let path = `/${accountTypeMap[this.props.currentUser.account_type]}/class/${notif.data.class.classID}/${notif.data.material.type}/${notif.data.material.id}/manage-students`;
    this.props.history.push(path, { studentID: notif.data.from.id });
  }
  handleAttendance = notif => {
    this.props.hideNotifications();
    this.props.history.push(`/${accountTypeMap[this.props.currentUser.account_type]}/class/${notif.data.class.classID}/attendance`);
  }
  handleGrade = notif => {
    this.props.hideNotifications();
    this.props.history.push(`/${accountTypeMap[this.props.currentUser.account_type]}/class/${notif.data.class.classID}/grades`);
  }
  handleViewable = (event, notif) => {
    event.stopPropagation();
    this.props.hideNotifications();
    this.props.history.push(`/${accountTypeMap[this.props.currentUser.account_type]}/class/${notif.data.class.classID}/${notif.data.material.type}/${notif.data.material.id}`);
  }
  handlePost = notif => {
    this.props.hideNotifications();
    this.props.history.push(`/${accountTypeMap[this.props.currentUser.account_type]}/class/${notif.data.class.classID}/discussions`);
  }
  handleUser = (event, notif) => {
    event.stopPropagation();
    this.props.hideNotifications();
    this.props.history.push(`/profile/${notif.data.from.id}`);
  }
  handleLike = notif => {
    this.props.hideNotifications();
    this.props.history.push(`/${accountTypeMap[this.props.currentUser.account_type]}/class/${notif.data.classID}/discussions`);
  }
  handleApproval = notif => {
    this.props.hideNotifications();
    if (notif.data.status === 'request') {
      this.props.history.push(`/admin/approval-requests`);
    } else {
      this.props.history.push(`/profile`);
    }
  }
  handleTransaction = notif => {
    this.props.hideNotifications();
    if (notif.data.status === 'request') {
      this.props.history.push(`/payment-transaction`);
    } else {
      this.props.history.push(`/profile`);
    }
  }
  renderNotif = notif => {
    if (notif.data.type === 'material') {
      if (notif.data.from) {
        if (notif.data.subType && notif.data.subType === 'specialExamRequest') {
          return (
            <div
              key={notif.id}
              className={`px-3 py-2 ${notif.read_at ? '' : 'unread'}`}
              onClick={() => this.handleMaterialRequest(notif)}>
              <div className='d-flex'>
                {
                  !notif.read_at && (
                    <div className='pr-2' style={{ fontSize: '.875rem' }}>
                      <FontAwesomeIcon icon='circle' className='text-primary fa-xs' />
                    </div>
                  )
                }
                <div className='flex-fill'>
                  <div>
                    <span className='btn-link font-weight-bold' onClick={e => this.handleUser(e, notif)}>{notif.data.from.name}</span> {notif.data.message}
                  </div>
                  <small className='d-block text-right text-muted font-italic'>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          {moment(notif.created_at).format('MMM D, YYYY hh:mm A')}
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <FontAwesomeIcon icon='clock' />
                    </OverlayTrigger> {moment(notif.created_at).fromNow()}
                  </small>
                </div>
              </div>
            </div>
          );
        }

        return (
          <div
            key={notif.id}
            className={`px-3 py-2 ${notif.read_at ? '' : 'unread'}`}
            onClick={() => this.handleMaterial(notif)}>
            <div className='d-flex'>
              {
                !notif.read_at && (
                  <div className='pr-2' style={{ fontSize: '.875rem' }}>
                    <FontAwesomeIcon icon='circle' className='text-primary fa-xs' />
                  </div>
                )
              }
              <div className='flex-fill'>
                <div>
                  <span className='btn-link font-weight-bold' onClick={e => this.handleUser(e, notif)}>{notif.data.from.name}</span> {notif.data.message} {notif.data.material.type}
                </div>
                <small className='d-block text-right text-muted font-italic'>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {moment(notif.created_at).format('MMM D, YYYY hh:mm A')}
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <FontAwesomeIcon icon='clock' />
                  </OverlayTrigger> {moment(notif.created_at).fromNow()}
                </small>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div
          key={notif.id}
          className={`px-3 py-2 ${notif.read_at ? '' : 'unread'}`}
          onClick={() => this.handleMaterial(notif)}>
          <div className='d-flex'>
            {
              !notif.read_at && (
                <div className='pr-2' style={{ fontSize: '.875rem' }}>
                  <FontAwesomeIcon icon='circle' className='text-primary fa-xs' />
                </div>
              )
            }
            <div className='flex-fill'>
              <div>
                <span className='btn-link font-weight-bold' onClick={e => this.handleClass(e, notif.data.class.classID)}>{notif.data.class.course}</span> {notif.data.message}
              </div>
              <small className='d-block text-right text-muted font-italic'>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {moment(notif.created_at).format('MMM D, YYYY hh:mm A')}
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='clock' />
                </OverlayTrigger> {moment(notif.created_at).fromNow()}
              </small>
            </div>
          </div>
        </div>
      );
    } else if (notif.data.type === 'attendance') {
      return (
        <div
          key={notif.id}
          className={`px-3 py-2 ${notif.read_at ? '' : 'unread'}`}
          onClick={() => this.handleAttendance(notif)}>
          <div className='d-flex'>
            {
              !notif.read_at && (
                <div className='pr-2' style={{ fontSize: '.875rem' }}>
                  <FontAwesomeIcon icon='circle' className='text-primary fa-xs' />
                </div>
              )
            }
            <div className='flex-fill'>
              <div>
                <span className='btn-link font-weight-bold' onClick={e => this.handleClass(e, notif.data.class.classID)}>{notif.data.class.course}</span> has updated the attendance
              </div>
              <small className='d-block text-right text-muted font-italic'>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {moment(notif.created_at).format('MMM D, YYYY hh:mm A')}
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='clock' />
                </OverlayTrigger> {moment(notif.created_at).fromNow()}
              </small>
            </div>
          </div>
        </div>
      );
    } else if (notif.data.type === 'grade') {
      return (
        <div
          key={notif.id}
          className={`px-3 py-2 ${notif.read_at ? '' : 'unread'}`}
          onClick={() => this.handleGrade(notif)}>
          <div className='d-flex'>
            {
              !notif.read_at && (
                <div className='pr-2' style={{ fontSize: '.875rem' }}>
                  <FontAwesomeIcon icon='circle' className='text-primary fa-xs' />
                </div>
              )
            }
            <div className='flex-fill'>
              <div>
                <span className='btn-link font-weight-bold' onClick={e => this.handleClass(e, notif.data.class.classID)}>{notif.data.class.course}</span> has graded {notif.data.message} <span className='btn-link font-weight-bold' onClick={e => this.handleViewable(e, notif)}>{notif.data.material.type}</span>
              </div>
              <small className='d-block text-right text-muted font-italic'>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {moment(notif.created_at).format('MMM D, YYYY hh:mm A')}
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='clock' />
                </OverlayTrigger> {moment(notif.created_at).fromNow()}
              </small>
            </div>
          </div>
        </div>
      );
    } else if (notif.data.type === 'post') {

      if (notif.data.from.type === 'student') {
        return (
          <div
            key={notif.id}
            className={`px-3 py-2 ${notif.read_at ? '' : 'unread'}`}
            onClick={() => this.handlePost(notif)}>
            <div className='d-flex'>
              {
                !notif.read_at && (
                  <div className='pr-2' style={{ fontSize: '.875rem' }}>
                    <FontAwesomeIcon icon='circle' className='text-primary fa-xs' />
                  </div>
                )
              }
              <div className='flex-fill'>
                <div>
                  <span className='btn-link font-weight-bold' onClick={e => this.handleUser(e, notif)}>{notif.data.from.name}</span> {notif.data.message} in <span className='btn-link font-weight-bold' onClick={e => this.handleClass(e, notif.data.class.classID)}>{notif.data.class.course}</span>
                </div>
                <small className='d-block text-right text-muted font-italic'>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {moment(notif.created_at).format('MMM D, YYYY hh:mm A')}
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <FontAwesomeIcon icon='clock' />
                  </OverlayTrigger> {moment(notif.created_at).fromNow()}
                </small>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div
          key={notif.id}
          className={`px-3 py-2 ${notif.read_at ? '' : 'unread'}`}
          onClick={() => this.handlePost(notif)}>
          <div className='d-flex'>
            {
              !notif.read_at && (
                <div className='pr-2' style={{ fontSize: '.875rem' }}>
                  <FontAwesomeIcon icon='circle' className='text-primary fa-xs' />
                </div>
              )
            }
            <div className='flex-fill'>
              <div>
                <span className='btn-link font-weight-bold' onClick={e => this.handleClass(e, notif.data.class.classID)}>{notif.data.class.course}</span> {notif.data.message} in the discussion
              </div>
              <small className='d-block text-right text-muted font-italic'>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {moment(notif.created_at).format('MMM D, YYYY hh:mm A')}
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='clock' />
                </OverlayTrigger> {moment(notif.created_at).fromNow()}
              </small>
            </div>
          </div>
        </div>
      );
    } else if (notif.data.type === 'like') {
      return (
        <div
          key={notif.id}
          className={`px-3 py-2 ${notif.read_at ? '' : 'unread'}`}
          onClick={() => this.handleLike(notif)}>
          <div className='d-flex'>
            {
              !notif.read_at && (
                <div className='pr-2' style={{ fontSize: '.875rem' }}>
                  <FontAwesomeIcon icon='circle' className='text-primary fa-xs' />
                </div>
              )
            }
            <div className='flex-fill'>
              <div>
                <span className='btn-link font-weight-bold' onClick={e => this.handleUser(e, notif)}>{notif.data.from.name}</span> liked your post
              </div>
              <small className='d-block text-right text-muted font-italic'>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {moment(notif.created_at).format('MMM D, YYYY hh:mm A')}
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='clock' />
                </OverlayTrigger> {moment(notif.created_at).fromNow()}
              </small>
            </div>
          </div>
        </div>
      );
    } else if (notif.data.type === 'comment') {
      return (
        <div
          key={notif.id}
          className={`px-3 py-2 ${notif.read_at ? '' : 'unread'}`}
          onClick={() => this.handleLike(notif)}>
          <div className='d-flex'>
            {
              !notif.read_at && (
                <div className='pr-2' style={{ fontSize: '.875rem' }}>
                  <FontAwesomeIcon icon='circle' className='text-primary fa-xs' />
                </div>
              )
            }
            <div className='flex-fill'>
              <div>
                <span className='btn-link font-weight-bold' onClick={e => this.handleUser(e, notif)}>{notif.data.from.name}</span> commented on your post
              </div>
              <small className='d-block text-right text-muted font-italic'>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {moment(notif.created_at).format('MMM D, YYYY hh:mm A')}
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='clock' />
                </OverlayTrigger> {moment(notif.created_at).fromNow()}
              </small>
            </div>
          </div>
        </div>
      );
    } else if (notif.data.type === 'approval') {
      return (
        <div
          key={notif.id}
          className={`px-3 py-2 ${notif.read_at ? '' : 'unread'}`}
          onClick={() => this.handleApproval(notif)}>
          <div className='d-flex'>
            {
              !notif.read_at && (
                <div className='pr-2' style={{ fontSize: '.875rem' }}>
                  <FontAwesomeIcon icon='circle' className='text-primary fa-xs' />
                </div>
              )
            }
            <div className='flex-fill'>
              {
                notif.data.status === 'request' ? (
                  <div>
                    <span className='btn-link font-weight-bold' onClick={e => this.handleUser(e, notif)}>{notif.data.from.name}</span> {notif.data.message}
                  </div>
                ) : (
                  <div>
                    {notif.data.message}
                  </div>
                )
              }
              <small className='d-block text-right text-muted font-italic'>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {moment(notif.created_at).format('MMM D, YYYY hh:mm A')}
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='clock' />
                </OverlayTrigger> {moment(notif.created_at).fromNow()}
              </small>
            </div>
          </div>
        </div>
      );
    } else if (notif.data.type === 'transaction') {
      return (
        <div
          key={notif.id}
          className={`px-3 py-2 ${notif.read_at ? '' : 'unread'}`}
          onClick={() => this.handleTransaction(notif)}>
          <div className='d-flex'>
            {
              !notif.read_at && (
                <div className='pr-2' style={{ fontSize: '.875rem' }}>
                  <FontAwesomeIcon icon='circle' className='text-primary fa-xs' />
                </div>
              )
            }
            <div className='flex-fill'>
              {
                notif.data.status === 'request' ? (
                  <div>
                    <span className='btn-link font-weight-bold' onClick={e => this.handleUser(e, notif)}>{notif.data.from.name}</span> {notif.data.message}
                  </div>
                ) : (
                  <div>
                    {notif.data.message}
                  </div>
                )
              }
              <small className='d-block text-right text-muted font-italic'>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {moment(notif.created_at).format('MMM D, YYYY hh:mm A')}
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='clock' />
                </OverlayTrigger> {moment(notif.created_at).fromNow()}
              </small>
            </div>
          </div>
        </div>
      );
    }
  }
  renderContent = () => {
    const { isLoading, errorMessage, notifications } = this.state;

    if (isLoading) {
      return (
        <div className='text-center p-3'>
          <LoadingIcon />
        </div>
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger' className='m-3'>
          {errorMessage}
        </Alert>
      );
    }

    if (notifications.length === 0) {
      return (
        <Alert variant='light' className='my-3'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <div className='notification-list'>
        {
          notifications.map(notif => this.renderNotif(notif))
        }
      </div>
    );
  }
  render() {
    return (
      <div>
        { this.renderContent() }
      </div>
    );
  }
}