import React from 'react';
import { Col, Image, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import moment from 'moment';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import RichTextEditor from '../../../../common/RichTextEditor/RichTextEditor';

const Details = props => {
  const { assignment, match: { params: { classID } } } = props;

  const images = assignment.files.filter(file => {
    return !!file.is_image;
  });

  const files = assignment.files.filter(file => {
    return !file.is_image;
  });
  
  const handleFileDownload = fileID => {
    axiosRequest('get', `school-admin/class/${classID}/material/assignment/${assignment.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, props.history);
  }

  const handlePreviewImages = (assignment, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/school-admin/class/${classID}/material/assignment/${assignment.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }

  return (
    <>
      <div className='d-flex'>
        <div className='flex-fill'>
          <div className='h5 pt-1' style={{ wordBreak: 'break-all' }}>
            {assignment.title}
          </div>
          {
            assignment.faculty_load_id ? (
              <div className='text-muted font-italic'>
                Available from
                <b> {moment(assignment.from).format('ddd, MMM D, YYYY hh:mm A')} </b>
                and due
                <b> {moment(assignment.until).format('ddd, MMM D, YYYY hh:mm A')} </b>
              </div>
            ) : (
              <div className='text-muted font-italic'>
                Available from
                <b> {moment(assignment.audience.from).format('ddd, MMM D, YYYY hh:mm A')} </b>
                and due
                <b> {moment(assignment.audience.until).format('ddd, MMM D, YYYY hh:mm A')} </b>
              </div>
            )
          }
        </div>
        <div className='d-block pl-2'>
          <OverlayTrigger
            overlay={
              <Tooltip>
                {
                  assignment.faculty_load_id ? (
                    <div>Category: {assignment.grade_category.name}</div>
                  ) : (
                    <div>Category: {assignment.audience.grade_category.name}</div>
                  )
                }
                <div>Posted: {moment(assignment.created_at).format('dddd, MMM D, YYYY hh:mm A')}</div>
              </Tooltip>
            }
            trigger={['hover', 'focus']}>
            <FontAwesomeIcon icon='info-circle' className='info-icon text-primary' size='lg' />
          </OverlayTrigger>
        </div>
      </div>
      <div className='p-md-3'>
        <RichTextEditor.Viewer body={assignment.description} />
        {/* <div style={{ whiteSpace: 'pre-line' }}>
          {assignment.description}
        </div> */}
        {
          assignment.files.length > 0 && (
            <div>
              {
                images.length > 0 && (
                  <Row>
                  {
                    images.map((image, index) => (
                      <Col key={image.id} md={6} className='question-image-container' onClick={e => handlePreviewImages(assignment, images, index)}>
                        <Image src={`${process.env['REACT_APP_API_BASE_URL']}/school-admin/class/${classID}/material/assignment/${assignment.id}/image/${image.id}`} thumbnail />
                      </Col>
                    ))
                  }
                  </Row>
                )
              }
              {
                files.length > 0 && (
                  <div className='p-1'>
                    {
                      files.map(file => (
                        <div key={file.id} className='d-flex mb-2'>
                          <div className='mr-2'>
                            <FontAwesomeIcon icon='file' className='text-primary' />
                          </div>
                          <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                            <span className='btn-link' onClick={() => handleFileDownload(file.id)}>
                              {file.file_title}
                            </span>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Details);