import React, { Component } from 'react';
import { Alert, Breadcrumb, Button, Col, Collapse, Dropdown, Form, Image, Modal, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import moment from 'moment';
import axios from 'axios';
import Validator from 'validatorjs';
import { loadRule } from '../../../../../util';
import PromptDeleteModal from '../../../../modals/PromptDeleteModal/PromptDeleteModal';
import './style.scss';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../../common/RichTextEditor/RichTextEditor';

class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      createUpdateModal: {
        show: false,
        fileCollection: null,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        formInputs: {
          title: '',
          description: '',
          audience: []
        },
        fileNames: [],
        loadingFiles: false,
        showAttachments: false,
        deleteFiles: [],
        loadingDeleteFiles: []
      },
      deleteModal: {
        show: false,
        fileCollection: null,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      },
      viewFiles: null,
      isNextPageLoading: false,
      nextPageError: ''
    };

    this.files = [];
  }
  componentDidMount() {
    if (this.props.data.data.length === 0) {
      axiosRequest('get', 'faculty/materials/files', null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isLoading: false
        }, () => {
          this.props.onFetch(data, 'files');
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    } else {
      this.setState({
        ...this.state,
        isLoading: false
      });
    }

    loadRule(['date', 'after_or_equal'], {
      after_or_equal: 'The due date must be equal or after the current date and time.'
    });
  }
  showCreateUpdateModal = (event, fileCollection = null) => {
    let formInputs = {
      title: '',
      description: '',
      audience: []
    };

    if (fileCollection) {
      let audience = [];
      for (let i = 0; i < fileCollection.audiences.length; i++) {
        let availability = fileCollection.audiences[i].from && fileCollection.audiences[i].until ? 'fromUntil' :
                            fileCollection.audiences[i].from ? 'from' :
                            fileCollection.audiences[i].until ? 'until' : 'always';
        audience = [
          ...audience,
          {
            facultyLoadId: fileCollection.audiences[i].faculty_load_id,
            availability,
            from: moment(fileCollection.audiences[i].from).format('YYYY-MM-DDTHH:mm'),
            until: moment(fileCollection.audiences[i].until).format('YYYY-MM-DDTHH:mm'),
          }
        ];
      }
      formInputs = {
        title: fileCollection.title,
        description: fileCollection.description,
        audience
      };
    }

    this.setState({
      ...this.state,
      createUpdateModal: {
        show: true,
        fileCollection,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        formInputs,
        fileNames: [],
        loadingFiles: false,
        showAttachments: false,
        deleteFiles: [],
        loadingDeleteFiles: []
      }
    }, () => {
      this.files = [];
    });
  }
  hideCreateUpdateModal = () => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        show: false,
        fileCollection: null,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        formInputs: {
          title: '',
          description: '',
          audience: []
        },
        fileNames: [],
        loadingFiles: false,
        showAttachments: false,
        deleteFiles: [],
        loadingDeleteFiles: []
      }
    }, () => {
      this.files = [];
    });
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          [event.target.name]: event.target.value
        }
      }
    });
  }
  handleDescriptionInputChange = description => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          description
        }
      }
    });
  }
  handleFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.files = [...this.files, ...files];
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          fileNames: [
            ...this.state.createUpdateModal.fileNames,
            ...filesState
          ]
        }
      });
    }
  }
  handleRemoveUpload = id => {
    let newFileNames = [...this.state.createUpdateModal.fileNames];
    newFileNames.splice(id, 1);

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        fileNames: newFileNames
      }
    }, () => {
      this.files.splice(id, 1);
    });
  }
  handleAudienceChange = event => {
    const { createUpdateModal: { formInputs } } = this.state;

    let audience = [...formInputs.audience];
    if (event.target.checked) {
      audience = [
        ...audience,
        {
          facultyLoadId: +event.target.value,
          availability: '',
          from: '',
          until: ''
        }
      ];
    } else {
      audience = audience.filter(a => a.facultyLoadId !== +event.target.value);
    }

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          audience
        }
      }
    });
  }
  handleAudienceParamsChange = (event, facultyLoadID) => {
    let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
      if (a.facultyLoadId === facultyLoadID) {
        return {
          ...a,
          [event.target.name]: event.target.value
        };
      }

      return a;
    });

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          audience: newAudience
        }
      }
    });
  }
  handleShowAttachments = () => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        showAttachments: !this.state.createUpdateModal.showAttachments
      }
    });
  }
  handleCancelDeleteFile = id => {
    let newDeleteFiles = [...this.state.createUpdateModal.deleteFiles].filter(fileID => fileID !== id);
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        deleteFiles: newDeleteFiles
      }
    });
  }
  handleDeleteFile = id => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        deleteFiles: [...this.state.createUpdateModal.deleteFiles, id]
      }
    });
  }
  handleCreate = () => {
    const { createUpdateModal: { formInputs } } = this.state;

    const formData = new window.FormData();
    formData.append('file', this.files[0], this.files[0].name);
    formData.append('title', formInputs.title);
    formData.append('description', formInputs.description);
    for (let i = 0; i < formInputs.audience.length; i++) {
      formData.append(`audience[${i}][facultyLoadId]`, formInputs.audience[i].facultyLoadId);
      formData.append(`audience[${i}][availability]`, formInputs.audience[i].availability);
      formData.append(`audience[${i}][from]`, formInputs.audience[i].from);
      formData.append(`audience[${i}][until]`, formInputs.audience[i].until);
    }

    axiosRequest('post', `faculty/materials/files`, formData, ({ data: { data, message }}) => {
      const { createUpdateModal: { fileNames } } = this.state;
      fileNames[0].isLoading = false;
      fileNames[0].isSuccess = true;
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          fileNames
        }
      }, () => {
        if (this.files.length > 1) {
          let fileRequests = [];
          for (let i = 1; i < this.files.length; i++) {
            fileRequests.push(this.uploadFile(i, data.id));
          }
          Promise.all(fileRequests).then(result => {
            data.files = [...data.files, ...result];
            this.setState({
              ...this.state,
              createUpdateModal: {
                ...this.state.createUpdateModal,
                successMessage: message
              }
            }, () => {
              this.props.onCreate(data, 'files');
            });
            // this.hideCreateUpdateModal();
          }).catch((error) => {
            this.setState({
              ...this.state,
              createUpdateModal: {
                ...this.state.createUpdateModal,
                errorMessage: error,
                isLoading: false,
                loadingFiles: false,
                loadingDeleteFiles: []
              }
            });
          });
        } else {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              successMessage: message
            }
          }, () => {
            this.props.onCreate(data, 'files');
          });
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          isLoading: false,
          loadingFiles: false,
          loadingDeleteFiles: []
        }
      });
    }, this.props.history);
  }
  handleUpdate = () => {
    const { createUpdateModal: { fileCollection, formInputs, deleteFiles } } = this.state;

    axiosRequest('patch', `faculty/materials/files/${fileCollection.id}`, formInputs, ({ data: { data, message }}) => {
      data = {...fileCollection, ...data};
      let uploadRequests = [];
      let deleteRequests = [];

      if (this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          uploadRequests.push(this.uploadFile(i, fileCollection.id));
        }
      }

      if (deleteFiles.length > 0) {
        for (let i = 0; i < deleteFiles.length; i++) {
          deleteRequests.push(this.deleteFile(deleteFiles[i], fileCollection.id));
        }
      }

      if (uploadRequests.length && deleteRequests.length) {
        Promise.all([Promise.all(uploadRequests), Promise.all(deleteRequests)]).then(result => {
          data.files = [...result[0], ...fileCollection.files].filter(file => result[1].indexOf(file.id) === -1);
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              successMessage: message
            }
          }, () => {
            this.props.onUpdate(data, 'files');
          });
          // this.hideCreateUpdateModal();
        }).catch((error) => {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: error,
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
        });
      } else if (uploadRequests.length) {
        Promise.all(uploadRequests).then(result => {
          data.files = [...result, ...fileCollection.files];
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              successMessage: message
            }
          }, () => {
            this.props.onUpdate(data, 'files');
          });
          // this.hideCreateUpdateModal();
        }).catch((error) => {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: error,
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
        });
      } else if (deleteRequests.length) {
        Promise.all(deleteRequests).then(result => {
          data.files = [...fileCollection.files].filter(file => result.indexOf(file.id) === -1);
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              successMessage: message
            }
          }, () => {
            this.props.onUpdate(data, 'files');
          });
          // this.hideCreateUpdateModal();
        }).catch((error) => {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: error,
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
        });
      } else {
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            successMessage: message
          }
        }, () => {
          this.props.onUpdate(data, 'files');
        });
        // this.hideCreateUpdateModal();
      }
    }, (error) => {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          isLoading: false,
          loadingFiles: false,
          loadingDeleteFiles: []
        }
      });
    }, this.props.history);
  }
  uploadFile = (i, fileCollectionID) => {
    const formData = new window.FormData();
    formData.append('file', this.files[i], this.files[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/faculty/materials/files/${fileCollectionID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { createUpdateModal: { fileNames } } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            fileNames
          }
        });
      }
    }).then(({ data: { data }}) => {
      const { createUpdateModal: { fileNames } } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          fileNames
        }
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { createUpdateModal: { fileNames } } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          fileNames
        }
      });
      throw fileNames[i].isError;
    });
  }
  deleteFile = (id, fileCollectionID) => {
    return axios.delete(`${process.env['REACT_APP_API_BASE_URL']}/faculty/materials/files/${fileCollectionID}/file/${id}`, {
      withCredentials: true,
    }).then(({ data: { data }}) => {
      let newLoadingDeleteFiles = [...this.state.createUpdateModal.loadingDeleteFiles].filter(fileID => fileID !== id);
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          loadingDeleteFiles: newLoadingDeleteFiles
        }
      });
      return id;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      throw error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
    });
  }
  handleSave = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        isLoading: true,
        errorMessage: '',
        loadingFiles: true,
        loadingDeleteFiles: [...this.state.createUpdateModal.deleteFiles]
      }
    }, () => {
      const { createUpdateModal: { formInputs, fileCollection, deleteFiles } } = this.state;
      const { audiences } = this.props;
      
      let validator = new Validator(formInputs, {
        title: 'required|min:3',
        description: 'required',
        audience: 'required|array|min:1',
        'audience.*.facultyLoadId': 'required|integer|min:1',
        'audience.*.availability': 'required|in:always,from,fromUntil,until'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            errorMessage: validator.errors.errors[firstKey][0],
            isLoading: false,
            loadingFiles: false,
            loadingDeleteFiles: []
          }
        });
        return;
      }

      for (let i = 0; i < formInputs.audience.length; i++) {
        let tempAudience = audiences.find(a => a.id === formInputs.audience[i].facultyLoadId);
        if (!tempAudience) {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: 'Classroom not found.',
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
          return;
        }

        let rules = null;
        switch (formInputs.audience[i].availability) {
          case 'from':
            rules = {
              from: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`
            };
            break;
          case 'fromUntil':
            rules = {
              from: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`,
              until: `required|date|after_or_equal:from`
            };
            break;
          case 'until':
            rules = {
              until: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`
            };
            break;
          default:
            break;
        }

        if (rules !== null) {
          validator = new Validator({
            from: formInputs.audience[i].from,
            until: formInputs.audience[i].until,
          }, rules);

          if (validator.fails()) {
            const firstKey = Object.keys(validator.errors.errors)[0];
            this.setState({
              ...this.state,
              createUpdateModal: {
                ...this.state.createUpdateModal,
                errorMessage: validator.errors.errors[firstKey][0],
                isLoading: false,
                loadingFiles: false,
                loadingDeleteFiles: []
              }
            });
            return;
          }
        }
      }
      
      if (fileCollection) {
        if (fileCollection.files.length > 0 && fileCollection.files.length !== deleteFiles.length) {
          this.handleUpdate();
        } else {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: 'Files are required.',
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
          return;
        }
      } else {
        if (this.files.length > 0) {
          this.handleCreate();
        } else {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: 'Files are required.',
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
          return;
        }
      }
    });
  }
  showDeleteModal = fileCollection => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        fileCollection,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        fileCollection: null,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleDelete = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { deleteModal } = this.state;
      axiosRequest('delete', `faculty/materials/files/${deleteModal.fileCollection.id}`, null, ({ data: { message }}) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            successMessage: message
          }
        }, () => {
          this.props.onDelete(deleteModal.fileCollection.id, 'files');
        });
        // this.hideDeleteModal();
      }, (error) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showViewFiles = viewFiles => {
    this.setState({
      ...this.state,
      viewFiles
    });
  }
  hideViewFiles = () => {
    this.setState({
      ...this.state,
      viewFiles: null
    });
  }
  handleFileDownload = fileID => {
    const { viewFiles } = this.state;

    axiosRequest('get', `faculty/materials/files/${viewFiles.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { data } = this.props;

      const path = data.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false
        }, () => {
          this.props.onNextPage(data, 'files');
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handlePreviewImages = (viewFiles, images, index) => {
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/faculty/materials/files/${viewFiles.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  renderAdditionalParams = facultyLoad => {
    const { createUpdateModal: { formInputs, isLoading } } = this.state;

    let formInputValues = formInputs.audience.find(a => a.facultyLoadId === facultyLoad.id);

    if (!formInputValues) {
      return null;
    }

    return (
      <div className='mt-3 px-3'>
        <Form.Group>
          <Form.Label>Availability</Form.Label>
          <Form.Control
            as='select'
            name='availability'
            value={formInputValues.availability}
            onChange={e => this.handleAudienceParamsChange(e, facultyLoad.id)}
            disabled={isLoading} >
              <option disabled hidden value=''>Availability...</option>
              <option value='always'>Always</option>
              <option value='from'>From...</option>
              <option value='fromUntil'>From... Until...</option>
              <option value='until'>Until...</option>
          </Form.Control>
        </Form.Group>
        {this.renderAvailability(formInputValues, facultyLoad)}
      </div>
    );
  }
  renderAvailability = (formInputValues, facultyLoad) => {
    const { createUpdateModal: { isLoading } } = this.state;

    switch(formInputValues.availability) {
      case 'from':
        return (
          <Form.Group>
            <Form.Label>From</Form.Label>
            <Form.Control type='datetime-local' name='from' min={moment().format('YYYY-MM-DDTHH:mm')} max={formInputValues.until} value={formInputValues.from} onChange={e => this.handleAudienceParamsChange(e, facultyLoad.id)} disabled={isLoading} />
          </Form.Group>
        );
      case 'fromUntil':
        return (
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>From</Form.Label>
              <Form.Control type='datetime-local' name='from' min={moment().format('YYYY-MM-DDTHH:mm')} max={formInputValues.until} value={formInputValues.from} onChange={e => this.handleAudienceParamsChange(e, facultyLoad.id)} disabled={isLoading} />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>Until</Form.Label>
              <Form.Control type='datetime-local' name='until' min={formInputValues.from} value={formInputValues.until} onChange={e => this.handleAudienceParamsChange(e, facultyLoad.id)} disabled={isLoading} />
            </Form.Group>
          </Form.Row>
        );
      case 'until':
        return (
          <Form.Group md={6}>
            <Form.Label>Until</Form.Label>
            <Form.Control type='datetime-local' name='until' min={formInputValues.from} value={formInputValues.until} onChange={e => this.handleAudienceParamsChange(e, facultyLoad.id)} disabled={isLoading} />
          </Form.Group>
        );
      default:
        return null;
    }
  }
  renderAudience = () => {
    const { createUpdateModal: { formInputs: { audience }, isLoading } } = this.state;
    const { audiences } = this.props;

    return (
      <Form.Group>
        <Form.Label>
          Audience
        </Form.Label>
        <div className='ml-3'>
        {
          audiences.map(a => (
            <Form.Group
              controlId={`audience${a.id}`}
              key={a.id}>
              <Form.Check
                type='checkbox'
                name='audience'
                label={`${a.class_course.school_class.program.code} ${a.class_course.school_class.year_level.code} - ${a.class_course.school_class.section.code} - ${a.class_course.course.code}`}
                value={a.id}
                checked={audience.find(a2 => a2.facultyLoadId === a.id)}
                onChange={this.handleAudienceChange}
                disabled={isLoading} />
              {this.renderAdditionalParams(a)}
            </Form.Group>
          ))
        }
        </div>
      </Form.Group>
    );
  }
  renderFileUpload = () => {
    const { createUpdateModal: { fileNames, loadingFiles } } = this.state;
    if (fileNames.length > 0) {
      return (
        <Form.Group>
          <Form.Label>File(s) to be uploaded</Form.Label>
          <small className='file-upload-display d-block rounded-top'>
          {
            fileNames.map((fileName, index) => (
              <div key={index} className='file-name d-flex'>
                <div>
                  {
                    fileName.isLoading || (loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                      <LoadingIcon />
                    ) : fileName.isError ? (
                      <FontAwesomeIcon icon='times-circle' className='text-danger' />
                    ) : fileName.isSuccess ? (
                      <FontAwesomeIcon icon='check-circle' className='text-green' />
                    ) : (
                      <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                    )
                  }
                </div>
                <div className='ml-2 flex-fill'>
                  <div>
                    {fileName.name}
                  </div>
                  {
                    fileName.isLoading && (
                      <div className='mt-1'>
                        <ProgressBar now={fileName.isLoading} />
                      </div>
                    )
                  }
                </div>
                {
                  (!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !loadingFiles) && (
                    <div className='ml-2 align-items-center d-flex'>
                      <Button
                        variant='link'
                        className='text-danger'
                        size='sm'
                        title='Remove'
                        onClick={(e) => this.handleRemoveUpload(index)}>
                        <FontAwesomeIcon icon='times' size='sm' />
                      </Button>
                    </div>
                  )
                }
              </div>
            ))
          }
          </small>
        </Form.Group>
      );
    }
  }
  renderAttachedFiles = () => {
    const { createUpdateModal: { showAttachments, deleteFiles, loadingDeleteFiles, fileCollection, isLoading } } = this.state;

    if (fileCollection && fileCollection.files.length > 0) {
      return (
        <>
          <div className={`text-right border rounded-top ${showAttachments ? 'border-bottom-0' : 'rounded-bottom'}`}>
            <Button variant='link' onClick={this.handleShowAttachments} block>
              <FontAwesomeIcon icon='paperclip' /> Show {fileCollection.files.length} attachment(s)
            </Button>
          </div>
          <Collapse in={showAttachments}>
            <div className='file-upload-display small'>
            {
              fileCollection.files.map(file => (
                <div className={`file-name d-flex ${deleteFiles.indexOf(file.id) !== -1 ? 'alert-light' : ''}`}>
                  {
                    loadingDeleteFiles.indexOf(file.id) !== -1 && (
                      <LoadingIcon />
                    )
                  }
                  <div className='ml-2 flex-fill'>
                    <div>
                      {file.file_title}
                    </div>
                    {
                      deleteFiles.indexOf(file.id) !== -1 && (
                        <div className='font-italic'>
                          To be deleted
                        </div>
                      )
                    }
                  </div>
                  <div className='ml-2 align-items-center d-flex'>
                    {
                      deleteFiles.indexOf(file.id) !== -1 ? (
                        <Button
                          variant='link'
                          className='text-green'
                          size='sm'
                          title='Cancel delete'
                          onClick={() => this.handleCancelDeleteFile(file.id)}
                          disabled={isLoading}>
                          <FontAwesomeIcon icon='ban' size='sm' />
                        </Button>
                      ) : (
                        <Button
                          variant='link'
                          className='text-danger'
                          size='sm'
                          title='Delete'
                          onClick={() => this.handleDeleteFile(file.id)}
                          disabled={isLoading}>
                          <FontAwesomeIcon icon='trash-alt' size='sm' />
                        </Button>
                      )
                    }
                  </div>
                </div>
              ))
            }
            </div>
          </Collapse>
        </>
      );
    }
  }
  renderCreateUpdateModal = () => {
    const { show, fileCollection, formInputs, isLoading, errorMessage, successMessage } = this.state.createUpdateModal;

    return (
      <Modal show={show} backdrop='static' onHide={this.hideCreateUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {
              fileCollection ? 'Update file collection' : 'Create an file collection'
            }
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleSave} className='file-form'>
          <Modal.Body>
            {
              errorMessage ? (
                <Alert variant='danger'>
                  {errorMessage}
                </Alert>
              ) : successMessage ? (
                <Alert variant='success'>
                  {successMessage}
                </Alert>
              ) : null
            }
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type='text' name='title' value={formInputs.title} onChange={this.handleInputChange} disabled={isLoading} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <RichTextEditor.Editor
                value={formInputs.description}
                onChange={this.handleDescriptionInputChange}
                readOnly={isLoading} />
              {/* <Form.Control as='textarea' rows='4' style={{ resize: 'none' }} name='description' value={formInputs.description} onChange={this.handleInputChange} disabled={isLoading} /> */}
            </Form.Group>
            {this.renderAudience()}
            {this.renderFileUpload()}
            {this.renderAttachedFiles()}
          </Modal.Body>
          <Modal.Footer>
            {
              successMessage ? (
                <Button variant='light' onClick={this.hideCreateUpdateModal}>
                  Close
                </Button>
              ) : (
                <>
                  <div className='mr-auto'>
                    <label className={`btn btn-info ${isLoading ? 'disabled' : ''}`} htmlFor='add-file' title='File upload'>
                      <FontAwesomeIcon icon='file-upload' />
                    </label>
                    <Form.File className='d-none' id='add-file' onChange={this.handleFileUpload} multiple disabled={isLoading} />
                  </div>
                  <Button variant='danger mr-2' onClick={this.hideCreateUpdateModal} disabled={isLoading}>
                    Cancel
                  </Button>
                  <Button variant='green' type='submit' disabled={isLoading}>
                    Save
                  </Button>
                </>
              )
            }
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
  renderDeleteModal = () => {
    const { deleteModal } = this.state;

    let renderAudience = audience => {
      const { audiences } = this.props;

      let tempAudience = audiences.find(a => a.id === audience.faculty_load_id);
      if (!tempAudience) {
        return null;
      }

      return (
        <div key={audience.id} className='mb-1'>
          {`${tempAudience.class_course.school_class.program.code} ${tempAudience.class_course.school_class.year_level.code}-${tempAudience.class_course.school_class.section.code} - ${tempAudience.class_course.course.code}`}
        </div>
      );
    };

    return (
      <PromptDeleteModal {...deleteModal} title='Delete file collection' onHide={this.hideDeleteModal} onDelete={this.handleDelete}>
        {
          !deleteModal.successMessage && (
            <div className='font-weight-bold'>
              <div>Are you sure you want to delete the file collection?</div>
            </div>
          )
        }
        {
          deleteModal.fileCollection && (
            <Alert variant='light'>
              <div>{deleteModal.fileCollection.title}</div>
              {
                deleteModal.fileCollection.files.length > 0 && (
                  <div className='font-italic'><FontAwesomeIcon icon='paperclip' /> {`${deleteModal.fileCollection.files.length} attachment(s)`}</div>
                )
              }
              <div className='d-md-flex'>
                <div className='mr-1'>
                  <FontAwesomeIcon icon='users' /> Audience(s):
                </div>
                <div>
                  {
                    deleteModal.fileCollection.audiences.map(renderAudience)
                  }
                </div>
              </div>
            </Alert>
          )
        }
      </PromptDeleteModal>
    );
  }
  renderViewFiles = () => {
    const { viewFiles } = this.state;

    if (!viewFiles) {
      return null;
    }

    let images = viewFiles.files.length > 0 ? viewFiles.files.filter(file => {
      return !!file.is_image;
    }) : [];
  
    let files = viewFiles.files.length > 0 ? viewFiles.files.filter(file => {
      return !file.is_image;
    }) : [];

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.hideViewFiles}>Files</Breadcrumb.Item>
          <Breadcrumb.Item active>{viewFiles.title}</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <div className='d-flex px-md-3'>
            <div className='flex-fill h5 pt-1' style={{ wordBreak: 'break-all' }}>
              {viewFiles.title}
            </div>
            <div className='d-block pl-2'>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    <div>Posted: {moment(viewFiles.created_at).format('dddd, MMM D, YYYY hh:mm A')}</div>
                  </Tooltip>
                }
                trigger={['hover', 'focus']}>
                <FontAwesomeIcon icon='info-circle' className='info-icon text-primary' size='lg' />
              </OverlayTrigger>
            </div>
          </div>
          <div className='px-md-3'>
            <RichTextEditor.Viewer body={viewFiles.description} />
            {/* <div style={{ whiteSpace: 'pre-line' }}>
              {viewFiles.description}
            </div> */}
            {
              viewFiles.files.length > 0 && (
                <div>
                  {
                    images.length > 0 && (
                      <Row>
                      {
                        images.map((image, index) => (
                          <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(viewFiles, images, index)}>
                            <Image src={`${process.env['REACT_APP_API_BASE_URL']}/faculty/materials/files/${viewFiles.id}/image/${image.id}`} thumbnail />
                          </Col>
                        ))
                      }
                      </Row>
                    )
                  }
                  {
                    files.length > 0 && (
                      <div className='p-1'>
                        {
                          files.map(file => (
                            <div key={file.id} className='d-flex mb-2'>
                              <div className='mr-2'>
                                <FontAwesomeIcon icon='file' className='text-primary' />
                              </div>
                              <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                                <span className='btn-link' onClick={e => this.handleFileDownload(file.id)}>
                                  {file.file_title}
                                </span>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
        </div>
      </>
    );
  }
  renderAudiencesDisplay = audience => {
    const { audiences } = this.props;

    let tempAudience = audiences.find(a => a.id === audience.faculty_load_id);
    if (!tempAudience) {
      return null;
    }

    return (
      <div key={audience.id} className='mb-1'>
        <div className='font-weight-bold'>
          {`${tempAudience.class_course.school_class.program.code} ${tempAudience.class_course.school_class.year_level.code}-${tempAudience.class_course.school_class.section.code} - ${tempAudience.class_course.course.code}`}
        </div>
        <div className='px-1 px-md-3'>
          {
            moment().isBefore(audience.from) && (
              <span className='text-muted font-weight-normal font-italic'> (Not yet available)</span>
            )
          }
          <div className='text-muted font-italic d-flex'>
            <div className='mr-1'>
              Available
            </div>
            <div>
              {
                audience.from && audience.until ? (
                  <>
                    <div>
                      From: {moment(audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
                    </div>
                    <div>
                      Until: {moment(audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
                    </div>
                  </>
                ) : audience.from ? (
                  <div>
                    From: {moment(audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
                  </div>
                ) : audience.until ? (
                  <div>
                    Until: {moment(audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
                  </div>
                ) : ''
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderContent = () => {
    const { isNextPageLoading, nextPageError } = this.state;
    const { data } = this.props;

    if (data.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='material-list'>
          {
            data.data.map(d => (
              <div key={d.id}>
                <div className='d-flex'>
                  <div className='flex-fill'>
                    <div className='btn-link font-weight-bold' onClick={e => this.showViewFiles(d)}>
                      {d.title}
                    </div>
                    {
                      d.files.length > 0 && (
                        <div className='text-muted font-italic'><FontAwesomeIcon icon='paperclip' /> {`${d.files.length} attachment(s)`}</div>
                      )
                    }
                    <RichTextEditor.Viewer body={d.description} className='text-muted' />
                    {/* <div className='text-muted'>{d.description}</div> */}
                  </div>
                  <div className='d-flex d-md-none'>
                    <div className='mr-2'>
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            <div>
                              {moment(d.created_at).format('MMM D, YYYY hh:mm A')}
                            </div>
                            <div>
                              {moment(d.created_at).fromNow()}
                            </div>
                          </Tooltip>
                        }
                        trigger={['hover', 'focus']}>
                        <FontAwesomeIcon icon='clock' className='text-muted' />
                      </OverlayTrigger>
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle as='span' className='text-green'>
                        <FontAwesomeIcon icon='cog' />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={e => this.showCreateUpdateModal(e, d)}>
                          <FontAwesomeIcon icon='pencil-alt' /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => this.showDeleteModal(d)}>
                          <FontAwesomeIcon icon='trash-alt' /> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className='font-small text-muted font-italic pr-3 d-none d-md-block'>
                    &mdash; <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {moment(d.created_at).format('MMM D, YYYY hh:mm A')}
                                </Tooltip>
                              }
                              trigger={['hover', 'focus']}>
                              <FontAwesomeIcon icon='clock' />
                            </OverlayTrigger> {moment(d.created_at).fromNow()}
                  </div>
                  <div className='d-none d-md-block' style={{ whiteSpace: 'nowrap' }}>
                    <Button variant='info' size='sm' className='mr-1' onClick={e => this.showCreateUpdateModal(e, d)}>
                      <FontAwesomeIcon icon='pencil-alt' />
                    </Button>
                    <Button variant='danger' size='sm' onClick={() => this.showDeleteModal(d)}>
                      <FontAwesomeIcon icon='trash-alt' />
                    </Button>
                  </div>
                </div>
                <div className='text-muted d-md-flex mt-1'>
                  <div className='mr-1'>
                    <FontAwesomeIcon icon='users' /> Audience(s):
                  </div>
                  <div>
                    {
                      d.audiences.map(this.renderAudiencesDisplay)
                    }
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        {
          data.next_page_url && (
            <div className='text-center mt-3'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                View more files
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          )
        }
      </>
    );
  }
  render() {
    const { isLoading, errorMessage, viewFiles } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        {
          viewFiles ? this.renderViewFiles() : (
            <>
              <div className='text-right mb-3'>
                <Button variant='green' onClick={this.showCreateUpdateModal}>
                  <FontAwesomeIcon icon='plus' /> Create a file collection
                </Button>
              </div>
              {this.renderContent()}
              {this.renderCreateUpdateModal()}
              {this.renderDeleteModal()}
            </>
          )
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Files);