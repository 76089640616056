import React, { Component } from 'react';
import { Alert, Breadcrumb, Button, Col, Dropdown, Form, InputGroup, Modal, Nav, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import moment from 'moment';
import axios from 'axios';
import Validator from 'validatorjs';
import { loadRule } from '../../../../../util';
import PromptDeleteModal from '../../../../modals/PromptDeleteModal/PromptDeleteModal';
import { TrueFalse, MultipleChoice, Ordering, Essay, Matching, Identification, Enumeration } from '../../../../common/Questions/parts';
import {
  TrueFalse as ViewTrueFalse,
  MultipleChoice as ViewMultipleChoice,
  Ordering as ViewOrdering,
  Essay as ViewEssay,
  Matching as ViewMatching,
  Identification as ViewIdentification,
  Enumeration as ViewEnumeration
} from '../../../../common/Questions/View/parts';
import './style.scss';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../../common/RichTextEditor/RichTextEditor';

const questionTypeMap = {
  trueFalse: 'True/False',
  multipleChoice: 'Multiple Choice',
  ordering: 'Ordering',
  essay: 'Essay/Problem Solving',
  matching: 'Matching',
  identification: 'Identification',
  enumeration: 'Enumeration'
};

const questionRelationTypeMap = {
  trueFalse: 'question_boolean',
  multipleChoice: 'question_multiple_choice',
  ordering: 'question_ordering',
  essay: 'question_essay',
  matching: 'question_matching',
  identification: 'question_identification',
  enumeration: 'question_enumeration'
};

export default class Quizzes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      createUpdateModal: {
        show: false,
        quiz: null,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        formInputs: {
          title: '',
          description: '',
          checkTimeLimit: false,
          timeLimit: '',
          audience: []
        }
      },
      deleteModal: {
        show: false,
        quiz: null,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      },
      viewQuiz: {
        show: false,
        isLoading: true,
        errorMessage: '',
        quiz: null,
        questions: {
          data: []
        },
        activeNav: '',
        createUpdateModal: {
          show: false,
          data: null,
          type: '',
          index: 0
        },
        deleteModal: {
          show: false,
          index: 0,
          data: null,
          isLoading: false,
          errorMessage: ''
        },
        isNextPageLoading: false,
        nextPageError: '',
        preview: {
          current: 'intro',
          timer: false,
          alreadyReviewed: false
        }
      },
      isNextPageLoading: false,
      nextPageError: ''
    };
    
    this.timerInterval = null;
  }
  componentDidMount() {
    if (this.props.data.data.length === 0) {
      axiosRequest('get', 'faculty/materials/quizzes', null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isLoading: false
        }, () => {
          this.props.onFetch(data, 'quizzes');
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    } else {
      this.setState({
        ...this.state,
        isLoading: false
      });
    }

    loadRule(['date', 'after_or_equal'], {
      after_or_equal: 'The due date must be equal or after the current date and time.'
    });
  }
  showCreateUpdateModal = (event, quiz = null) => {
    let formInputs = {
      title: '',
      description: '',
      checkTimeLimit: false,
      timeLimit: '',
      audience: []
    };

    let asyncSchedules = [];
    if (quiz) {
      let audience = [];
      for (let i = 0; i < quiz.audiences.length; i++) {
        let tempAsync = quiz.material_async_schedules.find(mas => mas.faculty_load_id === quiz.audiences[i].faculty_load_id);
        if (tempAsync) {
          let asyncDate = {
            date: tempAsync.schedule_date,
            start_time: tempAsync.start_time,
            end_time:tempAsync.end_time,
            schedule_from: tempAsync.schedule_from
          };
          audience = [
            ...audience,
            {
              facultyLoadId: quiz.audiences[i].faculty_load_id,
              gradeCategoryId: quiz.audiences[i].grade_category_id,
              from: moment(quiz.audiences[i].from).format('YYYY-MM-DDTHH:mm'),
              until: moment(quiz.audiences[i].until).format('YYYY-MM-DDTHH:mm'),
              isAsync: true,
              asyncDate
            }
          ];
          asyncSchedules = [
            ...asyncSchedules,
            {
              classID: quiz.audiences[i].faculty_load_id,
              asyncDate
            }
          ];
        } else {
          audience = [
            ...audience,
            {
              facultyLoadId: quiz.audiences[i].faculty_load_id,
              gradeCategoryId: quiz.audiences[i].grade_category_id,
              from: moment(quiz.audiences[i].from).format('YYYY-MM-DDTHH:mm'),
              until: moment(quiz.audiences[i].until).format('YYYY-MM-DDTHH:mm'),
              isAsync: false
            }
          ];
        }
      }
      formInputs = {
        title: quiz.title,
        description: quiz.description,
        checkTimeLimit: !!quiz.time_limit,
        timeLimit: quiz.time_limit,
        audience
      };
    }

    this.setState({
      ...this.state,
      createUpdateModal: {
        show: true,
        quiz,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        formInputs
      }
    }, () => {
      if (asyncSchedules.length > 0) {
        this.setAsyncSchedules(asyncSchedules);
      }
    });
  }
  hideCreateUpdateModal = () => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        show: false,
        quiz: null,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        formInputs: {
          title: '',
          description: '',
          checkTimeLimit: false,
          timeLimit: '',
          audience: []
        }
      }
    }, this.props.onResetAsync);
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          [event.target.name]: event.target.value
        }
      }
    });
  }
  handleDescriptionInputChange = description => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          description
        }
      }
    });
  }
  handleCheckTimeLimit = event => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          checkTimeLimit: event.target.checked
        }
      }
    });
  }
  handleAudienceChange = event => {
    const { createUpdateModal: { formInputs } } = this.state;

    let audience = [...formInputs.audience];
    if (event.target.checked) {
      audience = [
        ...audience,
        {
          facultyLoadId: +event.target.value,
          gradeCategoryId: '',
          from: '',
          until: '',
          isAsync: false,
          asyncDate: null
        }
      ];
    } else {
      audience = audience.filter(a => a.facultyLoadId !== +event.target.value);
    }

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          audience
        }
      }
    });
  }
  handleAudienceParamsChange = (event, facultyLoadID) => {
    let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
      if (a.facultyLoadId === facultyLoadID) {
        return {
          ...a,
          [event.target.name]: event.target.value
        };
      }

      return a;
    });

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          audience: newAudience
        }
      }
    });
  }
  handleAsyncInputChange = (event, facultyLoadID) => {
    const isAsync = event.target.checked;
    let asyncSchedule = [...this.props.asyncSchedules].find(as => as.classID === facultyLoadID);
  
    if (isAsync && (this.props.asyncSchedules.length === 0 || !asyncSchedule || asyncSchedule.schedules.length === 0)) {
      this.setAsyncSchedule(facultyLoadID);
      return;
    }

    let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
      if (a.facultyLoadId === facultyLoadID) {
        return {
          ...a,
          isAsync
        };
      }

      return a;
    });

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          audience: newAudience
        }
      }
    });
  }
  setAsyncSchedule = (classID, currentAsyncDate = null) => {
    let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
      if (a.facultyLoadId === classID) {
        return {
          ...a,
          isAsync: true
        };
      }

      return a;
    });

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        isLoading: true,
        errorMessage: '',
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          audience: newAudience
        }
      }
    }, () => {
      axiosRequest('get', `faculty/class/${classID}/material/async-schedules${currentAsyncDate ? `/${currentAsyncDate.date}` : ''}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            isLoading: false
          }
        }, () => {
          this.props.setAsyncSchedules(classID, data, currentAsyncDate, (isSuccess, returnData) => {
            let newAudience2 = [...this.state.createUpdateModal.formInputs.audience].map(a => {
              if (a.facultyLoadId === classID) {
                if (isSuccess) {
                  return {
                    ...a,
                    asyncDate: returnData,
                    from: moment(`${returnData.date} ${returnData.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                    until: moment(`${returnData.date} ${returnData.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm')
                  };
                } else {
                  return {
                    ...a,
                    isAsync: false
                  };
                }
              }
        
              return {
                ...a
              };
            });
            if (isSuccess) {
              this.setState({
                ...this.state,
                createUpdateModal: {
                  ...this.state.createUpdateModal,
                  formInputs: {
                    ...this.state.createUpdateModal.formInputs,
                    audience: newAudience2
                  }
                }
              });
            } else {
              this.setState({
                ...this.state,
                createUpdateModal: {
                  ...this.state.createUpdateModal,
                  errorMessage: 'There are no schedules available.',
                  formInputs: {
                    ...this.state.createUpdateModal.formInputs,
                    audience: newAudience2
                  }
                }
              });
            }
          });
        });
      }, error => {
        let newAudience3 = [...this.state.createUpdateModal.formInputs.audience].map(a => {
          if (a.facultyLoadId === classID) {
            return {
              ...a,
              isAsync: false
            };
          }
    
          return a;
        });

        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            formInputs: {
              ...this.state.createUpdateModal.formInputs,
              audience: newAudience3
            },
            isLoading: false,
            errorMessage: (error.response && error.response.data.message) ? error.response.data.message : error.message ? error.message : error
          },
        });
      }, this.props.history);
    });
  }
  setAsyncSchedules = toFetchArray => {
    let fetchAsyncSchedules = (classID, currentAsyncDate) => {
      return axios.get(`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/async-schedules${currentAsyncDate ? `/${currentAsyncDate.date}` : ''}`, {
        withCredentials: true,
      }).then(({ data: { data }}) => {
        this.props.setAsyncSchedules(classID, data, currentAsyncDate, (isSuccess, returnData) => {
          let newAudience2 = [...this.state.createUpdateModal.formInputs.audience].map(a => {
            if (a.facultyLoadId === classID) {
              if (isSuccess) {
                return {
                  ...a,
                  asyncDate: returnData,
                  from: moment(`${returnData.date} ${returnData.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                  until: moment(`${returnData.date} ${returnData.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm')
                };
              } else {
                return {
                  ...a,
                  isAsync: false
                };
              }
            }
      
            return {
              ...a
            };
          });
          if (isSuccess) {
            this.setState({
              ...this.state,
              createUpdateModal: {
                ...this.state.createUpdateModal,
                formInputs: {
                  ...this.state.createUpdateModal.formInputs,
                  audience: newAudience2
                }
              }
            });
          } else {
            this.setState({
              ...this.state,
              createUpdateModal: {
                ...this.state.createUpdateModal,
                errorMessage: 'There are no schedules available.',
                formInputs: {
                  ...this.state.createUpdateModal.formInputs,
                  audience: newAudience2
                }
              }
            });
          }
        });
        return data;
      }).catch((error) => {
        if (error.response && error.response.status === 403) {
          this.props.history.push('/login');
        }
  
        let newAudience3 = [...this.state.createUpdateModal.formInputs.audience].map(a => {
          if (a.facultyLoadId === classID) {
            return {
              ...a,
              isAsync: false
            };
          }
    
          return a;
        });

        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            formInputs: {
              ...this.state.createUpdateModal.formInputs,
              audience: newAudience3
            },
            errorMessage: (error.response && error.response.data.message) ? error.response.data.message : error.message ? error.message : error
          },
        });
        throw (error.response && error.response.data.message) ? error.response.data.message : error.message ? error.message : error;
      });
    };

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      let fetchRequests = [];
      for (let i = 0; i < toFetchArray.length; i++) {
        fetchRequests.push(fetchAsyncSchedules(toFetchArray[i].classID, toFetchArray[i].asyncDate));
      }
      
      Promise.all(fetchRequests).then(result => {
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            isLoading: false
          }
        });
      }).catch((error) => {
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            isLoading: false
          }
        });
      });
    });
  }
  handlePrevAsync = classID => {
    const { asyncSchedules } = this.props;

    let audience = [...this.state.createUpdateModal.formInputs.audience].find(a => a.facultyLoadId === classID);
    
    if (audience && audience.asyncDate) {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          isLoading: true
        }
      }, () => {
        let asyncSchedule = asyncSchedules.find(as => as.classID === classID);

        if (asyncSchedule) {
          let index = asyncSchedule.schedules.findIndex(as1 => 
            as1.date === audience.asyncDate.date &&
            as1.start_time === audience.asyncDate.start_time &&
            as1.end_time === audience.asyncDate.end_time &&
            as1.schedule_from === audience.asyncDate.schedule_from
          );
          if (index !== -1) {
            if (index === 0) {
              this.props.onPrevAsync(classID, audience.asyncDate, newAsyncDate => {
                let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
                  if (a.facultyLoadId === classID) {
                    return {
                      ...a,
                      asyncDate: newAsyncDate,
                      from: moment(`${newAsyncDate.date} ${newAsyncDate.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                      until: moment(`${newAsyncDate.date} ${newAsyncDate.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm')
                    };
                  }
            
                  return a;
                });
                this.setState({
                  ...this.state,
                  createUpdateModal: {
                    ...this.state.createUpdateModal,
                    isLoading: false,
                    formInputs: {
                      ...this.state.createUpdateModal.formInputs,
                      audience: newAudience
                    }
                  }
                });
              });
            } else {
              let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
                if (a.facultyLoadId === classID) {
                  return {
                    ...a,
                    asyncDate: {...asyncSchedule.schedules[index-1]},
                    from: moment(`${{...asyncSchedule.schedules[index-1]}.date} ${{...asyncSchedule.schedules[index-1]}.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                    until: moment(`${{...asyncSchedule.schedules[index-1]}.date} ${{...asyncSchedule.schedules[index-1]}.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm')
                  };
                }
          
                return a;
              });
              this.setState({
                ...this.state,
                createUpdateModal: {
                  ...this.state.createUpdateModal,
                  isLoading: false,
                  formInputs: {
                    ...this.state.createUpdateModal.formInputs,
                    audience: newAudience
                  }
                }
              });
            }
          }
        }
      });
    }
  }
  handleNextAsync = classID => {
    const { asyncSchedules } = this.props;

    let audience = [...this.state.createUpdateModal.formInputs.audience].find(a => a.facultyLoadId === classID);

    if (audience && audience.asyncDate) {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          isLoading: true
        }
      }, () => {
        let asyncSchedule = asyncSchedules.find(as => as.classID === classID);

        if (asyncSchedule) {
          let index = asyncSchedule.schedules.findIndex(as1 => 
            as1.date === audience.asyncDate.date &&
            as1.start_time === audience.asyncDate.start_time &&
            as1.end_time === audience.asyncDate.end_time &&
            as1.schedule_from === audience.asyncDate.schedule_from
          );
          if (index !== -1) {
            if (index === asyncSchedule.schedules.length-1) {
              this.props.onNextAsync(classID, audience.asyncDate, newAsyncDate => {
                let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
                  if (a.facultyLoadId === classID) {
                    return {
                      ...a,
                      asyncDate: newAsyncDate,
                      from: moment(`${newAsyncDate.date} ${newAsyncDate.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                      until: moment(`${newAsyncDate.date} ${newAsyncDate.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm')
                    };
                  }
            
                  return a;
                });
                this.setState({
                  ...this.state,
                  createUpdateModal: {
                    ...this.state.createUpdateModal,
                    isLoading: false,
                    formInputs: {
                      ...this.state.createUpdateModal.formInputs,
                      audience: newAudience
                    }
                  }
                });
              });
            } else {
              let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
                if (a.facultyLoadId === classID) {
                  return {
                    ...a,
                    asyncDate: {...asyncSchedule.schedules[index+1]},
                    from: moment(`${{...asyncSchedule.schedules[index+1]}.date} ${{...asyncSchedule.schedules[index+1]}.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                    until: moment(`${{...asyncSchedule.schedules[index+1]}.date} ${{...asyncSchedule.schedules[index+1]}.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm')
                  };
                }
          
                return a;
              });
              this.setState({
                ...this.state,
                createUpdateModal: {
                  ...this.state.createUpdateModal,
                  isLoading: false,
                  formInputs: {
                    ...this.state.createUpdateModal.formInputs,
                    audience: newAudience
                  }
                }
              });
            }
          }
        }
      });
    }
  }
  handleCreate = inputs => {
    axiosRequest('post', `faculty/materials/quizzes`, inputs, ({ data: { data, message }}) => {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          successMessage: message
        }
      }, () => {
        this.props.onCreate(data, 'quizzes');
      });
      // this.hideCreateUpdateModal();
    }, (error) => {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          isLoading: false,
        }
      });
    }, this.props.history);
  }
  handleUpdate = inputs => {
    const { createUpdateModal: { quiz } } = this.state;

    axiosRequest('patch', `faculty/materials/quizzes/${quiz.id}`, inputs, ({ data: { data, message }}) => {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          successMessage: message
        }
      }, () => {
        this.props.onUpdate(data, 'quizzes');
      });
      // this.hideCreateUpdateModal();
    }, (error) => {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          isLoading: false
        }
      });
    }, this.props.history);
  }
  handleSave = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { createUpdateModal: { formInputs, quiz } } = this.state;
      const { audiences } = this.props;
      
      let validator = new Validator(formInputs, {
        title: 'required|min:3',
        description: 'required',
        timeLimit: [{required_if: ['checkTimeLimit', true]}, 'numeric', 'min:1'],
        checkTimeLimit: 'required|boolean',
        audience: 'required|array|min:1',
        'audience.*.facultyLoadId': 'required|integer|min:1',
        'audience.*.gradeCategoryId': 'required|integer|min:1',
        'audience.*.from': `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`,
        'audience.*.until': 'required|date',
        'audience.*.isAsync': 'required|boolean'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            errorMessage: validator.errors.errors[firstKey][0],
            isLoading: false
          }
        });
        return;
      }

      let newAudience = [];
      for (let i = 0; i < formInputs.audience.length; i++) {
        let tempAudience = audiences.find(a => a.id === formInputs.audience[i].facultyLoadId);
        if (!tempAudience) {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: 'Classroom not found.',
              isLoading: false
            }
          });
          return;
        }

        let tempGradeCategory = tempAudience.grade_categories.find(gc => gc.id === +formInputs.audience[i].gradeCategoryId);
        if (!tempGradeCategory) {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: 'Grade category not found.',
              isLoading: false
            }
          });
          return;
        }

        if (moment(formInputs.audience[i].from).isAfter(moment(formInputs.audience[i].until))) {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: 'Until date must be after or equal to from date.',
              isLoading: false
            }
          });
          return;
        }
        
        let custom = {};
        if (formInputs.audience[i].isAsync) {
          validator = {
            passes: true,
            error: ''
          };
  
          if (formInputs.audience[i].asyncDate === null || !formInputs.audience[i].asyncDate || !formInputs.audience[i].asyncDate.date) {
            validator.passes = false;
            validator.error = 'Asynchronous schedule is required.';
          } else {
            let asyncEnd = moment(`${formInputs.audience[i].asyncDate.date} ${formInputs.audience[i].asyncDate.end_time}`, 'YYYY-MM-DD HH:mm');
            if (moment(formInputs.audience[i].from).isSameOrAfter(asyncEnd)) {
              validator.passes = false;
              validator.error = 'The "from" field must be before the end of the asynchronous schedule.';
            }
          }
  
          if (!validator.passes) {
            this.setState({
              ...this.state,
              createUpdateModal: {
                ...this.state.createUpdateModal,
                errorMessage: validator.error,
                isLoading: false
              }
            });
            return;
          }
  
          custom = {
            asyncDate: formInputs.audience[i].asyncDate.date,
            asyncStartTime: formInputs.audience[i].asyncDate.start_time,
            asyncEndTime: formInputs.audience[i].asyncDate.end_time,
            asyncFrom: formInputs.audience[i].asyncDate.schedule_from
          };
        }

        newAudience.push({...formInputs.audience[i], ...custom});
      }
      
      if (quiz) {
        this.handleUpdate({
          ...formInputs,
          audience: newAudience
        });
      } else {
        this.handleCreate({
          ...formInputs,
          audience: newAudience
        });
      }
    });
  }
  showDeleteModal = quiz => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        quiz,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        quiz: null,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      }
    });
  }
  handleDelete = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { deleteModal } = this.state;
      axiosRequest('delete', `faculty/materials/quizzes/${deleteModal.quiz.id}`, null, ({ data: { message }}) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            successMessage: message
          }
        }, () => {
          this.props.onDelete(deleteModal.quiz.id, 'quizzes');
        });
        // this.hideDeleteModal();
      }, (error) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showViewQuiz = (quiz, activeNav = 'preview') => {
    this.setState({
      ...this.state,
      viewQuiz: {
        show: true,
        isLoading: true,
        errorMessage: '',
        quiz,
        questions: {
          data: []
        },
        activeNav,
        createUpdateModal: {
          show: false,
          data: null,
          type: '',
          index: 0
        },
        deleteModal: {
          show: false,
          index: 0,
          data: null,
          isLoading: false,
          errorMessage: ''
        },
        isNextPageLoading: false,
        nextPageError: '',
        preview: {
          current: 'intro',
          timer: false,
          alreadyReviewed: false
        }
      }
    }, () => {
      axiosRequest('get', `faculty/materials/quizzes/${quiz.id}/questions`, null, ({ data: { data }}) => {
        let questions = data.data.map(q => {
          if (q.type === 'multipleChoice') {
            q[questionRelationTypeMap[q.type]] = {
              ...q[questionRelationTypeMap[q.type]],
              radio: q[questionRelationTypeMap[q.type]].choices.filter(c => c.is_correct).length === 1
            };
          } else if (q.type === 'matching') {
            q[questionRelationTypeMap[q.type]] = {
              ...q[questionRelationTypeMap[q.type]],
              answers: q[questionRelationTypeMap[q.type]].items.map(i => i.answer)
            };
          }
          
          q.question = q[questionRelationTypeMap[q.type]];
  
          return q;
        });
        this.setState({
          ...this.state,
          viewQuiz: {
            ...this.state.viewQuiz,
            isLoading: false,
            questions: {
              ...data,
              data: questions
            }
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          viewQuiz: {
            ...this.state.viewQuiz,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  hideViewQuiz = () => {
    this.setState({
      ...this.state,
      viewQuiz: {
        show: false,
        isLoading: true,
        errorMessage: '',
        quiz: null,
        questions: {
          data: []
        },
        activeNav: '',
        createUpdateModal: {
          show: false,
          data: null,
          type: '',
          index: 0
        },
        deleteModal: {
          show: false,
          index: 0,
          data: null,
          isLoading: false,
          errorMessage: ''
        },
        isNextPageLoading: false,
        nextPageError: '',
        preview: {
          current: 'intro',
          timer: false,
          alreadyReviewed: false
        }
      }
    });
  }
  handleViewSelect = activeNav => {
    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        activeNav,
        createUpdateModal: {
          show: false,
          data: null,
          type: '',
          index: 0
        },
        deleteModal: {
          show: false,
          index: 0,
          data: null,
          isLoading: false,
          errorMessage: ''
        },
        preview: {
          current: 'intro',
          timer: false,
          alreadyReviewed: false
        }
      }
    });
  }
  showQuestionCreateUpdateModal = (key = '', index = 0, data = null) => {
    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        createUpdateModal: {
          show: true,
          data,
          type: key,
          index
        }
      }
    });
  }
  hideQuestionCreateUpdateModal = () => {
    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        createUpdateModal: {
          show: false,
          data: null,
          type: '',
          index: 0
        }
      }
    });
  }
  handleQuestionSave = data => {
    const { viewQuiz: { questions, createUpdateModal: { data: questionData } } } = this.state;

    let newQuestions = [...questions.data];
    let nextPageUrl = questions.next_page_url;

    if (questionData) {
      newQuestions = [...questions.data].map(question => {
        if (question.id === data.id && question.type === data.type) {
          if (data.type === 'multipleChoice') {
            data[questionRelationTypeMap[data.type]] = {
              ...data[questionRelationTypeMap[data.type]],
              radio: data[questionRelationTypeMap[data.type]].choices.filter(c => c.is_correct).length === 1
            };
          } else if (data.type === 'matching') {
            data[questionRelationTypeMap[data.type]] = {
              ...data[questionRelationTypeMap[data.type]],
              answers: data[questionRelationTypeMap[data.type]].items.map(i => i.answer)
            };
          }

          data.question = data[questionRelationTypeMap[data.type]];

          return {
            ...question,
            ...data
          };
        }
  
        return question;
      });
    } else {
      if (questions.data.length === 0 || questions.data.length % 10 !== 0) {
        if (data.type === 'multipleChoice') {
          data[questionRelationTypeMap[data.type]] = {
            ...data[questionRelationTypeMap[data.type]],
            radio: data[questionRelationTypeMap[data.type]].choices.filter(c => c.is_correct).length === 1
          };
        } else if (data.type === 'matching') {
          data[questionRelationTypeMap[data.type]] = {
            ...data[questionRelationTypeMap[data.type]],
            answers: data[questionRelationTypeMap[data.type]].items.map(i => i.answer)
          };
        }
        newQuestions = [...questions.data, data];
      } else {
        if (!nextPageUrl) {
          nextPageUrl = `${questions.path}?page=${questions.last_page+1}`;
        }
      }
    }

    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        questions: {
          ...this.state.viewQuiz.questions,
          next_page_url: nextPageUrl,
          data: newQuestions
        },
        createUpdateModal: {
          show: false,
          data: null,
          type: '',
          index: 0
        }
      }
    });
  }
  showQuestionDeleteModal = (data, index) => {
    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        deleteModal: {
          show: true,
          index,
          data,
          isLoading: false,
          errorMessage: ''
        }
      }
    });
  }
  hideQuestionDeleteModal = () => {
    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        deleteModal: {
          show: true,
          index: 0,
          data: null,
          isLoading: false,
          errorMessage: ''
        }
      }
    });
  }
  handleQuestionDelete = () => {
    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        deleteModal: {
          ...this.state.viewQuiz.deleteModal,
          isLoading: true,
          errorMessage: ''
        }
      }
    }, () => {
      const { viewQuiz: { questions, deleteModal, quiz } } = this.state;
      axiosRequest('delete', `faculty/materials/quizzes/${quiz.id}/questions/${deleteModal.data.id}`, null, ({ data: { data }}) => {
        const newQuestions = [...this.state.viewQuiz.questions.data].filter(question => question.id !== deleteModal.data.id);
        let lastPage = questions.last_page;
        if (newQuestions.length > 0 && newQuestions.length % 10 === 0) {
          lastPage = lastPage-1;
        }
        this.setState({
          ...this.state,
          viewQuiz: {
            ...this.state.viewQuiz,
            questions: {
              ...this.state.viewQuiz.questions,
              last_page: lastPage,
              data: newQuestions
            },
            deleteModal: {
              show: false,
              index: 0,
              data: null,
              isLoading: false,
              errorMessage: ''
            }
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          viewQuiz: {
            ...this.state.viewQuiz,
            deleteModal: {
              ...this.state.viewQuiz.deleteModal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          }
        });
      }, this.props.history);
    });
  }
  handleQuestionNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        isNextPageLoading: true
      }
    }, () => {
      const { viewQuiz: { questions } } = this.state;

      const path = questions.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        let newQuestions = data.data.map(q => {
          if (q.type === 'multipleChoice') {
            q[questionRelationTypeMap[q.type]] = {
              ...q[questionRelationTypeMap[q.type]],
              radio: q[questionRelationTypeMap[q.type]].choices.filter(c => c.is_correct).length === 1
            };
          } else if (q.type === 'matching') {
            q[questionRelationTypeMap[q.type]] = {
              ...q[questionRelationTypeMap[q.type]],
              answers: q[questionRelationTypeMap[q.type]].items.map(i => i.answer)
            };
          }

          q.question = q[questionRelationTypeMap[q.type]];
  
          return q;
        });
        this.setState({
          ...this.state,
          viewQuiz: {
            ...this.state.viewQuiz,
            questions: {
              ...data,
              data: [
                ...this.state.viewQuiz.questions.data,
                ...newQuestions
              ]
            },
            isNextPageLoading: false,
            nextPageError: ''
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          viewQuiz: {
            ...this.state.viewQuiz,
            isNextPageLoading: false,
            nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handlePreviewStart = () => {
    const { viewQuiz: { quiz } } = this.state;

    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        preview: {
          ...this.state.viewQuiz.preview,
          current: 0,
          timer: quiz.time_limit
        }
      }
    }, this.startTimer);
  }
  startTimer = () => {
    const { viewQuiz: { quiz } } = this.state;

    if (quiz.time_limit) {
      this.timerInterval = setInterval(() => {
        const { viewQuiz: { preview: { timer }} } = this.state;
        let currentTime = timer-1;

        if (currentTime < 0) {
          this.setState({
            ...this.state,
            viewQuiz: {
              ...this.state.viewQuiz,
              preview: {
                ...this.state.viewQuiz.preview,
                current: 'intro',
                timer: false
              }
            }
          }, () => {
            clearInterval(this.timerInterval);
          });
        } else {
          this.setState({
            ...this.state,
            viewQuiz: {
              ...this.state.viewQuiz,
              preview: {
                ...this.state.viewQuiz.preview,
                timer: timer-1
              }
            }
          });
        }
      }, 60000);
    }
  }
  handlePreviewAnswer = (data, immediate = false, callback = () => {}) => {
    const { viewQuiz: { questions, preview: { current } } } = this.state;
    
    let answer = null;
    switch(data.type) {
      case 'trueFalse':
        answer = {
          answer_boolean: {
            answer: data.answer === 't'
          }
        };
        break;
      case 'multipleChoice':
        let multipleChoice = Array.isArray(data.answer) ? data.answer.map(answer => {
          return {
            question_multiple_choice_choice_id: answer
          };
        }) : [{
          question_multiple_choice_choice_id: data.answer
        }];
        answer = {
          answer_multiple_choice: multipleChoice
        };
        break;
      case 'ordering':
        let ordering = data.answer.map(answer => {
          return {
            question_ordering_item_id: answer
          };
        });
        answer = {
          answer_ordering: ordering
        };
        break;
      case 'essay':
        answer = {
          answer_essay: {
            answer: data.answer
          }
        };
        break;
      case 'matching':
        let matching = data.answer.map(answer => {
          return {
            question_matching_item_id: answer.itemID,
            answer: answer.answer
          };
        });
        answer = {
          answer_matching: matching
        };
        break;
      case 'identification':
        let identification = data.answer.map(answer => {
          return {
            question_identification_item_id: answer.itemID,
            answer: answer.answer
          };
        });
        answer = {
          answer_identification: identification
        };
        break;
      case 'enumeration':
        let enumeration = data.answer.map(answer => {
          return {
            answer
          };
        });
        answer = {
          answer_enumeration: enumeration
        };
        break;
      default:
        answer = null;
        break;
    }

    const newQuestionsData = [...questions.data].map((question, index) => {
      if (index === current) {
        if (question.type === 'essay') {
          answer.files = question.answer && question.answer.files ? question.answer.files : [];
        }
        
        question.answer = answer;
      }

      return question;
    });

    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        questions: {
          ...this.state.viewQuiz.questions,
          data: newQuestionsData
        }
      }
    }, callback);
  }
  addEssayAttachments = attachments => {
    const { viewQuiz: { questions, preview: { current } } } = this.state;

    const newQuestionsData = [...questions.data].map((question, index) => {
      if (index === current && question.type === 'essay') {
        question.answer = {
          answer_essay: question.answer && question.answer.answer_essay ? question.answer.answer_essay : { answer: '' },
          files: question.answer && question.answer.files ? [...question.answer.files, ...attachments] : []
        };
      }

      return question;
    });

    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        questions: {
          ...this.state.viewQuiz.questions,
          data: newQuestionsData
        }
      }
    });
  }
  removeEssayAttachment = (file, fileID)  => {
    const { viewQuiz: { questions, preview: { current } } } = this.state;
    
    const newQuestionsData = [...questions.data].map((question, i) => {
      if (i === current && question.type === 'essay' && question.answer) {
        question.answer.files = [...question.answer.files].filter(f => {
          return f.id !== fileID;
        });
      }

      return question;
    });

    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        questions: {
          ...this.state.viewQuiz.questions,
          data: newQuestionsData
        }
      }
    });
  }
  handlePreviewNext = () => {
    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        preview: {
          ...this.state.viewQuiz.preview,
          current: this.state.viewQuiz.preview.current+1
        }
      }
    }, () => {
      const { viewQuiz: { questions, preview: { current } } } = this.state;
      if (current === questions.data.length && questions.next_page_url) {
        this.setState({
          ...this.state,
          viewQuiz: {
            ...this.state.viewQuiz,
            isNextPageLoading: true,
            nextPageError: ''
          }
        }, () => {
          const path = questions.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
          axiosRequest('get', path, null, ({ data: { data }}) => {
            let newQuestions = data.data.map(q => {
              if (q.type === 'multipleChoice') {
                q[questionRelationTypeMap[q.type]] = {
                  ...q[questionRelationTypeMap[q.type]],
                  radio: q[questionRelationTypeMap[q.type]].choices.filter(c => c.is_correct).length === 1
                };
              } else if (q.type === 'matching') {
                q[questionRelationTypeMap[q.type]] = {
                  ...q[questionRelationTypeMap[q.type]],
                  answers: q[questionRelationTypeMap[q.type]].items.map(i => i.answer)
                };
              }

              q.question = q[questionRelationTypeMap[q.type]];
      
              return q;
            });
            this.setState({
              ...this.state,
              viewQuiz: {
                ...this.state.viewQuiz,
                questions: {
                  ...data,
                  data: [
                    ...this.state.viewQuiz.questions.data,
                    ...newQuestions
                  ]
                },
                isNextPageLoading: false,
                nextPageError: ''
              }
            });
          }, (error) => {
            this.setState({
              ...this.state,
              viewQuiz: {
                ...this.state.viewQuiz,
                isNextPageLoading: false,
                nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
              }
            });
          }, this.props.history);
        });
      }
    });
  }
  handlePreviewPrev = () => {
    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        preview: {
          ...this.state.viewQuiz.preview,
          current: this.state.viewQuiz.preview.current-1
        }
      }
    });
  }
  handlePreviewReview = () => {
    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        preview: {
          ...this.state.viewQuiz.preview,
          current: 'review',
          alreadyReviewed: true
        }
      }
    });
  }
  handlePreviewJump = current => {
    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        preview: {
          ...this.state.viewQuiz.preview,
          current
        }
      }
    });
  }
  handlePreviewFinish = () => {
    this.setState({
      ...this.state,
      viewQuiz: {
        ...this.state.viewQuiz,
        preview: {
          ...this.state.viewQuiz.preview,
          current: 'intro'
        }
      }
    });
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { data } = this.props;

      const path = data.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false
        }, () => {
          this.props.onNextPage(data, 'quizzes');
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  renderAdditionalParams = facultyLoad => {
    const { createUpdateModal: { formInputs, isLoading } } = this.state;
    const { asyncSchedules } = this.props;

    let formInputValues = formInputs.audience.find(a => a.facultyLoadId === facultyLoad.id);

    if (!formInputValues) {
      return null;
    }

    let asyncSchedule = asyncSchedules.find(as => as.classID === facultyLoad.id);

    return (
      <div className='mt-3 px-3'>
        <Form.Group>
          <Form.Control name='gradeCategoryId' onChange={e => this.handleAudienceParamsChange(e, facultyLoad.id)} value={formInputValues.gradeCategoryId} as='select' disabled={isLoading}>
            <option value='' selected hidden>Select grade category...</option>
            {
              facultyLoad.grade_categories.map(gc => (
                <option key={gc.id} value={gc.id}>{gc.name}</option>
              ))
            }
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Check id={`isAsync-${facultyLoad.id}`} type='switch' label='Asynchronous' checked={formInputValues.isAsync} onChange={e => this.handleAsyncInputChange(e, facultyLoad.id)} disabled={isLoading} />
          {
            (formInputValues.isAsync && asyncSchedule && asyncSchedule.schedules.length > 0) && (
              <div className='px-3 pt-2'>
                <InputGroup>
                  <InputGroup.Prepend>
                    <Button className='border' variant='light' onClick={e => this.handlePrevAsync(facultyLoad.id)} disabled={isLoading}>
                      <FontAwesomeIcon icon='chevron-left' />
                    </Button>
                  </InputGroup.Prepend>
                  <Form.Control
                    className='text-center'
                    value={formInputValues.asyncDate ? 
                      `${moment(formInputValues.asyncDate.date).format('MMMM D, YYYY (ddd)')} ${moment(formInputValues.asyncDate.start_time, 'HH:mm').format('hh:mm A')}-${moment(formInputValues.asyncDate.end_time, 'HH:mm').format('hh:mm A')}` : 
                      null}
                    type='text'
                    disabled={isLoading}
                    readOnly />
                  <InputGroup.Append>
                    <Button className='border' variant='light' onClick={e => this.handleNextAsync(facultyLoad.id)} disabled={isLoading}>
                      <FontAwesomeIcon icon='chevron-right' />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                <Form.Text className='text-muted text-center'>Asynchronous schedule to which the material will be tagged to.</Form.Text>
              </div>
            )
          }
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>From</Form.Label>
            <Form.Control type='datetime-local' name='from' min={moment().format('YYYY-MM-DDTHH:mm')} max={formInputValues.until} value={formInputValues.from} onChange={e => this.handleAudienceParamsChange(e, facultyLoad.id)} disabled={isLoading} />
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>Until</Form.Label>
            <Form.Control type='datetime-local' name='until' min={formInputValues.from} value={formInputValues.until} onChange={e => this.handleAudienceParamsChange(e, facultyLoad.id)} disabled={isLoading} />
          </Form.Group>
        </Form.Row>
      </div>
    );
  }
  renderAudience = () => {
    const { createUpdateModal: { formInputs: { audience }, isLoading } } = this.state;
    const { audiences } = this.props;

    return (
      <Form.Group>
        <Form.Label>
          Audience
        </Form.Label>
        <div className='ml-3'>
        {
          audiences.map(a => (
            <div key={a.id}>
              <Form.Group controlId={`audience${a.id}`}>
                <Form.Check
                  type='checkbox'
                  name='audience'
                  label={`${a.class_course.school_class.program.code} ${a.class_course.school_class.year_level.code} - ${a.class_course.school_class.section.code} - ${a.class_course.course.code}`}
                  value={a.id}
                  checked={audience.find(a2 => a2.facultyLoadId === a.id)}
                  onChange={this.handleAudienceChange}
                  disabled={isLoading} />
                {this.renderAdditionalParams(a)}
              </Form.Group>
            </div>
          ))
        }
        </div>
      </Form.Group>
    );
  }
  renderCreateUpdateModal = () => {
    const { show, quiz, formInputs, isLoading, errorMessage, successMessage } = this.state.createUpdateModal;

    return (
      <Modal show={show} backdrop='static' onHide={this.hideCreateUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {
              quiz ? 'Update quiz' : 'Create a quiz'
            }
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleSave}>
          <Modal.Body>
            {
              errorMessage ? (
                <Alert variant='danger'>
                  {errorMessage}
                </Alert>
              ) : successMessage ? (
                <Alert variant='success'>
                  {successMessage}
                </Alert>
              ) : null
            }
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type='text' name='title' value={formInputs.title} onChange={this.handleInputChange} disabled={isLoading} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <RichTextEditor.Editor
                value={formInputs.description}
                onChange={this.handleDescriptionInputChange}
                readOnly={isLoading} />
              {/* <Form.Control as='textarea' rows='4' style={{ resize: 'none' }} name='description' value={formInputs.description} onChange={this.handleInputChange} disabled={isLoading} /> */}
            </Form.Group>
            <Form.Group>
              <Form.Label>Time Limit (minutes)</Form.Label>
              <Form.Control type='number' name='timeLimit' min='1' placeholder='e.g. 30' value={formInputs.timeLimit} onChange={this.handleInputChange} disabled={!formInputs.checkTimeLimit || isLoading} />
              <Form.Group as='div' controlId='checkTimeLimit'>
                <Form.Check type='checkbox' label='Set time limit' checked={formInputs.checkTimeLimit} onChange={this.handleCheckTimeLimit} disabled={isLoading} />
              </Form.Group>
            </Form.Group>
            {this.renderAudience()}
          </Modal.Body>
          <Modal.Footer>
            {
              successMessage ? (
                <Button variant='light' onClick={this.hideCreateUpdateModal}>
                  Close
                </Button>
              ) : (
                <>
                  <Button variant='danger mr-2' onClick={this.hideCreateUpdateModal} disabled={isLoading}>
                    Cancel
                  </Button>
                  <Button variant='green' type='submit' disabled={isLoading}>
                    Save
                  </Button>
                </>
              )
            }
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
  renderDeleteModal = () => {
    const { deleteModal } = this.state;

    let renderAudience = audience => {
      const { audiences } = this.props;

      let tempAudience = audiences.find(a => a.id === audience.faculty_load_id);
      if (!tempAudience) {
        return null;
      }

      return (
        <div key={audience.id} className='mb-1'>
          {`${tempAudience.class_course.school_class.program.code} ${tempAudience.class_course.school_class.year_level.code}-${tempAudience.class_course.school_class.section.code} - ${tempAudience.class_course.course.code}`}
        </div>
      );
    };

    return (
      <PromptDeleteModal {...deleteModal} title='Delete quiz' onHide={this.hideDeleteModal} onDelete={this.handleDelete}>
        {
          !deleteModal.successMessage && (
            <div className='font-weight-bold'>
              <div>Are you sure you want to delete the quiz?</div>
              <div>Students' submissions will also be deleted.</div>
            </div>
          )
        }
        {
          deleteModal.quiz && (
            <Alert variant='light'>
              <div>{deleteModal.quiz.title}</div>
              <div className='d-md-flex'>
                <div className='mr-1'>
                  <FontAwesomeIcon icon='users' /> Audience(s):
                </div>
                <div>
                  {
                    deleteModal.quiz.audiences.map(renderAudience)
                  }
                </div>
              </div>
            </Alert>
          )
        }
      </PromptDeleteModal>
    );
  }
  renderPreview = () => {
    const { viewQuiz: { quiz, questions, preview, isNextPageLoading, nextPageError } } = this.state;

    let renderTimeLimit = q => {
      if (q.time_limit) {
        let duration = moment.duration(q.time_limit, 'minutes');
        let hours = duration.hours();
        let minutes = duration.minutes();
        let display = hours && minutes ? `${hours} hour${hours > 1 ? 's' : ''} and ${minutes} minute${minutes > 1 ? 's' : ''}` :
                      hours ? `${hours} hour${hours > 1 ? 's' : ''}` : 
                      minutes ? `${minutes} minute${minutes > 1 ? 's' : ''}` : duration.humanize();
        return (
          <div className='mt-1'>
            <FontAwesomeIcon icon='stopwatch' /> You have at least <b>{display} ({q.time_limit}m)</b> to finish the quiz.
          </div>
        );
      }
  
      return (
        <div className='mt-1 text-muted'>
          No time limit was set.
        </div>
      );
    }

    let renderIntro = () => {
      return (
        <>
          <div className='h5'>
            {quiz.title}
          </div>
          <RichTextEditor.Viewer body={quiz.description} />
          {/* <div style={{ whiteSpace: 'pre-line' }}>
            {quiz.description}
          </div> */}
          <div className='mt-3'>
            <span className='pr-1'>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    <div>
                      Submissions <b>AFTER</b> the given duration will be <b>SAVED</b> but will be marked as <b>LATE</b>.
                    </div>
                  </Tooltip>
                }
                trigger={['hover', 'focus']}>
                <FontAwesomeIcon icon='info-circle' className='info-icon text-primary' />
              </OverlayTrigger>
            </span>
            <span className='text-muted font-italic'>
              (Availability)
            </span>
          </div>
          { renderTimeLimit(quiz) }
          <div className='dropdown-divider'></div>
          <div>
            You have made 0 of 3 attempts. You have 3 attempts remaining.
            <Col md={6} className='mt-2'>
              <ProgressBar now={0} label={'0 of 3'} />
            </Col>
          </div>
          {
            questions.data.length === 0 ? (
              <div className='mt-2'>
                <Alert variant='danger'>
                  Questions are not available.
                </Alert>
              </div>
            ) : (
              <div className='mt-2'>
                <Button variant='green' onClick={this.handlePreviewStart}>
                  Start a new attempt
                </Button>
              </div>
            )
          }
        </>
      );
    }

    let renderTimer = () => {
      if (quiz.time_limit) {
        let duration = moment.duration(preview.timer, 'minutes');
        let hours = duration.hours()+'';
        let minutes = duration.minutes()+'';
        return (
          <div className={`text-right font-weight-bold mb-3 mb-md-0 ${preview.timer <= 10 ? 'text-danger' : ''}`}>
            <FontAwesomeIcon icon='clock' className={`timer ${preview.timer > 10 ? '' : (preview.timer <= 5 ? 'shake fast' : 'shake slow')}`} /> Time remaining: {`${hours.length > 1 ? hours : (`0${hours}`)}:${minutes.length > 1 ? minutes : (`0${minutes}`)}`}
          </div>
        );
      }
    }

    let renderQuestion = () => {
      let question = questions.data[preview.current];

      if (!question) {
        return null;
      }

      let questionProps = {
        ...question,
        quiz,
        url: `faculty/materials/quizzes/${quiz.id}/questions`,
        onChange: this.handlePreviewAnswer
      };

      switch (question.type) {
        case 'trueFalse':
          return <ViewTrueFalse {...questionProps} />;
        case 'multipleChoice':
          return <ViewMultipleChoice {...questionProps} />;
        case 'ordering':
          return <ViewOrdering {...questionProps} />;
        case 'essay':
          return <ViewEssay {...questionProps} onAddAttachments={this.addEssayAttachments} onRemoveAttachment={this.removeEssayAttachment} />;
        case 'matching':
          return <ViewMatching {...questionProps} />;
        case 'identification':
          return <ViewIdentification {...questionProps} />;
        case 'enumeration':
          return <ViewEnumeration {...questionProps} />;
        default:
          return null;
      }
    }

    let renderAnswer = (question, answer, type) => {
      switch(type) {
        case 'trueFalse':
          return answer.answer_boolean ? (answer.answer_boolean.answer ? 'True' : 'False') : (
            <>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </>
          );
        case 'multipleChoice':
          if (!answer.answer_multiple_choice || answer.answer_multiple_choice.length === 0) {
            return (
              <>
                <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
              </>
            );
          }
  
          if (question.radio) {
            return (question.choices.find(choice => choice.id === +answer.answer_multiple_choice[0].question_multiple_choice_choice_id)).description;
          } else {
            return answer.answer_multiple_choice.map((answer, index) => (
              <div key={index}>{(question.choices.find(choice => choice.id === answer.question_multiple_choice_choice_id)).description}</div>
            ))
          }
        case 'ordering':
          if (!answer.answer_ordering || answer.answer_ordering.length === 0) {
            return (
              <>
                <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
              </>
            );
          }
  
          return answer.answer_ordering.map((answer, index) => (
            <div key={index}>{(question.items.find(item => item.id === answer.question_ordering_item_id)).description}</div>
          ));
        case 'essay':
          if ((!answer.answer_essay || !answer.answer_essay.answer) && (!answer.files || answer.files.length === 0)) {
            return (
              <>
                <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
              </>
            );
          }
          return (
            <>
              {
                answer.answer_essay && (
                  <div>
                    {answer.answer_essay.answer}
                  </div>
                )
              }
              {
                (answer.files && answer.files.length > 0) && (
                  <div>
                    <FontAwesomeIcon icon='paperclip' /> {answer.files.length} attachment{answer.files.length !== 1 ? 's' : ''}
                  </div>
                )
              }
            </>
          );
        case 'matching':
          if (!answer.answer_matching || answer.answer_matching.length === 0) {
            return (
              <>
                <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
              </>
            );
          }
  
          return answer.answer_matching.map(({question_matching_item_id, answer}, index) => (
            <div key={index}>
              <div>{(question.items.find(item => item.id === question_matching_item_id)).description}</div>
              <div className='pl-1 d-flex'>
                <div className='mr-1'>
                  <FontAwesomeIcon icon='long-arrow-alt-right' />
                </div>
                <div className='flex-fill'>
                  {answer}
                </div>
              </div>
            </div>
          ));
        case 'identification':
          if (!answer.answer_identification || answer.answer_identification.length === 0) {
            return (
              <>
                <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
              </>
            );
          }

          return answer.answer_identification.map(({question_identification_item_id, answer}, index) => (
            <div key={index}>
              <div>{answer}</div>
              <div className='pl-1 d-flex'>
                <div className='mr-1'>&mdash;</div>
                <div className='flex-fill'>
                  {(question.items.find(item => item.id === question_identification_item_id)).description}
                </div>  
              </div>
            </div>
          ));
        case 'enumeration':
          if (!answer.answer_enumeration || answer.answer_enumeration.length === 0 || !answer.answer_enumeration.find(a => a.answer !== '')) {
            return (
              <>
                <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
              </>
            );
          }

          return answer.answer_enumeration.map(({answer}, index) => (
            <div key={index}>
              {answer}
            </div>
          ));
        default:
          return null;
      }
    }
    
    let renderReview = () => {
      return (
        <div className='p-1 p-md-3'>
          {renderTimer()}
          <div className='review-answers'>
          {
            questions.data.map(({question, answer, type}, index) => (
              <div key={index} className='d-flex py-3'>
                <div className='h5 pr-1 pr-md-2'>{index+1}</div>
                <div className='flex-fill'>
                  <RichTextEditor.Viewer body={question.description} />
                  {/* <div>{question.description}</div> */}
                  <div className='p-1 p-md-2 alert-light'>
                  {
                    answer ? renderAnswer(question, answer, type) : (
                      <>
                        <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
                      </>
                    )
                  }
                  </div>
                </div>
                <div className='pl-1 pl-md-2'>
                  <Button variant='info' size='sm' onClick={() => this.handlePreviewJump(index)}>
                    <FontAwesomeIcon icon='redo' />
                    <span className='d-none d-md-inline-block ml-1'>Return to page</span>
                  </Button>
                </div>
              </div>
            ))
          }
          </div>
          <div className='text-right'>
            <Button variant='green' onClick={this.handlePreviewFinish}>
              Finish
            </Button>
          </div>
        </div>
      );
    }

    if (isNextPageLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (nextPageError) {
      return (
        <Alert variant='danger'>
          {nextPageError}
        </Alert>
      );
    }

    return (
      <div>
        {
          preview.current === 'intro' ? renderIntro() : preview.current === 'review' ? renderReview() : (
            <>
              <div className='p-md-5'>
                {renderTimer()}
                <div className='d-flex'>
                  <div className='h4'>
                    {preview.current+1}
                  </div>
                  <div className='flex-fill'>
                    {renderQuestion()}
                  </div>
                </div>
                <div className='d-none d-md-block text-right'>
                  {
                    preview.current !== 0 && (
                      <Button variant='green' className='mr-2' onClick={this.handlePreviewPrev}>
                        Previous
                      </Button>
                    )
                  }
                  {
                    (preview.current !== questions.data.length -1 || questions.next_page_url || (preview.alreadyReviewed && preview.current !== questions.data.length - 1)) && (
                      <Button variant='green' className='mr-2' onClick={this.handlePreviewNext}>
                        Next
                      </Button>
                    )
                  }
                  {
                    ((preview.current === questions.data.length - 1 && !questions.next_page_url) || preview.alreadyReviewed) && (
                      <Button variant='green' onClick={this.handlePreviewReview}>
                        Review answers
                      </Button>
                    )
                  }
                </div>
                <div className='d-md-none'>
                  <div className='mb-1 d-flex justify-content-end'>
                    {
                      preview.current !== 0 && (
                        <div className='w-50 px-1'>
                          <Button variant='green' className='mr-2' onClick={this.handlePreviewPrev} title='Previous' block>
                            <FontAwesomeIcon icon='chevron-left' />
                          </Button>
                        </div>
                      )
                    }
                    {
                      (preview.current !== questions.data.length -1 || questions.next_page_url || (preview.alreadyReviewed && preview.current !== questions.data.length - 1)) && (
                        <div className='w-50 px-1'>
                          <Button variant='green' className='mr-2' onClick={this.handlePreviewNext} title='Next' block>
                            <FontAwesomeIcon icon='chevron-right' />
                          </Button>
                        </div>
                      )
                    }
                  </div>
                  <div className='px-1'>
                    {
                      ((preview.current === questions.data.length - 1 && !questions.next_page_url) || preview.alreadyReviewed) && (
                        <Button variant='green' onClick={this.handlePreviewReview} block>
                          Review answers
                        </Button>
                      )
                    }
                  </div>
                </div>
              </div>
            </>
          )
        }
      </div>
    )
  }
  renderQuestionCreateUpdateModal = () => {
    const { viewQuiz: { quiz, createUpdateModal } } = this.state;
    const questionProps = {
      ...createUpdateModal,
      onCancel: this.hideQuestionCreateUpdateModal,
      onSave: this.handleQuestionSave,
      history: this.props.history,
      url: `faculty/materials/quizzes/${quiz.id}/questions`
    };

    switch(createUpdateModal.type) {
      case 'trueFalse':
        return (
          <TrueFalse {...questionProps} />
        );
      case 'multipleChoice':
        return (
          <MultipleChoice {...questionProps} />
        );
      case 'ordering':
        return (
          <Ordering {...questionProps} />
        );
      case 'essay':
        return (
          <Essay {...questionProps} />
        );
      case 'matching':
        return (
          <Matching {...questionProps} />
        );
      case 'identification':
        return (
          <Identification {...questionProps} />
        );
      case 'enumeration':
        return (
          <Enumeration {...questionProps} />
        );
      default:
        return null;
    }
  }
  renderQuestions = () => {
    const { viewQuiz: { questions, deleteModal, isNextPageLoading, nextPageError } } = this.state;

    return (
      <>
        <div className='text-right'>
          <Dropdown onSelect={this.showQuestionCreateUpdateModal}>
            <Dropdown.Toggle variant='green'>
              <FontAwesomeIcon icon='plus' /> Add Question
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey='enumeration'>Enumeration</Dropdown.Item>
              <Dropdown.Item eventKey='essay'>Essay/Problem Solving</Dropdown.Item>
              <Dropdown.Item eventKey='identification'>Identification</Dropdown.Item>
              <Dropdown.Item eventKey='matching'>Matching</Dropdown.Item>
              <Dropdown.Item eventKey='multipleChoice'>Multiple Choice</Dropdown.Item>
              <Dropdown.Item eventKey='ordering'>Ordering</Dropdown.Item>
              <Dropdown.Item eventKey='trueFalse'>True/False</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='dropdown-divider'></div>
        {
          questions.data.length > 0 ? (
            <div className='question-list'>
              {
                questions.data.map((question, index) => (
                  <div key={index} className='d-flex'>
                    <div className='pl-1 pr-3 h5'>{index+1}</div>
                    <div className='flex-fill'>
                      <RichTextEditor.Viewer body={question.question.description} />
                      {/* <div style={{ whiteSpace: 'pre-wrap' }}>{question.question.description}</div> */}
                      <div className='text-muted'>{questionTypeMap[question.type]}</div>
                    </div>
                    <div className='d-md-none'>
                      <Dropdown>
                        <Dropdown.Toggle as='span' className='text-green'>
                          <FontAwesomeIcon icon='cog' />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => this.showQuestionCreateUpdateModal(question.type, index+1, question)}>
                            <FontAwesomeIcon icon='pencil-alt' /> Edit
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => this.showQuestionDeleteModal(question, index+1)}>
                            <FontAwesomeIcon icon='trash-alt' /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className='d-none d-md-block'>
                      <Button variant='info' size='sm' className='mr-2' onClick={() => this.showQuestionCreateUpdateModal(question.type, index+1, question)}>
                        <FontAwesomeIcon icon='pencil-alt' />
                      </Button>
                      <Button variant='danger' size='sm' onClick={() => this.showQuestionDeleteModal(question, index+1)}>
                        <FontAwesomeIcon icon='trash-alt' />
                      </Button>
                    </div>
                  </div>
                ))
              }
            </div>
          ) : (
            <Alert variant='light'>
              Start adding a question.
            </Alert>
          )
        }
        {
          questions.next_page_url && (
            <div className='text-center mt-3'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleQuestionNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                View more questions
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          )
        }
        {this.renderQuestionCreateUpdateModal()}
        <PromptDeleteModal
          {...deleteModal}
          title={`Delete question ${deleteModal.index}`}
          onHide={this.hideQuestionDeleteModal}
          onDelete={this.handleQuestionDelete}>
            <div className='font-weight-bold'>
              <div>Are you sure you want to delete the question?</div>
              <div>Students' answers on this question will also be deleted.</div>
            </div>
            {
              deleteModal.data && (
                <Alert variant='light'>
                  <RichTextEditor.Viewer body={deleteModal.data.question.description} />
                  {/* <div>{deleteModal.data.question.description}</div> */}
                  <div>{questionTypeMap[deleteModal.data.type]}</div>
                </Alert>
              )
            }
        </PromptDeleteModal>
      </>
    )
  }
  renderViewQuiz = () => {
    const { viewQuiz: { isLoading, errorMessage, quiz, activeNav } } = this.state;

    if (!quiz) {
      return null;
    }

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.hideViewQuiz}>Quizzes</Breadcrumb.Item>
          <Breadcrumb.Item active>{quiz.title}</Breadcrumb.Item>
        </Breadcrumb>
        {
          isLoading ? (
            <LoadingIcon />
          ) : errorMessage ? (
            <Alert variant='danger'>
              {errorMessage}
            </Alert>
          ) : (
            <div>
              <Nav variant='tabs bg-light rounded-top' className='flex-column flex-md-row mx-0 mb-0 border-bottom'>
                <Nav.Item>
                  <Nav.Link eventKey='preview' onSelect={this.handleViewSelect} active={activeNav === 'preview'}>Preview</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='questions' onSelect={this.handleViewSelect} active={activeNav === 'questions'}>Questions</Nav.Link>
                </Nav.Item>
              </Nav>
              <div className='content border border-top-0 rounded-bottom p-3'>
                {
                  activeNav === 'preview' ? this.renderPreview() : this.renderQuestions()
                }
              </div>
            </div>
          )
        }
      </>
    );
  }
  renderAudiencesDisplay = (audience, material) => {
    const { audiences } = this.props;

    let tempAudience = audiences.find(a => a.id === audience.faculty_load_id);
    if (!tempAudience) {
      return null;
    }

    let tempGradeCategory = tempAudience.grade_categories.find(gc => gc.id === audience.grade_category_id);
    let tempMaterialAsyncSchedule = material.material_async_schedules.find(mas => mas.faculty_load_id === audience.faculty_load_id);

    return (
      <div key={audience.id} className='mb-1'>
        <div className='font-weight-bold'>
          {`${tempAudience.class_course.school_class.program.code} ${tempAudience.class_course.school_class.year_level.code}-${tempAudience.class_course.school_class.section.code} - ${tempAudience.class_course.course.code}`}
        </div>
        <div className='px-1 px-md-3'>
          {
            moment().isBefore(audience.from) && (
              <span className='text-muted font-weight-normal font-italic'> (Not yet available)</span>
            )
          }
          {
            tempGradeCategory && (
              <div>
                Category: {tempGradeCategory.name}
              </div>
            )
          }
          <div className='text-muted font-italic d-flex'>
            <div className='mr-1'>
              Available
            </div>
            <div>
              <div>
                From: {moment(audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
              </div>
              <div>
                Until: {moment(audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
              </div>
            </div>
          </div>
          {
            tempMaterialAsyncSchedule && (
              <div className='text-muted font-italic d-flex'>
                <div className='mr-1'>
                  {`${material.type === 'exam' ? 'Asynchronous/exam schedule:' : 'Asynchronous schedule:'}`}
                </div>
                <div>
                  <div>
                    {moment(tempMaterialAsyncSchedule.schedule_date).format('ddd, MMMM D, YYYY')}
                  </div>
                  <div>
                    {`${moment(tempMaterialAsyncSchedule.start_time, 'HH:mm').format('hh:mm A')} - ${moment(tempMaterialAsyncSchedule.end_time, 'HH:mm').format('hh:mm A')}`}
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
  renderContent = () => {
    const { isNextPageLoading, nextPageError } = this.state;
    const { data } = this.props;

    if (data.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='material-list'>
          {
            data.data.map(d => (
              <div key={d.id}>
                <div className='d-flex'>
                  <div className='flex-fill'>
                    <div className='btn-link font-weight-bold' onClick={e => this.showViewQuiz(d)}>
                      {d.title}
                    </div>
                    {
                      d.time_limit && (
                        <div className='text-muted'>
                          <FontAwesomeIcon icon='stopwatch' /> {`${d.time_limit} minutes (${d.time_limit}m)`}
                        </div>
                      )
                    }
                    <RichTextEditor.Viewer body={d.description} className='text-muted' />
                    {/* <div className='text-muted'>{d.description}</div> */}
                  </div>
                  <div className='d-flex d-md-none'>
                    <div className='mr-2'>
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            <div>
                              {moment(d.created_at).format('MMM D, YYYY hh:mm A')}
                            </div>
                            <div>
                              {moment(d.created_at).fromNow()}
                            </div>
                          </Tooltip>
                        }
                        trigger={['hover', 'focus']}>
                        <FontAwesomeIcon icon='clock' className='text-muted' />
                      </OverlayTrigger>
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle as='span' className='text-green'>
                        <FontAwesomeIcon icon='cog' />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={e => this.showViewQuiz(d, 'questions')}>
                          <span className='fa-layers fa-fw'>
                            <FontAwesomeIcon icon='question' transform='right-2' />
                            <FontAwesomeIcon icon='plus' transform='shrink-6 left-6 down-4' />
                          </span> Questions
                        </Dropdown.Item>
                        <Dropdown.Item onClick={e => this.showCreateUpdateModal(e, d)}>
                          <FontAwesomeIcon icon='pencil-alt' /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => this.showDeleteModal(d)}>
                          <FontAwesomeIcon icon='trash-alt' /> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className='font-small text-muted font-italic pr-3 d-none d-md-block'>
                    &mdash; <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {moment(d.created_at).format('MMM D, YYYY hh:mm A')}
                                </Tooltip>
                              }
                              trigger={['hover', 'focus']}>
                              <FontAwesomeIcon icon='clock' />
                            </OverlayTrigger> {moment(d.created_at).fromNow()}
                  </div>
                  <div className='d-none d-md-block' style={{ whiteSpace: 'nowrap' }}>
                    <Button variant='primary' size='sm' className='mr-1' title='Questions' onClick={e => this.showViewQuiz(d, 'questions')}>
                      <span className='fa-layers fa-fw'>
                        <FontAwesomeIcon icon='question' transform='right-2' />
                        <FontAwesomeIcon icon='plus' transform='shrink-6 left-6 down-4' />
                      </span>
                    </Button>
                    <Button variant='info' size='sm' className='mr-1' onClick={e => this.showCreateUpdateModal(e, d)}>
                      <FontAwesomeIcon icon='pencil-alt' />
                    </Button>
                    <Button variant='danger' size='sm' onClick={() => this.showDeleteModal(d)}>
                      <FontAwesomeIcon icon='trash-alt' />
                    </Button>
                  </div>
                </div>
                <div className='text-muted d-md-flex mt-1'>
                  <div className='mr-1'>
                    <FontAwesomeIcon icon='users' /> Audience(s):
                  </div>
                  <div>
                    {
                      d.audiences.map(audience => this.renderAudiencesDisplay(audience, d))
                    }
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        {
          data.next_page_url && (
            <div className='text-center mt-3'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                View more quizzes
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          )
        }
      </>
    );
  }
  render() {
    const { isLoading, errorMessage, viewQuiz } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        {
          viewQuiz.show ? this.renderViewQuiz() : (
            <>
              <div className='text-right mb-3'>
                <Button variant='green' onClick={this.showCreateUpdateModal}>
                  <FontAwesomeIcon icon='plus' /> Create a quiz
                </Button>
              </div>
              {this.renderContent()}
              {this.renderCreateUpdateModal()}
              {this.renderDeleteModal()}
            </>
          )
        }
      </div>
    );
  }
}