import React, { Component } from 'react';
import { Col, Form, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import PropTypes from 'prop-types';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import RichTextEditor from '../../../RichTextEditor/RichTextEditor';

class Identification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: null
    };
  }
  componentDidMount() {
    const { question } = this.props;

    let inputs = {};
    for (let i = 0; i < question.items.length; i++) {
      inputs[question.items[i].id] = '';
    }
    if (this.props.answer && this.props.answer.answer_identification) {
      for (let i = 0; i < this.props.answer.answer_identification.length; i++) {
        inputs[this.props.answer.answer_identification[i].question_identification_item_id] = this.props.answer.answer_identification[i].answer;
      }
    }

    this.setState({
      ...this.state,
      inputs
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      const { question } = this.props;

      let inputs = {};
      for (let i = 0; i < question.items.length; i++) {
        inputs[question.items[i].id] = '';
      }
      if (this.props.answer && this.props.answer.answer_identification) {
        for (let i = 0; i < this.props.answer.answer_identification.length; i++) {
          inputs[this.props.answer.answer_identification[i].question_identification_item_id] = this.props.answer.answer_identification[i].answer;
        }
      }

      this.setState({
        ...this.state,
        inputs
      });
    }
  }
  onChange = () => {
    const { question } = this.props;
    const { inputs } = this.state;

    if (!inputs) {
      return;
    }

    let answer = [];
    for (let i = 0; i < question.items.length; i++) {
      if (inputs[question.items[i].id] && inputs[question.items[i].id] !== '') {
        answer.push({
          itemID: question.items[i].id,
          answer: inputs[question.items[i].id]
        });
      }
    }

    this.props.onChange({
      type: 'identification',
      questionID: this.props.id,
      answer
    });
  }
  handleInputChange = (event, itemID) => {
    this.setState({
      ...this.state,
      inputs: {
        ...this.state.inputs,
        [itemID]: event.target.value
      }
    }, this.onChange);
  }
  handleFileDownload = fileID => {
    const { url, id } = this.props;

    axiosRequest('get', `${url}/${id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handleCopyPaste = e => {
    e.preventDefault();
  }
  handlePreviewImages = (url, id, images, index) => {
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/${url}/${id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  render() {
    const { question, files, url, id, points } = this.props;
    const { inputs } = this.state;

    const questionImages = files.filter(file => {
      return !!file.is_image;
    });

    const questionFiles = files.filter(file => {
      return !file.is_image;
    });

    return (
      <div className='identification'>
        <RichTextEditor.Viewer body={question.description} className='px-2 px-md-3 py-1 user-select-none' />
        {/* <div className='px-2 px-md-3 py-1 user-select-none'>{question.description}</div> */}
        {
          files.length > 0 && (
            <div className='px-2 px-md-3'>
              {
                questionImages.length > 0 && (
                  <Row>
                  {
                    questionImages.map((image, index) => (
                      <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(url, id, questionImages, index)}>
                        <Image src={`${process.env['REACT_APP_API_BASE_URL']}/${url}/${id}/image/${image.id}`} thumbnail />
                      </Col>
                    ))
                  }
                  </Row>
                )
              }
              {
                questionFiles.length > 0 && (
                  <div className='p-1'>
                    {
                      questionFiles.map(file => (
                        <small key={file.id} className='d-flex mb-2'>
                          <div className='mr-2'>
                            <FontAwesomeIcon icon='file' className='text-primary' />
                          </div>
                          <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                            <span className='btn-link' onClick={() => this.handleFileDownload(file.id)}>
                              {file.file_title}
                            </span>
                          </div>
                        </small>
                      ))
                    }
                  </div>
                )
              }
            </div>
          )
        }
        <div className='px-2 px-md-3 text-muted'>
          {
            question.is_case_sensitive && (
              <div>
                <FontAwesomeIcon icon='exclamation' /> Case-sensitive
              </div>
            )
          }
          <div>
            ({points} point{points > 1 ? 's' : ''})
          </div>
        </div>
        {
          (question.show_answers && question.answers && question.answers.length > 0) && (
            <div className='border rounded p-3 mt-3 shadow-sm'>
              <Row>
                {
                  question.answers.map((a, i) => (
                    <Col key={i} lg={3} md={4} sm={6}>
                      {a}
                    </Col>
                  ))
                }
              </Row>
            </div>
          )
        }
        <div className='p-2 p-md-3 identification-items'>
          {
            question.items.map((item, index) => (
              <Row key={index} className='py-3'>
                <Col lg={3} md={4}>
                  <Form.Control type='text' size='sm' value={inputs ? inputs[item.id] : ''} onChange={e => this.handleInputChange(e, item.id)} onPaste={this.handleCopyPaste} onCopy={this.handleCopyPaste} />
                </Col>
                <Col>
                  {item.description}
                </Col>
              </Row>
            ))
          }
        </div>
      </div>
    );
  }
}

Identification.propTypes = {
  onChange: PropTypes.func.isRequired
};

Identification.defaultProps = {
  onChange: () => {}
};

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Identification);