import React, { Component } from 'react';
import { Alert, Button, Col, Form, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../../../../util/helpers/axiosRequest';
import { loadRule } from '../../../../../../../util';
import moment from 'moment';
import Validator from 'validatorjs';
import LoadingIcon from '../../../../../../common/LoadingIcon/LoadingIcon';

const accountTypeMap = {
  1: 'school-admin',
  2: 'faculty',
  3: 'staff'
};

const learningPlatforms = [
  {
    platform: 'Digital',
    icon: (
      <FontAwesomeIcon icon='laptop' className='fa-fw' />
    )
  },
  {
    platform: 'Digital-Modular',
    icon: (
      <span className='fa-layers fa-fw'>
        <FontAwesomeIcon icon='laptop' transform='shrink-3 left-5 up-2' />
        <FontAwesomeIcon icon='book-open' transform='shrink-3 right-3 down-5' />
      </span>
    )
  },
  {
    platform: 'Modular-Printed',
    icon: (
      <FontAwesomeIcon icon='book-open' className='fa-fw' />
    )
  },
];

const marks = [
  'Present',
  'Absent',
  'Late',
  'Excused'
];

export default class Custom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errorMessage: '',
      weekdays: [],
      attendance: [],
      attendanceDisplay: [],
      hasNoSchedule: false,
      formInputs: {
        from: '',
        until: ''
      },
      from: '',
      until: '',
      colCounter: null
    };
  }
  componentDidMount() {
    loadRule('date');
  }
  getAttendance = () => {
    const { attendance, from, until } = this.state;
    const { facultyLoads } = this.props;
    
    if (!from || !until) {
      return;
    }

    let attendanceDisplay = [];
    let hasNoSchedule = false;
    let colCounter = {
      Synchronous: {
        Present: 0,
        Absent: 0,
        Late: 0,
        Excused: 0
      },
      Asynchronous: {
        Present: 0,
        Absent: 0,
        Late: 0,
        Excused: 0
      },
      Exam: {
        Present: 0,
        Absent: 0,
        Late: 0,
        Excused: 0
      },
      Empty: {
        Present: 0,
        Absent: 0,
        Late: 0,
        Excused: 0
      },
      Total: {
        Present: 0,
        Absent: 0,
        Late: 0,
        Excused: 0
      }
    };
    let momentStart = moment(from);
    let momentEnd = moment(until);
    let weekdays = [];
    for (let i = 0; i < facultyLoads.length; i++) {
      let facultyLoad = facultyLoads[i];
      let students = {
        'Digital': [],
        'Digital-Modular': [],
        'Modular-Printed': []
      };

      for (let j = 0; j < facultyLoad.class_course.school_class.student_classes.length; j++) {
        let studentID = facultyLoad.class_course.school_class.student_classes[j].id;
        let learningPlatform = facultyLoad.student_learning_platforms.find(slp => slp.faculty_load_id === facultyLoad.id && slp.student_id === studentID);
        if (learningPlatform) {
          students[learningPlatform.platform].push(studentID);
        } else {
          students['Digital'].push(studentID);
        }
      }

      if (facultyLoad.class_faculty_load && facultyLoad.class_faculty_load.length > 0) {
        for (let j = 0; j < facultyLoad.class_faculty_load.length; j++) {
          for (let k = 0; k < facultyLoad.class_faculty_load[j].school_class.student_classes.length; k++) {
            let studentID = facultyLoad.class_faculty_load[j].school_class.student_classes[k].id;
            let learningPlatform = facultyLoad.student_learning_platforms.find(slp => slp.faculty_load_id === facultyLoad.id && slp.student_id === studentID);
            if (learningPlatform) {
              students[learningPlatform.platform].push(studentID);
            } else {
              students['Digital'].push(studentID);
            }
          }
        }
      }
      if (facultyLoad.student_faculty_load && facultyLoad.student_faculty_load.length > 0) {
        for (let j = 0; j < facultyLoad.student_faculty_load.length; j++) {
          let studentID = facultyLoad.student_faculty_load[j].student_id;
          let learningPlatform = facultyLoad.student_learning_platforms.find(slp => slp.faculty_load_id === facultyLoad.id && slp.student_id === studentID);
          if (learningPlatform) {
            students[learningPlatform.platform].push(studentID);
          } else {
            students['Digital'].push(studentID);
          }
        }
      }

      let rowCounter = {
        Present: 0,
        Absent: 0,
        Late: 0,
        Excused: 0
      };
      let counter = {
        Synchronous: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        },
        Asynchronous: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        },
        Exam: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        },
        Empty: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        }
      };
      let scheduleByScheduleType = {
        Synchronous: {
          weekly: [],
          date: [],
          all: []
        },
        Asynchronous: {
          weekly: [],
          date: [],
          all: []
        },
        Exam: {
          weekly: [],
          date: [],
          all: []
        },
        Empty: {
          weekly: [],
          date: [],
          all: []
        }
      };
      
      let temp = momentStart.clone();
      while(temp.isSameOrBefore(momentEnd, 'day')) {
        let weekday = {
          date: temp.format('YYYY-MM-DD'),
          weekday: temp.format('ddd'),
          weekdayFull: temp.format('dddd'),
          dateDisplayLg: temp.format('dddd, MMM D'),
          dateDisplaySm: temp.format('MMM D')
        };

        let schedule = facultyLoad.date_schedule.find(ds => ds.schedule_date === weekday.date);
        if (!schedule) {
          schedule = facultyLoad.weekly_schedule.find(ws => ws.day === weekday.weekdayFull);
        }

        if (schedule && schedule.type && schedule.type === 'Synchronous') {
          if (schedule.schedule_date) {
            scheduleByScheduleType[schedule.type].date.push({...schedule, weekday});
          } else {
            if (!scheduleByScheduleType[schedule.type].weekly.find(w => w.weekday.weekdayFull === weekday.weekdayFull)) {
              scheduleByScheduleType[schedule.type].weekly.push({...schedule, weekday});
            }
          }
          scheduleByScheduleType[schedule.type].all.push({...schedule, weekday});
          for (let k = 0; k < students['Digital'].length; k++) {
            let studentID = students['Digital'][k];

            let temp = attendance.find(a => a.faculty_load_id === facultyLoad.id && a.attendance_date === weekday.date && a.account_id === studentID);
            if (temp) {
              rowCounter[temp.mark]++;
              counter[schedule.type][temp.mark]++;
            }
          }
        } else if (schedule && schedule.type && schedule.type === 'Asynchronous') {
          if (schedule.schedule_date) {
            scheduleByScheduleType[schedule.type].date.push({...schedule, weekday});
          } else {
            if (!scheduleByScheduleType[schedule.type].weekly.find(w => w.weekday.weekdayFull === weekday.weekdayFull)) {
              scheduleByScheduleType[schedule.type].weekly.push({...schedule, weekday});
            }
          }
          scheduleByScheduleType[schedule.type].all.push({...schedule, weekday});
          let asyncStudents = [...students['Digital'], ...students['Digital-Modular']];
          for (let k = 0; k < asyncStudents.length; k++) {
            let studentID = asyncStudents[k];

            let temp = attendance.find(a => a.faculty_load_id === facultyLoad.id && a.attendance_date === weekday.date && a.account_id === studentID);
            if (temp) {
              rowCounter[temp.mark]++;
              counter[schedule.type][temp.mark]++;
            }
          }
        } else if (schedule && schedule.type && schedule.type === 'Exam') {
          if (schedule.schedule_date) {
            scheduleByScheduleType[schedule.type].date.push({...schedule, weekday});
          } else {
            if (!scheduleByScheduleType[schedule.type].weekly.find(w => w.weekday.weekdayFull === weekday.weekdayFull)) {
              scheduleByScheduleType[schedule.type].weekly.push({...schedule, weekday});
            }
          }
          scheduleByScheduleType[schedule.type].all.push({...schedule, weekday});
          let asyncStudents = [...students['Digital'], ...students['Digital-Modular'], ...students['Modular-Printed']];
          for (let k = 0; k < asyncStudents.length; k++) {
            let studentID = asyncStudents[k];

            let temp = attendance.find(a => a.faculty_load_id === facultyLoad.id && a.attendance_date === weekday.date && a.account_id === studentID);
            if (temp) {
              rowCounter[temp.mark]++;
              counter[schedule.type][temp.mark]++;
            }
          }
        } else {
          let asyncStudents = [...students['Digital'], ...students['Digital-Modular'], ...students['Modular-Printed']];
          let hasAttendance = false;
          for (let k = 0; k < asyncStudents.length; k++) {
            let studentID = asyncStudents[k];

            let temp = attendance.find(a => a.faculty_load_id === facultyLoad.id && a.attendance_date === weekday.date && a.account_id === studentID);
            if (temp) {
              rowCounter[temp.mark]++;
              if (schedule && schedule.type) {
                counter[schedule.type][temp.mark]++;
              } else {
                hasAttendance = true;
                counter['Empty'][temp.mark]++;
              }
            }
          }
          if (hasAttendance) {
            if (schedule.schedule_date) {
              scheduleByScheduleType['Empty'].date.push({...schedule, weekday});
            } else {
              if (!scheduleByScheduleType['Empty'].weekly.find(w => w.weekday.weekdayFull === weekday.weekdayFull)) {
                scheduleByScheduleType['Empty'].weekly.push({...schedule, weekday});
              }
            }
            scheduleByScheduleType['Empty'].all.push({...schedule, weekday});
            hasNoSchedule = true;
          }
        }

        weekdays.push(weekday);
        temp.add(1, 'day');
      }

      colCounter['Synchronous']['Present'] += counter['Synchronous']['Present'];
      colCounter['Synchronous']['Absent'] += counter['Synchronous']['Absent'];
      colCounter['Synchronous']['Late'] += counter['Synchronous']['Late'];
      colCounter['Synchronous']['Excused'] += counter['Synchronous']['Excused'];

      colCounter['Asynchronous']['Present'] += counter['Asynchronous']['Present'];
      colCounter['Asynchronous']['Absent'] += counter['Asynchronous']['Absent'];
      colCounter['Asynchronous']['Late'] += counter['Asynchronous']['Late'];
      colCounter['Asynchronous']['Excused'] += counter['Asynchronous']['Excused'];

      colCounter['Exam']['Present'] += counter['Exam']['Present'];
      colCounter['Exam']['Absent'] += counter['Exam']['Absent'];
      colCounter['Exam']['Late'] += counter['Exam']['Late'];
      colCounter['Exam']['Excused'] += counter['Exam']['Excused'];

      colCounter['Empty']['Present'] += counter['Empty']['Present'];
      colCounter['Empty']['Absent'] += counter['Empty']['Absent'];
      colCounter['Empty']['Late'] += counter['Empty']['Late'];
      colCounter['Empty']['Excused'] += counter['Empty']['Excused'];

      colCounter['Total']['Present'] += rowCounter['Present'];
      colCounter['Total']['Absent'] += rowCounter['Absent'];
      colCounter['Total']['Late'] += rowCounter['Late'];
      colCounter['Total']['Excused'] += rowCounter['Excused'];

      attendanceDisplay.push({
        facultyLoadID: facultyLoad.id,
        students,
        counter,
        rowCounter,
        scheduleByScheduleType
      });
    }
    this.setState({
      ...this.state,
      attendanceDisplay,
      hasNoSchedule,
      isLoading: false,
      colCounter
    });
  }
  handleInputChange = e => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [e.target.name]: e.target.value
      }
    });
  }
  handleGenerate = e => {
    e.preventDefault();

    const { formInputs } = this.state;
    const { profile } = this.props;

    if (!formInputs.from || !formInputs.until) {
      return;
    }

    this.setState({
      ...this.state,
      from: formInputs.from,
      until: formInputs.until,
      isLoading: true,
      errorMessage: ''
    }, () => {

      let validator = new Validator(formInputs, {
        from: 'required|date',
        until: 'required|date'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          from: '',
          until: '',
          isLoading: false,
          errorMessage: validator.errors.errors[firstKey][0]
        });
        return;
      } else if (moment(formInputs.from).isSameOrAfter(moment(formInputs.until), 'day')) {
        this.setState({
          ...this.state,
          from: '',
          until: '',
          isLoading: false,
          errorMessage: 'The from field must not be the same or after the until field.'
        });
        return;
      }

      axiosRequest('get', `profile-menu/${profile.id}/classroom/attendance/custom/${formInputs.from}/${formInputs.until}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          attendance: data
        }, () => {
          this.getAttendance();
        });
      }, error => {
        this.setState({
          ...this.state,
          from: '',
          until: '',
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  renderSchedule = (schedule, scheduleType) => {
    let displayWeekly = s => {
      if (s.start_time && s.end_time) {
        let temp = [...schedule.all].filter(t => t.weekday.weekdayFull === s.weekday.weekdayFull);
        return (
          <>
            <div>Every {s.weekday.weekdayFull}</div>
            <div>{s.weekday.dateDisplaySm} - {temp[temp.length-1].weekday.dateDisplaySm} ({temp.length} day{temp.length !== 1 ? 's' : ''})</div>
            <div className='pl-1'>
              {moment(s.start_time, 'HH:mm').format('hh:mm A')} - {moment(s.end_time, 'HH:mm').format('hh:mm A')}
            </div>
          </>
        );
      }

      return (
        <div>{s.weekday.dateDisplayLg}</div>
      );
    };
    return (
      <>
        {
          schedule.weekly && schedule.weekly.length > 0 && (
            <div>
              {
                schedule.weekly.map(displayWeekly)
              }
            </div>
          )
        }
      </>
    );
  }
  renderCell = (facultyLoadID, scheduleType) => {
    const { attendanceDisplay } = this.state;

    if (attendanceDisplay && attendanceDisplay.length > 0) {
      let data = attendanceDisplay.find(ad => ad.facultyLoadID === facultyLoadID);
      if (!data) {
        return null;
      }

      let hasAttendance = marks.find(m => data.counter[scheduleType][m] !== 0);
      let learningPlatformSchedule = {
        Synchronous: [
          'Digital'
        ],
        Asynchronous: [
          'Digital',
          'Digital-Modular'
        ],
        Exam: [
          'Digital',
          'Digital-Modular',
          'Modular-Printed'
        ],
        Empty: [
          'Digital',
          'Digital-Modular',
          'Modular-Printed'
        ]
      };

      return (
        <div>
          {
            data.scheduleByScheduleType[scheduleType].all.length > 0 && (
              <div className='text-right'>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      <div className='text-left'>
                        <div className='font-weight-bold'>
                          Schedule:
                        </div>
                        <div className='pl-1'>
                          {this.renderSchedule(data.scheduleByScheduleType[scheduleType], scheduleType)}
                          {
                            data.scheduleByScheduleType[scheduleType].date.map((schedule, i) => (
                              <div key={i}>
                                <div>{schedule.weekday.dateDisplayLg}</div>
                                {
                                  (schedule.start_time && schedule.end_time) && (
                                    <div className='pl-1'>
                                      {moment(schedule.start_time, 'HH:mm').format('hh:mm A')} - {moment(schedule.end_time, 'HH:mm').format('hh:mm A')}
                                    </div>
                                  )
                                }
                              </div>
                            ))
                          }
                        </div>
                        <div className='font-weight-bold'>
                          Applies to:
                        </div>
                        <div className='pl-2'>
                          {
                            learningPlatforms.map((lp, i) => {
                              if (data.students[lp.platform].length > 0 && learningPlatformSchedule[scheduleType].indexOf(lp.platform) !== -1) {
                                return (
                                  <div key={i}>
                                    {lp.icon} <span className='font-weight-normal font-italic ml-1'>({data.students[lp.platform].length}<span className='d-none d-lg-inline-block ml-1'>student{data.students[lp.platform].length !== 1 ? 's' : ''}</span>)</span>
                                  </div>
                                );
                              }
              
                              return null;
                            })
                          }
                        </div>
                      </div>
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='info-circle' className='text-info' />
                </OverlayTrigger>
              </div>
            )
          }
          {
            data.scheduleByScheduleType[scheduleType].all.length > 0 && !hasAttendance ? (
              <div className='text-center mt-3'>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      No attendance
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='exclamation-circle' className='text-danger fa-lg' />
                </OverlayTrigger>
              </div>
            ) : hasAttendance ? (
              <div className='d-flex'>
                <div className='flex-fill font-weight-bold'>
                  {
                    marks.map((mark, i) => (
                      <div key={i}>
                        {mark}:
                      </div>
                    ))
                  }
                </div>
                <div className='flex-fill text-center'>
                  {
                    marks.map((mark, i) => (
                      <div key={i}>
                        {data.counter[scheduleType][mark]}
                      </div>
                    ))
                  }
                </div>
              </div>
            ) : null
          }
        </div>
      );
    }

    return null;
  }
  renderTotal = facultyLoadID => {
    const { attendanceDisplay } = this.state;

    if (attendanceDisplay && attendanceDisplay.length > 0) {
      let data = attendanceDisplay.find(ad => ad.facultyLoadID === facultyLoadID);
      if (data && marks.find(m => data.rowCounter[m] !== 0)) {
        return (
          <div className='d-flex'>
            <div className='flex-fill font-weight-bold'>
              {
                marks.map((mark, i) => (
                  <div key={i}>
                    {mark}:
                  </div>
                ))
              }
            </div>
            <div className='flex-fill text-center'>
              {
                marks.map((mark, i) => (
                  <div key={i}>
                    {data.rowCounter[mark]}
                  </div>
                ))
              }
            </div>
          </div>
        );
      }
    }
    return null;
  }
  renderColumnTotal = () => {
    const { colCounter, hasNoSchedule } = this.state;

    if (!colCounter) {
      return null;
    }

    let scheduleTypes = [
      'Synchronous',
      'Asynchronous',
      'Exam',
      'Total'
    ];

    if (hasNoSchedule) {
      scheduleTypes = [
        'Synchronous',
        'Asynchronous',
        'Exam',
        'Empty',
        'Total'
      ];
    }

    return (
      <tr>
        <th style={{ borderTop: '2px solid #dee2e6' }}>Total</th>
        {
          scheduleTypes.map(scheduleType => {
            if (marks.find(m => colCounter[scheduleType][m] !== 0)) {
              return (
                <td style={{ borderTop: '2px solid #dee2e6' }}>
                  <div className='d-flex'>
                    <div className='flex-fill font-weight-bold'>
                      {
                        marks.map((mark, i) => (
                          <div key={i}>
                            {mark}:
                          </div>
                        ))
                      }
                    </div>
                    <div className='flex-fill text-center'>
                      {
                        marks.map((mark, i) => (
                          <div key={i}>
                            {colCounter[scheduleType][mark]}
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </td>
              );
            }

            return (
              <td style={{ borderTop: '2px solid #dee2e6' }}></td>
            );
          })
        }
      </tr>
    );
  }
  renderStudents = facultyLoadID => {
    const { attendanceDisplay } = this.state;

    if (attendanceDisplay && attendanceDisplay.length > 0) {
      let data = attendanceDisplay.find(ad => ad.facultyLoadID === facultyLoadID);
      if (data) {
        return (
          <div>
            {
              learningPlatforms.map((lp, i) => {
                if (data.students[lp.platform].length > 0) {
                  return (
                    <div key={i}>
                      <span className='text-primary'>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              <div className='font-weight-bold'>Learning Platform</div>
                              <div>{lp.platform}</div>
                            </Tooltip>
                          }
                          trigger={['hover', 'focus']}>
                          {lp.icon}
                        </OverlayTrigger>
                      </span>
                      <span className='font-weight-normal font-italic ml-1'>({data.students[lp.platform].length}<span className='d-none d-lg-inline-block ml-1'>student{data.students[lp.platform].length !== 1 ? 's' : ''}</span>)</span>
                    </div>
                  );
                }

                return null;
              })
            }
          </div>
        )
      }
    }

    return null;
  }
  renderTable = () => {
    const { isLoading, errorMessage, hasNoSchedule } = this.state;
    const { currentUser, facultyLoads } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <Table bordered hover responsive>
        <thead>
          <tr>
            <th style={{ width: '16%' }}>Classroom</th>
            <th style={{ width: '16%' }}>Synchronous</th>
            <th style={{ width: '16%' }}>Asynchronous</th>
            <th style={{ width: '16%' }}>Exam</th>
            {
              hasNoSchedule && (
                <th className='font-italic text-muted' style={{ width: '16%', whiteSpace: 'nowrap' }}>
                  (No schedule)
                </th>
              )
            }
            <th style={{ width: '16%' }}>
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {
            facultyLoads.map(facultyLoad => (
              <tr key={facultyLoad.id}>
                <th className='pt-1 pr-1'>
                  <div className='text-right'>
                    <span style={{ cursor: 'pointer' }}>
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            <div className='border-bottom'>
                              {facultyLoad.class_course.course.description}
                            </div>
                            <div>
                              <div>
                                {facultyLoad.class_course.school_class.program.description}
                              </div>
                              <div>
                                {facultyLoad.class_course.school_class.year_level.description}
                              </div>
                              <div>
                                Section {facultyLoad.class_course.school_class.section.code}
                              </div>
                            </div>
                          </Tooltip>
                        }
                        trigger={['hover', 'focus']}>
                        <FontAwesomeIcon icon='info-circle' className='text-info' />
                      </OverlayTrigger>
                    </span>
                  </div>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {
                      (currentUser.account_type === 1 && !facultyLoad.class_course.school_class.year_level.school_level.school_admin_scope) ? (
                        <div>
                          {facultyLoad.class_course.course.code}
                        </div>
                      ) : (
                        <a href={`/${accountTypeMap[currentUser.account_type]}/class/${facultyLoad.id}`}>
                          {facultyLoad.class_course.course.code} <FontAwesomeIcon icon='external-link-alt' />
                        </a>
                      )
                    }
                  </div>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {facultyLoad.class_course.school_class.program.code} {facultyLoad.class_course.school_class.year_level.code} - {facultyLoad.class_course.school_class.section.code}
                  </div>
                  {
                    facultyLoad.class_faculty_load.map(cfl => (
                      <div key={cfl.id} style={{ whiteSpace: 'nowrap' }}>
                        {cfl.school_class.program.code} {cfl.school_class.year_level.code} - {cfl.school_class.section.code}
                      </div>
                    ))
                  }
                  <div>
                    {this.renderStudents(facultyLoad.id)}
                  </div>
                </th>
                <td className='pt-1 pr-1'>
                  {this.renderCell(facultyLoad.id, 'Synchronous')}
                </td>
                <td className='pt-1 pr-1'>
                  {this.renderCell(facultyLoad.id, 'Asynchronous')}
                </td>
                <td className='pt-1 pr-1'>
                  {this.renderCell(facultyLoad.id, 'Exam')}
                </td>
                {
                  hasNoSchedule && (
                    <td className='pt-1 pr-1'>
                      {this.renderCell(facultyLoad.id, 'Empty')}
                    </td>
                  )
                }
                <td>
                  {this.renderTotal(facultyLoad.id)}
                </td>
              </tr>
            ))
          }
        </tbody>
        <tfoot>
          {this.renderColumnTotal()}
        </tfoot>
      </Table>
    );
  }
  renderCustom = () => {
    const { from, until } = this.state;

    if (!from || !until) {
      return null;
    }

    return (
      <>
        <div className='mt-3 h5'>
          <div className='d-lg-none'>
            {moment(from).format('MMM D, YYYY')} &mdash; {moment(until).format('MMM D, YYYY')}
          </div>
          <div className='d-none d-lg-block'>
            {moment(from).format('MMMM D, YYYY')} &mdash; {moment(until).format('MMMM D, YYYY')}
          </div>
        </div>
        {this.renderTable()}
      </>
    );
  }
  render() {
    const { formInputs } = this.state;
    const { facultyLoads } = this.props;

    if (facultyLoads.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <Form onSubmit={this.handleGenerate}>
          <Form.Row>
            <Form.Group as={Col} lg={4}>
              <Form.Label>
                From
              </Form.Label>
              <Form.Control
                type='date'
                name='from'
                value={formInputs.from}
                max={formInputs.until ? moment(formInputs.until).subtract(1, 'day').format('YYYY-MM-DD') : moment().subtract(1, 'day').format('YYYY-MM-DD')}
                onChange={this.handleInputChange} />
            </Form.Group>
            <Form.Group as={Col} lg={4}>
              <Form.Label>
                Until
              </Form.Label>
              <Form.Control
                type='date'
                name='until'
                value={formInputs.until}
                min={formInputs.from ? moment(formInputs.from).add(1, 'day').format('YYYY-MM-DD') : ''}
                max={moment().format('YYYY-MM-DD')}
                onChange={this.handleInputChange} />
            </Form.Group>
            <Form.Group as={Col} lg={4} className='d-flex align-items-end'>
              <Button type='submit' variant='green' disabled={!formInputs.from || !formInputs.until} block>
                Generate
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        {this.renderCustom()}
      </>
    );
  }
}