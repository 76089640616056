import React from 'react';

export default function Discussion(props) {
  function postDescription() {
    switch(props.accountType) {
      case 5:
      case 2:
        return (
          <>
            <div className='mb-3'>
              <div className='h4 text-primary'>Post a discussion</div>
              <div>
                <ol>
                  <li>
                    Enter a discussion to the textarea at the top of the screen.
                  </li>
                  <li>
                    Optionally, you may upload files and images by clicking the upload icon under the textarea.
                  </li>
                  <li>
                    Click <b>Post</b> to complete.
                  </li>
                </ol>
              </div>
            </div>
            <div className='mb-3'>
              <div className='h5 text-primary'>Edit a discussion</div>
              <div>
                <ol>
                  <li>
                    Click on the dropdown icon in the top right corner of the discussion and click <b>edit</b>.
                  </li>
                  <li>
                    Enter a new discussion text to the textarea.
                  </li>
                  <li>
                    Optionally, you may upload more files and images by cicking the upload icon under the textarea.
                  </li>
                  <li>
                    Click <b>Save</b> to complete.
                  </li>
                </ol>
              </div>
            </div>
            <div className='mb-3'>
              <div className='h5 text-primary'>Delete attachments from the discussion</div>
              <div className='font-weight-bold'>To delete images:</div>
              <div className='ml-3'>
                <ol>
                  <li>
                    Hover over the image and click the close icon.
                  </li>
                  <li>
                    Click <b>Delete</b> from the prompt to confirm deletion.
                  </li>
                </ol>
              </div>
              <div className='font-weight-bold'>To delete files:</div>
              <div className='ml-3'>
                <ol>
                  <li>
                    Click on the close icon on the right side of the attached file.
                  </li>
                  <li>
                    Click <b>Delete</b> from the prompt to confirm deletion.
                  </li>
                </ol>
              </div>
            </div>
            <div className='mb-3'>
              <div className='h5 text-primary'>Delete a discussion</div>
              <div>
                <ol>
                  <li>
                    Click on the dropdown icon in the top right corner of the discussion and click <b>delete</b>.
                  </li>
                  <li>
                    Click <b>Delete</b> from the prompt to confirm deletion.
                  </li>
                </ol>
              </div>
            </div>
            <div className='mb-3'>
              <div className='h4 text-primary'>Comment on a discussion</div>
              <div>
                <ol>
                  <li>
                    Enter text to the comment field at the bottom of the discussion.
                  </li>
                  <li>
                    Click <b>Comment</b> to complete.
                  </li>
                </ol>
              </div>
            </div>
          </>
        );
      default:
        return (
          <div>
            Only members of the classroom are allowed to post a discussion and comment.
          </div>
        );
    }
  }
  return (
    <>
      <div className='mb-3'>
        The <b>Discussion</b> area is the first place you'll land every time you enter a classroom.<br /><br />
        This area is used by the faculty to create announcements and brief messages for the course.
        Students in the classroom are also authorized to post a discussion and comment on each post.
      </div>
      {postDescription()}
    </>
  );
}