import React, { Component } from 'react';
import { Alert, Breadcrumb, Nav } from 'react-bootstrap';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import Questions from '../../../common/Questions/Questions';
// import Details from './parts/Details';
import Preview from './parts/Preview';
import Submissions from './parts/Submissions';
import ManageStudents from './parts/ManageStudents';
import Submission from './parts/Submission';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Questionable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      material: null,
      categories: [],
      examCategories: [],
      isLoading: true,
      errorMessage: '',
      viewSubmission: {
        questions: [],
        materialPoints: 0,
        submission: null
      }
    };
  }
  componentDidMount() {
    const { classID, materialType, materialID } = this.props.match.params;
    axiosRequest('get', `faculty/class/${classID}/material/${materialType}/${materialID}`, null, ({ data: { data }}) => {
      data.material.grade_category = data.categories.find(c => c.id === data.material.grade_category_id);
      this.setState({
        ...this.state,
        material: data.material,
        categories: data.categories,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  onViewSubmission = (viewSubmission) => {
    this.setState({
      ...this.state,
      viewSubmission
    });
  }
  handleGrade = (data, cb = () => {}) => {
    const grades = [...this.state.material.grades];

    let hasRow = grades.find(grade => grade.student_id === data.student_id);
    let newGrades = grades;

    if (hasRow) {
      newGrades = grades.map(grade => {
        if (grade.student_id === data.student_id) {
          return data;
        }

        return grade;
      });
    } else {
      newGrades = [...newGrades, data];
    }

    this.setState({
      ...this.state,
      material: {
        ...this.state.material,
        grades: newGrades
      }
    }, cb);
  }
  handleMaterials = () => {
    const { classID } = this.props.match.params;
    this.props.history.push(`/faculty/class/${classID}/materials`);
  }
  handleSelect = key => {
    this.setState({
      ...this.state,
      viewSubmission: {
        questions: [],
        materialPoints: 0,
        submission: null
      }
    }, () => {
      this.props.history.push(key);
    });
  }
  handleUpdate = data => {
    const { categories } = this.state;

    data.grade_category = categories.find(c => c.id === data.grade_category_id);
    this.setState({
      ...this.state,
      material: data
    });
  }
  renderNav = () => {
    const { isLoading, errorMessage, material, viewSubmission } = this.state;
    const { activeNav } = this.props.match.params;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }
    
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.handleMaterials}>Materials</Breadcrumb.Item>
          <Breadcrumb.Item active>{material.title}</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <Nav variant='tabs bg-light rounded-top' className='flex-column flex-md-row'>
            {
              material.faculty_load_id && (
                <>
                  {/* <Nav.Item>
                    <Nav.Link eventKey='details' onSelect={this.handleSelect} active={activeNav === 'details'}>Details</Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey='questions' onSelect={this.handleSelect} active={activeNav === 'questions' && !viewSubmission.submission}>Questions</Nav.Link>
                  </Nav.Item>
                </>
              )
            }
            <Nav.Item>
              <Nav.Link eventKey='preview' onSelect={this.handleSelect} active={activeNav === 'preview' && !viewSubmission.submission}>Preview</Nav.Link>
            </Nav.Item>
            {
              material.type === 'exam' && (
                <Nav.Item>
                  <Nav.Link eventKey='manage-students' onSelect={this.handleSelect} active={activeNav === 'manage-students' && !viewSubmission.submission}>Manage Students</Nav.Link>
                </Nav.Item>
              )
            }
            <Nav.Item>
              <Nav.Link eventKey='submissions' onSelect={this.handleSelect} active={activeNav === 'submissions' && !viewSubmission.submission}>Submissions</Nav.Link>
            </Nav.Item>
            {
              viewSubmission.submission && (
                <Nav.Item>
                  <Nav.Link className='font-italic mr-1' active>Submission: {viewSubmission.submission.profile.name}</Nav.Link>
                </Nav.Item>
              )
            }
          </Nav>
          <div className='content border border-top-0 rounded-bottom p-3'>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
  renderContent = () => {
    const { material, categories, viewSubmission } = this.state;
    const { activeNav } = this.props.match.params;

    if (viewSubmission.submission) {
      return (
        <Submission
          {...this.props}
          questions={viewSubmission.questions}
          materialData={material}
          materialPoints={viewSubmission.materialPoints}
          submission={viewSubmission.submission}
          onGrade={this.handleGrade}
        />
      );
    }
    
    switch(activeNav) {
      // case 'details':
      //   if (material.faculty_load_id) {
      //     return (
      //       <Details
      //         {...this.props}
      //         materialData={material}
      //         categories={categories}
      //         onSave={this.handleUpdate}
      //       />
      //     );
      //   }
      //   break;
      case 'questions':
        if (material.faculty_load_id) {
          return (
            <Questions
              {...this.props}
              type={material.type}
              data={material}
            />
          );
        }
        break;
      case 'preview':
        return (
          <Preview
            {...this.props}
            materialData={material}
            gradeCategories={categories}
          />
        );
      case 'submissions':
        return (
          <Submissions
            {...this.props}
            materialData={material}
            handleViewSubmission={this.onViewSubmission}
            onGrade={this.handleGrade}
          />
        );
      case 'manage-students':
        return (
          <ManageStudents
            {...this.props}
            materialData={material}
          />
        );
      default:
        return null;
    }

    return null;
  }
  render() {

    return (
      <div className='border rounded p-4 my-3'>
        {this.renderNav()}
      </div>
    );
  }
}