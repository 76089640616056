import React, { Component } from 'react';
import { Alert, Button, Card, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import axiosRequest from '../../../util/helpers/axiosRequest';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Validator from 'validatorjs';
import { loadRule } from '../../../util/';
import PromptDeleteModal from '../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

const localizer = momentLocalizer(moment);

export default class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      isNextPageLoading: false,
      nextPageError: '',
      events: [],
      loadedMonths: [],
      saveModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        event: null,
        formInputs: {
          title: '',
          description: '',
          start: moment().format('YYYY-MM-DD'),
          end: ''
        }
      },
      deleteModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        event: null
      }
    };
  }
  componentDidMount() {
    const { userType } = this.props.match.params;

    axiosRequest('get', `${userType}/events`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        isLoading: false,
        events: data,
        loadedMonths: [moment().format('MM-YYYY')]
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);

    loadRule('date');
  }
  fetchEvents = e => {
    const { loadedMonths, events } = this.state;
    let temp = moment(e.start).add(7, 'days').format('MM-YYYY');
    if (loadedMonths.indexOf(temp) === -1) {
      this.setState({
        ...this.state,
        isNextPageLoading: true,
        nextPageError: ''
      }, () => {
        const { userType } = this.props.match.params;
        let start = moment(e.start).format('YYYY-MM-DD');
        let end = moment(e.end).format('YYYY-MM-DD');
        axiosRequest('get', `${userType}/events/range/${start}/${end}`, null, ({ data: { data }}) => {
          let newEvents = [...events, ...data].filter((e, i, t) => (
            i === t.findIndex(e2 => (
              e2.id === e.id
            ))
          ));
          this.setState({
            ...this.state,
            isNextPageLoading: false,
            events: [...newEvents],
            loadedMonths: [
              ...this.state.loadedMonths,
              moment(e.start).add(7, 'days').format('MM-YYYY')
            ]
          });
        }, error => {
          this.setState({
            ...this.state,
            isNextPageLoading: false,
            nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    }
  }
  handleSelectEvent = e => {
    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        show: true,
        event: e,
        formInputs: {
          title: e.title,
          description: e.description,
          start: moment(e.start).format('YYYY-MM-DD'),
          end: moment(e.end).format('YYYY-MM-DD')
        }
      }
    });
  }
  handleSelectSlot = e => {
    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        show: true,
        formInputs: {
          title: '',
          description: '',
          start: moment(e.start).format('YYYY-MM-DD'),
          end: moment(e.end).format('YYYY-MM-DD')
        }
      }
    });
  }
  showSaveModal = () => {
    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        show: true
      }
    });
  }
  hideSaveModal = () => {
    this.setState({
      ...this.state,
      saveModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        event: null,
        formInputs: {
          title: '',
          description: '',
          start: moment().format('YYYY-MM-DD'),
          end: ''
        }
      }
    });
  }
  showDeleteModal = e => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        isLoading: false,
        errorMessage: '',
        event: e
      }
    }, this.hideSaveModal);
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        event: null
      }
    });
  }
  handleDelete = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { events, deleteModal } = this.state;
      const { userType } = this.props.match.params;

      axiosRequest('delete', `${userType}/events/${deleteModal.event.id}`, null, ({ data: { message }}) => {
        let newEvents = [...events].filter(e => (e.id !== deleteModal.event.id));
        this.setState({
          ...this.state,
          events: [...newEvents],
          deleteModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            event: null
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleInputChange = e => {
    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        formInputs: {
          ...this.state.saveModal.formInputs,
          [e.target.name]: e.target.value
        }
      }
    });
  }
  handleCreate = () => {
    const { saveModal: { formInputs } } = this.state;
    const { userType } = this.props.match.params;

    axiosRequest('post', `${userType}/events`, formInputs, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        saveModal: {
          show: false,
          isLoading: false,
          errorMessage: '',
          event: null,
          formInputs: {
            title: '',
            description: '',
            start: moment().format('YYYY-MM-DD'),
            end: ''
          }
        },
        events: [
          ...this.state.events,
          data
        ]
      });
    }, error => {
      this.setState({
        ...this.state,
        saveModal: {
          ...this.state.saveModal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleUpdate = () => {
    const { saveModal: { formInputs, event } } = this.state;
    const { userType } = this.props.match.params;

    axiosRequest('patch', `${userType}/events/${event.id}`, formInputs, ({ data: { data }}) => {
      let newEvents = [...this.state.events].map(e => {
        if (e.id === event.id) {
          return {
            ...data
          };
        }

        return {...e}
      })
      this.setState({
        ...this.state,
        saveModal: {
          show: false,
          isLoading: false,
          errorMessage: '',
          event: null,
          formInputs: {
            title: '',
            description: '',
            start: moment().format('YYYY-MM-DD'),
            end: ''
          }
        },
        events: [...newEvents]
      });
    }, error => {
      this.setState({
        ...this.state,
        saveModal: {
          ...this.state.saveModal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleSave = e => {
    e.preventDefault();

    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { saveModal } = this.state;

      let validator = new Validator(saveModal.formInputs, {
        title: 'required|string|min:3',
        description: 'string|min:3',
        start: 'required|date',
        end: 'required|date',
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          saveModal: {
            ...this.state.saveModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      if (saveModal.event) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    });
  }
  renderEventTooltip = e => {
    return e.description ? e.description : e.title;
  }
  renderEvents = () => {
    const { events, isNextPageLoading, nextPageError } = this.state;

    return (
      <div style={{ height: '100vh'}}>
        {
          isNextPageLoading ? (
            <LoadingIcon />
          ) : nextPageError ? (
            <Alert variant='danger'>
              {nextPageError}
            </Alert>
          ) : null
        }
        <Calendar
          popup
          localizer={localizer}
          events={events}
          views={['month']}
          defaultView='month'
          onSelectEvent={this.handleSelectEvent}
          onSelectSlot={this.handleSelectSlot}
          tooltipAccessor={this.renderEventTooltip}
          onRangeChange={this.fetchEvents}
          selectable
        />
      </div>
    );
  }
  renderContent = () => {
    const { isLoading, errorMessage, saveModal, deleteModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <div className='d-flex'>
          <div className='h4 mb-0'>
            <FontAwesomeIcon icon='calendar-week' /> Events
          </div>
          <div className='ml-auto'>
            <Button variant='green' onClick={this.showSaveModal}>
              <FontAwesomeIcon icon='plus' />
              <span className='ml-1 d-none d-md-inline-block'>Create an event</span>
            </Button>
          </div>
        </div>
        <div className='dropdown-divider'></div>
        {this.renderEvents()}
        <Modal show={saveModal.show} onHide={this.hideSaveModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {
                saveModal.event ? 'Update event' : 'Create an event'
              }
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleSave}>
            <Modal.Body>
              {
                saveModal.errorMessage && (
                  <Alert variant='danger'>
                    {saveModal.errorMessage}
                  </Alert>
                )
              }
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control type='text' value={saveModal.formInputs.title} name='title' onChange={this.handleInputChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Description <span className='text-muted font-italic'>(Optional)</span></Form.Label>
                <Form.Control as='textarea' value={saveModal.formInputs.description} name='description' onChange={this.handleInputChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control type='date' value={saveModal.formInputs.start} max={saveModal.formInputs.end} name='start' onChange={this.handleInputChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>End Date</Form.Label>
                <Form.Control type='date' value={saveModal.formInputs.end} min={saveModal.formInputs.start} name='end' onChange={this.handleInputChange} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              {
                saveModal.event && (
                  <Button variant='danger' className='mr-auto' onClick={e => this.showDeleteModal(saveModal.event)}>
                    <FontAwesomeIcon icon='trash-alt' /> Delete
                  </Button>
                )
              }
              <Button variant='light' onClick={this.hideSaveModal} disabled={saveModal.isLoading}>
                Cancel
              </Button>
              <Button variant='green' type='submit' disabled={saveModal.isLoading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <PromptDeleteModal
          {...deleteModal}
          title='Delete event'
          onHide={this.hideDeleteModal}
          onDelete={this.handleDelete}
          >
          {
            deleteModal.event && (
              <>
                <Card.Subtitle>Are you sure you want to delete the event?</Card.Subtitle>
                <Alert variant='light'>
                  <div>
                    {deleteModal.event.title}
                  </div>
                  <div>
                    {deleteModal.event.description}
                  </div>
                  <div>
                    Start: {deleteModal.event.start}
                  </div>
                  <div>
                    End: {deleteModal.event.end}
                  </div>
                </Alert>
              </>
            )
          }
        </PromptDeleteModal>
      </div>
    );
  }
  render() {
    return (
      <Card className='mt-3'>
        <Card.Body>
          {this.renderContent()}
        </Card.Body>
      </Card>
    );
  }
}