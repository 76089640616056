import React, { Component } from 'react';
import { Alert, Button, Card, Nav, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NameLink from '../../common/NameLink/NameLink';
import axiosRequest from '../../../util/helpers/axiosRequest';
import './style.scss';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

export default class Badges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      top: null,
      isTopLoading: false,
      topError: ''
    };
  }
  componentDidMount() {
    const { userType } = this.props.match.params;
    axiosRequest('get', `${userType}/badges`, null, ({ data: { data } }) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false,
        errorMessage: ''
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleSelectSchoolClass = eventKey => {
    const { top } = this.state;

    if (top.badges[eventKey]) {
      this.setState({
        ...this.state,
        top: {
          ...top,
          school_class_id: eventKey
        }
      });
      return;
    }

    this.setState({
      ...this.state,
      top: {
        ...top,
        school_class_id: eventKey
      },
      isTopLoading: true,
      topError: ''
    }, () => {
      const { userType } = this.props.match.params;
      axiosRequest('get', `${userType}/badges/${eventKey}`, null, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          top: {
            ...this.state.top,
            badges: {
              ...this.state.top.badges,
              ...data.badges
            }
          },
          isTopLoading: false,
          topError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isTopLoading: false,
          topError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handlePrint = () => {
    window.print();
  }
  renderTopBadge = badge => {
    if (!badge) {
      return null;
    }

    return (
      <div>
        <span>
          <FontAwesomeIcon icon='trophy' size='lg' />
        </span>
        <span className='pl-2'>
          x {badge}
        </span>
      </div>
    );
  }
  renderBadgeTable = top => {
    const { isTopLoading, topError } = this.state;
    const { userType } = this.props.match.params;

    if (isTopLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (topError) {
      return (
        <Alert variant='danger'>
          {topError}
        </Alert>
      );
    }

    if (!Array.isArray(top) || top.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        {
          (userType !== 'student' && userType !== 'parent') && (
            <div className='text-right mb-2 action-header-print'>
              <Button variant='light' onClick={this.handlePrint}>
                <FontAwesomeIcon icon='print' /> Print
              </Button>
            </div>
          )
        }
        <Table size='sm' responsive striped hover className='top-badge-table'>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th className='text-center'>
                <span className='fa-layers fa-fw my-1 mx-2' title='Login'>
                  <FontAwesomeIcon icon='sign-in-alt' size='lg' />
                  <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-7' className='text-green' />
                </span>
              </th>
              <th className='text-center'>
                <span className='fa-layers fa-fw my-1 mx-2' title='Submission'>
                  <FontAwesomeIcon icon='paper-plane' size='lg' />
                  <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-9' className='text-green' />
                </span>
              </th>
              <th className='text-center'>
                <span className='fa-layers fa-fw my-1 mx-2' title='Attendance'>
                  <FontAwesomeIcon icon='calendar-alt' size='lg' />
                  <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
                </span>
              </th>
              <th className='text-center'>
                <span className='fa-layers fa-fw my-1 mx-2' title='Participation'>
                  <FontAwesomeIcon icon='comments' size='lg' />
                  <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
                </span>
              </th>
              <th className='text-center'>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {
              top.map((student, rankIndex) => (
                <tr key={student.profile.id} className={rankIndex === 0 ? 'h5' : rankIndex === 1 ? 'h6' : ''}>
                  <th className='text-center align-middle'>
                    {rankIndex+1}
                  </th>
                  <th>
                    <NameLink {...student.profile} />
                  </th>
                  <td>{this.renderTopBadge(student.Login)}</td>
                  <td>{this.renderTopBadge(student.Submission)}</td>
                  <td>{this.renderTopBadge(student.Attendance)}</td>
                  <td>{this.renderTopBadge(student.Participation)}</td>
                  <td>{this.renderTopBadge(student.total)}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </>
    );
  }
  renderContainer = () => {
    const { isLoading, errorMessage, top } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <div className='h4'>
          Top Badge Holders
        </div>
        {
          Array.isArray(top) ? this.renderBadgeTable(top) : (
            <>
              <div className='dropdown-divider'></div>
              <Nav variant='tabs' className='m-0 border-bottom flex-column flex-md-row' onSelect={this.handleSelectSchoolClass}>
                {
                  top.school_classes.map((schoolClass, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={schoolClass.id} className='font-weight-bold' active={schoolClass.id === +top.school_class_id}>
                        {`${schoolClass.program.code} ${schoolClass.year_level.code} - ${schoolClass.section.code}`}
                      </Nav.Link>
                    </Nav.Item>
                  ))
                }
              </Nav>
              <div className='border-left border-right border-bottom rounded-bottom p-2'>
                {this.renderBadgeTable(top.badges[top.school_class_id])}
              </div>
            </>
          )
        }
      </>
    );
  }
  render() {
    const { top } = this.state;
    const { show } = this.props;

    if (!top || !show) {
      return null;
    }

    return (
      <Card className='mt-3'>
        <Card.Body>
          {this.renderContainer()}
        </Card.Body>
      </Card>
    );
  }
}