import React from 'react';
import { Button, Card, Image, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import profileDefault from '../../../resources/image/profile-default.png';

const ViewUserModal = props => (
  <Modal show={props.show} onHide={props.onHide}>
    {
      props.data !== null && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>{props.data.profile.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body>
                <div style={{ height: '10rem', textAlign: 'center', marginBottom: '1rem' }}>
                  <Image src={props.data.profile.image ? `${process.env['REACT_APP_API_BASE_URL']}/profile/${props.data.profile.id}/${props.data.profile.image}` : profileDefault} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
                </div>
                <Card.Title><FontAwesomeIcon icon='user' size='sm' /> Information</Card.Title>
                <div className='dropdown-divider'></div>
                <b>{props.data.username}</b>
                <div>{props.data.email}</div>
                {
                  props.data.profile.phone && (
                    <div>{props.data.profile.phone}</div>
                  )
                }
                <div>{props.data.profile.gender}</div>
                <div>{props.data.profile.birthdate}</div>
                {
                  props.data.profile.position && (
                    <div>{props.data.profile.position}</div>
                  )
                }
                {
                  (props.data.account_type === 5 && props.data.profile.student_number) && (
                    <div>{props.data.profile.student_number}</div>
                  )
                }
                {
                  (props.data.account_type === 5 && props.data.profile.lrn) && (
                    <div>{props.data.profile.lrn}</div>
                  )
                }
                <div className='dropdown-divider'></div>
                <div className='font-weight-bold'>Signature</div>
                {
                  props.data.profile.signature ? (
                    <div className='my-3' style={{ height: '7.5rem', textAlign: 'center' }}>
                      <Image src={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/id/${props.data.profile.id}/signature`} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
                    </div>
                  ) : (
                    <div className='m-2 text-muted font-italic'>
                      Nothing to show
                    </div>
                  )
                }
                {
                  props.data.account_type === 5 && (
                    <>
                      <div className='dropdown-divider'></div>
                      {
                        props.data.student_class.school_class_id ? (
                          <>
                            <b>Regular</b>
                            <div>{props.data.student_class.school_class.program.description}</div>
                            <div>{props.data.student_class.school_class.year_level.description}</div>
                            <div>{props.data.student_class.school_class.section.code}</div>
                          </>
                        ) : (
                          <>
                            <b>Irregular</b>
                            <div>{props.data.profile.program.description}</div>
                            <div>{props.data.profile.year_level.description}</div>
                          </>
                        )
                      }
                    </>
                  )
                }
              </Card.Body>
            </Card>
            {
              props.data.account_type === 5 ? (
                <Card className='mt-2'>
                  <Card.Body>
                    <Card.Title><FontAwesomeIcon icon='id-card' size='sm' /> Emergency Contact</Card.Title>
                    <div className='dropdown-divider'></div>
                    {
                      props.data.profile.emergency_contact ? (
                        <div>
                          <div>
                            {`${props.data.profile.emergency_contact.title} ${props.data.profile.emergency_contact.name}`}
                          </div>
                          <div>
                            {props.data.profile.emergency_contact.phone}
                          </div>
                          <div>
                            {props.data.profile.emergency_contact.address}
                          </div>
                        </div>
                      ) : (
                        <div className='m-2 text-muted font-italic'>
                          Nothing to show
                        </div>
                      )
                    }
                  </Card.Body>
                </Card>
              ) : props.data.account_type === 4 ? (
                <Card className='mt-2'>
                  <Card.Body>
                    <Card.Title><FontAwesomeIcon icon='user' size='sm' /> Linked Student</Card.Title>
                    <div className='dropdown-divider'></div>
                    <b>{props.data.parent_link.student_account.profile.name}</b>
                    <div>{props.data.parent_link.student_account.username}</div>
                    <div>{props.data.parent_link.student_account.email}</div>
                    <div>{props.data.parent_link.student_account.profile.gender}</div>
                    <div>{props.data.parent_link.student_account.profile.birthdate}</div>
                  </Card.Body>
                </Card>
              ) : props.data.account_type === 1 ? (
                <Card className='mt-2'>
                  <Card.Body>
                    <Card.Title><FontAwesomeIcon icon='users' size='sm' /> School Level Scope</Card.Title>
                    <div className='dropdown-divider'></div>
                    {
                      props.data.school_admin_scopes.length > 0 ? (
                        <>
                          {
                            props.data.school_admin_scopes.map(scope => (
                              <div>
                                <FontAwesomeIcon icon='check' /> {scope.school_level.title}
                              </div>
                            ))
                          }
                        </>
                      ) : (
                        <div className='m-2 text-muted font-italic'>
                          Scope is not limited to any school level.
                        </div>
                      )
                    }
                  </Card.Body>
                </Card>
              ) : null
            }
          </Modal.Body>
        </>
      )
    }
    <Modal.Footer>
      <Button variant='secondary' onClick={props.onHide}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

ViewUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired
};

ViewUserModal.defaultProps = {
  show: false,
  title: '',
  onHide: () => {}
}

export default ViewUserModal;