import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import './style.scss';
import axiosRequest from '../../../util/helpers/axiosRequest';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});
const localizer = momentLocalizer(moment);

const CalendarEvent = ({ event }) => {
  let start = moment(event.start);
  let end = moment(event.end);
  return (
    <div>
      <div>{`${start.format('hh:mm A')} - ${end.format('hh:mm A')}`}</div>
      <div>{event.type}</div>
    </div>
  );
}

const accountTypeMap = {
  1: 'school-admin',
  2: 'faculty',
  4: 'parent',
  5: 'student'
};

class TimeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      dateSchedules: {},
      weeklySchedules: {},
      events: []
    };
  }
  componentDidMount() {
    const { classID } = this.props.match.params;
    const { currentUser } = this.props;

    axiosRequest('get', `${accountTypeMap[currentUser.account_type]}/class/${classID}/schedule`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data
      }, () => {
        this.getEvents();
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  getEvents = (weekStart) => {
    const { dateSchedules, weeklySchedules } = this.state;

    if (weekStart) {
      weekStart = moment(weekStart).startOf('isoWeek');
    } else {
      weekStart = moment().startOf('isoWeek');
    }

    let events = [];

    for (let i = 0; i < 7; i++) {
      let temp = moment(weekStart).add(i, 'days');

      let event = weeklySchedules[temp.format('dddd')];

      let dateSchedule = dateSchedules[temp.format('YYYY-MM-DD')];
      if (dateSchedule) {
        event = dateSchedule;
      }

      if (event.start_time && event.end_time) {
        let start = moment(`${temp.format('YYYY-MM-DD')} ${event.start_time}`, 'YYYY-MM-DD HH:mm');
        let end = moment(`${temp.format('YYYY-MM-DD')} ${event.end_time}`, 'YYYY-MM-DD HH:mm');
        if (start.isAfter(end)) {
          end = moment(`${temp.clone().add(1, 'days').format('YYYY-MM-DD')} ${event.end_time}`, 'YYYY-MM-DD HH:mm');
        }
        events.push({
          // title: moment.duration(end.diff(start, 'minutes'), 'minutes').humanize(),
          title: event.type ? event.type : moment.duration(end.diff(start, 'minutes'), 'minutes').humanize(),
          start: start.toDate(),
          end: end.toDate(),
          type: event.type
        });
      }
    }
    this.setState({
      ...this.state,
      events,
      isLoading: false
    });
  }
  render() {
    const { isLoading, errorMessage, events } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <Alert variant='warning' className='d-lg-none'>
          <div>The <b>day view</b> is clearer for your device.</div>
        </Alert>
        <Calendar
          localizer={localizer}
          events={events}
          views={['week', 'day']}
          defaultView='week'
          onNavigate={this.getEvents}
          selectable='ignoreEvents'
          components={{
            event: CalendarEvent
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(TimeTable);