import React, { Component } from 'react';
import { Alert, Card, Col, Container, Nav, Row } from 'react-bootstrap';
import Header from '../../../common/Header/Header';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import Students from './parts/Students';
import './style.scss';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class ExamPermit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      categories: [],
      activeCategory: null,
      disabled: false
    };
  }
  componentDidMount() {
    axiosRequest('get', 'staff/exam-permit', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        categories: data,
        activeCategory: data && data.length > 1 ? data[0] : null,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleSelect = id => {
    if (this.state.disabled) {
      return false;
    }

    this.setState({
      ...this.state,
      activeCategory: this.state.categories.find(c => c.id === +id)
    });
  }
  handleDisableNav = (cb = () => {}) => {
    this.setState({
      ...this.state,
      disabled: true
    }, cb);
  }
  handleEnableNav = () => {
    this.setState({
      ...this.state,
      disabled: false
    });
  }
  renderContent = () => {
    const { isLoading, errorMessage, categories, activeCategory, disabled } = this.state;

    if (isLoading) {
      return (
        <Card className='my-3'>
          <Card.Body>
            <LoadingIcon lg />
          </Card.Body>
        </Card>
      );
    }
    
    if (errorMessage) {
      return (
        <Card className='my-3'>
          <Card.Body>
            <Alert variant='danger'>
              {errorMessage}
            </Alert>
          </Card.Body>
        </Card>
      );
    }

    if (categories.length === 0) {
      return (
        <Card className='my-3'>
          <Card.Body>
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          </Card.Body>
        </Card>
      );
    }

    return (
      <div className='exam-permit my-3'>
        <Row noGutters>
          <Col lg={3} className='exam-permit-sidebar'>
            <Nav variant='tabs bg-light flex-column sticky-top'>
              {
                categories.map(category => (
                  <Nav.Item key={category.id}>
                    <Nav.Link
                      eventKey={category.id}
                      onSelect={this.handleSelect}
                      active={activeCategory.id === category.id}
                      disabled={disabled}>
                        {category.title}
                    </Nav.Link>
                  </Nav.Item>
                ))
              }
            </Nav>
          </Col>
          <Col lg={9} className='p-3 exam-permit-content'>
            <Students {...this.props} category={activeCategory} onLoading={this.handleDisableNav} onComplete={this.handleEnableNav} />
          </Col>
        </Row>
      </div>
    );
  }
  render() {
    return (
      <>
        <Header active='Exam Permit' />
        <Container>
          {this.renderContent()}
        </Container>
      </>
    );
  }
}