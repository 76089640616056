import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Alert, Badge, Button, ButtonGroup, Card, Col, Container, Form, Image, InputGroup, ListGroup, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../actions';
import Header from '../../../common/Header/Header';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import NameLink from '../../../common/NameLink/NameLink';
import moment from 'moment';
import Validator from 'validatorjs';
import PromptDeleteModal from '../../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

class PaymentTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      transactions: {
        data: []
      },
      isNextPageLoading: false,
      nextPageError: '',
      modal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        data: null,
        comment: '',
        isFormLoading: false,
        formError: '',
        profile: null
      },
      search: {
        isLoading: false,
        errorMessage: '',
        query: '',
        lastQuery: '',
        isSearch: false
      },
      examPermitModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null,
        categories: [],
        disabledExams: [],
        successMessage: '',
        isFormLoading: false,
        formError: '',
        profile: null
      },
      deleteModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null
      },
      exportModal: {
        show: false,
        formInputs: {
          order: 'transaction',
          start: '',
          end: ''
        }
      }
    };
  }
  componentDidMount() {
    if (this.props?.location?.state?.query) {
      let query = this.props.location.state.query
      this.setState({
        ...this.state,
        isLoading: false,
        search: {
          ...this.state.search,
          isLoading: true,
          query
        }
      }, () => {
        axiosRequest('post', `staff/payment-transaction/search`, {
          query
        }, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            transactions: data,
            search: {
              ...this.state.search,
              isLoading: false,
              lastQuery: query,
              isSearch: true
            }
          });
        }, error => {
          this.setState({
            ...this.state,
            search: {
              ...this.state.search,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        }, this.props.history);
      });
    } else {
      axiosRequest('get', 'staff/payment-transaction', null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isLoading: false,
          transactions: data
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    }
  }
  showModal = (data, profile) => {
    this.setState({
      ...this.state,
      modal: {
        show: true,
        isLoading: true,
        errorMessage: '',
        data,
        comment: data.comment ? data.comment : '',
        isFormLoading: false,
        formError: '',
        profile
      }
    }, () => {
      if (data && (!data.screenshot || !data.read_at)) {
        axiosRequest('get', `staff/payment-transaction/${data.id}`, null, ({ data: { data: newData }}) => {
          let newTransactionsData = [...this.state.transactions.data].map(profile => {
            if (profile.id === data.account_id) {
              profile.payment_transactions = [...profile.payment_transactions].map(transaction => {
                if (transaction.id === data.id) {
                  return {...newData};
                }
                return {...transaction};
              });
            }

            return {...profile};
          });
          this.setState({
            ...this.state,
            transactions: {
              ...this.state.transactions,
              data: newTransactionsData
            },
            modal: {
              ...this.state.modal,
              data: newData,
              isLoading: false
            }
          });
        }, error => {
          this.setState({
            ...this.state,
            modal: {
              ...this.state.modal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        }, this.props.history);
      } else {
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isLoading: false
          }
        });
      }
    });
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      modal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        data: null,
        comment: '',
        isFormLoading: false,
        formError: '',
        profile: null
      }
    });
  }
  showExamPermitModal = (data, profile) => {
    this.setState({
      ...this.state,
      examPermitModal: {
        show: true,
        isLoading: true,
        errorMessage: '',
        data,
        categories: [],
        disabledExams: [],
        successMessage: '',
        isFormLoading: false,
        formError: '',
        profile
      }
    }, () => {
      axiosRequest('get', `staff/payment-transaction/exam-permit/${profile.id}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          examPermitModal: {
            ...this.state.examPermitModal,
            isLoading: false,
            categories: data.categories,
            disabledExams: data.profile.disabled_exams.map(de => de.exam_category_id),
            profile: {
              ...profile,
              ...data.profile
            }
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          examPermitModal: {
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  hideExamPermitModal = () => {
    this.setState({
      ...this.state,
      examPermitModal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        data: null,
        categories: [],
        disabledExams: [],
        successMessage: '',
        isFormLoading: false,
        formError: '',
        profile: null
      }
    });
  }
  toggleExamPermit = (type, categoryID) => {
    const { examPermitModal } = this.state;

    if ((type === 'enable' && examPermitModal.disabledExams.indexOf(categoryID) === -1) || (type === 'disable' && examPermitModal.disabledExams.indexOf(categoryID) !== -1)) {
      return false;
    }

    let newDisabledExams = examPermitModal.disabledExams.filter(de => de !== categoryID);

    if (type === 'disable') {
      newDisabledExams = [...newDisabledExams, categoryID];
    }

    this.setState({
      ...this.state,
      examPermitModal: {
        ...this.state.examPermitModal,
        disabledExams: newDisabledExams
      }
    });
  }
  showDeleteModal = data => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        show: true,
        data
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null
      }
    });
  }
  showExportModal = () => {
    this.setState({
      ...this.state,
      exportModal: {
        ...this.state.exportModal,
        show: true
      }
    });
  }
  hideExportModal = () => {
    this.setState({
      ...this.state,
      exportModal: {
        ...this.state.exportModal,
        show: false,
        formInputs: {
          order: 'transaction',
          start: '',
          end: ''
        }
      }
    });
  }
  handleCommentInputChange = e => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        comment: e.target.value
      }
    });
  }
  handleSearchInputChange = e => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        query: e.target.value
      }
    });
  }
  handleSearch = e => {
    e.preventDefault();

    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { search } = this.state;

      let validator = new Validator({
        query: search.query
      }, {
        query: 'required'
      }, {
        'required.query': 'The search field is required.'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          search: {
            ...this.state.search,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', `staff/payment-transaction/search`, {
        query: search.query
      }, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          transactions: data,
          search: {
            ...this.state.search,
            isLoading: false,
            lastQuery: search.query,
            isSearch: true
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          search: {
            ...this.state.search,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleViewAll = () => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      axiosRequest('get', `staff/payment-transaction`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          transactions: data,
          search: {
            isLoading: false,
            errorMessage: '',
            query: '',
            lastQuery: '',
            isSearch: false
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          search: {
            ...this.state.search,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true,
      nextPageError: ''
    }, () => {
      const { transactions, search } = this.state;

      const path = transactions.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest(search.isSearch ? 'post' : 'get', path, search.isSearch ? {query: search.lastQuery} : null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          transactions: {
            ...data,
            data: [
              ...this.state.transactions.data,
              ...data.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, error => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleAccept = () => {
    const { modal } = this.state;

    if (!modal.data) {
      return;
    }

    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        isFormLoading: true,
        formError: ''
      }
    }, () => {
      axiosRequest('patch', `staff/payment-transaction/${modal.data.id}/accept`, {
        comment: modal.comment
      }, ({ data: { data }}) => {
        let newTransactionsData = [...this.state.transactions.data].map(profile => {
          if (profile.id === data.account_id) {
            profile.payment_transactions = [...profile.payment_transactions].map(transaction => {
              if (transaction.id === data.id) {
                return {...data};
              }
              return {...transaction};
            });
          }

          return {...profile};
        });
        this.setState({
          ...this.state,
          transactions: {
            ...this.state.transactions,
            data: newTransactionsData
          },
          modal: {
            ...this.state.modal,
            isFormLoading: false
          }
        }, this.hideModal);
      }, error => {
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isFormLoading: false,
            formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleDecline = () => {
    const { modal } = this.state;

    if (!modal.data) {
      return;
    }

    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        isFormLoading: true,
        formError: ''
      }
    }, () => {
      axiosRequest('patch', `staff/payment-transaction/${modal.data.id}/decline`, {
        comment: modal.comment
      }, ({ data: { data }}) => {
        let newTransactionsData = [...this.state.transactions.data].map(profile => {
          if (profile.id === data.account_id) {
            profile.payment_transactions = [...profile.payment_transactions].map(transaction => {
              if (transaction.id === data.id) {
                return {...data};
              }
              return {...transaction};
            });
          }

          return {...profile};
        });
        this.setState({
          ...this.state,
          transactions: {
            ...this.state.transactions,
            data: newTransactionsData
          },
          modal: {
            ...this.state.modal,
            isFormLoading: false
          }
        }, this.hideModal);
      }, error => {
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isFormLoading: false,
            formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleSave = () => {
    const { modal } = this.state;

    if (!modal.data) {
      return;
    }

    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        isFormLoading: true,
        formError: ''
      }
    }, () => {
      axiosRequest('patch', `staff/payment-transaction/${modal.data.id}/comment`, {
        comment: modal.comment
      }, ({ data: { data }}) => {
        let newTransactionsData = [...this.state.transactions.data].map(profile => {
          if (profile.id === data.account_id) {
            profile.payment_transactions = [...profile.payment_transactions].map(transaction => {
              if (transaction.id === data.id) {
                return {...data};
              }
              return {...transaction};
            });
          }

          return {...profile};
        });
        this.setState({
          ...this.state,
          transactions: {
            ...this.state.transactions,
            data: newTransactionsData
          },
          modal: {
            ...this.state.modal,
            isFormLoading: false
          }
        }, this.hideModal);
      }, error => {
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isFormLoading: false,
            formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleExamPermit = () => {
    this.setState({
      ...this.state,
      examPermitModal: {
        ...this.state.examPermitModal,
        isFormLoading: true,
        formError: '',
        successMessage: ''
      }
    }, () => {
      const { examPermitModal } = this.state;

      let validator = new Validator({
        disabledExams: examPermitModal.disabledExams
      }, {
        disabledExams: 'array',
        'disabledExams.*': 'integer|min:0'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          examPermitModal: {
            ...this.state.examPermitModal,
            isFormLoading: false,
            formError: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', `staff/payment-transaction/exam-permit/${examPermitModal.profile.id}`, {
        disabledExams: examPermitModal.disabledExams
      }, ({ data: { data, message }}) => {
        this.setState({
          ...this.state,
          examPermitModal: {
            ...this.state.examPermitModal,
            successMessage: message,
            isFormLoading: false,
            formError: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          examPermitModal: {
            ...this.state.examPermitModal,
            isFormLoading: false,
            formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
            successMessage: ''
          }
        });
      }, this.props.history);
    });
  }
  handleDelete = () => {

    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { deleteModal } = this.state;

      axiosRequest('delete', `staff/payment-transaction/${deleteModal.data.id}`, null, ({ data: { message }}) => {
        let newTransactionsData = [...this.state.transactions.data].map(profile => {
          if (profile.id === deleteModal.data.account_id) {
            profile.payment_transactions = [...profile.payment_transactions].filter(t => t.id !== deleteModal.data.id);
          }

          return {...profile};
        });
        newTransactionsData = newTransactionsData.filter(td => td.payment_transactions.length !== 0);

        this.setState({
          ...this.state,
          transactions: {
            ...this.state.transactions,
            data: newTransactionsData
          },
          deleteModal: {
            ...this.state.deleteModal,
            show: false,
            isLoading: false,
            errorMessage: '',
            data: null
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleOrderSelectChange = e => {
    this.setState({
      ...this.state,
      exportModal: {
        ...this.state.exportModal,
        formInputs: {
          ...this.state.exportModal.formInputs,
          order: e.target.value
        }
      }
    });
  }
  handleDateInputChange = e => {
    this.setState({
      ...this.state,
      exportModal: {
        ...this.state.exportModal,
        formInputs: {
          ...this.state.exportModal.formInputs,
          [e.target.name]: e.target.value
        }
      }
    });
  }
  handlePreviewImage = (image, imageID) => {
    this.props.showImagePreview([
      {
        id: imageID,
        src: image
      }
    ]);
  }
  isExportDisabled = () => {
    const { exportModal: { formInputs } } = this.state;

    return !formInputs.order || !formInputs.start || !formInputs.end || moment(formInputs.start).isAfter(moment(formInputs.end), 'day');
  }
  renderModalBody = () => {
    const { modal } = this.state;

    if (!modal.data) {
      return null;
    }

    if (modal.isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (modal.errorMessage) {
      return (
        <Alert variant='danger'>
          {modal.errorMessage}
        </Alert>
      );
    }

    return (
      <>
        {
          modal.formError && (
            <Alert variant='danger'>
              {modal.formError}
            </Alert>
          )
        }
        <div className='text-right font-italic text-muted'>
          <FontAwesomeIcon icon='clock' /> {moment(modal.data.created_at).format('MMM D, YYYY hh:mm A')}
        </div>
        <a href={modal.data.screenshot}>
          <div style={{ height: '7.5rem', textAlign: 'center' }} className='my-3'>
            <Image
              onClick={e => this.handlePreviewImage(modal.data.screenshot, modal.data.id)}
              src={modal.data.screenshot}
              style={{ objectFit: 'contain', height: '100%' }}
              fluid
              thumbnail />
          </div>
        </a>
        <div className='h5'>
          <div>
            {modal.data.mode}
          </div>
          <div>
            &#8369; {modal.data.amount.toFixed(2)}
          </div>
        </div>
        <div>
          <b className='mr-1'>{modal.data.mode === 'cash' ? 'OR No.: ' : 'Ref. No.: '}</b>
          {modal.data.reference_number}
        </div>
        <div>
          <b className='mr-1'>Date:</b>
          {
            modal.data.transaction_time ?
              moment(`${modal.data.transaction_date} ${modal.data.transaction_time}`, 'YYYY-MM-DD HH:mm').format('MMM D, YYYY hh:mm A') :
              moment(modal.data.transaction_date).format('MMMM D, YYYY')
          }
        </div>
        {
          modal.data.mode === 'gcash' && (
            <div>
              <b className='mr-1'>Sender:</b>
              {modal.data.sender_name}
            </div>
          )
        }
        <div className='dropdown-divider'></div>
        <div>
          <Form.Group className='mb-0 mt-3'>
            <Form.Label>
              Comment <span className='font-italic text-muted'>(Optional)</span>
            </Form.Label>
            <Form.Control
                as='textarea'
                name='comment'
                rows='4'
                value={modal.comment}
                onChange={this.handleCommentInputChange}
                disabled={modal.isLoading || modal.isFormLoading}/>
          </Form.Group>
        </div>
      </>
    );
  }
  renderExamPermitModalBody = () => {
    const { examPermitModal } = this.state;

    if (!examPermitModal.data) {
      return null;
    }

    if (examPermitModal.isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (examPermitModal.errorMessage) {
      return (
        <Alert variant='danger'>
          {examPermitModal.errorMessage}
        </Alert>
      );
    }

    let changeIcon = (disabledExams, categoryID) => {
      let disabledExam = disabledExams.find(de => de.exam_category_id === categoryID);
      let stateDisabledExam = examPermitModal.disabledExams.indexOf(categoryID) !== -1;

      if ((disabledExam && !stateDisabledExam) || (!disabledExam && stateDisabledExam)) {
        return (
          <span>
            <FontAwesomeIcon icon='circle' className='text-info' size='xs' />
          </span>
        );
      }

      return null;
    }

    let pending = examPermitModal.data.filter(d => !d.approved_at && !d.declined_at && !d.read_at).length;
    let review = examPermitModal.data.filter(d => !d.approved_at && !d.declined_at && d.read_at).length;
    let declined = examPermitModal.data.filter(d => d.declined_at).length;

    return (
      <>
        {
          examPermitModal.formError ? (
            <Alert variant='danger'>
              {examPermitModal.formError}
            </Alert>
          ) : examPermitModal.successMessage ? (
            <Alert variant='success'>
              {examPermitModal.successMessage}
            </Alert>
          ) : pending > 0 ? (
            <Alert variant='warning'>
              <b>{pending} transaction{pending !== 1 ? 's' : ''}</b> {pending !== 1 ? 'are' : 'is'} still awaiting for review
            </Alert>
          ) : review > 0 ? (
            <Alert variant='warning'>
              <b>{review} transaction{review !== 1 ? 's' : ''}</b> {review !== 1 ? 'are' : 'is'} still awaiting for approval
            </Alert>
          ) : declined > 0 ? (
            <Alert variant='warning'>
              <b>{declined} transaction{declined !== 1 ? 's' : ''}</b> {declined !== 1 ? 'are' : 'is'} declined
            </Alert>
          ) : null
        }
        {
          examPermitModal.categories.length === 0 ? (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          ) : (
            <>
              {
                examPermitModal.categories.map(category => (
                  <div key={category.id} className='d-flex py-2 border-bottom align-items-center'>
                    <div className='flex-fill d-flex'>
                      <div>
                        {category.title}
                      </div>
                      <div className='ml-2 flex-fill'>
                        {changeIcon(examPermitModal.profile.disabled_exams, category.id)}
                      </div>
                    </div>
                    <div>
                      <ButtonGroup size='sm'>
                        <Button
                          variant={examPermitModal.disabledExams.indexOf(category.id) !== -1 ? 'outline-green' : 'green'}
                          onClick={e => this.toggleExamPermit('enable', category.id)}
                          disabled={examPermitModal.isFormLoading || examPermitModal.successMessage}
                        >
                          {examPermitModal.disabledExams.indexOf(category.id) !== -1 ? 'Enable' : 'Enabled'}
                        </Button>
                        <Button
                          variant={examPermitModal.disabledExams.indexOf(category.id) !== -1 ? 'danger' : 'outline-danger'}
                          onClick={e => this.toggleExamPermit('disable', category.id)}
                          disabled={examPermitModal.isFormLoading || examPermitModal.successMessage}
                        >
                          {examPermitModal.disabledExams.indexOf(category.id) !== -1 ? 'Disabled' : 'Disable'}
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                ))
              }
            </>
          )
        }
      </>
    )
  }
  renderList = () => {
    const { transactions, isNextPageLoading, nextPageError } = this.state;

    if (transactions.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <ListGroup>
        {
          transactions.data.map(profile => (
            <ListGroup.Item key={profile.id}>
              <div className='text-muted font-italic text-right'>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {moment(profile.payment_transactions[0].created_at).format('MMMM D, YYYY hh:mm A')}
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='clock' />
                </OverlayTrigger> {moment(profile.payment_transactions[0].created_at).fromNow()}
              </div>
              <div className='d-flex mb-3'>
                <NameLink {...profile} nameStyle={{ alignSelf: 'end', fontSize: '1.5rem', fontWeight: 500 }} />
              </div>
              <div className='px-3'>
                <div className='h5 d-flex'>
                  <div className='flex-fill font-italic'>
                    {profile.payment_transactions.length} Payment Transaction{profile.payment_transactions.length === 1 ? '' : 's'}
                  </div>
                  <div>
                    <Button variant='info' className='ml-1' onClick={e => this.showExamPermitModal(profile.payment_transactions, profile)} size='sm'>
                      <FontAwesomeIcon icon='file-invoice' /> Exam Permit
                    </Button>
                  </div>
                </div>
                <div className='px-1'>
                  <Table responsive hover size='sm'>
                    <tbody>
                      {
                        profile.payment_transactions.map((transaction, index) => (
                          <tr key={transaction.id}>
                            <th>
                              {index+1}
                            </th>
                            <td>
                              {transaction.mode}
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              {
                                transaction.transaction_time ?
                                  moment(`${transaction.transaction_date} ${transaction.transaction_time}`, 'YYYY-MM-DD HH:mm').format('MMM D, YYYY hh:mm A') :
                                  moment(transaction.transaction_date).format('MMMM D, YYYY')
                              }
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                              &#8369; {transaction.amount.toFixed(2)}
                            </td>
                            <td>
                              {
                                transaction.approved_at ? (
                                  <Badge variant='green'>
                                    Accepted
                                  </Badge>
                                ) : transaction.declined_at ? (
                                  <Badge variant='danger'>
                                    Declined
                                  </Badge>
                                ) : transaction.read_at ? (
                                  <Badge variant='warning'>
                                    Reviewing
                                  </Badge>
                                ) : (
                                  <Badge variant='light'>
                                    Processing
                                  </Badge>
                                )
                              }
                            </td>
                            <td className='text-center' style={{ whiteSpace: 'nowrap' }}>
                              <Button variant='warning' size='sm' onClick={e => this.showModal(transaction, profile)}>
                                <FontAwesomeIcon icon='eye' className='fa-fw' /> View
                              </Button>
                              {
                                (transaction.approved_at || transaction.declined_at) && (
                                  <Button variant='danger' className='ml-1' onClick={e => this.showDeleteModal(transaction)} size='sm'>
                                    <FontAwesomeIcon icon='trash-alt' /> Delete
                                  </Button>
                                )
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </ListGroup.Item>
          ))
        }
        {
          transactions.next_page_url && (
            <ListGroup.Item>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
              <Button variant='link' onClick={this.handleNextPage} block disabled={isNextPageLoading}>
                <span>
                  {
                    isNextPageLoading && (
                      <LoadingIcon />
                    )
                  }
                </span>
                <span className='ml-1'>Show more...</span>
              </Button>
            </ListGroup.Item>
          )
        }
      </ListGroup>
    );
  }
  renderContent = () => {
    const { isLoading, errorMessage, search } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <div className='border rounded mb-3 p-3'>
          <div className='text-right'>
            <Button variant='green' onClick={this.showExportModal}>
              <FontAwesomeIcon icon='file-export' /> <span className='d-none d-md-inline-block'>Export Transactions</span>
            </Button>
          </div>
          <div className='dropdown-divider'></div>
          <Form onSubmit={this.handleSearch}>
            <Form.Group>
              <Form.Label>Name or Student Number</Form.Label>
              <Row noGutters>
                <Col>
                  <InputGroup>
                    <Form.Control
                      type='text'
                      size='sm'
                      name='query'
                      placeholder='e.g. Juan Dela Cruz'
                      onChange={this.handleSearchInputChange}
                      value={search.query} />
                    <InputGroup.Append>
                      <Button variant='green' type='submit' size='sm'>
                        <FontAwesomeIcon icon='search' /> <span className='d-none d-md-inline-block'>Search</span>
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
                {
                  search.isSearch && (
                    <div className='pl-1'>
                      <Button variant='outline-green mr-2' type='button' onClick={this.handleViewAll} size='sm'>
                        <FontAwesomeIcon icon='list' /> <span className='d-none d-md-inline-block'>View all</span>
                      </Button>
                    </div>
                  )
                }
              </Row>
            </Form.Group>
          </Form>
        </div>
        {
          search.isLoading ? (
            <LoadingIcon />
          ) : (
            <>
              {
                search.errorMessage && (
                  <Alert variant='danger'>
                    {search.errorMessage}
                  </Alert>
                )
              }
              {this.renderList()}
            </>
          )
        }
      </>
    );
  }
  render() {
    const { modal, examPermitModal, deleteModal, exportModal } = this.state;

    return (
      <>
        <Header active='Payment Transaction' />
        <Container>
          <Card className='my-3'>
            <Card.Body>
              {this.renderContent()}
              <Modal show={modal.show} onHide={this.hideModal} backdrop={(modal.isLoading || modal.isFormLoading) ? 'static' : true}>
                <Modal.Header closeButton={!modal.isLoading && !modal.isFormLoading}>
                  <div>
                    <Modal.Title>
                      {modal.profile ? modal.profile.name : 'View Transaction'}
                    </Modal.Title>
                    {
                      modal.data && (
                        <>
                          {
                            modal.data.approved_at ? (
                              <div>
                                <Badge variant='green'>
                                  Accepted
                                </Badge>
                                <span className='ml-1 font-italic text-muted'>&mdash; {moment(modal.data.approved_at).format('MMM D, YYYY hh:mm A')}</span>
                              </div>
                            ) : modal.data.declined_at ? (
                              <div>
                                <Badge variant='danger'>
                                  Declined
                                </Badge>
                                <span className='ml-1 font-italic text-muted'>&mdash; {moment(modal.data.declined_at).format('MMM D, YYYY hh:mm A')}</span>
                              </div>
                            ) : modal.data.read_at ? (
                              <div>
                                <Badge variant='warning'>
                                  Reviewing
                                </Badge>
                                <span className='ml-1 font-italic text-muted'>&mdash; {moment(modal.data.read_at).format('MMM D, YYYY hh:mm A')}</span>
                              </div>
                            ) : (
                              <Badge variant='light'>
                                Processing
                              </Badge>
                            )
                          }
                        </>
                      )
                    }
                  </div>
                </Modal.Header>
                <Modal.Body>
                  {this.renderModalBody()}
                </Modal.Body>
                <Modal.Footer>
                  {
                    (modal.data && (modal.data.approved_at || modal.data.declined_at)) && (
                      <>
                        {
                          !modal.data.declined_at ? (
                            <Button variant='danger' className='mr-auto' disabled={modal.isLoading || modal.isFormLoading} onClick={this.handleDecline}>
                              <FontAwesomeIcon icon='times' /> Decline
                            </Button>
                          ) : !modal.data.approved_at ? (
                            <Button variant='green' className='mr-auto' disabled={modal.isLoading || modal.isFormLoading} onClick={this.handleAccept}>
                              <FontAwesomeIcon icon='check' /> Accept
                            </Button>
                          ) : null
                        }
                      </>
                    )
                  }
                  <Button variant='light' onClick={this.hideModal} disabled={modal.isLoading || modal.isFormLoading}>
                    Close
                  </Button>
                  {
                    modal.data && (
                      <>
                        {
                          (!modal.data.declined_at && !modal.data.approved_at) && (
                            <>
                              <Button variant='danger' disabled={modal.isLoading || modal.isFormLoading} onClick={this.handleDecline}>
                                <FontAwesomeIcon icon='times' /> Decline
                              </Button>
                              <Button variant='green' disabled={modal.isLoading || modal.isFormLoading} onClick={this.handleAccept}>
                                <FontAwesomeIcon icon='check' /> Accept
                              </Button>
                            </>
                          )
                        }
                        {
                          (modal.data.approved_at || modal.data.declined_at) && (
                            <Button variant='info' disabled={modal.isLoading || modal.isFormLoading} onClick={this.handleSave}>
                              Save
                            </Button>
                          )
                        }
                      </>
                    )
                  }
                </Modal.Footer>
              </Modal>
              <Modal show={examPermitModal.show} onHide={this.hideExamPermitModal} backdrop={(examPermitModal.isLoading || examPermitModal.isFormLoading) ? 'static' : true}>
                <Modal.Header closeButton={!examPermitModal.isLoading && !examPermitModal.isFormLoading}>
                  <Modal.Title>
                    {
                      examPermitModal.profile ? (
                        <div>
                          {examPermitModal.profile.name}
                          <span className='font-italic font-weight-normal'> - Exam Permit</span>
                        </div>
                      ) : 'Exam Permit'
                    }
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {this.renderExamPermitModalBody()}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='light' onClick={this.hideExamPermitModal} disabled={examPermitModal.isLoading || examPermitModal.isFormLoading}>
                    Close
                  </Button>
                  {
                    !examPermitModal.successMessage && (
                      <Button variant='info' onClick={this.handleExamPermit} disabled={examPermitModal.isLoading || examPermitModal.isFormLoading}>
                        Save
                      </Button>
                    )
                  }
                </Modal.Footer>
              </Modal>
              <PromptDeleteModal {...deleteModal} title='Delete transaction' onHide={this.hideDeleteModal} onDelete={this.handleDelete}>
                <div className='font-weight-bold'>
                  Are you sure you want to delete the transaction?
                </div>
                {
                  deleteModal.data && (
                    <Alert variant='light'>
                      <div>{deleteModal.data.mode}</div>
                      <div>
                        {
                          deleteModal.data.transaction_time ?
                            moment(`${deleteModal.data.transaction_date} ${deleteModal.data.transaction_time}`, 'YYYY-MM-DD HH:mm').format('MMM D, YYYY hh:mm A') :
                            moment(deleteModal.data.transaction_date).format('MMMM D, YYYY')
                        }
                      </div>
                      <div>&#8369; {deleteModal.data.amount.toFixed(2)}</div>
                    </Alert>
                  )
                }
              </PromptDeleteModal>
              <Modal show={exportModal.show} onHide={this.hideExportModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Export Transactions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Alert variant='warning'>
                    Only <b>accepted</b> payment transactions will be <b>exported</b>
                  </Alert>
                  <Form.Group>
                    <div>
                      <Form.Label>Order by</Form.Label>
                    </div>
                    <div className='form-check-inline'>
                      <Form.Check
                        id='orderTransaction'
                        type='radio'
                        name='orderBy'
                        value='transaction'
                        label='Transaction Date'
                        onChange={this.handleOrderSelectChange}
                        checked={exportModal.formInputs.order === 'transaction'} />
                      <Form.Check
                        id='orderSubmission'
                        type='radio'
                        name='orderBy'
                        value='submission'
                        label='Submission Date'
                        onChange={this.handleOrderSelectChange}
                        checked={exportModal.formInputs.order === 'submission'} />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type='date'
                      name='start'
                      value={exportModal.formInputs.start}
                      max={exportModal.formInputs.end ? moment(exportModal.formInputs.end).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                      onChange={this.handleDateInputChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type='date'
                      name='end'
                      value={exportModal.formInputs.end}
                      min={exportModal.formInputs.start ? moment(exportModal.formInputs.start).format('YYYY-MM-DD') : ''}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={this.handleDateInputChange} />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='light' onClick={this.hideExportModal}>
                    Close
                  </Button>
                  <a href={`${process.env['REACT_APP_API_BASE_URL']}/staff/payment-transaction/export/${exportModal.formInputs.order}/${exportModal.formInputs.start}/${exportModal.formInputs.end}`} className={`btn btn-green ${this.isExportDisabled() ? 'disabled' : ''}`}>
                    Export
                  </a>
                </Modal.Footer>
              </Modal>
            </Card.Body>
          </Card>
        </Container>
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(PaymentTransaction);