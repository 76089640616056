import React, { Component } from 'react';
import { Alert, Badge, Button, Col, Dropdown, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Validator from 'validatorjs';
import { loadRule } from '../../../../../util';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import PromptDeleteModal from '../../../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

export default class RequestSpecialExam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      specialExamRequest: null,
      isFormLoading: false,
      formError: '',
      formInputs: {
        reason: '',
        startDate: ''
      },
      editModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        formInputs: {
          reason: '',
          startDate: ''
        }
      }
    };
  }
  componentDidMount() {
    const { material, match: { params: { classID } } } = this.props;
    axiosRequest('get', `class/${classID}/material/exam/${material.id}/special-exam-request`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        specialExamRequest: data,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
    loadRule(['date', 'after_or_equal'], {
      after_or_equal: 'The :attribute date must be equal or after the current date and time.'
    });
  }
  handleInputChange = e => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [e.target.name]: e.target.value
      }
    });
  }
  handleReset = () => {
    this.setState({
      ...this.state,
      formInputs: {
        reason: '',
        startDate: ''
      }
    });
  }
  handleRequest = e => {
    e.preventDefault();

    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: ''
    }, () => {
      const { formInputs } = this.state;
      const { material, match: { params: { classID } } } = this.props;

      let validator = new Validator(formInputs, {
        reason: 'required|string|min:3',
        startDate: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: validator.errors.errors[firstKey][0]
        });
        return;
      }

      axiosRequest('post', `class/${classID}/material/exam/${material.id}/special-exam-request`, formInputs, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          specialExamRequest: data,
          isFormLoading: false,
          formInputs: {
            reason: '',
            startDate: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showEditModal = () => {
    const { specialExamRequest } = this.state;

    if (specialExamRequest) {
      this.setState({
        ...this.state,
        editModal: {
          show: true,
          isLoading: false,
          errorMessage: '',
          formInputs: {
            reason: specialExamRequest.reason,
            startDate: moment(specialExamRequest.from).format('YYYY-MM-DDTHH:mm')
          }
        }
      });
    }
    return false;
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        formInputs: {
          reason: '',
          startDate: ''
        }
      }
    });
  }
  handleEditInputChange = e => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        formInputs: {
          ...this.state.editModal.formInputs,
          [e.target.name]: e.target.value
        }
      }
    });
  }
  handleEditRequest = e => {
    e.preventDefault();

    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { editModal } = this.state;
      const { material, match: { params: { classID } } } = this.props;

      let validator = new Validator(editModal.formInputs, {
        reason: 'required|string|min:3',
        startDate: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('patch', `class/${classID}/material/exam/${material.id}/special-exam-request`, editModal.formInputs, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          specialExamRequest: data,
          editModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            formInputs: {
              reason: '',
              startDate: ''
            }
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleDeleteRequest = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { material, match: { params: { classID } } } = this.props;

      axiosRequest('delete', `class/${classID}/material/exam/${material.id}/special-exam-request`, null, ({ data: { message }}) => {
        this.setState({
          ...this.state,
          specialExamRequest: null,
          deleteModal: {
            show: false,
            isLoading: false,
            errorMessage: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  render() {
    const { isLoading, errorMessage, specialExamRequest, formInputs, isFormLoading, formError, editModal, deleteModal } = this.state;
    const { material } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    let duration = material.faculty_load_id ?
                    moment(material.until).diff(material.from) :
                    moment(material.audience.until).diff(material.audience.from);

    return (
      <div>
        {
          specialExamRequest ? (
            <div>
              <div className='d-flex'>
                <div className='mr-2'>Reason:</div>
                <div className='flex-fill font-italic' style={{ whiteSpace: 'pre-line' }}>
                  {specialExamRequest.reason}
                </div>
                {
                  (!specialExamRequest.approved_at || !specialExamRequest.read_at) && (
                    <>
                      <div className='d-none d-lg-block' style={{ whiteSpace: 'nowrap' }}>
                        <Button className='mr-2' variant='info' size='sm' onClick={this.showEditModal}>
                          <FontAwesomeIcon icon='pencil-alt' /> Edit
                        </Button>
                        <Button variant='danger' size='sm' onClick={this.showDeleteModal}>
                          <FontAwesomeIcon icon='trash-alt' /> Delete
                        </Button>
                      </div>
                      <div className='d-lg-none'>
                        <Dropdown>
                          <Dropdown.Toggle variant='link' size='sm'>
                            <FontAwesomeIcon icon='cog' />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={this.showEditModal}><FontAwesomeIcon icon='pencil-alt' /> Edit</Dropdown.Item>
                            <Dropdown.Item onClick={this.showDeleteModal}><FontAwesomeIcon icon='trash-alt' /> Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </>
                  )
                }
              </div>
              <div className='d-flex'>
                <div className='mr-1'>Preferred</div>
                <div className='flex-fill font-italic'>
                  <div>Start: {moment(specialExamRequest.from).format('MMM D, YYYY hh:mm A')}</div>
                  <div>End: {moment(specialExamRequest.until).format('MMM D, YYYY hh:mm A')}</div>
                </div>
              </div>
              <div>
                <span className='mr-2'>Status:</span>
                {
                  specialExamRequest.approved_at ? (
                    <Badge variant='green'>
                      Accepted
                    </Badge>
                  ) : specialExamRequest.declined_at ? (
                    <Badge variant='danger'>
                      Declined
                    </Badge>
                  ) : specialExamRequest.read_at ? (
                    <Badge variant='warning'>
                      Reviewing
                    </Badge>
                  ) : (
                    <Badge variant='light'>
                      Requested
                    </Badge>
                  )
                }
              </div>
              {
                !specialExamRequest.comment ? null :
                  specialExamRequest.approved_at ? (
                    <Alert variant='green' className='mt-2'>
                      {specialExamRequest.comment}
                    </Alert>
                  ) : specialExamRequest.declined_at ? (
                    <Alert variant='danger' className='mt-2'>
                      {specialExamRequest.comment}
                    </Alert>
                  ) : null
              }
            </div>
          ) : (
            <Form onSubmit={this.handleRequest}>
              {
                formError && (
                  <Alert variant='danger'>
                    {formError}
                  </Alert>
                )
              }
              <Form.Row>
                <Form.Group as={Col} md={7}>
                  <Form.Label>Reason</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows='6'
                    name='reason'
                    onChange={this.handleInputChange}
                    value={formInputs.reason}
                    disabled={isFormLoading} />
                </Form.Group>
                <Col md={5}>
                  <Form.Label>Preferred Date and Time</Form.Label>
                  <div>
                    <Form.Group>
                      <Form.Label>Start</Form.Label>
                      <Form.Control
                        type='datetime-local'
                        name='startDate'
                        min={moment().format('YYYY-MM-DDTHH:mm')}
                        onChange={this.handleInputChange}
                        value={formInputs.startDate}
                        disabled={isFormLoading} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>End</Form.Label>
                      <Form.Control
                        type='datetime-local'
                        value={formInputs.startDate ? moment(formInputs.startDate).add(duration, 'milliseconds').format('YYYY-MM-DDTHH:mm') : ''}
                        readOnly />
                    </Form.Group>
                  </div>
                </Col>
              </Form.Row>
              <div className='text-right'>
                <Button variant='danger' className='mr-2' onClick={this.handleReset} disabled={isFormLoading}>
                  Reset
                </Button>
                <Button variant='green' type='submit' disabled={isFormLoading}>
                  <FontAwesomeIcon icon='redo' /> Request special exam
                </Button>
              </div>
            </Form>
          )
        }
        <Modal show={editModal.show} onHide={this.hideEditModal} backdrop={editModal.isLoading ? 'static' : true}>
          <Modal.Header closeButton={!editModal.isLoading}>
            <Modal.Title>Edit request</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleEditRequest}>
            <Modal.Body>
              {
                editModal.errorMessage && (
                  <Alert variant='danger'>
                    {editModal.errorMessage}
                  </Alert>
                )
              }
              <Form.Group>
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  as='textarea'
                  rows='3'
                  name='reason'
                  onChange={this.handleEditInputChange}
                  value={editModal.formInputs.reason}
                  disabled={editModal.isLoading} />
              </Form.Group>
              <div>
                <Form.Label>Preferred Date and Time</Form.Label>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Start</Form.Label>
                    <Form.Control
                      type='datetime-local'
                      name='startDate'
                      min={moment().format('YYYY-MM-DDTHH:mm')}
                      onChange={this.handleEditInputChange}
                      value={editModal.formInputs.startDate}
                      disabled={editModal.isLoading} />
                  </Form.Group>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>End</Form.Label>
                    <Form.Control
                      type='datetime-local'
                      value={editModal.formInputs.startDate ? moment(editModal.formInputs.startDate).add(duration, 'milliseconds').format('YYYY-MM-DDTHH:mm') : ''}
                      readOnly />
                  </Form.Group>
                </Form.Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' onClick={this.hideEditModal} disabled={editModal.isLoading}>
                Cancel
              </Button>
              <Button variant='info' type='submit' disabled={editModal.isLoading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <PromptDeleteModal
          {...deleteModal}
          title='Delete request'
          onHide={this.hideDeleteModal}
          onDelete={this.handleDeleteRequest}>
            <div className='font-weight-bold'>
              <div>Are you sure you want to delete the request?</div>
            </div>
            {
              specialExamRequest && (
                <Alert variant='light'>
                  <div>{specialExamRequest.reason}</div>
                  <div>{`Start: ${moment(specialExamRequest.from).format('MMM D, YYYY hh:mm A')}`}</div>
                  <div>{`End: ${moment(specialExamRequest.until).format('MMM D, YYYY hh:mm A')}`}</div>
                </Alert>
              )
            }
          </PromptDeleteModal>
      </div>
    );
  }
}