import React from 'react';
import { Alert } from 'react-bootstrap';

export default function Attendance(props) {
  function attendanceDescription() {
    switch(props.accountType) {
      case 5:
        return 'The Attendance area of the classroom is used for viewing your recorded attendance.';
      case 4:
        return 'The Attendance area of the classroom is used for viewing your student\'s recorded attendance.';
      case 2:
        return 'The Attendance area of the classroom is used for recording the attendance of the students.';
      default:
        return 'The Attendance area of the classroom is used for viewing the recorded attendance of the students.';
    }
  }
  function student() {
    return (
      <>
        <div className='mb-3'>
          Records are displayed in a table:
          <ol>
            <li><b>Date</b></li>
            <li><b>Mark</b> &mdash; present, late, absent, or excused</li>
            <li><b>Comment</b> &mdash; comment from the faculty</li>
            <li><b>Activity</b> &mdash; you may view your activity log by clicking the <b>View Log</b></li>
          </ol>
        </div>
      </>
    );
  }
  function parent() {
    return (
      <>
        <div className='mb-3'>
          Records are displayed in a table:
          <ol>
            <li><b>Date</b></li>
            <li><b>Mark</b> &mdash; present, late, absent, or excused</li>
            <li><b>Comment</b> &mdash; comment from the faculty</li>
            <li><b>Activity</b> &mdash; you may view your student's activity log by clicking the <b>View Log</b></li>
          </ol>
        </div>
      </>
    );
  }
  function faculty() {
    return (
      <>
        <div className='mb-3'>
          Students' attendance are displayed in a weekly table.
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Activity Log</div>
          <div>
            The activity logs are automatically fetched and inserted to the table.<br /><br />
            Suggestions are already inserted to the table, however, if an attendance record is already saved, the suggestion will be disregarded.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Navigating</div>
          <div>
            <ol>
              <li>
                <div className='font-weight-bold'>Week navigation</div>
                <div>
                  <div className='font-weight-bold'>To view the attendance on the previous week:</div>
                  <div className='ml-3'>
                    {'Click the previous icon (<) at the top-right of the attendance table.'}
                  </div>
                  <div className='font-weight-bold'>To view the attendance on the succeeding week:</div>
                  <div className='ml-3'>
                    {'Click the next icon (>) at the top-right of the attendance table.'}
                  </div>
                </div>
              </li>
              <li><b>Legends</b> &mdash; holds the legends of the attendance remarks</li>
              <li>
                <div className='font-weight-bold'>Dates</div>
                <div>
                  Dates are displayed as monday to sunday.<br />
                  You may also view the schedule on the date specified by hovering at the clock icon beside the weekday.
                </div>
              </li>
              <li><b>Student</b> &mdash; name of the student.</li>
              <li>
                <div className='font-weight-bold'>Activity log</div>
                <div>
                  You may view the activity log of the student in the current week by clicking the list icon.
                </div>
              </li>
              <li><b>Remarks</b> &mdash; display an icon based on the legends</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Updating the attendance</div>
          <Alert variant='warning'>
            Saved attendance record cannot be deleted, however, changes for the attendance are allowed.
          </Alert>
          <div>
            <ol>
              <li>Click on the table cell to change the remark for specific dates.</li>
              <li>Optionally, you may add a comment on an attendance record by hovering over the cell and clicking the blue comment icon.</li>
              <li>To discard the unsaved changes, click <b>Reset</b>.</li>
              <li>Click <b>Save Changes</b> to complete.</li>
            </ol>
          </div>
        </div>
      </>
    );
  }
  function other() {
    return (
      <>
        <div className='mb-3'>
          Students' attendance are displayed in a weekly table.
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Navigating</div>
          <div>
            <ol>
              <li>
                <div className='font-weight-bold'>Week navigation</div>
                <div>
                  <div className='font-weight-bold'>To view the attendance on the previous week:</div>
                  <div className='ml-3'>
                    {'Click the previous icon (<) at the top-right of the attendance table.'}
                  </div>
                  <div className='font-weight-bold'>To view the attendance on the succeeding week:</div>
                  <div className='ml-3'>
                    {'Click the next icon (>) at the top-right of the attendance table.'}
                  </div>
                </div>
              </li>
              <li><b>Legends</b> &mdash; holds the legends of the attendance remarks</li>
              <li>
                <div className='font-weight-bold'>Dates</div>
                <div>
                  Dates are displayed as monday to sunday.<br />
                  You may also view the schedule on the date specified by hovering at the clock icon beside the weekday.
                </div>
              </li>
              <li><b>Student</b> &mdash; name of the student.</li>
              <li>
                <div className='font-weight-bold'>Activity log</div>
                <div>
                  You may view the activity log of the student in the current week by clicking the list icon.
                </div>
              </li>
              <li><b>Remarks</b> &mdash; display an icon based on the legends</li>
            </ol>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className='mb-3'>
        {attendanceDescription()}
      </div>
      {
        props.accountType === 5 ? student() :
          props.accountType === 4 ? parent() :
          props.accountType === 2 ? faculty() : other()
      }
    </>
  );
}