import React, { Component } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

export default class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      isLoading: false
    };

    this.input = null;
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      comment: event.target.value
    });
  }
  handleComment = event => {
    const { comment } = this.state;
    const { postID } = this.props;

    if (!comment) {
      this.input.focus();
    } else {
      this.setState({
        ...this.state,
        isLoading: true
      }, () => {
        this.props.onComment(postID, comment, (hasError) => {
          if (hasError) {
            this.setState({
              ...this.state,
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false,
              comment: ''
            });
          }
        });
      });
    }
  }
  render() {
    const { isLoading, comment } = this.state;
    return (
      <InputGroup>
        <Form.Control
          ref={input => {this.input = input}}
          as='textarea'
          size='sm'
          rows={1}
          style={{ resize: 'none' }}
          placeholder='Write a comment...'
          onChange={this.handleInputChange}
          disabled={isLoading}
          value={comment}
          />
        <InputGroup.Append>
          <Button
            size='sm'
            variant='green'
            onClick={this.handleComment}
            disabled={isLoading}
            >
            Comment
          </Button>
        </InputGroup.Append>
      </InputGroup>
    );
  }
}