import React, { Component } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
} from 'chart.js';
import { Line, Radar } from 'react-chartjs-2';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import hexToRgba from '../../../../../util/helpers/hexToRgba';
import moment from 'moment';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  maintainAspectRatio: false,
  // responsive: false,
  plugins: {
    legend: {
      position: 'top',
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      max: 100,
      ticks: {
        stepSize: 20
      }
    }
  }
};

export default class Performance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      selectedStudent: '',
      isGraphLoading: false,
      activities: null,
      activitiesData: {
        labels: [],
        data: []
      },
      exams: null,
      examsData: {
        labels: [],
        data: []
      },
      attendance: [],
      attendanceData: {
        labels: [],
        data: []
      },
      overAll: []
    };
  }
  componentDidMount() {
    const { schoolClass: { id } } = this.props;
    
    axiosRequest('get', `faculty/class-advisory/${id}/performance`, null, ({ data: { data }}) => {
      let activitiesData = {
        labels: Object.keys(data.activities),
        data: []
      };
      activitiesData.labels.sort((a, b) => {
        let momentA = moment(a, 'MMMM YYYY');
        let momentB = moment(b, 'MMMM YYYY');

        if (momentA.isBefore(momentB, 'month')) {
          return -1;
        } else if (momentA.isAfter(momentB, 'month')) {
          return 1;
        }
  
        return 0;
      });
      for (let i = 0; i < activitiesData.labels.length; i++) {
        let percentageSum = 0;
        for (let j = 0; j < data.activities[activitiesData.labels[i]].length; j++) {
          percentageSum += data.activities[activitiesData.labels[i]][j].percentage;
        }
        activitiesData.data = [...activitiesData.data, (percentageSum/data.activities[activitiesData.labels[i]].length)];
      }
      activitiesData.labels = activitiesData.labels.map(l => moment(l, 'MMMM YYYY').format('MMM YYYY'));

      let examsData = {
        labels: Object.keys(data.exams),
        data: []
      };
      examsData.labels.sort((a, b) => {
        let momentA = moment(a, 'MMMM YYYY');
        let momentB = moment(b, 'MMMM YYYY');

        if (momentA.isBefore(momentB, 'month')) {
          return -1;
        } else if (momentA.isAfter(momentB, 'month')) {
          return 1;
        }
  
        return 0;
      });
      for (let i = 0; i < examsData.labels.length; i++) {
        let percentageSum = 0;
        for (let j = 0; j < data.exams[examsData.labels[i]].length; j++) {
          percentageSum += data.exams[examsData.labels[i]][j].percentage;
        }
        examsData.data = [...examsData.data, (percentageSum/data.exams[examsData.labels[i]].length)];
      }
      examsData.labels = examsData.labels.map(l => moment(l, 'MMMM YYYY').format('MMM YYYY'));

      let attendanceData = {
        labels: Object.keys(data.attendance),
        data: []
      };
      for (let i = 0; i < attendanceData.labels.length; i++) {
        let totalPresent = 0;
        for (let j = 0; j < data.attendance[attendanceData.labels[i]].length; j++) {
          if (data.attendance[attendanceData.labels[i]][j].mark === 'Present') {
            totalPresent++;
          }
        }
        attendanceData.data = [...attendanceData.data, Math.round(((totalPresent/data.attendance[attendanceData.labels[i]].length)*100 + Number.EPSILON)*100)/100];
      }
      attendanceData.labels = attendanceData.labels.map(l => moment(l, 'MMMM YYYY').format('MMM YYYY'));

      let overAll = [
        (activitiesData.data.reduce((p, c) => p + c, 0)/activitiesData.data.length).toFixed(2),
        (examsData.data.reduce((p, c) => p + c, 0)/examsData.data.length).toFixed(2),
        (attendanceData.data.reduce((p, c) => p + c, 0)/attendanceData.data.length).toFixed(2)
      ];

      this.setState({
        ...this.state,
        ...data,
        activitiesData,
        examsData,
        attendanceData,
        overAll,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleSelectStudent = e => {
    let studentID = e.target.value;

    this.setState({
      ...this.state,
      selectedStudent: studentID,
      isGraphLoading: true
    }, () => {
      const { activities, attendance, exams } = this.state;

      let activitiesData = {
        labels: Object.keys(activities),
        data: []
      };
      let examsData = {
        labels: Object.keys(exams),
        data: []
      };
      let attendanceData = {
        labels: Object.keys(attendance),
        data: []
      };
      let overAll = [];

      if (studentID) {
        let studentActivitiesData = {
          labels: [],
          data: []
        };
        activitiesData.labels.sort((a, b) => {
          let momentA = moment(a, 'MMMM YYYY');
          let momentB = moment(b, 'MMMM YYYY');
  
          if (momentA.isBefore(momentB, 'month')) {
            return -1;
          } else if (momentA.isAfter(momentB, 'month')) {
            return 1;
          }
  
          return 0;
        });
        for (let i = 0; i < activitiesData.labels.length; i++) {
          let percentageSum = 0;
          let numberOfItems = 0;
          for (let j = 0; j < activities[activitiesData.labels[i]].length; j++) {
            if (activities[activitiesData.labels[i]][j].studentID === +studentID) {
              percentageSum += activities[activitiesData.labels[i]][j].percentage;
              numberOfItems++;
            }
          }
          if (numberOfItems > 0) {
            studentActivitiesData.labels = [...studentActivitiesData.labels, activitiesData.labels[i]];
            studentActivitiesData.data = [...studentActivitiesData.data, (percentageSum/numberOfItems)];
          }
        }
        studentActivitiesData.labels = studentActivitiesData.labels.map(l => moment(l, 'MMMM YYYY').format('MMM YYYY'));
        activitiesData = studentActivitiesData;
  
        let studentExamsData = {
          labels: [],
          data: []
        };
        examsData.labels.sort((a, b) => {
          let momentA = moment(a, 'MMMM YYYY');
          let momentB = moment(b, 'MMMM YYYY');
  
          if (momentA.isBefore(momentB, 'month')) {
            return -1;
          } else if (momentA.isAfter(momentB, 'month')) {
            return 1;
          }
  
          return 0;
        });
        for (let i = 0; i < examsData.labels.length; i++) {
          let percentageSum = 0;
          let numberOfItems = 0;
          for (let j = 0; j < exams[examsData.labels[i]].length; j++) {
            if (exams[examsData.labels[i]][j].studentID === +studentID) {
              percentageSum += exams[examsData.labels[i]][j].percentage;
              numberOfItems++;
            }
          }
          if (numberOfItems > 0) {
            studentExamsData.labels = [...studentExamsData.labels, examsData.labels[i]];
            studentExamsData.data = [...studentExamsData.data, (percentageSum/numberOfItems)];
          }
        }
        studentExamsData.labels = studentExamsData.labels.map(l => moment(l, 'MMMM YYYY').format('MMM YYYY'));
        examsData = studentExamsData;
  
        let studentAttendanceData = {
          labels: [],
          data: []
        };
        for (let i = 0; i < attendanceData.labels.length; i++) {
          let totalPresent = 0;
          let numberOfItems = 0;
          for (let j = 0; j < attendance[attendanceData.labels[i]].length; j++) {
            if (attendance[attendanceData.labels[i]][j].account_id === +studentID) {
              numberOfItems++;
              if (attendance[attendanceData.labels[i]][j].mark === 'Present') {
                totalPresent++;
              }
            }
          }
          if (numberOfItems > 0) {
            studentAttendanceData.labels = [...studentAttendanceData.labels, attendanceData.labels[i]];
            studentAttendanceData.data = [...studentAttendanceData.data, Math.round(((totalPresent/numberOfItems)*100 + Number.EPSILON)*100)/100];
          }
        }
        studentAttendanceData.labels = studentAttendanceData.labels.map(l => moment(l, 'MMMM YYYY').format('MMM YYYY'));
        attendanceData = studentAttendanceData;
  
        overAll = [
          (activitiesData.data.reduce((p, c) => p + c, 0)/activitiesData.data.length).toFixed(2),
          (examsData.data.reduce((p, c) => p + c, 0)/examsData.data.length).toFixed(2),
          (attendanceData.data.reduce((p, c) => p + c, 0)/attendanceData.data.length).toFixed(2)
        ];
      } else {
        activitiesData.labels.sort((a, b) => {
          let momentA = moment(a, 'MMMM YYYY');
          let momentB = moment(b, 'MMMM YYYY');
  
          if (momentA.isBefore(momentB, 'month')) {
            return -1;
          } else if (momentA.isAfter(momentB, 'month')) {
            return 1;
          }
  
          return 0;
        });
        for (let i = 0; i < activitiesData.labels.length; i++) {
          let percentageSum = 0;
          for (let j = 0; j < activities[activitiesData.labels[i]].length; j++) {
            percentageSum += activities[activitiesData.labels[i]][j].percentage;
          }
          activitiesData.data = [...activitiesData.data, (percentageSum/activities[activitiesData.labels[i]].length)];
        }
        activitiesData.labels = activitiesData.labels.map(l => moment(l, 'MMMM YYYY').format('MMM YYYY'));
  
        examsData.labels.sort((a, b) => {
          let momentA = moment(a, 'MMMM YYYY');
          let momentB = moment(b, 'MMMM YYYY');
  
          if (momentA.isBefore(momentB, 'month')) {
            return -1;
          } else if (momentA.isAfter(momentB, 'month')) {
            return 1;
          }
  
          return 0;
        });
        for (let i = 0; i < examsData.labels.length; i++) {
          let percentageSum = 0;
          for (let j = 0; j < exams[examsData.labels[i]].length; j++) {
            percentageSum += exams[examsData.labels[i]][j].percentage;
          }
          examsData.data = [...examsData.data, (percentageSum/exams[examsData.labels[i]].length)];
        }
        examsData.labels = examsData.labels.map(l => moment(l, 'MMMM YYYY').format('MMM YYYY'));
  
        for (let i = 0; i < attendanceData.labels.length; i++) {
          let totalPresent = 0;
          for (let j = 0; j < attendance[attendanceData.labels[i]].length; j++) {
            if (attendance[attendanceData.labels[i]][j].mark === 'Present') {
              totalPresent++;
            }
          }
          attendanceData.data = [...attendanceData.data, Math.round(((totalPresent/attendance[attendanceData.labels[i]].length)*100 + Number.EPSILON)*100)/100];
        }
        attendanceData.labels = attendanceData.labels.map(l => moment(l, 'MMMM YYYY').format('MMM YYYY'));
  
        overAll = [
          (activitiesData.data.reduce((p, c) => p + c, 0)/activitiesData.data.length).toFixed(2),
          (examsData.data.reduce((p, c) => p + c, 0)/examsData.data.length).toFixed(2),
          (attendanceData.data.reduce((p, c) => p + c, 0)/attendanceData.data.length).toFixed(2)
        ];
      }

      this.setState({
        ...this.state,
        activitiesData,
        examsData,
        attendanceData,
        overAll,
        isGraphLoading: false
      });
    });
  }
  renderGraph = () => {
    const {
      activitiesData,
      examsData,
      attendanceData,
      overAll
    } = this.state;
    const { schoolClass } = this.props;

    let hexColor = schoolClass?.year_level?.school_level?.theme;
    hexColor = hexColor ?? '#131b7f';
    let overAllBgColor = hexToRgba(hexColor, 0.2);
    let backgroundColor = hexToRgba(hexColor, 0.5);
    let borderColor = hexToRgba(hexColor);

    let showAll = activitiesData.labels.length > 0 && examsData.labels.length > 0 && attendanceData.labels.length > 0;

    return (
      <Row>
        {
          showAll && (
            <Col lg={{ span: 4, order: 2 }}>
              <div className='sticky-top pt-5 mt-n5'>
                <div>
                  <Radar
                    options={{
                      scales: {
                        r: {
                          beginAtZero: true,
                          max: 100,
                          ticks: {
                            stepSize: 20
                          }
                        }
                      }
                    }}
                    data={{
                      labels: ['Activities', 'Exams', 'Attendance'],
                      datasets: [
                        {
                          label: 'Overall performance',
                          data: overAll,
                          backgroundColor: overAllBgColor,
                          borderColor,
                          borderWidth: 1,
                        }
                      ]
                    }} />
                </div>
              </div>
            </Col>
          )
        }
        <Col lg={{ span: showAll ? 8 : 12, order: 1 }}>
          {
            attendanceData.labels.length > 0 && (
              <div className='position-relative' style={{ height: '40vh' }}>
                <Line
                  height='200'
                  className='mb-3'
                  options={{
                    ...options,
                    plugins: {
                      ...options.plugins,
                      title: {
                        display: true,
                        text: 'Attendance'
                      }
                    }
                  }}
                  data={{
                    labels: attendanceData.labels,
                    datasets: [
                      {
                        label: 'Percentage',
                        data: attendanceData.data,
                        backgroundColor,
                      }
                    ]
                  }} />
              </div>
            )
          }
          <div className='dropdown-divider'></div>
          {
            activitiesData.labels.length > 0 && (
              <div className='position-relative' style={{ height: '40vh' }}>
                <Line
                  height='200'
                  className='mb-3'
                  options={{
                    ...options,
                    plugins: {
                      ...options.plugins,
                      title: {
                        display: true,
                        text: 'Activities'
                      }
                    }
                  }}
                  data={{
                    labels: activitiesData.labels,
                    datasets: [
                      {
                        label: 'Percentage',
                        data: activitiesData.data,
                        backgroundColor,
                      }
                    ]
                  }} />
              </div>
            )
          }
          <div className='dropdown-divider'></div>
          {
            examsData.labels.length > 0 && (
              <div className='position-relative' style={{ height: '40vh' }}>
                <Line
                  height='200'
                  options={{
                    ...options,
                    plugins: {
                      ...options.plugins,
                      title: {
                        display: true,
                        text: 'Exams'
                      }
                    }
                  }}
                  data={{
                    labels: examsData.labels,
                    datasets: [
                      {
                        label: 'Percentage',
                        data: examsData.data,
                        backgroundColor,
                      }
                    ]
                  }} />
              </div>
            )
          }
        </Col>
      </Row>
    );
  }
  render() {
    const {
      isLoading,
      errorMessage,
      activities,
      exams,
      attendance,
      selectedStudent
    } = this.state;
    const { schoolClass: { students } } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if ((!selectedStudent && !activities && !exams && !attendance) || students.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <div>
        <Form.Control as='select' className='mb-3' value={selectedStudent} onChange={this.handleSelectStudent}>
          <option value=''>All students</option>
          {
            students.map(student => (
              <option key={student.id} value={student.id}>{student.name}</option>
            ))
          }
        </Form.Control>
        {this.renderGraph()}
      </div>
    );
  }
}