import React, { Component  } from 'react';
import { Alert, Button, Col, Form, InputGroup, Modal, OverlayTrigger, Tooltip, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import NameLink from '../../../common/NameLink/NameLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import './style.scss';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Attendance extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      attendance: [],
      schedule: {
        dateSchedule: {},
        weeklySchedule: {}
      },
      weekdays: [],
      currentDayIndex: 0,
      currentDisplayIndex: 0,
      isLoading: true,
      errorMessage: '',
      commentModal: {
        title: '',
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null
      },
      activityLog: [],
      logModal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        data: {
          student: null,
          logs: {}
        }
      },
      isAttendanceLoading: false,
      attendanceError: '',
      loadedWeeks: [],
      asyncSchedules: []
    };
  }
  componentDidMount() {
    const { classID } = this.props.match.params;
    axiosRequest('get', `school-admin/class/${classID}/attendance`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        loadedWeeks: [
          moment().isoWeek()
        ]
      }, () => this.getWeek());
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  getAttendance = (weekStart, currentDisplayIndex) => {
    const { classID } = this.props.match.params;
    const isoWeek = moment(weekStart).isoWeek();

    if (this.state.loadedWeeks.indexOf(isoWeek) === -1) {
      this.setState({
        ...this.state,
        isAttendanceLoading: true,
        attendanceError: ''
      }, () => {
        axiosRequest('get', `school-admin/class/${classID}/attendance/${weekStart.format('YYYY-MM-DD')}`, null, ({ data: { data } }) => {
          this.setState({
            ...this.state,
            attendance: [...this.state.attendance, ...data.attendance],
            asyncSchedules: [...this.state.asyncSchedules, ...data.asyncSchedules],
            isAttendanceLoading: false,
            attendanceError: '',
            loadedWeeks: [
              ...this.state.loadedWeeks,
              isoWeek
            ]
          }, () => this.getWeek(weekStart, currentDisplayIndex));
        }, (error) => {
          this.setState({
            ...this.state,
            isAttendanceLoading: false,
            attendanceError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    } else {
      this.getWeek(weekStart, currentDisplayIndex);
    }
  }
  getWeek = (weekStart, currentDisplayIndex) => {
    if(!weekStart) {
      weekStart = moment().startOf('isoWeek');
    }
    let days = [];
    let currentDayIndex = 0;

    for (let i = 0; i < 7; i++) {
      let temp = moment(weekStart).add(i, 'days');

      if (temp.isSame(moment(), 'day')) {
        currentDayIndex = i+1;
      }

      days.push({
        date: temp.format('YYYY-MM-DD'),
        weekdayFull: temp.format('dddd'),
        dateDisplay: temp.format('MMM D'),
        weekday: temp.format('ddd')
      });
    }

    this.setState({
      ...this.state,
      weekdays: days,
      currentDayIndex,
      currentDisplayIndex: currentDisplayIndex ? currentDisplayIndex : (currentDayIndex === 0 ? 1 : currentDayIndex),
      isLoading: false,
      activityLog: []
    });
  }
  getActivityLog = studentID => {
    const { weekdays } = this.state;
    const { classID } = this.props.match.params;

    axiosRequest('post', `school-admin/class/${classID}/attendance/activity/student/${studentID}`, {
      date: weekdays[0].date
    }, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        logModal: {
          ...this.state.logModal,
          data: {
            ...this.state.logModal.data,
            logs: data
          },
          isLoading: false,
          errorMessage: ''
        },
        activityLog: {
          ...this.state.activityLog,
          [studentID]: data
        },
      });
    }, (error) => {
      this.setState({
        ...this.state,
        logModal: {
          ...this.state.logModal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleStudentActivityRefresh = event => {
    this.setState({
      ...this.state,
      logModal: {
        ...this.state.logModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { logModal } = this.state;

      this.getActivityLog(logModal.data.student.id);
    });
  }
  showActivityLog = student => {
    const { activityLog } = this.state;

    this.setState({
      ...this.state,
      logModal: {
        show: true,
        isLoading: true,
        errorMessage: '',
        data: {
          student,
          logs: {...activityLog[student.id]}
        }
      }
    }, () => {
      if (activityLog[student.id]) {
        this.setState({
          ...this.state,
          logModal: {
            ...this.state.logModal,
            isLoading: false,
            errorMessage: ''
          }
        });
      } else {
        this.getActivityLog(student.id);
      }
    });
  }
  hideActivityLog = () => {
    this.setState({
      ...this.state,
      logModal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        data: {
          student: null,
          logs: {}
        }
      }
    });
  }
  handleNextDay = () => {
    const { currentDisplayIndex } = this.state;

    if (currentDisplayIndex === 7) {
      this.handleNextWeek();
    } else {
      this.setState({
        ...this.state,
        currentDisplayIndex: currentDisplayIndex+1
      });
    }
  }
  handlePrevDay = () => {
    const { weekdays, currentDisplayIndex } = this.state;

    if (currentDisplayIndex === 1) {
      this.getAttendance(moment(weekdays[0].date).subtract(1, 'weeks'), 7);
    } else {
      this.setState({
        ...this.state,
        currentDisplayIndex: currentDisplayIndex-1
      });
    }
  }
  handleNextWeek = () => {
    const { weekdays } = this.state;

    this.getAttendance(moment(weekdays[0].date).add(1, 'weeks'), 1);
  }
  handlePrevWeek = () => {
    const { weekdays } = this.state;

    this.getAttendance(moment(weekdays[0].date).subtract(1, 'weeks'));
  }
  getScheduleType = day => {
    const { schedule: { dateSchedule, weeklySchedule } } = this.state;
    let scheduleType = null;

    if (weeklySchedule[day.weekdayFull]) {
      scheduleType = weeklySchedule[day.weekdayFull].type;
    }

    if (dateSchedule[day.date]) {
      scheduleType = dateSchedule[day.date].type;
    }

    return scheduleType;
  }
  showCommentModal = (event, attendanceData, student) => {
    event.stopPropagation();

    this.setState({
      ...this.state,
      commentModal: {
        title: 'Comment',
        show: true,
        isLoading: false,
        errorMessage: '',
        data: {
          attendance: attendanceData,
          student
        }
      }
    });
  }
  hideCommentModal = () => {
    this.setState({
      ...this.state,
      commentModal: {
        title: '',
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null
      }
    });
  }
  handleViewMaterial = material => {
    const { classID } = this.props.match.params;

    this.props.history.push(`/school-admin/class/${classID}/${material.type}/${material.id}`);
  }
  renderActionIcon = day => {
    let asyncSchedule = this.getAsyncSchedule(day);
    if (asyncSchedule) {
      return (
        <div className='text-center mb-1 pb-1 border-bottom'>
          <OverlayTrigger
            overlay={
              <Tooltip>
                View asynchronous material
              </Tooltip>
            }
            trigger={['hover', 'focus']}>
            <span onClick={() => this.handleViewMaterial(asyncSchedule.material)}>
              <div className='fa-layers schedule-icon text-primary'>
                <FontAwesomeIcon icon='file' size='lg' />
                <FontAwesomeIcon icon='eye' inverse transform='shrink-5 down-3' />
              </div>
            </span>
          </OverlayTrigger>
        </div>
      );
    }

    return null;
  }
  getAsyncSchedule = day => {
    const { asyncSchedules, schedule: { weeklySchedule, dateSchedule } } = this.state;

    let schedule = null;
    if (weeklySchedule[day.weekdayFull]) {
      schedule = weeklySchedule[day.weekdayFull];
    }

    if (dateSchedule[day.date]) {
      schedule = dateSchedule[day.date];
    }

    if(schedule && schedule.type === 'Asynchronous') {
      let asyncSchedule = asyncSchedules.find(as => as.schedule_date === day.date && as.start_time === schedule.start_time && as.end_time === schedule.end_time);
      return asyncSchedule;
    }

    return null;
  }
  displayAsyncSchedule = (day, schedule) => {
    const { asyncSchedules } = this.state;

    if(schedule.type === 'Asynchronous') {
      let asyncSchedule = asyncSchedules.find(as => as.schedule_date === day.date && as.start_time === schedule.start_time && as.end_time === schedule.end_time);
      if (asyncSchedule) {
        return (
          <>
            <div>{schedule.type}</div>
            <div>{moment(asyncSchedule.material.from).format('MMM D hh:mm A')} - {moment(asyncSchedule.material.until).format('MMM D hh:mm A')}</div>
          </>
        );
      }
    }

    return (
      <div>{schedule.type}</div>
    );
  }
  renderSchedule = day => {
    const { schedule: { weeklySchedule, dateSchedule } } = this.state;
    const hasScheduleDisplay = schedule => {
      let scheduleType = schedule.type ? this.displayAsyncSchedule(day, schedule) : null;
      return (
        <>
          {
            (!schedule.start_time && !schedule.end_time) ? (
              <div>No schedule</div>
            ) : (
              <div>
                <div>{moment(schedule.start_time, 'HH:mm').format('hh:mm A')} - {moment(schedule.end_time, 'HH:mm').format('hh:mm A')}</div>
                {scheduleType}
              </div>
            )
          }
        </>
      );
    };
    let tooltip = null;

    if (weeklySchedule[day.weekdayFull]) {
      tooltip = (
        <Tooltip>
          {hasScheduleDisplay(weeklySchedule[day.weekdayFull])}
        </Tooltip>
      );
    }

    if (dateSchedule[day.date]) {
      tooltip = (
        <Tooltip>
          {hasScheduleDisplay(dateSchedule[day.date])}
        </Tooltip>
      );
    }

    return tooltip ? (
      <OverlayTrigger
        overlay={tooltip}
        trigger={['hover', 'focus']}>
        <FontAwesomeIcon icon='clock' className='schedule-icon text-primary' />
      </OverlayTrigger>
    ) : null;
  }
  renderIcon = (weekdayIndex, student) => {
    const { weekdays, attendance } = this.state;

    if (attendance.length > 0) {
      const weekday = weekdays[weekdayIndex].date;
      const attendanceData = attendance.find(att => att.attendance_date === weekday && att.account_id === student.id);

      if (attendanceData) {
        const iconMap = {
          Present: <FontAwesomeIcon icon='check-circle' className='text-green' />,
          Absent: <FontAwesomeIcon icon={['far', 'circle']} className='text-danger' />,
          Late: <FontAwesomeIcon icon='dot-circle' className='text-danger' />,
          Excused: <FontAwesomeIcon icon='circle' className='text-green' />
        };
        return (
          <div style={{ height: '5rem' }}>
            <div style={{ height: '35%' }}></div>
            <div className=''>{iconMap[attendanceData.mark]}</div>
            <div className={`text-right comment-icon px-1 ${attendanceData.comment ? 'show' : ''}`} style={{ marginTop: '4%' }}>
              <span onClick={e => this.showCommentModal(e, attendanceData, student)} title='Comment'>
                <FontAwesomeIcon icon='comment' className='text-info' />
              </span>
            </div>
          </div>
        );
      }
    }
    return null;
  }
  renderActivityLogBody = () => {
    const { logModal, weekdays } = this.state;

    if (!logModal.data.student) {
      return null;
    }

    if (logModal.isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (logModal.errorMessage) {
      return (
        <Alert variant='danger'>
          {logModal.errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        {
          Object.keys(logModal.data.logs).length > 0 ? weekdays.map((weekday, key) => {
            const currentDate = moment().format('YYYY-MM-DD');
            return moment(weekday.date).isAfter(currentDate) ? null : (
              <div key={key}>
                <b>{moment(weekday.date).format('MMMM D, YYYY')}</b>
                {
                  logModal.data.logs[weekday.date] ? (
                    <ul>
                    {
                      logModal.data.logs[weekday.date].map(log => (
                        <li key={log.id}>{log.description} <small className='font-italic' style={{ color: '#6c757d' }}>&mdash;{moment(log.created_at).format('hh:mm A')}</small></li>
                      ))
                    }
                    </ul>
                  ) : (
                    <Alert variant='light'>
                      No activities logged for this day.
                    </Alert>
                  )
                }
              </div>
            )
          }) : (
            <Alert variant='light'>
              No activities logged for this week.
            </Alert>
          )
        }
      </div>
    );
  }
  renderCell = (weekdayIndex, student) => {
    const { weekdays, currentDisplayIndex } = this.state;

    let scheduleType = this.getScheduleType(weekdays[weekdayIndex]);
    let platformType = student.student_learning_platform ? student.student_learning_platform.platform : null;

    if (platformType && platformType === 'Digital-Modular' && scheduleType && scheduleType === 'Synchronous') {
      return (
        <td className={`${currentDisplayIndex === (weekdayIndex+1) ? 'current-display' : ''} disabled`} title='Unable to set attendance to a synchronous schedule for students on "Digital-Modular" learning platform'>
          
        </td>
      );
    }

    let asyncSchedule = this.getAsyncSchedule(weekdays[weekdayIndex]);
    if (asyncSchedule && moment().isSameOrBefore(asyncSchedule.material.until)) {
      return (
        <td className={`${currentDisplayIndex === (weekdayIndex+1) ? 'current-display' : ''} disabled`} title='Can only set attendance when the availability of the material has lapsed.'>
          { this.renderIcon(weekdayIndex, student) }
        </td>
      );
    }

    return (
      <td className={currentDisplayIndex === (weekdayIndex+1) ? 'current-display' : ''}>
        { this.renderIcon(weekdayIndex, student) }
      </td>
    );
  }
  render() {
    const {
      isLoading,
      errorMessage,
      students,
      weekdays,
      currentDayIndex,
      currentDisplayIndex,
      commentModal,
      logModal,
      isLogLoading,
      logError,
      logText,
      isAttendanceLoading,
      attendanceError
    } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (students.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    const male = students.filter(s => s.gender === 'Male');
    const female = students.filter(s => s.gender === 'Female');

    return (
      <div className='attendance'>
        <Row>
          <Col className='d-md-none'>
            <InputGroup>
              <InputGroup.Prepend>
                <Button size='sm' onClick={this.handlePrevDay} disabled={isAttendanceLoading}>
                  <FontAwesomeIcon icon='chevron-left' />
                </Button>
              </InputGroup.Prepend>
              <Form.Control className='text-center' type='text' size='sm' value={weekdays[currentDisplayIndex-1].dateDisplay} readOnly />
              <InputGroup.Append>
                <Button size='sm' onClick={this.handleNextDay} disabled={isAttendanceLoading}>
                  <FontAwesomeIcon icon='chevron-right' />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col md={8} className='text-md-right'>
            {
              isLogLoading ? (
                <Alert variant='light' className='py-1 mt-1 mx-0 mb-0 mt-md-0 d-md-inline-block'>
                  <LoadingIcon /> {logText}
                </Alert>
              ) : logError ? (
                <Alert variant='danger' className='py-1 mt-1 mx-0 mb-0 mt-md-0 d-md-inline-block'>
                  {logError}
                </Alert>
              ) : null
            }
          </Col>
          <Col className='d-none d-md-block' md={4}>
            <InputGroup>
              <InputGroup.Prepend>
                <Button size='sm' onClick={this.handlePrevWeek} disabled={isAttendanceLoading}>
                  <FontAwesomeIcon icon='chevron-left' />
                </Button>
              </InputGroup.Prepend>
              <Form.Control className='text-center' type='text' size='sm' value={`${weekdays[0].dateDisplay} - ${weekdays[6].dateDisplay}`} readOnly />
              <InputGroup.Append>
                <Button size='sm' onClick={this.handleNextWeek} disabled={isAttendanceLoading}>
                  <FontAwesomeIcon icon='chevron-right' />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
        {
          isAttendanceLoading ? (
            <LoadingIcon />
          ) : attendanceError ? (
            <Alert variant='danger'>
              {attendanceError}
            </Alert>
          ) : (
            <>
              <Table bordered responsive size='sm' className='attendance-table mt-2'>
                <colgroup>
                  <col style={{ width: '30%' }}></col>
                  <col className={currentDayIndex === 1 ? 'current-day' : ''}></col>
                  <col className={currentDayIndex === 2 ? 'current-day' : ''}></col>
                  <col className={currentDayIndex === 3 ? 'current-day' : ''}></col>
                  <col className={currentDayIndex === 4 ? 'current-day' : ''}></col>
                  <col className={currentDayIndex === 5 ? 'current-day' : ''}></col>
                  <col className={currentDayIndex === 6 ? 'current-day' : ''}></col>
                  <col className={currentDayIndex === 7 ? 'current-day' : ''}></col>
                </colgroup>
                <thead>
                  <tr>
                    <th className='pl-3'>
                      <small className='font-weight-bold'>
                        <div className='d-inline-block w-50'>
                          <div><FontAwesomeIcon icon='check-circle' className='text-green' /> Present</div>
                          <div><FontAwesomeIcon icon={['far', 'circle']} className='text-danger' /> Absent</div>
                        </div>
                        <div className='d-inline-block'>
                          <div><FontAwesomeIcon icon='dot-circle' className='text-danger' /> Late</div>
                          <div><FontAwesomeIcon icon='circle' className='text-green' /> Excused</div>
                        </div>
                      </small>
                    </th>
                    {
                      weekdays.map((day, index) => (
                        <th key={index} className={`text-center${(currentDisplayIndex-1) === index ? ' current-display' : ''}`} style={{ width: '10%' }}>
                          <small>
                            {this.renderActionIcon(day)}
                            <div>{day.dateDisplay}</div>
                            <div className='font-weight-bold'>
                              <span className='mr-1'>
                                {day.weekday}
                              </span>
                                { this.renderSchedule(day) }
                            </div>
                          </small>
                        </th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    male.length > 0 && (
                      <>
                        <tr className='gender-row-title bg-green text-white'>
                          <th className='text-center'>
                            <div className='mb-0'>Male</div>
                            <small className='font-italic'>{male.length} student{male.length !== 1 ? 's' : ''}</small>
                          </th>
                          <td colspan='7'></td>
                        </tr>
                        {
                          male.map(student => {
                            return (
                              <tr key={student.id}>
                                <th className='pl-2' style={{ height: '5rem' }}>
                                  <div className='d-flex align-items-center'>
                                    <div className='flex-fill'>
                                      <NameLink
                                        id={student.id}
                                        name={student.formal_name}
                                        image={student.image}
                                        learningPlatform={student.student_learning_platform ? student.student_learning_platform.platform : null}
                                        rank={student.rank} />
                                    </div>
                                    {
                                      (!isLogLoading && !logError) && (
                                      <div>
                                        <Button variant='light' size='sm' title='View activity log' onClick={e => this.showActivityLog(student)}>
                                          <FontAwesomeIcon icon={['fas', 'th-list']} />
                                        </Button>
                                      </div>
                                      )
                                    }
                                  </div>
                                </th>
                                { this.renderCell(0, student) }
                                { this.renderCell(1, student) }
                                { this.renderCell(2, student) }
                                { this.renderCell(3, student) }
                                { this.renderCell(4, student) }
                                { this.renderCell(5, student) }
                                { this.renderCell(6, student) }
                              </tr>
                            );
                          })
                        }
                      </>
                    )
                  }
                  {
                    female.length > 0 && (
                      <>
                        <tr className='gender-row-title bg-green text-white'>
                          <th className='text-center'>
                            <div className='mb-0'>Female</div>
                            <small className='font-italic'>{female.length} student{female.length !== 1 ? 's' : ''}</small>
                          </th>
                          <td colspan='7'></td>
                        </tr>
                        {
                          female.map(student => {
                            return (
                              <tr key={student.id}>
                                <th className='pl-2' style={{ height: '5rem' }}>
                                  <div className='d-flex align-items-center'>
                                    <div className='flex-fill'>
                                      <NameLink
                                        id={student.id}
                                        name={student.formal_name}
                                        image={student.image}
                                        learningPlatform={student.student_learning_platform ? student.student_learning_platform.platform : null}
                                        rank={student.rank} />
                                    </div>
                                    {
                                      (!isLogLoading && !logError) && (
                                      <div>
                                        <Button variant='light' size='sm' title='View activity log' onClick={e => this.showActivityLog(student)}>
                                          <FontAwesomeIcon icon={['fas', 'th-list']} />
                                        </Button>
                                      </div>
                                      )
                                    }
                                  </div>
                                </th>
                                { this.renderCell(0, student) }
                                { this.renderCell(1, student) }
                                { this.renderCell(2, student) }
                                { this.renderCell(3, student) }
                                { this.renderCell(4, student) }
                                { this.renderCell(5, student) }
                                { this.renderCell(6, student) }
                              </tr>
                            );
                          })
                        }
                      </>
                    )
                  }
                </tbody>
              </Table>
              <div className='h6 font-italic px-1 text-right mb-3'>
                Total of <span className='font-weight-bold'>{students.length}</span> students
              </div>
              <Modal show={logModal.show} onHide={this.hideActivityLog}>
                <Modal.Header closeButton>
                  <div className='modal-title h5'>{logModal.data.student ? logModal.data.student.name : ''}</div>
                </Modal.Header>
                <Modal.Body>
                  {this.renderActivityLogBody()}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='light' onClick={this.hideActivityLog}>Close</Button>
                </Modal.Footer>
              </Modal>
              <Modal show={commentModal.show} onHide={this.hideCommentModal}>
                <Modal.Header closeButton>
                  <div className='modal-title h5'>{commentModal.title}</div>
                </Modal.Header>
                <Modal.Body>
                  {
                    commentModal.data && (
                      <>
                        <b>
                          <div>{commentModal.data.student.name}</div>
                          <div className='mb-3'>{moment(commentModal.data.attendance.attendance_date).format('MMMM D, YYYY')}</div>
                        </b>
                        <Form.Control
                          as='textarea'
                          rows={4}
                          value={commentModal.data.attendance.comment}
                          style={{ resize: 'none' }}
                          readOnly
                        />
                      </>
                    )
                  }
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='danger' onClick={this.hideCommentModal}>Close</Button>
                </Modal.Footer>
              </Modal>
            </>
          )
        }
      </div>
    );
  }
}