import React, { Component } from 'react';
import { Alert, Button, Card, Carousel, Col, Image, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../actions';
import moment from 'moment';
import axiosRequest from '../../../util/helpers/axiosRequest';
import './style.scss';
import NameLink from '../../common/NameLink/NameLink';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      announcements: [],
      viewModal: {
        show: false,
        announcement: null
      }
    };
  }
  componentDidMount() {
    axiosRequest('get', 'announcement', null, ({data: { data }}) => {
      this.setState({
        ...this.state,
        isLoading: false,
        announcements: data
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  onManage = event => {
    const { userType } = this.props.match.params;
    this.props.history.push(`/${userType}/announcement`);
  }
  handleFileDownload = fileID => {
    const { viewModal } = this.state;

    axiosRequest('get', `announcement/${viewModal.announcement.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  showViewModal = announcement => {
    this.setState({
      ...this.state,
      viewModal: {
        show: true,
        announcement
      }
    });
  }
  hideViewModal = () => {
    this.setState({
      ...this.state,
      viewModal: {
        shiow: false,
        announcement: null
      }
    });
  }
  handlePreviewImage = announcement => {
    let images = [
      {
        id: announcement.files[0].id,
        src: `${process.env['REACT_APP_API_BASE_URL']}/announcement/${announcement.id}/image/${announcement.files[0].id}`
      }
    ];
    this.props.showImagePreview(images, 0, false, () => {}, 1100);
  }
  handlePreviewImages = (announcement, images, index) => {
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/announcement/${announcement.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  renderTextAnnouncement = announcement => {
    return (
      <div>
        <div className='d-flex'>
          <div className='h5 flex-fill'>
            {announcement.title}
          </div>
          {
            announcement.files && announcement.files.length > 0 && (
              <div className='font-italic text-green' style={{ whiteSpace: 'nowrap' }}>
                <FontAwesomeIcon icon='paperclip' /> {announcement.files.length} <span className='d-none d-md-inline-block'>{`attachment${announcement.files.length === 1 ? '' : 's'}`}</span>
              </div>
            )
          }
        </div>
        <div className='announcement-body-container d-flex'>
          <div className='announcement-body flex-fill overflow-hidden ql-snow'>
            {/* {announcement.description} */}
            <div className='ql-editor' dangerouslySetInnerHTML={{__html: announcement.description}} style={{ overflowX: 'auto' }}>
            </div>
          </div>
          <div className='align-self-end d-none d-md-block'>
            <NameLink
              {...announcement.profile}
              imageAlign='right'
              imageStyle={{ width: '10rem', height: '10rem' }}
              disableLink
              enableTooltip={false}
              hideName />
          </div>
        </div>
        <div style={{ fontSize: '1rem' }} className='text-right font-italic font-weight-light text-muted'>
          <div className='d-none d-md-block' style={{ fontSize: '1.25rem' }}>
            &mdash; {announcement.profile.name}
          </div>
          <div className='d-md-none'>
            <NameLink
              {...announcement.profile}
              imageAlign='right'
              imageStyle={{ width: '2rem', height: '2rem' }}
              nameStyle={{ alignSelf: 'end' }}
              disableLink />
          </div>
          <div>
            <FontAwesomeIcon icon='clock' size='sm' /> {moment(announcement.created_at).format('MMM D, YYYY hh:mm A')}
          </div>
        </div>
        <div className='announcement-actions'>
          <Button variant='green' onClick={() => this.showViewModal(announcement)}>
            <FontAwesomeIcon icon='eye' /> <span className='d-none d-md-inline-block'>View announcement</span>
          </Button>
        </div>
      </div>
    );
  }
  renderImageAnnouncement = announcement => {

    if (!announcement.files || announcement.files.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <div>
        <div style={{ height: '15rem', textAlign: 'center', cursor: 'pointer' }} onClick={e => this.handlePreviewImage(announcement)}>
          <Image src={`${process.env['REACT_APP_API_BASE_URL']}/announcement/${announcement.id}/image/${announcement.files[0].id}`} style={{ height: '100%', objectFit: 'contain' }} fluid />
        </div>
        <div style={{ fontSize: '1rem' }} className='text-right font-italic font-weight-light text-muted'>
          <div className='d-flex justify-content-end'>
            <NameLink
              {...announcement.profile}
              imageAlign='right'
              imageStyle={{ width: '2rem', height: '2rem' }}
              nameStyle={{ alignSelf: 'end' }}
              disableLink />
          </div>
          <div>
            <FontAwesomeIcon icon='clock' size='sm' />  {moment(announcement.created_at).format('MMM D, YYYY hh:mm A')}
          </div>
        </div>
      </div>
    );
  }
  renderViewModalBody = () => {
    const { viewModal } = this.state;

    if (viewModal.announcement) {
      const images = viewModal.announcement.files ? viewModal.announcement.files.filter(file => {
        return !!file.is_image;
      }) : [];
  
      const files = viewModal.announcement.files ? viewModal.announcement.files.filter(file => {
        return !file.is_image;
      }) : [];

      return (
        <div className='ql-snow'>
          {/* <div style={{ whiteSpace: 'pre-line' }}>
            {viewModal.announcement.description}
          </div> */}
          <div className='ql-editor' dangerouslySetInnerHTML={{__html: viewModal.announcement.description}}>
          </div>
          <Row className='mt-3'>
            <Col lg={8}>
              {
                (viewModal.announcement.files && viewModal.announcement.files.length > 0) && (
                  <>
                    <div className='font-weight-bold'>
                      <FontAwesomeIcon icon='paperclip' /> Attachments
                    </div>
                    <div className='px-3'>
                    {
                      images.length > 0 && (
                        <Row>
                        {
                          images.map((image, index) => (
                            <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(viewModal.announcement, images, index)}>
                              <Image src={`${process.env['REACT_APP_API_BASE_URL']}/announcement/${viewModal.announcement.id}/image/${image.id}`}  thumbnail />
                            </Col>
                          ))
                        }
                        </Row>
                      )
                    }
                    {
                      files.length > 0 && (
                        <div className='p-1'>
                          {
                            files.map(file => (
                              <div key={file.id} className='d-flex mb-2'>
                                <div className='mr-2'>
                                  <FontAwesomeIcon icon='file' className='text-primary' />
                                </div>
                                <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                                  <span className='btn-link' onClick={() => this.handleFileDownload(file.id)}>
                                    {file.file_title}
                                  </span>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }
                    </div>
                  </>
                )
              }
            </Col>
            <Col lg={4} className='d-flex justify-content-end'>
              <div className='text-right font-weight-light font-italic text-muted align-self-end'>
                <div className='d-flex justify-content-end'>
                  <NameLink
                    {...viewModal.announcement.profile}
                    imageAlign='right'
                    imageStyle={{ width: '5rem', height: '5rem' }}
                    nameStyle={{ alignSelf: 'end', fontSize: '1.25rem' }}
                    hideName
                    enableTooltip={false}
                    disableLink />
                </div>
                <div>
                  {viewModal.announcement.profile.name}
                </div>
                <div>
                  <FontAwesomeIcon icon='clock' size='sm' />  {moment(viewModal.announcement.created_at).format('MMM D, YYYY hh:mm A')}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    return null;
  }
  renderContent = () => {
    const { isLoading, errorMessage, announcements, viewModal } = this.state;
    
    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (announcements.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='px-3'>
          <Carousel
            prevIcon={<FontAwesomeIcon icon='chevron-left' className='text-primary' size='lg' />}
            nextIcon={<FontAwesomeIcon icon='chevron-right' className='text-primary' size='lg' />}
          >
            {
              announcements.map(a => (
                <Carousel.Item key={a.id}>
                  {
                    a.format === 'Text' ? this.renderTextAnnouncement(a) : this.renderImageAnnouncement(a)
                  }
                </Carousel.Item>
              ))
            }
          </Carousel>
        </div>
        <Modal size='lg' show={viewModal.show} onHide={this.hideViewModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {
                viewModal.announcement && viewModal.announcement.title ? (
                  <>
                    <div>
                      {viewModal.announcement.title}
                    </div>
                  </>
                ) : 'View announcement'
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderViewModalBody()}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={this.hideViewModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  render() {
    const { userType } = this.props.match.params;
    const { isLoading, errorMessage } = this.state;

    return (
      <Card className='mt-3'>
        <Card.Body>
          <div className='announcements'>
            <div className='d-flex'>
              <div className='h4 mb-0'>
                <span className='d-none d-md-inline-block'><FontAwesomeIcon icon='bullhorn' /></span> Announcements
              </div>
              {
                (userType && (userType === 'school-admin' || userType === 'faculty')) && (
                  <div className='ml-auto'>
                    <Button variant='green' onClick={this.onManage} disabled={isLoading || errorMessage}>
                      <FontAwesomeIcon icon='cog' />
                      <span className='ml-1 d-none d-md-inline-block'>Manage</span>
                    </Button>
                  </div>
                )
              }
            </div>
            <div className='dropdown-divider'></div>
            {this.renderContent()}
          </div>
        </Card.Body>
      </Card>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Announcement);