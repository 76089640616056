import React from 'react';
import { Alert } from 'react-bootstrap';

export default function Grade(props) {
  function student() {
    return (
      <>
        <div className='mb-3'>
          Grades will be displayed in a table per grading category and the list of materials under a grading category with your grade on the right side of the table.
        </div>
      </>
    );
  }
  function parent() {
    return (
      <>
        <div className='mb-3'>
          Grades will be displayed in a table per grading category and the list of materials under a grading category with your students' grade on the right side of the table.
        </div>
      </>
    );
  }
  function faculty() {
    return (
      <>
        <div className='mb-3'>
          <div className='h4 text-primary'>Gradebook</div>
          <div>
            Students' grades are displayed in a table based on the date of the due.
          </div>
          <div>
            <ol>
              <li><b>Students</b></li>
              <li>
                <div className='font-weight-bold'>Materials</div>
                <div>
                  If a text is truncated, you can hover over the text to show its full text.
                </div>
                <div>
                  <ol>
                    <li><b>Title</b></li>
                    <li><b>Grade category</b></li>
                    <li><b>Maximum points</b></li>
                  </ol>
                </div>
              </li>
              <li>
                <div className='font-weight-bold'>Grade</div>
                <div>
                  <ul>
                    <li><b>N/A</b> &mdash; only visible if the student has no submission or attempt.</li>
                    <li><b>(Ungraded)</b> &mdash; only visible if the submission is not yet graded.</li>
                  </ul>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <b>To navigate to a different date</b>, you may click on the dropdown at the top of the table and select a specific date
        </div>
        <div className='mb-3'>
          <b>To export grades</b>, you may click on <b>Export grades</b> to download a spreadsheet file of all the grades.
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Settings</div>
          <div>
            This area is for setting the grade categories as a requirement for the creation of gradeable materials.
          </div>
          <div>
            <ol>
              <li>To add a grade category, you may fill out the category name</li>
              <li>Click <b>Save</b> to complete.</li>
              <li>You may also delete a grade category by clicking on the trash icon at the right side of the grade category.</li>
            </ol>
            <Alert variant='warning'>
              Deleting a grade category will also delete all the materials under the grade category.
            </Alert>
          </div>
        </div>
      </>
    );
  }
  function other() {
    return (
      <>
        <div className='mb-3'>
          Students' grades are displayed in a table based on the date of the due.
          <div>
            <ul>
              <li><b>Students</b></li>
              <li>
                <div className='font-weight-bold'>Materials</div>
                <div>
                  If a text is truncated, you can hover over the text to show its full text.
                </div>
                <div>
                  <ul>
                    <li><b>Title</b></li>
                    <li><b>Grade category</b></li>
                    <li><b>Maximum points</b></li>
                  </ul>
                </div>
              </li>
              <li>
                <div className='font-weight-bold'>Grade</div>
                <div>
                  <ol>
                    <li><b>N/A</b> &mdash; only visible if the student has no submission or attempt.</li>
                    <li><b>(Ungraded)</b> &mdash; only visible if the submission is not yet graded.</li>
                  </ol>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
  
  return props.accountType === 5 ? student() :
          props.accountType === 4 ? parent() :
          props.accountType === 2 ? faculty() : other();
}