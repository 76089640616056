import React, { Component } from 'react';
import { Alert, Badge, Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import NameLink from '../../../../common/NameLink/NameLink';
import Validator from 'validatorjs';
import { loadRule } from '../../../../../util';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

export default class ManageStudents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      students: [],
      reschedules: [],
      disabledExams: [],
      specialExamRequests: [],
      studentAttempts: [],
      rescheduleModal: {
        show: false,
        title: '',
        studentID: '',
        from: '',
        until: '',
        isLoading: false,
        errorMessage: '',
        requestBased: false
      },
      requestModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        student: null,
        data: null,
        comment: '',
        isFormLoading: false,
        formError: ''
      }
    };
  }
  componentDidMount() {
    const { materialData, match: { params: { classID } } } = this.props;
    
    axiosRequest('get', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/manage-students`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false,
        rescheduleModal: {
          ...this.state.rescheduleModal,
          from: moment().format('YYYY-MM-DDTHH:mm')
        }
      }, () => {
        if (this.props.location.state && this.props.location.state.studentID) {
          let student = data.students.find(d => d.id === +this.props.location.state.studentID);
          if (student) {
            let specialExamRequest = data.specialExamRequests.find(ser => ser.student_id === +this.props.location.state.studentID);
            if (specialExamRequest) {
              this.showRequestModal(student, specialExamRequest);
            }
          }
        }
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);

    loadRule(['date', 'after_or_equal'], {
      after_or_equal: 'The :attribute date must be equal or after the current date and time.'
    });
  }
  showRescheduleModal = student => {
    const { reschedules } = this.state;
    let reschedule = reschedules.find(rs => rs.student_id === student.id);
    
    let defaultFromUntil = {};
    if (reschedule) {
      defaultFromUntil = {
        from: moment(reschedule.from).format('YYYY-MM-DDTHH:mm'),
        until: moment(reschedule.until).format('YYYY-MM-DDTHH:mm')
      };
    }

    this.setState({
      ...this.state,
      rescheduleModal: {
        ...this.state.rescheduleModal,
        ...defaultFromUntil,
        show: true,
        title: student.name,
        studentID: student.id
      }
    });
  }
  hideRescheduleModal = () => {
    this.setState({
      ...this.state,
      rescheduleModal: {
        ...this.state.rescheduleModal,
        show: false,
        title: '',
        studentID: '',
        requestBased: false
      }
    });
  }
  handleRescheduleInputChange = event => {
    this.setState({
      ...this.state,
      rescheduleModal: {
        ...this.state.rescheduleModal,
        [event.target.name]: event.target.value
      }
    });
  }
  handleReschedule = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      rescheduleModal: {
        ...this.state.rescheduleModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { rescheduleModal: { from, until, studentID } } = this.state;
      
      let validator = new Validator({ from, until }, {
        from: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`,
        until: 'required|date|after_or_equal:from'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          rescheduleModal: {
            ...this.state.rescheduleModal,
            errorMessage: validator.errors.errors[firstKey][0],
            isLoading: false
          }
        });
        return;
      }

      const { materialData, match: { params: { classID } } } = this.props;
      axiosRequest('post', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/reschedule/${studentID}`, { from, until }, ({ data: { data }}) => {
        let reschedules = [...this.state.reschedules].filter(rs => rs.student_id !== studentID);
        reschedules = [...reschedules, data];
        
        let studentAttempts = [...this.state.studentAttempts].filter(sa => sa.student_id !== studentID);
        
        this.setState({
          ...this.state,
          reschedules,
          studentAttempts,
          rescheduleModal: {
            ...this.state.rescheduleModal,
            show: false,
            title: '',
            studentID: '',
            errorMessage: '',
            isLoading: false
          }
        })
      }, (error) => {
        this.setState({
          ...this.state,
          rescheduleModal: {
            ...this.state.rescheduleModal,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
            isLoading: false
          }
        });
      }, this.props.history);
    });
  }
  showRequestModal = (student, specialExamRequest) => {
    this.setState({
      ...this.state,
      requestModal: {
        show: true,
        isLoading: true,
        errorMessage: '',
        student,
        data: specialExamRequest,
        comment: specialExamRequest.comment,
        isFormLoading: false,
        formError: ''
      }
    }, () => {
      const { materialData, match: { params: { classID } } } = this.props;

      if (!specialExamRequest.read_at) {
        axiosRequest('get', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/manage-students/special-exam-request/${specialExamRequest.id}`, null, ({ data: { data }}) => {
          let newSpecialExamRequests = [...this.state.specialExamRequests].map(ser => {
            if (ser.id === data.id) {
              return {
                ...data
              };
            }

            return {...ser};
          });
          this.setState({
            ...this.state,
            specialExamRequests: newSpecialExamRequests,
            requestModal: {
              ...this.state.requestModal,
              data,
              isLoading: false
            }
          });
        }, error => {
          this.setState({
            ...this.state,
            requestModal: {
              ...this.state.requestModal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        }, this.props.history);
      } else {
        this.setState({
          ...this.state,
          requestModal: {
            ...this.state.requestModal,
            isLoading: false
          }
        });
      }
    });
  }
  hideRequestModal = () => {
    this.setState({
      ...this.state,
      requestModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        student: null,
        data: null,
        comment: '',
        isFormLoading: false,
        formError: ''
      }
    });
  }
  handleCommentInputChange = e => {
    this.setState({
      ...this.state,
      requestModal: {
        ...this.state.requestModal,
        comment: e.target.value
      }
    });
  }
  handleAcceptRequest = () => {
    this.setState({
      ...this.state,
      requestModal: {
        ...this.state.requestModal,
        isFormLoading: true,
        formError: ''
      }
    }, () => {
      const { requestModal } = this.state;
      const { materialData, match: { params: { classID } } } = this.props;

      axiosRequest('post', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/manage-students/special-exam-request/${requestModal.data.id}/accept`, {
        comment: requestModal.comment
      }, ({ data: { data }}) => {
        let newSpecialExamRequests = [...this.state.specialExamRequests].map(ser => {
          if (ser.id === data.id) {
            return {
              ...data
            };
          }

          return {...ser};
        });
        let studentCopy = {...requestModal.student};
        this.setState({
          ...this.state,
          specialExamRequests: newSpecialExamRequests,
          requestModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            student: null,
            data: null,
            comment: '',
            isFormLoading: false,
            formError: ''
          }
        }, () => {

          this.setState({
            ...this.state,
            rescheduleModal: {
              ...this.state.rescheduleModal,
              from: moment(data.from).format('YYYY-MM-DDTHH:mm'),
              until: moment(data.until).format('YYYY-MM-DDTHH:mm'),
              show: true,
              title: studentCopy.name,
              studentID: studentCopy.id,
              requestBased: true
            }
          });
        });
      }, error => {
        this.setState({
          ...this.state,
          requestModal: {
            ...this.state.requestModal,
            isFormLoading: false,
            formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleDeclineRequest = () => {
    this.setState({
      ...this.state,
      requestModal: {
        ...this.state.requestModal,
        isFormLoading: true,
        formError: ''
      }
    }, () => {
      const { requestModal } = this.state;
      const { materialData, match: { params: { classID } } } = this.props;

      axiosRequest('post', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/manage-students/special-exam-request/${requestModal.data.id}/decline`, {
        comment: requestModal.comment
      }, ({ data: { data }}) => {
        let newSpecialExamRequests = [...this.state.specialExamRequests].map(ser => {
          if (ser.id === data.id) {
            return {
              ...data
            };
          }

          return {...ser};
        });

        this.setState({
          ...this.state,
          specialExamRequests: newSpecialExamRequests,
          requestModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            student: null,
            data: null,
            comment: '',
            isFormLoading: false,
            formError: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          requestModal: {
            ...this.state.requestModal,
            isFormLoading: false,
            formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleSaveRequest = () => {
    this.setState({
      ...this.state,
      requestModal: {
        ...this.state.requestModal,
        isFormLoading: true,
        formError: ''
      }
    }, () => {
      const { requestModal } = this.state;
      const { materialData, match: { params: { classID } } } = this.props;

      axiosRequest('post', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/manage-students/special-exam-request/${requestModal.data.id}`, {
        comment: requestModal.comment
      }, ({ data: { data }}) => {
        let newSpecialExamRequests = [...this.state.specialExamRequests].map(ser => {
          if (ser.id === data.id) {
            return {
              ...data
            };
          }

          return {...ser};
        });

        this.setState({
          ...this.state,
          specialExamRequests: newSpecialExamRequests,
          requestModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            student: null,
            data: null,
            comment: '',
            isFormLoading: false,
            formError: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          requestModal: {
            ...this.state.requestModal,
            isFormLoading: false,
            formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  renderRequestModal = () => {
    const { requestModal: { isLoading, errorMessage, formError, isFormLoading, student, data, comment } } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (!data || !student) {
      return null;
    }

    return (
      <>
        {
          formError && (
            <Alert variant='danger'>
              {formError}
            </Alert>
          )
        }
        <div className='mb-1 font-weight-bold'>
          Reason:
        </div>
        <div className='mb-2 px-2' style={{ whiteSpace: 'pre-line' }}>
          {data.reason}
        </div>
        <div className='mb-1 font-weight-bold'>
          Preferred Date and Time
        </div>
        <div className='px-2'>
          <div>
            Start: {moment(data.from).format('MMM D, YYYY hh:mm A')}
          </div>
          <div>
            End: {moment(data.until).format('MMM D, YYYY hh:mm A')}
          </div>
        </div>
        <div className='dropdown-divider'></div>
        <Form.Group>
          <Form.Label>Comment <span className='font-italic text-muted'>(Optional)</span></Form.Label>
          <Form.Control
            as='textarea'
            rows='4'
            placeholder='If the request is accepted, let the student know whether to take the same exam on a different schedule or take a totally different exam...'
            onChange={this.handleCommentInputChange}
            value={comment}
            disabled={isFormLoading} />
        </Form.Group>
      </>
    );
  }
  renderSpecialExamRequest = student => {
    const { specialExamRequests } = this.state;
    let specialExamRequest = specialExamRequests.find(de => de.student_id === student.id);

    if (specialExamRequest) {
      let buttonVariant = !specialExamRequest.read_at ? 'light' :
                            specialExamRequest.approved_at ? 'green' :
                            specialExamRequest.declined_at ? 'danger' : 'warning';
      return (
        <Button variant={buttonVariant} size='sm' onClick={e => this.showRequestModal(student, specialExamRequest)}>
          {
            buttonVariant === 'light' ? (
              <FontAwesomeIcon icon='exclamation' className='text-danger' />
            ) : (
              <FontAwesomeIcon icon='eye' />
            )
          }
          <span className='ml-1'>View special exam request</span>
        </Button>
      );
    }

    return null;
  }
  renderExamPermit = studentID => {
    const { disabledExams } = this.state;
    let disabledExam = disabledExams.find(de => de.student_id === studentID);

    return (
      <div className='text-muted d-flex'>
        <div className='font-italic mr-1'>Exam permit status: </div>
        {
          !disabledExam ? (
            <div className='text-green'>Enabled</div>
          ) : disabledExam.deleted_at ? (
            <div>
              <div className='text-green'>Enabled</div>
              <div>{moment(disabledExam.deleted_at).format('ddd, MMMM D, YYYY hh:mm A')}</div>
            </div>
          ) : (
            <div>
              <div className='text-danger'>Disabled</div>
              <div>{moment(disabledExam.updated_at).format('ddd, MMMM D, YYYY hh:mm A')}</div>
            </div>
          )
        }
      </div>
    );
  }
  renderStudentAttempts = studentID => {
    const { studentAttempts } = this.state;
    let studentAttempt = studentAttempts.find(sa => sa.student_id === studentID);

    return (
      <div className='text-muted'>
        <span className='font-italic'>Attempt status: </span>
        {
          !studentAttempt ? (
            <span>No attempt has been made</span>
          ) : studentAttempt.finish ? (
            <span>Finished attempt</span>
          ) : (
            <span>Unfinished attempt</span>
          )
        }
      </div>
    );
  }
  renderReschedule = studentID => {
    const { reschedules } = this.state;
    let reschedule = reschedules.find(rs => rs.student_id === studentID);

    if (reschedule) {
      return (
        <div className='d-flex text-muted'>
          <div className='font-italic'>
            Rescheduled
          </div>
          <div className='ml-1'>
            <div>
              <span className='font-italic'>From:</span> {moment(reschedule.from).format('ddd, MMMM D, YYYY hh:mm A')}
            </div>
            <div>
              <span className='font-italic'>Until:</span> {moment(reschedule.until).format('ddd, MMMM D, YYYY hh:mm A')}
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
  render() {
    const { isLoading, errorMessage, students, rescheduleModal, requestModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (students.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='submission-list'>
          {
            students.map(student => (
              <div key={student.id} className='py-3 p-md-3 py-md-0'>
                <div className='d-flex'>
                  <div className='flex-fill'>
                    <div className='mb-2 d-flex'>
                      <NameLink
                        id={student.id}
                        name={student.name}
                        image={student.image}
                        learningPlatform={student.student_learning_platform ? student.student_learning_platform.platform : null}
                        rank={student.rank} />
                    </div>
                    {this.renderSpecialExamRequest(student)}
                    {this.renderExamPermit(student.id)}
                    {this.renderStudentAttempts(student.id)}
                    {this.renderReschedule(student.id)}
                  </div>
                  <div>
                    <Button variant='info' size='sm' onClick={() => this.showRescheduleModal(student)} title='Reschedule'>
                      <FontAwesomeIcon icon='redo' /> <span className='d-none d-lg-inline-block'>Reschedule</span>
                    </Button>
                  </div>
                </div>
              </div>
            ))
          }
          <Modal show={rescheduleModal.show} backdrop='static' onHide={this.hideRescheduleModal}>
            <Modal.Header closeButton>
              <Modal.Title>{rescheduleModal.title}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={this.handleReschedule}>
              <Modal.Body>
                {
                  rescheduleModal.errorMessage ? (
                    <Alert variant='danger'>
                      {rescheduleModal.errorMessage}
                    </Alert>
                  ) : (
                    <Alert variant='warning'>
                      <div className='d-flex'>
                        <div className='mr-2'>
                          <FontAwesomeIcon icon='exclamation-circle' />
                        </div>
                        <div className='flex-fill'>
                          Rescheduling the exam for a student, will result to the <b>deletion</b> of the student's previous <b>attempt, answers, and recorded grade</b>.
                        </div>
                      </div>
                    </Alert>
                  )
                }
                <Form.Group>
                  <Form.Label>From</Form.Label>
                  <Form.Control type='datetime-local' name='from' min={moment().format('YYYY-MM-DDTHH:mm')} max={rescheduleModal.until} value={rescheduleModal.from} onChange={this.handleRescheduleInputChange} />
                  {
                    rescheduleModal.requestBased && (
                      <Form.Text className='text-muted'>
                        <FontAwesomeIcon icon='exclamation-circle' /> The <b>schedule</b> is based on the <b>accepted</b> request.
                      </Form.Text>
                    )
                  }
                </Form.Group>
                <Form.Group>
                  <Form.Label>Until</Form.Label>
                  <Form.Control type='datetime-local' name='until' min={rescheduleModal.from} value={rescheduleModal.until} onChange={this.handleRescheduleInputChange} />
                  {
                    rescheduleModal.requestBased && (
                      <Form.Text className='text-muted'>
                        <FontAwesomeIcon icon='exclamation-circle' /> The <b>schedule</b> is based on the <b>accepted</b> request.
                      </Form.Text>
                    )
                  }
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='danger' className='mr-2' onClick={this.hideRescheduleModal} disabled={rescheduleModal.isLoading}>
                  Cancel
                </Button>
                <Button variant='info' type='submit' disabled={rescheduleModal.isLoading}>
                  Reschedule
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal show={requestModal.show} backdrop={requestModal.isLoading || requestModal.isFormLoading ? 'static' : true} onHide={this.hideRequestModal}>
            <Modal.Header closeButton={!(requestModal.isLoading || requestModal.isFormLoading)}>
              <div>
                <div>
                  <Modal.Title>
                    {
                      requestModal.student ? requestModal.student.name : 'View Special Exam Request'
                    }
                  </Modal.Title>
                </div>
                {
                  requestModal.data && (
                    <>
                      {
                        requestModal.data.approved_at ? (
                          <div>
                            <Badge variant='green'>
                              Accepted
                            </Badge>
                            <span className='ml-1 font-italic text-muted'>&mdash; {moment(requestModal.data.approved_at).format('MMM D, YYYY hh:mm A')}</span>
                          </div>
                        ) : requestModal.data.declined_at ? (
                          <div>
                            <Badge variant='danger'>
                              Declined
                            </Badge>
                            <span className='ml-1 font-italic text-muted'>&mdash; {moment(requestModal.data.declined_at).format('MMM D, YYYY hh:mm A')}</span>
                          </div>
                        ) : requestModal.data.read_at ? (
                          <div>
                            <Badge variant='warning'>
                              Reviewing
                            </Badge>
                            <span className='ml-1 font-italic text-muted'>&mdash; {moment(requestModal.data.read_at).format('MMM D, YYYY hh:mm A')}</span>
                          </div>
                        ) : (
                          <Badge variant='light'>
                            Processing
                          </Badge>
                        )
                      }
                    </>
                  )
                }
              </div>
            </Modal.Header>
            <Modal.Body>
              {this.renderRequestModal()}
            </Modal.Body>
            <Modal.Footer>
              {
                (requestModal.data && (requestModal.data.approved_at || requestModal.data.declined_at)) && (
                  <>
                    {
                      !requestModal.data.declined_at ? (
                        <Button variant='danger' className='mr-auto' disabled={requestModal.isLoading || requestModal.isFormLoading} onClick={this.handleDeclineRequest}>
                          <FontAwesomeIcon icon='times' /> Decline
                        </Button>
                      ) : !requestModal.data.approved_at ? (
                        <Button variant='green' className='mr-auto' disabled={requestModal.isLoading || requestModal.isFormLoading} onClick={this.handleAcceptRequest}>
                          <FontAwesomeIcon icon='check' /> Accept
                        </Button>
                      ) : null
                    }
                  </>
                )
              }
              <Button variant='light' onClick={this.hideRequestModal} disabled={requestModal.isLoading || requestModal.isFormLoading}>
                Close
              </Button>
              {
                requestModal.data && (
                  <>
                    {
                      (!requestModal.data.declined_at && !requestModal.data.approved_at) && (
                        <>
                          <Button variant='danger' disabled={requestModal.isLoading || requestModal.isFormLoading} onClick={this.handleDeclineRequest}>
                            <FontAwesomeIcon icon='times' /> Decline
                          </Button>
                          <Button variant='green' disabled={requestModal.isLoading || requestModal.isFormLoading} onClick={this.handleAcceptRequest}>
                            <FontAwesomeIcon icon='check' /> Accept
                          </Button>
                        </>
                      )
                    }
                    {
                      (requestModal.data.approved_at || requestModal.data.declined_at) && (
                        <Button variant='info' disabled={requestModal.isLoading || requestModal.isFormLoading} onClick={this.handleSaveRequest}>
                          Save
                        </Button>
                      )
                    }
                  </>
                )
              }
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}