import React, { Component } from 'react';
import { Alert, Badge, Button, ButtonGroup, Col, Form, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import NameLink from '../../../../common/NameLink/NameLink';
import Validator from 'validatorjs';
import moment from 'moment';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

const modeTitleMap = {
  'gcash': 'GCash',
  'cash': 'Cash'
};

export default class Students extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      students: {
        data: []
      },
      programs: [],
      yearLevels: [],
      sections: [],
      modal: {
        show: false,
        type: '',
        student: null,
        isLoading: false,
        errorMessage: ''
      },
      isNextPageLoading: false,
      nextPageError: '',
      search: {
        isLoading: false,
        errorMessage: '',
        formInputs: {
          program: '',
          yearLevel: '',
          section: '',
          name: '',
          examPermit: ''
        },
        lastInputs: {
          program: '',
          yearLevel: '',
          section: '',
          name: '',
          examPermit: ''
        },
        isSearch: false
      },
      transactionModal: {
        show: false,
        data: null
      }
    };
  }
  componentDidMount() {
    this.props.onLoading(() => {
      const { category } = this.props;
      axiosRequest('get', `staff/exam-permit/${category.id}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          ...data,
          isLoading: false
        }, this.props.onComplete);
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }, this.props.onComplete);
      }, this.props.history);
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps && prevProps.category && this.props.category && prevProps.category.id !== this.props.category.id) {
      this.setState({
        ...this.state,
        isLoading: true,
        errorMessage: '',
        students: {
          data: []
        },
        programs: [],
        yearLevels: [],
        sections: [],
        modal: {
          show: false,
          type: '',
          student: null,
          isLoading: false,
          errorMessage: ''
        },
        isNextPageLoading: false,
        nextPageError: '',
        search: {
          isLoading: false,
          errorMessage: '',
          formInputs: {
            program: '',
            yearLevel: '',
            section: '',
            name: '',
            examPermit: ''
          },
          lastInputs: {
            program: '',
            yearLevel: '',
            section: '',
            name: '',
            examPermit: ''
          },
          isSearch: false
        }
      }, () => {
        this.props.onLoading(() => {
          const { category } = this.props;
          axiosRequest('get', `staff/exam-permit/${category.id}`, null, ({ data: { data }}) => {
            this.setState({
              ...this.state,
              ...data,
              isLoading: false
            }, this.props.onComplete);
          }, error => {
            this.setState({
              ...this.state,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }, this.props.onComplete);
          }, this.props.history);
        });
      });
    }
  }
  showModal = (type, student) => {
    if ((type === 'enable' && !student.disabled_exam) || (type === 'disable' && student.disabled_exam)) {
      return false;
    }
    
    this.setState({
      ...this.state,
      modal: {
        show: true,
        type,
        student,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      modal: {
        show: false,
        type: '',
        student: null,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  showTransactionModal = data => {
    this.setState({
      ...this.state,
      transactionModal: {
        show: true,
        data
      }
    });
  }
  hideTransactionModal = () => {
    this.setState({
      ...this.state,
      transactionModal: {
        show: false,
        data: null
      }
    });
  }
  handleTransactions = () => {
    const { transactionModal } = this.state;

    this.props.history.push('payment-transaction', {
      query: transactionModal.data.student_number ? transactionModal.data.student_number : `${transactionModal.data.first_name} ${transactionModal.data.last_name}`
    });
  }
  handleExamPermit = e => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      this.props.onLoading(() => {
        const { modal: { student, type }, students } = this.state;
        const { category } = this.props;
        axiosRequest('post', `staff/exam-permit/${category.id}/student/${student.id}`, {
          type
        }, ({ data: { data }}) => {
          let newStudents = [];
          if (type === 'enable') {
            newStudents = [...students.data].map(st => {
              if (st.id === student.id) {
                return {
                  ...st,
                  disabled_exam: null
                }
              }

              return { ...st };
            });
          } else {
            newStudents = [...students.data].map(st => {
              if (st.id === student.id) {
                return {
                  ...st,
                  disabled_exam: data
                }
              }

              return { ...st };
            });
          }
          this.setState({
            ...this.state,
            students: {
              ...this.state.students,
              data: newStudents
            },
            modal: {
              show: false,
              type: '',
              student: null,
              isLoading: false,
              errorMessage: ''
            }
          }, this.props.onComplete);
        }, error => {
          this.setState({
            ...this.state,
            modal: {
              ...this.state.modal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          }, this.props.onComplete);
        }, this.props.history);
      });
    });
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true,
      nextPageError: ''
    }, () => {
      this.props.onLoading(() => {
        const { students, search } = this.state;

        const path = students.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
        
        axiosRequest(search.isSearch ? 'post' : 'get', path, search.isSearch ? search.lastInputs : null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            students: {
              ...data,
              data: [
                ...this.state.students.data,
                ...data.data
              ]
            },
            isNextPageLoading: false,
            nextPageError: ''
          }, this.props.onComplete);
        }, (error) => {
          this.setState({
            ...this.state,
            isNextPageLoading: false,
            nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }, this.props.onComplete);
        }, this.props.history);
      });
    });
  }
  handleSearchInputChange = e => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        formInputs: {
          ...this.state.search.formInputs,
          [e.target.name]: e.target.value
        }
      }
    });
  }
  handleExamPermitSelect = e => {
    if (e.target.checked) {
      this.setState({
        ...this.state,
        search: {
          ...this.state.search,
          formInputs: {
            ...this.state.search.formInputs,
            examPermit: e.target.value
          }
        }
      });
    }
  }
  handleSearch = e => {
    e.preventDefault();

    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { search: { formInputs } } = this.state;

      let validator = new Validator(formInputs, {
        program: 'required_without_all:yearLevel,section,name,examPermit',
        yearLevel: 'required_without_all:program,section,name,examPermit',
        section: 'required_without_all:yearLevel,program,name,examPermit',
        name: 'required_without_all:yearLevel,section,program,examPermit',
        examPermit: 'required_without_all:yearLevel,section,program,name'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          search: {
            ...this.state.search,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      this.props.onLoading(() => {
        const { category } = this.props;
        axiosRequest('post', `staff/exam-permit/${category.id}/search`, formInputs, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            students: data,
            search: {
              ...this.state.search,
              isLoading: false,
              lastInputs: {
                ...formInputs
              },
              isSearch: true
            }
          }, this.props.onComplete);
        }, error => {
          this.setState({
            ...this.state,
            search: {
              ...this.state.search,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          }, this.props.onComplete);
        }, this.props.history);
      });
    });
  }
  handleViewAll = () => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      this.props.onLoading(() => {
        const { category } = this.props;
        axiosRequest('get', `staff/exam-permit/${category.id}/search`, null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            students: data,
            search: {
              isLoading: false,
              errorMessage: '',
              formInputs: {
                program: '',
                yearLevel: '',
                section: '',
                name: '',
                examPermit: ''
              },
              lastInputs: {
                program: '',
                yearLevel: '',
                section: '',
                name: '',
                examPermit: ''
              },
              isSearch: false
            }
          }, this.props.onComplete);
        }, error => {
          this.setState({
            ...this.state,
            search: {
              ...this.state.search,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          }, this.props.onComplete);
        }, this.props.history);
      });
    });
  }
  renderModal = () => {
    const { modal: { type, student, isLoading, errorMessage } } = this.state;

    if (!type || !student) {
      return null;
    }

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>{type === 'enable' ? 'Enable - Exam Permit' : 'Disable - Exam Permit'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            errorMessage && (
              <Alert variant='danger'>
                {errorMessage}
              </Alert>
            )
          }
          {
            type === 'enable' ? (
              <Alert variant='warning'>
                <b>{student.name}</b> will gain <b>access</b> to take the filtered exams
              </Alert>
            ) : (
              <Alert variant='warning'>
                <b>{student.name}</b> will be <b>blocked</b> to take the filtered exams
              </Alert>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          {
            type === 'enable' ? (
              <>
                <Button variant='danger' onClick={this.hideModal} disabled={isLoading}>
                  Cancel
                </Button>
                <Button variant='green' onClick={this.handleExamPermit} disabled={isLoading}>
                  Enable
                </Button>
              </>
            ) : (
              <>
                <Button variant='info' onClick={this.hideModal} disabled={isLoading}>
                  Cancel
                </Button>
                <Button variant='danger' onClick={this.handleExamPermit} disabled={isLoading}>
                  Disable
                </Button>
              </>
            )
          }
        </Modal.Footer>
      </>
    )
  }
  renderTable = () => {
    const { students, isNextPageLoading, nextPageError } = this.state;

    if (students.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <div className='table-responsive'>
        <Table size='sm'>
          <thead>
            <tr>
              <th className='w-50'>Name</th>
              <th className='w-25'>Student Number</th>
              <th className='w-25'>Class</th>
              <th className='text-center'>Exam Permit</th>
            </tr>
          </thead>
          <tbody>
            {
              students.data.map(student => (
                <tr key={student.id}>
                  <th>
                    <div className='d-flex'>
                      <div className='flex-fill d-flex'>
                        <NameLink {...student} imageStyle={{ width: '3rem', height: '3rem' }} />
                      </div>
                      {
                        (student.payment_transactions && student.payment_transactions.length > 0) && (
                          <div className='px-2 align-self-center'>
                            <Button variant='warning' size='sm' title='View payment transactions' onClick={e => this.showTransactionModal(student)}>
                              <span className='fa-layers fa-fw'>
                                <FontAwesomeIcon icon='file-invoice' />
                                {
                                  (student.payment_transactions.find(t => !t.approved_at && !t.declined_at)) && (
                                    <span className='fa-layers-counter fa-fw fa-2x bg-danger font-weight-bold' style={{ transformOrigin: '1.675em -0.1875em'}}>!</span>
                                  )
                                }
                              </span>
                            </Button>
                          </div>
                        )
                      }
                    </div>
                  </th>
                  <td className='align-middle'>
                    {student.student_number}
                  </td>
                  <td className='align-middle'>
                    {
                      (student.program && student.year_level) ? 
                        `${student.program.code} ${student.year_level.code}` :
                        `${student.student_class.school_class.program.code} ${student.student_class.school_class.year_level.code}-${student.student_class.school_class.section.code}`
                    }
                  </td>
                  <td className='text-center align-middle'>
                    <ButtonGroup>
                      <Button
                        variant={student.disabled_exam ? 'outline-green' : 'green'}
                        onClick={e => this.showModal('enable', student)}
                      >
                        {student.disabled_exam ? 'Enable' : 'Enabled'}
                      </Button>
                      <Button
                        variant={student.disabled_exam ? 'danger' : 'outline-danger'}
                        onClick={e => this.showModal('disable', student)}
                      >
                        {student.disabled_exam ? 'Disabled' : 'Disable'}
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))
            }
            {
              students.next_page_url && (
                <tr>
                  <th colspan='4' className='text-center'>
                    {
                      nextPageError && (
                        <Alert variant='danger'>
                          {nextPageError}
                        </Alert>
                      )
                    }
                    <Button variant='link' onClick={this.handleNextPage} disabled={isNextPageLoading}>
                      Show more... {
                        isNextPageLoading && (
                          <LoadingIcon />
                        )
                      }
                    </Button>
                  </th>
                </tr>
              )
            }
          </tbody>
        </Table>
      </div>
    );
  }
  render() {
    const { isLoading, errorMessage, modal, search, programs, yearLevels, sections, transactionModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }
    
    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <div className='border rounded mb-3 p-3'>
          <Form onSubmit={this.handleSearch}>
            <Form.Row>
              <Form.Group as={Col} lg={4}>
                <Form.Label>Program</Form.Label>
                <Form.Control
                  as='select'
                  size='sm'
                  name='program'
                  onChange={this.handleSearchInputChange}
                  value={search.formInputs.program}
                  disabled={programs.length === 0}>
                  <option value=''>Select program...</option>
                  {
                    programs.map(program => (
                      <option key={program.id} value={program.id}>{`${program.code} - ${program.description}`}</option>
                    ))
                  }
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} lg={4}>
                <Form.Label>Year Level</Form.Label>
                <Form.Control
                  as='select'
                  size='sm'
                  name='yearLevel'
                  onChange={this.handleSearchInputChange}
                  value={search.formInputs.yearLevel}
                  disabled={yearLevels.length === 0}>
                  <option value=''>Select year level...</option>
                  {
                    yearLevels.map(yearLevel => (
                      <option key={yearLevel.id} value={yearLevel.id}>{`${yearLevel.code} - ${yearLevel.description}`}</option>
                    ))
                  }
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} lg={4}>
                <Form.Label>Section</Form.Label>
                <Form.Control
                  as='select'
                  size='sm'
                  name='section'
                  onChange={this.handleSearchInputChange}
                  value={search.formInputs.section}
                  disabled={sections.length === 0}>
                  <option value=''>Select section...</option>
                  {
                    sections.map(section => (
                      <option key={section.id} value={section.id}>{section.code}</option>
                    ))
                  }
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Name or Student Number</Form.Label>
              <Form.Control type='text' size='sm' name='name' placeholder='e.g. Juan Dela Cruz' onChange={this.handleSearchInputChange} value={search.formInputs.name} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Exam Permit</Form.Label>
              <div>
                <Form.Check
                  type='radio'
                  id='examPermitAll'
                  label='All'
                  name='examPermit'
                  checked={search.formInputs.examPermit === ''}
                  onChange={this.handleExamPermitSelect}
                  value=''
                  inline />
                <Form.Check
                  type='radio'
                  id='examPermitEnabled'
                  label='Enabled'
                  name='examPermit'
                  checked={search.formInputs.examPermit === 'enabled'}
                  onChange={this.handleExamPermitSelect}
                  value='enabled'
                  inline />
                <Form.Check
                  type='radio'
                  id='examPermitDisabled'
                  label='Disabled'
                  name='examPermit'
                  checked={search.formInputs.examPermit === 'disabled'}
                  onChange={this.handleExamPermitSelect}
                  value='disabled'
                  inline />
              </div>
            </Form.Group>
            <div className='dropdown-divider'></div>
            <div className='text-right'>
              {
                search.isSearch && (
                  <Button variant='outline-green mr-2' type='button' onClick={this.handleViewAll} size='sm'>
                    <FontAwesomeIcon icon='list' /> View all
                  </Button>
                )
              }
              <Button variant='green' type='submit' size='sm'>
                <FontAwesomeIcon icon='search' /> Search
              </Button>
            </div>
          </Form>
        </div>
        {
          search.isLoading ? (
            <LoadingIcon />
          ) : (
            <>
              {
                search.errorMessage && (
                  <Alert variant='danger'>
                    {search.errorMessage}
                  </Alert>
                )
              }
              {this.renderTable()}
            </>
          )
        }
        <Modal show={modal.show} onHide={this.hideModal}>
          {this.renderModal()}
        </Modal>
        <Modal show={transactionModal.show} onHide={this.hideTransactionModal}>
          <Modal.Header closeButton>
            <Modal.Title>Payment Transactions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              (transactionModal.data && transactionModal.data?.payment_transactions?.length > 0) && (
                <Table hover responsive>
                  <tbody>
                    {
                      transactionModal.data.payment_transactions.map((transaction, index) => (
                        <tr key={transaction.id}>
                          <th>
                            {index+1}
                          </th>
                          <td>
                            {modeTitleMap[transaction.mode]}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {
                              transaction.mode === 'gcash' ?
                                moment(transaction.transaction_date_time).format('MMM D, YYYY hh:mm A') :
                                moment(transaction.transaction_date).format('MMMM D, YYYY')
                            }
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            &#8369; {transaction.amount.toFixed(2)}
                          </td>
                          <td>
                            {
                              transaction.approved_at ? (
                                <Badge variant='green'>
                                  Accepted
                                </Badge>
                              ) : transaction.declined_at ? (
                                <Badge variant='danger'>
                                  Declined
                                </Badge>
                              ) : transaction.read_at ? (
                                <Badge variant='warning'>
                                  Reviewing
                                </Badge>
                              ) : (
                                <Badge variant='light'>
                                  Processing
                                </Badge>
                              )
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={this.hideTransactionModal}>
              Close
            </Button>
            <Button variant='warning' onClick={this.handleTransactions}>
              Transactions <FontAwesomeIcon icon='chevron-right' />
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}