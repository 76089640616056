import React from 'react';

export default function Question(props) {
  function student() {
    return (
      <>
        <div className='mb-3'>
          <div className='h4 text-primary'>Answering questions on Quizzes and Exams</div>
        </div>
        <div className='mb-3'>
          <div>
            Five (5) types of questions are available: True or false, Essay, Multiple choices, Matching, and Ordering.
          </div>
          <div>
            <ul>
              <li>Answers will automatically save after a few seconds of inactivity.</li>
              <li>To manually save an answer, click on <b>next</b> or <b>previous</b>.</li>
              <li>
                <div>Questions are displayed as:</div>
                <div>
                  <ol>
                    <li><b>Question number</b></li>
                    <li><b>Question or instruction</b> &mdash; depends on the type of the question</li>
                    <li><b>Points</b> &mdash; number of points if your answer is correct</li>
                    <li><b>Answer</b> &mdash; area that depends on the type of the question</li>
                  </ol>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>True or False</div>
          <div>
            To add an answer, select an option between True or False.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Essay</div>
          <div>
            To add an answer, enter text to the textarea.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Multiple Choices</div>
          <div>
            To add an answer, select an option between the choices.<br />
            Some multiple choices questions requires two or more correct answers.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Matching</div>
          <div>
            <div className='font-weight-bold'>To add an answer:</div>
            <ol>
              <li>Click on a circle on the left side (beside the item)</li>
              <li>Then, click on a circle on the right side (beside the answer)</li>
              <li>Optionally, you may click on the left side circle and drag the line to the right side circle</li>
              <li>Two inter-connected circles will be your answer</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Ordering</div>
          <div>
            To add an answer, click on the arrows (up or down) on the left of the item to move it up or down, respectively.
          </div>
        </div>
      </>
    );
  }

  function faculty() {
    return (
      <>
        <div className='mb-3'>
          Five (5) types of questions are available: True or false, Essay, Multiple choices, Matching, and Ordering.<br />
          Navigate to the questions by clicking on the question icon at the right side of the quiz or exam material
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Create a question</div>
          <div>
            To start creating a question click on <b>Add Question</b> at the top right corner of the questions pane.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>True or False</div>
          <div>
            <ol>
              <li>Click on <b>True/False</b> from the dropdown list</li>
              <li>Fill out the form.</li>
              <li>Optionally, you may attach files to the question by clicking on the upload icon at the bottom of the form.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Multiple Choices</div>
          <div>
            <ol>
              <li>Click on <b>Multiple Choice</b> from the dropdown list</li>
              <li>Fill out the form.</li>
              <li>
                You may add another choice by clicking on <b>Add another choice</b>.
                Furthermore, you may also delete a choice by clicking on the close icon at the top right corner of the choice.
              </li>
              <li>At least one (1) correct answer must be specified.</li>
              <li>Optionally, you may attach files to the question by clicking on the upload icon at the bottom of the form.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Ordering</div>
          <div>
            <ol>
              <li>Click on <b>Ordering</b> from the dropdown list</li>
              <li>Fill out the form.</li>
              <li>
                You may add another item by clicking on <b>Add another item</b>.
                Furthermore, you may also delete an item by clicking on the close icon at the top right corner of the item.
              </li>
              <li>The correct order will depend on how you add the items.</li>
              <li>Optionally, you may attach files to the question by clicking on the upload icon at the bottom of the form.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Essay</div>
          <div>
            <ol>
              <li>Click on <b>Ordering</b> from the dropdown list</li>
              <li>Fill out the form.</li>
              <li>This is a subjective question, since this will be manually graded.</li>
              <li>Optionally, you may attach files to the question by clicking on the upload icon at the bottom of the form.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Matching</div>
          <div>
            <ol>
              <li>Click on <b>Matching</b> from the dropdown list</li>
              <li>Fill out the form.</li>
              <li>
                You may add another item by clicking on <b>Add another item</b>.
                Furthermore, you may also delete an item by clicking on the close icon at the top right corner of the item.
              </li>
              <li>Optionally, you may attach files to the question by clicking on the upload icon at the bottom of the form.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Update a question</div>
          <div>
            <ol>
              <li>Click on the pencil icon at the right side of the question to be updated.</li>
              <li>Fill out the form.</li>
              <li>
                If a file is attached to the question, clicking the <b>Show attachments</b> will show the currently attached file.
                You may delete the attached file by clicking on the trash icon at the right side of the file.
                Furthermore, to cancel deletion of the file, you may click on the ban icon at the right side of the file.
              </li>
              <li>Optionally, you may attach new files by clicking on the upload icon at the bottom of the form.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Delete a question</div>
          <div>
            <ol>
              <li>Click on the trash icon at the right side of the question to be deleted.</li>
              <li>Click <b>Delete</b> from the prompt to confirm deletion.</li>
            </ol>
          </div>
        </div>
      </>
    );
  }

  return props.accountType === 5 ? student() :
          props.accountType === 2 ? faculty() : null;
}