import React, { Component } from 'react';
import { Alert, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../util/helpers/axiosRequest';
import moment from 'moment';
import './style.scss';
import LoadingIcon from '../LoadingIcon/LoadingIcon';

export default class CheckInOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      classes: null,
      attendance: null,
      firstClass: null,
      modal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        type: ''
      }
    }
  }
  componentDidMount() {
    axiosRequest('get', 'faculty/attendance', null, ({ data: { data }}) => {
      let firstClass = null;
      for (let i = 0; i < data.classes.length; i++) {
        if (data.classes[i].date_schedule.length > 0) {
          if (data.classes[i].date_schedule[0].type === 'Synchronous') {
            firstClass = {
              ...data.classes[i],
              schedule: data.classes[i].date_schedule[0]
            };
          }
        } else if (data.classes[i].weekly_schedule[0].type === 'Synchronous') {
          firstClass = {
            ...data.classes[i],
            schedule: data.classes[i].weekly_schedule[0]
          };
        }
      }
      this.setState({
        ...this.state,
        ...data,
        isLoading: false,
        firstClass
      }, () => {
        if (
              data.classes.length > 0 &&
              firstClass &&
              !data.attendance &&
              moment().isSameOrAfter(moment(firstClass.schedule.start_time, 'HH:mm').subtract(1, 'hour'))
            ) {
          this.showModal('checkin');
        }
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  retryFetch = () => {
    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: ''
    }, () => {
      axiosRequest('get', 'faculty/attendance', null, ({ data: { data }}) => {
        let firstClass = null;
        for (let i = 0; i < data.classes.length; i++) {
          if (data.classes[i].date_schedule.length > 0) {
            if (data.classes[i].date_schedule[0].type === 'Synchronous') {
              firstClass = {
                ...data.classes[i],
                schedule: data.classes[i].date_schedule[0]
              };
            }
          } else if (data.classes[i].weekly_schedule[0].type === 'Synchronous') {
            firstClass = {
              ...data.classes[i],
              schedule: data.classes[i].weekly_schedule[0]
            };
          }
        }
        this.setState({
          ...this.state,
          ...data,
          isLoading: false,
          firstClass
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showModal = type => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        show: true,
        type
      }
    });
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        show: false
      }
    });
  }
  handleCheckin = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        isLoading: true,
        errorMessage: '',
      }
    }, () => {
      axiosRequest('post', 'faculty/attendance', {}, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          attendance: data,
          modal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            type: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      });
    });
  }
  handleCheckout = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        isLoading: true,
        errorMessage: '',
      }
    }, () => {
      axiosRequest('patch', 'faculty/attendance', {}, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          attendance: data,
          modal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            type: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      });
    });
  }
  renderButton = () => {
    const { modal, attendance, classes, firstClass } = this.state;

    if (classes.length === 0) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              No classes scheduled today
            </Tooltip>
          }
          trigger={['hover', 'focus']}>
          <Button variant='light' className='check-in-out-float shadow-lg'>
            <FontAwesomeIcon icon='user-clock' />
          </Button>
        </OverlayTrigger>
      );
    }

    if (!firstClass) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              No synchronous classes scheduled today
            </Tooltip>
          }
          trigger={['hover', 'focus']}>
          <Button variant='light' className='check-in-out-float shadow-lg'>
            <FontAwesomeIcon icon='user-clock' />
          </Button>
        </OverlayTrigger>
      );
    }

    if (modal.isLoading) {
      if (modal.type === 'checkin') {
        return (
          <Button variant='info' title='Checking in...' className='check-in-out-float shadow-lg' disabled>
            <LoadingIcon /> <span className='d-md-none'><FontAwesomeIcon icon='user-clock' /></span> <span className='text d-none d-md-inline-block'>Checking in...</span>
          </Button>
        );
      }
      if (modal.type === 'checkout') {
        return (
          <Button variant='danger' title='Checking out...' className='check-in-out-float shadow-lg' disabled>
            <LoadingIcon /> <span className='d-md-none'><FontAwesomeIcon icon='user-clock' /></span> <span className='text d-none d-md-inline-block'>Checking out...</span>
          </Button>
        );
      }
    }

    if (attendance) {
      if (!attendance.check_out) {
        return (
          <Button
            variant='danger'
            title='Save your time of logout'
            className='check-in-out-float shadow-lg'
            onClick={e => this.showModal('checkout')}
            disabled={modal.isLoading}>
            <FontAwesomeIcon icon='user-clock' /> <span className='text d-none d-md-inline-block'>Time out</span>
          </Button>
        );
      } else {
        return (
          <OverlayTrigger
            overlay={
              <Tooltip>
                <div>
                  Time in: {moment(attendance.check_in, 'HH:mm:ss').format('hh:mm:ss A')}
                </div>
                <div>
                  Time out: {moment(attendance.check_out, 'HH:mm:ss').format('hh:mm:ss A')}
                </div>
              </Tooltip>
            }
            trigger={['hover', 'focus']}>
            <Button variant='primary' className='check-in-out-float shadow-lg'>
              <FontAwesomeIcon icon='check' /> <FontAwesomeIcon icon='user-clock' />
            </Button>
          </OverlayTrigger>
        );
      }
    }

    return (
      <Button
        variant='info'
        className={`check-in-out-float shadow-lg ${!modal.show ? 'pulse' : ''}`}
        title='Save your time of login'
        onClick={e => this.showModal('checkin')}
        disabled={modal.isLoading}>
        <FontAwesomeIcon icon='user-clock' /> <span className='text d-none d-md-inline-block'>Time in</span>
      </Button>
    );
  }
  render() {
    const { isLoading, errorMessage, modal } = this.state;

    if (isLoading) {
      return (
        <Button variant='light' className='check-in-out-float shadow-lg' disabled>
          <LoadingIcon /> <FontAwesomeIcon icon='user-clock' />
        </Button>
      );
    }

    if (errorMessage) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              {errorMessage}
            </Tooltip>
          }
          trigger={['hover', 'focus']}>
          <Button variant='danger' className='check-in-out-float shadow-lg error-button' onClick={this.retryFetch}>
            <span className='notif-icon'><FontAwesomeIcon icon='exclamation' /> <FontAwesomeIcon icon='user-clock' /></span> <span className='refresh-icon'><FontAwesomeIcon icon='redo' /> Retry</span>
          </Button>
        </OverlayTrigger>
      );
    }

    return (
      <>
        {this.renderButton()}
        <Modal show={modal.show} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>{modal.type === 'checkin' ? 'Time In' : modal.type === 'checkout' ? 'Time Out' : ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              modal.errorMessage ? (
                <Alert variant='danger'>
                  {modal.errorMessage}
                </Alert>
              ) : modal.type === 'checkin' ? (
                <Alert variant='warning'>
                  Time in to <b>save</b> the time of your <b>login</b>.
                </Alert>
              ) : modal.type === 'checkout' ? (
                <Alert variant='warning'>
                  Time out to <b>save</b> the time of your <b>logout</b>.
                </Alert>
              ) : null
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={this.hideModal}>
              Close
            </Button>
            {
              modal.type === 'checkin' ? (
                <Button variant='info' onClick={this.handleCheckin} disabled={modal.isLoading}>
                  Time In
                </Button>
              ) : modal.type === 'checkout' ? (
                <Button variant='danger' onClick={this.handleCheckout} disabled={modal.isLoading}>
                  Time Out
                </Button>
              ) : null
            }
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}