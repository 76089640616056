import React, { Component } from 'react';
import { Alert, Breadcrumb, Nav } from 'react-bootstrap';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import Details from './parts/Details';
import Submissions from './parts/Submissions';
import RequestSpecialExam from './parts/RequestSpecialExam';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

const viewableMaterials = [
  'assignment',
  'quiz',
  'exam'
];

export default class Material extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      material: null,
      activeNav: 'details'
    }
  }
  componentDidMount() {
    const { classID, materialType, materialID } = this.props.match.params;

    if (viewableMaterials.indexOf(materialType) === -1) {
      this.handleMaterials();
      return;
    }

    axiosRequest('get', `class/${classID}/material/${materialType}/${materialID}`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        material: data,
        isLoading: false
      }, () => {
        if (this.props.location.state && this.props.location.state.activeNav) {
          this.setState({
            ...this.state,
            activeNav: this.props.location.state.activeNav
          });
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    });
  }
  handleMaterials = () => {
    const { classID } = this.props.match.params;
    this.props.history.push(`/student/class/${classID}/materials`);
  }
  handleSelect = key => {
    this.setState({
      ...this.state,
      activeNav: key
    });
  }
  handleRevision = () => {
    this.setState({
      ...this.state,
      material: {
        ...this.state.material,
        revision: false
      }
    });
  }
  handleRequestSpecialExam = () => {
    this.setState({
      ...this.state,
      activeNav: 'request-special-exam'
    });
  }
  renderContent = () => {
    const { activeNav, material } = this.state;

    switch(activeNav) {
      case 'details':
        return <Details {...this.props} material={material} onSubmit={this.handleRevision} onRequest={this.handleRequestSpecialExam} />;
      case 'submissions':
        return <Submissions {...this.props} material={material} />;
      case 'request-special-exam':
        return <RequestSpecialExam {...this.props} material={material} />;
      default:
        return null;
    }
  }
  renderContainer = () => {
    const { isLoading, errorMessage, material, activeNav } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.handleMaterials}>Materials</Breadcrumb.Item>
          <Breadcrumb.Item active>{material.title}</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <Nav variant='tabs bg-light rounded-top' className='flex-column flex-md-row' onSelect={this.handleSelect}>
            <Nav.Item>
              <Nav.Link eventKey='details' active={activeNav === 'details'}>Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='submissions' active={activeNav === 'submissions'}>Submissions</Nav.Link>
            </Nav.Item>
            {
              material.special_exam && (
                <Nav.Item>
                  <Nav.Link eventKey='request-special-exam' active={activeNav === 'request-special-exam'} className='font-italic'>Request Special Exam</Nav.Link>
                </Nav.Item>
              )
            }
          </Nav>
          <div className='content border border-top-0 rounded-bottom p-3'>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className='border rounded p-4 my-3'>
        {this.renderContainer()}
      </div>
    );
  }
}