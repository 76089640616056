import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Form, Modal } from 'react-bootstrap';

const AdminSettingsModal = props => (
  <Modal backdrop='static' show={props.show} onHide={props.onHide}>
    <Modal.Header>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>

    <Form onSubmit={props.onSubmit}>
      <Modal.Body>
        {
          props.errorMessage && (
            <Alert variant='danger'>
              {props.errorMessage}
            </Alert>
          )
        }
        { props.children }
      </Modal.Body>
      <Modal.Footer>
        <Button type='button' variant='danger' onClick={props.onHide}>Cancel</Button>
        <Button type='submit' variant='primary'>Save</Button>
      </Modal.Footer>
    </Form>

  </Modal>
);

AdminSettingsModal.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  data: PropTypes.any
}

AdminSettingsModal.defaultProps = {
  onSubmit: () => {},
  onHide: () => {},
  show: true,
  data: null
}

export default AdminSettingsModal;