import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Container, Form, InputGroup, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header/Header';
import './style.scss';
import axiosRequest from '../../../util/helpers/axiosRequest';
import Validator from 'validatorjs';
import NameLink from '../../common/NameLink/NameLink';
import moment from 'moment';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      chatNotifications: {
        data: []
      },
      messageModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        isFormLoading: false,
        formError: '',
        to: null,
        type: 'user',
        message: '',
        search: {
          query: '',
          show: false,
          users: [],
          isLoading: false
        },
        classes: []
      },
      isNextPageLoading: false,
      nextPageError: ''
    };

    this.searchInput = createRef();
    this.messageModal = createRef();
    this.searchTimeout = null;
  }
  componentDidMount() {
    axiosRequest('get', 'chat/conversations', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        chatNotifications: data,
        isLoading: false
      }, () => {
        if (this.props.location.state && this.props.location.state.newMessage) {
          if (this.props.location.state.to && this.props.location.state.type) {
            this.setState({
              ...this.state,
              messageModal: {
                ...this.state.messageModal,
                to: this.props.location.state.to,
                type: this.props.location.state.type
              }
            }, this.showMessageModal);
          } else {
            this.showMessageModal();
          }
        }
      });
    }, error => {
      this.setState({
        ...this.state,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        isLoading: false
      });
    });
  }
  showMessageModal = () => {
    if (this.props.currentUser.account_type !== 2) {
      this.setState({
        ...this.state,
        messageModal: {
          ...this.state.messageModal,
          show: true
        }
      });
    } else {
      this.setState({
        ...this.state,
        messageModal: {
          ...this.state.messageModal,
          show: true,
          isLoading: true,
          errorMessage: ''
        }
      }, () => {
        axiosRequest('get', 'faculty/chat/classes', null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            messageModal: {
              ...this.state.messageModal,
              isLoading: false,
              classes: data
            }
          });
        }, error => {
          this.setState({
            ...this.state,
            messageModal: {
              ...this.state.messageModal,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
              isLoading: false
            }
          });
        }, this.props.history);
      });
    }
  }
  hideMessageModal = () => {
    this.setState({
      ...this.state,
      messageModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        to: null,
        type: 'user',
        message: '',
        search: {
          query: '',
          show: false,
          users: [],
          isLoading: false
        },
        classes: []
      }
    });
  }
  showUsers = () => {
    this.setState({
      ...this.state,
      messageModal: {
        ...this.state.messageModal,
        search: {
          ...this.state.messageModal.search,
          show: true
        }
      }
    });
  }
  hideUsers = () => {
    setTimeout(() => {
      this.setState({
        ...this.state,
        messageModal: {
          ...this.state.messageModal,
          search: {
            ...this.state.messageModal.search,
            show: false
          }
        }
      });
    }, 500);
  }
  getUsers = () => {
    const { messageModal: { search: { query } } } = this.state;

    axiosRequest('get', `chat/users/${query}`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        messageModal: {
          ...this.state.messageModal,
          search: {
            ...this.state.messageModal.search,
            users: data,
            isLoading: false
          }
        }
      });
    }, error => {

    }, this.props.history);
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { chatNotifications } = this.state;

      const path = chatNotifications.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        let chatNotificationsData = [...this.state.chatNotifications.data, ...data.data].filter((v, i, t) => {
          return t.findIndex(rv => rv.id === v.id) === i;
        });
        this.setState({
          ...this.state,
          chatNotifications: {
            ...data,
            data: chatNotificationsData
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleSendMessage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      messageModal: {
        ...this.state.messageModal,
        isFormLoading: true,
        formError: ''
      }
    }, () => {
      const { messageModal: { to, type, message } } = this.state;
      const { currentUser } = this.props;
      
      if (!to) {
        this.setState({
          ...this.state,
          messageModal: {
            ...this.state.messageModal,
            isFormLoading: false,
            formError: 'The recipient is required.'
          }
        });
        return;
      }

      let inputs = {
        to: to.id,
        type: 'user',
        message
      };

      if (currentUser.account_type === 2) {
        inputs.type = type;
      }

      let validator = new Validator(inputs, {
        to: 'required|integer',
        type: 'required|in:user,classroom',
        message: 'required'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          messageModal: {
            ...this.state.messageModal,
            isFormLoading: false,
            formError: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', 'chat/send', inputs, ({ data: { data }}) => {
        let info = data.chat_participants.find(participant => participant.profile_id !== this.props.currentUser.id);
        this.showConversation(data, info);
      }, error => {
        this.setState({
          ...this.state,
          messageModal: {
            ...this.state.messageModal,
            isFormLoading: false,
            formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleMessageInputChange = event => {
    this.setState({
      ...this.state,
      messageModal: {
        ...this.state.messageModal,
        message: event.target.value
      }
    });
  }
  handleAddTo = user => {
    this.setState({
      ...this.state,
      messageModal: {
        ...this.state.messageModal,
        to: user
      }
    });
  }
  handleRemoveTo = () => {
    this.setState({
      ...this.state,
      messageModal: {
        ...this.state.messageModal,
        to: null,
        search: {
          query: '',
          show: false,
          users: [],
          isLoading: false
        }
      }
    }, () => {
      this.searchInput.current.focus();
    });
  }
  handleSearchInputChange = event => {
    let searchQuery = event.target.value;

    this.setState({
      ...this.state,
      messageModal: {
        ...this.state.messageModal,
        search: {
          ...this.state.messageModal.search,
          query: searchQuery,
          users: [],
          isLoading: false
        }
      }
    }, () => {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      let validator = new Validator({
        searchQuery
      }, {
        searchQuery: 'required|string|min:2'
      });

      if (validator.passes()) {
        this.setState({
          ...this.state,
          messageModal: {
            ...this.state.messageModal,
            search: {
              ...this.state.messageModal.search,
              isLoading: true
            }
          }
        }, () => {
          this.searchTimeout = setTimeout(() => {
            this.getUsers();
          }, 1000);
        });
      }
    });
  }
  showConversation = (conversation, info) => {
    this.props.history.push(`/chat/${conversation.id}`, { name: conversation.title ? conversation.title : info.profile.name });
  }
  handleChatType = e => {
    this.setState({
      ...this.state,
      messageModal: {
        ...this.state.messageModal,
        type: e.target.value,
        to: null,
      }
    });
  }
  handleSelectClassroom = e => {
    const { messageModal } = this.state;

    let to = messageModal.classes.find(cl => cl.id === +e.target.value);
    this.setState({
      ...this.state,
      messageModal: {
        ...this.state.messageModal,
        to
      }
    });
  }
  renderModalRecipient = () => {
    const { messageModal } = this.state;
    const { currentUser } = this.props;

    if (currentUser.account_type === 2) {
      return (
        <Form.Group>
          <Form.Label>To</Form.Label>
          <div className='mb-2'>
            <Form.Check
              type='radio'
              id='chatTypeUser'
              name='chatType'
              value='user'
              inline
              label='User'
              checked={messageModal.type === 'user'}
              onChange={this.handleChatType} />
            <Form.Check
              type='radio'
              id='chatTypeClassroom'
              name='chatType'
              value='classroom'
              inline
              label='Classroom'
              checked={messageModal.type === 'classroom'}
              onChange={this.handleChatType} />
          </div>
          {
            (messageModal.to && messageModal.type === 'user') ? (
              <InputGroup>
                <Form.Control type='text' value={messageModal.to.name} disabled />
                <InputGroup.Append>
                  <Button variant='danger' onClick={this.handleRemoveTo}>
                    <FontAwesomeIcon icon='times' />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            ) : messageModal.type === 'user' ? (
              <>
                <Form.Control ref={this.searchInput} type='text' value={messageModal.search.query} onChange={this.handleSearchInputChange} onFocus={this.showUsers} onBlur={this.hideUsers} />
                <div style={{ position: 'relative' }}>
                {
                  messageModal.search.show && (
                    <div className='border rounded-bottom bg-light shadow-sm search-user-list' style={{ position: 'absolute', left: 0, right: 0, fontSize: '.875rem' }}>
                      {
                        messageModal.search.isLoading ? (
                          <div className='px-2 py-1'>
                            <LoadingIcon />
                          </div>
                        ) : messageModal.search.users.length === 0 ? (
                          <div className='alert-light py-1 px-2'>Nothing to show.</div>
                        ) : messageModal.search.users.map(user => (
                          <div key={user.id} className='btn-link px-2 py-1' onClick={() => this.handleAddTo(user)}>
                            {user.name}
                          </div>
                        ))
                      }
                    </div>
                  )
                }
                </div>
              </>
            ) : (
              <Form.Control as='select' value={messageModal.to ? messageModal.to.id : ''} onChange={this.handleSelectClassroom}>
                <option hidden selected value=''>Select classroom...</option>
                {
                  messageModal.classes.map(cl => (
                    <option key={cl.id} value={cl.id}>
                      {cl.class_course.course.code} &mdash; {cl.class_course.school_class.program.code} {cl.class_course.school_class.year_level.code}-{cl.class_course.school_class.section.code}
                    </option>
                  ))
                }
              </Form.Control>
            )
          }
        </Form.Group>
      );
    }

    return (
      <Form.Group>
        <Form.Label>To</Form.Label>
        {
          messageModal.to ? (
            <InputGroup>
              <Form.Control type='text' value={messageModal.to.name} disabled />
              <InputGroup.Append>
                <Button variant='danger' onClick={this.handleRemoveTo}>
                  <FontAwesomeIcon icon='times' />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          ) : (
            <>
              <Form.Control ref={this.searchInput} type='text' value={messageModal.search.query} onChange={this.handleSearchInputChange} onFocus={this.showUsers} onBlur={this.hideUsers} />
              <div style={{ position: 'relative' }}>
              {
                messageModal.search.show && (
                  <div className='border rounded-bottom bg-light shadow-sm search-user-list' style={{ position: 'absolute', left: 0, right: 0, fontSize: '.875rem' }}>
                    {
                      messageModal.search.isLoading ? (
                        <div className='px-2 py-1'>
                          <LoadingIcon />
                        </div>
                      ) : messageModal.search.users.length === 0 ? (
                        <div className='alert-light py-1 px-2'>Nothing to show.</div>
                      ) : messageModal.search.users.map(user => (
                        <div key={user.id} className='btn-link px-2 py-1' onClick={() => this.handleAddTo(user)}>
                          {user.name}
                        </div>
                      ))
                    }
                  </div>
                )
              }
              </div>
            </>
          )
        }
      </Form.Group>
    );
  }
  renderConversations = () => {
    const { chatNotifications, messageModal, isLoading, errorMessage, isNextPageLoading, nextPageError } = this.state;
    const { currentUser } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <div className='text-right'>
          <Button variant='green' onClick={this.showMessageModal}>
            New Message
          </Button>
        </div>

        <Modal show={messageModal.show} backdrop='static' onHide={this.hideMessageModal}>
          <Modal.Header closeButton>
            <Modal.Title>New Message</Modal.Title>
          </Modal.Header>
          {
            messageModal.isLoading ? (
              <Modal.Body>
                <LoadingIcon />
              </Modal.Body>
            ) : messageModal.errorMessage ? (
              <>
                <Modal.Body>
                  <Alert variant='danger'>
                    {messageModal.errorMessage}
                  </Alert>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='light' onClick={this.hideMessageModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </>
            ) : (
              <Form onSubmit={this.handleSendMessage}>
                <Modal.Body ref={this.messageModal}>
                  {
                    messageModal.formError && (
                      <Alert variant='danger'>
                        {messageModal.formError}
                      </Alert>
                    )
                  }
                  {this.renderModalRecipient()}
                  <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control as='textarea' rows={4} style={{ resize: 'none' }} value={messageModal.message} onChange={this.handleMessageInputChange} />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='danger' className='mr-2' onClick={this.hideMessageModal} disabled={messageModal.isFormLoading}>
                    Cancel
                  </Button>
                  <Button variant='green' type='submit' disabled={messageModal.isFormLoading}>
                    Send
                  </Button>
                </Modal.Footer>
              </Form>
            )
          }
        </Modal>
        {
          chatNotifications.data.length === 0 ? (
            <Alert variant='light' className='mt-2'>
              Nothing to show.
            </Alert>
          ) : (
            <div className='conversation-list mt-2'>
              {
                chatNotifications.data.map(chatNotification => {
                  if (chatNotification.chat_conversation.title && chatNotification.chat_conversation.faculty_load_id) {
                    return (
                      <div
                        key={chatNotification.id}
                        className={`py-1 px-2 ${chatNotification.seen_at ? '' : 'unseen'}`}
                        onClick={() => this.showConversation(chatNotification.chat_conversation, chatNotification.chat_conversation.chat_participant)}>
                        <div className='h6 mb-0 d-flex text-primary align-items-center'>
                          <div className='mr-2 text-center bg-green' style={{ width: '2.5rem', height: '2.5rem', borderRadius: '2.5rem', lineHeight: '2.5rem' }}>
                            <FontAwesomeIcon icon='users' className='text-white' />
                          </div>
                          <div>
                            {chatNotification.chat_conversation.title}
                          </div>
                        </div>
                        <div className='pl-5'>
                          <div className='text-truncate'>
                            {
                              chatNotification.chat_message.chat_participant.profile.id === currentUser.id ?
                                'You: ' :
                              chatNotification.chat_message.chat_participant.profile.title ?
                                `${chatNotification.chat_message.chat_participant.profile.title} ${chatNotification.chat_message.chat_participant.profile.last_name}: ` :
                                `${chatNotification.chat_message.chat_participant.profile.first_name}: `
                            }
                            {chatNotification.chat_message.message}
                          </div>
                          <small className='d-block font-italic text-muted'>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {moment(chatNotification.chat_message.created_at).format('MMM D, YYYY hh:mm A')}
                                </Tooltip>
                              }
                              trigger={['hover', 'focus']}>
                              <FontAwesomeIcon icon='clock' />
                            </OverlayTrigger> {moment(chatNotification.chat_message.created_at).fromNow()}
                          </small>
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div
                      key={chatNotification.id}
                      className={`py-1 px-2 ${chatNotification.seen_at ? '' : 'unseen'}`}
                      onClick={() => this.showConversation(chatNotification.chat_conversation, chatNotification.chat_conversation.chat_participant)}>
                      <div className='h6 mb-0 d-flex'>
                        <NameLink {...chatNotification.chat_conversation.chat_participant.profile} />
                      </div>
                      <div className='pl-5'>
                        <div className='text-truncate'>
                          {chatNotification.chat_message.chat_participant.profile.id === currentUser.id ? 'You: ' : ''}
                          {chatNotification.chat_message.message}
                        </div>
                        <small className='d-block font-italic text-muted'>
                          <OverlayTrigger
                            overlay={
                              <Tooltip>
                                {moment(chatNotification.chat_message.created_at).format('MMM D, YYYY hh:mm A')}
                              </Tooltip>
                            }
                            trigger={['hover', 'focus']}>
                            <FontAwesomeIcon icon='clock' />
                          </OverlayTrigger> {moment(chatNotification.chat_message.created_at).fromNow()}
                        </small>
                      </div>
                    </div>
                  );
                })
              }
              {
                chatNotifications.next_page_url && (
                  <div className={`text-center p-3 view-more ${isNextPageLoading ? 'disabled' : ''}`} onClick={this.handleNextPage}>
                    {
                      isNextPageLoading && (
                        <LoadingIcon className='mr-2' sm />
                      )
                    }
                    <span>
                      View more...
                      <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
                    </span>
                    {
                      nextPageError && (
                        <div className='alert-danger rounded'>
                          {nextPageError}
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    );
  }
  render() {
    return (
      <>
        <Header active='Chat' />
        <Container>
          <Row>
            <Col className='mx-auto' md={10}>
              <div className='border rounded p-4 my-3'>
                <div className='h4'>Messages</div>
                <div className='dropdown-divider'></div>
                {this.renderConversations()}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(Chat);