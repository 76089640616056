import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Col,
  Form,
  Image,
  InputGroup,
  Modal
} from 'react-bootstrap';
import SignaturePad from 'react-signature-pad-wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Validator from 'validatorjs';
import axios from 'axios';
import axiosRequest from '../../../util/helpers/axiosRequest';
import signatureRemoveBlank from '../../../util/helpers/signatureRemoveBlank';
import { loadRule } from '../../../util';
import profileDefault from '../../../resources/image/profile-default.png';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

const titleMap = {
  students: 'Edit student',
  parents: 'Edit parent',
  staff: 'Edit staff',
  faculty: 'Edit faculty',
  'school-admins': 'Edit school administrator',
  'web-admins': 'Edit web administrator'
}

const forms = {
  staff: [
    {
      name: 'title',
      label: 'Title',
      placeholder: 'e.g. Mrs.'
    },
    {
      name: 'position',
      label: 'Position / Designation',
      placeholder: 'e.g. Cashier'
    }
  ],
  faculty: [
    {
      name: 'title',
      label: 'Title',
      placeholder: 'e.g. Prof.'
    },
    {
      name: 'position',
      label: 'Position / Designation',
      placeholder: 'e.g. Instructor'
    }
  ],
  'school-admins': [
    {
      name: 'title',
      label: 'Title',
      placeholder: 'e.g. Prof.'
    },
    {
      name: 'position',
      label: 'Position / Designation',
      placeholder: 'e.g. DBM Head'
    }
  ],
  'web-admins': [
    {
      name: 'title',
      label: 'Title',
      placeholder: 'e.g. Prof.'
    },
    {
      name: 'position',
      label: 'Position / Designation',
      placeholder: 'e.g. DBM Head'
    }
  ]
};

const commonInputs = {
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  email: '',
  phone: '',
  username: '',
  gender: '',
  birthdate: '',
  drawSignature: false
};

export default class EditUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backdrop: 'static',
      formInputs: {
        students: {
          ...commonInputs,
          isIrregular: false,
          classID: '',
          programID: '',
          yearLevelID: '',
          studentNumber: '',
          lrn: '',
          emergencyTitle: '',
          emergencyName: '',
          emergencyPhone: '',
          emergencyAddress: ''
        },
        parents: {
          ...commonInputs,
          title: '',
          student: ''
        },
        staff: {
          ...commonInputs,
          title: '',
          position: ''
        },
        faculty: {
          ...commonInputs,
          title: '',
          position: ''
        },
        'school-admins': {
          ...commonInputs,
          title: '',
          position: '',
          scope: []
        },
        'web-admins': {
          ...commonInputs,
          title: '',
          position: ''
        }
      },
      image: {
        preview: null,
        isLoading: false,
        fileName: '',
        deleteImage: false
      },
      signature: {
        preview: null,
        isLoading: false,
        fileName: '',
        deleteImage: false
      },
      isLoading: true,
      formError: ''
    };

    this.file = null;
    this.signature = null;

    this.signaturePad = createRef();
  }
  componentDidMount() {
    loadRule('date');
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data === null && this.props.data !== null) {
      const { data } = this.props;
      const formInputs = {...this.state.formInputs};
      formInputs[this.props.userType] = {
        firstName: data.profile.first_name,
        middleName: data.profile.middle_name ?? '',
        lastName: data.profile.last_name,
        suffix: data.profile.suffix ?? '',
        email: data.email,
        phone: data.profile.phone ?? '',
        username: data.username,
        gender: data.profile.gender,
        birthdate: data.profile.birthdate
      };

      switch(data.account_type) {
        case 0:
        case 2:
        case 3:
          formInputs[this.props.userType].title = data.profile.title;
          formInputs[this.props.userType].position = data.profile.position;
          break;
        case 1:
          formInputs[this.props.userType].title = data.profile.title;
          formInputs[this.props.userType].position = data.profile.position;
          formInputs[this.props.userType].scope = data.school_admin_scopes.map(scope => `${scope.school_level_id}`);
          break;
        case 4:
          formInputs[this.props.userType].title = data.profile.title;
          formInputs[this.props.userType].student = data.parent_link.student_id;
          break;
        case 5:
          formInputs[this.props.userType].studentNumber = data.profile.student_number ? data.profile.student_number : '';
          formInputs[this.props.userType].lrn = data.profile.lrn ? data.profile.lrn : '';
          formInputs[this.props.userType].isIrregular = data.student_class.school_class_id === null;
          formInputs[this.props.userType].programID = data.profile.program_id ? data.profile.program_id : '';
          formInputs[this.props.userType].yearLevelID = data.profile.year_level_id ? data.profile.year_level_id : '';
          formInputs[this.props.userType].classID = data.student_class.school_class_id ? data.student_class.school_class_id : '';

          if (data.profile.emergency_contact) {
            formInputs[this.props.userType].emergencyTitle = data.profile.emergency_contact.title;
            formInputs[this.props.userType].emergencyName = data.profile.emergency_contact.name;
            formInputs[this.props.userType].emergencyAddress = data.profile.emergency_contact.address;
            formInputs[this.props.userType].emergencyPhone = data.profile.emergency_contact.phone;
          } else {
            formInputs[this.props.userType].emergencyTitle = '';
            formInputs[this.props.userType].emergencyName = '';
            formInputs[this.props.userType].emergencyAddress = '';
            formInputs[this.props.userType].emergencyPhone = '';
          }
          break;
        default:
          break;
      }

      this.setState({
        ...this.state,
        formInputs
      }, () => {
        if (this.signaturePad && this.signaturePad.current && prevProps.show !== this.props.show) {
          this.resizeCanvas();
        }
      });
    }
  }
  resizeCanvas = () => {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1);
    this.signaturePad.current.signaturePad.canvas.width = this.signaturePad.current.signaturePad.canvas.offsetWidth * ratio;
    this.signaturePad.current.signaturePad.canvas.height = this.signaturePad.current.signaturePad.canvas.offsetHeight * ratio;
    this.signaturePad.current.signaturePad.canvas.getContext("2d").scale(ratio, ratio);
    this.signaturePad.current.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }
  handleImageChange = event => {
    const files = event.target.files;

    if (files.length > 0) {
      this.setState({
        ...this.state,
        image: {
          ...this.state.image,
          isLoading: true,
          fileName: ''
        },
        formError: ''
      }, () => {
        const fileToBase64 = file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result));
            reader.addEventListener('error', error => reject(error));
            reader.readAsDataURL(file);
          });
        };
        const image = files[0];
        let validator = {
          passed: true,
          error: null
        };

        if (typeof image.name !== 'string') {
          validator.passed = false;
          validator.error = 'Invalid file.';
        } else if (image.type !== 'image/jpeg' && image.type !== 'image/png') {
          validator.passed = false;
          validator.error = 'Invalid image format.';
        }
        
        if (validator.passed) {
          fileToBase64(image).then(imageSrc => {
            this.setState({
              ...this.state,
              image: {
                ...this.state.image,
                preview: imageSrc,
                isLoading: false,
                fileName: image.name,
                deleteImage: true
              }
            }, () => {
              this.file = image;
            });
          }).catch(() => {
            this.setState({
              ...this.state,
              image: {
                ...this.state.image,
                preview: null,
                isLoading: false,
                fileName: ''
              },
              formError:  'Failed to read image.',
            }, () => {
              this.file = null;
            });
          });
        } else {
          this.setState({
            ...this.state,
            image: {
              ...this.state.image,
              preview: null,
              isLoading: false,
              fileName: ''
            },
            formError:  validator.error,
          }, () => {
            this.file = null;
          });
        }
      });
    }
  }
  handleSignatureChange = event => {
    const files = event.target.files;

    if (files.length > 0) {
      this.setState({
        ...this.state,
        signature: {
          ...this.state.signature,
          isLoading: true,
          fileName: ''
        },
        formError: ''
      }, () => {
        const fileToBase64 = file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result));
            reader.addEventListener('error', error => reject(error));
            reader.readAsDataURL(file);
          });
        };
        const image = files[0];
        let validator = {
          passed: true,
          error: null
        };

        if (typeof image.name !== 'string') {
          validator.passed = false;
          validator.error = 'Invalid file.';
        } else if (image.type !== 'image/png') {
          validator.passed = false;
          validator.error = 'Invalid image format.';
        }
        // else if (image.size > 5*1024*1024) {
        //   validator.passed = false;
        //   validator.error = 'Image must not exceed 5MB.'
        // }
        
        if (validator.passed) {
          fileToBase64(image).then(imageSrc => {
            this.setState({
              ...this.state,
              signature: {
                ...this.state.signature,
                preview: imageSrc,
                isLoading: false,
                fileName: image.name
              }
            }, () => {
              this.signature = image;
            });
          }).catch(() => {
            this.setState({
              ...this.state,
              signature: {
                ...this.state.signature,
                preview: null,
                isLoading: false,
                fileName: ''
              },
              formError:  'Failed to read image.',
            }, () => {
              this.signature = null;
            });
          });
        } else {
          this.setState({
            ...this.state,
            signature: {
              ...this.state.signature,
              preview: null,
              isLoading: false,
              fileName: ''
            },
            formError:  validator.error,
          }, () => {
            this.signature = null;
          });
        }
      });
    }
  }
  removeImage = () => {
    this.setState({
      ...this.state,
      image: {
        ...this.state.image,
        preview: null,
        isLoading: false,
        fileName: '',
        deleteImage: false
      }
    }, () => {
      this.file = null
    });
  }
  removeSignature = () => {
    this.setState({
      ...this.state,
      signature: {
        ...this.state.signature,
        preview: null,
        isLoading: false,
        fileName: '',
        deleteImage: false
      }
    }, () => {
      this.signature = null
    });
  }
  deleteImage = () => {
    this.setState({
      ...this.state,
      image: {
        ...this.state.image,
        deleteImage: true
      }
    });
  }
  cancelDeleteImage = () => {
    this.setState({
      ...this.state,
      image: {
        ...this.state.image,
        deleteImage: false
      }
    });
  }
  deleteSignature = () => {
    this.setState({
      ...this.state,
      signature: {
        ...this.state.signature,
        deleteImage: true
      }
    });
  }
  cancelDeleteSignature = () => {
    this.setState({
      ...this.state,
      signature: {
        ...this.state.signature,
        deleteImage: false
      }
    });
  }
  handleIrregularSwitch = () => {
    const { formInputs } = this.state;

    formInputs.students.isIrregular = !formInputs.students.isIrregular;

    this.setState({
      ...this.state,
      formInputs
    });
  }
  handleSignatureSwitch = () => {
    const { formInputs } = this.state;
    const { userType } = this.props;

    formInputs[userType].drawSignature = !formInputs[userType].drawSignature;

    this.setState({
      ...this.state,
      formInputs,
      signature: {
        ...this.state.signature,
        preview: null,
        isLoading: false,
        fileName: ''
      }
    }, () => {
      this.signature = null;
    });
  }
  handleClearSignature = e => {
    this.signaturePad.current.signaturePad.clear();
  }
  handleSchoolAdminScope = e => {
    const { formInputs } = this.state;

    formInputs['school-admins'][e.target.name] = formInputs['school-admins'][e.target.name].filter(id => id !== e.target.value);
    if (e.target.checked) {
      formInputs['school-admins'][e.target.name] = [...formInputs['school-admins'][e.target.name], e.target.value];
    }

    this.setState({
      ...this.state,
      formInputs
    });
  }
  handleInputChange = (event) => {
    const { userType } = this.props;
    const { formInputs } = this.state;

    formInputs[userType][event.target.name] = event.target.value;

    this.setState({
      ...this.state,
      formInputs
    });
  }
  handleModalHide = () => {
    this.setState({
      backdrop: 'static',
      formInputs: {
        students: {
          ...commonInputs,
          isIrregular: false,
          classID: '',
          programID: '',
          yearLevelID: '',
          studentNumber: '',
          lrn: '',
          emergencyTitle: '',
          emergencyName: '',
          emergencyPhone: '',
          emergencyAddress: ''
        },
        parents: {
          ...commonInputs,
          title: '',
          student: ''
        },
        staff: {
          ...commonInputs,
          title: '',
          position: ''
        },
        faculty: {
          ...commonInputs,
          title: '',
          position: ''
        },
        'school-admins': {
          ...commonInputs,
          title: '',
          position: '',
          scope: []
        },
        'web-admins': {
          ...commonInputs,
          title: '',
          position: ''
        }
      },
      image: {
        preview: null,
        isLoading: false,
        fileName: '',
        deleteImage: false
      },
      signature: {
        preview: null,
        isLoading: false,
        fileName: '',
        deleteImage: false
      },
      isLoading: true,
      formError: ''
    }, () => {
      this.file = null;
      this.signature = null;
      this.props.onHide();
    });
  }
  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      ...this.state,
      formError: ''
    }, () => {
      const accountType = {
        students: 5,
        parents: 4,
        staff: 3,
        faculty: 2,
        'school-admins': 1,
        'web-admins': 0
      };
      const { userType } = this.props;
      const formInputs = {...this.state.formInputs[userType]};
      const { image, signature } = this.state;

      let rules = {
        firstName: 'required|string|min:2',
        middleName: 'present|string|min:2',
        lastName: 'required|string|min:2',
        suffix: 'present|string',
        email: 'required|email',
        phone: 'present|regex:/^09[0-9]{9}$/',
        username: 'required|regex:/^[A-Za-z0-9]{5,}$/',
        gender: 'required|in:Male,Female',
        birthdate: 'required|date',
        accountType: 'required|between:0,5',
      };

      let data = {
        username: formInputs.username,
        email: formInputs.email,
        phone: formInputs.phone,
        accountType: accountType[userType],
        firstName: formInputs.firstName,
        middleName: formInputs.middleName,
        lastName: formInputs.lastName,
        suffix: formInputs.suffix,
        gender: formInputs.gender,
        birthdate: formInputs.birthdate
      };

      switch(accountType[userType]) {
        case 0:
        case 2:
        case 3:
          rules.title = 'present|string';
          rules.position = 'present|string';
          data.title = formInputs.title;
          data.position = formInputs.position;
          break;
        case 1:
          rules.title = 'present|string';
          rules.position = 'present|string';
          rules.scope = 'present|array';
          rules['scope.*'] = 'integer|min:1';
          data.title = formInputs.title;
          data.position = formInputs.position;
          data.scope = formInputs.scope;
          break;
        case 4:
          rules.title = 'required|string';
          rules.studentID = 'integer|required';
          data.studentID = formInputs.student;
          data.title = formInputs.title;
          break;
        case 5:
          rules.isIrregular = 'in:1,0';
          data.isIrregular = formInputs.isIrregular ? 1 : 0;
          if (formInputs.isIrregular) {
            rules.programID = 'integer|required';
            rules.yearLevelID = 'integer|required';
            data.programID = formInputs.programID;
            data.yearLevelID = formInputs.yearLevelID;
          } else {
            rules.classID = 'integer|required';
            data.classID = formInputs.classID;
          }
          rules.studentNumber = 'required';
          data.studentNumber = formInputs.studentNumber;

          rules.lrn = 'present|string|size:12';
          data.lrn = formInputs.lrn;

          rules.emergencyName = 'required_with:emergencyTitle,emergencyAddress,emergencyPhone|string|min:2';
          rules.emergencyTitle = 'required_with:emergencyName,emergencyAddress,emergencyPhone|string';
          rules.emergencyAddress = 'required_with:emergencyTitle,emergencyName,emergencyPhone|string';
          rules.emergencyPhone = 'required_with:emergencyTitle,emergencyAddress,emergencyName|regex:/^09[0-9]{9}$/';
          data.emergencyName = formInputs.emergencyName;
          data.emergencyTitle = formInputs.emergencyTitle;
          data.emergencyAddress = formInputs.emergencyAddress;
          data.emergencyPhone = formInputs.emergencyPhone;
          break;
        default:
          this.setState({
            ...this.state,
            formError: 'Invalid account type.'
          });
          return;
      }

      let validation = new Validator(data, rules);
      validation.setAttributeNames({
        firstName: 'first name',
        middleName: 'middle name',
        lastName: 'last name',
        programID: 'program',
        yearLevelID: 'year level',
        classID: 'class',
        lrn: 'lrn',
        studentNumber: 'student number',
        emergencyName: 'emergency contact name',
        emergencyTitle: 'emergency contact title',
        emergencyAddress: 'emergency contact address',
        emergencyPhone: 'emergency contact cellphone number'
      });
      if (validation.fails()) {
        const firstKey = Object.keys(validation.errors.errors)[0];
        this.setState({
          ...this.state,
          formError: validation.errors.errors[firstKey][0]
        });
        return;
      }

      if (formInputs.drawSignature && (!this.signaturePad || !this.signaturePad.current || this.signaturePad.current.signaturePad.isEmpty())) {
        this.setState({
          ...this.state,
          formError: 'Signature is required.'
        });
        return;
      }

      axiosRequest('patch', `admin/account/id/${this.props.data.id}`, {
        ...data
      }, ({ data: { data }}) => {
        let fileRequests = [];
        if (this.file || image.deleteImage) {
          fileRequests.push(this.submitImage(this.props.data.id));
        }

        if (this.signature || signature.deleteImage || (formInputs.drawSignature && this.signaturePad && this.signaturePad.current && !this.signaturePad.current.signaturePad.isEmpty())) {
          fileRequests.push(this.submitSignature(this.props.data.id));
        }

        if (fileRequests.length === 0) {
          this.props.onSuccess(data, this.handleModalHide);
        } else {
          Promise.all(fileRequests).then(result => {
            let tempData = {};
            for (let i = 0; i < result.length; i++) {
              tempData = {...tempData, ...result[i]};
            }
            this.props.onSuccess({...data, profile: { ...data.profile, ...tempData } }, this.handleModalHide);
          }).catch(error => {
            this.setState({
              ...this.state,
              formError: error
            });
          });
        }
      }, (error) => {
        this.setState({
          ...this.state,
          isLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  submitImage = id => {
    if (this.file) {
      let formData = new window.FormData();
      formData.append('file', this.file, this.file.name);
      return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/admin/account/id/${id}/image`, formData, {
        withCredentials: true,
        header: {
          'content-type': 'multipart/form-data'
        },
      }).then(({ data: { data }}) => {
        return {
          image: data.image
        };
      }).catch(error => {
        if (error.response && error.response.status === 403) {
          this.props.history.push('/login');
        }
        throw error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      });
    }
    return axios.delete(`${process.env['REACT_APP_API_BASE_URL']}/admin/account/id/${id}/image`, {
      withCredentials: true
    }).then(({ data: { message }}) => {
      return {
        image: null
      };
    }).catch(error => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }
      throw error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
    });
  }
  submitSignature = id => {
    const { signature } = this.state;
    if (this.signature) {
      let formData = new window.FormData();
      formData.append('signature', this.signature, this.signature.name);
      return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/admin/account/id/${id}/signature`, formData, {
        withCredentials: true,
        header: {
          'content-type': 'multipart/form-data'
        },
      }).then(({ data: { data }}) => {
        return {
          signature: data.signature
        };
      }).catch(error => {
        if (error.response && error.response.status === 403) {
          this.props.history.push('/login');
        }
        throw error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      });
    } else if (signature.deleteImage) {
      return axios.delete(`${process.env['REACT_APP_API_BASE_URL']}/admin/account/id/${id}/signature`, {
        withCredentials: true
      }).then(({ data: { message }}) => {
        return {
          signature: null
        };
      }).catch(error => {
        if (error.response && error.response.status === 403) {
          this.props.history.push('/login');
        }
        throw error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      });
    }

    return new Promise((resolve, reject) => {
      let processedCanvas = signatureRemoveBlank(this.signaturePad.current.signaturePad.canvas);
      processedCanvas.toBlob(signature => {
        let formData = new window.FormData();
        formData.append('signature', signature, 'signature.png');
  
        axios.post(`${process.env['REACT_APP_API_BASE_URL']}/admin/account/id/${id}/signature`, formData, {
          withCredentials: true,
          header: {
            'content-type': 'multipart/form-data'
          },
        }).then(({ data: { data }}) => {
          resolve({
            signature: data.signature
          });
        }).catch(error => {
          if (error.response && error.response.status === 403) {
            this.props.history.push('/login');
          }
          let e = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
          throw e;
        });
      });
    });
  }
  renderFormCommons = () => {
    const { userType, data } = this.props;
    const { formInputs, signature } = this.state;
    return (
      <>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Name</Form.Label>
            <InputGroup>
              <Form.Control type='text' name='firstName' placeholder='First Name' value={formInputs[userType].firstName} onChange={this.handleInputChange} />
              <Form.Control type='text' name='middleName' placeholder='Middle Name' value={formInputs[userType].middleName} onChange={this.handleInputChange} />
              <Form.Control type='text' name='lastName' placeholder='Last Name' value={formInputs[userType].lastName} onChange={this.handleInputChange} />
              <Form.Control type='text' name='suffix' placeholder='Suffix' value={formInputs[userType].suffix} onChange={this.handleInputChange} />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} lg={6}>
            <Form.Label>Email Address</Form.Label>
            <Form.Control type='email' name='email' placeholder='e.g. juan@yahoo.com' value={formInputs[userType].email} onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} lg={6}>
            <Form.Label>Cellphone Number</Form.Label>
            <Form.Control type='phone' name='phone' placeholder='e.g. 09123456789' value={formInputs[userType].phone} onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} lg={4}>
            <Form.Label>Username</Form.Label>
            <Form.Control type='text' name='username' placeholder='e.g. dlcjuan' value={formInputs[userType].username} onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} lg={4}>
            <Form.Label>Gender</Form.Label>
            <div>
              <Form.Check type='radio' name='gender' value='Male' label='Male' id='gender-male' inline checked={formInputs[userType].gender === 'Male'} onChange={this.handleInputChange} />
              <Form.Check type='radio' name='gender' value='Female' label='Female' id='gender-female' inline checked={formInputs[userType].gender === 'Female'} onChange={this.handleInputChange} />
            </div>
          </Form.Group>
          <Form.Group as={Col} lg={4}>
            <Form.Label>Birthdate</Form.Label>
            <Form.Control type='date' name='birthdate' value={formInputs[userType].birthdate} onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <Form.Label>Signature <span className='text-muted'>(Optional)</span></Form.Label>
          <Form.Group>
            <Form.Check
                type='switch'
                id='drawSignature'
                label='Draw Signature'
                checked={formInputs[userType].drawSignature}
                onChange={this.handleSignatureSwitch}
              />
          </Form.Group>
          <div className='px-3'>
            {
              formInputs[userType].drawSignature ? (
                <>
                  <div className='text-right mb-1'>
                    <Button variant='danger' size='sm' onClick={this.handleClearSignature}>
                      <FontAwesomeIcon icon='times' /> Clear
                    </Button>
                  </div>
                  <div className='d-block position-relative border rounded'>
                    <SignaturePad redrawOnResize ref={this.signaturePad} height={200} />
                  </div>
                </>
              ) : (
                <>
                  {
                    signature.isLoading ? (
                      <div style={{ height: '10rem', textAlign: 'center', marginBottom: '.5rem' }}>
                        <LoadingIcon />
                      </div>
                    ) : !signature.preview && data.profile.signature && signature.deleteImage ? (
                      <div style={{ height: '10rem', textAlign: 'center', marginBottom: '.5rem', position: 'relative' }}>
                        <div className='position-absolute d-flex align-items-center justify-content-center' style={{ backgroundColor: 'rgba(0,0,0,.5)', top: 0, bottom: 0, right: 0, left: 0 }}>
                          <div className='text-white font-italic'>(To be deleted)</div>
                        </div>
                        <Image src={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/id/${data.profile.id}/signature`} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
                      </div>
                    ) : (signature.fileName || data.profile.signature) ? (
                      <div style={{ height: '10rem', textAlign: 'center', marginBottom: '.5rem' }}>
                        <Image src={signature.preview ? signature.preview : `${process.env['REACT_APP_API_BASE_URL']}/admin/account/id/${data.profile.id}/signature`} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
                      </div>
                    ) : null
                  }
                  <InputGroup>
                    <Form.File
                      id='signature'
                      label={signature.fileName ? signature.fileName : 'No file chosen'}
                      accept='image/png'
                      onChange={this.handleSignatureChange}
                      onClick={e => { e.target.value = null }}
                      disabled={signature.isLoading}
                      custom />
                    {
                      signature.preview ? (
                        <InputGroup.Append>
                          <Button variant='outline-danger' title='Remove' onClick={this.removeSignature} disabled={signature.isLoading}>Remove</Button>
                        </InputGroup.Append>
                      ) : signature.deleteImage && data.profile.signature && !signature.preview ? (
                        <InputGroup.Append>
                          <Button variant='outline-green' title='Cancel Delete' onClick={this.cancelDeleteSignature} disabled={signature.isLoading}>Cancel Delete</Button>
                        </InputGroup.Append>
                      ) : data.profile.signature ? (
                        <InputGroup.Append>
                          <Button variant='danger' title='Delete' onClick={this.deleteSignature} disabled={signature.isLoading}>Delete</Button>
                        </InputGroup.Append>
                      ) : null
                    }
                  </InputGroup>
                </>
              )
            }
          </div>
        </Form.Group>
      </>
    );
  }
  renderFormGroup = ({label, ...controlProps}, index) => {
    const { userType } = this.props;
    const { formInputs } = this.state;
    return (
      <Col key={index} lg={4}>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Form.Control type='text' {...controlProps} value={formInputs[userType][controlProps.name]} onChange={this.handleInputChange} />
        </Form.Group>
      </Col>
    );
  }
  renderForm = () => {
    const { userType } = this.props;

    if (userType === 'students') {
      return this.renderStudentForm();
    }

    if (userType === 'parents') {
      return this.renderParentForm();
    }

    if (userType === 'school-admins') {
      return this.renderSchoolAdminForm();
    }

    return (
      <>
        { this.renderFormCommons() }
        <Form.Row>
          { forms[userType].map(this.renderFormGroup) }
        </Form.Row>
      </>
    );
  }
  renderStudentForm = () => {
    const {
      formInputs: {
        students: {
          isIrregular,
          programID,
          yearLevelID,
          classID,
          studentNumber,
          lrn,
          emergencyTitle,
          emergencyName,
          emergencyPhone,
          emergencyAddress
        }
      }
    } = this.state;
    const { 
      options: {
        programs,
        yearLevels,
        classes
      }
    } = this.props;
    return (
      <>
        { this.renderFormCommons() }
        <div className='dropdown-divider'></div>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Check
              type='switch'
              id='isIrregular'
              label='Irregular'
              checked={isIrregular}
              onChange={this.handleIrregularSwitch}
            />
          </Form.Group>
        </Form.Row>
        {
          isIrregular ? (
            <Form.Row>
              <Form.Group as={Col} lg={6}>
                <Form.Label>Program</Form.Label>
                <Form.Control as='select' name='programID' value={programID} onChange={this.handleInputChange}>
                  <option value='' disabled hidden>Select program...</option>
                  {
                    programs.map((program, index) => (
                      <option key={index} value={program.id}>{program.code}</option>
                    ))
                  }
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} lg={6}>
                <Form.Label>Year Level</Form.Label>
                <Form.Control as='select' name='yearLevelID' value={yearLevelID} onChange={this.handleInputChange}>
                  <option value='' disabled hidden>Select year level...</option>
                  {
                    yearLevels.map((yearLevel, index) => (
                      <option key={index} value={yearLevel.id}>{yearLevel.code}</option>
                    ))
                  }
                </Form.Control>
              </Form.Group>
            </Form.Row>
          ) : (
            <Form.Group>
              <Form.Label>Class</Form.Label>
              <Form.Control as='select' name='classID' value={classID} onChange={this.handleInputChange}>
                <option value='' disabled hidden>Select class...</option>
                {
                  classes.map((cl, index) => (
                    <option key={index} value={cl.id}>{`${cl.program.code} ${cl.year_level.code} - ${cl.section.code}`}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
          )
        }
        <Form.Row>
          <Form.Group as={Col} lg={6}>
            <Form.Label>Student Number</Form.Label>
            <Form.Control type='text' name='studentNumber' placeholder='e.g. 06463' value={studentNumber} onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} lg={6}>
            <Form.Label>Learner Reference Number <span className='text-muted'>(Optional)</span></Form.Label>
            <Form.Control type='text' name='lrn' placeholder='e.g. 108123456789' value={lrn} onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>
        <div className='dropdown-divider'></div>
        <div className='h6'>
          Emergency Contact <span className='text-muted font-weight-normal'>(Optional)</span>
        </div>
        <Form.Row>
          <Form.Group as={Col} lg={3}>
            <Form.Label>Title</Form.Label>
            <Form.Control type='text' value={emergencyTitle} name='emergencyTitle' placeholder='e.g. Mrs.' onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} lg={9}>
            <Form.Label>Name</Form.Label>
            <Form.Control type='text' value={emergencyName} name='emergencyName' placeholder='e.g. Juana M. Cruz' onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} lg={7}>
            <Form.Label>Address</Form.Label>
            <Form.Control as='textarea' value={emergencyAddress} name='emergencyAddress' placeholder='e.g. Brgy. Poblacion I, Sta. Cruz, Laguna' rows={2} onChange={this.handleInputChange} />
          </Form.Group>
        <Form.Group as={Col} lg={5}>
            <Form.Label>Cellphone Number</Form.Label>
            <Form.Control type='text' value={emergencyPhone} name='emergencyPhone' placeholder='e.g. 09123456789' onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>
      </>
    );
  }
  renderParentForm = () => {
    const { options } = this.props;
    return (
      <>
        { this.renderFormCommons() }
        <Form.Row>
          <Form.Group as={Col} lg={4}>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type='text'
              name='title'
              placeholder='e.g. Mr.'
              value={this.state.formInputs.parents.title}
              onChange={this.handleInputChange} />
          </Form.Group>
          <Form.Group as={Col} lg={8}>
            <Form.Label>Title</Form.Label>
            <Form.Control
              as='select'
              name='student'
              value={this.state.formInputs.parents.student}
              onChange={this.handleInputChange}>
                <option value='' disabled hidden>Select student...</option>
              {
                this.props.data !== null && (
                  <option value={this.props.data.parent_link.student_account.id}>
                    {`${this.props.data.parent_link.student_account.username} - ${this.props.data.parent_link.student_account.profile.name}`}
                  </option>
                )
              }
              {
                options.students.map((student) => (
                  <option key={student.id} value={student.id}>{`${student.username} - ${student.profile.name}`}</option>
                ))
              }
            </Form.Control>
          </Form.Group>
        </Form.Row>
      </>
    );
  }
  renderSchoolAdminForm = () => {
    const { options } = this.props;
    const { formInputs } = this.state;
    return (
      <>
        {this.renderFormCommons()}
        <Form.Row>
          { forms['school-admins'].map(this.renderFormGroup) }
        </Form.Row>
        <div className='dropdown-divider'></div>
        <Form.Group>
          <Form.Label>
            School Level Scope <span className='text-muted'>(Optional)</span>
          </Form.Label>
          <div>
            {
              options.schoolLevels.map(schoolLevel => (
                <Form.Check
                  key={schoolLevel.id}
                  type='checkbox'
                  name='scope'
                  value={schoolLevel.id}
                  id={`scope-${schoolLevel.id}`}
                  label={schoolLevel.title}
                  inline
                  onChange={this.handleSchoolAdminScope}
                  checked={formInputs['school-admins'].scope.indexOf(`${schoolLevel.id}`) !== -1} />
              ))
            }
          </div>
          <Form.Text className='text-muted'>
            <FontAwesomeIcon icon='info-circle' /> When none is selected, the scope will <b>not</b> be limited to any school level.
          </Form.Text>
        </Form.Group>
      </>
    );
  }
  renderProfilePicture = () => {
    const { image } = this.state;
    const { data } = this.props;

    return (
      <Form.Row>
        <Form.Group as={Col} lg={{ span: 8, offset: 2 }}>
          <Form.Label>Profile Picture <span className='text-muted'>(Optional)</span></Form.Label>
          <div className='position-relative' style={{ height: '10rem', textAlign: 'center', marginBottom: '.5rem' }}>
            {
              image.isLoading ? (
                <LoadingIcon />
              ) : !image.preview && data.profile.image && image.deleteImage ? (
                <div style={{ height: '100%' }}>
                  <div className='position-absolute d-flex align-items-center justify-content-center' style={{ backgroundColor: 'rgba(0,0,0,.5)', top: 0, bottom: 0, right: 0, left: 0 }}>
                    <div className='text-white font-italic'>(To be deleted)</div>
                  </div>
                  <Image src={`${process.env['REACT_APP_API_BASE_URL']}/profile/${data.profile.id}/${data.profile.image}`} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
                </div>
              ) : (
                <Image src={image.preview ? image.preview : (data.profile.image ? `${process.env['REACT_APP_API_BASE_URL']}/profile/${data.profile.id}/${data.profile.image}` : profileDefault)} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
              )
            }
          </div>
          <InputGroup>
            <Form.File
              id='image-upload'
              label={image.fileName ? image.fileName : 'No file chosen'}
              accept='image/png,image/jpeg'
              onChange={this.handleImageChange}
              onClick={e => {e.target.value = null}}
              custom />
            {
              image.preview ? (
                <InputGroup.Append>
                  <Button variant='outline-danger' title='Remove' onClick={this.removeImage}>Remove</Button>
                </InputGroup.Append>
              ) : image.deleteImage && data.profile.image && !image.preview ? (
                <InputGroup.Append>
                  <Button variant='outline-green' title='Cancel Delete' onClick={this.cancelDeleteImage}>Cancel Delete</Button>
                </InputGroup.Append>
              ) : data.profile.image ? (
                <InputGroup.Append>
                  <Button variant='danger' title='Delete' onClick={this.deleteImage}>Delete</Button>
                </InputGroup.Append>
              ) : null
            }
          </InputGroup>
        </Form.Group>
      </Form.Row>
    );
  }
  render() {
    const { userType, show, data } = this.props;
    const { backdrop, formError } = this.state;
    return (
      <Modal size='lg' backdrop={backdrop} show={show} onHide={this.handleModalHide}>
        {
          data && (
            <>
              <Modal.Header closeButton>
                <Modal.Title>{titleMap[userType]}</Modal.Title>
              </Modal.Header>
                
              <Form onSubmit={this.handleSubmit}>
                <Modal.Body>
                  {
                    formError && (
                      <Alert variant='danger'>
                        {formError}
                      </Alert>
                    )
                  }
                  { this.renderProfilePicture() }
                  { this.renderForm() }
                </Modal.Body>
                <Modal.Footer>
                  <Button type='button' variant='danger' onClick={this.handleModalHide}>Cancel</Button>
                  <Button type='submit' variant='primary'>Save</Button>
                </Modal.Footer>
              </Form>
            </>
          )
        }
      </Modal>
    );
  }
}

EditUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

EditUserModal.defaultProps = {
  show: false,
  userType: 'students',
  onHide: () => {},
  onSuccess: () => {}
};