import React, { Component  } from 'react';
import { Alert, Button, Col, Form, InputGroup, Modal, OverlayTrigger, Tooltip, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import NameLink from '../../common/NameLink/NameLink';
import PromptDeleteModal from '../../modals/PromptDeleteModal/PromptDeleteModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../util/helpers/axiosRequest';
import './style.scss';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

export default class Attendance extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      studentsBackup: [],
      studentsResetBackup: [],
      attendance: [],
      attendanceBackup: [],
      attendanceResetBackup: [],
      savedAttendance: [],
      savedAttendanceBackup: [],
      schedule: {
        dateSchedule: {},
        weeklySchedule: {}
      },
      loadedWeeks: [],
      weekdays: [],
      currentDayIndex: 0,
      currentDisplayIndex: 0,
      attendanceCount: {},
      attendanceCountBackup: {},
      countDate: [],
      isLoading: true,
      errorMessage: '',
      hasChanged: false,
      hasChangedLog: false,
      attendanceDate: [],
      logAttendanceDate: [],
      commentModal: {
        title: '',
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null
      },
      saveModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      },
      deleteModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        day: null
      },
      activityLog: [],
      activityLogView: [],
      showSuggestions: [],
      isLogLoading: false,
      logError: '',
      logText: '',
      logModal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        data: {
          student: null,
          logs: {}
        }
      },
      isAttendanceLoading: false,
      attendanceError: '',
      asyncSchedules: []
    };

  }
  componentDidMount() {
    const { classID } = this.props.match.params;
    axiosRequest('get', `faculty/class/${classID}/attendance`, null, ({ data: { data }}) => {
      let attendanceCount = {...this.state.attendanceCount};
      let attendanceCountBackup = {...this.state.attendanceCountBackup};
      let countDate = [...this.state.countDate];
      for (let i = 0; i < data.attendanceDates.length; i++) {
        let count = {
          Present: 0,
          Late: 0,
          Absent: 0,
          Excused: 0
        };
        for (let j = 0; j < data.students.length; j++) {
          let studentAttendance = data.attendance.find(att => att.attendance_date === data.attendanceDates[i] && att.account_id === data.students[j].id);
          if (studentAttendance) {
            count[studentAttendance.mark] += 1;
          }
        }
        attendanceCount = {
          ...attendanceCount,
          [data.attendanceDates[i]]: count
        };
        attendanceCountBackup = {
          ...attendanceCountBackup,
          [data.attendanceDates[i]]: {...count}
        }
        countDate = [
          ...countDate,
          data.attendanceDates[i]
        ];
      }
      this.setState({
        ...this.state,
        students: data.students,
        attendance: data.attendance,
        attendanceBackup: [...data.attendance].map(da => ({...da})),
        savedAttendance: data.attendanceDates,
        savedAttendanceBackup: [...data.attendanceDates],
        schedule: data.schedule,
        loadedWeeks: [
          moment().isoWeek()
        ],
        attendanceCount,
        attendanceCountBackup,
        countDate,
        asyncSchedules: data.asyncSchedules
      }, () => {
        this.getWeek();
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  getAttendance = (weekStart, currentDisplayIndex) => {
    const { classID } = this.props.match.params;
    const isoWeek = weekStart.isoWeek();
    if (this.state.loadedWeeks.indexOf(isoWeek) === -1) {
      this.setState({
        ...this.state,
        isAttendanceLoading: true,
        attendanceError: ''
      }, () => {
        axiosRequest('get', `faculty/class/${classID}/attendance/${weekStart.format('YYYY-MM-DD')}`, null, ({ data: { data }}) => {
          let attendanceCount = {...this.state.attendanceCount};
          let countDate = [...this.state.countDate];
          let attendanceCountBackup = {...this.state.attendanceCountBackup};
          for (let i = 0; i < data.attendanceDates.length; i++) {
            let count = {
              Present: 0,
              Late: 0,
              Absent: 0,
              Excused: 0
            };
            for (let j = 0; j < data.students.length; j++) {
              let studentAttendance = data.attendance.find(att => att.attendance_date === data.attendanceDates[i] && att.account_id === data.students[j].id);
              if (studentAttendance) {
                count[studentAttendance.mark] += 1;
              }
            }
            attendanceCount = {
              ...attendanceCount,
              [data.attendanceDates[i]]: count
            };
            attendanceCountBackup = {
              ...attendanceCountBackup,
              [data.attendanceDates[i]]: {...count}
            }
            countDate = [
              ...countDate,
              data.attendanceDates[i]
            ];
          }
          this.setState({
            ...this.state,
            attendance: [...this.state.attendance, ...data.attendance].map(aa => ({...aa})),
            attendanceBackup: [...this.state.attendanceBackup, ...data.attendance].map(aba => ({...aba})),
            attendanceResetBackup: [...this.state.attendanceResetBackup, ...data.attendance].map(arba => ({...arba})),
            savedAttendance: [...this.state.savedAttendance, ...data.attendanceDates],
            savedAttendanceBackup: [...this.state.savedAttendanceBackup, ...data.attendanceDates],
            loadedWeeks: [...this.state.loadedWeeks, isoWeek],
            isAttendanceLoading: false,
            attendanceError: '',
            attendanceCount,
            attendanceCountBackup,
            countDate,
            asyncSchedules: [...this.state.asyncSchedules, ...data.asyncSchedules]
            // schedule: data.schedule
          }, () => {
            this.getWeek(weekStart, currentDisplayIndex);
          });
        }, (error) => {
          this.setState({
            ...this.state,
            isAttendanceLoading: false,
            attendanceError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    } else {
      this.getWeek(weekStart, currentDisplayIndex);
    }
  }
  getWeek = (weekStart, currentDisplayIndex) => {
    const { showSuggestions, savedAttendance } = this.state;

    let showToday = false;
    if(!weekStart) {
      showToday = true;
      weekStart = moment().startOf('isoWeek');
    }
    let days = [];
    let currentDayIndex = 0;

    for (let i = 0; i < 7; i++) {
      let temp = moment(weekStart).add(i, 'days');

      if (temp.isSame(moment(), 'day')) {
        currentDayIndex = i+1;
      }

      days.push({
        date: temp.format('YYYY-MM-DD'),
        weekdayFull: temp.format('dddd'),
        dateDisplay: temp.format('MMM D'),
        weekday: temp.format('ddd')
      });
    }

    this.setState({
      ...this.state,
      weekdays: days,
      currentDayIndex,
      currentDisplayIndex: currentDisplayIndex ? currentDisplayIndex : (currentDayIndex === 0 ? 1 : currentDayIndex),
      isLoading: false,
      activityLogView: []
    }, () => {
      const dateToday = moment().format('YYYY-MM-DD');
      if (showToday && savedAttendance.indexOf(dateToday) === -1 && !this.getAsyncSchedule(days[(currentDayIndex === 0 ? 1 : currentDayIndex)-1])) {
        if (showSuggestions.indexOf(dateToday) !== -1) {
          this.getActivityLog(dateToday);
        } else {
          this.setState({
            ...this.state,
            showSuggestions: [...showSuggestions, dateToday]
          }, () => {
            this.getActivityLog(dateToday);
          });
        }
      }
    });
  }
  getActivityLog = weekday => {
    const { classID } = this.props.match.params;

    if (moment().isSameOrAfter(weekday, 'day')) {
      this.setState({
        ...this.state,
        isLogLoading: true,
        logError: '',
        logText: 'Fetching activity logs...'
      }, () => {
        axiosRequest('post', `faculty/class/${classID}/attendance/activity`, {
          date: weekday
        }, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            activityLog: data
          }, () => {
            this.loadSuggestions();
          });
        }, (error) => {
          this.setState({
            ...this.state,
            isLogLoading: false,
            logError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
            logText: ''
          });
        }, this.props.history);
      });
    }
  }
  loadSuggestions = () => {
    this.setState({
      ...this.state,
      isLogLoading: true,
      logText: 'Populating...'
    }, () => {
      const { weekdays, schedule, attendanceDate, logAttendanceDate, showSuggestions, students } = this.state;
      let attendance = [...this.state.attendance].map(att => ({...att}));
      let attendanceResetBackup = (this.state.attendanceResetBackup.length > 0 ? [...this.state.attendanceResetBackup] : [...this.state.attendance]).map(att => ({...att}));
      const activityLog = {...this.state.activityLog};
      let hasChangedLog = this.state.hasChangedLog;
      let attendanceCount = {...this.state.attendanceCount};
      let countDate = [...this.state.countDate];

      for (let i = 0; i < weekdays.length; i++) {
        let weekday = weekdays[i];

        if (logAttendanceDate.indexOf(weekday.date) === -1 && showSuggestions.indexOf(weekday.date) !== -1) {
          let currentSchedule = schedule.dateSchedule[weekday.date] ? schedule.dateSchedule[weekday.date] : schedule.weeklySchedule[weekday.weekdayFull];
          let isGreaterThanCurrentDate = moment(weekday.date, 'YYYY-MM-DD').isAfter(moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD'));
          if (!isGreaterThanCurrentDate && currentSchedule.start_time && currentSchedule.end_time) {
            let count = {
              Present: 0,
              Late: 0,
              Absent: 0,
              Excused: 0
            };
            for (let j = 0; j < students.length; j++) {
              let student = students[j];
              let hasAttendance = attendance.find(att => att.attendance_date === weekday.date && att.account_id === student.id);

              let mark = 'Absent';
              if (activityLog[student.id] && activityLog[student.id][weekday.date]) {
                let startTime = moment(currentSchedule.start_time, 'HH:mm');
                let activityWithinRange = activityLog[student.id][weekday.date].filter(log => {
                  let logTime = moment(moment(log.created_at).format('HH:mm'), 'HH:mm');
                  return logTime.isBetween(startTime.clone().subtract(15, 'minutes'), startTime.clone().add(15, 'minutes'));
                });
                
                if (activityWithinRange.length > 0 && activityWithinRange[activityWithinRange.length-1].log_name !== 'Logout') {
                  mark = 'Present';
                }
              }

              count[mark] += 1;

              let tempAttendance = {
                mark,
                comment: '',
                attendance_date: weekday.date,
                account_id: student.id
              };

              if (!hasAttendance) {
                attendance = [...attendance, {...tempAttendance}];
                attendanceResetBackup = [...attendanceResetBackup, {...tempAttendance}].map(arbta => ({...arbta}));
                if (logAttendanceDate.indexOf(weekday.date) === -1) {
                  logAttendanceDate.push(weekday.date);
                }
                hasChangedLog = true;
              } else if (hasAttendance && attendanceDate.indexOf(hasAttendance.attendance_date) !== -1) {
                attendanceResetBackup = [...attendanceResetBackup, {...tempAttendance}].map(arbta => ({...arbta}));
              }
            }
            attendanceCount = {
              ...attendanceCount,
              [weekday.date]: count
            };
            countDate = [
              ...countDate,
              weekday.date
            ];
          }
        }
      }
      this.setState({
        ...this.state,
        attendance,
        attendanceResetBackup,
        logAttendanceDate,
        hasChangedLog,
        activityLog,
        isLogLoading: false,
        logText: '',
        attendanceCount,
        countDate
      });
    });
  }
  showActivityLog = student => {
    const { activityLogView } = this.state;

    this.setState({
      ...this.state,
      logModal: {
        show: true,
        isLoading: true,
        errorMessage: '',
        data: {
          student,
          logs: {...activityLogView[student.id]}
        }
      }
    }, () => {

      if (activityLogView[student.id]) {
        this.setState({
          ...this.state,
          logModal: {
            ...this.state.logModal,
            isLoading: false,
            errorMessage: ''
          }
        });
      } else {
        this.getStudentActivityLog(student.id);
      }
    });
  }
  getStudentActivityLog = studentID => {
    const { weekdays } = this.state;
    const { classID } = this.props.match.params;

    axiosRequest('post', `faculty/class/${classID}/attendance/activity/student/${studentID}`, {
      date: weekdays[0].date
    }, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        logModal: {
          ...this.state.logModal,
          isLoading: false,
          data: {
            ...this.state.logModal.data,
            logs: data
          }
        },
        activityLogView: {
          ...this.state.activityLogView,
          [studentID]: data
        }
      });
    }, error => {
      this.setState({
        ...this.state,
        logModal: {
          ...this.state.logModal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleStudentActivityRefresh = event => {
    this.setState({
      ...this.state,
      logModal: {
        ...this.state.logModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { logModal } = this.state;

      this.getStudentActivityLog(logModal.data.student.id);
    });
  }
  hideActivityLog = () => {
    this.setState({
      ...this.state,
      logModal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        data: {
          student: null,
          logs: {}
        }
      }
    });
  }
  handleNextDay = () => {
    const { currentDisplayIndex } = this.state;

    if (currentDisplayIndex === 7) {
      this.handleNextWeek();
    } else {
      this.setState({
        ...this.state,
        currentDisplayIndex: currentDisplayIndex+1
      });
    }
  }
  handlePrevDay = () => {
    const { weekdays, currentDisplayIndex } = this.state;

    if (currentDisplayIndex === 1) {
      // this.getWeek(moment(weekdays[0].date).subtract(1, 'weeks'), 7);
      this.getAttendance(moment(weekdays[0].date).subtract(1, 'weeks'), 7);
    } else {
      this.setState({
        ...this.state,
        currentDisplayIndex: currentDisplayIndex-1
      });
    }
  }
  handleNextWeek = () => {
    const { weekdays } = this.state;

    // this.getWeek(moment(weekdays[0].date).add(1, 'weeks'), 1);
    this.getAttendance(moment(weekdays[0].date).add(1, 'weeks'), 1);
  }
  handlePrevWeek = () => {
    const { weekdays } = this.state;

    // this.getWeek(moment(weekdays[0].date).subtract(1, 'weeks'));
    this.getAttendance(moment(weekdays[0].date).subtract(1, 'weeks'));
  }
  nextMark = (currentMark) => {
    const marks = ['Present', 'Absent', 'Late', 'Excused'];
    let index = marks.indexOf(currentMark);
    index = index === 3 ? 0 : index+1;
    return marks[index];
  }
  getScheduleType = day => {
    const { schedule: { dateSchedule, weeklySchedule } } = this.state;
    let scheduleType = null;

    if (weeklySchedule[day.weekdayFull]) {
      scheduleType = weeklySchedule[day.weekdayFull].type;
    }

    if (dateSchedule[day.date]) {
      scheduleType = dateSchedule[day.date].type;
    }

    return scheduleType;
  }
  handleCellClick = (weekdayIndex, studentID) => {
    const { weekdays, attendance, students, attendanceCount, countDate } = this.state;

    let attendanceDate = [...this.state.attendanceDate];
    let savedAttendanceBackup = [...this.state.savedAttendanceBackup];

    const weekday = weekdays[weekdayIndex].date;
    const attendanceColumn = attendance.filter(att => att.attendance_date === weekday);

    let newAttendance = [];
    let newAttendanceCount = {...attendanceCount};
    let newCountDate = [...countDate];

    let scheduleType = this.getScheduleType(weekdays[weekdayIndex]);

    let filteredStudents = [...students];
    if (scheduleType && scheduleType === 'Synchronous') {
      filteredStudents = filteredStudents.filter(st => !st.student_learning_platform || st.student_learning_platform.platform === 'Digital');
    } else if (scheduleType && scheduleType === 'Asynchronous') {
      filteredStudents = filteredStudents.filter(st => !st.student_learning_platform || st.student_learning_platform.platform !== 'Modular-Printed');
    }

    if (attendanceColumn.length > 0) {
      newAttendance = [...attendance].map(att => {
        if (att.attendance_date === weekday && att.account_id === studentID) {
          return {
            ...att,
            mark: this.nextMark(att.mark)
          };
        }

        return {...att};
      });
      let attData = attendance.find(att => att.attendance_date === weekday && att.account_id === studentID);
      if (attData) {
        let count = newAttendanceCount[weekday];
        count[attData.mark] = count[attData.mark]-1;
        count[this.nextMark(attData.mark)] = count[this.nextMark(attData.mark)]+1;
      } else {
        newAttendance = [
          ...newAttendance,
          {
            mark: 'Present',
            comment: '',
            attendance_date: weekday,
            account_id: studentID
          }
        ];
        newAttendanceCount[weekday].Present = newAttendanceCount[weekday].Present+1;
      }
    } else {
      newAttendance = [
        ...attendance,
        ...filteredStudents.map(st => {
          return {
            mark: 'Present',
            comment: '',
            attendance_date: weekday,
            account_id: st.id
          };
        })
      ];
      newAttendanceCount[weekday] = {
        Present: filteredStudents.length,
        Late: 0,
        Absent: 0,
        Excused: 0
      };
      newCountDate = [
        ...newCountDate,
        weekday
      ];
    }

    if (attendanceDate.indexOf(weekday) === -1) {
      attendanceDate.push(weekday);
    }

    if (savedAttendanceBackup.indexOf(weekday) === -1) {
      savedAttendanceBackup.push(weekday);
    }

    const savedAttendance = [...this.state.savedAttendance].filter(sa => sa !== weekday);

    this.setState({
      ...this.state,
      attendance: newAttendance,
      hasChanged: true,
      attendanceDate,
      savedAttendance,
      attendanceCount: newAttendanceCount,
      countDate: newCountDate
    });
  }
  showCommentModal = (event, attendanceData, student) => {
    event.stopPropagation();

    this.setState({
      ...this.state,
      commentModal: {
        title: attendanceData.comment ? 'Edit comment' : 'Add comment',
        show: true,
        isLoading: false,
        errorMessage: '',
        data: {
          attendance: attendanceData,
          student
        }
      }
    });
  }
  hideCommentModal = () => {
    this.setState({
      ...this.state,
      commentModal: {
        title: '',
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null
      }
    });
  }
  handleCommentInputChange = event => {
    const { commentModal } = this.state;
    this.setState({
      ...this.state,
      commentModal: {
        ...commentModal,
        data: {
          ...commentModal.data,
          attendance: {
            ...commentModal.data.attendance,
            comment: event.target.value
          }
        }
      }
    });
  }
  handleComment = () => {
    const { attendance, commentModal } = this.state;

    let attendanceDate = [...this.state.attendanceDate];

    const newAttendance = attendance.map(att => {
      if (att.attendance_date === commentModal.data.attendance.attendance_date && att.account_id === commentModal.data.attendance.account_id) {
        return {
          ...att,
          comment: commentModal.data.attendance.comment
        };
      }

      return att;
    });

    if (attendanceDate.indexOf(commentModal.data.attendance.attendance_date) === -1) {
      attendanceDate.push(commentModal.data.attendance.attendance_date);
    }

    this.setState({
      ...this.state,
      attendance: newAttendance,
      commentModal: {
        title: '',
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null
      },
      hasChanged: true,
      attendanceDate
    });
  }
  handleReset = () => {
    const { showSuggestions, attendanceCountBackup } = this.state;

    let keys = Object.keys(attendanceCountBackup);
    let newAttendanceCount = {};
    for (let i = 0; i < keys.length; i++) {
      newAttendanceCount[keys[i]] = {...attendanceCountBackup[keys[i]]};
    }
    this.setState({
      ...this.state,
      attendance: (showSuggestions.length > 0 ? [...this.state.attendanceResetBackup] : [...this.state.attendanceBackup]).map(ab => ({...ab})),
      hasChanged: false,
      attendanceDate: [],
      savedAttendance: [...this.state.savedAttendanceBackup],
      attendanceCount: {...newAttendanceCount}
    });
  }
  showSaveModal = () => {
    this.setState({
      ...this.state,
      saveModal: {
        show: true,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideSaveModal = () => {
    this.setState({
      ...this.state,
      saveModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleSave = () => {
    const dates = [...this.state.attendanceDate, ...this.state.logAttendanceDate].filter((v, i, t) => {
      return t.indexOf(v) === i;
    }); // get unique values

    this.setState({
      ...this.state,
      saveModal: {
        show: true,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { classID } = this.props.match.params;
      axiosRequest('post', `faculty/class/${classID}/attendance`, {
        dates: dates,
        attendance: [...this.state.attendance].filter(att => dates.indexOf(att.attendance_date) !== -1)
      }, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          attendanceBackup: [...this.state.attendance].map(a => ({...a})),
          attendanceResetBackup: [],
          savedAttendance: [...this.state.savedAttendance, ...dates],
          savedAttendanceBackup: [...this.state.savedAttendance, ...dates],
          hasChanged: false,
          attendanceDate: [],
          hasChangedLog: false,
          logAttendanceDate: [],
          showSuggestions: [],
          saveModal: {
            show: false,
            isLoading: false,
            errorMessage: ''
          }
        }, () => {
          this.getWeek();
        });
      }, (error) => {
        this.setState({
          ...this.state,
          saveModal: {
            show: true,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      });
    });
  }
  handleShowSuggestions = day => {
    const { showSuggestions, activityLog, isLogLoading } = this.state;

    if (isLogLoading) {
      return;
    }

    if (showSuggestions.indexOf(day.date) === -1) {
      const studentIDs = Object.keys(activityLog);
      
      if (studentIDs.length > 0) {
        let hasActivityFetched = false;
        for (let i = 0; i < studentIDs.length; i++) {
          let activityLogDates = Object.keys(activityLog[studentIDs[i]]);
          for (let j = 0; j < activityLogDates.length; j++) {
            if (activityLogDates[j] === day.date) {
              hasActivityFetched = true;
              break;
            }
          }
          if (hasActivityFetched) {
            break;
          }
        }
        
        if (hasActivityFetched) {
          this.setState({
            ...this.state,
            showSuggestions: [...showSuggestions, day.date]
          }, () => {
            this.loadSuggestions();
          });
          return;
        }
      }

      this.setState({
        ...this.state,
        isLogLoading: true,
        logError: '',
        logText: 'Fetching activity logs...',
        showSuggestions: [...showSuggestions, day.date]
      }, () => {
        this.getActivityLog(day.date);
      });
    } else {
      const { logAttendanceDate, attendanceDate, countDate } = this.state;
      const newShowSuggestions = [...showSuggestions].filter(ss => ss !== day.date);
      const newCountDate = [...countDate].filter(cd => cd !== day.date);

      let newAttendance = [...this.state.attendance].filter(att => {
        if (att.attendance_date === day.date) {
          return attendanceDate.indexOf(att.attendance_date) !== -1 || logAttendanceDate.indexOf(att.attendance_date) === -1;
        }

        return true;
      });

      const newLogAttendanceDate = [...this.state.logAttendanceDate].filter(lad => lad !== day.date);
      let attendanceResetBackup = (this.state.attendanceResetBackup.length > 0 ? [...this.state.attendanceResetBackup] : [...this.state.attendance]).map(att => ({...att}));
      attendanceResetBackup = attendanceResetBackup.filter(arb => {
          if (arb.attendance_date === day.date) {
            return false;
          }

          return true;
      });
      
      this.setState({
        ...this.state,
        attendance: newAttendance,
        attendanceResetBackup,
        hasChangedLog: newLogAttendanceDate.length > 0,
        logAttendanceDate: newLogAttendanceDate,
        showSuggestions: newShowSuggestions,
        countDate: newCountDate
      });
    }
  }
  handleRemoveColumn = day => {
    const { showSuggestions, logAttendanceDate, attendanceDate, savedAttendanceBackup, attendanceBackup, countDate, attendanceCount } = this.state;

    let newAttendance = [];
    let newCountDate = [...countDate];
    let newAttendanceCount = {...attendanceCount};
    if (savedAttendanceBackup.indexOf(day.date) !== -1) {
      newAttendance = [...this.state.attendance].map(att => {
        let backup = attendanceBackup.find(ab => ab.attendance_date === day.date && att.attendance_date === day.date && ab.account_id === att.account_id);
        if (backup) {
          return {...backup};
        }

        return {...att};
      });
      newAttendance = newAttendance.filter(na => {
        return attendanceBackup.find(ab => ab.attendance_date === day.date && na.attendance_date === day.date && ab.account_id === na.account_id);
      });
      let count = {
        Present: 0,
        Late: 0,
        Absent: 0,
        Excused: 0
      };
      for (let i = 0; i < newAttendance.length; i++) {
        if (newAttendance[i].attendance_date === day.date) {
          count[newAttendance[i].mark] += 1;
        }
      }
      newAttendanceCount[day.date] = count;
    } else {
      newAttendance = [...this.state.attendance].filter(att => {
        if (att.attendance_date === day.date) {
          return attendanceDate.indexOf(att.attendance_date) === -1 && logAttendanceDate.indexOf(att.attendance_date) === -1;
        }

        return true;
      });
      newCountDate = [...countDate].filter(cd => cd !== day.date);
    }

    const newLogAttendanceDate = [...this.state.logAttendanceDate].filter(lad => lad !== day.date);
    const newAttendanceDate = [...this.state.attendanceDate].filter(lad => lad !== day.date);
    const newShowSuggestions = [...showSuggestions].filter(ss => ss !== day.date);
    let newAttendanceResetBackup = (this.state.attendanceResetBackup.length > 0 ? [...this.state.attendanceResetBackup] : [...this.state.attendance]).map(att => ({...att}));
    
    if (savedAttendanceBackup.indexOf(day.date) !== -1) {
      newAttendanceResetBackup = newAttendanceResetBackup.map(att => {
        let backup = attendanceBackup.find(ab => ab.attendance_date === day.date && ab.account_id === att.account_id);
        if (backup) {
          return {...backup};
        }

        return {...att};
      });
    } else {
      newAttendanceResetBackup = newAttendanceResetBackup.filter(att => {
        if (att.attendance_date === day.date) {
          return attendanceDate.indexOf(att.attendance_date) === -1 && logAttendanceDate.indexOf(att.attendance_date) === -1;
        }

        return true;
      });
    }

    let newSavedAttendance = [...this.state.savedAttendance];

    if (savedAttendanceBackup.indexOf(day.date) !== -1) {
      newSavedAttendance.push(day.date);
    }

    this.setState({
      ...this.state,
      attendance: newAttendance,
      showSuggestions: newShowSuggestions,
      logAttendanceDate: newLogAttendanceDate,
      attendanceDate: newAttendanceDate,
      attendanceResetBackup: newAttendanceResetBackup,
      hasChangedLog: newLogAttendanceDate.length > 0,
      hasChanged: newAttendanceDate.length > 0,
      savedAttendance: newSavedAttendance,
      countDate: newCountDate,
      attendanceCount: newAttendanceCount
    });
  }
  showDeleteModal = day => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        isLoading: false,
        errorMessage: '',
        day
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        day: null
      }
    });
  }
  handleDeleteColumn = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { classID } = this.props.match.params;
      const { showSuggestions, deleteModal: { day } } = this.state;
// TODO attendance Backup
      axiosRequest('delete', `faculty/class/${classID}/attendance/${day.date}`, null, ({ data: { message }}) => {
        const newSavedAttendance = [...this.state.savedAttendance].filter(sa => sa !== day.date);
        let newAttendance = [...this.state.attendance].filter(att => att.attendance_date !== day.date);
        const newLogAttendanceDate = [...this.state.logAttendanceDate].filter(lad => lad !== day.date);
        const newAttendanceDate = [...this.state.attendanceDate].filter(lad => lad !== day.date);
        const newShowSuggestions = [...showSuggestions].filter(ss => ss !== day.date);
        const savedAttendanceBackup = [...this.state.savedAttendanceBackup].filter(sab => sab !== day.date);
        const attendanceBackup = [...this.state.attendanceBackup].filter(ab => ab.attendance_date !== day.date);
        let attendanceResetBackup = (this.state.attendanceResetBackup.length > 0 ? [...this.state.attendanceResetBackup] : [...this.state.attendance]).map(att => ({...att}));
        attendanceResetBackup = attendanceResetBackup.filter(att => att.attendance_date !== day.date);
        let newCountDate = [...this.state.countDate].filter(cd => cd !== day.date);

        this.setState({
          ...this.state,
          savedAttendance: newSavedAttendance,
          attendance: newAttendance,
          showSuggestions: newShowSuggestions,
          logAttendanceDate: newLogAttendanceDate,
          attendanceDate: newAttendanceDate,
          attendanceBackup,
          savedAttendanceBackup,
          attendanceResetBackup,
          hasChangedLog: newLogAttendanceDate.length > 0,
          hasChanged: newAttendanceDate.length > 0,
          deleteModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            day: null
          },
          countDate: newCountDate
        });
      }, error => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        })
      }, this.props.history);
    });
  }
  handleViewMaterial = material => {
    const { classID } = this.props.match.params;

    this.props.history.push(`/faculty/class/${classID}/${material.type}/${material.id}`);
  }
  renderActionIcon = day => {
    const { schedule: { weeklySchedule, dateSchedule }, showSuggestions, attendanceDate, isLogLoading, savedAttendance } = this.state;
    const schedule = dateSchedule[day.date] ? dateSchedule[day.date] : weeklySchedule[day.weekdayFull] ? weeklySchedule[day.weekdayFull] : null;
    
    let asyncSchedule = this.getAsyncSchedule(day);
    if (savedAttendance.indexOf(day.date) === -1) {
      if (attendanceDate.indexOf(day.date) === -1) {
        if (moment().isSameOrAfter(day.date, 'day') && schedule && schedule.start_time && schedule.end_time && !asyncSchedule) {
          return (
            <div className='text-center mb-1 pb-1 border-bottom'>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    {showSuggestions.indexOf(day.date) !== -1 ? 'Hide suggestions' : 'Show suggestions'}
                  </Tooltip>
                }
                trigger={['hover', 'focus']}>
                <span onClick={() => this.handleShowSuggestions(day)} className={isLogLoading ? 'disabled' : ''}>
                  {
                    showSuggestions.indexOf(day.date) !== -1 ? (
                      <FontAwesomeIcon icon='eye' className='schedule-icon text-primary' size='lg' />
                    ) : (
                      <FontAwesomeIcon icon='eye-slash' className='schedule-icon text-primary' size='lg' />
                    )
                  }
                </span>
              </OverlayTrigger>
            </div>
          );
        } else if (asyncSchedule) {
          return (
            <div className='text-center mb-1 pb-1 border-bottom'>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    {asyncSchedule.material.type === 'exam' && schedule.type === 'Exam' ? 'View exam' : 'View asynchronous material'}
                  </Tooltip>
                }
                trigger={['hover', 'focus']}>
                <span onClick={() => this.handleViewMaterial(asyncSchedule.material)} className={isLogLoading ? 'disabled' : ''}>
                  <div className='fa-layers schedule-icon text-primary'>
                    <FontAwesomeIcon icon='file' size='lg' />
                    <FontAwesomeIcon icon='eye' inverse transform='shrink-5 down-3' />
                  </div>
                </span>
              </OverlayTrigger>
            </div>
          );
        }
      } else {
        return (
          <div className='text-center mb-1 pb-1 border-bottom'>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  Clear column changes
                </Tooltip>
              }
              trigger={['hover', 'focus']}>
              <span onClick={() => this.handleRemoveColumn(day)}>
                <FontAwesomeIcon icon='times' className='schedule-icon text-danger' size='lg' />
              </span>
            </OverlayTrigger>
          </div>
        );
      }
    } else {
      if (asyncSchedule) {
        if (moment().isSameOrBefore(asyncSchedule.material.until)) {
          return (
            <div className='text-center mb-1 pb-1 border-bottom'>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    {asyncSchedule.material.type === 'exam' && schedule.type === 'Exam' ? 'View exam' : 'View asynchronous material'}
                  </Tooltip>
                }
                trigger={['hover', 'focus']}>
                <span onClick={() => this.handleViewMaterial(asyncSchedule.material)} className={isLogLoading ? 'disabled' : ''}>
                  <div className='fa-layers schedule-icon text-primary'>
                    <FontAwesomeIcon icon='file' size='lg' />
                    <FontAwesomeIcon icon='eye' inverse transform='shrink-5 down-3' />
                  </div>
                </span>
              </OverlayTrigger>
            </div>
          );
        } else {
          return (
            <div className='text-center mb-1 pb-1 border-bottom d-flex justify-content-around'>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    {asyncSchedule.material.type === 'exam' && schedule.type === 'Exam' ? 'View exam' : 'View asynchronous material'}
                  </Tooltip>
                }
                trigger={['hover', 'focus']}>
                <span onClick={() => this.handleViewMaterial(asyncSchedule.material)} className={isLogLoading ? 'disabled' : ''}>
                  <div className='fa-layers schedule-icon text-primary'>
                    <FontAwesomeIcon icon='file' size='lg' />
                    <FontAwesomeIcon icon='eye' inverse transform='shrink-5 down-3' />
                  </div>
                </span>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Delete attendance column
                  </Tooltip>
                }
                trigger={['hover', 'focus']}>
                <span onClick={() => this.showDeleteModal(day)}>
                  <FontAwesomeIcon icon='trash-alt' className='schedule-icon text-danger' size='lg' />
                </span>
              </OverlayTrigger>
            </div>
          );
        }
      }
      return (
        <div className='text-center mb-1 pb-1 border-bottom'>
          <OverlayTrigger
            overlay={
              <Tooltip>
                Delete attendance column
              </Tooltip>
            }
            trigger={['hover', 'focus']}>
            <span onClick={() => this.showDeleteModal(day)}>
              <FontAwesomeIcon icon='trash-alt' className='schedule-icon text-danger' size='lg' />
            </span>
          </OverlayTrigger>
        </div>
      );
    }
  }
  getAsyncSchedule = day => {
    const { asyncSchedules, schedule: { weeklySchedule, dateSchedule } } = this.state;

    let schedule = null;
    if (weeklySchedule[day.weekdayFull]) {
      schedule = weeklySchedule[day.weekdayFull];
    }

    if (dateSchedule[day.date]) {
      schedule = dateSchedule[day.date];
    }

    if(schedule && schedule.type !== 'Synchronous') {
      let asyncSchedule = asyncSchedules.find(as => as.schedule_date === day.date && as.start_time === schedule.start_time && as.end_time === schedule.end_time);
      return asyncSchedule;
    }

    return null;
  }
  displayAsyncSchedule = (day, schedule) => {
    const { asyncSchedules } = this.state;

    if(schedule.type !== 'Synchronous') {
      let asyncSchedule = asyncSchedules.find(as => as.schedule_date === day.date && as.start_time === schedule.start_time && as.end_time === schedule.end_time);
      if (asyncSchedule) {
        return (
          <>
            <div>{schedule.type}</div>
            {
              asyncSchedule.material.faculty_load_id ? (
                <div>{moment(asyncSchedule.material.from).format('MMM D hh:mm A')} - {moment(asyncSchedule.material.until).format('MMM D hh:mm A')}</div>
              ) : (
                <div>{moment(asyncSchedule.material.audience.from).format('MMM D hh:mm A')} - {moment(asyncSchedule.material.audience.until).format('MMM D hh:mm A')}</div>
              )
            }
          </>
        );
      }
    }

    return (
      <div>{schedule.type}</div>
    );
  }
  renderSchedule = day => {
    const { schedule: { weeklySchedule, dateSchedule } } = this.state;
    const hasScheduleDisplay = schedule => {
      let scheduleType = schedule.type ? this.displayAsyncSchedule(day, schedule) : null;
      return (
        <>
          {
            (!schedule.start_time && !schedule.end_time) ? (
              <div>No schedule</div>
            ) : (
              <div>
                <div>{moment(schedule.start_time, 'HH:mm').format('hh:mm A')} - {moment(schedule.end_time, 'HH:mm').format('hh:mm A')}</div>
                {scheduleType}
              </div>
            )
          }
        </>
      );
    };
    let tooltip = null;

    if (weeklySchedule[day.weekdayFull]) {
      tooltip = (
        <Tooltip>
          {hasScheduleDisplay(weeklySchedule[day.weekdayFull])}
        </Tooltip>
      );
    }

    if (dateSchedule[day.date]) {
      tooltip = (
        <Tooltip>
          {hasScheduleDisplay(dateSchedule[day.date])}
        </Tooltip>
      );
    }

    return tooltip ? (
      <OverlayTrigger
        overlay={tooltip}
        trigger={['hover', 'focus']}>
        <FontAwesomeIcon icon='clock' className='schedule-icon text-primary' />
      </OverlayTrigger>
    ) : null;
  }
  renderIcon = (weekdayIndex, student, disabled = false) => {
    const { weekdays, attendance } = this.state;

    if (attendance.length > 0) {
      let date = weekdays[weekdayIndex].date;
      const attendanceData = attendance.find(att => att.attendance_date === date && att.account_id === student.id);

      if (attendanceData) {
        const iconMap = {
          Present: <FontAwesomeIcon icon='check-circle' className='text-green' />,
          Absent: <FontAwesomeIcon icon={['far', 'circle']} className='text-danger' />,
          Late: <FontAwesomeIcon icon='dot-circle' className='text-danger' />,
          Excused: <FontAwesomeIcon icon='circle' className='text-green' />
        };
        return (
          <div style={{ height: '5rem' }}>
            <div style={{ height: '35%' }}></div>
            <div className=''>{iconMap[attendanceData.mark]}</div>
            {
              !disabled && (
                <div className={`text-right comment-icon px-1 ${attendanceData.comment ? 'show' : ''}`} style={{ marginTop: '4%' }}>
                  <span onClick={e => this.showCommentModal(e, attendanceData, student)} title='Comment'>
                    <FontAwesomeIcon icon='comment' className='text-info' />
                  </span>
                </div>
              )
            }
          </div>
        );
      }
    }
  }
  renderActivityLogBody = () => {
    const { logModal, weekdays } = this.state;

    if (!logModal.data.student) {
      return null;
    }

    if (logModal.isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (logModal.errorMessage) {
      return (
        <Alert variant='danger'>
          {logModal.errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        {
          Object.keys(logModal.data.logs).length > 0 ? weekdays.map((weekday, index) => {
            const currentDate = moment().format('YYYY-MM-DD');
            return moment(weekday.date).isAfter(currentDate) ? null : (
              <div key={index}>
                <b>{moment(weekday.date).format('MMMM D, YYYY')}</b>
                {
                  logModal.data.logs[weekday.date] ? (
                    <ul>
                    {
                      logModal.data.logs[weekday.date].map(log => (
                        <li key={log.id}>{log.description} <small className='font-italic' style={{ color: '#6c757d' }}>&mdash;{moment(log.created_at).format('hh:mm A')}</small></li>
                      ))
                    }
                    </ul>
                  ) : (
                    <Alert variant='light'>
                      No activities logged for this day.
                    </Alert>
                  )
                }
              </div>
            )
          }) : (
            <Alert variant='light'>
              No activities logged for this week.
            </Alert>
          )
        }
      </div>
    );
  }
  renderCell = (weekdayIndex, student) => {
    const { weekdays, currentDisplayIndex } = this.state;

    let scheduleType = this.getScheduleType(weekdays[weekdayIndex]);
    let platformType = student.student_learning_platform ? student.student_learning_platform.platform : null;

    if (platformType && platformType === 'Digital-Modular' && scheduleType && scheduleType === 'Synchronous') {
      return (
        <td className={`${currentDisplayIndex === (weekdayIndex+1) ? 'current-display' : ''} disabled`} title='Unable to set attendance to a synchronous schedule for students on "Digital-Modular" learning platform'>

        </td>
      );
    } else if (platformType && platformType === 'Modular-Printed' && scheduleType && scheduleType !== 'Exam') {
      return (
        <td className={`${currentDisplayIndex === (weekdayIndex+1) ? 'current-display' : ''} disabled`} title='Unable to set attendance to a synchronous or asynchronous schedule for students on "Modular-Printed" learning platform'>
          { this.renderIcon(weekdayIndex, student) }
        </td>
      );
    }

    let asyncSchedule = this.getAsyncSchedule(weekdays[weekdayIndex]);
    if (asyncSchedule && moment().isSameOrBefore(asyncSchedule.material.faculty_load_id ? asyncSchedule.material.until : asyncSchedule.material.audience.until)) {
      return (
        <td className={`${currentDisplayIndex === (weekdayIndex+1) ? 'current-display' : ''} disabled`} title='Can only set attendance when the availability of the material has lapsed.'>
          { this.renderIcon(weekdayIndex, student, true) }
        </td>
      );
    }

    return (
      <td onClick={() => this.handleCellClick(weekdayIndex, student.id)} className={currentDisplayIndex === (weekdayIndex+1) ? 'current-display' : ''}>
        { this.renderIcon(weekdayIndex, student) }
      </td>
    );
  }
  renderStudentTable = () => {
    const {
      students,
      weekdays,
      currentDayIndex,
      currentDisplayIndex,
      isLogLoading,
      logError,
      attendanceCount,
      countDate
    } = this.state;
    const { classInfo } = this.props;

    const male = students.filter(s => s.gender === 'Male');
    const female = students.filter(s => s.gender === 'Female');

    if (classInfo.class_course.school_class.year_level.school_level.students_alphabetical) {
      return (
        <>
          <Table bordered responsive size='sm' className='attendance-table mt-2'>
            <colgroup>
              <col style={{ width: '30%' }}></col>
              <col className={currentDayIndex === 1 ? 'current-day' : ''}></col>
              <col className={currentDayIndex === 2 ? 'current-day' : ''}></col>
              <col className={currentDayIndex === 3 ? 'current-day' : ''}></col>
              <col className={currentDayIndex === 4 ? 'current-day' : ''}></col>
              <col className={currentDayIndex === 5 ? 'current-day' : ''}></col>
              <col className={currentDayIndex === 6 ? 'current-day' : ''}></col>
              <col className={currentDayIndex === 7 ? 'current-day' : ''}></col>
            </colgroup>
            <thead>
              <tr>
                <th className='align-middle p-0'>
                  <div className='font-weight-bold d-flex'>
                    <div className='flex-fill d-flex justify-content-center'>
                      <span>
                        <div className='mb-2'><FontAwesomeIcon icon='check-circle' className='text-green' /> Present</div>
                        <div><FontAwesomeIcon icon={['far', 'circle']} className='text-danger' /> Absent</div>
                      </span>
                    </div>
                    <div className='flex-fill justify-content-center'>
                      <span>
                        <div className='mb-2'><FontAwesomeIcon icon='dot-circle' className='text-danger' /> Late</div>
                        <div><FontAwesomeIcon icon='circle' className='text-green' /> Excused</div>
                      </span>
                    </div>
                  </div>
                </th>
                {
                  weekdays.map((day, index) => (
                    <th key={index} className={`text-center${(currentDisplayIndex-1) === index ? ' current-display' : ''}`} style={{ width: '10%' }}>
                      <small>
                        {this.renderActionIcon(day)}
                        <div>{day.dateDisplay}</div>
                        <div className='font-weight-bold'>
                          <span className='mr-1'>
                            {day.weekday}
                          </span>
                            { this.renderSchedule(day) }
                        </div>
                      </small>
                    </th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                students.map(student => {
                  return (
                    <tr key={student.id}>
                      <th className='pl-2' style={{ height: '5rem' }}>
                        <div className='d-flex align-items-center'>
                          <div className='flex-fill'>
                            <NameLink
                              id={student.id}
                              name={student.formal_name}
                              image={student.image}
                              learningPlatform={student.student_learning_platform ? student.student_learning_platform.platform : null}
                              rank={student.rank} />
                          </div>
                          {
                            (!isLogLoading && !logError) && (
                            <div>
                              <Button variant='light' size='sm' title='View activity log' onClick={e => this.showActivityLog(student)}>
                                <FontAwesomeIcon icon={['fas', 'th-list']} />
                              </Button>
                            </div>
                            )
                          }
                        </div>
                      </th>
                      { this.renderCell(0, student) }
                      { this.renderCell(1, student) }
                      { this.renderCell(2, student) }
                      { this.renderCell(3, student) }
                      { this.renderCell(4, student) }
                      { this.renderCell(5, student) }
                      { this.renderCell(6, student) }
                    </tr>
                  );
                })
              }
              <tr>
                <th className='align-middle'>
                  Total:
                </th>
                {
                  weekdays.map((day, index) => (
                    <td key={index} className={(currentDisplayIndex-1) === index ? 'text-left align-top current-display px-2' : 'text-left align-top px-2'} style={{ width: '10%' }}>
                      {
                        (countDate.indexOf(day.date) !== -1 && attendanceCount[day.date]) && (
                          <small className='font-weight-bold'>
                            <div className='text-green' title='Present'>
                              <span><FontAwesomeIcon icon='check-circle' /> :</span>
                              <span className='ml-1 font-weight-bolder'>{attendanceCount[day.date].Present}</span>
                            </div>
                            {
                              attendanceCount[day.date].Late > 0 && (
                                <div className='text-danger' title='Late'>
                                  <span><FontAwesomeIcon icon='dot-circle' /> :</span>
                                  <span className='ml-1 font-weight-bolder'>{attendanceCount[day.date].Late}</span>
                                </div>
                              )
                            }
                            <div className='text-danger' title='Absent'>
                              <span><FontAwesomeIcon icon={['far', 'circle']} /> :</span>
                              <span className='ml-1 font-weight-bolder'>{attendanceCount[day.date].Absent}</span>
                            </div>
                            {
                              attendanceCount[day.date].Excused > 0 && (
                                <div className='text-green' title='Excused'>
                                  <span><FontAwesomeIcon icon='circle' /> :</span>
                                  <span className='ml-1 font-weight-bolder'>{attendanceCount[day.date].Excused}</span>
                                </div>
                              )
                            }
                          </small>
                        )
                      }
                    </td>
                  ))
                }
              </tr>
            </tbody>
          </Table>
          <div className='h6 font-italic px-1 text-right mb-3'>
            Total of <span className='font-weight-bold'>{students.length}</span> student{students.length === 1 ? '' : 's'}
            <div>
              <small className='font-italic'>{male.length} male student{male.length !== 1 ? 's' : ''}</small>
            </div>
            <div>
              <small className='font-italic'>{female.length} female student{female.length !== 1 ? 's' : ''}</small>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <Table bordered responsive size='sm' className='attendance-table mt-2'>
          <colgroup>
            <col style={{ width: '30%' }}></col>
            <col className={currentDayIndex === 1 ? 'current-day' : ''}></col>
            <col className={currentDayIndex === 2 ? 'current-day' : ''}></col>
            <col className={currentDayIndex === 3 ? 'current-day' : ''}></col>
            <col className={currentDayIndex === 4 ? 'current-day' : ''}></col>
            <col className={currentDayIndex === 5 ? 'current-day' : ''}></col>
            <col className={currentDayIndex === 6 ? 'current-day' : ''}></col>
            <col className={currentDayIndex === 7 ? 'current-day' : ''}></col>
          </colgroup>
          <thead>
            <tr>
              <th className='align-middle p-0'>
                <div className='font-weight-bold d-flex'>
                  <div className='flex-fill d-flex justify-content-center'>
                    <span>
                      <div className='mb-2'><FontAwesomeIcon icon='check-circle' className='text-green' /> Present</div>
                      <div><FontAwesomeIcon icon={['far', 'circle']} className='text-danger' /> Absent</div>
                    </span>
                  </div>
                  <div className='flex-fill justify-content-center'>
                    <span>
                      <div className='mb-2'><FontAwesomeIcon icon='dot-circle' className='text-danger' /> Late</div>
                      <div><FontAwesomeIcon icon='circle' className='text-green' /> Excused</div>
                    </span>
                  </div>
                </div>
              </th>
              {
                weekdays.map((day, index) => (
                  <th key={index} className={`text-center${(currentDisplayIndex-1) === index ? ' current-display' : ''}`} style={{ width: '10%' }}>
                    <small>
                      {this.renderActionIcon(day)}
                      <div>{day.dateDisplay}</div>
                      <div className='font-weight-bold'>
                        <span className='mr-1'>
                          {day.weekday}
                        </span>
                          { this.renderSchedule(day) }
                      </div>
                    </small>
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {
              male.length > 0 && (
                <>
                <tr className='gender-row-title bg-green text-white'>
                  <th className='text-center'>
                    <div className='mb-0'>Male</div>
                    <small className='font-italic'>{male.length} student{male.length !== 1 ? 's' : ''}</small>
                  </th>
                  <td colspan='7'></td>
                </tr>
                {
                  male.map(student => {
                    return (
                      <tr key={student.id}>
                        <th className='pl-2' style={{ height: '5rem' }}>
                          <div className='d-flex align-items-center'>
                            <div className='flex-fill'>
                              <NameLink
                                id={student.id}
                                name={student.formal_name}
                                image={student.image}
                                learningPlatform={student.student_learning_platform ? student.student_learning_platform.platform : null}
                                rank={student.rank} />
                            </div>
                            {
                              (!isLogLoading && !logError) && (
                              <div>
                                <Button variant='light' size='sm' title='View activity log' onClick={e => this.showActivityLog(student)}>
                                  <FontAwesomeIcon icon={['fas', 'th-list']} />
                                </Button>
                              </div>
                              )
                            }
                          </div>
                        </th>
                        { this.renderCell(0, student) }
                        { this.renderCell(1, student) }
                        { this.renderCell(2, student) }
                        { this.renderCell(3, student) }
                        { this.renderCell(4, student) }
                        { this.renderCell(5, student) }
                        { this.renderCell(6, student) }
                      </tr>
                    );
                  })
                }
              </>
              )
            }
            {
              female.length > 0 && (
                <>
                <tr className='bg-green text-white'>
                  <th className='text-center'>
                    <div className='mb-0'>Female</div>
                    <small className='font-italic'>{female.length} student{female.length !== 1 ? 's' : ''}</small>
                  </th>
                  <td colspan='7'></td>
                </tr>
                {
                  female.map(student => {
                    return (
                      <tr key={student.id}>
                        <th className='pl-2' style={{ height: '5rem' }}>
                          <div className='d-flex align-items-center'>
                            <div className='flex-fill'>
                              <NameLink
                                id={student.id}
                                name={student.formal_name}
                                image={student.image}
                                learningPlatform={student.student_learning_platform ? student.student_learning_platform.platform : null}
                                rank={student.rank} />
                            </div>
                            {
                              (!isLogLoading && !logError) && (
                              <div>
                                <Button variant='light' size='sm' title='View activity log' onClick={e => this.showActivityLog(student)}>
                                  <FontAwesomeIcon icon={['fas', 'th-list']} />
                                </Button>
                              </div>
                              )
                            }
                          </div>
                        </th>
                        { this.renderCell(0, student) }
                        { this.renderCell(1, student) }
                        { this.renderCell(2, student) }
                        { this.renderCell(3, student) }
                        { this.renderCell(4, student) }
                        { this.renderCell(5, student) }
                        { this.renderCell(6, student) }
                      </tr>
                    );
                  })
                }
              </>
              )
            }
            <tr>
              <th className='align-middle'>
                Total:
              </th>
              {
                weekdays.map((day, index) => (
                  <td key={index} className={(currentDisplayIndex-1) === index ? 'text-left align-top current-display px-2' : 'text-left align-top px-2'} style={{ width: '10%' }}>
                    {
                      (countDate.indexOf(day.date) !== -1 && attendanceCount[day.date]) && (
                        <small className='font-weight-bold'>
                          <div className='text-green' title='Present'>
                            <span><FontAwesomeIcon icon='check-circle' /> :</span>
                            <span className='ml-1 font-weight-bolder'>{attendanceCount[day.date].Present}</span>
                          </div>
                          {
                            attendanceCount[day.date].Late > 0 && (
                              <div className='text-danger' title='Late'>
                                <span><FontAwesomeIcon icon='dot-circle' /> :</span>
                                <span className='ml-1 font-weight-bolder'>{attendanceCount[day.date].Late}</span>
                              </div>
                            )
                          }
                          <div className='text-danger' title='Absent'>
                            <span><FontAwesomeIcon icon={['far', 'circle']} /> :</span>
                            <span className='ml-1 font-weight-bolder'>{attendanceCount[day.date].Absent}</span>
                          </div>
                          {
                            attendanceCount[day.date].Excused > 0 && (
                              <div className='text-green' title='Excused'>
                                <span><FontAwesomeIcon icon='circle' /> :</span>
                                <span className='ml-1 font-weight-bolder'>{attendanceCount[day.date].Excused}</span>
                              </div>
                            )
                          }
                        </small>
                      )
                    }
                  </td>
                ))
              }
            </tr>
          </tbody>
        </Table>
        <div className='h6 font-italic px-1 text-right mb-3'>
          Total of <span className='font-weight-bold'>{students.length}</span> student{students.length === 1 ? '' : 's'}
        </div>
      </>
    );
  }
  render() {
    const {
      isLoading,
      errorMessage,
      students,
      weekdays,
      currentDisplayIndex,
      hasChanged,
      saveModal,
      attendanceDate,
      logAttendanceDate,
      commentModal,
      logModal,
      isLogLoading,
      logError,
      logText,
      hasChangedLog,
      deleteModal,
      isAttendanceLoading,
      attendanceError,
    } = this.state;

    const changedDates = [...attendanceDate, ...logAttendanceDate].filter((v, i, t) => t.indexOf(v) === i);

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (students.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <div className='attendance'>
        <Row>
          <Col md={8} className='text-center text-md-right'>
            {
              isLogLoading ? (
                <Alert variant='light' className='py-1 mt-1 mx-0 mb-0 mt-lg-0 d-lg-inline-block'>
                  <LoadingIcon /> {logText}
                </Alert>
              ) : logError ? (
                <Alert variant='danger' className='py-1 mt-1 mx-0 mb-0 mt-lg-0 d-lg-inline-block'>
                  {logError}
                </Alert>
              ) : null
            }
          </Col>
          <Col className='d-md-none'>
            <InputGroup>
              <InputGroup.Prepend>
                <Button size='sm' onClick={this.handlePrevDay} disabled={isLogLoading || isAttendanceLoading}>
                  <FontAwesomeIcon icon='chevron-left' />
                </Button>
              </InputGroup.Prepend>
              <Form.Control className='text-center' type='text' size='sm' value={weekdays[currentDisplayIndex-1].dateDisplay} readOnly />
              <InputGroup.Append>
                <Button size='sm' onClick={this.handleNextDay} disabled={isLogLoading || isAttendanceLoading}>
                  <FontAwesomeIcon icon='chevron-right' />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col className='d-none d-md-block ml-auto' md={4}>
            <InputGroup>
              <InputGroup.Prepend>
                <Button size='sm' onClick={this.handlePrevWeek} disabled={isLogLoading || isAttendanceLoading}>
                  <FontAwesomeIcon icon='chevron-left' />
                </Button>
              </InputGroup.Prepend>
              <Form.Control className='text-center' type='text' size='sm' value={`${weekdays[0].dateDisplay} - ${weekdays[6].dateDisplay}`} readOnly />
              <InputGroup.Append>
                <Button size='sm' onClick={this.handleNextWeek} disabled={isLogLoading || isAttendanceLoading}>
                  <FontAwesomeIcon icon='chevron-right' />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
        {
          isAttendanceLoading ? (
            <LoadingIcon />
          ) : attendanceError ? (
            <Alert variant='danger'>
              {attendanceError}
            </Alert>
          ) : (
            <>
              {this.renderStudentTable()}
              {
                (hasChanged || hasChangedLog) && (
                  <div className='text-right'>
                    <Button variant='danger' size='sm' className='mr-2' onClick={this.handleReset}>
                      Reset
                    </Button>
                    <Button variant='green' size='sm' onClick={this.showSaveModal}>
                      Save Changes
                    </Button>
                  </div>
                )
              }
              <Modal show={logModal.show} onHide={this.hideActivityLog}>
                <Modal.Header closeButton>
                  <div className='modal-title h5'>{logModal.data.student ? logModal.data.student.name : ''}</div>
                </Modal.Header>
                <Modal.Body>
                  {this.renderActivityLogBody()}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='info' className='mr-auto' disabled={logModal.isLoading} onClick={this.handleStudentActivityRefresh} title='Refresh'>
                    <FontAwesomeIcon icon='sync-alt' />
                  </Button>
                  <Button variant='light' onClick={this.hideActivityLog}>Close</Button>
                </Modal.Footer>
              </Modal>
              <Modal show={commentModal.show} onHide={this.hideCommentModal} backdrop='static'>
                <Modal.Header closeButton>
                  <div className='modal-title h5'>{commentModal.title}</div>
                </Modal.Header>
                <Modal.Body>
                  {
                    commentModal.errorMessage && (
                      <Alert variant='danger'>
                        {errorMessage}
                      </Alert>
                    )
                  }
                  {
                    commentModal.data && (
                      <>
                        <b>
                          <div>{commentModal.data.student.name}</div>
                          <div className='mb-3'>{moment(commentModal.data.attendance.attendance_date).format('MMMM D, YYYY')}</div>
                        </b>
                        <Form.Control
                          as='textarea'
                          rows='4'
                          value={commentModal.data.attendance.comment}
                          placeholder='Write a comment...'
                          onChange={this.handleCommentInputChange}
                          style={{ resize: 'none' }} />
                      </>
                    )
                  }
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='danger' onClick={this.hideCommentModal}>Cancel</Button>
                  <Button variant='green' onClick={this.handleComment} disabled={commentModal.isLoading}>Save</Button>
                </Modal.Footer>
              </Modal>
              <PromptDeleteModal
                {...deleteModal}
                onHide={this.hideDeleteModal}
                onDelete={this.handleDeleteColumn}
                title='Delete attendance column'
              >
                Are you sure you want to delete the attendance data for the following date?
                {
                  deleteModal.day && (
                    <Alert variant='light'>
                      <div className='font-weight-bold'>
                        {moment(deleteModal.day.date).format('MMMM D, YYYY')}
                      </div>
                      {deleteModal.day.weekdayFull}
                    </Alert>
                  )
                }
              </PromptDeleteModal>
              <Modal show={saveModal.show} onHide={this.hideSaveModal} backdrop={saveModal.isLoading?'static':true}>
                <Modal.Header closeButton>
                  <Modal.Title>Save Attendance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {
                    saveModal.errorMessage && (
                      <Alert variant='danger'>
                        {errorMessage}
                      </Alert>
                    )
                  }
                  {
                    changedDates.length > 0 && (
                      <div>
                        <b className='d-block mb-2'>The attendance for the following date{changedDates.length > 1?'s':''} will be saved:</b>
                        <ul>
                        {
                          changedDates.sort().map((date, index) => (
                            <li key={index}>{moment(date).format('MMMM D, YYYY')}</li>
                          ))
                        }
                        </ul>
                      </div>
                    )
                  }
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='danger' onClick={this.hideSaveModal}>Cancel</Button>
                  <Button variant='green' onClick={this.handleSave} disabled={saveModal.isLoading}>Save</Button>
                </Modal.Footer>
              </Modal>
            </>
          )
        }
      </div>
    );
  }
}