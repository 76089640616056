import React from 'react';

export default function Materials(props) {
  return (
    <>
      <div className='mb-3'>
        There are five (5) types of materials:
        <ul>
          <li>Assignments</li>
          <li>Quizzes</li>
          <li>Exams</li>
          <li>Files</li>
          <li>Links</li>
        </ul>
        These materials are uploaded and created by the faculty.
      </div>
    </>
  );
}