import React, { Component } from 'react';
import { Alert, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TrueFalse, MultipleChoice, Ordering, Essay, Matching, Identification, Enumeration } from './parts';
import './style.scss';
import axiosRequest from '../../../util/helpers/axiosRequest';
import PromptDeleteModal from '../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

const questionTypeMap = {
  trueFalse: 'True/False',
  multipleChoice: 'Multiple Choice',
  ordering: 'Ordering',
  essay: 'Essay/Problem Solving',
  matching: 'Matching',
  identification: 'Identification',
  enumeration: 'Enumeration'
};

const questionRelationTypeMap = {
  trueFalse: 'question_boolean',
  multipleChoice: 'question_multiple_choice',
  ordering: 'question_ordering',
  essay: 'question_essay',
  matching: 'question_matching',
  identification: 'question_identification',
  enumeration: 'question_enumeration'
};

export default class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      createModal: {
        show: false,
        type: ''
      },
      editModal: {
        show: false,
        data: null,
        index: 0
      },
      deleteModal: {
        show: false,
        index: 0,
        data: null,
        isLoading: false,
        errorMessage: ''
      },
      isLoading: true,
      errorMessage: ''
    };
  }
  componentDidMount() {
    const { classID } = this.props.match.params;
    const { data, type } = this.props;

    axiosRequest('get', `faculty/class/${classID}/material/${type}/${data.id}/question`, null, ({ data: { data }}) => {
      data = data.map(q => {
        q.question = q[questionRelationTypeMap[q.type]];

        return q;
      });
      this.setState({
        ...this.state,
        questions: data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showAddModal = key => {
    this.setState({
      ...this.state,
      createModal: {
        show: true,
        type: key
      }
    });
  }
  hideAddModal = () => {
    this.setState({
      ...this.state,
      createModal: {
        show: false,
        type: ''
      }
    });
  }
  handleAddQuestion = data => {
    this.setState({
      ...this.state,
      questions: [...this.state.questions, data],
      createModal: {
        show: false,
        type: ''
      }
    });
  }
  showEditModal = (question, index) => {
    this.setState({
      ...this.state,
      editModal: {
        show: true,
        data: question,
        index: index+1
      }
    });
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        data: null,
        index: 0
      }
    });
  }
  handleEditQuestion = data => {
    const newQuestions = [...this.state.questions].map(question => {
      if (question.id === data.id && question.type === data.type) {
        question = {
          ...question,
          ...data
        };
        question.question = question[questionRelationTypeMap[question.type]];
      }

      return question;
    });
    this.setState({
      ...this.state,
      questions: newQuestions,
      editModal: {
        show: false,
        data: null,
        index: 0
      }
    });
  }
  showDeleteModal = (question, index) => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        index: index+1,
        data: question,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        index: 0,
        data: null,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleDeleteQuestion = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { deleteModal } = this.state;
      const { data: { id, type }, match: { params: { classID }} } = this.props;
      axiosRequest('delete', `faculty/class/${classID}/material/question/${type}/${id}/${deleteModal.data.id}`, null, ({ data: { data }}) => {
        const newQuestions = [...this.state.questions].filter(question => question.id !== deleteModal.data.id);
        this.setState({
          ...this.state,
          questions: newQuestions,
          deleteModal: {
            show: false,
            index: 0,
            data: null,
            isLoading: false,
            errorMessage: ''
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  renderCreateModal = () => {
    const { createModal } = this.state;
    const questionProps = {
      show: createModal.show,
      onCancel: this.hideAddModal,
      onSave: this.handleAddQuestion,
      history: this.props.history,
      url: `faculty/class/${this.props.match.params.classID}/material/question/${this.props.data.type}/${this.props.data.id}`
    };

    switch(createModal.type) {
      case 'trueFalse':
        return (
          <TrueFalse {...questionProps} />
        );
      case 'multipleChoice':
        return (
          <MultipleChoice {...questionProps} />
        );
      case 'ordering':
        return (
          <Ordering {...questionProps} />
        );
      case 'essay':
        return (
          <Essay {...questionProps} />
        );
      case 'matching':
        return (
          <Matching {...questionProps} />
        );
      case 'identification':
        return (
          <Identification {...questionProps} />
        );
      case 'enumeration':
        return (
          <Enumeration {...questionProps} />
        );
      default:
        return null;
    }
  }
  renderEditModal = () => {
    const { editModal } = this.state;

    if (!editModal.data) {
      return null;
    }

    const questionProps = {
      ...editModal,
      onCancel: this.hideEditModal,
      onSave: this.handleEditQuestion,
      history: this.props.history,
      url: `faculty/class/${this.props.match.params.classID}/material/question/${this.props.data.type}/${this.props.data.id}`
    };

    switch(editModal.data.type) {
      case 'trueFalse':
        return (
          <TrueFalse {...questionProps} />
        );
      case 'multipleChoice':
        return (
          <MultipleChoice {...questionProps} />
        );
      case 'ordering':
        return (
          <Ordering {...questionProps} />
        );
      case 'essay':
        return (
          <Essay {...questionProps} />
        );
      case 'matching':
        return (
          <Matching {...questionProps} />
        );
      case 'identification':
        return (
          <Identification {...questionProps} />
        );
      case 'enumeration':
        return (
          <Enumeration {...questionProps} />
        );
      default:
        return null;
    }
  }
  render() {
    const { isLoading, errorMessage, questions, deleteModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <div className='text-right'>
          <Dropdown onSelect={this.showAddModal}>
            <Dropdown.Toggle variant='green'>
              <FontAwesomeIcon icon='plus' /> Add Question
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey='enumeration'>Enumeration</Dropdown.Item>
              <Dropdown.Item eventKey='essay'>Essay/Problem Solving</Dropdown.Item>
              <Dropdown.Item eventKey='identification'>Identification</Dropdown.Item>
              <Dropdown.Item eventKey='matching'>Matching</Dropdown.Item>
              <Dropdown.Item eventKey='multipleChoice'>Multiple Choice</Dropdown.Item>
              <Dropdown.Item eventKey='ordering'>Ordering</Dropdown.Item>
              <Dropdown.Item eventKey='trueFalse'>True/False</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='dropdown-divider'></div>
        {
          questions.length > 0 ? (
            <div className='question-list'>
              {
                questions.map((question, index) => (
                  <div key={index} className='d-flex'>
                    <div className='pl-1 pr-3 h5'>{index+1}</div>
                    <div className='flex-fill'>
                      <RichTextEditor.Viewer body={question.question.description} />
                      {/* <div style={{ whiteSpace: 'pre-wrap' }}>{question.question.description}</div> */}
                      <div className='text-muted'>{questionTypeMap[question.type]}</div>
                    </div>
                    <div className='d-md-none'>
                      <Dropdown>
                        <Dropdown.Toggle as='span' className='text-green'>
                          <FontAwesomeIcon icon='cog' />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => this.showEditModal(question, index)}>
                            <FontAwesomeIcon icon='pencil-alt' /> Edit
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => this.showDeleteModal(question, index)}>
                            <FontAwesomeIcon icon='trash-alt' /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className='d-none d-md-block'>
                      <Button variant='info' size='sm' className='mr-2' onClick={() => this.showEditModal(question, index)}>
                        <FontAwesomeIcon icon='pencil-alt' />
                      </Button>
                      <Button variant='danger' size='sm' onClick={() => this.showDeleteModal(question, index)}>
                        <FontAwesomeIcon icon='trash-alt' />
                      </Button>
                    </div>
                  </div>
                ))
              }
            </div>
          ) : (
            <Alert variant='light'>
              Start adding a question.
            </Alert>
          )
        }
        {this.renderCreateModal()}
        {this.renderEditModal()}
        <PromptDeleteModal
          {...deleteModal}
          title={`Delete question ${deleteModal.index}`}
          onHide={this.hideDeleteModal}
          onDelete={this.handleDeleteQuestion}>
            <div className='font-weight-bold'>
              <div>Are you sure you want to delete the question?</div>
              <div>Students' answers on this question will also be deleted.</div>
            </div>
            {
              deleteModal.data && (
                <Alert variant='light'>
                  <RichTextEditor.Viewer body={deleteModal.data.question.description} />
                  {/* <div>{deleteModal.data.question.description}</div> */}
                  <div>{questionTypeMap[deleteModal.data.type]}</div>
                </Alert>
              )
            }
          </PromptDeleteModal>
      </>
    );
  }
}