import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../../common/Header/Header';
import AdminSchoolClasses from '../../units/SchoolClasses/AdminSchoolClasses';
import SchoolAdminClassroom from '../../units/SchoolAdminClassroom/SchoolAdminClassroom';
// import Material from './Material/Material';
// import Questions from '../../common/Questions/View/Questions';
import { Redirect, Route, Switch } from 'react-router-dom';
import Announcement from '../Announcement/Announcement';
import Assignment from './Assignment/Assignment';
import Questionable from './Questionable/Questionable';
import Events from '../Events/Events';

export default class SchoolAdmin extends Component {
  render() {
    return (
      <>
        <Header />
        <Container>
          <Switch>
            <Route exact path='/:userType' component={AdminSchoolClasses} />
            <Route exact path='/:userType/events' component={Events} />
            <Route exact path='/:userType/announcement' component={Announcement} />
            <Route path='/school-admin/class/:classID' render={props => (
              <Switch>
                <Route path='/school-admin/class/:classID/:materialType(quiz|exam)/:materialID' render={props => (
                  <Switch>
                    <Route exact path='/school-admin/class/:classID/:materialType(quiz|exam)/:materialID/:activeNav(questions|preview|submissions)' component={Questionable} />
                    <Route exact path='/school-admin/class/:classID/:materialType(exam)/:materialID/:activeNav(questions|preview|submissions|reschedule)' component={Questionable} />
                    <Redirect to={{ pathname: '/school-admin/class/:classID/:materialType(quiz|exam)/:materialID/preview' }} />
                  </Switch>
                )} />
                {/* <Route exact path='/parent/class/:classID/:materialType/:materialID' component={Material} /> */}
                {/* <Route exact path='/parent/class/:classID/:materialType(quiz|exam)/:materialID/answer' component={Questions} /> */}
                <Route exact path='/school-admin/class/:classID/:materialType(assignment)/:materialID' component={Assignment} />
                <Route exact path={`/school-admin/class/:classID/:menu`} component={SchoolAdminClassroom} />
                <Redirect to={{ pathname: '/school-admin/class/:classID/discussions' }} />
              </Switch>
            )} />
            <Redirect to={{ pathname: '/school-admin' }} />
          </Switch>
        </Container>
      </>
    );
  }
}