import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faLock,
  faSearch,
  faBell,
  faPencilAlt,
  faTrashAlt,
  faPlus,
  faEye,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faReply,
  faCheckCircle,
  faAngleDoubleRight,
  faFileUpload,
  faTimesCircle,
  faMinusCircle,
  faTimes,
  faFile,
  faImage,
  faSmileBeam,
  faCircle,
  faDotCircle,
  faChevronLeft,
  faComment,
  faClock,
  faThList,
  faCog,
  faBook,
  faFileAlt,
  faPaperclip,
  faBan,
  faQuestion,
  faCheck,
  faExclamation,
  faRedo,
  faInfoCircle,
  faLongArrowAltRight,
  faClipboardList,
  faFileExport,
  faCalendarDay,
  faTag,
  faLink,
  faExternalLinkAlt,
  faFileDownload,
  faExclamationCircle,
  faArrowLeft,
  faEnvelope,
  faEdit,
  faTrophy,
  faSignInAlt,
  faPaperPlane,
  faCalendarAlt,
  faComments,
  faFileImport,
  faArrowCircleRight,
  faEyeSlash,
  faSyncAlt,
  faAlignLeft,
  faArchive,
  faPrint,
  faUsers,
  faStopwatch,
  faLaptop,
  faBookOpen,
  faTrash,
  faAngleDoubleLeft,
  faPeopleCarry,
  faUserGraduate,
  faUserLock,
  faGlobeAsia,
  faUserClock,
  faSignOutAlt,
  faBullhorn,
  faCalendarWeek,
  faSun,
  faListOl,
  faCrown,
  faMedal,
  faSquare,
  faExpandAlt,
  faCompressAlt,
  faList,
  faLayerGroup,
  faIdCard,
  faSignature,
  faEllipsisH,
  faLongArrowAltDown,
  faFileInvoice,
  faCalendar,
  faIdBadge,
  faHandPointUp,
  faUserFriends,
  faInfo,
  faChartLine,
  faParagraph,
  faDownload
} from '@fortawesome/free-solid-svg-icons';
import {
  faSmileBeam as farSmileBeam,
  faCircle as farCircle,
  faFile as farFile,
  faComment as farComment,
  faComments as farComments
} from '@fortawesome/free-regular-svg-icons';

const solidIcons = [
  faUser,
  faLock,
  faSearch,
  faBell,
  faPencilAlt,
  faTrashAlt,
  faPlus,
  faEye,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faReply,
  faCheckCircle,
  faAngleDoubleRight,
  faFileUpload,
  faTimesCircle,
  faMinusCircle,
  faTimes,
  faFile,
  faImage,
  faSmileBeam,
  faCircle,
  faDotCircle,
  faChevronLeft,
  faComment,
  faClock,
  faThList,
  faCog,
  faBook,
  faFileAlt,
  faPaperclip,
  faBan,
  faQuestion,
  faCheck,
  faExclamation,
  faRedo,
  faInfoCircle,
  faLongArrowAltRight,
  faClipboardList,
  faFileExport,
  faCalendarDay,
  faTag,
  faLink,
  faExternalLinkAlt,
  faFileDownload,
  faExclamationCircle,
  faArrowLeft,
  faEnvelope,
  faEdit,
  faTrophy,
  faSignInAlt,
  faPaperPlane,
  faCalendarAlt,
  faComments,
  faFileImport,
  faArrowCircleRight,
  faEyeSlash,
  faSyncAlt,
  faAlignLeft,
  faArchive,
  faPrint,
  faUsers,
  faStopwatch,
  faLaptop,
  faBookOpen,
  faTrash,
  faAngleDoubleLeft,
  faPeopleCarry,
  faUserGraduate,
  faUserLock,
  faGlobeAsia,
  faUserClock,
  faSignOutAlt,
  faBullhorn,
  faCalendarWeek,
  faSun,
  faListOl,
  faCrown,
  faMedal,
  faSquare,
  faExpandAlt,
  faCompressAlt,
  faList,
  faLayerGroup,
  faIdCard,
  faSignature,
  faEllipsisH,
  faLongArrowAltDown,
  faFileInvoice,
  faCalendar,
  faIdBadge,
  faHandPointUp,
  faUserFriends,
  faInfo,
  faChartLine,
  faParagraph,
  faDownload
];

const regularIcons = [
  farSmileBeam,
  farCircle,
  farFile,
  farComment,
  farComments
];

const loadFaIcons = () => library.add(
  ...solidIcons,
  ...regularIcons
);

export default loadFaIcons;