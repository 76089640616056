import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, Breadcrumb, Card, Col, Container, Nav, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header/Header';
import { SiteNavigation, Guide, Discussion, Classroom, Attendance, Assignment, Quiz, Exam, Question, File, Link, Schedule, Grade, Badge, Students, Materials } from './parts';
import './style.scss';

const helpMenu = {
  1: [
    {
      header: {
        key: 'getting-started',
        title: 'Getting Started'
      },
      menu: [
        {
          key: 'site-navigation',
          title: 'Site Navigation'
        },
        {
          key: 'guide',
          title: 'Guide'
        }
      ]
    },
    {
      header: {
        key: 'classrooms',
        title: 'Classrooms'
      },
      menu: [
        {
          key: 'general',
          title: 'General'
        },
        {
          key: 'discussion',
          title: 'Discussion'
        },
        {
          key: 'materials',
          title: 'Materials'
        },
        {
          key: 'schedule',
          title: 'Schedule'
        },
        {
          key: 'attendance',
          title: 'Attendance'
        },
        {
          key: 'grade',
          title: 'Grades'
        },
        {
          key: 'students',
          title: 'Students'
        },
        {
          key: 'badge',
          title: 'Badges'
        }
      ]
    },
  ],
  2: [
    {
      header: {
        key: 'getting-started',
        title: 'Getting Started'
      },
      menu: [
        {
          key: 'site-navigation',
          title: 'Site Navigation'
        },
        {
          key: 'guide',
          title: 'Guide'
        }
      ]
    },
    {
      header: {
        key: 'classrooms',
        title: 'Classrooms'
      },
      menu: [
        {
          key: 'general',
          title: 'General'
        },
        {
          key: 'discussion',
          title: 'Discussion'
        },
        {
          key: 'schedule',
          title: 'Schedule'
        },
        {
          key: 'attendance',
          title: 'Attendance'
        },
        {
          key: 'grade',
          title: 'Grades'
        },
        {
          key: 'students',
          title: 'Students'
        },
        {
          key: 'badge',
          title: 'Badges'
        }
      ]
    },
    {
      header: {
        key: 'materials',
        title: 'Materials'
      },
      menu: [
        {
          key: 'assignment',
          title: 'Assignments'
        },
        {
          key: 'quiz',
          title: 'Quizzes'
        },
        {
          key: 'exam',
          title: 'Exams'
        },
        {
          key: 'question',
          title: 'Questions'
        },
        {
          key: 'file',
          title: 'Files'
        },
        {
          key: 'link',
          title: 'Links'
        }
      ]
    }
  ],
  3: [
    {
      header: {
        key: 'getting-started',
        title: 'Getting Started'
      },
      menu: [
        {
          key: 'site-navigation',
          title: 'Site Navigation'
        },
        {
          key: 'guide',
          title: 'Guide'
        }
      ]
    },
    {
      header: {
        key: 'classrooms',
        title: 'Classrooms'
      },
      menu: [
        {
          key: 'general',
          title: 'General'
        },
        {
          key: 'attendance',
          title: 'Attendance'
        }
      ]
    },
  ],
  4: [
    {
      header: {
        key: 'getting-started',
        title: 'Getting Started'
      },
      menu: [
        {
          key: 'site-navigation',
          title: 'Site Navigation'
        },
        {
          key: 'guide',
          title: 'Guide'
        }
      ]
    },
    {
      header: {
        key: 'classrooms',
        title: 'Classrooms'
      },
      menu: [
        {
          key: 'general',
          title: 'General'
        },
        {
          key: 'discussion',
          title: 'Discussion'
        },
        {
          key: 'materials',
          title: 'Materials'
        },
        {
          key: 'schedule',
          title: 'Schedule'
        },
        {
          key: 'attendance',
          title: 'Attendance'
        },
        {
          key: 'grade',
          title: 'Grades'
        },
        {
          key: 'students',
          title: 'Students'
        },
        {
          key: 'badge',
          title: 'Badges'
        }
      ]
    },
  ],
  5: [
    {
      header: {
        key: 'getting-started',
        title: 'Getting Started'
      },
      menu: [
        {
          key: 'site-navigation',
          title: 'Site Navigation'
        },
        {
          key: 'guide',
          title: 'Guide'
        }
      ]
    },
    {
      header: {
        key: 'classrooms',
        title: 'Classrooms'
      },
      menu: [
        {
          key: 'general',
          title: 'General'
        },
        {
          key: 'discussion',
          title: 'Discussion'
        },
        {
          key: 'schedule',
          title: 'Schedule'
        },
        {
          key: 'attendance',
          title: 'Attendance'
        },
        {
          key: 'grade',
          title: 'Grades'
        },
        {
          key: 'students',
          title: 'Students'
        },
        {
          key: 'badge',
          title: 'Badges'
        }
      ]
    },
    {
      header: {
        key: 'materials',
        title: 'Materials'
      },
      menu: [
        {
          key: 'assignment',
          title: 'Assignments'
        },
        {
          key: 'quiz',
          title: 'Quizzes'
        },
        {
          key: 'exam',
          title: 'Exams'
        },
        {
          key: 'question',
          title: 'Questions'
        },
        {
          key: 'file',
          title: 'Files'
        },
        {
          key: 'link',
          title: 'Links'
        }
      ]
    }
  ]
};

const headerNavigation = [
  'getting-started',
  'classrooms',
  'materials'
];

const articleNavigation = {
  'site-navigation': accountType => <SiteNavigation accountType={accountType} />,
  'guide': accountType => <Guide accountType={accountType} />,
  'discussion': accountType => <Discussion accountType={accountType} />,
  'general': accountType => <Classroom accountType={accountType} />,
  'schedule': accountType => <Schedule accountType={accountType} />,
  'attendance': accountType => <Attendance accountType={accountType} />,
  'grade': accountType => <Grade accountType={accountType} />,
  'badge': accountType => <Badge accountType={accountType} />,
  'students': accountType => <Students accountType={accountType} />,
  'assignment': accountType => <Assignment accountType={accountType} />,
  'materials': accountType => <Materials accountType={accountType} />,
  'quiz': accountType => <Quiz accountType={accountType} />,
  'exam': accountType => <Exam accountType={accountType} />,
  'question': accountType => <Question accountType={accountType} />,
  'file': accountType => <File accountType={accountType} />,
  'link': accountType => <Link accountType={accountType} />,
};

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'getting-started'
    };
  }
  componentDidMount() {
    const menus = Object.keys(articleNavigation);
    const { header, article } = this.props.match.params;
    if (header && headerNavigation.indexOf(header) === -1) {
      this.props.history.push('/help');
    } else if (header && article && menus.indexOf(article) === -1) {
      this.props.history.push('/help');
    } else if (header && !article) {
      this.setState({
        activeKey: header
      });
    }
  }
  handleSelect = activeKey => {
    this.setState({
      ...this.state,
      activeKey
    });
  }
  handleNavigation = (header, article) => {
    const menus = Object.keys(articleNavigation);
    if (menus.indexOf(article) !== -1) {
      this.props.history.push(`/help/${header}/${article}`);
    }
  }
  getNavigation = (headerKey, article) => {
    const header = helpMenu[this.props.currentUser.account_type].find(hm => hm.header.key === headerKey);

    if (header) {
      const menu = header.menu.find(hm => hm.key === article);

      if (menu) {
        return {
          header,
          menu
        };
      }
    }

    return null;
  }
  renderArticle = (header, article) => {
    const { currentUser } = this.props;
    const nav = this.getNavigation(header, article);

    if (nav) {
      return (
        <>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => this.props.history.push(`/help`)}>
              Help Menu
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {nav.header.header.title}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Row className='mb-3'>
            <Col md={3}>
              <Nav variant='pills' activeKey={nav.menu.key} onSelect={eventKey => this.handleNavigation(nav.header.header.key, eventKey)} className='flex-column'>
                {
                  nav.header.menu.map((hm, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={hm.key}>
                        {hm.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))
                }
              </Nav>
            </Col>
            <Col md={9} className='py-2'>
              {articleNavigation[article](currentUser.account_type)}
            </Col>
          </Row>
        </>
      );
    }

    return null;
  }
  renderMain = () => {
    const { activeKey } = this.state;
    return (
      <>
        <div className='h2'>
          Help Menu
        </div>
        <div className='dropdown-divider'></div>
        <Accordion onSelect={this.handleSelect} activeKey={activeKey}>
          {
            helpMenu[this.props.currentUser.account_type].map((help, index) => (
              <Card key={index}>
                <Accordion.Toggle as={Card.Header} eventKey={help.header.key} className='btn-link h6'>
                  {help.header.title} <FontAwesomeIcon icon={activeKey === help.header.key ? 'chevron-down' : 'chevron-right'} />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={help.header.key}>
                  <Card.Body>
                    <ul>
                      {
                        help.menu.map((hm, j) => (
                          <li key={j}><div className='btn-link' onClick={() => this.handleNavigation(help.header.key, hm.key)}>{hm.title}</div></li>
                        ))
                      }
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))
          }
        </Accordion>
      </>
    );
  }
  render() {
    const { header, article } = this.props.match.params;
    return (
      <>
        <Header active='Help' />
        <Container className='mt-3 help'>
          {
            article ? this.renderArticle(header, article) : this.renderMain()
          }
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(Help);