import React, { Component } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import Validator from 'validatorjs';
import moment from 'moment';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

const weekdays = [
  {
    key: 'mon',
    title: 'Monday'
  },
  {
    key: 'tue',
    title: 'Tuesday'
  },
  {
    key: 'wed',
    title: 'Wednesday'
  },
  {
    key: 'thu',
    title: 'Thursday'
  },
  {
    key: 'fri',
    title: 'Friday'
  },
  {
    key: 'sat',
    title: 'Saturday'
  },
  {
    key: 'sun',
    title: 'Sunday'
  }
];

const weekdaysMap = {
  Monday: 'mon',
  Tuesday: 'tue',
  Wednesday: 'wed',
  Thursday: 'thu',
  Friday: 'fri',
  Saturday: 'sat',
  Sunday: 'sun'
};

export default class Weekly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      formInputs: {
        mon: {
          start: '',
          end: '',
          noSchedule: false,
          type: 'sync'
        },
        tue: {
          start: '',
          end: '',
          noSchedule: false,
          type: 'sync'
        },
        wed: {
          start: '',
          end: '',
          noSchedule: false,
          type: 'sync'
        },
        thu: {
          start: '',
          end: '',
          noSchedule: false,
          type: 'sync'
        },
        fri: {
          start: '',
          end: '',
          noSchedule: false,
          type: 'sync'
        },
        sat: {
          start: '',
          end: '',
          noSchedule: false,
          type: 'sync'
        },
        sun: {
          start: '',
          end: '',
          noSchedule: false,
          type: 'sync'
        },
      },
      isFormLoading: false,
      formError: ''
    };

    this.backupData = [];
  }
  componentDidMount() {
    const { classID } = this.props.match.params;

    axiosRequest('get', `faculty/class/${classID}/schedule/weekly`, null, ({ data: { data }}) => {
      const formInputs = {...this.state.formInputs};
      
      for (let i = 0; i < data.length; i++) {
        formInputs[weekdaysMap[data[i].day]].start = data[i].start_time ?? '';
        formInputs[weekdaysMap[data[i].day]].end = data[i].end_time ?? '';
        formInputs[weekdaysMap[data[i].day]].noSchedule = !data[i].start_time && !data[i].end_time;
        formInputs[weekdaysMap[data[i].day]].type = data[i].type === 'Asynchronous' ? 'async' : 'sync';
      }

      this.backupData = data;

      this.setState({
        ...this.state,
        formInputs,
        isLoading: false,
        errorMessage: ''
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleCheck = (event, key) => {
    const formInputs = {...this.state.formInputs};

    formInputs[key].noSchedule = event.target.checked;
    this.setState({
      ...this.state,
      formInputs
    });
  }
  handleInputChange = (event, key, startEnd) => {
    const formInputs = {...this.state.formInputs};

    formInputs[key][startEnd] = event.target.value;
    this.setState({
      ...this.state,
      formInputs
    });
  }
  handleReset = () => {
    const formInputs = {...this.state.formInputs};

    for (let i = 0; i < this.backupData.length; i++) {
      formInputs[weekdaysMap[this.backupData[i].day]].start = this.backupData[i].start_time ?? '';
      formInputs[weekdaysMap[this.backupData[i].day]].end = this.backupData[i].end_time ?? '';
      formInputs[weekdaysMap[this.backupData[i].day]].noSchedule = !this.backupData[i].start_time && !this.backupData[i].end_time;
      formInputs[weekdaysMap[this.backupData[i].day]].type = this.backupData[i].type === 'Asynchronous' ? 'async' : 'sync';
    }

    this.setState({
      ...this.state,
      formInputs
    });
  }
  handleSave = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: ''
    }, () => {
      Validator.register('time', (value, requirement, attribute) => {
        return moment(value, 'HH:mm', true).isValid();
      }, 'The time is invalid.');
      
      const { formInputs } = this.state;
  
      const customErrorMessages = {
        'required_if.start': 'The start time is required if the \'No Schedule\' is not checked.',
        'required_if.end': 'The end time is required if the \'No Schedule\' is not checked.',
        'required_if.type': 'The type is required if the \'No Schedule\' is not checked.',
      };
  
      let validator = new Validator(formInputs, {
        'mon.start': [{required_if: ['mon.noSchedule', false]}, 'time'],
        'mon.end': [{required_if: ['mon.noSchedule', false]}, 'time'],
        'mon.type': [{required_if: ['mon.noSchedule', false]}, 'in:sync,async'],
        'mon.noSchedule': 'required|boolean',
        'tue.start': [{required_if: ['tue.noSchedule', false]}, 'time'],
        'tue.end': [{required_if: ['tue.noSchedule', false]}, 'time'],
        'tue.type': [{required_if: ['tue.noSchedule', false]}, 'in:sync,async'],
        'tue.noSchedule': 'required|boolean',
        'wed.start': [{required_if: ['wed.noSchedule', false]}, 'time'],
        'wed.end': [{required_if: ['wed.noSchedule', false]}, 'time'],
        'wed.type': [{required_if: ['wed.noSchedule', false]}, 'in:sync,async'],
        'wed.noSchedule': 'required|boolean',
        'thu.start': [{required_if: ['thu.noSchedule', false]}, 'time'],
        'thu.end': [{required_if: ['thu.noSchedule', false]}, 'time'],
        'thu.type': [{required_if: ['thu.noSchedule', false]}, 'in:sync,async'],
        'thu.noSchedule': 'required|boolean',
        'fri.start': [{required_if: ['fri.noSchedule', false]}, 'time'],
        'fri.end': [{required_if: ['fri.noSchedule', false]}, 'time'],
        'fri.type': [{required_if: ['fri.noSchedule', false]}, 'in:sync,async'],
        'fri.noSchedule': 'required|boolean',
        'sat.start': [{required_if: ['sat.noSchedule', false]}, 'time'],
        'sat.end': [{required_if: ['sat.noSchedule', false]}, 'time'],
        'sat.type': [{required_if: ['sat.noSchedule', false]}, 'in:sync,async'],
        'sat.noSchedule': 'required|boolean',
        'sun.start': [{required_if: ['sun.noSchedule', false]}, 'time'],
        'sun.end': [{required_if: ['sun.noSchedule', false]}, 'time'],
        'sun.type': [{required_if: ['sun.noSchedule', false]}, 'in:sync,async'],
        'sun.noSchedule': 'required|boolean',
      }, customErrorMessages);
  
      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          formError: validator.errors.errors[firstKey][0],
          isFormLoading: false
        });
        return;
      }

      const formInputsArray = weekdays.map(weekday => {
        return {
          day: weekday.title,
          ...formInputs[weekday.key]
        };
      });

      const { classID } = this.props.match.params;
      axiosRequest('post', `faculty/class/${classID}/schedule/weekly`, {
        schedule: formInputsArray
      }, ({ data: { data }}) => {
        const formInputs = {...this.state.formInputs};
      
        for (let i = 0; i < data.length; i++) {
          formInputs[weekdaysMap[data[i].day]].start = data[i].start_time ?? '';
          formInputs[weekdaysMap[data[i].day]].end = data[i].end_time ?? '';
          formInputs[weekdaysMap[data[i].day]].noSchedule = !data[i].start_time && !data[i].end_time;
          formInputs[weekdaysMap[data[i].day]].type = data[i].type === 'Asynchronous' ? 'async' : 'sync';
        }

        this.backupForm = data;

        this.setState({
          ...this.state,
          isFormLoading: false,
          formInputs
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  render() {
    const { isLoading, errorMessage, formInputs, isFormLoading, formError } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div className='weekly-schedule'>
        {
          formError && (
            <Alert variant='danger'>
              {formError}
            </Alert>
          )
        }
        <Form onSubmit={this.handleSave}>
          {
            weekdays.map((weekday, index) => (
              <div className='border-bottom' key={index}>
                <b className='d-block mb-1'>{weekday.title}</b>
                <div className='ml-3'>
                  <Form.Row>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>Start time</Form.Label>
                      <Form.Control
                        type='time'
                        value={formInputs[weekday.key].start}
                        onChange={e => this.handleInputChange(e, weekday.key, 'start')}
                        disabled={formInputs[weekday.key].noSchedule} />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>End time</Form.Label>
                      <Form.Control
                        type='time'
                        value={formInputs[weekday.key].end}
                        onChange={e => this.handleInputChange(e, weekday.key, 'end')}
                        disabled={formInputs[weekday.key].noSchedule} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row className='mb-2'>
                    <Form.Group as={Col} controlId={weekday.key} md={6}>
                      <Form.Check
                        type='checkbox'
                        label='No schedule'
                        checked={formInputs[weekday.key].noSchedule}
                        onChange={e => this.handleCheck(e, weekday.key)}/>
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>Type</Form.Label>
                      <div>
                        <Form.Check
                          id={`${weekday.key}-type-sync`}
                          type='radio'
                          label='Synchronous'
                          name={`${weekday.key}-type`}
                          value='sync'
                          checked={formInputs[weekday.key].type === 'sync'}
                          onChange={e => this.handleInputChange(e, weekday.key, 'type')}
                          disabled={formInputs[weekday.key].noSchedule}
                          inline />
                        <Form.Check
                          id={`${weekday.key}-type-async`}
                          type='radio'
                          label='Asynchronous'
                          name={`${weekday.key}-type`}
                          value='async'
                          checked={formInputs[weekday.key].type === 'async'}
                          onChange={e => this.handleInputChange(e, weekday.key, 'type')}
                          disabled={formInputs[weekday.key].noSchedule}
                          inline />
                      </div>
                    </Form.Group>
                  </Form.Row>
                </div>
              </div>
            ))
          }
          <div className='text-right mt-2'>
            <Button variant='danger' className='mr-2' onClick={this.handleReset} disabled={isFormLoading}>
              Reset
            </Button>
            <Button variant='green' type='submit' disabled={isFormLoading}>
              Save
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}