import React, { Component } from 'react';
import { Alert, Badge, Col, Nav, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import './style.scss';
import moment from 'moment';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

const typeMorphsMap = {
  "App\\Models\\Assignment": 'assignment',
  "App\\Models\\Quiz": 'quiz',
  "App\\Models\\Exam": 'exam'
};

export default class Grades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      materials: [],
      grades: [],
      categories: [],
      terms: [],
      active: 'materials'
    };
  }
  componentDidMount() {
    const { userType, classID } = this.props.match.params;

    axiosRequest('get', `${userType}/class/${classID}/grades`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleSelect = key => {
    this.setState({
      ...this.state,
      active: key
    });
  }
  renderGrade = material => {
    const { grades } = this.state;

    let gradeData = grades.find(grade => grade.gradeable_id === material.id && typeMorphsMap[grade.gradeable_type] === material.type);

    if (gradeData) {
      return <Col xs={4}><b>{gradeData.grade}</b> / {material.points}</Col>
    } else if (material.type === 'assignment') {
      if (material.has_submission) {
        return <Col xs={4} className='text-muted'>(Ungraded)</Col>
      }
    }

    return <Col xs={4} className='text-danger'>N/A</Col>
  }
  renderMaterials = category => {
    const { materials } = this.state;

    let materialsData = materials[category.id];

    if (materialsData && materialsData.length > 0) {
      return (
        <div>
          {
            materialsData.map(materialData => (
              <div>
                <Row>
                  <Col xs={8}>
                    {materialData.title}
                    <small className='ml-2 text-muted font-italic'>{moment(materialData.created_at).format('M/D/YYYY hh:mma')}</small>
                  </Col>
                  { this.renderGrade(materialData) }
                  {/* <Col xs={4}><b>{grade.grade}</b> / {grade.gradeable.points}</Col> */}
                </Row>
              </div>
            ))
          }        
        </div>
      );
    }
  }
  renderMaterialGrades = () => {
    const { categories } = this.state;

    if (categories.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <div className='grade-list mb-3'>
        {
          categories.map(category => (
            <div key={category.id}>
              <div>
                {category.name}
              </div>
              <div className='grade-list-materials'>
                { this.renderMaterials(category) }
              </div>
            </div>
          ))
        }
      </div>
    );
  }
  renderTermGrades = () => {
    const { terms } = this.state;
    const { classInfo } = this.props;

    if (terms.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <Table hover>
        <thead>
          <tr>
            <th>Term</th>
            <th className='text-center'>Grade</th>
          </tr>
        </thead>
        <tbody>
          {
            terms.map(term => (
              <tr key={term.id}>
                <td className='pl-3'>
                  {term.title}
                </td>
                <th className='text-center'>
                  {
                    classInfo.class_course.school_class.year_level.school_level.term_grades && !term.enabled_grade ? (
                      <Badge variant='danger'>
                        Inaccessible
                      </Badge>
                    ) : term.grade ? term.grade.grade : ''
                  }
                </th>
              </tr>
            ))
          }
        </tbody>
      </Table>
    );
  }
  renderContent = () => {
    const { active } = this.state;

    switch(active) {
      case 'materials':
        return this.renderMaterialGrades();
      case 'terms':
        return this.renderTermGrades();
      default:
        return null;
    }
  }
  render() {
    const { isLoading, errorMessage, categories, terms, active } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (categories.length === 0 && terms.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <Nav variant='tabs bg-light rounded-top'>
          <Nav.Item className='flex-fill'>
            <Nav.Link
              eventKey='materials'
              onSelect={this.handleSelect}
              active={active === 'materials'}>
                <FontAwesomeIcon icon='book' /> Materials
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className='flex-fill'>
            <Nav.Link
              eventKey='terms'
              onSelect={this.handleSelect}
              active={active === 'terms'}>
                <FontAwesomeIcon icon='layer-group' /> Terms
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className='p-3 border rounded-bottom border-top-0'>
          {this.renderContent()}
        </div>
      </>
    );
  }
}