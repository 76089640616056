import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, Image, ListGroup, Modal, Nav, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import NameLink from '../../../common/NameLink/NameLink';
import moment from 'moment';
import Validator from 'validatorjs';
import './style.scss';

const requiredFields = [
  {
    label: 'Image',
    key: 'image'
  },
  {
    label: 'Name',
    key: 'name'
  },
  {
    label: 'Student Number',
    key: 'student_number'
  },
  {
    label: 'Emergency Contact',
    key: 'emergency_contact'
  },
  {
    label: 'Signature',
    key: 'signature'
  },
]

export default class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      activeKey: 'requests',
      requests: {
        data: []
      },
      generated: {
        data: [],
      },
      approved: {
        data: []
      },
      declined: {
        data: []
      },
      isNextPageLoading: false,
      nextPageError: '',
      generateModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null,
        disabled: false,
        signatureBaseline: 0,
        academicYear: ''
      },
      svgBox: {
        width: 0,
        height: 0
      },
      declineModal: {
        show: false,
        comment: '',
        data: null,
        isLoading: false,
        errorMessage: ''
      },
      approveModal: {
        show: false,
        data: null,
        isLoading: false,
        errorMessage: ''
      },
    };

    this.baselineContainerRef = null;
  }
  componentDidMount() {
    this.fetchList();
    window.addEventListener('resize', () => this.updateSvg());
  }
  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateSvg());
  }
  fetchList = () => {
    const { activeKey } = this.state;
    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: ''
    }, () => {
      axiosRequest('get', `admin/id-generation/${activeKey}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          [activeKey]: data,
          isLoading: false
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  updateSvg = e => {
    const { generateModal } = this.state;
    if (generateModal.show) {
      this.setState({
        ...this.state,
        svgBox: {
          width: this.baselineContainerRef.clientWidth,
          height: this.baselineContainerRef.clientHeight
        }
      });
    }
  }
  handleNavSelect = activeKey => {
    this.setState({
      ...this.state,
      activeKey
    }, () => {
      let list = this.state[activeKey];

      if (!list.current_page) {
        this.fetchList();
      }
    });
  }
  showDeclineModal = data => {
    this.setState({
      ...this.state,
      declineModal: {
        ...this.state.declineModal,
        show: true,
        data
      }
    });
  }
  hideDeclineModal = () => {
    this.setState({
      ...this.state,
      declineModal: {
        ...this.state.declineModal,
        show: false,
        errorMessage: '',
        comment: '',
        data: null
      }
    });
  }
  handleCommentInputChange = e => {
    this.setState({
      ...this.state,
      declineModal: {
        ...this.state.declineModal,
        comment: e.target.value
      }
    });
  }
  handleDecline = () => {
    const { declineModal } = this.state;

    if (!declineModal.data) {
      return;
    }

    this.setState({
      ...this.state,
      declineModal: {
        ...this.state.declineModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      let validator = new Validator({
        comment: declineModal.comment
      }, {
        comment: 'required|string|min:2'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          declineModal: {
            ...this.state.declineModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', 'admin/id-generation/decline', {
        requestID: declineModal.data.id,
        comment: declineModal.comment
      }, ({ data: { data }}) => {
        const newRequestsData = [...this.state.requests.data].filter(req => req.id !== data.id);

        this.setState({
          ...this.state,
          declined: {
            ...this.state.declined,
            data: [
              data,
              ...this.state.declined.data
            ]
          },
          requests: {
            ...this.state.requests,
            data: newRequestsData
          },
          declineModal: {
            ...this.state.declineModal,
            show: false,
            isLoading: false,
            errorMessage: '',
            comment: '',
            data: null
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          declineModal: {
            ...this.state.declineModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showGenerateModal = req => {
    let frontTemplate = '';
    let backTemplate = '';

    if (req.profile.student_class) {
      frontTemplate = req.profile.student_class?.school_class?.year_level?.school_level?.front_id_template?.url;
      backTemplate = req.profile.student_class?.school_class?.year_level?.school_level?.back_id_template?.url;

      if (req.profile.student_class?.school_class?.program?.front_id_template?.url) {
        frontTemplate = req.profile.student_class?.school_class?.program?.front_id_template?.url;
      }

      if (req.profile.student_class?.school_class?.program?.back_id_template?.url) {
        backTemplate = req.profile.student_class?.school_class?.program?.back_id_template?.url;
      }
    } else if (req.profile.program && req.profile.year_level) {
      frontTemplate = req.profile.year_level?.school_level?.front_id_template?.url;
      backTemplate = req.profile.year_level?.school_level?.back_id_template?.url;

      if (req.profile.program?.front_id_template?.url) {
        frontTemplate = req.profile.program?.front_id_template?.url;
      }

      if (req.profile.program?.back_id_template?.url) {
        backTemplate = req.profile.program?.back_id_template?.url;
      }
    }
    let isIncomplete = requiredFields.reduce((t, c, i) => !(req.profile[c.key] && !t), false);
    if (!frontTemplate || !backTemplate || isIncomplete) {
      this.setState({
        ...this.state,
        generateModal: {
          ...this.state.generateModal,
          show: true,
          data: req,
          errorMessage: isIncomplete ? 'Required fields are incomplete' : 'Upload all the front and back templates first',
          disabled: true
        }
      });
    } else {
      this.setState({
        ...this.state,
        generateModal: {
          ...this.state.generateModal,
          show: true,
          data: req
        }
      });
    }
  }
  hideGenerateModal = () => {
    this.setState({
      ...this.state,
      generateModal: {
        ...this.state.generateModal,
        show: false,
        data: null,
        disabled: false,
        errorMessage: '',
        signatureBaseline: 0,
        academicYear: ''
      }
    });
  }
  handleBaselineChange = e => {
    this.setState({
      ...this.state,
      generateModal: {
        ...this.state.generateModal,
        signatureBaseline: e.target.value
      }
    });
  }
  handleAcademicYearChange = e => {
    this.setState({
      ...this.state,
      generateModal: {
        ...this.state.generateModal,
        academicYear: e.target.value
      }
    });
  }
  handleGenerate = e => {
    e.preventDefault();

    const { generateModal } = this.state;

    this.setState({
      ...this.state,
      generateModal: {
        ...this.state.generateModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      axiosRequest('post', 'admin/id-generation/generate', {
        requestID: generateModal.data.id,
        signatureBaseline: generateModal.signatureBaseline,
        academicYear: generateModal.academicYear
      }, ({ data: { data }}) => {
        let newRequestData = [...this.state.requests.data].filter(r => r.id !== data.id);
        let newGeneratedData = [...this.state.generated.data].filter(r => r.id !== data.id);
        this.setState({
          ...this.state,
          requests: {
            ...this.state.requests,
            data: newRequestData
          },
          generated: {
            ...this.state.generated,
            data: [
              data,
              ...newGeneratedData
            ]
          },
          generateModal: {
            ...this.state.generateModal,
            show: false,
            data: null,
            disabled: false,
            errorMessage: '',
            signatureBaseline: 0,
            academicYear: '',
            isLoading: false
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          generateModal: {
            ...this.state.generateModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showApproveModal = data => {
    this.setState({
      ...this.state,
      approveModal: {
        ...this.state.approveModal,
        show: true,
        data
      }
    });
  }
  hideApproveModal = () => {
    this.setState({
      ...this.state,
      approveModal: {
        ...this.state.approveModal,
        show: false,
        errorMessage: '',
        data: null
      }
    });
  }
  handleApprove = () => {
    const { approveModal } = this.state;

    if (!approveModal.data) {
      return;
    }

    this.setState({
      ...this.state,
      approveModal: {
        ...this.state.approveModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      axiosRequest('post', 'admin/id-generation/approve', {
        requestID: approveModal.data.id
      }, ({ data: { data }}) => {
        const newRequestsData = [...this.state.requests.data].filter(req => req.id !== data.id);

        this.setState({
          ...this.state,
          approved: {
            ...this.state.approved,
            data: [
              data,
              ...this.state.approved.data
            ]
          },
          requests: {
            ...this.state.requests,
            data: newRequestsData
          },
          approveModal: {
            ...this.state.approveModal,
            show: false,
            isLoading: false,
            errorMessage: '',
            data: null
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          approveModal: {
            ...this.state.approveModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  renderApproved = req => {
    return (
      <ListGroup.Item key={req.id}>
        <div className='d-flex'>
          <div className='flex-fill d-flex'>
            <NameLink {...req.profile} nameStyle={{ fontSize: '1.2rem', fontWeight: '500'}} />
          </div>
          <div className='align-self-end font-italic text-muted'>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {moment(req.created_at).format('MMMM D, YYYY hh:mm A')}
                </Tooltip>
              }
              trigger={['hover', 'focus']}>
              <FontAwesomeIcon icon='clock' />
            </OverlayTrigger> {moment(req.created_at).fromNow()}
          </div>
        </div>
        <div className='dropdown-divider'></div>
        <Row>
          <Col md={6}>
            <div>Front</div>
            <div style={{ height: '10rem', textAlign: 'center' }}>
              {
                req.data.frontUrl ? (
                  <a href={req.data.frontUrl}>
                    <Image src={req.data.frontUrl} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                  </a>
                ) : (
                  <div style={{ lineHeight: '10rem' }} className='px-1 border rounded border-danger'>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          No image found
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
                    </OverlayTrigger>
                  </div>
                )
              }
            </div>
          </Col>
          <Col md={6}>
            <div>Back</div>
            <div style={{ height: '10rem', textAlign: 'center' }}>
              {
                req.data.backUrl ? (
                  <a href={req.data.backUrl}>
                    <Image src={req.data.backUrl} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                  </a>
                ) : (
                  <div style={{ lineHeight: '10rem' }} className='px-1 border rounded border-danger'>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          No image found
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
                    </OverlayTrigger>
                  </div>
                )
              }
            </div>
          </Col>
        </Row>
        <div className='dropdown-divider'></div>
        <Alert variant='green'>
          Request was approved on <b>{moment(req.date_approved).format('MMMM D, YYYY hh:mm A')}</b>
        </Alert>
      </ListGroup.Item>
    )
  }
  renderDeclined = req => {
    return (
      <ListGroup.Item key={req.id}>
        <div className='d-flex'>
          <div className='flex-fill d-flex'>
            <NameLink {...req.profile} nameStyle={{ fontSize: '1.2rem', fontWeight: '500'}} />
          </div>
          <div className='align-self-end font-italic text-muted'>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {moment(req.created_at).format('MMMM D, YYYY hh:mm A')}
                </Tooltip>
              }
              trigger={['hover', 'focus']}>
              <FontAwesomeIcon icon='clock' />
            </OverlayTrigger> {moment(req.created_at).fromNow()}
          </div>
        </div>
        <div className='dropdown-divider'></div>
        {
          requiredFields.map((field, i) => (
            <div key={i} className={`d-flex ${req.profile[field.key] ? 'text-green' : 'text-danger'}`}>
              <div>
                {
                  req.profile[field.key] ? (
                    <FontAwesomeIcon icon='check' className='fa-fw' />
                  ) : (
                    <FontAwesomeIcon icon='times' className='fa-fw' />
                  )
                }
              </div>
              <div className='flex-fill ml-2'>
                {field.label}
              </div>
            </div>
          ))
        }
        <div className='d-flex text-muted'>
          <div>
            {
              req.profile?.lrn ? (
                <FontAwesomeIcon icon='check' className='fa-fw' />
              ) : (
                <FontAwesomeIcon icon='times' className='fa-fw' />
              )
            }
          </div>
          <div className='flex-fill ml-2'>
            LRN
          </div>
        </div>
        <div className='dropdown-divider'></div>
        <Alert variant='danger'>
          Request was declined on <b>{moment(req.date_declined).format('MMMM D, YYYY hh:mm A')}</b>
          {
            req.comment && (
              <div className='d-flex'>
                <div>
                  <FontAwesomeIcon icon='comment' className='fa-fw mr-1' />
                </div>
                <div className='font-italic'>
                  {req.comment}
                </div>
              </div>
            )
          }
        </Alert>
      </ListGroup.Item>
    )
  }
  renderGenerated = req => {
    const { isNextPageLoading } = this.state;

    return (
      <ListGroup.Item key={req.id}>
        <div className='d-flex'>
          <div className='flex-fill d-flex'>
            <NameLink {...req.profile} nameStyle={{ fontSize: '1.2rem', fontWeight: '500'}} />
          </div>
          <div className='align-self-end font-italic text-muted'>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {moment(req.updated_at).format('MMMM D, YYYY hh:mm A')}
                </Tooltip>
              }
              trigger={['hover', 'focus']}>
              <FontAwesomeIcon icon='clock' />
            </OverlayTrigger> {moment(req.updated_at).fromNow()}
          </div>
        </div>
        <div className='dropdown-divider'></div>
        <Row>
          <Col md={6}>
            <div>Front</div>
            <div style={{ height: '10rem', textAlign: 'center' }}>
              {
                req.data.frontUrl ? (
                  <a href={req.data.frontUrl}>
                    <Image src={req.data.frontUrl} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                  </a>
                ) : (
                  <div style={{ lineHeight: '10rem' }} className='px-1 border rounded border-danger'>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          No image found
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
                    </OverlayTrigger>
                  </div>
                )
              }
            </div>
          </Col>
          <Col md={6}>
            <div>Back</div>
            <div style={{ height: '10rem', textAlign: 'center' }}>
              {
                req.data.backUrl ? (
                  <a href={req.data.backUrl}>
                    <Image src={req.data.backUrl} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                  </a>
                ) : (
                  <div style={{ lineHeight: '10rem' }} className='px-1 border rounded border-danger'>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          No image found
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
                    </OverlayTrigger>
                  </div>
                )
              }
            </div>
          </Col>
        </Row>
        <div className='dropdown-divider'></div>
        <>
          <div className='text-right d-none d-md-block'>
            <Button variant='danger' size='sm' onClick={e => this.showDeclineModal(req)} disabled={isNextPageLoading}>
              <FontAwesomeIcon icon='times' className='fa-fw' /> Reject
            </Button>
            <Button variant='info' className='ml-2' size='sm' onClick={e => this.showGenerateModal(req)} disabled={isNextPageLoading}>
              <FontAwesomeIcon icon='redo' className='fa-fw' /> Regenerate
            </Button>
            <Button variant='green' className='ml-2' size='sm' onClick={e => this.showApproveModal(req)} disabled={isNextPageLoading}>
              <FontAwesomeIcon icon='check' className='fa-fw' /> Approve
            </Button>
          </div>
          <div className='d-flex d-md-none'>
            <div className='flex-fill'>
              <Button variant='danger' block onClick={e => this.showDeclineModal(req)} disabled={isNextPageLoading}>
                <FontAwesomeIcon icon='times' className='fa-fw' />
              </Button>
            </div>
            <div className='flex-fill mx-2'>
              <Button variant='info' block onClick={e => this.showGenerateModal(req)} disabled={isNextPageLoading}>
                <FontAwesomeIcon icon='redo' className='fa-fw' />
              </Button>
            </div>
            <div className='flex-fill'>
              <Button variant='green' block onClick={e => this.showApproveModal(req)} disabled={isNextPageLoading}>
                <FontAwesomeIcon icon='check' className='fa-fw' />
              </Button>
            </div>
          </div>
        </>
      </ListGroup.Item>
    );
  }
  renderRequest = req => {
    const { isNextPageLoading } = this.state;

    let frontTemplate = '';
    let backTemplate = '';

    if (req.profile.student_class) {
      frontTemplate = req.profile.student_class?.school_class?.year_level?.school_level?.front_id_template?.url;
      backTemplate = req.profile.student_class?.school_class?.year_level?.school_level?.back_id_template?.url;

      if (req.profile.student_class?.school_class?.program?.front_id_template?.url) {
        frontTemplate = req.profile.student_class?.school_class?.program?.front_id_template?.url;
      }

      if (req.profile.student_class?.school_class?.program?.back_id_template?.url) {
        backTemplate = req.profile.student_class?.school_class?.program?.back_id_template?.url;
      }
    } else if (req.profile.program && req.profile.year_level) {
      frontTemplate = req.profile.year_level?.school_level?.front_id_template?.url;
      backTemplate = req.profile.year_level?.school_level?.back_id_template?.url;

      if (req.profile.program?.front_id_template?.url) {
        frontTemplate = req.profile.program?.front_id_template?.url;
      }

      if (req.profile.program?.back_id_template?.url) {
        backTemplate = req.profile.program?.back_id_template?.url;
      }
    }

    return (
      <ListGroup.Item key={req.id}>
        <div className='d-flex'>
          <div className='flex-fill d-flex'>
            <NameLink {...req.profile} nameStyle={{ fontSize: '1.2rem', fontWeight: '500'}} />
          </div>
          <div className='align-self-end font-italic text-muted'>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {moment(req.created_at).format('MMMM D, YYYY hh:mm A')}
                </Tooltip>
              }
              trigger={['hover', 'focus']}>
              <FontAwesomeIcon icon='clock' />
            </OverlayTrigger> {moment(req.created_at).fromNow()}
          </div>
        </div>
        <div className='dropdown-divider'></div>
        <Row>
          <Col lg={6}>
            {
              requiredFields.map((field, i) => (
                <div key={i} className={`d-flex ${req.profile[field.key] ? 'text-green' : 'text-danger'}`}>
                  <div>
                    {
                      req.profile[field.key] ? (
                        <FontAwesomeIcon icon='check' className='fa-fw' />
                      ) : (
                        <FontAwesomeIcon icon='times' className='fa-fw' />
                      )
                    }
                  </div>
                  <div className='flex-fill ml-2'>
                    {field.label}
                  </div>
                </div>
              ))
            }
            <div className='d-flex text-muted'>
              <div>
                {
                  req.profile?.lrn ? (
                    <FontAwesomeIcon icon='check' className='fa-fw' />
                  ) : (
                    <FontAwesomeIcon icon='times' className='fa-fw' />
                  )
                }
              </div>
              <div className='flex-fill ml-2'>
                LRN
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <Row>
              <Col md={6}>
                <div>Front</div>
                <div style={{ height: '5rem', textAlign: 'center' }}>
                  {
                    frontTemplate ? (
                      <Image src={frontTemplate} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                    ) : (
                      <div style={{ lineHeight: '5rem' }} className='px-1 border rounded border-danger'>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              No template found
                            </Tooltip>
                          }
                          trigger={['hover', 'focus']}>
                          <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
                        </OverlayTrigger>
                      </div>
                    )
                  }
                </div>
              </Col>
              <Col md={6}>
                <div>Back</div>
                <div style={{ height: '5rem', textAlign: 'center' }}>
                  {
                    backTemplate ? (
                      <Image src={backTemplate} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                    ) : (
                      <div style={{ lineHeight: '5rem' }} className='px-1 border rounded border-danger'>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              No template found
                            </Tooltip>
                          }
                          trigger={['hover', 'focus']}>
                          <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
                        </OverlayTrigger>
                      </div>
                    )
                  }
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='dropdown-divider'></div>
        <>
          <div className='text-right d-none d-md-block'>
            <Button variant='danger' size='sm' onClick={e => this.showDeclineModal(req)} disabled={isNextPageLoading}>
              <FontAwesomeIcon icon='times' className='fa-fw' /> Decline
            </Button>
            <Button variant='green' className='ml-2' size='sm' onClick={e => this.showGenerateModal(req)} disabled={isNextPageLoading}>
              <FontAwesomeIcon icon='check' className='fa-fw' /> Generate
            </Button>
          </div>
          <div className='d-flex d-md-none'>
            <div className='flex-fill'>
              <Button variant='danger' block onClick={e => this.showDeclineModal(req)} disabled={isNextPageLoading}>
                <FontAwesomeIcon icon='times' className='fa-fw' />
              </Button>
            </div>
            <div className='flex-fill'>
              <Button variant='green' className='ml-2' block onClick={e => this.showGenerateModal(req)} disabled={isNextPageLoading}>
                <FontAwesomeIcon icon='check' className='fa-fw' />
              </Button>
            </div>
          </div>
        </>
      </ListGroup.Item>
    );
  }
  renderContent = () => {
    const { isLoading, errorMessage, activeKey, isNextPageLoading } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    let list = this.state[activeKey];

    return (
      <>
        <div className='text-right mb-3'>
          <Button variant='info' onClick={this.fetchList} size='sm' disabled={isNextPageLoading}>
            <FontAwesomeIcon icon='redo' /> Refresh
          </Button>
        </div>
        {
          list.data.length === 0 ? (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          ) : (
            <ListGroup>
              {
                list.data.map(req => {
                  if (activeKey === 'requests') {
                    return this.renderRequest(req);
                  } else if (activeKey === 'generated') {
                    return this.renderGenerated(req);
                  } else if (activeKey === 'declined') {
                    return this.renderDeclined(req);
                  } else if (activeKey === 'approved') {
                    return this.renderApproved(req);
                  }
                  return null;
                })
              }
            </ListGroup>
          )
        }
      </>
    );  
  }
  render() {
    const { activeKey, isLoading, isNextPageLoading, generateModal, svgBox, declineModal, approveModal } = this.state;
    return (
      <Card>
        <Card.Header>
          <Nav variant='tabs' className='flex-sm-fill' activeKey={activeKey} onSelect={this.handleNavSelect}>
            <Nav.Item>
              <Nav.Link eventKey='requests' disabled={isNextPageLoading || isLoading}>
                Requests
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='generated' disabled={isNextPageLoading || isLoading}>
                Generated
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='approved' disabled={isNextPageLoading || isLoading}>
                Approved
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='declined' disabled={isNextPageLoading || isLoading}>
                Declined
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body>
          {this.renderContent()}
          <Modal show={generateModal.show} onHide={this.hideGenerateModal}>
            <Modal.Header closeButton>
              <Modal.Title>Generate ID</Modal.Title>
            </Modal.Header>
            <Form onSubmit={this.handleGenerate}>
              <Modal.Body>
                {
                  generateModal.errorMessage && (
                    <Alert variant='danger'>
                      {generateModal.errorMessage}
                    </Alert>
                  )
                }
                {
                  generateModal?.data?.profile?.signature && (
                    <Form.Group>
                      <Form.Label>Signature Baseline</Form.Label>
                      <Row>
                        <Col>
                          <div>
                            <Form.Control
                              className='vertical-range'
                              onChange={this.handleBaselineChange}
                              value={generateModal.signatureBaseline}
                              type='range'
                              min='0'
                              max='100'
                              step='1'
                              orient='vertical' />
                          </div>
                          <div className='text-center'>
                            {`${generateModal.signatureBaseline}%`}
                          </div>
                        </Col>
                        <Col xs={8}>
                          <div className='border rounded position-relative px-1' ref={ref => {
                            if (ref) {
                              this.baselineContainerRef = ref;
                            }
                          }}>
                            <Image src={generateModal.data.profile.signature} style={{ objectFit: 'cover', margin: 0 }} fluid onLoad={this.updateSvg} />
                            <svg {...svgBox} style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                              <line x1={0} y1={((100-generateModal.signatureBaseline)/100)*svgBox.height} x2={svgBox.width} y2={((100-generateModal.signatureBaseline)/100)*svgBox.height} style={{stroke: '#131b7f', strokeWidth: 2 }} />
                            </svg>
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                  )
                }
                <Form.Group>
                  <Form.Label>Academic Year</Form.Label>
                  <Form.Control value={generateModal.academicYear} onChange={this.handleAcademicYearChange} placeholder='A.Y 2022 - 2023' />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='danger' onClick={this.hideGenerateModal}>
                  Cancel
                </Button>
                <Button type='submit' variant='green' disabled={generateModal.isLoading || generateModal.disabled}>
                  Generate
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal show={declineModal.show} onHide={this.hideDeclineModal}>
            <Modal.Header closeButton>
              <Modal.Title>Decline Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                declineModal.errorMessage ? (
                  <Alert variant='danger'>
                    {declineModal.errorMessage}
                  </Alert>
                ) : (
                  <Alert variant='warning'>
                    Are you sure you want to <b>decline the request</b>?
                  </Alert>
                )
              }
              {
                declineModal.data && (
                  <>
                    <div>
                      <NameLink {...declineModal.data.profile} nameStyle={{ fontWeight: 'bold' }} disableLink />
                    </div>
                    {
                      declineModal.data.type === 'Identification Card' && (
                        <div className='mb-3 ml-lg-3 ml-1'>
                          {
                            requiredFields.map((field, i) => (
                              <div key={i} className={`d-flex ${declineModal.data.profile[field.key] ? 'text-green' : 'text-danger'}`}>
                                <div>
                                  {
                                    declineModal.data.profile[field.key] ? (
                                      <FontAwesomeIcon icon='check' className='fa-fw' />
                                    ) : (
                                      <FontAwesomeIcon icon='times' className='fa-fw' />
                                    )
                                  }
                                </div>
                                <div className='flex-fill ml-2'>
                                  {field.label}
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }
                    <div className='dropdown-divider'></div>
                  </>
                )
              }
              <Form.Group>
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as='textarea'
                  value={declineModal.comment}
                  rows={4}
                  disabled={declineModal.isLoading}
                  onChange={this.handleCommentInputChange} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='light' onClick={this.hideDeclineModal}>
                Close
              </Button>
              <Button variant='danger' disabled={declineModal.isLoading} onClick={this.handleDecline}>
                Decline
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={approveModal.show} onHide={this.hideApproveModal}>
            <Modal.Header closeButton>
              <Modal.Title>Approve Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                approveModal.errorMessage ? (
                  <Alert variant='danger'>
                    {approveModal.errorMessage}
                  </Alert>
                ) : (
                  <Alert variant='warning'>
                    A copy of the ID will be <b>available</b> to the student.
                    Are you sure you want to <b>approve the request</b>?
                  </Alert>
                )
              }
              {
                approveModal.data && (
                  <>
                    <div className='mb-3'>
                      <NameLink {...approveModal.data.profile} nameStyle={{ fontWeight: 'bold' }} disableLink />
                    </div>
                    {
                      approveModal.data.type === 'Identification Card' && (
                        <Row>
                          <Col md={6}>
                            <div>Front</div>
                            <div style={{ height: '10rem', textAlign: 'center' }}>
                              {
                                approveModal?.data?.data?.frontUrl ? (
                                  <a href={approveModal.data.data.frontUrl}>
                                    <Image src={approveModal.data.data.frontUrl} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                                  </a>
                                ) : (
                                  <div style={{ lineHeight: '10rem' }} className='px-1 border rounded border-danger'>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip>
                                          No image found
                                        </Tooltip>
                                      }
                                      trigger={['hover', 'focus']}>
                                      <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
                                    </OverlayTrigger>
                                  </div>
                                )
                              }
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>Back</div>
                            <div style={{ height: '10rem', textAlign: 'center' }}>
                              {
                                approveModal?.data?.data?.backUrl ? (
                                  <a href={approveModal.data.data.backUrl}>
                                    <Image src={approveModal.data.data.backUrl} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                                  </a>
                                ) : (
                                  <div style={{ lineHeight: '10rem' }} className='px-1 border rounded border-danger'>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip>
                                          No image found
                                        </Tooltip>
                                      }
                                      trigger={['hover', 'focus']}>
                                      <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
                                    </OverlayTrigger>
                                  </div>
                                )
                              }
                            </div>
                          </Col>
                        </Row>
                      )
                    }
                  </>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant='light' onClick={this.hideApproveModal}>
                Close
              </Button>
              <Button variant='green' disabled={approveModal.isLoading} onClick={this.handleApprove}>
                Approve
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      </Card>
    );
  }
}