import React, { Component, useState, useEffect } from 'react';
import { Alert, Button, Col, Collapse, Form, Image, InputGroup, Modal, ProgressBar, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import PromptDeleteModal from '../../../../modals/PromptDeleteModal/PromptDeleteModal';
import Validator from 'validatorjs';
import NameLink from '../../../../common/NameLink/NameLink';
import moment from 'moment';
import axios from 'axios';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../../common/RichTextEditor/RichTextEditor';

const CorrectAnswers = props => {
  const [show, setShow] = useState(props.show ? props.show : false);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  if (props.answers && !props.answers.find(a => !a.is_correct)) {
    return null;
  }

  switch (props.type) {
    case 'enumeration':
      return (
        <div>
          <div>
            <Button variant='warning' onClick={e => setShow(!show)} size='sm'>
              {
                show ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Hide correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-up' className='ml-1' />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Show correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-down' className='ml-1' />
                  </>
                )
              }
            </Button>
          </div>
          <Collapse in={show}>
            <div>
              {
                props.question.items.map((item, key) => (
                  <div key={key} className='d-flex text-muted'>
                    <div className='p-1 font-weight-bold'>
                      {`${key+1}.`}
                    </div>
                    {
                      props.answers && (
                        <div className='py-1'>
                          {
                            props.answers[key].is_correct ? (
                              <FontAwesomeIcon icon='check' className='fa-fw' />
                            ) : (
                              <FontAwesomeIcon icon='times' className='fa-fw' />
                            )
                          }
                        </div>
                      )
                    }
                    <div className='flex-fill p-1'>
                      {item.description}
                    </div>
                  </div>
                ))
              }
            </div>
          </Collapse>
        </div>
      );
    case 'matching':
      return (
        <div>
          <div>
            <Button variant='warning' onClick={e => setShow(!show)} size='sm'>
              {
                show ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Hide correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-up' className='ml-1' />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Show correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-down' className='ml-1' />
                  </>
                )
              }
            </Button>
          </div>
          <Collapse in={show}>
            <div>
              {
                props.question.items.map((item, key) => (
                  <div key={key} className='d-flex text-muted'>
                    <div className='p-1 font-weight-bold'>
                      {`${key+1}.`}
                    </div>
                    {
                      props.answers && (
                        <div className='py-1'>
                          {
                            props.answers[key].is_correct ? (
                              <FontAwesomeIcon icon='check' className='fa-fw' />
                            ) : (
                              <FontAwesomeIcon icon='times' className='fa-fw' />
                            )
                          }
                        </div>
                      )
                    }
                    <div className='flex-fill p-1'>
                      <div>
                        {item.description}
                      </div>
                      <div className='d-flex pl-1'>
                        <div className='mr-1'>
                          <FontAwesomeIcon icon='long-arrow-alt-right' />
                        </div>
                        <div className='flex-fill'>
                          {item.answer}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </Collapse>
        </div>
      );
    case 'identification':
      return (
        <div>
          <div>
            <Button variant='warning' onClick={e => setShow(!show)} size='sm'>
              {
                show ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Hide correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-up' className='ml-1' />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Show correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-down' className='ml-1' />
                  </>
                )
              }
            </Button>
          </div>
          <Collapse in={show}>
            <div>
              {
                props.question.items.map((item, key) => (
                  <div key={key} className='d-flex text-muted'>
                    <div className='p-1 font-weight-bold'>
                      {`${key+1}.`}
                    </div>
                    {
                      props.answers && (
                        <div className='py-1'>
                          {
                            props.answers[key].is_correct ? (
                              <FontAwesomeIcon icon='check' className='fa-fw' />
                            ) : (
                              <FontAwesomeIcon icon='times' className='fa-fw' />
                            )
                          }
                        </div>
                      )
                    }
                    <div className='flex-fill'>
                      <div className='p-1'>
                        {item.answer}
                      </div>
                      <div className='p-1 font-italic small'>
                        {item.description}
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </Collapse>
        </div>
      );
    case 'multipleChoice':
      return (
        <div>
          <div>
            <Button variant='warning' onClick={e => setShow(!show)} size='sm'>
              {
                show ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Hide correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-up' className='ml-1' />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Show correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-down' className='ml-1' />
                  </>
                )
              }
            </Button>
          </div>
          <Collapse in={show}>
            <div>
              {
                props.question.choices.map((item, key) => (
                  <div key={key} className='d-flex text-muted'>
                    <div className='py-1 mr-1 fa-fw'>
                      {
                        item.is_correct && (
                          <FontAwesomeIcon icon='check' />
                        )
                      }
                    </div>
                    <div className='flex-fill p-1'>
                      {item.description}
                    </div>
                  </div>
                ))
              }
            </div>
          </Collapse>
        </div>
      );
    case 'ordering':
      return (
        <div>
          <div>
            <Button variant='warning' onClick={e => setShow(!show)} size='sm'>
              {
                show ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Hide correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-up' className='ml-1' />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Show correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-down' className='ml-1' />
                  </>
                )
              }
            </Button>
          </div>
          <Collapse in={show}>
            <div>
              {
                props.question.items.map((item, key) => (
                  <div key={key} className='d-flex text-muted'>
                    <div className='p-1 font-weight-bold'>
                      {`${key+1}.`}
                    </div>
                    {
                      props.answers && (
                        <div className='py-1'>
                          {
                            props.answers[key].is_correct ? (
                              <FontAwesomeIcon icon='check' className='fa-fw' />
                            ) : (
                              <FontAwesomeIcon icon='times' className='fa-fw' />
                            )
                          }
                        </div>
                      )
                    }
                    <div className='flex-fill p-1'>
                      {item.description}
                    </div>
                  </div>
                ))
              }
            </div>
          </Collapse>
        </div>
      );
    case 'trueFalse':
      return (
        <div>
          <div>
            <Button variant='warning' onClick={e => setShow(!show)} size='sm'>
              {
                show ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Hide correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-up' className='ml-1' />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Show correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-down' className='ml-1' />
                  </>
                )
              }
            </Button>
          </div>
          <Collapse in={show}>
            <div>
              <div className='d-flex text-muted'>
                <div className='py-1 fa-fw mr-1'>
                  {
                    props.question.correct_answer && (
                      <FontAwesomeIcon icon='check' />
                    )
                  }
                </div>
                <div className='flex-fill p-1'>
                  True
                </div>
              </div>
              <div className='d-flex text-muted'>
                <div className='py-1 fa-fw mr-1'>
                  {
                    !props.question.correct_answer && (
                      <FontAwesomeIcon icon='check' />
                    )
                  }
                </div>
                <div className='flex-fill p-1'>
                  False
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      );
    default:
      return null;
  }
};

class Submission extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      attempts: [],
      comments: [],
      selectedAttempt: 0,
      gradeModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        grade: ''
      },
      overridePointsModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        index: 0,
        points: 0,
        maxPoints: 0,
        overridePoints: 0,
        attemptData: null,
        answerData: null
      },
      deleteOverridePointsModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        index: 0,
        maxPoints: 0,
        overridePoints: 0,
        attemptData: null,
        answerData: null
      },
      comment: {
        messages: [],
        input: '',
        isLoading: false,
        errorMessage: ''
      },
      editModal: {
        show: false,
        data: null,
        input: '',
        isLoading: false,
        errorMessage: '',
        fileNames: [],
        loadingFiles: false,
        showAttachments: false,
        deleteFiles: [],
        loadingDeleteFiles: []
      },
      deleteModal: {
        show: false,
        data: null,
        isLoading: false,
        errorMessage: ''
      },
      deleteAllModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      },
      fileNames: [],
      loadingFiles: false,
      viewAllAnswers: false
    };
    
    this.files = [];
    this.editFiles = [];
  }
  componentDidMount() {
    const { materialData, submission, match: { params: { classID } }, questions } = this.props;

    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: '',
      selectedAttempt: submission.id
    }, () => {
      axiosRequest('get', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/submission/student/${submission.student_id}`, null, ({ data: { data }}) => {
        let latestUpdate = null;
        let pointsOverridden = false;
        let answerPointsOverride = 0;
        let messages = [];

        if (data.comments.length > 0) {
          messages = data.comments.map(c => ({
            data: c,
            attempt: '',
            message: c.description,
            type: c.from === 'faculty' ? 'right' : 'left',
            created_at: c.created_at
          }));
        }

        for (let i = 0; i < data.submissions.length; i++) {
          latestUpdate = data.submissions[i].updated_at;
          pointsOverridden = false;
          answerPointsOverride = 0;
          for (let j = 0; j < data.submissions[i].question_answers.length; j++) {
            latestUpdate = data.submissions[i].question_answers[j].updated_at;
            if (data.submissions[i].question_answers[j].points_override) {
              answerPointsOverride += data.submissions[i].question_answers[j].points_override.points;
              pointsOverridden = true;
            } else {
              answerPointsOverride += data.submissions[i].question_answers[j].points;
            }

            if (data.submissions[i].question_answers[j].points_override) {
              let questionIndex = questions.findIndex(q => data.submissions[i].question_answers[j].question_id === q.id);
              messages.push({
                data: null,
                attempt: 'Attempt ' + (data.submissions.length-i) + ': Question ' + (questionIndex+1),
                message: 'Override points',
                type: 'right',
                created_at: data.submissions[i].question_answers[j].points_override.created_at
              });

              if (data.submissions[i].question_answers[j].points_override.created_at !== data.submissions[i].question_answers[j].points_override.updated_at) {
                messages.push({
                  data: null,
                  attempt: 'Attempt ' + (data.submissions.length-i) + ': Question ' + (questionIndex+1),
                  message: '(Update) Override points',
                  type: 'right',
                  created_at: data.submissions[i].question_answers[j].points_override.updated_at
                });
              }
            }
          }
          data.submissions[i].latest_update = latestUpdate;
          data.submissions[i].points_overridden = pointsOverridden;
          data.submissions[i].points_with_override = answerPointsOverride;

          messages.push({
            data: null,
            attempt: 'Attempt ' + (data.submissions.length-i),
            message: 'Started an attempt',
            type: 'left',
            created_at: data.submissions[i].created_at
          });
          if (data.submissions[i].finish) {
            messages.push({
              data: null,
              attempt: 'Attempt ' + (data.submissions.length-i),
              message: 'Finished attempt',
              type: 'left',
              created_at: data.submissions[i].updated_at
            });
          } else {
            messages.push({
              data: null,
              attempt: 'Attempt ' + (data.submissions.length-i),
              message: 'Last answer',
              type: 'left',
              created_at: data.submissions[i].latest_update
            });
          }
        }
        let gradeData = materialData.grades.find(grade => grade.student_id === submission.student_id);
        if (gradeData && gradeData.created_at !== gradeData.updated_at && data.submissions[0].points_with_override !== gradeData.grade) {
          messages.push({
            data: null,
            attempt: '',
            message: 'Override grade',
            type: 'right',
            created_at: gradeData.updated_at
          });
        }
        messages.sort((a, b) => { // SORT DESCENDING
          let momentA = moment(a.created_at);
          let momentB = moment(b.created_at);

          if (momentA.isAfter(momentB)) {
            return -1;
          } else if (momentA.isBefore(momentB)) {
            return 1;
          }

          return 0;
        });
        this.setState({
          ...this.state,
          isLoading: false,
          attempts: data.submissions,
          comments: data.comments,
          comment: {
            ...this.state.comment,
            messages
          }
        });
      }, (error) => {
        console.log(error)
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  refreshMessages = () => {
    const { attempts, comments } = this.state;
    const { questions, materialData, submission } = this.props;

    let messages = [];

    if (comments.length > 0) {
      messages = comments.map(c => ({
        data: c,
        attempt: '',
        message: c.description,
        type: c.from === 'faculty' ? 'right' : 'left',
        created_at: c.created_at
      }));
    }

    for (let i = 0; i < attempts.length; i++) {
      for (let j = 0; j < attempts[i].question_answers.length; j++) {
        if (attempts[i].question_answers[j].points_override) {
          let questionIndex = questions.findIndex(q => attempts[i].question_answers[j].question_id === q.id);
          messages.push({
            data: null,
            attempt: 'Attempt ' + (attempts.length-i) + ': Question ' + (questionIndex+1),
            message: 'Override points',
            type: 'right',
            created_at: attempts[i].question_answers[j].points_override.created_at
          });

          if (attempts[i].question_answers[j].points_override.created_at !== attempts[i].question_answers[j].points_override.updated_at) {
            messages.push({
              data: null,
              attempt: 'Attempt ' + (attempts.length-i) + ': Question ' + (questionIndex+1),
              message: '(Update) Override points',
              type: 'right',
              created_at: attempts[i].question_answers[j].points_override.updated_at
            });
          }
        }
      }

      messages.push({
        data: null,
        attempt: 'Attempt ' + (attempts.length-i),
        message: 'Started an attempt',
        type: 'left',
        created_at: attempts[i].created_at
      });
      if (attempts[i].finish) {
        messages.push({
          data: null,
          attempt: 'Attempt ' + (attempts.length-i),
          message: 'Finished attempt',
          type: 'left',
          created_at: attempts[i].updated_at
        });
      } else {
        messages.push({
          data: null,
          attempt: 'Attempt ' + (attempts.length-i),
          message: 'Last answer',
          type: 'left',
          created_at: attempts[i].latest_update
        });
      }
    }
    let gradeData = materialData.grades.find(grade => grade.student_id === submission.student_id);
    if (gradeData && gradeData.created_at !== gradeData.updated_at && attempts[0].points_with_override !== gradeData.grade) {
      messages.push({
        data: null,
        attempt: '',
        message: 'Override grade',
        type: 'right',
        created_at: gradeData.updated_at
      });
    }

    messages.sort((a, b) => { // SORT DESCENDING
      let momentA = moment(a.created_at);
      let momentB = moment(b.created_at);

      if (momentA.isAfter(momentB)) {
        return -1;
      } else if (momentA.isBefore(momentB)) {
        return 1;
      }

      return 0;
    });

    this.setState({
      ...this.state,
      comment: {
        ...this.state.comment,
        messages
      }
    });
  }
  showOverridePointsModal = (answer, index, maxPoints, attempt) => {
    this.setState({
      ...this.state,
      overridePointsModal: {
        show: true,
        isLoading: false,
        errorMessage: '',
        index,
        points: answer.points,
        maxPoints,
        overridePoints: answer.points_override ? answer.points_override.points : answer.points,
        attemptData: attempt,
        answerData: answer
      }
    });
  }
  hideOverridePointsModal = () => {
    this.setState({
      ...this.state,
      overridePointsModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        index: 0,
        points: 0,
        maxPoints: 0,
        overridePoints: 0,
        attemptData: null,
        answerData: null
      }
    });
  }
  showDeleteOverridePointsModal = (answer, index, maxPoints, attempt) => {
    this.setState({
      ...this.state,
      deleteOverridePointsModal: {
        show: true,
        isLoading: false,
        errorMessage: '',
        index,
        maxPoints,
        overridePoints: answer.points_override.points,
        attemptData: attempt,
        answerData: answer
      }
    });
  }
  hideDeleteOverridePointsModal = () => {
    this.setState({
      ...this.state,
      deleteOverridePointsModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        index: 0,
        maxPoints: 0,
        overridePoints: 0,
        attemptData: null,
        answerData: null
      }
    });
  }
  handleSelectAttempt = event => {
    this.setState({
      ...this.state,
      selectedAttempt: event.target.value
    });
  }
  showGradeModal = () => {
    const { materialData, submission } = this.props;

    const gradeData = materialData.grades.find(grade => grade.student_id === submission.student_id);
    const grade = gradeData ? gradeData.grade : '';

    this.setState({
      ...this.state,
      gradeModal: {
        ...this.state.gradeModal,
        show: true,
        grade
      }
    });
  }
  hideGradeModal = () => {
    this.setState({
      ...this.state,
      gradeModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        grade: ''
      }
    });
  }
  handleGrade = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      gradeModal: {
        ...this.state.gradeModal,
        isLoading: true,
        errorMessage: false
      }
    }, () => {
      const { gradeModal } = this.state;
      const { materialData, materialPoints, submission, match: { params: { classID } } } = this.props;

      let validator = new Validator({
        grade: gradeModal.grade
      }, {
        grade: `required|numeric|min:0|max:${materialPoints}`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          gradeModal: {
            ...this.state.gradeModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/grade/${submission.student_id}`, {
        grade: gradeModal.grade
      }, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          gradeModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            grade: ''
          }
        }, () => this.props.onGrade(data, this.refreshMessages));
      }, (error) => {
        this.setState({
          ...this.state,
          gradeModal: {
            ...this.state.gradeModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleGradeInputChange = event => {
    this.setState({
      ...this.state,
      gradeModal: {
        ...this.state.gradeModal,
        grade: event.target.value
      }
    });
  }
  handleOverridePointsSubmit = event => {
    event.preventDefault();
    this.setState({
      ...this.state,
      overridePointsModal: {
        ...this.state.overridePointsModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { materialData, match: { params: { classID } } } = this.props;
      const { overridePointsModal: { attemptData: { student_id, id }, answerData, overridePoints, maxPoints } } = this.state;

      let validator = new Validator({
        points: overridePoints
      }, {
        points: `required|numeric|min:0|max:${maxPoints}`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          overridePointsModal: {
            ...this.state.overridePointsModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/submission/student/${student_id}/attempt/${id}/answer/${answerData.id}`, {
        points: overridePoints
      }, ({ data: { data }}) => {
        const attempts = [...this.state.attempts].map(attempt => {
          if (attempt.id === id) {
            // attempt.points_with_override = data.points_with_override;
            let answerPointsOverride = 0;
            for (let i = 0; i < attempt.question_answers.length; i++) {
              if (attempt.question_answers[i].id === answerData.id) {
                attempt.question_answers[i].files = [];
                attempt.question_answers[i].points_override = data;
                answerPointsOverride += data.points;
              } else if (attempt.question_answers[i].points_override) {
                answerPointsOverride += attempt.question_answers[i].points_override.points;
              } else {
                answerPointsOverride += attempt.question_answers[i].points;
              }
            }
            attempt.points_overridden = true;
            attempt.points_with_override = answerPointsOverride;
          }

          return attempt;
        });
        let newSubmission = attempts.find(a => a.id === id);
        this.setState({
          ...this.state,
          attempts,
          overridePointsModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            index: 0,
            points: 0,
            maxPoints: 0,
            overridePoints: 0,
            attemptData: null,
            answerData: null
          }
        }, () => {
          let gradeData = materialData.grades.find(grade => grade.student_id === student_id);
          gradeData.grade = newSubmission.points_with_override;
          this.props.onGrade(gradeData, this.refreshMessages);
        });
        
        
      }, (error) => {
        this.setState({
          ...this.state,
          overridePointsModal: {
            ...this.state.overridePointsModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleOverridePointsInputChange = event => {
    this.setState({
      ...this.state,
      overridePointsModal: {
        ...this.state.overridePointsModal,
        overridePoints: event.target.value
      }
    });
  }
  handleDeleteOverridePoints = () => {
    this.setState({
      ...this.state,
      deleteOverridePointsModal: {
        ...this.state.deleteOverridePointsModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { materialData, match: { params: { classID } } } = this.props;
      const { deleteOverridePointsModal: { attemptData: { student_id, id }, answerData } } = this.state;

      axiosRequest('delete', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/submission/student/${student_id}/attempt/${id}/answer/${answerData.id}`, null, ({ data: { data }}) => {
        const attempts = [...this.state.attempts].map(attempt => {
          if (attempt.id === id) {
            let answerPointsOverride = 0;
            let pointsOverridden = false;
            for (let i = 0; i < attempt.question_answers.length; i++) {
              if (attempt.question_answers[i].id === answerData.id) {
                attempt.question_answers[i].points_override = null;
                answerPointsOverride += attempt.question_answers[i].points;
              } else if (attempt.question_answers[i].points_override) {
                answerPointsOverride += attempt.question_answers[i].points_override.points;
                pointsOverridden = true;
              } else {
                answerPointsOverride += attempt.question_answers[i].points;
              }
            }
            attempt.points_overridden = pointsOverridden;
            attempt.points_with_override = answerPointsOverride;
          }

          return attempt;
        });
        let newSubmission = attempts.find(a => a.id === id);
        this.setState({
          ...this.state,
          attempts,
          deleteOverridePointsModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            index: 0,
            maxPoints: 0,
            overridePoints: 0,
            attemptData: null,
            answerData: null
          }
        }, () => {
          let gradeData = materialData.grades.find(grade => grade.student_id === student_id);
          gradeData.grade = newSubmission.points_with_override;
          this.props.onGrade(gradeData, this.refreshMessages);
        });
      }, (error) => {
        this.setState({
          ...this.state,
          deleteOverridePointsModal: {
            ...this.state.deleteOverridePointsModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleCommentFileDownload = (comment, fileID) => {
    const { materialData, submission, match: { params: { classID } } } = this.props;

    axiosRequest('get', `faculty/class/${classID}/material/comment/${materialData.type}/${materialData.id}/student/${submission.student_id}/${comment.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handleCommentInputChange = event => {
    this.setState({
      ...this.state,
      comment: {
        ...this.state.comment,
        input: event.target.value
      }
    });
  }
  handleComment = () => {
    this.setState({
      ...this.state,
      comment: {
        ...this.state.comment,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { comment } = this.state;
      const { materialData, submission, match: { params: { classID } } } = this.props;

      let validator = new Validator({
        comment: comment.input
      }, {
        comment: `required`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          comment: {
            ...this.state.comment,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', `faculty/class/${classID}/material/comment/${materialData.type}/${materialData.id}/student/${submission.student_id}`, {
        comment: comment.input
      }, ({ data: { data }}) => {
        if (this.files.length > 0) {
          let fileRequests = [];
          for (let i = 0; i < this.files.length; i++) {
            fileRequests.push(this.uploadFile(i, classID, materialData.type, materialData.id, submission.student_id, data.id));
          }
          Promise.all(fileRequests).then(result => {
            data.files = result;
            this.setState({
              ...this.state,
              comments: [
                ...this.state.comments,
                data
              ],
              comment: {
                ...this.state.comment,
                input: '',
                isLoading: false
              },
              fileNames: [],
              loadingFiles: false
            }, () => {
              this.files = [];
              this.refreshMessages();
            });
          }).catch((error) => {
            this.setState({
              ...this.state,
              comment: {
                ...this.state.comment,
                isLoading: false,
                errorMessage: error
              },
              loadingFiles: false
            });
          });
        } else {
          data.files = [];
          this.setState({
            ...this.state,
            comments: [
              ...this.state.comments,
              data
            ],
            comment: {
              ...this.state.comment,
              input: '',
              isLoading: false
            },
            fileNames: [],
            loadingFiles: false
          }, () => {
            this.files = [];
            this.refreshMessages();
          });
        }
      }, error => {
        this.setState({
          ...this.state,
          comment: {
            ...this.state.comment,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showEditModal = data => {
    this.setState({
      ...this.state,
      editModal: {
        show: true,
        data,
        input: data.description,
        isLoading: false,
        errorMessage: '',
        fileNames: [],
        loadingFiles: false,
        showAttachments: false,
        deleteFiles: [],
        loadingDeleteFiles: []
      }
    });
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        data: null,
        input: '',
        isLoading: false,
        errorMessage: '',
        fileNames: [],
        loadingFiles: false,
        showAttachments: false,
        deleteFiles: [],
        loadingDeleteFiles: []
      }
    });
  }
  handleEditInputChange = event => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        input: event.target.value
      }
    });
  }
  handleEditComment = () => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { editModal } = this.state;
      const { materialData, submission, match: { params: { classID } } } = this.props;

      let validator = new Validator({
        comment: editModal.input
      }, {
        comment: `required`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('patch', `faculty/class/${classID}/material/comment/${materialData.type}/${materialData.id}/student/${submission.student_id}/${editModal.data.id}`, {
        comment: editModal.input
      }, ({ data: { data }}) => {
        let comment = [...this.state.comments].find(c => c.id === data.id);
        if (comment) {
          comment = {...comment, ...data};
        } else {
          comment = {...data};
        }

        let uploadRequests = [];
        let deleteRequests = [];

        if (this.editFiles.length > 0) {
          for (let i = 0; i < this.editFiles.length; i++) {
            uploadRequests.push(this.uploadEditFile(i, classID, materialData.type, materialData.id, submission.student_id, data.id));
          }
        }

        if (editModal.deleteFiles.length > 0) {
          for (let i = 0; i < editModal.deleteFiles.length; i++) {
            deleteRequests.push(this.deleteFile(editModal.deleteFiles[i], classID, materialData.type, materialData.id, submission.student_id, data.id));
          }
        }

        if (uploadRequests.length && deleteRequests.length) {
          Promise.all([Promise.all(uploadRequests), Promise.all(deleteRequests)]).then(result => {
            comment.files = [...result[0], ...comment.files];
            comment.files = comment.files.filter(file => result[1].indexOf(file.id) === -1);
            
            let comments = [...this.state.comments].map(c => {
              if (c.id === data.id) {
                return comment;
              }
    
              return c;
            });
            this.setState({
              ...this.state,
              comments,
              editModal: {
                show: false,
                data: null,
                input: '',
                isLoading: false,
                errorMessage: '',
                fileNames: [],
                loadingFiles: false,
                showAttachments: false,
                deleteFiles: [],
                loadingDeleteFiles: []
              }
            }, () => {
              this.editFiles = [];
              this.refreshMessages();
            });

          }).catch((error) => {
            this.setState({
              ...this.state,
              editModal: {
                ...this.state.editModal,
                formError: error,
                isFormLoading: false
              }
            });
          });
        } else if (uploadRequests.length) {
          Promise.all(uploadRequests).then(result => {
            comment.files = [...result, ...comment.files];
            
            let comments = [...this.state.comments].map(c => {
              if (c.id === data.id) {
                return comment;
              }
    
              return c;
            });
            this.setState({
              ...this.state,
              comments,
              editModal: {
                show: false,
                data: null,
                input: '',
                isLoading: false,
                errorMessage: '',
                fileNames: [],
                loadingFiles: false,
                showAttachments: false,
                deleteFiles: [],
                loadingDeleteFiles: []
              }
            }, () => {
              this.editFiles = [];
              this.refreshMessages();
            });
          }).catch((error) => {
            this.setState({
              ...this.state,
              editModal: {
                ...this.state.editModal,
                formError: error,
                isFormLoading: false
              }
            });
          });
        } else if (deleteRequests.length) {
          Promise.all(deleteRequests).then(result => {
            comment.files = comment.files.filter(file => result.indexOf(file.id) === -1);
            
            let comments = [...this.state.comments].map(c => {
              if (c.id === data.id) {
                return comment;
              }
    
              return c;
            });
            this.setState({
              ...this.state,
              comments,
              editModal: {
                show: false,
                data: null,
                input: '',
                isLoading: false,
                errorMessage: '',
                fileNames: [],
                loadingFiles: false,
                showAttachments: false,
                deleteFiles: [],
                loadingDeleteFiles: []
              }
            }, () => {
              this.editFiles = [];
              this.refreshMessages();
            });
          });
        } else {
          let comments = [...this.state.comments].map(c => {
            if (c.id === data.id) {
              return comment;
            }
  
            return c;
          });
          this.setState({
            ...this.state,
            comments,
            editModal: {
              show: false,
              data: null,
              input: '',
              isLoading: false,
              errorMessage: '',
              fileNames: [],
              loadingFiles: false,
              showAttachments: false,
              deleteFiles: [],
              loadingDeleteFiles: []
            }
          }, () => {
            this.editFiles = [];
            this.refreshMessages();
          });
        }
      }, error => {
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showDeleteModal = data => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        data,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        data: null,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleDeleteComment = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { deleteModal } = this.state;
      const { materialData, submission, match: { params: { classID } } } = this.props;

      axiosRequest('delete', `faculty/class/${classID}/material/comment/${materialData.type}/${materialData.id}/student/${submission.student_id}/${deleteModal.data.id}`, null, ({ data: { data }}) => {
        let comments = [...this.state.comments].filter(c => c.id !== deleteModal.data.id);
        this.setState({
          ...this.state,
          comments,
          deleteModal: {
            show: false,
            data: null,
            isLoading: false,
            errorMessage: ''
          }
        }, this.refreshMessages);
      }, error => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.files = [...this.files, ...files];
      this.setState({
        ...this.state,
        fileNames: [
          ...this.state.fileNames,
          ...filesState
        ]
      });
    }
  }
  handleEditFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.editFiles = [...this.editFiles, ...files];
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          fileNames: [
            ...this.state.editModal.fileNames,
            ...filesState
          ]
        }
      });
    }
  }
  handleRemoveUpload = id => {
    let newFileNames = [...this.state.fileNames];
    newFileNames.splice(id, 1);

    this.setState({
      ...this.state,
      fileNames: newFileNames
    }, () => {
      this.files.splice(id, 1);
    });
  }
  handleEditRemoveUpload = id => {
    let newFileNames = [...this.state.editModal.fileNames];
    newFileNames.splice(id, 1);

    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        fileNames: newFileNames
      }
    }, () => {
      this.editFiles.splice(id, 1);
    });
  }
  uploadFile = (i, classID, materialType, materialID, studentID, commentID) => {
    const formData = new window.FormData();
    formData.append('file', this.files[i], this.files[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/comment/${materialType}/${materialID}/student/${studentID}/${commentID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { fileNames } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          fileNames
        });
      }
    }).then(({ data: { data }}) => {
      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        fileNames
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        fileNames
      });
      throw fileNames[i].isError;
    });
  }
  uploadEditFile = (i, classID, materialType, materialID, studentID, commentID) => {
    const formData = new window.FormData();
    formData.append('file', this.editFiles[i], this.editFiles[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/comment/${materialType}/${materialID}/student/${studentID}/${commentID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { editModal: { fileNames } } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            fileNames
          }
        });
      }
    }).then(({ data: { data }}) => {
      const { editModal: { fileNames } } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          fileNames
        }
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { editModal: { fileNames } } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          fileNames
        }
      });
      throw fileNames[i].isError;
    });
  }
  deleteFile = (id, classID, materialType, materialID, studentID, commentID) => {
    return axios.delete(`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/comment/${materialType}/${materialID}/student/${studentID}/${commentID}/file/${id}`, {
      withCredentials: true,
    }).then(({ data: { data }}) => {
      let newLoadingDeleteFiles = [...this.state.editModal.loadingDeleteFiles].filter(fileID => fileID !== id);
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          loadingDeleteFiles: newLoadingDeleteFiles
        }
      });
      return id;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }
    });
  }
  handleCancelDeleteFile = id => {
    let newDeleteFiles = [...this.state.editModal.deleteFiles].filter(fileID => fileID !== id);
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        deleteFiles: newDeleteFiles
      }
    });
  }
  handleDeleteFile = id => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        deleteFiles: [...this.state.editModal.deleteFiles, id]
      }
    });
  }
  handleShowAttachments = () => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        showAttachments: !this.state.editModal.showAttachments
      }
    });
  }
  showDeleteAllModal = () => {
    this.setState({
      ...this.state,
      deleteAllModal: {
        show: true,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteAllModal = () => {
    this.setState({
      ...this.state,
      deleteAllModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleDeleteAllCommentFiles = () => {
    this.setState({
      ...this.state,
      deleteAllModal: {
        ...this.state.deleteAllModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { materialData, submission, match: { params: { classID } } } = this.props;
      axiosRequest('delete', `faculty/class/${classID}/material/comment/${materialData.type}/${materialData.id}/student/${submission.student_id}`, null, ({ data: { message }}) => {
        let newComments = [...this.state.comments].map(c => {
          return {
            ...c,
            files: []
          };
        });
        this.setState({
          ...this.state,
          comments: newComments,
          deleteAllModal: {
            show: false,
            isLoading: false,
            errorMessage: ''
          }
        }, this.refreshMessages);
      }, error => {
        this.setState({
          ...this.state,
          deleteAllModal: {
            ...this.state.deleteAllModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleAnswerFileDownload = (answerID, fileID) => {
    const { materialData, submission: { student_id }, match: { params: { classID } } } = this.props;
    const { selectedAttempt } = this.state;

    axiosRequest('get', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/submission/student/${student_id}/attempt/${selectedAttempt}/answer/${answerID}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handlePreviewImages = (materialData, submission, comment, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/comment/${materialData.type}/${materialData.id}/student/${submission.student_id}/${comment.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  handlePreviewAnswerImages = (materialData, student_id, selectedAttempt, answer, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/${materialData.type}/${materialData.id}/submission/student/${student_id}/attempt/${selectedAttempt}/answer/${answer.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  renderAttachedFiles = () => {
    const { editModal: { data: comment, showAttachments, deleteFiles, loadingDeleteFiles } } = this.state;

    if (comment && comment.files && comment.files.length > 0) {
      return (
        <>
          <div className={`text-right border rounded-top ${showAttachments ? 'border-bottom-0' : 'rounded-bottom'}`}>
            <Button variant='link' onClick={this.handleShowAttachments} size='sm' block>
              <FontAwesomeIcon icon='paperclip' /> Show {comment.files.length} attachment(s)
            </Button>
          </div>
          <Collapse in={showAttachments}>
            <div className='file-upload-display small'>
            {
              comment.files.map(file => (
                <div className={`file-name d-flex ${deleteFiles.indexOf(file.id) !== -1 ? 'alert-light' : ''}`}>
                  {
                    loadingDeleteFiles.indexOf(file.id) !== -1 && (
                      <LoadingIcon />
                    )
                  }
                  <div className='ml-2 flex-fill'>
                    <div>
                      {file.file_title}
                    </div>
                    {
                      deleteFiles.indexOf(file.id) !== -1 && (
                        <div className='font-italic'>
                          To be deleted
                        </div>
                      )
                    }
                  </div>
                  <div className='ml-2 align-items-center d-flex'>
                    {
                      deleteFiles.indexOf(file.id) !== -1 ? (
                        <Button
                          variant='link'
                          className='text-green'
                          size='sm'
                          title='Cancel delete'
                          onClick={() => this.handleCancelDeleteFile(file.id)}>
                          <FontAwesomeIcon icon='ban' size='sm' />
                        </Button>
                      ) : (
                        <Button
                          variant='link'
                          className='text-danger'
                          size='sm'
                          title='Delete'
                          onClick={() => this.handleDeleteFile(file.id)}>
                          <FontAwesomeIcon icon='trash-alt' size='sm' />
                        </Button>
                      )
                    }
                  </div>
                </div>
              ))
            }
            </div>
          </Collapse>
        </>
      );
    }
  }
  renderEditFileUpload = () => {
    const { editModal: { fileNames, loadingFiles } } = this.state;
    if (fileNames.length > 0) {
      return (
        <Form.Group>
          <Form.Label>File(s) to be uploaded</Form.Label>
          <small className='file-upload-display d-block rounded-top'>
          {
            fileNames.map((fileName, index) => (
              <div key={index} className='file-name d-flex px-2 py-1 align-items-center'>
                <div>
                  {
                    fileName.isLoading || (loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                      <LoadingIcon />
                    ) : fileName.isError ? (
                      <FontAwesomeIcon icon='times-circle' className='text-danger' />
                    ) : fileName.isSuccess ? (
                      <FontAwesomeIcon icon='check-circle' className='text-green' />
                    ) : (
                      <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                    )
                  }
                </div>
                <div className='ml-2 flex-fill' style={{ minWidth: 0 }}>
                  <div className='text-truncate'>
                    {fileName.name}
                  </div>
                  {
                    fileName.isLoading && (
                      <div className='mt-1'>
                        <ProgressBar now={fileName.isLoading} />
                      </div>
                    )
                  }
                </div>
                {
                  ((!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !loadingFiles) || fileName.isError) && (
                    <div className='align-items-center d-flex'>
                      <Button
                        variant='link'
                        className='text-danger'
                        size='sm'
                        title='Remove'
                        onClick={(e) => this.handleEditRemoveUpload(index)}>
                        <FontAwesomeIcon icon='times' size='sm' />
                      </Button>
                    </div>
                  )
                }
              </div>
            ))
          }
          </small>
        </Form.Group>
      );
    }
  }
  renderFileUpload = () => {
    const { fileNames, loadingFiles } = this.state;
    if (fileNames.length > 0) {
      return (
        <small className='file-upload-display d-block rounded-top'>
        {
          fileNames.map((fileName, index) => (
            <div key={index} className='file-name d-flex px-2 py-1 align-items-center'>
              <div>
                {
                  fileName.isLoading || (loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                    <LoadingIcon />
                  ) : fileName.isError ? (
                    <FontAwesomeIcon icon='times-circle' className='text-danger' />
                  ) : fileName.isSuccess ? (
                    <FontAwesomeIcon icon='check-circle' className='text-green' />
                  ) : (
                    <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                  )
                }
              </div>
              <div className='ml-2 flex-fill' style={{ minWidth: 0 }}>
                <div className='text-truncate'>
                  {fileName.name}
                </div>
                {
                  fileName.isLoading && (
                    <div className='mt-1'>
                      <ProgressBar now={fileName.isLoading} />
                    </div>
                  )
                }
              </div>
              {
                ((!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !loadingFiles) || fileName.isError) && (
                  <div className='align-items-center d-flex'>
                    <Button
                      variant='link'
                      className='text-danger'
                      size='sm'
                      title='Remove'
                      onClick={(e) => this.handleRemoveUpload(index)}>
                      <FontAwesomeIcon icon='times' size='sm' />
                    </Button>
                  </div>
                )
              }
            </div>
          ))
        }
        </small>
      );
    }
  }
  renderFiles = comment => {
    const { materialData, match: { params: { classID } }, submission } = this.props;

    if (comment && comment.files && comment.files.length > 0) {
      const images = comment.files.filter(file => {
        return !!file.is_image;
      });
  
      const files = comment.files.filter(file => {
        return !file.is_image;
      });

      return (
        <div>
          {
            images.length > 0 && (
              <Row className='flex-row-reverse' noGutters>
              {
                images.map((image, index) => (
                  <Col key={image.id} md={6} className='question-image-container' style={{ height: '4rem' }} onClick={e => this.handlePreviewImages(materialData, submission, comment, images, index)}>
                    <Image className='submission-comment-image' src={`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/comment/${materialData.type}/${materialData.id}/student/${submission.student_id}/${comment.id}/image/${image.id}`} thumbnail />
                  </Col>
                ))
              }
              </Row>
            )
          }
          {
            files.length > 0 && (
              <div className='p-1'>
                {
                  files.map(file => (
                    <small key={file.id} className='d-flex mb-2'>
                      <div className='mr-2'>
                        <FontAwesomeIcon icon='file' className='text-primary' />
                      </div>
                      <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                        <span className='btn-link' onClick={() => this.handleCommentFileDownload(comment, file.id)}>
                          {file.file_title}
                        </span>
                      </div>
                    </small>
                  ))
                }
              </div>
            )
          }
        </div>
      );
    }

    return null;
  }
  renderAnswer = (type, question, answer) => {
    const { viewAllAnswers } = this.state;
    if (!answer) {
      return (
        <>
          <div>
            <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
          </div>
          <div>
            <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
          </div>
        </>
      );
    }

    switch(type) {
      case 'trueFalse':
        if (answer.answer_boolean) {
          return (
            <div>
              <div className='d-flex mb-1'>
                {
                  answer.answer_boolean.answer ? (
                    <>
                      <div className='mr-1 fa-fw py-1'>
                        {
                          (answer.answer_boolean.answer && question.correct_answer) ? (
                            <FontAwesomeIcon icon='check' className='text-green' />
                          ) : (
                            <FontAwesomeIcon icon='times' className='text-danger' />
                          )
                        }
                      </div>
                      <div className={`p-1 rounded border ${answer.answer_boolean.answer && question.correct_answer ? 'border-green' : 'border-danger'}`}>
                        True
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='mr-1 fa-fw py-1'>
                        {
                          question.correct_answer && (
                            <FontAwesomeIcon icon='check' className='text-green' />
                          )
                        }
                      </div>
                      <div className='p-1 rounded'>
                        True
                      </div>
                    </>
                  )
                }
              </div>
              <div className='d-flex mb-1'>
                {
                  !answer.answer_boolean.answer ? (
                    <>
                      <div className='mr-1 fa-fw py-1'>
                        {
                          (!answer.answer_boolean.answer && !question.correct_answer) ? (
                            <FontAwesomeIcon icon='check' className='text-green' />
                          ) : (
                            <FontAwesomeIcon icon='times' className='text-danger' />
                          )
                        }
                      </div>
                      <div className={`p-1 rounded border ${!answer.answer_boolean.answer && !question.correct_answer ? 'border-green' : 'border-danger'}`}>
                        False
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='mr-1 fa-fw py-1'>
                        {
                          !question.correct_answer && (
                            <FontAwesomeIcon icon='check' className='text-green' />
                          )
                        }
                      </div>
                      <div className='p-1 rounded'>
                        False
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          );
        }

        return (
          <>
            <div>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </div>
            <div>
              <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
            </div>
          </>
        );
      case 'multipleChoice':
        if (answer.answer_multiple_choice) {
          return (
            <div>
              {
                question.choices.map((choice, key) => {
                  let choiceAnswer = answer.answer_multiple_choice.find(amc => amc.question_multiple_choice_choice_id === choice.id);
                  if (choiceAnswer) {
                    return (
                      <div className='d-flex mb-1'>
                        <div className='mr-1 py-1'>
                          {
                            choice.is_correct ? (
                              <FontAwesomeIcon icon='check' className='text-green fa-fw' />
                            ) : (
                              <FontAwesomeIcon icon='times' className='text-danger fa-fw' />
                            )
                          }
                        </div>
                        <div className={`flex-fill p-1 rounded border ${choice.is_correct ? 'border-green' : 'border-danger'}`}>
                          {choice.description}
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div className='d-flex mb-1'>
                      <div className='mr-1 fa-fw'>
                        {
                          choice.is_correct && (
                            <FontAwesomeIcon icon='check' className='text-green' />
                          )
                        }
                      </div>
                      <div className='flex-fill'>
                        {choice.description}
                      </div>
                    </div>
                  );
                })
              }
            </div>
          );
        }
        
        return (
          <>
            <div>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </div>
            <div>
              <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
            </div>
          </>
        );
      case 'ordering':
        if (answer.answer_ordering) {
          let answers = [];
          for(let i = 0; i < answer.answer_ordering.length; i++) {
            let item = question.items.find(qi => qi.id === answer.answer_ordering[i].question_ordering_item_id);

            answers = [
              ...answers,
              {
                description: item.description,
                is_correct: item.order === answer.answer_ordering[i].order
              }
            ];
          }

          return (
            <>
              <div>
                {
                  answers.map((answer, key) => (
                    <div key={key} className='d-flex mb-1'>
                      <div className='font-weight-bold py-1 pl-1'>
                        {`${key+1}.`}
                      </div>
                      {
                        answer.is_correct !== null ? (
                          <>
                            <div className='mx-1 py-1'>
                              {
                                answer.is_correct ? (
                                  <FontAwesomeIcon icon='check' className='text-green fa-fw' />
                                ) : (
                                  <FontAwesomeIcon icon='times' className='text-danger fa-fw' />
                                )
                              }
                            </div>
                            <div className={`flex-fill p-1 rounded border ${answer.is_correct ? 'border-green' : 'border-danger'}`}>
                              {answer.description}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='fa-fw'>
                            </div>
                            <div className='flex-fill p-1'>
                              {answer.description}
                            </div>
                          </>
                        )
                      }
                    </div>
                  ))
                }
              </div>
              <div>
                <CorrectAnswers type={type} question={question} show={viewAllAnswers} answers={answers} />
              </div>
            </>
          )
        }
        
        return (
          <>
            <div>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </div>
            <div>
              <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
            </div>
          </>
        );
      case 'essay':
        const { materialData, submission: { student_id }, match: { params: { classID } } } = this.props;
        const { selectedAttempt } = this.state;

        let imagesMap = answer.files && answer.files.length > 0 ? answer.files.filter(f => f.is_image) : [];
        let filesMap = answer.files && answer.files.length > 0 ? answer.files.filter(f => !f.is_image) : [];

        return (
          <>
            {
              answer.files && answer.files.length > 0 && (!answer.answer_essay || !answer.answer_essay.answer) ? null : (answer.answer_essay && answer.answer_essay.answer) ? (
                <div style={{ whiteSpace: 'pre-line' }}>
                  {answer.answer_essay.answer}
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
                </div>
              )
            }
            {
              answer.files && answer.files.length > 0 && (
                <>
                  {
                    imagesMap.length > 0 && (
                      <Row noGutters>
                      {
                        imagesMap.map((image, index) => {
                          return (
                            <Col key={index} md={6} className='question-image-container' onClick={e => this.handlePreviewAnswerImages(materialData, student_id, selectedAttempt, answer, imagesMap, index)}>
                              <Image src={`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/${materialData.type}/${materialData.id}/submission/student/${student_id}/attempt/${selectedAttempt}/answer/${answer.id}/image/${image.id}`} thumbnail />
                            </Col>
                          );
                        })
                      }
                      </Row>
                    )
                  }
                  {
                    filesMap.length > 0 && (
                      <div className='px-2'>
                        {
                          filesMap.map((file, index) => (
                            <div key={index} className='d-flex mb-2'>
                              <div className='mr-2'>
                                <FontAwesomeIcon icon='file' className='text-primary' />
                              </div>
                              <div style={{ wordBreak: 'break-all' }}>
                                <span className='btn-link' onClick={e => this.handleAnswerFileDownload(answer.id, file.id)}>
                                  {file.file_title}
                                </span>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    )
                  }
                </>
              )
            }
          </>
        );
      case 'matching':
        if (answer.answer_matching) {
          let answers = [];
          for (let i = 0; i < question.items.length; i++) {
            let itemAnswer = answer.answer_matching.find(am => am.question_matching_item_id === question.items[i].id);

            if (!itemAnswer || !itemAnswer.answer) {
              answers = [
                ...answers,
                {
                  question: question.items[i].description,
                  answer: '',
                  is_correct: false,
                }
              ];
              continue;
            }

            answers = [
              ...answers,
              {
                question: question.items[i].description,
                answer: itemAnswer.answer,
                is_correct: question.items[i].answer === itemAnswer.answer
              }
            ];
          }

          return (
            <>
              <div>
                {
                  answers.map((answer, key) => (
                    <div key={key} className='d-flex mb-1'>
                      <div className='font-weight-bold py-1 pl-1'>
                        {`${key+1}.`}
                      </div>
                      <div className='mx-1 py-1'>
                        {
                          answer.is_correct ? (
                            <FontAwesomeIcon icon='check' className='text-green fa-fw' />
                          ) : (
                            <FontAwesomeIcon icon='times' className='text-danger fa-fw' />
                          )
                        }
                      </div>
                      <div className='flex-fill'>
                        {
                          answer.answer ? (
                            <div className={`p-1 rounded border ${answer.is_correct ? 'border-green' : 'border-danger'}`}>
                              <div>
                                {answer.question}
                              </div>
                              <div className='d-flex pl-1'>
                                <div className='mr-1'>
                                  <FontAwesomeIcon icon='long-arrow-alt-right' className='text-info' />
                                </div>
                                <div className='flex-fill'>
                                  {answer.answer}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='font-italic text-muted p-1 rounded border border-danger'>
                              (Blank)
                            </div>
                          )
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
              <div>
                <CorrectAnswers type={type} question={question} show={viewAllAnswers} answers={answers} />
              </div>
            </>
          );
        }

        return (
          <>
            <div>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </div>
            <div>
              <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
            </div>
          </>
        );
      case 'identification':
        if (answer.answer_identification) {
          let answers = [];
          for (let i = 0; i < question.items.length; i++) {
            let itemAnswer = answer.answer_identification.find(am => am.question_identification_item_id === question.items[i].id);

            if (!itemAnswer || !itemAnswer.answer) {
              answers = [
                ...answers,
                {
                  question: question.items[i].description,
                  answer: !itemAnswer ? '' : itemAnswer.answer,
                  is_correct: false,
                }
              ];
              continue;
            }

            if (question.is_case_sensitive) {
              answers = [
                ...answers,
                {
                  question: question.items[i].description,
                  answer: itemAnswer.answer,
                  is_correct: question.items[i].answer === itemAnswer.answer
                }
              ];
            } else {
              answers = [
                ...answers,
                {
                  question: question.items[i].description,
                  answer: itemAnswer.answer,
                  is_correct: question.items[i].answer.toUpperCase() === itemAnswer.answer.toUpperCase()
                }
              ];
            }
          }

          return (
            <>
              <div>
                {
                  answers.map((answer, key) => (
                    <div key={key} className='d-flex mb-1'>
                      <div className='font-weight-bold py-1 pl-1'>
                        {`${key+1}.`}
                      </div>
                      <div className='mx-1 py-1'>
                        {
                          answer.is_correct ? (
                            <FontAwesomeIcon icon='check' className='text-green fa-fw' />
                          ) : (
                            <FontAwesomeIcon icon='times' className='text-danger fa-fw' />
                          )
                        }
                      </div>
                      <div className='flex-fill'>
                        {
                          answer.answer ? (
                            <div className={`p-1 rounded border ${answer.is_correct ? 'border-green' : 'border-danger'}`}>
                              {answer.answer}
                            </div>
                          ) : (
                            <div className={`font-italic text-muted p-1 rounded border ${answer.is_correct ? 'border-green' : 'border-danger'}`}>
                              (Blank)
                            </div>
                          )
                        }
                        <div className='font-italic text-muted p-1 small'>
                          {answer.question}
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div>
                <CorrectAnswers type={type} question={question} show={viewAllAnswers} answers={answers} />
              </div>
            </>
          );
        }

        return (
          <>
            <div>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </div>
            <div>
              <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
            </div>
          </>
        );
      case 'enumeration':
        if (answer.answer_enumeration && answer.answer_enumeration.find(a => a.answer !== '')) {
          let caseSensitiveAnswers = [...question.items.map(item => item.description)];
          let caseInsensitiveAnswers = [...caseSensitiveAnswers].map(a => a.toUpperCase());

          let answers = [];
          for (let i = 0; i < answer.answer_enumeration.length; i++) {
            if (question.is_case_sensitive) {
              let searchIndex = caseSensitiveAnswers.indexOf(answer.answer_enumeration[i].answer);
              if (searchIndex !== -1) {
                caseSensitiveAnswers.splice(searchIndex, 1);
                if (question.is_in_order) {
                  answers = [
                    ...answers,
                    {
                      answer: answer.answer_enumeration[i].answer,
                      is_correct: question.items[i].description === answer.answer_enumeration[i].answer
                    }
                  ];
                } else {
                  answers = [
                    ...answers,
                    {
                      answer: answer.answer_enumeration[i].answer,
                      is_correct: true
                    }
                  ];
                }
              } else {
                answers = [
                  ...answers,
                  {
                    answer: answer.answer_enumeration[i].answer,
                    is_correct: false
                  }
                ];
              }
            } else {
              let searchIndex = caseInsensitiveAnswers.indexOf(answer.answer_enumeration[i].answer.toUpperCase());
              if (searchIndex !== -1) {
                caseInsensitiveAnswers.splice(searchIndex, 1);
                if (question.is_in_order) {
                  answers = [
                    ...answers,
                    {
                      answer: answer.answer_enumeration[i].answer,
                      is_correct: question.items[i].description.toUpperCase() === answer.answer_enumeration[i].answer.toUpperCase()
                    }
                  ];
                } else {
                  answers = [
                    ...answers,
                    {
                      answer: answer.answer_enumeration[i].answer,
                      is_correct: true
                    }
                  ];
                }
              } else {
                answers = [
                  ...answers,
                  {
                    answer: answer.answer_enumeration[i].answer,
                    is_correct: false
                  }
                ];
              }
            }
          }
          return (
            <>
              <div>
                {
                  answers.map((answer, key) => (
                    <div key={key} className='d-flex mb-1'>
                      <div className='font-weight-bold py-1 pl-1'>
                        {`${key+1}.`}
                      </div>
                      <div className='mx-1 py-1'>
                        {
                          answer.is_correct ? (
                            <FontAwesomeIcon icon='check' className='text-green fa-fw' />
                          ) : (
                            <FontAwesomeIcon icon='times' className='text-danger fa-fw' />
                          )
                        }
                      </div>
                      <div className='flex-fill'>
                        {
                          answer.answer ? (
                            <div className={`p-1 rounded border ${answer.is_correct ? 'border-green' : 'border-danger'}`}>
                              {answer.answer}
                            </div>
                          ) : (
                            <div className={`font-italic text-muted p-1 rounded border ${answer.is_correct ? 'border-green' : 'border-danger'}`}>
                              (Blank)
                            </div>
                          )
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
              <div>
                <CorrectAnswers type={type} question={question} show={viewAllAnswers} answers={answers} />
              </div>
            </>
          );
        }

        return (
          <>
            <div>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </div>
            <div>
              <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
            </div>
          </>
        );
      default:
        return null;
    }
  }
  renderQuestion = (id, type, points, question, attempt) => {
    let answer = attempt.question_answers.find(qa => qa.question_id === id);

    return (
      <>
        {
          answer && answer.points_override !== null ? (
            <div className='text-info'>
              (<b>{answer.points_override.points}</b> / {points}) &mdash;<small className='font-italic'>Overridden</small></div>
          ) : (
            <div className='text-muted'>(<b>{answer ? answer.points : 0}</b> / {points})</div>
          )
        }
        <div className='my-1 pl-2 pl-md-3'>
          {this.renderAnswer(type, question, answer)}
        </div>
      </>
    );
  }
  renderOverridePointsButton = (id, index, points, attempt) => {
    let answer = attempt.question_answers.find(qa => qa.question_id === id);
    if (answer) {
      return (      
        <div>
          <Button variant='info' size='sm' title='Override points' onClick={() => this.showOverridePointsModal(answer, index, points, attempt)}>
            <FontAwesomeIcon icon='pencil-alt' />
          </Button>
          {
            answer.points_override !== null && (
              <Button variant='danger' size='sm' title='Clear override points' className='ml-2' onClick={() => this.showDeleteOverridePointsModal(answer, index, points, attempt)}>
                <FontAwesomeIcon icon='trash-alt' />
              </Button>
            )
          }
        </div>
      );
    }
    return null;
  }
  renderGraded = submission => {
    const { materialData, materialPoints } = this.props;

    let gradeData = materialData.grades.find((grade) => grade.student_id === submission.student_id);

    if (gradeData) {
      return (
        <div className='text-muted'>
          Recorded grade: {gradeData.grade} / {materialPoints}
          {
            submission.points_with_override !== gradeData.grade && (
              <small className='font-italic ml-2'>(Overridden)</small>
            )
          }
        </div>
      );
    }

    return (
      <div className='text-muted'>
        (No grade record saved)
      </div>
    );
  }
  renderViewAttempt = () => {
    const { errorMessage, attempts, selectedAttempt, overridePointsModal, deleteOverridePointsModal, gradeModal, viewAllAnswers } = this.state;
    const { questions, submission, materialData, materialPoints } = this.props;

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (attempts.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let attempt = attempts.find(attempt => attempt.id === +selectedAttempt);
    let revisionIndex = attempts.length;

    if (!attempt) {
      return null;
    }

    return (
      <>
        <div>
          <div className='d-flex justify-content-between'>
            <NameLink
              id={submission.profile.id}
              name={submission.profile.name}
              image={submission.profile.image}
              learningPlatform={submission.profile.student_learning_platform ? submission.profile.student_learning_platform.platform : null}
              rank={submission.profile.rank} />
            <div>
              <Button variant='green' size='sm' title='Override grade' onClick={this.showGradeModal}>
                <FontAwesomeIcon icon='pencil-alt' />
              </Button>
            </div>
          </div>
          {
            materialData.type !== 'exam' && (
              <>
                <div className='text-muted'>
                  Attempts: {attempts.length}
                </div>
              </>
            )
          }
          {this.renderGraded(submission)}
          <div>
            <Button variant='warning' onClick={e => this.setState({...this.state, viewAllAnswers: !viewAllAnswers})} size='sm'>
              {
                viewAllAnswers ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' /> Hide correct answers
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' /> Show correct answers
                  </>
                )
              }
            </Button>
          </div>
        </div>
        <div className='dropdown-divider'></div>
        {
          attempts.length > 1 && (
            <Form.Control as='select' value={selectedAttempt} onChange={this.handleSelectAttempt}>
              <option disabled hidden value=''>Select attempt...</option>
              {
                attempts.map((att, index) => (
                  <option key={index} value={att.id}>Attempt {revisionIndex--} &mdash; ({att.points_with_override} point{att.points_with_override > 1 ? 's' : ''})</option>
                ))
              }
            </Form.Control>
          )
        }
        <div className='text-muted px-3'>
          <div>
            Points: {attempt.points_with_override} / {materialPoints}
            {
              attempt.points_overridden && (
                <small className='ml-2 font-italic'>&mdash;Overridden</small>
              )
            }
          </div>
          <div>
            Duration: {moment.utc(moment.duration(moment(attempt.latest_update).diff(attempt.created_at)).asMilliseconds()).format('HH:mm:ss')}
          </div>
        </div>
        <div className='dropdown-divider'></div>
        <div className='p-1 p-md-3 submission-question-list'>
          {
            questions.map(({question, points, type, id}, index) => (
              <div key={id}>
                <div className='d-flex'>
                  <div className='h6 px-1 px-md-3 m-0'>{index+1}</div>
                  <div className='flex-fill'>
                    <RichTextEditor.Viewer body={question.description} />
                    {/* <div>{question.description}</div> */}
                    {
                      ((type === 'identification' || type === 'enumeration') && question.is_case_sensitive) && (
                        <div className='text-muted'>
                          <FontAwesomeIcon icon='exclamation' /> Case-sensitive
                        </div>
                      )
                    }
                    {
                      (type === 'enumeration' && question.is_in_order) && (
                        <div className='text-muted'>
                          <FontAwesomeIcon icon='exclamation' /> Sequential order
                        </div>
                      )
                    }
                    {this.renderQuestion(id, type, points, question, attempt)}
                  </div>
                  {this.renderOverridePointsButton(id, index, points, attempt)}
                </div>
              </div>
            ))
          }
        </div>

        <Modal show={gradeModal.show} backdrop='static' onHide={this.hideGradeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{submission.profile.name}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleGrade}>
            <Modal.Body>
              {
                gradeModal.errorMessage && (
                  <Alert variant='danger'>
                    {gradeModal.errorMessage}
                  </Alert>
                )
              }
              <div className='h5'>
                {materialData.title}
              </div>
              <Form.Group>
                <Form.Label>Category</Form.Label>
                <Form.Control value={materialData.faculty_load_id ? materialData.grade_category.name : materialData.audience.grade_category.name} disabled />
              </Form.Group>
              <Form.Group>
                <Form.Label>Grade</Form.Label>
                <InputGroup>
                  <Form.Control type='number' onChange={this.handleGradeInputChange} value={gradeModal.grade} min='0' max={materialPoints} />
                  <InputGroup.Append>
                    <InputGroup.Text>/ {materialPoints}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' className='mr-2' onClick={this.hideGradeModal} disabled={gradeModal.isLoading}>
                Cancel
              </Button>
              <Button variant='green' type='submit' disabled={gradeModal.isLoading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal show={overridePointsModal.show} backdrop='static' onHide={this.hideOverridePointsModal}>
          <Modal.Header closeButton>
            <Modal.Title>Override points</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleOverridePointsSubmit}>
            <Modal.Body>
              {
                overridePointsModal.errorMessage ? (
                  <Alert variant='danger'>
                    {overridePointsModal.errorMessage}
                  </Alert>
                ) : (overridePointsModal.answerData && (overridePointsModal.answerData.answer_essay || (overridePointsModal.answerData.images && overridePointsModal.answerData.images.length > 0))) ? (
                  <Alert variant='warning'>
                    Proceeding will <b>delete</b> the attached files
                  </Alert>
                ) : null
              }
              <div className='h5'>
                Question {overridePointsModal.index+1}
              </div>
              <Form.Group>
                <Form.Label>Points</Form.Label>
                <InputGroup>
                  <Form.Control type='number' value={overridePointsModal.overridePoints} min='0' max={overridePointsModal.maxPoints} onChange={this.handleOverridePointsInputChange} />
                  <InputGroup.Append>
                    <InputGroup.Text> / {overridePointsModal.maxPoints}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' className='mr-2' onClick={this.hideOverridePointsModal} disabled={overridePointsModal.isLoading}>
                Cancel
              </Button>
              <Button variant='green' type='submit' disabled={overridePointsModal.isLoading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <PromptDeleteModal
          show={deleteOverridePointsModal.show}
          title='Clear override points'
          isLoading={deleteOverridePointsModal.isLoading}
          errorMessage={deleteOverridePointsModal.errorMessage}
          onHide={this.hideDeleteOverridePointsModal}
          onDelete={this.handleDeleteOverridePoints}
        >
          <div className='h6 font-weight-bold'>
            Are you sure you want to clear the overridden points for Question {deleteOverridePointsModal.index+1}?
          </div>
          <Alert variant='light'>
            {deleteOverridePointsModal.overridePoints} / {deleteOverridePointsModal.maxPoints}
          </Alert>
        </PromptDeleteModal>
      </>
    );
  }
  render() {
    const { isLoading, comment, editModal, deleteModal, deleteAllModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    return (
      <div>
        <Row>
          <Col lg={9}>
            {this.renderViewAttempt()}
          </Col>
          <Col lg={3}>
            <div className='sticky-top pt-2'>
              <div className='rounded border comment-panel'>
                <div className='bg-light p-2 rounded-top border-bottom d-flex align-items-center'>
                  <div className='flex-fill'>
                    <h6 className='m-0'>Feedback</h6>
                  </div>
                  <div className='small'>
                    <Button variant='danger' className='small' size='sm' title='Delete all feedback files' onClick={this.showDeleteAllModal}>
                      <div className='fa-layers'>
                        <FontAwesomeIcon icon={['far', 'file']} />
                        <FontAwesomeIcon icon='trash' inverse transform='shrink-4 down-5 right-5' />
                      </div>
                    </Button>
                  </div>
                </div>
                <div className='comment-text px-2 py-1'>
                  {
                    comment.messages.length > 0 && (
                      <>
                        {
                          comment.messages.map((cm, i) => (
                            <div key={i} className={`d-flex my-1 small ${cm.type === 'left' ? 'justify-content-start' : 'justify-content-end'}`}>
                              <div className={`rounded border text-${cm.type}`} style={{ maxWidth: '85%', minWidth: '70%', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                <div className={`px-2 py-1 text-${cm.type}`} style={{ whiteSpace: 'pre-wrap' }}>
                                  {
                                    cm.attempt && (
                                      <div className='font-weight-bold'>{cm.attempt}</div>
                                    )
                                  }
                                  <div>{cm.message}</div>
                                  {this.renderFiles(cm.data)}
                                  <div className='font-italic text-muted'>
                                    <div>
                                      {moment(cm.created_at).format('hh:mm:ss A')}
                                    </div>
                                    <div>
                                      {moment(cm.created_at).format('MMMM D, YYYY')}
                                    </div>
                                  </div>
                                </div>
                                {
                                  (cm.data && cm.data.from === 'faculty') && (
                                    <div className='flex-fill d-flex border-top'>
                                      <div className='flex-fill border-right'>
                                        <Button variant='outline-info border-0' size='sm' block onClick={() => this.showEditModal(cm.data)} disabled={comment.isLoading}>
                                          <FontAwesomeIcon icon='pencil-alt' />
                                        </Button>
                                      </div>
                                      <div className='flex-fill'>
                                        <Button variant='outline-danger border-0' size='sm' block onClick={() => this.showDeleteModal(cm.data)} disabled={comment.isLoading}>
                                          <FontAwesomeIcon icon='trash-alt' />
                                        </Button>
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          ))
                        }
                      </>
                    )
                  }
                </div>
                <div className='comment-box p-2 border-top bg-light'>
                  {
                    comment.errorMessage && (
                      <Alert variant='danger' className='small'>
                        {comment.errorMessage}
                      </Alert>
                    )
                  }
                  <InputGroup size='sm'>
                    <InputGroup.Prepend className='border rounded-left'>
                      <label className={`m-0 btn btn-light btn-sm ${comment.isLoading ? 'disabled' : ''}`} htmlFor='add-file' title='File upload'>
                        <FontAwesomeIcon icon='file-upload' />
                      </label>
                      <Form.File className='d-none' id='add-file' onChange={this.handleFileUpload} multiple disabled={comment.isLoading} />
                    </InputGroup.Prepend>
                    <Form.Control as='textarea' rows={1} value={comment.input} onChange={this.handleCommentInputChange} disabled={comment.isLoading} />
                    <InputGroup.Append>
                      <Button variant='green' disabled={comment.isLoading} onClick={this.handleComment}>
                        Publish
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  {this.renderFileUpload()}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Modal show={editModal.show} size='sm' onHide={this.hideEditModal} backdrop='static'>
          <Modal.Header closeButton className='font-weight-bold'>
            <h6 className='m-0'>Edit feedback</h6>
          </Modal.Header>
          <Modal.Body>
            {
              editModal.errorMessage && (
                <Alert variant='danger' className='small'>
                  {editModal.errorMessage}
                </Alert>
              )
            }
            <Form.Group>
              <Form.Control as='textarea' rows={4} value={editModal.input} disabled={editModal.isLoading} onChange={this.handleEditInputChange} />
            </Form.Group>
            {this.renderEditFileUpload()}
            {this.renderAttachedFiles()}
          </Modal.Body>
          <Modal.Footer>
            <div className='mr-auto'>
              <label className={`m-0 btn btn-light btn-sm ${editModal.isLoading ? 'disabled' : ''}`} htmlFor='add-edit-file' title='File upload'>
                <FontAwesomeIcon icon='file-upload' />
              </label>
              <Form.File className='d-none' id='add-edit-file' onChange={this.handleEditFileUpload} multiple disabled={editModal.isLoading} />
            </div>
            <Button variant='danger' size='sm' disabled={editModal.isLoading} onClick={this.hideEditModal}>
              Cancel
            </Button>
            <Button variant='info' size='sm' disabled={editModal.isLoading} onClick={this.handleEditComment}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={deleteModal.show} size='sm' onHide={this.hideDeleteModal}>
          <Modal.Header closeButton className='font-weight-bold'>
            <h6 className='m-0'>Delete feedback</h6>
          </Modal.Header>
          <Modal.Body>
            {
              deleteModal.errorMessage && (
                <Alert variant='danger' className='small'>
                  {deleteModal.errorMessage}
                </Alert>
              )
            }
            {
              deleteModal.data && (
                <>
                  <div className='h6 font-weight-bold small'>
                    Are you sure you want to delete the feedback?
                  </div>
                  <Alert variant='light' className='small'>
                    <div>
                      {deleteModal.data.description}
                    </div>
                    <div className='font-italic'>
                      {moment(deleteModal.data.created_at).format('MMMM D, YYYY hh:mm:ss A')}
                    </div>
                  </Alert>
                </>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='green' size='sm' disabled={deleteModal.isLoading} onClick={this.hideDeleteModal}>
              Cancel
            </Button>
            <Button variant='danger' size='sm' disabled={deleteModal.isLoading} onClick={this.handleDeleteComment}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <PromptDeleteModal
          show={deleteAllModal.show}
          title='Delete all feedback files'
          isLoading={deleteAllModal.isLoading}
          errorMessage={deleteAllModal.errorMessage}
          onHide={this.hideDeleteAllModal}
          onDelete={this.handleDeleteAllCommentFiles}
        >
          <div className='h6 font-weight-bold'>
            Are you sure you want to delete all feedback files?
          </div>
        </PromptDeleteModal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Submission);