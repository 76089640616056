import React, { Component } from 'react';
import { Alert, Breadcrumb, Nav } from 'react-bootstrap';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import Details from './parts/Details';
import Submissions from './parts/Submissions';
import Submission from './parts/Submission';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      assignment: null,
      activeNav: 'details',
      viewSubmission: null
    }
  }
  componentDidMount() {
    const { classID, materialID } = this.props.match.params;

    axiosRequest('get', `faculty/class/${classID}/material/assignment/${materialID}`, null, ({ data: { data }}) => {
      let custom = {};
      if (this.props.location.state && this.props.location.state.nav) {
        custom = {
          activeNav: this.props.location.state.nav
        };
      }
      this.setState({
        ...this.state,
        assignment: data,
        isLoading: false,
        ...custom
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    });
  }
  onViewSubmission = viewSubmission => {
    this.setState({
      ...this.state,
      viewSubmission
    });
  }
  handleMaterials = () => {
    const { classID } = this.props.match.params;
    this.props.history.push(`/faculty/class/${classID}/materials`);
  }
  handleSelect = key => {
    this.setState({
      ...this.state,
      activeNav: key,
      viewSubmission: null
    });
  }
  handleGrade = (data, cb = () => {}) => {
    const { assignment: { grades } } = this.state;

    let hasRow = [...grades].find(grade => grade.student_id === data.student_id);
    let newGrades = [...grades];

    if (hasRow) {
      newGrades = [...grades].map(grade => {
        if (grade.student_id === data.student_id) {
          return data;
        }

        return grade;
      });
    } else {
      newGrades = [...newGrades, data];
    }

    this.setState({
      ...this.state,
      assignment: {
        ...this.state.assignment,
        grades: newGrades
      }
    }, cb);
  }
  renderContent = () => {
    const { assignment, viewSubmission, activeNav } = this.state;

    if (viewSubmission) {
      return (
        <Submission
          {...this.props}
          assignment={assignment}
          submission={viewSubmission}
          onGrade={this.handleGrade}
        />
      );
    }

    switch (activeNav) {
      case 'details':
        return (
          <Details {...this.props} assignment={assignment} />
        );
      case 'submissions':
        return (
          <Submissions
            {...this.props}
            assignment={assignment}
            onGrade={this.handleGrade}
            handleViewSubmission={this.onViewSubmission} />
        );
      default:
        return null;
    }
  }
  renderContainer = () => {
    const { isLoading, errorMessage, activeNav, assignment, viewSubmission } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.handleMaterials}>Materials</Breadcrumb.Item>
          <Breadcrumb.Item active>{assignment.title}</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <Nav variant='tabs bg-light rounded-top' className='flex-column flex-md-row' onSelect={this.handleSelect}>
            <Nav.Item>
              <Nav.Link eventKey='details' active={activeNav === 'details' && !viewSubmission}>Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='submissions' active={activeNav === 'submissions' && !viewSubmission}>Submissions</Nav.Link>
            </Nav.Item>
            {
              viewSubmission && (
                <Nav.Item>
                  <Nav.Link className='font-italic mr-1' active>Submission: {viewSubmission.profile.name}</Nav.Link>
                </Nav.Item>
              )
            }
          </Nav>
          <div className='content border border-top-0 rounded-bottom p-3'>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className='border rounded p-4 my-3'>
        {this.renderContainer()}
      </div>
    );
  }
}