import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RankBadge = props => {
  if (props.rank && props.rank.rank > 0) {
    return (
      <div style={{ position: 'absolute', bottom: 0, right: 0, fontSize: '1rem', ...props.rankStyle }}>
        {
          props.rank.rank === 1 ? (
            <span className='fa-layers fa-fw' style={{ color: '#c9b037' }}>
              <FontAwesomeIcon icon='sun' transform='grow-5' enableBackground style={{ filter: 'drop-shadow(1px 1px 1px rgba(127, 127, 127, .3))' }} />
              <FontAwesomeIcon icon='circle' transform='shrink-4' />
              <FontAwesomeIcon icon='crown' transform='shrink-8' inverse />
            </span>
          ) : props.rank.rank === 2 ? (
            <span className='fa-layers fa-fw' style={{ color: '#b4b4b4' }}>
              <FontAwesomeIcon icon='sun' transform='grow-5' enableBackground style={{ filter: 'drop-shadow(1px 1px 1px rgba(127, 127, 127, .3))' }} />
              <FontAwesomeIcon icon='circle' transform='shrink-4' />
              <FontAwesomeIcon icon='crown' transform='shrink-8' inverse />
            </span>
          ) : props.rank.rank === 3 ? (
            <span className='fa-layers fa-fw' style={{ color: '#ad8a56' }}>
              <FontAwesomeIcon icon='sun' transform='grow-5' enableBackground style={{ filter: 'drop-shadow(1px 1px 1px rgba(127, 127, 127, .3))' }} />
              <FontAwesomeIcon icon='circle' transform='shrink-4' />
              <FontAwesomeIcon icon='crown' transform='shrink-8' inverse />
            </span>
          ) : (
            <span className='fa-layers fa-fw text-green'>
              <FontAwesomeIcon icon='sun' transform='grow-5' enableBackground style={{ filter: 'drop-shadow(1px 1px 1px rgba(127, 127, 127, .3))' }} />
              <FontAwesomeIcon icon='circle' transform='shrink-4' />
              <FontAwesomeIcon icon='medal' transform='shrink-8' inverse />
            </span>
          )
        }
      </div>
    );
  }

  return null;
}

RankBadge.propTypes = {
  rank: PropTypes.object,
  rankStyle: PropTypes.object,
};

RankBadge.defaultProps = {
  rank: null,
  rankStyle: {}
};

export default RankBadge;