import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../util/helpers/axiosRequest';
import moment from 'moment';
import NameLink from '../NameLink/NameLink';
import './style.scss';
import LoadingIcon from '../LoadingIcon/LoadingIcon';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      chatNotifications: {
        data: []
      }
    };
  }
  componentDidMount() {
    axiosRequest('get', 'chat/conversations', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        chatNotifications: data,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        isLoading: false
      });
    });
  }
  showConversation = (conversation, info) => {
    this.props.hideMessages();
    console.log(conversation)
    this.props.history.push(`/chat/${conversation.id}`, { name: conversation.title ? conversation.title : info.profile.name });
  }
  handleViewAll = () => {
    this.props.hideMessages();
    this.props.history.push('/chat');
  }
  handleNewMessage = () => {
    this.props.hideMessages();
    this.props.history.push('/chat', { newMessage: true });
  }
  renderContent = () => {
    const { isLoading, errorMessage, chatNotifications } = this.state;
    const { currentUser } = this.props;

    if (isLoading) {
      return (
        <div className='text-center p-3'>
          <LoadingIcon />
        </div>
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger' className='m-3'>
          {errorMessage}
        </Alert>
      );
    }

    if (chatNotifications.data.length === 0) {
      return (
        <>
          <div className='border-bottom text-center py-2 px-3 btn-link d-flex justify-content-center align-items-center' onClick={this.handleNewMessage}>
            <div>
              <FontAwesomeIcon icon='edit' /> New message
            </div>
          </div>
          <Alert variant='light' className='my-3'>
            Nothing to show.
          </Alert>
        </>
      );
    }

    return (
      <div className='conversation-list'>
        {
          chatNotifications.data.map(chatNotification => {
            if (chatNotification.chat_conversation.title && chatNotification.chat_conversation.faculty_load_id) {
              return (
                <div
                  key={chatNotification.id}
                  className={`py-1 px-2 ${chatNotification.seen_at ? '' : 'unseen'}`}
                  onClick={() => this.showConversation(chatNotification.chat_conversation, chatNotification.chat_conversation.chat_participant)}>
                  <div className='h6 mb-0 d-flex text-primary align-items-center'>
                    <div className='mr-2 text-center bg-green' style={{ width: '2.5rem', height: '2.5rem', borderRadius: '2.5rem', lineHeight: '2.5rem' }}>
                      <FontAwesomeIcon icon='users' className='text-white' />
                    </div>
                    <div>
                      {chatNotification.chat_conversation.title}
                    </div>
                  </div>
                  <div className='pl-5'>
                    <div className='text-truncate'>
                      {
                        chatNotification.chat_message.chat_participant.profile.id === currentUser.id ?
                          'You: ' :
                        chatNotification.chat_message.chat_participant.profile.title ?
                          `${chatNotification.chat_message.chat_participant.profile.title} ${chatNotification.chat_message.chat_participant.profile.last_name}: ` :
                          `${chatNotification.chat_message.chat_participant.profile.first_name}: `
                      }
                      {chatNotification.chat_message.message}
                    </div>
                    <small className='d-block font-italic text-muted'>
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            {moment(chatNotification.chat_message.created_at).format('MMM D, YYYY hh:mm A')}
                          </Tooltip>
                        }
                        trigger={['hover', 'focus']}>
                        <FontAwesomeIcon icon='clock' />
                      </OverlayTrigger> {moment(chatNotification.chat_message.created_at).fromNow()}
                    </small>
                  </div>
                </div>
              );
            }

            return (
              <div
                key={chatNotification.id}
                className={`py-1 px-2 ${chatNotification.seen_at ? '' : 'unseen'}`}
                onClick={() => this.showConversation(chatNotification.chat_conversation, chatNotification.chat_conversation.chat_participant)}>
                <div className='h6 mb-0 d-flex'>
                  <NameLink {...chatNotification.chat_conversation.chat_participant.profile} />
                </div>
                <div className='pl-5'>
                  <div className='text-truncate'>
                    {chatNotification.chat_message.chat_participant.profile.id === currentUser.id ? 'You: ' : ''}
                    {chatNotification.chat_message.message}
                  </div>
                  <small className='d-block font-italic text-muted'>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          {moment(chatNotification.chat_message.created_at).format('MMM D, YYYY hh:mm A')}
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <FontAwesomeIcon icon='clock' />
                    </OverlayTrigger> {moment(chatNotification.chat_message.created_at).fromNow()}
                  </small>
                </div>
              </div>
            );
          })
        }
        <div className='text-center py-2 px-3 btn-link' onClick={this.handleViewAll}>
          View all
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        { this.renderContent() }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(Messages);