import React, { Component } from 'react';
import { Alert, Nav, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NameLink from '../../../common/NameLink/NameLink';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import './style.scss';
import moment from 'moment';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Badges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      badges: null,
      students: [],
      activeKey: 'ranking'
    };
  }
  componentDidMount() {
    const { userType, classID } = this.props.match.params;

    axiosRequest('get', `${userType}/class/${classID}/badges`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      })
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleSelect = key => {
    this.setState({
      ...this.state,
      activeKey: key
    });
  }
  renderBadge = (studentID, badgeType) => {
    const { badges } = this.state;
    
    if (badges.length === 0) {
      return null;
    }

    const badgeCount = badges.filter(badge => badge.student_id === studentID && badge.type === badgeType).length;

    return badgeCount > 0 ? (
        <div>
          <span>
            <FontAwesomeIcon icon='trophy' size='lg' />
          </span>
          <span className='pl-2'>
            x {badgeCount}
          </span>
        </div>
      ) : null;
  }
  renderName = studentID => {
    const { students } = this.state;

    const student = students.find(student => student.id === studentID);

    return <NameLink {...student} />;
  }
  renderTopBadge = badge => {
    if (!badge) {
      return null;
    }

    return (
      <div>
        <span>
          <FontAwesomeIcon icon='trophy' size='lg' />
        </span>
        <span className='pl-2'>
          x {badge}
        </span>
      </div>
    );
  }
  renderTop = () => {
    const { top } = this.state;

    if (top.length === 0) {
      return (
        <Alert variant='light'>
          <div>
            No top badge holders last month.
          </div>
          <div>
            This month's top badge holders will be announced on {moment().add(1, 'months').startOf('month').format('MMMM D, YYYY, dddd')}
          </div>
        </Alert>
      );
    }
    
    return (
      <>
        <div className='h4'>
          {moment().subtract(1, 'months').format('MMMM YYYY')}
          <small className='ml-2 text-muted font-italic'>(Last month)</small>
        </div>
        <Table size='sm' responsive hover striped className='top-badge-table'>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th className='text-center'>
                <span className='fa-layers fa-fw my-1 mx-2' title='Login'>
                  <FontAwesomeIcon icon='sign-in-alt' size='lg' />
                  <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-7' className='text-green' />
                </span>
              </th>
              <th className='text-center'>
                <span className='fa-layers fa-fw my-1 mx-2' title='Submission'>
                  <FontAwesomeIcon icon='paper-plane' size='lg' />
                  <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-9' className='text-green' />
                </span>
              </th>
              <th className='text-center'>
                <span className='fa-layers fa-fw my-1 mx-2' title='Attendance'>
                  <FontAwesomeIcon icon='calendar-alt' size='lg' />
                  <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
                </span>
              </th>
              <th className='text-center'>
                <span className='fa-layers fa-fw my-1 mx-2' title='Participation'>
                  <FontAwesomeIcon icon='comments' size='lg' />
                  <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
                </span>
              </th>
              <th className='text-center'>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {
              top.map((student, rankIndex) => (
                <tr key={student.id} className={rankIndex === 0 ? 'h5' : rankIndex === 1 ? 'h6' : ''}>
                  <th className='text-center align-middle'>
                    {rankIndex+1}
                  </th>
                  <th>
                    {this.renderName(student.id)}
                  </th>
                  <td>{this.renderTopBadge(student.Login)}</td>
                  <td>{this.renderTopBadge(student.Submission)}</td>
                  <td>{this.renderTopBadge(student.Attendance)}</td>
                  <td>{this.renderTopBadge(student.Participation)}</td>
                  <td>{this.renderTopBadge(student.total)}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </>
    )
  }
  render() {
    const { isLoading, errorMessage, students, activeKey } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (students.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <Nav variant='tabs bg-light rounded-top' onSelect={this.handleSelect}>
          <Nav.Item className='flex-fill'>
            <Nav.Link eventKey='ranking' active={activeKey === 'ranking'}>Ranking</Nav.Link>
          </Nav.Item>
          <Nav.Item className='flex-fill'>
            <Nav.Link eventKey='students' active={activeKey === 'students'}>Student Badges</Nav.Link>
          </Nav.Item>
        </Nav>
        <div className='p-3 border rounded-bottom border-top-0'>
          {
            activeKey === 'ranking' ? this.renderTop() : (
              <>
                <div className='h4 px-2'>
                  {moment().format('MMMM YYYY')}
                  <small className='ml-2 text-muted font-italic'>(Current month)</small>
                </div>
                <Table size='sm' responsive hover striped className='badge-table'>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className='text-center'>
                        <div>
                          <span className='fa-layers fa-2x'>
                            <FontAwesomeIcon icon='sign-in-alt' />
                            <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-7' className='text-green' />
                          </span>
                        </div>
                        <div>Login</div>
                      </th>
                      <th className='text-center'>
                        <div>
                          <span className='fa-layers fa-2x'>
                            <FontAwesomeIcon icon='paper-plane' />
                            <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-9' className='text-green' />
                          </span>
                        </div>
                        <div>Submission</div>
                      </th>
                      <th className='text-center'>
                        <div>
                          <span className='fa-layers fa-2x'>
                            <FontAwesomeIcon icon='calendar-alt' />
                            <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
                          </span>
                        </div>
                        <div>Attendance</div>
                      </th>
                      <th className='text-center'>
                        <div>
                          <span className='fa-layers fa-2x'>
                            <FontAwesomeIcon icon='comments' />
                            <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
                          </span>
                        </div>
                        <div>Participation</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      students.map(student => (
                        <tr key={student.id}>
                          <th>
                            <NameLink {...student} />
                          </th>
                          <td>{this.renderBadge(student.id, 'Login')}</td>
                          <td>{this.renderBadge(student.id, 'Submission')}</td>
                          <td>{this.renderBadge(student.id, 'Attendance')}</td>
                          <td>{this.renderBadge(student.id, 'Participation')}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </>
            )
          }
        </div>
      </>
    );
  }
}