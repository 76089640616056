import React, { Component } from 'react';
import { Card, Container, Nav } from 'react-bootstrap';
import Header from '../../../common/Header/Header';
import { Redirect, Route, Switch } from 'react-router-dom';
import Student from './parts/Student';
import Faculty from './parts/Faculty';

export default class Attendance extends Component {
  handleSelect = key => {
    this.props.history.push(key);
  }
  render() {
    const { userType } = this.props.match.params;

    return (
      <>
        <Header active='Attendance' />
        <Container>
          <Card className='my-3'>
            <Card.Header>
              <Nav variant='tabs bg-light rounded-top'>
                <Nav.Item>
                  <Nav.Link
                    eventKey='student'
                    onSelect={this.handleSelect}
                    active={userType === 'student'}>
                      Student
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='faculty'
                    onSelect={this.handleSelect}
                    active={userType === 'faculty'}>
                      Faculty
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <Switch>
                <Route exact path='/attendance/student' component={Student} />
                <Route exact path='/attendance/faculty' component={Faculty} />
                <Redirect to={{ pathname: '/attendance/student' }} />
              </Switch>
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  }
}