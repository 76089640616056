import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

const titleMap = {
  allow: 'Allow',
  block: 'Block'
};

export default class AccountType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      schoolClasses: {
        data: []
      },
      modal: {
        show: false,
        type: '',
        data: null,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      },
      search: {
        data: {
          data: []
        },
        isLoading: false,
        errorMessage: '',
        query: '',
        isSearch: false,
        nextPageLoading: false,
        nextPageError: ''
      },
      isNextPageLoading: false,
      nextPageError: ''
    }
  }
  componentDidMount() {
    axiosRequest('get', 'admin/block-users/classes', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        isLoading: false,
        schoolClasses: data
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showModal = (data, type) => {
    this.setState({
      ...this.state,
      modal: {
        show: true,
        type,
        data,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      }
    });
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      modal: {
        show: false,
        type: '',
        data: null,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      }
    });
  }
  handleSearchInputChange = event => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        query: event.target.value
      }
    });
  }
  handleSearch = event => {
    event.preventDefault();

    const { search } = this.state;

    if (search.query.trim() === '') {
      this.setState({
        ...this.state,
        search: {
          ...this.state.search,
          isSearch: false
        }
      });
      return;
    }

    // if (!search.isSearch) {
    //   return;
    // }

    this.setState({
      ...this.state,
      search: {
        ...search,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {

      axiosRequest('get', `admin/block-users/classes/search/${search.query}`, null, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          search: {
            ...search,
            data,
            isLoading: false,
            errorMessage: '',
            isSearch: !!search.query
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          search: {
            ...search,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleSearchNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        isNextPageLoading: true,
        nexPageError: ''
      }
    }, () => {
      const { search } = this.state;

      const path = search.data.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          search: {
            ...this.state.search,
            data: {
              ...data,
              data: [
                ...this.state.search.data.data,
                ...data.data
              ]
            },
            isNextPageLoading: false,
            nextPageError: ''
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          search: {
            ...this.state.search,
            isNextPageLoading: false,
            nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true,
      nextPageError: ''
    }, () => {
      const { schoolClasses } = this.state;

      const path = schoolClasses.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          schoolClasses: {
            ...data,
            data: [
              ...this.state.schoolClasses.data,
              ...data.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleSubmit = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        isLoading: true,
        errorMessage: '',
        successMessage: ''
      }
    }, () => {
      const { modal } = this.state;
      axiosRequest('post', `admin/block-users/classes/${modal.data.id}`, {
        type: modal.type
      }, ({ data: { message }}) => {
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isLoading: false,
            successMessage: message
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  renderContent = () => {
    const { isLoading, errorMessage, schoolClasses, modal, search, isNextPageLoading, nextPageError } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (schoolClasses.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }
    
    return (
      <>
        {
          search.errorMessage && (
            <Alert variant='danger'>
              {search.errorMessage}
            </Alert>
          )
        }
        <Row>
          <Col lg={6} className='ml-auto'>
            <div className='mb-2 mt-1'>
              <Form onSubmit={this.handleSearch}>
                <InputGroup>
                  <Form.Control type='search' placeholder='Search classes...' value={search.query} onChange={this.handleSearchInputChange} />
                  <InputGroup.Append>
                    <Button variant='primary' type='submit' disabled={search.isLoading}>
                      {
                        search.isLoading ? (
                          <LoadingIcon />
                        ) : (
                          <FontAwesomeIcon icon='search' />
                        )
                      }
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </div>
          </Col>
        </Row>
        <div className='dropdown-divider'></div>
        {
          search.isSearch ? (
            search.data.data.length === 0 ? (
              <Alert variant='light'>
                Nothing to show.
              </Alert>
            ) : (
              <>
                {
                  search.data.data.map((sc, i) => (
                    <div key={i}>
                      <Row>
                        <Col lg={8} className='px-4'>
                          <div className='font-weight-bold'>
                            {`${sc.program.code} ${sc.year_level.code} - ${sc.section.code}`}
                          </div>
                          <div>{sc.program.description}</div>
                          <div>{sc.year_level.description}</div>
                          <div>Section {sc.section.code}</div>
                          <div className='text-muted font-italic'>{sc.student_classes.length} student{sc.student_classes.length !== 1 ? 's' : ''}</div>
                        </Col>
                        <Col lg={4} className='text-center'>
                          <Button variant='green' className='mr-2' onClick={() => this.showModal(sc, 'allow')}>
                            Allow
                          </Button>
                          <Button variant='danger' onClick={() => this.showModal(sc, 'block')}>
                            Block
                          </Button>
                        </Col>
                      </Row>
                      <div className='dropdown-divider'></div>
                    </div>
                  ))
                }
                {
                  search.data.next_page_url ? (
                    <div className='text-center'>
                      {
                        search.isNextPageLoading && (
                          <LoadingIcon className='mr-2' sm />
                        )
                      }
                      <span onClick={this.handleSearchNextPage} className={`view-more ${search.isNextPageLoading ? 'disabled' : ''}`}>
                        View more...
                        <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
                      </span>
                      {
                        search.nextPageError && (
                          <Alert variant='danger'>
                            {search.nextPageError}
                          </Alert>
                        )
                      }
                    </div>
                  ) : (
                    <Alert variant='light' className='text-center'>
                      No more classes.
                    </Alert>
                  )
                }
              </>
            )
          ) : (
            <>
              {
                schoolClasses.data.map((sc, i) => (
                  <div key={i}>
                    <Row>
                      <Col lg={8} className='px-4'>
                        <div className='font-weight-bold'>
                          {`${sc.program.code} ${sc.year_level.code} - ${sc.section.code}`}
                        </div>
                        <div>{sc.program.description}</div>
                        <div>{sc.year_level.description}</div>
                        <div>Section {sc.section.code}</div>
                        <div className='text-muted font-italic'>{sc.student_classes.length} student{sc.student_classes.length !== 1 ? 's' : ''}</div>
                      </Col>
                      <Col lg={4} className='text-center'>
                        <Button variant='green' className='mr-2' onClick={() => this.showModal(sc, 'allow')}>
                          Allow
                        </Button>
                        <Button variant='danger' onClick={() => this.showModal(sc, 'block')}>
                          Block
                        </Button>
                      </Col>
                    </Row>
                    <div className='dropdown-divider'></div>
                  </div>
                ))
              }
              {
                schoolClasses.next_page_url ? (
                  <div className='text-center'>
                    {
                      isNextPageLoading && (
                        <LoadingIcon className='mr-2' sm />
                      )
                    }
                    <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                      View more...
                      <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
                    </span>
                    {
                      nextPageError && (
                        <Alert variant='danger'>
                          {nextPageError}
                        </Alert>
                      )
                    }
                  </div>
                ) : (
                  <Alert variant='light' className='text-center'>
                    No more classes.
                  </Alert>
                )
              }
            </>
          )
        }
        <Modal show={modal.show} onHide={this.hideModal} backdrop='static' keyboard={false}>
          <Modal.Header>
            <Modal.Title>{modal.type ? titleMap[modal.type] : ''} Access</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              modal.errorMessage ? (
                <Alert variant='danger'>
                  {modal.errorMessage}
                </Alert>
              ) : modal.successMessage ? (
                <Alert variant='success'>
                  {modal.successMessage}
                </Alert>
              ) : (
                <Alert variant='warning'>
                  All the students within the following class
                  {
                    modal.type === 'allow' ? ' will be allowed to access VLE:' : ' will be blocked from VLE:'
                  }
                </Alert>
              )
            }
            {
              modal.data && (
                <div className='px-2'>
                  <div className='font-weight-bold'>
                    {`${modal.data.program.code} ${modal.data.year_level.code} - ${modal.data.section.code}`}
                  </div>
                  <div>{modal.data.program.description}</div>
                  <div>{modal.data.year_level.description}</div>
                  <div>Section {modal.data.section.code}</div>
                  <div className='text-muted font-italic'>{modal.data.student_classes.length} student{modal.data.student_classes.length !== 1 ? 's' : ''}</div>
                </div>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' className='mr-2' disabled={modal.isLoading} onClick={this.hideModal}>
              {modal.successMessage ? 'Close' : 'Cancel'}
            </Button>
            {
              modal.type === 'allow' ? (
                <Button variant='green' disabled={modal.isLoading} onClick={this.handleSubmit}>
                  Allow
                </Button>
              ) : modal.type === 'block' ? (
                <Button variant='danger' disabled={modal.isLoading} onClick={this.handleSubmit}>
                  Block
                </Button>
              ) : null
            }
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  render() {
    return (
      <Card>
        <Card.Body>
          {this.renderContent()}
        </Card.Body>
      </Card>
    );
  }
}