import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, InputGroup, Modal, Pagination, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Validator from 'validatorjs';
import axiosRequest from '../../../../util/helpers/axiosRequest';

import AdminSettingsModal from '../../../modals/AdminSettingsModal/AdminSettingsModal';
import PromptDeleteModal from '../../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class SchoolLevelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      schoolLevels: {
        data: []
      },
      formInputs: {
        title: '',
        theme: '',
        examPermit: false,
        classAdvisory: false,
        studentsAlphabetical: false,
        termGrades: false
      },
      isFormLoading: false,
      formError: '',
      configModal: {
        show: false
      },
      editModal: {
        show: false,
        data: {
          id: '',
          title: '',
          theme: '',
          examPermit: false,
          classAdvisory: false,
          studentsAlphabetical: false,
          termGrades: false
        },
        errorMessage: ''
      },
      deleteModal: {
        show: false,
        data: {
          id: '',
          title: '',
          theme: '',
          examPermit: false,
          classAdvisory: false,
          studentsAlphabetical: false,
          termGrades: false
        },
        errorMessage: ''
      },
      search: {
        isLoading: false,
        errorMessage: '',
        query: '',
        isSearch: false
      }
    };
  }
  componentDidMount() {
    axiosRequest('get', 'admin/settings/school-level', null, ({ data: { data } }) => {
      this.setState({
        ...this.state,
        schoolLevels: data,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showEditModal = (id) => {
    const schoolLevel = [...this.state.schoolLevels.data].find(p => p.id === id);
    this.setState({
      ...this.state,
      editModal: {
        show: true,
        data: {
          ...schoolLevel,
          examPermit: schoolLevel.exam_permit,
          classAdvisory: schoolLevel.class_advisory,
          studentsAlphabetical: schoolLevel.students_alphabetical,
          termGrades: schoolLevel.term_grades
        },
        errorMessage: ''
      }
    });
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        data: {
          id: '',
          title: '',
          theme: '',
          examPermit: false,
          classAdvisory: false,
          studentsAlphabetical: false,
          termGrades: false
        },
        errorMessage: ''
      }
    });
  }
  handleEditInputChange = event => {
    const { editModal } = this.state;
    editModal.data[event.target.name] = event.target.value;
    this.setState({
      ...this.state,
      editModal
    });
  }
  handleEditExamPermitChange = event => {
    const { editModal } = this.state;
    editModal.data.examPermit = event.target.checked;
    this.setState({
      ...this.state,
      editModal
    });
  }
  handleEditClassAdvisoryChange = event => {
    const { editModal } = this.state;
    editModal.data.classAdvisory = event.target.checked;
    this.setState({
      ...this.state,
      editModal
    });
  }
  handleEditStudentsAlphabeticalChange = event => {
    const { editModal } = this.state;
    editModal.data.studentsAlphabetical = event.target.checked;
    this.setState({
      ...this.state,
      editModal
    });
  }
  handleEditTermGradesChange = event => {
    const { editModal } = this.state;
    editModal.data.termGrades = event.target.checked;
    this.setState({
      ...this.state,
      editModal
    });
  }
  handleEdit = event => {
    event.preventDefault();
    const editModal = {...this.state.editModal};

    let rules = {
      id: 'required|integer',
      title: 'required|max:255',
      theme: 'present|regex:/^#([0-9A-F]{3}){1,2}$/i',
      examPermit: 'required|boolean',
      classAdvisory: 'required|boolean',
      studentsAlphabetical: 'required|boolean',
      termGrades: 'required|boolean'
    };
    
    let validation = new Validator(editModal.data, rules);

    if (validation.fails()) {
      const firstKey = Object.keys(validation.errors.errors)[0];
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          errorMessage: validation.errors.errors[firstKey][0]
        }
      });
      return;
    }

    axiosRequest('patch', `admin/settings/school-level/${editModal.data.id}`, {
      title: editModal.data.title,
      theme: editModal.data.theme,
      examPermit: editModal.data.examPermit,
      classAdvisory: editModal.data.classAdvisory,
      studentsAlphabetical: editModal.data.studentsAlphabetical,
      termGrades: editModal.data.termGrades
    }, ({ data: { data } }) => {
      const schoolLevels = [...this.state.schoolLevels.data].map((p) => {
        if (p.id === editModal.data.id) {
          return data;
        }
        
        return p;
      });
      this.setState({
        ...this.state,
        schoolLevels: {
          ...this.state.schoolLevels,
          data: [
            ...schoolLevels
          ]
        },
        editModal: {
          show: false,
          data: {
            id: '',
            title: '',
            theme: '',
            examPermit: false,
            classAdvisory: false,
            studentsAlphabetical: false,
            termGrades: false
          },
          errorMessage: ''
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  showDeleteModal = (id) => {
    const schoolLevel = [...this.state.schoolLevels.data].find(p => p.id === id);
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        data: {
          ...schoolLevel,
          examPermit: schoolLevel.exam_permit,
          classAdvisory: schoolLevel.class_advisory,
          studentsAlphabetical: schoolLevel.students_alphabetical,
          termGrades: schoolLevel.term_grades
        },
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        data: {
          id: '',
          title: '',
          theme: '',
          examPermit: false,
          classAdvisory: false,
          studentsAlphabetical: false,
          termGrades: false
        },
        errorMessage: ''
      }
    });
  }
  handleDelete = () => {
    const deleteModal = {...this.state.deleteModal};

    axiosRequest('delete', `admin/settings/school-level/${deleteModal.data.id}`, null, (res) => {
      const schoolLevels = [...this.state.schoolLevels.data].filter((p) => {
        return p.id !== deleteModal.data.id;
      });
      this.setState({
        ...this.state,
        schoolLevels: {
          ...this.state.schoolLevels,
          data: [
            ...schoolLevels
          ]
        },
        deleteModal: {
          show: false,
          data: {
            id: '',
            title: '',
            theme: '',
            examPermit: false,
            classAdvisory: false,
            studentsAlphabetical: false,
            termGrades: false
          },
          errorMessage: ''
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        deleteModal: {
          ...this.state.deleteModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleInputChange = e => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [e.target.name]: e.target.value
      }
    });
  }
  handleExamPermitChange = e => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        examPermit: e.target.checked
      }
    });
  }
  handleClassAdvisoryChange = e => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        classAdvisory: e.target.checked
      }
    });
  }
  handleStudentsAlphabeticalChange = e => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        studentsAlphabetical: e.target.checked
      }
    });
  }
  handleTermGradesChange = e => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        termGrades: e.target.checked
      }
    });
  }
  handleSubmit = event => {
    event.preventDefault();
    const { formInputs } = this.state;

    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: ''
    }, () => {
      let rules = {
        title: 'required|max:255',
        theme: 'present|regex:/^#([0-9A-F]{3}){1,2}$/i',
        examPermit: 'required|boolean',
        classAdvisory: 'required|boolean',
        studentsAlphabetical: 'required|boolean',
        termGrades: 'required|boolean'
      };
  
      let validation = new Validator(formInputs, rules);
  
      if (validation.fails()) {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: validation.errors.errors['title'][0]
        });
        return;
      }
  
      axiosRequest('post', 'admin/settings/school-level', formInputs, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          schoolLevels: {
            ...this.state.schoolLevels,
            data: [
              ...this.state.schoolLevels.data,
              {...data}
            ]
          },
          formInputs: {
            title: '',
            theme: '',
            examPermit: false,
            classAdvisory: false,
            studentsAlphabetical: false,
            termGrades: false
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showConfigModal = () => {
    this.setState({
      ...this.state,
      configModal: {
        show: true
      }
    });
  }
  hideConfigModal = () => {
    this.setState({
      ...this.state,
      configModal: {
        show: false
      }
    });
  }
  handlePagination = url => {
    if (url) {
      this.setState({
        ...this.state,
        isLoading: true
      }, () => {
        const path = url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
        
        axiosRequest('get', path, null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            schoolLevels: data,
            isLoading: false,
            errorMessage: ''
          });
        }, (error) => {
          this.setState({
            ...this.state,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    }
  }
  handleSearchInputChange = event => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        query: event.target.value
      }
    });
  }
  handleSearch = event => {
    event.preventDefault();

    const { search } = this.state;

    if (!search.query && !search.isSearch) {
      return;
    }

    this.setState({
      ...this.state,
      search: {
        ...search,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {

      axiosRequest('get', `admin/settings/school-level/search/${search.query}`, null, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          schoolLevels: data,
          search: {
            ...search,
            isLoading: false,
            errorMessage: '',
            isSearch: !!search.query
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          search: {
            ...search,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  renderTable = () => {
    const { schoolLevels, isLoading, errorMessage, search } = this.state;
    
    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        {
          search.errorMessage && (
            <Alert variant='danger'>
              {search.errorMessage}
            </Alert>
          )
        }
        <Row>
          <Col lg={6} className='ml-auto'>
            <div className='mb-2 mt-1'>
              <Form onSubmit={this.handleSearch}>
                <InputGroup>
                  <Form.Control type='search' placeholder='Search school levels...' value={search.query} onChange={this.handleSearchInputChange} />
                  <InputGroup.Append>
                    <Button variant='primary' type='submit' disabled={search.isLoading}>
                      {
                        search.isLoading ? (
                          <LoadingIcon />
                        ) : (
                          <FontAwesomeIcon icon='search' />
                        )
                      }
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </div>
          </Col>
        </Row>
        {
          schoolLevels.data.length > 0 ? (
            <>
              <Table striped bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Theme</th>
                    <th>Configuration</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    schoolLevels.data.map(schoolLevel => (
                      <tr key={schoolLevel.id}>
                        <td>{schoolLevel.id}</td>
                        <td>{schoolLevel.title}</td>
                        <td>
                          {
                            schoolLevel.theme ? (
                              <>
                                <span className='border rounded bg-white'>
                                  <FontAwesomeIcon fixedWidth icon='square' style={{ color: schoolLevel.theme }} fontSize='1.5rem' size='sm' />
                                </span> {schoolLevel.theme}
                              </>
                            ) : (
                              <div className='text-muted'>
                                (None)
                              </div>
                            )
                          }
                        </td>
                        <td>
                          <span className='mr-2'>
                            {
                              !!schoolLevel.exam_permit + 
                              !!schoolLevel.class_advisory +
                              !!schoolLevel.students_alphabetical +
                              !!schoolLevel.term_grades
                            } <FontAwesomeIcon icon='check' className='text-green' />
                          </span>
                          <span>
                            {
                              !schoolLevel.exam_permit + 
                              !schoolLevel.class_advisory +
                              !schoolLevel.students_alphabetical +
                              !schoolLevel.term_grades
                            } <FontAwesomeIcon icon='times' className='text-danger' />
                          </span>
                        </td>
                        <td className='text-center'>
                          <Button
                            variant='primary'
                            size='sm'
                            title='Edit'
                            className='mx-1'
                            onClick={(e) => {this.showEditModal(schoolLevel.id)}}>
                            <FontAwesomeIcon icon='pencil-alt' />
                          </Button>
                          <Button variant='danger' size='sm' title='Delete' className='mx-1' onClick={(e) => {this.showDeleteModal(schoolLevel.id)}}>
                            <FontAwesomeIcon icon='trash-alt' />
                          </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <Pagination className='justify-content-end'>
                <Pagination.First onClick={() => this.handlePagination(schoolLevels.first_page_url)} disabled={schoolLevels.current_page === 1} />
                <Pagination.Prev onClick={() => this.handlePagination(schoolLevels.prev_page_url)} disabled={schoolLevels.current_page === 1} />
                <Pagination.Next onClick={() => this.handlePagination(schoolLevels.next_page_url)} disabled={schoolLevels.current_page === schoolLevels.last_page} />
                <Pagination.Last onClick={() => this.handlePagination(schoolLevels.last_page_url)} disabled={schoolLevels.current_page === schoolLevels.last_page} />
              </Pagination>
            </>
          ) : (
            <Alert variant='light'>
              Nothing to show
            </Alert>
          )
        }
      </>
    );
  }
  render() {
    const { formInputs, isFormLoading, formError, editModal, deleteModal, configModal } = this.state;
    return (
      <Card>
        <Card.Body>
          {
            !!formError && (
              <Alert variant='danger'>{formError}</Alert>
            )
          }
          <Form onSubmit={this.handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} lg={3}>
                <Form.Label>School Level Title</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='e.g. Senior High School'
                  name='title'
                  value={formInputs.title}
                  onChange={this.handleInputChange}
                  disabled={isFormLoading} />
              </Form.Group>
              <Form.Group as={Col} lg={3}>
                <Form.Label>School Level Theme</Form.Label>
                <Form.Control
                  type='color'
                  name='theme'
                  value={formInputs.theme}
                  onChange={this.handleInputChange}
                  disabled={isFormLoading} />
              </Form.Group>
              <Form.Group as={Col} lg={3} className='d-flex align-items-end'>
                <Button variant='outline-green' onClick={this.showConfigModal} block>
                  Configuration...
                </Button>
              </Form.Group>
              <Form.Group as={Col} lg={3} className='d-flex align-items-end'>
                <Button type='submit' variant='green' disabled={isFormLoading} block>Add school level</Button>
              </Form.Group>
            </Form.Row>
            <Modal show={configModal.show} onHide={this.hideConfigModal}>
              <Modal.Header closeButton>
                <Modal.Title>Configuration</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                    <Form.Check id='examPermit' type='switch' checked={formInputs.examPermit} onChange={this.handleExamPermitChange} label='Exam Permit' disabled={isFormLoading} />
                    <Form.Text className='text-muted'>
                      <FontAwesomeIcon icon='info-circle' /> Students are not allowed to take the exam without an exam permit.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Check id='classAdvisory' type='switch' checked={formInputs.classAdvisory} onChange={this.handleClassAdvisoryChange} label='Class Advisory' disabled={isFormLoading} />
                    <Form.Text className='text-muted'>
                      <FontAwesomeIcon icon='info-circle' /> Classes are managed by an adviser.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Check id='studentsAlphabetical' type='switch' checked={formInputs.studentsAlphabetical} onChange={this.handleStudentsAlphabeticalChange} label='Students Alphabetical' disabled={isFormLoading} />
                    <Form.Text className='text-muted'>
                      <FontAwesomeIcon icon='info-circle' /> Students are listed alphabetically regardless of gender.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Check id='termGrades' type='switch' checked={formInputs.termGrades} onChange={this.handleTermGradesChange} label='Term Grades' disabled={isFormLoading} />
                    <Form.Text className='text-muted'>
                      <FontAwesomeIcon icon='info-circle' /> Students' term grades are managed or controlled.
                    </Form.Text>
                  </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='light' onClick={this.hideConfigModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
          <div className='dropdown-divider'></div>
          {this.renderTable()}
          <AdminSettingsModal
            {...editModal}
            title='Edit school level'
            onHide={this.hideEditModal}
            onSubmit={this.handleEdit}>
            <Form.Group>
              <Form.Label>School Level Title</Form.Label>
              <Form.Control
                type='text'
                placeholder='e.g. Senior High School'
                name='title'
                value={editModal.data.title}
                onChange={this.handleEditInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>School Level Theme</Form.Label>
              <Form.Control
                type='color'
                name='theme'
                value={editModal.data.theme}
                onChange={this.handleEditInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Check
                id='examPermitEdit'
                type='switch'
                label='Exam Permit'
                checked={editModal.data.examPermit}
                onChange={this.handleEditExamPermitChange} />
              <Form.Text className='text-muted'>
                <FontAwesomeIcon icon='info-circle' /> Students are not allowed to take the exam without an exam permit.
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Check
                id='classAdvisoryEdit'
                type='switch'
                label='Class Advisory'
                checked={editModal.data.classAdvisory}
                onChange={this.handleEditClassAdvisoryChange} />
              <Form.Text className='text-muted'>
                <FontAwesomeIcon icon='info-circle' /> Classes are managed by an adviser.
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Check
                id='studentsAlphabeticalEdit'
                type='switch'
                label='Students Alphabetical'
                checked={editModal.data.studentsAlphabetical}
                onChange={this.handleEditStudentsAlphabeticalChange} />
              <Form.Text className='text-muted'>
                <FontAwesomeIcon icon='info-circle' /> Students are listed alphabetically regardless of gender.
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Check
                id='termGradesEdit'
                type='switch'
                label='Term Grades'
                checked={editModal.data.termGrades}
                onChange={this.handleEditTermGradesChange} />
              <Form.Text className='text-muted'>
                <FontAwesomeIcon icon='info-circle' /> Students' term grades are managed or controlled.
              </Form.Text>
            </Form.Group>
          </AdminSettingsModal>
          <PromptDeleteModal
            {...deleteModal}
            title='Delete school level'
            onHide={this.hideDeleteModal}
            onDelete={this.handleDelete}>
            <Card.Subtitle>Are you sure you want to delete the school level?</Card.Subtitle>
            <Alert variant='light'>
              <div>{deleteModal.data.title}</div>
              <div>{deleteModal.data.theme}</div>
              <div>
                {
                  deleteModal.data.examPermit ? 'Exam Permit Enabled' : 'Exam Permit Disabled'
                }
              </div>
              <div>
                {
                  deleteModal.data.classAdvisory ? 'Class Advisory Enabled' : 'Class Advisory Disabled'
                }
              </div>
              <div>
                {
                  deleteModal.data.studentsAlphabetical ? 'Students Alphabetical Enabled' : 'Students Alphabetical Disabled'
                }
              </div>
              <div>
                {
                  deleteModal.data.termGrades ? 'Term Grades Enabled' : 'Term Grades Disabled'
                }
              </div>
            </Alert>
          </PromptDeleteModal>
        </Card.Body>
      </Card>
    );
  }
}