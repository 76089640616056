import React, { Component } from 'react';
import { Alert, Button, Modal, Table } from 'react-bootstrap';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NameLink from '../../../common/NameLink/NameLink';
import { Form } from 'react-bootstrap';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Students extends Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      platformModal: {
        show: false,
        profile: null,
        platform: '',
        isLoading: false,
        errorMessage: ''
      },
      studentType: 'regular',
      isLoading: true,
      errorMessage: ''
    };
  }
  componentDidMount() {
    const { classID, userType } = this.props.match.params;
    axiosRequest('get', `${userType}/class/${classID}/students`, null, ({ data: { data } }) => {
      this.setState({
        ...this.state,
        students: data,
        isLoading: false,
        errorMessage: ''
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showPlatformModal = profile => {
    this.setState({
      ...this.state,
      platformModal: {
        show: true,
        profile,
        platform: profile.student_learning_platform ? profile.student_learning_platform.platform.toLowerCase() : '',
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hidePlatformModal = () => {
    this.setState({
      ...this.state,
      platformModal: {
        show: false,
        profile: null,
        platform: '',
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleInputChange = e => {
    this.setState({
      ...this.state,
      platformModal: {
        ...this.state.platformModal,
        platform: e.target.value
      }
    });
  }
  handlePlatformSave = e => {
    e.preventDefault();

    this.setState({
      ...this.state,
      platformModal: {
        ...this.state.platformModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { classID } = this.props.match.params;
      const { platformModal: { profile, platform } } = this.state;

      axiosRequest('post', `faculty/class/${classID}/students/${profile.id}`, {
        platformType: platform
      }, ({ data: { data }}) => {
        const { students } = this.state;
        const newStudents = [...students].map(student => {
          if (student.id === profile.id) {
            return {
              ...student,
              student_learning_platform: data
            };
          }

          return student;
        });

        this.setState({
          ...this.state,
          students: newStudents,
          platformModal: {
            show: false,
            profile: null,
            platform: '',
            isLoading: false,
            errorMessage: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          platformModal: {
            ...this.state.platformModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  renderStudents = () => {
    const { students } = this.state;
    const { classInfo, match: { params: { userType } } } = this.props;

    if (students.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let male = students.filter(s => s.gender === 'Male');
    let female = students.filter(s => s.gender === 'Female');
    if (classInfo.class_course.school_class.year_level.school_level.students_alphabetical) {
      return (
        <Table hover size='sm'>
          <thead>
            <tr className='text-green'>
              <th className='text-center' colspan='2'>
                <div>
                  {students.length} student{students.length === 1 ? '' : 's'}
                </div>
                <div className='small font-italic font-weight-normal d-flex'>
                  <div className='flex-fill'>
                    {male.length} male student{male.length === 1 ? '' : 's'}
                  </div>
                  <div className='flex-fill'>
                    {female.length} female student{female.length === 1 ? '' : 's'}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              students.map(student => (
                <tr key={student.id}>
                  <td className='w-100'>
                    <NameLink
                      id={student.id}
                      name={student.formal_name}
                      image={student.image}
                      learningPlatform={student.student_learning_platform ? student.student_learning_platform.platform : null}
                      rank={student.rank} />
                  </td>
                  {
                    userType === 'faculty' && (
                      <td className='align-middle'>
                        <Button variant='info' size='sm' title='Set learning platform' onClick={e => this.showPlatformModal(student)}>
                          <FontAwesomeIcon icon='pencil-alt' />
                        </Button>
                      </td>
                    )
                  }
                </tr>
              ))
            }
          </tbody>
        </Table>
      );
    }

    return (
      <>
        {
          male.length > 0 && (
            <Table hover size='sm'>
              <thead>
                <tr className='text-green'>
                  <th className='text-center' colspan='2'>
                    <div>Male</div>
                    <div className='small font-italic font-weight-normal'>{male.length} student{male.length === 1 ? '' : 's'}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  male.map(student => (
                    <tr key={student.id}>
                      <td className='w-100'>
                        <NameLink
                          id={student.id}
                          name={student.formal_name}
                          image={student.image}
                          learningPlatform={student.student_learning_platform ? student.student_learning_platform.platform : null}
                          rank={student.rank} />
                      </td>
                      {
                        userType === 'faculty' && (
                          <td className='align-middle'>
                            <Button variant='info' size='sm' title='Set learning platform' onClick={e => this.showPlatformModal(student)}>
                              <FontAwesomeIcon icon='pencil-alt' />
                            </Button>
                          </td>
                        )
                      }
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          )
        }
        {
          female.length > 0 && (
            <Table hover size='sm'>
              <thead>
                <tr className='text-green'>
                  <th className='text-center' colspan='2'>
                    <div>Female</div>
                    <div className='small font-italic font-weight-normal'>{female.length} student{female.length === 1 ? '' : 's'}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  female.map(student => (
                    <tr key={student.id}>
                      <td className='w-100'>
                        <NameLink
                          id={student.id}
                          name={student.formal_name}
                          image={student.image}
                          learningPlatform={student.student_learning_platform ? student.student_learning_platform.platform : null}
                          rank={student.rank} />
                      </td>
                      {
                        userType === 'faculty' && (
                          <td className='align-middle'>
                            <Button variant='info' size='sm' title='Set learning platform' onClick={e => this.showPlatformModal(student)}>
                              <FontAwesomeIcon icon='pencil-alt' />
                            </Button>
                          </td>
                        )
                      }
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          )
        }
      </>
    );
  }
  render() {
    const { isLoading, errorMessage, platformModal } = this.state;
    const { userType } = this.props.match.params;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }


    return (
      <>
        {this.renderStudents()}
        {
          userType === 'faculty' && (
            <Modal show={platformModal.show} onHide={this.hidePlatformModal}>
              <Modal.Header closeButton>
                <Modal.Title>{platformModal.profile ? platformModal.profile.name : 'Set Learning Platform'}</Modal.Title>
              </Modal.Header>
              <Form onSubmit={this.handlePlatformSave}>
                <Modal.Body>
                  {
                    platformModal.errorMessage && (
                      <Alert variant='danger'>
                        {platformModal.errorMessage}
                      </Alert>
                    )
                  }
                  <Form.Group>
                    <Form.Label>Learning Platform</Form.Label>
                    <Form.Control as='select' value={platformModal.platform} onChange={this.handleInputChange}>
                      <option value='' hidden selected>Select learning platform...</option>
                      <option value='digital'>Digital</option>
                      <option value='digital-modular'>Digital-Modular</option>
                      <option value='modular-printed'>Modular-Printed</option>
                    </Form.Control>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='danger' className='ml-2' onClick={this.hidePlatformModal} disabled={platformModal.isLoading}>
                    Cancel
                  </Button>
                  <Button type='submit' variant='green' disabled={platformModal.isLoading}>
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          )
        }
      </>
    );
  }
}