import axios from 'axios';

export default function AxiosRequest(method, path, data = null, successCb = () => {}, errorCb = () => {}, history, config = {}) {

  if (data !== null) {
    axios[method](`${process.env['REACT_APP_API_BASE_URL']}/${path}`, data, {
      withCredentials: true,
      ...config
    }).then(successCb).catch((error) => {
      if (error.response && error.response.status === 403) {
        history.push('/login');
      } else {
        errorCb(error);
      }
    });
    return;
  }
  
  axios[method](`${process.env['REACT_APP_API_BASE_URL']}/${path}`, {
    withCredentials: true,
    ...config
  }).then(successCb).catch((error) => {
    if (error.response && error.response.status === 403) {
      history.push('/login');
    } else {
      errorCb(error);
    }
  });
}