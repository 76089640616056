export default function durationFormat(duration) {
  let seconds = duration.asSeconds();
  let text = '';

  if (seconds/31536000 > 1) {
    text += duration.years()+'y ';
  }

  if (seconds/2678400 > 1) {
    text += duration.months()+'mo ';
  }

  if (seconds/86400 > 1) {
    text += duration.days()+'d ';
  }

  if (seconds/3600 > 1) {
    text += duration.hours()+'h ';
  }

  if (seconds/60 > 1) {
    text += duration.minutes()+'m ';
  }

  if (seconds > 1) {
    text += duration.seconds()+'s';
  }

  return text;
}