import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';
import moment from 'moment';

export default class ExportAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: 'all',
      range: {
        from: '',
        until: ''
      },
      date: ''
    };
  }
  componentDidMount() {
    this.setState({
      ...this.state,
      range: {
        from: '',
        until: moment().format('YYYY-MM-DD')
      },
      date: moment().format('YYYY-MM-DD')
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({
        ...this.state,
        option: 'all',
        range: {
          from: '',
          until: moment().format('YYYY-MM-DD')
        },
        date: moment().format('YYYY-MM-DD')
      });
    }
  }
  handleExportOptions = event => {
    if (event.target.checked) {
      this.setState({
        ...this.state,
        option: event.target.value
      });
    }
  }
  handleRangeInputChange = event => {
    this.setState({
      ...this.state,
      range: {
        ...this.state.range,
        [event.target.name]: event.target.value
      }
    });
  }
  handleDateInputChange = event => {
    this.setState({
      ...this.state,
      date: event.target.value
    });
  }
  renderExportOptions = () => {
    const { option, range, date } = this.state;
    const { classID: insideClassID } = this.props.match.params;
    const { classID: outsideClassID } = this.props;
    const classID = outsideClassID ? outsideClassID : insideClassID;

    switch (option) {
      case 'range':
        return (
          <>
            <div className='px-3 mt-3'>
              <Form.Group>
                <Form.Label>From</Form.Label>
                <Form.Control type='date' name='from' value={range.from} onChange={this.handleRangeInputChange} max={moment(range.until).subtract(1, 'days').format('YYYY-MM-DD')} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Until</Form.Label>
                <Form.Control type='date' name='until' value={range.until} onChange={this.handleRangeInputChange} min={moment(range.from).add(1, 'days').format('YYYY-MM-DD')} />
              </Form.Group>
            </div>
            <div className='text-right mt-3'>
              <a href={`${process.env['REACT_APP_API_BASE_URL']}/staff/class/${classID}/export/range/${range.from}/${range.until}`} className={`btn btn-green ${range.from && range.until ? '' : 'disabled'}`}>
                Export
              </a>
            </div>
          </>
        );
      case 'date':
        return (
          <>
            <div className='px-3 mt-3'>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control type='date' onChange={this.handleDateInputChange} value={date} max={moment().format('YYYY-MM-DD')} />
              </Form.Group>
            </div>
            <div className='text-right mt-3'>
              <a href={`${process.env['REACT_APP_API_BASE_URL']}/staff/class/${classID}/export/date/${date}`} className={`btn btn-green ${date ? '' : 'disabled'}`}>
                Export
              </a>
            </div>
          </>
        );
      default:
        return (
          <div className='text-right mt-3'>
            <a href={`${process.env['REACT_APP_API_BASE_URL']}/staff/class/${classID}/export`} className='btn btn-green'>
              Export
            </a>
          </div>
        );
    }
  }
  render() {
    const { option } = this.state;

    return (
      <Modal {...this.props}>
        <Modal.Header closeButton>
          <Modal.Title>Export Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-inline px-3'>
            <Form.Group controlId='eaAll'>
              <Form.Check type='radio' name='loginCredentials' label='All' value='all' onChange={this.handleExportOptions} checked={option === 'all'} />
            </Form.Group>
            <Form.Group controlId='eaRange' className='ml-2'>
              <Form.Check type='radio' name='loginCredentials' label='Range' value='range' onChange={this.handleExportOptions} checked={option === 'range'} />
            </Form.Group>
            <Form.Group controlId='eaDate' className='ml-2'>
              <Form.Check type='radio' name='loginCredentials' label='Date' value='date' onChange={this.handleExportOptions} checked={option === 'date'} />
            </Form.Group>
          </div>
          {this.renderExportOptions()}
        </Modal.Body>
      </Modal>
    );
  }
}

ExportAttendance.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  classID: PropTypes.number
};

ExportAttendance.defaultProps = {
  show: false,
  onHide: () => {}
};