import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, Image, ListGroup, Modal, Nav, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../util/helpers/axiosRequest';
import NameLink from '../../common/NameLink/NameLink';
import moment from 'moment';
import Validator from 'validatorjs';
import transparency from '../../../resources/image/transparency.png';
import './style.scss';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

export default class ApprovalRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      activeKey: 'requests',
      requests: {
        data: []
      },
      approved: {
        data: []
      },
      declined: {
        data: []
      },
      approveModal: {
        show: false,
        data: null,
        isLoading: false,
        errorMessage: ''
      },
      declineModal: {
        show: false,
        comment: '',
        data: null,
        isLoading: false,
        errorMessage: ''
      },
      isNextPageLoading: false,
      nextPageError: ''
    };
  }
  componentDidMount() {
    this.fetchList();
  }
  fetchList = () => {
    const { activeKey } = this.state;
    
    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: ''
    }, () => {
      axiosRequest('get', `admin/approval-requests/${activeKey}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isLoading: false,
          [activeKey]: data
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleNavSelect = activeKey => {
    this.setState({
      ...this.state,
      activeKey
    }, () => {
      let list = this.state[activeKey];

      if (!list.current_page) {
        this.fetchList();
      }
    });
  }
  showApproveModal = data => {
    this.setState({
      ...this.state,
      approveModal: {
        ...this.state.approveModal,
        show: true,
        data
      }
    });
  }
  hideApproveModal = () => {
    this.setState({
      ...this.state,
      approveModal: {
        ...this.state.approveModal,
        show: false,
        errorMessage: '',
        data: null
      }
    });
  }
  handleApprove = () => {
    const { approveModal } = this.state;

    if (!approveModal.data) {
      return;
    }

    this.setState({
      ...this.state,
      approveModal: {
        ...this.state.approveModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      axiosRequest('post', 'admin/approval-requests/approve', {
        requestID: approveModal.data.id
      }, ({ data: { data }}) => {
        const newRequestsData = [...this.state.requests.data].filter(req => req.id !== data.id);

        this.setState({
          ...this.state,
          approved: {
            ...this.state.approved,
            data: [
              data,
              ...this.state.approved.data
            ]
          },
          requests: {
            ...this.state.requests,
            data: newRequestsData
          },
          approveModal: {
            ...this.state.approveModal,
            show: false,
            isLoading: false,
            errorMessage: '',
            data: null
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          approveModal: {
            ...this.state.approveModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showDeclineModal = data => {
    this.setState({
      ...this.state,
      declineModal: {
        ...this.state.declineModal,
        show: true,
        data
      }
    });
  }
  hideDeclineModal = () => {
    this.setState({
      ...this.state,
      declineModal: {
        ...this.state.declineModal,
        show: false,
        errorMessage: '',
        comment: '',
        data: null
      }
    });
  }
  handleCommentInputChange = e => {
    this.setState({
      ...this.state,
      declineModal: {
        ...this.state.declineModal,
        comment: e.target.value
      }
    });
  }
  handleDecline = () => {
    const { declineModal } = this.state;

    if (!declineModal.data) {
      return;
    }

    this.setState({
      ...this.state,
      declineModal: {
        ...this.state.declineModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      let validator = new Validator({
        comment: declineModal.comment
      }, {
        comment: 'required|string|min:2'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          declineModal: {
            ...this.state.declineModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', 'admin/approval-requests/decline', {
        requestID: declineModal.data.id,
        comment: declineModal.comment
      }, ({ data: { data }}) => {
        const newRequestsData = [...this.state.requests.data].filter(req => req.id !== data.id);

        this.setState({
          ...this.state,
          declined: {
            ...this.state.declined,
            data: [
              data,
              ...this.state.declined.data
            ]
          },
          requests: {
            ...this.state.requests,
            data: newRequestsData
          },
          declineModal: {
            ...this.state.declineModal,
            show: false,
            isLoading: false,
            errorMessage: '',
            comment: '',
            data: null
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          declineModal: {
            ...this.state.declineModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleNextPage = () => {
    const { activeKey } = this.state;

    let list = this.state[activeKey];

    if (!list.next_page_url) {
      return;
    }

    this.setState({
      ...this.state,
      isNextPageLoading: true,
      nextPageError: ''
    }, () => {

      const path = list.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          [activeKey]: {
            ...data,
            data: [
              ...list.data,
              ...data.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, error => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  renderContent = () => {
    const { isLoading, errorMessage, activeKey, isNextPageLoading, nextPageError } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }
    
    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    let list = this.state[activeKey];

    return (
      <>
        <div className='text-right mb-3'>
          <Button variant='info' onClick={this.fetchList} size='sm' disabled={isNextPageLoading}>
            <FontAwesomeIcon icon='redo' /> Refresh
          </Button>
        </div>
        {
          list.data.length === 0 ? (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          ) : (
            <ListGroup>
              {
                list.data.map(req => (
                  <ListGroup.Item key={req.id}>
                    <div className='d-flex'>
                      <div className='flex-fill d-flex'>
                        <NameLink {...req.profile} nameStyle={{ fontSize: '1.2rem', fontWeight: '500'}} />
                      </div>
                      <div className='align-self-end font-italic text-muted'>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              {moment(req.created_at).format('MMMM D, YYYY hh:mm A')}
                            </Tooltip>
                          }
                          trigger={['hover', 'focus']}>
                          <FontAwesomeIcon icon='clock' />
                        </OverlayTrigger> {moment(req.created_at).fromNow()}
                      </div>
                    </div>
                    <div className='dropdown-divider'></div>
                    {
                      req.type === 'Signature' ? (
                        <div>
                          <div className='h5'>
                            <FontAwesomeIcon icon='signature' /> Signature
                          </div>
                          {
                            req.previous_signature ? (
                              <Row>
                                <Col md>
                                  <div style={{ height: '7.5rem', textAlign: 'center' }}>
                                    <a href={req.previous_signature}>
                                      <Image
                                        className='signature-image'
                                        src={req.previous_signature}
                                        style={{
                                          objectFit: 'contain',
                                          height: '100%',
                                          backgroundImage: `url(${transparency})`,
                                          backgroundSize: 'contain'
                                        }}
                                        fluid
                                        thumbnail />
                                    </a>
                                  </div>
                                </Col>
                                <Col className='text-center' md={2}>
                                  <span className='d-md-inline-block d-none' style={{ lineHeight: '7.5rem', fontSize: '2rem' }}>
                                    <FontAwesomeIcon icon='long-arrow-alt-right' />
                                  </span>
                                  <span className='d-md-none' style={{ lineHeight: '5rem', fontSize: '2rem' }}>
                                    <FontAwesomeIcon icon='long-arrow-alt-down' />
                                  </span>
                                </Col>
                                <Col md>
                                  <div style={{ height: '7.5rem', textAlign: 'center' }}>
                                    <a href={req.signature}>
                                    <Image
                                      className='signature-image'
                                      src={req.signature}
                                      style={{
                                        objectFit: 'contain',
                                        height: '100%',
                                        backgroundImage: `url(${transparency})`,
                                        backgroundSize: 'contain'
                                      }}
                                      fluid
                                      thumbnail />
                                    </a>
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              <div style={{ height: '7.5rem', textAlign: 'center' }}>
                                <a href={req.signature}>
                                  <Image
                                    className='signature-image'
                                    src={req.signature}
                                    style={{
                                      objectFit: 'contain',
                                      height: '100%',
                                      backgroundImage: `url(${transparency})`,
                                      backgroundSize: 'contain'
                                    }}
                                    fluid
                                    thumbnail />
                                </a>
                              </div>
                            )
                          }
                        </div>
                      ) : req.type === 'Emergency Contact' ? (
                        <div>
                          <div className='h5'>
                            <FontAwesomeIcon icon='id-card' /> Emergency Contact
                          </div>
                          {
                            req.profile.emergency_contact ? (
                              <Row>
                                <Col md>
                                  <div className='p-3 rounded border'>
                                    <div>{`${req.profile.emergency_contact.title} ${req.profile.emergency_contact.name}`}</div>
                                    <div>{req.profile.emergency_contact.address}</div>
                                    <div>{req.profile.emergency_contact.phone}</div>
                                  </div>
                                </Col>
                                <Col className='text-center' md={2}>
                                  <div className='h-100 d-flex justify-content-center align-items-center'>
                                    <div className='d-md-inline-block d-none' style={{ fontSize: '2rem' }}>
                                      <FontAwesomeIcon icon='long-arrow-alt-right' />
                                    </div>
                                    <div className='d-md-none my-3' style={{ fontSize: '2rem' }}>
                                      <FontAwesomeIcon icon='long-arrow-alt-down' />
                                    </div>
                                  </div>
                                </Col>
                                <Col md>
                                  <div className='p-3 rounded border'>
                                    <div>{`${req.data.title} ${req.data.name}`}</div>
                                    <div>{req.data.address}</div>
                                    <div>{req.data.phone}</div>
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              <div className='d-flex'>
                                <div className='p-3 rounded border'>
                                  <div>{`${req.data.title} ${req.data.name}`}</div>
                                  <div>{req.data.address}</div>
                                  <div>{req.data.phone}</div>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      ) : null
                    }
                    <div className='dropdown-divider'></div>
                    {
                      activeKey === 'approved' ? (
                        <Alert variant='green'>
                          Request was approved on <b>{moment(req.date_approved).format('MMMM D, YYYY hh:mm A')}</b>
                        </Alert>
                      ) : activeKey === 'declined' ? (
                        <Alert variant='danger'>
                          Request was declined on <b>{moment(req.date_declined).format('MMMM D, YYYY hh:mm A')}</b>
                          {
                            req.comment && (
                              <div className='d-flex'>
                                <div>
                                  <FontAwesomeIcon icon='comment' className='fa-fw mr-1' />
                                </div>
                                <div className='font-italic'>
                                  {req.comment}
                                </div>
                              </div>
                            )
                          }
                        </Alert>
                      ) : (
                        <>
                          <div className='text-right d-none d-md-block'>
                            <Button variant='danger' size='sm' onClick={e => this.showDeclineModal(req)} disabled={isNextPageLoading}>
                              <FontAwesomeIcon icon='times' className='fa-fw' /> Decline
                            </Button>
                            <Button variant='green' className='ml-2' size='sm' onClick={e => this.showApproveModal(req)} disabled={isNextPageLoading}>
                              <FontAwesomeIcon icon='check' className='fa-fw' /> Approve
                            </Button>
                          </div>
                          <div className='d-flex d-md-none'>
                            <div className='flex-fill'>
                              <Button variant='danger' block onClick={e => this.showDeclineModal(req)} disabled={isNextPageLoading}>
                                <FontAwesomeIcon icon='times' className='fa-fw' />
                              </Button>
                            </div>
                            <div className='flex-fill'>
                              <Button variant='green' className='ml-2' block onClick={e => this.showApproveModal(req)} disabled={isNextPageLoading}>
                                <FontAwesomeIcon icon='check' className='fa-fw' />
                              </Button>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </ListGroup.Item>
                ))
              }
              {
                list.next_page_url && (
                  <ListGroup.Item>
                    {
                      nextPageError && (
                        <Alert variant='danger'>
                          {nextPageError}
                        </Alert>
                      )
                    }
                    <Button variant='link' onClick={this.handleNextPage} block disabled={isNextPageLoading}>
                      <span>
                        {
                          isNextPageLoading && (
                            <LoadingIcon />
                          )
                        }
                      </span>
                      <span className='ml-1'>Show more...</span>
                    </Button>
                  </ListGroup.Item>
                )
              }
            </ListGroup>
          )
        }
      </>
    );
  }
  render() {
    const { isLoading, activeKey, approveModal, declineModal, isNextPageLoading } = this.state;

    return (
      <Card>
        <Card.Header>
          <Nav variant='tabs' className='flex-sm-fill' activeKey={activeKey} onSelect={this.handleNavSelect}>
            <Nav.Item>
              <Nav.Link eventKey='requests' disabled={isNextPageLoading || isLoading}>
                Requests
                {/* <span className='fa-layers ml-2'>
                  <FontAwesomeIcon icon='circle' transform='grow-5' className='text-muted' />
                  <span className='fa-layers-text fa-inverse' style={{ transform: 'scale(.75,.75) translate(-12px,-15px)' }}></span>
                </span> */}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='approved' disabled={isNextPageLoading || isLoading}>
                Approved
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='declined' disabled={isNextPageLoading || isLoading}>
                Declined
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body>
          {this.renderContent()}
          <Modal show={approveModal.show} onHide={this.hideApproveModal}>
            <Modal.Header closeButton>
              <Modal.Title>Approve Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                approveModal.errorMessage ? (
                  <Alert variant='danger'>
                    {approveModal.errorMessage}
                  </Alert>
                ) : (
                  <Alert variant='warning'>
                    Proceeding will <b>permanently</b> change the data.
                    Are you sure you want to <b>approve the request</b>?
                  </Alert>
                )
              }
              {
                approveModal.data && (
                  <>
                    <div className='mb-3'>
                      <NameLink {...approveModal.data.profile} nameStyle={{ fontWeight: 'bold' }} disableLink />
                    </div>
                    {
                      approveModal.data.type === 'Signature' ? (
                        <div style={{ height: '7.5rem', textAlign: 'center' }}>
                          <Image
                            className='signature-image'
                            src={approveModal.data.signature}
                            style={{
                              objectFit: 'contain',
                              height: '100%',
                              backgroundImage: `url(${transparency})`,
                              backgroundSize: 'contain'
                            }}
                            fluid
                            thumbnail />
                        </div>
                      ) : approveModal.data.type === 'Emergency Contact' ? (
                        <div className='d-flex justify-content-center align-items-center'>
                          <div className='p-3 rounded border'>
                            <div>{`${approveModal.data.data.title} ${approveModal.data.data.name}`}</div>
                            <div>{approveModal.data.data.address}</div>
                            <div>{approveModal.data.data.phone}</div>
                          </div>
                        </div>
                      ) : null
                    }
                  </>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant='light' onClick={this.hideApproveModal}>
                Close
              </Button>
              <Button variant='green' disabled={approveModal.isLoading} onClick={this.handleApprove}>
                Approve
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={declineModal.show} onHide={this.hideDeclineModal}>
            <Modal.Header closeButton>
              <Modal.Title>Decline Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                declineModal.errorMessage ? (
                  <Alert variant='danger'>
                    {declineModal.errorMessage}
                  </Alert>
                ) : (
                  <Alert variant='warning'>
                    Are you sure you want to <b>decline the request</b>?
                  </Alert>
                )
              }
              {
                declineModal.data && (
                  <>
                    <div className='mb-3'>
                      <NameLink {...declineModal.data.profile} nameStyle={{ fontWeight: 'bold' }} disableLink />
                    </div>
                    {
                      declineModal.data.type === 'Signature' ? (
                        <div style={{ height: '7.5rem', textAlign: 'center' }}>
                          <Image
                            className='signature-image'
                            src={declineModal.data.signature}
                            style={{
                              objectFit: 'contain',
                              height: '100%',
                              backgroundImage: `url(${transparency})`,
                              backgroundSize: 'contain'
                            }}
                            fluid
                            thumbnail />
                        </div>
                      ) : declineModal.data.type === 'Emergency Contact' ? (
                        <div className='d-flex justify-content-center align-items-center'>
                          <div className='p-3 rounded border'>
                            <div>{`${declineModal.data.data.title} ${declineModal.data.data.name}`}</div>
                            <div>{declineModal.data.data.address}</div>
                            <div>{declineModal.data.data.phone}</div>
                          </div>
                        </div>
                      ) : null
                    }
                  </>
                )
              }
              <Form.Group>
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as='textarea'
                  value={declineModal.comment}
                  rows={4}
                  disabled={declineModal.isLoading}
                  onChange={this.handleCommentInputChange} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='light' onClick={this.hideDeclineModal}>
                Close
              </Button>
              <Button variant='danger' disabled={declineModal.isLoading} onClick={this.handleDecline}>
                Decline
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      </Card>
    );
  }
}