import React, { Component } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import TermGrades from './parts/TermGrades';
import Archive from './parts/Archive';
import './style.scss';
import ClassroomsAttendance from './parts/ClassroomsAttendance/ClassroomsAttendance';
import Attendance from './parts/Attendance';
import Information from './parts/Information';
import PaymentTransactions from './parts/PaymentTransactions';
import Performance from './parts/Performance';

export default class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: props.view === 'student' ? 'performance' : 'classrooms-attendance'
    };
  }
  handleNavSelect = activeNav => {
    this.setState({
      ...this.state,
      activeNav
    });
  }
  renderContent = () => {
    const { activeNav } = this.state;

    switch (activeNav) {
      case 'performance':
        return <Performance {...this.props} />;
      case 'information':
        return <Information {...this.props} />;
      case 'grades':
        return <TermGrades {...this.props} />;
      case 'attendance':
        return <Attendance {...this.props} />;
      case 'payment':
        return <PaymentTransactions {...this.props} />;
      case 'archive':
        return <Archive {...this.props} />;
      case 'classrooms-attendance':
        return <ClassroomsAttendance {...this.props} />;
      default:
        return null;
    }
  }
  renderStudent = () => {
    const { activeNav } = this.state;
    const { profile } = this.props;

    return (
      <Row noGutters>
        <Col lg={2} className='profile-menu-sidebar position-relative'>
          <Nav variant='tabs bg-light flex-column'>
            <Nav.Item>
              <Nav.Link
                eventKey='performance'
                onSelect={this.handleNavSelect}
                active={activeNav === 'performance'}>
                  Performance
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey='information'
                onSelect={this.handleNavSelect}
                active={activeNav === 'information'}>
                  Information
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey='grades'
                onSelect={this.handleNavSelect}
                active={activeNav === 'grades'}>
                  Grades
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey='attendance'
                onSelect={this.handleNavSelect}
                active={activeNav === 'attendance'}>
                  Attendance
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey='payment'
                onSelect={this.handleNavSelect}
                active={activeNav === 'payment'}>
                  Payment Transactions
              </Nav.Link>
            </Nav.Item>
            {
              profile.upload_limit && (
                <Nav.Item>
                  <Nav.Link
                    eventKey='archive'
                    onSelect={this.handleNavSelect}
                    active={activeNav === 'archive'}>
                      Archive
                  </Nav.Link>
                </Nav.Item>
              )
            }
          </Nav>
        </Col>
        <Col lg={10} className='p-3 profile-menu-content'>
          {this.renderContent()}
        </Col>
      </Row>
    );
  }
  renderFaculty = () => {
    const { activeNav } = this.state;
    const { profile, currentUser } = this.props;

    return (
      <Row noGutters>
        <Col lg={3} className='profile-menu-sidebar position-relative'>
          <Nav variant='tabs bg-light flex-column'>
            <Nav.Item>
              <Nav.Link
                eventKey='classrooms-attendance'
                onSelect={this.handleNavSelect}
                active={activeNav === 'classrooms-attendance'}>
                  Classrooms Attendance
              </Nav.Link>
            </Nav.Item>
            {
              (profile.upload_limit && currentUser.id === profile.id && (currentUser.account_type === 5 || currentUser.account_type === 2)) && (
                <Nav.Item>
                  <Nav.Link
                    eventKey='archive'
                    onSelect={this.handleNavSelect}
                    active={activeNav === 'archive'}>
                      Archive
                  </Nav.Link>
                </Nav.Item>
              )
            }
          </Nav>
        </Col>
        <Col lg={9} className='p-3 profile-menu-content'>
          {this.renderContent()}
        </Col>
      </Row>
    );
  }
  renderView = view => {
    switch (view) {
      case 'student':
        return this.renderStudent();
      case 'faculty':
        return this.renderFaculty();
      default:
        return null;
    }
  }
  render() {
    const { view } = this.props;

    if (!view) {
      return null;
    }

    return (
      <div className='profile-menu mt-3'>
        {this.renderView(view)}
      </div>
    );
  }
}