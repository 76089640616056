import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, Image, InputGroup, ListGroup, Modal, Nav, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';
import Validator from 'validatorjs';

const categoryMap = {
  'schoolLevel': 'School Level',
  'program': 'Program'
};

const frontBackMap = {
  'front': 'Front',
  'back': 'Back'
};

export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      selectedCategory: 'schoolLevel',
      schoolLevels: [],
      programs: [],
      uploadModal: {
        show: false,
        title: '',
        frontBack: '',
        toDelete: false,
        data: null,
        isLoading: false,
        errorMessage: ''
      },
      image: {
        isPreviewLoading: false,
        preview: null,
        fileName: ''
      }
    };

    this.image = null;
  }
  componentDidMount() {
    axiosRequest('get', 'admin/id-generation/settings', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleSelectCategory = selectedCategory => {
    this.setState({
      ...this.state,
      selectedCategory
    });
  }
  showUploadModal = (data, title, frontBack) => {
    this.setState({
      ...this.state,
      uploadModal: {
        ...this.state.uploadModal,
        show: true,
        title,
        frontBack,
        data
      }
    });
  }
  hideUploadModal = () => {
    this.setState({
      ...this.state,
      uploadModal: {
        ...this.state.uploadModal,
        show: false,
        title: '',
        frontBack: '',
        toDelete: false,
        data: null,
        errorMessage: ''
      },
      image: {
        isPreviewLoading: false,
        preview: null,
        fileName: ''
      }
    }, () => {
      this.image = null;
    });
  }
  fileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result));
      reader.addEventListener('error', error => reject(error));
      reader.readAsDataURL(file);
    });
  }
  handleImageChange = e => {
    let files = e.target.files;

    if (files.length > 0) {
      this.setState({
        ...this.state,
        uploadModal: {
          ...this.state.uploadModal,
          errorMessage: ''
        },
        image: {
          ...this.state.image,
          isPreviewLoading: true,
          fileName: ''
        }
      }, () => {
        const image = files[0];
        let validator = {
          passed: true,
          error: null
        };

        if (typeof image.name !== 'string') {
          validator.passed = false;
          validator.error = 'Invalid file.';
        } else if (image.type !== 'image/jpeg' && image.type !== 'image/png') {
          validator.passed = false;
          validator.error = 'Invalid image format.';
        }
        
        if (validator.passed) {
          this.fileToBase64(image).then(imageSrc => {
            this.setState({
              ...this.state,
              image: {
                ...this.state.image,
                preview: imageSrc,
                isPreviewLoading: false,
                fileName: image.name
              }
            }, () => {
              this.image = image;
            });
          }).catch(() => {
            this.setState({
              ...this.state,
              uploadModal: {
                ...this.state.uploadModal,
                errorMessage:  'Failed to read image.'
              },
              image: {
                ...this.state.image,
                preview: null,
                isPreviewLoading: false,
                fileName: ''
              }
            }, () => {
              this.image = null;
            });
          });
        } else {
          this.setState({
            ...this.state,
            uploadModal: {
              ...this.state.uploadModal,
              errorMessage:  validator.error
            },
            image: {
              ...this.state.image,
              preview: null,
              isPreviewLoading: false,
              fileName: ''
            }
          }, () => {
            this.image = null;
          });
        }
      });
    }
  }
  handleRemoveImage = () => {
    this.setState({
      ...this.state,
      image: {
        ...this.state.image,
        isPreviewLoading: false,
        preview: null,
        fileName: ''
      }
    }, () => {
      this.image = null;
    });
  }
  handleDeleteImage = e => {
    this.setState({
      ...this.state,
      uploadModal: {
        ...this.state.uploadModal,
        toDelete: true
      }
    });
  }
  handleCancelDeleteImage = e => {
    this.setState({
      ...this.state,
      uploadModal: {
        ...this.state.uploadModal,
        toDelete: false
      }
    });
  }
  handleUpload = () => {
    const { selectedCategory, uploadModal } = this.state;

    const formData = new window.FormData();
    formData.append('template', this.image, this.image.name);
    formData.append('type', uploadModal.frontBack);
    formData.append('category', selectedCategory);
    formData.append('categoryID', uploadModal.data.id);
    axiosRequest('post', 'admin/id-generation/settings/upload', formData, ({ data: { data }}) => {
      if (selectedCategory === 'schoolLevel') {
        let newSchoolLevels = [...this.state.schoolLevels].map(sl => {
          if (sl.id === uploadModal.data.id) {
            return {...data};
          }

          return {...sl};
        });
        this.setState({
          ...this.state,
          schoolLevels: newSchoolLevels,
          uploadModal: {
            ...this.state.uploadModal,
            isLoading: false,
            show: false,
            frontBack: '',
            title: '',
            data: null,
            errorMessage: '',
            toDelete: false
          },
          image: {
            isPreviewLoading: false,
            preview: null,
            fileName: ''
          }
        }, () => {
          this.image = null;
        });
      } else if (selectedCategory === 'program') {
        let newPrograms = [...this.state.programs].map(sl => {
          if (sl.id === uploadModal.data.id) {
            return {...data};
          }

          return {...sl};
        });
        this.setState({
          ...this.state,
          programs: newPrograms,
          uploadModal: {
            ...this.state.uploadModal,
            isLoading: false,
            show: false,
            title: '',
            frontBack: '',
            data: null,
            errorMessage: '',
            toDelete: false
          },
          image: {
            isPreviewLoading: false,
            preview: null,
            fileName: ''
          }
        }, () => {
          this.image = null;
        });
      } else {
        this.hideUploadModal();
      }
    }, error => {
      this.setState({
        ...this.state,
        uploadModal: {
          ...this.state.uploadModal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleDelete = () => {
    const { uploadModal, selectedCategory } = this.state;

    let templateID = 0;
    if (uploadModal.frontBack === 'front') {
      templateID = uploadModal?.data?.front_id_template?.id;
    } else if (uploadModal.frontBack === 'back') {
      templateID = uploadModal?.data?.back_id_template?.id;
    }

    if (!templateID) {
      this.setState({
        ...this.state,
        uploadModal: {
          ...this.state.uploadModal,
          isLoading: false,
          errorMessage: 'Template not found'
        }
      });
      return;
    }

    axiosRequest('delete', `admin/id-generation/settings/${templateID}`, null, ({ data: { data }}) => {
      if (selectedCategory === 'schoolLevel') {
        let newSchoolLevels = [...this.state.schoolLevels].map(sl => {
          if (sl.id === uploadModal.data.id) {
            return {...data};
          }

          return {...sl};
        });
        this.setState({
          ...this.state,
          schoolLevels: newSchoolLevels,
          uploadModal: {
            ...this.state.uploadModal,
            isLoading: false,
            frontBack: '',
            show: false,
            title: '',
            data: null,
            errorMessage: '',
            toDelete: false
          },
          image: {
            isPreviewLoading: false,
            preview: null,
            fileName: ''
          }
        }, () => {
          this.image = null;
        });
      } else if (selectedCategory === 'program') {
        let newPrograms = [...this.state.programs].map(sl => {
          if (sl.id === uploadModal.data.id) {
            return {...data};
          }

          return {...sl};
        });
        this.setState({
          ...this.state,
          programs: newPrograms,
          uploadModal: {
            ...this.state.uploadModal,
            isLoading: false,
            frontBack: '',
            show: false,
            title: '',
            data: null,
            errorMessage: '',
            toDelete: false
          },
          image: {
            isPreviewLoading: false,
            preview: null,
            fileName: ''
          }
        }, () => {
          this.image = null;
        });
      } else {
        this.hideUploadModal();
      }
    }, error => {
      this.setState({
        ...this.state,
        uploadModal: {
          ...this.state.uploadModal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleSave = e => {
    e.preventDefault();

    this.setState({
      ...this.state,
      uploadModal: {
        ...this.state.uploadModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { image, selectedCategory, uploadModal } = this.state;

      let validator = new Validator({
        template: image.fileName,
        type: uploadModal.frontBack,
        category: selectedCategory,
        category_id: uploadModal?.data?.id,
        to_delete: uploadModal.toDelete
      }, {
        template: 'required_without:to_delete',
        type: 'required|in:front,back',
        category: 'required|in:schoolLevel,program',
        category_id: 'required|integer',
        to_delete: 'required|boolean'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          uploadModal: {
            ...this.state.uploadModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      if (this.image) {
        this.handleUpload();
      } else if (uploadModal.toDelete) {
        this.handleDelete();
      }
    });
  }
  renderList = () => {
    const { programs, schoolLevels, selectedCategory } = this.state;

    switch (selectedCategory) {
      case 'program':
        return (
          <div className='mt-3'>
            {
              programs.length > 0 ? (
                <ListGroup>
                  {
                    programs.map(program => (
                      <ListGroup.Item key={program.id}>
                        <div className='h5'>
                          {program.description}
                        </div>
                        <div className='dropdown-divider'></div>
                        <Row>
                          <Col md={6}>
                            <div className='d-flex mb-1'>
                              <div className='flex-fill'>
                                Front
                              </div>
                              <div>
                                {
                                  program.front_id_template ? (
                                    <Button variant='info' size='sm' onClick={e => this.showUploadModal(program, program.description, 'front')}>
                                      <FontAwesomeIcon icon='pencil-alt' /><span className='d-none d-lg-inline-block ml-1'>Edit</span>
                                    </Button>
                                  ) : (
                                    <Button variant='green' size='sm' onClick={e => this.showUploadModal(program, program.description, 'front')}>
                                      <FontAwesomeIcon icon='file-upload' /><span className='d-none d-lg-inline-block ml-1'>Upload</span>
                                    </Button>
                                  )
                                }
                              </div>
                            </div>
                            <div style={{ height: '5rem', textAlign: 'center' }}>
                            {
                              program.front_id_template ? (
                                <a href={program.front_id_template.url}>
                                  <Image src={program.front_id_template.url} style={{ height: '100%', objectFit: 'cover' }} fluid thumbnail />
                                </a>  
                              ) : (
                                <Alert variant='light' className='text-left'>
                                  Nothing to show.
                                </Alert>
                              )
                            }
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className='d-flex mb-1'>
                              <div className='flex-fill'>
                                Back
                              </div>
                              <div>
                                {
                                  program.back_id_template ? (
                                    <Button variant='info' size='sm' onClick={e => this.showUploadModal(program, program.description, 'back')}>
                                      <FontAwesomeIcon icon='pencil-alt' /><span className='d-none d-lg-inline-block ml-1'>Edit</span>
                                    </Button>
                                  ) : (
                                    <Button variant='green' size='sm' onClick={e => this.showUploadModal(program, program.description, 'back')}>
                                      <FontAwesomeIcon icon='file-upload' /><span className='d-none d-lg-inline-block ml-1'>Upload</span>
                                    </Button>
                                  )
                                }
                              </div>
                            </div>
                            <div style={{ height: '5rem', textAlign: 'center' }}>
                            {
                              program.back_id_template ? (
                                <a href={program.back_id_template.url}>
                                  <Image src={program.back_id_template.url} style={{ height: '100%', objectFit: 'cover' }} fluid thumbnail />
                                </a>
                              ) : (
                                <Alert variant='light' className='text-left'>
                                  Nothing to show.
                                </Alert>
                              )
                            }
                            </div>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))
                  }
                </ListGroup>
              ) : (
                <Alert variant='light'>
                  Nothing to show.
                </Alert>
              )
            }
          </div>
        );
      case 'schoolLevel':
        return (
          <div className='mt-3'>
            {
              schoolLevels.length > 0 ? (
                <ListGroup>
                  {
                    schoolLevels.map(schoolLevel => (
                      <ListGroup.Item key={schoolLevel.id}>
                        <div className='h5'>
                          {schoolLevel.title}
                        </div>
                        <div className='dropdown-divider'></div>
                        <Row>
                          <Col md={6}>
                            <div className='d-flex mb-1'>
                              <div className='flex-fill'>
                                Front
                              </div>
                              <div>
                                {
                                  schoolLevel.front_id_template ? (
                                    <Button variant='info' size='sm' onClick={e => this.showUploadModal(schoolLevel, schoolLevel.title, 'front')}>
                                      <FontAwesomeIcon icon='pencil-alt' /><span className='d-none d-lg-inline-block ml-1'>Edit</span>
                                    </Button>
                                  ) : (
                                    <Button variant='green' size='sm' onClick={e => this.showUploadModal(schoolLevel, schoolLevel.title, 'front')}>
                                      <FontAwesomeIcon icon='file-upload' /><span className='d-none d-lg-inline-block ml-1'>Upload</span>
                                    </Button>
                                  )
                                }
                              </div>
                            </div>
                            <div style={{ height: '5rem', textAlign: 'center' }}>
                            {
                              schoolLevel.front_id_template ? (
                                <a href={schoolLevel.front_id_template.url}>
                                  <Image src={schoolLevel.front_id_template.url} style={{ height: '100%', objectFit: 'cover' }} fluid thumbnail />
                                </a>  
                              ) : (
                                <Alert variant='light' className='text-left'>
                                  Nothing to show.
                                </Alert>
                              )
                            }
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className='d-flex mb-1'>
                              <div className='flex-fill'>
                                Back
                              </div>
                              <div>
                                {
                                  schoolLevel.back_id_template ? (
                                    <Button variant='info' size='sm' onClick={e => this.showUploadModal(schoolLevel, schoolLevel.title, 'back')}>
                                      <FontAwesomeIcon icon='pencil-alt' /><span className='d-none d-lg-inline-block ml-1'>Edit</span>
                                    </Button>
                                  ) : (
                                    <Button variant='green' size='sm' onClick={e => this.showUploadModal(schoolLevel, schoolLevel.title, 'back')}>
                                      <FontAwesomeIcon icon='file-upload' /><span className='d-none d-lg-inline-block ml-1'>Upload</span>
                                    </Button>
                                  )
                                }
                              </div>
                            </div>
                            <div style={{ height: '5rem', textAlign: 'center' }}>
                            {
                              schoolLevel.back_id_template ? (
                                <a href={schoolLevel.back_id_template.url}>
                                  <Image src={schoolLevel.back_id_template.url} style={{ height: '100%', objectFit: 'cover' }} fluid thumbnail />
                                </a>
                              ) : (
                                <Alert variant='light' className='text-left'>
                                  Nothing to show.
                                </Alert>
                              )
                            }
                            </div>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))
                  }
                </ListGroup>
              ) : (
                <Alert variant='light'>
                  Nothing to show.
                </Alert>
              )
            }
          </div>
        );
      default:
        return null;
    }
  }
  renderContent = () => {
    const {
      isLoading,
      errorMessage,
      selectedCategory,
      schoolLevels,
      programs,
      uploadModal,
      image
    } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (schoolLevels.length === 0 && programs.length === 0) {
      return (
        <Alert variant='warning'>
          Nothing to show. Try adding a <b>school level</b> and/or a <b>program</b>.
        </Alert>
      );
    }

    return (
      <>
        <Alert variant='warning'>
          Upload ID templates on chosen category.
        </Alert>
        <Nav variant='pills' activeKey={selectedCategory} onSelect={this.handleSelectCategory}>
          {
            schoolLevels.length > 0 && (
              <Nav.Item>
                <Nav.Link eventKey='schoolLevel'>School Level</Nav.Link>
              </Nav.Item>
            )
          }
          {
            programs.length > 0 && (
              <Nav.Item>
                <Nav.Link eventKey='program'>Program</Nav.Link>
              </Nav.Item>
            )
          }
        </Nav>
        {this.renderList()}
        <Modal show={uploadModal.show} onHide={this.hideUploadModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div>{uploadModal.title ? uploadModal.title : 'Upload Template'}</div>
              <small className='text-muted font-italic'>{categoryMap[selectedCategory]}</small>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleSave}>
            <Modal.Body>
              {
                uploadModal.errorMessage && (
                  <Alert variant='danger'>
                    {uploadModal.errorMessage}
                  </Alert>
                )
              }
              <Form.Group>
                <Form.Label>{frontBackMap[uploadModal.frontBack]}</Form.Label>
                {
                  (image.isPreviewLoading || image.preview) ? (
                    <div className='my-2 text-center' style={{ height: '10rem' }}>
                      {
                        image.isPreviewLoading ? (
                          <LoadingIcon />
                        ) : image.preview ? (
                          <Image src={image.preview} style={{ objectFit: 'cover', height: '100%' }} fluid thumbnail />
                        ) : null
                      }
                    </div>
                  ) : (uploadModal.frontBack && uploadModal?.data[`${uploadModal.frontBack}_id_template`]?.url) ? (
                    <div className='my-2 text-center position-relative' style={{ height: '10rem' }}>
                      {/* {
                        uploadModal.toDelete && (
                          <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(0,0,0,.12)' }}></div>
                        )
                      } */}
                      <Image src={uploadModal.data[`${uploadModal.frontBack}_id_template`].url} style={{ objectFit: 'cover', height: '100%', ...(uploadModal.toDelete ? { opacity: .32 } : {}) }} fluid thumbnail />
                    </div>
                  ) : null
                }
                <InputGroup>
                  <Form.File 
                    id='front-image-upload'
                    label={image.fileName ? image.fileName : 'No file chosen'}
                    accept='image/png,image/jpeg'
                    onChange={this.handleImageChange}
                    disabled={uploadModal.isLoading}
                    custom
                  />
                  {
                    image.preview ? (
                      <InputGroup.Append>
                        <Button variant='outline-danger' onClick={this.handleRemoveImage}>
                          Remove
                        </Button>
                      </InputGroup.Append>
                    ) : (uploadModal.frontBack && uploadModal?.data[`${uploadModal.frontBack}_id_template`]?.url) ? (
                      <InputGroup.Append>
                        {
                          uploadModal.toDelete ? (
                            <Button variant='outline-danger' onClick={this.handleCancelDeleteImage}>
                              Cancel
                            </Button>
                          ) : (
                            <Button variant='outline-danger' onClick={this.handleDeleteImage}>
                              Delete
                            </Button>
                          )
                        }
                      </InputGroup.Append>
                    ) : null
                  }
                </InputGroup>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' onClick={this.hideUploadModal}>
                Cancel
              </Button>
              <Button type='submit' variant='green' disabled={uploadModal.isLoading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }

  render() {
    return (
      <Card>
        <Card.Body>
          {this.renderContent()}
        </Card.Body>
      </Card>
    );
  }
}