import React, { Component } from 'react';
import katex from 'katex';
import 'katex/dist/katex.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.scss';

window.katex = katex;

class Editor extends Component {
  render() {
    const { onChange, value, ...restProps } = this.props;
    return (
      <ReactQuill
        {...restProps}
        className='rich-text-editor-editor'
        theme='snow'
        modules={{
          toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'align': [] }],

            ['link', 'formula'],

            ['clean']
          ],
        }}
        formats={[
          'header',
          'bold', 'italic', 'underline', 'strike',
          'blockquote', 'code-block', 'code',
          'list',
          'script',
          'indent',
          'color', 'background',
          'align',
          'link', 'formula'
        ]}
        value={value}
        onChange={onChange} />
    );
  }
}

Editor.defaultProps = {
  onChange: () => {},
  value: ''
};

export default Editor;