import React from 'react';
import { Alert } from 'react-bootstrap';

export default function Assignment(props) {
  function student() {
    return (
      <>
        <div className='mb-3'>
          To <b>view</b> an assignment, click on the name of the material.<br />
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Details</div>
          <div>
            <ol>
              <li><b>Title</b></li>
              <li><b>Due date</b></li>
              <li><b>Description</b></li>
              <li><b>Attachments</b> &mdash; optionally visible</li>
              <li>
                <div><b>Information icon</b></div>
                <div>
                  Hover on the icon to view the material's grade category and the date it was posted.
                </div>
              </li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Submit an assignment</div>
          <Alert variant='warning'>
            Assignment submissions are limited to one attempt only. However, the faculty may <b>authorize</b> a revision for your submission.
          </Alert>
          <div>
            <ol>
              <li>Click on <b>Submit assignment</b> to show the form.</li>
              <li>Enter text to the textarea.</li>
              <li>For file attachments, you may use third-party services (Google Drive, Dropbox, etc.) and paste the link inside the textarea.</li>
              <li>Click <b>Submit</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Submissions</div>
          <div>
            Submissions are ordered based on the date it was submitted.
          </div>
          <div>
            <ol>
              <li><b>Revision number</b></li>
              <li><b>Description</b> &mdash; submission details</li>
              <li><b>Submission date</b></li>
              <li>
                <div><b>Remarks</b></div>
                <div>
                  <b className='text-green'>On time</b> &mdash; if the submission date is before the due date specified<br/>
                  <b className='text-danger'>Late</b> &mdash; if the submission date is after the due date specified
                </div>
              </li>
            </ol>
          </div>
        </div>
      </>
    );
  }

  function faculty() {
    return (
      <>
        <div className='mb-3'>
          <div className='h4 text-primary'>Create an assignment</div>
          <div>
            <ol>
              <li>Click on <b>Add Material</b> at the top right corner of the material pane.</li>
              <li>Click <b>Assignment</b> to open the form.</li>
              <li>Fill out the form.</li>
              <li>The <b>due date</b> field must be after the current date and time.</li>
              <li>Optionally, you may attach files by clicking on the upload icon at the bottom of the form.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Update an assignment</div>
          <Alert variant='warning'>
            Changes made will reflect back to the students' submissions.
          </Alert>
          <div>
            <ol>
              <li>Click on the pencil icon at the right side of the assignment to be updated.</li>
              <li>Fill out the form.</li>
              <li>
                If a file is attached to the assignment, clicking the <b>Show attachments</b> will show the currently attached file.
                You may delete the attached file by clicking on the trash icon at the right side of the file.
                Furthermore, to cancel deletion of the file, you may click on the ban icon at the right side of the file.
              </li>
              <li>Optionally, you may attach new files by clicking on the upload icon at the bottom of the form.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Delete an assignment</div>
          <Alert variant='warning'>
            Deleting an assignment will also delete the students' submissions. Furthermore, grades will also be deleted.
          </Alert>
          <div>
            <ol>
              <li>Click on the trash icon at the right side of the assignment to be deleted.</li>
              <li>Click <b>Delete</b> from the prompt to confirm deletion.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>View assignment</div>
          <div>
            To <b>view</b> an assignment, click on the name of the material.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Details</div>
          <div>
            <ol>
              <li><b>Title</b></li>
              <li><b>Due date</b></li>
              <li><b>Description</b></li>
              <li><b>Attachments</b> &mdash; optionally visible</li>
              <li>
                <div><b>Information icon</b></div>
                <div>
                  Hover on the icon to view the material's grade category and the date it was posted.
                </div>
              </li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Submissions</div>
          <div className='mb-3'>
            <div>
              Students' submissions are limited to one (1) only. Unless, you authorize a revision. <br />
              The submissions area will display all the students that has a submission.
            </div>
            <ol>
              <li><b>Student</b></li>
              <li>
                <div><b>Remarks</b></div>
                <div>
                  <b className='text-green'>On time</b> &mdash; if the submission date is before the due date specified<br/>
                  <b className='text-danger'>Late</b> &mdash; if the submission date is after the due date specified
                </div>
              </li>
              <li><b>Grade</b></li>
            </ol>
          </div>
          <div className='font-weight-bold'>To authorize a revision:</div>
          <div className='ml-3'>
            <ol>
              <li>Click on the blue redo icon at the right side of the submission.</li>
              <li>Click <b>Authorize</b> from the prompt to confirm.</li>
            </ol>
          </div>
          <div className='font-weight-bold'>To view all the revisions submitted by the student:</div>
          <div className='ml-3'>
            <ol>
              <li>Click on the eye icon at the right side of the submission.</li>
              <li>Select on the dropdown list the revision to be viewed.</li>
            </ol>
          </div>
          <div className='font-weight-bold'>To grade the submission:</div>
          <div className='ml-3'>
            <ol>
              <li>Click on the pencil icon at the right side of the submission.</li>
              <li>Enter the grade to the grade field. Be aware of the maximum points you have set.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
      </>
    );
  }

  return props.accountType === 5 ? student() :
          props.accountType === 2 ? faculty() : null;
}