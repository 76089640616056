import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import StackTrace from 'stacktrace-js';

window.onError = (msg, file, line, col, error) => {
  StackTrace.fromError(error).then(err => {
    axios.post(`${process.env['REACT_APP_API_BASE_URL']}/report-error`, {
      error: {
        type: 'Window error',
        url: window.location.href,
        agent: window.navigator.userAgent,
        date: new Date(),
        stack: err
      },
      errorInfo: error.toString()
    }, {
      withCredentials: true
    });
  });
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // const errorJSON = {};
    // Object.getOwnPropertyNames(error).forEach(function (key) {
    //     errorJSON[key] = error[key];
    // });
    // axios.post(`${process.env['REACT_APP_API_BASE_URL']}/report-error`, {
    //   error: errorJSON,
    //   errorInfo
    // }, {
    //   withCredentials: true
    // });

    StackTrace.fromError(error).then(err => {
      axios.post(`${process.env['REACT_APP_API_BASE_URL']}/report-error`, {
        error: {
          type: 'Error boundary',
          url: window.location.href,
          agent: window.navigator.userAgent,
          date: new Date(),
          stack: err
        },
        errorInfo: error.toString()
      }, {
        withCredentials: true
      });
    });
  }
  reloadPage = () => {
    window.location.reload(true);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className='d-flex flex-column align-items-center justify-content-center' style={{ minHeight: '100vh' }}>
          <div className='fa-4x'>
            <FontAwesomeIcon icon='exclamation-circle' size='lg' />
          </div>
          <h2 className='text-center'>Sorry! Something went wrong...</h2>
          <div className='text-center'>
            Please try to <span className='btn-link' onClick={this.reloadPage}> <FontAwesomeIcon icon='redo' size='sm' /> refresh</span> the page. If the problem persists, please contact the system administrator.
          </div>
        </div>
      );
    }

    // Normally, just render children
    return this.props.children;
  }  
}