import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, InputGroup, Pagination, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Validator from 'validatorjs';
import axiosRequest from '../../../../util/helpers/axiosRequest';

import AdminSettingsModal from '../../../modals/AdminSettingsModal/AdminSettingsModal';
import PromptDeleteModal from '../../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class ClassList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formInputs: {
        program: '',
        yearLevel: '',
        section: ''
      },
      programs: [],
      yearLevels: [],
      sections: [],
      classes: {
        data: []
      },
      isLoading: true,
      errorMessage: '',
      formError: '',
      editModal: {
        show: false,
        data: {
          id: '',
          program: '',
          yearLevel: '',
          section: ''
        },
        errorMessage: ''
      },
      deleteModal: {
        show: false,
        data: {
          id: '',
          program: '',
          yearLevel: '',
          section: ''
        },
        errorMessage: ''
      },
      search: {
        isLoading: false,
        errorMessage: '',
        query: '',
        isSearch: false
      }
    }
  }
  componentDidMount() {
    axiosRequest('get', 'admin/settings/class', null, ({ data: { data } }) => {
      this.setState({
        ...this.state,
        programs: data.options.programs,
        yearLevels: data.options.yearLevels,
        sections: data.options.sections,
        classes: data.classes,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showEditModal = (id) => {
    const schoolClass = [...this.state.classes.data].filter((p) => {
      return p.id === id;
    });
    this.setState({
      ...this.state,
      editModal: {
        show: true,
        data: {
          id: schoolClass[0].id,
          program: schoolClass[0].program.id,
          yearLevel: schoolClass[0].year_level.id,
          section: schoolClass[0].section.id
        },
        errorMessage: ''
      }
    });
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        data: {
          id: '',
          program: '',
          yearLevel: '',
          section: ''
        },
        errorMessage: ''
      }
    })
  }
  handleEditSelectChange = event => {
    const { editModal } = this.state;
    editModal.data[event.target.name] = event.target.value;
    this.setState({
      ...this.state,
      editModal
    });
  }
  handleEdit = event => {
    event.preventDefault();
    const editModal = {...this.state.editModal};

    let rules = {
      id: 'required|integer',
      program: 'required|integer',
      yearLevel: 'required|integer',
      section: 'required|integer'
    };
    
    let validation = new Validator(editModal.data, rules, {
      'required.yearLevel': 'The year level field is required.'
    });

    if (validation.fails()) {
      const firstKey = Object.keys(validation.errors.errors)[0];
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          errorMessage: validation.errors.errors[firstKey][0]
        }
      });
      return;
    }

    axiosRequest('patch', `admin/settings/class/${editModal.data.id}`, {
      programID: editModal.data.program,
      yearLevelID: editModal.data.yearLevel,
      sectionID: editModal.data.section
    }, ({ data: { data } }) => {
      const classes = [...this.state.classes.data].map((p) => {
        if (p.id === editModal.data.id) {
          return data;
        }
        
        return p;
      });
      this.setState({
        ...this.state,
        classes: {
          ...this.state.classes,
          data: [
            ...classes
          ]
        },
        editModal: {
          show: false,
          data: {
            id: '',
            program: '',
            yearLevel: '',
            section: ''
          },
          errorMessage: ''
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  showDeleteModal = (id) => {
    const schoolClass = [...this.state.classes.data].filter((p) => {
      return p.id === id;
    });
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        data: {
          id: schoolClass[0].id,
          program: `${schoolClass[0].program.description} (${schoolClass[0].program.code})`,
          yearLevel: `${schoolClass[0].year_level.description} (${schoolClass[0].year_level.code})`,
          section: schoolClass[0].section.code,
        },
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        data: {
          id: '',
          program: '',
          yearLevel: '',
          section: ''
        },
        errorMessage: ''
      }
    });
  }
  handleDelete = () => {
    const deleteModal = {...this.state.deleteModal};

    axiosRequest('delete', `admin/settings/class/${deleteModal.data.id}`, null, (res) => {
      const classes = [...this.state.classes.data].filter((p) => {
        return p.id !== deleteModal.data.id;
      });
      this.setState({
        ...this.state,
        classes: {
          ...this.state.classes,
          data: [
            ...classes
          ]
        },
        deleteModal: {
          show: false,
          data: {
            id: '',
            program: '',
            yearLevel: '',
            section: ''
          },
          errorMessage: ''
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        deleteModal: {
          ...this.state.deleteModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleSelectChange = event => {
    const { formInputs } = this.state;
    formInputs[event.target.name] = event.target.value;
    this.setState({
      ...this.state,
      formInputs
    });
  }
  handleSubmit = event => {
    event.preventDefault();
    const { formInputs } = this.state;

    this.setState({
      ...this.state,
      formError: ''
    }, () => {
      let rules = {
        program: 'required|integer',
        yearLevel: 'required|integer',
        section: 'required|integer'
      };
  
      let validation = new Validator(formInputs, rules, {
        'required.yearLevel': 'The year level field is required.'
      });
  
      if (validation.fails()) {
        const firstKey = Object.keys(validation.errors.errors)[0];
        this.setState({
          ...this.state,
          formError: validation.errors.errors[firstKey][0]
        });
        return;
      }
  
      axiosRequest('post', 'admin/settings/class', {
        programID: formInputs.program,
        yearLevelID: formInputs.yearLevel,
        sectionID: formInputs.section
      }, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          classes: {
            ...this.state.classes,
            data: [
              ...this.state.classes.data,
              {...data}
            ]
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handlePagination = url => {
    if (url) {
      this.setState({
        ...this.state,
        isLoading: true
      }, () => {
        const path = url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
        
        axiosRequest('get', path, null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            classes: data.classes,
            isLoading: false,
            errorMessage: ''
          });
        }, (error) => {
          this.setState({
            ...this.state,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    }
  }
  handleSearchInputChange = event => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        query: event.target.value
      }
    });
  }
  handleSearch = event => {
    event.preventDefault();

    const { search } = this.state;

    if (!search.query && !search.isSearch) {
      return;
    }

    this.setState({
      ...this.state,
      search: {
        ...search,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {

      axiosRequest('get', `admin/settings/class/search/${search.query}`, null, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          classes: data.classes,
          search: {
            ...search,
            isLoading: false,
            errorMessage: '',
            isSearch: !!search.query
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          search: {
            ...search,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  renderSelect = (name, list) => {
    const placeholders = {
      program: 'Select a program...',
      yearLevel: 'Select a year level...'
    };

    return (
      <Form.Control as='select' name={name}  onChange={this.handleSelectChange} disabled={list.length === 0}>
        <option hidden disabled selected>{placeholders[name]}</option>
        {
          list.map(item => (
            <option key={item.id} value={item.id}>{`${item.code} - ${item.description}`}</option>
          ))
        }
      </Form.Control>
    );
  }
  renderForm() {
    const { programs, yearLevels, sections, formError } = this.state;

    return (
      <>
        {
          !!formError && (
            <Alert variant='danger'>{formError}</Alert>
          )
        }
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} lg={3}>
              <Form.Label>Program</Form.Label>
              { this.renderSelect('program', programs) }
            </Form.Group>
            <Form.Group as={Col} lg={3}>
              <Form.Label>Year Level</Form.Label>
              { this.renderSelect('yearLevel', yearLevels) }
            </Form.Group>
            <Form.Group as={Col} lg={3}>
              <Form.Label>Section</Form.Label>
              <Form.Control as='select' name='section' onChange={this.handleSelectChange}>
                <option hidden disabled selected>Select a section...</option>
                {
                  sections.map(section => (
                    <option key={section.id} value={section.id}>{section.code}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} lg={3} className='d-flex align-items-end'>
              <Button type='submit' block variant='green'>Add class</Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </>
    );
  }
  renderTable = () => {
    const { classes, search } = this.state;

    return (
      <>
        {
          search.errorMessage && (
            <Alert variant='danger'>
              {search.errorMessage}
            </Alert>
          )
        }
        <Row>
          <Col lg={6} className='ml-auto'>
            <div className='mb-2 mt-1'>
              <Form onSubmit={this.handleSearch}>
                <InputGroup>
                  <Form.Control type='search' placeholder='Search classes...' value={search.query} onChange={this.handleSearchInputChange} />
                  <InputGroup.Append>
                    <Button variant='primary' type='submit' disabled={search.isLoading}>
                      {
                        search.isLoading ? (
                          <LoadingIcon />
                        ) : (
                          <FontAwesomeIcon icon='search' />
                        )
                      }
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </div>
          </Col>
        </Row>
        {
          classes.data.length > 0 ? (
            <>
              <Table striped bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Program</th>
                    <th>Year Level</th>
                    <th>Section</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    classes.data.map(cl => (
                      <tr key={cl.id}>
                        <td>{cl.id}</td>
                        <td title={cl.program.description}>{cl.program.code}</td>
                        <td title={cl.year_level.description}>{cl.year_level.code}</td>
                        <td>{cl.section.code}</td>
                        <td className='text-center'>
                          <Button
                            variant='primary'
                            size='sm'
                            title='Edit'
                            className='mx-1'
                            onClick={(e) => {this.showEditModal(cl.id)}}
                            >
                            <FontAwesomeIcon icon='pencil-alt' />
                          </Button>
                          <Button
                            variant='danger'
                            size='sm'
                            title='Delete'
                            className='mx-1'
                            onClick={(e) => {this.showDeleteModal(cl.id)}}
                            >
                            <FontAwesomeIcon icon='trash-alt' />
                          </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <Pagination className='justify-content-end'>
                <Pagination.First onClick={() => this.handlePagination(classes.first_page_url)} disabled={classes.current_page === 1} />
                <Pagination.Prev onClick={() => this.handlePagination(classes.prev_page_url)} disabled={classes.current_page === 1} />
                <Pagination.Next onClick={() => this.handlePagination(classes.next_page_url)} disabled={classes.current_page === classes.last_page} />
                <Pagination.Last onClick={() => this.handlePagination(classes.last_page_url)} disabled={classes.current_page === classes.last_page} />
              </Pagination>
            </>
          ) : (
            <Alert variant='light'>
              Nothing to show
            </Alert>
          )
        }
      </>
    );
  }
  renderContent = () => {
    const { isLoading, programs, yearLevels, sections, errorMessage, editModal, deleteModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    let message = '';
    switch(true) {
      case programs.length === 0:
        message = 'program';
        break;
      case yearLevels.length === 0:
        message = 'year level';
        break;
      case sections.length === 0:
        message = 'section';
        break;
      default:
        message = '';
        break;
    }

    if (message !== '') {
      return (
        <Alert variant='warning'>
          Unable to add a class with incomplete settings.
          Try adding a <b>{message}</b> first.
        </Alert>
      );
    }

    return (
      <>
        { this.renderForm() }
        <div className='dropdown-divider'></div>
        { this.renderTable() }
        <AdminSettingsModal
          {...editModal}
          title='Edit class'
          onHide={this.hideEditModal}
          onSubmit={this.handleEdit}>
            <Form.Group>
              <Form.Label>Program</Form.Label>
              <Form.Control
                as='select'
                name='program'
                value={editModal.data.program}
                onChange={this.handleEditSelectChange}>
                  {
                    programs.map(program => (
                      <option key={program.id} value={program.id}>{`${program.code} - ${program.description}`}</option>
                    ))
                  }
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Year Level</Form.Label>
              <Form.Control
                as='select'
                name='yearLevel'
                value={editModal.data.yearLevel}
                onChange={this.handleEditSelectChange}>
                  {
                    yearLevels.map(yearLevel => (
                      <option key={yearLevel.id} value={yearLevel.id}>{`${yearLevel.code} - ${yearLevel.description}`}</option>
                    ))
                  }
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Section</Form.Label>
              <Form.Control
                as='select'
                name='section'
                value={editModal.data.section}
                onChange={this.handleEditSelectChange}>
                  {
                    sections.map(section => (
                      <option key={section.id} value={section.id}>{section.code}</option>
                    ))
                  }
              </Form.Control>
            </Form.Group>
        </AdminSettingsModal>
        <PromptDeleteModal
          {...deleteModal}
          title='Delete class'
          onHide={this.hideDeleteModal}
          onDelete={this.handleDelete}>
            <Card.Subtitle>Are you sure you want to delete the class?</Card.Subtitle>
            <Alert variant='light'>
              <div>{deleteModal.data.program}</div>
              <div>{deleteModal.data.yearLevel}</div>
              <div>{deleteModal.data.section}</div>
            </Alert>
        </PromptDeleteModal>
      </>
    );
  }
  render() {
    return (
      <Card>
        <Card.Body>
          { this.renderContent() }
        </Card.Body>
      </Card>
    );
  }
}