import React, { Component, createRef } from 'react';
import { Alert, Badge, Button, Col, Form, Image, InputGroup, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import { loadRule } from '../../../../../util';
import Validator from 'validatorjs';
import moment from 'moment';
import PromptDeleteModal from '../../../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

const modesOfPayment = [
  'Cashier\'s Office',
  'GCash to GCash',
  'GCash to China Bank',
  'Bank to Bank Transfer'
];

class PaymentTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      transactions: [],
      modal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        formInputs: {
          mode: '',
          otherMode: '',
          date: '',
          time: '',
          amount: '',
          referenceNumber: '',
          sender: '',
          image: ''
        },
        data: null
      },
      viewModal: {
        show: false,
        data: null
      },
      deleteModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null
      }
    };

    this.otherMode = createRef();
    this.image = null;
  }
  componentDidMount() {
    const { profile } = this.props;

    axiosRequest('get', `profile-menu/${profile.id}/payment-transaction`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        isLoading: false,
        transactions: data
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);

    loadRule('date');
  }
  showModal = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        show: true
      }
    });
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        show: false,
        isLoading: false,
        errorMessage: '',
        formInputs: {
          mode: '',
          otherMode: '',
          date: '',
          time: '',
          amount: '',
          referenceNumber: '',
          sender: '',
          image: ''
        },
        data: null
      }
    }, () => {
      this.image = null;
    });
  }
  showViewModal = data => {
    this.setState({
      ...this.state,
      viewModal: {
        show: true,
        data
      }
    });
  }
  hideViewModal = () => {
    this.setState({
      ...this.state,
      viewModal: {
        show: false,
        data: null
      }
    });
  }
  showEditModal = data => {
    let formInputs = {
      mode: data.mode,
      otherMode: '',
      date: data.transaction_date,
      time: data.transaction_time,
      amount: data.amount,
      referenceNumber: data.reference_number,
      sender: data.sender_name,
      image: data.image
    };

    if (modesOfPayment.indexOf(data.mode) === -1) {
      formInputs.mode = 'Other';
      formInputs.otherMode = data.mode;
    }

    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        show: true,
        formInputs,
        data
      }
    });
  }
  showDeleteModal = data => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        show: true,
        data
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null
      }
    });
  }
  handleTimeClear = e => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        formInputs: {
          ...this.state.modal.formInputs,
          time: ''
        }
      }
    });
  }
  handleInputChange = e => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        formInputs: {
          ...this.state.modal.formInputs,
          [e.target.name]: e.target.value
        }
      }
    });
  }
  handleImageChange = e => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    let image = e.target.files[0];

    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        formInputs: {
          ...this.state.modal.formInputs,
          image: image.name
        }
      }
    }, () => {
      this.image = image;
    });
  }
  handleAdd = () => {
    const { modal } = this.state;
    const { profile } = this.props;

    const formData = new window.FormData();
    formData.append('mode', modal.formInputs.mode === 'Other' ? modal.formInputs.otherMode : modal.formInputs.mode);
    formData.append('referenceNumber', modal.formInputs.referenceNumber);
    formData.append('date', modal.formInputs.date);
    formData.append('time', modal.formInputs.time ? modal.formInputs.time : '');
    formData.append('sender', modal.formInputs.sender);
    formData.append('amount', modal.formInputs.amount);
    formData.append('image', this.image, this.image.name);

    axiosRequest('post', `profile-menu/${profile.id}/payment-transaction`, formData, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        transactions: [
          ...this.state.transactions,
          data
        ]
      }, this.hideModal);
    }, error => {
      this.setState({
        ...this.state,
        modal: {
          ...this.state.modal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleEdit = () => {
    const { modal } = this.state;
    const { profile } = this.props;

    const formData = new window.FormData();
    formData.append('mode', modal.formInputs.mode === 'Other' ? modal.formInputs.otherMode : modal.formInputs.mode);
    formData.append('referenceNumber', modal.formInputs.referenceNumber);
    formData.append('date', modal.formInputs.date);
    formData.append('time', modal.formInputs.time ? modal.formInputs.time : '');
    formData.append('sender', modal.formInputs.sender);
    formData.append('amount', modal.formInputs.amount);

    if (this.image) {
      formData.append('image', this.image, this.image.name);
    }

    axiosRequest('post', `profile-menu/${profile.id}/payment-transaction/${modal.data.id}`, formData, ({ data: { data }}) => {
      let newTransactions = [...this.state.transactions].map(t => {
        if (t.id === data.id) {
          return {...data};
        }

        return {...t};
      });
      this.setState({
        ...this.state,
        transactions: newTransactions
      }, this.hideModal);
    }, error => {
      this.setState({
        ...this.state,
        modal: {
          ...this.state.modal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleSubmit = e => {
    e.preventDefault();

    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { modal } = this.state;

      let validator = new Validator(modal.formInputs, {
        mode: 'required|string',
        otherMode: 'required_if:mode,Other|min:2',
        referenceNumber: 'required|string|min:2',
        date: 'required|date',
        sender: 'required|string|min:2',
        amount: 'required|numeric|min:1'
      }, {
        'required.referenceNumber': 'The reference number is required.',
        'min.referenceNumber': 'The reference number must be at least 2 characters.',
        'required.date': 'The transaction date is required.',
        'required.sender': 'The sender\'s name is required',
        'min.sender': 'The sender\'s name must be at least 2 characters',
        'required_if.otherMode': 'Please specify the mode of payment.'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      if (!modal.data || modal.formInputs.image) {
        validator = {
          passed: true,
          error: null
        };
  
        if (this.image === null) {
          validator.passed = false;
          validator.error = 'The screenshot/image of transaction is required.';
        } else if (typeof this.image.name !== 'string') {
          validator.passed = false;
          validator.error = 'Invalid file.';
        } else if (this.image.type !== 'image/jpeg' && this.image.type !== 'image/png') {
          validator.passed = false;
          validator.error = 'Invalid image format.';
        }
  
        if (!validator.passed) {
          this.setState({
            ...this.state,
            modal: {
              ...this.state.modal,
              isLoading: false,
              errorMessage: validator.error
            }
          });
          return;
        }
      }

      if (modal.data) {
        this.handleEdit();
      } else {
        this.handleAdd();
      }
    });
  }
  handleDelete = () => {

    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { deleteModal } = this.state;
      const { profile } = this.props;

      axiosRequest('delete', `profile-menu/${profile.id}/payment-transaction/${deleteModal.data.id}`, null, ({ data: { message }}) => {
        let newTransactions = [...this.state.transactions].filter(t => t.id !== deleteModal.data.id);
        this.setState({
          ...this.state,
          transactions: newTransactions,
          deleteModal: {
            ...this.state.deleteModal,
            show: false,
            isLoading: false,
            errorMessage: '',
            data: null
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handlePreviewImage = (image, imageID) => {
    this.props.showImagePreview([
      {
        id: imageID,
        src: image
      }
    ]);
  }
  render() {
    const { isLoading, errorMessage, transactions, modal, viewModal, deleteModal } = this.state;
    const { currentUser, profile } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        {
          currentUser.id === profile.id && (
            <>
              <div className='text-right'>
                <Button variant='green' onClick={this.showModal}>
                  Add transaction
                </Button>
              </div>
              <div className='dropdown-divider'></div>
            </>
          )
        }
        {
          transactions.length === 0 ? (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          ) : (
            <Table hover responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Mode of Payment</th>
                  <th>Transaction Date</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  transactions.map((transaction, index) => (
                    <tr key={transaction.id}>
                      <th>
                        {index+1}
                      </th>
                      <td>
                        {transaction.mode}
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        {
                          transaction.transaction_time ?
                            moment(`${transaction.transaction_date} ${transaction.transaction_time}`, 'YYYY-MM-DD HH:mm').format('MMM D, YYYY hh:mm A') :
                            moment(transaction.transaction_date).format('MMMM D, YYYY')
                        }
                      </td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        &#8369; {transaction.amount.toFixed(2)}
                      </td>
                      <td>
                        {
                          transaction.approved_at ? (
                            <Badge variant='green'>
                              Accepted
                            </Badge>
                          ) : transaction.declined_at ? (
                            <Badge variant='danger'>
                              Declined
                            </Badge>
                          ) : transaction.read_at ? (
                            <Badge variant='warning'>
                              Reviewing
                            </Badge>
                          ) : (
                            <Badge variant='light'>
                              Processing
                            </Badge>
                          )
                        }
                      </td>
                      <td className='text-center' style={{ whiteSpace: 'nowrap' }}>
                        <Button variant='warning' size='sm' title='View' onClick={e => this.showViewModal(transaction)}>
                          <FontAwesomeIcon icon='eye' />
                        </Button>
                        {
                          currentUser.id === profile.id && (
                            <>
                              {
                                (transaction.declined_at || (!transaction.read_at && !transaction.approved_at)) && (
                                  <>
                                    <Button variant='info' size='sm' className='mx-1' onClick={e => this.showEditModal(transaction)}>
                                      <FontAwesomeIcon icon='pencil-alt' />
                                    </Button>
                                    <Button variant='danger' size='sm' onClick={e => this.showDeleteModal(transaction)}>
                                      <FontAwesomeIcon icon='trash-alt' />
                                    </Button>
                                  </>
                                )
                              }
                            </>
                          )
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          )
        }
        <Modal show={modal.show} onHide={this.hideModal} backdrop='static'>
          <Modal.Header closeButton>
            <Modal.Title>{modal.data ? 'Edit Transaction' : 'Add Transaction'}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              {
                modal.errorMessage && (
                  <Alert variant='danger'>
                    {modal.errorMessage}
                  </Alert>
                )
              }
              <Form.Group>
                <Form.Label>Mode of Payment</Form.Label>
                <Form.Control as='select' name='mode' value={modal.formInputs.mode} onChange={this.handleInputChange}>
                  <option value='' hidden>Select mode of payment...</option>
                  {
                    modesOfPayment.map((mop, i) => (
                      <option key={i} value={mop}>{mop}</option>
                    ))
                  }
                  <option value='Other'>Others...</option>
                </Form.Control>
                {
                  modal.formInputs.mode === 'Other' && (
                    <div className='mt-2 pl-3'>
                      <Form.Control type='text' value={modal.formInputs.otherMode} name='otherMode' onChange={this.handleInputChange} placeholder='Please specify...' autoFocus />
                    </div>
                  )
                }
              </Form.Group>
              <Form.Group>
                <Form.Label>Reference Number</Form.Label>
                <Form.Control type='text' name='referenceNumber' value={modal.formInputs.referenceNumber} onChange={this.handleInputChange} disabled={modal.isLoading} />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Transaction Date</Form.Label>
                  <Form.Control type='date' name='date' max={moment().format('YYYY-MM-DD')} value={modal.formInputs.date} onChange={this.handleInputChange} disabled={modal.isLoading} />
                </Form.Group>
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Transaction Time <span className='font-italic text-muted'>(Optional)</span></Form.Label>
                  <InputGroup>
                    <Form.Control type='time' name='time' value={modal.formInputs.time} onChange={this.handleInputChange} disabled={modal.isLoading} />
                    {
                      modal.formInputs.time && (
                        <InputGroup.Append>
                          <Button variant='danger' title='Clear' onClick={this.handleTimeClear}>
                            <FontAwesomeIcon icon='times' />
                          </Button>
                        </InputGroup.Append>
                      )
                    }
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Form.Label>Name of Sender</Form.Label>
                <Form.Control type='text' name='sender' value={modal.formInputs.sender} onChange={this.handleInputChange} disabled={modal.isLoading} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Amount</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>&#8369;</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control type='number' name='amount' value={modal.formInputs.amount} onChange={this.handleInputChange} disabled={modal.isLoading}  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label>Screenshot/Image of Transaction</Form.Label>
                <Form.File 
                  id='transactionImage'
                  label={modal.formInputs.image ? modal.formInputs.image : 'No file chosen'}
                  accept='image/png, image/jpeg'
                  onChange={this.handleImageChange}
                  onClick={e => {e.target.value = null}}
                  disabled={modal.isLoading}
                  custom />
              </Form.Group>
              {
                (modal.data && !modal.formInputs.image) && (
                  <div style={{ height: '7.5rem', textAlign: 'center' }} className='mb-3'>
                    <Image src={modal.data.screenshot} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
                  </div>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' onClick={this.hideModal} disabled={modal.isLoading}>
                Cancel
              </Button>
              <Button type='submit' variant={modal.data ? 'info' : 'green'} disabled={modal.isLoading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal show={viewModal.show} onHide={this.hideViewModal}>
          <Modal.Header closeButton>
            <div>
              <Modal.Title>View Transaction</Modal.Title>
              {
                viewModal.data && (
                  <>
                    {
                      viewModal.data.approved_at ? (
                        <div>
                          <Badge variant='green'>
                            Accepted
                          </Badge>
                          <span className='ml-1 font-italic text-muted'>&mdash; {moment(viewModal.data.approved_at).format('MMM D, YYYY hh:mm A')}</span>
                        </div>
                      ) : viewModal.data.declined_at ? (
                        <div>
                          <Badge variant='danger'>
                            Declined
                          </Badge>
                          <span className='ml-1 font-italic text-muted'>&mdash; {moment(viewModal.data.declined_at).format('MMM D, YYYY hh:mm A')}</span>
                        </div>
                      ) : viewModal.data.read_at ? (
                        <div>
                          <Badge variant='warning'>
                            Reviewing
                          </Badge>
                          <span className='ml-1 font-italic text-muted'>&mdash; {moment(viewModal.data.read_at).format('MMM D, YYYY hh:mm A')}</span>
                        </div>
                      ) : (
                        <Badge variant='light'>
                          Processing
                        </Badge>
                      )
                    }
                  </>
                )
              }
            </div>
          </Modal.Header>
          <Modal.Body>
            {
              viewModal.data && (
                <>
                  <div className='text-right font-italic text-muted'>
                    <FontAwesomeIcon icon='clock' /> {moment(viewModal.data.created_at).format('MMM D, YYYY hh:mm A')}
                  </div>
                  <div style={{ height: '7.5rem', textAlign: 'center' }} className='my-3'>
                    <Image
                      onClick={e => this.handlePreviewImage(viewModal.data.screenshot, viewModal.data.id)}
                      src={viewModal.data.screenshot}
                      style={{ objectFit: 'contain', height: '100%', cursor: 'pointer' }}
                      fluid
                      thumbnail />
                  </div>
                  <div className='h5'>
                    <div>
                      {viewModal.data.mode}
                    </div>
                    <div>
                      &#8369; {viewModal.data.amount.toFixed(2)}
                    </div>
                  </div>
                  <div>
                    <b className='mr-1'>{viewModal.data.mode === 'cash' ? 'OR No.: ' : 'Ref. No.: '}</b>
                    {viewModal.data.reference_number}
                  </div>
                  <div>
                    <b className='mr-1'>Date:</b>
                    {
                      viewModal.data.transaction_time ?
                        moment(`${viewModal.data.transaction_date} ${viewModal.data.transaction_time}`, 'YYYY-MM-DD HH:mm').format('MMM D, YYYY hh:mm A') :
                        moment(viewModal.data.transaction_date).format('MMMM D, YYYY')
                    }
                  </div>
                  <div>
                    <b className='mr-1'>Sender:</b>
                    {viewModal.data.sender_name}
                  </div>
                  {
                    viewModal.data.comment && (
                      <>
                        {
                          viewModal.data.approved_at ? (
                            <>
                              <div className='dropdown-divider'></div>
                              <Alert variant='green' className='mb-0 mt-3'>
                                <div className='d-flex'>
                                  <div>
                                    <FontAwesomeIcon icon='comment' className='fa-fw mr-1' />
                                  </div>
                                  <div>
                                    {viewModal.data.comment}
                                  </div>
                                </div>
                              </Alert>
                            </>
                          ) : viewModal.data.declined_at ? (
                            <>
                              <div className='dropdown-divider'></div>
                              <Alert variant='danger' className='mb-0 mt-3'>
                                <div className='d-flex'>
                                  <div>
                                    <FontAwesomeIcon icon='comment' className='fa-fw mr-1' />
                                  </div>
                                  <div>
                                    {viewModal.data.comment}
                                  </div>
                                </div>
                              </Alert>
                            </>
                          ) : null
                        }
                      </>
                    )
                  }
                </>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={this.hideViewModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <PromptDeleteModal {...deleteModal} title='Delete transaction' onHide={this.hideDeleteModal} onDelete={this.handleDelete}>
          <div className='font-weight-bold'>
            Are you sure you want to delete the transaction?
          </div>
          {
            deleteModal.data && (
              <Alert variant='light'>
                <div>{deleteModal.data.mode}</div>
                <div>
                  {
                    deleteModal.data.transaction_time ?
                      moment(`${deleteModal.data.transaction_date} ${deleteModal.data.transaction_time}`, 'YYYY-MM-DD HH:mm').format('MMM D, YYYY hh:mm A') :
                      moment(deleteModal.data.transaction_date).format('MMMM D, YYYY')
                  }
                </div>
                <div>&#8369; {deleteModal.data.amount.toFixed(2)}</div>
              </Alert>
            )
          }
        </PromptDeleteModal>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(PaymentTransactions);