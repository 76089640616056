import React, { Component } from 'react';
import { Alert, Breadcrumb, Nav } from 'react-bootstrap';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import Questions from './parts/Questions';
import Preview from './parts/Preview';
import Submissions from './parts/Submissions';
import Reschedule from './parts/Reschedule';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Questionable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      material: null,
      isLoading: true,
      errorMessage: '',
    };
  }
  componentDidMount() {
    const { classID, materialType, materialID } = this.props.match.params;
    axiosRequest('get', `school-admin/class/${classID}/material/${materialType}/${materialID}`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        material: data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleMaterials = () => {
    const { classID } = this.props.match.params;
    this.props.history.push(`/school-admin/class/${classID}/materials`);
  }
  handleSelect = key => {
    this.props.history.push(key);
  }
  renderNav = () => {
    const { isLoading, errorMessage, material } = this.state;
    const { activeNav } = this.props.match.params;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }
    
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.handleMaterials}>Materials</Breadcrumb.Item>
          <Breadcrumb.Item active>{material.title}</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <Nav variant='tabs bg-light rounded-top' className='flex-column flex-md-row'>
            <Nav.Item>
              <Nav.Link eventKey='questions' onSelect={this.handleSelect} active={activeNav === 'questions'}>Questions</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='preview' onSelect={this.handleSelect} active={activeNav === 'preview'}>Preview</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='submissions' onSelect={this.handleSelect} active={activeNav === 'submissions'}>Submissions</Nav.Link>
            </Nav.Item>
            {
              material.type === 'exam' && (
                <Nav.Item>
                  <Nav.Link eventKey='reschedule' onSelect={this.handleSelect} active={activeNav === 'reschedule'}>Reschedule</Nav.Link>
                </Nav.Item>
              )
            }
          </Nav>
          <div className='content border border-top-0 rounded-bottom p-3'>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
  renderContent = () => {
    const { material } = this.state;
    const { activeNav } = this.props.match.params;
    
    switch(activeNav) {
      case 'questions':
        return (
          <Questions
            {...this.props}
            type={material.type}
            data={material}
          />
        );
      case 'preview':
        return (
          <Preview
            {...this.props}
            materialData={material}
          />
        );
      case 'submissions':
        return (
          <Submissions
            {...this.props}
            materialData={material}
            onGrade={this.handleGrade}
          />
        );
      case 'reschedule':
        return (
          <Reschedule
            {...this.props}
            materialData={material}
          />
        );
      default:
        return null;
    }
  }
  render() {

    return (
      <div className='border rounded p-4 my-3'>
        {this.renderNav()}
      </div>
    );
  }
}