import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Validator from 'validatorjs';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isLoading: false,
      errorMessage: '',
      success: false
    };
  }
  handleBack = () => {
    this.props.history.goBack();
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      email: event.target.value
    });
  }
  handleRequestReset = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: ''
    }, () => {
      const { email } = this.state;

      let validator = new Validator({
        email
      }, {
        email: 'required|email'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          errorMessage: validator.errors.errors[firstKey][0],
          isLoading: false
        });
        return;
      }

      axios.post(`${process.env['REACT_APP_API_BASE_URL']}/forgot-password`, { email }, { withCredentials: true }).then(({ data }) => {
        this.setState({
          ...this.state,
          isLoading: false,
          success: true
        });
      }).catch((error) => {
        this.setState({
          ...this.state,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          isLoading: false
        });
      });
    });
  }
  render() {
    const { isLoading, errorMessage, success } = this.state;

    return (
      <div className='bg-secondary d-flex flex-column align-items-center justify-content-center' style={{ minHeight: '100vh' }}>
        <Col lg={5}>
          <Card>
            <Card.Body>
              <div>
                <Button variant='link' onClick={this.handleBack}>
                  <FontAwesomeIcon icon='arrow-left' /> Go back
                </Button>
              </div>
              {
                success ? (
                  <Alert variant='success mt-2'>
                    <b className='d-block font-weight-bold'>Successfully requested for a password reset.</b>
                    Please check your email to proceed.
                  </Alert>
                ) : (
                  <div className='m-3'>
                    {
                      errorMessage && (
                        <Alert variant='danger'>
                          {errorMessage}
                        </Alert>
                      )
                    }
                    <Form onSubmit={this.handleRequestReset}>
                      <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type='email' onChange={this.handleInputChange} disabled={isLoading}/>
                      </Form.Group>
                      <div className='text-right'>
                        <Button variant='info' type='submit' disabled={isLoading}>
                          Request password reset
                        </Button>
                      </div>
                    </Form>
                  </div>
                )
              }
            </Card.Body>
          </Card>
        </Col>
      </div>
    );
  }
}