import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import CheckInOut from '../CheckInOut/CheckInOut';
import Announcements from '../Announcements/Announcements';
import Events from '../Events/Events';
import DayQuestion from '../DayQuestion/DayQuestion';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const blacklist = [
  '/student',
  '/parent',
  '/faculty',
  '/faculty/announcement',
  '/faculty/events',
  '/staff',
  '/staff/events',
  '/school-admin',
  '/school-admin/announcement',
  '/school-admin/events'
];

class FloatingButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
  }
  renderToggleButton = () => {
    const { show } = this.state;

    if (show) {
      return (
        <div>
          <Button variant='light' style={{ borderRadius: '50%' }} title='Minimize buttons' onClick={this.handleToggleButton}>
            <FontAwesomeIcon icon='compress-alt' />
          </Button>
        </div>
      );
    }

    return (
      <div>
        <Button variant='light' style={{ borderRadius: '50%' }} title='Expand buttons' onClick={this.handleToggleButton}>
          <FontAwesomeIcon icon='expand-alt' />
        </Button>
      </div>
    );
  }
  handleToggleButton = () => {
    this.setState({
      ...this.state,
      show: !this.state.show
    });
  }
  render() {
    const { show } = this.state;
    const { currentUser, location } = this.props;

    if (!currentUser) {
      return null;
    }

    return (
      <div className='float-container'>
        {this.renderToggleButton()}
        {
          show && (
            <>
              {
                (
                  location &&
                  location.pathname &&
                  blacklist.indexOf(location.pathname) === -1 &&
                  location.pathname.search('/assignment/') === -1 &&
                  location.pathname.search('/quiz/') === -1 &&
                  location.pathname.search('/exam/') === -1
                ) && (
                  <>
                    <div>
                      <Events {...this.props} />
                    </div>
                    <div>
                      <Announcements {...this.props} />
                    </div>
                  </>
                )
              }
              {
                (
                  location &&
                  location.pathname &&
                  location.pathname.search('/question-of-the-day') === -1 &&
                  location.pathname.search('/assignment/') === -1 &&
                  location.pathname.search('/quiz/') === -1 &&
                  location.pathname.search('/exam/') === -1
                ) && (
                  <div>
                    <DayQuestion {...this.props} />
                  </div>
                )
              }
              {
                currentUser.account_type === 2 && (
                  <div>
                    <CheckInOut {...this.props} />
                  </div>
                )
              }
            </>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(FloatingButtons);