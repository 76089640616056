import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../util/helpers/axiosRequest';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

export default class ClassAdvisory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      facultyID: '',
      faculty: [],
      school_classes: [],
      isSchoolClassesLoading: false,
      classAdvisory: [],
      loadingSchoolClasses: [],
      errorMessage: '',
      schoolClassError: '',
      formError: '',
      searchQuery: ''
    };
  }
  componentDidMount() {
    axiosRequest('get', 'admin/class-advisory/options', null, ({ data: { data }}) => {
      data.faculty.sort((a, b) => {
        if (a.last_name < b.last_name) {
          return -1;
        } else if (a.last_name > b.last_name) {
          return 1;
        }

        return 0;
      });
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  removeFromLoading = id => {
    const loadingSchoolClasses = [...this.state.loadingSchoolClasses].filter(loadingSchoolClass => {
      return loadingSchoolClass !== id;
    });
    this.setState({
      ...this.state,
      loadingSchoolClasses
    });
  }
  handleAssign = id => {
    const { facultyID } = this.state;
    this.setState({
      ...this.state,
      loadingSchoolClasses: [
        ...this.state.loadingSchoolClasses,
        id
      ]
    }, () => {
      axiosRequest('post', 'admin/class-advisory', {
        facultyID,
        schoolClassID: id
      }, ({ data: { data } }) => {
        const { school_classes } = this.state;
        let class_advisory = {...data};
        let newSchoolClasses = [...school_classes].map(schoolClass => {
          if (schoolClass.id === data.school_class_id_id) {
            schoolClass.class_advisory = class_advisory;
          }

          return schoolClass;
        });

        this.setState({
          ...this.state,
          school_classes: newSchoolClasses,
          classAdvisory: [
            ...this.state.classAdvisory,
            data
          ]
        }, () => {
          this.removeFromLoading(id);
        });
      }, error => {
        this.setState({
          ...this.state,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }, () => {
          this.removeFromLoading(id);
        });
      }, this.props.history);
    });
  }
  handleUnassign = (id, schoolClassID) => {
    this.setState({
      ...this.state,
      loadingSchoolClasses: [
        ...this.state.loadingSchoolClasses,
        schoolClassID
      ]
    }, () => {
      axiosRequest('delete', `admin/class-advisory/${id}`, null, ({ data: { data } }) => {
        const class_advisory = [...this.state.classAdvisory].filter(item => {
          return item.id !== id;
        });
        let newSchoolClasses = [...this.state.school_classes].map(schoolClass => {
          if (schoolClass.class_advisory && schoolClass.class_advisory.id === id) {
            schoolClass.class_advisory = null;
          }

          return schoolClass;
        })
        this.setState({
          ...this.state,
          school_classes: newSchoolClasses,
          classAdvisory: [
            ...class_advisory
          ]
        }, () => {
          this.removeFromLoading(schoolClassID);
        });
      }, (error) => {
        this.setState({
          ...this.state,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }, () => {
          this.removeFromLoading(schoolClassID);
        });
      }, this.props.history);
    });
  }
  handleFacultyChange = event => {
    const facultyID = event.target.value;
    this.setState({
      isSchoolClassLoading: true
    }, () => {
      axiosRequest('get', `admin/class-advisory/${facultyID}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          facultyID,
          classAdvisory: [...data],
          isSchoolClassLoading: false
        });
      }, error => {
        this.setState({
          ...this.state,
          isSchoolClassLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleSearchInputChange = event => {
    this.setState({
      ...this.state,
      searchQuery: event.target.value
    });
  }
  renderAssignedTo = schoolClass => {
    const { faculty } = this.state;
    
    let assignedTo = faculty.find(f => f.id === schoolClass.class_advisory.faculty_id);
    if (assignedTo) {
      return (
        <div className='font-italic text-right'>
          The class is already assigned to {assignedTo.name} - {assignedTo.position}.
        </div>
      );
    }

    return null;
  }
  renderSchoolClasses = () => {
    const {
      school_classes,
      facultyID,
      isSchoolClassLoading,
      classAdvisory,
      loadingSchoolClasses,
      schoolClassError,
      formError,
      searchQuery,
      faculty
    } = this.state;

    if (isSchoolClassLoading) {
      return (
        <LoadingIcon />
      );
    }
    
    if (schoolClassError) {
      return (
        <Alert variant='danger'>
          {schoolClassError}
        </Alert>
      );
    }

    if (!facultyID) {
      return;
    }

    let searchFilteredSchoolClasses = [...school_classes];
    let searchFilteredClassAdvisories = [...classAdvisory];

    if (searchQuery.toString()) {
      searchFilteredSchoolClasses = [...school_classes].filter(f => (
        f.program.code.indexOf(searchQuery) > -1 ||
        f.program.description.indexOf(searchQuery) > -1 ||
        f.year_level.code.indexOf(searchQuery) > -1 ||
        f.year_level.description.indexOf(searchQuery) > -1 ||
        f.section.code.indexOf(searchQuery) > -1
      ));
      searchFilteredClassAdvisories = [...classAdvisory].filter(f => (
        f.school_class.program.code.indexOf(searchQuery) > -1 ||
        f.school_class.program.description.indexOf(searchQuery) > -1 ||
        f.school_class.year_level.code.indexOf(searchQuery) > -1 ||
        f.school_class.year_level.description.indexOf(searchQuery) > -1 ||
        f.school_class.section.code.indexOf(searchQuery) > -1
      ));
    }
    
    let assignedClasses = [];
    for (let i = 0; i < classAdvisory.length; i++) {
      assignedClasses.push(classAdvisory[i].school_class.id);
    }

    let filteredSchoolClasses = [...searchFilteredSchoolClasses].filter(school_class => {
      return assignedClasses.indexOf(school_class.id) === -1;
    });

    let alreadyAssignedSchoolClasses = [...filteredSchoolClasses].filter(filteredSchoolClass => {
      return filteredSchoolClass.class_advisory !== null;
    });

    let selectedFaculty = faculty.find(f => f.id === +facultyID);

    return (
      <>
        {
          selectedFaculty && (
            <Card.Title>
              <div>{`${selectedFaculty.name}`}</div>
              <div>{`${selectedFaculty.position}`}</div>
            </Card.Title>
          )
        }
        {
          formError && (
            <Alert variant='danger'>
              {formError}
            </Alert>
          )
        }
        <Row>
          <Col lg={6} className='ml-auto'>
            <div className='mb-2 mt-1'>
              <Form.Control type='search' placeholder='Search classrooms...' value={searchQuery} onChange={this.handleSearchInputChange} />
            </div>
          </Col>
        </Row>
        {
          searchFilteredSchoolClasses.length > 0 || searchFilteredClassAdvisories.length > 0 ? (
            <ListGroup>
              {
                searchFilteredClassAdvisories.map(advisory => (
                  <ListGroup.Item key={advisory.school_class.id} variant='green'>
                    <div className='d-flex'>
                      <div className='pr-2'>
                        {
                          loadingSchoolClasses.indexOf(advisory.school_class.id) !== -1 ? (
                            <LoadingIcon />
                          ) : (
                            <FontAwesomeIcon icon='check-circle' />
                          )
                        }
                      </div>
                      <div className='flex-fill'>
                        <b>{`${advisory.school_class.program.description} (${advisory.school_class.program.code})`}</b>
                        <div className='font-weight-bold'>
                          {`${advisory.school_class.year_level.description}
                          (${advisory.school_class.year_level.code}) - ${advisory.school_class.section.code}`}
                        </div>
                      </div>
                      <div className='d-flex align-items-center'>
                        <Button
                          size='sm'
                          variant='danger'
                          onClick={() => this.handleUnassign(advisory.id, advisory.school_class.id)}
                          disabled={loadingSchoolClasses.indexOf(advisory.school_class.id) !== -1}
                          >
                            Unassign <FontAwesomeIcon icon='angle-double-right' />
                        </Button>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))
              }
              {
                filteredSchoolClasses.map(schoolClass => {
                  return schoolClass.class_advisory === null ? (
                    <ListGroup.Item key={schoolClass.id}>
                      <div className='d-flex'>
                        {
                          loadingSchoolClasses.indexOf(schoolClass.id) !== -1 ? (
                            <div className='pr-2'>
                              <LoadingIcon />
                            </div>
                          ) : (
                            <div className='pr-4'></div>
                          )
                        }
                        <div className='flex-fill'>
                          <b>{`${schoolClass.program.description} (${schoolClass.program.code})`}</b>
                          <div className='font-weight-bold'>
                            {`${schoolClass.year_level.description}
                            (${schoolClass.year_level.code}) - ${schoolClass.section.code}`}
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <Button
                            size='sm'
                            variant='green'
                            onClick={() => this.handleAssign(schoolClass.id)}
                            disabled={loadingSchoolClasses.indexOf(schoolClass.id) !== -1}>
                            Assign <FontAwesomeIcon icon='angle-double-right' /></Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ) : null;
                })
              }
              {
                alreadyAssignedSchoolClasses.map(schoolClass => (
                  <ListGroup.Item key={schoolClass.id} className='bg-light' style={{ opacity: '.9' }}>
                    <div className='d-flex'>
                      {
                        loadingSchoolClasses.indexOf(schoolClass.id) !== -1 ? (
                          <div className='pr-2'>
                            <LoadingIcon />
                          </div>
                        ) : (
                          <div className='pr-4'></div>
                        )
                      }
                      <div className='flex-fill'>
                        <b>{`${schoolClass.program.description} (${schoolClass.program.code})`}</b>
                        <div className='font-weight-bold'>
                          {`${schoolClass.year_level.description}
                          (${schoolClass.year_level.code}) - ${schoolClass.section.code}`}
                        </div>
                        { this.renderAssignedTo(schoolClass) }
                      </div>
                    </div>
                  </ListGroup.Item>
                ))
              }
            </ListGroup>
          ) : searchQuery ? (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          ) : (
            <Alert variant='warning'>
              Nothing to show. Try adding a <b>school class</b> first.
            </Alert>
          )
        }
        
      </>
    );
  }
  renderContent = () => {
    const { isLoading, faculty, errorMessage } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    
    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (faculty.length === 0) {
      return (
        <Alert variant='warning'>
          Nothing to show. Try adding a <b>faculty</b> first.
        </Alert>
      );
    }

    return (
      <>
        <Form.Group>
          <Form.Label>Faculty</Form.Label>
          <Form.Control as='select' onChange={this.handleFacultyChange}>
            <option disabled hidden selected>Select a faculty to assign...</option>
            {
              faculty.map((f, index) => (
                <option key={index} value={f.id}>{`${f.name} - ${f.position}`}</option>
              ))
            }
          </Form.Control>
        </Form.Group>
        <div className='dropdown-divider'></div>
        { this.renderSchoolClasses() }
      </>
    );
  }
  render() {
    return (
      <Card>
        <Card.Body>
          { this.renderContent() }
        </Card.Body>
      </Card>
    );
  }
}