import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Card, Col, Dropdown, Form, InputGroup, Modal, Pagination, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddUserModal from '../../modals/AddUserModal/AddUserModal';
import ViewUserModal from '../../modals/ViewUserModal/ViewUserModal';
import EditUserModal from '../../modals/EditUserModal/EditUserModal';
import PromptDeleteModal from '../../modals/PromptDeleteModal/PromptDeleteModal';
import PromptResetPasswordModal from '../../modals/PromptResetPasswordModal/PromptResetPasswordModal';
import axiosRequest from '../../../util/helpers/axiosRequest';
import UserImportModal from '../../modals/UserImportModal/UserImportModal';
import Validator from 'validatorjs';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

const deleteTitleMap = {
  students: 'student',
  parents: 'parent',
  staff: 'staff',
  faculty: 'faculty',
  'school-admins': 'school administrator',
  'web-admins': 'web administrator'
};

const exportTitleMap = {
  students: 'students',
  parents: 'parents',
  staff: 'staff',
  faculty: 'faculty',
  'school-admins': 'school administrators',
  'web-admins': 'web administrators'
};

const accountType = {
  students: 5,
  parents: 4,
  staff: 3,
  faculty: 2,
  'school-admins': 1,
  'web-admins': 0
};

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importModalShow: false,
      addModalShow: false,
      exportModalShow: false,
      loginCredentials: {
        option: 'all',
        subOption: ''
      },
      users: {
        data: []
      },
      options: {
        programs: [],
        yearLevels: [],
        classes: [],
        students: [],
        faculty: [],
        schoolLevels: []
      },
      isLoading: true,
      errorMessage: '',
      exportModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        name: '',
        phone: '',
        data: null,
        copySuccess: ''
      },
      viewModal: {
        show: false,
        data: null
      },
      editModal: {
        show: false,
        data: null
      },
      resetModal: {
        show: false,
        data: null,
        errorMessage: '',
        successMessage: ''
      },
      deleteModal: {
        show: false,
        data: null,
        errorMessage: ''
      },
      activeModal: {
        show: false,
        active: null,
        data: null,
        isLoading: false,
        errorMessage: ''
      },
      roleModal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        user: null,
        data: null,
        accountTypes: [],
        isFormLoading: false,
        formError: ''
      },
      schoolAdminOptions: {
        isLoading: false,
        errorMessage: '',
        data: null,
        formInputs: {
          scopes: []
        }
      },
      search: {
        isLoading: false,
        errorMessage: '',
        query: '',
        isSearch: false
      }
    }
  }
  componentDidMount() {
    this.getUsers(this.props.match.params.activeSub);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.activeSub !== this.props.match.params.activeSub) {
      this.setState({
        ...this.state,
        isLoading: true
      }, () => {
        this.getUsers(this.props.match.params.activeSub);
      });
    }
  }
  getUsers = (activeSub) => {
    axiosRequest('get', `admin/account/type/${accountType[activeSub]}`, null, ({ data: { data } }) => {
      if (activeSub === 'students' || activeSub === 'parents') {
        data.options.classes.sort((a, b) => {
          if (a.program.code < b.program.code) {
            return -1;
          } else if (a.program.code > b.program.code) {
            return 1;
          }
  
          if (a.year_level.code < b.year_level.code) {
            return -1;
          } else if (a.year_level.code > b.year_level.code) {
            return 1;
          }
  
          if (a.section.code < b.section.code) {
            return -1;
          } else if (a.section.code > b.section.code) {
            return 1;
          }
  
          return 0;
        });
        data.options.programs.sort((a, b) => {
          if (a.code < b.code) {
            return -1;
          } else if (a.code > b.code) {
            return 1;
          }

          return 0;
        });
        this.setState({
          ...this.state,
          users: data.users,
          options: {
            ...this.state.options,
            ...data.options
          },
          isLoading: false,
          search: {
            isLoading: false,
            errorMessage: '',
            query: '',
            isSearch: false
          }
        });
      } else if (activeSub === 'school-admins') {
        this.setState({
          ...this.state,
          users: data.users,
          options: {
            ...this.state.options,
            ...data.options
          },
          isLoading: false,
          search: {
            isLoading: false,
            errorMessage: '',
            query: '',
            isSearch: false
          }
        });
      } else {
        this.setState({
          ...this.state,
          users: data,
          isLoading: false,
          search: {
            isLoading: false,
            errorMessage: '',
            query: '',
            isSearch: false
          }
        });
      }
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleImportModal = () => {
    this.setState({
      ...this.state,
      importModalShow: !this.state.importModalShow
    });
  }
  handleExportModal = () => {
    this.setState({
      ...this.state,
      exportModalShow: !this.state.exportModalShow
    });
  }
  handleAddUserModal = () => {
    this.setState({
      ...this.state,
      addModalShow: !this.state.addModalShow
    });
  }
  handleAddSuccess = (data, callback = () => {}) => {
    this.setState({
      ...this.state,
      users: {
        ...this.state.users,
        data: [
          ...this.state.users.data,
          {...data}
        ]
      }
    }, callback);
  }
  handleImportSuccess = (data) => {
    this.setState({
      ...this.state,
      users: {
        ...this.state.users,
        data: [
          ...this.state.users.data,
          ...data
        ]
      },
      importModalShow: !this.state.importModalShow
    });
  }
  handleEmergencyContactSuccess = data => {
    const newUsersData = [...this.state.users.data].map(user => {
      let updatedUser = data.find(d => d.id === user.id);
      if (updatedUser) {
        return {
          ...user,
          profile: {
            ...user.profile,
            emergency_contact: updatedUser
          }
        };
      }

      return {...user};
    });
    this.setState({
      ...this.state,
      users: {
        ...this.state.users,
        data: newUsersData
      },
      importModalShow: !this.state.importModalShow
    });
  }
  handleUpdateImageSuccess = data => {
    let newUsers = [...this.state.users.data].map(user => {
      let newUser = data.find(d => d.id === user.id);
      if (newUser) {
        return {
          ...user,
          profile: {
            ...user.profile,
            ...newUser
          }
        };
      }

      return user;
    });
    this.setState({
      ...this.state,
      users: {
        ...this.state.users,
        data: newUsers
      },
      importModalShow: !this.state.importModalShow
    });
  }
  showExportModal = (id, name, phone) => {
    this.setState({
      ...this.state,
      exportModal: {
        ...this.state.exportModal,
        show: true,
        isLoading: true,
        name,
        phone
      }
    }, () => {
      axiosRequest('get', `admin/account/id/${id}/generated-credential`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          exportModal: {
            ...this.state.exportModal,
            isLoading: false,
            data
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          exportModal: {
            ...this.state.exportModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  hideExportModal = () => {
    this.setState({
      ...this.state,
      exportModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        name: '',
        phone: '',
        data: null,
        copySuccess: ''
      }
    });
  }
  showViewModal = (id) => {
    const { options } = this.state;
    let user = [...this.state.users.data].find(user => user.id === id);

    if (user) {
      if (user.account_type === 1 && user.school_admin_scopes.length > 0) {
        user.school_admin_scopes = user.school_admin_scopes.map(scope => {
          let schoolLevel = options.schoolLevels.find(sl => sl.id === scope.school_level_id);
          if (schoolLevel) {
            return {
              ...scope,
              school_level: schoolLevel
            };
          }

          return {...scope};
        });
      }

      this.setState({
        ...this.state,
        viewModal: {
          show: true,
          data: {...user}
        }
      });
    }
  }
  hideViewModal = () => {
    this.setState({
      ...this.state,
      viewModal: {
        show: false,
        data: null
      }
    });
  }
  showEditModal = (id) => {
    const user = [...this.state.users.data].filter(user => {
      return user.id === id;
    });

    this.setState({
      ...this.state,
      editModal: {
        show: true,
        data: {...user[0]}
      }
    });
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        data: null
      }
    });
  }
  handleEditSuccess = (data, callback = () => {}) => {
    const users = [...this.state.users.data].map(user => {
      if (user.id === data.id) {
        return data;
      }

      return user;
    });
    this.setState({
      ...this.state,
      users: {
        ...this.state.users,
        data: [...users]
      }
    }, callback);
  }
  showDeleteModal = (id) => {
    const user = [...this.state.users.data].filter(user => {
      return user.id === id;
    });

    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        data: {...user[0]},
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        data: null,
        errorMessage: ''
      }
    });
  }
  handleDelete = () => {
    const deleteModal = {...this.state.deleteModal};

    axiosRequest('delete', `admin/account/id/${deleteModal.data.id}`, null, (res) => {
      const users = [...this.state.users.data].filter((p) => {
        return p.id !== deleteModal.data.id;
      });
      this.setState({
        ...this.state,
        users: {
          ...this.state.users,
          data: [
            ...users
          ]
        },
        deleteModal: {
          show: false,
          data: null,
          errorMessage: ''
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        deleteModal: {
          ...this.state.deleteModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  showResetModal = (id) => {
    const user = [...this.state.users.data].filter(user => {
      return user.id === id;
    });

    this.setState({
      ...this.state,
      resetModal: {
        show: true,
        data: {...user[0]},
        errorMessage: '',
        successMessage: ''
      }
    });
  }
  hideResetModal = () => {
    this.setState({
      ...this.state,
      resetModal: {
        show: false,
        data: null,
        errorMessage: '',
        successMessage: ''
      }
    });
  }
  handleReset = () => {
    const resetModal = {...this.state.resetModal};

    axiosRequest('post', 'admin/account/reset', {
      userID: resetModal.data.id
    }, ({ data: { message } }) => {
      this.setState({
        ...this.state,
        resetModal: {
          show: false,
          data: null,
          errorMessage: '',
          successMessage: message
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        resetModal: {
          ...this.state.resetModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handlePagination = url => {
    if (url) {
      this.setState({
        ...this.state,
        isLoading: true,
        errorMessage: ''
      }, () => {
        const path = url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
        const { activeSub } = this.props.match.params;
        const { search } = this.state;

        let config = {};
        if (search.query && search.isSearch) {
          config = {
            params: {
              searchQuery: search.query
            }
          };
        }

        axiosRequest('get', path, null, ({ data: { data }}) => {
          if (activeSub === 'students' || activeSub === 'parents') {
            this.setState({
              ...this.state,
              users: data.users,
              options: {
                ...this.state.options,
                ...data.options
              },
              isLoading: false,
              errorMessage: ''
            });
          } else {
            this.setState({
              ...this.state,
              users: data,
              isLoading: false,
              errorMessage: ''
            });
          }
        }, (error) => {
          this.setState({
            ...this.state,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history, {
          ...config
        });
      });
    }
  }
  handleSearchInputChange = event => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        query: event.target.value
      }
    });
  }
  handleSearch = event => {
    event.preventDefault();

    const { search } = this.state;

    if (!search.query && !search.isSearch) {
      return;
    }

    this.setState({
      ...this.state,
      search: {
        ...search,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { activeSub } = this.props.match.params;

      axiosRequest('get', `admin/account/type/${accountType[activeSub]}/search`, null, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          users: (activeSub === 'students' || activeSub === 'parents') ? data.users : data,
          search: {
            ...search,
            isLoading: false,
            errorMessage: '',
            isSearch: !!search.query
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          search: {
            ...search,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history, {
        params: {
          searchQuery: search.query
        }
      });
    });
  }
  handleExportOptions = event => {
    if (event.target.checked) {
      this.setState({
        ...this.state,
        loginCredentials: {
          ...this.state.loginCredentials,
          option: event.target.value,
          subOption: ''
        }
      });
    }
  }
  handleExportSubOptions = event => {
    this.setState({
      ...this.state,
      loginCredentials: {
        ...this.state.loginCredentials,
        subOption: event.target.value
      }
    });
  }
  copyGeneratedCredentials = event => {
    const { exportModal } = this.state;
    let textArea = document.createElement('textarea');
    textArea.innerHTML = `${exportModal.name}\nUsername: ${exportModal.data.username}\nPassword: ${exportModal.data.password}`;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    this.setState({
      ...this.state,
      exportModal: {
        ...this.state.exportModal,
        copySuccess: 'Copied to clipboard!'
      }
    });
  }
  showActiveModal = (event, user) => {
    this.setState({
      ...this.state,
      activeModal: {
        show: true,
        active: event.target.checked,
        data: user,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideActiveModal = () => {
    this.setState({
      ...this.state,
      activeModal: {
        show: false,
        active: null,
        data: null,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleActive = () => {
    this.setState({
      ...this.state,
      activeModal: {
        ...this.state.activeModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { activeModal } = this.state;

      axiosRequest('post', `admin/account/id/${activeModal.data.id}`, {
        isActive: activeModal.active ? 1 : 0
      }, ({ data: { data }}) => {
        const newUsersData = [...this.state.users.data].map(u => {
          if (u.id === data.id) {
            u.is_active = data.is_active;
          }

          return u;
        });
        this.setState({
          ...this.state,
          users: {
            ...this.state.users,
            data: newUsersData
          },
          activeModal: {
            show: false,
            active: null,
            data: null,
            isLoading: false,
            errorMessage: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          activeModal: {
            ...this.state.activeModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showRoleModal = user => {
    this.setState({
      ...this.state,
      roleModal: {
        ...this.state.roleModal,
        show: true,
        isLoading: true,
        errorMessage: '',
        user,
        isFormLoading: false,
        formError: ''
      }
    }, () => {
      axiosRequest('get', `admin/account/id/${user.id}/account-roles`, null, ({ data: { data }}) => {
        let accountTypes = [];
        if (data.account_roles.length > 0) {
          accountTypes = data.account_roles.map(d => d.account_type);
        } else {
          accountTypes = [user.account_type];
        }
        let custom = {};
        if (data.school_admin_scopes.length > 0) {
          custom['schoolAdminOptions'] = {
            isLoading: true,
            errorMessage: '',
            data: null,
            formInputs: {
              scopes: data.school_admin_scopes.map(sas => sas.school_level_id)
            }
          };
        }
        this.setState({
          ...this.state,
          ...custom,
          roleModal: {
            ...this.state.roleModal,
            data,
            accountTypes,
            isLoading: false
          }
        }, () => {
          this.getRoleOptions();
        });
      }, error => {
        this.setState({
          ...this.state,
          roleModal: {
            ...this.state.roleModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  hideRoleModal = () => {
    this.setState({
      ...this.state,
      roleModal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        user: null,
        data: null,
        accountTypes: [],
        isFormLoading: false,
        formError: ''
      },
      schoolAdminOptions: {
        isLoading: false,
        errorMessage: '',
        data: null,
        formInputs: {
          scopes: []
        }
      }
    });
  }
  handleAccountTypeSelect = e => {
    const { roleModal } = this.state;
    const { value, checked } = e.target;

    let newAccountTypes = roleModal.accountTypes.filter(at => at !== +value);

    if (checked) {
      newAccountTypes = [...newAccountTypes, +value];
      this.setState({
        ...this.state,
        roleModal: {
          ...this.state.roleModal,
          accountTypes: newAccountTypes
        }
      }, () => {
        this.getRoleOptions(+value);
      });
    } else {
      this.setState({
        ...this.state,
        roleModal: {
          ...this.state.roleModal,
          accountTypes: newAccountTypes
        }
      });
    }
  }
  getRoleOptions = (accountType = null) => {
    if (accountType !== null) {
      let key = '';
      switch (accountType) {
        case 1:
          key = 'schoolAdminOptions';
          break;
        default:
          break;
      }

      if (!key) {
        this.setState({
          ...this.state,
          roleModal: {
            ...this.state.roleModal,
            errorMessage: 'Role(s) not found.'
          }
        });
      }

      this.setState({
        ...this.state,
        [key]: {
          ...this.state[key],
          isLoading: true,
          errorMessage: ''
        }
      }, () => {
        axiosRequest('get', `admin/account/role-options/${accountType}`, null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            [key]: {
              ...this.state[key],
              isLoading: false,
              data
            }
          });
        }, error => {
          this.setState({
            ...this.state,
            [key]: {
              ...this.state[key],
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        }, this.props.history);
      });
    } else {
      const { roleModal, schoolAdminOptions } = this.state;

      axiosRequest('post', 'admin/account/role-options', {
        accountTypes: roleModal.accountTypes
      }, ({ data: { data }}) => {
        let custom = {};
        for (let i = 0; i < roleModal.accountTypes.length; i++) {
          switch (roleModal.accountTypes[i]) {
            case 1:
              custom['schoolAdminOptions'] = {
                ...schoolAdminOptions,
                isLoading: false,
                errorMessage: '',
                data: {
                  schoolLevels: data.schoolLevels
                }
              };
              break;
            default:
              break;
          }
        }
        this.setState({
          ...this.state,
          ...custom
        });
      }, error => {
        this.setState({
          ...this.state,
          roleModal: {
            ...this.state.roleModal,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    }
  }
  handleSchoolAdminOptions = e => {
    const { schoolAdminOptions: { formInputs: { scopes } } } = this.state;
    const { checked, value } = e.target;

    let newScopes = [...scopes].filter(s => +s !== +value);
    if (checked) {
      newScopes = [...newScopes, +value];
    }

    this.setState({
      ...this.state,
      schoolAdminOptions: {
        ...this.state.schoolAdminOptions,
        formInputs: {
          ...this.state.formInputs,
          scopes: [...newScopes]
        }
      }
    });
  }
  handleManageRole = () => {
    const { roleModal, schoolAdminOptions } = this.state;

    this.setState({
      ...this.state,
      roleModal: {
        ...this.state.roleModal,
        isFormLoading: true,
        formError: ''
      }
    }, () => {
      let inputs = {
        accountTypes: roleModal.accountTypes.filter(at => at !== roleModal.user.account_type)
      };
      let rules = {
        accountTypes: 'present|array',
        'accountTypes.*': 'integer|min:0'
      };
      if (inputs.accountTypes.indexOf(1) !== -1) {
        inputs['schoolAdminScopes'] = schoolAdminOptions.formInputs.scopes;
        rules['schoolAdminScopes'] = 'present|array';
        rules['schoolAdminScopes.*'] = 'integer|min:1';
      }

      let validation = new Validator(inputs, rules);

      if (validation.fails()) {
        let firstKey = Object.keys(validation.errors.errors)[0];
        this.setState({
          ...this.state,
          roleModal: {
            ...this.state.roleModal,
            isFormLoading: false,
            formError: validation.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', `admin/account/id/${roleModal.user.id}/account-roles`, inputs, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          roleModal: {
            show: false,
            isLoading: true,
            errorMessage: '',
            user: null,
            data: null,
            accountTypes: [],
            isFormLoading: false,
            formError: ''
          },
          schoolAdminOptions: {
            isLoading: false,
            errorMessage: '',
            data: null,
            formInputs: {
              scopes: []
            }
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          roleModal: {
            ...this.state.roleModal,
            isFormLoading: false,
            formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  renderRoleOptions = (roleType) => {
    const { roleModal } = this.state;

    if (roleType === 1) {
      let { schoolAdminOptions: { isLoading, errorMessage, data, formInputs: { scopes } } } = this.state;

      if (roleModal.accountTypes.indexOf(roleType) === -1) {
        return null;
      }

      if (isLoading) {
        return (
          <LoadingIcon sm />
        );
      }
  
      if (errorMessage) {
        return (
          <Alert variant='danger'>
            {errorMessage}
          </Alert>
        );
      }

      return (
        <Form.Group className='px-3 mt-2'>
          <Form.Label>
            School Level Scope
          </Form.Label>
          {
            (data && data.schoolLevels) && (
              <div>
                {
                  data.schoolLevels.map(schoolLevel => (
                    <Form.Check
                      key={schoolLevel.id}
                      type='checkbox'
                      name='scope'
                      value={schoolLevel.id}
                      id={`scope-${schoolLevel.id}`}
                      label={schoolLevel.title}
                      inline
                      onChange={this.handleSchoolAdminOptions}
                      checked={scopes.indexOf(schoolLevel.id) !== -1}
                      disabled={roleModal.isFormLoading} />
                  ))
                }
              </div>
            )
          }
          <Form.Text className='text-muted'>
            <FontAwesomeIcon icon='info-circle' /> When none is selected, the scope will <b>not</b> be limited to any school level.
          </Form.Text>
        </Form.Group>
      );
    }

    return null;
  }
  renderTable = () => {
    const { users, isLoading, errorMessage, search } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    const { currentUser, match: { params: { activeSub } } } = this.props;
    
    return (
      <>
        {
          search.errorMessage && (
            <Alert variant='danger'>
              {search.errorMessage}
            </Alert>
          )
        }
        <Row>
          <Col lg={6} className='ml-auto'>
            <div className='mb-2 mt-1'>
              <Form onSubmit={this.handleSearch}>
                <InputGroup>
                  <Form.Control type='search' placeholder={`Search ${activeSub}...`} value={search.query} onChange={this.handleSearchInputChange} />
                  <InputGroup.Append>
                    <Button variant='primary' type='submit' disabled={search.isLoading}>
                      {
                        search.isLoading ? (
                          <LoadingIcon />
                        ) : (
                          <FontAwesomeIcon icon='search' />
                        )
                      }
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </div>
          </Col>
        </Row>
        {
          users.data.length > 0 ? (
            <>
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Active</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    users.data.map((user, index) => (
                      <tr key={index}>
                        <td>{user.id}</td>
                        <td>{user.username}</td>
                        <td>{user.profile.name}</td>
                        <td className='text-center'>
                          {
                            currentUser.id !== user.id && (
                              <Form.Check
                                type='switch'
                                id={`activeAccount-${user.id}`}
                                label=''
                                checked={user.is_active}
                                onChange={e => this.showActiveModal(e, user)} />
                            )
                          }
                        </td>
                        <td className='text-center'>
                          <Button
                            variant='warning'
                            size='sm'
                            title='View'
                            className='mx-1'
                            onClick={(e) => this.showViewModal(user.id)}>
                            <FontAwesomeIcon icon='eye' />
                          </Button>
                          <Button
                            variant='primary'
                            size='sm'
                            title='Edit'
                            className='mx-1'
                            onClick={(e) => this.showEditModal(user.id)}>
                            <FontAwesomeIcon icon='pencil-alt' />
                          </Button>
                          {
                            currentUser.id !== user.id && (
                              <Button
                                variant='danger'
                                size='sm'
                                title='Delete'
                                className='mx-1'
                                onClick={(e) => this.showDeleteModal(user.id)}>
                                <FontAwesomeIcon icon='trash-alt' />
                              </Button>
                            )
                          }
                          <Dropdown className='d-inline' drop='left'>
                            <Dropdown.Toggle as={Button} variant='info' size='sm' className='mx-1 px-2'>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {
                                user.is_first_login === true && (
                                  <Dropdown.Item onClick={(e) => this.showExportModal(user.id, user.profile.name, user.profile.phone)}>
                                    <FontAwesomeIcon icon='file-export' /> Export login credentials
                                  </Dropdown.Item>
                                )
                              }
                              <Dropdown.Item onClick={e => this.showResetModal(user.id)}>
                                <FontAwesomeIcon icon='reply' /> Reset password
                              </Dropdown.Item>
                              {
                                user.account_type === 2 && (
                                  <Dropdown.Item onClick={e => this.showRoleModal(user)}>
                                    <FontAwesomeIcon icon='user-friends' /> Manage roles
                                  </Dropdown.Item>
                                )
                              }
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <Pagination className='justify-content-end'>
                <Pagination.First onClick={() => this.handlePagination(users.first_page_url)} disabled={users.current_page === 1} />
                <Pagination.Prev onClick={() => this.handlePagination(users.prev_page_url)} disabled={users.current_page === 1} />
                <Pagination.Next onClick={() => this.handlePagination(users.next_page_url)} disabled={users.current_page === users.last_page} />
                <Pagination.Last onClick={() => this.handlePagination(users.last_page_url)} disabled={users.current_page === users.last_page} />
              </Pagination>
            </>
          ) : (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          )
        }
      </>
    );
  }
  renderActiveModal = () => {
    const { activeModal } = this.state;

    if (!activeModal.data) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    if (activeModal.active) {
      return (
        <Alert variant='success'>
          <div>
            The following user will <b>regain access</b> to VLE:
          </div>
          <div className='px-2 mt-1'>
            <div>{activeModal.data.username}</div>
            <div>{activeModal.data.profile.name}</div>
          </div>
        </Alert>
      );
    }

    return (
      <Alert variant='danger'>
        <div>
          The following user will be <b>blocked</b> from VLE:
        </div>
        <div className='px-2 mt-1'>
          <div>{activeModal.data.username}</div>
          <div>{activeModal.data.profile.name}</div>
        </div>
      </Alert>
    );
  }
  renderExportModal = () => {
    const { exportModal } = this.state;

    if (exportModal.isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (exportModal.errorMessage) {
      return (
        <Alert variant='danger'>
          {exportModal.errorMessage}
        </Alert>
      );
    }

    if (!exportModal.data) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <Card>
        <Card.Body>
          <div><b>Username:</b> {exportModal.data.username}</div>
          <div><b>Password:</b> {exportModal.data.password}</div>
        </Card.Body>
      </Card>
    );
  }
  renderExportOptions = () => {
    const { loginCredentials, options } = this.state;
    const { activeSub } = this.props.match.params;

    switch(loginCredentials.option) {
      case 'faculty':
        return (
          <div className='px-3 mt-3'>
            <Form.Group>
              <Form.Control as='select' value={loginCredentials.subOption} onChange={this.handleExportSubOptions}>
                <option value='' disabled hidden>Select faculty...</option>
                {
                  options.faculty.map(f => (
                    <option value={f.id}>{f.name}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
            <div className='text-right mt-3'>
              <a href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/export/${activeSub}/faculty/${loginCredentials.subOption}`} className={`btn btn-green ${loginCredentials.subOption ? '' : 'disabled'}`}>
                Export as Excel
              </a>
            </div>
          </div>
        );
      case 'class':
        return (
          <div className='px-3 mt-3'>
            <Form.Group>
              <Form.Control as='select' value={loginCredentials.subOption} onChange={this.handleExportSubOptions}>
                <option value='' disabled hidden>Select class...</option>
                {
                  options.classes.map(cl => (
                    <option value={cl.id}>{`${cl.program.code} ${cl.year_level.code}`} &mdash; {cl.section.code}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
            <div className='text-right mt-3'>
              <a href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/export/${activeSub}/class/${loginCredentials.subOption}`} className={`btn btn-green ${loginCredentials.subOption ? '' : 'disabled'}`}>
                Export as Excel
              </a>
            </div>
          </div>
        );
      case 'program':
        return (
          <div className='px-3 mt-3'>
            <Form.Group>
              <Form.Control as='select' value={loginCredentials.subOption} onChange={this.handleExportSubOptions}>
                <option value='' disabled hidden>Select program...</option>
                {
                  options.programs.map(program => (
                    <option value={program.id}>{program.code} &mdash; {program.description}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
            <div className='text-right mt-3'>
              <a href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/export/${activeSub}/program/${loginCredentials.subOption}`} className={`btn btn-green ${loginCredentials.subOption ? '' : 'disabled'}`}>
                Export as Excel
              </a>
            </div>
          </div>
        );
      case 'yearLevel':
        return (
          <div className='px-3 mt-3'>
            <Form.Group>
              <Form.Control as='select' value={loginCredentials.subOption} onChange={this.handleExportSubOptions}>
                <option value='' disabled hidden>Select year level...</option>
                {
                  options.yearLevels.map(yearLevel => (
                    <option value={yearLevel.id}>{yearLevel.code} &mdash; {yearLevel.description}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
            <div className='text-right mt-3'>
              <a href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/export/${activeSub}/year-level/${loginCredentials.subOption}`} className={`btn btn-green ${loginCredentials.subOption ? '' : 'disabled'}`}>
                Export as Excel
              </a>
            </div>
          </div>
        );
      default:
        return (
          <div className='text-right mt-3'>
            <a href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/export/${activeSub}`} className='btn btn-green'>
              Export as Excel
            </a>
          </div>
        );
    }
  }
  renderRoleModal = () => {
    const { roleModal, schoolAdminOptions } = this.state;

    if (roleModal.isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (roleModal.errorMessage) {
      return (
        <Alert variant='danger'>
          {roleModal.errorMessage}
        </Alert>
      );
    }

    if (!roleModal.user) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        {
          roleModal.formError && (
            <Alert variant='danger'>
              {roleModal.formError}
            </Alert>
          )
        }
        <h6 className='mb-3'>
          <div>
            Add or remove roles for:
          </div>
           <b>{roleModal.user ? roleModal.user.profile.name : ''}</b>
        </h6>
        <Form.Group>
          {/* <Form.Check
            type='checkbox'
            label='Web Administrator'
            value={0}
            id='roleWebAdmin'
            onChange={this.handleAccountTypeSelect}
            checked={roleModal.accountTypes.indexOf(0) !== -1} /> */}
          <Form.Check
            type='checkbox'
            label='School Administrator'
            value={1}
            id='roleSchoolAdmin'
            onChange={this.handleAccountTypeSelect}
            checked={roleModal.accountTypes.indexOf(1) !== -1}
            disabled={schoolAdminOptions.isLoading || roleModal.isFormLoading} />
          { this.renderRoleOptions(1) }
          <Form.Check
            type='checkbox'
            label='Faculty'
            value={2}
            id='roleFaculty'
            onChange={this.handleAccountTypeSelect}
            checked={roleModal.accountTypes.indexOf(2) !== -1}
            disabled={roleModal.user && roleModal.user.account_type === 2} />
          {/* <Form.Check
            type='checkbox'
            label='Staff'
            value={3}
            id='roleStaff'
            onChange={this.handleAccountTypeSelect}
            checked={roleModal.accountTypes.indexOf(3) !== -1} />
          <Form.Check
            type='checkbox'
            label='Parent'
            value={4}
            id='roleParent'
            onChange={this.handleAccountTypeSelect}
            checked={roleModal.accountTypes.indexOf(4) !== -1} /> */}
        </Form.Group>
      </>
    );
  }
  render() {
    const { 
      importModalShow,
      exportModalShow,
      loginCredentials,
      addModalShow,
      options,
      viewModal,
      editModal,
      deleteModal,
      resetModal,
      exportModal,
      activeModal,
      roleModal
    } = this.state;
    const { activeSub } = this.props.match.params;
    return (
      <Card>
        <Card.Body>
          <div className='mb-2 d-flex'>
            <Button variant='green' className='mr-2' onClick={this.handleAddUserModal}>
              <FontAwesomeIcon icon='plus' className='mr-1' />
              Add new user
            </Button>
            <Button variant='link' title='Bulk import' onClick={this.handleImportModal}>
              <FontAwesomeIcon icon='file-import' /> Import
            </Button>
            <Button variant='link' className='ml-auto' title='Bulk export login credentials' onClick={this.handleExportModal}>
              <FontAwesomeIcon icon='file-export' /> Export
            </Button>
          </div>
          <div className='dropdown-divider'></div>
          {
            resetModal.successMessage && (
              <Alert variant='green'>
                {resetModal.successMessage}
              </Alert>
            )
          }
          { this.renderTable() }
          <UserImportModal
            show={importModalShow}
            onHide={this.handleImportModal}
            type={activeSub}
            history={this.props.history}
            onImageSuccess={this.handleUpdateImageSuccess}
            onEmergencyContactSuccess={this.handleEmergencyContactSuccess}
            onSuccess={this.handleImportSuccess} />
          <Modal show={exportModalShow} onHide={this.handleExportModal}>
            <Modal.Header closeButton>
              <Modal.Title>Bulk export of {exportTitleMap[activeSub]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant='warning'>
                Export the <b>user account information</b> of all the {exportTitleMap[activeSub]}.
              </Alert>
              <div className='text-right'>
                <a href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/info/export/${activeSub}`} className='btn btn-green'>
                  Export as Excel
                </a>
              </div>
              <div className='dropdown-divider'></div>
              {
                (activeSub === 'students' || activeSub === 'parents') ? (
                  <>
                    <div className='h5'>Login Credentials</div>
                    <div className='form-inline px-3'>
                      <Form.Group controlId='lcAll'>
                        <Form.Check type='radio' name='loginCredentials' label='All' value='all' onChange={this.handleExportOptions} checked={loginCredentials.option === 'all'} />
                      </Form.Group>
                      <Form.Group controlId='lcFaculty' className='ml-2'>
                        <Form.Check type='radio' name='loginCredentials' label='Faculty' value='faculty' onChange={this.handleExportOptions} checked={loginCredentials.option === 'faculty'} />
                      </Form.Group>
                      <Form.Group controlId='lcClass' className='ml-2'>
                        <Form.Check type='radio' name='loginCredentials' label='Class' value='class' onChange={this.handleExportOptions} checked={loginCredentials.option === 'class'} />
                      </Form.Group>
                      <Form.Group controlId='lcProgram' className='ml-2'>
                        <Form.Check type='radio' name='loginCredentials' label='Program' value='program' onChange={this.handleExportOptions} checked={loginCredentials.option === 'program'} />
                      </Form.Group>
                      <Form.Group controlId='lcYear' className='ml-2'>
                        <Form.Check type='radio' name='loginCredentials' label='Year Level' value='yearLevel' onChange={this.handleExportOptions} checked={loginCredentials.option === 'yearLevel'} />
                      </Form.Group>
                    </div>
                    {this.renderExportOptions()}
                  </>
                ) : (
                  <>
                    <Alert variant='warning'>
                      Export the <b>login credentials</b> of all the {exportTitleMap[activeSub]}.
                    </Alert>
                    <div className='text-right'>
                      <a href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/export/${activeSub}`} className='btn btn-green'>
                        Export as Excel
                      </a>
                      {/* <a href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/export/${activeSub}`} className='ml-2 btn btn-green'>
                        Export as SMS Files
                      </a> */}
                    </div>
                  </>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' onClick={this.handleExportModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <AddUserModal
            show={addModalShow}
            onHide={this.handleAddUserModal}
            userType={activeSub}
            options={options}
            history={this.props.history}
            onSuccess={this.handleAddSuccess} />
          <ViewUserModal {...viewModal} onHide={this.hideViewModal} />
          <EditUserModal
            {...editModal}
            onHide={this.hideEditModal}
            userType={activeSub}
            options={options}
            history={this.props.history}
            onSuccess={this.handleEditSuccess} />
          <PromptResetPasswordModal
            {...resetModal}
            title='Reset password'
            onHide={this.hideResetModal}
            onReset={this.handleReset}>
              <Card.Subtitle>
                <div>Are you sure you want to reset the password for the user?</div>
                <div>The new password will be sent to the following email.</div>
              </Card.Subtitle>
              {
                resetModal.data && (
                  <Alert variant='light'>
                    <b>{resetModal.data.email}</b>
                    <div>{resetModal.data.profile.name}</div>
                    <div>{resetModal.data.username}</div>
                  </Alert>
                )
              }
          </PromptResetPasswordModal>
          <PromptDeleteModal
            {...deleteModal}
            title={`Delete ${deleteTitleMap[activeSub]}`}
            onHide={this.hideDeleteModal}
            onDelete={this.handleDelete}>
              <Card.Subtitle>Are you sure you want to delete the user?</Card.Subtitle>
              {
                deleteModal.data && (
                  <Alert variant='light'>
                    <div>{deleteModal.data.profile.name}</div>
                    <div>{deleteModal.data.username}</div>
                    <div>{deleteModal.data.email}</div>
                  </Alert>
                )
              }
          </PromptDeleteModal>
          <Modal show={exportModal.show} onHide={this.hideExportModal}>
            <Modal.Header closeButton>
              <Modal.Title>{exportModal.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                exportModal.copySuccess && (
                  <Alert variant='success'>
                    {exportModal.copySuccess}
                  </Alert>
                )
              }
              {this.renderExportModal()}
            </Modal.Body>
            <Modal.Footer>
              {
                (exportModal.data && document.queryCommandSupported('copy')) && (
                  <Button variant='light' className='mr-auto' onClick={this.copyGeneratedCredentials}>
                    Copy to clipboard
                  </Button>
                )
              }
              <Button variant='danger' onClick={this.hideExportModal}>
                Close
              </Button>
              {
                exportModal.data && (
                  <a href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/id/${exportModal.data.id}/export-generated-credential`} className='btn btn-green' disabled={exportModal.isLoading || exportModal.errorMessage || !exportModal.phone}>
                    Export SMS File
                  </a>
                )
              }
            </Modal.Footer>
          </Modal>
          <Modal show={activeModal.show} onHide={this.hideActiveModal}>
            <Modal.Header closeButton>
              <Modal.Title>{activeModal.active === null ? '' : (activeModal.active ? 'Regain Access' : 'Block Access')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                activeModal.errorMessage && (
                  <Alert variant='danger'>
                    {activeModal.errorMessage}
                  </Alert>
                )
              }
              {this.renderActiveModal()}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='light' className='mr-2' onClick={this.hideActiveModal} disabled={activeModal.isLoading}>
                Close
              </Button>
              {
                activeModal.active ? (
                  <Button variant='green' onClick={this.handleActive} disabled={activeModal.isLoading}>
                    Save
                  </Button>
                ) : (
                  <Button variant='danger' onClick={this.handleActive} disabled={activeModal.isLoading}>
                    Block
                  </Button>
                )
              }
            </Modal.Footer>
          </Modal>
          <Modal show={roleModal.show} onHide={this.hideRoleModal}>
            <Modal.Header closeButton>
              <Modal.Title>Manage Roles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.renderRoleModal()}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' onClick={this.hideRoleModal}>
                Cancel
              </Button>
              <Button variant='green' onClick={this.handleManageRole} disabled={roleModal.isLoading || roleModal.isFormLoading}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(UserList);