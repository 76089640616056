import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Card, Container } from 'react-bootstrap';
import Header from '../../common/Header/Header';
import './style.scss';
import axiosRequest from '../../../util/helpers/axiosRequest';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});
const localizer = momentLocalizer(moment);

const accountTypeMap = {
  1: 'school-admin',
  2: 'faculty',
  4: 'parent',
  5: 'student'
};

const CalendarEvent = ({ event }) => {
  let start = moment(event.start);
  let end = moment(event.end);
  return (
    <div>
      <div>{`${start.format('hh:mm A')} - ${end.format('hh:mm A')}`}</div>
      <div>{event.type}</div>
      <div className='mt-1 font-weight-bold'>{event.course.code}</div>
      <div>{`${event.schoolClass.program.code} ${event.schoolClass.year_level.code} - ${event.schoolClass.section.code}`}</div>
    </div>
  );
}

const CalendarDayEvent = ({ event }) => {
  let start = moment(event.start);
  let end = moment(event.end);
  return (
    <div>
      <div>{`${start.format('hh:mm A')} - ${end.format('hh:mm A')} (${moment.duration(end.diff(start, 'minutes'), 'minutes').humanize()})`}</div>
      <div>{event.type}</div>
      <div className='font-weight-bold my-1'>{`${event.course.description} (${event.course.code})`}</div>
      <div>{`${event.schoolClass.program.description} (${event.schoolClass.program.code})`}</div>
      <div>{`${event.schoolClass.year_level.description} (${event.schoolClass.year_level.code})`}</div>
      <div>{`Section ${event.schoolClass.section.code}`}</div>
    </div>
  );
}

class TimeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      facultyLoads: [],
      events: [],
      calendarView: 'week',
      calendarDate: new Date()
    };
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        ...this.state,
        ...this.props.location.state
      });
    } else {
      const { currentUser } = this.props;
      axiosRequest('get', `${accountTypeMap[currentUser.account_type]}/time-table`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          facultyLoads: data
        }, () => {
          this.getEvents();
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    }
  }
  getEvents = (date) => {
    const { facultyLoads } = this.state;

    let weekStart = date;
    if (weekStart) {
      weekStart = moment(weekStart).startOf('isoWeek');
    } else {
      weekStart = moment().startOf('isoWeek');
    }

    let events = [];

    for (let i = 0; i < facultyLoads.length; i++) {
      for (let j = 0; j < 7; j++) {
        let temp = moment(weekStart).add(j, 'days');
  
        let event = facultyLoads[i].weekly_schedule[temp.format('dddd')];
  
        let dateSchedule = facultyLoads[i].date_schedule[temp.format('YYYY-MM-DD')];
        if (dateSchedule) {
          event = dateSchedule;
        }
  
        if (event.start_time && event.end_time) {
          let start = moment(`${temp.format('YYYY-MM-DD')} ${event.start_time}`, 'YYYY-MM-DD HH:mm');
          let end = moment(`${temp.format('YYYY-MM-DD')} ${event.end_time}`, 'YYYY-MM-DD HH:mm');
          if (start.isAfter(end)) {
            end = moment(`${temp.clone().add(1, 'days').format('YYYY-MM-DD')} ${event.end_time}`, 'YYYY-MM-DD HH:mm');
          }
          events.push({
            title: `${facultyLoads[i].class_course.course.code} ${facultyLoads[i].class_course.school_class.program.code} ${facultyLoads[i].class_course.school_class.year_level.code} - ${facultyLoads[i].class_course.school_class.section.code}`,
            start: start.toDate(),
            end: end.toDate(),
            type: event.type,
            classID: facultyLoads[i].id,
            course: facultyLoads[i].class_course.course,
            schoolClass: facultyLoads[i].class_course.school_class
          });
        }
      }
    }
    this.setState({
      ...this.state,
      events,
      calendarDate: date,
      isLoading: false
    }, () => {
      this.props.history.replace(this.props.location.pathname, {...this.state});
    });
  }
  handleViewChange = view => {
    this.setState({
      ...this.state,
      calendarView: view
    }, () => {
      this.props.history.replace(this.props.location.pathname, {...this.state});
    });
  }
  handleEventSelect = event => {
    const { currentUser } = this.props;
    this.props.history.push(`${accountTypeMap[currentUser.account_type]}/class/${event.classID}`);
  }
  renderCalendar() {
    const { isLoading, errorMessage, events, calendarView, calendarDate } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (events.length === 0) {
      return (
        <Alert variant='danger'>
          No schedules found.
        </Alert>
      );
    }

    return (
      <div>
        <Alert variant='warning'>
          <div className='d-none d-lg-block'>Use the <b>day view</b> for a more descriptive schedule.</div>
          <div className='d-lg-none'>The <b>day view</b> is clearer for your device.</div>
        </Alert>
        <Calendar
          localizer={localizer}
          events={events}
          view={calendarView}
          date={calendarDate}
          views={['week', 'day']}
          defaultView='week'
          onNavigate={this.getEvents}
          selectable='ignoreEvents'
          onSelectEvent={this.handleEventSelect}
          onView={this.handleViewChange}
          components={{
            event: CalendarEvent,
            day: {
              event: CalendarDayEvent
            }
          }}
        />
      </div>
    );
  }
  render() {
    return (
      <>
        <Header active='Calendar' />
        <Container>
          <Card className='my-3'>
            <Card.Body>
              {this.renderCalendar()}
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(TimeTable);