import React, { Component } from 'react';
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import durationFormat from '../../../../../util/helpers/durationFormat';
import moment from 'moment';
import Validator from 'validatorjs';
import './style.scss';
import NameLink from '../../../../common/NameLink/NameLink';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

export default class Submissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      submissions: [],
      student_count: 0,
      viewModal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        submissionData: null,
        selectedSubmission: '',
        submissions: []
      },
      gradeModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        submissionData: null,
        grade: ''
      }
    };
  }
  componentDidMount() {
    const { assignment, match: { params: { classID } } } = this.props;

    axiosRequest('get', `faculty/class/${classID}/material/assignment/${assignment.id}/submission`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showGradeModal = submission => {
    const { assignment } = this.props;

    const gradeData = assignment.grades.find(grade => grade.student_id === submission.student_id);
    const grade = gradeData ? gradeData.grade : '';

    this.setState({
      ...this.state,
      gradeModal: {
        ...this.state.gradeModal,
        show: true,
        grade,
        submissionData: submission
      }
    });
  }
  hideGradeModal = () => {
    this.setState({
      ...this.state,
      gradeModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        submissionData: null,
        grade: ''
      }
    });
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      gradeModal: {
        ...this.state.gradeModal,
        grade: event.target.value
      }
    });
  }
  handleGrade = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      gradeModal: {
        ...this.state.gradeModal,
        isLoading: true,
        errorMessage: false
      }
    }, () => {
      const { gradeModal } = this.state;
      const { assignment, match: { params: { classID } } } = this.props;

      let validator = new Validator({
        grade: gradeModal.grade
      }, {
        grade: `required|numeric|min:0|max:${assignment.points}`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          gradeModal: {
            ...this.state.gradeModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', `faculty/class/${classID}/material/assignment/${assignment.id}/grade/${gradeModal.submissionData.student_id}`, {
        grade: gradeModal.grade
      }, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          gradeModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            submissionData: null,
            submissions: [],
            grade: ''
          }
        }, () => {
          this.props.onGrade(data);
        });
      }, (error) => {
        this.setState({
          ...this.state,
          gradeModal: {
            ...this.state.gradeModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  renderGraded = submission => {
    const { assignment } = this.props;

    let gradeData = assignment.grades.find((grade) => grade.student_id === submission.student_id);

    if (gradeData) {
      return (
        <div className='text-muted'>
          Grade: {gradeData.grade} / {assignment.points}
        </div>
      );
    }

    return (
      <div className='text-muted'>
        (Not yet graded)
      </div>
    );
  }
  renderSubmissionRemark = submission => {
    const { assignment } = this.props;

    let start = moment(assignment.faculty_load_id ? assignment.until : assignment.audience.until);
    let end = moment(submission.created_at);

    if (end.isAfter(start)) {
      let duration = moment.duration(end.diff(start));
      let humanized = duration.humanize();
      // humanized = humanized.split(' ').map(h => '' + h.charAt(0).toUpperCase() + h.slice(1)).join(' ');
      humanized = humanized.charAt(0).toUpperCase() + humanized.slice(1);
      return (
        <div className='text-danger font-weight-bold'>
          {humanized} late <span className='text-muted font-italic font-weight-normal'>({durationFormat(duration)})</span>
        </div>
      );
    }

    return (
      <div className='text-green font-weight-bold'>
        On time
      </div>
    );
  }
  render() {
    const { isLoading, errorMessage, submissions, gradeModal, student_count } = this.state;
    const { assignment } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (submissions.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='text-right text-primary mb-2 font-weight-bold font-italic px-3'>
          Submissions: {submissions.length}/{student_count}
        </div>
        <div className='submission-list'>
          {
            submissions.map(submission => (
              <div key={submission.id} className='py-3 p-md-3 py-md-0'>
                <div className='d-flex'>
                  <div className='flex-fill'>
                    <div className='d-flex'>
                      <NameLink
                        id={submission.profile.id}
                        name={submission.profile.name}
                        image={submission.profile.image}
                        learningPlatform={submission.profile.student_learning_platform ? submission.profile.student_learning_platform.platform : null}
                        rank={submission.profile.rank} />
                    </div>
                    {this.renderSubmissionRemark(submission)}
                    {this.renderGraded(submission)}
                  </div>
                  <div>
                    <Button variant='warning' size='sm' title='View submission' className='mr-2' onClick={() => this.props.handleViewSubmission(submission)}>
                      <FontAwesomeIcon icon='eye' />
                    </Button>
                    <Button variant='green' size='sm' title='Grade submission' onClick={() => this.showGradeModal(submission)}>
                      <FontAwesomeIcon icon='pencil-alt' />
                    </Button>
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        <Modal show={gradeModal.show} backdrop='static' onHide={this.hideGradeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{gradeModal.submissionData ? gradeModal.submissionData.profile.name : ''}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleGrade}>
            <Modal.Body>
              {
                gradeModal.errorMessage ? (
                  <Alert variant='danger'>
                    {gradeModal.errorMessage}
                  </Alert>
                ) : (
                  <Alert variant='warning'>
                    Proceeding will <b>delete</b> all the attached files from all the <b>student's submissions</b> and all the <b>feedback</b> files
                  </Alert>
                )
              }
              <div className='h5'>
                {assignment.title}
              </div>
              <Form.Group>
                <Form.Label>Category</Form.Label>
                {
                  assignment.faculty_load_id ? (
                    <Form.Control value={assignment.grade_category.name} disabled />
                  ) : (
                    <Form.Control value={assignment.audience.grade_category.name} disabled />
                  )
                }
              </Form.Group>
              <Form.Group>
                <Form.Label>Grade</Form.Label>
                <InputGroup>
                  <Form.Control type='number' onChange={this.handleInputChange} value={gradeModal.grade} min='0' max={assignment.points} />
                  <InputGroup.Append>
                    <InputGroup.Text>/ {assignment.points}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' className='mr-2' onClick={this.hideGradeModal} disabled={gradeModal.isLoading}>
                Cancel
              </Button>
              <Button variant='green' type='submit' disabled={gradeModal.isLoading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}