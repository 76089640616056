import React from 'react';
import PropTypes from 'prop-types';
import { Alert,  Button, Modal } from 'react-bootstrap';

const PromptDeleteModal = props => {
  return (
    <Modal show={props.show} onHide={props.onHide} onExited={props.onExited}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          props.errorMessage ? (
            <Alert variant='danger'>{props.errorMessage}</Alert>
          ) : props.successMessage ? (
            <Alert variant='success'>{props.successMessage}</Alert>
          ) : null
        }
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        {
          props.successMessage ? (
            <Button variant='light' onClick={props.onHide}>Close</Button>
          ) : (
            <>
              <Button variant='info' onClick={props.onHide} disabled={props.isLoading}>Cancel</Button>
              <Button variant='danger' onClick={props.onDelete} disabled={props.isLoading}>Delete</Button>
            </>
          )
        }
      </Modal.Footer>
    </Modal>
  );
};

PromptDeleteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onExited: PropTypes.func,
};

PromptDeleteModal.defaultProps = {
  show: false,
  title: '',
  isLoading: false,
  errorMessage: '',
  successMessage: '',
  onHide: () => {},
  onDelete: () => {},
  onExited: () => {}
};

export default PromptDeleteModal;