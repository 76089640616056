import React, { Component } from 'react';
import { Alert, Button, Form, InputGroup, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import moment from 'moment';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

const accountTypeMap = {
  1: 'school-admin',
  2: 'faculty',
  3: 'staff',
  5: 'student'
};

const iconMap = {
  Present: <FontAwesomeIcon icon='check-circle' className='text-green' />,
  Absent: <FontAwesomeIcon icon={['far', 'circle']} className='text-danger' />,
  Late: <FontAwesomeIcon icon='dot-circle' className='text-danger' />,
  Excused: <FontAwesomeIcon icon='circle' className='text-green' />
};
const iconMapMuted = {
  Present: <FontAwesomeIcon icon='check-circle' className='text-muted' />,
  Absent: <FontAwesomeIcon icon={['far', 'circle']} className='text-muted' />,
  Late: <FontAwesomeIcon icon='dot-circle' className='text-muted' />,
  Excused: <FontAwesomeIcon icon='circle' className='text-muted' />
};
const learningPlatforms = {
  'Digital': (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <div className='font-weight-bold'>Learning Platform</div>
          <div>Digital</div>
        </Tooltip>
      }
      trigger={['hover', 'focus']}>
      <FontAwesomeIcon icon='laptop' className='fa-fw text-primary' />
    </OverlayTrigger>
  ),
  'Digital-Modular': (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <div className='font-weight-bold'>Learning Platform</div>
          <div>Digital-Modular</div>
        </Tooltip>
      }
      trigger={['hover', 'focus']}>
      <span className='fa-layers fa-fw text-primary'>
        <FontAwesomeIcon icon='laptop' transform='shrink-3 left-5 up-2' />
        <FontAwesomeIcon icon='book-open' transform='shrink-3 right-3 down-5' />
      </span>
    </OverlayTrigger>
  ),
  'Modular-Printed': (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <div className='font-weight-bold'>Learning Platform</div>
          <div>Modular-Printed</div>
        </Tooltip>
      }
      trigger={['hover', 'focus']}>
      <FontAwesomeIcon icon='book-open' className='fa-fw text-primary' />
    </OverlayTrigger>
  )
};

export default class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      facultyLoads: [],
      weekdays: [],
      attendance: [],
      isAttendanceLoading: false,
      attendanceError: '',
      loadedWeeks: [],
      attendanceDisplay: []
    };
  }
  componentDidMount() {
    const { profile } = this.props;

    axiosRequest('get', `profile-menu/${profile.id}/attendance`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        loadedWeeks: [
          moment().isoWeek()
        ]
      }, () => {
        this.getWeek();
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  getWeek = weekStart => {
    if(!weekStart) {
      weekStart = moment().startOf('isoWeek');
    }
    let days = [];

    for (let i = 0; i < 7; i++) {
      let temp = moment(weekStart).add(i, 'days');

      days.push({
        date: temp.format('YYYY-MM-DD'),
        weekdayFull: temp.format('dddd'),
        dateDisplayLg: temp.format('dddd, MMM D'),
        dateDisplaySm: temp.format('MMM D')
      });
    }

    this.setState({
      ...this.state,
      weekdays: days
    }, this.getAttendance);
  }
  getAttendance = () => {
    const { weekdays, attendance, facultyLoads } = this.state;

    let attendanceDisplay = [];
    for (let i = 0; i < facultyLoads.length; i++) {
      let facultyLoad = facultyLoads[i];
      let learningPlatform = facultyLoad.student_learning_platform;
      let data = [];
      for (let j = 0; j < weekdays.length; j++) {
        let weekday = weekdays[j];
        let schedule = facultyLoad.date_schedule.find(ds => ds.schedule_date === weekday.date);
        if (!schedule) {
          schedule = facultyLoad.weekly_schedule.find(ws => ws.day === weekday.weekdayFull);
        }

        let temp = attendance.find(a => a.faculty_load_id === facultyLoad.id && a.attendance_date === weekday.date);
        if (temp) {
          if (schedule && schedule.type) {
            if (schedule.type === 'Synchronous' && learningPlatform && learningPlatform === 'Digital') {
              data.push(
                <span className='mr-1' style={{ cursor: 'pointer' }}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          {weekday.dateDisplayLg}
                        </div>
                        <div>
                          {schedule.type}
                        </div>
                        <div className='font-weight-bold'>
                          {temp.mark}
                        </div>
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <span className='fa-lg'>
                      {iconMap[temp.mark]}
                    </span>
                  </OverlayTrigger>
                </span>
              );
            } else if (schedule.type === 'Asynchronous' && learningPlatform && (learningPlatform.platform === 'Digital' || learningPlatform.platform === 'Digital-Modular')) {
              data.push(
                <span className='mr-1' style={{ cursor: 'pointer' }}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          {weekday.dateDisplayLg}
                        </div>
                        <div>
                          {schedule.type}
                        </div>
                        <div className='font-weight-bold'>
                          {temp.mark}
                        </div>
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <span className='fa-lg'>
                      {iconMap[temp.mark]}
                    </span>
                  </OverlayTrigger>
                </span>
              );
            } else if (!learningPlatform || schedule.type === 'Exam') {
              data.push(
                <span className='mr-1' style={{ cursor: 'pointer' }}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          {weekday.dateDisplayLg}
                        </div>
                        <div>
                          {schedule.type}
                        </div>
                        <div className='font-weight-bold'>
                          {temp.mark}
                        </div>
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <span className='fa-lg'>
                      {iconMap[temp.mark]}
                    </span>
                  </OverlayTrigger>
                </span>
              );
            } else {
              data.push(
                <span className='mr-1' style={{ cursor: 'pointer' }}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          {weekday.dateDisplayLg}
                        </div>
                        <div>
                          {schedule.type}
                        </div>
                        <div className='font-weight-bold'>
                          {temp.mark}
                        </div>
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <span className='fa-lg'>
                      {iconMapMuted[temp.mark]}
                    </span>
                  </OverlayTrigger>
                </span>
              );
            }
          } else {
            data.push(
              <span className='mr-1' style={{ cursor: 'pointer' }}>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      <div>
                        {weekday.dateDisplayLg}
                      </div>
                      <div className='font-italic'>
                        No schedule
                      </div>
                      <div className='font-weight-bold'>
                        {temp.mark}
                      </div>
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <span className='fa-lg'>
                    {iconMapMuted[temp.mark]}
                  </span>
                </OverlayTrigger>
              </span>
            );
          }
        } else if (schedule && schedule.type) {
          if (schedule.type === 'Synchronous' && learningPlatform && learningPlatform.platform === 'Digital') {
            data.push(
              <span className='mr-1' style={{ cursor: 'pointer' }}>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      <div>
                        {weekday.dateDisplayLg}
                      </div>
                      <div>
                        {schedule.type}
                      </div>
                      <div className='font-italic font-weight-bold'>
                        No attendance
                      </div>
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <span className='fa-lg'>
                    <FontAwesomeIcon icon='exclamation-circle' className='text-muted' />
                  </span>
                </OverlayTrigger>
              </span>
            );
          } else if (schedule.type === 'Asynchronous' && learningPlatform && (learningPlatform.platform === 'Digital' || learningPlatform.platform === 'Digital-Modular')) {
            data.push(
              <span className='mr-1' style={{ cursor: 'pointer' }}>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      <div>
                        {weekday.dateDisplayLg}
                      </div>
                      <div>
                        {schedule.type}
                      </div>
                      <div className='font-italic font-weight-bold'>
                        No attendance
                      </div>
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <span className='fa-lg'>
                    <FontAwesomeIcon icon='exclamation-circle' className='text-muted' />
                  </span>
                </OverlayTrigger>
              </span>
            );
          } else if (!learningPlatform || schedule.type === 'Exam') {
            data.push(
              <span className='mr-1' style={{ cursor: 'pointer' }}>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      <div>
                        {weekday.dateDisplayLg}
                      </div>
                      <div>
                        {schedule.type}
                      </div>
                      <div className='font-italic font-weight-bold'>
                        No attendance
                      </div>
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <span className='fa-lg'>
                    <FontAwesomeIcon icon='exclamation-circle' className='text-muted' />
                  </span>
                </OverlayTrigger>
              </span>
            );
          }
        }
      }
      attendanceDisplay.push({
        facultyLoadID: facultyLoad.id,
        data
      });
    }

    this.setState({
      ...this.state,
      isLoading: false,
      attendanceDisplay
    });
  }
  getWeekAttendance = weekStart => {
    const { loadedWeeks } = this.state;
    const { profile } = this.props;
    const isoWeek = moment(weekStart).isoWeek();

    if (loadedWeeks.indexOf(isoWeek) === -1) {
      this.setState({
        ...this.state,
        isAttendanceLoading: true,
        attendanceError: ''
      }, () => {
        axiosRequest('get', `profile-menu/${profile.id}/attendance/${weekStart.format('YYYY-MM-DD')}`, null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            isAttendanceLoading: false,
            attendance: [
              ...this.state.attendance,
              ...data
            ],
            loadedWeeks: [
              ...this.state.loadedWeeks,
              isoWeek
            ]
          }, () => {
            this.getWeek(weekStart);
          });
        }, error => {
          this.setState({
            ...this.state,
            isAttendanceLoading: false,
            attendanceError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    } else {
      this.getWeek(weekStart);
    }
  }
  handleNextWeek = () => {
    const { weekdays } = this.state;

    this.getWeekAttendance(moment(weekdays[0].date).add(1, 'weeks'));
  }
  handlePrevWeek = () => {
    const { weekdays } = this.state;

    this.getWeekAttendance(moment(weekdays[0].date).subtract(1, 'weeks'));
  }
  renderAttendance = facultyLoad => {
    const { attendanceDisplay } = this.state;

    let display = attendanceDisplay.find(ad => ad.facultyLoadID === facultyLoad.id);
    
    if (display) {
      if (display.data.length === 0) {
        return (
          <span style={{ cursor: 'pointer' }}>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  No schedule
                </Tooltip>
              }
              trigger={['hover', 'focus']}>
              <span className='fa-lg'>
                <FontAwesomeIcon icon='ban' className='text-muted' />
              </span>
            </OverlayTrigger>
          </span>
        );
      }
      return (
        <div>
          {display.data.map(d => d)}
        </div>
      );
    }

    return null;
  }
  renderTable = () => {
    const { facultyLoads, isAttendanceLoading, attendanceError } = this.state;
    const { currentUser } = this.props;

    if (isAttendanceLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (attendanceError) {
      return (
        <Alert variant='danger'>
          {attendanceError}
        </Alert>
      );
    }

    return (
      <Table bordered hover responsive>
        <thead>
          <tr>
            <th className='w-75'>Course</th>
            <th className='text-center'>Attendance</th>
          </tr>
        </thead>
        <tbody>
          {
            facultyLoads.map(facultyLoad => (
              <tr key={facultyLoad.id}>
                  <td>
                    {
                      accountTypeMap[currentUser.account_type] ? (
                        <div>
                          <a href={`/${accountTypeMap[currentUser.account_type]}/class/${facultyLoad.id}`} className='btn-link'>
                            <span className='font-weight-bold'>
                              {facultyLoad.class_course.course.code}
                              <span className='ml-2'>
                                <FontAwesomeIcon icon='external-link-alt' />
                              </span>
                            </span>
                          </a>
                        </div>
                      ) : (
                        <div className='font-weight-bold'>
                          {facultyLoad.class_course.course.code}
                        </div>
                      )
                    }
                    <div>{facultyLoad.class_course.course.description}</div>
                    <div>{facultyLoad.profile.name}</div>
                    {
                      (facultyLoad.student_learning_platform && facultyLoad.student_learning_platform.platform) && (
                        <div>
                          <span className='font-italic'>Learning Platform:</span> {learningPlatforms[facultyLoad.student_learning_platform.platform]}
                        </div>
                      )
                    }
                  </td>
                  <th style={{ fontSize: '1.1rem' }} className='px-2 pt-1'>
                    {this.renderAttendance(facultyLoad)}
                  </th>
                </tr>
            ))
          }
        </tbody>
      </Table>
    );
  }
  render() {
    const { isLoading, errorMessage, facultyLoads, weekdays } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (facultyLoads.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='d-none d-md-block'>
          <InputGroup className='mb-3'>
            <InputGroup.Prepend>
              <Button onClick={this.handlePrevWeek}>
                <FontAwesomeIcon icon='chevron-left' />
              </Button>
            </InputGroup.Prepend>
            <Form.Control className='text-center' type='text' value={`${weekdays[0].dateDisplayLg} - ${weekdays[6].dateDisplayLg}`} disabled />
            <InputGroup.Append>
              <Button onClick={this.handleNextWeek}>
                <FontAwesomeIcon icon='chevron-right' />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
        <div className='d-md-none'>
          <InputGroup className='mb-3' size='sm'>
            <InputGroup.Prepend>
              <Button onClick={this.handlePrevWeek}>
                <FontAwesomeIcon icon='chevron-left' />
              </Button>
            </InputGroup.Prepend>
            <Form.Control className='text-center' type='text' value={`${weekdays[0].dateDisplaySm} - ${weekdays[6].dateDisplaySm}`} disabled />
            <InputGroup.Append>
              <Button onClick={this.handleNextWeek}>
                <FontAwesomeIcon icon='chevron-right' />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
        {this.renderTable()}
      </>
    );
  }
}