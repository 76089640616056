import React, { Component } from 'react';
import { Alert, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../util/helpers/axiosRequest';
import List from './parts/List';
import Performance from './parts/Performance';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

export default class Students extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 'list',
      isLoading: true,
      errorMessage: '',
      students: []
    };
  }
  componentDidMount() {
    const { classID, userType } = this.props.match.params;
    axiosRequest('get', `${userType}/class/${classID}/students`, null, ({ data: { data } }) => {
      this.setState({
        ...this.state,
        students: data,
        isLoading: false,
        errorMessage: ''
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleSelect = key => {
    this.setState({
      ...this.state,
      active: key
    });
  }
  handleUpdateStudents = students => {
    this.setState({
      ...this.state,
      students
    });
  }
  renderContent = () => {
    const { active, students } = this.state;

    switch(active) {
      case 'list':
        return <List {...this.props} students={students} onUpdateStudents={this.handleUpdateStudents} />;
      case 'performance':
        return <Performance {...this.props} students={students} />;
      default:
        return null;
    }
  }
  render() {
    const { isLoading, errorMessage, active, students } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (students.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <Nav variant='tabs bg-light rounded-top'>
          <Nav.Item className='flex-fill'>
            <Nav.Link
              eventKey='list'
              onSelect={this.handleSelect}
              active={active === 'list'}>
                <FontAwesomeIcon icon='list' /> Student List
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className='flex-fill'>
            <Nav.Link
              eventKey='performance'
              onSelect={this.handleSelect}
              active={active === 'performance'}>
                <FontAwesomeIcon icon='chart-line' /> Performance
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className='p-3 border rounded-bottom border-top-0'>
          {this.renderContent()}
        </div>
      </>
    );
  }
}