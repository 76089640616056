import React from 'react';

export default function Badge(props) {
  function student() {
    return (
      <>
        <div className='mb-3'>
          Badges are the rewards for your activity in a classroom. These rewards are automatically given based on your activity.<br /><br />
          There are four (4) types of badges:
          <div>
            <ul>
              <li>On-time login</li>
              <li>On-time submissions</li>
              <li>Good attendance</li>
              <li>Active participaton</li>
            </ul>
          </div>
          For every activity, a trophy is awarded on a badge, depending on the type of activity.
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Ranking</div>
          <div>
            This is the area for viewing the ranking of students in a classroom on the previous month based on their badges.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Student Badges</div>
          <div>
            This is the area for viewing your badges for the current month.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Ranking every end of semester</div>
          <div>
            A ranking at the end of the semester will be visible at the homepage.
            These will be visible to all your classmates.
          </div>
        </div>
      </>
    );
  }
  function parent() {
    return (
      <>
        <div className='mb-3'>
          Badges are the rewards for your student's activity in a classroom. These rewards are automatically given based on your student's activity.<br /><br />
          There are four (4) types of badges:
          <div>
            <ul>
              <li>On-time login</li>
              <li>On-time submissions</li>
              <li>Good attendance</li>
              <li>Active participaton</li>
            </ul>
          </div>
          For every activity, a trophy is awarded on a badge, depending on the type of activity.
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Ranking</div>
          <div>
            This is the area for viewing the ranking of students in a classroom on the previous month based on their badges.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Student Badges</div>
          <div>
            This is the area for viewing your student's badges for the current month.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Ranking every end of semester</div>
          <div>
            A ranking at the end of the semester will be visible at the homepage.
            These will be visible to all your student's classmates.
          </div>
        </div>
      </>
    );
  }
  function faculty() {
    return (
      <>
        <div className='mb-3'>
          Badges are the rewards for the students' activity in a classroom. These rewards are automatically given based on the students' activity.<br /><br />
          There are four (4) types of badges:
          <div>
            <ul>
              <li>On-time login</li>
              <li>On-time submissions</li>
              <li>Good attendance</li>
              <li>Active participaton</li>
            </ul>
          </div>
          For every activity, a trophy is awarded on a badge, depending on the type of activity.
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Ranking</div>
          <div>
            This is the area for viewing the ranking of students in a classroom on the previous month based on their badges.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Student Badges</div>
          <div>
            This is the area for viewing your students' badges for the current month.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Ranking every end of semester</div>
          <div>
            A ranking at the end of the semester will be visible at the homepage.
            This will also be visible to your students per classroom.
          </div>
        </div>
      </>
    );
  }
  function other() {
    return (
      <>
        <div className='mb-3'>
          Badges are the rewards for the students' activity in a classroom. These rewards are automatically given based on the students' activity.<br /><br />
          There are four (4) types of badges:
          <div>
            <ul>
              <li>On-time login</li>
              <li>On-time submissions</li>
              <li>Good attendance</li>
              <li>Active participaton</li>
            </ul>
          </div>
          For every activity, a trophy is awarded on a badge, depending on the type of activity.
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Ranking</div>
          <div>
            This is the area for viewing the ranking of students in a classroom on the previous month based on their badges.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Student Badges</div>
          <div>
            This is the area for viewing the students' badges for the current month.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Ranking every end of semester</div>
          <div>
            A ranking at the end of the semester will be visible at the homepage.
            This will also be visible to the students per classroom.
          </div>
        </div>
      </>
    );
  }
  
  return props.accountType === 5 ? student() :
          props.accountType === 4 ? parent() :
          props.accountType === 2 ? faculty() : other();
}