import React, { Component, useState, useEffect } from 'react';
import { Alert, Breadcrumb, Button, Col, Collapse, Form, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import NameLink from '../../../../common/NameLink/NameLink';
import moment from 'moment';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import durationFormat from '../../../../../util/helpers/durationFormat';
import './style.scss';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../../common/RichTextEditor/RichTextEditor';

const CorrectAnswers = props => {
  const [show, setShow] = useState(props.show ? props.show : false);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  if (props.answers && !props.answers.find(a => !a.is_correct)) {
    return null;
  }

  switch (props.type) {
    case 'enumeration':
      return (
        <div>
          <div>
            <Button variant='warning' onClick={e => setShow(!show)} size='sm'>
              {
                show ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Hide correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-up' className='ml-1' />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Show correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-down' className='ml-1' />
                  </>
                )
              }
            </Button>
          </div>
          <Collapse in={show}>
            <div>
              {
                props.question.items.map((item, key) => (
                  <div key={key} className='d-flex text-muted'>
                    <div className='p-1 font-weight-bold'>
                      {`${key+1}.`}
                    </div>
                    {
                      props.answers && (
                        <div className='py-1'>
                          {
                            props.answers[key].is_correct ? (
                              <FontAwesomeIcon icon='check' className='fa-fw' />
                            ) : (
                              <FontAwesomeIcon icon='times' className='fa-fw' />
                            )
                          }
                        </div>
                      )
                    }
                    <div className='flex-fill p-1'>
                      {item.description}
                    </div>
                  </div>
                ))
              }
            </div>
          </Collapse>
        </div>
      );
    case 'matching':
      return (
        <div>
          <div>
            <Button variant='warning' onClick={e => setShow(!show)} size='sm'>
              {
                show ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Hide correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-up' className='ml-1' />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Show correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-down' className='ml-1' />
                  </>
                )
              }
            </Button>
          </div>
          <Collapse in={show}>
            <div>
              {
                props.question.items.map((item, key) => (
                  <div key={key} className='d-flex text-muted'>
                    <div className='p-1 font-weight-bold'>
                      {`${key+1}.`}
                    </div>
                    {
                      props.answers && (
                        <div className='py-1'>
                          {
                            props.answers[key].is_correct ? (
                              <FontAwesomeIcon icon='check' className='fa-fw' />
                            ) : (
                              <FontAwesomeIcon icon='times' className='fa-fw' />
                            )
                          }
                        </div>
                      )
                    }
                    <div className='flex-fill p-1'>
                      <div>
                        {item.description}
                      </div>
                      <div className='d-flex pl-1'>
                        <div className='mr-1'>
                          <FontAwesomeIcon icon='long-arrow-alt-right' />
                        </div>
                        <div className='flex-fill'>
                          {item.answer}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </Collapse>
        </div>
      );
    case 'identification':
      return (
        <div>
          <div>
            <Button variant='warning' onClick={e => setShow(!show)} size='sm'>
              {
                show ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Hide correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-up' className='ml-1' />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Show correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-down' className='ml-1' />
                  </>
                )
              }
            </Button>
          </div>
          <Collapse in={show}>
            <div>
              {
                props.question.items.map((item, key) => (
                  <div key={key} className='d-flex text-muted'>
                    <div className='p-1 font-weight-bold'>
                      {`${key+1}.`}
                    </div>
                    {
                      props.answers && (
                        <div className='py-1'>
                          {
                            props.answers[key].is_correct ? (
                              <FontAwesomeIcon icon='check' className='fa-fw' />
                            ) : (
                              <FontAwesomeIcon icon='times' className='fa-fw' />
                            )
                          }
                        </div>
                      )
                    }
                    <div className='flex-fill'>
                      <div className='p-1'>
                        {item.answer}
                      </div>
                      <div className='p-1 font-italic small'>
                        {item.description}
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </Collapse>
        </div>
      );
    case 'multipleChoice':
      return (
        <div>
          <div>
            <Button variant='warning' onClick={e => setShow(!show)} size='sm'>
              {
                show ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Hide correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-up' className='ml-1' />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Show correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-down' className='ml-1' />
                  </>
                )
              }
            </Button>
          </div>
          <Collapse in={show}>
            <div>
              {
                props.question.choices.map((item, key) => (
                  <div key={key} className='d-flex text-muted'>
                    <div className='py-1 mr-1 fa-fw'>
                      {
                        item.is_correct && (
                          <FontAwesomeIcon icon='check' />
                        )
                      }
                    </div>
                    <div className='flex-fill p-1'>
                      {item.description}
                    </div>
                  </div>
                ))
              }
            </div>
          </Collapse>
        </div>
      );
    case 'ordering':
      return (
        <div>
          <div>
            <Button variant='warning' onClick={e => setShow(!show)} size='sm'>
              {
                show ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Hide correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-up' className='ml-1' />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Show correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-down' className='ml-1' />
                  </>
                )
              }
            </Button>
          </div>
          <Collapse in={show}>
            <div>
              {
                props.question.items.map((item, key) => (
                  <div key={key} className='d-flex text-muted'>
                    <div className='p-1 font-weight-bold'>
                      {`${key+1}.`}
                    </div>
                    {
                      props.answers && (
                        <div className='py-1'>
                          {
                            props.answers[key].is_correct ? (
                              <FontAwesomeIcon icon='check' className='fa-fw' />
                            ) : (
                              <FontAwesomeIcon icon='times' className='fa-fw' />
                            )
                          }
                        </div>
                      )
                    }
                    <div className='flex-fill p-1'>
                      {item.description}
                    </div>
                  </div>
                ))
              }
            </div>
          </Collapse>
        </div>
      );
    case 'trueFalse':
      return (
        <div>
          <div>
            <Button variant='warning' onClick={e => setShow(!show)} size='sm'>
              {
                show ? (
                  <>
                    <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Hide correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-up' className='ml-1' />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon='eye' className='mr-1' />
                    <span className='d-none d-md-inline-block'>Show correct answer(s)</span>
                    <span className='d-md-none fa-layers mx-1'>
                      <FontAwesomeIcon icon={['far', 'comment']} />
                      <FontAwesomeIcon icon='check' transform='up-2 right-2 shrink-1' />
                    </span>
                    <FontAwesomeIcon icon='chevron-down' className='ml-1' />
                  </>
                )
              }
            </Button>
          </div>
          <Collapse in={show}>
            <div>
              <div className='d-flex text-muted'>
                <div className='py-1 fa-fw mr-1'>
                  {
                    props.question.correct_answer && (
                      <FontAwesomeIcon icon='check' />
                    )
                  }
                </div>
                <div className='flex-fill p-1'>
                  True
                </div>
              </div>
              <div className='d-flex text-muted'>
                <div className='py-1 fa-fw mr-1'>
                  {
                    !props.question.correct_answer && (
                      <FontAwesomeIcon icon='check' />
                    )
                  }
                </div>
                <div className='flex-fill p-1'>
                  False
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      );
    default:
      return null;
  }
};

const questionRelationTypeMap = {
  trueFalse: 'question_boolean',
  multipleChoice: 'question_multiple_choice',
  ordering: 'question_ordering',
  essay: 'question_essay',
  matching: 'question_matching',
  identification: 'question_identification',
  enumeration: 'question_enumeration',
};

class Submissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      submissions: [],
      questions: [],
      materialPoints: 0,
      view: 'list',
      viewSubmission: {
        isLoading: true,
        errorMessage: '',
        submissionData: null,
        selectedSubmission: '',
        submissions: [],
        viewAllAnswers: false
      }
    };
  }
  componentDidMount() {
    const { materialData, match: { params: { classID } } } = this.props;

    axiosRequest('get', `school-admin/class/${classID}/material/${materialData.type}/${materialData.id}/submission`, null, ({ data: { data }}) => {
      let materialPoints = 0;
      for (let i = 0; i < data.questions.length; i++) {
        materialPoints += data.questions[i].points;
        data.questions[i].question = data.questions[i][questionRelationTypeMap[data.questions[i].type]];
      }
      let latestUpdate = null;
      let pointsOverridden = false;
      let answerPointsOverride = 0;
      for (let i = 0; i < data.submissions.length; i++) {
        latestUpdate = data.submissions[i].updated_at;
        pointsOverridden = false;
        answerPointsOverride = 0;
        for (let j = 0; j < data.submissions[i].question_answers.length; j++) {
          latestUpdate = data.submissions[i].question_answers[j].updated_at;
          if (data.submissions[i].question_answers[j].points_override) {
            answerPointsOverride += data.submissions[i].question_answers[j].points_override.points;
            pointsOverridden = true;
          } else {
            answerPointsOverride += data.submissions[i].question_answers[j].points;
          }
        }
        data.submissions[i].latest_update = latestUpdate;
        data.submissions[i].points_overridden = pointsOverridden;
        data.submissions[i].points_with_override = answerPointsOverride;
      }
      this.setState({
        ...this.state,
        submissions: data.submissions,
        questions: data.questions,
        materialPoints,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showViewSubmission = submission => {
    this.setState({
      ...this.state,
      view: 'submission',
      viewSubmission: {
        ...this.state.viewSubmission,
        isLoading: true,
        errorMessage: '',
        submissionData: submission,
        selectedSubmission: submission.id
      }
    }, () => {
      const { materialData, match: { params: { classID } } } = this.props;

      axiosRequest('get', `school-admin/class/${classID}/material/${materialData.type}/${materialData.id}/submission/student/${submission.student_id}`, null, ({ data: { data }}) => {
        let pointsOverridden = false;
        let answerPointsOverride = 0;
        for (let i = 0; i < data.length; i++) {
          pointsOverridden = false;
          answerPointsOverride = 0;
          for (let j = 0; j < data[i].question_answers.length; j++) {
            if (data[i].question_answers[j].points_override) {
              answerPointsOverride += data[i].question_answers[j].points_override.points;
              pointsOverridden = true;
            } else {
              answerPointsOverride += data[i].question_answers[j].points;
            }
          }
          data[i].points_overridden = pointsOverridden;
          data[i].points_with_override = answerPointsOverride;
        }
        this.setState({
          ...this.state,
          viewSubmission: {
            ...this.state.viewSubmission,
            isLoading: false,
            submissions: data
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          viewSubmission: {
            ...this.state.viewSubmission,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  hideViewSubmission = () => {
    this.setState({
      ...this.state,
      view: 'list',
      viewSubmission: {
        isLoading: true,
        errorMessage: '',
        submissionData: null,
        selectedSubmission: '',
        submissions: [],
        viewAllAnswers: false
      }
    });
  }
  handleSelectSubmission = event => {
    this.setState({
      ...this.state,
      viewSubmission: {
        ...this.state.viewSubmission,
        selectedSubmission: event.target.value
      }
    })
  }
  handleAnswerFileDownload = (answerID, fileID) => {
    const { materialData, match: { params: { classID } } } = this.props;
    const { viewSubmission: { submissionData: { student_id }, selectedSubmission } } = this.state;

    axiosRequest('get', `school-admin/class/${classID}/material/${materialData.type}/${materialData.id}/submission/student/${student_id}/attempt/${selectedSubmission}/answer/${answerID}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handlePreviewImages = (materialData, student_id, selectedSubmission, answer, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/school-admin/class/${classID}/material/${materialData.type}/${materialData.id}/submission/student/${student_id}/attempt/${selectedSubmission}/answer/${answer.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  renderAnswer = (type, question, answer) => {
    const { viewSubmission: { viewAllAnswers } } = this.state;
    if (!answer) {
      return (
        <>
          <div>
            <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
          </div>
          <div>
            <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
          </div>
        </>
      );
    }

    switch(type) {
      case 'trueFalse':
        if (answer.answer_boolean) {
          return (
            <div>
              <div className='d-flex mb-1'>
                {
                  answer.answer_boolean.answer ? (
                    <>
                      <div className='mr-1 fa-fw py-1'>
                        {
                          (answer.answer_boolean.answer && question.correct_answer) ? (
                            <FontAwesomeIcon icon='check' className='text-green' />
                          ) : (
                            <FontAwesomeIcon icon='times' className='text-danger' />
                          )
                        }
                      </div>
                      <div className={`p-1 rounded border ${answer.answer_boolean.answer && question.correct_answer ? 'border-green' : 'border-danger'}`}>
                        True
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='mr-1 fa-fw py-1'>
                        {
                          question.correct_answer && (
                            <FontAwesomeIcon icon='check' className='text-green' />
                          )
                        }
                      </div>
                      <div className='p-1 rounded'>
                        True
                      </div>
                    </>
                  )
                }
              </div>
              <div className='d-flex mb-1'>
                {
                  !answer.answer_boolean.answer ? (
                    <>
                      <div className='mr-1 fa-fw py-1'>
                        {
                          (!answer.answer_boolean.answer && !question.correct_answer) ? (
                            <FontAwesomeIcon icon='check' className='text-green' />
                          ) : (
                            <FontAwesomeIcon icon='times' className='text-danger' />
                          )
                        }
                      </div>
                      <div className={`p-1 rounded border ${!answer.answer_boolean.answer && !question.correct_answer ? 'border-green' : 'border-danger'}`}>
                        False
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='mr-1 fa-fw py-1'>
                        {
                          !question.correct_answer && (
                            <FontAwesomeIcon icon='check' className='text-green' />
                          )
                        }
                      </div>
                      <div className='p-1 rounded'>
                        False
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          );
        }

        return (
          <>
            <div>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </div>
            <div>
              <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
            </div>
          </>
        );
      case 'multipleChoice':
        if (answer.answer_multiple_choice) {
          return (
            <div>
              {
                question.choices.map((choice, key) => {
                  let choiceAnswer = answer.answer_multiple_choice.find(amc => amc.question_multiple_choice_choice_id === choice.id);
                  if (choiceAnswer) {
                    return (
                      <div className='d-flex mb-1'>
                        <div className='mr-1 py-1'>
                          {
                            choice.is_correct ? (
                              <FontAwesomeIcon icon='check' className='text-green fa-fw' />
                            ) : (
                              <FontAwesomeIcon icon='times' className='text-danger fa-fw' />
                            )
                          }
                        </div>
                        <div className={`flex-fill p-1 rounded border ${choice.is_correct ? 'border-green' : 'border-danger'}`}>
                          {choice.description}
                        </div>
                      </div>
                    );
                  }

                  return (
                    <div className='d-flex mb-1'>
                      <div className='mr-1 fa-fw'>
                        {
                          choice.is_correct && (
                            <FontAwesomeIcon icon='check' className='text-green' />
                          )
                        }
                      </div>
                      <div className='flex-fill'>
                        {choice.description}
                      </div>
                    </div>
                  );
                })
              }
            </div>
          );
        }
        
        return (
          <>
            <div>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </div>
            <div>
              <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
            </div>
          </>
        );
      case 'ordering':
        if (answer.answer_ordering) {
          let answers = [];
          for(let i = 0; i < answer.answer_ordering.length; i++) {
            let item = question.items.find(qi => qi.id === answer.answer_ordering[i].question_ordering_item_id);

            answers = [
              ...answers,
              {
                description: item.description,
                is_correct: item.order === answer.answer_ordering[i].order
              }
            ];
          }

          return (
            <>
              <div>
                {
                  answers.map((answer, key) => (
                    <div key={key} className='d-flex mb-1'>
                      <div className='font-weight-bold py-1 pl-1'>
                        {`${key+1}.`}
                      </div>
                      {
                        answer.is_correct !== null ? (
                          <>
                            <div className='mx-1 py-1'>
                              {
                                answer.is_correct ? (
                                  <FontAwesomeIcon icon='check' className='text-green fa-fw' />
                                ) : (
                                  <FontAwesomeIcon icon='times' className='text-danger fa-fw' />
                                )
                              }
                            </div>
                            <div className={`flex-fill p-1 rounded border ${answer.is_correct ? 'border-green' : 'border-danger'}`}>
                              {answer.description}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='fa-fw'>
                            </div>
                            <div className='flex-fill p-1'>
                              {answer.description}
                            </div>
                          </>
                        )
                      }
                    </div>
                  ))
                }
              </div>
              <div>
                <CorrectAnswers type={type} question={question} show={viewAllAnswers} answers={answers} />
              </div>
            </>
          )
        }
        
        return (
          <>
            <div>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </div>
            <div>
              <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
            </div>
          </>
        );
      case 'essay':
        const { materialData, match: { params: { classID } } } = this.props;
          const { viewSubmission: { submissionData: { student_id }, selectedSubmission } } = this.state;

        let imagesMap = answer.files && answer.files.length > 0 ? answer.files.filter(f => f.is_image) : [];
        let filesMap = answer.files && answer.files.length > 0 ? answer.files.filter(f => !f.is_image) : [];

        return (
          <>
            {
              answer.files && answer.files.length > 0 && (!answer.answer_essay || !answer.answer_essay.answer) ? null : (answer.answer_essay && answer.answer_essay.answer) ? (
                <div style={{ whiteSpace: 'pre-line' }}>
                  {answer.answer_essay.answer}
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
                </div>
              )
            }
            {
              answer.files && answer.files.length > 0 && (
                <>
                    {
                      imagesMap.length > 0 && (
                        <Row noGutters>
                        {
                          imagesMap.map((image, index) => {
                            return (
                              <Col key={index} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(materialData, student_id, selectedSubmission, answer, imagesMap, index)}>
                                <Image src={`${process.env['REACT_APP_API_BASE_URL']}/school-admin/class/${classID}/material/${materialData.type}/${materialData.id}/submission/student/${student_id}/attempt/${selectedSubmission}/answer/${answer.id}/image/${image.id}`} thumbnail />
                              </Col>
                            );
                          })
                        }
                        </Row>
                      )
                    }
                    {
                      filesMap.length > 0 && (
                        <div className='px-2'>
                          {
                            filesMap.map((file, index) => (
                              <div key={index} className='d-flex mb-2'>
                                <div className='mr-2'>
                                  <FontAwesomeIcon icon='file' className='text-primary' />
                                </div>
                                <div style={{ wordBreak: 'break-all' }}>
                                  <span className='btn-link' onClick={e => this.handleAnswerFileDownload(answer.id, file.id)}>
                                    {file.file_title}
                                  </span>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }
                  </>
              )
            }
          </>
        );
      case 'matching':
        if (answer.answer_matching) {
          let answers = [];
          for (let i = 0; i < question.items.length; i++) {
            let itemAnswer = answer.answer_matching.find(am => am.question_matching_item_id === question.items[i].id);

            if (!itemAnswer || !itemAnswer.answer) {
              answers = [
                ...answers,
                {
                  question: question.items[i].description,
                  answer: '',
                  is_correct: false,
                }
              ];
              continue;
            }

            answers = [
              ...answers,
              {
                question: question.items[i].description,
                answer: itemAnswer.answer,
                is_correct: question.items[i].answer === itemAnswer.answer
              }
            ];
          }

          return (
            <>
              <div>
                {
                  answers.map((answer, key) => (
                    <div key={key} className='d-flex mb-1'>
                      <div className='font-weight-bold py-1 pl-1'>
                        {`${key+1}.`}
                      </div>
                      <div className='mx-1 py-1'>
                        {
                          answer.is_correct ? (
                            <FontAwesomeIcon icon='check' className='text-green fa-fw' />
                          ) : (
                            <FontAwesomeIcon icon='times' className='text-danger fa-fw' />
                          )
                        }
                      </div>
                      <div className='flex-fill'>
                        {
                          answer.answer ? (
                            <div className={`p-1 rounded border ${answer.is_correct ? 'border-green' : 'border-danger'}`}>
                              <div>
                                {answer.question}
                              </div>
                              <div className='d-flex pl-1'>
                                <div className='mr-1'>
                                  <FontAwesomeIcon icon='long-arrow-alt-right' className='text-info' />
                                </div>
                                <div className='flex-fill'>
                                  {answer.answer}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='font-italic text-muted p-1 rounded border border-danger'>
                              (Blank)
                            </div>
                          )
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
              <div>
                <CorrectAnswers type={type} question={question} show={viewAllAnswers} answers={answers} />
              </div>
            </>
          );
        }

        return (
          <>
            <div>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </div>
            <div>
              <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
            </div>
          </>
        );
      case 'identification':
        if (answer.answer_identification) {
          let answers = [];
          for (let i = 0; i < question.items.length; i++) {
            let itemAnswer = answer.answer_identification.find(am => am.question_identification_item_id === question.items[i].id);

            if (!itemAnswer || !itemAnswer.answer) {
              answers = [
                ...answers,
                {
                  question: question.items[i].description,
                  answer: !itemAnswer ? '' : itemAnswer.answer,
                  is_correct: false,
                }
              ];
              continue;
            }

            if (question.is_case_sensitive) {
              answers = [
                ...answers,
                {
                  question: question.items[i].description,
                  answer: itemAnswer.answer,
                  is_correct: question.items[i].answer === itemAnswer.answer
                }
              ];
            } else {
              answers = [
                ...answers,
                {
                  question: question.items[i].description,
                  answer: itemAnswer.answer,
                  is_correct: question.items[i].answer.toUpperCase() === itemAnswer.answer.toUpperCase()
                }
              ];
            }
          }

          return (
            <>
              <div>
                {
                  answers.map((answer, key) => (
                    <div key={key} className='d-flex mb-1'>
                      <div className='font-weight-bold py-1 pl-1'>
                        {`${key+1}.`}
                      </div>
                      <div className='mx-1 py-1'>
                        {
                          answer.is_correct ? (
                            <FontAwesomeIcon icon='check' className='text-green fa-fw' />
                          ) : (
                            <FontAwesomeIcon icon='times' className='text-danger fa-fw' />
                          )
                        }
                      </div>
                      <div className='flex-fill'>
                        {
                          answer.answer ? (
                            <div className={`p-1 rounded border ${answer.is_correct ? 'border-green' : 'border-danger'}`}>
                              {answer.answer}
                            </div>
                          ) : (
                            <div className={`font-italic text-muted p-1 rounded border ${answer.is_correct ? 'border-green' : 'border-danger'}`}>
                              (Blank)
                            </div>
                          )
                        }
                        <div className='font-italic text-muted p-1 small'>
                          {answer.question}
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
              <div>
                <CorrectAnswers type={type} question={question} show={viewAllAnswers} answers={answers} />
              </div>
            </>
          );
        }

        return (
          <>
            <div>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </div>
            <div>
              <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
            </div>
          </>
        );
      case 'enumeration':
        if (answer.answer_enumeration && answer.answer_enumeration.find(a => a.answer !== '')) {
          let caseSensitiveAnswers = [...question.items.map(item => item.description)];
          let caseInsensitiveAnswers = [...caseSensitiveAnswers].map(a => a.toUpperCase());

          let answers = [];
          for (let i = 0; i < answer.answer_enumeration.length; i++) {
            if (question.is_case_sensitive) {
              let searchIndex = caseSensitiveAnswers.indexOf(answer.answer_enumeration[i].answer);
              if (searchIndex !== -1) {
                caseSensitiveAnswers.splice(searchIndex, 1);
                if (question.is_in_order) {
                  answers = [
                    ...answers,
                    {
                      answer: answer.answer_enumeration[i].answer,
                      is_correct: question.items[i].description === answer.answer_enumeration[i].answer
                    }
                  ];
                } else {
                  answers = [
                    ...answers,
                    {
                      answer: answer.answer_enumeration[i].answer,
                      is_correct: true
                    }
                  ];
                }
              } else {
                answers = [
                  ...answers,
                  {
                    answer: answer.answer_enumeration[i].answer,
                    is_correct: false
                  }
                ];
              }
            } else {
              let searchIndex = caseInsensitiveAnswers.indexOf(answer.answer_enumeration[i].answer.toUpperCase());
              if (searchIndex !== -1) {
                caseInsensitiveAnswers.splice(searchIndex, 1);
                if (question.is_in_order) {
                  answers = [
                    ...answers,
                    {
                      answer: answer.answer_enumeration[i].answer,
                      is_correct: question.items[i].description.toUpperCase() === answer.answer_enumeration[i].answer.toUpperCase()
                    }
                  ];
                } else {
                  answers = [
                    ...answers,
                    {
                      answer: answer.answer_enumeration[i].answer,
                      is_correct: true
                    }
                  ];
                }
              } else {
                answers = [
                  ...answers,
                  {
                    answer: answer.answer_enumeration[i].answer,
                    is_correct: false
                  }
                ];
              }
            }
          }
          return (
            <>
              <div>
                {
                  answers.map((answer, key) => (
                    <div key={key} className='d-flex mb-1'>
                      <div className='font-weight-bold py-1 pl-1'>
                        {`${key+1}.`}
                      </div>
                      <div className='mx-1 py-1'>
                        {
                          answer.is_correct ? (
                            <FontAwesomeIcon icon='check' className='text-green fa-fw' />
                          ) : (
                            <FontAwesomeIcon icon='times' className='text-danger fa-fw' />
                          )
                        }
                      </div>
                      <div className='flex-fill'>
                        {
                          answer.answer ? (
                            <div className={`p-1 rounded border ${answer.is_correct ? 'border-green' : 'border-danger'}`}>
                              {answer.answer}
                            </div>
                          ) : (
                            <div className={`font-italic text-muted p-1 rounded border ${answer.is_correct ? 'border-green' : 'border-danger'}`}>
                              (Blank)
                            </div>
                          )
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
              <div>
                <CorrectAnswers type={type} question={question} show={viewAllAnswers} answers={answers} />
              </div>
            </>
          );
        }

        return (
          <>
            <div>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </div>
            <div>
              <CorrectAnswers type={type} question={question} show={viewAllAnswers} />
            </div>
          </>
        );
      default:
        return null;
    }
  }
  renderSubmission = (id, type, points, question, submission) => {
    let answer = submission.question_answers.find(qa => qa.question_id === id);

    return (
      <>
        {
          answer && answer.points_override !== null ? (
            <div className='text-info'>
              (<b>{answer.points_override.points}</b> / {points}) &mdash;<small className='font-italic'>Overridden</small></div>
          ) : (
            <div className='text-muted'>(<b>{answer ? answer.points : 0}</b> / {points})</div>
          )
        }
        <div className='my-1 pl-2 pl-md-3'>
          {this.renderAnswer(type, question, answer)}
        </div>
      </>
    );
  }
  renderViewSubmission = () => {
    const {
      viewSubmission: {
        errorMessage,
        submissions,
        selectedSubmission,
        viewAllAnswers
      },
      questions
    } = this.state;

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (submissions.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let submission = submissions.find(submission => submission.id === +selectedSubmission);
    let revisionIndex = submissions.length;

    return (
      <>
        {
          submissions.length > 1 && (
            <Form.Control as='select' value={selectedSubmission} onChange={this.handleSelectSubmission}>
              <option disabled hidden value=''>Select attempt...</option>
              {
                submissions.map((submission, index) => (
                  <option key={index} value={submission.id}>Attempt {revisionIndex--} &mdash; ({submission.points_with_override} point{submission.points_with_override > 1 ? 's' : ''})</option>
                ))
              }
            </Form.Control>
          )
        }
        <div className='mt-1'>
          <Button variant='warning' onClick={e => this.setState({...this.state, viewSubmission: {...this.state.viewSubmission, viewAllAnswers: !viewAllAnswers}})}>
            {
              viewAllAnswers ? (
                <>
                  <FontAwesomeIcon icon='eye-slash' className='mr-1' />
                  <span className='d-none d-md-inline-block'>Hide all correct answer(s)</span>
                  <span className='d-md-none fa-layers'>
                    <FontAwesomeIcon icon={['far', 'comments']} flip='horizontal' transform='grow-3' />
                    <FontAwesomeIcon icon='check' transform='up-4 right-7 shrink-2' />
                  </span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon='eye' className='mr-1' />
                  <span className='d-none d-md-inline-block'>Show all correct answer(s)</span>
                  <span className='d-md-none fa-layers'>
                    <FontAwesomeIcon icon={['far', 'comments']} flip='horizontal' transform='grow-3' />
                    <FontAwesomeIcon icon='check' transform='up-4 right-7 shrink-2' />
                  </span>
                </>
              )
            }
          </Button>
        </div>
        <div className='p-1 p-md-3 submission-question-list'>
          {
            questions.map(({question, points, type, id}, index) => (
              <div key={id}>
                <div className='d-flex'>
                  <div className='h6 px-1 px-md-3 m-0'>{index+1}</div>
                  <div className='flex-fill'>
                    <RichTextEditor.Viewer body={question.description} />
                    {/* <div>{question.description}</div> */}
                    {this.renderSubmission(id, type, points, question, submission)}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </>
    );
  }
  renderGraded = submission => {
    const { materialData } = this.props;
    const { materialPoints } = this.state;

    let gradeData = materialData.grades.find((grade) => grade.student_id === submission.student_id);

    if (gradeData) {
      return (
        <div className='text-muted'>
          Recorded grade: {gradeData.grade} / {materialPoints}
          {
            submission.points_with_override !== gradeData.grade && (
              <small className='font-italic ml-2'>(Overridden)</small>
            )
          }
        </div>
      );
    }

    return (
      <div className='text-muted'>
        (No grade record saved)
      </div>
    );
  }
  renderSubmissionRemark = submission => {
    const { materialData } = this.props;

    let start = moment(materialData.faculty_load_id ? materialData.until : materialData.audience.until);
    let end = moment(submission.latest_update);

    if (end.isAfter(start)) {
      let duration = moment.duration(end.diff(start));
      let humanized = duration.humanize();
      // humanized = humanized.split(' ').map(h => '' + h.charAt(0).toUpperCase() + h.slice(1)).join(' ');
      humanized = humanized.charAt(0).toUpperCase() + humanized.slice(1);
      // let detailed = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
      // let detailed = duration.months()+'mo ' + duration.days()+'d ' + duration.hours()+'h ' + duration.minutes()+'m ' + duration.seconds()+'s';
      return (
        <div className='text-danger font-weight-bold'>
          {humanized} late <span className='text-muted font-italic font-weight-normal'>({durationFormat(duration)})</span>
        </div>
      );
    }

    return (
      <div className='text-green font-weight-bold'>
        On time
      </div>
    );
  }
  render() {
    const { isLoading, errorMessage, submissions, view, viewSubmission, materialPoints } = this.state;
    const { materialData } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (submissions.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      )
    }

    return (
      <>
        {
          view === 'list' ? (
            <>
              <div className='submission-list'>
                {
                  submissions.map(submission => (
                    <div key={submission.id} className='py-3 p-md-3 py-md-0'>
                      <div className='d-flex'>
                        <div className='flex-fill'>
                          <div>
                            <NameLink
                              id={submission.profile.id}
                              name={submission.profile.name}
                              image={submission.profile.image}
                              rank={submission.profile.rank} />
                          </div>
                          {
                            materialData.type !== 'exam' && (
                              <>
                                {this.renderSubmissionRemark(submission)}
                                <div className='text-muted'>
                                  Attempts: {submission.attempt_count}
                                </div>
                              </>
                            )
                          }
                          <div className='text-muted'>
                            <div>
                              Points: {submission.points_with_override} / {materialPoints}
                              {
                                materialData.type !== 'exam' && (
                                  <small className='ml-2 font-italic'>(Based on the latest attempt)</small>
                                )
                              }
                              {
                                submission.points_overridden && (
                                  <small className='ml-2 font-italic'>&mdash;Overridden</small>
                                )
                              }
                            </div>
                            <div>
                              Duration: {moment.utc(moment.duration(moment(submission.latest_update).diff(submission.created_at)).asMilliseconds()).format('HH:mm:ss')}
                            </div>
                            {this.renderGraded(submission)}
                          </div>
                        </div>
                        <div>
                          <Button variant='warning' size='sm' title='View submission' className='mr-2' onClick={() => this.showViewSubmission(submission)}>
                            <FontAwesomeIcon icon='eye' />
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </>
          ) : viewSubmission.isLoading ? (
            <LoadingIcon />
          ) : (
            <div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={this.hideViewSubmission}>Submissions</Breadcrumb.Item>
                <Breadcrumb.Item active>{viewSubmission.submissionData ? viewSubmission.submissionData.profile.name : 'Student'}</Breadcrumb.Item>
              </Breadcrumb>
              <div>
                {this.renderViewSubmission()}
              </div>
            </div>
          )
        }
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Submissions);