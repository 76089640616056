import React, { Component } from 'react';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import moment from 'moment';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../common/RichTextEditor/RichTextEditor';

const typeTitleMap = {
  assignment: 'Assignment',
  quiz: 'Quiz',
  exam: 'Exam',
  file: 'File',
  link: 'Link'
};

export default class Materials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      materials: {
        data: []
      },
      isNextPageLoading: false,
      nextPageError: ''
    };
  }
  componentDidMount() {
    const { classID } = this.props.match.params;

    axiosRequest('get', `parent/class/${classID}/material`, null, ({ data: { data }}) => {
        this.setState({
        ...this.state,
        materials: data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { materials } = this.state;

      const path = materials.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          materials: {
            ...data,
            data: [
              ...this.state.materials.data,
              ...data.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  renderFileTitle = material => {
    let from = material.faculty_load_id ? material.from : material.audience.from;
    let until = material.faculty_load_id ? material.until : material.audience.until;

    if (from && until && !moment().isBetween(from, until)) {
      return (
        <div className='font-weight-bold'>{material.title} <span className='text-muted font-weight-normal font-italic'>(Unavailable)</span></div>
      );
    } else if (from && moment().isBefore(from)) {
      return (
        <div className='font-weight-bold'>{material.title} <span className='text-muted font-weight-normal font-italic'>(Unavailable)</span></div>
      );
    } else if (until && moment().isAfter(until)) {
      return (
        <div className='font-weight-bold'>{material.title} <span className='text-muted font-weight-normal font-italic'>(Unavailable)</span></div>
      );
    }

    return (
      <div className='font-weight-bold'>{material.title}</div>
    );
  }
  renderMaterialIcon = type => {
    switch (type) {
      case 'assignment':
        return (
          <div className='fa-3x'>
            <div className='fa-layers'>
              <FontAwesomeIcon icon='file' size='lg' />
              <FontAwesomeIcon icon='pencil-alt' inverse transform='shrink-4 right-2 down-4' />
            </div>
          </div>
        );
      case 'quiz':
        return (
          <div className='fa-3x'>
            <div className='fa-layers'>
              <FontAwesomeIcon icon='file-alt' size='lg' />
              <FontAwesomeIcon icon='pencil-alt' inverse transform='shrink-4 right-2 down-4' />
            </div>
          </div>
        );
      case 'exam':
        return (
          <div className='fa-3x'>
            <div className='fa-layers'>
              <FontAwesomeIcon icon='clipboard-list' size='lg' />
              <FontAwesomeIcon icon='pencil-alt' inverse transform='shrink-4 right-2 down-4' />
            </div>
          </div>
        );
      case 'file':
        return (
          <div className='fa-3x'>
            <FontAwesomeIcon icon='file-download' size='lg' />
          </div>
        );
      case 'link':
        return (
          <div className='fa-3x'>
            <FontAwesomeIcon icon='link' />
          </div>
        );
      default:
        return null;
    }
  }
  render() {
    const { materials, isLoading, errorMessage, isNextPageLoading, nextPageError } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (materials.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='material-list'>
          {
            materials.data.map(material => (
              <div key={`${material.type}-${material.id}`} className='d-flex' title={typeTitleMap[material.type]}>
                <div className='mr-2'>
                  {this.renderMaterialIcon(material.type)}
                </div>
                <div className='flex-fill'>
                  {
                    material.type === 'file' ? this.renderFileTitle(material) :
                      material.type === 'link' ? (
                        <div className='font-weight-bold'>
                          {material.title} <FontAwesomeIcon icon='external-link-alt' />
                        </div>
                      ) : material.type === 'assignment' ? (
                        <div className='font-weight-bold'>
                          {material.title}
                          {
                            moment().isBefore(material.faculty_load_id ? material.from : material.audience.from) && (
                              <span className='text-muted font-weight-normal font-italic'> (Not yet available)</span>
                            )
                          }
                        </div>
                      ) : (
                      <div className='font-weight-bold'>{material.title}</div>
                    )
                  }
                  <RichTextEditor.Viewer body={material.description} className='text-muted' />
                  {/* <div className='text-muted'>{material.description}</div> */}
                  {
                    material.type === 'file' ? (
                      <div className='text-muted font-italic d-flex'>
                        <div className='mr-1'>
                          Available
                        </div>
                        <div>
                          {
                            material.faculty_load_id ? (
                              material.from && material.until ? (
                                <>
                                  <div>
                                    From: {moment(material.from).format('ddd, MMMM D, YYYY hh:mm A')}
                                  </div>
                                  <div>
                                    Until: {moment(material.until).format('ddd, MMMM D, YYYY hh:mm A')}
                                  </div>
                                </>
                              ) : material.from ? (
                                <div>
                                  From: {moment(material.from).format('ddd, MMMM D, YYYY hh:mm A')}
                                </div>
                              ) : material.until ? (
                                <div>
                                  Until: {moment(material.until).format('ddd, MMMM D, YYYY hh:mm A')}
                                </div>
                              ) : ''
                            ) : (
                              material.audience.from && material.audience.until ? (
                                <>
                                  <div>
                                    From: {moment(material.audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
                                  </div>
                                  <div>
                                    Until: {moment(material.audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
                                  </div>
                                </>
                              ) : material.audience.from ? (
                                <div>
                                  From: {moment(material.audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
                                </div>
                              ) : material.audience.until ? (
                                <div>
                                  Until: {moment(material.audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
                                </div>
                              ) : ''
                            )
                          }
                        </div>
                      </div>
                    ) : material.type === 'link' ? '' : (
                      <div className='text-muted font-italic d-flex'>
                        <div className='mr-1'>
                          Available
                        </div>
                        <div>
                          <div>
                            From: {moment(material.faculty_load_id ? material.from : material.audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
                          </div>
                          <div>
                            Until: {moment(material.faculty_load_id ? material.until : material.audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
                <div className='d-md-none'>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          {moment(material.created_at).format('MMM D, YYYY hh:mm A')}
                        </div>
                        <div>
                          {moment(material.created_at).fromNow()}
                        </div>
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <FontAwesomeIcon icon='clock' className='text-muted' />
                  </OverlayTrigger>
                </div>
                <div className='font-small text-muted font-italic pr-3 d-none d-md-block'>
                  &mdash; <OverlayTrigger
                            overlay={
                              <Tooltip>
                                {moment(material.created_at).format('MMM D, YYYY hh:mm A')}
                              </Tooltip>
                            }
                            trigger={['hover', 'focus']}>
                            <FontAwesomeIcon icon='clock' />
                          </OverlayTrigger> {moment(material.created_at).fromNow()}
                </div>
              </div>
            ))
          }
        </div>
        {
          materials.next_page_url && (
            <div className='text-center mt-3'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                View more materials
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          )
        }
      </>
    );
  }
}