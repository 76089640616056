import React, { Component } from 'react';
import { Alert, Button, Col, Form, Image, Modal, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import './style.scss';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import Validator from 'validatorjs';
import axios from 'axios';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../../common/RichTextEditor/RichTextEditor';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        show: false,
        description: '',
        isLoading: false,
        errorMessage: '',
        loadingFiles: false
      },
      fileNames: []
    };
    
    this.files = [];
  }
  showModal = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        show: true
      }
    })
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        show: false
      },
      fileNames: []
    }, () => {
      this.files = [];
    });
  }
  handleInputChange = event => {
    this.setState({
      modal: {
        ...this.state.modal,
        description: event.target.value
      }
    });
  }
  handleFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.files = [...this.files, ...files];
      this.setState({
        ...this.state,
        fileNames: [
          ...this.state.fileNames,
          ...filesState
        ]
      });
    }
  }
  handleRemoveUpload = id => {
    const { fileNames } = this.state;

    const newFileNames = fileNames.filter((fileName, index) => {
      return index !== id;
    });

    this.setState({
      ...this.state,
      fileNames: newFileNames
    }, () => {
      this.files = [...this.files].filter((file, index) => {
        return index !== id;
      });
    });
  }
  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        isLoading: true,
        errorMessage: '',
        loadingFiles: true,
      }
    }, () => {
      const { modal } = this.state;

      let validator = new Validator({
        description: modal.description
      }, {
        description: 'required|min:3'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0],
            loadingFiles: false
          }
        });
        return;
      }

      const { material, match: { params: { classID } } } = this.props;

      axiosRequest('post', `class/${classID}/material/${material.type}/${material.id}/submission`, {
        description: modal.description
      }, ({ data: { data }}) => {
        if (this.files.length > 0) {
          let fileRequests = [];
          for (let i = 0; i < this.files.length; i++) {
            fileRequests.push(this.uploadFile(i, data.id));
          }
          Promise.all(fileRequests).then(result => {
            this.setState({
              ...this.state,
              modal: {
                show: false,
                description: '',
                isLoading: false,
                errorMessage: '',
                loadingFiles: false
              },
              fileNames: []
            }, () => {
              this.files = [];
              this.props.onSubmit();
            });
          }).catch(error => {
            this.setState({
              ...this.state,
              modal: {
                ...this.state.modal,
                isLoading: false,
                errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
                loadingFiles: false
              }
            });
          });
        } else {
          this.setState({
            ...this.state,
            modal: {
              show: false,
              description: '',
              isLoading: false,
              errorMessage: '',
              loadingFiles: false
            }
          }, this.props.onSubmit);
        }
      }, (error) => {
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
            loadingFiles: false
          }
        });
      }, this.props.history);
    });
  }
  uploadFile = (i, submissionID) => {
    const { material, match: { params: { classID } } } = this.props;
    const formData = new window.FormData();
    formData.append('file', this.files[i], this.files[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/material/${material.type}/${material.id}/submission/${submissionID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { fileNames } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          fileNames
        });
      }
    }).then(({ data: { data }}) => {
      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        fileNames
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        fileNames
      });

      throw fileNames[i].isError;
    });
  }
  handleStart = () => {
    this.props.history.push(`${this.props.match.url}/answer/start`);
  }
  handleResume = () => {
    this.props.history.push(`${this.props.match.url}/answer/resume`);
  }
  handleFileDownload = fileID => {
    const { material, match: { params: { classID } } } = this.props;

    axiosRequest('get', `class/${classID}/material/${material.type}/${material.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handlePreviewImages = (material, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/material/${material.type}/${material.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  renderAssignment = () => {
    const { material, match: { params: { classID } } } = this.props;
    const { modal, fileNames } = this.state;

    const images = material.files ? material.files.filter(file => {
      return !!file.is_image;
    }) : [];

    const files = material.files ? material.files.filter(file => {
      return !file.is_image;
    }) : [];

    return (
      <>
        <div className='d-flex'>
          <div className='flex-fill'>
            <div className='h5 pt-1 m-0' style={{ wordBreak: 'break-all' }}>
              {material.title}
            </div>
            {
              material.faculty_load_id ? (
                <div className='text-muted font-italic'>
                  Available from
                  <b> {moment(material.from).format('ddd, MMM D, YYYY hh:mm A')} </b>
                  and due
                  <b> {moment(material.until).format('ddd, MMM D, YYYY hh:mm A')} </b>
                </div>
              ) : (
                <div className='text-muted font-italic'>
                  Available from
                  <b> {moment(material.audience.from).format('ddd, MMM D, YYYY hh:mm A')} </b>
                  and due
                  <b> {moment(material.audience.until).format('ddd, MMM D, YYYY hh:mm A')} </b>
                </div>
              )
            }
          </div>
          <div className='d-block pl-2'>
            <div className='d-flex align-items-center'>
              <div className='pr-2 mt-1'>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {
                        material.faculty_load_id ? (
                          <div>Category: {material.grade_category.name}</div>
                        ) : (
                          <div>Category: {material.audience.grade_category.name}</div>
                        )
                      }
                      <div>Posted: {moment(material.created_at).format('dddd, MMM D, YYYY hh:mm A')}</div>
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='info-circle' className='info-icon text-primary' size='lg' />
                </OverlayTrigger>
              </div>
              {
                material.revision && (
                  <div className='d-block'>
                    <Button variant='green' onClick={this.showModal} className='d-flex align-items-center'>
                      <div className='fa-layers fa-fw my-1'>
                        <FontAwesomeIcon icon='file' size='lg' />
                        <FontAwesomeIcon icon='pencil-alt' color='black' transform='shrink-4 right-2 down-4' />
                      </div>
                      <div className='ml-2 d-none d-md-block' style={{ whiteSpace: 'nowrap'}}>
                        Submit assignment
                      </div>
                    </Button>
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className='p-md-3'>
          <RichTextEditor.Viewer body={material.description} />
          {/* <div style={{ whiteSpace: 'pre-line' }}>
            {material.description}
          </div> */}
          {
            (material.files && material.files.length > 0) && (
              <div>
                {
                  images.length > 0 && (
                    <Row>
                    {
                      images.map((image, index) => (
                        <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(material, images, index)}>
                          <Image src={`${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/material/${material.type}/${material.id}/image/${image.id}`} thumbnail />
                        </Col>
                      ))
                    }
                    </Row>
                  )
                }
                {
                  files.length > 0 && (
                    <div className='p-1'>
                      {
                        files.map(file => (
                          <div key={file.id} className='d-flex mb-2'>
                            <div className='mr-2'>
                              <FontAwesomeIcon icon='file' className='text-primary' />
                            </div>
                            <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                              <span className='btn-link' onClick={() => this.handleFileDownload(file.id)}>
                                {file.file_title}
                              </span>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  )
                }
              </div>
            )
          }
        </div>

        <Modal show={modal.show} backdrop='static' onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Submit assignment</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleSubmit} className='submit-form'>
            <Modal.Body>
              {
                modal.errorMessage && (
                  <Alert variant='danger'>
                    {modal.errorMessage}
                  </Alert>
                )
              }
              <Form.Group>
                <Form.Label>Answer/Description</Form.Label>
                <Form.Control as='textarea' rows={8} value={modal.description} onChange={this.handleInputChange} className={fileNames.length > 0 ? 'has-file-upload' : ''} />
                {
                fileNames.length > 0 && (
                  <small className='file-upload-display d-block'>
                  {
                    fileNames.map((fileName, index) => (
                      <div key={index} className='file-name d-flex'>
                        <div>
                          {
                            fileName.isLoading || (modal.loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                              <LoadingIcon />
                            ) : fileName.isError ? (
                              <FontAwesomeIcon icon='times-circle' className='text-danger' />
                            ) : fileName.isSuccess ? (
                              <FontAwesomeIcon icon='check-circle' className='text-green' />
                            ) : (
                              <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                            )
                          }
                        </div>
                        <div className='ml-2 flex-fill'>
                          <div>
                            {fileName.name}
                          </div>
                          {
                            fileName.isLoading && (
                              <div className='mt-1'>
                                <ProgressBar now={fileName.isLoading} />
                              </div>
                            )
                          }
                        </div>
                        {
                          (!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !modal.loadingFiles) && (
                            <div className='ml-2 align-items-center d-flex'>
                              <Button
                                variant='link'
                                className='text-danger'
                                size='sm'
                                title='Remove'
                                onClick={(e) => this.handleRemoveUpload(index)}>
                                <FontAwesomeIcon icon='times' size='sm' />
                              </Button>
                            </div>
                          )
                        }
                      </div>
                    ))
                  }
                  </small>
                )
              }
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <div className='mr-auto'>
                <label className={`btn btn-info m-0 ${modal.isLoading ? 'disabled' : ''}`} htmlFor='add-file' title='File upload'>
                    <FontAwesomeIcon icon='file-upload' />
                  </label>
                  <Form.File className='d-none' id='add-file' onChange={this.handleFileUpload} multiple disabled={modal.isLoading} />
              </div>
              <Button variant='danger' className='mr-2' onClick={this.hideModal} disabled={modal.isLoading}>
                Close
              </Button>
              <Button variant='green' type='submit' disabled={modal.isLoading}>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
  renderTimeLimit = () => {
    const { material } = this.props;
    if (material.time_limit) {
      let duration = moment.duration(material.time_limit, 'minutes');
      let hours = duration.hours();
      let minutes = duration.minutes();
      let display = hours && minutes ? `${hours} hour${hours > 1 ? 's' : ''} and ${minutes} minute${minutes > 1 ? 's' : ''}` :
                    hours ? `${hours} hour${hours > 1 ? 's' : ''}` : 
                    minutes ? `${minutes} minute${minutes > 1 ? 's' : ''}` : duration.humanize();
      return (
        <div className='mt-1'>
          <FontAwesomeIcon icon='stopwatch' /> You have at least <b>{display} ({material.time_limit}m)</b> to finish the {material.type}.
        </div>
      )
    }

    return (
      <div className='mt-1 text-muted'>
        No time limit was set.
      </div>
    );
  }
  renderQuiz = () => {
    const { material } = this.props;
    
    return (
      <>
        <div className='h5'>
          {material.title} <small>({material.faculty_load_id ? material.grade_category.name : material.audience.grade_category.name})</small>
        </div>
        <RichTextEditor.Viewer body={material.description} />
        {/* <div style={{ whiteSpace: 'pre-line' }}>
          {material.description}
        </div> */}
        <div className='mt-3'>
          <span className='pr-1'>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  Submissions <b>AFTER</b> the given duration will be <b>SAVED</b> but will be marked as <b>LATE</b>.
                </Tooltip>
              }
              trigger={['hover', 'focus']}>
              <FontAwesomeIcon icon='info-circle' className='info-icon text-primary' />
            </OverlayTrigger>
          </span>
          Submissions are enabled from
          <b> {moment(material.faculty_load_id ? material.from : material.audience.from).format('ddd, MMM D, YYYY hh:mm A')} </b>
          until
          <b> {moment(material.faculty_load_id ? material.until : material.audience.until).format('ddd, MMM D, YYYY hh:mm A')} </b>
        </div>
        { this.renderTimeLimit() }
        <div className='dropdown-divider'></div>
        <div>
          You have made {material.attempt_count} of 3 attempts. You have {3 - material.attempt_count} attempt{(3-material.attempt_count) !== 1 ? 's' : ''} remaining.
          <Col md={6} className='mt-2'>
            <ProgressBar now={Math.round((material.attempt_count / 3) * 100)} label={`${material.attempt_count} of 3`} />
          </Col>
        </div>
        <div className='mt-2'>
        {
          moment().isBefore(moment(material.faculty_load_id ? material.from : material.audience.from)) ? (
            <Alert variant='danger'>
              Quiz is not yet available.
            </Alert>
          ) : material.resume ? (
            <Button variant='green' onClick={this.handleResume}>
              Resume last attempt
            </Button>
          ) : material.attempt_count >= 3 ? (
            <Alert variant='danger'>
              You have reached the maximum number of attempts.
            </Alert>
          ) : !material.has_question ? (
            <Alert variant='danger'>
              Questions are not available.
            </Alert>
          ) : (
              <Button
                variant='green'
                onClick={this.handleStart}
              >
                Start a new attempt
              </Button>
          )
        }
        </div>
      </>
    );
  }
  renderExam = () => {
    const { material } = this.props;
    
    return (
      <>
        <div className='h5 m-0'>
          {material.title} <small>({material.faculty_load_id ? material.grade_category.name : material.audience.grade_category.name})</small>
        </div>
        <div className='font-italic mb-1'>
          {
            material.faculty_load_id ? (material.exam_category ? material.exam_category.title : '') : material.audience.exam_category ? material.audience.exam_category.title : '' 
          }
        </div>
        <RichTextEditor.Viewer body={material.description} />
        {/* <div style={{ whiteSpace: 'pre-line' }}>
          {material.description}
        </div> */}
        <div className='mt-3'>
          <span className='pr-1'>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  Submissions <b>AFTER</b> the given duration will <b>NOT</b> be saved.
                </Tooltip>
              }
              trigger={['hover', 'focus']}>
              <FontAwesomeIcon icon='info-circle' className='info-icon text-primary' />
            </OverlayTrigger>
          </span>
          Submissions are enabled from
          <b> {moment(material.faculty_load_id ? material.from : material.audience.from).format('ddd, MMM D, YYYY hh:mm A')} </b>
          until
          <b> {moment(material.faculty_load_id ? material.until : material.audience.until).format('ddd, MMM D, YYYY hh:mm A')} </b>
        </div>
        <div className='mt-2'>
        {
          material.disabled_exam ? (
            <Alert variant='danger'>
              Please secure an examination permit to take the scheduled exam.
            </Alert>
          ) : moment().isBefore(moment(material.faculty_load_id ? material.from : material.audience.from)) ? (
            <Alert variant='danger'>
              Exam is not yet available.
            </Alert>
          ) : material.resume ? (
            <Button variant='green' onClick={this.handleStart}>
              Resume attempt
            </Button>
          ) : moment().isAfter(moment(material.faculty_load_id ? material.until : material.audience.until)) ? (
            <Alert variant='danger'>
              Exam availability has lapsed.
            </Alert>
          ) : material.attempt_count > 0 ? (
            <Alert variant='danger'>
              Only <b>one</b> attempt is allowed.
            </Alert>
          ) : (
              <Button
                variant='green'
                onClick={this.handleStart}
              >
                Start answering
              </Button>
          )
        }
        </div>
      </>
    );
  }
  renderContent = () => {
    const { materialType } = this.props.match.params;

    switch (materialType) {
      case 'assignment':
        return this.renderAssignment();
      case 'quiz':
        return this.renderQuiz();
      case 'exam':
        return this.renderExam();
      default:
        return null;
    }
  }
  render() {
    return (
      <div className='p-md-3'>
        {this.renderContent()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Details);