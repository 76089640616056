import React, { Component } from 'react';
import { Alert, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NameLink from '../../common/NameLink/NameLink';
import moment from 'moment';
import axiosRequest from '../../../util/helpers/axiosRequest';
import './style.scss';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../common/RichTextEditor/RichTextEditor';
import PostImages from '../../common/PostImages/PostImages';

export default class Discussion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      posts: {
        data: []
      },
      isNextPageLoading: false,
      nextPageError: ''
    };
  }
  componentDidMount() {
    const { userType, classID } = this.props.match.params;

    axiosRequest('get', `${userType}/class/${classID}/posts`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        posts: {...data},
        isLoading: false,
        errorMessage: ''
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { posts } = this.state;

      const path = posts.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          posts: {
            ...data,
            data: [
              ...this.state.posts.data,
              ...data.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleFileDownload = (post, fileID) => {
    const { userType } = this.props.match.params;
    
    axiosRequest('get', `${userType}/class/${post.faculty_load_id}/posts/${post.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  render() {
    const { isLoading, errorMessage, posts, isNextPageLoading, nextPageError } = this.state;
    const { userType } = this.props.match.params;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (posts.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <div className='posts-container mx-n4 px-4 my-n4 py-4'>
        {
          posts.data.map(post => {
            const images = post.files.filter(file => {
              return !!file.is_image;
            });
  
            const files = post.files.filter(file => {
              return !file.is_image;
            });

            return (
              <Card key={post.id} className='mb-3 shadow-sm'>
                <Card.Body>
                  <div className='post-header'>
                    <div>
                      <b className='d-block'>
                        <NameLink
                          id={post.profile.id}
                          name={post.profile.name}
                          image={post.profile.image}
                          learningPlatform={post.profile.student_learning_platform ? post.profile.student_learning_platform.platform : null}
                          rank={post.profile.rank} />
                      </b>
                    </div>
                  </div>
                  <div className='dropdown-divider'></div>
                  <small className='d-block text-right font-italic' style={{ color: '#6c757d' }}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          {moment(post.created_at).format('MMM D, YYYY hh:mm A')}
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <FontAwesomeIcon icon='clock' />
                    </OverlayTrigger> {moment(post.created_at).fromNow()}
                  </small>
                  <div className='mb-1'>
                    <RichTextEditor.Viewer body={post.message} />
                  </div>
                  {
                    post.links.length > 0 && (
                      <div className='p-1'>
                        {
                          post.links.map(link => (
                            <small key={link.id} className='d-flex mb-2'>
                              <div className='mr-2'>
                                <FontAwesomeIcon icon='link' className='text-primary' />
                              </div>
                              <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                                <a href={link.url} target='_blank' rel='noopener noreferrer'>
                                  {link.title} <FontAwesomeIcon icon='external-link-alt' />
                                </a>
                              </div>
                            </small>
                          ))
                        }
                      </div>
                    )
                  }
                  {
                    files.length > 0 && (
                      <div className='p-1'>
                        {
                          files.map(file => (
                            <small key={file.id} className='d-flex mb-2'>
                              <div className='mr-2'>
                                <FontAwesomeIcon icon='file' className='text-primary' />
                              </div>
                              <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                                <span className='btn-link' onClick={() => this.handleFileDownload(post, file.id)}>
                                  {file.file_title}
                                </span>
                              </div>
                            </small>
                          ))
                        }
                      </div>
                    )
                  }
                  {
                    images.length > 0 && (
                      <PostImages
                        images={images.map(image => ({
                          id: image.id,
                          src: `${process.env['REACT_APP_API_BASE_URL']}/${userType}/class/${post.faculty_load_id}/posts/${post.id}/image/${image.id}`
                        }))} />
                    )
                  }
                  {/* {
                    images.length > 0 && (
                      <Row noGutters>
                      {
                        images.map(image => (
                          <Col key={image.id} xs={4} className='image-container'>
                            <a href={`${process.env['REACT_APP_API_BASE_URL']}/${userType}/class/${post.faculty_load_id}/posts/${post.id}/image/${image.id}`} target='_blank' rel='noopener noreferrer'>
                              <Image className='post-image' src={`${process.env['REACT_APP_API_BASE_URL']}/${userType}/class/${post.faculty_load_id}/posts/${post.id}/image/${image.id}`} thumbnail />
                            </a>
                          </Col>
                        ))
                      }
                      </Row>
                    )
                  } */}
                </Card.Body>
                {
                  post.likes.length > 0 && (
                    <Card.Footer className='px-3 py-1 text-right'>
                      <small className='mr-2 font-italic'>
                        {`${post.likes.length > 1 ? post.likes.length : post.likes[0].profile.name} liked this`}
                      </small>
                    </Card.Footer>
                  )
                }
                {
                  post.comments.length > 0 && (
                    <div className='border-top comments-section'>
                      {
                        post.comments.map(comment => (
                          <div key={comment.id} className='py-1 px-3'>
                            <div>
                              <div className='d-flex justify-content-between'>
                                <b>
                                  <NameLink
                                    id={comment.profile.id}
                                    name={comment.profile.name}
                                    image={comment.profile.image}
                                    learningPlatform={comment.profile.student_learning_platform ? comment.profile.student_learning_platform.platform : null}
                                    rank={comment.profile.rank} />
                                </b>
                                <div>
                                  <small className='d-block text-right font-italic' style={{ color: '#6c757d' }}>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip>
                                          {moment(comment.created_at).format('MMM D, YYYY hh:mm A')}
                                        </Tooltip>
                                      }
                                      trigger={['hover', 'focus']}>
                                      <FontAwesomeIcon icon='clock' />
                                    </OverlayTrigger> {moment(comment.created_at).fromNow()}
                                  </small>
                                </div>
                              </div>
                              <div style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}>
                                {comment.message}
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  )
                }
              </Card>
            );
          })
        }
        {
          posts.next_page_url ? (
            <div className='text-center'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                View more posts
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          ) : (
            <Alert variant='light' className='text-center'>
              No more posts.
            </Alert>
          )
        }
      </div>
    );
  }
}