import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginUser, logoutUser } from './actions';
import { Redirect, Route, Switch } from 'react-router-dom';
import ChangePassword from './components/views/ChangePassword/ChangePassword';
import Admin from './components/views/Admin/Admin';
import Faculty from './components/views/Faculty/Faculty';
import Student from './components/views/Student/Student';
import Parent from './components/views/Parent/Parent';
import SchoolAdmin from './components/views/SchoolAdmin/SchoolAdmin';
import Staff from './components/views/Staff/Staff';
import Profile from './components/views/Profile/Profile';
import Chat from './components/views/Chat/Chat';
import axios from 'axios';
import Conversation from './components/views/Chat/Conversation';
import Help from './components/views/Help/Help';
import TimeTable from './components/views/TimeTable/TimeTable';
// import Activities from './components/views/SchoolAdmin/Activities/Activities';
import Attendance from './components/views/Staff/Attendance/Attendance';
import SchoolAdminAttendance from './components/views/SchoolAdmin/Attendance/Attendance';
import UploadLimit from './components/views/UploadLimit/UploadLimit';
import Materials from './components/views/Faculty/Materials/Materials';
import FloatingButtons from './components/common/FloatingButtons/FloatingButtons';
import ExamPermit from './components/views/Staff/ExamPermit/ExamPermit';
import ClassAdvisory from './components/views/Faculty/ClassAdvisory/ClassAdvisory';
import PaymentTransaction from './components/views/Staff/PaymentTransaction/PaymentTransaction';
import Grade from './components/views/Staff/Grade/Grade';
import LoadingPage from './components/common/LoadingPage/LoadingPage';
import ImagePreview from './components/common/ImagePreview/ImagePreview';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      closeLoading: false
    }
  }
  componentDidMount() {
    const { currentUser } = this.props;
    if (!currentUser) {
      axios.get(`${process.env['REACT_APP_API_BASE_URL']}/current-user`, {
        withCredentials: true
      }).then(({ data: { account } }) => {
        this.props.loginUser(account);
        this.setState({
          closeLoading: true
        });
      }).catch((res) => {
        this.props.logoutUser();
        this.setState({
          closeLoading: true
        });
      });
    } else {
      this.setState({
        closeLoading: true
      });
    }
  }
  handleCloseLoading = () => {
    this.setState({
      ...this.state,
      isLoading: false
    });
  }
  render() {
    const { isLoading, closeLoading } = this.state;
    const { currentUser } = this.props;

    if (isLoading) {
      return (
        <LoadingPage closeLoading={closeLoading} onClose={this.handleCloseLoading} />
      );
    }

    if (!currentUser) {
      return <Redirect to={{ pathname:'/login', state: { from: this.props.location }}} />;
    }

    if (currentUser.is_first_login) {
      return (
        <Switch>
          <Route exact path='/change-password' component={ChangePassword} />
          <Redirect to={{ pathname:'/change-password', state: { from: this.props.location }}} />
        </Switch>
      );
    }

    return <AccountTypeRoute currentUser={currentUser} {...this.props} />;
              
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

const mapDispatchToProps = dispatch => ({
  loginUser: user => dispatch(loginUser(user)),
  logoutUser: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

const AccountTypeRoute = props => {
  switch(props.currentUser.account_type) {
    case 0:
      return (
        <Switch>
          <Route path='/admin/:activeNav(settings|users|enrollment|faculty-load|class-advisory|drop-courses|block-users|transition|approval-requests|id-generation)' component={Admin} />
          <Route path='/profile/:profileID?' component={Profile} />
          <Route path='/change-password' component={ChangePassword} />
          <Route exact path='/chat' component={Chat} />
          <Route exact path='/chat/:conversationID' component={Conversation} />
          <Redirect to={{ pathname: '/admin/settings/school-levels' }} />
        </Switch>
      );
    case 1:
      return (
        <>
          <Switch>
            <Route path='/school-admin' component={SchoolAdmin} />
            <Route path='/profile/:profileID?' component={Profile} />
            <Route path='/attendance' component={SchoolAdminAttendance} />
            <Route path='/change-password' component={ChangePassword} />
            <Route exact path='/chat' component={Chat} />
            <Route exact path='/chat/:conversationID' component={Conversation} />
            <Route exact path='/time-table' component={TimeTable} />
            <Route exact path='/help/:header?/:article?' component={Help} />
            <Redirect to={{ pathname: '/school-admin' }} />
          </Switch>
          <FloatingButtons {...props} />
        </>
      );
    case 2:
      return (
        <>
          <Switch>
            <Route path='/faculty' component={Faculty} />
            <Route path='/profile/:profileID?' component={Profile} />
            <Route path='/materials/:activeNav?' component={Materials} />
            <Route path='/class-advisory' component={ClassAdvisory} />
            <Route path='/upload-limit' component={UploadLimit} />
            <Route path='/change-password' component={ChangePassword} />
            <Route exact path='/chat' component={Chat} />
            <Route exact path='/chat/:conversationID' component={Conversation} />
            <Route exact path='/time-table' component={TimeTable} />
            <Route exact path='/help/:header?/:article?' component={Help} />
            <Redirect to={{ pathname: '/faculty' }} />
          </Switch>
          <FloatingButtons {...props} />
          <ImagePreview {...props} />
        </>
      );
    case 3:
      return (
        <>
          <Switch>
            <Route path='/staff' component={Staff} />
            <Route path='/profile/:profileID?' component={Profile} />
            <Route path='/attendance/:userType?' component={Attendance} />
            <Route path='/grade' component={Grade} />
            <Route path='/exam-permit' component={ExamPermit} />
            <Route path='/payment-transaction' component={PaymentTransaction} />
            <Route path='/change-password' component={ChangePassword} />
            <Route exact path='/chat' component={Chat} />
            <Route exact path='/chat/:conversationID' component={Conversation} />
            <Route exact path='/help/:header?/:article?' component={Help} />
            <Redirect to={{ pathname: '/staff' }} />
          </Switch>
          <FloatingButtons {...props} />
        </>
      );
    case 4:
      return (
        <>
          <Switch>
            <Route path='/parent' component={Parent} />
            <Route path='/profile/:profileID?' component={Profile} />
            <Route path='/change-password' component={ChangePassword} />
            <Route exact path='/chat' component={Chat} />
            <Route exact path='/chat/:conversationID' component={Conversation} />
            <Route exact path='/time-table' component={TimeTable} />
            <Route exact path='/help/:header?/:article?' component={Help} />
            <Redirect to={{ pathname: '/parent' }} />
          </Switch>
          <FloatingButtons {...props} />
        </>
      );
    case 5:
      return (
        <>
          <Switch>
            <Route path='/student' component={Student} />
            <Route path='/profile/:profileID?' component={Profile} />
            <Route path='/change-password' component={ChangePassword} />
            <Route exact path='/chat' component={Chat} />
            <Route exact path='/chat/:conversationID' component={Conversation} />
            <Route exact path='/time-table' component={TimeTable} />
            <Route exact path='/help/:header?/:article?' component={Help} />
            <Redirect to={{ pathname: '/student' }} />
          </Switch>
          <FloatingButtons {...props} />
        </>
      );
    default:
      return <Redirect to={{ pathname: '/login' }} />;
  }
}