import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Settings from './parts/Settings';
import Requests from './parts/Requests';

const IDGeneration = props => {
  return (
    <Switch>
      <Route exact path='/admin/id-generation/settings' component={Settings} />
      <Route exact path='/admin/id-generation/requests' component={Requests} />
      <Redirect to={{ pathname: '/admin/id-generation/settings'}} />
    </Switch>
  );
};

export default IDGeneration;
