import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../util/helpers/axiosRequest';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

export default class ClassEnrollment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      classID: '',
      classes: [],
      courses: [],
      isCourseLoading: false,
      classCourse: [],
      loadingCourses: [],
      errorMessage: '',
      courseError: '',
      formError: '',
      searchQuery: ''
    };
  }
  componentDidMount() {
    axiosRequest('get', 'admin/class-course/options', null, ({ data: { data }}) => {
      data.classes.sort((a, b) => {
        if (a.program.code < b.program.code) {
          return -1;
        } else if (a.program.code > b.program.code) {
          return 1;
        }

        if (a.year_level.code < b.year_level.code) {
          return -1;
        } else if (a.year_level.code > b.year_level.code) {
          return 1;
        }

        if (a.section.code < b.section.code) {
          return -1;
        } else if (a.section.code > b.section.code) {
          return 1;
        }

        return 0;
      });
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  removeFromLoading = (id) => {
    const loadingCourses = [...this.state.loadingCourses].filter(loadingCourse => {
      return loadingCourse !== id;
    });
    this.setState({
      ...this.state,
      loadingCourses
    });
  }
  handleEnroll = (id) => {
    const { classID } = this.state;
    this.setState({
      ...this.state,
      loadingCourses: [
        ...this.state.loadingCourses,
        id
      ],
      formError: ''
    }, () => {
      axiosRequest('post', 'admin/class-course', {
        classID,
        courseID: id
      }, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          classCourse: {
            ...this.state.classCourse,
            class_course: [
              ...this.state.classCourse.class_course,
              data
            ]
          }
        }, () => {
          this.removeFromLoading(id);
        });
      }, (error) => {
        this.setState({
          ...this.state,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }, () => {
          this.removeFromLoading(id);
        });
      }, this.props.history);
    });
  }
  handleUnenroll = (id, courseID) => {
    this.setState({
      ...this.state,
      loadingCourses: [
        ...this.state.loadingCourses,
        courseID
      ],
      formError: ''
    }, () => {
      axiosRequest('delete', `admin/class-course/${id}`, null, ({ data: { data } }) => {
        const class_course = [...this.state.classCourse.class_course].filter(item => {
          return item.id !== id;
        });
        this.setState({
          ...this.state,
          classCourse: {
            ...this.state.classCourse,
            class_course: [
              ...class_course
            ]
          }
        }, () => {
          this.removeFromLoading(courseID);
        });
      }, (error) => {
        this.setState({
          ...this.state,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }, () => {
          this.removeFromLoading(courseID);
        });
      }, this.props.history);
    });
  }
  handleUnenrollUnassign = (id, courseID) => {
    this.setState({
      ...this.state,
      loadingCourses: [
        ...this.state.loadingCourses,
        courseID
      ],
      formError: ''
    }, () => {
      axiosRequest('delete', `admin/class-course/${id}/unassign`, null, ({ data: { data } }) => {
        const class_course = [...this.state.classCourse.class_course].filter(item => {
          return item.id !== id;
        });
        this.setState({
          ...this.state,
          classCourse: {
            ...this.state.classCourse,
            class_course: [
              ...class_course
            ]
          }
        }, () => {
          this.removeFromLoading(courseID);
        });
      }, (error) => {
        this.setState({
          ...this.state,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }, () => {
          this.removeFromLoading(courseID);
        });
      }, this.props.history);
    });
  }
  handleClassChange = event => {
    const classID = event.target.value;
    this.setState({
      isCourseLoading: true
    }, () => {
      axiosRequest('get', `admin/class-course/${classID}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          classID,
          classCourse: data,
          isCourseLoading: false
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isCourseLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleSearchInputChange = event => {
    this.setState({
      ...this.state,
      searchQuery: event.target.value
    });
  }
  renderCourses = () => {
    const { courses, classID, isCourseLoading, classCourse, loadingCourses, courseError, formError, searchQuery } = this.state;

    if (isCourseLoading) {
      return (
        <LoadingIcon />
      );
    }
    
    if (courseError) {
      return (
        <Alert variant='danger'>
          {courseError}
        </Alert>
      );
    }

    if (!classID) {
      return;
    }

    let searchFilteredCourses = [...courses];
    let searchFilteredClassCourses = [...classCourse.class_course];

    if (searchQuery.toString()) {
      searchFilteredCourses = [...courses].filter(c => c.code.indexOf(searchQuery) > -1 || c.description.indexOf(searchQuery) > -1);
      searchFilteredClassCourses = [...classCourse.class_course].filter(c => c.course.code.indexOf(searchQuery) > -1 || c.course.description.indexOf(searchQuery) > -1);
    }
    
    let enrolledCourses = [];
    for (let i = 0; i < classCourse.class_course.length; i++) {
      enrolledCourses.push(classCourse.class_course[i].course.id);
    }

    let filteredCourses = searchFilteredCourses.filter((course) => {
      return enrolledCourses.indexOf(course.id) === -1;
    });

    return (
      <>
        <Card.Title>
          <div>{`${classCourse.program.description} (${classCourse.program.code})`}</div>
          <div>{`${classCourse.year_level.description} (${classCourse.year_level.code})`}</div>
          <div>{`Section ${classCourse.section.code}`}</div>
        </Card.Title>
        {
          formError && (
            <Alert variant='danger'>
              {formError}
            </Alert>
          )
        }
        <Row>
          <Col lg={6} className='ml-auto'>
            <div className='mb-2 mt-1'>
              <Form.Control type='search' placeholder='Search courses...' value={searchQuery} onChange={this.handleSearchInputChange} />
            </div>
          </Col>
        </Row>
        {
          (searchFilteredCourses.length > 0 || searchFilteredClassCourses.length > 0) ? (
            <ListGroup>
              {
                searchFilteredClassCourses.map((classCourse) => (
                  <ListGroup.Item key={classCourse.course.id} variant='green'>
                    <div className='d-flex'>
                      <div className='pr-2'>
                        {
                          loadingCourses.indexOf(classCourse.course.id) !== -1 ? (
                            <LoadingIcon />
                          ) : (
                            <FontAwesomeIcon icon='check-circle' />
                          )
                        }
                      </div>
                      <div className='flex-fill'>
                        <b>{classCourse.course.code}</b>
                        <div>{classCourse.course.description}</div>
                      </div>
                      <div className='d-flex flex-column align-items-end justify-content-center'>
                        <div>
                          <Button
                            size='sm'
                            variant='danger'
                            onClick={() => this.handleUnenroll(classCourse.id, classCourse.course.id)}
                            disabled={loadingCourses.indexOf(classCourse.course.id) !== -1}>
                              Unenroll <FontAwesomeIcon icon='angle-double-right' />
                          </Button>
                        </div>
                        {
                          classCourse.faculty_load && (
                            <div className='mt-1'>
                              <Button
                                size='sm'
                                variant='danger'
                                className='ml-2'
                                onClick={() => this.handleUnenrollUnassign(classCourse.id, classCourse.course.id)}
                                disabled={loadingCourses.indexOf(classCourse.course.id) !== -1}>
                                  Unenroll (Delete Faculty Load) <FontAwesomeIcon icon='angle-double-right' />
                              </Button>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </ListGroup.Item>
                ))
              }
              {
                filteredCourses.map((course) => {
                  return (
                    <ListGroup.Item key={course.id}>
                      <div className='d-flex'>
                        {
                          loadingCourses.indexOf(course.id) !== -1 ? (
                            <div className='pr-2'>
                              <LoadingIcon />
                            </div>
                          ) : (
                            <div className='pr-4'></div>
                          )
                        }
                        <div className='flex-fill'>
                          <b>{course.code}</b>
                          <div>{course.description}</div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <Button
                            size='sm'
                            variant='green'
                            onClick={() => this.handleEnroll(course.id)}
                            disabled={loadingCourses.indexOf(course.id) !== -1}>
                            Enroll <FontAwesomeIcon icon='angle-double-right' /></Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  );
                })
              }
            </ListGroup>
          ) : searchQuery ? (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          ) : (
            <Alert variant='warning'>
              Nothing to show. Try adding a <b>course</b> first.
            </Alert>
          )
        }
        
      </>
    );
  }
  renderContent = () => {
    const { isLoading, classes, errorMessage } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }
    
    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (classes.length === 0) {
      return (
        <Alert variant='warning'>
          Nothing to show. Try adding a <b>class</b> first.
        </Alert>
      );
    }

    return (
      <>
        <Form.Group>
          <Form.Label>Class</Form.Label>
          <Form.Control as='select' onChange={this.handleClassChange}>
            <option disabled hidden selected>Select a class to enroll...</option>
            {
              classes.map((cl, index) => (
                <option key={index} value={cl.id}>{`${cl.program.code} ${cl.year_level.code} - ${cl.section.code}`}</option>
              ))
            }
          </Form.Control>
        </Form.Group>
        <div className='dropdown-divider'></div>
        { this.renderCourses() }
      </>
    );
  }
  render() {
    return (
      <Card>
        <Card.Body>
          { this.renderContent() }
        </Card.Body>
      </Card>
    );
  }
}