import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Card, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import Header from '../../common/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../util/helpers/axiosRequest';
import EditModal from '../../modals/EditModal/EditModal';
import profileDefault from '../../../resources/image/profile-default.png';
import './style.scss';
import PromptDeleteModal from '../../modals/PromptDeleteModal/PromptDeleteModal';
import RankBadge from '../../common/RankBadge/RankBadge';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      isLoading: true,
      errorMessage: '',
      isImageLoading: true,
      editModal: {
        show: false,
        isLoading: false,
        errorMessage: false,
        data: null
      },
      imageModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        preview: null,
        isPreviewLoading: false,
        fileName: ''
      },
      deleteImageModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      }
    };

    this.file = null;
  }
  componentDidMount() {
    this.getProfile();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.profileID !== this.props.match.params.profileID) {
      this.setState({
        ...this.state,
        isLoading: true
      }, () => {
        this.getProfile();
      });
    }
  }
  getProfile = () => {
    let path = 'profile';
    if (this.props.match.params.profileID) {
      path += `/${this.props.match.params.profileID}`;
    }

    axiosRequest('get', path, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        profile: data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showEditModal = () => {
    const { profile } = this.state;
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        show: true,
        data: {...profile}
      }
    });
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        data: null,
        errorMessage: '',
        isLoading: false
      }
    });
  }
  showImageModal = () => {
    this.setState({
      ...this.state,
      imageModal: {
        show: true,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideImageModal = () => {
    this.setState({
      ...this.state,
      imageModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        preview: null,
        isPreviewLoading: false,
        fileName: ''
      }
    }, () => {
      this.file = null;
    });
  }
  showDeleteImageModal = () => {
    this.setState({
      ...this.state,
      deleteImageModal: {
        show: true,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteImageModal = () => {
    this.setState({
      ...this.state,
      deleteImageModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleEditInputChange = event => {
    const editModal = {...this.state.editModal};
    
    editModal.data[event.target.name] = event.target.value;

    this.setState({
      ...this.state,
      editModal
    });
  }
  handleEdit = () => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      axiosRequest('patch', 'profile', {
        ...this.state.editModal.data
      }, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          profile: {
            ...this.state.profile,
            ...data
          },
          editModal: {
            show: false,
            data: null,
            errorMessage: '',
            isLoading: false
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      });
    });
  }
  handleImageChange = event => {
    const files = event.target.files;

    if (files.length > 0) {
      this.setState({
        ...this.state,
        imageModal: {
          ...this.state.imageModal,
          isPreviewLoading: true,
          errorMessage: '',
          fileName: ''
        }
      }, () => {
        const fileToBase64 = file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result));
            reader.addEventListener('error', error => reject(error));
            reader.readAsDataURL(file);
          });
        };
        const image = files[0];
        let validator = {
          passed: true,
          error: null
        };

        if (typeof image.name !== 'string') {
          validator.passed = false;
          validator.error = 'Invalid file.';
        } else if (image.type !== 'image/jpeg' && image.type !== 'image/png') {
          validator.passed = false;
          validator.error = 'Invalid image format.';
        }
        // else if (image.size > 5*1024*1024) {
        //   validator.passed = false;
        //   validator.error = 'Image must not exceed 5MB.'
        // }
        
        if (validator.passed) {
          fileToBase64(image).then(imageSrc => {
            this.setState({
              ...this.state,
              imageModal: {
                ...this.state.imageModal,
                preview: imageSrc,
                isPreviewLoading: false,
                fileName: image.name
              }
            }, () => {
              this.file = image;
            });
          }).catch(() => {
            this.setState({
              ...this.state,
              imageModal: {
                ...this.state.imageModal,
                preview: null,
                errorMessage:  'Failed to read image.',
                isPreviewLoading: false,
                fileName: ''
              }
            }, () => {
              this.file = null;
            });
          });
        } else {
          this.setState({
            ...this.state,
            imageModal: {
              ...this.state.imageModal,
              preview: null,
              errorMessage:  validator.error,
              isPreviewLoading: false,
              fileName: ''
            }
          }, () => {
            this.file = null;
          });
        }
      });
    }
  }
  handleUploadImage = () => {
    this.setState({
      ...this.state,
      imageModal: {
        ...this.state.imageModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      let validator = {
        passed: true,
        error: null
      };

      if (this.file === null) {
        validator.passed = false;
        validator.error = 'The image is required.';
      } else if (typeof this.file.name !== 'string') {
        validator.passed = false;
        validator.error = 'Invalid file.';
      } else if (this.file.type !== 'image/jpeg' && this.file.type !== 'image/png') {
        validator.passed = false;
        validator.error = 'Invalid image format.';
      }

      if (validator.passed) {
        const formData = new window.FormData();
        formData.append('file', this.file, this.file.name);

        axiosRequest('post', `profile`, formData, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            profile: {
              ...this.state.profile,
              ...data
            },
            imageModal: {
              show: false,
              isLoading: false,
              errorMessage: '',
              preview: null,
              isPreviewLoading: false,
              fileName: ''
            }
          }, () => {
            this.file = null;
          });
        }, error => {
          this.setState({
            ...this.state,
            imageModal: {
              ...this.state.imageModal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        }, this.props.history);
      } else {
        this.setState({
          ...this.state,
          imageModal: {
            ...this.state.imageModal,
            isLoading: false,
            errorMessage: validator.error
          }
        });
      }
    });
  }
  handleDeleteImage = () => {
    this.setState({
      ...this.state,
      deleteImageModal: {
        ...this.state.deleteImageModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      axiosRequest('delete', `profile`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          profile: {
            ...this.state.profile,
            upload_limit: data,
            image: null
          },
          deleteImageModal: {
            show: false,
            isLoading: false,
            errorMessage: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          deleteImageModal: {
            ...this.state.deleteImageModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleMessage = () => {
    const { profile } = this.state;

    if (profile) {
      this.props.history.push('/chat', { newMessage: true, to: { id: profile.id, name: profile.name } });
    }
  }
  handleArchive = () => {
    this.props.history.push('/upload-limit');
  }
  renderProfileMenu = () => {
    const { currentUser } = this.props;
    const { profile } = this.state;

    if (currentUser.account_type !== 4) {
      if ((currentUser.account_type === 5 && profile.id === currentUser.id) || (currentUser.account_type !== 5 && profile.id !== currentUser.id && profile.account_type === 5)) {
        return <ProfileMenu {...this.props} profile={profile} view='student' />;
      } else if (profile.account_type === 2 && (profile.id === currentUser.id || currentUser.account_type !== 5)) {
        return <ProfileMenu {...this.props} profile={profile} currentUser={currentUser} view='faculty' />;
      }
    }

    return null;
  }
  renderSubProfile = () => {
    const { profile, editModal } = this.state;
    switch (profile.account_type) {
      case 0:
      case 1:
      case 2:
      case 3:
        return (
          <>
            <div>{profile.position}</div>
            {
              editModal.data && (
                <EditModal
                  {...editModal}
                  title='Edit Profile'
                  onHide={this.hideEditModal}
                  onEdit={this.handleEdit}>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control type='text' name='title' value={editModal.data.title} onChange={this.handleEditInputChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Position</Form.Label>
                    <Form.Control type='text' name='position' value={editModal.data.position} onChange={this.handleEditInputChange} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Short Bio</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={4}
                      style={{ resize: 'none' }}
                      value={editModal.data.description}
                      name='description'
                      onChange={this.handleEditInputChange} />
                  </Form.Group>
                </EditModal>
              )
            }
          </>
        );
      case 5:
        return (
          <>
            {
              profile.student_class.school_class ? (
                <>
                  <div>{`${profile.student_class.school_class.program.description} (${profile.student_class.school_class.program.code})`}</div>
                  <div>{`${profile.student_class.school_class.year_level.description} (${profile.student_class.school_class.year_level.code}) - ${profile.student_class.school_class.section.code}`}</div>
                </>
              ) : (
                <>
                  <div>{`${profile.program.description} (${profile.program.code})`}</div>
                  <div>{`${profile.year_level.description} (${profile.year_level.code})`}</div>
                </>
              )
            }
            {
              editModal.data && (
                <EditModal {...editModal} title='Edit Profile' onHide={this.hideEditModal} onEdit={this.handleEdit}>
                  <Form.Group>
                    <Form.Label>Short Bio</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={4}
                      style={{ resize: 'none' }}
                      value={editModal.data.description}
                      name='description'
                      onChange={this.handleEditInputChange} />
                  </Form.Group>
                </EditModal>
              )
            }
          </>
        );
      default:
        return null;
    }
  }
  renderProfile = () => {
    const { isLoading, errorMessage, isImageLoading, profile, imageModal, deleteImageModal } = this.state;
    const { currentUser } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <Row>
          <Col md={3}>
            <div className='profile-image-container'>
              <div className='profile-image-background-container' style={{ position: 'relative' }}>
                {
                  isImageLoading && (
                    <div className='d-flex justify-content-center align-items-center' style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                      <LoadingIcon />
                    </div>
                  )
                }
                <Image
                  src={profile.image ? `${process.env['REACT_APP_API_BASE_URL']}/profile/${profile.id}/${profile.image}` : profileDefault}
                  onLoad={e => this.setState({...this.state, isImageLoading: false})} />
                <RankBadge rank={profile.rank} rankStyle={{ fontSize: '2rem' }} />
              </div>
              {
                (currentUser.id === profile.id && (currentUser.account_type !== 5 && currentUser.account_type !== 4)) && (
                  <>
                    <div className='image-action-container'>
                      {
                        profile.image && (
                          <div onClick={this.showDeleteImageModal} title='Delete profile picture' className='mb-2'>
                            <span>
                              <FontAwesomeIcon icon='trash-alt' />
                            </span>
                          </div>
                        )
                      }
                      <div onClick={this.showImageModal} title='Update profile picture'>
                        <span>
                          <FontAwesomeIcon icon='pencil-alt' />
                        </span>
                      </div>
                    </div>
                    <Modal show={imageModal.show} onHide={this.hideImageModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Update profile picture</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {
                          imageModal.errorMessage && (
                            <Alert variant='danger'>
                              {imageModal.errorMessage}
                            </Alert>
                          )
                        }
                        <div style={{ height: '15rem', textAlign: 'center' }}>
                          {
                            imageModal.isPreviewLoading ? (
                              <LoadingIcon />
                            ) : (
                              <Image
                                src={
                                  imageModal.preview ? imageModal.preview :
                                    profile.image ? `${process.env['REACT_APP_API_BASE_URL']}/profile/${profile.id}/${profile.image}` : profileDefault
                                }
                                style={{ objectFit: 'cover', height: '100%' }} fluid />
                            )
                          }
                        </div>
                        <div className='dropdown-divider'></div>
                        <Form.Group>
                          <Form.File.Label>Upload image</Form.File.Label>
                          <Form.File 
                            id='image-upload'
                            label={imageModal.fileName ? imageModal.fileName : 'No file chosen'}
                            accept='image/png,image/jpeg'
                            onChange={this.handleImageChange}
                            custom
                          />
                        </Form.Group>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant='danger' className='mr-2' onClick={this.hideImageModal}>
                          Cancel
                        </Button>
                        <Button variant='green' onClick={this.handleUploadImage} disabled={imageModal.isLoading}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <PromptDeleteModal
                      {...deleteImageModal}
                      title='Delete profile picture'
                      onHide={this.hideDeleteImageModal}
                      onDelete={this.handleDeleteImage}
                    >
                      {
                        profile.image && (
                          <>
                            <div className='font-weight-bold'>
                              Are you sure you want to delete the profile picture?
                            </div>
                            <Alert variant='light'>
                              <div style={{ height: '15rem', textAlign: 'center' }}>
                                <Image
                                  src={`${process.env['REACT_APP_API_BASE_URL']}/profile/${profile.id}/${profile.image}`}
                                  style={{ objectFit: 'cover', height: '100%' }} fluid />
                              </div>
                            </Alert>
                          </>
                        )
                      }
                    </PromptDeleteModal>
                  </>
                )
              }
            </div>
          </Col>
          <Col className='mt-2 mt-md-0'>
            <div className='d-flex'>
              <h4 className='d-block flex-fill'>{profile.name}</h4>
              {
                currentUser.account_type !== 4 && (
                  <>
                    {
                      currentUser.id === profile.id ? (
                        <div>
                          <Button variant='info' size='sm' onClick={this.showEditModal}>
                            <FontAwesomeIcon icon='pencil-alt' />
                            <span className='ml-2 d-none d-md-inline-block'>
                              Edit Profile
                            </span>
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button variant='info' size='sm' onClick={this.handleMessage}>
                            <FontAwesomeIcon icon='envelope' />
                            <span className='ml-2 d-none d-md-inline-block'>
                              Message
                            </span>
                          </Button>
                        </div>
                      )
                    }
                  </>
                )
              }
            </div>
            { this.renderSubProfile() }
            <div className='font-italic mt-2' style={{ color: '#6c757d', whiteSpace: 'pre-line' }}>
              {profile.description}
            </div>
            {/* {
              (currentUser.account_type !== 5 && currentUser.account_type !== 2 && profile.id === currentUser.id && profile.upload_limit) && (
                <div className='text-muted'>
                  <div className='mb-2 d-flex'>
                    <Button
                      variant='warning'
                      size='sm'
                      className='mr-2'
                      title='Archive'
                      onClick={this.handleArchive}>
                      <FontAwesomeIcon icon='archive' />
                      <span className='ml-2 d-none d-lg-inline-block'>Archive</span>
                    </Button>
                    <div className='align-self-end'>
                      Upload limit ({profile.upload_limit.current_upload_size} / {profile.upload_limit.max_upload_size} MB)
                    </div>
                  </div>
                  <ProgressBar now={Math.round(profile.upload_limit.current_upload_size / profile.upload_limit.max_upload_size) * 100} label={`${profile.upload_limit.current_upload_size} MB`} />
                </div>
              )
            } */}
          </Col>
        </Row>
        { this.renderProfileMenu() }
      </>
    );
  }
  render() {
    const { currentUser } = this.props;
    const { params } = this.props.match;
    return (
      <>
        <Header active={(params.profileID && +params.profileID !== currentUser.id) ? 'Other Profile' : 'Profile'} />
        <Container>
          <Card className='my-3'>
            <Card.Body>
              { this.renderProfile() }
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(Profile);