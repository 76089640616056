import React from 'react';
import { Alert } from 'react-bootstrap';

export default function Quiz(props) {
  function student() {
    return (
      <>
        <div className='mb-3'>
          <div className='h4 text-primary'>Quizzes</div>
          <div>
            To <b>view</b> a quiz, click on the name of the material.<br />
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Details</div>
          <div>
            <ol>
              <li><b>Title</b></li>
              <li><b>Grade category</b></li>
              <li><b>Date available</b> &mdash; submitted attempts after the <b>until</b> date, will be marked as late</li>
              <li><b>Time limit</b> &mdash; optionally visible</li>
              <li><b>Number of attempts</b></li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Start an attempt</div>
          <div>
            To start an attempt, click on <b>Start a new attempt</b>.
          </div>
          <div>
            <ul>
              <li>Before starting an attempt, please make sure you have a <b>stable Internet connection</b> and a <b>good battery power</b> to prevent wasting of attempts.</li>
              <li>Quiz attempts are limited to <b>three (3)</b> only</li>
              <li>If a time limit was set, the quiz will automatically end after the time runs out</li>
              <li><b>Do not close</b> the browser when an attempt has started.</li>
            </ul>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Submissions</div>
          <div>
            Submissions are ordered based on the date it was submitted.
          </div>
          <div>
            <ol>
              <li><b>Submission number</b></li>
              <li><b>Score</b> &mdash; system generated score or grade.</li>
              <li><b>Submission date</b></li>
              <li>
                <div><b>Remarks</b></div>
                <div>
                  <b className='text-green'>On time</b> &mdash; if the submission date is before the <b>until</b> date<br/>
                  <b className='text-danger'>Late</b> &mdash; if the submission date is after the <b>until</b> date specified
                </div>
              </li>
            </ol>
          </div>
        </div>
      </>
    );
  }

  function faculty() {
    return (
      <>
        <div className='mb-3'>
          <div className='h4 text-primary'>Create a quiz</div>
          <div>
            <ol>
              <li>Click on <b>Add Material</b> at the top right corner of the material pane.</li>
              <li>Click <b>Quiz</b> to open the form.</li>
              <li>Fill out the form.</li>
              <li>The <b>from</b> date must be after the current date and time.</li>
              <li>Optionally, you may set a time limit for the quiz by ticking the <b>Set time limit</b> and entering the number of minutes.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Update a quiz</div>
          <Alert variant='warning'>
            Changes made will reflect back to the students' attempts.
          </Alert>
          <div>
            <ol>
              <li>Click on the pencil icon at the right side of the quiz to be updated.</li>
              <li>Fill out the form.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Delete a quiz</div>
          <Alert variant='warning'>
            Deleting a quiz will also delete the students' attempts. Furthermore, grades will also be deleted.
          </Alert>
          <div>
            <ol>
              <li>Click on the trash icon at the right side of the quiz to be deleted.</li>
              <li>Click <b>Delete</b> from the prompt to confirm deletion.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>View quiz</div>
          <div>
            To <b>view</b> a quiz, click on the name of the material.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Details</div>
          <div>
            The details area is also an editable form. Once a change was made, the reset and save buttons will show.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Preview</div>
          <div>
            The preview area is the students' interface when they open the quiz.
            You may also start answering by clicking on <b>Start an new attempt</b>. However, your answers will not be saved.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Submissions</div>
          <div className='mb-3'>
            <div>
              Students' attempts are limited to three (3) only. <br />
              The submissions area will display all the students that has an attempt.
            </div>
            <ol>
              <li><b>Student</b></li>
              <li>
                <div><b>Remarks</b></div>
                <div>
                  <b className='text-green'>On time</b> &mdash; if the submission date is before the <b>until</b> date specified<br/>
                  <b className='text-danger'>Late</b> &mdash; if the submission date is after the <b>until</b> date specified
                </div>
              </li>
              <li><b>Number of attempts</b></li>
              <li><b>Points</b> &mdash; system generated points based on the last attempt</li>
              <li><b>Grade</b> &mdash; overrideable grade</li>
            </ol>
          </div>
          <div className='font-weight-bold'>To view all the attempts of the student:</div>
          <div className='ml-3'>
            <ol>
              <li>Click on the eye icon at the right side of the submission.</li>
              <li>Select on the dropdown list the attempt to be viewed.</li>
              <li>Attempts will display the questions, answers of the student, and the points they got.</li>
              <li>Optionally, you may override the points for each question by clicking the blue pencil icon at the right side of the question. However, if no answer was provided, the points cannot be overridden.</li>
            </ol>
          </div>
          <div className='font-weight-bold'>To override the grade for the submission:</div>
          <div className='ml-3'>
            <ol>
              <li>Click on the pencil icon at the right side of the submission.</li>
              <li>Enter the grade to the grade field. Be aware of the maximum points you have set.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
      </>
    );
  }

  return props.accountType === 5 ? student() : 
          props.accountType === 2 ? faculty() : null;
}