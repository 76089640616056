import React, { Component } from 'react';
import { Alert, Button, Card, Col, Nav, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import NameLink from '../../common/NameLink/NameLink';
import axiosRequest from '../../../util/helpers/axiosRequest';
import moment from 'moment';
import Announcement from '../Announcement/Announcement';
import Badges from '../Badges/Badges';
import { connect } from 'react-redux';
import Events from '../Events/Events';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

class SchoolClasses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: [],
      showTop: false,
      isLoading: true,
      errorMessage: ''
    }
  }
  componentDidMount() {
    const { userType } = this.props.match.params;
    axiosRequest('get', `${userType}/classes`, null, ({ data: { data } }) => {
      let classes = [...data.classes];
      classes = classes.map(cl => {
        cl.schedule = cl.date_schedule.length ? cl.date_schedule[0] : (cl.weekly_schedule.length ? cl.weekly_schedule[0] : null);
        return cl;
      });
      classes.sort((a, b) => {
        if (!a.schedule && !b.schedule) {
          return 0;
        } else if (!a.schedule && b.schedule) {
          return 1;
        } else if (a.schedule && !b.schedule) {
          return -1;
        }

        if (!a.schedule.start_time && !b.schedule.start_time) {
          return 0;
        } else if (!a.schedule.start_time && b.schedule.start_time) {
          return 1;
        } else if (a.schedule.start_time && !b.schedule.start_time) {
          return -1;
        }

        let momentA = moment(a.schedule.start_time, 'HH:mm');
        let momentB = moment(b.schedule.start_time, 'HH:mm');

        if (momentA.isSame(momentB)) {
          return 0;
        } else if (momentA.isAfter(momentB)) {
          return 1;
        } else if (momentA.isBefore(momentB)) {
          return -1;
        }

        return 0;
      });
      this.setState({
        ...this.state,
        ...data,
        classes,
        isLoading: false,
        errorMessage: ''
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleClick = (e, id) => {
    e.preventDefault();

    const { history } = this.props;
    history.push(`${history.location.pathname}/class/${id}`);

    return false;
  }
  handleSelectSchoolClass = eventKey => {
    const { top } = this.state;

    if (top.badges[eventKey]) {
      this.setState({
        ...this.state,
        top: {
          ...top,
          school_class_id: eventKey
        }
      });
      return;
    }

    this.setState({
      ...this.state,
      top: {
        ...top,
        school_class_id: eventKey
      },
      isTopLoading: true,
      topError: ''
    }, () => {
      // const { userType } = this.props.match.params;
      axiosRequest('get', `faculty/badges/${eventKey}`, null, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          top: {
            ...this.state.top,
            badges: {
              ...this.state.top.badges,
              ...data.badges
            }
          },
          isTopLoading: false,
          topError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isTopLoading: false,
          topError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleMeetingLink = (event, meetingLink, classID) => {
    event.stopPropagation();
    event.preventDefault();
  
    const { userType } = this.props.match.params;
    axiosRequest('get', `${userType}/class/${classID}/meeting-link/${meetingLink.id}`, null, ({ data: { data } }) => {
      window.open(data, '_blank');
    }, error => {}, this.props.history);
  }
  handleMessage = (e, cl) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.history.push(`chat`, { newMessage: true, to: cl, type: 'classroom' });
  }
  renderTopBadge = badge => {
    if (!badge) {
      return null;
    }

    return (
      <div>
        <span>
          <FontAwesomeIcon icon='trophy' size='lg' />
        </span>
        <span className='pl-2'>
          x {badge}
        </span>
      </div>
    );
  }
  renderBadgeTable = top => {
    const { isTopLoading, topError } = this.state;

    if (isTopLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (topError) {
      return (
        <Alert variant='danger'>
          {topError}
        </Alert>
      );
    }

    if (!Array.isArray(top) || top.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <Table size='sm' responsive striped hover className='top-badge-table'>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th className='text-center'>
              <span className='fa-layers fa-fw my-1 mx-2' title='Login'>
                <FontAwesomeIcon icon='sign-in-alt' size='lg' />
                <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-7' className='text-green' />
              </span>
            </th>
            <th className='text-center'>
              <span className='fa-layers fa-fw my-1 mx-2' title='Submission'>
                <FontAwesomeIcon icon='paper-plane' size='lg' />
                <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-9' className='text-green' />
              </span>
            </th>
            <th className='text-center'>
              <span className='fa-layers fa-fw my-1 mx-2' title='Attendance'>
                <FontAwesomeIcon icon='calendar-alt' size='lg' />
                <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
              </span>
            </th>
            <th className='text-center'>
              <span className='fa-layers fa-fw my-1 mx-2' title='Participation'>
                <FontAwesomeIcon icon='comments' size='lg' />
                <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
              </span>
            </th>
            <th className='text-center'>
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {
            top.map((student, rankIndex) => (
              <tr key={student.profile.id} className={rankIndex === 0 ? 'h5' : rankIndex === 1 ? 'h6' : ''}>
                <th className='text-center align-middle'>
                  {rankIndex+1}
                </th>
                <th>
                  <NameLink {...student.profile} />
                </th>
                <td>{this.renderTopBadge(student.Login)}</td>
                <td>{this.renderTopBadge(student.Submission)}</td>
                <td>{this.renderTopBadge(student.Attendance)}</td>
                <td>{this.renderTopBadge(student.Participation)}</td>
                <td>{this.renderTopBadge(student.total)}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    );
  }
  renderBadgesRanking = () => {
    const { top } = this.state;

    if (!top) {
      return null;
    }


    return (
      <Card className='mt-3'>
        <Card.Body>
          <div className='h4'>
            Top Badge Holders
          </div>
          {
            Array.isArray(top) ? this.renderBadgeTable(top) : (
              <>
                <div className='dropdown-divider'></div>
                <Nav variant='tabs' className='m-0 border-bottom flex-column flex-md-row' onSelect={this.handleSelectSchoolClass}>
                  {
                    top.school_classes.map((schoolClass, index) => (
                      <Nav.Item key={index}>
                        <Nav.Link eventKey={schoolClass.id} className='font-weight-bold' active={schoolClass.id === +top.school_class_id}>
                          {`${schoolClass.program.code} ${schoolClass.year_level.code} - ${schoolClass.section.code}`}
                        </Nav.Link>
                      </Nav.Item>
                    ))
                  }
                </Nav>
                <div className='border-left border-right border-bottom rounded-bottom p-2'>
                  {this.renderBadgeTable(top.badges[top.school_class_id])}
                </div>
              </>
            )
          }
        </Card.Body>
      </Card>
    );
  }
  renderSchedule = (schedule) => {
    if (!schedule) {
      return null;
    }

    if (schedule.start_time && schedule.end_time) {
      let momentStart = moment(schedule.start_time, 'HH:mm');
      let momentEnd = moment(schedule.end_time, 'HH:mm');
      return (
        <div className='d-flex align-items-center'>
          {
            moment().isBetween(momentStart, momentEnd) && (
              <div className='mr-2'>
                <FontAwesomeIcon icon='dot-circle' className='text-green' size='sm' />
              </div>
            )
          }
          <div className='font-italic'>
            <div>
              {`${momentStart.format('h:mm A')} - ${momentEnd.format('h:mm A')}`}
            </div>
            {
              schedule.type && (
                <div>
                  {schedule.type}
                </div>
              )
            }
          </div>
        </div>
      );
    }

    return (
      <div className='font-italic'>No schedule for today</div>
    );
  }
  renderClasses = () => {
    const { classes } = this.state;
    const { match: { params: { userType } } } = this.props;

    if (classes.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <Row className='my-n2'>
        {
          // (userType === 'student' || userType === 'parent') ? classes.map(cl => (
          //     <Col key={cl.faculty_load.id} lg={4} className='my-2'>
          //       <a className='school-class-card-link' href={`${this.props.history.location.pathname}/class/${cl.faculty_load_id}`} onClick={(e) => this.handleClick(e, cl.faculty_load.id)}>
          //         <Card className='shadow-sm school-class-card'>
          //           <Card.Body>
          //             {
          //               cl.course ? (
          //                 <>
          //                   <b>{cl.course.code}</b>
          //                   <div>{cl.course.description}</div>
          //                   <b>{`${cl.school_class.program.code} ${cl.school_class.year_level.code} - ${cl.school_class.section.code}`}</b>
          //                   <div>{cl.school_class.program.description}</div>
          //                   <div>{cl.school_class.year_level.description}</div>
          //                   {
          //                     cl.faculty_load.class_faculty_load.map(clf => (
          //                       <div key={clf.id}>
          //                         <b>{`${clf.school_class.program.code} ${clf.school_class.year_level.code} - ${clf.school_class.section.code}`}</b>
          //                         <div>{clf.school_class.program.description}</div>
          //                         <div>{clf.school_class.year_level.description}</div>
          //                       </div>
          //                     ))
          //                   }
          //                   <b>{cl.faculty_load.account.profile.name}</b>
          //                   {
          //                     (userType !== 'parent' && cl.faculty_load.meeting_link) && (
          //                       <div>
          //                         <span className='btn btn-link pl-0' onClick={e => this.handleMeetingLink(e, cl.faculty_load.meeting_link, cl.faculty_load.id)}>
          //                           <FontAwesomeIcon icon='external-link-alt' /> Meeting Link
          //                         </span>
          //                       </div>
          //                     )
          //                   }
          //                   {this.renderSchedule(cl.faculty_load.schedule)}
          //                 </>
          //               ) : (
          //                 <>
          //                   <b>{cl.faculty_load.class_course.course.code}</b>
          //                   <div>{cl.faculty_load.class_course.course.description}</div>
          //                   <b>{`${cl.faculty_load.class_course.school_class.program.code} ${cl.faculty_load.class_course.school_class.year_level.code} - ${cl.faculty_load.class_course.school_class.section.code}`}</b>
          //                   <div>{cl.faculty_load.class_course.school_class.program.description}</div>
          //                   <div>{cl.faculty_load.class_course.school_class.year_level.description}</div>
          //                   {
          //                     cl.faculty_load.class_faculty_load.map(clf => (
          //                       <div key={clf.id}>
          //                         <b>{`${clf.school_class.program.code} ${clf.school_class.year_level.code} - ${clf.school_class.section.code}`}</b>
          //                         <div>{clf.school_class.program.description}</div>
          //                         <div>{clf.school_class.year_level.description}</div>
          //                       </div>
          //                     ))
          //                   }
          //                   <b>{cl.faculty_load.account.profile.name}</b>
          //                   {
          //                     cl.faculty_load.meeting_link && (
          //                       <div>
          //                         <span className='btn btn-link pl-0' onClick={e => this.handleMeetingLink(e, cl.faculty_load.meeting_link, cl.faculty_load.id)}>
          //                           <FontAwesomeIcon icon='external-link-alt' /> Meeting Link
          //                         </span>
          //                       </div>
          //                     )
          //                   }
          //                   {this.renderSchedule(cl.faculty_load.schedule)}
          //                 </>
          //               )
          //             }
          //           </Card.Body>
          //         </Card>
          //       </a>
          //     </Col>
          // )) : 
          classes.map(cl => (
            <Col key={cl.id} lg={4} className='my-2'>
              <a className='school-class-card-link' href={`${this.props.history.location.pathname}/class/${cl.id}`} onClick={(e) => this.handleClick(e, cl.id)}>
                <Card className='shadow-sm school-class-card text-primary'>
                  <Card.Body style={{ backgroundImage: cl.class_course.school_class.year_level.school_level ? `linear-gradient(135deg, #fff 25%, ${cl.class_course.school_class.year_level.school_level.theme})` : 'linear-gradient(135deg, #fff 25%, rgba(1,169,9,.35))' }}>
                    <div className='d-flex'>
                      <div className='flex-fill'>
                        <b>{cl.class_course.course.code}</b>
                        <div>{cl.class_course.course.description}</div>
                        <b>{`${cl.class_course.school_class.program.code} ${cl.class_course.school_class.year_level.code} - ${cl.class_course.school_class.section.code}`}</b>
                        <div>{cl.class_course.school_class.program.description}</div>
                        <div>{cl.class_course.school_class.year_level.description}</div>
                        {
                          cl.class_faculty_load.map(clf => (
                            <div key={clf.id}>
                              <b>{`${clf.school_class.program.code} ${clf.school_class.year_level.code} - ${clf.school_class.section.code}`}</b>
                              <div>{clf.school_class.program.description}</div>
                              <div>{clf.school_class.year_level.description}</div>
                            </div>
                          ))
                        }
                      </div>
                      {
                        userType === 'faculty' && (
                          <div>
                            <Button variant='primary' size='sm' onClick={e => this.handleMessage(e, cl)} title='Message'>
                              <FontAwesomeIcon icon='envelope' />
                            </Button>
                          </div>
                        )
                      }
                    </div>
                    <div className='d-flex'>
                      <div className='flex-fill d-flex flex-column'>
                        <div className='flex-fill'>
                          {
                            (userType !== 'parent' && cl.meeting_link) && (
                              <div>
                                <span className='btn btn-link pl-0' onClick={e => this.handleMeetingLink(e, cl.meeting_link, cl.id)}>
                                  <FontAwesomeIcon icon='external-link-alt' /> Meeting Link
                                </span>
                              </div>
                            )
                          }
                        </div>
                        {this.renderSchedule(cl.schedule)}
                      </div>
                      <div className='d-flex justify-content-end text-right align-items-end'>
                        {
                          userType !== 'faculty' && (
                            <NameLink
                              {...cl.profile}
                              imageAlign='right'
                              nameStyle={{ alignSelf: 'end' }}
                              imageStyle={{ height: '5rem', width: '5rem' }}
                              hideName
                              disableLink
                            />
                          )
                        }
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          ))
        }
      </Row>
    );
  }
  render() {
    const { isLoading, errorMessage, showTop } = this.state;
    
    if (isLoading) {
      return (
        <LoadingIcon className='mt-3' lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger' className='mt-3'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <Row>
          <Col lg={8}>
            <Announcement {...this.props} />
          </Col>
          <Col lg={4}>
            <Events {...this.props} />
          </Col>
        </Row>
        <Badges {...this.props} show={showTop} />
        <div className='my-3'>
          { this.renderClasses() }
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(SchoolClasses);