import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../util/helpers/axiosRequest';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

export default class ClassMerge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      classID: '',
      classes: [],
      facultyLoads: [],
      isFacultyLoadLoading: false,
      classFacultyLoad: [],
      loadingFacultyLoads: [],
      facultyLoadError: '',
      formError: '',
      searchQuery: ''
    }
  }
  componentDidMount() {
    axiosRequest('get', 'admin/merge-class/options', null, ({ data: { data }}) => {
      data.classes.sort((a, b) => {
        if (a.program.code < b.program.code) {
          return -1;
        } else if (a.program.code > b.program.code) {
          return 1;
        }

        if (a.year_level.code < b.year_level.code) {
          return -1;
        } else if (a.year_level.code > b.year_level.code) {
          return 1;
        }

        if (a.section.code < b.section.code) {
          return -1;
        } else if (a.section.code > b.section.code) {
          return 1;
        }

        return 0;
      });
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  removeFromLoading = id => {
    const loadingFacultyLoads = [...this.state.loadingFacultyLoads].filter(loadingFacultyLoad => {
      return loadingFacultyLoad !== id;
    });
    this.setState({
      ...this.state,
      loadingFacultyLoads
    });
  }
  handleMerge = id => {
    const { classID } = this.state;
    this.setState({
      ...this.state,
      loadingFacultyLoads: [
        ...this.state.loadingFacultyLoads,
        id
      ],
      formError: ''
    }, () => {
      axiosRequest('post', 'admin/merge-class', {
        classID,
        facultyLoadID: id
      }, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          classFacultyLoad: [
            ...this.state.classFacultyLoad,
            data
          ]
        }, () => {
          this.removeFromLoading(id);
        });
      }, (error) => {
        this.setState({
          ...this.state,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }, () => {
          this.removeFromLoading(id);
        });
      }, this.props.history);
    });
  }
  handleUnmerge = (id, facultyLoadID) => {
    this.setState({
      ...this.state,
      loadingFacultyLoads: [
        ...this.state.loadingFacultyLoads,
        facultyLoadID
      ],
      formError: ''
    }, () => {
      axiosRequest('delete', `admin/merge-class/${id}`, null, ({ data: { data } }) => {
        const class_faculty_load = [...this.state.classFacultyLoad].filter(item => {
          return item.id !== id;
        });
        this.setState({
          ...this.state,
          classFacultyLoad: [
            ...class_faculty_load
          ]
        }, () => {
          this.removeFromLoading(facultyLoadID);
        });
      }, (error) => {
        this.setState({
          ...this.state,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }, () => {
          this.removeFromLoading(id);
        });
      }, this.props.history);
    });
  }
  handleClassChange = event => {
    const classID = event.target.value;
    this.setState({
      isFacultyLoadLoading: true
    }, () => {
      axiosRequest('get', `admin/merge-class/${classID}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          classID,
          classFacultyLoad: data,
          isFacultyLoadLoading: false
        });
      }, (error) => {
        this.setState({
          ...this.state,
          classFacultyLoad: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleSearchInputChange = event => {
    this.setState({
      ...this.state,
      searchQuery: event.target.value
    });
  }
  renderFacultyLoads = () => {
    const { facultyLoads, classID, isFacultyLoadLoading, classFacultyLoad, loadingFacultyLoads, facultyLoadError, formError, searchQuery, classes } = this.state;

    if (isFacultyLoadLoading) {
      return (
        <LoadingIcon />
      );
    }
    
    if (facultyLoadError) {
      return (
        <Alert variant='danger'>
          {facultyLoadError}
        </Alert>
      );
    }

    if (!classID) {
      return;
    }

    let searchFilteredFacultyLoads = [...facultyLoads];
    let searchFilteredClassFacultyLoads = [...classFacultyLoad];

    if (searchQuery.toString()) {
      searchFilteredFacultyLoads = [...facultyLoads].filter(f => (
        f.class_course.course.code.indexOf(searchQuery) > -1 ||
        f.class_course.course.description.indexOf(searchQuery) > -1 ||
        f.class_course.school_class.program.code.indexOf(searchQuery) > -1 ||
        f.class_course.school_class.program.description.indexOf(searchQuery) > -1 ||
        f.class_course.school_class.year_level.code.indexOf(searchQuery) > -1 ||
        f.class_course.school_class.year_level.description.indexOf(searchQuery) > -1 ||
        f.class_course.school_class.section.code.indexOf(searchQuery) > -1 ||
        f.profile.name.indexOf(searchQuery) > -1
      ));
      searchFilteredClassFacultyLoads = [...classFacultyLoad].filter(f => (
        f.faculty_load.class_course.course.code.indexOf(searchQuery) > -1 ||
        f.faculty_load.class_course.course.description.indexOf(searchQuery) > -1 ||
        f.faculty_load.class_course.school_class.program.code.indexOf(searchQuery) > -1 ||
        f.faculty_load.class_course.school_class.program.description.indexOf(searchQuery) > -1 ||
        f.faculty_load.class_course.school_class.year_level.code.indexOf(searchQuery) > -1 ||
        f.faculty_load.class_course.school_class.year_level.description.indexOf(searchQuery) > -1 ||
        f.faculty_load.class_course.school_class.section.code.indexOf(searchQuery) > -1 ||
        f.faculty_load.profile.name.indexOf(searchQuery) > -1
      ));
    }
    
    let enrolledFacultyLoads = [];
    for (let i = 0; i < classFacultyLoad.length; i++) {
      enrolledFacultyLoads.push(classFacultyLoad[i].faculty_load.id);
    }

    let filteredFacultyLoads = [...searchFilteredFacultyLoads].filter((facultyLoad) => {
      return enrolledFacultyLoads.indexOf(facultyLoad.id) === -1 && facultyLoad.class_course.school_class_id !== +classID;
    });

    let filteredEnrolledFacultyLoads = [...searchFilteredFacultyLoads].filter(facultyLoad => {
      return facultyLoad.class_course.school_class_id === +classID;
    });

    let selectedClass = classes.find(c => c.id === +classID);

    return (
      <>
        {
          selectedClass && (
            <Card.Title>
              <div>{`${selectedClass.program.description} (${selectedClass.program.code})`}</div>
              <div>{`${selectedClass.year_level.description} (${selectedClass.year_level.code})`}</div>
              <div>{`Section ${selectedClass.section.code}`}</div>
            </Card.Title>
          )
        }
        {
          formError && (
            <Alert variant='danger'>
              {formError}
            </Alert>
          )
        }
        <Row>
          <Col lg={6} className='ml-auto'>
            <div className='mb-2 mt-1'>
              <Form.Control type='search' placeholder='Search classrooms...' value={searchQuery} onChange={this.handleSearchInputChange} />
            </div>
          </Col>
        </Row>
        {
          (searchFilteredFacultyLoads.length > 0 || searchFilteredClassFacultyLoads.length > 0) ? (
            <ListGroup>
              {
                searchFilteredClassFacultyLoads.map((studenFacultyLoad) => (
                  <ListGroup.Item key={studenFacultyLoad.faculty_load.id} variant='green'>
                    <div className='d-flex'>
                      <div className='pr-2'>
                        {
                          loadingFacultyLoads.indexOf(studenFacultyLoad.faculty_load.id) !== -1 ? (
                            <LoadingIcon />
                          ) : (
                            <FontAwesomeIcon icon='check-circle' />
                          )
                        }
                      </div>
                      <div className='flex-fill'>
                        <b>{studenFacultyLoad.faculty_load.class_course.course.code}</b>
                        <div>{studenFacultyLoad.faculty_load.class_course.course.description}</div>
                        <b>{`${studenFacultyLoad.faculty_load.class_course.school_class.program.description} (${studenFacultyLoad.faculty_load.class_course.school_class.program.code})`}</b>
                        <div>
                          {`${studenFacultyLoad.faculty_load.class_course.school_class.year_level.description}
                          (${studenFacultyLoad.faculty_load.class_course.school_class.year_level.code}) - ${studenFacultyLoad.faculty_load.class_course.school_class.section.code}`}
                        </div>
                        <b>{studenFacultyLoad.faculty_load.profile.name}</b>
                        <div>{studenFacultyLoad.faculty_load.profile.position}</div>
                      </div>
                      <div className='d-flex align-items-center'>
                        <Button
                          size='sm'
                          variant='danger'
                          onClick={() => this.handleUnmerge(studenFacultyLoad.id, studenFacultyLoad.faculty_load.id)}
                          disabled={loadingFacultyLoads.indexOf(studenFacultyLoad.faculty_load.id) !== -1}>
                            Unmerge <FontAwesomeIcon icon='angle-double-right' />
                        </Button>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))
              }
              {
                filteredFacultyLoads.map((facultyLoad) => {
                  return (
                    <ListGroup.Item key={facultyLoad.id}>
                      <div className='d-flex'>
                        {
                          loadingFacultyLoads.indexOf(facultyLoad.id) !== -1 ? (
                            <div className='pr-2'>
                              <LoadingIcon />
                            </div>
                          ) : (
                            <div className='pr-4'></div>
                          )
                        }
                        <div className='flex-fill'>
                          <b>{facultyLoad.class_course.course.code}</b>
                          <div>{facultyLoad.class_course.course.description}</div>
                          <b>{`${facultyLoad.class_course.school_class.program.description} (${facultyLoad.class_course.school_class.program.code})`}</b>
                          <div>
                            {`${facultyLoad.class_course.school_class.year_level.description}
                            (${facultyLoad.class_course.school_class.year_level.code}) - ${facultyLoad.class_course.school_class.section.code}`}
                          </div>
                          <b>{facultyLoad.profile.name}</b>
                          <div>{facultyLoad.profile.position}</div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <Button
                            size='sm'
                            variant='green'
                            onClick={() => this.handleMerge(facultyLoad.id)}
                            disabled={loadingFacultyLoads.indexOf(facultyLoad.id) !== -1}>
                            Merge <FontAwesomeIcon icon='angle-double-right' /></Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  );
                })
              }
              {
                filteredEnrolledFacultyLoads.map(facultyLoad => (
                  <ListGroup.Item key={facultyLoad.id} className='bg-light' style={{ opacity: '.9' }}>
                    <div className='d-flex'>
                      {
                        loadingFacultyLoads.indexOf(facultyLoad.id) !== -1 ? (
                          <div className='pr-2'>
                            <LoadingIcon />
                          </div>
                        ) : (
                          <div className='pr-4'></div>
                        )
                      }
                      <div className='flex-fill'>
                        <b>{facultyLoad.class_course.course.code}</b>
                        <div>{facultyLoad.class_course.course.description}</div>
                        <b>{`${facultyLoad.class_course.school_class.program.description} (${facultyLoad.class_course.school_class.program.code})`}</b>
                        <div>
                          {`${facultyLoad.class_course.school_class.year_level.description}
                          (${facultyLoad.class_course.school_class.year_level.code}) - ${facultyLoad.class_course.school_class.section.code}`}
                        </div>
                        <b>{facultyLoad.profile.name}</b>
                        <div>{facultyLoad.profile.position}</div>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))
              }
            </ListGroup>
          ) : searchQuery ? (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          ) : (
            <Alert variant='warning'>
              Nothing to show. Try assigning a <b>faculty load</b> first.
            </Alert>
          )
        }
        
      </>
    );
  }
  renderContent = () => {
    const { isLoading, errorMessage, classes } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }
    
    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (classes.length === 0) {
      return (
        <Alert variant='warning'>
          Nothing to show. Try adding a <b>class</b> first.
        </Alert>
      );
    }

    return (
      <>
        <Form.Group>
          <Form.Label>Class</Form.Label>
          <Form.Control as='select' onChange={this.handleClassChange}>
            <option disabled hidden selected>Select a class to merge...</option>
            {
              classes.map((cl, index) => (
                <option key={index} value={cl.id}>{`${cl.program.code} ${cl.year_level.code} - ${cl.section.code}`}</option>
              ))
            }
          </Form.Control>
        </Form.Group>
        <div className='dropdown-divider'></div>
        { this.renderFacultyLoads() }
      </>
    );
  }
  render() {
    return (
      <Card>
        <Card.Body>
          { this.renderContent() }
        </Card.Body>
      </Card>
    );
  }
}