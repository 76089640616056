import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../../common/Header/Header';
import SchoolClasses from '../../units/SchoolClasses/SchoolClasses';
import StudentClassroom from '../../units/StudentClassroom/StudentClassroom';
import Material from './Material/Material';
import Questions from '../../common/Questions/View/Questions';
import { Redirect, Route, Switch } from 'react-router-dom';

export default class Student extends Component {
  render() {
    return (
      <>
        <Header />
        <Container>
          <Switch>
            <Route exact path='/:userType' component={SchoolClasses} />
            <Route path='/student/class/:classID' render={props => (
              <Switch>
                <Route exact path='/student/class/:classID/:materialType/:materialID' component={Material} />
                <Route exact path='/student/class/:classID/:materialType(quiz|exam)/:materialID/answer/:attempt(start|resume)' component={Questions} />
                <Route path={`/student/class/:classID/:menu`} component={StudentClassroom} />
                <Redirect to={{ pathname: '/student/class/:classID/discussions' }} />
              </Switch>
            )} />
            <Redirect to={{ pathname: '/student' }} />
          </Switch>
        </Container>
      </>
    );
  }
}