import React from 'react';

export default function Link(props) {
  function student() {
    return (
      <>
        <div className='mb-3'>
          <div className='h4 text-primary'>Opening a link</div>
          <div>
            Clicking on the name of the material will automatically redirect you to the link uploaded by the faculty.
          </div>
        </div>
      </>
    );
  }

  function faculty() {
    return (
      <>
        <div className='mb-3'>
          <div className='h4 text-primary'>Creating a link</div>
          <div>
            <ol>
              <li>Click on <b>Add Material</b> at the top right corner of the material pane.</li>
              <li>Click <b>Link</b> to open the form.</li>
              <li>Fill out the form.</li>
              <li>The <b>url</b> field must be a valid url with http protocol &mdash; http or https.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Updating a link</div>
          <div>
            <ol>
              <li>Click on the pencil icon at the right side of the link to be updated.</li>
              <li>Fill out the form.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Deleting a link</div>
          <div>
            <ol>
              <li>Click on the trash icon at the right side of the link to be deleted.</li>
              <li>Click <b>Delete</b> from the prompt to confirm deletion.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Opening a link</div>
          <div>
            Clicking on the name of the material will automatically redirect you to the link.
          </div>
        </div>
      </>
    );
  }

  return props.accountType === 2 ? faculty() :
          props.accountType === 5 ? student() : null;
}