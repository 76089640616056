import Validator from 'validatorjs';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import moment from 'moment';

function leapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

function isValidDate(inDate) {
  if (inDate instanceof Date) {
    return !isNaN(inDate);
  }

  // reformat if supplied as mm.dd.yyyy (period delimiter)
  if (typeof inDate === 'string') {
    var pos = inDate.indexOf('.');
    if (pos > 0 && pos <= 6) {
      inDate = inDate.replace(/\./g, '-');
    }

    // if date is mm-dd-yyyy or yyyy-mm-dd
    if (inDate.length === 10) {
      return isValid(parseISO(inDate));
    }
  }

  var testDate = new Date(inDate);
  var yr = testDate.getFullYear();
  var mo = testDate.getMonth();
  var day = testDate.getDate();

  var daysInMonth = [31, leapYear(yr) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (yr < 1000) {
    return false;
  }
  if (isNaN(mo)) {
    return false;
  }
  if (mo + 1 > 12) {
    return false;
  }
  if (isNaN(day)) {
    return false;
  }
  if (day > daysInMonth[mo]) {
    return false;
  }

  return true;
}

const rules = {
  date: (msg) => {
    Validator.register('date', function (val, format) {
      return isValidDate(val);
    }, msg ? msg : 'The :attribute must be a valid date.');
  },
  after_or_equal: (msg) => {
    Validator.register('after_or_equal', function(val, req) {
      var val1 = this.validator.input[req];
      var val2 = val;

      if (val1) {
        if (!isValidDate(val1)) {
          return false;
        }
        if (!isValidDate(val2)) {
          return false;
        }
      } else {
        if (!isValidDate(req)) {
          return false;
        }
        val1 = req;
      }
      
      if (new Date(val1).getTime() <= new Date(val2).getTime()) {
        return true;
      }
  
      return false;
    }, msg ? msg : 'The :attribute must be equal or after :after_or_equal.');
  },
  before_or_equal: (msg) => {
    Validator.register('before_or_equal', function(val, req) {
      var val1 = this.validator.input[req];
      var val2 = val;
  
      if (!isValidDate(val1)) {
        return false;
      }
      if (!isValidDate(val2)) {
        return false;
      }
  
      if (new Date(val1).getTime() >= new Date(val2).getTime()) {
        return true;
      }
  
      return false;
    }, msg ? msg : 'The :attribute must be equal or before :before_or_equal.');
  },
  time: (msg) => {
    Validator.register('time', function(val, req) {
      return moment(val, 'HH:mm', true).isValid();
    }, msg ? msg : 'The :attribute must be a valid time.');
  }
}

const loadRule = (rule, message = {}) => {
  if (Array.isArray(rule)) {
    rule.forEach(r => {
      rules[r](message[r] ?? null);
    });
  } else {
    rules[rule](message[rule] ?? null);
  }
};

export default loadRule;