import React, { Component } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import Validator from 'validatorjs';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../common/RichTextEditor/RichTextEditor';

export default class Link extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      formInputs: {
        title: '',
        description: '',
        url: ''
      },
      isFormLoading: false,
      formError: '',
      formSuccess: ''
    };
  }
  componentDidMount() {
    const { materialData } = this.props;

    if (materialData) {
      this.setState({
        ...this.state,
        formInputs: {
          title: materialData.title,
          description: materialData.description,
          url: materialData.url
        },
        isLoading: false
      });
    } else {
      this.setState({
        ...this.state,
        isLoading: false
      });
    }
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [event.target.name]: event.target.value
      }
    });
  }
  handleDescriptionInputChange = description => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        description
      }
    });
  }
  handleCreate = () => {
    const { formInputs } = this.state;

    const { classID } = this.props.match.params;
    axiosRequest('post', `faculty/class/${classID}/material/link`, formInputs, ({ data: { data, message }}) => {
      this.setState({
        ...this.state,
        formSuccess: message
      }, () => {
        this.props.onSave(data);
      });
    }, (error) => {
      this.setState({
        ...this.state,
        formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        isFormLoading: false
      });
    }, this.props.history);
  }
  handleUpdate = () => {
    const { formInputs } = this.state;

    const { classID } = this.props.match.params;
    axiosRequest('patch', `faculty/class/${classID}/material/link/${this.props.materialData.id}`, formInputs, ({ data: { data, message }}) => {
      this.setState({
        ...this.state,
        formSuccess: message
      }, () => {
        this.props.onUpdate(data);
      });
    }, (error) => {
      this.setState({
        ...this.state,
        formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        isFormLoading: false
      });
    }, this.props.history);
  }
  handleSave = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: ''
    }, () => {
      const { formInputs } = this.state;

      let validator = new Validator(formInputs, {
        title: 'required|min:3',
        description: 'required',
        url: 'required|url'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          formError: validator.errors.errors[firstKey][0],
          isFormLoading: false
        });
        return;
      }
      
      if (this.props.materialData) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    });
  }
  render() {
    const { isLoading, errorMessage, isFormLoading, formError, formSuccess, formInputs } = this.state;
    const { materialData } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>
            {
              materialData ? 'Update link' : 'Upload link'
            }
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleSave}>
          <Modal.Body>
            {
              formError ? (
                <Alert variant='danger'>
                  {formError}
                </Alert>
              ) : formSuccess ? (
                <Alert variant='success'>
                  {formSuccess}
                </Alert>
              ) : null
            }
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type='text' name='title' value={formInputs.title} onChange={this.handleInputChange} disabled={isFormLoading} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <RichTextEditor.Editor
                value={formInputs.description}
                onChange={this.handleDescriptionInputChange}
                readOnly={isFormLoading} />
              {/* <Form.Control as='textarea' rows={4} style={{ resize: 'none' }} name='description' value={formInputs.description} onChange={this.handleInputChange} disabled={isFormLoading} /> */}
            </Form.Group>
            <Form.Group>
              <Form.Label>URL</Form.Label>
              <Form.Control type='url' name='url' value={formInputs.url} onChange={this.handleInputChange} disabled={isFormLoading} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {
              formSuccess ? (
                <Button variant='light' onClick={this.props.onCancel}>
                  Close
                </Button>
              ) : (
                <>
                  <Button variant='danger' onClick={this.props.onCancel} disabled={isFormLoading}>
                    Cancel
                  </Button>
                  <Button variant='green' type='submit' disabled={isFormLoading}>
                    Save
                  </Button>
                </>
              )
            }
          </Modal.Footer>
        </Form>
      </>
    );
  }
}