import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../util/helpers/axiosRequest';
import Header from '../../common/Header/Header';
import NameLink from '../../common/NameLink/NameLink';
import moment from 'moment';
import Validator from 'validatorjs';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      conversation: null,
      messages: {
        data: []
      },
      isNextPageLoading: false,
      nextPageError: '',
      message: '',
      isFormLoading: false,
      formError: ''
    };
  }
  componentDidMount() {
    const { conversationID } = this.props.match.params;

    axiosRequest('get', `chat/messages/${conversationID}`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        isLoading: false
      });
    }, this.props.history);
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      message: event.target.value
    });
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { messages } = this.state;

      const path = messages.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        let messagesData = [...this.state.messages.data, ...data.messages.data].filter((v, i, t) => {
          return t.findIndex(rv => rv.id === v.id) === i;
        });
        this.setState({
          ...this.state,
          messages: {
            ...data.messages,
            data: messagesData
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleSend = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: ''
    }, () => {
      const { message } = this.state;

      let validator = new Validator({
        message
      }, {
        message: 'required'
      });

      if (validator.fails()) {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: 'The message field is required.'
        });
        return;
      }

      axiosRequest('post', `chat/send/${this.state.conversation.id}`, {
        message
      }, ({ data: { data }}) => {
        let lastMessage = [...this.state.messages.data].find(md => md.chat_notifications.length > 0);
        if (lastMessage) {
          data.chat_notifications = lastMessage.chat_notifications.map(cn => ({
            chat_conversation_id: cn.chat_conversation_id,
            chat_message_id: data.id,
            chat_participant_id: cn.chat_participant_id,
            seen_at: null
          }));
        }
        this.setState({
          ...this.state,
          messages: {
            ...this.state.messages,
            data: [data, ...this.state.messages.data]
          },
          message: '',
          isFormLoading: false
        });
      }, error => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        });
      }, this.props.history);
    });
  }
  renderSeen = message => {
    const { conversation } = this.state;

    if (message.chat_notifications.length > 0) {
      let names = [];
      let isEveryone = true;

      for (let i = 0; i < message.chat_notifications.length; i++) {
        let participant = conversation.chat_participants.find(cp => cp.id === message.chat_notifications[i].chat_participant_id);
        
        if (participant && participant.profile_id !== this.props.currentUser.id && message.chat_notifications[i].seen_at === null) {
          isEveryone = false;
        } else {
          if (participant && participant.profile_id !== this.props.currentUser.id && message.chat_participant_id !== participant.id) {
            if (participant.profile.title) {
              names.push(`${participant.profile.title} ${participant.profile.last_name}`);
            } else {
              names.push(participant.profile.first_name);
            }
          }
        }
      }

      if (conversation.chat_participants.length !== 2) {
        if (isEveryone) {
          return (
            <small className='text-muted font-italic d-block'>
              <FontAwesomeIcon icon='check' /> Seen by everyone
            </small>
          );
        }
  
        if (names.length > 0) {
          return (
            <small className='text-muted font-italic d-block'>
              <FontAwesomeIcon icon='check' /> {names.join(', ')}
            </small>
          );
        }
      } else {
        if (names.length > 0) {
          return (
            <small className='text-muted font-italic d-block'>
              <FontAwesomeIcon icon='check' /> Seen
            </small>
          );
        }
      }
    }

    return null;
  }
  renderMessage = message => {
    const { conversation } = this.state;

    let participant = conversation.chat_participants.find(participant => participant.id === message.chat_participant_id);

    if (participant.profile_id === this.props.currentUser.id) {
      return (
        <div key={message.id} className='message message-right'>
          <div className='message-box-container'>
            <div className='d-flex message-right'>
              <div className='p-2 message-box bg-green text-white'>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {message.message}
                </div>
                <small className='d-block font-italic'>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {moment(message.created_at).format('MMM D, YYYY hh:mm:ss A')}
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <FontAwesomeIcon icon='clock' style={{ cursor: 'pointer' }} />
                  </OverlayTrigger>
                  <span className='ml-1'>{moment(message.created_at).fromNow()}</span>
                </small>
              </div>
            </div>
            {this.renderSeen(message)}
          </div>
        </div>
      );
    }

    return (
      <div key={message.id} className='message message-left'>
        <div className='d-flex'>
          <div className='mr-2'>
            <NameLink
              id={participant.profile.id}
              name={participant.profile.name}
              image={participant.profile.image}
              rank={participant.profile.rank}
              disableLink
              hideName />
          </div>
          <div className='message-box-container'>
            <div className='d-flex message-left'>
              <div className='p-2 border message-box'>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {message.message}
                </div>
                <small className='d-block font-italic text-muted'>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {moment(message.created_at).format('MMM D, YYYY hh:mm:ss A')}
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <FontAwesomeIcon icon='clock' style={{ cursor: 'pointer' }} />
                  </OverlayTrigger>
                  <span className='ml-1'>{moment(message.created_at).fromNow()}</span>
                </small>
              </div>
            </div>
            {this.renderSeen(message)}
          </div>
        </div>
      </div>
    );
  }
  renderConversation = () => {
    const { isLoading, errorMessage, messages, conversation, isNextPageLoading, nextPageError, message, isFormLoading, formError } = this.state;
    const { currentUser } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    let senderInfo = conversation.chat_participants.find(participant => participant.profile_id !== currentUser.id);

    return (
      <div>
        <div className='h4'>
          {
            (conversation.title && conversation.faculty_load_id) ? (
              <div className='d-flex text-primary align-items-center'>
                <div className='mr-2 text-center bg-green' style={{ width: '2.5rem', height: '2.5rem', borderRadius: '2.5rem', lineHeight: '2.5rem' }}>
                  <FontAwesomeIcon icon='users' className='text-white' />
                </div>
                <div>
                  {
                    currentUser.account_type === 2 ? (
                      <a title='View students' href={`/faculty/class/${conversation.faculty_load_id}/students`}>
                        {conversation.title}<FontAwesomeIcon icon='external-link-alt' className='ml-2' />
                      </a>
                    ) : currentUser.account_type === 5 ? (
                      <a title='View students' href={`/student/class/${conversation.faculty_load_id}/students`}>
                        {conversation.title}<FontAwesomeIcon icon='external-link-alt' className='ml-2' />
                      </a>
                    ) : conversation.title
                  }
                </div>
              </div>
            ) : senderInfo ? (
              <NameLink {...senderInfo.profile} />
            ) : 'Messages'
          }
        </div>
        <div className='dropdown-divider'></div>
        {
          messages.data.length === 0 ? (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          ) : (
            <>
              <div className='rounded-top p-3 messages-list'>
                {
                  messages.data.map(this.renderMessage)
                }
                <div className='p-3'>
                {
                  messages.next_page_url ? (
                    <div className='text-center'>
                      {
                        isNextPageLoading && (
                          <LoadingIcon className='mr-2' sm />
                        )
                      }
                      <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                        View more messages...
                        <FontAwesomeIcon icon='chevron-up' size='sm' className='ml-2' />
                      </span>
                      {
                        nextPageError && (
                          <div className='alert-danger rounded'>
                            {nextPageError}
                          </div>
                        )
                      }
                    </div>
                  ) : (
                    <div className='text-center text-muted'>
                      No more messages
                    </div>
                  )
                }
                </div>
              </div>
            </>
          )
        }
        <div>
          {
            formError && (
              <Alert variant='danger'>
                {formError}
              </Alert>
            )
          }
          <Form onSubmit={this.handleSend}>
            <Form.Group>
              <Form.Control as='textarea' rows={3} style={{ resize: 'none', borderTopRightRadius: 0, borderTopLeftRadius: 0 }} value={message} onChange={this.handleInputChange} disabled={isFormLoading} />
            </Form.Group>
            <div className='text-right'>
              <Button variant='green' type='submit' disabled={isFormLoading}>
                Send
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
  render() {
    const { state } = this.props.location;
    const { isLoading, errorMessage } = this.state;

    return (
      <>
        <Header active='Chat' />
        <Container>
          <Row>
            <Col className='mx-auto' md={10}>
              <div className='border rounded p-4 my-3'>
                <div className='btn-link mb-3' onClick={() => this.props.history.push('/chat')}>
                  <FontAwesomeIcon icon='chevron-left' /> Back to messages
                </div>
                {
                  isLoading && !errorMessage && state && state.name && (
                    <div>
                      <div className='h4'>{state.name}</div>
                      <div className='dropdown-divider'></div>
                    </div>
                  )
                }
                {this.renderConversation()}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(Conversation);