import React, { Component } from 'react';
import katex from 'katex';
import 'katex/dist/katex.css';
import 'react-quill/dist/quill.snow.css';

window.katex = katex;

class Viewer extends Component {
  render() {
    const { body, className, ...restProps } = this.props;
    return (
      <div className='ql-snow'>
        <div {...restProps} className={`ql-editor rich-text-editor-viewer ${className ? className : ''}`} dangerouslySetInnerHTML={{ __html: body }}>
        </div>
      </div>
    );
  }
}

Viewer.defaultProps = {
  body: ''
};

export default Viewer;