import React from 'react';

export default function SiteNavigation(props) {
  return (
    <>
      <div className='mb-3'>
        Click <b>ACTS-VLE</b> in the top left corner to return to the homepage at any time.
      </div>
      <div className='mb-3'>
        <div className='h4 text-primary'>Home</div>
        <div>
          Click <b>Home</b> to view the card-based menu your courses:
        </div>
      </div>
      <div className='mb-3'>
        <div className='h4 text-primary'>Profile</div>
        <div>
          Click <b>Profile</b> to view your own profile.
        </div>
      </div>
      <div className='mb-3'>
        <div className='h4 text-primary'>Right side of the Header</div>
        <div>
          <ol>
            <li>
              <b>Search</b> &mdash; Search users of ACTS-VLE
            </li>
            <li>
              <b>Time Table</b> &mdash; View your schedule.
            </li>
            <li>
              <b>Messages</b> &mdash; Send a message, and view your sent and received messages with other ACTS-VLE users.
            </li>
            <li>
              <b>Notifications</b> &mdash; View your most recent notifications.
            </li>
          </ol>
        </div>
      </div>
      <div className='mb-3'>
        <div className='h4 text-primary'>Account drop-down menu</div>
        <div>
          <ol>
            <li>
              View your own profile.
            </li>
            <li>
              Change your account password.
            </li>
            <li>
              View the help menu.
            </li>
            <li>
              Logout of ACTS-VLE.
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}