import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { hideImagePreview, showImagePreview } from '../../../actions';
import './style.scss';

class ImagePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndexState: props.imagePreview.activeIndex
    };

    this.closeButton = null;
    this.activeImage = [];
  }
  componentDidMount() {    
    if(this.props.imagePreview.images.length > 0){
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }
  componentDidUpdate(prevProps) {    
    if(this.props.imagePreview.images.length > 0){
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    if (prevProps.imagePreview.activeIndex !== this.props.imagePreview.activeIndex) {
      this.setState({
        ...this.state,
        activeIndexState: this.props.imagePreview.activeIndex
      });
    }
  }
  componentWillUnmount() {
      document.body.style.overflow = 'unset';
  }
  handleNext = () => {
    const { activeIndexState } = this.state;
    const { imagePreview: { images } } = this.props;
    if (activeIndexState+1 >= images.length) {
      return false;
    }
    this.activeImage[activeIndexState+1].scrollIntoView();

    this.setState({
      ...this.state,
      activeIndexState: activeIndexState+1
    });
  }
  handlePrev = () => {
    const { activeIndexState } = this.state;
    if (activeIndexState === 0) {
      return false;
    }
    this.activeImage[activeIndexState-1].scrollIntoView();

    this.setState({
      ...this.state,
      activeIndexState: this.state.activeIndexState-1
    });
  }
  handleDelete = e => {
    const { activeIndexState } = this.state;
    const { imagePreview: { onDelete, images, deletable } } = this.props;

    onDelete(images[activeIndexState].id, () => {
      let prevIndex = activeIndexState;
      let nextIndex = activeIndexState !== 0 ? activeIndexState-1 : 0;
      this.setState({
        ...this.state,
        activeIndexState: nextIndex
      }, () => {
        let newImages = [...images].filter((image, index) => index !== prevIndex);
        this.closeButton.click();
        this.props.showImagePreview(newImages, nextIndex, deletable, onDelete);
      });
    });
  }
  handleSelect = activeIndexState => {
    this.setState({
      ...this.state,
      activeIndexState
    });
  }
  handleClose = e => {
    this.props.hideImagePreview();
  }
  render() {
    const { activeIndexState } = this.state;
    const { imagePreview: { images, deletable } } = this.props;

    if (images.length === 0) {
      return null;
    }

    return (
      <div className='image-preview-container' style={deletable ? {} : { zIndex: 1500 }}>
        <div className='image-preview-container_toolbar d-flex align-items-center justify-content-between px-3'>
          <div className='text-white'>
            {`${activeIndexState+1} / ${images.length}`}
          </div>
          <div className='d-flex'>
            {
              deletable && (
                <div className='text-white py-3 px-2 mr-3' title='Delete' onClick={this.handleDelete} style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon='trash-alt' />
                </div>
              )
            }
            <div className='py-3 px-2 mr-3'>
              <a href={images[activeIndexState].src} className='text-white' title='Download' target='_blank' rel='noopener noreferrer'>
                <FontAwesomeIcon icon='download' size='lg' />
              </a>
            </div>
            <div className='text-white py-3 px-2' title='Close' onClick={this.handleClose} style={{ cursor: 'pointer' }} ref={e => this.closeButton = e}>
              <FontAwesomeIcon icon='times' size='lg' />
            </div>
          </div>
        </div>
        {
          images.length > 1 && (
            <>
              <div className={`image-preview-container_left-control ${activeIndexState === 0 ? 'disabled' : ''}`}>
                <FontAwesomeIcon icon='chevron-left' className='fa-fw' onClick={this.handlePrev} />
              </div>
              <div className={`image-preview-container_right-control ${activeIndexState < (images.length-1) ? '' : 'disabled'}`}>
                <FontAwesomeIcon icon='chevron-right' className='fa-fw' onClick={this.handleNext} />
              </div>
            </>
          )
        }
        <div className={`image-preview-container_images-container ${images.length === 1 ? 'single' : ''}`} onClick={this.handleClose}>
          <div className='image-preview-container_image-container'>
            <Image src={images[activeIndexState].src} fluid onClick={e => e.stopPropagation()} loading='lazy' />
          </div>
        </div>
        <div className={`text-center image-preview-container_thumbnails ${images.length === 1 ? 'single' : ''}`}>
          {
            images.map((image, i) => (
              <span key={i} className='image-preview-container_thumbnail-container mx-1' ref={e => this.activeImage[i] = e}>
                <Image
                  src={image.src}
                  thumbnail
                  fluid
                  className={activeIndexState === i ? 'border-green' : ''}
                  onClick={e => this.handleSelect(i)}
                  loading='lazy' />
              </span>
            ))
          }
        </div>
      </div>
    );
  }
}

ImagePreview.defaultProps = {
  imagePreview: {
    images: [],
    activeIndex: 0
  }
};

const mapStateToProps = state => ({
  imagePreview: state.imagePreview
});

const mapDispatchToProps = dispatch => ({
  hideImagePreview: () => dispatch(hideImagePreview()),
  showImagePreview: (images, activeIndex, deletable, onDelete) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagePreview);