import React, { Component } from 'react';
import { Alert, Button, ButtonGroup, Card, Container, Form, Modal, Nav, OverlayTrigger, ProgressBar, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../../common/Header/Header';
import NameLink from '../../../common/NameLink/NameLink';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import Validator from 'validatorjs';
import axios from 'axios';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Grade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      classes: [],
      selectedClass: '',
      isGradesLoading: false,
      gradesError: '',
      facultyLoads: [],
      terms: [],
      students: [],
      grades: [],
      selectedTerm: '',
      editFacultyLoadID: '',
      formInputs: [],
      isColumnLoading: false,
      columnError: '',
      importModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      },
      accessibilityModal: {
        show: false,
        type: '',
        student: null,
        isLoading: false,
        errorMessage: ''
      }
    };

    this.importFile = [];
  }
  componentDidMount() {
    axiosRequest('get', 'staff/grades', null, ({ data: { data }}) => {
      data.sort((a, b) => {
        if (a.program.code < b.program.code) {
          return -1;
        } else if (a.program.code > b.program.code) {
          return 1;
        }

        if (a.year_level.code < b.year_level.code) {
          return -1;
        } else if (a.year_level.code > b.year_level.code) {
          return 1;
        }

        if (a.section.code < b.section.code) {
          return -1;
        } else if (a.section.code > b.section.code) {
          return 1;
        }

        return 0;
      });
      this.setState({
        ...this.state,
        isLoading: false,
        classes: data
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  getGrades = () => {
    const { selectedClass } = this.state;

    if (!selectedClass) {
      return;
    }

    this.setState({
      ...this.state,
      isGradesLoading: true,
      gradesError: ''
    }, () => {
      axiosRequest('get', `staff/grades/${selectedClass}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          ...data,
          selectedTerm: data.terms && data.terms.length > 0 ? data.terms[0].id : '',
          isGradesLoading: false
        });
      }, error => {
        this.setState({
          ...this.state,
          isGradesLoading: false,
          gradesError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleSelectClass = e => {
    this.setState({
      ...this.state,
      selectedClass: e.target.value
    }, this.getGrades);
  }
  handleSelectTerm = selectedTerm => {
    this.setState({
      ...this.state,
      selectedTerm
    });
  }
  handleEditColumn = editFacultyLoadID => {
    const { grades, selectedTerm, students } = this.state;

    let formInputs = students.map(st => {
      let grade = grades.find(g => g.student_id === st.id && g.term_id === +selectedTerm && g.faculty_load_id === +editFacultyLoadID);
      grade = grade ? grade.grade : ''
      return {
        studentID: st.id,
        grade
      };
    });

    this.setState({
      ...this.state,
      editFacultyLoadID,
      formInputs
    });
  }
  handleCancelEditColumn = () => {
    this.setState({
      ...this.state,
      editFacultyLoadID: '',
      formInputs: [],
      isColumnLoading: false,
      columnError: ''
    });
  }
  handleInputChange = (e, facultyLoadID, studentID) => {
    const { editFacultyLoadID } = this.state;

    if (facultyLoadID === +editFacultyLoadID) {
      let newFormInputs = [...this.state.formInputs].map(inp => {
        if (inp.studentID === studentID) {
          return {
            ...inp,
            grade: e.target.value
          };
        }
        return {...inp};
      });
      this.setState({
        ...this.state,
        formInputs: newFormInputs
      });
    }

    return false;
  }
  handleSaveEditColumn = e => {
    this.setState({
      ...this.state,
      isColumnLoading: true,
      columnError: ''
    }, () => {
      const { formInputs, selectedClass, selectedTerm, editFacultyLoadID } = this.state;

      let formData = {
        termID: selectedTerm,
        facultyLoadID: editFacultyLoadID,
        termGrades: formInputs
      };

      let validator = new Validator(formData, {
        termID: 'required|integer',
        facultyLoadID: 'required|integer',
        termGrades: 'required|array',
        'termGrades.*.studentID': 'required|integer',
        'termGrades.*.grade': 'present'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          isColumnLoading: false,
          columnError: validator.errors.errors[firstKey][0]
        });
        return;
      }

      axiosRequest('post', `staff/grades/${selectedClass}`, formData, ({ data: { data }}) => {
        const { grades } = this.state;

        let newGrades = [...grades];
        if (grades.length > 0) {
          newGrades = [...grades].filter(g => !(g.term_id === +selectedTerm && g.faculty_load_id === +editFacultyLoadID));
          newGrades = [
            ...newGrades,
            ...data
          ];
        } else {
          newGrades = data;
        }
        this.setState({
          ...this.state,
          isColumnLoading: false,
          grades: newGrades,
          editFacultyLoadID: '',
          formInputs: []
        });
      }, error => {
        this.setState({
          ...this.state,
          isColumnLoading: false,
          columnError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showModal = () => {
    this.setState({
      ...this.state,
      importModal: {
        ...this.state.importModal,
        show: true
      }
    });
  }
  hideModal = () => {
    const { importModal } = this.state;
    
    if (importModal.successMessage && !importModal.isLoading) {
      this.setState({
        ...this.state,
        importModal: {
          show: false,
          isLoading: false,
          errorMessage: '',
          successMessage: ''
        }
      });
    } else {
      this.setState({
        ...this.state,
        importModal: {
          ...this.state.importModal,
          show: false
        }
      });
    }
  }
  handleFileSelect = event => {
    this.importFile = event.target.files;
  }
  handleUpload = event => {
    event.preventDefault();

    if (this.importFile.length > 0) {
      this.setState({
        ...this.state,
        importModal: {
          ...this.state.importModal,
          isLoading: true,
          errorMessage: '',
          successMessage: ''
        }
      }, () => {
        const { selectedTerm, selectedClass, classes, grades } = this.state;
        let schoolClass = classes.find(cl => cl.id === +selectedClass);

        const formData = new window.FormData();
        formData.append('file', this.importFile[0], this.importFile[0].name);

        let url = (schoolClass.year_level.school_level.title === 'Senior High School' && selectedTerm) ?
                    `${process.env['REACT_APP_API_BASE_URL']}/staff/grades/${schoolClass.id}/term/${selectedTerm}/import` :
                    `${process.env['REACT_APP_API_BASE_URL']}/staff/grades/${schoolClass.id}/import`;
        axios.post(url, formData, {
          withCredentials: true,
          header: {
            'content-type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.setState({
              ...this.state,
              importModal: {
                ...this.state.importModal,
                isLoading: percentCompleted
              }
            });
          }
        }).then(({ data: { data, message }}) => {
          let newGrades = [...grades].filter(g => (
            !data.find(d => d.student_id === g.student_id && d.term_id === g.term_id && d.faculty_load_id === g.faculty_load_id)
          ));
          newGrades = [...newGrades, ...data];
          this.setState({
            ...this.state,
            editFacultyLoadID: '',
            formInputs: [],
            grades: newGrades,
            importModal: {
              ...this.state.importModal,
              isLoading: false,
              errorMessage: '',
              successMessage: message
            }
          }, () => {
            this.importFile = [];
          });
        }).catch((error) => {
          if (error.response && error.response.status === 403) {
            this.props.history.push('/login');
          }
          this.setState({
            ...this.state,
            importModal: {
              ...this.state.importModal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        });
      });
    } else {
      this.setState({
        ...this.state,
        importModal: {
          ...this.state.importModal,
          isLoading: false,
          errorMessage: 'The import file is required.'
        }
      });
    }
  }
  showAccessibilityModal = (type, student = null) => {
    const { selectedTerm } = this.state;

    if (student) {
      let enabledGrade = student.enabled_grades.find(eg => eg.term_id === +selectedTerm);
      if ((type === 'enable' && enabledGrade) || (type === 'disable' && !enabledGrade)) {
        return false;
      }
    }

    this.setState({
      ...this.state,
      accessibilityModal: {
        show: true,
        type,
        student,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideAccessibilityModal = () => {
    this.setState({
      ...this.state,
      accessibilityModal: {
        show: false,
        type: '',
        student: null,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleAccessibility = e => {
    this.setState({
      ...this.state,
      accessibilityModal: {
        ...this.state.accessibilityModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { selectedClass, selectedTerm, accessibilityModal: { type, student }, students } = this.state;
      if (student) {
        axiosRequest('post', `staff/grades/${selectedClass}/accessibility/term/${selectedTerm}/student/${student.id}`, {
          type
        }, ({ data: { data }}) => {
          let newStudents = [];
          if (type === 'enable') {
            newStudents = [...students].map(st => {
              if (st.id === student.id) {
                let temp = st.enabled_grades.filter(eg => eg.term_id !== data.term_id);
                return {
                  ...st,
                  enabled_grades: [...temp, data]
                };
              }
  
              return { ...st };
            });
          } else {
            newStudents = [...students].map(st => {
              if (st.id === student.id) {
                let temp = st.enabled_grades.filter(eg => eg.term_id !== +selectedTerm);
                return {
                  ...st,
                  enabled_grades: [...temp]
                }
              }
  
              return { ...st };
            });
          }
          this.setState({
            ...this.state,
            students: [...newStudents],
            accessibilityModal: {
              show: false,
              type: '',
              student: null,
              isLoading: false,
              errorMessage: ''
            }
          });
        }, error => {
          this.setState({
            ...this.state,
            accessibilityModal: {
              ...this.state.accessibilityModal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        }, this.props.history);
      } else {
        axiosRequest('post', `staff/grades/${selectedClass}/accessibility/term/${selectedTerm}`, {
          type
        }, ({ data: { data }}) => {
          let newStudents = [];
          if (type === 'enable') {
            newStudents = [...students].map(st => {
              let temp = st.enabled_grades.filter(eg => eg.term_id !== +selectedTerm);
              let temp2 = data.filter(d => d.student_id === st.id);
              return {
                ...st,
                enabled_grades: [...temp, ...temp2]
              };
            });
          } else {
            newStudents = [...students].map(st => {
              let temp = st.enabled_grades.filter(eg => eg.term_id !== +selectedTerm);
              return {
                ...st,
                enabled_grades: [...temp]
              };
            });
          }
          this.setState({
            ...this.state,
            students: [...newStudents],
            accessibilityModal: {
              show: false,
              type: '',
              student: null,
              isLoading: false,
              errorMessage: ''
            }
          });
        }, error => {
          this.setState({
            ...this.state,
            accessibilityModal: {
              ...this.state.accessibilityModal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        }, this.props.history);
      }
    });
  }
  renderGrade = (facultyLoadID, studentID) => {
    const { editFacultyLoadID, formInputs, isColumnLoading, grades, selectedTerm } = this.state;

    if (facultyLoadID === +editFacultyLoadID) {
      let grade = formInputs.find(inp => inp.studentID === studentID);
      grade = grade ? grade.grade : '';
      return (
        <Form.Control
          type='text'
          size='sm'
          className='text-center'
          value={grade}
          onChange={e => this.handleInputChange(e, facultyLoadID, studentID)}
          disabled={isColumnLoading} />
      );
    }

    let savedGrade = grades.find(g => g.student_id === studentID && g.term_id === +selectedTerm && g.faculty_load_id === +facultyLoadID);
    if (savedGrade) {
      return savedGrade.grade;
    }

    return null;
  }
  renderGradesTable = () => {
    const {
      selectedClass,
      classes,
      isGradesLoading,
      gradesError,
      facultyLoads,
      terms,
      students,
      selectedTerm,
      editFacultyLoadID,
      isColumnLoading,
      columnError,
      importModal,
      accessibilityModal
    } = this.state;

    if (!selectedClass) {
      return null;
    }

    if (isGradesLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (gradesError) {
      return (
        <Alert variant='danger'>
          {gradesError}
        </Alert>
      );
    }

    if (terms.length === 0 || students.length === 0 || facultyLoads.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let male = students.filter(s => s.gender === 'Male');
    let female = students.filter(s => s.gender === 'Female');

    let selectedClassData = classes.find(cl => cl.id === +selectedClass);
    let selectedTermData = terms.find(t => t.id === +selectedTerm);

    return (
      <>
        {
          (selectedClassData && selectedClassData.year_level.school_level.title !== 'Senior High School') && (
            <>
              <div className='text-right'>
                <Button variant='green' size='sm' className='mr-2' onClick={this.showModal}>
                  <FontAwesomeIcon icon='file-import' />  <span className='d-none d-md-inline-block'>Import grades</span>
                </Button>
                <a href={`${process.env['REACT_APP_API_BASE_URL']}/staff/grades/${selectedClass}/export`} className='btn btn-green btn-sm'>
                  <FontAwesomeIcon icon='file-export' /> <span className='d-none d-md-inline-block'>Export grades</span>
                </a>
              </div>
              <div className='dropdown-divider'></div>
            </>
          )
        }
        <div className='mb-3'>
          <Nav fill variant='pills' activeKey={selectedTerm} className='m-0' onSelect={this.handleSelectTerm}>
            {
              terms.map(term => (
                <Nav.Item key={term.id}>
                  <Nav.Link eventKey={term.id} disabled={!!editFacultyLoadID || isGradesLoading}>{term.title}</Nav.Link>
                </Nav.Item>
              ))
            }
          </Nav>
        </div>
        {
          (selectedClassData && selectedClassData.year_level.school_level.title === 'Senior High School') && (
            <>
              <div className='dropdown-divider'></div>
              <div className='text-right mb-3'>
                <Button variant='green' size='sm' className='mr-2' onClick={this.showModal}>
                  <FontAwesomeIcon icon='file-import' />  <span className='d-none d-md-inline-block'>Import grades</span>
                </Button>
                <a href={`${process.env['REACT_APP_API_BASE_URL']}/staff/grades/${selectedClass}/term/${selectedTerm}/export`} className='btn btn-green btn-sm'>
                  <FontAwesomeIcon icon='file-export' /> <span className='d-none d-md-inline-block'>Export grades</span>
                </a>
              </div>
            </>
          )
        }
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th></th>
              {
                (selectedClassData && selectedClassData.year_level.school_level.term_grades) ? (
                  <th className='text-center' style={{ width: '10rem' }}>
                    <div>Accessibility</div>
                    <div className='mt-2'>
                      <ButtonGroup>
                        <Button variant='outline-green' size='sm' onClick={e => this.showAccessibilityModal('enable')}>
                          Enable
                        </Button>
                        <Button variant='outline-danger' size='sm' onClick={e => this.showAccessibilityModal('disable')}>
                          Disable
                        </Button>
                      </ButtonGroup>
                    </div>
                  </th>
                ) : null
              }
              {
                facultyLoads.map(facultyLoad => (
                  <th key={facultyLoad.id} style={{ width: '7.5rem' }}>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <span className='mr-1' style={{ cursor: 'pointer' }}>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              <div>
                                {facultyLoad.class_course.course.description}
                              </div>
                              <div>
                                {facultyLoad.profile.name}
                              </div>
                            </Tooltip>
                          }
                          trigger={['hover', 'focus']}>
                          <FontAwesomeIcon icon='info-circle' className='text-info' />
                        </OverlayTrigger>
                      </span>
                      {facultyLoad.class_course.course.code}
                    </div>
                    {
                      facultyLoad.id === +editFacultyLoadID ? (
                        <>
                          <div className='d-flex mt-2'>
                            <div className='pr-1 flex-fill'>
                              {
                                isColumnLoading ? (
                                  <Button variant='green' size='sm' block disabled>
                                    <LoadingIcon />
                                  </Button>
                                ) : columnError ? (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip>
                                        {columnError}
                                      </Tooltip>
                                    }
                                    trigger={['hover', 'focus']}>
                                    <Button variant='green' size='sm' block onClick={this.handleSaveEditColumn} className='course-column-error'>
                                      <FontAwesomeIcon icon='exclamation' /> <span className='refresh-icon'><FontAwesomeIcon icon='redo' /></span>
                                    </Button>
                                  </OverlayTrigger>
                                ) : (
                                  <Button variant='green' size='sm' block title='Save' onClick={this.handleSaveEditColumn}>
                                    <FontAwesomeIcon icon='check' />
                                  </Button>
                                )
                              }
                            </div>
                            <div className='pl-1 flex-fill'>
                              <Button variant='danger' size='sm' block title='Cancel' onClick={this.handleCancelEditColumn} disabled={isColumnLoading}>
                                <FontAwesomeIcon icon='times' />
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className='mt-2'>
                          <Button
                            variant='info'
                            size='sm'
                            block
                            title='Edit column'
                            onClick={e => this.handleEditColumn(facultyLoad.id)}
                            disabled={!!editFacultyLoadID || importModal.isLoading}>
                            <FontAwesomeIcon icon='pencil-alt' />
                          </Button>
                        </div>
                      )
                    }
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {
              male.length > 0 && (
                <>
                  <tr className='text-white bg-green'>
                    <th className='py-1 text-center'>
                      <div className='mb-0'>Male</div>
                      <small className='font-italic'>{male.length} student{male.length !== 1 ? 's' : ''}</small>
                    </th>
                    <td colspan={facultyLoads.length+(selectedClassData && selectedClassData.year_level.school_level.term_grades)}></td>
                  </tr>
                  {
                    male.map(student => (
                      <tr key={student.id}>
                        <th>
                          <NameLink tabIndex='-1' id={student.id} name={student.formal_name} image={student.image} />
                        </th>
                        {
                          (selectedClassData && selectedClassData.year_level.school_level.term_grades) ? (
                            <td className='text-center align-middle'>
                              <ButtonGroup>
                                <Button variant={student.enabled_grades.find(eg => eg.term_id === +selectedTerm) ? 'green' : 'outline-green'} size='sm' onClick={e => this.showAccessibilityModal('enable', student)}>
                                  {student.enabled_grades.find(eg => eg.term_id === +selectedTerm) ? 'Enabled' : 'Enable'}
                                </Button>
                                <Button variant={student.enabled_grades.find(eg => eg.term_id === +selectedTerm) ? 'outline-danger' : 'danger'} size='sm' onClick={e => this.showAccessibilityModal('disable', student)}>
                                  {student.enabled_grades.find(eg => eg.term_id === +selectedTerm) ? 'Disable' : 'Disabled'}
                                </Button>
                              </ButtonGroup>
                            </td>
                          ) : null
                        }
                        {
                          facultyLoads.map(facultyLoad => (
                            <th key={facultyLoad.id} className='align-middle text-center' style={{ fontSize: '1.1rem' }}>
                              {this.renderGrade(facultyLoad.id, student.id)}
                            </th>
                          ))
                        }
                      </tr>
                    ))
                  }
                </>
              )
            }
            {
              female.length > 0 && (
                <>
                  <tr className='text-white bg-green'>
                    <th className='py-1 text-center'>
                      <div className='mb-0'>Female</div>
                      <small className='font-italic'>{female.length} student{female.length !== 1 ? 's' : ''}</small>
                    </th>
                    <td colspan={facultyLoads.length+(selectedClassData && selectedClassData.year_level.school_level.term_grades)}></td>
                  </tr>
                  {
                    female.map(student => (
                      <tr key={student.id}>
                        <th>
                          <NameLink tabIndex='-1' id={student.id} name={student.formal_name} image={student.image} />
                        </th>
                        {
                          (selectedClassData && selectedClassData.year_level.school_level.term_grades) ? (
                            <td className='text-center align-middle'>
                              <ButtonGroup>
                                <Button variant={student.enabled_grades.find(eg => eg.term_id === +selectedTerm) ? 'green' : 'outline-green'} size='sm' onClick={e => this.showAccessibilityModal('enable', student)}>
                                  {student.enabled_grades.find(eg => eg.term_id === +selectedTerm) ? 'Enabled' : 'Enable'}
                                </Button>
                                <Button variant={student.enabled_grades.find(eg => eg.term_id === +selectedTerm) ? 'outline-danger' : 'danger'} size='sm' onClick={e => this.showAccessibilityModal('disable', student)}>
                                  {student.enabled_grades.find(eg => eg.term_id === +selectedTerm) ? 'Disable' : 'Disabled'}
                                </Button>
                              </ButtonGroup>
                            </td>
                          ) : null
                        }
                        {
                          facultyLoads.map(facultyLoad => (
                            <th key={facultyLoad.id} className='align-middle text-center' style={{ fontSize: '1.1rem' }}>
                              {this.renderGrade(facultyLoad.id, student.id)}
                            </th>
                          ))
                        }
                      </tr>
                    ))
                  }
                </>
              )
            }
          </tbody>
        </Table>
        <Modal show={importModal.show} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Term Grades</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleUpload}>
            <Modal.Body>
              {
                importModal.errorMessage ? (
                  <Alert variant='danger'>
                    {importModal.errorMessage}
                  </Alert>
                ) : importModal.successMessage ? (
                  <Alert variant='success'>
                    {importModal.successMessage}
                  </Alert>
                ) : null
              }
              <Form.Group>
                <Form.File label='Import term grades' accept='.xlsx, .xls' onChange={this.handleFileSelect} disabled={importModal.isLoading || importModal.successMessage} />
              </Form.Group>
              {
                importModal.isLoading && (
                  <div className='mb-3'>
                    <ProgressBar now={importModal.isLoading} label='Uploading...' />
                  </div>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant='light' onClick={this.hideModal}>
                Close
              </Button>
              {
                !importModal.successMessage && (
                  <Button type='submit' variant='green' disabled={importModal.isLoading}>
                    Upload
                  </Button>
                )
              }
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal show={accessibilityModal.show} onHide={this.hideAccessibilityModal}>
          <Modal.Header closeButton>
            <Modal.Title>{accessibilityModal.type === 'disable' ? 'Disable - Accessibility' : 'Enable - Accessibility'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              accessibilityModal.errorMessage && (
                <Alert variant='danger'>
                  {accessibilityModal.errorMessage}
                </Alert>
              )
            }
            {
              accessibilityModal.student ? (
                <>
                  {
                    accessibilityModal.type === 'enable' ? (
                      <Alert variant='warning'>
                        The grades of <b>{accessibilityModal.student.name}</b> for the term <b>{selectedTermData ? selectedTermData.title : ''}</b> will be <b>accessible</b>
                      </Alert>
                    ) : (
                      <Alert variant='warning'>
                        The term grades of <b>{accessibilityModal.student.name}</b> for the term <b>{selectedTermData ? selectedTermData.title : ''}</b> will be <b>inaccessible</b>
                      </Alert>
                    )
                  }
                </>
              ) : (
                <>
                  {
                    accessibilityModal.type === 'enable' ? (
                      <Alert variant='warning'>
                        The grades of the students of <b>{`${selectedClassData.program.code} ${selectedClassData.year_level.code}-${selectedClassData.section.code}`}</b> for the term <b>{selectedTermData ? selectedTermData.title : ''}</b> will be <b>accessible</b>
                      </Alert>
                    ) : (
                      <Alert variant='warning'>
                        The term grades of the students of <b>{`${selectedClassData.program.code} ${selectedClassData.year_level.code}-${selectedClassData.section.code}`}</b> for the term <b>{selectedTermData ? selectedTermData.title : ''}</b> will be <b>inaccessible</b>
                      </Alert>
                    )
                  }
                </>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            {
              accessibilityModal.type === 'enable' ? (
                <>
                  <Button variant='danger' onClick={this.hideAccessibilityModal} disabled={accessibilityModal.isLoading}>
                    Cancel
                  </Button>
                  <Button variant='green' onClick={this.handleAccessibility} disabled={accessibilityModal.isLoading}>
                    Enable
                  </Button>
                </>
              ) : (
                <>
                  <Button variant='info' onClick={this.hideAccessibilityModal} disabled={accessibilityModal.isLoading}>
                    Cancel
                  </Button>
                  <Button variant='danger' onClick={this.handleAccessibility} disabled={accessibilityModal.isLoading}>
                    Disable
                  </Button>
                </>
              )
            }
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  renderContent = () => {
    const { isLoading, errorMessage, classes, selectedClass } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    let schoolClass = classes.find(c => c.id === +selectedClass);

    return (
      <>
        <Form.Control as='select' value={selectedClass} onChange={this.handleSelectClass}>
          <option value='' disabled hidden>Select class...</option>
          {
            classes.map(cl => (
              <option value={cl.id}>{`${cl.program.code} ${cl.year_level.code} - ${cl.section.code} `}&mdash;{` ${cl.program.description} ${cl.year_level.description} - ${cl.section.code}`}</option>
            ))
          }
        </Form.Control>
        {
          schoolClass && (
            <>
              <div className='h5 mt-3'>
                <div>
                  {schoolClass.program.description} ({schoolClass.program.code})
                </div>
                <div>
                  {schoolClass.year_level.description} ({schoolClass.year_level.code})
                </div>
                <div>
                  Section {schoolClass.section.code}
                </div>
              </div>
              {this.renderGradesTable()}
            </>
          )
        }
      </>
    );
  }
  render() {
    return (
      <>
        <Header active='Grades' />
        <Container>
          <Card className='my-3'>
            <Card.Body>
              {this.renderContent()}
            </Card.Body>
          </Card>
        </Container>
      </>
    )
  }
}