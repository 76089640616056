import React, { Component } from 'react';
import { Alert, Breadcrumb, Button, Col, Collapse, Dropdown, Form, Image, InputGroup, Modal, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import moment from 'moment';
import axios from 'axios';
import Validator from 'validatorjs';
import { loadRule } from '../../../../../util';
import PromptDeleteModal from '../../../../modals/PromptDeleteModal/PromptDeleteModal';
import './style.scss';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../../common/RichTextEditor/RichTextEditor';

class Assignments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      createUpdateModal: {
        show: false,
        assignment: null,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        formInputs: {
          title: '',
          description: '',
          points: '',
          audience: []
        },
        fileNames: [],
        loadingFiles: false,
        showAttachments: false,
        deleteFiles: [],
        loadingDeleteFiles: []
      },
      deleteModal: {
        show: false,
        assignment: null,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      },
      viewAssignment: null,
      isNextPageLoading: false,
      nextPageError: ''
    };

    this.files = [];
  }
  componentDidMount() {
    if (this.props.data.data.length === 0) {
      axiosRequest('get', 'faculty/materials/assignments', null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isLoading: false
        }, () => {
          this.props.onFetch(data, 'assignments');
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    } else {
      this.setState({
        ...this.state,
        isLoading: false
      });
    }

    loadRule(['date', 'after_or_equal'], {
      after_or_equal: 'The due date must be equal or after the current date and time.'
    });
  }
  showCreateUpdateModal = (event, assignment = null) => {
    let formInputs = {
      title: '',
      description: '',
      points: '',
      audience: []
    };

    let asyncSchedules = [];
    if (assignment) {
      let audience = [];
      for (let i = 0; i < assignment.audiences.length; i++) {
        let tempAsync = assignment.material_async_schedules.find(mas => mas.faculty_load_id === assignment.audiences[i].faculty_load_id);
        if (tempAsync) {
          let asyncDate = {
            date: tempAsync.schedule_date,
            start_time: tempAsync.start_time,
            end_time:tempAsync.end_time,
            schedule_from: tempAsync.schedule_from
          };
          audience = [
            ...audience,
            {
              facultyLoadId: assignment.audiences[i].faculty_load_id,
              gradeCategoryId: assignment.audiences[i].grade_category_id,
              from: moment(assignment.audiences[i].from).format('YYYY-MM-DDTHH:mm'),
              until: moment(assignment.audiences[i].until).format('YYYY-MM-DDTHH:mm'),
              isAsync: true,
              asyncDate
            }
          ];
          asyncSchedules = [
            ...asyncSchedules,
            {
              classID: assignment.audiences[i].faculty_load_id,
              asyncDate
            }
          ];
        } else {
          audience = [
            ...audience,
            {
              facultyLoadId: assignment.audiences[i].faculty_load_id,
              gradeCategoryId: assignment.audiences[i].grade_category_id,
              from: moment(assignment.audiences[i].from).format('YYYY-MM-DDTHH:mm'),
              until: moment(assignment.audiences[i].until).format('YYYY-MM-DDTHH:mm'),
              isAsync: false
            }
          ];
        }
      }
      formInputs = {
        title: assignment.title,
        description: assignment.description,
        points: assignment.points,
        audience
      };
    }

    this.setState({
      ...this.state,
      createUpdateModal: {
        show: true,
        assignment,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        formInputs,
        fileNames: [],
        loadingFiles: false,
        showAttachments: false,
        deleteFiles: [],
        loadingDeleteFiles: []
      }
    }, () => {
      this.files = [];
      if (asyncSchedules.length > 0) {
        this.setAsyncSchedules(asyncSchedules);
      }
    });
  }
  hideCreateUpdateModal = () => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        show: false,
        assignment: null,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        formInputs: {
          title: '',
          description: '',
          points: '',
          audience: []
        },
        fileNames: [],
        loadingFiles: false,
        showAttachments: false,
        deleteFiles: [],
        loadingDeleteFiles: []
      }
    }, () => {
      this.files = [];
      this.props.onResetAsync();
    });
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          [event.target.name]: event.target.value
        }
      }
    });
  }
  handleDescriptionInputChange = description => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          description
        }
      }
    });
  }
  handleFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.files = [...this.files, ...files];
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          fileNames: [
            ...this.state.createUpdateModal.fileNames,
            ...filesState
          ]
        }
      });
    }
  }
  handleRemoveUpload = id => {
    let newFileNames = [...this.state.createUpdateModal.fileNames];
    newFileNames.splice(id, 1);

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        fileNames: newFileNames
      }
    }, () => {
      this.files.splice(id, 1);
    });
  }
  handleAudienceChange = event => {
    const { createUpdateModal: { formInputs } } = this.state;

    let audience = [...formInputs.audience];
    if (event.target.checked) {
      audience = [
        ...audience,
        {
          facultyLoadId: +event.target.value,
          gradeCategoryId: '',
          from: '',
          until: '',
          isAsync: false,
          asyncDate: null
        }
      ];
    } else {
      audience = audience.filter(a => a.facultyLoadId !== +event.target.value);
    }

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          audience
        }
      }
    });
  }
  handleAudienceParamsChange = (event, facultyLoadID) => {
    let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
      if (a.facultyLoadId === facultyLoadID) {
        return {
          ...a,
          [event.target.name]: event.target.value
        };
      }

      return a;
    });

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          audience: newAudience
        }
      }
    });
  }
  handleAsyncInputChange = (event, facultyLoadID) => {
    const isAsync = event.target.checked;
    let asyncSchedule = [...this.props.asyncSchedules].find(as => as.classID === facultyLoadID);
  
    if (isAsync && (this.props.asyncSchedules.length === 0 || !asyncSchedule || asyncSchedule.schedules.length === 0)) {
      this.setAsyncSchedule(facultyLoadID);
      return;
    }

    let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
      if (a.facultyLoadId === facultyLoadID) {
        return {
          ...a,
          isAsync
        };
      }

      return a;
    });

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          audience: newAudience
        }
      }
    });
  }
  setAsyncSchedule = (classID, currentAsyncDate = null) => {

    let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
      if (a.facultyLoadId === classID) {
        return {
          ...a,
          isAsync: true
        };
      }

      return a;
    });

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        isLoading: true,
        errorMessage: '',
        formInputs: {
          ...this.state.createUpdateModal.formInputs,
          audience: newAudience
        }
      }
    }, () => {
      axiosRequest('get', `faculty/class/${classID}/material/async-schedules${currentAsyncDate ? `/${currentAsyncDate.date}` : ''}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            isLoading: false
          }
        }, () => {
          this.props.setAsyncSchedules(classID, data, currentAsyncDate, (isSuccess, returnData) => {
            let newAudience2 = [...this.state.createUpdateModal.formInputs.audience].map(a => {
              if (a.facultyLoadId === classID) {
                if (isSuccess) {
                  return {
                    ...a,
                    asyncDate: returnData,
                    from: moment(`${returnData.date} ${returnData.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                    until: moment(`${returnData.date} ${returnData.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm')
                  };
                } else {
                  return {
                    ...a,
                    isAsync: false
                  };
                }
              }
        
              return {
                ...a
              };
            });
            if (isSuccess) {
              this.setState({
                ...this.state,
                createUpdateModal: {
                  ...this.state.createUpdateModal,
                  formInputs: {
                    ...this.state.createUpdateModal.formInputs,
                    audience: newAudience2
                  }
                }
              });
            } else {
              this.setState({
                ...this.state,
                createUpdateModal: {
                  ...this.state.createUpdateModal,
                  errorMessage: 'There are no schedules available.',
                  formInputs: {
                    ...this.state.createUpdateModal.formInputs,
                    audience: newAudience2
                  }
                }
              });
            }
          });
        });
      }, error => {
        let newAudience3 = [...this.state.createUpdateModal.formInputs.audience].map(a => {
          if (a.facultyLoadId === classID) {
            return {
              ...a,
              isAsync: false
            };
          }
    
          return a;
        });

        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            formInputs: {
              ...this.state.createUpdateModal.formInputs,
              audience: newAudience3
            },
            isLoading: false,
            errorMessage: (error.response && error.response.data.message) ? error.response.data.message : error.message ? error.message : error
          },
        });
      }, this.props.history);
    });
  }
  setAsyncSchedules = toFetchArray => {
    let fetchAsyncSchedules = (classID, currentAsyncDate) => {
      return axios.get(`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/async-schedules${currentAsyncDate ? `/${currentAsyncDate.date}` : ''}`, {
        withCredentials: true,
      }).then(({ data: { data }}) => {
        this.props.setAsyncSchedules(classID, data, currentAsyncDate, (isSuccess, returnData) => {
          let newAudience2 = [...this.state.createUpdateModal.formInputs.audience].map(a => {
            if (a.facultyLoadId === classID) {
              if (isSuccess) {
                return {
                  ...a,
                  asyncDate: returnData,
                  from: moment(`${returnData.date} ${returnData.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                  until: moment(`${returnData.date} ${returnData.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm')
                };
              } else {
                return {
                  ...a,
                  isAsync: false
                };
              }
            }
      
            return {
              ...a
            };
          });
          if (isSuccess) {
            this.setState({
              ...this.state,
              createUpdateModal: {
                ...this.state.createUpdateModal,
                formInputs: {
                  ...this.state.createUpdateModal.formInputs,
                  audience: newAudience2
                }
              }
            });
          } else {
            this.setState({
              ...this.state,
              createUpdateModal: {
                ...this.state.createUpdateModal,
                errorMessage: 'There are no schedules available.',
                formInputs: {
                  ...this.state.createUpdateModal.formInputs,
                  audience: newAudience2
                }
              }
            });
          }
        });
        return data;
      }).catch((error) => {
        if (error.response && error.response.status === 403) {
          this.props.history.push('/login');
        }
  
        let newAudience3 = [...this.state.createUpdateModal.formInputs.audience].map(a => {
          if (a.facultyLoadId === classID) {
            return {
              ...a,
              isAsync: false
            };
          }
    
          return a;
        });

        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            formInputs: {
              ...this.state.createUpdateModal.formInputs,
              audience: newAudience3
            },
            errorMessage: (error.response && error.response.data.message) ? error.response.data.message : error.message ? error.message : error
          },
        });
        throw (error.response && error.response.data.message) ? error.response.data.message : error.message ? error.message : error;
      });
    };

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      let fetchRequests = [];
      for (let i = 0; i < toFetchArray.length; i++) {
        fetchRequests.push(fetchAsyncSchedules(toFetchArray[i].classID, toFetchArray[i].asyncDate));
      }
      
      Promise.all(fetchRequests).then(result => {
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            isLoading: false
          }
        });
      }).catch((error) => {
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            isLoading: false
          }
        });
      });
    });
  }
  handlePrevAsync = classID => {
    const { asyncSchedules } = this.props;

    let audience = [...this.state.createUpdateModal.formInputs.audience].find(a => a.facultyLoadId === classID);
    
    if (audience && audience.asyncDate) {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          isLoading: true
        }
      }, () => {
        let asyncSchedule = asyncSchedules.find(as => as.classID === classID);

        if (asyncSchedule) {
          let index = asyncSchedule.schedules.findIndex(as1 => 
            as1.date === audience.asyncDate.date &&
            as1.start_time === audience.asyncDate.start_time &&
            as1.end_time === audience.asyncDate.end_time &&
            as1.schedule_from === audience.asyncDate.schedule_from
          );
          if (index !== -1) {
            if (index === 0) {
              this.props.onPrevAsync(classID, audience.asyncDate, newAsyncDate => {
                let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
                  if (a.facultyLoadId === classID) {
                    return {
                      ...a,
                      asyncDate: newAsyncDate,
                      from: moment(`${newAsyncDate.date} ${newAsyncDate.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                      until: moment(`${newAsyncDate.date} ${newAsyncDate.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm')
                    };
                  }
            
                  return a;
                });
                this.setState({
                  ...this.state,
                  createUpdateModal: {
                    ...this.state.createUpdateModal,
                    isLoading: false,
                    formInputs: {
                      ...this.state.createUpdateModal.formInputs,
                      audience: newAudience
                    }
                  }
                });
              });
            } else {
              let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
                if (a.facultyLoadId === classID) {
                  return {
                    ...a,
                    asyncDate: {...asyncSchedule.schedules[index-1]},
                    from: moment(`${{...asyncSchedule.schedules[index-1]}.date} ${{...asyncSchedule.schedules[index-1]}.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                    until: moment(`${{...asyncSchedule.schedules[index-1]}.date} ${{...asyncSchedule.schedules[index-1]}.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm')
                  };
                }
          
                return a;
              });
              this.setState({
                ...this.state,
                createUpdateModal: {
                  ...this.state.createUpdateModal,
                  isLoading: false,
                  formInputs: {
                    ...this.state.createUpdateModal.formInputs,
                    audience: newAudience
                  }
                }
              });
            }
          }
        }
      });
    }
  }
  handleNextAsync = classID => {
    const { asyncSchedules } = this.props;

    let audience = [...this.state.createUpdateModal.formInputs.audience].find(a => a.facultyLoadId === classID);

    if (audience && audience.asyncDate) {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          isLoading: true
        }
      }, () => {
        let asyncSchedule = asyncSchedules.find(as => as.classID === classID);

        if (asyncSchedule) {
          let index = asyncSchedule.schedules.findIndex(as1 => 
            as1.date === audience.asyncDate.date &&
            as1.start_time === audience.asyncDate.start_time &&
            as1.end_time === audience.asyncDate.end_time &&
            as1.schedule_from === audience.asyncDate.schedule_from
          );
          if (index !== -1) {
            if (index === asyncSchedule.schedules.length-1) {
              this.props.onNextAsync(classID, audience.asyncDate, newAsyncDate => {
                let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
                  if (a.facultyLoadId === classID) {
                    return {
                      ...a,
                      asyncDate: newAsyncDate,
                      from: moment(`${newAsyncDate.date} ${newAsyncDate.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                      until: moment(`${newAsyncDate.date} ${newAsyncDate.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm')
                    };
                  }
            
                  return a;
                });
                this.setState({
                  ...this.state,
                  createUpdateModal: {
                    ...this.state.createUpdateModal,
                    isLoading: false,
                    formInputs: {
                      ...this.state.createUpdateModal.formInputs,
                      audience: newAudience
                    }
                  }
                });
              });
            } else {
              let newAudience = [...this.state.createUpdateModal.formInputs.audience].map(a => {
                if (a.facultyLoadId === classID) {
                  return {
                    ...a,
                    asyncDate: {...asyncSchedule.schedules[index+1]},
                    from: moment(`${{...asyncSchedule.schedules[index+1]}.date} ${{...asyncSchedule.schedules[index+1]}.start_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm'),
                    until: moment(`${{...asyncSchedule.schedules[index+1]}.date} ${{...asyncSchedule.schedules[index+1]}.end_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm')
                  };
                }
          
                return a;
              });
              this.setState({
                ...this.state,
                createUpdateModal: {
                  ...this.state.createUpdateModal,
                  isLoading: false,
                  formInputs: {
                    ...this.state.createUpdateModal.formInputs,
                    audience: newAudience
                  }
                }
              });
            }
          }
        }
      });
    }
  }
  handleShowAttachments = () => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        showAttachments: !this.state.createUpdateModal.showAttachments
      }
    });
  }
  handleCancelDeleteFile = id => {
    let newDeleteFiles = [...this.state.createUpdateModal.deleteFiles].filter(fileID => fileID !== id);
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        deleteFiles: newDeleteFiles
      }
    });
  }
  handleDeleteFile = id => {
    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        deleteFiles: [...this.state.createUpdateModal.deleteFiles, id]
      }
    });
  }
  handleCreate = inputs => {
    axiosRequest('post', `faculty/materials/assignments`, inputs, ({ data: { data, message }}) => {
      if (this.files.length > 0) {
        let fileRequests = [];
        for (let i = 0; i < this.files.length; i++) {
          fileRequests.push(this.uploadFile(i, data.id));
        }
        Promise.all(fileRequests).then(result => {
          data.files = result;
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              successMessage: message
            }
          }, () => {
            this.props.onCreate(data, 'assignments');
          });
          // this.hideCreateUpdateModal();
        }).catch((error) => {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: error,
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
        });
      } else {
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            successMessage: message
          }
        }, () => {
          this.props.onCreate(data, 'assignments');
        });
        // this.hideCreateUpdateModal();
      }
    }, (error) => {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          isLoading: false,
          loadingFiles: false,
          loadingDeleteFiles: []
        }
      });
    }, this.props.history);
  }
  handleUpdate = inputs => {
    const { createUpdateModal: { assignment, deleteFiles } } = this.state;

    axiosRequest('patch', `faculty/materials/assignments/${assignment.id}`, inputs, ({ data: { data, message }}) => {
      data = {...assignment, ...data};
      let uploadRequests = [];
      let deleteRequests = [];

      if (this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          uploadRequests.push(this.uploadFile(i, assignment.id));
        }
      }

      if (deleteFiles.length > 0) {
        for (let i = 0; i < deleteFiles.length; i++) {
          deleteRequests.push(this.deleteFile(deleteFiles[i], assignment.id));
        }
      }

      if (uploadRequests.length && deleteRequests.length) {
        Promise.all([Promise.all(uploadRequests), Promise.all(deleteRequests)]).then(result => {
          data.files = [...result[0], ...assignment.files].filter(file => result[1].indexOf(file.id) === -1);
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              successMessage: message
            }
          }, () => {
            this.props.onUpdate(data, 'assignments');
          });
          // this.hideCreateUpdateModal();
        }).catch((error) => {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: error,
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
        });
      } else if (uploadRequests.length) {
        Promise.all(uploadRequests).then(result => {
          data.files = [...result, ...assignment.files];
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              successMessage: message
            }
          }, () => {
            this.props.onUpdate(data, 'assignments');
          });
          // this.hideCreateUpdateModal();
        }).catch((error) => {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: error,
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
        });
      } else if (deleteRequests.length) {
        Promise.all(deleteRequests).then(result => {
          data.files = [...assignment.files].filter(file => result.indexOf(file.id) === -1);
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              successMessage: message
            }
          }, () => {
            this.props.onUpdate(data, 'assignments');
          });
          // this.hideCreateUpdateModal();
        }).catch((error) => {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: error,
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
        });
      } else {
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            successMessage: message
          }
        }, () => {
          this.props.onUpdate(data, 'assignments');
        });
        // this.hideCreateUpdateModal();
      }
    }, (error) => {
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          isLoading: false,
          loadingFiles: false,
          loadingDeleteFiles: []
        }
      });
    }, this.props.history);
  }
  uploadFile = (i, assignmentID) => {
    const formData = new window.FormData();
    formData.append('file', this.files[i], this.files[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/faculty/materials/assignments/${assignmentID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { createUpdateModal: { fileNames } } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            fileNames
          }
        });
      }
    }).then(({ data: { data }}) => {
      const { createUpdateModal: { fileNames } } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          fileNames
        }
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { createUpdateModal: { fileNames } } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          fileNames
        }
      });
      throw fileNames[i].isError;
    });
  }
  deleteFile = (id, assignmentID) => {
    return axios.delete(`${process.env['REACT_APP_API_BASE_URL']}/faculty/materials/assignments/${assignmentID}/file/${id}`, {
      withCredentials: true,
    }).then(({ data: { data }}) => {
      let newLoadingDeleteFiles = [...this.state.createUpdateModal.loadingDeleteFiles].filter(fileID => fileID !== id);
      this.setState({
        ...this.state,
        createUpdateModal: {
          ...this.state.createUpdateModal,
          loadingDeleteFiles: newLoadingDeleteFiles
        }
      });
      return id;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      throw error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
    });
  }
  handleSave = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      createUpdateModal: {
        ...this.state.createUpdateModal,
        isLoading: true,
        errorMessage: '',
        loadingFiles: true,
        loadingDeleteFiles: [...this.state.createUpdateModal.deleteFiles]
      }
    }, () => {
      const { createUpdateModal: { formInputs, assignment } } = this.state;
      const { audiences } = this.props;
      
      let validator = new Validator(formInputs, {
        title: 'required|min:3',
        description: 'required',
        points: 'required|numeric|min:1',
        audience: 'required|array|min:1',
        'audience.*.facultyLoadId': 'required|integer|min:1',
        'audience.*.gradeCategoryId': 'required|integer|min:1',
        'audience.*.from': `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`,
        'audience.*.until': 'required|date',
        'audience.*.isAsync': 'required|boolean'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          createUpdateModal: {
            ...this.state.createUpdateModal,
            errorMessage: validator.errors.errors[firstKey][0],
            isLoading: false,
            loadingFiles: false,
            loadingDeleteFiles: []
          }
        });
        return;
      }

      let newAudience = [];
      for (let i = 0; i < formInputs.audience.length; i++) {
        let tempAudience = audiences.find(a => a.id === formInputs.audience[i].facultyLoadId);
        if (!tempAudience) {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: 'Classroom not found.',
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
          return;
        }

        let tempGradeCategory = tempAudience.grade_categories.find(gc => gc.id === +formInputs.audience[i].gradeCategoryId);
        if (!tempGradeCategory) {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: 'Grade category not found.',
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
          return;
        }

        if (moment(formInputs.audience[i].from).isAfter(moment(formInputs.audience[i].until))) {
          this.setState({
            ...this.state,
            createUpdateModal: {
              ...this.state.createUpdateModal,
              errorMessage: 'Until date must be after or equal to from date.',
              isLoading: false,
              loadingFiles: false,
              loadingDeleteFiles: []
            }
          });
          return;
        }

        let custom = {};
        if (formInputs.audience[i].isAsync) {
          validator = {
            passes: true,
            error: ''
          };
  
          if (formInputs.audience[i].asyncDate === null || !formInputs.audience[i].asyncDate || !formInputs.audience[i].asyncDate.date) {
            validator.passes = false;
            validator.error = 'Asynchronous schedule is required.';
          } else {
            let asyncEnd = moment(`${formInputs.audience[i].asyncDate.date} ${formInputs.audience[i].asyncDate.end_time}`, 'YYYY-MM-DD HH:mm');
            if (moment(formInputs.audience[i].from).isSameOrAfter(asyncEnd)) {
              validator.passes = false;
              validator.error = 'The "from" field must be before the end of the asynchronous schedule.';
            }
          }
  
          if (!validator.passes) {
            this.setState({
              ...this.state,
              createUpdateModal: {
                ...this.state.createUpdateModal,
                errorMessage: validator.error,
                isLoading: false,
                loadingFiles: false,
                loadingDeleteFiles: []
              }
            });
            return;
          }
  
          custom = {
            asyncDate: formInputs.audience[i].asyncDate.date,
            asyncStartTime: formInputs.audience[i].asyncDate.start_time,
            asyncEndTime: formInputs.audience[i].asyncDate.end_time,
            asyncFrom: formInputs.audience[i].asyncDate.schedule_from
          };
        }

        newAudience.push({...formInputs.audience[i], ...custom});
      }
      
      
      if (assignment) {
        this.handleUpdate({
          ...formInputs,
          audience: newAudience
        });
      } else {
        this.handleCreate({
          ...formInputs,
          audience: newAudience
        });
      }
    });
  }
  showDeleteModal = assignment => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        assignment,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        assignment: null,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      }
    });
  }
  handleDelete = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { deleteModal } = this.state;
      axiosRequest('delete', `faculty/materials/assignments/${deleteModal.assignment.id}`, null, ({ data: { message }}) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            successMessage: message
          }
        }, () => {
          this.props.onDelete(deleteModal.assignment.id, 'assignments');
        });
        // this.hideDeleteModal();
      }, (error) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showViewAssignment = viewAssignment => {
    this.setState({
      ...this.state,
      viewAssignment
    });
  }
  hideViewAssignment = () => {
    this.setState({
      ...this.state,
      viewAssignment: null
    });
  }
  handleFileDownload = fileID => {
    const { viewAssignment } = this.state;

    axiosRequest('get', `faculty/materials/assignments/${viewAssignment.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { data } = this.props;

      const path = data.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false
        }, () => {
          this.props.onNextPage(data, 'assignments');
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handlePreviewImages = (viewAssignment, images, index) => {
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/faculty/materials/assignments/${viewAssignment.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  renderAdditionalParams = facultyLoad => {
    const { createUpdateModal: { formInputs, isLoading } } = this.state;
    const { asyncSchedules } = this.props;

    let formInputValues = formInputs.audience.find(a => a.facultyLoadId === facultyLoad.id);

    if (!formInputValues) {
      return null;
    }

    let asyncSchedule = asyncSchedules.find(as => as.classID === facultyLoad.id);

    return (
      <div className='mt-3 px-3'>
        <Form.Group>
          <Form.Control name='gradeCategoryId' onChange={e => this.handleAudienceParamsChange(e, facultyLoad.id)} value={formInputValues.gradeCategoryId} as='select' disabled={isLoading}>
            <option value='' selected hidden>Select grade category...</option>
            {
              facultyLoad.grade_categories.map(gc => (
                <option key={gc.id} value={gc.id}>{gc.name}</option>
              ))
            }
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Check id={`isAsync-${facultyLoad.id}`} type='switch' label='Asynchronous' checked={formInputValues.isAsync} onChange={e => this.handleAsyncInputChange(e, facultyLoad.id)} disabled={isLoading} />
          {
            (formInputValues.isAsync && asyncSchedule && asyncSchedule.schedules.length > 0) && (
              <div className='px-3 pt-2'>
                <InputGroup>
                  <InputGroup.Prepend>
                    <Button className='border' variant='light' onClick={e => this.handlePrevAsync(facultyLoad.id)} disabled={isLoading}>
                      <FontAwesomeIcon icon='chevron-left' />
                    </Button>
                  </InputGroup.Prepend>
                  <Form.Control
                    className='text-center'
                    value={formInputValues.asyncDate ? 
                      `${moment(formInputValues.asyncDate.date).format('MMMM D, YYYY (ddd)')} ${moment(formInputValues.asyncDate.start_time, 'HH:mm').format('hh:mm A')}-${moment(formInputValues.asyncDate.end_time, 'HH:mm').format('hh:mm A')}` : 
                      null}
                    type='text'
                    disabled={isLoading}
                    readOnly />
                  <InputGroup.Append>
                    <Button className='border' variant='light' onClick={e => this.handleNextAsync(facultyLoad.id)} disabled={isLoading}>
                      <FontAwesomeIcon icon='chevron-right' />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                <Form.Text className='text-muted text-center'>Asynchronous schedule to which the material will be tagged to.</Form.Text>
              </div>
            )
          }
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>From</Form.Label>
            <Form.Control type='datetime-local' name='from' min={moment().format('YYYY-MM-DDTHH:mm')} max={formInputValues.until} value={formInputValues.from} onChange={e => this.handleAudienceParamsChange(e, facultyLoad.id)} disabled={isLoading} />
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>Until</Form.Label>
            <Form.Control type='datetime-local' name='until' min={formInputValues.from} value={formInputValues.until} onChange={e => this.handleAudienceParamsChange(e, facultyLoad.id)} disabled={isLoading} />
          </Form.Group>
        </Form.Row>
      </div>
    );
  }
  renderAudience = () => {
    const { createUpdateModal: { formInputs: { audience }, isLoading } } = this.state;
    const { audiences } = this.props;

    return (
      <Form.Group>
        <Form.Label>
          Audience
        </Form.Label>
        <div className='ml-3'>
        {
          audiences.map(a => (
            <div key={a.id}>
              <Form.Group controlId={`audience${a.id}`}>
                <Form.Check
                  type='checkbox'
                  name='audience'
                  label={`${a.class_course.school_class.program.code} ${a.class_course.school_class.year_level.code} - ${a.class_course.school_class.section.code} - ${a.class_course.course.code}`}
                  value={a.id}
                  checked={audience.find(a2 => a2.facultyLoadId === a.id)}
                  onChange={this.handleAudienceChange}
                  disabled={isLoading} />
                {this.renderAdditionalParams(a)}
              </Form.Group>
            </div>
          ))
        }
        </div>
      </Form.Group>
    );
  }
  renderFileUpload = () => {
    const { createUpdateModal: { fileNames, loadingFiles } } = this.state;
    if (fileNames.length > 0) {
      return (
        <Form.Group>
          <Form.Label>File(s) to be uploaded</Form.Label>
          <small className='file-upload-display d-block rounded-top'>
          {
            fileNames.map((fileName, index) => (
              <div key={index} className='file-name d-flex'>
                <div>
                  {
                    fileName.isLoading || (loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                      <LoadingIcon />
                    ) : fileName.isError ? (
                      <FontAwesomeIcon icon='times-circle' className='text-danger' />
                    ) : fileName.isSuccess ? (
                      <FontAwesomeIcon icon='check-circle' className='text-green' />
                    ) : (
                      <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                    )
                  }
                </div>
                <div className='ml-2 flex-fill'>
                  <div>
                    {fileName.name}
                  </div>
                  {
                    fileName.isLoading && (
                      <div className='mt-1'>
                        <ProgressBar now={fileName.isLoading} />
                      </div>
                    )
                  }
                </div>
                {
                  (!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !loadingFiles) && (
                    <div className='ml-2 align-items-center d-flex'>
                      <Button
                        variant='link'
                        className='text-danger'
                        size='sm'
                        title='Remove'
                        onClick={(e) => this.handleRemoveUpload(index)}>
                        <FontAwesomeIcon icon='times' size='sm' />
                      </Button>
                    </div>
                  )
                }
              </div>
            ))
          }
          </small>
        </Form.Group>
      );
    }
  }
  renderAttachedFiles = () => {
    const { createUpdateModal: { showAttachments, deleteFiles, loadingDeleteFiles, assignment, isLoading } } = this.state;

    if (assignment && assignment.files.length > 0) {
      return (
        <>
          <div className={`text-right border rounded-top ${showAttachments ? 'border-bottom-0' : 'rounded-bottom'}`}>
            <Button variant='link' onClick={this.handleShowAttachments} block>
              <FontAwesomeIcon icon='paperclip' /> Show {assignment.files.length} attachment(s)
            </Button>
          </div>
          <Collapse in={showAttachments}>
            <div className='file-upload-display small'>
            {
              assignment.files.map(file => (
                <div className={`file-name d-flex ${deleteFiles.indexOf(file.id) !== -1 ? 'alert-light' : ''}`}>
                  {
                    loadingDeleteFiles.indexOf(file.id) !== -1 && (
                      <LoadingIcon />
                    )
                  }
                  <div className='ml-2 flex-fill'>
                    <div>
                      {file.file_title}
                    </div>
                    {
                      deleteFiles.indexOf(file.id) !== -1 && (
                        <div className='font-italic'>
                          To be deleted
                        </div>
                      )
                    }
                  </div>
                  <div className='ml-2 align-items-center d-flex'>
                    {
                      deleteFiles.indexOf(file.id) !== -1 ? (
                        <Button
                          variant='link'
                          className='text-green'
                          size='sm'
                          title='Cancel delete'
                          onClick={() => this.handleCancelDeleteFile(file.id)}
                          disabled={isLoading}>
                          <FontAwesomeIcon icon='ban' size='sm' />
                        </Button>
                      ) : (
                        <Button
                          variant='link'
                          className='text-danger'
                          size='sm'
                          title='Delete'
                          onClick={() => this.handleDeleteFile(file.id)}
                          disabled={isLoading}>
                          <FontAwesomeIcon icon='trash-alt' size='sm' />
                        </Button>
                      )
                    }
                  </div>
                </div>
              ))
            }
            </div>
          </Collapse>
        </>
      );
    }
  }
  renderCreateUpdateModal = () => {
    const { show, assignment, formInputs, isLoading, errorMessage, successMessage } = this.state.createUpdateModal;

    return (
      <Modal show={show} backdrop='static' onHide={this.hideCreateUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {
              assignment ? 'Update assignment' : 'Create an assignment'
            }
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleSave} className='assignment-form'>
          <Modal.Body>
            {
              errorMessage ? (
                <Alert variant='danger'>
                  {errorMessage}
                </Alert>
              ) : successMessage ? (
                <Alert variant='success'>
                  {successMessage}
                </Alert>
              ) : null
            }
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type='text' name='title' value={formInputs.title} onChange={this.handleInputChange} disabled={isLoading} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <RichTextEditor.Editor
                value={formInputs.description}
                onChange={this.handleDescriptionInputChange}
                readOnly={isLoading} />
              {/* <Form.Control as='textarea' rows='4' style={{ resize: 'none' }} name='description' value={formInputs.description} onChange={this.handleInputChange} disabled={isLoading} /> */}
            </Form.Group>
            <Form.Group>
              <Form.Label>Points</Form.Label>
              <Form.Control type='number' name='points' min='0' value={formInputs.points} onChange={this.handleInputChange} disabled={isLoading} />
            </Form.Group>
            {this.renderAudience()}
            {this.renderFileUpload()}
            {this.renderAttachedFiles()}
          </Modal.Body>
          <Modal.Footer>
            {
              successMessage ? (
                <Button variant='light' onClick={this.hideCreateUpdateModal}>
                  Close
                </Button>
              ) : (
                <>
                  <div className='mr-auto'>
                    <label className={`btn btn-info ${isLoading ? 'disabled' : ''}`} htmlFor='add-file' title='File upload'>
                      <FontAwesomeIcon icon='file-upload' />
                    </label>
                    <Form.File className='d-none' id='add-file' onChange={this.handleFileUpload} multiple disabled={isLoading} />
                  </div>
                  <Button variant='danger mr-2' onClick={this.hideCreateUpdateModal} disabled={isLoading}>
                    Cancel
                  </Button>
                  <Button variant='green' type='submit' disabled={isLoading}>
                    Save
                  </Button>
                </>
              )
            }
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
  renderDeleteModal = () => {
    const { deleteModal } = this.state;

    let renderAudience = audience => {
      const { audiences } = this.props;

      let tempAudience = audiences.find(a => a.id === audience.faculty_load_id);
      if (!tempAudience) {
        return null;
      }

      return (
        <div key={audience.id} className='mb-1'>
          {`${tempAudience.class_course.school_class.program.code} ${tempAudience.class_course.school_class.year_level.code}-${tempAudience.class_course.school_class.section.code} - ${tempAudience.class_course.course.code}`}
        </div>
      );
    };

    return (
      <PromptDeleteModal {...deleteModal} title='Delete assignment' onHide={this.hideDeleteModal} onDelete={this.handleDelete}>
        {
          !deleteModal.successMessage && (
            <div className='font-weight-bold'>
              <div>Are you sure you want to delete the assignment?</div>
              <div>Students' submissions will also be deleted.</div>
            </div>
          )
        }
        {
          deleteModal.assignment && (
            <Alert variant='light'>
              <div>{deleteModal.assignment.title}</div>
              {
                deleteModal.assignment.files.length > 0 && (
                  <div className='font-italic'><FontAwesomeIcon icon='paperclip' /> {`${deleteModal.assignment.files.length} attachment(s)`}</div>
                )
              }
              <div className='d-md-flex'>
                <div className='mr-1'>
                  <FontAwesomeIcon icon='users' /> Audience(s):
                </div>
                <div>
                  {
                    deleteModal.assignment.audiences.map(renderAudience)
                  }
                </div>
              </div>
            </Alert>
          )
        }
      </PromptDeleteModal>
    );
  }
  renderViewAssignment = () => {
    const { viewAssignment } = this.state;

    if (!viewAssignment) {
      return null;
    }

    let images = viewAssignment.files.length > 0 ? viewAssignment.files.filter(file => {
      return !!file.is_image;
    }) : [];
  
    let files = viewAssignment.files.length > 0 ? viewAssignment.files.filter(file => {
      return !file.is_image;
    }) : [];

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.hideViewAssignment}>Assignments</Breadcrumb.Item>
          <Breadcrumb.Item active>{viewAssignment.title}</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <div className='d-flex px-md-3'>
            <div className='flex-fill h5 pt-1' style={{ wordBreak: 'break-all' }}>
              {viewAssignment.title}
            </div>
            <div className='d-block pl-2'>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    <div>Posted: {moment(viewAssignment.created_at).format('dddd, MMM D, YYYY hh:mm A')}</div>
                  </Tooltip>
                }
                trigger={['hover', 'focus']}>
                <FontAwesomeIcon icon='info-circle' className='info-icon text-primary' size='lg' />
              </OverlayTrigger>
            </div>
          </div>
          <div className='px-md-3'>
            <RichTextEditor.Viewer body={viewAssignment.description} />
            {/* <div style={{ whiteSpace: 'pre-line' }}>
              {viewAssignment.description}
            </div> */}
            {
              viewAssignment.files.length > 0 && (
                <div>
                  {
                    images.length > 0 && (
                      <Row>
                      {
                        images.map((image, index) => (
                          <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(viewAssignment, images, index)}>
                            <Image src={`${process.env['REACT_APP_API_BASE_URL']}/faculty/materials/assignments/${viewAssignment.id}/image/${image.id}`} thumbnail />
                          </Col>
                        ))
                      }
                      </Row>
                    )
                  }
                  {
                    files.length > 0 && (
                      <div className='p-1'>
                        {
                          files.map(file => (
                            <div key={file.id} className='d-flex mb-2'>
                              <div className='mr-2'>
                                <FontAwesomeIcon icon='file' className='text-primary' />
                              </div>
                              <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                                <span className='btn-link' onClick={e => this.handleFileDownload(file.id)}>
                                  {file.file_title}
                                </span>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
        </div>
      </>
    );
  }
  renderAudiencesDisplay = (audience, material) => {
    const { audiences } = this.props;

    let tempAudience = audiences.find(a => a.id === audience.faculty_load_id);
    if (!tempAudience) {
      return null;
    }

    let tempGradeCategory = tempAudience.grade_categories.find(gc => gc.id === audience.grade_category_id);
    let tempMaterialAsyncSchedule = material.material_async_schedules.find(mas => mas.faculty_load_id === audience.faculty_load_id);

    return (
      <div key={audience.id} className='mb-1'>
        <div className='font-weight-bold'>
          {`${tempAudience.class_course.school_class.program.code} ${tempAudience.class_course.school_class.year_level.code}-${tempAudience.class_course.school_class.section.code} - ${tempAudience.class_course.course.code}`}
        </div>
        <div className='px-1 px-md-3'>
          {
            moment().isBefore(audience.from) && (
              <span className='text-muted font-weight-normal font-italic'> (Not yet available)</span>
            )
          }
          {
            tempGradeCategory && (
              <div>
                Category: {tempGradeCategory.name}
              </div>
            )
          }
          <div className='text-muted font-italic d-flex'>
            <div className='mr-1'>
              Available
            </div>
            <div>
              <div>
                From: {moment(audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
              </div>
              <div>
                Until: {moment(audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
              </div>
            </div>
          </div>
          {
            tempMaterialAsyncSchedule && (
              <div className='text-muted font-italic d-flex'>
                <div className='mr-1'>
                  {`${material.type === 'exam' ? 'Asynchronous/exam schedule:' : 'Asynchronous schedule:'}`}
                </div>
                <div>
                  <div>
                    {moment(tempMaterialAsyncSchedule.schedule_date).format('ddd, MMMM D, YYYY')}
                  </div>
                  <div>
                    {`${moment(tempMaterialAsyncSchedule.start_time, 'HH:mm').format('hh:mm A')} - ${moment(tempMaterialAsyncSchedule.end_time, 'HH:mm').format('hh:mm A')}`}
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
  renderContent = () => {
    const { isNextPageLoading, nextPageError } = this.state;
    const { data } = this.props;

    if (data.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='material-list'>
          {
            data.data.map(d => (
              <div key={d.id}>
                <div className='d-flex'>
                  <div className='flex-fill'>
                    <div className='btn-link font-weight-bold' onClick={e => this.showViewAssignment(d)}>
                      {d.title}
                    </div>
                    {
                      d.files.length > 0 && (
                        <div className='text-muted font-italic'><FontAwesomeIcon icon='paperclip' /> {`${d.files.length} attachment(s)`}</div>
                      )
                    }
                    <RichTextEditor.Viewer body={d.description} className='text-muted' />
                    {/* <div className='text-muted'>{d.description}</div> */}
                  </div>
                  <div className='d-flex d-md-none'>
                    <div className='mr-2'>
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            <div>
                              {moment(d.created_at).format('MMM D, YYYY hh:mm A')}
                            </div>
                            <div>
                              {moment(d.created_at).fromNow()}
                            </div>
                          </Tooltip>
                        }
                        trigger={['hover', 'focus']}>
                        <FontAwesomeIcon icon='clock' className='text-muted' />
                      </OverlayTrigger>
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle as='span' className='text-green'>
                        <FontAwesomeIcon icon='cog' />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={e => this.showCreateUpdateModal(e, d)}>
                          <FontAwesomeIcon icon='pencil-alt' /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => this.showDeleteModal(d)}>
                          <FontAwesomeIcon icon='trash-alt' /> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className='font-small text-muted font-italic pr-3 d-none d-md-block'>
                    &mdash; <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {moment(d.created_at).format('MMM D, YYYY hh:mm A')}
                                </Tooltip>
                              }
                              trigger={['hover', 'focus']}>
                              <FontAwesomeIcon icon='clock' />
                            </OverlayTrigger> {moment(d.created_at).fromNow()}
                  </div>
                  <div className='d-none d-md-block' style={{ whiteSpace: 'nowrap' }}>
                    <Button variant='info' size='sm' className='mr-1' onClick={e => this.showCreateUpdateModal(e, d)}>
                      <FontAwesomeIcon icon='pencil-alt' />
                    </Button>
                    <Button variant='danger' size='sm' onClick={() => this.showDeleteModal(d)}>
                      <FontAwesomeIcon icon='trash-alt' />
                    </Button>
                  </div>
                </div>
                <div className='text-muted d-md-flex mt-1'>
                  <div className='mr-1'>
                    <FontAwesomeIcon icon='users' /> Audience(s):
                  </div>
                  <div>
                    {
                      d.audiences.map(audience => this.renderAudiencesDisplay(audience, d))
                    }
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        {
          data.next_page_url && (
            <div className='text-center mt-3'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                View more assignments
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          )
        }
      </>
    );
  }
  render() {
    const { isLoading, errorMessage, viewAssignment } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        {
          viewAssignment ? this.renderViewAssignment() : (
            <>
              <div className='text-right mb-3'>
                <Button variant='green' onClick={this.showCreateUpdateModal}>
                  <FontAwesomeIcon icon='plus' /> Create an assignment
                </Button>
              </div>
              {this.renderContent()}
              {this.renderCreateUpdateModal()}
              {this.renderDeleteModal()}
            </>
          )
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Assignments);