import React, { Component } from 'react';
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NameLink from '../../../../common/NameLink/NameLink';
import moment from 'moment';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import durationFormat from '../../../../../util/helpers/durationFormat';
import './style.scss';
import Validator from 'validatorjs';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

const questionRelationTypeMap = {
  trueFalse: 'question_boolean',
  multipleChoice: 'question_multiple_choice',
  ordering: 'question_ordering',
  essay: 'question_essay',
  matching: 'question_matching',
  identification: 'question_identification',
  enumeration: 'question_enumeration'
};

export default class Submissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      submissions: [],
      questions: [],
      student_count: 0,
      materialPoints: 0,
      gradeModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        grade: '',
        submissionData: null
      }
    };
  }
  componentDidMount() {
    const { materialData, match: { params: { classID } } } = this.props;

    axiosRequest('get', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/submission`, null, ({ data: { data }}) => {
      let materialPoints = 0;
      for (let i = 0; i < data.questions.length; i++) {
        materialPoints += data.questions[i].points;
        data.questions[i].question = data.questions[i][questionRelationTypeMap[data.questions[i].type]];
      }
      let latestUpdate = null;
      let pointsOverridden = false;
      let answerPointsOverride = 0;
      for (let i = 0; i < data.submissions.length; i++) {
        latestUpdate = data.submissions[i].updated_at;
        pointsOverridden = false;
        answerPointsOverride = 0;
        for (let j = 0; j < data.submissions[i].question_answers.length; j++) {
          latestUpdate = data.submissions[i].question_answers[j].updated_at;
          if (data.submissions[i].question_answers[j].points_override) {
            answerPointsOverride += data.submissions[i].question_answers[j].points_override.points;
            pointsOverridden = true;
          } else {
            answerPointsOverride += data.submissions[i].question_answers[j].points;
          }
        }
        data.submissions[i].latest_update = latestUpdate;
        data.submissions[i].points_overridden = pointsOverridden;
        data.submissions[i].points_with_override = answerPointsOverride;
      }
      this.setState({
        ...this.state,
        submissions: data.submissions,
        questions: data.questions,
        student_count: data.student_count,
        materialPoints,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showGradeModal = submission => {
    const { materialData } = this.props;

    const gradeData = materialData.grades.find(grade => grade.student_id === submission.student_id);
    const grade = gradeData ? gradeData.grade : '';

    this.setState({
      ...this.state,
      gradeModal: {
        ...this.state.gradeModal,
        show: true,
        grade,
        submissionData: submission
      }
    });
  }
  hideGradeModal = () => {
    this.setState({
      ...this.state,
      gradeModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        submissionData: null,
        grade: ''
      }
    });
  }
  handleGrade = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      gradeModal: {
        ...this.state.gradeModal,
        isLoading: true,
        errorMessage: false
      }
    }, () => {
      const { gradeModal, materialPoints } = this.state;
      const { materialData, match: { params: { classID } } } = this.props;

      let validator = new Validator({
        grade: gradeModal.grade
      }, {
        grade: `required|numeric|min:0|max:${materialPoints}`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          gradeModal: {
            ...this.state.gradeModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', `faculty/class/${classID}/material/${materialData.type}/${materialData.id}/grade/${gradeModal.submissionData.student_id}`, {
        grade: gradeModal.grade
      }, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          gradeModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            submissionData: null,
            grade: ''
          }
        }, () => this.props.onGrade(data));
      }, (error) => {
        this.setState({
          ...this.state,
          gradeModal: {
            ...this.state.gradeModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleGradeInputChange = event => {
    this.setState({
      ...this.state,
      gradeModal: {
        ...this.state.gradeModal,
        grade: event.target.value
      }
    });
  }
  renderGraded = submission => {
    const { materialData } = this.props;
    const { materialPoints } = this.state;

    let gradeData = materialData.grades.find((grade) => grade.student_id === submission.student_id);

    if (gradeData) {
      return (
        <div className='text-muted'>
          Recorded grade: {gradeData.grade} / {materialPoints}
          {
            submission.points_with_override !== gradeData.grade && (
              <small className='font-italic ml-2'>(Overridden)</small>
            )
          }
        </div>
      );
    }

    return (
      <div className='text-muted'>
        (No grade record saved)
      </div>
    );
  }
  renderSubmissionRemark = submission => {
    const { materialData } = this.props;

    let start = moment(materialData.faculty_load_id ? materialData.until : materialData.audience.until);
    let end = moment(submission.latest_update);

    if (end.isAfter(start)) {
      let duration = moment.duration(end.diff(start));
      let humanized = duration.humanize();
      // humanized = humanized.split(' ').map(h => '' + h.charAt(0).toUpperCase() + h.slice(1)).join(' ');
      humanized = humanized.charAt(0).toUpperCase() + humanized.slice(1);
      // let detailed = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
      // let detailed = duration.months()+'mo ' + duration.days()+'d ' + duration.hours()+'h ' + duration.minutes()+'m ' + duration.seconds()+'s';
      return (
        <div className='text-danger font-weight-bold'>
          {humanized} late <span className='text-muted font-italic font-weight-normal'>({durationFormat(duration)})</span>
        </div>
      );
    }

    return (
      <div className='text-green font-weight-bold'>
        On time
      </div>
    );
  }
  render() {
    const { isLoading, errorMessage, submissions, gradeModal, materialPoints, student_count, questions } = this.state;
    const { materialData } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (submissions.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      )
    }

    return (
      <>
        <div className='text-right text-primary mb-2 font-weight-bold font-italic px-3'>
          Submissions: {submissions.length}/{student_count}
        </div>
        <div className='submission-list'>
          {
            submissions.map(submission => (
              <div key={submission.id} className='py-3 p-md-3 py-md-0'>
                <div className='d-flex'>
                  <div className='flex-fill'>
                    <div className='d-flex'>
                      <NameLink
                        id={submission.profile.id}
                        name={submission.profile.name}
                        image={submission.profile.image}
                        learningPlatform={submission.profile.student_learning_platform ? submission.profile.student_learning_platform.platform : null}
                        rank={submission.profile.rank} />
                    </div>
                    {
                      materialData.type !== 'exam' && (
                        <>
                          {this.renderSubmissionRemark(submission)}
                          <div className='text-muted'>
                            Attempts: {submission.attempt_count}
                          </div>
                        </>
                      )
                    }
                    <div className='text-muted'>
                      <div>
                        Points: {submission.points_with_override} / {materialPoints}
                        {
                          materialData.type !== 'exam' && (
                            <small className='ml-2 font-italic'>(Based on the latest attempt)</small>
                          )
                        }
                        {
                          submission.points_overridden && (
                            <small className='ml-2 font-italic'>&mdash;Overridden</small>
                          )
                        }
                      </div>
                      <div>
                        Duration: {moment.utc(moment.duration(moment(submission.latest_update).diff(submission.created_at)).asMilliseconds()).format('HH:mm:ss')}
                      </div>
                      {this.renderGraded(submission)}
                    </div>
                  </div>
                  <div>
                    <Button variant='warning' size='sm' title='View submission' className='mr-2' onClick={() => this.props.handleViewSubmission({questions, materialData, materialPoints, submission})}>
                      <FontAwesomeIcon icon='eye' />
                    </Button>
                    <Button variant='green' size='sm' title='Override grade' onClick={() => this.showGradeModal(submission)}>
                      <FontAwesomeIcon icon='pencil-alt' />
                    </Button>
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        <Modal show={gradeModal.show} backdrop='static' onHide={this.hideGradeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{gradeModal.submissionData ? gradeModal.submissionData.profile.name : ''}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleGrade}>
            <Modal.Body>
              {
                gradeModal.errorMessage && (
                  <Alert variant='danger'>
                    {gradeModal.errorMessage}
                  </Alert>
                )
              }
              <div className='h5'>
                {materialData.title}
              </div>
              <Form.Group>
                <Form.Label>Category</Form.Label>
                <Form.Control value={materialData.faculty_load_id ? materialData.grade_category.name : materialData.audience.grade_category.name} disabled />
              </Form.Group>
              <Form.Group>
                <Form.Label>Grade</Form.Label>
                <InputGroup>
                  <Form.Control type='number' onChange={this.handleGradeInputChange} value={gradeModal.grade} min='0' max={materialPoints} />
                  <InputGroup.Append>
                    <InputGroup.Text>/ {materialPoints}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' className='mr-2' onClick={this.hideGradeModal} disabled={gradeModal.isLoading}>
                Cancel
              </Button>
              <Button variant='green' type='submit' disabled={gradeModal.isLoading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}