import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../../common/Header/Header';
import AdminSchoolClasses from '../../units/SchoolClasses/AdminSchoolClasses';
import StaffAttendance from '../../units/StaffAttendance/StaffAttendance';
// import Material from './Material/Material';
// import Questions from '../../common/Questions/View/Questions';
import { Redirect, Route, Switch } from 'react-router-dom';
import Events from '../Events/Events';
import DayQuestion from '../DayQuestion/DayQuestion';

export default class Staff extends Component {
  render() {
    return (
      <>
        <Header />
        <Container>
          <Switch>
            <Route exact path='/:userType' component={AdminSchoolClasses} />
            <Route exact path='/:userType/events' component={Events} />
            <Route exact path='/:userType/question-of-the-day' component={DayQuestion} />
            <Route exact path='/staff/class/:classID' component={StaffAttendance} />
            <Redirect to={{ pathname: '/staff' }} />
          </Switch>
        </Container>
      </>
    );
  }
}