import React, { Component } from 'react';
import { Alert, Button, Form, Modal, Nav, OverlayTrigger, ProgressBar, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import NameLink from '../../../common/NameLink/NameLink';
import Validator from 'validatorjs';
import axios from 'axios';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class TermGrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      terms: [],
      grades: [],
      selectedTerm: '',
      editGrade: false,
      isGradeLoading: false,
      gradeError: '',
      formInputs: [],
      importModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      }
    };

    this.importFile = [];
  }
  componentDidMount() {
    const { classID } = this.props.match.params;

    axiosRequest('get', `faculty/class/${classID}/grade/term`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false,
        selectedTerm: data.terms && data.terms.length > 0 ? data.terms[0].id : ''
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleSelectTerm = selectedTerm => {
    this.setState({
      ...this.state,
      selectedTerm
    });
  }
  handleInputChange = (e, studentID) => {
    const { editGrade } = this.state;

    if (editGrade) {
      let newFormInputs = [...this.state.formInputs].map(inp => {
        if (inp.studentID === studentID) {
          return {
            ...inp,
            grade: e.target.value
          };
        }
        return {...inp};
      });
      this.setState({
        ...this.state,
        formInputs: newFormInputs
      });
    }

    return false;
  }
  handleCancelEditGrade = () => {
    this.setState({
      ...this.state,
      editGrade: false,
      formInputs: [],
      isGradeLoading: false,
      gradeError: ''
    });
  }
  handleEditGrade = () => {
    const { grades, selectedTerm } = this.state;
    const { students } = this.props;

    let formInputs = students.map(st => {
      let grade = grades.find(g => g.student_id === st.id && g.term_id === +selectedTerm);
      grade = grade ? grade.grade : ''
      return {
        studentID: st.id,
        grade
      };
    });

    this.setState({
      ...this.state,
      editGrade: true,
      formInputs
    });
  }
  handleSaveGrade = e => {
    this.setState({
      ...this.state,
      isGradeLoading: true,
      gradeError: ''
    }, () => {
      const { formInputs, selectedTerm } = this.state;
      const { classID } = this.props.match.params;

      let formData = {
        termID: selectedTerm,
        termGrades: formInputs
      };

      let validator = new Validator(formData, {
        termID: 'required|integer',
        termGrades: 'required|array',
        'termGrades.*.studentID': 'required|integer',
        'termGrades.*.grade': 'present'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          isGradeLoading: false,
          gradeError: validator.errors.errors[firstKey][0]
        });
        return;
      }

      axiosRequest('post', `faculty/class/${classID}/grade/term`, formData, ({ data: { data }}) => {
        const { grades } = this.state;

        let newGrades = [...grades];
        if (grades.length > 0) {
          newGrades = [...grades].filter(g => g.term_id !== +selectedTerm);
          newGrades = [
            ...newGrades,
            ...data
          ];
        } else {
          newGrades = data;
        }
        this.setState({
          ...this.state,
          isGradeLoading: false,
          grades: newGrades,
          editGrade: false,
          formInputs: []
        });
      }, error => {
        this.setState({
          ...this.state,
          isGradeLoading: false,
          gradeError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showModal = () => {
    this.setState({
      ...this.state,
      importModal: {
        ...this.state.importModal,
        show: true
      }
    });
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      importModal: {
        ...this.state.importModal,
        show: false
      }
    });
  }
  handleFileSelect = event => {
    this.importFile = event.target.files;
  }
  handleUpload = event => {
    event.preventDefault();

    if (this.importFile.length > 0) {
      this.setState({
        ...this.state,
        importModal: {
          ...this.state.importModal,
          isLoading: true,
          errorMessage: '',
          successMessage: ''
        }
      }, () => {
        const { selectedTerm, grades } = this.state;
        const { match: { params: { classID } }, classInfo } = this.props;

        const formData = new window.FormData();
        formData.append('file', this.importFile[0], this.importFile[0].name);

        let url = (classInfo.class_course.school_class.year_level.school_level.title === 'Senior High School' && selectedTerm) ?
                    `${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/grade/term/import/${selectedTerm}` :
                    `${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/grade/term/import`;
        
        axios.post(url, formData, {
          withCredentials: true,
          header: {
            'content-type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.setState({
              ...this.state,
              importModal: {
                ...this.state.importModal,
                isLoading: percentCompleted
              }
            });
          }
        }).then(({ data: { data, message }}) => {
          let newGrades = [...grades].filter(g => (
            !data.find(d => d.student_id === g.student_id && d.term_id === g.term_id && d.faculty_load_id === g.faculty_load_id)
          ));
          newGrades = [...newGrades, ...data];
          this.setState({
            ...this.state,
            editFacultyLoadID: '',
            formInputs: [],
            grades: newGrades,
            importModal: {
              ...this.state.importModal,
              isLoading: false,
              errorMessage: '',
              successMessage: message
            }
          }, () => {
            this.importFile = [];
          });
        }).catch((error) => {
          if (error.response && error.response.status === 403) {
            this.props.history.push('/login');
          }
          this.setState({
            ...this.state,
            importModal: {
              ...this.state.importModal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        });
      });
    } else {
      this.setState({
        ...this.state,
        importModal: {
          ...this.state.importModal,
          isLoading: false,
          errorMessage: 'The import file is required.'
        }
      });
    }
  }
  renderGrade = studentID => {
    const { editGrade, formInputs, isGradeLoading, grades, selectedTerm } = this.state;

    if (editGrade) {
      let grade = formInputs.find(inp => inp.studentID === studentID);
      grade = grade ? grade.grade : '';
      return (
        <Form.Control type='text' size='sm' className='text-center' value={grade} onChange={e => this.handleInputChange(e, studentID)} disabled={isGradeLoading} />
      );
    }

    let savedGrade = grades.find(g => g.student_id === studentID && g.term_id === +selectedTerm);
    if (savedGrade) {
      return savedGrade.grade;
    }

    return null;
  }
  renderStudents = () => {
    const { students, classInfo } = this.props;

    let male = students.filter(s => s.gender === 'Male');
    let female = students.filter(s => s.gender === 'Female');

    if (classInfo.class_course.school_class.year_level.school_level.students_alphabetical) {
      return (
        <tbody>{
          students.map(student => (
            <tr key={student.id}>
              <th>
                <NameLink tabIndex='-1' id={student.id} name={student.formal_name} image={student.image} />
              </th>
              <th style={{ fontSize: '1.1rem' }} className='text-center align-middle'>
                {this.renderGrade(student.id)}
              </th>
            </tr>
          ))
        }
        </tbody>
      );
    }

    return (
      <tbody>
        {
          male.length > 0 && (
            <>
              <tr className='text-white bg-green'>
                <th className='py-1 text-center'>
                  <div className='mb-0'>Male</div>
                  <small className='font-italic'>{male.length} student{male.length !== 1 ? 's' : ''}</small>
                </th>
                <td></td>
              </tr>
              {
                male.map(student => (
                  <tr key={student.id}>
                    <th>
                      <NameLink tabIndex='-1' id={student.id} name={student.formal_name} image={student.image} />
                    </th>
                    <th style={{ fontSize: '1.1rem' }} className='text-center align-middle'>
                      {this.renderGrade(student.id)}
                    </th>
                  </tr>
                ))
              }
            </>
          )
        }
        {
          female.length > 0 && (
            <>
              <tr className='text-white bg-green'>
                <th className='py-1 text-center'>
                  <div className='mb-0'>Female</div>
                  <small className='font-italic'>{female.length} student{female.length !== 1 ? 's' : ''}</small>
                </th>
                <td></td>
              </tr>
              {
                female.map(student => (
                  <tr key={student.id}>
                    <th>
                      <NameLink tabIndex='-1' id={student.id} name={student.formal_name} image={student.image} />
                    </th>
                    <th style={{ fontSize: '1.1rem' }} className='text-center align-middle'>
                      {this.renderGrade(student.id)}
                    </th>
                  </tr>
                ))
              }
            </>
          )
        }
      </tbody>
    );
  }
  render() {
    const { isLoading, errorMessage, terms, selectedTerm, editGrade, isGradeLoading, gradeError, importModal } = this.state;
    const { match: { params: { classID } }, classInfo } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (terms.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        {
          classInfo.class_course.school_class.year_level.school_level.title !== 'Senior High School' && (
            <>
              <div className='text-right mb-2'>
                <Button variant='green' className='mr-2' onClick={this.showModal}>
                  <FontAwesomeIcon icon='file-import' /> <span className='d-none d-md-inline-block'>Import grades</span>
                </Button>
                <a href={`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/grade/term/export`} className='btn btn-green'>
                  <FontAwesomeIcon icon='file-export' /> <span className='d-none d-md-inline-block'>Export grades</span>
                </a>
              </div>
              <div className='dropdown-divider'></div>
            </>
          )
        }
        <Modal show={importModal.show} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Term Grade</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleUpload}>
            <Modal.Body>
              {
                importModal.errorMessage ? (
                  <Alert variant='danger'>
                    {importModal.errorMessage}
                  </Alert>
                ) : importModal.successMessage ? (
                  <Alert variant='success'>
                    {importModal.successMessage}
                  </Alert>
                ) : null
              }
              <Form.Group>
                <Form.File label='Import term grade' accept='.xlsx, .xls' onChange={this.handleFileSelect} disabled={importModal.isLoading || importModal.successMessage} />
              </Form.Group>
              {
                importModal.isLoading && (
                  <div className='mb-3'>
                    <ProgressBar now={importModal.isLoading} label='Uploading...' />
                  </div>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant='light' onClick={this.hideModal}>
                Close
              </Button>
              {
                !importModal.successMessage && (
                  <Button type='submit' variant='green' disabled={importModal.isLoading}>
                    Upload
                  </Button>
                )
              }
            </Modal.Footer>
          </Form>
        </Modal>
        <div className='mb-3'>
          <Nav fill variant='pills' activeKey={selectedTerm} className='m-0' onSelect={this.handleSelectTerm}>
            {
              terms.map(term => (
                <Nav.Item>
                  <Nav.Link eventKey={term.id} disabled={editGrade}>{term.title}</Nav.Link>
                </Nav.Item>
              ))
            }
          </Nav>
        </div>
        {/* {
          classInfo.class_course.school_class.year_level.school_level.title === 'Senior High School' && (
            <>
              <div className='dropdown-divider'></div>
              <div className='text-right mb-2'>
                <Button variant='green' className='mr-2' onClick={this.showModal}>
                  <FontAwesomeIcon icon='file-import' /> <span className='d-none d-md-inline-block'>Import grades</span>
                </Button>
                <a href={`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/grade/term/export/${selectedTerm}`} className='btn btn-green'>
                  <FontAwesomeIcon icon='file-export' /> <span className='d-none d-md-inline-block'>Export grades</span>
                </a>
              </div>
            </>
          )
        } */}
        <Table responsive hover bordered>
          <thead>
            <tr>
              <th className='w-75'>Name</th>
              <th>
                <div className='text-center'>
                  Grade
                </div>
                {
                  editGrade ? (
                    <>
                      <div className='d-flex mt-2'>
                        <div className='pr-1 flex-fill'>
                          {
                            isGradeLoading ? (
                              <Button variant='green' size='sm' block disabled>
                                <LoadingIcon />
                              </Button>
                            ) : gradeError ? (
                              <OverlayTrigger
                                overlay={
                                  <Tooltip>
                                    {gradeError}
                                  </Tooltip>
                                }
                                trigger={['hover', 'focus']}>
                                <Button variant='green' size='sm' block onClick={this.handleSaveGrade} className='course-column-error'>
                                  <FontAwesomeIcon icon='exclamation' /> <span className='refresh-icon'><FontAwesomeIcon icon='redo' /></span>
                                </Button>
                              </OverlayTrigger>
                            ) : (
                              <Button variant='green' size='sm' block title='Save' onClick={this.handleSaveGrade}>
                                <FontAwesomeIcon icon='check' />
                              </Button>
                            )
                          }
                        </div>
                        <div className='pl-1 flex-fill'>
                          <Button variant='danger' size='sm' block title='Cancel' onClick={this.handleCancelEditGrade} disabled={isGradeLoading}>
                            <FontAwesomeIcon icon='times' />
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : classInfo.class_course.school_class.year_level.school_level.title !== 'Senior High School' ? (
                    <div className='mt-2'>
                      <Button variant='info' size='sm' block title='Edit grade' onClick={this.handleEditGrade} disabled={editGrade || importModal.isLoading}>
                        <FontAwesomeIcon icon='pencil-alt' />
                      </Button>
                    </div>
                  ) : (
                    <div></div>
                  )
                }
              </th>
            </tr>
          </thead>
          { this.renderStudents() }
        </Table>
      </>
    );
  }
}