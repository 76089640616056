import React, { Component } from 'react';
import { Alert, Button, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import moment from 'moment';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      attendance: {
        data: []
      },
      isNextPageLoading: false,
      nextPageError: '',
      activityLog: [],
      activityModal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        date: '',
        logs: []
      }
    };
  }
  componentDidMount() {
    const { userType, classID } = this.props.match.params;

    axiosRequest('get', `${userType}/class/${classID}/attendance`, null, ({ data: { data }}) => {
        this.setState({
        ...this.state,
        attendance: data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { attendance } = this.state;

      const path = attendance.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          attendance: {
            ...data,
            data: [
              ...this.state.attendance.data,
              ...data.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showActivityModal = date => {
    const { activityLog } = this.state;

    let logs = [];
    if (activityLog[date]) {
      logs = activityLog[date]
    }
    
    this.setState({
      ...this.state,
      activityModal: {
        show: true,
        isLoading: true,
        errorMessage: '',
        date,
        logs
      }
    }, () => {
      if (activityLog[date]) {
        this.setState({
          ...this.state,
          activityModal: {
            ...this.state.activityModal,
            isLoading: false,
            errorMessage: ''
          }
        });
      } else {
        this.getActivityLog(date);
      }
    });
  }
  getActivityLog = date => {
    const { userType, classID } = this.props.match.params;

    axiosRequest('post', `${userType}/class/${classID}/attendance/activity`, {
      date
    }, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        activityModal: {
          ...this.state.activityModal,
          isLoading: false,
          errorMessage: '',
          logs: data
        },
        activityLog: {
          ...this.state.activityLog,
          [date]: data
        }
      });
    }, error => {
      this.setState({
        ...this.state,
        activityModal: {
          ...this.state.activityModal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  hideActivityModal = () => {
    this.setState({
      ...this.state,
      activityModal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        date: '',
        logs: []
      }
    });
  }
  renderActivityModalBody = () => {
    const { activityModal } = this.state;

    if (!activityModal.date) {
      return null;
    }

    if (activityModal.isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (activityModal.errorMessage) {
      return (
        <Alert variant='danger'>
          {activityModal.errorMessage}
        </Alert>
      );
    }

    if (!activityModal.logs || activityModal.logs.length === 0) {
      return (
        <Alert variant='light'>
          No activities logged for this day.
        </Alert>
      );
    }

    return (
      <ul>
        {
          activityModal.logs.map(log => (
            <li key={log.id}>{log.description} <small className='font-italic' style={{ color: '#6c757d' }}>&mdash;{moment(log.created_at).format('hh:mm A')}</small></li>
          ))
        }
      </ul>
    );
  }
  render() {
    const { isLoading, errorMessage, attendance, isNextPageLoading, nextPageError, activityModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (attendance.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }
    
    return (
      <div>
        <Table hover responsive>
          <thead>
            <tr>
              <th>
                <div className='d-block d-md-none' title='Date'><FontAwesomeIcon icon='calendar-day' /></div>
                <div className='d-none d-md-block'>Date</div>
              </th>
              <th>
                <div className='d-block d-md-none' title='Mark'><FontAwesomeIcon icon='tag' /></div>
                <div className='d-none d-md-block'>Mark</div>
              </th>
              <th>
                <div className='d-block d-md-none' title='Comment'><FontAwesomeIcon icon='comment' /></div>
                <div className='d-none d-md-block'>Comment</div>
              </th>
              <th>
                <div className='d-block d-md-none' title='Activity'><FontAwesomeIcon icon='clipboard-list' /></div>
                <div className='d-none d-md-block'>Activity</div>
              </th>
            </tr>
          </thead>
          <tbody>
          {
            attendance.data.map(att => (
              <tr>
                <td>{moment(att.attendance_date).format('MMMM D, YYYY, ddd')}</td>
                <th>
                  {
                    att.mark === 'Present' ? (
                      <div className='text-green'>{att.mark}</div>
                    ) : att.mark === 'Absent' ? (
                      <div className='text-danger'>{att.mark}</div>
                    ) : att.mark
                  }
                </th>
                <td>{att.comment}</td>
                <td className='text-center'>
                  <Button variant='light' size='sm' title='View log' onClick={() => this.showActivityModal(att.attendance_date)}>
                    <FontAwesomeIcon icon={['fas', 'th-list']} />
                    <span className='ml-2 d-none d-md-inline-block'>View log</span>
                  </Button>
                </td>
              </tr>
            ))
          }
          </tbody>
        </Table>
        {
          attendance.next_page_url && (
            <div className='text-center mt-3'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                Show more...
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          )
        }

        <Modal show={activityModal.show} onHide={this.hideActivityModal}>
          <Modal.Header closeButton>
            <Modal.Title>{activityModal.date ? moment(activityModal.date).format('MMMM D, YYYY') : ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderActivityModalBody()}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={this.hideActivityModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}