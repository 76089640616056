import React, { Component } from 'react';
import { Alert, Card, Col, Container, Form, Nav, Row } from 'react-bootstrap';
import Header from '../../../common/Header/Header';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import './style.scss';
import Grades from './parts/Grades';
import Attendance from './parts/Attendance';
import Performance from './parts/Performance';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class ClassAdvisory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      schoolClasses: [],
      selectedClass: '',
      classData: null,
      isClassLoading: false,
      classError: '',
      activeNav: 'performance'
    };
  }
  componentDidMount() {
    axiosRequest('get', 'faculty/class-advisory', null, ({ data: { data } }) => {
      this.setState({
        ...this.state,
        schoolClasses: data,
        isLoading: false
      }, () => {
        if (data.length === 1) {
          this.fetchClassData(data[0].id);
        }
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  fetchClassData = schoolClassID => {
    const { schoolClasses } = this.state;

    this.setState({
      ...this.state,
      isClassLoading: true,
      classError: '',
      selectedClass: schoolClassID
    }, () => {
      axiosRequest('get', `faculty/class-advisory/${schoolClassID}`, null, ({ data: { data }}) => {
        let schoolClass = schoolClasses.find(sc => sc.id === +schoolClassID);
        data = {
          ...schoolClass,
          ...data
        };
        this.setState({
          ...this.state,
          isClassLoading: false,
          classData: data
        });
      }, error => {
        this.setState({
          ...this.state,
          isClassLoading: false,
          classError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleClassSelect = e => {
    let selectedClass = e.target.value;
    this.setState({
      ...this.state,
      selectedClass,
      activeNav: 'performance'
    }, () => {
      this.fetchClassData(selectedClass);
    });
  }
  handleNavSelect = activeNav => {
    this.setState({
      ...this.state,
      activeNav
    });
  }
  renderAdvisoryContent = () => {
    const { activeNav, classData } = this.state;

    switch(activeNav) {
      case 'performance':
        return <Performance {...this.props} schoolClass={classData} />;
      case 'grades':
        return <Grades {...this.props} schoolClass={classData} />;
      case 'attendance':
        return <Attendance {...this.props} schoolClass={classData} />;
      default:
        return null;
    }
  }
  renderSelectedClass = () => {
    const { schoolClasses, isClassLoading, classError, selectedClass, activeNav, classData } = this.state;

    if (!selectedClass) {
      return null;
    }

    let schoolClass = schoolClasses.find(sc => sc.id === +selectedClass);

    if (!schoolClass) {
      return null;
    }

    return (
      <>
        <div className='h5'>
          <div>
            {schoolClass.program.description} ({schoolClass.program.code})
          </div>
          <div>
            {schoolClass.year_level.description} ({schoolClass.year_level.code})
          </div>
          <div>
            Section {schoolClass.section.code}
          </div>
        </div>
        {
          isClassLoading ? (
            <LoadingIcon />
          ) : classError ? (
            <Alert variant='danger'>
              {classError}
            </Alert>
          ) : classData ? (
            <div className='class-advisory mt-3'>
              <Row noGutters>
                <Col lg={2} className='class-advisory-sidebar'>
                  <Nav variant='tabs bg-light flex-column'>
                    <Nav.Item>
                      <Nav.Link
                        eventKey='performance'
                        onSelect={this.handleNavSelect}
                        active={activeNav === 'performance'}>
                          Performance
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey='grades'
                        onSelect={this.handleNavSelect}
                        active={activeNav === 'grades'}>
                          Grades
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey='attendance'
                        onSelect={this.handleNavSelect}
                        active={activeNav === 'attendance'}>
                          Attendance
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg={10} className='p-3 class-advisory-content'>
                  {this.renderAdvisoryContent()}
                </Col>
              </Row>
            </div>
          ) : null
        }
      </>
    )
  }
  renderContent = () => {
    const { isLoading, errorMessage, schoolClasses, selectedClass } = this.state;

    if (isLoading) {
      return (
        <Card className='my-3'>
          <Card.Body>
            <LoadingIcon lg />
          </Card.Body>
        </Card>
      );
    }

    if (errorMessage) {
      return (
        <Card className='my-3'>
          <Card.Body>
            <Alert variant='danger'>
              {errorMessage}
            </Alert>
          </Card.Body>
        </Card>
      );
    }

    if (schoolClasses.length === 0) {
      return (
        <Card className='my-3'>
          <Card.Body>
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          </Card.Body>
        </Card>
      );
    }

    return (
      <Card className='my-3'>
        <Card.Body>
          {
            schoolClasses.length > 1 && (
              <>
                <Form.Control as='select' value={selectedClass} onChange={this.handleClassSelect}>
                  <option value='' hidden>Select class...</option>
                  {
                    schoolClasses.map(schoolClass => (
                      <option key={schoolClass.id} value={schoolClass.id}>
                        {`${schoolClass.program.code} ${schoolClass.year_level.code} - ${schoolClass.section.code}`}
                      </option>
                    ))
                  }
                </Form.Control>
                <div className='dropdown-divider'></div>
              </>
            )
          }
          {this.renderSelectedClass()}
        </Card.Body>
      </Card>
    )
  }
  render() {
    return (
      <>
        <Header active='Advisory' />
        <Container>
          {this.renderContent()}
        </Container>
      </>
    );
  }
}