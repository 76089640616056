import React, { Component } from 'react';
import { Alert, Button, Col, Collapse, Form, Modal, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Validator from 'validatorjs';
import { loadRule } from '../../../../util';
import axios from 'axios';
import './style.scss';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../common/RichTextEditor/RichTextEditor';

export default class File extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      formInputs: {
        title: '',
        description: '',
        availability: '',
        from: '',
        until: ''
      },
      fileNames: [],
      loadingFiles: false,
      isFormLoading: false,
      formError: '',
      formSuccess: '',
      showAttachments: false,
      deleteFiles: [],
      loadingDeleteFiles: []
    };
    
    this.files = [];
  }
  componentDidMount() {
    const { materialData } = this.props;

    if (materialData) {
      let availability = materialData.from && materialData.until ? 'fromUntil' :
                          materialData.from ? 'from' :
                          materialData.until ? 'until' : 'always';
      this.setState({
        ...this.state,
        formInputs: {
          title: materialData.title,
          description: materialData.description,
          availability,
          from: materialData.from ? moment(materialData.from).format('YYYY-MM-DDTHH:mm') : '',
          until: materialData.until ? moment(materialData.until).format('YYYY-MM-DDTHH:mm') : ''
        },
        isLoading: false
      });
    } else {
      this.setState({
        ...this.state,
        isLoading: false
      });
    }

    loadRule(['date', 'after_or_equal'], {
      after_or_equal: 'The :attribute date must be equal or after the current date and time.'
    });
  }
  handleCancelDeleteFile = id => {
    let newDeleteFiles = [...this.state.deleteFiles].filter(fileID => fileID !== id);
    this.setState({
      ...this.state,
      deleteFiles: newDeleteFiles
    });
  }
  handleDeleteFile = id => {
    this.setState({
      ...this.state,
      deleteFiles: [...this.state.deleteFiles, id]
    });
  }
  handleShowAttachments = () => {
    this.setState({
      ...this.state,
      showAttachments: !this.state.showAttachments
    });
  }
  handleFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.files = [...this.files, ...files];
      this.setState({
        ...this.state,
        fileNames: [
          ...this.state.fileNames,
          ...filesState
        ]
      });
    }
  }
  handleRemoveUpload = id => {
    let newFileNames = [...this.state.fileNames];
    newFileNames.splice(id, 1);

    this.setState({
      ...this.state,
      fileNames: newFileNames
    }, () => {
      this.files.splice(id, 1);
    });
  }
  handleInputChange = event => {
    let custom = {};
    if (event.target.name === 'availability') {
      custom = {
        from: moment().format('YYYY-MM-DDTHH:mm'),
        until: ''
      };
    }
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        ...custom,
        [event.target.name]: event.target.value
      }
    });
  }
  handleDescriptionInputChange = description => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        description
      }
    });
  }
  handleCreate = () => {
    const { formInputs } = this.state;
    const { classID } = this.props.match.params;
    
    if (this.files.length === 0) {
      this.setState({
        ...this.state,
        formError: 'The files are required',
        isFormLoading: false,
        loadingFiles: false,
        loadingDeleteFiles: []
      });
      return;
    }

    const formData = new window.FormData();
    formData.append('file', this.files[0], this.files[0].name);
    formData.append('title', formInputs.title);
    formData.append('description', formInputs.description);
    formData.append('availability', formInputs.availability);
    formData.append('from', formInputs.from);
    formData.append('until', formInputs.until);

    axiosRequest('post', `faculty/class/${classID}/material/file`, formData, ({ data: { data, message }}) => {
      const { fileNames } = this.state;
      fileNames[0].isLoading = false;
      fileNames[0].isSuccess = true;
      this.setState({
        ...this.state,
        fileNames
      }, () => {
        if (this.files.length > 1) {
          let fileRequests = [];
          for (let i = 1; i < this.files.length; i++) {
            fileRequests.push(this.uploadFile(i, classID, data.id));
          }
          Promise.all(fileRequests).then(result => {
            data.files = [...data.files, ...result];
            this.setState({
              ...this.state,
              formSuccess: message
            }, () => {
              this.props.onSave(data);
            });
          }).catch((error) => {
            this.setState({
              ...this.state,
              formError: error,
              isFormLoading: false,
              loadingFiles: false
            });
          });
        } else {
          this.setState({
            ...this.state,
            formSuccess: message
          }, () => {
            this.props.onSave(data);
          });
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        isFormLoading: false,
        loadingFiles: false
      });
    }, this.props.history);
  }
  handleUpdate = () => {
    const { formInputs, deleteFiles } = this.state;

    const { classID } = this.props.match.params;
    axiosRequest('patch', `faculty/class/${classID}/material/file/${this.props.materialData.id}`, formInputs, ({ data: { data, message }}) => {
      let uploadRequests = [];
      let deleteRequests = [];

      if (this.files.length > 0) {
        for (let i = 0; i < this.files.length; i++) {
          uploadRequests.push(this.uploadFile(i, classID, this.props.materialData.id));
        }
      }

      if (deleteFiles.length > 0) {
        for (let i = 0; i < deleteFiles.length; i++) {
          deleteRequests.push(this.deleteFile(deleteFiles[i], classID, this.props.materialData.id));
        }
      }

      if (uploadRequests.length && deleteRequests.length) {
        Promise.all([Promise.all(uploadRequests), Promise.all(deleteRequests)]).then(result => {
          data.files = [...result[0], ...this.props.materialData.files];
          data.files = data.files.filter(file => result[1].indexOf(file.id) === -1);
          this.setState({
            ...this.state,
            formSuccess: message
          }, () => {
            this.props.onUpdate(data);
          });
        }).catch((error) => {
          this.setState({
            ...this.state,
            formError: error,
            isFormLoading: false
          });
        });
      } else if (uploadRequests.length) {
        Promise.all(uploadRequests).then(result => {
          data.files = [...result, ...this.props.materialData.files];
          this.setState({
            ...this.state,
            formSuccess: message
          }, () => {
            this.props.onUpdate(data);
          });
        }).catch((error) => {
          this.setState({
            ...this.state,
            formError: error,
            isFormLoading: false
          });
        });
      } else if (deleteRequests.length) {
        Promise.all(deleteRequests).then(result => {
          data.files = [...this.props.materialData.files];
          data.files = data.files.filter(file => result.indexOf(file.id) === -1);
          this.setState({
            ...this.state,
            formSuccess: message
          }, () => {
            this.props.onUpdate(data);
          });
        });
      } else {
        this.setState({
          ...this.state,
          formSuccess: message
        }, () => {
          this.props.onUpdate(data);
        });
      }
    }, (error) => {
      this.setState({
        ...this.state,
        formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        isFormLoading: false
      });
    }, this.props.history);
  }
  handleSave = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      ...this.state,
      isFormLoading: true,
      formError: '',
      loadingFiles: true,
      loadingDeleteFiles: [...this.state.deleteFiles]
    }, () => {
      const { formInputs, fileNames } = this.state;

      let validator = new Validator(formInputs, {
        title: 'required|min:3',
        description: 'required',
        availability: 'required|in:always,from,fromUntil,until',
        from: `required_if:availability,from|required_if:availability,fromUntil`,
        until: 'required_if:availability,fromUntil|required_if:availability,until'
      }, {
        required_if: 'The :attribute field is required.'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          formError: validator.errors.errors[firstKey][0],
          isFormLoading: false,
          loadingFiles: false,
          loadingDeleteFiles: []
        });
        return;
      }

      let rules = null;

      switch (formInputs.availability) {
        case 'from':
          rules = {
            from: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`
          };
          break;
        case 'fromUntil':
          rules = {
            from: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`,
            until: `required|date|after_or_equal:from`
          };
          break;
        case 'until':
          rules = {
            until: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`
          };
          break;
        default:
          break;
      }

      if (rules !== null) {
        validator = new Validator(formInputs, rules);

        if (validator.fails()) {
          const firstKey = Object.keys(validator.errors.errors)[0];
          this.setState({
            ...this.state,
            formError: validator.errors.errors[firstKey][0],
            isFormLoading: false,
            loadingFiles: false,
            loadingDeleteFiles: []
          });
          return;
        }
      }

      let filesLength = fileNames.length;

      if (this.props.materialData) {
        filesLength += this.props.materialData.files.length;
        filesLength = filesLength - this.state.deleteFiles.length;
      }

      if (filesLength <= 0) {
        this.setState({
          ...this.state,
          formError: 'The files are required.',
          isFormLoading: false,
          loadingFiles: false,
          loadingDeleteFiles: []
        });
        return;
      }

      if (this.props.materialData) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    });
  }
  uploadFile = (i, classID, materialID) => {
    const formData = new window.FormData();
    formData.append('file', this.files[i], this.files[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/file/${materialID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { fileNames } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          fileNames
        });
      }
    }).then(({ data: { data }}) => {
      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        fileNames
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        fileNames
      });
      throw fileNames[i].isError;
    });
  }
  deleteFile = (id, classID, materialID) => {
    return axios.delete(`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/file/${materialID}/file/${id}`, {
      withCredentials: true,
    }).then(({ data: { data }}) => {
      let newLoadingDeleteFiles = [...this.state.loadingDeleteFiles].filter(fileID => fileID !== id);
      this.setState({
        ...this.state,
        loadingDeleteFiles: newLoadingDeleteFiles
      });
      return id;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }
    });
  }
  renderAvailability = () => {
    const { formInputs: { availability, from, until }, isFormLoading } = this.state;

    switch(availability) {
      case 'from':
        return (
          <Form.Group>
            <Form.Label>From</Form.Label>
            <Form.Control type='datetime-local' name='from' value={from} min={moment().format('YYYY-MM-DDTHH:mm')} onChange={this.handleInputChange} disabled={isFormLoading} />
          </Form.Group>
        );
      case 'fromUntil':
        return (
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>From</Form.Label>
              <Form.Control type='datetime-local' name='from' value={from} min={moment().format('YYYY-MM-DDTHH:mm')} max={until} onChange={this.handleInputChange} disabled={isFormLoading} />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>Until</Form.Label>
              <Form.Control type='datetime-local' name='until' value={until} min={from} onChange={this.handleInputChange} disabled={isFormLoading} />
            </Form.Group>
          </Form.Row>
        );
      case 'until':
        return (
          <Form.Group>
            <Form.Label>Until</Form.Label>
            <Form.Control type='datetime-local' name='until' value={until} min={from} onChange={this.handleInputChange} disabled={isFormLoading} />
          </Form.Group>
        );
      default:
        return null;
    }
  }
  renderFileUpload = () => {
    const { fileNames, loadingFiles } = this.state;
    if (fileNames.length > 0) {
      return (
        <Form.Group>
          <Form.Label>File(s) to be uploaded</Form.Label>
          <small className='file-upload-display d-block rounded-top'>
          {
            fileNames.map((fileName, index) => (
              <div key={index} className='file-name d-flex'>
                <div>
                  {
                    fileName.isLoading || (loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                      <LoadingIcon />
                    ) : fileName.isError ? (
                      <FontAwesomeIcon icon='times-circle' className='text-danger' />
                    ) : fileName.isSuccess ? (
                      <FontAwesomeIcon icon='check-circle' className='text-green' />
                    ) : (
                      <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                    )
                  }
                </div>
                <div className='ml-2 flex-fill'>
                  <div>
                    {fileName.name}
                  </div>
                  {
                    fileName.isLoading && (
                      <div className='mt-1'>
                        <ProgressBar now={fileName.isLoading} />
                      </div>
                    )
                  }
                </div>
                {
                  (!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !loadingFiles) && (
                    <div className='ml-2 align-items-center d-flex'>
                      <Button
                        variant='link'
                        className='text-danger'
                        size='sm'
                        title='Remove'
                        onClick={(e) => this.handleRemoveUpload(index)}>
                        <FontAwesomeIcon icon='times' size='sm' />
                      </Button>
                    </div>
                  )
                }
              </div>
            ))
          }
          </small>
        </Form.Group>
      );
    }
  }
  renderAttachedFiles = () => {
    const { showAttachments, deleteFiles, loadingDeleteFiles, isFormLoading } = this.state;
    const { materialData } = this.props;

    if (materialData && materialData.files.length > 0) {
      return (
        <>
          <div className={`text-right border rounded-top ${showAttachments ? 'border-bottom-0' : 'rounded-bottom'}`}>
            <Button variant='link' onClick={this.handleShowAttachments} block>
              <FontAwesomeIcon icon='paperclip' /> Show {materialData.files.length} attachment(s)
            </Button>
          </div>
          <Collapse in={showAttachments}>
            <div className='file-upload-display small'>
            {
              materialData.files.map(file => (
                <div className={`file-name d-flex ${deleteFiles.indexOf(file.id) !== -1 ? 'alert-light' : ''}`}>
                  {
                    loadingDeleteFiles.indexOf(file.id) !== -1 && (
                      <LoadingIcon />
                    )
                  }
                  <div className='ml-2 flex-fill'>
                    <div>
                      {file.file_title}
                    </div>
                    {
                      deleteFiles.indexOf(file.id) !== -1 && (
                        <div className='font-italic'>
                          To be deleted
                        </div>
                      )
                    }
                  </div>
                  <div className='ml-2 align-items-center d-flex'>
                    {
                      deleteFiles.indexOf(file.id) !== -1 ? (
                        <Button
                          variant='link'
                          className='text-green'
                          size='sm'
                          title='Cancel delete'
                          onClick={() => this.handleCancelDeleteFile(file.id)}
                          disabled={isFormLoading}>
                          <FontAwesomeIcon icon='ban' size='sm' />
                        </Button>
                      ) : (
                        <Button
                          variant='link'
                          className='text-danger'
                          size='sm'
                          title='Delete'
                          onClick={() => this.handleDeleteFile(file.id)}
                          disabled={isFormLoading}>
                          <FontAwesomeIcon icon='trash-alt' size='sm' />
                        </Button>
                      )
                    }
                  </div>
                </div>
              ))
            }
            </div>
          </Collapse>
        </>
      );
    }
  }
  render() {
    const { isLoading, errorMessage, formInputs, isFormLoading, formError, formSuccess, fileNames } = this.state;
    const { materialData } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon className='m-3' />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger' className='m-3'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>
            {
              materialData ? 'Update file' : 'Upload file'
            }
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleSave} className='file-form'>
          <Modal.Body>
            {
              formError ? (
                <Alert variant='danger'>
                  {formError}
                </Alert>
              ) : formSuccess ? (
                <Alert variant='success'>
                  {formSuccess}
                </Alert>
              ) : null
            }
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type='text' name='title' value={formInputs.title} onChange={this.handleInputChange} disabled={isFormLoading} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <RichTextEditor.Editor
                value={formInputs.description}
                onChange={this.handleDescriptionInputChange}
                readOnly={isFormLoading} />
              {/* <Form.Control as='textarea' rows={4} style={{ resize: 'none' }} name='description' value={formInputs.description} onChange={this.handleInputChange} disabled={isFormLoading} /> */}
            </Form.Group>
            <Form.Group>
              <Form.Label>Availability</Form.Label>
              <Form.Control
               as='select'
               name='availability'
               value={formInputs.availability}
               onChange={this.handleInputChange}
               disabled={isFormLoading}>
                 <option disabled hidden value=''>Availability...</option>
                 <option value='always'>Always</option>
                 <option value='from'>From...</option>
                 <option value='fromUntil'>From... Until...</option>
                 <option value='until'>Until...</option>
              </Form.Control>
            </Form.Group>
            { this.renderAvailability() }
            <Form.Group>
              <Form.Label>File</Form.Label>
              <Form.File 
                id='add-file'
                label={fileNames.length > 0 ? (fileNames.length + ` file${fileNames.length > 1 ? 's' : ''} chosen`) : 'No file chosen'}
                custom
                onChange={this.handleFileUpload}
                multiple
                disabled={isFormLoading}
              />
            </Form.Group>
            { this.renderFileUpload() }
            { this.renderAttachedFiles() }
          </Modal.Body>
          <Modal.Footer>
            {
              formSuccess ? (
                <Button variant='light' onClick={this.props.onCancel}>
                  Close
                </Button>
              ) : (
                <>
                  <Button variant='danger' onClick={this.props.onCancel} disabled={isFormLoading}>
                    Cancel
                  </Button>
                  <Button variant='green' type='submit' disabled={isFormLoading}>
                    Save
                  </Button>
                </>
              )
            }
          </Modal.Footer>
        </Form>
      </>
    );
  }
}