import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccountType from './parts/AccountType';
import SchoolClass from './parts/SchoolClass';

const BlockUsers = props => {
  return (
    <Switch>
      <Route exact path='/admin/block-users/account-types' component={AccountType} />
      <Route exact path='/admin/block-users/classes' component={SchoolClass} />
      <Redirect to={{ pathname: '/admin/block-users/account-types'}} />
    </Switch>
  );
};

export default BlockUsers;
