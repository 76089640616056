import React, { Component } from 'react';
import { Alert, Nav } from 'react-bootstrap';
import './style.scss';
import Weekly from './parts/Weekly';
import Date from './parts/Date';
import TimeTable from '../TimeTable/TimeTable';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';


export default class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 'weekly',
      isLoading: true,
      errorMessage : ''
    };
  }
  componentDidMount() {
    this.setState({
      ...this.state,
      isLoading: false
    });
  }
  handleSelect = eventKey => {
    this.setState({
      ...this.state,
      activeNav: eventKey
    });
  }
  render() {
    const { isLoading, errorMessage, activeNav } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <Nav variant='tabs bg-light rounded-top'>
          <Nav.Item className='flex-fill'>
            <Nav.Link eventKey='weekly' onSelect={this.handleSelect} active={activeNav === 'weekly'}>Weekly Schedule</Nav.Link>
          </Nav.Item>
          <Nav.Item className='flex-fill'>
            <Nav.Link eventKey='date' onSelect={this.handleSelect} active={activeNav === 'date'}>Schedule by Date</Nav.Link>
          </Nav.Item>
          <Nav.Item className='flex-fill'>
            <Nav.Link eventKey='timeTable' onSelect={this.handleSelect} active={activeNav === 'timeTable'}>Time Table</Nav.Link>
          </Nav.Item>
        </Nav>
        <div className='border-right border-bottom border-left p-3'>
          {
            activeNav === 'weekly' ? (
              <Weekly {...this.props} />
            ) : activeNav === 'date' ? (
              <Date {...this.props} />
            ) : (
              <TimeTable {...this.props} />
            )
          }
        </div>
      </>
    );
  }
}