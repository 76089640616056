import React, { Component } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import './style.scss';
import NameLink from '../../../common/NameLink/NameLink';
import moment from 'moment';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

const defaultNumberOfColumns = 10;

export default class Grades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      materials: null,
      dates: [],
      gradeCategories: [],
      selectedDate: '',
      isLoading: true,
      errorMessage: ''
    };
  }
  componentDidMount() {
    const { classID } = this.props.match.params;

    axiosRequest('get', `school-admin/class/${classID}/grade`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        isLoading: false,
        students: data.students,
        materials: data.materials,
        dates: data.dates,
        gradeCategories: data.gradeCategories,
        selectedDate: data.dates.length > 0 ? data.dates[0] : '',
        errorMessage: ''
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleDateChange = event => {
    this.setState({
      ...this.state,
      selectedDate: event.target.value
    });
  }
  renderGradeCategory = material => {
    const { gradeCategories } = this.state;

    let gradeCategory = gradeCategories.find(gc => gc.id === (material.faculty_load_id ? material.grade_category_id : material.audience.grade_category_id));

    if (gradeCategory) {
      return (
        <div className='text-muted font-weight-bold text-truncate' title={gradeCategory.name}>
          {gradeCategory.name}
        </div>
      );
    }

    return null;
  }
  renderGrade = (student, material) => {
    let gradeData = material.grades.find(grade => grade.student_id === student.id);

    if (gradeData) {
      return gradeData.grade;
    } else if (material.type === 'assignment') {
      if (material.submissions.find(submission => submission.student_id === student.id)) {
        return <small className='text-muted'>(Ungraded)</small>;
      }
    }

    return <span className='text-danger'>N/A</span>;
  }
  renderTable = () => {
    const { students, materials, selectedDate } = this.state;

    if (!selectedDate) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let gradeColumns = materials[selectedDate];

    if (!gradeColumns) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let columnFiller = defaultNumberOfColumns-gradeColumns.length;
    columnFiller = columnFiller < 0 ? 0 : columnFiller;

    return (
      <Row className='mt-3'>
        <Col>
          <div className='gradebook-container'>
            <div className='gradebook' style={{ width: `${150+((defaultNumberOfColumns > gradeColumns.length ? defaultNumberOfColumns : materials.length)*100)}px` }}>
              <div className='tr'>
                <div className='th'>
                  Name
                </div>
                {
                  gradeColumns.map(material => (
                    <div key={`${material.type}-${material.id}`} className='th'>
                      <small>
                        <div className='text-truncate' title={material.title}>{material.title}</div>
                        { this.renderGradeCategory(material) }
                        <div className='font-weight-bold text-center'>
                          ({material.points} point{material.points > 1 ? 's' : ''})
                        </div>
                      </small>
                    </div>
                  ))
                }
                {
                  Array.from({ length: columnFiller }, (column, index) => (
                    <div key={`cf-${index}`} className='th'></div>
                  ))
                }
              </div>
              {
                students.map(student => (
                  <div key={student.id} className='tr'>
                    <div className='th'>
                      <NameLink
                        id={student.id}
                        name={student.name}
                        image={student.image}
                        rank={student.rank} />
                    </div>
                    {
                      gradeColumns.map(material => (
                        <div key={`${material.type}-${material.id}`} className='td'>
                          {this.renderGrade(student, material)}
                        </div>
                      ))
                    }
                    {
                      Array.from({ length: columnFiller }, (column, index) => (
                        <div key={`cf-${index}`} className='td'></div>
                      ))
                    }
                  </div>
                ))
              }
            </div>
          </div>
        </Col>
      </Row>
    );
  }
  render() {
    const { isLoading, errorMessage, selectedDate, dates, students } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (students.length === 0 || dates.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <Form.Control as='select' value={selectedDate} onChange={this.handleDateChange}>
          <option hidden disabled value=''>Select date...</option>
          {
            dates.map(date => (
              <option value={date}>{moment(date).format('dddd, MMMM D, YYYY')}</option>
            ))
          }
        </Form.Control>
        { this.renderTable() }
      </>
    );
  }
}