import React from 'react';

export default function Guide(props) {
  function profileDescription() {
    switch(props.accountType) {
      case 4:
        return 'Your personal profile contains information about you.';
      case 2:
      case 5:
        return 'Your personal profile contains information about you, an editable short bio and your upload limit.';
      default:
        return 'Your personal profile contains information about you and an editable short bio';
    }
  }
  return (
    <>
      <div className='mb-3'>
        <div className='h4 text-primary'>Homepage</div>
        <div>
          Your homepage is the first place you'll land every time you log in to ACTS-VLE.
          Your homepage will show you the card-based menu of your courses.
        </div>
      </div>
      <div className='mb-3'>
        <div className='h4 text-primary'>Profile</div>
        <div>
          {profileDescription()}
        </div>
      </div>
      <div className='ml-3'>
        <div className='h5 text-primary'>Profile Picture</div>
        <div className='font-weight-bold'>To change your profile picture:</div>
        <div>
          <ol>
            <li>Hover over the profile picture and click the pencil icon.</li>
            <li>Click <b>Browse</b>.</li>
            <li>Choose a JPEG, or PNG file from your device.</li>
            <li>Click <b>Save</b>.</li>
          </ol>
        </div>
        <div className='font-weight-bold'>To delete your profile picture:</div>
        <div>
          <ol>
            <li>Hover over the profile picture and click the trash icon.</li>
            <li>If you don't have a profile picture uploaded, the trash icon will not be visible.</li>
            <li>Click <b>Delete</b> from the prompt to confirm deletion.</li>
          </ol>
        </div>
      </div>
      {
        props.accountType !== 4 && (
          <div className='ml-3'>
            <div className='h5 text-primary'>Information</div>
            <div className='font-weight-bold'>To update your profile information:</div>
            <div>
              <ol>
                <li>Click <b>Edit Profile</b> in the top-right corner.</li>
                <li>Fill out the form.</li>
                <li>Some information are not editable. To make changes on uneditable information, contact the system administrator.</li>
                <li>Click <b>Save</b>.</li>
              </ol>
            </div>
          </div>
        )
      }
      <div className='mb-3'>
        <div className='h4 text-primary'>Search</div>
        <div>
          Use the <b>Search</b> tool to search for any ACTS-VLE user.
        </div>
      </div>
      <div className='mb-3'>
        <div className='h4 text-primary'>Messages</div>
        <div>
          <ol>
            <li>Navigate to your <b>Messages</b> area by clicking the envelope icon on the top menu.</li>
            <li>Click <b>View All</b>.</li>
          </ol>
        </div>
      </div>
      <div className='ml-3'>
        <div className='h5'>Time Table</div>
        <div>
          View your weekly schedule.
        </div>
      </div>
      <div className='ml-3'>
        <div className='h5 text-primary'>Recent Messages</div>
        <div className='mb-3'>Navigate to your <b>Recent Messages</b> by clicking the envelope icon on the top menu.</div>
        <div className='h5 text-primary'>New Message</div>
        <div>
          <ol>
            <li>Navigate to your <b>Messages</b> area by clicking the envelope icon on the top menu.</li>
            <li>Click <b>View All</b>.</li>
            <li>Click <b>New Message</b> in the top right corner.</li>
            <li>Enter the recipient's name in the <b>To</b> field. You must select a recipient from the list under the field.</li>
            <li>Enter a message.</li>
            <li>Click <b>Send</b> to complete.</li>
          </ol>
        </div>
      </div>
      <div className='mb-3'>
        <div className='h4 text-primary'>Notifications</div>
        <div>
          Notifications display classroom events in chronological order.
          New notifications are indicated by a number on the bell icon.
          Clicking a notification will allow you to view the related content.
        </div>
      </div>
    </>
  );
}