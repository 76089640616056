import React, { Component } from 'react';
import { Alert, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../../../util/helpers/axiosRequest';
import Week from './parts/Week';
import Term from './parts/Term';
import Custom from './parts/Custom';
import LoadingIcon from '../../../../../common/LoadingIcon/LoadingIcon';

export default class ClassroomsAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      active: 'week',
      facultyLoads: [],
      terms: []
    }
  }
  componentDidMount() {
    const { profile } = this.props;
    axiosRequest('get', `profile-menu/${profile.id}/classroom`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history)
  }
  handleSelect = key => {
    this.setState({
      ...this.state,
      active: key
    });
  }
  renderContent = () => {
    const { active, facultyLoads, terms } = this.state;

    switch(active) {
      case 'week':
        return <Week {...this.props} facultyLoads={facultyLoads} />;
      case 'term':
        return <Term {...this.props} facultyLoads={facultyLoads} terms={terms} />;
      case 'custom':
        return <Custom {...this.props} facultyLoads={facultyLoads} />;
      default:
        return null;
    }
  }
  render() {
    const { isLoading, errorMessage, active } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <Nav fill variant='pills' className='m-0'>
          <Nav.Item>
            <Nav.Link
              eventKey='week'
              onSelect={this.handleSelect}
              active={active === 'week'}>
                <FontAwesomeIcon icon='calendar-week' /> Week
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='term'
              onSelect={this.handleSelect}
              active={active === 'term'}>
                <FontAwesomeIcon icon='calendar' /> Term
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='custom'
              onSelect={this.handleSelect}
              active={active === 'custom'}>
                <FontAwesomeIcon icon='calendar-alt' /> Custom
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className='dropdown-divider'></div>
        {this.renderContent()}
      </>
    );
  }
}