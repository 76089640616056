import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';

import Header from '../../common/Header/Header';
import Sidebar from '../../units/Sidebar/Sidebar';
import UserList from '../../units/UserList/UserList';
import AdminSettings from '../../units/AdminSettings/AdminSettings';
import ClassEnrollment from '../../units/ClassEnrollment/ClassEnrollment';
import FacultyLoad from '../../units/FacultyLoad/FacultyLoad';
import StudentEnrollment from '../../units/StudentEnrollment/StudentEnrollment';
import ClassMerge from '../../units/ClassMerge/ClassMerge';
import BlockUsers from '../../units/BlockUsers/BlockUsers';
import DropCourses from '../../units/DropCourses/DropCourses';
import Transition from '../../units/Transition/Transition';
import ClassAdvisory from '../../units/ClassAdvisory/ClassAdvisory';
import ApprovalRequests from '../../units/ApprovalRequests/ApprovalRequests';
import IDGeneration from '../../units/IDGeneration/IDGeneration';

export default class Admin extends Component {
  renderContent = () => {
    const { activeNav } = this.props.match.params;
    const defaultSubs = {
      settings: 'school-levels',
      users: 'students',
      enrollment: 'class',
      'block-users': 'account-types',
      transition: 'class',
      'id-generation': 'settings'
    };
    return (
      <Switch>
        <Route exact path='/admin/settings/:activeSub(school-levels|programs|year-levels|sections|classes|courses|exam-categories|terms|semester|upload-limit)' component={AdminSettings} />
        <Route exact path='/admin/users/:activeSub(students|parents|staff|faculty|school-admins|web-admins)' component={UserList} />
        <Route exact path='/admin/enrollment/:activeSub(class|student|merge)' render={props => (
          <Switch>
            <Route exact path='/admin/enrollment/class' component={ClassEnrollment} />
            <Route exact path='/admin/enrollment/student' component={StudentEnrollment} />
            <Route exact path='/admin/enrollment/merge' component={ClassMerge} />
          </Switch>
        )} />
        <Route path='/admin/faculty-load' component={FacultyLoad} />
        <Route path='/admin/class-advisory' component={ClassAdvisory} />
        <Route path='/admin/drop-courses' component={DropCourses} />
        <Route path='/admin/approval-requests' component={ApprovalRequests} />
        <Route exact path='/admin/block-users/:activeSub(account-types|classes)' component={BlockUsers} />
        <Route exact path='/admin/transition/:activeSub(class|clear)' component={Transition} />
        <Route exact path='/admin/id-generation/:activeSub(settings|requests)' component={IDGeneration} />
        <Redirect to={{ pathname: `/admin/${activeNav}/${defaultSubs[activeNav]}` }} />
      </Switch>
    );
  }
  render () {
    return (
      <>
        <Header />
        <Container>
          <Row className='mt-lg-3 mb-3'>
            <Col lg={3} className='bg-light p-0 my-1 border rounded'>
              <Route path='/admin/:activeNav' render={(props) => (
                <Switch>
                  <Route exact path='/admin/:activeNav/:activeSub' component={Sidebar} />
                  <Route exact path='/admin/:activeNav(faculty-load|class-advisory|drop-courses|approval-requests|id-generation)' component={Sidebar} />
                </Switch>
              )} />
            </Col>
            <Col lg={9} className='my-1'>
              { this.renderContent() }
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}