import React from 'react';

export default function Schedule(props) {
  function faculty() {
    return (
      <>
        <div className='mb-3'>
          The Schedule area is the place where you set the weekly schedule of the classroom as requirement to use the attendance module.
          You may also set the schedule for a specific date.
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Weekly Schedule</div>
          <div>
            The weekly schedule form is displayed as monday to sunday.
          </div>
          <div>
            <ol>
              <li>Fill out the start time and end time for specific weekdays.</li>
              <li>Optionally, if a specific weekday has no schedule, you can set it by ticking the <b>No Schedule</b>.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Schedule by Date</div>
          <div>
            This area is used for setting a schedule for specific dates.
          </div>
          <div>
            <ol>
              <li>Select a date to set a schedule to.</li>
              <li>Fill out the start and end time.</li>
              <li>Optionally, if a date has no schedule, you can set it by ticking the <b>No Schedule</b>.</li>
              <li>Click <b>Save</b> to complete.</li>
              <li>You may also delete date schedules by clicking the trash icon at the right side of the schedule.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Time Table</div>
          <div>
            This area displays the weekly schedule of the class.
          </div>
        </div>
      </>
    );
  }

  function other() {
    return (
      <>
        <div>
          This area displays the weekly schedule of the class.
        </div>
      </>
    );
  }
  
  return props.accountType === 2 ? faculty() : other();
}