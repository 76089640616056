import React, { Component } from 'react';
import { Alert, Nav, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NameLink from '../../../common/NameLink/NameLink';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import './style.scss';
import moment from 'moment';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

const badgeIconMap = {
  Login: (
    <>
      <div>
        <span className='fa-layers fa-2x'>
          <FontAwesomeIcon icon='sign-in-alt' />
          <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-7' className='text-green' />
        </span>
      </div>
      <div>On Time Login</div>
    </>
  ),
  Submission: (
    <>
      <div>
        <span className='fa-layers fa-2x'>
          <FontAwesomeIcon icon='paper-plane' />
          <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-9' className='text-green' />
        </span>
      </div>
      <div>On Time Submission</div>
    </>
  ),
  Attendance: (
    <>
      <div>
        <span className='fa-layers fa-2x'>
          <FontAwesomeIcon icon='calendar-alt' />
          <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
        </span>
      </div>
      <div>Attendance</div>
    </>
  ),
  Participation: (
    <>
      <div>
        <span className='fa-layers fa-2x'>
          <FontAwesomeIcon icon='comments' />
          <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
        </span>
      </div>
      <div>Discussion Participation</div>
    </>
  )
};

export default class Badges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      badges: null,
      activeKey: 'ranking'
    };
  }
  componentDidMount() {
    const { userType, classID } = this.props.match.params;

    axiosRequest('get', `${userType}/class/${classID}/badges`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      })
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleSelect = key => {
    this.setState({
      ...this.state,
      activeKey: key
    });
  }
  renderBadge = badge => {
    const { badges } = this.state;

    return (
      <tr key={badge}>
        <th className='w-50'>
          {badgeIconMap[badge]}
        </th>
        <td className='align-middle text-primary'>
          <div>
            <span>
              <FontAwesomeIcon icon='trophy' size='lg' />
            </span>
            <span className='pl-2'>
              x {badges[badge]}
            </span>
          </div>
        </td>
      </tr>
    );
  }
  renderTopBadge = badge => {
    if (!badge) {
      return null;
    }

    return (
      <div>
        <span>
          <FontAwesomeIcon icon='trophy' size='lg' />
        </span>
        <span className='pl-2'>
          x {badge}
        </span>
      </div>
    );
  }
  renderTop = () => {
    const { top } = this.state;

    if (top.length === 0) {
      return (
        <Alert variant='light'>
          <div>
            No top badge holders last month.
          </div>
          <div>
            This month's top badge holders will be announced on {moment().add(1, 'months').startOf('month').format('MMMM D, YYYY, dddd')}
          </div>
        </Alert>
      );
    }
    
    return (
      <>
        <div className='h4'>
          {moment().subtract(1, 'months').format('MMMM YYYY')}
          <small className='ml-2 text-muted font-italic'>(Last month)</small>
        </div>
        <Table size='sm' responsive striped hover className='top-badge-table'>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th className='text-center'>
                <span className='fa-layers fa-fw my-1 mx-2' title='Login'>
                  <FontAwesomeIcon icon='sign-in-alt' size='lg' />
                  <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-7' className='text-green' />
                </span>
              </th>
              <th className='text-center'>
                <span className='fa-layers fa-fw my-1 mx-2' title='Submission'>
                  <FontAwesomeIcon icon='paper-plane' size='lg' />
                  <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-9' className='text-green' />
                </span>
              </th>
              <th className='text-center'>
                <span className='fa-layers fa-fw my-1 mx-2' title='Attendance'>
                  <FontAwesomeIcon icon='calendar-alt' size='lg' />
                  <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
                </span>
              </th>
              <th className='text-center'>
                <span className='fa-layers fa-fw my-1 mx-2' title='Participation'>
                  <FontAwesomeIcon icon='comments' size='lg' />
                  <FontAwesomeIcon icon='check-circle' transform='shrink-5 down-6 right-10' className='text-green' />
                </span>
              </th>
              <th className='text-center'>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {
              top.map((student, rankIndex) => (
                <tr key={student.profile.id} className={rankIndex === 0 ? 'h5' : rankIndex === 1 ? 'h6' : ''}>
                  <th className='text-center align-middle'>
                    {rankIndex+1}
                  </th>
                  <th>
                    <NameLink {...student.profile} />
                  </th>
                  <td>{this.renderTopBadge(student.Login)}</td>
                  <td>{this.renderTopBadge(student.Submission)}</td>
                  <td>{this.renderTopBadge(student.Attendance)}</td>
                  <td>{this.renderTopBadge(student.Participation)}</td>
                  <td>{this.renderTopBadge(student.total)}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </>
    )
  }
  render() {
    const { isLoading, errorMessage, badges, activeKey } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <Nav variant='tabs bg-light rounded-top' onSelect={this.handleSelect}>
          <Nav.Item className='flex-fill'>
            <Nav.Link eventKey='ranking' active={activeKey === 'ranking'}>Ranking</Nav.Link>
          </Nav.Item>
          <Nav.Item className='flex-fill'>
            <Nav.Link eventKey='students' active={activeKey === 'students'}>Student Badges</Nav.Link>
          </Nav.Item>
        </Nav>
        <div className='p-3 border rounded-bottom border-top-0'>
          {
            activeKey === 'ranking' ? this.renderTop() : Object.keys(badges).length > 0 ? (
              <>
                <div className='h4 px-2'>
                  {moment().format('MMMM YYYY')}
                  <small className='ml-2 text-muted font-italic'>(Current month)</small>
                </div>
                <Table hover>
                  <tbody>
                    {
                      Object.keys(badges).map(badge => this.renderBadge(badge))
                    }
                  </tbody>
                </Table>
              </>
            ) : (
              <>
                No badges earned.
              </>
            )
          }
        </div>
      </>
    );
  }
}