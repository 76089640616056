import React, { Component } from 'react';
import { Alert, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Gradebook from './parts/Gradebook';
import GradeSettings from './parts/GradeSettings';
import TermGrade from './parts/TermGrade';
import axiosRequest from '../../../util/helpers/axiosRequest';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

export default class Grades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 'gradebook',
      isLoading: true,
      errorMessage: '',
      students: []
    };
  }
  componentDidMount() {
    const { classID } = this.props.match.params;
    axiosRequest('get', `faculty/class/${classID}/grade`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        isLoading: false,
        students: data
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleSelect = key => {
    this.setState({
      ...this.state,
      active: key
    });
  }
  renderContent = () => {
    const { active, students } = this.state;

    switch(active) {
      case 'gradebook':
        return <Gradebook {...this.props} students={students} />;
      case 'term-grade':
        return <TermGrade {...this.props} students={students} />;
      case 'settings':
        return <GradeSettings {...this.props} />;
      default:
        return null;
    }
  }
  render() {
    const { isLoading, errorMessage, active, students } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (students.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <Nav variant='tabs bg-light rounded-top'>
          <Nav.Item className='flex-fill'>
            <Nav.Link
              eventKey='gradebook'
              onSelect={this.handleSelect}
              active={active === 'gradebook'}>
                <FontAwesomeIcon icon='book' /> Material Grade
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className='flex-fill'>
            <Nav.Link
              eventKey='term-grade'
              onSelect={this.handleSelect}
              active={active === 'term-grade'}>
                <FontAwesomeIcon icon='layer-group' /> Term Grade
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='settings'
              onSelect={this.handleSelect}
              active={active === 'settings'}>
                <FontAwesomeIcon icon='cog' /> Settings
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className='p-3 border rounded-bottom border-top-0'>
          {this.renderContent()}
        </div>
      </>
    );
  }
}