import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { loginUser } from '../../../actions';
import PropTypes from 'prop-types';
import { Container, Dropdown, Form, Image, Nav, Navbar, Overlay, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import actsccLogo from '../../../resources/image/actscc-logo.png';
import './style.scss';
import Notifications from '../Notifications/Notifications';
import axiosRequest from '../../../util/helpers/axiosRequest';
import Messages from '../Messages/Messages';
import Validator from 'validatorjs';
import NameLink from '../NameLink/NameLink';
import LoadingIcon from '../LoadingIcon/LoadingIcon';

const Header = props => {
  let history = useHistory();
  let params = useParams();
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchUsers, setSearchUsers] = useState([]);
  const [fetchNotif, setFetchNotif] = useState(true);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [fetchChat, setFetchChat] = useState(true);
  const [unseenMessagesCount, setUnseenMessagesCount] = useState(0);
  const [fetchSwitchRoleLoading, setSwitchRoleLoading] = useState(-1);
  const target = useRef(null);
  const targetMessage = useRef(null);
  const targetSearch = useRef(null);
  const searchTimeout = useRef(null);
  const getUsers = useRef(null);
  
  const accountTypeMap = {
    0: 'Web Administrator',
    1: 'School Administrator',
    2: 'Faculty',
    3: 'Staff',
    4: 'Parent',
    5: 'Student'
  };

  getUsers.current = () => {
    axiosRequest('get', `users/${searchQuery}`, null, ({ data: { data }}) => {
      setSearchUsers(data);
      setSearchLoading(false);
    }, error => {
  
    }, history);
  }

  useEffect(() => {
    if (searchTimeout.current) {
      setSearchLoading(false);
      clearTimeout(searchTimeout.current);
    }

    let validator = new Validator({
      searchQuery
    }, {
      searchQuery: 'required|string|min:2'
    });

    if (validator.passes()) {
      setSearchLoading(true);
      searchTimeout.current = setTimeout(() => {
        getUsers.current();
      }, 1000);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (fetchNotif) {
      setFetchNotif(false);
      axiosRequest('get', `notification/unread`, null, ({ data: { data }}) => {
        setUnreadNotifications(data);
      }, error => {
    
      }, history);
    }
  }, [fetchNotif, history]);

  useEffect(() => {
    if (fetchChat) {
      setFetchChat(false);
      axiosRequest('get', `chat/unseen`, null, ({ data: { data }}) => {
        setUnseenMessagesCount(data);
      }, error => {
    
      }, history);
    }
  }, [fetchChat, history]);
  
  function logout() {
    axios.delete(`${process.env['REACT_APP_API_BASE_URL']}/logout`, { withCredentials: true }).then(({ data: { message } }) => {
      history.replace('/login', { successMessage: 'Successfully logged out.' });
    }).catch((error) => {

    });
  }

  function help() {
    history.push('/help');
  }

  function changePassword() {
    history.push('/change-password');
  }

  function changeRole(e) {
    if (e.target.checked) {
      setSwitchRoleLoading(+e.target.value);
      axiosRequest('put', `switch-role/${e.target.value}`, {}, ({ data: { data }}) => {
        props.loginUser(data);
        history.replace('/');
        setSwitchRoleLoading(false);
      }, error => {
        setSwitchRoleLoading(false);
      }, history);
    }
  }

  function notification() {
    setShow(!show);
  }

  function notificationHide() {
    setShow(false);
    if (unreadNotifications && unreadNotifications.length !== 0) {
      axiosRequest('patch', 'notification', {
        notifications: unreadNotifications
      }, ({ data: { data }}) => {
        // let user = {...currentUser};
        setUnreadNotifications(data);
        // user.unread_notification_ids = data;
        // props.loginUser(user);
      }, () => {}, history);
    }
  }

  function message() {
    setShowMessage(!showMessage);
  }

  function messageHide() {
    setShowMessage(false);
  }

  function calendar() {
    history.push('/time-table');
  }

  function searchChange (event) {
    setSearchQuery(event.target.value);
  }

  function hideSearch() {
    setSearchUsers([]);
    setSearchQuery('');
  }

  function newMessage (user) {
    history.push('/chat', { newMessage: true, to: { id: user.id, name: user.name }, type: 'user' });
  }

  function payment() {
    history.push('/payment-transaction');
  }

  function examPermit() {
    history.push('/exam-permit');
  }

  function grade() {
    history.push('/grade');
  }

  function attendance() {
    history.push('/attendance');
  }

  function materials() {
    history.push('/materials');
  }

  function advisory() {
    history.push('/class-advisory');
  }

  // function activities() {
  //   history.push('/activities');
  // }

  function profile(event) {
    event.preventDefault();
    history.push('/profile');
  }

  function home(event) {
    event.preventDefault();
    history.push('/');
  }

  const { currentUser } = props;

  let isActiveMore = props.active === 'Attendance' || props.active === 'Exam Permit' || props.active === 'Payment Transaction' || props.active === 'Grades';

  return (
    <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
      <Container>
        <Navbar.Brand href='#' onClick={home}>
          <Image
            src={actsccLogo}
            width='30'
            height='30'
            className='d-inline-block align-top'
          />{' '}
          {process.env['REACT_APP_NAME']}
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav>
            <Nav.Link onClick={home} active={props.active === 'Home'}>Home</Nav.Link>
            <Nav.Link onClick={profile} active={props.active === 'Profile'}>Profile</Nav.Link>
            {
              currentUser.account_type === 3 ? (
                <>
                  {
                    isActiveMore && (
                      <>
                        {
                          props.active === 'Attendance' && (
                            <Nav.Link onClick={attendance} active>Attendance</Nav.Link>
                          )
                        }
                        {
                          props.active === 'Grades' && (
                            <Nav.Link onClick={grade} active>Grades</Nav.Link>
                          )
                        }
                        {
                          props.active === 'Exam Permit' && (
                            <Nav.Link onClick={examPermit} active>Exam Permit</Nav.Link>
                          )
                        }
                        {
                          props.active === 'Payment Transaction' && (
                            <Nav.Link onClick={payment} active>Payment Transactions</Nav.Link>
                          )
                        }
                      </>
                    )
                  }
                  <Dropdown>
                    <Dropdown.Toggle as={Nav.Link}>
                      <span className='pr-1'>More</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ zIndex: 1030 }}>
                      {
                        props.active !== 'Attendance' && (
                          <Dropdown.Item onClick={attendance}>Attendance</Dropdown.Item>
                        )
                      }
                      {
                        props.active !== 'Grades' && (
                          <Dropdown.Item onClick={grade}>Grades</Dropdown.Item>
                        )
                      }
                      {
                        props.active !== 'Exam Permit' && (
                          <Dropdown.Item onClick={examPermit}>Exam Permit</Dropdown.Item>
                        )
                      }
                      {
                        props.active !== 'Payment Transaction' && (
                          <Dropdown.Item onClick={payment}>Payment Transactions</Dropdown.Item>
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : (
                <>
                  {
                    currentUser.account_type === 2 && (
                      <>
                        <Nav.Link onClick={materials} active={props.active === 'Materials'}>Materials</Nav.Link>
                        {
                          currentUser.is_adviser && (
                            <Nav.Link onClick={advisory} active={props.active === 'Advisory'}>Advisory</Nav.Link>
                          )
                        }
                      </>
                    )
                  }
                  {
                    currentUser.account_type === 1 && (
                      <Nav.Link onClick={attendance} active={props.active === 'Attendance'}>Attendance</Nav.Link>
                    )
                  }
                </>
              )
            }
          </Nav>
          <Nav className='ml-auto'>
            <Form inline className='mr-3'>
              <Form.Control ref={targetSearch} type='search' size='sm' value={searchQuery} onChange={searchChange} />
            </Form>
            {
              (currentUser.account_type !== 3 && currentUser.account_type !== 0) && (
                <Nav.Link onClick={calendar} active={props.active === 'Calendar'}>
                  <span className='fa-fw align-middle'>
                    <FontAwesomeIcon icon='calendar-alt' size='lg' />
                  </span>
                </Nav.Link>
              )
            }
            <Overlay
              show={searchLoading || (searchUsers && searchUsers.length > 0)}
              target={targetSearch.current}
              placement='bottom-end'
              rootClose
              onHide={hideSearch}
            >
              <Popover id='users' className='header-notifications shadow-sm user-list'>
                {
                  searchLoading ? (
                    <div className='py-2 px-3'>
                      <LoadingIcon />
                    </div>
                  ) : searchUsers.map(user => (
                    <div key={user.id} className='d-flex'>
                      <small className='d-block flex-fill py-2 px-3'>
                        <NameLink {...user} />
                      </small>
                      <div className='border-left px-3 btn-link align-self-center' title='Message' onClick={() => newMessage(user)}>
                        <FontAwesomeIcon icon='envelope' />
                      </div>
                    </div>
                  ))
                }
              </Popover>
            </Overlay>
            <Nav.Link onClick={message} active={props.active === 'Chat'} ref={targetMessage}>
              <span className='fa-layers fa-fw align-middle'>
                <FontAwesomeIcon icon='envelope' size='lg' />
                {
                  unseenMessagesCount > 0 && (
                    <span className='fa-layers-counter fa-fw fa-2x bg-danger' style={{ transformOrigin: '1.675em -0.1875em'}}>{unseenMessagesCount}</span>
                  )
                }
              </span>
            </Nav.Link>
            <Overlay
              show={showMessage}
              target={targetMessage.current}
              placement='bottom-end'
              rootClose
              onHide={messageHide}
            >
              <Popover id='messages' className='header-notifications shadow-sm'>
                <Messages
                  {...props}
                  history={history}
                  params={params}
                  hideMessages={messageHide} />
              </Popover>
            </Overlay>
            <Nav.Link onClick={notification} ref={target}>
              <span className='fa-layers fa-fw align-middle'>
                <FontAwesomeIcon icon='bell' size='lg' />
                {
                  (unreadNotifications && unreadNotifications.length !== 0) && (
                    <span className='fa-layers-counter fa-fw fa-2x bg-danger' style={{ transformOrigin: '1.675em -0.1875em'}}>{unreadNotifications.length}</span>
                  )
                }
              </span>
            </Nav.Link>
            <Overlay
              show={show}
              target={target.current}
              placement='bottom-end'
              rootClose
              onHide={notificationHide}
            >
              <Popover id='notifications' className='header-notifications shadow-sm'>
                <Notifications
                  {...props}
                  history={history}
                  params={params}
                  hideNotifications={notificationHide} />
              </Popover>
            </Overlay>
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} style={{ whiteSpace: 'nowrap' }}>
                {
                  currentUser && (
                    <div className='d-inline-block mr-2'>
                      <NameLink
                        id={currentUser.id}
                        name={currentUser.profile.name}
                        image={currentUser.profile.image}
                        rank={currentUser.profile.rank}
                        imageStyle={{ width: '30px', height: '30px' }}
                        rankStyle={{ fontSize: '.75rem' }}
                        disableLink />
                    </div>
                  )
                }
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {
                  (currentUser.account_roles && currentUser.account_roles.length > 1) && (
                    <>
                      {
                        currentUser.account_roles.map(role => (
                          <div key={role.id} className='py-1 d-flex'>
                            <div className={(fetchSwitchRoleLoading >= 0 && fetchSwitchRoleLoading === role.account_type) ? '' : 'ml-4'}>
                              {
                                (fetchSwitchRoleLoading >= 0 && fetchSwitchRoleLoading === role.account_type) && (
                                  <LoadingIcon className='mx-1' />
                                )
                              }
                            </div>
                            <div className='mr-4'>
                              <Form.Check
                                id={`role-${role.id}`}
                                type='switch'
                                label={accountTypeMap[role.account_type]}
                                checked={role.is_active}
                                value={role.account_type}
                                readOnly={role.is_active}
                                disabled={fetchSwitchRoleLoading >= 0}
                                onChange={changeRole} />
                            </div>
                          </div>
                        ))
                      }
                      <Dropdown.Divider />
                    </>
                  )
                }
                <Dropdown.Item onClick={profile}>Profile</Dropdown.Item>
                <Dropdown.Item onClick={changePassword}>Change password</Dropdown.Item>
                <Dropdown.Item onClick={help}>Help</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

Header.propTypes = {
  active: PropTypes.string.isRequired
};

Header.defaultProps = {
  active: 'Home'
};

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

const mapDispatchToProps = dispatch => ({
  loginUser: user => dispatch(loginUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);