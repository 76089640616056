import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const links = [
  {
    key: 'settings',
    title: 'Settings',
    submenu: [
      {
        key: 'school-levels',
        title: 'School Levels'
      },
      {
        key: 'programs',
        title: 'Programs'
      },
      {
        key: 'year-levels',
        title: 'Year Levels'
      },
      {
        key: 'sections',
        title: 'Sections'
      },
      {
        key: 'classes',
        title: 'Classes'
      },
      {
        key: 'courses',
        title: 'Courses'
      },
      {
        key: 'exam-categories',
        title: 'Exam Categories'
      },
      {
        key: 'terms',
        title: 'Terms'
      },
      {
        key: 'semester',
        title: 'Semester'
      },
      {
        key: 'upload-limit',
        title: 'Upload Limit'
      }
    ]
  },
  {
    key: 'users',
    title: 'Users',
    submenu: [
      {
        key: 'students',
        title: 'Students'
      },
      {
        key: 'parents',
        title: 'Parents'
      },
      {
        key: 'staff',
        title: 'Staff'
      },
      {
        key: 'faculty',
        title: 'Faculty'
      },
      {
        key: 'school-admins',
        title: 'School Administrators'
      },
      {
        key: 'web-admins',
        title: 'Web Administrators'
      }
    ]
  },
  {
    key: 'enrollment',
    title: 'Enrollment',
    submenu: [
      {
        key: 'class',
        title: 'Class Enrollment'
      },
      {
        key: 'student',
        title: 'Irregular Student Enrollment'
      },
      {
        key: 'merge',
        title: 'Class Merge'
      },
    ]
  },
  {
    key: 'faculty-load',
    title: 'Faculty Load Assignment',
    submenu: []
  },
  {
    key: 'class-advisory',
    title: 'Class Advisory',
    submenu: []
  },
  {
    key: 'drop-courses',
    title: 'Drop Courses',
    submenu: []
  },
  {
    key: 'block-users',
    title: 'Block Users',
    submenu: [
      {
        key: 'account-types',
        title: 'By Account Type'
      },
      {
        key: 'classes',
        title: 'By Class'
      }
    ]
  },
  {
    key: 'transition',
    title: 'Transition',
    submenu: [
      {
        key: 'class',
        title: 'Class Transition'
      },
      {
        key: 'clear',
        title: 'Clear Data'
      }
    ]
  },
  {
    key: 'approval-requests',
    title: 'Approval Requests',
    submenu: []
  },
  {
    key: 'id-generation',
    title: 'ID Generation',
    submenu: [
      {
        key: 'settings',
        title: 'Settings'
      },
      {
        key: 'requests',
        title: 'Requests'
      }
    ]
  }
];

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  handleSelect = (eventKey) => {
    this.props.history.push(`/admin/${eventKey}`);
  }
  handleSubSelect = (eventKey) => {
    this.props.history.push(eventKey);
  }
  render() {
    const { activeNav, activeSub } = this.props.match.params;
    return (
      <Nav variant="pills" className='flex-column sidebar'>
        {
          links.map(({ key, title, submenu }, index) => {
            return (
              <div key={index}>
                {
                  submenu.length > 0 ? (
                    <Nav.Link
                      key={index}
                      className='d-flex flex-row justify-content-between align-items-center'
                      active={activeNav === key}
                      eventKey={key}
                      onSelect={this.handleSelect}
                    >
                      {title}
                      {
                        activeNav === key ? (
                          <FontAwesomeIcon icon='chevron-down' />
                        ) : (
                          <FontAwesomeIcon icon='chevron-right' />
                        )
                      }
                    </Nav.Link>
                  ) : (
                    <Nav.Link
                      active={activeNav === key}
                      eventKey={key}
                      onSelect={this.handleSelect}
                    >
                      {title}
                    </Nav.Link>
                  )
                }
                {
                  (submenu.length > 0 && activeNav === key) && (
                    <Nav variant="pills" className='flex-column sidebar-submenu'>
                      {
                        submenu.map((sub, i) => (
                          <Nav.Link
                            key={i}
                            active={activeSub === sub.key} 
                            eventKey={sub.key}
                            onSelect={this.handleSubSelect}>{sub.title}</Nav.Link>
                        ))
                      }
                    </Nav>
                  )
                }
              </div>
            );
          })
        }
      </Nav>
    );
  }
}