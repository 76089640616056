import React, { Component } from 'react';
import { Alert, Card, Col, Form, Nav, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import { Route, Switch } from 'react-router-dom';
import axiosRequest from '../../../util/helpers/axiosRequest';
import textColorPicker from '../../../util/helpers/textColorPicker';

import Discussion from '../Discussion/Discussion';
import Students from '../Classroom/parts/Students';
import Materials from './parts/Materials';
import Attendance from '../StudentClassroom/parts/Attendance';
import Grades from '../StudentClassroom/parts/Grades';
import Badges from '../StudentClassroom/parts/Badges';
import Upcoming from '../Upcoming/Upcoming';
import TimeTable from '../TimeTable/TimeTable';
import NameLink from '../../common/NameLink/NameLink';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

const menu = [
  {
    title: 'Discussions',
    key: 'discussions'
  },
  {
    title: 'Materials',
    key: 'materials'
  },
  {
    title: 'Schedule',
    key: 'schedule'
  },
  {
    title: 'Attendance',
    key: 'attendance'
  },
  {
    title: 'Grades',
    key: 'grades'
  },
  {
    title: 'Students',
    key: 'students'
  },
  {
    title: 'Badges',
    key: 'badges'
  }
];

export default class Classroom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showUpcoming: false,
      isLoading: true,
      errorMessage: '',
      classInfo: null
    };
  }
  componentDidMount() {
    const { classID, menu } = this.props.match.params;
    this.setState({
      ...this.state,
      showUpcoming: menu === 'discussions' || menu === 'students'
    }, () => {
      axiosRequest('get', `parent/class/${classID}`, null, ({ data: { data } }) => {
        this.setState({
          classInfo: data,
          isLoading: false,
          errorMessage: ''
        });
      }, (error) => {
        this.setState({
          classInfo: null,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.menu !== this.props.match.params.menu) {
      this.setState({
        ...this.state,
        showUpcoming: this.props.match.params.menu === 'discussions' || this.props.match.params.menu === 'students'
      });
    }
  }
  handleCollapseMenu = event => {
    event.preventDefault();
    this.setState({
      ...this.state,
      showMenu: !this.state.showMenu
    });
  }
  handleShowUpcoming = event => {
    event.preventDefault();
    this.setState({
      ...this.state,
      showUpcoming: !this.state.showUpcoming
    });
  }
  handleMenuSelect = key => {
    this.props.history.push(key);
  }
  renderMenu = () => {
    const { params } = this.props.match;

    return menu.map((m, index) => (
      <Nav.Item key={index}>
        <Nav.Link
          eventKey={m.key}
          onSelect={this.handleMenuSelect}
          active={m.key === params.menu}>
          {m.title}
        </Nav.Link>
      </Nav.Item>
    ));
  }
  render() {
    const { showMenu, showUpcoming, isLoading, errorMessage, classInfo } = this.state;

    if (isLoading) {
      return (
        <Card className='mt-3'>
          <Card.Body>
            <LoadingIcon lg />
          </Card.Body>
        </Card>
      );
    }

    if (errorMessage) {
      return (
        <Card className='mt-3'>
          <Card.Body>
            <Alert variant='danger'>
              {errorMessage}
            </Alert>
          </Card.Body>
        </Card>
      );
    }

    return (
      <div className='classroom' style={{ backgroundImage: classInfo.class_course.school_class.year_level.school_level ? `linear-gradient(to left, ${classInfo.class_course.school_class.year_level.school_level.theme} 1%, #fff)` : 'linear-gradient(to left, rgba(1,169,9,.35) 1%, #fff)' }}>
      <Row className='mt-3 rounded-top border border-bottom-0 classroom-info text-primary' style={{ backgroundImage: classInfo.class_course.school_class.year_level.school_level ? `linear-gradient(to left, ${classInfo.class_course.school_class.year_level.school_level.theme} 1%, #fff)` : 'linear-gradient(to left, rgba(1,169,9,.35) 1%, #fff)' }} noGutters>
        <Col lg={12} className='px-3 pt-3'>
          <div className='d-flex'>
            <div className='flex-fill'>
              <h5 className='mb-1'>{`${classInfo.class_course.course.description} (${classInfo.class_course.course.code})`}</h5>
              <div>{`${classInfo.class_course.school_class.program.description} (${classInfo.class_course.school_class.program.code})`}</div>
              <div>{`${classInfo.class_course.school_class.year_level.description} (${classInfo.class_course.school_class.year_level.code})`}</div>
              <div>{`Section ${classInfo.class_course.school_class.section.code}`}</div>
            </div>
            <div className='d-flex align-items-end justify-content-end'>
              <div>
                <div className='d-none d-lg-block'>
                  <NameLink
                    {...classInfo.profile}
                    imageAlign='right'
                    imageStyle={{ height: '7.5rem', width: '7.5rem' }}
                    nameStyle={{ fontSize: '1.5rem', alignSelf: 'end', fontWeight: 'bold', color: classInfo.class_course.school_class.year_level.school_level ? textColorPicker(classInfo.class_course.school_class.year_level.school_level.theme) : '#fff', textAlign: 'right' }}
                    disableLink
                    showPosition
                  />
                </div>
                <div className='d-lg-none'>
                  <NameLink
                    {...classInfo.profile}
                    imageAlign='right'
                    imageStyle={{ width: '4rem', height: '4rem' }}
                    hideName
                    disableLink
                    showPosition
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='dropdown-divider'></div>
        </Col>
      </Row>
      <Row className='content mb-5' noGutters>

        <Col lg={{ span: 3, order: 1 }} className='content-sidebar collapsible'>
          <Nav className={`flex-column bg-white ${showMenu ? 'show-collapse' : ''}`}>
            <Nav.Item className='collapse-trigger'>
              <Nav.Link className='d-flex justify-content-between align-items-center' onClick={this.handleCollapseMenu}>
                <div>Menu</div>
                <FontAwesomeIcon icon={showMenu ? 'chevron-up' : 'chevron-down'} />
              </Nav.Link>
            </Nav.Item>
            { this.renderMenu() }
            <Nav.Item>
              <Nav.Link className='d-flex justify-content-between align-items-center show-upcoming-switch' onClick={this.handleShowUpcoming}>
                <Form.Check
                  type='switch'
                  id='showUpcoming'
                  label='Events'
                  checked={showUpcoming}
                  readOnly
                />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>

        <Col xs={{ order: 3 }} className='content-data'>
          <Switch>
            <Route exact path={`/:userType/class/:classID/discussions`} component={Discussion} />
            <Route exact path={`/:userType/class/:classID/students`} component={Students} />
            <Route exact path={`/:userType/class/:classID/materials`} component={Materials} />
            <Route exact path={`/:userType/class/:classID/attendance`} component={Attendance} />
            <Route exact path={`/:userType/class/:classID/schedule`} component={TimeTable} />
            <Route exact path={`/:userType/class/:classID/grades`} component={Grades} />
            <Route exact path={`/:userType/class/:classID/badges`} component={Badges} />
          </Switch>
        </Col>

        {
          showUpcoming && (
            <Col xs={{ span: 12, order: 2 }} lg={{ span: 3, order: 4 }} className='ml-lg-3 border upcoming-bordered-container'>
              <div>
                <div className='h6 px-2 pt-2'>Events</div>
                <div className='dropdown-divider'></div>
                <Upcoming {...this.props} />
              </div>
            </Col>
          )
        }
      </Row>
      </div>
    );
  }
}