import React, { Component } from 'react';
import { Alert, Card, Container, Nav } from 'react-bootstrap';
import Header from '../../../common/Header/Header';
import { Redirect, Route, Switch } from 'react-router-dom';
import Assignments from './parts/Assignments';
import Quizzes from './parts/Quizzes';
import Exams from './parts/Exams';
import Files from './parts/Files';
import Links from './parts/Links';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import moment from 'moment';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class Materials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      audiences: [],
      assignments: {
        data: []
      },
      quizzes: {
        data: []
      },
      exams: {
        data: []
      },
      files: {
        data: []
      },
      links: {
        data: []
      },
      asyncSchedules: [],
      asyncSchedulesRaw: []
    };
  }
  componentDidMount() {
    axiosRequest('get', 'faculty/materials', null, ({ data: { data }}) => {
      let asyncSchedules = data.map(facultyLoad => ({
        classID: facultyLoad.id,
        schedules: []
      }));
      let asyncSchedulesRaw = data.map(facultyLoad => ({
        classID: facultyLoad.id,
        schedules: {
          dateSchedules: [],
          weeklySchedules: [],
          asyncSchedules: []
        }
      }));
      this.setState({
        ...this.state,
        isLoading: false,
        audiences: data,
        asyncSchedules,
        asyncSchedulesRaw
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  setAsyncSchedules = (classID, data, currentAsyncDate = null, cb = () => {}) => { //TODO
    const { activeNav } = this.props.match.params;

    let schedules = [];
    let minDate = null;
    let maxDate = null;
    let baseDate = currentAsyncDate ? moment(currentAsyncDate.date) : moment();
    for (let i = 0; i < data.weeklySchedules.length; i++) {
      let startDate = baseDate.clone().day(data.weeklySchedules[i].day).subtract(21, 'days');
      let tempSchedules = [];
      while (tempSchedules.length < 5 || (minDate && maxDate)) {
        startDate.add(7, 'days');
        if (minDate && maxDate && !startDate.isBetween(minDate, maxDate, 'day', '[]')) {
          break;
        }
        if (data.asyncSchedules.length > 0) {
          let tempDate = data.asyncSchedules.find(das => {
            return das.schedule_date === startDate.format('YYYY-MM-DD') &&
                    das.start_time === data.weeklySchedules[i].start_time &&
                    das.end_time === data.weeklySchedules[i].end_time &&
                    das.schedule_from === 'weekly_schedule';
          });
          if (!tempDate) {
            tempSchedules = [
              ...tempSchedules,
              {
                date: startDate.format('YYYY-MM-DD'),
                start_time: data.weeklySchedules[i].start_time,
                end_time: data.weeklySchedules[i].end_time,
                schedule_from: 'weekly_schedule'
              }
            ];
          }
        } else {
          tempSchedules = [
            ...tempSchedules,
            {
              date: startDate.format('YYYY-MM-DD'),
              start_time: data.weeklySchedules[i].start_time,
              end_time: data.weeklySchedules[i].end_time,
              schedule_from: 'weekly_schedule'
            }
          ];
        }
      }
      if (!minDate && !maxDate) {
        minDate = tempSchedules[0].date;
        maxDate = tempSchedules[tempSchedules.length-1].date;
      }
      schedules = [...schedules, ...tempSchedules];
    }
    schedules.sort((a, b) => {
      let aDate = moment(a.date);
      let bDate = moment(b.date);

      if (aDate.isAfter(bDate)) {
        return 1;
      } else if (aDate.isBefore(bDate)) {
        return -1;
      }
      return 0;
    });

    let currentAsyncDateSet = false;
    if (schedules.length > 0) {
      let startSchedule = schedules[0];
      let endSchedule = schedules[schedules.length-1];
      let filteredDateSchedules = [];
      for (let i = 0; i < data.dateSchedules.length; i++) {
        if (activeNav !== 'exams' && data.dateSchedules[i].type === 'Exam') {
          continue;
        }
        if (moment(data.dateSchedules[i].schedule_date).isBetween(startSchedule.date, endSchedule.date, 'day', '[]')) {
          let tempDsIndex = schedules.findIndex(s => data.dateSchedules[i].schedule_date === s.date);
          if (tempDsIndex !== -1) {
            if (
                currentAsyncDate && 
                currentAsyncDate.date === data.dateSchedules[i].schedule_date &&
                currentAsyncDate.start_time === data.dateSchedules[i].start_time &&
                currentAsyncDate.end_time === data.dateSchedules[i].end_time &&
                currentAsyncDate.schedule_from === 'date_schedule'
              ) {
                currentAsyncDateSet = true;
                schedules[tempDsIndex] = {
                  date: data.dateSchedules[i].schedule_date,
                  start_time: data.dateSchedules[i].start_time,
                  end_time: data.dateSchedules[i].end_time,
                  schedule_from: 'date_schedule'
                };
            } else {
              schedules[tempDsIndex] = {
                date: data.dateSchedules[i].schedule_date,
                start_time: data.dateSchedules[i].start_time,
                end_time: data.dateSchedules[i].end_time,
                schedule_from: 'date_schedule'
              };
            }
          } else {
            if (data.asyncSchedules.length > 0) {
              let tempDate = data.asyncSchedules.find(das => {
                return das.schedule_date === data.dateSchedules[i].schedule_date &&
                        das.start_time === data.dateSchedules[i].start_time &&
                        das.end_time === data.dateSchedules[i].end_time &&
                        das.schedule_from === 'date_schedule';
              });
              if (!tempDate) {
                filteredDateSchedules = [
                  ...filteredDateSchedules,
                  {
                    date: data.dateSchedules[i].schedule_date,
                    start_time: data.dateSchedules[i].start_time,
                    end_time: data.dateSchedules[i].end_time,
                    schedule_from: 'date_schedule'
                  }
                ];
              }
            } else {
              filteredDateSchedules = [
                ...filteredDateSchedules,
                {
                  date: data.dateSchedules[i].schedule_date,
                  start_time: data.dateSchedules[i].start_time,
                  end_time: data.dateSchedules[i].end_time,
                  schedule_from: 'date_schedule'
                }
              ];
            }
          }
        } else if (moment(data.dateSchedules[i].schedule_date).isBetween(moment(startSchedule.date).subtract(7, 'days'), moment(endSchedule.date).add(7, 'days'), 'day', '()')) {
          filteredDateSchedules = [
            ...filteredDateSchedules,
            {
              date: data.dateSchedules[i].schedule_date,
              start_time: data.dateSchedules[i].start_time,
              end_time: data.dateSchedules[i].end_time,
              schedule_from: 'date_schedule'
            }
          ]
        }
      }
      schedules = [
        ...schedules,
        ...filteredDateSchedules
      ];
    } else {
      for (let i = 0; i < data.dateSchedules.length; i++) {
        if (!(activeNav !== 'exams' && data.dateSchedules[i].type === 'Exam')) {
          schedules.push({
            date: data.dateSchedules[i].schedule_date,
            start_time: data.dateSchedules[i].start_time,
            end_time: data.dateSchedules[i].end_time,
            schedule_from: 'date_schedule'
          });
        }
      }
    }

    if (currentAsyncDate && !currentAsyncDateSet) {
      schedules = [...schedules, currentAsyncDate];
    }
    schedules.sort((a, b) => {
      let aDate = moment(a.date);
      let bDate = moment(b.date);

      if (aDate.isAfter(bDate)) {
        return 1;
      } else if (aDate.isBefore(bDate)) {
        return -1;
      }
      return 0;
    });

    if (schedules.length > 0) {
      if (moment(schedules[schedules.length-1].date).isAfter(baseDate.clone().add(14, 'days'))) {
        axiosRequest('get', `faculty/class/${classID}/material/async-schedules/material/between/${schedules[0].date}/${schedules[schedules.length-1].date}`, null, ({ data: { data: newData }}) => {
          schedules = schedules.filter(s => {
            return !newData.find(nd => {
              if (currentAsyncDate) {
                return (
                  nd.schedule_date !== currentAsyncDate.date &&
                  nd.start_time !== currentAsyncDate.start_time &&
                  nd.end_time !== currentAsyncDate.end_time &&
                  nd.schedule_from !== currentAsyncDate.schedule_from
                );
              }

              return (
                nd.schedule_date === s.date &&
                nd.start_time === s.start_time &&
                nd.end_time === s.end_time &&
                nd.schedule_from === s.schedule_from
              );
            });
          });

          let asyncDate = currentAsyncDate;
          if (!currentAsyncDate) {
            asyncDate = schedules.find(as => moment().isSameOrBefore(as.date));
          }

          asyncDate = asyncDate ? {...asyncDate} : null;
          data.asyncSchedules = [...data.asyncSchedules, ...newData];
          let newAsyncSchedules = [...this.state.asyncSchedules].map(as => {
            if (as.classID === classID) {
              return {
                ...as,
                schedules
              };
            }

            return {...as};
          });
          let newAsyncSchedulesRaw = [...this.state.asyncSchedulesRaw].map(asr => {
            if (asr.classID === classID) {
              return {
                ...asr,
                schedules: {
                  ...data
                }
              };
            }

            return {...asr};
          });
          this.setState({
            ...this.state,
            asyncSchedulesRaw: newAsyncSchedulesRaw,
            asyncSchedules: newAsyncSchedules
          }, () => {
            cb(true, asyncDate);
          });
        }, error => {
          cb(false);
        }, this.props.history);
      } else {
        let asyncDate = currentAsyncDate ? currentAsyncDate : schedules.find(as => moment().isSameOrBefore(as.date));
        asyncDate = asyncDate ? {...asyncDate} : null;

        let newAsyncSchedules = [...this.state.asyncSchedules].map(as => {
          if (as.classID === classID) {
            return {
              ...as,
              schedules
            };
          }

          return {...as};
        });
        let newAsyncSchedulesRaw = [...this.state.asyncSchedulesRaw].map(asr => {
          if (asr.classID === classID) {
            return {
              ...asr,
              schedules: {
                ...data
              }
            };
          }

          return {...asr};
        });

        this.setState({
          ...this.state,
          asyncSchedulesRaw: newAsyncSchedulesRaw,
          asyncSchedules: newAsyncSchedules
        }, () => {
          cb(true, asyncDate);
        });
      }
    } else {
      cb(false);
    }
  }
  handlePrevAsync = (classID, asyncDate, cb = () => {}) => {
    const { asyncSchedules, asyncSchedulesRaw } = this.state;
    const { activeNav } = this.props.match.params;

    let newAsyncDate = null;
    let asyncSchedule = [...asyncSchedules].find(as => as.classID === classID);
    let asyncScheduleRaw = [...asyncSchedulesRaw].find(asr => asr.classID === classID);
  
    if (asyncSchedule && asyncScheduleRaw && asyncDate) {
      let schedules = asyncSchedule.schedules.map(s => ({...s}));
      let { dateSchedules, weeklySchedules, asyncSchedules: materialAsyncSchedules } = asyncScheduleRaw.schedules;

      let index = asyncSchedule.schedules.findIndex(as1 => as1.date === asyncDate.date && as1.start_time === asyncDate.start_time && as1.end_time === asyncDate.end_time && as1.schedule_from === asyncDate.schedule_from);
      if (index === 0) {
        if (weeklySchedules.length > 0) {
          let firstDate = moment(asyncDate.date).startOf('week').format('YYYY-MM-DD');
          axiosRequest('get', `faculty/class/${classID}/material/async-schedules/material/prev/${firstDate}`, null, ({ data: { data }}) => {
            let tempAsyncSchedules = [];
            let minDate = null;
            for (let i = 0; i < weeklySchedules.length; i++) {
              let firstSchedule = asyncSchedule.schedules.find(as2 => moment(as2.date).format('dddd') === weeklySchedules[i].day);
              let tempDate = moment(asyncSchedule.schedules[0].date).day(weeklySchedules[i].day);

              if (firstSchedule) {
                tempDate = moment(firstSchedule.date);
              }

              let tempSchedules = [];
              while (tempSchedules.length < 5 || minDate) {
                tempDate.subtract(7, 'days');
                if (minDate && tempDate.isSameOrBefore(minDate, 'day')) {
                  break;
                }
                if (data && data.length > 0) {
                  let temp = data.find(das => {
                    return das.schedule_date === tempDate.format('YYYY-MM-DD') &&
                            das.start_time === weeklySchedules[i].start_time &&
                            das.end_time === weeklySchedules[i].end_time &&
                            das.schedule_from === 'weekly_schedule';
                  });
                  if (!temp) {
                    tempSchedules = [
                      {
                        date: tempDate.format('YYYY-MM-DD'),
                        start_time: weeklySchedules[i].start_time,
                        end_time: weeklySchedules[i].end_time,
                        schedule_from: 'weekly_schedule'
                      },
                      ...tempSchedules
                    ];
                  }
                } else {
                  tempSchedules = [
                    {
                      date: tempDate.format('YYYY-MM-DD'),
                      start_time: weeklySchedules[i].start_time,
                      end_time: weeklySchedules[i].end_time,
                      schedule_from: 'weekly_schedule'
                    },
                    ...tempSchedules
                  ];
                }
              }
              if (!minDate) {
                minDate = tempSchedules[0].date;
              }
              tempAsyncSchedules = [...tempSchedules, ...tempAsyncSchedules];
            }

            tempAsyncSchedules.sort((a, b) => {
              let aDate = moment(a.date);
              let bDate = moment(b.date);
        
              if (aDate.isAfter(bDate)) {
                return 1;
              } else if (aDate.isBefore(bDate)) {
                return -1;
              }
              return 0;
            });

            if (tempAsyncSchedules.length > 0) {
              let startSchedule = tempAsyncSchedules[0];
              let endSchedule = tempAsyncSchedules[tempAsyncSchedules.length-1];
              for (let i = 0; i < dateSchedules.length; i++) {
                if (activeNav !== 'exams' && dateSchedules[i].type === 'Exam') {
                  continue;
                }
                if (moment(dateSchedules[i].schedule_date).isBetween(startSchedule.date, endSchedule.date, 'day', '[]')) {
                  let tempDsIndex = tempAsyncSchedules.findIndex(s => dateSchedules[i].schedule_date === s.date);
                  if (tempDsIndex !== -1) {
                    tempAsyncSchedules[tempDsIndex] = {
                      date: dateSchedules[i].schedule_date,
                      start_time: dateSchedules[i].start_time,
                      end_time: dateSchedules[i].end_time,
                      schedule_from: 'date_schedule'
                    };
                  } else {
                    if (data && data.length > 0) {
                      let temp = data.find(das => {
                        return das.schedule_date === dateSchedules[i].schedule_date &&
                                das.start_time === dateSchedules[i].start_time &&
                                das.end_time === dateSchedules[i].end_time &&
                                das.schedule_from === 'date_schedule';
                      });
                      if (!temp) {
                        tempAsyncSchedules = [
                          ...tempAsyncSchedules,
                          {
                            date: dateSchedules[i].schedule_date,
                            start_time: dateSchedules[i].start_time,
                            end_time: dateSchedules[i].end_time,
                            schedule_from: 'date_schedule'
                          }
                        ];
                      }
                    } else {
                      tempAsyncSchedules = [
                        ...tempAsyncSchedules,
                        {
                          date: dateSchedules[i].schedule_date,
                          start_time: dateSchedules[i].start_time,
                          end_time: dateSchedules[i].end_time,
                          schedule_from: 'date_schedule'
                        }
                      ];
                    }
                  }
                } else if (moment(dateSchedules[i].schedule_date).isBetween(moment(startSchedule.date).subtract(7, 'days'), moment(endSchedule.date).add(7, 'days'), 'day', '()')) {
                  tempAsyncSchedules = [
                    ...tempAsyncSchedules,
                    {
                      date: dateSchedules[i].schedule_date,
                      start_time: dateSchedules[i].start_time,
                      end_time: dateSchedules[i].end_time,
                      schedule_from: 'date_schedule'
                    }
                  ];
                }
              }
            }

            if (tempAsyncSchedules.length > 0) {
              tempAsyncSchedules.sort((a, b) => {
                let aDate = moment(a.date);
                let bDate = moment(b.date);

                if (aDate.isAfter(bDate)) {
                  return 1;
                } else if (aDate.isBefore(bDate)) {
                  return -1;
                }
                return 0;
              });

              if (moment(tempAsyncSchedules[0].date).isBefore(moment(asyncDate.date).subtract(35, 'days'))) {
                axiosRequest('get', `faculty/class/${classID}/material/async-schedules/material/between/${tempAsyncSchedules[0].date}/${tempAsyncSchedules[tempAsyncSchedules.length-1].date}`, null, ({ data: { data: newData }}) => {
                  tempAsyncSchedules = tempAsyncSchedules.filter(tas => {
                    return !newData.find(nd => {
                      return (
                        nd.schedule_date === tas.date &&
                        nd.start_time === tas.start_time &&
                        nd.end_time === tas.end_time &&
                        nd.schedule_from === tas.schedule_from
                      );
                    });
                  });
                  
                  newAsyncDate = {...tempAsyncSchedules[tempAsyncSchedules.length-1]};
                  schedules = [...tempAsyncSchedules, ...schedules];

                  let newAsyncSchedules = [...this.state.asyncSchedules].map(as => {
                    if (as.classID === classID) {
                      return {
                        ...as,
                        schedules
                      };
                    }
          
                    return {...as};
                  });
                  let newAsyncSchedulesRaw = [...this.state.asyncSchedulesRaw].map(asr => {
                    if (asr.classID === classID) {
                      return {
                        ...asr,
                        schedules: {
                          ...asr.schedules,
                          asyncSchedules: [...materialAsyncSchedules, ...data]
                        }
                      };
                    }
          
                    return {...asr};
                  });

                  this.setState({
                    ...this.state,
                    asyncSchedules: newAsyncSchedules,
                    asyncSchedulesRaw: newAsyncSchedulesRaw
                  }, () => {
                    cb(newAsyncDate);
                  });
                }, error => {}, this.props.history);
              } else {
                newAsyncDate = {...tempAsyncSchedules[tempAsyncSchedules.length-1]};

                let newAsyncSchedules = [...this.state.asyncSchedules].map(as => {
                  if (as.classID === classID) {
                    return {
                      ...as,
                      schedules: [...tempAsyncSchedules, ...schedules]
                    };
                  }
        
                  return {...as};
                });
                let newAsyncSchedulesRaw = [...this.state.asyncSchedulesRaw].map(asr => {
                  if (asr.classID === classID) {
                    return {
                      ...asr,
                      schedules: {
                        ...asr.schedules,
                        asyncSchedules: [...materialAsyncSchedules, ...data]
                      }
                    };
                  }
        
                  return {...asr};
                });
  
                this.setState({
                  ...this.state,
                  asyncSchedules: newAsyncSchedules,
                  asyncSchedulesRaw: newAsyncSchedulesRaw
                }, () => {
                  cb(newAsyncDate);
                });
              }
            }
          }, error => {
            cb(null);
          }, this.props.history);
        } else {
          cb(null);
        }
      } else {
        cb({...schedules[index-1]});
      }
    } else {
      cb(null);
    }
  }
  handleNextAsync = (classID, asyncDate, cb = () => {}) => {
    const { asyncSchedules, asyncSchedulesRaw } = this.state;
    const { activeNav } = this.props.match.params;

    let newAsyncDate = null;
    let asyncSchedule = [...asyncSchedules].find(as => as.classID === classID);
    let asyncScheduleRaw = [...asyncSchedulesRaw].find(asr => asr.classID === classID);

    if (asyncSchedule && asyncScheduleRaw && asyncDate) {
      let schedules = asyncSchedule.schedules.map(s => ({...s}));
      let { dateSchedules, weeklySchedules, asyncSchedules: materialAsyncSchedules } = asyncScheduleRaw.schedules;
      let index = asyncSchedule.schedules.findIndex(as1 => as1.date === asyncDate.date && as1.start_time === asyncDate.start_time && as1.end_time === asyncDate.end_time && as1.schedule_from === asyncDate.schedule_from);

      if (index === asyncSchedule.schedules.length-1) {
        if (weeklySchedules.length > 0) {
          let lastDate = moment(asyncDate.date).startOf('week').format('YYYY-MM-DD');
          axiosRequest('get', `faculty/class/${classID}/material/async-schedules/material/next/${lastDate}`, null, ({ data: { data }}) => {
            let tempAsyncSchedules = [];
            let maxDate = null;
            let reversedAsyncSchedules = [...asyncSchedule.schedules].reverse();
            for (let i = 0; i < weeklySchedules.length; i++) {
              let lastSchedule = reversedAsyncSchedules.find(as2 => moment(as2.date).format('dddd') === weeklySchedules[i].day);
              let tempDate = moment(reversedAsyncSchedules[0].date).day(weeklySchedules[i].day);

              if (lastSchedule) {
                tempDate = moment(lastSchedule.date);
              }

              let tempSchedules = [];
              while (tempSchedules.length < 5 || maxDate) {
                tempDate.add(7, 'days');
                if (maxDate && tempDate.isSameOrAfter(maxDate)) {
                  break;
                }
                if (data && data.length > 0) {
                  let temp = data.find(das => {
                    return das.schedule_date === tempDate.format('YYYY-MM-DD') &&
                            das.start_time === weeklySchedules[i].start_time &&
                            das.end_time === weeklySchedules[i].end_time &&
                            das.schedule_from === 'weekly_schedule';
                  });
                  if (!temp) {
                    tempSchedules = [
                      ...tempSchedules,
                      {
                        date: tempDate.format('YYYY-MM-DD'),
                        start_time: weeklySchedules[i].start_time,
                        end_time: weeklySchedules[i].end_time,
                        schedule_from: 'weekly_schedule'
                      }
                    ];
                  }
                } else {
                  tempSchedules = [
                    ...tempSchedules,
                    {
                      date: tempDate.format('YYYY-MM-DD'),
                      start_time: weeklySchedules[i].start_time,
                      end_time: weeklySchedules[i].end_time,
                      schedule_from: 'weekly_schedule'
                    }
                  ];
                }
              }
              if (!maxDate) {
                maxDate = tempSchedules[tempSchedules.length-1].date;
              }
              tempAsyncSchedules = [...tempAsyncSchedules, ...tempSchedules];
            }

            tempAsyncSchedules.sort((a, b) => {
              let aDate = moment(a.date);
              let bDate = moment(b.date);

              if (aDate.isAfter(bDate)) {
                return 1;
              } else if (aDate.isBefore(bDate)) {
                return -1;
              }
              return 0;
            });

            if (tempAsyncSchedules.length > 0) {
              let startSchedule = tempAsyncSchedules[0];
              let endSchedule = tempAsyncSchedules[tempAsyncSchedules.length-1];
              for (let i = 0; i < dateSchedules.length; i++) {
                if (activeNav !== 'exams' && dateSchedules[i].type === 'Exam') {
                  continue;
                }
                if (moment(dateSchedules[i].schedule_date).isBetween(startSchedule.date, endSchedule.date, 'day', '[]')) {
                  let tempDsIndex = tempAsyncSchedules.findIndex(s => dateSchedules[i].schedule_date === s.date);
                  if (tempDsIndex !== -1) {
                    tempAsyncSchedules[tempDsIndex] = {
                      date: dateSchedules[i].schedule_date,
                      start_time: dateSchedules[i].start_time,
                      end_time: dateSchedules[i].end_time,
                      schedule_from: 'date_schedule'
                    };
                  } else {
                    if (data && data.length > 0) {
                      let temp = data.find(das => {
                        return das.schedule_date === dateSchedules[i].schedule_date &&
                                das.start_time === dateSchedules[i].start_time &&
                                das.end_time === dateSchedules[i].end_time &&
                                das.schedule_from === 'date_schedule';
                      });
                      if (!temp) {
                        tempAsyncSchedules = [
                          {
                            date: dateSchedules[i].schedule_date,
                            start_time: dateSchedules[i].start_time,
                            end_time: dateSchedules[i].end_time,
                            schedule_from: 'date_schedule'
                          },
                          ...tempAsyncSchedules
                        ];
                      }
                    } else {
                      tempAsyncSchedules = [
                        {
                          date: dateSchedules[i].schedule_date,
                          start_time: dateSchedules[i].start_time,
                          end_time: dateSchedules[i].end_time,
                          schedule_from: 'date_schedule'
                        },
                        ...tempAsyncSchedules
                      ];
                    }
                  }
                } else if (moment(dateSchedules[i].schedule_date).isBetween(moment(startSchedule.date).subtract(7, 'days'), moment(endSchedule.date).add(7, 'days'), 'day', '()')) {
                  tempAsyncSchedules = [
                    ...tempAsyncSchedules,
                    {
                      date: dateSchedules[i].schedule_date,
                      start_time: dateSchedules[i].start_time,
                      end_time: dateSchedules[i].end_time,
                      schedule_from: 'date_schedule'
                    }
                  ];
                }
              }
            }

            if (tempAsyncSchedules.length > 0) {
              tempAsyncSchedules.sort((a, b) => {
                let aDate = moment(a.date);
                let bDate = moment(b.date);

                if (aDate.isAfter(bDate)) {
                  return 1;
                } else if (aDate.isBefore(bDate)) {
                  return -1;
                }
                return 0;
              });

              if (moment(tempAsyncSchedules[tempAsyncSchedules.length-1].date).isAfter(moment(asyncDate.date).add(35, 'days'))) {
                axiosRequest('get', `faculty/class/${classID}/material/async-schedules/material/between/${tempAsyncSchedules[0].date}/${tempAsyncSchedules[tempAsyncSchedules.length-1].date}`, null, ({ data: { data: newData }}) => {
                  tempAsyncSchedules = tempAsyncSchedules.filter(tas => {
                    return !newData.find(nd => {
                      return (
                        nd.schedule_date === tas.date &&
                        nd.start_time === tas.start_time &&
                        nd.end_time === tas.end_time &&
                        nd.schedule_from === tas.schedule_from
                      );
                    });
                  });
                  
                  newAsyncDate = {...tempAsyncSchedules[0]};
                  schedules = [...schedules, ...tempAsyncSchedules];

                  let newAsyncSchedules = [...this.state.asyncSchedules].map(as => {
                    if (as.classID === classID) {
                      return {
                        ...as,
                        schedules
                      };
                    }
          
                    return {...as};
                  });
                  let newAsyncSchedulesRaw = [...this.state.asyncSchedulesRaw].map(asr => {
                    if (asr.classID === classID) {
                      return {
                        ...asr,
                        schedules: {
                          ...asr.schedules,
                          asyncSchedules: [...materialAsyncSchedules, ...data]
                        }
                      };
                    }
          
                    return {...asr};
                  });
                  
                  this.setState({
                    ...this.state,
                    asyncSchedules: newAsyncSchedules,
                    asyncSchedulesRaw: newAsyncSchedulesRaw
                  }, () => {
                    cb(newAsyncDate);
                  });
                }, error => {}, this.props.history);
              } else {
                newAsyncDate = {...tempAsyncSchedules[0]};
                schedules = [...schedules, ...tempAsyncSchedules];

                let newAsyncSchedules = [...this.state.asyncSchedules].map(as => {
                  if (as.classID === classID) {
                    return {
                      ...as,
                      schedules
                    };
                  }
        
                  return {...as};
                });
                let newAsyncSchedulesRaw = [...this.state.asyncSchedulesRaw].map(asr => {
                  if (asr.classID === classID) {
                    return {
                      ...asr,
                      schedules: {
                        ...asr.schedules,
                        asyncSchedules: [...materialAsyncSchedules, ...data]
                      }
                    };
                  }
        
                  return {...asr};
                });
                
                this.setState({
                  ...this.state,
                  asyncSchedules: newAsyncSchedules,
                  asyncSchedulesRaw: newAsyncSchedulesRaw
                }, () => {
                  cb(newAsyncDate);
                });
              }
            }
          }, error => {}, this.props.history);
        } else {
          cb(null);
        }
      } else {
        cb({...schedules[index+1]});
      }
    } else {
      cb(null);
    }
  }
  handleResetAsync = () => {
    const { audiences } = this.state;

    let asyncSchedules = audiences.map(facultyLoad => ({
      classID: facultyLoad.id,
      schedules: []
    }));
    let asyncSchedulesRaw = audiences.map(facultyLoad => ({
      classID: facultyLoad.id,
      schedules: {
        dateSchedules: [],
        weeklySchedules: [],
        asyncSchedules: []
      }
    }));

    this.setState({
      ...this.state,
      asyncSchedules,
      asyncSchedulesRaw
    });
  }
  handleSelect = key => {
    this.props.history.push(key);
  }
  handleFetch = (data, type) => {
    this.setState({
      ...this.state,
      [type]: data
    });
  }
  handleCreate = (data, type) => {
    let list = {...{...this.state}[type]};
    const newList = [data, ...list.data];
    this.setState({
      ...this.state,
      [type]: {
        ...list,
        data: newList
      }
    });
  }
  handleUpdate = (data, type) => {
    let list = {...{...this.state}[type]};
    const newList = [...list.data].map(l => {
      if (l.id === data.id) {
        return {
          ...l,
          ...data
        };
      }

      return l;
    });
    this.setState({
      ...this.state,
      [type]: {
        ...list,
        data: newList
      }
    });
  }
  handleDelete = (id, type) => {
    let list = {...{...this.state}[type]};
    const newList = [...list.data].filter(l => l.id !== id);
    this.setState({
      ...this.state,
      [type]: {
        ...list,
        data: newList
      }
    });
  }
  handleNextPage = (data, type) => {
    this.setState({
      ...this.state,
      [type]: {
        ...this.state[type],
        data: [
          ...this.state[type].data,
          ...data.data
        ]
      }
    });
  }
  renderContent = () => {
    const { isLoading, errorMessage, audiences, assignments, quizzes, exams, files, links, asyncSchedules } = this.state;
    const { activeNav } = this.props.match.params;

    if (isLoading) {
      return (
        <Card.Body>
          <LoadingIcon lg />
        </Card.Body>
      );
    }

    if (errorMessage) {
      return (
        <Card.Body>
          <Alert variant='danger'>
            {errorMessage}
          </Alert>
        </Card.Body>
      );
    }

    return (
      <div className='border rounded pb-4 my-3'>
        <div className='mb-3'>
          <Nav variant='tabs bg-light rounded-top' className='flex-column flex-md-row pt-3 px-3'>
            <Nav.Item>
              <Nav.Link eventKey='assignments' onSelect={this.handleSelect} active={activeNav === 'assignments'}>Assignments</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='quizzes' onSelect={this.handleSelect} active={activeNav === 'quizzes'}>Quizzes</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='exams' onSelect={this.handleSelect} active={activeNav === 'exams'}>Exams</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='files' onSelect={this.handleSelect} active={activeNav === 'files'}>Files</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='links' onSelect={this.handleSelect} active={activeNav === 'links'}>Links</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className='px-4'>
          <Switch>
            <Route
              exact
              path='/materials/assignments'
              render={props => (
                <Assignments
                  {...props}
                  data={assignments}
                  audiences={audiences}
                  asyncSchedules={asyncSchedules}
                  onFetch={this.handleFetch}
                  onCreate={this.handleCreate}
                  onUpdate={this.handleUpdate}
                  onDelete={this.handleDelete}
                  setAsyncSchedules={this.setAsyncSchedules}
                  onPrevAsync={this.handlePrevAsync}
                  onNextAsync={this.handleNextAsync}
                  onResetAsync={this.handleResetAsync}
                  onNextPage={this.handleNextPage} />
              )} />
            <Route
              exact
              path='/materials/quizzes'
              render={props => (
                <Quizzes
                  {...props}
                  data={quizzes}
                  audiences={audiences}
                  asyncSchedules={asyncSchedules}
                  onFetch={this.handleFetch}
                  onCreate={this.handleCreate}
                  onUpdate={this.handleUpdate}
                  onDelete={this.handleDelete}
                  setAsyncSchedules={this.setAsyncSchedules}
                  onPrevAsync={this.handlePrevAsync}
                  onNextAsync={this.handleNextAsync}
                  onResetAsync={this.handleResetAsync}
                  onNextPage={this.handleNextPage} />
              )} />
            <Route
              exact
              path='/materials/exams'
              render={props => (
                <Exams
                  {...props}
                  data={exams}
                  audiences={audiences}
                  asyncSchedules={asyncSchedules}
                  onFetch={this.handleFetch}
                  onCreate={this.handleCreate}
                  onUpdate={this.handleUpdate}
                  onDelete={this.handleDelete}
                  setAsyncSchedules={this.setAsyncSchedules}
                  onPrevAsync={this.handlePrevAsync}
                  onNextAsync={this.handleNextAsync}
                  onResetAsync={this.handleResetAsync}
                  onNextPage={this.handleNextPage} />
              )} />
            <Route
              exact
              path='/materials/files'
              render={props => (
                <Files
                  {...props}
                  data={files}
                  audiences={audiences}
                  onFetch={this.handleFetch}
                  onCreate={this.handleCreate}
                  onUpdate={this.handleUpdate}
                  onDelete={this.handleDelete}
                  onNextPage={this.handleNextPage} />
              )} />
            <Route
              exact
              path='/materials/links'
              render={props => (
                <Links
                  {...props}
                  data={links}
                  audiences={audiences}
                  onFetch={this.handleFetch}
                  onCreate={this.handleCreate}
                  onUpdate={this.handleUpdate}
                  onDelete={this.handleDelete}
                  onNextPage={this.handleNextPage} />
              )} />
            <Redirect to={{ pathname: '/materials/assignments' }} />
          </Switch>
        </div>
      </div>
    );
  }
  render() {
    return (
      <>
        <Header active='Materials' />
        <Container>
          {this.renderContent()}
        </Container>
      </>
    );
  }
}