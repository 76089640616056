import React, { Component } from 'react';
import { Alert, Button, ButtonGroup, Form, Modal, Nav, OverlayTrigger, ProgressBar, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NameLink from '../../../../common/NameLink/NameLink';
import Validator from 'validatorjs';
import axios from 'axios';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import './style.scss';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

export default class Grades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      selectedTerm: '',
      editFacultyLoadID: '',
      formInputs: [],
      isColumnLoading: false,
      columnError: '',
      grades: [],
      enabledGrades: [],
      importModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      },
      accessibilityModal: {
        show: false,
        type: '',
        student: null,
        isLoading: false,
        errorMessage: ''
      }
    };

    this.importFile = [];
  }
  componentDidMount() {
    const { schoolClass: { id, terms } } = this.props;

    this.setState({
      ...this.state,
      isLoading: true,
      selectedTerm: terms && terms.length > 0 ? terms[0].id : ''
    }, () => {
      axiosRequest('get', `faculty/class-advisory/${id}/grades`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          ...data,
          isLoading: false
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleSelectTerm = selectedTerm => {
    this.setState({
      ...this.state,
      selectedTerm
    });
  }
  handleEditColumn = editFacultyLoadID => {
    const { grades, selectedTerm } = this.state;
    const { schoolClass: { students } } = this.props;

    let formInputs = students.map(st => {
      let grade = grades.find(g => g.student_id === st.id && g.term_id === +selectedTerm && g.faculty_load_id === +editFacultyLoadID);
      grade = grade ? grade.grade : ''
      return {
        studentID: st.id,
        grade
      };
    });

    this.setState({
      ...this.state,
      editFacultyLoadID,
      formInputs
    });
  }
  handleCancelEditColumn = () => {
    this.setState({
      ...this.state,
      editFacultyLoadID: '',
      formInputs: [],
      isColumnLoading: false,
      columnError: ''
    });
  }
  handleInputChange = (e, facultyLoadID, studentID) => {
    const { editFacultyLoadID } = this.state;

    if (facultyLoadID === +editFacultyLoadID) {
      let newFormInputs = [...this.state.formInputs].map(inp => {
        if (inp.studentID === studentID) {
          return {
            ...inp,
            grade: e.target.value
          };
        }
        return {...inp};
      });
      this.setState({
        ...this.state,
        formInputs: newFormInputs
      });
    }

    return false;
  }
  handleSaveEditColumn = e => {
    this.setState({
      ...this.state,
      isColumnLoading: true,
      columnError: ''
    }, () => {
      const { formInputs, selectedTerm, editFacultyLoadID } = this.state;
      const { schoolClass } = this.props;

      let formData = {
        termID: selectedTerm,
        facultyLoadID: editFacultyLoadID,
        termGrades: formInputs
      };

      let validator = new Validator(formData, {
        termID: 'required|integer',
        facultyLoadID: 'required|integer',
        termGrades: 'required|array',
        'termGrades.*.studentID': 'required|integer',
        'termGrades.*.grade': 'present'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          isColumnLoading: false,
          columnError: validator.errors.errors[firstKey][0]
        });
        return;
      }

      axiosRequest('post', `faculty/class-advisory/${schoolClass.id}`, formData, ({ data: { data }}) => {
        const { grades } = this.state;

        let newGrades = [...grades];
        if (grades.length > 0) {
          newGrades = [...grades].filter(g => !(g.term_id === +selectedTerm && g.faculty_load_id === +editFacultyLoadID));
          newGrades = [
            ...newGrades,
            ...data
          ];
        } else {
          newGrades = data;
        }
        this.setState({
          ...this.state,
          isColumnLoading: false,
          grades: newGrades,
          editFacultyLoadID: '',
          formInputs: []
        });
      }, error => {
        this.setState({
          ...this.state,
          isColumnLoading: false,
          columnError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showModal = () => {
    this.setState({
      ...this.state,
      importModal: {
        ...this.state.importModal,
        show: true
      }
    });
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      importModal: {
        ...this.state.importModal,
        show: false
      }
    });
  }
  handleFileSelect = event => {
    this.importFile = event.target.files;
  }
  handleUpload = event => {
    event.preventDefault();

    if (this.importFile.length > 0) {
      this.setState({
        ...this.state,
        importModal: {
          ...this.state.importModal,
          isLoading: true,
          errorMessage: '',
          successMessage: ''
        }
      }, () => {
        const { selectedTerm, grades } = this.state;
        const { schoolClass } = this.props;

        const formData = new window.FormData();
        formData.append('file', this.importFile[0], this.importFile[0].name);

        let url = (schoolClass.year_level.school_level.title === 'Senior High School' && selectedTerm) ?
                    `${process.env['REACT_APP_API_BASE_URL']}/faculty/class-advisory/${schoolClass.id}/grades/term/${selectedTerm}/import` :
                    `${process.env['REACT_APP_API_BASE_URL']}/faculty/class-advisory/${schoolClass.id}/grades/import`;
        axios.post(url, formData, {
          withCredentials: true,
          header: {
            'content-type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.setState({
              ...this.state,
              importModal: {
                ...this.state.importModal,
                isLoading: percentCompleted
              }
            });
          }
        }).then(({ data: { data, message }}) => {
          let newGrades = [...grades].filter(g => (
            !data.find(d => d.student_id === g.student_id && d.term_id === g.term_id && d.faculty_load_id === g.faculty_load_id)
          ));
          newGrades = [...newGrades, ...data];
          this.setState({
            ...this.state,
            editFacultyLoadID: '',
            formInputs: [],
            grades: newGrades,
            importModal: {
              ...this.state.importModal,
              isLoading: false,
              errorMessage: '',
              successMessage: message
            }
          }, () => {
            this.importFile = [];
          });
        }).catch((error) => {
          if (error.response && error.response.status === 403) {
            this.props.history.push('/login');
          }
          this.setState({
            ...this.state,
            importModal: {
              ...this.state.importModal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        });
      });
    } else {
      this.setState({
        ...this.state,
        importModal: {
          ...this.state.importModal,
          isLoading: false,
          errorMessage: 'The import file is required.'
        }
      });
    }
  }
  showAccessibilityModal = (type, student = null) => {
    const { selectedTerm, enabledGrades } = this.state;

    if (student) {
      let enabledGrade = enabledGrades.find(eg => eg.term_id === +selectedTerm && eg.student_id === student.id);
      if ((type === 'enable' && enabledGrade) || (type === 'disable' && !enabledGrade)) {
        return false;
      }
    }

    this.setState({
      ...this.state,
      accessibilityModal: {
        show: true,
        type,
        student,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideAccessibilityModal = () => {
    this.setState({
      ...this.state,
      accessibilityModal: {
        show: false,
        type: '',
        student: null,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleAccessibility = e => {
    this.setState({
      ...this.state,
      accessibilityModal: {
        ...this.state.accessibilityModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { selectedTerm, accessibilityModal: { type, student }, enabledGrades } = this.state;
      const { schoolClass } = this.props;
      if (student) {
        axiosRequest('post', `faculty/class-advisory/${schoolClass.id}/grades/accessibility/term/${selectedTerm}/student/${student.id}`, {
          type
        }, ({ data: { data }}) => {
          let newEnabledGrades = [];
          if (type === 'enable') {
            newEnabledGrades = [...enabledGrades, data];
          } else {
            newEnabledGrades = [...enabledGrades].filter(eg => !(eg.term_id === +selectedTerm && eg.student_id === student.id));
          }
          this.setState({
            ...this.state,
            enabledGrades: [...newEnabledGrades],
            accessibilityModal: {
              show: false,
              type: '',
              student: null,
              isLoading: false,
              errorMessage: ''
            }
          });
        }, error => {
          this.setState({
            ...this.state,
            accessibilityModal: {
              ...this.state.accessibilityModal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        }, this.props.history);
      } else {
        axiosRequest('post', `faculty/class-advisory/${schoolClass.id}/grades/accessibility/term/${selectedTerm}`, {
          type
        }, ({ data: { data }}) => {
          let newEnabledGrades = [];
          if (type === 'enable') {
            newEnabledGrades = [...enabledGrades].filter(eg => eg.term_id !== +selectedTerm);
            newEnabledGrades = [...newEnabledGrades, ...data];
          } else {
            newEnabledGrades = [...enabledGrades].filter(eg => eg.term_id !== +selectedTerm);
          }
          this.setState({
            ...this.state,
            enabledGrades: [...newEnabledGrades],
            accessibilityModal: {
              show: false,
              type: '',
              student: null,
              isLoading: false,
              errorMessage: ''
            }
          });
        }, error => {
          this.setState({
            ...this.state,
            accessibilityModal: {
              ...this.state.accessibilityModal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        }, this.props.history);
      }
    });
  }
  renderGrade = (facultyLoadID, studentID) => {
    const { editFacultyLoadID, formInputs, isColumnLoading, grades, selectedTerm } = this.state;

    if (facultyLoadID === +editFacultyLoadID) {
      let grade = formInputs.find(inp => inp.studentID === studentID);
      grade = grade ? grade.grade : '';
      return (
        <Form.Control type='text' size='sm' className='text-center' value={grade} onChange={e => this.handleInputChange(e, facultyLoadID, studentID)} disabled={isColumnLoading} />
      );
    }

    let savedGrade = grades.find(g => g.student_id === studentID && g.term_id === +selectedTerm && g.faculty_load_id === +facultyLoadID);
    if (savedGrade) {
      return savedGrade.grade;
    }

    return null;
  }
  renderContent() {
    const {
      editFacultyLoadID,
      isColumnLoading,
      columnError,
      // importModal,
      selectedTerm,
      enabledGrades
    } = this.state;
    const { schoolClass: { students, facultyLoads, year_level } } = this.props;

    let male = students.filter(s => s.gender === 'Male');
    let female = students.filter(s => s.gender === 'Female');

    return (
      <Table className='mt-3' bordered hover responsive>
        <thead>
          <tr>
            <th></th>
            {
              year_level.school_level.term_grades ? (
                <th className='text-center' style={{ width: '10rem' }}>
                  <div>Accessibility</div>
                  <div className='mt-2'>
                    <ButtonGroup>
                      <Button variant='outline-green' size='sm' onClick={e => this.showAccessibilityModal('enable')}>
                        Enable
                      </Button>
                      <Button variant='outline-danger' size='sm' onClick={e => this.showAccessibilityModal('disable')}>
                        Disable
                      </Button>
                    </ButtonGroup>
                  </div>
                </th>
              ) : null
            }
            {
              facultyLoads.map(facultyLoad => (
                <th key={facultyLoad.id} style={{ width: '7.5rem' }}>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <span className='mr-1' style={{ cursor: 'pointer' }}>
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            <div>
                              {facultyLoad.class_course.course.description}
                            </div>
                            <div>
                              {facultyLoad.profile.name}
                            </div>
                          </Tooltip>
                        }
                        trigger={['hover', 'focus']}>
                        <FontAwesomeIcon icon='info-circle' className='text-info' />
                      </OverlayTrigger>
                    </span>
                    {facultyLoad.class_course.course.code}
                  </div>
                  {
                    facultyLoad.id === +editFacultyLoadID ? (
                      <>
                        <div className='d-flex mt-2'>
                          <div className='pr-1 flex-fill'>
                            {
                              isColumnLoading ? (
                                <Button variant='green' size='sm' block disabled>
                                  <LoadingIcon />
                                </Button>
                              ) : columnError ? (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {columnError}
                                    </Tooltip>
                                  }
                                  trigger={['hover', 'focus']}>
                                  <Button variant='green' size='sm' block onClick={this.handleSaveEditColumn} className='course-column-error'>
                                    <FontAwesomeIcon icon='exclamation' /> <span className='refresh-icon'><FontAwesomeIcon icon='redo' /></span>
                                  </Button>
                                </OverlayTrigger>
                              ) : (
                                <Button variant='green' size='sm' block title='Save' onClick={this.handleSaveEditColumn}>
                                  <FontAwesomeIcon icon='check' />
                                </Button>
                              )
                            }
                          </div>
                          <div className='pl-1 flex-fill'>
                            <Button variant='danger' size='sm' block title='Cancel' onClick={this.handleCancelEditColumn} disabled={isColumnLoading}>
                              <FontAwesomeIcon icon='times' />
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div>
                        {/* <div className='mt-2'>
                          <Button variant='info' size='sm' block title='Edit column' onClick={e => this.handleEditColumn(facultyLoad.id)} disabled={!!editFacultyLoadID || importModal.isLoading}>
                            <FontAwesomeIcon icon='pencil-alt' />
                          </Button>
                        </div> */}
                      </div>
                    )
                  }
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            male.length > 0 && (
              <>
                <tr className='text-white bg-green'>
                  <th className='py-1 text-center'>
                    <div className='mb-0'>Male</div>
                    <small className='font-italic'>{male.length} student{male.length !== 1 ? 's' : ''}</small>
                  </th>
                  <td colspan={facultyLoads.length+(year_level.school_level.term_grades)}></td>
                </tr>
                {
                  male.map(student => (
                    <tr key={student.id}>
                      <th>
                        <NameLink tabIndex='-1' id={student.id} name={student.formal_name} image={student.image} />
                      </th>
                      {
                        year_level.school_level.term_grades ? (
                          <td className='text-center align-middle'>
                            <ButtonGroup>
                              <Button variant={enabledGrades.find(eg => eg.term_id === +selectedTerm && eg.student_id === student.id) ? 'green' : 'outline-green'} size='sm' onClick={e => this.showAccessibilityModal('enable', student)}>
                                {enabledGrades.find(eg => eg.term_id === +selectedTerm && eg.student_id === student.id) ? 'Enabled' : 'Enable'}
                              </Button>
                              <Button variant={enabledGrades.find(eg => eg.term_id === +selectedTerm && eg.student_id === student.id) ? 'outline-danger' : 'danger'} size='sm' onClick={e => this.showAccessibilityModal('disable', student)}>
                                {enabledGrades.find(eg => eg.term_id === +selectedTerm && eg.student_id === student.id) ? 'Disable' : 'Disabled'}
                              </Button>
                            </ButtonGroup>
                          </td>
                        ) : null
                      }
                      {
                        facultyLoads.map(facultyLoad => (
                          <th key={facultyLoad.id} className='align-middle text-center' style={{ fontSize: '1.1rem' }}>
                            {this.renderGrade(facultyLoad.id, student.id)}
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </>
            )
          }
          {
            female.length > 0 && (
              <>
                <tr className='text-white bg-green'>
                  <th className='py-1 text-center'>
                    <div className='mb-0'>Female</div>
                    <small className='font-italic'>{female.length} student{female.length !== 1 ? 's' : ''}</small>
                  </th>
                  <td colspan={facultyLoads.length+(year_level.school_level.term_grades)}></td>
                </tr>
                {
                  female.map(student => (
                    <tr key={student.id}>
                      <th>
                        <NameLink tabIndex='-1' id={student.id} name={student.formal_name} image={student.image} />
                      </th>
                      {
                        year_level.school_level.term_grades ? (
                          <td className='text-center align-middle'>
                            <ButtonGroup>
                              <Button variant={enabledGrades.find(eg => eg.term_id === +selectedTerm && eg.student_id === student.id) ? 'green' : 'outline-green'} size='sm' onClick={e => this.showAccessibilityModal('enable', student)}>
                                {enabledGrades.find(eg => eg.term_id === +selectedTerm && eg.student_id === student.id) ? 'Enabled' : 'Enable'}
                              </Button>
                              <Button variant={enabledGrades.find(eg => eg.term_id === +selectedTerm && eg.student_id === student.id) ? 'outline-danger' : 'danger'} size='sm' onClick={e => this.showAccessibilityModal('disable', student)}>
                                {enabledGrades.find(eg => eg.term_id === +selectedTerm && eg.student_id === student.id) ? 'Disable' : 'Disabled'}
                              </Button>
                            </ButtonGroup>
                          </td>
                        ) : null
                      }
                      {
                        facultyLoads.map(facultyLoad => (
                          <th key={facultyLoad.id} className='align-middle text-center' style={{ fontSize: '1.1rem' }}>
                            {this.renderGrade(facultyLoad.id, student.id)}
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </>
            )
          }
        </tbody>
      </Table>
    );
  }
  render() {
    const { isLoading, errorMessage, selectedTerm, editFacultyLoadID, importModal, accessibilityModal } = this.state;
    const { schoolClass: { id, terms, students, facultyLoads, year_level, program, section } } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (terms.length === 0 || students.length === 0 || facultyLoads.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let selectedTermData = terms.find(t => t.id === +selectedTerm);

    return (
      <>
        <Modal show={importModal.show} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Term Grades</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleUpload}>
            <Modal.Body>
              {
                importModal.errorMessage ? (
                  <Alert variant='danger'>
                    {importModal.errorMessage}
                  </Alert>
                ) : importModal.successMessage ? (
                  <Alert variant='success'>
                    {importModal.successMessage}
                  </Alert>
                ) : null
              }
              <Form.Group>
                <Form.File label='Import term grades' accept='.xlsx, .xls' onChange={this.handleFileSelect} disabled={importModal.isLoading || importModal.successMessage} />
              </Form.Group>
              {
                importModal.isLoading && (
                  <div className='mb-3'>
                    <ProgressBar now={importModal.isLoading} label='Uploading...' />
                  </div>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant='light' onClick={this.hideModal}>
                Close
              </Button>
              {
                !importModal.successMessage && (
                  <Button type='submit' variant='green' disabled={importModal.isLoading}>
                    Upload
                  </Button>
                )
              }
            </Modal.Footer>
          </Form>
        </Modal>
        {
          year_level.school_level.title !== 'Senior High School' && (
            <>
              <div className='text-right mb-2'>
                <Button variant='green' size='sm' className='mr-2' onClick={this.showModal}>
                  <FontAwesomeIcon icon='file-import' /> <span className='d-none d-md-inline-block'>Import grades</span>
                </Button>
                <a href={`${process.env['REACT_APP_API_BASE_URL']}/faculty/class-advisory/${id}/grades/export`} className='btn btn-green btn-sm'>
                  <FontAwesomeIcon icon='file-export' /> <span className='d-none d-md-inline-block'>Export grades</span>
                </a>
              </div>
              <div className='dropdown-divider'></div>
            </>
          )
        }
        <div>
          <Nav fill variant='pills' activeKey={selectedTerm} className='m-0' onSelect={this.handleSelectTerm}>
            {
              terms.map(term => (
                <Nav.Item key={term.id}>
                  <Nav.Link eventKey={term.id} disabled={!!editFacultyLoadID || isLoading}>{term.title}</Nav.Link>
                </Nav.Item>
              ))
            }
          </Nav>
        </div>
        {/* {
          year_level.school_level.title === 'Senior High School' && (
            <>
              <div className='dropdown-divider'></div>
              <div className='text-right mt-3 mb-2'>
                <Button variant='green' size='sm' className='mr-2' onClick={this.showModal}>
                  <FontAwesomeIcon icon='file-import' /> <span className='d-none d-md-inline-block'>Import grades</span>
                </Button>
                <a href={`${process.env['REACT_APP_API_BASE_URL']}/faculty/class-advisory/${id}/grades/term/${selectedTerm}/export`} className={`btn btn-green btn-sm ${selectedTerm ? '' : 'disabled'}`}>
                  <FontAwesomeIcon icon='file-export' /> <span className='d-none d-md-inline-block'>Export grades</span>
                </a>
              </div>
            </>
          )
        } */}
        {this.renderContent()}
        <Modal show={accessibilityModal.show} onHide={this.hideAccessibilityModal}>
          <Modal.Header closeButton>
            <Modal.Title>{accessibilityModal.type === 'disable' ? 'Disable - Accessibility' : 'Enable - Accessibility'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              accessibilityModal.errorMessage && (
                <Alert variant='danger'>
                  {accessibilityModal.errorMessage}
                </Alert>
              )
            }
            {
              accessibilityModal.student ? (
                <>
                  {
                    accessibilityModal.type === 'enable' ? (
                      <Alert variant='warning'>
                        The grades of <b>{accessibilityModal.student.name}</b> for the term <b>{selectedTermData ? selectedTermData.title : ''}</b> will be <b>accessible</b>
                      </Alert>
                    ) : (
                      <Alert variant='warning'>
                        The term grades of <b>{accessibilityModal.student.name}</b> for the term <b>{selectedTermData ? selectedTermData.title : ''}</b> will be <b>inaccessible</b>
                      </Alert>
                    )
                  }
                </>
              ) : (
                <>
                  {
                    accessibilityModal.type === 'enable' ? (
                      <Alert variant='warning'>
                        The grades of the students of <b>{`${program.code} ${year_level.code}-${section.code}`}</b> for the term <b>{selectedTermData ? selectedTermData.title : ''}</b> will be <b>accessible</b>
                      </Alert>
                    ) : (
                      <Alert variant='warning'>
                        The term grades of the students of <b>{`${program.code} ${year_level.code}-${section.code}`}</b> for the term <b>{selectedTermData ? selectedTermData.title : ''}</b> will be <b>inaccessible</b>
                      </Alert>
                    )
                  }
                </>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            {
              accessibilityModal.type === 'enable' ? (
                <>
                  <Button variant='danger' onClick={this.hideAccessibilityModal} disabled={accessibilityModal.isLoading}>
                    Cancel
                  </Button>
                  <Button variant='green' onClick={this.handleAccessibility} disabled={accessibilityModal.isLoading}>
                    Enable
                  </Button>
                </>
              ) : (
                <>
                  <Button variant='info' onClick={this.hideAccessibilityModal} disabled={accessibilityModal.isLoading}>
                    Cancel
                  </Button>
                  <Button variant='danger' onClick={this.handleAccessibility} disabled={accessibilityModal.isLoading}>
                    Disable
                  </Button>
                </>
              )
            }
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}