import React, { Component } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
} from 'chart.js';
import { Line, Radar } from 'react-chartjs-2';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import hexToRgba from '../../../../../util/helpers/hexToRgba';
import moment from 'moment';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  maintainAspectRatio: false,
  // responsive: false,
  plugins: {
    legend: {
      position: 'top',
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      max: 100,
      ticks: {
        stepSize: 20
      }
    }
  }
};

export default class Performance extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      activities: null,
      activitiesData: {
        labels: [],
        data: []
      },
      exams: null,
      examsData: {
        labels: [],
        data: []
      },
      attendance: null,
      attendanceData: {
        labels: [],
        data: []
      },
      overAll: []
    };
  }
  componentDidMount() {
    const { profile } = this.props;

    axiosRequest('get', `profile-menu/${profile.id}/statistics`, null, ({ data: { data }}) => {
      let activitiesData = {
        labels: Object.keys(data.activities),
        data: []
      };
      activitiesData.labels.sort((a, b) => {
        let momentA = moment(a, 'MMMM YYYY');
        let momentB = moment(b, 'MMMM YYYY');

        if (momentA.isBefore(momentB, 'month')) {
          return -1;
        } else if (momentA.isAfter(momentB, 'month')) {
          return 1;
        }
  
        return 0;
      });
      for (let i = 0; i < activitiesData.labels.length; i++) {
        let percentageSum = 0;
        for (let j = 0; j < data.activities[activitiesData.labels[i]].length; j++) {
          percentageSum += data.activities[activitiesData.labels[i]][j].percentage;
        }
        activitiesData.data = [...activitiesData.data, (percentageSum/data.activities[activitiesData.labels[i]].length)];
      }
      activitiesData.labels = activitiesData.labels.map(l => moment(l, 'MMMM YYYY').format('MMM YYYY'));

      let examsData = {
        labels: Object.keys(data.exams),
        data: []
      };
      examsData.labels.sort((a, b) => {
        let momentA = moment(a, 'MMMM YYYY');
        let momentB = moment(b, 'MMMM YYYY');

        if (momentA.isBefore(momentB, 'month')) {
          return -1;
        } else if (momentA.isAfter(momentB, 'month')) {
          return 1;
        }
  
        return 0;
      });
      for (let i = 0; i < examsData.labels.length; i++) {
        let percentageSum = 0;
        for (let j = 0; j < data.exams[examsData.labels[i]].length; j++) {
          percentageSum += data.exams[examsData.labels[i]][j].percentage;
        }
        examsData.data = [...examsData.data, (percentageSum/data.exams[examsData.labels[i]].length)];
      }
      examsData.labels = examsData.labels.map(l => moment(l, 'MMMM YYYY').format('MMM YYYY'));

      let attendanceData = {
        labels: Object.keys(data.attendance),
        data: []
      };
      for (let i = 0; i < attendanceData.labels.length; i++) {
        let totalPresent = 0;
        for (let j = 0; j < data.attendance[attendanceData.labels[i]].length; j++) {
          if (data.attendance[attendanceData.labels[i]][j].mark === 'Present') {
            totalPresent++;
          }
        }
        attendanceData.data = [...attendanceData.data, Math.round(((totalPresent/data.attendance[attendanceData.labels[i]].length)*100 + Number.EPSILON)*100)/100];
      }
      attendanceData.labels = attendanceData.labels.map(l => moment(l, 'MMMM YYYY').format('MMM YYYY'));

      let overAll = [
        (activitiesData.data.reduce((p, c) => p + c, 0)/activitiesData.data.length).toFixed(2),
        (examsData.data.reduce((p, c) => p + c, 0)/examsData.data.length).toFixed(2),
        (attendanceData.data.reduce((p, c) => p + c, 0)/attendanceData.data.length).toFixed(2)
      ];

      this.setState({
        ...this.state,
        ...data,
        activitiesData,
        examsData,
        attendanceData,
        overAll,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  render() {
    const { isLoading, errorMessage, activities, exams, attendance, activitiesData, examsData, attendanceData, overAll } = this.state;
    const { profile } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (!activities && !exams && !attendance) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let hexColor = '';
    if (profile?.student_class?.school_class) {
      hexColor = profile.student_class.school_class?.year_level?.school_level?.theme;
    } else if (profile?.year_level) {
      hexColor = profile?.year_level?.school_level?.theme;
    }
    hexColor = hexColor ?? '#131b7f';
    let overAllBgColor = hexToRgba(hexColor, 0.2);
    let backgroundColor = hexToRgba(hexColor, 0.5);
    let borderColor = hexToRgba(hexColor);

    let showAll = activitiesData.labels.length > 0 && examsData.labels.length > 0 && attendanceData.labels.length > 0;

    return (
      <div>
        <Row>
          {
            showAll && (
              <Col lg={{ span: 4, order: 2 }}>
                <div className='sticky-top pt-5 mt-n5'>
                  <div>
                    <Radar
                      options={{
                        scales: {
                          r: {
                            beginAtZero: true,
                            max: 100,
                            ticks: {
                              stepSize: 20
                            }
                          }
                        }
                      }}
                      data={{
                        labels: ['Activities', 'Exams', 'Attendance'],
                        datasets: [
                          {
                            label: 'Overall performance',
                            data: overAll,
                            backgroundColor: overAllBgColor,
                            borderColor,
                            borderWidth: 1,
                          }
                        ]
                      }} />
                  </div>
                </div>
              </Col>
            )
          }
          <Col lg={{ span: showAll ? 8 : 12, order: 1 }}>
            {
              attendanceData.labels.length > 0 && (
                <div className='position-relative' style={{ height: '40vh' }}>
                  <Line
                    height='200'
                    className='mb-3'
                    options={{
                      ...options,
                      plugins: {
                        ...options.plugins,
                        title: {
                          display: true,
                          text: 'Attendance'
                        }
                      }
                    }}
                    data={{
                      labels: attendanceData.labels,
                      datasets: [
                        {
                          label: 'Percentage',
                          data: attendanceData.data,
                          backgroundColor,
                        }
                      ]
                    }} />
                </div>
              )
            }
            <div className='dropdown-divider'></div>
            {
              activitiesData.labels.length > 0 && (
                <div className='position-relative' style={{ height: '40vh' }}>
                  <Line
                    height='200'
                    className='mb-3'
                    options={{
                      ...options,
                      plugins: {
                        ...options.plugins,
                        title: {
                          display: true,
                          text: 'Activities'
                        }
                      }
                    }}
                    data={{
                      labels: activitiesData.labels,
                      datasets: [
                        {
                          label: 'Percentage',
                          data: activitiesData.data,
                          backgroundColor,
                        }
                      ]
                    }} />
                </div>
              )
            }
            <div className='dropdown-divider'></div>
            {
              examsData.labels.length > 0 && (
                <div className='position-relative' style={{ height: '40vh' }}>
                  <Line
                    height='200'
                    options={{
                      ...options,
                      plugins: {
                        ...options.plugins,
                        title: {
                          display: true,
                          text: 'Exams'
                        }
                      }
                    }}
                    data={{
                      labels: examsData.labels,
                      datasets: [
                        {
                          label: 'Percentage',
                          data: examsData.data,
                          backgroundColor,
                        }
                      ]
                    }} />
                </div>
              )
            }
          </Col>
        </Row>
      </div>
    );
  }
}