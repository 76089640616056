import React, { Component } from 'react';
import { Accordion, Alert, Button, Card, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NameLink from '../../../../common/NameLink/NameLink';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import durationFormat from '../../../../../util/helpers/durationFormat';
import moment from 'moment';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

const materialTitleMap = {
  assignment: 'Assignment/Activity',
  quiz: 'Quiz',
  exam: 'Exam'
};

export default class Faculty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      faculty: [],
      weekdays: [],
      currentDayIndex: 0,
      currentDisplayIndex: 0,
      // isLogLoading: false,
      // logError: '',
      activityLogs: [],
      modal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        day: null,
        faculty: null,
        activities: [],
        selectedAccordion: null
      }
    };
  }
  componentDidMount() {
    axiosRequest('get', 'staff/attendance-summary/faculty', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        faculty: data,
        activityLogs: data.map(f => ({faculty_id: f.id, logs: []}))
      }, () => this.getWeek());
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  getWeek = (weekStart, currentDisplayIndex) => {
    if(!weekStart) {
      weekStart = moment().startOf('isoWeek');
    }
    let days = [];
    let currentDayIndex = 0;

    for (let i = 0; i < 7; i++) {
      let temp = moment(weekStart).add(i, 'days');

      if (temp.isSame(moment(), 'day')) {
        currentDayIndex = i+1;
      }

      days.push({
        date: temp.format('YYYY-MM-DD'),
        weekdayFull: temp.format('dddd'),
        dateDisplay: temp.format('MMM D'),
        weekday: temp.format('ddd')
      });
    }

    this.setState({
      ...this.state,
      weekdays: days,
      currentDayIndex,
      currentDisplayIndex: currentDisplayIndex ? currentDisplayIndex : (currentDayIndex === 0 ? 1 : currentDayIndex),
      isLoading: false
    }, () => {
      // if (weekStart) {
      //   this.setState({
      //     ...this.state,
      //     isLogLoading: true,
      //     logError: ''
      //   }, () => {
      //     axiosRequest('post', 'staff/attendance-summary/faculty', {}, ({ data: { data }}) => {
      //       const newFaculty = [...this.state.faculty].map(f => {})
      //       this.setState({
      //         ...this.state,
      //         isLogLoading: false,
      //         faculty: data
      //       });
      //     }, error => {
      //       this.setState({
      //         ...this.state,
      //         isLogLoading: false,
      //         logError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      //       });
      //     }, this.props.history);
      //   });
      // }
    });
  }
  handleNextDay = () => {
    const { currentDisplayIndex } = this.state;

    if (currentDisplayIndex === 7) {
      this.handleNextWeek();
    } else {
      this.setState({
        ...this.state,
        currentDisplayIndex: currentDisplayIndex+1
      });
    }
  }
  handlePrevDay = () => {
    const { weekdays, currentDisplayIndex } = this.state;

    if (currentDisplayIndex === 1) {
      this.getWeek(moment(weekdays[0].date).subtract(1, 'weeks'), 7);
    } else {
      this.setState({
        ...this.state,
        currentDisplayIndex: currentDisplayIndex-1
      });
    }
  }
  handleNextWeek = () => {
    const { weekdays } = this.state;

    this.getWeek(moment(weekdays[0].date).add(1, 'weeks'), 1);
  }
  handlePrevWeek = () => {
    const { weekdays } = this.state;

    this.getWeek(moment(weekdays[0].date).subtract(1, 'weeks'));
  }
  showModal = (day, faculty) => {
    const { activityLogs } = this.state;
    this.setState({
      ...this.state,
      modal: {
        show: true,
        isLoading: true,
        errorMessage: '',
        day,
        faculty,
        activities: []
      }
    }, () => {
      if (moment().isBefore(day.date, 'day')) {
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isLoading: false
          }
        });
        return;
      }

      let facultyLog = activityLogs.find(al => al.faculty_id === faculty.id);
      if (facultyLog && facultyLog.logs.length > 0) {
        let logs = facultyLog.logs.find(fl => fl.date === day.date);
        if (logs) {
          this.setState({
            ...this.state,
            modal: {
              ...this.state.modal,
              activities: logs.logs,
              isLoading: false
            }
          });
          return;
        }
      }

      axiosRequest('post', `staff/attendance-summary/faculty/${faculty.id}`, {
        date: day.date
      }, ({ data: { data }}) => {
        let newActivityLogs = [...activityLogs];
        if (moment().isAfter(day.date, 'day')) {
          newActivityLogs = [...activityLogs].map(al => {
            if (al.faculty_id === faculty.id) {
              return {
                ...al,
                logs: [
                  ...al.logs,
                  {
                    date: day.date,
                    logs: data
                  }
                ]
              };
            }
  
            return {...al}
          });
        }
        this.setState({
          ...this.state,
          activityLogs: newActivityLogs,
          modal: {
            ...this.state.modal,
            activities: data,
            isLoading: false
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          modal: {
            ...this.state.modal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      modal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        day: 0,
        faculty: null,
        activities: []
      }
    });
  }
  renderCheckInCheckOut = (weekdayIndex, faculty) => {
    const { weekdays } = this.state;

    if (!faculty.faculty_load) {
      return (
        <div style={{ height: '4.5rem', lineHeight: '4.5rem' }}>
          <OverlayTrigger
            overlay={
              <Tooltip>
                No classes available
              </Tooltip>
            }
            trigger={['hover', 'focus']}>
            <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
          </OverlayTrigger>
        </div>
      );
    }

    let hasSchedule = false;
    let firstClass = null;
    let lastClass = null;
    for (let i = 0; i < faculty.faculty_load.length; i++) {
      let schedule = faculty.faculty_load[i].date_schedule.find(ds => ds.schedule_date === weekdays[weekdayIndex].date);

      if (!schedule) {
        schedule = faculty.faculty_load[i].weekly_schedule.find(ws => ws.day === weekdays[weekdayIndex].weekdayFull);
      }

      if (schedule) {
        if (schedule.start_time && schedule.end_time) {
          hasSchedule = true;

          if (schedule.type === 'Synchronous') {
            if (!firstClass) {
              firstClass = {
                ...faculty.faculty_load[i],
                schedule
              };
              lastClass = {
                ...faculty.faculty_load[i],
                schedule
              };
            } else {
              if (moment(firstClass.schedule.start_time, 'HH:mm').isAfter(moment(schedule.start_time, 'HH:mm'))) {
                if (!lastClass || moment(firstClass.schedule.end_time, 'HH:mm').isAfter(moment(lastClass.schedule.end_time, 'HH:mm'))) {
                  lastClass = {...firstClass};
                }
                firstClass = {
                  ...faculty.faculty_load[i],
                  schedule
                };
              } else {
                if (!lastClass || moment(schedule.end_time, 'HH:mm').isAfter(moment(lastClass.schedule.end_time, 'HH:mm'))) {
                  lastClass = {
                    ...faculty.faculty_load[i],
                    schedule
                  };
                }
              }
            }
          }
        }
      }
    }

    if (!hasSchedule) {
      return (
        <div style={{ height: '4.5rem', lineHeight: '4.5rem' }}>
          <OverlayTrigger
            overlay={
              <Tooltip>
                No scheduled classes
              </Tooltip>
            }
            trigger={['hover', 'focus']}>
            <FontAwesomeIcon icon='info-circle' className='text-info' />
          </OverlayTrigger>
        </div>
      );
    } else if (hasSchedule && !firstClass) {
      return (
        <div style={{ height: '3rem', lineHeight: '3rem' }}>
          <OverlayTrigger
            overlay={
              <Tooltip>
                No scheduled synchronous classes
              </Tooltip>
            }
            trigger={['hover', 'focus']}>
            <FontAwesomeIcon icon='info-circle' className='text-info' />
          </OverlayTrigger>
        </div>
      );
    }

    let attendance = faculty.employee_attendances.find(ea => ea.attendance_date === weekdays[weekdayIndex].date);

    let checkinTheme = moment(weekdays[weekdayIndex].date).isAfter(moment(), 'day') ? 'muted' : attendance &&
                        moment(attendance.check_in, 'HH:mm:ss').isAfter(moment(firstClass.schedule.start_time, 'HH:mm'), 'second') ?
                          'danger' :
                        attendance ?
                          'green' :
                        moment().isAfter(weekdays[weekdayIndex].date, 'day') ||
                        moment().isAfter(moment(firstClass.schedule.start_time, 'HH:mm'), 'second') ?
                          'danger' :
                          'muted';
    let checkoutTheme = attendance &&
                        attendance.check_out &&
                        moment(attendance.check_out, 'HH:mm:ss').isBefore(moment(lastClass.schedule.end_time, 'HH:mm'), 'second') ?
                          'danger' :
                        attendance &&
                        attendance.check_out ?
                          'green' :
                        (!attendance || !attendance.check_out) &&
                        moment().isAfter(weekdays[weekdayIndex].date, 'day') ?
                          'danger' :
                          'muted';

    return (
      <div className='text-left px-1' style={{ height: '3rem' }}>
        <div className={`text-${checkinTheme} text-truncate`}>
          <OverlayTrigger
            overlay={
              <Tooltip>
                Schedule starts at {moment(firstClass.schedule.start_time, 'HH:mm').format('hh:mm A')}
              </Tooltip>
            }
            trigger={['hover', 'focus']}>
            <FontAwesomeIcon icon='sign-in-alt' />
          </OverlayTrigger>
          {
            (checkinTheme === 'green' || (checkinTheme === 'danger' && attendance)) ? (
              <span className='ml-1 small' title={moment(attendance.check_in, 'HH:mm:ss').format('hh:mm:ss A')}>{moment(attendance.check_in, 'HH:mm:ss').format('hh:mm A')}</span>
            ) : (checkinTheme === 'danger' && !attendance) ? (
              <span className='ml-1 small'>-</span>
            ) : null
          }
        </div>
        <div className={`text-${checkoutTheme}`}>
          <OverlayTrigger
            overlay={
              <Tooltip>
                Schedule ends at {moment(lastClass.schedule.end_time, 'HH:mm').format('hh:mm A')}
              </Tooltip>
            }
            trigger={['hover', 'focus']}>
            <FontAwesomeIcon icon='sign-out-alt' />
          </OverlayTrigger>
          {
            (checkoutTheme === 'green' || (checkoutTheme === 'danger' && attendance && attendance.check_out)) ? (
              <span className='ml-1 small' title={moment(attendance.check_out, 'HH:mm:ss').format('hh:mm:ss A')}>{moment(attendance.check_out, 'HH:mm:ss').format('hh:mm A')}</span>
            ) : (checkoutTheme === 'danger' && (!attendance || !attendance.check_out)) ? (
              <span className='ml-1 small'>-</span>
            ) : null
          }
        </div>
      </div>
    );
  }
  renderAsyncMaterials = (weekdayIndex, faculty) => {
    const { weekdays } = this.state;

    if (!faculty.faculty_load) {
      return null;
    }

    let hasSchedule = false;
    let hasSync = false;
    let asyncClasses = [];
    let hasLateAsync = false;
    for (let i = 0; i < faculty.faculty_load.length; i++) {
      let schedule = faculty.faculty_load[i].date_schedule.find(ds => ds.schedule_date === weekdays[weekdayIndex].date);

      if (!schedule) {
        schedule = faculty.faculty_load[i].weekly_schedule.find(ws => ws.day === weekdays[weekdayIndex].weekdayFull);
      }

      if (schedule) {
        if (schedule.start_time && schedule.end_time) {
          hasSchedule = true;

          if (schedule.type === 'Synchronous') {
            hasSync = true;
          } else {
            let material_async_schedule = faculty.faculty_load[i].material_async_schedules.find(mas => mas.schedule_date === weekdays[weekdayIndex].date);
            if (material_async_schedule && moment(material_async_schedule.material.created_at).isSameOrAfter(moment(`${weekdays[weekdayIndex].date} ${schedule.start_time}`, 'YYYY-MM-DD HH:mm'), 'day')) {
              hasLateAsync = true;
            }
            asyncClasses.push({
              ...faculty.faculty_load[i],
              material_async_schedule,
              schedule
            });
          }
        }
      }
    }

    if (!hasSchedule) {
      return null;
    }

    if (hasSync && asyncClasses.length === 0) {
      return (
        <div className='text-left px-1'>
          <OverlayTrigger
            overlay={
              <Tooltip>
                No scheduled asynchronous classes
              </Tooltip>
            }
            trigger={['hover', 'focus']}>
              <div className='fa-layers'>
                <FontAwesomeIcon icon='file' className='text-muted' />
                <FontAwesomeIcon icon='clock' inverse transform='shrink-6 right-1 down-3' />
              </div>
          </OverlayTrigger>
        </div>
      );
    }

    let filteredAsyncClass = asyncClasses.filter(ac => {
      return ac.material_async_schedule;
    });
    return (
      <div className={`text-left px-1 text-${(filteredAsyncClass.length !== asyncClasses.length || hasLateAsync) ? 'danger' : 'green'}`}>
        <span className='fa-layers'>
          <FontAwesomeIcon icon='file' />
          <FontAwesomeIcon icon='clock' inverse transform='shrink-6 right-1 down-3' />
        </span>
        <span className='ml-1 small'>
          {`${filteredAsyncClass.length}/${asyncClasses.length}`}
        </span>
      </div>
    );
  }
  renderIcon = (weekdayIndex, faculty) => {
    const {
      weekdays,
      // isLogLoading,
      // logError
    } = this.state;

    // if (isLogLoading) {
    //   return (
    //     <div style={{ height: '6.5rem', lineHeight: '6.5rem' }}>
    //       <LoadingIcon />
    //     </div>
    //   );
    // }

    // if (logError) {
    //   return (
    //     <div style={{ height: '6.5rem', lineHeight: '6.5rem' }}>
    //       <OverlayTrigger
    //         overlay={
    //           <Tooltip>
    //             {logError}
    //           </Tooltip>
    //         }
    //         trigger={['hover', 'focus']}>
    //         <FontAwesomeIcon icon='exclamation-circle' className='text-danger' />
    //       </OverlayTrigger>
    //     </div>
    //   );
    // }

    return (
      <div style={{ height: '6.5rem' }}>
        <div className='text-right' style={{ height: '2rem' }}>
          <Button variant='light' size='sm' title='View details' onClick={e => this.showModal(weekdays[weekdayIndex], faculty)}>
            <FontAwesomeIcon icon={['fas', 'th-list']} />
          </Button>
        </div>
        {this.renderCheckInCheckOut(weekdayIndex, faculty)}
        {this.renderAsyncMaterials(weekdayIndex, faculty)}
      </div>
    );
  }
  renderAsyncDuration = (createdAt, startSchedule) => {
    let duration = null;
    if (createdAt.isAfter(startSchedule)) {
      duration = moment.duration(createdAt.diff(startSchedule));
      return `${duration.humanize()} after schedule (${durationFormat(duration)})`;
    }

    duration = moment.duration(startSchedule.diff(createdAt));
    return `${duration.humanize()} before schedule (${durationFormat(duration)})`;
  }
  renderModal = () => {
    const {
      modal: {
        faculty,
        day,
        isLoading,
        errorMessage,
        activities
      }
    } = this.state;

    if (!faculty || !day) {
      return null;
    }

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (!faculty.faculty_load) {
      return (
        <Alert variant='light'>
          No classes available
        </Alert>
      );
    }

    let hasSchedule = false;
    let firstClass = null;
    let lastClass = null;
    let asyncClasses = [];
    let modifiedClasses = [];
    for (let i = 0; i < faculty.faculty_load.length; i++) {
      let schedule = faculty.faculty_load[i].date_schedule.find(ds => ds.schedule_date === day.date);

      if (!schedule) {
        schedule = faculty.faculty_load[i].weekly_schedule.find(ws => ws.day === day.weekdayFull);
      }

      let material_async_schedule = null;

      if (schedule) {
        if (schedule.start_time && schedule.end_time) {
          hasSchedule = true;

          if (schedule.type === 'Synchronous') {
            if (!firstClass) {
              firstClass = {
                ...faculty.faculty_load[i],
                schedule
              };
              lastClass = {
                ...faculty.faculty_load[i],
                schedule
              };
            } else {
              if (moment(firstClass.schedule.start_time, 'HH:mm').isAfter(moment(schedule.start_time, 'HH:mm'))) {
                if (!lastClass || moment(firstClass.schedule.end_time, 'HH:mm').isAfter(moment(lastClass.schedule.end_time, 'HH:mm'))) {
                  lastClass = {...firstClass};
                }
                firstClass = {
                  ...faculty.faculty_load[i],
                  schedule
                };
              } else {
                if (!lastClass || moment(schedule.end_time, 'HH:mm').isAfter(moment(lastClass.schedule.end_time, 'HH:mm'))) {
                  lastClass = {
                    ...faculty.faculty_load[i],
                    schedule
                  };
                }
              }
            }
          } else {
            material_async_schedule = faculty.faculty_load[i].material_async_schedules.find(mas => mas.schedule_date === day.date);
            asyncClasses.push({
              ...faculty.faculty_load[i],
              material_async_schedule,
              schedule
            });
          }
        }
      }

      modifiedClasses.push({
        ...faculty.faculty_load[i],
        material_async_schedule,
        schedule
      });
    }

    if (!hasSchedule) {
      return (
        <Alert variant='light'>No scheduled classes</Alert>
      );
    }

    let attendance = faculty.employee_attendances.find(ea => ea.attendance_date === day.date);
    let lastActivity = activities.length > 1 ? activities[activities.length-1] : null;
    
    return (
      <div>
        <h5>Synchronous classes</h5>
        <div className='px-2'>
          {
            !firstClass ? (
              <div className='text-muted font-italic'>
                No scheduled synchronous classes
              </div>
            ) : (
              <div>
                <div>
                  Starts at: <b>{moment(firstClass.schedule.start_time, 'HH:mm').format('hh:mm A')}</b> &mdash; {firstClass.class_course.course.code} ({firstClass.class_course.school_class.program.code} {firstClass.class_course.school_class.year_level.code} - {firstClass.class_course.school_class.section.code})
                </div>
                {
                  moment().isBefore(day.date, 'day') ? (
                    <div className='ml-2 text-muted d-flex'>
                      <div>
                        <FontAwesomeIcon icon='sign-in-alt' />
                      </div>
                      <div className='ml-1'>
                        <div className='font-italic'>(Not applicable)</div>
                      </div>
                    </div>
                  ) :
                  !attendance ? (
                    <div className='ml-2 text-danger font-weight-bold'>
                        <FontAwesomeIcon icon='sign-in-alt' /> No time in
                      </div>
                  ) :
                  moment(attendance.check_in, 'HH:mm:ss').isSameOrBefore(moment(firstClass.schedule.start_time, 'HH:mm')) ? (
                      <div className='ml-2 text-green font-weight-bold'>
                        <FontAwesomeIcon icon='sign-in-alt' /> Time in: {moment(attendance.check_in, 'HH:mm:ss').format('hh:mm:ss A')}
                      </div>
                    ) : (
                      <div className='ml-2 text-danger font-weight-bold d-flex'>
                        <div>
                          <FontAwesomeIcon icon='sign-in-alt' /> Time in: 
                        </div>
                        <div className='ml-1'>
                          <div>
                            {moment(attendance.check_in, 'HH:mm:ss').format('hh:mm:ss A')}
                          </div>
                          <div>
                            {moment.duration(moment(attendance.check_in, 'HH:mm:ss').diff(moment(firstClass.schedule.start_time, 'HH:mm'))).humanize()} late ({durationFormat(moment.duration(moment(attendance.check_in, 'HH:mm:ss').diff(moment(firstClass.schedule.start_time, 'HH:mm'))))})
                          </div>
                        </div>
                      </div>
                    )
                }
                <div>
                  Ends at: <b>{moment(lastClass.schedule.end_time, 'HH:mm').format('hh:mm A')}</b> &mdash; {lastClass.class_course.course.code} ({lastClass.class_course.school_class.program.code} {lastClass.class_course.school_class.year_level.code} - {lastClass.class_course.school_class.section.code})
                </div>
                {
                  moment().isBefore(day.date, 'day') ? (
                    <div className='ml-2 text-muted d-flex'>
                      <div>
                        <FontAwesomeIcon icon='sign-out-alt' />
                      </div>
                      <div className='ml-1'>
                        <div className='font-italic'>(Not applicable)</div>
                      </div>
                    </div>
                  ) :
                  (!attendance || !attendance.check_out) ? (
                    <div className='ml-2 text-danger d-flex'>
                      <div>
                        <FontAwesomeIcon icon='sign-out-alt' />
                      </div>
                      <div className='ml-1'>
                        <div className='font-weight-bold'>No time out</div>
                        {
                          lastActivity ? (
                            <div className='d-flex font-italic'>
                              <div>
                                Last activity:
                              </div>
                              <div className='ml-1'>
                                <div>
                                  {lastActivity.description}
                                </div>
                                <div>
                                  {moment(lastActivity.created_at).format('hh:mm:ss A')}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className='font-italic'>
                              (No activity)
                            </div>
                          )
                        }
                      </div>
                    </div>
                  ) :
                  moment(attendance.check_out, 'HH:mm:ss').isSameOrAfter(moment(lastClass.schedule.end_time, 'HH:mm')) ? (
                      <div className='ml-2 text-green font-weight-bold'>
                        <FontAwesomeIcon icon='sign-out-alt' /> Time out: {moment(attendance.check_out, 'HH:mm:ss').format('hh:mm:ss A')}
                      </div>
                    ) : (
                      <div className='ml-2 text-danger font-weight-bold d-flex'>
                        <div>
                          <FontAwesomeIcon icon='sign-in-alt' /> Time out: 
                        </div>
                        <div className='ml-1'>
                          <div>
                            {moment(attendance.check_out, 'HH:mm:ss').format('hh:mm:ss A')}
                          </div>
                          <div>
                            {moment.duration(moment(lastClass.schedule.end_time, 'HH:mm').diff(moment(attendance.check_out, 'HH:mm:ss'))).humanize()} early ({durationFormat(moment.duration(moment(lastClass.schedule.end_time, 'HH:mm').diff(moment(attendance.check_out, 'HH:mm:ss'))))})
                          </div>
                        </div>
                      </div>
                    )
                }
              </div>
            )
          }
        </div>
        <div className='dropdown-divider'></div>
        <h5>Asynchronous classes</h5>
        <div className='px-2'>
          {
            asyncClasses.length === 0 ? (
              <div className='text-muted font-italic'>
                No scheduled asynchronous classes
              </div>
            ) : (
              <div>
                {
                  asyncClasses.map(ac => (
                    <div key={ac.id}>
                      <div>
                        {ac.class_course.course.code} ({ac.class_course.school_class.program.code} {ac.class_course.school_class.year_level.code} - {ac.class_course.school_class.section.code}) &mdash; <i>{moment(ac.schedule.start_time, 'HH:mm').format('hh:mm A')}-{moment(ac.schedule.end_time, 'HH:mm').format('hh:mm A')}</i>
                      </div>
                      {
                        !ac.material_async_schedule ? (
                          <div className='ml-2 text-danger font-weight-bold'>
                            No uploaded material
                          </div>
                        ) : (
                          <div className='ml-2'>
                            <div>
                              {ac.material_async_schedule.material.title} &mdash; {materialTitleMap[ac.material_async_schedule.material.type]}
                            </div>
                            <div className={`text-${moment(ac.material_async_schedule.material.created_at).isBefore(day.date, 'day') ? 'green' : 'danger'} d-flex font-weight-bold`}>
                              <div>
                                Uploaded on:
                              </div>
                              <div className='ml-1'>
                                <div>
                                  {moment(ac.material_async_schedule.material.created_at).format('MMMM D, YYYY hh:mm:ss A')}
                                </div>
                                <div>
                                  {this.renderAsyncDuration(moment(ac.material_async_schedule.material.created_at), moment(`${day.date} ${ac.schedule.start_time}`, 'YYYY-MM-DD HH:mm'))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  ))
                }
              </div>
            )
          }
        </div>
        <div className='dropdown-divider'></div>
        <h5>Activities</h5>
        {this.renderActivities(modifiedClasses)}
      </div>
    );
  }
  handleSelectAccordion = selectedAccordion => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        selectedAccordion
      }
    });
  }
  renderActivities = classes => {
    const { modal: { activities, selectedAccordion } } = this.state;

    if (activities.length === 0) {
      return (
        <div className='text-muted px-2 font-italic'>
          No activities logged
        </div>
      );
    }

    let renderClassLogs = (logs, cl) => {
      let filteredLogs = logs.filter(l => +l.subject_id === +cl.id);

      return (
        <>
          <Accordion.Toggle as={Card.Header} eventKey={cl.id} style={{ cursor: 'pointer' }}>
            <div className='d-flex'>
              <div className='flex-fill'>
                <h6 className='m-0'>{cl.class_course.course.code} &mdash; {cl.class_course.school_class.program.code} {cl.class_course.school_class.year_level.code} - {cl.class_course.school_class.section.code} <i className='font-weight-normal text-muted'>({filteredLogs.length} log{filteredLogs.length === 1 ? '' : 's'})</i></h6>
                <div className='text-muted font-italic'>
                  {
                    !cl.schedule ?
                      'No schedule was set' :
                    (!cl.schedule.start_time || !cl.schedule.end_time) ?
                      'No schedule' :
                      `${moment(cl.schedule.start_time, 'HH:mm').format('hh:mm A')} - ${moment(cl.schedule.end_time, 'HH:mm').format('hh:mm A')}`
                  }
                </div>
              </div>
              <div>
                {
                  (selectedAccordion && selectedAccordion === cl.id) ? (
                    <FontAwesomeIcon icon='chevron-up' />
                  ) : (
                    <FontAwesomeIcon icon='chevron-down' />
                  )
                }
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={cl.id}>
            {
              filteredLogs.length > 0 ? (
                <ul className='mt-1'>
                  {
                    filteredLogs.map(l => (
                      <li key={l.id}>{l.description} <small className='font-italic text-muted'>&mdash;{moment(l.created_at).format('hh:mm A')}</small></li>
                    ))
                  }
                </ul>
              ) : (
                <Alert variant='light'>
                  Nothing to show
                </Alert>
              )
            }
          </Accordion.Collapse>
        </>
      );
    }

    classes.sort((a, b) => {
      if (!a.schedule && !b.schedule) {
        return 0;
      } else if (!a.schedule && b.schedule) {
        return 1;
      } else if (a.schedule && !b.schedule) {
        return -1;
      }

      if (!a.schedule.start_time && !b.schedule.start_time) {
        return 0;
      } else if (!a.schedule.start_time && b.schedule.start_time) {
        return 1;
      } else if (a.schedule.start_time && !b.schedule.start_time) {
        return -1;
      }

      let momentA = moment(a.schedule.start_time, 'HH:mm');
      let momentB = moment(b.schedule.start_time, 'HH:mm');

      if (momentA.isSame(momentB)) {
        return 0;
      } else if (momentA.isAfter(momentB)) {
        return 1;
      } else if (momentA.isBefore(momentB)) {
        return -1;
      }

      return 0;
    });

    let general = activities.filter(a => {
      return a.subject_id === null;
    });

    return (
      <div>
        <Accordion onSelect={this.handleSelectAccordion} activeKey={selectedAccordion}>
          <Card className='small'>
            <Accordion.Toggle as={Card.Header} eventKey='general' style={{ cursor: 'pointer' }}>
              <div className='d-flex'>
                <div className='flex-fill'>
                  <h6 className='m-0'>General <i className='font-weight-normal text-muted'>({general.length} log{general.length === 1 ? '' : 's'})</i></h6>
                </div>
                <div>
                  {
                    (selectedAccordion && selectedAccordion === 'general') ? (
                      <FontAwesomeIcon icon='chevron-up' />
                    ) : (
                      <FontAwesomeIcon icon='chevron-down' />
                    )
                  }
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='general'>
              {
                general.length > 0 ? (
                  <ul className='mt-1'>
                    {
                      general.map(l => (
                        <li key={l.id}>{l.description} <small className='font-italic text-muted'>&mdash;{moment(l.created_at).format('hh:mm A')}</small></li>
                      ))
                    }
                  </ul>
                ) : (
                  <Alert variant='light'>
                    Nothing to show.
                  </Alert>
                )
              }
            </Accordion.Collapse>
          </Card>
          {
            classes.length > 0 && (
              <>
                {
                  classes.map(cl => (
                    <Card key={cl.id} className='small'>
                      {renderClassLogs(activities, cl)}
                    </Card>
                  ))
                }
              </>
            )
          }
        </Accordion>
      </div>
    );
  }
  render() {
    const {
      isLoading,
      errorMessage, 
      faculty,
      weekdays,
      currentDayIndex,
      currentDisplayIndex,
      modal
    } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (faculty.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <div className='attendance bg-white mx-n3 px-3 mt-n2 pt-2 mb-n3 pb-3 rounded-bottom'>
        <Row>
          <Col className='d-md-none'>
            <InputGroup>
              <InputGroup.Prepend>
                <Button size='sm' onClick={this.handlePrevDay}>
                  <FontAwesomeIcon icon='chevron-left' />
                </Button>
              </InputGroup.Prepend>
              <Form.Control className='text-center' type='text' size='sm' value={weekdays[currentDisplayIndex-1].dateDisplay} readOnly />
              <InputGroup.Append>
                <Button size='sm' onClick={this.handleNextDay}>
                  <FontAwesomeIcon icon='chevron-right' />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col className='d-none d-md-block' md={{ span: 4, offset: 8 }}>
            <InputGroup>
              <InputGroup.Prepend>
                <Button onClick={this.handlePrevWeek}>
                  <FontAwesomeIcon icon='chevron-left' />
                </Button>
              </InputGroup.Prepend>
              <Form.Control className='text-center' type='text' value={`${weekdays[0].dateDisplay} - ${weekdays[6].dateDisplay}`} readOnly />
              <InputGroup.Append>
                <Button onClick={this.handleNextWeek}>
                  <FontAwesomeIcon icon='chevron-right' />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
        <Table bordered responsive size='sm' className='attendance-table mt-2 mt-md-3'>
          <colgroup>
            <col style={{ width: '30%' }}></col>
            <col className={currentDayIndex === 1 ? 'current-day' : ''}></col>
            <col className={currentDayIndex === 2 ? 'current-day' : ''}></col>
            <col className={currentDayIndex === 3 ? 'current-day' : ''}></col>
            <col className={currentDayIndex === 4 ? 'current-day' : ''}></col>
            <col className={currentDayIndex === 5 ? 'current-day' : ''}></col>
            <col className={currentDayIndex === 6 ? 'current-day' : ''}></col>
            <col className={currentDayIndex === 7 ? 'current-day' : ''}></col>
          </colgroup>
          <thead>
            <tr>
              <th className='pl-3'>
                {/* <small className='font-weight-bold'>
                  <div className='d-inline-block d-lg-none'>
                    <div><FontAwesomeIcon icon='check-circle' className='text-green' /> Has Activity</div>
                    <div><FontAwesomeIcon icon={['far', 'circle']} className='text-danger' /> No Activity</div>
                  </div>
                </small>
                <div className='font-weight-bold'>
                  <div className='d-none d-lg-inline-block'>
                    <div><FontAwesomeIcon icon='check-circle' className='text-green' /> Has Activity</div>
                    <div><FontAwesomeIcon icon={['far', 'circle']} className='text-danger' /> No Activity</div>
                  </div>
                </div> */}
              </th>
              {
                weekdays.map((day, index) => {
                  let display = (
                    <>
                      <div>{day.dateDisplay}</div>
                      <div className='font-weight-bold'>
                        <span className='mr-1'>
                          {day.weekdayFull}
                        </span>
                      </div>
                    </>
                  );

                  return (
                    <th key={index} className={`text-center${(currentDisplayIndex-1) === index ? ' current-display' : ''}`} style={{ width: '10%' }}>
                      <small>
                        <div className='d-lg-none'>
                          {display}
                        </div>
                      </small>
                      <div className='d-none d-lg-block'>
                        {display}
                      </div>
                    </th>
                  )
                })
              }
            </tr>
          </thead>
          <tbody>
          {
            faculty.map(f => {
              return (
                <tr key={f.id}>
                  <th className='pl-2' style={{ height: '5rem' }}>
                    <div className='d-flex align-items-center'>
                      <div className='flex-fill'>
                        <NameLink id={f.id} name={f.name} image={f.image} />
                      </div>
                    </div>
                  </th>
                  <td className={currentDisplayIndex === 1 ? 'current-display' : ''}>
                    { this.renderIcon(0, f) }
                  </td>
                  <td className={currentDisplayIndex === 2 ? 'current-display' : ''}>
                    { this.renderIcon(1, f) }
                  </td>
                  <td className={currentDisplayIndex === 3 ? 'current-display' : ''}>
                    { this.renderIcon(2, f) }
                  </td>
                  <td className={currentDisplayIndex === 4 ? 'current-display' : ''}>
                    { this.renderIcon(3, f) }
                  </td>
                  <td className={currentDisplayIndex === 5 ? 'current-display' : ''}>
                    { this.renderIcon(4, f) }
                  </td>
                  <td className={currentDisplayIndex === 6 ? 'current-display' : ''}>
                    { this.renderIcon(5, f) }
                  </td>
                  <td className={currentDisplayIndex === 7 ? 'current-display' : ''}>
                    { this.renderIcon(6, f) }
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </Table>
        <Modal show={modal.show} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div>
                {modal.faculty ? modal.faculty.name : ''}
              </div>
              <div className='small font-italic'>
                {modal.day ? moment(modal.day.date).format('MMMM D, YYYY') : ''}
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderModal()}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={this.hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}