import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import moment from 'moment';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import NameLink from '../../../../common/NameLink/NameLink';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

export default class Reschedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      students: [],
      reschedules: [],
      studentAttempts: []
    };
  }
  componentDidMount() {
    const { materialData, match: { params: { classID } } } = this.props;
    
    axiosRequest('get', `school-admin/class/${classID}/material/${materialData.type}/${materialData.id}/reschedule`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  renderStudentAttempts = studentID => {
    const { studentAttempts } = this.state;
    let studentAttempt = studentAttempts.find(sa => sa.student_id === studentID);

    if (studentAttempt) {
      return (
        <div className='text-muted'>
          Attempt status: {studentAttempt.finish ? 'Finished attempt' : 'Unfinished attempt'}
        </div>
      )
    }
  }
  renderReschedule = studentID => {
    const { reschedules } = this.state;
    let reschedule = reschedules.find(rs => rs.student_id === studentID);

    if (reschedule) {
      return (
        <div className='text-muted'>
          <div>
            From: {moment(reschedule.from).format('ddd, MMMM D, YYYY hh:mm A')}
          </div>
          <div>
            Until: {moment(reschedule.until).format('ddd, MMMM D, YYYY hh:mm A')}
          </div>
        </div>
      );
    }

    return null;
  }
  render() {
    const { isLoading, errorMessage, students } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (students.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='submission-list'>
          {
            students.map(student => (
              <div key={student.id} className='py-3 p-md-3 py-md-0'>
                <div className='d-flex'>
                  <div className='flex-fill'>
                    <div className='mb-2'>
                      <NameLink id={student.id} name={student.name} image={student.image} rank={student.rank} />
                    </div>
                    {this.renderStudentAttempts(student.id)}
                    {this.renderReschedule(student.id)}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </>
    );
  }
}