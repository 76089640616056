import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ClassTransition from './parts/ClassTransition';
import ClearData from './parts/ClearData';

const Transition = props => {
  return (
    <Switch>
      <Route exact path='/admin/transition/class' component={ClassTransition} />
      <Route exact path='/admin/transition/clear' component={ClearData} />
      <Redirect to={{ pathname: '/admin/transition/class'}} />
    </Switch>
  );
};

export default Transition;
