import React, { Component } from 'react';
import { Alert, Button, Card, Col, Container, Image, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../common/Header/Header';
import NameLink from '../../common/NameLink/NameLink';
import PromptDeleteModal from '../../modals/PromptDeleteModal/PromptDeleteModal';
import axiosRequest from '../../../util/helpers/axiosRequest';
import moment from 'moment';
import './style.scss';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

const displayMap = {
  'App\\Models\\MaterialFile': 'Materials',
  'App\\Models\\AnnouncementFile': 'Announcements',
  'App\\Models\\PostFile': 'Discussions',
  'App\\Models\\Profile': 'Profile'
};

export default class UploadLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      uploadLimits: {
        data: []
      },
      isNextPageLoading: false,
      nextPageError: '',
      viewModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        id: '',
        section: '',
        data: null
      },
      deleteModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null
      }
    };
  }
  componentDidMount() {
    axiosRequest('get', 'upload-limit', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        isLoading: false,
        uploadLimits: data
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    });
  }
  showViewModal = (id, section) => {
    this.setState({
      ...this.state,
      viewModal: {
        show: true,
        isLoading: true,
        errorMessage: '',
        id,
        section,
        data: null
      }
    }, () => {
      if (section === 'Profile') {
        this.setState({
          ...this.state,
          viewModal: {
            ...this.state.viewModal,
            isLoading: false
          }
        });
      } else {
        axiosRequest('get', `upload-limit/${section.toLowerCase()}/${id}`, null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            viewModal: {
              ...this.state.viewModal,
              isLoading: false,
              data
            }
          });
        }, error => {
          this.setState({
            ...this.state,
            viewModal: {
              ...this.state.viewModal,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        }, this.props.history);
      }
    });
  }
  hideViewModal = () => {
    this.setState({
      ...this.state,
      viewModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        id: '',
        section: '',
        data: null
      }
    });
  }
  handleDownloadFile = id => {
    axiosRequest('get', `upload-limit/download/${id}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  showDeleteModal = data => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        isLoading: false,
        errorMessage: '',
        data
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        data: null
      }
    });
  }
  handleDelete = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { deleteModal: { data: deleteData }, uploadLimits } = this.state;

      axiosRequest('delete', `upload-limit/${deleteData.id}`, null, ({ data: { data }}) => {
        const newUploadLimitsData = [...uploadLimits.data].filter(uploadLimit => uploadLimit.id !== deleteData.id);
        this.setState({
          ...this.state,
          uploadLimits: {
            ...this.state.uploadLimits,
            data: newUploadLimitsData
          },
          deleteModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            data: null
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { uploadLimits } = this.state;

      const path = uploadLimits.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          uploadLimits: {
            ...data,
            data: [
              ...this.state.uploadLimits.data,
              ...data.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  renderViewModalBody = () => {
    const { viewModal } = this.state;

    if (viewModal.isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (viewModal.errorMessage) {
      return (
        <Alert variant='danger'>
          {viewModal.errorMessage}
        </Alert>
      );
    }

    if (!viewModal.data && viewModal.section !== 'Profile') {
      return null;
    }

    const { data } = viewModal;
    let images = [];
    let files = [];

    switch (viewModal.section) {
      case 'Profile':
        return (
          <div style={{ height: '15rem', textAlign: 'center' }}>
            <Image
              src={`${process.env['REACT_APP_API_BASE_URL']}/upload-limit/profile`}
              style={{ objectFit: 'cover', height: '100%' }} fluid />
          </div>
        );
      case 'Announcements':
        images = data.files ? data.files.filter(file => {
          return !!file.is_image;
        }) : [];
    
        files = data.files ? data.files.filter(file => {
          return !file.is_image;
        }) : [];

        if (data.format === 'Image') {
          return (
            <div style={{ height: '15rem', textAlign: 'center' }}>
              <Image
                src={`${process.env['REACT_APP_API_BASE_URL']}/announcement/${data.id}/image/${images[0].id}`}
                style={{ objectFit: 'cover', height: '100%' }} fluid />
            </div>
          );
        }

        return (
          <div>
            <div className='h4'>{data.title}</div>
            <div style={{ whiteSpace: 'pre-line' }}>
              {data.description}
            </div>
            <>
              <div className='font-weight-bold mt-3'>
                <FontAwesomeIcon icon='paperclip' /> Attachments
              </div>
              <div className='px-3'>
              {
                images.length > 0 && (
                  <Row>
                  {
                    images.map(image => (
                      <Col key={image.id} md={6} className='question-image-container'>
                        <Image src={`${process.env['REACT_APP_API_BASE_URL']}/announcement/${data.id}/image/${image.id}`} thumbnail />
                      </Col>
                    ))
                  }
                  </Row>
                )
              }
              {
                files.length > 0 && (
                  <div className='p-1'>
                    {
                      files.map(file => (
                        <div key={file.id} className='d-flex mb-2'>
                          <div className='mr-2'>
                            <FontAwesomeIcon icon='file' className='text-primary' />
                          </div>
                          <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                            <span className='btn-link'>
                              {file.file_title}
                            </span>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )
              }
              </div>
            </>
          </div>
        );
      case 'Discussions':
        images = data.files ? data.files.filter(file => {
          return !!file.is_image;
        }) : [];
    
        files = data.files ? data.files.filter(file => {
          return !file.is_image;
        }) : [];

        return (
          <Card className='mb-3 shadow-sm'>
            <Card.Body>
              <div className='post-header'>
                <div>
                  <b className='d-block'>
                    <NameLink
                      id={data.account.id}
                      name={data.account.profile.name}
                      image={data.account.profile.image}
                      rank={data.account.profile.rank} />
                  </b>
                </div>
              </div>
              <div className='dropdown-divider'></div>
              <small className='d-block text-right font-italic' style={{ color: '#6c757d' }}>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {moment(data.created_at).format('MMM D, YYYY hh:mm A')}
                    </Tooltip>
                  }
                  trigger={['hover', 'focus']}>
                  <FontAwesomeIcon icon='clock' />
                </OverlayTrigger> {moment(data.created_at).fromNow()}
              </small>
              <div className='mb-1' style={{ whiteSpace: 'pre-line' }}>
                {data.message}
              </div>
              {
                data.links.length > 0 && (
                  <div className='p-1'>
                    {
                      data.links.map(link => (
                        <small key={link.id} className='d-flex mb-2'>
                          <div className='mr-2'>
                            <FontAwesomeIcon icon='link' className='text-primary' />
                          </div>
                          <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                            <a href={link.url} target='_blank' rel='noopener noreferrer'>
                              {link.title} <FontAwesomeIcon icon='external-link-alt' />
                            </a>
                          </div>
                        </small>
                      ))
                    }
                  </div>
                )
              }
              {
                files.length > 0 && (
                  <div className='p-1'>
                    {
                      files.map(file => (
                        <small key={file.id} className='d-flex mb-2'>
                          <div className='mr-2'>
                            <FontAwesomeIcon icon='file' className='text-primary' />
                          </div>
                          <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                            <span className='btn-link'>
                              {file.file_title}
                            </span>
                          </div>
                        </small>
                      ))
                    }
                  </div>
                )
              }
              {
                images.length > 0 && (
                  <Row noGutters>
                  {
                    images.map(image => (
                      <Col key={image.id} xs={4} className='image-container'>
                        <Image className='post-image' src={`${process.env['REACT_APP_API_BASE_URL']}/class/${data.faculty_load_id}/posts/${data.id}/image/${image.id}`} thumbnail />
                      </Col>
                    ))
                  }
                  </Row>
                )
              }
            </Card.Body>
            {
              data.likes.length > 0 && (
                <Card.Footer className='px-3 py-1 text-right'>
                  <small className='mr-2 font-italic'>
                    {`${data.likes.length > 1 ? data.likes.length : data.likes[0].name} liked this`}
                  </small>
                </Card.Footer>
              )
            }
            {
              data.comments.length > 0 && (
                <div className='border-top comments-section'>
                  {
                    data.comments.map(comment => (
                      <div key={comment.id} className='py-1 px-3'>
                        <div>
                          <div className='d-flex justify-content-between'>
                            <b>
                              <NameLink
                                id={comment.account_id}
                                name={comment.name}
                                image={comment.image}
                                rank={comment.rank} />
                            </b>
                            <div>
                              <small className='d-block text-right font-italic' style={{ color: '#6c757d' }}>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {moment(comment.created_at).format('MMM D, YYYY hh:mm A')}
                                    </Tooltip>
                                  }
                                  trigger={['hover', 'focus']}>
                                  <FontAwesomeIcon icon='clock' />
                                </OverlayTrigger> {moment(comment.created_at).fromNow()}
                              </small>
                            </div>
                          </div>
                          <div style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}>
                            {comment.message}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            }
          </Card>
        );
      default:
        return null;
    }
  }
  renderFiles = () => {
    const { isLoading, errorMessage, uploadLimits, isNextPageLoading, nextPageError } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (uploadLimits.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div>
          <div className='h4'>Archive</div>
          <div className='font-weight-bold font-italic'>Total: {uploadLimits.total} files</div>
        </div>
        <div className='dropdown-divider'></div>
        <Table striped bordered hover responsive size='sm'>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Size</th>
              <th>Section</th>
              <th>Date Uploaded</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              uploadLimits.data.map((uploadLimit, i) => (
                <tr key={uploadLimit.id}>
                  <th>{i+1}</th>
                  {
                    uploadLimit.title ? (
                      <td>
                        <Button
                          variant='link'
                          style={{ wordBreak: 'break-all'}}
                          onClick={() => this.handleDownloadFile(uploadLimit.id)}>
                            {uploadLimit.title}
                        </Button>
                      </td>
                    ) : displayMap[uploadLimit.uploadable_type] === 'Profile' ? (
                      <td>
                        <Button
                          variant='link'
                          className='font-italic'
                          onClick={() => this.handleDownloadFile(uploadLimit.id)}>
                            (Profile Picture)
                        </Button>
                      </td>
                    ) : (
                      <td>
                        <Button
                          variant='link'
                          className='font-italic'
                          onClick={() => this.handleDownloadFile(uploadLimit.id)}>
                            (Unknown Title)
                        </Button>
                      </td>
                    )
                  }
                  <td>{uploadLimit.size} bytes / <b>{Math.round((uploadLimit.size / 1024 / 1024)*100)/100} MB</b></td>
                  <td>{displayMap[uploadLimit.uploadable_type]}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>{moment(uploadLimit.created_at).format('MMM D, YYYY hh:mm A')}</td>
                  <td className='text-center' style={{ whiteSpace: 'nowrap' }}>
                    {
                      displayMap[uploadLimit.uploadable_type] !== 'Materials' && (
                        <Button variant='warning' className='mx-1' size='sm' title='Show' onClick={() => this.showViewModal(uploadLimit.id, displayMap[uploadLimit.uploadable_type])}>
                          <FontAwesomeIcon icon='eye' />
                        </Button>
                      )
                    }
                    <Button variant='danger' className='mx-1' size='sm' title='Delete' onClick={() => this.showDeleteModal(uploadLimit)}>
                      <FontAwesomeIcon icon='trash-alt' />
                    </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        {
          uploadLimits.next_page_url ? (
            <div className='text-center'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                Load more
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          ) : (
            <Alert variant='light' className='text-center'>
              No more files.
            </Alert>
          )
        }
      </>
    );
  }
  render() {
    const { viewModal, deleteModal } = this.state;

    return (
      <>
        <Header active='Profile' />
        <Container>
          <Card className='my-3'>
            <Card.Body>
              { this.renderFiles() }
              <Modal show={viewModal.show} onHide={this.hideViewModal}>
                <Modal.Header closeButton>
                  <Modal.Title>View under {viewModal.section.toLowerCase()} section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='upload-limit-view-overlay'></div>
                  {this.renderViewModalBody()}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='light' onClick={this.hideViewModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              <PromptDeleteModal
                {...deleteModal}
                title='Delete File'
                onHide={this.hideDeleteModal}
                onDelete={this.handleDelete}>
                  <Card.Subtitle>Are you sure you want to delete the file?</Card.Subtitle>
                  <Alert variant='light'>
                    {
                      deleteModal.data && (
                        <>
                          <div style={{ wordBreak: 'break-all' }}><b>{deleteModal.data.title ? deleteModal.data.title : displayMap[deleteModal.data.uploadable_type] === 'Profile' ? 'Profile Picture' : ''}</b></div>
                          <div>{deleteModal.data.size} bytes / <b>{Math.round((deleteModal.data.size / 1024 / 1024)*100)/100} MB</b></div>
                          <div>{displayMap[deleteModal.data.uploadable_type]}</div>
                          <div>{moment(deleteModal.data.created_at).format('MMM D, YYYY hh:mm A')}</div>
                        </>
                      )
                    }
                  </Alert>
              </PromptDeleteModal>
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  }
}