import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, Image, ProgressBar, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import PromptDeleteModal from '../../../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../../LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../RichTextEditor/RichTextEditor';

class Essay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      uploadFiles: {
        isLoading: false,
        fileNames: [],
        errorMessage: ''
      },
      files: [],
      deleteFileModal: {
        show: false,
        file: null,
        isLoading: false,
        errorMessage: ''
      }
    };

    this.files = [];
  }
  componentDidMount() {
    if (this.props.answer) {
      this.setState({
        ...this.state,
        value: this.props.answer.answer_essay && this.props.answer.answer_essay.answer ? this.props.answer.answer_essay.answer : '',
        files: this.props.answer.files ? this.props.answer.files : []
      });
    }
  }
  componentDidUpdate(prevProps) {
    if(prevProps.id !== this.props.id) {
      let value = '';
      let files = [];

      if (this.props.answer) {
        files = this.props.answer.files ? this.props.answer.files : [];
        if (this.props.answer.answer_essay && this.props.answer.answer_essay.answer) {
          value = this.props.answer.answer_essay.answer;
        }
      }

      this.setState({
        ...this.state,
        value,
        files
      });
    }
  }
  onChange = event => {
    let answer = event.target.value;
    this.setState({
      ...this.state,
      value: answer
    }, () => {
      this.props.onChange({
        type: 'essay',
        questionID: this.props.id,
        answer
      });
    });
  }
  onFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: true,
          isError: false,
          isSuccess: false
        };
      });
      this.files = [...this.files, ...files];
      this.setState({
        ...this.state,
        uploadFiles: {
          ...this.state.uploadFiles,
          fileNames: [
            ...this.state.uploadFiles.fileNames,
            ...filesState
          ]
        }
      }, () => {
        this.setState({
          ...this.state,
          uploadFiles: {
            ...this.state.uploadFiles,
            isLoading: true
          }
        }, () => {
          this.props.onChange({
            type: 'essay',
            questionID: this.props.id,
            answer: this.state.value
          }, true, this.handleFileUpload);
        });
      });
    }
  }
  onRemoveUpload = id => {
    const { uploadFiles } = this.state;

    const newFileNames = uploadFiles.fileNames.filter((fileName, index) => {
      return index !== id;
    });

    this.setState({
      ...this.state,
      uploadFiles: {
        ...this.state.uploadFiles,
        fileNames: newFileNames
      }
    }, () => {
      this.files = [...this.files].filter((file, index) => {
        return index !== id;
      });
    });
  }
  handleFileUpload = (id = null, classID, materialType, materialID) => {
    if (id) {
      let fileRequests = [];
      for (let i = 0; i < this.files.length; i++) {
        fileRequests.push(this.uploadFile(i, classID, id, materialType, materialID));
      }

      Promise.all(fileRequests).then(() => {
        this.setState({
          ...this.state,
          uploadFiles: {
            isLoading: false,
            fileNames: [],
            errorMessage: ''
          }
        }, () => {
          this.files = [];
          this.props.enableButtons();
        });
      }).catch(error => {
        this.setState({
          ...this.state,
          uploadFiles: {
            ...this.state.uploadFiles,
            isLoading: false,
            errorMessage: error
          }
        }, () => {
          this.props.enableButtons();
        });
      });
    } else {
      const fileToBase64 = file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', () => resolve(reader.result));
          reader.addEventListener('error', error => reject(error));
          reader.readAsDataURL(file);
        });
      };

      let promises = [];
      let files = [];

      for (let i = 0; i < this.files.length; i++) {
        let validator = {
          passed: true,
          error: null
        };

        if (typeof this.files[i].name !== 'string') {
          validator.passed = false;
          validator.error = 'Invalid file.';
        }

        if (validator.passed) {
          if (this.files[i].type === 'image/jpeg' || this.files[i].type === 'image/png') {
            promises.push(fileToBase64(this.files[i]).then(serial => {
              return {
                id: `${i}-${moment().format('MMDDYYY-HH:mm:ss')}`,
                serial,
                file_title: this.files[i].name,
                is_image: true,
                is_preview: true
              };
            }).catch(() => {
              let throwError = 'Failed to read image.';
              throw throwError;
            }));
          } else {
            files.push({
              id: `${i}-${moment().format('MMDDYYY-HH:mm:ss')}`,
              file_title: this.files[i].name,
              is_image: false,
              is_preview: true
            });
          }
        } else {
          this.setState({
            ...this.state,
            uploadFiles: {
              ...this.state.uploadFiles,
              isLoading: false,
              errorMessage: validator.error
            }
          });
          break;
        }
      }

      if (promises.length > 0) {
        Promise.all(promises).then((images) => {
          this.setState({
            ...this.state,
            uploadFiles: {
              isLoading: false,
              fileNames: [],
              errorMessage: ''
            },
            files: [...this.state.files, ...images, ...files]
          }, () => {
            this.files = [];
            this.props.onAddAttachments([...images, ...files]);
          });
        }).catch(error => {
          this.setState({
            ...this.state,
            uploadFiles: {
              ...this.state.uploadFiles,
              isLoading: false,
              errorMessage: error
            }
          });
        });
      } else {
        this.setState({
          ...this.state,
          uploadFiles: {
            isLoading: false,
            fileNames: [],
            errorMessage: ''
          },
          files: [...this.state.files, ...files]
        }, () => {
          this.files = [];
          this.props.onAddAttachments(files);
        });
      }
    }
  }
  uploadFile = (i, classID, answerID, materialType, materialID) => {
    const formData = new window.FormData();
    formData.append('file', this.files[i], this.files[i].name);
    // class/${classID}/material/${materialType}/${materialID}/questionable/answer
    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/material/${materialType}/${materialID}/questionable/answer/${answerID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { uploadFiles } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        uploadFiles.fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          uploadFiles
        });
      }
    }).then(({ data: { data }}) => {
      const { uploadFiles } = this.state;
      uploadFiles.fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        files: [
          ...this.state.files,
          data
        ],
        uploadFiles
      }, () => {
        this.props.onAddAttachments(data, this.props.id);
      });

      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { uploadFiles } = this.state;
      uploadFiles.fileNames[i].isLoading = false;
      uploadFiles.fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        uploadFiles
      });

      throw uploadFiles.fileNames[i].isError;
    });
  }
  showDeleteFileModal = (file) => {
    this.setState({
      ...this.state,
      deleteFileModal: {
        ...this.state.deleteFileModal,
        show: true,
        file
      }
    });
  }
  hideDeleteFileModal = () => {
    this.setState({
      ...this.state,
      deleteFileModal: {
        ...this.state,
        show: false,
        file: null,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleFileDelete = () => {
    const { deleteFileModal } = this.state;

    if (!deleteFileModal.file) {
      return false;
    }

    if (!deleteFileModal.file.is_preview) {
      const { answerUrl, answer} = this.props;

      axiosRequest('delete', `${answerUrl}/questionable/answer/${answer.id}/file/${deleteFileModal.file.id}`, null, ({ data: { message }}) => {
        let fileID = deleteFileModal.file.id;
        const newFiles = [...this.state.files].filter(file => {
          return file.id !== fileID;
        });
        this.setState({
          ...this.state,
          files: newFiles,
          deleteFileModal: {
            ...this.state,
            show: false,
            file: null,
            isLoading: false,
            errorMessage: ''
          }
        }, () => {
          this.props.onRemoveAttachment(fileID, this.props.id);
        });
      }, error => {
        this.setState({
          ...this.state,
          deleteFileModal: {
            ...this.state.deleteFileModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    } else {
      const newFiles = [...this.state.files].filter(file => {
        return file.id !== deleteFileModal.file.id;
      });
      this.setState({
        ...this.state,
        files: newFiles,
        deleteFileModal: {
          ...this.state.deleteFileModal,
          show: false,
          file: null,
          isLoading: false,
          errorMessage: ''
        }
      }, () => {
        this.props.onRemoveAttachment(null, deleteFileModal.file.id);
      });
    }
  }
  renderFileUpload = () => {
    const { uploadFiles } = this.state;

    if (uploadFiles.fileNames.length > 0) {
      return (
        <>
          <div className='text-muted mt-3'>
            {
              uploadFiles.isLoading ? (
                <div className='mb-1'>
                  <FontAwesomeIcon icon='exclamation' /> Please wait for the upload to finish.
                </div>
              ) : uploadFiles.errorMessage ? (
                <Alert variant='danger'>
                  {uploadFiles.errorMessage}
                </Alert>
              ) : null
            }
          </div>
          <small className='file-upload-display d-block border-top rounded-top'>
            {
              uploadFiles.fileNames.map((fileName, index) => (
                <div key={index} className='file-name d-flex'>
                  <div>
                    {
                      fileName.isLoading || (uploadFiles.isLoading && !fileName.isSuccess) ? (
                        <LoadingIcon />
                      ) : fileName.isError ? (
                        <FontAwesomeIcon icon='times-circle' className='text-danger' />
                      ) : fileName.isSuccess ? (
                        <FontAwesomeIcon icon='check-circle' className='text-green' />
                      ) : (
                        <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                      )
                    }
                  </div>
                  <div className='ml-2 flex-fill'>
                    <div>
                      {fileName.name}
                    </div>
                    {
                      fileName.isLoading && (
                        <div className='mt-1'>
                          <ProgressBar now={fileName.isLoading} />
                        </div>
                      )
                    }
                  </div>
                  {
                    (fileName.isError && !fileName.isLoading && !uploadFiles.isLoading) && (
                      <div className='ml-2 align-items-center d-flex'>
                        <Button
                          variant='link'
                          className='text-danger'
                          size='sm'
                          title='Remove'
                          onClick={(e) => this.onRemoveUpload(index)}>
                          <FontAwesomeIcon icon='times' size='sm' />
                        </Button>
                      </div>
                    )
                  }
                </div>
              ))
            }
          </small>
        </>
      )
    }
  }
  renderAnswerFiles = () => {
    const { files, deleteFileModal } = this.state;
    const { answerUrl, answer } = this.props;

    let imagesMap = files.filter(f => f.is_image);
    let filesMap = files.filter(f => !f.is_image);

    if (!answer) {
      return null;
    }

    return (
      <div>
        {
          (imagesMap && imagesMap.length > 0) && (
            <Row noGutters className='mb-2'>
              {
                imagesMap.map((image, index) => {
                  if (image.serial) {
                    return (
                      <Col key={index} md={4} className='question-image-container'>
                        <div className='image-delete-icon' title='Delete image' onClick={() => this.showDeleteFileModal(image, image.id)}>
                          <FontAwesomeIcon icon='times' size='sm' />
                        </div>
                        <Image src={image.serial} thumbnail />
                      </Col>
                    );
                  }

                  let imageSrc = `${process.env['REACT_APP_API_BASE_URL']}/${answerUrl}/questionable/answer/${answer.id}/image/${image.id}`;
                  return (
                    <Col key={index} md={4} className='question-image-container'>
                      <div className='image-delete-icon' title='Delete image' onClick={() => this.showDeleteFileModal(image, image.id)}>
                        <FontAwesomeIcon icon='times' size='sm' />
                      </div>
                      <a href={imageSrc} target='_blank' rel='noopener noreferrer'>
                        <Image src={imageSrc} thumbnail />
                      </a>
                    </Col>
                  );
                })
              }
            </Row>
          )
        }
        {
          (filesMap && filesMap.length > 0) && (
            <div className='px-2'>
              {
                filesMap.map((file, index) => (
                  <div key={index} className='d-flex mb-2'>
                    <div className='mr-2'>
                      <FontAwesomeIcon icon='file' className='text-primary' />
                    </div>
                    <div style={{ wordBreak: 'break-all' }}>
                      <span className='btn-link' onClick={e => this.handleAnswerFileDownload(file.id)}>
                        {file.file_title}
                      </span>
                    </div>
                    <div className='ml-2 text-danger' style={{ cursor: 'pointer' }} title='Delete file' onClick={() => this.showDeleteFileModal(file, file.id)}>
                      <FontAwesomeIcon icon='times' size='sm' />
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
        <PromptDeleteModal
          {...deleteFileModal}
          title='Delete attachment'
          onHide={this.hideDeleteFileModal}
          onDelete={this.handleFileDelete}
        >
          <Card.Subtitle>Are you sure you want to delete the attachment?</Card.Subtitle>
          {
            (deleteFileModal.file && !deleteFileModal.file.serial) && (
              <Alert variant='light'>
                <div style={{ wordBreak: 'break-all' }}>{deleteFileModal.file.file_title}</div>
              </Alert>
            )
          }
        </PromptDeleteModal>
      </div>
    );
  }
  handleFileDownload = fileID => {
    const { url, id } = this.props;

    axiosRequest('get', `${url}/${id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handleAnswerFileDownload = fileID => {
    const { answerUrl, answer } = this.props;

    axiosRequest('get', `${answerUrl}/questionable/answer/${answer.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handleCopyPaste = e => {
    e.preventDefault();
  }
  handlePreviewImages = (url, id, images, index) => {
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/${url}/${id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  render() {
    const { question, files, url, id, points } = this.props;
    const { value, uploadFiles } = this.state;

    const questionImages = files.filter(file => {
      return file.is_image;
    });

    const questionFiles = files.filter(file => {
      return !file.is_image;
    });

    return (
      <div>
        <RichTextEditor.Viewer body={question.description} className='px-2 px-md-3 py-1 user-select-none' />
        {/* <div className='px-2 px-md-3 py-1 user-select-none'>{question.description}</div> */}
        {
          files.length > 0 && (
            <div className='px-2 px-md-3'>
              {
                questionImages.length > 0 && (
                  <Row>
                  {
                    questionImages.map((image, index) => (
                      <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(url, id, questionImages, index)}>
                        <Image src={`${process.env['REACT_APP_API_BASE_URL']}/${url}/${id}/image/${image.id}`} thumbnail />
                      </Col>
                    ))
                  }
                  </Row>
                )
              }
              {
                questionFiles.length > 0 && (
                  <div className='p-1'>
                    {
                      questionFiles.map(file => (
                        <small key={file.id} className='d-flex mb-2'>
                          <div className='mr-2'>
                            <FontAwesomeIcon icon='file' className='text-primary' />
                          </div>
                          <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                            <span className='btn-link' onClick={() => this.handleFileDownload(file.id)}>
                              {file.file_title}
                            </span>
                          </div>
                        </small>
                      ))
                    }
                  </div>
                )
              }
            </div>
          )
        }
        <div className='px-2 px-md-3 text-muted'>
          ({points} point{points > 1 ? 's' : ''})
        </div>
        <div className='p-2 p-md-3'>
          <Form.Group controlId='answer'>
            <Form.Label>Answer</Form.Label>
            <Form.Control as='textarea' onChange={this.onChange} onPaste={this.handleCopyPaste} onCopy={this.handleCopyPaste} value={value} disabled={uploadFiles.isLoading} />
            {this.renderFileUpload()}
            <div>
              <label className={`btn btn-light m-0 ${uploadFiles.isLoading ? 'disabled' : ''}`} htmlFor='add-file' title='Upload file'>
                <FontAwesomeIcon icon='file-upload' /> Upload file
              </label>
              <Form.File className='d-none' id='add-file' onChange={this.onFileUpload} multiple disabled={uploadFiles.isLoading} />
            </div>
          </Form.Group>
          {this.renderAnswerFiles()}
        </div>
      </div>
    );
  }
}

Essay.propTypes = {
  onChange: PropTypes.func.isRequired
};

Essay.defaultProps = {
  onChange: () => {}
};

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Essay);