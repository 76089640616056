import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../../common/Header/Header';
import SchoolClasses from '../../units/SchoolClasses/SchoolClasses';
import Classroom from '../../units/Classroom/Classroom';
import { Redirect, Route, Switch } from 'react-router-dom';
import Questionable from './Questionable/Questionable';
import Assignment from './Assignment/Assignment';
import Announcement from '../Announcement/Announcement';
import Events from '../Events/Events';

export default class Faculty extends Component {
  render() {
    return (
      <>
        <Header />
        <Container>
          <Switch>
            <Route exact path='/:userType' component={SchoolClasses} />
            <Route exact path='/:userType/events' component={Events} />
            <Route exact path='/:userType/announcement' component={Announcement} />
            <Route path='/faculty/class/:classID' render={props => (
              <Switch>
                <Route path='/faculty/class/:classID/:materialType(quiz|exam)/:materialID' render={props => (
                  <Switch>
                    <Route exact path='/faculty/class/:classID/:materialType(quiz|exam)/:materialID/:activeNav(questions|preview|submissions)' component={Questionable} />
                    <Route exact path='/faculty/class/:classID/:materialType(exam)/:materialID/:activeNav(questions|preview|submissions|manage-students)' component={Questionable} />
                    <Redirect to={{ pathname: '/faculty/class/:classID/:materialType(quiz|exam)/:materialID/preview' }} />
                  </Switch>
                )} />
                <Route exact path='/faculty/class/:classID/:materialType(assignment)/:materialID' component={Assignment} />
                <Route exact path={`/faculty/class/:classID/:menu`} component={Classroom} />
                <Redirect to={{ pathname: '/faculty/class/:classID/discussions' }} />
              </Switch>
            )} />
            <Redirect to={{ pathname: '/faculty' }} />
          </Switch>
        </Container>
      </>
    );
  }
}