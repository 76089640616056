import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import { Alert, Button, ButtonGroup, Breadcrumb, Card, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt } from 'react-router-dom';
import NameLink from '../../../common/NameLink/NameLink';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import './style.scss';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

const typesOfTransition = [
  {
    title: 'Retain',
    key: 'retain',
    theme: 'info',
    icon: 'user-lock'
  },
  {
    title: 'Irregular',
    key: 'irregular',
    theme: 'warning',
    icon: 'user'
  },
  {
    title: 'Promote',
    key: 'promote',
    theme: 'green',
    icon: 'people-carry'
  },
  {
    title: 'Graduate',
    key: 'graduate',
    theme: 'danger',
    icon: 'user-graduate'
  }
];

export default class ClassTransition extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      classes: {
        data: []
      },
      isNextPageLoading: false,
      nextPageError: '',
      selectedClass: null,
      students: {
        selectedAccordion: null,
        list: [],
        step: '',
        transitions: [],
        disableNext: false,
        isOptionsLoading: false,
        optionsError: '',
        options: null,
        irregularAll: {
          program: '',
          yearLevel: ''
        },
        promoteAll: {
          schoolClass: ''
        }
      },
      block: false,
      showBlockModal: false,
      showSaveModal: false,
      isFormLoading: false,
      formError: ''
    };
  }
  componentDidMount() {
    axiosRequest('get', `admin/transition/classes`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        isLoading: false,
        classes: data
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  componentDidUpdate = () => {
    if (this.state.block) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }
  componentWillUnmount = () => {
    window.onbeforeunload = undefined;
  }
  handleSelectAccordion = selectedAccordion => {
    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        selectedAccordion
      }
    });
  }
  handleSelectClass = selectedClass => {
    const list = [...selectedClass.student_classes].map(st => {
      return {
        ...st,
        transitionType: ''
      }
    });
    this.setState({
      ...this.state,
      selectedClass,
      students: {
        ...this.state.students,
        list
      },
      block: false
    });
  }
  showBlockModal = () => {
    if (this.state.block) {
      this.setState({
        ...this.state,
        showBlockModal: true
      });
    } else {
      this.handleBack();
    }
  }
  hideBlockModal = () => {
    this.setState({
      ...this.state,
      showBlockModal: false
    });
  }
  handleBack = () => {
    this.setState({
      ...this.state,
      selectedClass: null,
      block: false,
      showBlockModal: false,
      students: {
        selectedAccordion: null,
        list: [],
        step: '',
        transitions: [],
        disableNext: false,
        isOptionsLoading: false,
        optionsError: '',
        options: null,
        irregularAll: {
          program: '',
          yearLevel: ''
        },
        promoteAll: {
          schoolClass: ''
        }
      }
    });
  }
  renderRetain = () => {
    const { students } = this.state;
    let transitions = students.transitions.find(t => t.key === 'retain');
    if (!transitions) {
      transitions = [];
    } else {
      transitions = [...transitions.list];
    }

    return (
      <>
        <h5>
          <FontAwesomeIcon icon='user-lock' className='text-info' /> Retain
        </h5>
        <div className='dropdown-divider'></div>
        {
          transitions.length === 0 ? (
            <Alert variant='warning'>
              No students will be retained.
            </Alert>
          ) : (
            <div className='py-2 px-3'>
              <div className='font-weight-bold'>
                {transitions.length} student{transitions.length === 1 ? '' : 's'} will be retained:
              </div>
              <div className='transition-students-list px-3'>
                {
                  transitions.map((st, i) => (
                    <div key={st.id} className='py-2 d-flex align-items-center'>
                      <div className='mr-3 font-weight-bold'>
                        {i+1}.
                      </div>
                      <NameLink id={st.id} name={st.profile.name} image={st.profile.image} disableLink />
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
      </>
    );
  }
  handleIrregularApply = () => {
    const { students } = this.state;
    const { irregularAll: { program, yearLevel } } = students;

    let newTransitions = [...students.transitions].map(t => {
      if (t.key === 'irregular') {
        let newList = t.list.map(l => {
          return {
            ...l,
            transition: {
              program,
              yearLevel
            }
          };
        });

        return {
          ...t,
          list: newList
        };
      }
      return {...t};
    });

    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        transitions: newTransitions,
        disableNext: false
      }
    });
  }
  handleIrregularSelectAll = e => {
    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        irregularAll: {
          ...this.state.students.irregularAll,
          [e.target.name]: e.target.value
        }
      }
    });
  }
  handleIrregularSelect = (e, st) => {
    const { students } = this.state;

    let isNotComplete = false;
    let newTransitions = [];
    for (let i = 0; i < students.transitions.length; i++) {
      let t = students.transitions[i];
      if (t.key === 'irregular') {
        let newList = [];
        for (let j = 0; j < t.list.length; j++) {
          let l = t.list[j];
          if (l.id === st.id) {
            newList.push({
              ...l,
              transition: {
                ...l.transition,
                [e.target.name]: e.target.value
              }
            });
          } else {
            newList.push({...l});
          }

          if (newList[j].transition.program === '' || newList[j].transition.yearLevel === '') {
            isNotComplete = true;
          }
        }
        newTransitions.push({
          ...t,
          list: newList
        });
      } else {
        newTransitions.push({...t});
      }
    }

    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        transitions: newTransitions,
        disableNext: isNotComplete
      }
    });
  }
  renderIrregular = () => {
    const { students } = this.state;
    let transitions = students.transitions.find(t => t.key === 'irregular');
    if (!transitions) {
      transitions = [];
    } else {
      transitions = [...transitions.list];
    }

    return (
      <>
        <h5>
          <FontAwesomeIcon icon='user' className='text-warning' /> Irregular
        </h5>
        <div className='dropdown-divider'></div>
        {
          transitions.length === 0 ? (
            <Alert variant='warning'>
              No students will be set to irregular.
            </Alert>
          ) : (
            <div className='py-2 px-3'>
              <div className='form-inline mb-2 justify-content-end'>
                <Form.Control as='select' name='program' size='sm' value={students.irregularAll.program} onChange={this.handleIrregularSelectAll}>
                  <option value='' hidden>Select program...</option>
                  {
                    students.options.programs.map(p => (
                      <option key={p.id} value={p.id}>
                        {p.code}
                      </option>
                    ))
                  }
                </Form.Control>
                <Form.Control as='select' name='yearLevel' size='sm' value={students.irregularAll.yearLevel} onChange={this.handleIrregularSelectAll}>
                  <option value='' hidden>Select year level...</option>
                  {
                    students.options.yearLevels.map(yl => (
                      <option key={yl.id} value={yl.id}>
                        {yl.code}
                      </option>
                    ))
                  }
                </Form.Control>
                <Button variant='info' size='sm' onClick={this.handleIrregularApply} disabled={students.irregularAll.program === '' || students.irregularAll.yearLevel === ''}>
                  Apply to all
                </Button>
              </div>
              <div className='font-weight-bold'>
                {transitions.length} student{transitions.length === 1 ? '' : 's'} will be set to irregular:
              </div>
              <div className='transition-students-list px-3'>
                {
                  transitions.map((st, i) => (
                    <div key={st.id} className='py-2 d-flex align-items-center'>
                      <div className='mr-3 font-weight-bold'>
                        {i+1}.
                      </div>
                      <div className='flex-fill'>
                        <NameLink id={st.id} name={st.profile.name} image={st.profile.image} disableLink />
                        <div className='form-inline mt-2'>
                          <Form.Control as='select' name='program' size='sm' value={st.transition.program} onChange={e => this.handleIrregularSelect(e, st)}>
                            <option value='' hidden>Select program...</option>
                            {
                              students.options.programs.map(p => (
                                <option key={p.id} value={p.id}>
                                  {p.code}
                                </option>
                              ))
                            }
                          </Form.Control>
                          <Form.Control as='select' name='yearLevel' size='sm' value={st.transition.yearLevel} onChange={e => this.handleIrregularSelect(e, st)}>
                            <option value='' hidden>Select year level...</option>
                            {
                              students.options.yearLevels.map(yl => (
                                <option key={yl.id} value={yl.id}>
                                  {yl.code}
                                </option>
                              ))
                            }
                          </Form.Control>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
      </>
    );
  }
  handlePromoteApply = () => {
    const { students } = this.state;
    const { promoteAll: { schoolClass } } = students;

    let newTransitions = [...students.transitions].map(t => {
      if (t.key === 'promote') {
        let newList = t.list.map(l => {
          return {
            ...l,
            transition: {
              schoolClass
            }
          };
        });

        return {
          ...t,
          list: newList
        };
      }
      return {...t};
    });

    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        transitions: newTransitions,
        disableNext: false
      }
    });
  }
  handlePromoteSelectAll = e => {
    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        promoteAll: {
          ...this.state.students.promoteAll,
          [e.target.name]: e.target.value
        }
      }
    });
  }
  handlePromoteSelect = (e, st) => {
    const { students } = this.state;

    let isNotComplete = false;
    let newTransitions = [];
    for (let i = 0; i < students.transitions.length; i++) {
      let t = students.transitions[i];
      if (t.key === 'promote') {
        let newList = [];
        for (let j = 0; j < t.list.length; j++) {
          let l = t.list[j];
          if (l.id === st.id) {
            newList.push({
              ...l,
              transition: {
                ...l.transition,
                [e.target.name]: e.target.value
              }
            });
          } else {
            newList.push({...l});
          }

          if (newList[j].transition.schoolClass === '') {
            isNotComplete = true;
          }
        }
        newTransitions.push({
          ...t,
          list: newList
        });
      } else {
        newTransitions.push({...t});
      }
    }

    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        transitions: newTransitions,
        disableNext: isNotComplete
      }
    });
  }
  getIrregularLabel = (programID, yearLevelID) => {
    const { students: { options } } = this.state;

    if (!options) {
      return null;
    }

    let program = options.programs.find(p => p.id === +programID);
    let yearLevel = options.yearLevels.find(yl => yl.id === +yearLevelID);

    if (program && yearLevel) {
      return (
        <div className='pl-2'>
          {program.code} {yearLevel.code}
        </div>
      )
    }

    return null;
  }
  getPromoteLabel = schoolClassID => {
    const { students: { options } } = this.state;

    if (!options) {
      return null;
    }

    let schoolClass = options.classes.find(cl => cl.id === +schoolClassID);

    if (schoolClass) {
      return (
        <div className='pl-2'>
          {schoolClass.program.code} {schoolClass.year_level.code} - {schoolClass.section.code}
        </div>
      )
    }

    return null;
  }
  handleEditSummary = key => {
    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        step: (key === 'irregular' || key === 'promote') ? key : ''
      },
      showSaveModal: false
    });
  }
  showSaveModal = () => {
    this.setState({
      ...this.state,
      showSaveModal: true
    });
  }
  hideSaveModal = () => {
    this.setState({
      ...this.state,
      showSaveModal: false
    });
  }
  handleSave = () => {
    const { students: { transitions }, selectedClass } = this.state;

    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: ''
    }, () => {
      let data = {
        retain: [],
        irregular: [],
        program: [],
        yearLevel: [],
        promote: [],
        schoolClass: [],
        graduate: []
      };
      let errors = [];
      for (let i = 0; i < typesOfTransition.length; i++) {
        let transition = transitions.find(t => t.key === typesOfTransition[i].key);
        if (transition) {
          if (typesOfTransition[i].key === 'irregular') {
            for (let j = 0; j < transition.list.length; j++) {
              if (transition.list[j].transition && transition.list[j].transition.program && transition.list[j].transition.yearLevel) {
                data[typesOfTransition[i].key].push(transition.list[j].id);
                data.program.push(transition.list[j].transition.program);
                data.yearLevel.push(transition.list[j].transition.yearLevel);
              } else {
                errors.push({
                  key: typesOfTransition[i].key,
                  student: transition.list[j]
                });
              }
            }
          } else if (typesOfTransition[i].key === 'promote') {
            for (let j = 0; j < transition.list.length; j++) {
              data[typesOfTransition[i].key].push(transition.list[j].id);
              if (transition.list[j].transition && transition.list[j].transition.schoolClass) {
                data.schoolClass.push(transition.list[j].transition.schoolClass);
              } else {
                errors.push({
                  key: typesOfTransition[i].key,
                  student: transition.list[j]
                });
              }
            }
          } else {
            data[typesOfTransition[i].key] = transition.list.map(l => l.id);
          }
        }
      }
  
      if (errors.length > 1) {
        let formError = `The ${errors[0].key === 'irregular' ? 'fields program and year level are' : 'field school class is'} required on "${errors[0].student.profile.name}".`;
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError
        });
        return;
      }

      axiosRequest('post', `admin/transition/${selectedClass.id}`, data, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          classes: data,
          isNextPageLoading: false,
          nextPageError: '',
          selectedClass: null,
          block: false,
          showBlockModal: false,
          showSaveModal: false,
          isFormLoading: false,
          students: {
            selectedAccordion: null,
            list: [],
            step: '',
            transitions: [],
            disableNext: false,
            isOptionsLoading: false,
            optionsError: '',
            options: null,
            irregularAll: {
              program: '',
              yearLevel: ''
            },
            promoteAll: {
              schoolClass: ''
            }
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  renderPromote = () => {
    const { students } = this.state;
    let transitions = students.transitions.find(t => t.key === 'promote');
    if (!transitions) {
      transitions = [];
    } else {
      transitions = [...transitions.list];
    }

    return (
      <>
        <h5>
          <FontAwesomeIcon icon='people-carry' className='text-green' /> Promote
        </h5>
        <div className='dropdown-divider'></div>
        {
          transitions.length === 0 ? (
            <Alert variant='warning'>
              No students will be promoted.
            </Alert>
          ) : (
            <div className='py-2 px-3'>
              <div className='form-inline mb-2 justify-content-end'>
                <Form.Control as='select' name='schoolClass' size='sm' value={students.promoteAll.schoolClass} onChange={this.handlePromoteSelectAll}>
                  <option value='' hidden>Select school class...</option>
                  {
                    students.options.classes.map(c => (
                      <option key={c.id} value={c.id}>
                        {c.program.code} {c.year_level.code} - {c.section.code}
                      </option>
                    ))
                  }
                </Form.Control>
                <Button variant='info' size='sm' onClick={this.handlePromoteApply} disabled={students.promoteAll.schoolClass === ''}>
                  Apply to all
                </Button>
              </div>
              <div className='font-weight-bold'>
                {transitions.length} student{transitions.length === 1 ? '' : 's'} will be promoted:
              </div>
              <div className='transition-students-list px-3'>
                {
                  transitions.map((st, i) => (
                    <div key={st.id} className='py-2 d-flex align-items-center'>
                      <div className='mr-3 font-weight-bold'>
                        {i+1}.
                      </div>
                      <div className='flex-fill'>
                        <NameLink id={st.id} name={st.profile.name} image={st.profile.image} disableLink />
                        <div className='form-inline mt-2'>
                          <Form.Control as='select' name='schoolClass' size='sm' value={st.transition.schoolClass} onChange={e => this.handlePromoteSelect(e, st)}>
                            <option value='' hidden>Select school class...</option>
                            {
                              students.options.classes.map(c => (
                                <option key={c.id} value={c.id}>
                                  {c.program.code} {c.year_level.code} - {c.section.code}
                                </option>
                              ))
                            }
                          </Form.Control>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
      </>
    );
  }
  renderGraduate = () => {
    const { students } = this.state;
    let transitions = students.transitions.find(t => t.key === 'graduate');
    if (!transitions) {
      transitions = [];
    } else {
      transitions = [...transitions.list];
    }

    return (
      <>
        <h5>
          <FontAwesomeIcon icon='user-graduate' className='text-danger' /> Graduate
        </h5>
        <div className='dropdown-divider'></div>
        {
          transitions.length === 0 ? (
            <Alert variant='warning'>
              No student accounts will be deleted.
            </Alert>
          ) : (
            <div className='py-2 px-3'>
              <div className='font-weight-bold'>
                {transitions.length} student account{transitions.length === 1 ? '' : 's'} will be deleted:
              </div>
              <div className='transition-students-list px-3'>
                {
                  transitions.map((st, i) => (
                    <div key={st.id} className='py-2 d-flex align-items-center'>
                      <div className='mr-3 font-weight-bold'>
                        {i+1}.
                      </div>
                      <NameLink id={st.id} name={st.profile.name} image={st.profile.image} disableLink />
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
      </>
    );
  }
  handleFirstNext = () => {
    const { students, selectedClass } = this.state;

    let transitions = [{key: '', list: []}];
    for (let i = 0; i < typesOfTransition.length; i++) {
      let list = this.getStudentTransitions(typesOfTransition[i].key);
      let tempTransition = students.transitions.find(t => t.key === typesOfTransition[i].key);
      if (list.length !== 0) {
        if (typesOfTransition[i].key === 'irregular') {
          list = [...list].map(l => {
            if (tempTransition) {
              let tempL = tempTransition.list.find(ttl => ttl.id === l.id);

              if (tempL && tempL.transition && tempL.transition.program && tempL.transition.yearLevel) {
                return {
                  ...l,
                  transition: {...tempL.transition}
                };
              }
            }
            return {
              ...l,
              transition: {
                program: '',
                yearLevel: ''
              }
            };
          });
        } else if (typesOfTransition[i].key === 'promote') {
          list = [...list].map(l => {
            if (tempTransition) {
              let tempL = tempTransition.list.find(ttl => ttl.id === l.id);

              if (tempL && tempL.transition && tempL.transition.schoolClass) {
                return {
                  ...l,
                  transition: {...tempL.transition}
                };
              }
            }
            return {
              ...l,
              transition: {
                schoolClass: ''
              }
            };
          });
        }

        transitions.push({
          key: typesOfTransition[i].key,
          list
        });
      }
    }

    let disableNext = false;
    if (transitions[1].key === 'irregular' || transitions[1].key === 'promote') {
      for (let i = 0; i < transitions[1].list.length; i++) {
        if (transitions[1].key === 'irregular') {
          if (transitions[1].list[i].transition && (transitions[1].list[i].transition.program === '' || transitions[1].list[i].transition.yearLevel === '')) {
            disableNext = true;
            break;
          }
        } else {
          if (transitions[1].list[i].transition && transitions[1].list[i].transition.schoolClass === '') {
            disableNext = true;
            break;
          }
        }
      }
    }

    if (students.options === null && transitions.find(t => t.key === 'irregular' || t.key === 'promote')) {
      this.setState({
        ...this.state,
        students: {
          ...this.state.students,
          isOptionsLoading: true,
          optionsError: ''
        }
      }, () => {
        axiosRequest('get', `admin/transition/options/${selectedClass.id}`, null, ({ data: { data }}) => {
          data.classes.sort((a, b) => {
            if (a.program.code > b.program.code) {
              return 1;
            } else if (a.program.code < b.program.code) {
              return -1;
            }

            if (a.year_level.code > b.year_level.code) {
              return 1;
            } else if (a.year_level.code < b.year_level.code) {
              return -1;
            }

            if (a.section.code > b.section.code) {
              return 1;
            } else if (a.section.code < b.section.code) {
              return -1;
            }

            return 0;
          });
          this.setState({
            ...this.state,
            students: {
              ...this.state.students,
              transitions,
              step: transitions[1].key,
              disableNext,
              isOptionsLoading: false,
              options: data
            }
          });
        }, error => {
          this.setState({
            ...this.state,
            students: {
              ...this.state.students,
              isOptionsLoading: false,
              optionsError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        }, this.props.history);
      });
    } else {
      this.setState({
        ...this.state,
        students: {
          ...this.state.students,
          transitions,
          step: transitions[1].key,
          disableNext
        }
      });
    }
  }
  handleNext = () => {
    const { students } = this.state;

    let findIndex = students.transitions.findIndex(t => t.key === students.step);

    if (findIndex === -1 || findIndex === students.transitions.length-1) {
      return;
    }

    let disableNext = false;

    if (students.transitions[findIndex+1].key === 'irregular' || students.transitions[findIndex+1].key === 'promote') {
      for (let i = 0; i < students.transitions[findIndex+1].list.length; i++) {
        if (students.transitions[findIndex+1].key === 'irregular') {
          if (students.transitions[findIndex+1].list[i].transition && (students.transitions[findIndex+1].list[i].transition.program === '' || students.transitions[findIndex+1].list[i].transition.yearLevel === '')) {
            disableNext = true;
            break;
          }
        } else {
          if (students.transitions[findIndex+1].list[i].transition && students.transitions[findIndex+1].list[i].transition.schoolClass === '') {
            disableNext = true;
            break;
          }
        }
      }
    }

    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        step: students.transitions[findIndex+1].key,
        disableNext
      }
    });
  }
  handlePrev = () => {
    const { students } = this.state;

    let findIndex = students.transitions.findIndex(t => t.key === students.step);

    if (findIndex < 1 ) {
      return;
    }

    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        step: students.transitions[findIndex-1].key,
        disableNext: false
      }
    });
  }
  handleSelectTransition = (studentClass, transition) => {
    const newList = [...this.state.students.list].map(st => {
      if (studentClass.id === st.id) {
        return {
          ...st,
          transitionType: transition.key
        }
      }

      return {
        ...st
      };
    });
    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        list: newList,
        selectedAccordion: transition.key
      },
      block: true
    });
  }
  handleSelectTransitionAll = transition => {
    const newList = [...this.state.students.list].map(st => {
      return {
        ...st,
        transitionType: transition.key
      }
    });

    this.setState({
      ...this.state,
      students: {
        ...this.state.students,
        list: newList,
        selectedAccordion: transition.key
      },
      block: true
    });
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { classes } = this.state;

      const path = classes.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          classes: {
            ...data,
            data: [
              ...this.state.classes.data,
              ...data.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  getStudentTransitions = transitionType => {
    const { students } = this.state;

    return students.list.filter(st => st.transitionType === transitionType);
  }
  renderTransitionTitle = transitionType => {
    let transitions = this.getStudentTransitions(transitionType);

    return (
      <i className='text-muted'>
        &mdash;{transitions.length} student{transitions.length === 1 ? '' : 's'}
      </i>
    );
  }
  renderStudentList = transitionType => {
    let transitions = this.getStudentTransitions(transitionType);

    if (transitions.length === 0) {
      return (
        <div className='text-muted py-3 px-4'>
          Nothing to show.
        </div>
      );
    }

    return (
      <ListGroup variant='flush' style={{ maxHeight: '60vh', overflowY: 'auto' }}>
        {
          transitions.map(st => (
            <ListGroup.Item key={st.id}>
              <NameLink id={st.id} name={st.profile.name} image={st.profile.image} disableLink />
            </ListGroup.Item>
          ))
        }
      </ListGroup>
    );
  }
  getDisabledFirstNext = () => {
    const { students } = this.state;
    let hasEmpty = students.list.find(st => st.transitionType === '');

    if (hasEmpty) {
      return true;
    }

    let filtered = students.list.filter(l => l.transitionType === 'retain');
    return students.list.length === filtered.length;
  }
  renderTransition = () => {
    const { selectedClass, students } = this.state;

    if (students.isOptionsLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (students.optionsError) {
      return (
        <Alert variant='danger'>
          {students.optionsError}
        </Alert>
      );
    }

    switch(students.step) {
      case 'retain':
        return this.renderRetain();
      case 'irregular':
        return this.renderIrregular();
      case 'promote':
        return this.renderPromote();
      case 'graduate':
        return this.renderGraduate();
      default:
        break;
    }

    return (
      <>
        <div>
          <div>
            <h5>
              {selectedClass.program.code} {selectedClass.year_level.code} - {selectedClass.section.code}
            </h5>
          </div>
          <div>
            <div>{selectedClass.program.description}</div>
            <div>{selectedClass.year_level.description}</div>
            <div>Section {selectedClass.section.code}</div>
          </div>
          <div className='font-weight-bold font-italic'>
            {selectedClass.student_classes.length} student{selectedClass.student_classes.length === 1 ? '' : 's'}
          </div>
        </div>
        <div className='dropdown-divider'></div>
        <Row>
          <Col lg={6} className='py-3'>
            <div className='mb-3'>
              <ButtonGroup size='sm' className='d-flex'>
                {
                  typesOfTransition.map((t, i) => (
                    <Button
                      key={i}
                      variant={t.theme}
                      title={`${t.title} All`}
                      onClick={e => this.handleSelectTransitionAll(t)}>
                      <div className='d-lg-none'>
                        <FontAwesomeIcon icon={t.icon} size='sm' />
                        <FontAwesomeIcon icon='globe-asia' transform='shrink-3 down-2' />
                      </div>
                      <div className='d-none d-lg-block'>
                        {t.title} All
                      </div>
                    </Button>
                  ))
                }
              </ButtonGroup>
            </div>
            <ListGroup>
              {
                students.list.map(studentClass => (
                  <ListGroup.Item key={studentClass.id}>
                    <div className='d-flex'>
                      <NameLink id={studentClass.id} name={studentClass.profile.name} image={studentClass.profile.image} disableLink />
                    </div>
                    <div className='dropdown-divider'></div>
                    <div className='px-2'>
                      <ButtonGroup size='sm' className='d-flex'>
                        {
                          typesOfTransition.map((t, i) => (
                            <Button
                              key={i}
                              variant={t.theme}
                              title={t.title}
                              onClick={e => this.handleSelectTransition(studentClass, t)}
                              disabled={studentClass.transitionType === t.key}>
                              <div>
                                <FontAwesomeIcon icon={t.icon} size='sm' />
                              </div>
                              <div className='d-none d-lg-block'>
                                {t.title}
                              </div>
                            </Button>
                          ))
                        }
                      </ButtonGroup>
                    </div>
                  </ListGroup.Item>
                ))
              }
            </ListGroup>
          </Col>
          <Col lg={6}>
            <div className='py-3 sticky-top'>
              <Accordion className='transition-list' onSelect={this.handleSelectAccordion} activeKey={students.selectedAccordion}>
                {
                  typesOfTransition.map((t, i) => (
                    <Card key={i} className='small'>
                      <Accordion.Toggle as={Card.Header} eventKey={t.key}>
                        <div className='d-flex'>
                          <div className='text-center mr-2'>
                            <FontAwesomeIcon icon={t.icon} className={`text-${t.theme}`} />
                          </div>
                          <div className='flex-fill'>
                            <b>{t.title}</b> {this.renderTransitionTitle(t.key)}
                          </div>
                          <div>
                            {
                              (students.selectedAccordion && students.selectedAccordion === t.key) ? (
                                <FontAwesomeIcon icon='chevron-up' />
                              ) : (
                                <FontAwesomeIcon icon='chevron-down' />
                              )
                            }
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={t.key}>
                        { this.renderStudentList(t.key) }
                      </Accordion.Collapse>
                    </Card>
                  ))
                }
              </Accordion>
            </div>
          </Col>
        </Row>
        <div className='my-3 text-right'>
          <Button variant='green' onClick={this.handleFirstNext} disabled={this.getDisabledFirstNext()}>
            Next
          </Button>
        </div>
      </>
    );
  }
  renderStepTitle = () => {
    const { students } = this.state;

    if (students.step === '') {
      return null;
    }

    let transitionType = typesOfTransition.find(t => t.key === students.step);
    if (!transitionType) {
      return null;
    }

    return (
      <span className='ml-2'>
        {`> ${transitionType.title}`}
      </span>
    )
  }
  renderContent = () => {
    const {
      isLoading,
      errorMessage,
      classes,
      isNextPageLoading,
      nextPageError,
      selectedClass,
      students
    } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (selectedClass) {
      return (
        <>
          <Breadcrumb>
            <Breadcrumb.Item href='#' onClick={this.showBlockModal}>
              Classes
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {selectedClass.program.code} {selectedClass.year_level.code} - {selectedClass.section.code}
              {this.renderStepTitle()}
            </Breadcrumb.Item>
          </Breadcrumb>
          {this.renderTransition()}
          {
            students.step !== '' && (
              <div className='my-3 text-right'>
                <Button variant='green' className='mr-2' onClick={this.handlePrev}>
                  Previous
                </Button>
                {
                  students.transitions.length > 0 && students.transitions[students.transitions.length-1].key === students.step ? (
                    <Button variant='green' onClick={this.showSaveModal} disabled={students.disableNext}>
                      Summary
                    </Button>
                  ) : (
                    <Button variant='green' onClick={this.handleNext} disabled={students.disableNext}>
                      Next
                    </Button>
                  )
                }
              </div>
            )
          }
        </>
      );
    }

    if (classes.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <ListGroup>
        {
          classes.data.map(cl => (
            <ListGroup.Item key={cl.id} className='drop-courses-student btn-light' onClick={e => this.handleSelectClass(cl)}>
              <div className='d-flex'>
                <div className='flex-fill'>
                  <div>
                    <h5>
                      {cl.program.code} {cl.year_level.code} - {cl.section.code}
                    </h5>
                  </div>
                  <div>{cl.program.description}</div>
                  <div>{cl.year_level.description}</div>
                  <div>Section {cl.section.code}</div>
                  <div className='font-weight-bold font-italic'>
                    {cl.student_classes.length} student{cl.student_classes.length === 1 ? '' : 's'}
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon icon='chevron-right' size='lg' />
                </div>
              </div>
            </ListGroup.Item>
          ))
        }
        {
          classes.next_page_url && (
            <ListGroup.Item className={`text-center btn-link ${isNextPageLoading ? 'disabled' : ''}`} onClick={this.handleNextPage}>
              {
                isNextPageLoading && (
                  <div>
                    <LoadingIcon />
                  </div>
                )
              }
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
              <div><h5>Show more...</h5></div>
            </ListGroup.Item>
          )
        }
      </ListGroup>
    );
  }
  render() {
    const { showBlockModal, showSaveModal, students, isFormLoading, formError } = this.state;
    return (
      <Card>
        <Card.Body>
          <Prompt when={this.state.block} message='Changes you made may not be saved.' />
          { this.renderContent() }
          <Modal show={showBlockModal} onHide={this.hideBlockModal} size='sm'>
            <Modal.Header>
              <Modal.Title>Unsaved changes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Changes you made may not be saved.
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' className='mr-2' onClick={this.hideBlockModal} size='sm'>
                Cancel
              </Button>
              <Button variant='green' onClick={this.handleBack} size='sm'>
                Classes
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showSaveModal} onHide={this.hideSaveModal} backdrop={isFormLoading ? 'static' : true}>
            <Modal.Header  closeButton={!isFormLoading}>
              <Modal.Title>Summary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                formError ? (
                  <Alert variant='danger'>
                    {formError}
                  </Alert>
                ) : (
                  <Alert variant='warning'>
                    Proceeding will <b>permanently delete</b> all the <b>enrolled courses</b>, <b>faculty loads</b>, and <b>badges</b> related to the class.
                  </Alert>
                )
              }
              <div className='transition-summary-list'>
                {
                  students.transitions.map(t => {
                    let typeOfTransition = typesOfTransition.find(tot => tot.key === t.key);

                    if (typeOfTransition) {
                      return (
                        <div key={t.key}>
                          <div className='d-flex'>
                            <div className='flex-fill font-weight-bold'>
                              {typeOfTransition.title} <i className='text-muted font-weight-normal'>&mdash;{t.list.length} student{t.list.length === 1 ? '' : 's'}</i>
                            </div>
                            <Button variant='link' onClick={e => this.handleEditSummary(t.key)} size='sm'>
                              <FontAwesomeIcon icon='pencil-alt' />
                            </Button>
                          </div>
                          <ul className='small m-0'>
                            {
                              t.list.map(st => (
                                <li key={st.id}>
                                  <div>{st.profile.name}</div>
                                  {
                                    t.key === 'irregular' ? 
                                      this.getIrregularLabel(st.transition.program, st.transition.yearLevel)
                                    : t.key === 'promote' ? 
                                      this.getPromoteLabel(st.transition.schoolClass)
                                    : null
                                  }
                                </li>
                              ))
                            }
                          </ul>
                        </div>
                      );
                    }

                    return null;
                  })
                }
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' className='mr-2' onClick={this.hideSaveModal} disabled={isFormLoading}>
                Cancel
              </Button>
              <Button variant='green' onClick={this.handleSave} disabled={isFormLoading}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      </Card>
    );
  }
}