import React, { Component } from 'react';
import { Alert, Button, Col, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import { TrueFalse, MultipleChoice, Ordering, Essay, Matching, Identification, Enumeration } from '../../../../common/Questions/View/parts';
import './style.scss';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../../common/RichTextEditor/RichTextEditor';

const questionRelationTypeMap = {
  trueFalse: 'question_boolean',
  multipleChoice: 'question_multiple_choice',
  ordering: 'question_ordering',
  essay: 'question_essay',
  matching: 'question_matching',
  identification: 'question_identification',
  enumeration: 'question_enumeration'
};

export default class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: {
        data: []
      },
      current: 'intro',
      timer: false,
      isLoading: true,
      errorMessage: '',
      alreadyReviewed: false
    };

    this.timerInterval = null;
  }
  componentDidMount() {
    const { materialData, match: { params: { classID }} } = this.props;
    axiosRequest('get', `school-admin/class/${classID}/material/${materialData.type}/${materialData.id}/questions`, null, ({ data: { data }}) => {
      let questions = data.data.map(q => {
        if (q.type === 'multipleChoice') {
          q[questionRelationTypeMap[q.type]] = {
            ...q[questionRelationTypeMap[q.type]],
            radio: q[questionRelationTypeMap[q.type]].choices.filter(c => c.is_correct).length === 1
          };
        } else if (q.type === 'matching') {
          q[questionRelationTypeMap[q.type]] = {
            ...q[questionRelationTypeMap[q.type]],
            answers: q[questionRelationTypeMap[q.type]].items.map(i => i.answer)
          };
        }
        
        q.question = q[questionRelationTypeMap[q.type]];

        return q;
      });
      this.setState({
        ...this.state,
        questions: {
          ...data,
          data: questions
        },
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleStart = () => {
    const { materialData } = this.props;

    this.setState({
      ...this.state,
      current: 0,
      timer: materialData.time_limit
    }, this.startTimer);
  }
  startTimer = () => {
    const { materialData } = this.props;

    if (materialData.time_limit) {
      this.timerInterval = setInterval(() => {
        const { timer } = this.state;
        let currentTime = timer-1;

        if (currentTime < 0) {
          this.setState({
            ...this.state,
            current: 'intro',
            timer: false,
          }, () => {
            clearInterval(this.timerInterval);
          });
        } else {
          this.setState({
            ...this.state,
            timer: timer-1
          });
        }
      }, 60000);
    }
  }
  handlePrev = () => {
    this.setState({
      ...this.state,
      current: this.state.current-1
    });
  }
  handleNext = () => {
    this.setState({
      ...this.state,
      current: this.state.current+1
    }, () => {
      const { current, questions } = this.state;
      if (current === questions.data.length && questions.next_page_url) {
        this.setState({
          ...this.state,
          isLoading: true,
          errorMessage: ''
        }, () => {
          const path = questions.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
          axiosRequest('get', path, null, ({ data: { data }}) => {
            let questions = data.data.map(q => {
              if (q.type === 'multipleChoice') {
                q[questionRelationTypeMap[q.type]] = {
                  ...q[questionRelationTypeMap[q.type]],
                  radio: q[questionRelationTypeMap[q.type]].choices.filter(c => c.is_correct).length === 1
                };
              } else if (q.type === 'matching') {
                q[questionRelationTypeMap[q.type]] = {
                  ...q[questionRelationTypeMap[q.type]],
                  answers: q[questionRelationTypeMap[q.type]].items.map(i => i.answer)
                };
              }
              
              q.question = q[questionRelationTypeMap[q.type]];
      
              return q;
            });
            this.setState({
              ...this.state,
              questions: {
                ...data,
                data: [
                  ...this.state.questions.data,
                  ...questions
                ]
              },
              isLoading: false
            });
          }, (error) => {
            this.setState({
              ...this.state,
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            });
          }, this.props.history);
        });
      }
    });
  }
  handleReview = () => {
    this.setState({
      ...this.state,
      current: 'review',
      alreadyReviewed: true
    });
  }
  handleFinish = () => {
    this.setState({
      ...this.state,
      current: 'intro'
    });
  }
  handleJump = index => {
    this.setState({
      ...this.state,
      current: index
    });
  }
  handleAnswer = (data, immediate = false, callback = () => {}) => {
    const { current, questions } = this.state;
    
    let answer = null;
    switch(data.type) {
      case 'trueFalse':
        answer = {
          answer_boolean: {
            answer: data.answer === 't'
          }
        };
        break;
      case 'multipleChoice':
        let multipleChoice = Array.isArray(data.answer) ? data.answer.map(answer => {
          return {
            question_multiple_choice_choice_id: answer
          };
        }) : [{
          question_multiple_choice_choice_id: data.answer
        }];
        answer = {
          answer_multiple_choice: multipleChoice
        };
        break;
      case 'ordering':
        let ordering = data.answer.map(answer => {
          return {
            question_ordering_item_id: answer
          };
        });
        answer = {
          answer_ordering: ordering
        };
        break;
      case 'essay':
        answer = {
          answer_essay: {
            answer: data.answer
          }
        };
        break;
      case 'matching':
        let matching = data.answer.map(answer => {
          return {
            question_matching_item_id: answer.itemID,
            answer: answer.answer
          };
        });
        answer = {
          answer_matching: matching
        };
        break;
      case 'identification':
        let identification = data.answer.map(answer => {
          return {
            question_identification_item_id: answer.itemID,
            answer: answer.answer
          };
        });
        answer = {
          answer_identification: identification
        };
        break;
      case 'enumeration':
        let enumeration = data.answer.map(answer => {
          return {
            answer
          };
        });
        answer = {
          answer_enumeration: enumeration
        };
        break;
      default:
        answer = null;
        break;
    }

    const newQuestionsData = [...questions.data].map((question, index) => {
      if (index === current) {
        if (question.type === 'essay') {
          answer.files = question.answer && question.answer.files ? question.answer.files : [];
        }
        
        question.answer = answer;
      }

      return question;
    });

    this.setState({
      ...this.state,
      questions: {
        ...this.state.questions,
        data: newQuestionsData
      }
    }, callback);
  }
  addEssayAttachments = (attachments) => {
    const { questions, current } = this.state;
    
    const newQuestionsData = [...questions.data].map((question, index) => {
      if (index === current && question.type === 'essay') {
        question.answer = {
          answer_essay: question.answer && question.answer.answer_essay ? question.answer.answer_essay : { answer: '' },
          files: question.answer && question.answer.files ? [...question.answer.files, ...attachments] : []
        };
      }

      return question;
    });

    this.setState({
      ...this.state,
      questions: {
        ...this.state.questions,
        data: newQuestionsData
      }
    });
  }
  removeEssayAttachment = (file, fileID) => {
    const { questions, current } = this.state;
    
    const newQuestionsData = [...questions.data].map((question, i) => {
      if (i === current && question.type === 'essay' && question.answer) {
        question.answer.files = [...question.answer.files].filter(f => {
          return f.id !== fileID;
        });
      }

      return question;
    });

    this.setState({
      ...this.state,
      questions: {
        ...this.state.questions,
        data: newQuestionsData
      }
    });
  }
  renderTimeLimit = () => {
    const { materialData } = this.props;
    if (materialData.time_limit) {
      let duration = moment.duration(materialData.time_limit, 'minutes');
      let hours = duration.hours();
      let minutes = duration.minutes();
      let display = hours && minutes ? `${hours} hour${hours > 1 ? 's' : ''} and ${minutes} minute${minutes > 1 ? 's' : ''}` :
                    hours ? `${hours} hour${hours > 1 ? 's' : ''}` : 
                    minutes ? `${minutes} minute${minutes > 1 ? 's' : ''}` : duration.humanize();
      return (
        <div className='mt-1'>
          <FontAwesomeIcon icon='stopwatch' /> You have at least <b>{display} ({materialData.time_limit}m)</b> to finish the {materialData.type}.
        </div>
      );
    }

    return (
      <div className='mt-1 text-muted'>
        No time limit was set.
      </div>
    );
  }
  renderIntro = () => {
    const { materialData } = this.props;
    
    return (
      <>
        <div className='h5'>
          {materialData.title} <small>({materialData.faculty_load_id ? materialData.grade_category.name : materialData.audience.grade_category.name})</small>
        </div>
        <RichTextEditor.Viewer body={materialData.description} />
        {/* <div style={{ whiteSpace: 'pre-line' }}>
          {materialData.description}
        </div> */}
        <div className='mt-3'>
          <span className='pr-1'>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {
                    materialData.type === 'quiz' ? (
                      <div>
                        Submissions <b>AFTER</b> the given duration will be <b>SAVED</b> but will be marked as <b>LATE</b>.
                      </div>
                    ) : (
                      <div>
                        Submissions <b>AFTER</b> the given duration will <b>NOT</b> be saved.
                      </div>
                    )
                  }
                </Tooltip>
              }
              trigger={['hover', 'focus']}>
              <FontAwesomeIcon icon='info-circle' className='info-icon text-primary' />
            </OverlayTrigger>
          </span>
          Submissions are enabled from
          <b> {moment(materialData.faculty_load_id ? materialData.from : materialData.audience.from).format('ddd, MMM D, YYYY hh:mm A')} </b>
          until
          <b> {moment(materialData.faculty_load_id ? materialData.until : materialData.audience.until).format('ddd, MMM D, YYYY hh:mm A')} </b>
        </div>
        {
          materialData.type === 'quiz' ? (
            <>
              { this.renderTimeLimit() }
              <div className='dropdown-divider'></div>
              <div>
                You have made 0 of 3 attempts. You have 3 attempts remaining.
                <Col md={6} className='mt-2'>
                  <ProgressBar now={0} label={'0 of 3'} />
                </Col>
              </div>
              <div className='mt-2'>
                <Button variant='green' onClick={this.handleStart}>
                  Start a new attempt
                </Button>
              </div>
            </>
          ) : (
            <div className='mt-2'>
              <Button variant='green' onClick={this.handleStart}>
                Start answering
              </Button>
            </div>
          )
        }
        
      </>
    );
  }
  renderTimer = () => {
    const { materialData } = this.props;

    if (materialData.time_limit) {
      const { timer } = this.state;
      let duration = moment.duration(timer, 'minutes');
      let hours = duration.hours()+'';
      let minutes = duration.minutes()+'';
      return (
        <div className={`text-right font-weight-bold mb-3 mb-md-0 ${timer <= 10 ? 'text-danger' : ''}`}>
          <FontAwesomeIcon icon='clock' className={`timer ${timer > 10 ? '' : (timer <= 5 ? 'shake fast' : 'shake slow')}`} /> Time remaining: {`${hours.length > 1 ? hours : (`0${hours}`)}:${minutes.length > 1 ? minutes : (`0${minutes}`)}`}
        </div>
      );
    }
  }
  renderQuestion = () => {
    const { materialData, match: { params: { classID } } } = this.props;
    const { questions, current } = this.state;
    
    const question = questions.data[current];

    if (!question) {
      return null;
    }

    let questionProps = {
      ...question,
      url: `school-admin/class/${classID}/material/${materialData.type}/${materialData.id}/questions`,
      onChange: this.handleAnswer,
    };

    switch (question.type) {
      case 'trueFalse':
        return <TrueFalse {...questionProps} />;
      case 'multipleChoice':
        return <MultipleChoice {...questionProps} />;
      case 'ordering':
        return <Ordering {...questionProps} />;
      case 'essay':
        return <Essay {...questionProps} onAddAttachments={this.addEssayAttachments} onRemoveAttachment={this.removeEssayAttachment} />;
      case 'matching':
        return <Matching {...questionProps} />;
      case 'identification':
        return <Identification {...questionProps} />;
      case 'enumeration':
        return <Enumeration {...questionProps} />;
      default:
        return null;
    }
  }
  renderAnswer = (question, answer, type) => {
    switch(type) {
      case 'trueFalse':
        return answer.answer_boolean ? (answer.answer_boolean.answer ? 'True' : 'False') : (
          <>
            <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
          </>
        );
      case 'multipleChoice':
        if (!answer.answer_multiple_choice || answer.answer_multiple_choice.length === 0) {
          return (
            <>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </>
          );
        }

        if (question.radio) {
          return (question.choices.find(choice => choice.id === +answer.answer_multiple_choice[0].question_multiple_choice_choice_id)).description;
        } else {
          return answer.answer_multiple_choice.map((answer, index) => (
            <div key={index}>{(question.choices.find(choice => choice.id === answer.question_multiple_choice_choice_id)).description}</div>
          ))
        }
      case 'ordering':
        if (!answer.answer_ordering || answer.answer_ordering.length === 0) {
          return (
            <>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </>
          );
        }

        return answer.answer_ordering.map((answer, index) => (
          <div key={index}>{(question.items.find(item => item.id === answer.question_ordering_item_id)).description}</div>
        ));
      case 'essay':
        if ((!answer.answer_essay || !answer.answer_essay.answer) && (!answer.files || answer.files.length === 0)) {
          return (
            <>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </>
          );
        }
        return (
          <>
            {
              answer.answer_essay && (
                <div>
                  {answer.answer_essay.answer}
                </div>
              )
            }
            {
              (answer.files && answer.files.length > 0) && (
                <div>
                  <FontAwesomeIcon icon='paperclip' /> {answer.files.length} attachment{answer.files.length !== 1 ? 's' : ''}
                </div>
              )
            }
          </>
        );
      case 'matching':
        if (!answer.answer_matching || answer.answer_matching.length === 0) {
          return (
            <>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </>
          );
        }

        return answer.answer_matching.map(({question_matching_item_id, answer}, index) => (
          <div key={index}>
            <div>{(question.items.find(item => item.id === question_matching_item_id)).description}</div>
            <div className='pl-1 d-flex'>
              <div className='mr-1'>
                <FontAwesomeIcon icon='long-arrow-alt-right' />
              </div>
              <div className='flex-fill'>
                {answer}
              </div>
            </div>
          </div>
        ));
      case 'identification':
        if (!answer.answer_identification || answer.answer_identification.length === 0) {
          return (
            <>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </>
          );
        }

        return answer.answer_identification.map(({question_identification_item_id, answer}, index) => (
          <div key={index}>
            <div>{answer}</div>
            <div className='pl-1 d-flex'>
              <div className='mr-1'>&mdash;</div>
              <div className='flex-fill'>
                {(question.items.find(item => item.id === question_identification_item_id)).description}
              </div>  
            </div>
          </div>
        ));
      case 'enumeration':
        if (!answer.answer_enumeration || answer.answer_enumeration.length === 0 || !answer.answer_enumeration.find(a => a.answer !== '')) {
          return (
            <>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </>
          );
        }

        return answer.answer_enumeration.map(({answer}, index) => (
          <div key={index}>
            {answer}
          </div>
        ));
      default:
        return null;
    }
  }
  renderReview = () => {
    const { questions } = this.state;

    return (
      <div className='p-1 p-md-3'>
        {this.renderTimer()}
        <div className='review-answers'>
        {
          questions.data.map(({question, answer, type}, index) => (
            <div key={index} className='d-flex py-3'>
              <div className='h5 pr-1 pr-md-2'>{index+1}</div>
              <div className='flex-fill'>
                <RichTextEditor.Viewer body={question.description} />
                {/* <div>{question.description}</div> */}
                <div className='p-1 p-md-2 alert-light'>
                {
                  answer ? this.renderAnswer(question, answer, type) : (
                    <>
                      <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
                    </>
                  )
                }
                </div>
              </div>
              <div className='pl-1 pl-md-2'>
                <Button variant='info' size='sm' onClick={() => this.handleJump(index)}>
                  <FontAwesomeIcon icon='redo' />
                  <span className='d-none d-md-inline-block ml-1'>Return to page</span>
                </Button>
              </div>
            </div>
          ))
        }
        </div>
        <div className='text-right'>
          <Button variant='green' onClick={this.handleFinish}>
            Finish
          </Button>
        </div>
      </div>
    );
  }
  render() {
    const { isLoading, errorMessage, current, questions, alreadyReviewed } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (questions.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <div>
        {
          current === 'intro' ? this.renderIntro() : current === 'review' ? this.renderReview() : (
            <>
              <div className='p-md-5'>
                {this.renderTimer()}
                <div className='d-flex'>
                  <div className='h4'>
                    {current+1}
                  </div>
                  <div className='flex-fill'>
                    {this.renderQuestion()}
                  </div>
                </div>
                <div className='d-none d-md-block text-right'>
                  {
                    current !== 0 && (
                      <Button variant='green' className='mr-2' onClick={this.handlePrev}>
                        Previous
                      </Button>
                    )
                  }
                  {
                    (current !== questions.data.length -1 || questions.next_page_url || (alreadyReviewed && current !== questions.data.length - 1)) && (
                      <Button variant='green' className='mr-2' onClick={this.handleNext}>
                        Next
                      </Button>
                    )
                  }
                  {
                    ((current === questions.data.length - 1 && !questions.next_page_url) || alreadyReviewed) && (
                      <Button variant='green' onClick={this.handleReview}>
                        Review answers
                      </Button>
                    )
                  }
                </div>
                <div className='d-md-none'>
                  <div className='mb-1 d-flex justify-content-end'>
                    {
                      current !== 0 && (
                        <div className='w-50 px-1'>
                          <Button variant='green' className='mr-2' onClick={this.handlePrev} title='Previous' block>
                            <FontAwesomeIcon icon='chevron-left' />
                          </Button>
                        </div>
                      )
                    }
                    {
                      (current !== questions.data.length -1 || questions.next_page_url || (alreadyReviewed && current !== questions.data.length - 1)) && (
                        <div className='w-50 px-1'>
                          <Button variant='green' className='mr-2 w-100' onClick={this.handleNext} title='Next' block>
                            <FontAwesomeIcon icon='chevron-right' />
                          </Button>
                        </div>
                      )
                    }
                  </div>
                  <div className='px-1'>
                    {
                      ((current === questions.data.length - 1 && !questions.next_page_url) || alreadyReviewed) && (
                        <Button variant='green' onClick={this.handleReview} block>
                          Review answers
                        </Button>
                      )
                    }
                  </div>
                </div>
              </div>
            </>
          )
        }
      </div>
    );
  }
}