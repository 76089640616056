import React from 'react';
import './style.scss';

export default function LoadingIcon ({ lg, sm, className, ...props }) {
  return (
    <svg {...props} className={`loading-icon${lg ? ' loading-icon-lg' : ''} ${className} ${sm ? 'loading-icon-sm' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1034 1018">
      <path class="loading-icon-lines" d="M517,137.5c210.7,0,381.505,168.567,381.505,376.5S727.7,890.5,517,890.5,135.5,721.937,135.5,514,306.3,137.5,517,137.5Z"/>
      <path class='loading-icon-lines' d="M517,9.487c279.74,0,506.51,223.64,506.51,499.513S796.74,1008.51,517,1008.51,10.485,784.873,10.485,509,237.26,9.487,517,9.487Z"/>
    </svg>
  );
}

LoadingIcon.defaultProps = {
  sm: false,
  lg: false,
  className: ''
};