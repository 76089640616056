import React, { Component } from 'react';
import { Button, Carousel, Col, Image, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Confetti from 'react-confetti';
import moment from 'moment';
import axiosRequest from '../../../util/helpers/axiosRequest';
import profileDefault from '../../../resources/image/profile-default.png';
import birthdayCake from '../../../resources/image/birthday-cake.png';
import './style.scss';
import LoadingIcon from '../LoadingIcon/LoadingIcon';

export default class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      events: [],
      birthdays: [],
      birthdayContainer: {
        width: 0,
        height: 0
      },
      showConfetti: true,
      showModal: false
    };

    this.birthdayContainer = null;
  }
  componentDidMount() {
    axiosRequest('get', 'events', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false,
      }, () => {
        this.updateBirthdayContainer();
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
    window.addEventListener('resize', () => this.updateBirthdayContainer());
  }
  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateBirthdayContainer());
  }
  updateBirthdayContainer = () => {
    if (this.birthdayContainer) {
      this.setState({
        ...this.state,
        birthdayContainer: {
          width: this.birthdayContainer.getBoundingClientRect().width,
          height: this.birthdayContainer.getBoundingClientRect().height,
        }
      });
    }
  }
  retryFetch = () => {
    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: ''
    }, () => {
      axiosRequest('get', 'events', null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          ...data,
          isLoading: false,
        }, () => {
          this.updateBirthdayContainer();
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showModal = () => {
    this.setState({
      ...this.state,
      showModal: true
    });
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      showModal: false
    });
  }
  renderStart = (start, end) => {
    let momentStart = moment(start);

    if (start === end) {
      if (moment().isSame(momentStart, 'day')) {
        return (
          <span>Today</span>
        );
      } else if (moment().add(1, 'day').isSame(momentStart, 'day')) {
        return (
          <span>Tomorrow</span>
        );
      }

      return (
        <span>{momentStart.format('ddd MMM D, YYYY')}</span>
      );
    }

    if (moment().subtract(1, 'day').isSame(momentStart, 'day')) {
      return (
        <span>Started yesterday</span>
      );
    } else if (moment().add(1, 'day').isSame(momentStart, 'day')) {
      return (
        <span>Starts tomorrow</span>
      );
    } else if (moment().isAfter(momentStart, 'day')) {
      return (
        <span>Started from {momentStart.format('ddd MMM D, YYYY')}</span>
      );
    } else if (moment().isSame(momentStart, 'day')) {
      return (
        <span>Starts today</span>
      );
    }

    return (
      <span>Starts on {momentStart.format('ddd MMM D, YYYY')}</span>
    );
  }
  renderEnd = (start, end) => {
    let momentEnd = moment(end);

    if (start === end) {
      return null;
    }

    if (moment().add(1, 'day').isSame(momentEnd, 'day')) {
      return (
        <span>Ends tomorrrow</span>
      );
    } else if (moment().isBefore(momentEnd, 'day')) {
      return (
        <span>Ends on {momentEnd.format('ddd MMM D, YYYY')}</span>
      );
    } else if (moment().isSame(momentEnd, 'day')) {
      return (
        <span>Ends today</span>
      );
    }

    return null;
  }
  renderEvents = () => {
    const { events } = this.state;

    if (events.length === 0) {
      return null;
    }

    let today = events.filter(e => moment().isBetween(moment(e.start), moment(e.end), 'day', '[]'));
    let other = events.filter(e => [...today.map(s => s.id)].indexOf(e.id) === -1);
    other.sort((a, b) => {
      let momentA = moment(a.start);
      let momentB = moment(b.start);

      if (momentA.isBefore(momentB, 'day')) {
        return -1;
      } else if (momentA.isAfter(momentB, 'day')) {
        return 1;
      }

      return 0;
    });

    return (
      <Carousel.Item>
        <div className='upcoming-container' style={{ fontSize: '.875rem' }}>
          {
            (today && today.length > 0) && (
              <div className='mb-3'>
                <div className='font-weight-bold text-muted px-2'>Today <span className='font-weight-normal font-italic'>({moment().format('ddd MMM D, YYYY')})</span></div>
                <div className='border-bottom'></div>
                <div className='upcoming-event-list'>
                  {
                    today.map(e => (
                      <div key={e.id} className='py-1 px-3' title={e.description}>
                        <div className='font-weight-bold'>
                          {e.title}
                        </div>
                        <div className='small font-text-muted'>
                          {this.renderStart(e.start, e.end)}
                        </div>
                        <div className='small font-text-muted'>
                          {this.renderEnd(e.start, e.end)}
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            )
          }
          {
            (other && other.length > 0) && (
              <div className='mb-3'>
                <div className='font-weight-bold text-muted px-2'>Upcoming this week</div>
                <div className='border-bottom'></div>
                <div className='upcoming-event-list'>
                  {
                    other.map(e => (
                      <div key={e.id} className='py-1 px-3' title={e.description}>
                        <div className='font-weight-bold'>
                          {e.title}
                        </div>
                        <div className='small font-text-muted'>
                          {this.renderStart(e.start, e.end)}
                        </div>
                        <div className='small font-text-muted'>
                          {this.renderEnd(e.start, e.end)}
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            )
          }
        </div>
      </Carousel.Item>
    );
  }
  renderBirthdays = () => {
    const { birthdays } = this.state;

    return birthdays.map(b => (
      <Carousel.Item key={`birthday-${b.id}`}>
        {this.renderBirthdayCard(b)}
      </Carousel.Item>
    ));
  }
  renderBirthdayCard = celebrant => {
    const { birthdayContainer } = this.state;

    return (
      <div className='p-2 rounded birthday-container position-relative'>
        <div className='h3 sticky-top bg-white' style={{ zIndex: 1 }}>
          <div className='d-flex'>
            <div className='flex-fill'></div>
            <div className='balloon mr-2'></div>
            <div className='flex-fill'>
              <div className='text-left wish-title'>
                <span>H</span>
                <span>a</span>
                <span>p</span>
                <span>p</span>
                <span>y</span>
                <span className='ml-1'>B</span>
                <span>i</span>
                <span>r</span>
                <span>t</span>
                <span>h</span>
                <span>d</span>
                <span>a</span>
                <span>y</span>
              </div>
              <div className='text-right wish-title'>
                <span>a</span>
                <span>n</span>
                <span>d</span>
                <span className='ml-1'>G</span>
                <span>O</span>
                <span>D</span>
                <span className='ml-1'>b</span>
                <span>l</span>
                <span>e</span>
                <span>s</span>
                <span>s</span>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
            <div className='flex-fill'></div>
          </div>
        </div>
        <div>
          <Row className='d-flex' noGutters>
            <Col xs={5}>
              <div>
                <Image
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                  }}
                  src={celebrant.image ? `${process.env['REACT_APP_API_BASE_URL']}/profile/${celebrant.id}/${celebrant.image}` : profileDefault}
                  onLoad={this.updateBirthdayContainer}
                  rounded
                  thumbnail />
              </div>
            </Col>
            <Col>
              <div className='pl-2 h5 d-flex flex-column'>
                <div>
                  {celebrant.name}
                  {
                    celebrant.position && (
                      <div className='text-muted font-italic font-weight-normal' style={{ fontSize: '1rem'}}>
                        {celebrant.position}
                      </div>
                    )
                  }
                </div>
                <div className='flex-fill'>
                  <div className='text-center pt-3 h-100'>
                    <Image
                      style={{
                        width: '50%',
                        height: '50%',
                        objectFit: 'contain'
                      }}
                      className='birthday-cake'
                      src={birthdayCake} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className='h6 text-right text-primary'>
            <span>&mdash;</span>
            <span>F</span>
            <span>r</span>
            <span>o</span>
            <span>m</span>
            <span className='ml-1'>y</span>
            <span>o</span>
            <span>u</span>
            <span>r</span>
            <span className='ml-1'>A</span>
            <span>C</span>
            <span>T</span>
            <span>S</span>
            <span className='ml-1'>K</span>
            <span>a</span>
            <span>p</span>
            <span>a</span>
            <span>m</span>
            <span>i</span>
            <span>l</span>
            <span>y</span>
            <span>a</span>
          </div>
        </div>
        <Confetti
          width={birthdayContainer.width}
          height={birthdayContainer.height}
          numberOfPieces={50} />
      </div>
    );
  }
  renderButton = () => {
    const { events, birthdays } = this.state;

    if (events.length === 0 && birthdays.length === 0) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              No events
            </Tooltip>
          }
          trigger={['hover', 'focus']}>
          <Button variant='light' className='events-float shadow-lg'>
            <FontAwesomeIcon icon='calendar-week' />
          </Button>
        </OverlayTrigger>
      );
    }

    return (
      <Button variant='green' className='events-float shadow-lg' title='Events' onClick={this.showModal}>
        <span className='fa-layers fa-fw'>
          <FontAwesomeIcon icon='calendar-week' />
          <span className='fa-layers-counter fa-fw fa-2x bg-danger' style={{ transformOrigin: '1.875em -0.2875em'}}>
            {events.length + birthdays.length}
          </span>
        </span>
      </Button>
    );
  }
  render() {
    const { isLoading, errorMessage, showModal } = this.state;
    const { currentUser } = this.props;

    if (isLoading) {
      return (
        <Button variant='light' className='events-float shadow-lg' disabled>
          <LoadingIcon /> <FontAwesomeIcon icon='calendar-week' />
        </Button>
      );
    }

    if (errorMessage) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              {errorMessage}
            </Tooltip>
          }
          trigger={['hover', 'focus']}>
          <Button variant='danger' className='events-float shadow-lg error-button' onClick={this.retryFetch}>
            <span className='notif-icon'><FontAwesomeIcon icon='exclamation' /> <FontAwesomeIcon icon='calendar-week' /></span> <span className='refresh-icon'><FontAwesomeIcon icon='redo' /> Retry</span>
          </Button>
        </OverlayTrigger>
      );
    }

    return (
      <>
        {this.renderButton()}
        <Modal show={showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Events</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='events-content floating-events' ref={ref => {
              if (ref) {
                this.birthdayContainer = ref;
              }
            }}>
              <Carousel
                prevIcon={<FontAwesomeIcon icon='chevron-left' className='text-primary' />}
                nextIcon={<FontAwesomeIcon icon='chevron-right' className='text-primary' />}
                interval={4000}
                onSlid={this.updateBirthdayContainer}>
                {this.renderEvents()}
                {this.renderBirthdays()}
              </Carousel>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {
              (currentUser && (currentUser.account_type === 1 || currentUser.account_type === 2 || currentUser.account_type === 3)) && (
                <a href={`/${currentUser.account_type === 1 ? 'school-admin' : currentUser.account_type === 2 ? 'faculty' : 'staff'}/events`} className='btn btn-green mr-auto'>
                  <FontAwesomeIcon icon='cog' />
                  <span className='ml-1 d-none d-md-inline-block'>Manage</span>
                </a>
              )
            }
            <Button variant='light' onClick={this.hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}