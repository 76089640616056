import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Validator from 'validatorjs';
import { loadRule } from '../../../util/';
import axiosRequest from '../../../util/helpers/axiosRequest';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './style.scss';
import PromptDeleteModal from '../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../common/RichTextEditor/RichTextEditor';

const localizer = momentLocalizer(moment);

export default class DayQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      isNextPageLoading: false,
      nextPageError: '',
      events: [],
      loadedMonths: [],
      saveModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        isFormLoading: false,
        formError: '',
        event: null,
        rawEvent: null,
        formInputs: {
          questions: [],
          start: '',
          end: ''
        }
      },
      deleteModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        event: null
      }
    };
  }
  componentDidMount() {
    axiosRequest('get', 'staff/day-question', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        events: data,
        isLoading: false,
        loadedMonths: [moment().format('MM-YYYY')]
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);

    loadRule('date');
  }
  fetchEvents = e => {
    const { loadedMonths, events } = this.state;
    let temp = moment(e.start).add(7, 'days').format('MM-YYYY');
    if (loadedMonths.indexOf(temp) === -1) {
      this.setState({
        ...this.state,
        isNextPageLoading: true,
        nextPageError: ''
      }, () => {
        let start = moment(e.start).format('YYYY-MM-DD');
        let end = moment(e.end).format('YYYY-MM-DD');
        axiosRequest('get', `staff/day-question/range/${start}/${end}`, null, ({ data: { data }}) => {
          let newEvents = [...events, ...data].filter((e, i, t) => (
            i === t.findIndex(e2 => (
              e2.id === e.id
            ))
          ));
          this.setState({
            ...this.state,
            isNextPageLoading: false,
            events: [...newEvents],
            loadedMonths: [
              ...this.state.loadedMonths,
              moment(e.start).add(7, 'days').format('MM-YYYY')
            ]
          });
        }, error => {
          this.setState({
            ...this.state,
            isNextPageLoading: false,
            nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    }
  }
  showSaveModal = () => {
    this.setState({
      ...this.state,
      saveModal: {
        show: true,
        isLoading: false,
        errorMessage: '',
        isFormLoading: false,
        formError: '',
        event: null,
        rawEvent: null,
        formInputs: {
          questions: [],
          start: '',
          end: ''
        }
      }
    });
  }
  hideSaveModal = () => {
    this.setState({
      ...this.state,
      saveModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        isFormLoading: false,
        formError: '',
        event: null,
        rawEvent: null,
        formInputs: {
          questions: [],
          start: '',
          end: ''
        }
      }
    });
  }
  showDeleteModal = event => {
    this.setState({
      ...this.state,
      saveModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        isFormLoading: false,
        formError: '',
        event: null,
        rawEvent: null,
        formInputs: {
          questions: [],
          start: '',
          end: ''
        }
      },
      deleteModal: {
        show: true,
        isLoading: false,
        errorMessage: '',
        event
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        event: null
      }
    });
  }
  handleSelectSlot = e => {
    const { events } = this.state;

    let momentStart = moment(e.start);
    let momentEnd = moment(e.end);

    let newQuestions = [];
    for (let i = 0; i < e.slots.length; i++) {
      let temp = events.filter(evt => moment(e.slots[i]).isBetween(moment(evt.start), moment(evt.end), 'day', []));
      if (temp.length) {
        this.setState({
          ...this.state,
          saveModal: {
            show: true,
            isLoading: false,
            errorMessage: '',
            isFormLoading: false,
            formError: 'Questions are limited to only one per day',
            event: null,
            rawEvent: null,
            formInputs: {
              questions: [],
              start: '',
              end: ''
            }
          }
        });
        return false;
      }

      newQuestions = [...newQuestions, {
        question: '',
        type: '',
        choices: []
      }];
    }

    // let numberOfDays = momentEnd.diff(momentStart, 'days') + 1;

    // let newQuestions = e.slots.map(s => ({
    //   question: '',
    //   type: '',
    //   choices: []
    // }));
    // if (!isNaN(numberOfDays) && numberOfDays > 0) {
    //   newQuestions = [...Array(numberOfDays)].map(n => ({
    //     question: '',
    //     type: '',
    //     choices: []
    //   }));
    // }

    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        show: true,
        formInputs: {
          questions: newQuestions,
          start: momentStart.format('YYYY-MM-DD'),
          end: momentEnd.format('YYYY-MM-DD')
        }
      }
    });
  }
  handleSelectEvent = event => {
    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        show: true,
        event,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      axiosRequest('get', `staff/day-question/${event.id}`, null, ({ data: { data }}) => {
        let questions = data.questions.map(q => {
          let custom = {};
          if (q.choices.length === 2 && q.choices[0].choice === 'True' && q.choices[1].choice === 'False') {
            let find = q.choices.find(c => c.is_correct);
            custom = {
              type: 'trueFalse',
              correctAnswer: !find ? '' : find.choice === 'True' ? 't' : 'f'
            };
          } else {
            let choices = q.choices.map(c => c.choice);
            if (choices.length < 4) {
              choices = [
                ...choices,
                ...[...Array(4-choices.length)].fill('')
              ];
            }
            custom = {
              type: 'multipleChoice',
              choices,
              correctAnswer: q.choices.findIndex(c => c.is_correct)
            };
          }

          return {
            question: q.question,
            ...custom
          };
        });

        this.setState({
          ...this.state,
          saveModal: {
            ...this.state.saveModal,
            isLoading: false,
            rawEvent: data,
            formInputs: {
              start: event.start,
              end: event.end,
              questions
            }
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          saveModal: {
            ...this.state.saveModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleDateChange = e => {
    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        formInputs: {
          ...this.state.saveModal.formInputs,
          [e.target.name]: e.target.value
        }
      }
    }, () => {
      const { start, end } = this.state.saveModal.formInputs;
      let momentStart = moment(start);
      let momentEnd = moment(end);

      let numberOfDays = momentEnd.diff(momentStart, 'days') + 1;

      if (!isNaN(numberOfDays) && numberOfDays > 0) {
        let slots = [...Array(numberOfDays)].map((n, i) => momentStart.clone().add(i, 'days').toDate());
        this.handleSelectSlot({
          start,
          end,
          slots
        });
      } else if (start && end) {
        this.setState({
          ...this.state,
          saveModal: {
            ...this.state.saveModal,
            formError: 'Start date must be less than or equal to end date.'
          }
        });
      }
    });
  }
  handleQuestionChange = (value, changedIndex) => {
    const { questions } = this.state.saveModal.formInputs;

    let newQuestions = [...questions].map((q, i) => {
      if (i === changedIndex) {
        return {
          ...q,
          question: value
        };
      }

      return {...q};
    });

    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        formInputs: {
          ...this.state.saveModal.formInputs,
          questions: newQuestions
        }
      }
    });
  }
  handleTypeChange = (e, changedIndex) => {
    const { questions } = this.state.saveModal.formInputs;

    let newQuestions = [...questions].map((q, i) => {
      if (i === changedIndex) {
        if (e.target.value === 'trueFalse') {
          return {
            ...q,
            type: 'trueFalse',
            correctAnswer: ''
          };
        } else if (e.target.value === 'multipleChoice') {
          return {
            ...q,
            type: 'multipleChoice',
            correctAnswer: '',
            choices: [
              '',
              '',
              '',
              ''
            ]
          };
        }
        return {
          ...q,
          type: e.target.value
        };
      }

      return {...q};
    });

    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        formInputs: {
          ...this.state.saveModal.formInputs,
          questions: newQuestions
        }
      }
    });
  }
  handleTrueFalseChange = (e, changedIndex) => {
    const { questions } = this.state.saveModal.formInputs;

    let newQuestions = [...questions].map((q, i) => {
      if (i === changedIndex) {
        return {
          ...q,
          correctAnswer: e.target.value
        };
      }

      return {...q};
    });

    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        formInputs: {
          ...this.state.saveModal.formInputs,
          questions: newQuestions
        }
      }
    });
  }
  handleChoiceInputChange = (e, changedIndex, choiceIndex) => {
    const { questions } = this.state.saveModal.formInputs;

    let newQuestions = [...questions].map((q, i) => {
      if (i === changedIndex) {
        let newChoices = [...q.choices].map((c, j) => {
          if (j === choiceIndex) {
            return e.target.value;
          }

          return c;
        });

        return {
          ...q,
          choices: newChoices
        };
      }

      return {...q};
    });

    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        formInputs: {
          ...this.state.saveModal.formInputs,
          questions: newQuestions
        }
      }
    });
  }
  handleChoiceRadioChange = (e, changedIndex, choiceIndex) => {
    const { questions } = this.state.saveModal.formInputs;

    let newQuestions = [...questions].map((q, i) => {
      if (i === changedIndex) {
        return {
          ...q,
          correctAnswer: choiceIndex
        };
      }

      return {...q};
    });

    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        formInputs: {
          ...this.state.saveModal.formInputs,
          questions: newQuestions
        }
      }
    });
  }
  handleCreate = inputs => {
    axiosRequest('post', 'staff/day-question', inputs, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        events: [...this.state.events, data],
        saveModal: {
          show: false,
          isLoading: false,
          errorMessage: '',
          isFormLoading: false,
          formError: '',
          event: null,
          rawEvent: null,
          formInputs: {
            questions: [],
            start: '',
            end: ''
          }
        }
      });
    }, error => {
      this.setState({
        ...this.state,
        saveModal: {
          ...this.state.saveModal,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleUpdate = inputs => {
    const { saveModal: { event } } = this.state;

    axiosRequest('patch', `staff/day-question/${event.id}`, inputs, ({ data: { data }}) => {
      let newEvents = [...this.state.events].map(e => {
        if (e.id === event.id) {
          return {...data};
        }

        return {...e};
      });
      this.setState({
        ...this.state,
        events: newEvents,
        saveModal: {
          show: false,
          isLoading: false,
          errorMessage: '',
          isFormLoading: false,
          formError: '',
          event: null,
          rawEvent: null,
          formInputs: {
            questions: [],
            start: '',
            end: ''
          }
        }
      });
    }, error => {
      this.setState({
        ...this.state,
        saveModal: {
          ...this.state.saveModal,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleSave = e => {
    e.preventDefault();

    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        isFormLoading: true,
        formError: ''
      }
    }, () => {
      const { saveModal: { formInputs, event } } = this.state;

      let validator = new Validator(formInputs, {
        start: 'required|date',
        end: 'required|date',
        questions: 'required|array|min:1',
        'questions.*.question': 'required',
        'questions.*.type': 'required|in:trueFalse,multipleChoice',
        'question.*.correctAnswer': 'required'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          saveModal: {
            ...this.state.saveModal,
            isFormLoading: false,
            formError: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      validator = {
        passed: true,
        error: ''
      };
      let booleanAnswers = ['t', 'f'];
      let newQuestions = [];
      for (let i = 0; i < formInputs.questions.length; i++) {
        let tempQuestion = {
          question: formInputs.questions[i].question,
          type: formInputs.questions[i].type
        };
        if (formInputs.questions[i].type === 'trueFalse') {
          if (booleanAnswers.indexOf(formInputs.questions[i].correctAnswer) === -1) {
            validator.passed = false;
            validator.error = `Correct answer is required in Question ${i+1}`;
            break;
          }
          tempQuestion = {
            ...tempQuestion,
            choices: [
              {
                choice: 'True',
                isCorrect: formInputs.questions[i].correctAnswer === 't'
              },
              {
                choice: 'False',
                isCorrect: formInputs.questions[i].correctAnswer === 'f'
              },
            ]
          };
        } else {
          if (formInputs.questions[i].correctAnswer < 0 || formInputs.questions[i].correctAnswer >= formInputs.questions[i].choices.length) {
            validator.passed = false;
            validator.error = `Correct answer is required in Question ${i+1}`;
            break;
          }

          let newChoices = [];
          let hasCorrectAnswer = false;
          let multipleCorrectAnswers = false;
          for (let j = 0; j < formInputs.questions[i].choices.length; j++) {
            if (formInputs.questions[i].choices[j] && formInputs.questions[i].choices[j].trim()) {
              let isCorrect = j === formInputs.questions[i].correctAnswer;
              if (isCorrect && !hasCorrectAnswer) {
                hasCorrectAnswer = true;
              } else if (isCorrect && hasCorrectAnswer) {
                multipleCorrectAnswers = true;
                break;
              }
              newChoices = [
                ...newChoices,
                {
                  choice: formInputs.questions[i].choices[j].trim(),
                  isCorrect
                }
              ];
            }
          }

          if (multipleCorrectAnswers) {
            validator.passed = false;
            validator.error = `Minimum of one correct answer only in Question ${i+1}`;
            break;
          }

          if (newChoices.length < 2) {
            validator.passed = false;
            validator.error = `Minimum of 2 choices in Question ${i+1}`;
            break;
          } else if (!hasCorrectAnswer) {
            validator.passed = false;
            validator.error = `Correct answer is required in Question ${i+1}`;
            break;
          }

          tempQuestion = {
            ...tempQuestion,
            choices: newChoices
          }
        }

        newQuestions = [...newQuestions, tempQuestion];
      }

      if (!validator.passed) {
        this.setState({
          ...this.state,
          saveModal: {
            ...this.state.saveModal,
            isFormLoading: false,
            formError: validator.error
          }
        });
        return;
      }

      if (event) {
        this.handleUpdate({
          ...formInputs,
          questions: newQuestions
        });
      } else {
        this.handleCreate({
          ...formInputs,
          questions: newQuestions
        });
      }
    });
  }
  handleDelete = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { deleteModal: { event } } = this.state;
      axiosRequest('delete', `staff/day-question/${event.id}`, null, ({ data: { message }}) => {
        let newEvents = [...this.state.events].filter(e => e.id !== event.id);
        this.setState({
          ...this.state,
          events: newEvents,
          deleteModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            event: null
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      });
    });
  }
  renderQuestionForms = () => {
    const { saveModal: { formInputs } } = this.state;

    return (
      <>
        <div>Questions</div>
        <div className='dropdown-divider'></div>
        {
          formInputs.questions.length === 0 ? (
            <Alert variant='light'>
              Please select a valid date range
            </Alert>
          ) : formInputs.questions.map((q, i) => (
            <div className='pr-3 pl-1 d-flex' key={i}>
              <div className='pr-2 font-weight-bold'>
                {i+1}
              </div>
              <div className='flex-fill'>
                <Form.Group>
                  <Form.Label>Question</Form.Label>
                  <RichTextEditor.Editor
                    value={q.question}
                    onChange={value => this.handleQuestionChange(value, i)} />
                  {/* <Form.Control as='textarea' rows={2} value={q.question} onChange={e => this.handleQuestionChange(e, i)} /> */}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Type</Form.Label>
                  <div>
                    <Form.Check
                      type='radio'
                      id={`trueFalse-${i}`}
                      label='True/False'
                      name={`type-${i}`}
                      onChange={e => this.handleTypeChange(e, i)}
                      value='trueFalse'
                      inline
                      checked={q.type === 'trueFalse'} />
                    <Form.Check
                      type='radio'
                      id={`multipleChoice-${i}`}
                      label='Multiple Choice'
                      name={`type-${i}`}
                      onChange={e => this.handleTypeChange(e, i)}
                      value='multipleChoice'
                      inline
                      checked={q.type === 'multipleChoice'} />
                  </div>
                </Form.Group>
                {
                  q.type && q.type === 'trueFalse' ? (
                    <Form.Group>
                      <Form.Label>Correct Answer</Form.Label>
                      <Form.Control as='select' value={q.correctAnswer} onChange={e => this.handleTrueFalseChange(e, i)}>
                        <option hidden value=''>Select correct answer...</option>
                        <option value='t'>True</option>
                        <option value='f'>False</option>
                      </Form.Control>
                    </Form.Group>
                  ) : q.type && q.type === 'multipleChoice' ? (
                    <Form.Group>
                      <Form.Label>Choices</Form.Label>
                      <div className='choices-container'>
                        {
                          q.choices.map((c, j) => (
                            <div key={j}>
                              <div className='d-flex align-items-end'>
                                <div className='pr-2 font-weight-bold'>
                                  {String.fromCharCode(j+97)}
                                </div>
                                <div className='flex-fill'>
                                  <Form.Control value={c} size='sm' onChange={e => this.handleChoiceInputChange(e, i, j)} />
                                </div>
                              </div>
                              {
                                (c && c.trim()) && (
                                  <div className='text-right'>
                                    <Form.Check
                                      type='radio'
                                      id={`choice-${i}-${j}`}
                                      label='Correct answer'
                                      onChange={e => this.handleChoiceRadioChange(e, i, j)}
                                      checked={q.correctAnswer === j} />
                                  </div>
                                )
                              }
                            </div>
                          ))
                        }
                      </div>
                    </Form.Group>
                  ) : null
                }
              </div>
            </div>
          ))
        }
      </>
    );
  }
  renderModalBody = () => {
    const { saveModal } = this.state;

    if (saveModal.isLoading) {
      return (
        <>
          <Modal.Body>
            <LoadingIcon />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={this.hideSaveModal}>Close</Button>
          </Modal.Footer>
        </>
      );
    }

    if (saveModal.errorMessage) {
      return (
        <>
          <Modal.Body>
            <Alert variant='danger'>
              {saveModal.errorMessage}
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={this.hideSaveModal}>Close</Button>
          </Modal.Footer>
        </>
      );
    }

    return (
      <Form onSubmit={this.handleSave}>
        <Modal.Body>
          {
            saveModal.formError ? (
              <Alert variant='danger'>
                {saveModal.formError}
              </Alert>
            ) : saveModal.event ? (
              <Alert variant='warning'>
                Updating the questions will also delete the answers of the students.
              </Alert>
            ) : null
          }
          <Form.Row>
            <Form.Group as={Col} lg={6}>
              <Form.Label>Start Date</Form.Label>
              <Form.Control type='date' name='start' value={saveModal.formInputs.start} max={saveModal.formInputs.end} onChange={this.handleDateChange} />
            </Form.Group>
            <Form.Group as={Col} lg={6}>
              <Form.Label>End Date</Form.Label>
              <Form.Control type='date' name='end' value={saveModal.formInputs.end} min={saveModal.formInputs.start} onChange={this.handleDateChange} />
            </Form.Group>
          </Form.Row>
          {this.renderQuestionForms()}
        </Modal.Body>
        <Modal.Footer>
          {
            saveModal.rawEvent && (
              <Button variant='danger' className='mr-auto' onClick={e => this.showDeleteModal(saveModal.rawEvent)} disabled={saveModal.isFormLoading}>
                <FontAwesomeIcon icon='trash-alt' /> Delete
              </Button>
            )
          }
          <Button variant='light' onClick={this.hideSaveModal} disabled={saveModal.isFormLoading}>
            Cancel
          </Button>
          <Button variant='green' type='submit' disabled={saveModal.isFormLoading}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    );
  }
  renderTitle = e => {
    if (e.title) {
      return e.title;
    }

    if (e.start === e.end) {
      return moment(e.start).format('MMM D');
    }

    return `${moment(e.start).format('MMM D')} - ${moment(e.end).format('MMM D')}`;
  }
  renderQuestions = () => {
    const { events, isNextPageLoading, nextPageError } = this.state;

    return (
      <div style={{ height: '100vh'}}>
        {
          isNextPageLoading ? (
            <LoadingIcon />
          ) : nextPageError ? (
            <Alert variant='danger'>
              {nextPageError}
            </Alert>
          ) : null
        }
        <Calendar
          popup
          localizer={localizer}
          events={events}
          views={['month']}
          defaultView='month'
          onSelectEvent={this.handleSelectEvent}
          onSelectSlot={this.handleSelectSlot}
          titleAccessor={this.renderTitle}
          onRangeChange={this.fetchEvents}
          selectable
        />
      </div>
    );
  }
  renderContent = () => {
    const { isLoading, errorMessage, saveModal, deleteModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <div className='d-flex'>
          <div className='h4 mb-0'>
          <span className='fa-layers fa-fw'>
              <FontAwesomeIcon icon='sun' transform='grow-5' enableBackground />
              <FontAwesomeIcon icon='circle' transform='shrink-4' />
              <FontAwesomeIcon icon='question' transform='shrink-5' inverse />
            </span> Question of the Day
          </div>
          <div className='ml-auto'>
            <Button variant='green' onClick={this.showSaveModal}>
              <FontAwesomeIcon icon='plus' />
              <span className='ml-1 d-none d-md-inline-block'>Create</span>
            </Button>
          </div>
        </div>
        <div className='dropdown-divider'></div>
        {this.renderQuestions()}
        <Modal show={saveModal.show} onHide={this.hideSaveModal} backdrop='static'>
          <Modal.Header closeButton>
            <Modal.Title>{saveModal.event ? 'Update' : 'Create'}</Modal.Title>
          </Modal.Header>
          {this.renderModalBody()}
        </Modal>
        <PromptDeleteModal
          {...deleteModal}
          title='Delete'
          onHide={this.hideDeleteModal}
          onDelete={this.handleDelete}
          >
          {
            deleteModal.event && (
              <>
                <Card.Subtitle>
                  <div>
                    Are you sure you want to delete the questions?
                  </div>
                  <div>
                    Proceeding will also delete the answers of the students.
                  </div>
                </Card.Subtitle>
                <Alert variant='light'>
                  <div>
                    Start: {moment(deleteModal.event.start).format('MMM D, YYYY')}
                  </div>
                  <div>
                    End: {moment(deleteModal.event.end).format('MMM D, YYYY')}
                  </div>
                  <div>
                    {deleteModal.event.questions.length} questions
                  </div>
                </Alert>
              </>
            )
          }
        </PromptDeleteModal>
      </div>
    );
  }
  render() {
    return (
      <Card className='mt-3'>
        <Card.Body>
          {this.renderContent()}
        </Card.Body>
      </Card>
    );
  }
}