import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, InputGroup, Pagination, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Validator from 'validatorjs';
import axiosRequest from '../../../../util/helpers/axiosRequest';

import AdminSettingsModal from '../../../modals/AdminSettingsModal/AdminSettingsModal';
import PromptDeleteModal from '../../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class YearLevelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formInputs: {
        schoolLevel: '',
        code: '',
        desription: ''
      },
      yearLevels: {
        data: []
      },
      schoolLevels: [],
      isLoading: true,
      errorMessage: '',
      formError: '',
      editModal: {
        show: false,
        data: {
          id: '',
          schoolLevel: '',
          code: '',
          description: ''
        },
        errorMessage: ''
      },
      deleteModal: {
        show: false,
        data: {
          id: '',
          schoolLevel: '',
          code: '',
          description: ''
        },
        errorMessage: ''
      },
      search: {
        isLoading: false,
        errorMessage: '',
        query: '',
        isSearch: false
      }
    };
  }
  componentDidMount() {
    axiosRequest('get', 'admin/settings/year-level', null, ({ data: { data } }) => {
      this.setState({
        ...this.state,
        yearLevels: data.yearLevels,
        schoolLevels: data.schoolLevels,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showEditModal = (id) => {
    const yearLevel = [...this.state.yearLevels.data].find(p => p.id === id);
    this.setState({
      ...this.state,
      editModal: {
        show: true,
        data: {
          ...yearLevel,
          schoolLevel: yearLevel.school_level ? yearLevel.school_level.id : ''
        },
        errorMessage: ''
      }
    });
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        data: {
          id: '',
          schoolLevel: '',
          code: '',
          description: ''
        },
        errorMessage: ''
      }
    })
  }
  handleEditInputChange = event => {
    const { editModal } = this.state;
    editModal.data[event.target.name] = event.target.value;
    this.setState({
      ...this.state,
      editModal
    });
  }
  handleEdit = event => {
    event.preventDefault();
    const editModal = {...this.state.editModal};

    let rules = {
      id: 'required|integer',
      schoolLevel: 'required|integer',
      code: 'required|max:10',
      description: 'required|max:255'
    };
    
    let validation = new Validator(editModal.data, rules);

    if (validation.fails()) {
      const firstKey = Object.keys(validation.errors.errors)[0];
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          errorMessage: validation.errors.errors[firstKey][0]
        }
      });
      return;
    }

    axiosRequest('patch', `admin/settings/year-level/${editModal.data.id}`, {
      schoolLevel: editModal.data.schoolLevel,
      code: editModal.data.code,
      description: editModal.data.description
    }, ({ data: { data } }) => {
      const yearLevels = [...this.state.yearLevels.data].map((p) => {
        if (p.id === editModal.data.id) {
          return data;
        }
        
        return p;
      });
      this.setState({
        ...this.state,
        yearLevels: {
          ...this.state.yearLevels,
          data: [
            ...yearLevels
          ]
        },
        editModal: {
          show: false,
          data: {
            id: '',
            schoolLevel: '',
            code: '',
            description: ''
          },
          errorMessage: ''
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  showDeleteModal = (id) => {
    const yearLevel = [...this.state.yearLevels.data].find(p => p.id === id);
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        data: {
          ...yearLevel,
          schoolLevel: yearLevel.school_level ? yearLevel.school_level.title : ''
        },
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        data: {
          id: '',
          schoolLevel: '',
          code: '',
          description: ''
        },
        errorMessage: ''
      }
    });
  }
  handleDelete = () => {
    const deleteModal = {...this.state.deleteModal};

    axiosRequest('delete', `admin/settings/year-level/${deleteModal.data.id}`, null, (res) => {
      const yearLevels = [...this.state.yearLevels.data].filter((p) => {
        return p.id !== deleteModal.data.id;
      });
      this.setState({
        ...this.state,
        yearLevels: {
          ...this.state.yearLevels,
          data: [
            ...yearLevels
          ]
        },
        deleteModal: {
          show: false,
          data: {
            id: '',
            schoolLevel: '',
            code: '',
            description: ''
          },
          errorMessage: ''
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        deleteModal: {
          ...this.state.deleteModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleInputChange = event => {
    const { formInputs } = this.state;
    formInputs[event.target.name] = event.target.value
    this.setState({
      ...this.state,
      formInputs
    });
  }
  handleSubmit = event => {
    event.preventDefault();
    const { formInputs } = this.state;

    this.setState({
      ...this.state,
      formError: ''
    }, () => {
      let rules = {
        schoolLevel: 'required|integer',
        code: 'required|max:10',
        description: 'required|max:255'
      };
  
      let validation = new Validator(formInputs, rules);
  
      if (validation.fails()) {
        const firstKey = Object.keys(validation.errors.errors)[0];
        this.setState({
          ...this.state,
          formError: validation.errors.errors[firstKey][0]
        });
        return;
      }
  
      axiosRequest('post', 'admin/settings/year-level', {
        ...formInputs
      }, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          yearLevels: {
            ...this.state.yearLevels,
            data: [
              ...this.state.yearLevels.data,
              {...data}
            ]
          },
          formInputs: {
            schoolLevel: '',
            code: '',
            description: ''
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handlePagination = url => {
    if (url) {
      this.setState({
        ...this.state,
        isLoading: true
      }, () => {
        const path = url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
        
        axiosRequest('get', path, null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            yearLevels: data.yearLevels,
            isLoading: false,
            errorMessage: ''
          });
        }, (error) => {
          this.setState({
            ...this.state,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    }
  }
  handleSearchInputChange = event => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        query: event.target.value
      }
    });
  }
  handleSearch = event => {
    event.preventDefault();

    const { search } = this.state;

    if (!search.query && !search.isSearch) {
      return;
    }

    this.setState({
      ...this.state,
      search: {
        ...search,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {

      axiosRequest('get', `admin/settings/year-level/search/${search.query}`, null, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          yearLevels: data.yearLevels,
          search: {
            ...search,
            isLoading: false,
            errorMessage: '',
            isSearch: !!search.query
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          search: {
            ...search,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  renderTable = () => {
    const { yearLevels, isLoading, errorMessage, search } = this.state;
    
    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }
    
    return (
      <>
        {
          search.errorMessage && (
            <Alert variant='danger'>
              {search.errorMessage}
            </Alert>
          )
        }
        <Row>
          <Col lg={6} className='ml-auto'>
            <div className='mb-2 mt-1'>
              <Form onSubmit={this.handleSearch}>
                <InputGroup>
                  <Form.Control type='search' placeholder='Search year levels...' value={search.query} onChange={this.handleSearchInputChange} />
                  <InputGroup.Append>
                    <Button variant='primary' type='submit' disabled={search.isLoading}>
                      {
                        search.isLoading ? (
                          <LoadingIcon />
                        ) : (
                          <FontAwesomeIcon icon='search' />
                        )
                      }
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </div>
          </Col>
        </Row>
        {
          yearLevels.data.length > 0 ? (
            <>
              <Table striped bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>School Level</th>
                    <th>Code</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    yearLevels.data.map(yearLevel => (
                      <tr key={yearLevel.id}>
                        <td>{yearLevel.id}</td>
                        <td>{yearLevel.school_level ? yearLevel.school_level.title : '-'}</td>
                        <td>{yearLevel.code}</td>
                        <td>{yearLevel.description}</td>
                        <td className='text-center'>
                          <Button
                            variant='primary'
                            size='sm'
                            title='Edit'
                            className='mx-1'
                            onClick={(e) => {this.showEditModal(yearLevel.id)}}>
                            <FontAwesomeIcon icon='pencil-alt' />
                          </Button>
                          <Button variant='danger' size='sm' title='Delete' className='mx-1' onClick={(e) => {this.showDeleteModal(yearLevel.id)}}>
                            <FontAwesomeIcon icon='trash-alt' />
                          </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <Pagination className='justify-content-end'>
                <Pagination.First onClick={() => this.handlePagination(yearLevels.first_page_url)} disabled={yearLevels.current_page === 1} />
                <Pagination.Prev onClick={() => this.handlePagination(yearLevels.prev_page_url)} disabled={yearLevels.current_page === 1} />
                <Pagination.Next onClick={() => this.handlePagination(yearLevels.next_page_url)} disabled={yearLevels.current_page === yearLevels.last_page} />
                <Pagination.Last onClick={() => this.handlePagination(yearLevels.last_page_url)} disabled={yearLevels.current_page === yearLevels.last_page} />
              </Pagination>
            </>
          ) : (
            <Alert variant='light'>
              Nothing to show
            </Alert>
          )
        }
      </>
    );
  }
  renderContent = () => {
    const { isLoading, errorMessage, schoolLevels, formInputs, formError, editModal, deleteModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (schoolLevels.length === 0) {
      return (
        <Alert variant='warning'>
          Unable to add a year level with incomplete settings.
          Try adding a <b>school level</b> first.
        </Alert>
      );
    }

    return (
      <>
        {
          !!formError && (
            <Alert variant='danger'>{formError}</Alert>
          )
        }
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} lg={3}>
              <Form.Label>School Level</Form.Label>
              <Form.Control as='select' name='schoolLevel' value={formInputs.schoolLevel} onChange={this.handleInputChange}>
                <option hidden value=''>Select school level...</option>
                {
                  schoolLevels.map(schoolLevel => (
                    <option key={schoolLevel.id} value={schoolLevel.id}>{schoolLevel.title}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} lg={2}>
              <Form.Label>Year Level Code</Form.Label>
              <Form.Control type='text' placeholder='e.g. 4' name='code' value={formInputs.code} onChange={this.handleInputChange} />
            </Form.Group>
            <Form.Group as={Col} lg={4}>
              <Form.Label>Year Level Description</Form.Label>
              <Form.Control type='text' placeholder='e.g. 4th year' name='description' value={formInputs.description} onChange={this.handleInputChange} />
            </Form.Group>
            <Form.Group as={Col} lg={3} className='d-flex align-items-end'>
              <Button type='submit' variant='green' block>Add year level</Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <div className='dropdown-divider'></div>
        { this.renderTable() }

        <AdminSettingsModal
          {...editModal}
          title='Edit year level'
          onHide={this.hideEditModal}
          onSubmit={this.handleEdit}>
            <Form.Group>
              <Form.Label>School Level</Form.Label>
              <Form.Control as='select' name='schoolLevel' value={editModal.data.schoolLevel} onChange={this.handleEditInputChange}>
                <option hidden value=''>Select school level...</option>
                {
                  schoolLevels.map(schoolLevel => (
                    <option key={schoolLevel.id} value={schoolLevel.id}>{schoolLevel.title}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Year Level Code</Form.Label>
              <Form.Control
                type='text'
                placeholder='e.g. 4'
                name='code'
                value={editModal.data.code}
                onChange={this.handleEditInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Year Level Description</Form.Label>
              <Form.Control
                type='text'
                placeholder='e.g. 4th year'
                name='description'
                value={editModal.data.description}
                onChange={this.handleEditInputChange} />
            </Form.Group>
        </AdminSettingsModal>
        <PromptDeleteModal
          {...deleteModal}
          title='Delete year level'
          onHide={this.hideDeleteModal}
          onDelete={this.handleDelete}>
            <Card.Subtitle>Are you sure you want to delete the year level?</Card.Subtitle>
            <Alert variant='light'>
              <div>{deleteModal.data.schoolLevel}</div>
              <div>{deleteModal.data.code}</div>
              <div>{deleteModal.data.description}</div>
            </Alert>
        </PromptDeleteModal>
      </>
    );
  }
  render() {
    return (
      <Card>
        <Card.Body>
          {this.renderContent()}
        </Card.Body>
      </Card>
    );
  }
}