import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Card, Col, Collapse, Dropdown, Form, Image, Modal, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PromptDeleteModal from '../../modals/PromptDeleteModal/PromptDeleteModal';
import moment from 'moment';
import axiosRequest from '../../../util/helpers/axiosRequest';
import Validator from 'validatorjs';
import { loadRule } from '../../../util';
import axios from 'axios';
import './style.scss';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../common/RichTextEditor/RichTextEditor';

class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      announcements: {
        data: []
      },
      isNextPageLoading: false,
      nextPageError: '',
      audiences: [],
      formInputs: {
        format: 'text',
        text: {
          title: '',
          description: '',
          availability: 'always',
          from: '',
          until: '',
          fileNames: [],
          audience: [],
          deleteFiles: []
        },
        image: {
          image: '',
          availability: 'always',
          from: '',
          until: '',
          audience: []
        }
      },
      saveModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        loadingFiles: false,
        preview: null,
        isPreviewLoading: false,
        announcement: null,
        showAttachments: false,
        loadingDeleteFiles: []
      },
      deleteModal: {
        show: false,
        announcement: null,
        isLoading: false,
        errorMessage: ''
      },
    };

    this.files = [];
    this.image = null;
  }
  componentDidMount() {
    const { userType } = this.props.match.params;

    axiosRequest('get', `${userType}/announcement/manage`, null, ({ data: { data }}) => {
      if (userType === 'faculty') {
        data.audiences.sort((a, b) => {
          if (a.class_course.school_class.program.code < b.class_course.school_class.program.code) {
            return -1;
          } else if (a.class_course.school_class.program.code > b.class_course.school_class.program.code) {
            return 1;
          }
  
          if (a.class_course.school_class.year_level.code < b.class_course.school_class.year_level.code) {
            return -1;
          } else if (a.class_course.school_class.year_level.code > b.class_course.school_class.year_level.code) {
            return 1;
          }
  
          if (a.class_course.school_class.section.code < b.class_course.school_class.section.code) {
            return -1;
          } else if (a.class_course.school_class.section.code > b.class_course.school_class.section.code) {
            return 1;
          }
  
          if (a.class_course.course.code < b.class_course.course.code) {
            return -1;
          } else if (a.class_course.course.code > b.class_course.course.code) {
            return 1;
          }
  
          return 0;
        });
      }
      
      const audience = data.audiences.map(a => a.id);
      this.setState({
        ...this.state,
        ...data,
        formInputs: {
          ...this.state.formInputs,
          text: {
            ...this.state.formInputs.text,
            audience
          },
          image: {
            ...this.state.formInputs.image,
            audience
          }
        },
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
    loadRule(['date', 'after_or_equal'], {
      after_or_equal: 'The :attribute date must be equal or after the current date and time.'
    });
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true,
      nextPageError: ''
    }, () => {
      const { announcements } = this.state;

      const path = announcements.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');

      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          announcements: {
            ...data.announcements,
            data: [
              ...this.state.announcements.data,
              ...data.announcements.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleFormatChange = event => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [event.target.name]: event.target.value
      }
    });
  }
  handleInputChange = event => {
    const { formInputs } = this.state;
    let custom = {};
    if (event.target.name === 'availability') {
      custom = {
        from: moment().format('YYYY-MM-DDTHH:mm'),
        until: ''
      };
    }
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [formInputs.format]: {
          ...formInputs[formInputs.format],
          ...custom,
          [event.target.name]: event.target.value
        }
      }
    });
  }
  handleDescriptionChange = value => {
    const { formInputs } = this.state;
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [formInputs.format]: {
          ...formInputs[formInputs.format],
          description: value
        }
      }
    });
  }
  handleAudienceChange = event => {
    const { formInputs } = this.state;
    let audience = [...formInputs[formInputs.format].audience];
    if (event.target.checked) {
      audience = [...audience, +event.target.value];
    } else {
      audience = audience.filter(a => a !== +event.target.value);
    }

    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [formInputs.format]: {
          ...formInputs[formInputs.format],
          audience
        }
      }
    });
  }
  showSaveModal = (event, announcement = null) => {
    const { formInputs } = this.state;

    let newFormInputs = {};
    if (announcement) {
      let format = announcement.format.toLowerCase();
      if (format === 'text') {
        newFormInputs = {
          formInputs: {
            ...formInputs,
            format,
            text: {
              title: announcement.title,
              description: announcement.description,
              availability: announcement.from && announcement.until ? 'fromUntil' : announcement.from ? 'from' : announcement.until ? 'until' : 'always',
              from: announcement.from ? moment(announcement.from).format('YYYY-MM-DDTHH:mm') : '',
              until: announcement.until ? moment(announcement.until).format('YYYY-MM-DDTHH:mm') : '',
              fileNames: [],
              audience: announcement.announcement_audiences.map(aa => aa.audience_id),
              deleteFiles: []
            }
          }
        };
      } else {
        newFormInputs = {
          formInputs: {
            ...formInputs,
            format,
            image: {
              image: '',
              availability: announcement.from && announcement.until ? 'fromUntil' : announcement.from ? 'from' : announcement.until ? 'until' : 'always',
              from: announcement.from ? moment(announcement.from).format('YYYY-MM-DDTHH:mm') : '',
              until: announcement.until ? moment(announcement.until).format('YYYY-MM-DDTHH:mm') : '',
              audience: announcement.announcement_audiences.map(aa => aa.audience_id)
            }
          }
        };
      }
    }

    this.setState({
      ...this.state,
      saveModal: {
        show: true,
        isLoading: false,
        errorMessage: '',
        loadingFiles: false,
        preview: null,
        isPreviewLoading: false,
        announcement,
        showAttachments: false,
        loadingDeleteFiles: []
      },
      ...newFormInputs
    });
  }
  hideSaveModal = () => {
    const { audiences } = this.state;

    const audience = audiences.map(a => a.id);

    this.setState({
      ...this.state,
      saveModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        loadingFiles: false,
        preview: null,
        isPreviewLoading: false,
        announcement: null,
        showAttachments: false,
        loadingDeleteFiles: []
      },
      formInputs: {
        format: 'text',
        text: {
          title: '',
          description: '',
          availability: 'always',
          from: '',
          until: '',
          fileNames: [],
          audience,
          deleteFiles: []
        },
        image: {
          image: '',
          availability: 'always',
          from: '',
          until: '',
          audience
        }
      }
    }, () => {
      this.files = [];
      this.image = null;
    });
  }
  handleFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.files = [...this.files, ...files];
      this.setState({
        ...this.state,
        formInputs: {
          ...this.state.formInputs,
          text: {
            ...this.state.formInputs.text,
            fileNames: [
              ...this.state.formInputs.text.fileNames,
              ...filesState
            ]
          }
        },
      });
    }
  }
  handleRemoveUpload = id => {
    let newFileNames = [...this.state.formInputs.text.fileNames];
    newFileNames.splice(id, 1);

    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        text: {
          ...this.state.formInputs.text,
          fileNames: newFileNames
        }
      }
    }, () => {
      this.files.splice(id, 1);
    });
  }
  handleImageChange = event => {
    const files = event.target.files;

    if (files.length > 0) {
      this.setState({
        ...this.state,
        saveModal: {
          ...this.state.saveModal,
          isPreviewLoading: true
        }
      }, () => {
        const fileToBase64 = file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result));
            reader.addEventListener('error', error => reject(error));
            reader.readAsDataURL(file);
          });
        };
        const image = files[0];
        let validator = {
          passed: true,
          error: null
        };

        if (typeof image.name !== 'string') {
          validator.passed = false;
          validator.error = 'Invalid file.';
        } else if (image.type !== 'image/jpeg' && image.type !== 'image/png') {
          validator.passed = false;
          validator.error = 'Invalid image format.';
        }
        
        if (validator.passed) {
          fileToBase64(image).then(imageSrc => {
            this.setState({
              ...this.state,
              saveModal: {
                ...this.state.saveModal,
                preview: imageSrc,
                isPreviewLoading: false
              },
              formInputs: {
                ...this.state.formInputs,
                image: {
                  ...this.state.formInputs.image,
                  image: image.name
                }
              }
            }, () => {
              this.image = image;
            });
          }).catch(() => {
            this.setState({
              ...this.state,
              saveModal: {
                ...this.state.saveModal,
                errorMessage: 'Failed to read image.',
                preview: null,
                isPreviewLoading: false
              },
              formInputs: {
                ...this.state.formInputs,
                image: {
                  ...this.state.formInputs.image,
                  image: ''
                }
              }
            }, () => {
              this.image = null;
            });
          });
        } else {
          this.setState({
            ...this.state,
            saveModal: {
              ...this.state.saveModal,
              errorMessage: validator.error,
              preview: null,
              isPreviewLoading: false
            },
            formInputs: {
              ...this.state.formInputs,
              image: {
                ...this.state.formInputs.image,
                image: ''
              }
            }
          }, () => {
            this.image = null;
          });
        }
      });
    }
  }
  handleShowAttachments = () => {
    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        showAttachments: !this.state.saveModal.showAttachments
      }
    });
  }
  handleCancelDeleteFile = id => {
    let newDeleteFiles = [...this.state.formInputs.text.deleteFiles].filter(fileID => fileID !== id);
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        text: {
          ...this.state.formInputs.text,
          deleteFiles: newDeleteFiles
        }
      }
    });
  }
  handleDeleteFile = id => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        text: {
          ...this.state.formInputs.text,
          deleteFiles: [...this.state.formInputs.text.deleteFiles, id]
        }
      }
    });
  }
  handleCreate = (format, announcementData) => {
    const { userType } = this.props.match.params;

    if (format === 'text') {
      axiosRequest('post', `${userType}/announcement`, {
        ...announcementData,
        format
      }, ({ data: { data }}) => {
        if (this.files.length > 0) {
          let fileRequests = [];
          for (let i = 0; i < this.files.length; i++) {
            fileRequests.push(this.uploadFile(i, userType, data.id));
          }
          Promise.all(fileRequests).then(result => {
            data.files = result;
            this.setState({
              ...this.state,
              announcements: {
                ...this.state.announcements,
                data: [
                  data,
                  ...this.state.announcements.data
                ]
              }
            }, this.hideSaveModal);
          }).catch((error) => {
            this.setState({
              ...this.state,
              saveModal: {
                ...this.state.saveModal,
                errorMessage: error,
                isLoading: false,
                loadingFiles: false
              }
            });
          });
        } else {
          this.setState({
            ...this.state,
            announcements: {
              ...this.state.announcements,
              data: [
                data,
                ...this.state.announcements.data
              ]
            }
          }, this.hideSaveModal);
        }
      }, error => {
        this.setState({
          ...this.state,
          saveModal: {
            ...this.state.saveModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      });
    } else {
      const formData = new window.FormData();
      formData.append('file', this.image, this.image.name);
      formData.append('availability', announcementData.availability);
      formData.append('from', announcementData.from);
      formData.append('until', announcementData.until);
      formData.append('format', format);

      for (let i = 0; i < announcementData.audience.length; i++) {
        formData.append('audience[]', announcementData.audience[i]);
      }

      axiosRequest('post', `${userType}/announcement`, formData, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          announcements: {
            ...this.state.announcements,
            data: [
              data,
              ...this.state.announcements.data
            ]
          }
        }, this.hideSaveModal);
      }, error => {
        this.setState({
          ...this.state,
          saveModal: {
            ...this.state.saveModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      });
    }
  }
  handleUpdate = (format, announcementData) => {
    const { userType } = this.props.match.params;
    const { saveModal } = this.state;

    if (format === 'text') {
      axiosRequest('post', `${userType}/announcement/${saveModal.announcement.id}/update`, {
        ...announcementData,
        format
      }, ({ data: { data }}) => {
        let uploadRequests = [];
        let deleteRequests = [];

        if (this.files.length > 0) {
          for (let i = 0; i < this.files.length; i++) {
            uploadRequests.push(this.uploadFile(i, userType, saveModal.announcement.id));
          }
        }

        if (announcementData.deleteFiles.length > 0) {
          for (let i = 0; i < announcementData.deleteFiles.length; i++) {
            deleteRequests.push(this.deleteFile(announcementData.deleteFiles[i], userType, saveModal.announcement.id));
          }
        }

        if (uploadRequests.length && deleteRequests.length) {
          Promise.all([Promise.all(uploadRequests), Promise.all(deleteRequests)]).then(result => {
            data.files = [...result[0], ...data.files];
            data.files = data.files.filter(file => result[1].indexOf(file.id) === -1);
            const newAnnouncementsData = [...this.state.announcements.data].map(a => {
              if (a.id === data.id) {
                return data;
              }

              return a;
            });
            this.setState({
              ...this.state,
              announcements: {
                ...this.state.announcements,
                data: newAnnouncementsData
              }
            }, this.hideSaveModal);
          }).catch((error) => {
            this.setState({
              ...this.state,
              saveModal: {
                ...this.state.saveModal,
                errorMessage: error,
                isLoading: false
              }
            });
          });
        } else if (uploadRequests.length) {
          Promise.all(uploadRequests).then(result => {
            data.files = [...result, ...data.files];
            const newAnnouncementsData = [...this.state.announcements.data].map(a => {
              if (a.id === data.id) {
                return data;
              }

              return a;
            });
            this.setState({
              ...this.state,
              announcements: {
                ...this.state.announcements,
                data: newAnnouncementsData
              }
            }, this.hideSaveModal);
          }).catch((error) => {
            this.setState({
              ...this.state,
              saveModal: {
                ...this.state.saveModal,
                errorMessage: error,
                isLoading: false
              }
            });
          });
        } else if (deleteRequests.length) {
          Promise.all(deleteRequests).then(result => {
            data.files = data.files.filter(file => result.indexOf(file.id) === -1);
            const newAnnouncementsData = [...this.state.announcements.data].map(a => {
              if (a.id === data.id) {
                return data;
              }

              return a;
            });
            this.setState({
              ...this.state,
              announcements: {
                ...this.state.announcements,
                data: newAnnouncementsData
              }
            }, this.hideSaveModal);
          });
        } else {
          const newAnnouncementsData = [...this.state.announcements.data].map(a => {
            if (a.id === data.id) {
              return data;
            }

            return a;
          });
          this.setState({
            ...this.state,
            announcements: {
              ...this.state.announcements,
              data: newAnnouncementsData
            }
          }, this.hideSaveModal);
        }
      }, error => {
        this.setState({
          ...this.state,
          saveModal: {
            ...this.state.saveModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      });
    } else {
      const formData = new window.FormData();
      if (this.image) {
        formData.append('file', this.image, this.image.name);
      }
      formData.append('availability', announcementData.availability);
      formData.append('from', announcementData.from);
      formData.append('until', announcementData.until);
      formData.append('format', format);

      for (let i = 0; i < announcementData.audience.length; i++) {
        formData.append('audience[]', announcementData.audience[i]);
      }

      axiosRequest('post', `${userType}/announcement/${saveModal.announcement.id}/update`, formData, ({ data: { data }}) => {
        const newAnnouncementsData = [...this.state.announcements.data].map(a => {
          if (a.id === data.id) {
            return data;
          }

          return a;
        });
        this.setState({
          ...this.state,
          announcements: {
            ...this.state.announcements,
            data: newAnnouncementsData
          }
        }, this.hideSaveModal);
      }, error => {
        this.setState({
          ...this.state,
          saveModal: {
            ...this.state.saveModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, {
        header: {
          'content-type': 'multipart/form-data'
        }
      });
    }
  }
  handleSave = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      saveModal: {
        ...this.state.saveModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { formInputs, saveModal } = this.state;

      let formData = null;
      let validator = null;
      if (formInputs.format === 'text') {
        formData = formInputs.text;
        validator = new Validator(formInputs.text, {
          title: 'required|min:3',
          description: 'required',
          availability: 'required|in:always,from,fromUntil,until',
          from: `required_if:availability,from|required_if:availability,fromUntil`,
          until: 'required_if:availability,fromUntil|required_if:availability,until',
          audience: `required|array|min:1`
        }, {
          required_if: 'The :attribute field is required.'
        });
      } else if (formInputs.format === 'image') {
        formData = formInputs.image;
        validator = new Validator(formInputs.image, {
          availability: 'required|in:always,from,fromUntil,until',
          from: `required_if:availability,from|required_if:availability,fromUntil`,
          until: 'required_if:availability,fromUntil|required_if:availability,until',
          audience: `required|array|min:1`
        }, {
          required_if: 'The :attribute field is required.'
        });

        if (!saveModal.announcement || saveModal.announcement.format === 'Text' || formData.image) {
          let imageValidator = {
            passed: true,
            error: null
          };
    
          if (this.image === null) {
            imageValidator.passed = false;
            imageValidator.error = 'The image is required.';
          } else if (typeof this.image.name !== 'string') {
            imageValidator.passed = false;
            imageValidator.error = 'Invalid file.';
          } else if (this.image.type !== 'image/jpeg' && this.image.type !== 'image/png') {
            imageValidator.passed = false;
            imageValidator.error = 'Invalid image format.';
          }
  
          if (!imageValidator.passed) {
            this.setState({
              ...this.state,
              saveModal: {
                ...this.state.saveModal,
                isLoading: false,
                errorMessage: imageValidator.error
              }
            });
            return;
          }
        }
      }

      if (!validator) {
        this.setState({
          ...this.state,
          saveModal: {
            ...this.state.saveModal,
            isLoading: false,
            errorMessage: 'Invalid announcement format.'
          }
        });
      }

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          saveModal: {
            ...this.state.saveModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      let rules = null;

      switch (formData.availability) {
        case 'from':
          rules = {
            from: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`
          };
          break;
        case 'fromUntil':
          rules = {
            from: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`,
            until: `required|date|after_or_equal:from`
          };
          break;
        case 'until':
          rules = {
            until: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`
          };
          break;
        default:
          break;
      }

      if (rules !== null) {
        validator = new Validator(formData, rules);

        if (validator.fails()) {
          const firstKey = Object.keys(validator.errors.errors)[0];
          this.setState({
            ...this.state,
            saveModal: {
              ...this.state.saveModal,
              isLoading: false,
              errorMessage: validator.errors.errors[firstKey][0]
            }
          });
          return;
        }
      }

      if (saveModal.announcement) {
        this.handleUpdate(formInputs.format, formData);
      } else {
        this.handleCreate(formInputs.format, formData);
      }
    });
  }
  showDeleteModal = announcement => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        announcement,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        announcement: null,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleDelete = () => {

    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { userType } = this.props.match.params;
      const { deleteModal } = this.state;

      axiosRequest('delete', `${userType}/announcement/${deleteModal.announcement.id}`, null, ({ data: { message }}) => {
        const announcements = [...this.state.announcements.data].filter(announcement => {
          return announcement.id !== deleteModal.announcement.id
        });

        this.setState({
          ...this.state,
          announcements: {
            ...this.state.announcements,
            data: announcements
          },
          deleteModal: {
            show: false,
            announcement: null,
            isLoading: false,
            errorMessage: ''
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  uploadFile = (i, userType, announcementID) => {
    const formData = new window.FormData();
    formData.append('file', this.files[i], this.files[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/${userType}/announcement/${announcementID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { formInputs: { text: { fileNames } } } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          formInputs: {
            ...this.state.formInputs,
            text: {
              ...this.state.formInputs.text,
              fileNames
            }
          }
        });
      }
    }).then(({ data: { data }}) => {
      const { formInputs: { text: { fileNames } } } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        formInputs: {
          ...this.state.formInputs,
          text: {
            ...this.state.formInputs.text,
            fileNames
          }
        }
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { formInputs: { text: { fileNames } } } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        formInputs: {
          ...this.state.formInputs,
          text: {
            ...this.state.formInputs.text,
            fileNames
          }
        }
      });
      throw fileNames[i].isError;
    });
  }
  deleteFile = (id, userType, announcementID) => {
    return axios.delete(`${process.env['REACT_APP_API_BASE_URL']}/${userType}/announcement/${announcementID}/file/${id}`, {
      withCredentials: true,
    }).then(({ data: { data }}) => {
      let newLoadingDeleteFiles = [...this.state.saveModal.loadingDeleteFiles].filter(fileID => fileID !== id);
      this.setState({
        ...this.state,
        saveModal: {
          ...this.state.saveModal,
          loadingDeleteFiles: newLoadingDeleteFiles
        }
      });
      return id;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }
    });
  }
  renderFileUpload = () => {
    const { formInputs: { text: { fileNames } }, saveModal: { loadingFiles } } = this.state;
    if (fileNames.length > 0) {
      return (
        <Form.Group>
          <Form.Label>File(s) to be uploaded</Form.Label>
          <small className='file-upload-display d-block rounded-top'>
          {
            fileNames.map((fileName, index) => (
              <div key={index} className='file-name d-flex'>
                <div>
                  {
                    fileName.isLoading || (loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                      <LoadingIcon />
                    ) : fileName.isError ? (
                      <FontAwesomeIcon icon='times-circle' className='text-danger' />
                    ) : fileName.isSuccess ? (
                      <FontAwesomeIcon icon='check-circle' className='text-green' />
                    ) : (
                      <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                    )
                  }
                </div>
                <div className='ml-2 flex-fill'>
                  <div>
                    {fileName.name}
                  </div>
                  {
                    fileName.isLoading && (
                      <div className='mt-1'>
                        <ProgressBar now={fileName.isLoading} />
                      </div>
                    )
                  }
                </div>
                {
                  (!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !loadingFiles) && (
                    <div className='ml-2 align-items-center d-flex'>
                      <Button
                        variant='link'
                        className='text-danger'
                        size='sm'
                        title='Remove'
                        onClick={(e) => this.handleRemoveUpload(index)}>
                        <FontAwesomeIcon icon='times' size='sm' />
                      </Button>
                    </div>
                  )
                }
              </div>
            ))
          }
          </small>
        </Form.Group>
      );
    }
  }
  renderAudience = () => {
    const { match: { params: { userType } }, currentUser } = this.props;
    const { formInputs, audiences } = this.state;
    const { audience } = formInputs[formInputs.format];

    if (userType === 'school-admin') {
      if (currentUser.school_admin_scopes && currentUser.school_admin_scopes.length > 0) {
        return (
          <Form.Group>
            <Form.Label>
              Audience
            </Form.Label>
            <div className='ml-3'>
            {
              audiences.map(a => (
                <Form.Group
                  controlId={`audience${a.id}`}
                  key={a.id}>
                  <Form.Check
                    type='checkbox'
                    name='audience'
                    label={`${a.class_course.school_class.program.code} ${a.class_course.school_class.year_level.code} - ${a.class_course.school_class.section.code} - ${a.class_course.course.code}`}
                    value={a.id}
                    checked={audience.indexOf(a.id) !== -1}
                    onChange={this.handleAudienceChange} />
                </Form.Group>
              ))
            }
            </div>
          </Form.Group>
        );
      }
      return (
        <Form.Group>
          <Form.Label>
            Audience
          </Form.Label>
          <div className='ml-3'>
          {
            audiences.map(a => (
              <Form.Group
                controlId={`audience${a.id}`}
                key={a.id}>
                <Form.Check
                  type='checkbox'
                  name='audience'
                  label={a.description}
                  value={a.id}
                  checked={audience.indexOf(a.id) !== -1}
                  onChange={this.handleAudienceChange} />
              </Form.Group>
            ))
          }
          </div>
        </Form.Group>
      );
    } else if (userType === 'faculty') {
      return (
        <Form.Group>
          <Form.Label>
            Audience
          </Form.Label>
          <div className='ml-3'>
          {
            audiences.map(a => (
              <Form.Group
                controlId={`audience${a.id}`}
                key={a.id}>
                <Form.Check
                  type='checkbox'
                  name='audience'
                  label={`${a.class_course.school_class.program.code} ${a.class_course.school_class.year_level.code} - ${a.class_course.school_class.section.code} - ${a.class_course.course.code}`}
                  value={a.id}
                  checked={audience.indexOf(a.id) !== -1}
                  onChange={this.handleAudienceChange} />
              </Form.Group>
            ))
          }
          </div>
        </Form.Group>
      );
    }

    return null;
  }
  renderAvailability = () => {
    const { formInputs } = this.state;

    const { availability, from, until } = formInputs[formInputs.format];

    switch(availability) {
      case 'from':
        return (
          <Form.Group>
            <Form.Label>From</Form.Label>
            <Form.Control type='datetime-local' name='from' value={from} min={moment().format('YYYY-MM-DDTHH:mm')} onChange={this.handleInputChange} />
          </Form.Group>
        );
      case 'fromUntil':
        return (
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>From</Form.Label>
              <Form.Control type='datetime-local' name='from' value={from} min={moment().format('YYYY-MM-DDTHH:mm')} max={until} onChange={this.handleInputChange} />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>Until</Form.Label>
              <Form.Control type='datetime-local' name='until' value={until} min={from} onChange={this.handleInputChange} />
            </Form.Group>
          </Form.Row>
        );
      case 'until':
        return (
          <Form.Group>
            <Form.Label>Until</Form.Label>
            <Form.Control type='datetime-local' name='until' value={until} min={from} onChange={this.handleInputChange} />
          </Form.Group>
        );
      default:
        return null;
    }
  }
  renderAttachedFiles = () => {
    const { saveModal: { announcement, loadingDeleteFiles, showAttachments }, formInputs: { text: { deleteFiles } } } = this.state;

    if (announcement && announcement.format === 'Text' && announcement.files.length > 0) {
      return (
        <>
          <div className={`text-right border rounded-top ${showAttachments ? 'border-bottom-0' : 'rounded-bottom'}`}>
            <Button variant='link' onClick={this.handleShowAttachments} block>
              <FontAwesomeIcon icon='paperclip' /> Show {announcement.files.length} attachment(s)
            </Button>
          </div>
          <Collapse in={showAttachments}>
            <div className='file-upload-display small'>
            {
              announcement.files.map(file => (
                <div className={`file-name d-flex ${deleteFiles.indexOf(file.id) !== -1 ? 'alert-light' : ''}`}>
                  {
                    loadingDeleteFiles.indexOf(file.id) !== -1 && (
                      <LoadingIcon />
                    )
                  }
                  <div className='ml-2 flex-fill'>
                    <div>
                      {file.file_title}
                    </div>
                    {
                      deleteFiles.indexOf(file.id) !== -1 && (
                        <div className='font-italic'>
                          To be deleted
                        </div>
                      )
                    }
                  </div>
                  <div className='ml-2 align-items-center d-flex'>
                    {
                      deleteFiles.indexOf(file.id) !== -1 ? (
                        <Button
                          variant='link'
                          className='text-green'
                          size='sm'
                          title='Cancel delete'
                          onClick={() => this.handleCancelDeleteFile(file.id)}>
                          <FontAwesomeIcon icon='ban' size='sm' />
                        </Button>
                      ) : (
                        <Button
                          variant='link'
                          className='text-danger'
                          size='sm'
                          title='Delete'
                          onClick={() => this.handleDeleteFile(file.id)}>
                          <FontAwesomeIcon icon='trash-alt' size='sm' />
                        </Button>
                      )
                    }
                  </div>
                </div>
              ))
            }
            </div>
          </Collapse>
        </>
      );
    }
  }
  renderActions = announcement => {
    return (
      <>
        <div className='d-flex d-md-none'>
          <div className='mr-2'>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  <div>
                    {moment(announcement.created_at).format('MMM D, YYYY hh:mm A')}
                  </div>
                  <div>
                    {moment(announcement.created_at).fromNow()}
                  </div>
                </Tooltip>
              }
              trigger={['hover', 'focus']}>
              <FontAwesomeIcon icon='clock' className='text-muted' />
            </OverlayTrigger>
          </div>
          <Dropdown>
            <Dropdown.Toggle as='span' className='text-green'>
              <FontAwesomeIcon icon='cog' />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => this.showSaveModal(e, announcement)}>
                <FontAwesomeIcon icon='pencil-alt' /> Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.showDeleteModal(announcement)}>
                <FontAwesomeIcon icon='trash-alt' /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='font-small text-muted font-italic pr-3 d-none d-md-block'>
          &mdash; <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {moment(announcement.created_at).format('MMM D, YYYY hh:mm A')}
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <FontAwesomeIcon icon='clock' />
                  </OverlayTrigger> {moment(announcement.created_at).fromNow()}
        </div>
        <div className='d-none d-md-block' style={{ whiteSpace: 'nowrap' }}>
          <Button variant='info' size='sm' className='mr-1' onClick={(e) => this.showSaveModal(e, announcement)}>
            <FontAwesomeIcon icon='pencil-alt' />
          </Button>
          <Button variant='danger' size='sm' onClick={() => this.showDeleteModal(announcement)}>
            <FontAwesomeIcon icon='trash-alt' />
          </Button>
        </div>
      </>
    );
  }
  renderDisplayTextAvailability = (from, until) => {
    if (from && until) {
      if (moment().isBefore(from)) {
        return 'Not yet available';
      } else if (moment().isAfter(until)) {
        return 'Expired';
      }
    } else if (from) {
      if (moment().isBefore(from)) {
        return 'Not yet available';
      }
    } else if (until) {
      if (moment().isAfter(until)) {
        return 'Expired';
      }
    } else {
      return 'Always available';
    }

    return 'Active';
  }
  renderDisplayAvailability = (from, until) => {
    return (
      <div className='text-muted font-italic d-flex'>
        {
          from && until ? (
            <>
              <div className='mr-1'>Available</div>
              <div>
                <div>
                  From: {moment(from).format('ddd, MMMM D, YYYY hh:mm A')}
                </div>
                <div>
                  Until: {moment(until).format('ddd, MMMM D, YYYY hh:mm A')}
                </div>
              </div>
            </>
          ) : from ? (
            <>
              <div className='mr-1'>Available</div>
              <div>
                From: {moment(from).format('ddd, MMMM D, YYYY hh:mm A')}
              </div>
            </>
          ) : until ? (
            <>
              <div className='mr-1'>Available</div>
              <div>
                Until: {moment(until).format('ddd, MMMM D, YYYY hh:mm A')}
              </div>
            </>
          ) : ''
        }
      </div>
    );
  }
  renderTextAnnouncement = announcement => {
    return (
      <div key={announcement.id} className='d-flex'>
        <div className='mr-2'>
          <FontAwesomeIcon icon='align-left' size='3x' />
        </div>
        <div className='flex-fill overflow-hidden'>
          <div className='font-weight-bold h6'>
            {announcement.title} <span className='font-weight-normal text-muted font-italic'>({this.renderDisplayTextAvailability(announcement.from, announcement.until)})</span>
          </div>
          <RichTextEditor.Viewer body={announcement.description} />
          
          {
            announcement.files && announcement.files.length > 0 && (
              <div className='text-muted font-italic'>
                <FontAwesomeIcon icon='paperclip' /> {`${announcement.files.length} attachment${announcement.files.length === 1 ? '' : 's'}`}
              </div>
            )
          }
          {this.renderDisplayAvailability(announcement.from, announcement.until)}
          {
            announcement.announcement_audiences && (
              <div className='text-muted font-italic mt-1'>
                {`${announcement.announcement_audiences.length} ${announcement.announcement_audiences.length === 1 ? 'type of audience' : 'types of audiences'}`} 
              </div>
            )
          }
        </div>
        {this.renderActions(announcement)}
      </div>
    );
  }
  renderImageAnnouncement = announcement => {
    return (
      <div key={announcement.id} className='d-flex'>
        <div className='mr-2'>
          <FontAwesomeIcon icon='image' size='3x' />
        </div>
        <div className='flex-fill'>
          <div className='font-weight-normal text-muted font-italic mb-1'>({this.renderDisplayTextAvailability(announcement.from, announcement.until)})</div>
          {
            announcement.files && announcement.files.length > 0 && (
              <div style={{ height: '5rem', marginBottom: '.5rem' }}>
                <Image src={`${process.env['REACT_APP_API_BASE_URL']}/announcement/${announcement.id}/image/${announcement.files[0].id}`} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
              </div>
            )
          }
          {this.renderDisplayAvailability(announcement.from, announcement.until)}
          {
            announcement.announcement_audiences && (
              <div className='text-muted font-italic'>
                {`${announcement.announcement_audiences.length} ${announcement.announcement_audiences.length === 1 ? 'type of audience' : 'types of audiences'}`} 
              </div>
            )
          }
        </div>
        {this.renderActions(announcement)}
      </div>
    );
  }
  renderAnnouncements = () => {
    const { announcements, isNextPageLoading, nextPageError } = this.state;

    if (announcements.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='announcement-list'>
          {
            announcements.data.map(announcement => announcement.format === 'Text' ? this.renderTextAnnouncement(announcement) : this.renderImageAnnouncement(announcement))
          }
        </div>
        {
          announcements.next_page_url && (
            <div className='text-center mt-3'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                Show more...
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          )
        }
      </>
    );
  }
  renderContent = () => {
    const { isLoading, errorMessage, saveModal, formInputs, deleteModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <div className='d-flex'>
          <div className='h4 mb-0'>
            <FontAwesomeIcon icon='bullhorn' /> Announcements
          </div>
          <div className='ml-auto'>
            <Button variant='green' onClick={this.showSaveModal}>
              <FontAwesomeIcon icon='plus' />
              <span className='ml-1 d-none d-md-inline-block'>Create an announcement</span>
            </Button>
          </div>
        </div>
        <div className='dropdown-divider'></div>
        {this.renderAnnouncements()}
        <Modal show={saveModal.show} onHide={this.hideSaveModal} size='lg' backdrop='static'>
          <Modal.Header closeButton>
            <Modal.Title>
              {
                saveModal.announcement ? 'Update announcement' : 'Create an announcement'
              }
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleSave}>
            <Modal.Body>
              {
                saveModal.errorMessage && (
                  <Alert variant='danger'>
                    {saveModal.errorMessage}
                  </Alert>
                )
              }
              <Form.Group>
                <div>
                  <Form.Label>
                    Format
                  </Form.Label>
                </div>
                <div className='form-check-inline'>
                  <Form.Group controlId='formatText' className='mr-3 mb-0'>
                    <div className='text-right'>
                      <FontAwesomeIcon icon='align-left' size='3x' />
                    </div>
                    <Form.Check type='radio' name='format' label='Text' value='text' onChange={this.handleFormatChange} checked={formInputs.format === 'text'} />
                  </Form.Group>
                  <Form.Group controlId='formatImage' className='mb-0'>
                    <div className='text-right'>
                      <FontAwesomeIcon icon='image' size='3x' />
                    </div>
                    <Form.Check type='radio' name='format' label='Image' value='image' onChange={this.handleFormatChange} checked={formInputs.format === 'image'} />
                  </Form.Group>
                </div>
              </Form.Group>
              {
                formInputs.format === 'text' ? (
                  <>
                    <Form.Group>
                      <Form.Label>Title</Form.Label>
                      <Form.Control type='text' name='title' value={formInputs.text.title} onChange={this.handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <RichTextEditor.Editor onChange={this.handleDescriptionChange} value={formInputs.text.description} />
                    </Form.Group>
                    {/* <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control as='textarea' name='description' value={formInputs.text.description} onChange={this.handleInputChange} />
                    </Form.Group> */}
                    <Form.Group>
                      <Form.Label>Availability</Form.Label>
                      <Form.Control
                      as='select'
                      name='availability'
                      value={formInputs.text.availability}
                      onChange={this.handleInputChange} >
                        <option disabled hidden value=''>Availability...</option>
                        <option value='always'>Always</option>
                        <option value='from'>From...</option>
                        <option value='fromUntil'>From... Until...</option>
                        <option value='until'>Until...</option>
                      </Form.Control>
                    </Form.Group>
                    {this.renderAvailability()}
                    {this.renderAudience()}
                    <Form.Group>
                      <Form.Label>Attachment(s) <span className='font-italic text-muted'>(Optional)</span></Form.Label>
                      <Form.File 
                        id='add-file'
                        label={formInputs.text.fileNames.length > 0 ? (formInputs.text.fileNames.length + ` file${formInputs.text.fileNames.length > 1 ? 's' : ''} chosen`) : 'No file chosen'}
                        custom
                        onChange={this.handleFileUpload}
                        multiple
                      />
                    </Form.Group>
                    {this.renderFileUpload()}
                    {this.renderAttachedFiles()}
                  </>
                ) : (
                  <>
                    {
                      saveModal.isPreviewLoading ? (
                        <div className='text-center'>
                          <LoadingIcon />
                        </div>
                      ) : saveModal.preview ? (
                        <div style={{ height: '15rem', textAlign: 'center', marginBottom: '1rem' }}>
                          <Image
                            src={saveModal.preview}
                            style={{ objectFit: 'contain', height: '100%' }} fluid />
                        </div>
                      ) : saveModal.announcement &&
                          saveModal.announcement.format === 'Image' &&
                          saveModal.announcement.files &&
                          saveModal.announcement.files.length > 0 ? (
                        <div style={{ height: '15rem', textAlign: 'center', marginBottom: '1rem' }}>
                          <Image
                            src={`${process.env['REACT_APP_API_BASE_URL']}/announcement/${saveModal.announcement.id}/image/${saveModal.announcement.files[0].id}`}
                            style={{ objectFit: 'contain', height: '100%' }} fluid />
                        </div>
                      ) : null
                    }
                    <Form.Group>
                      <Form.File.Label>Image</Form.File.Label>
                      <Form.File 
                        id='image-upload'
                        label={formInputs.image.image ? formInputs.image.image : 'No file chosen'}
                        accept='image/png,image/jpeg'
                        onChange={this.handleImageChange}
                        custom
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Availability</Form.Label>
                      <Form.Control
                      as='select'
                      name='availability'
                      value={formInputs.image.availability}
                      onChange={this.handleInputChange} >
                        <option disabled hidden value=''>Availability...</option>
                        <option value='always'>Always</option>
                        <option value='from'>From...</option>
                        <option value='fromUntil'>From... Until...</option>
                        <option value='until'>Until...</option>
                      </Form.Control>
                    </Form.Group>
                    {this.renderAvailability()}
                    {this.renderAudience()}
                  </>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' onClick={this.hideSaveModal} disabled={saveModal.isLoading}>
                Cancel
              </Button>
              <Button variant='green' type='submit' disabled={saveModal.isLoading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <PromptDeleteModal
          {...deleteModal}
          title='Delete announcement'
          onHide={this.hideDeleteModal}
          onDelete={this.handleDelete}
          >
          {
            deleteModal.announcement && (
              <>
                <Card.Subtitle>Are you sure you want to delete the announcement?</Card.Subtitle>
                <Alert variant='light'>
                  {
                    deleteModal.announcement.format === 'Text' ? (
                      <>
                        <div>{deleteModal.announcement.title}</div>
                        <div className='text-truncate'>
                          {deleteModal.announcement.description}
                        </div>
                        {
                          (deleteModal.announcement.files && deleteModal.announcement.files.length > 0) && (
                            <div className='mt-1'>
                              <b>Attached files:</b>
                              <div className='ml-3'>
                              {
                                deleteModal.announcement.files.map(file => (
                                  <div className='d-flex'>
                                    <div className='mr-1'>-</div>
                                    <div style={{ wordBreak: 'break-all' }}>
                                      {file.file_title || file.title}
                                    </div>
                                  </div>
                                ))
                              }
                              </div>
                            </div>
                          )
                        }
                      </>
                    ) : (
                      <>
                        {
                          (deleteModal.announcement.files && deleteModal.announcement.files.length > 0) && (
                            <div style={{ height: '5rem', marginBottom: '.5rem' }}>
                              <Image src={`${process.env['REACT_APP_API_BASE_URL']}/announcement/${deleteModal.announcement.id}/image/${deleteModal.announcement.files[0].id}`} style={{ objectFit: 'contain', height: '100%' }} fluid thumbnail />
                            </div>
                          )
                        }
                      </>
                    )
                  }
                </Alert>
              </>
            )
          }
        </PromptDeleteModal>
      </div>
    );
  }
  render() {
    return (
      <Card className='mt-3'>
        <Card.Body>
          {this.renderContent()}
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(Announcement);