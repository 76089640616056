import React, { Component } from 'react';
import { Alert, Button, Carousel, Col, Image, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../actions';
import moment from 'moment';
import axiosRequest from '../../../util/helpers/axiosRequest';
import NameLink from '../NameLink/NameLink';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import './style.scss';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

class Announcements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      announcements: [],
      showModal: false
    };
  }
  componentDidMount() {
    axiosRequest('get', 'announcement', null, ({data: { data }}) => {
      this.setState({
        ...this.state,
        isLoading: false,
        announcements: data
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  retryFetch = () => {
    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: ''
    }, () => {
      axiosRequest('get', 'announcement', null, ({data: { data }}) => {
        this.setState({
          ...this.state,
          isLoading: false,
          announcements: data
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showModal = () => {
    this.setState({
      ...this.state,
      showModal: true
    });
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      showModal: false
    });
  }
  handleFileDownload = (announcementID, fileID) => {
    axiosRequest('get', `announcement/${announcementID}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handlePreviewImage = announcement => {
    let images = [
      {
        id: announcement.files[0].id,
        src: `${process.env['REACT_APP_API_BASE_URL']}/announcement/${announcement.id}/image/${announcement.files[0].id}`
      }
    ];
    this.props.showImagePreview(images);
  }
  handlePreviewImages = (announcement, images, index) => {
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/announcement/${announcement.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index, false, () => {}, 1100);
  }
  renderTextAnnouncement = announcement => {
    const images = announcement.files ? announcement.files.filter(file => {
      return !!file.is_image;
    }) : [];

    const files = announcement.files ? announcement.files.filter(file => {
      return !file.is_image;
    }) : [];

    return (
      <div>
        <div className='h5'>
          {
            announcement.title ? (
              <div>
                {announcement.title}
              </div>
            ) : 'Announcement'
          }
        </div>
        <RichTextEditor.Viewer body={announcement.description} />
        {/* <div style={{ whiteSpace: 'pre-line', minHeight: '5rem' }}>
          {announcement.description}
        </div> */}
        {
          (announcement.files && announcement.files.length > 0) && (
            <div className='announcement-actions'>
              <div className='font-weight-bold mt-3'>
                <FontAwesomeIcon icon='paperclip' /> Attachments
              </div>
              <div className='px-3'>
              {
                images.length > 0 && (
                  <Row>
                  {
                    images.map((image, index) => (
                      <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(announcement, images, index)}>
                        <Image src={`${process.env['REACT_APP_API_BASE_URL']}/announcement/${announcement.id}/image/${image.id}`}  thumbnail />
                      </Col>
                    ))
                  }
                  </Row>
                )
              }
              {
                files.length > 0 && (
                  <div className='p-1'>
                    {
                      files.map(file => (
                        <div key={file.id} className='d-flex mb-2'>
                          <div className='mr-2'>
                            <FontAwesomeIcon icon='file' className='text-primary' />
                          </div>
                          <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                            <span className='btn-link' onClick={() => this.handleFileDownload(announcement.id, file.id)}>
                              {file.file_title}
                            </span>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )
              }
              </div>
            </div>
          )
        }
        <div className='text-right font-weight-light font-italic text-muted align-self-end'>
          <div className='d-flex justify-content-end'>
            <NameLink
              {...announcement.profile}
              imageAlign='right'
              imageStyle={{ width: '5rem', height: '5rem' }}
              nameStyle={{ alignSelf: 'end', fontSize: '1.25rem' }}
              hideName
              enableTooltip={false}
              disableLink />
          </div>
          <div>
            {announcement.profile.name}
          </div>
          <div>
            <FontAwesomeIcon icon='clock' size='sm' />  {moment(announcement.created_at).format('MMM D, YYYY hh:mm A')}
          </div>
        </div>
      </div>
    );
  }
  renderImageAnnouncement = announcement => {
    if (!announcement.files || announcement.files.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <div>
        <div style={{ height: '15rem', textAlign: 'center', cursor: 'pointer' }} onClick={e => this.handlePreviewImage(announcement)}>
          <Image src={`${process.env['REACT_APP_API_BASE_URL']}/announcement/${announcement.id}/image/${announcement.files[0].id}`} style={{ height: '100%', objectFit: 'contain' }} fluid />
        </div>
        <div style={{ fontSize: '1rem' }} className='text-right font-italic font-weight-light text-muted'>
          <div className='d-flex justify-content-end'>
            <NameLink
              {...announcement.profile}
              imageAlign='right'
              imageStyle={{ width: '2rem', height: '2rem' }}
              nameStyle={{ alignSelf: 'end' }}
              disableLink />
          </div>
          <div>
            <FontAwesomeIcon icon='clock' size='sm' />  {moment(announcement.created_at).format('MMM D, YYYY hh:mm A')}
          </div>
        </div>
      </div>
    );
  }
  renderAnnouncements = () => {
    const { announcements, showModal } = this.state;

    if (!showModal || announcements.length === 0) {
      return null;
    }

    return (
      <div className='floating-announcements'>
        <Carousel
          prevIcon={<FontAwesomeIcon icon='chevron-left' className='text-primary' size='lg' />}
          nextIcon={<FontAwesomeIcon icon='chevron-right' className='text-primary' size='lg' />}
        >
          {
            announcements.map(a => (
              <Carousel.Item key={a.id}>
                {
                  a.format === 'Text' ? this.renderTextAnnouncement(a) : this.renderImageAnnouncement(a)
                }
              </Carousel.Item>
            ))
          }
        </Carousel>
      </div>
    );
  }
  renderButton = () => {
    const { announcements } = this.state;

    if (announcements.length === 0) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              No announcements
            </Tooltip>
          }
          trigger={['hover', 'focus']}>
          <Button variant='light' className='announcements-float shadow-lg'>
            <FontAwesomeIcon icon='bullhorn' />
          </Button>
        </OverlayTrigger>
      );
    }

    return (
      <Button variant='green' className='announcements-float shadow-lg' title='Announcements' onClick={this.showModal}>
        <span className='fa-layers fa-fw'>
          <FontAwesomeIcon icon='bullhorn' />
          <span className='fa-layers-counter fa-fw fa-2x bg-danger' style={{ transformOrigin: '1.875em -0.2875em'}}>
            {announcements.length}
          </span>
        </span>
      </Button>
    );
  }
  render() {
    const { isLoading, errorMessage, showModal } = this.state;
    const { currentUser } = this.props;

    if (isLoading) {
      return (
        <Button variant='light' className='announcements-float shadow-lg' disabled>
          <LoadingIcon /> <FontAwesomeIcon icon='bullhorn' />
        </Button>
      );
    }

    if (errorMessage) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              {errorMessage}
            </Tooltip>
          }
          trigger={['hover', 'focus']}>
          <Button variant='danger' className='announcements-float shadow-lg error-button' onClick={this.retryFetch}>
            <span className='notif-icon'><FontAwesomeIcon icon='exclamation' /> <FontAwesomeIcon icon='bullhorn' /></span> <span className='refresh-icon'><FontAwesomeIcon icon='redo' /> Retry</span>
          </Button>
        </OverlayTrigger>
      );
    }

    return (
      <>
        {this.renderButton()}
        <Modal show={showModal} onHide={this.hideModal} size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Announcements</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderAnnouncements()}
          </Modal.Body>
          <Modal.Footer>
            {
              (currentUser && (currentUser.account_type === 1 || currentUser.account_type === 2)) && (
                <a href={`/${currentUser.account_type === 1 ? 'school-admin' : 'faculty'}/announcement`} className='btn btn-green mr-auto'>
                  <FontAwesomeIcon icon='cog' />
                  <span className='ml-1 d-none d-md-inline-block'>Manage</span>
                </a>
              )
            }
            <Button variant='light' onClick={this.hideModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Announcements);