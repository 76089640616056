import React from 'react';
import { Alert } from 'react-bootstrap';

export default function File(props) {
  function student() {
    return (
      <>
        <div className='mb-3'>
          <div className='h4 text-primary'>Files</div>
          <Alert variant='warning'>
            Some file materials may be unavailable due to the restriction set by the faculty.
          </Alert>
          <div>
            <div className='font-weight-bold'>To view file materials:</div>
            <ol>
              <li>Click on the name of the material.</li>
              <li>Click on an image or file to download and save it to your device.</li>
              <li>Optionally, some browsers may open the image in a new tab. To download the image, <b>right-click</b> on the page and click <b>Save image as...</b></li>
            </ol>
          </div>
        </div>
      </>
    );
  }
  function faculty() {
    return (
      <>
        <div className='mb-3'>
          <div className='h4 text-primary'>Uploading a file</div>
          <div>
            <ol>
              <li>Click on <b>Add Material</b> at the top right corner of the material pane.</li>
              <li>Click <b>File</b> to open the form.</li>
              <li>Fill out the form.</li>
              <li>You may choose the date of availability of the file.</li>
              <li>Click on <b>Browse</b> to choose a file to upload &mdash; multiple files are also supported.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Updating a file</div>
          <div>
            <ol>
              <li>Click on the pencil icon at the right side of the file to be updated.</li>
              <li>Fill out the form.</li>
              <li>
                Clicking the <b>Show attachments</b> will show the currently attached file.
                You may delete the attached file by clicking on the trash icon at the right side of the file.
                Furthermore, to cancel deletion of the file, you may click on the ban icon at the right side of the file.
              </li>
              <li>Optionally, you may attach new files by clicking on <b>Browse</b>.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Deleting a file</div>
          <div>
            <ol>
              <li>Click on the trash icon at the right side of the link to be deleted.</li>
              <li>Click <b>Delete</b> from the prompt to confirm deletion.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Opening a file</div>
          <div>
            Clicking on the name of the material will open the file or files in a window.<br />
            An indication of the availability will show beside the material name.
          </div>
        </div>
      </>
    );
  }

  return props.accountType === 5 ? student() :
          props.accountType === 2 ? faculty() : null;
}