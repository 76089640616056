import React from 'react';
import { Alert } from 'react-bootstrap';

export default function Exam(props) {
  function student() {
    return (
      <>
        <div className='mb-3'>
          <div className='h4 text-primary'>Exams</div>
          <div>
            To <b>view</b> an exam, click on the name of the material.<br />
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Details</div>
          <div>
            <ol>
              <li><b>Title</b></li>
              <li><b>Grade category</b></li>
              <li><b>Description or instruction</b></li>
              <li><b>Date available</b> &mdash; answering an exam is limited inclusively in these dates</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Start answering</div>
          <div>
            To start an attempt, click on <b>Start answering</b>.
          </div>
          <div>
            <ul>
              <li>Before starting an attempt, please make sure you have a <b>stable Internet connection</b> and a <b>good battery power</b> to prevent wasting of attempts.</li>
              <li>Exam attempts are limited to <b>one (1)</b> only, so please make it count.</li>
              <li>Submissions after the given duration will not be saved.</li>
              <li><b>Do not close</b> the browser when an attempt has started.</li>
            </ul>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Submissions</div>
          <div>
            <ol>
              <li><b>Submission number</b></li>
              <li><b>Score</b> &mdash; system generated score or grade.</li>
              <li><b>Submission date</b></li>
            </ol>
          </div>
        </div>
      </>
    );
  }
  
  function faculty() {
    return (
      <>
        <div className='mb-3'>
          <div className='h4 text-primary'>Create an exam</div>
          <div>
            <ol>
              <li>Click on <b>Add Material</b> at the top right corner of the material pane.</li>
              <li>Click <b>Exam</b> to open the form.</li>
              <li>Fill out the form.</li>
              <li>The <b>from</b> date must be after the current date and time.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Update an exam</div>
          <Alert variant='warning'>
            Changes made will reflect back to the students' attempts.
          </Alert>
          <div>
            <ol>
              <li>Click on the pencil icon at the right side of the exam to be updated.</li>
              <li>Fill out the form.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Delete an exam</div>
          <Alert variant='warning'>
            Deleting an exam will also delete the students' attempts. Furthermore, grades will also be deleted.
          </Alert>
          <div>
            <ol>
              <li>Click on the trash icon at the right side of the exam to be deleted.</li>
              <li>Click <b>Delete</b> from the prompt to confirm deletion.</li>
            </ol>
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>View exam</div>
          <div>
            To <b>view</b> an exam, click on the name of the material.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Details</div>
          <div>
            The details area is also an editable form. Once a change was made, the reset and save buttons will show.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h4 text-primary'>Preview</div>
          <div>
            The preview area is the students' interface when they open the exam.
            You may also start answering by clicking on <b>Start answering</b>. However, your answers will not be saved.
          </div>
        </div>
        <div className='mb-3'>
          <div className='h5 text-primary'>Submissions</div>
          <div className='mb-3'>
            <div>
              Students' attempts are limited to one (1) only. <br />
              The submissions area will display all the students that has an attempt.
            </div>
            <ol>
              <li><b>Student</b></li>
              <li><b>Points</b> &mdash; system generated points based on the last attempt</li>
              <li><b>Grade</b> &mdash; overrideable grade</li>
            </ol>
          </div>
          <div className='font-weight-bold'>To the attempt of the student:</div>
          <div className='ml-3'>
            <ol>
              <li>Click on the eye icon at the right side of the submission.</li>
              <li>Attempt will display the questions, answers of the student, and the points they got.</li>
              <li>Optionally, you may override the points for each question by clicking the blue pencil icon at the right side of the question. However, if no answer was provided, the points cannot be overridden.</li>
            </ol>
          </div>
          <div className='font-weight-bold'>To override the grade for the attempt:</div>
          <div className='ml-3'>
            <ol>
              <li>Click on the pencil icon at the right side of the submission.</li>
              <li>Enter the grade to the grade field. Be aware of the maximum points you have set.</li>
              <li>Click <b>Save</b> to complete.</li>
            </ol>
          </div>
        </div>
      </>
    );
  }

  return props.accountType === 5 ? student() : 
          props.accountType === 2 ? faculty() : null;
}