import React, { Component } from 'react';
import { Alert, Button, Col, Form, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Validator from 'validatorjs';
import { loadRule } from '../../../../util';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import PromptDeleteModal from '../../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class GradeSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      categories: [],
      formInputCategory: {
        name: ''
      },
      isFormCategoryLoading: false,
      formCategoryError: '',
      deleteModal: {
        show: false,
        data: null,
        isLoading: false,
        errorMessage: ''
      }
    }
  }
  componentDidMount() {
    const { classID } = this.props.match.params;

    axiosRequest('get', `faculty/class/${classID}/grade/settings`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        categories: data.categories,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    });
    
    loadRule(['date', 'before_or_equal', 'after_or_equal']);
  }
  showDeleteModal = (id) => {
    const categories = [...this.state.categories].filter(d => d.id === id);

    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        data: categories[0],
        isLoading: false,
        errorMessage: ''
      }
    })
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        data: null,
        isLoading: false,
        errorMessage: ''
      }
    })
  }
  handleDelete = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { classID } = this.props.match.params;
      const { deleteModal } = this.state;

      axiosRequest('delete', `faculty/class/${classID}/grade/category/${deleteModal.data.id}`, null, ({ data: { message }}) => {
        const newCategories = [...this.state.categories].filter(d => d.id !== deleteModal.data.id);

        this.setState({
          ...this.state,
          deleteModal: {
            show: false,
            data: null,
            isLoading: false,
            errorMessage: ''
          },
          categories: newCategories
        });
      }, (error) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleAddCategory = event => {
    event.preventDefault();
    
    this.setState({
      ...this.state,
      isFormCategoryLoading: true,
      formCategoryError: ''
    }, () => {
      const { formInputCategory } = this.state;
      
      let validator = new Validator(formInputCategory, {
        name: 'required|min:3'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          isFormCategoryLoading: false,
          formCategoryError: validator.errors.errors[firstKey][0]
        });
        return;
      }

      const { classID } = this.props.match.params;
      axiosRequest('post', `faculty/class/${classID}/grade/category`, formInputCategory, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          formInputCategory: {
            name: ''
          },
          isFormCategoryLoading: false,
          categories: [
            ...this.state.categories,
            data
          ]
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isFormCategoryLoading: false,
          formCategoryError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleResetCategory = () => {
    this.setState({
      ...this.state,
      formInputCategory: {
        name: ''
      }
    });
  }
  handleCategoryInputChange = event => {
    this.setState({
      ...this.state,
      formInputCategory: {
        ...this.state.formInputCategory,
        [event.target.name]: event.target.value
      }
    });
  }
  renderCategories = () => {
    const { categories, formInputCategory, formCategoryError, isFormCategoryLoading } = this.state;
    return (
      <div className='mb-3'>
        <div className='h6'>
          Categories
        </div>
        <div className='dropdown-divider'></div>
        <Row className='px-2'>
          <Col md={6}>
            {
              formCategoryError && (
                <Alert variant='danger'>
                  {formCategoryError}
                </Alert>
              )
            }
            <Form onSubmit={this.handleAddCategory}>
              <Form.Group>
                <Form.Label>Category Name</Form.Label>
                <Form.Control type='text' size='sm' placeholder='e.g. Quiz' name='name' value={formInputCategory.name} onChange={this.handleCategoryInputChange} />
              </Form.Group>
              <Form.Group className='text-right'>
                <Button variant='danger' size='sm' className='mr-2' disabled={isFormCategoryLoading} onClick={this.handleResetCategory}>
                  Reset
                </Button>
                <Button variant='green' size='sm' type='submit' disabled={isFormCategoryLoading}>
                  <FontAwesomeIcon icon='plus' /> Add
                </Button>
              </Form.Group>
            </Form>
          </Col>
          <Col md={6}>
            <Table striped hover size='sm'>
              <thead>
                <tr>
                  <th>Category</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {
                categories.map(category => (
                  <tr key={category.id}>
                    <td>{category.name}</td>
                    <td className='text-right'>
                      <Button variant='danger' size='sm' onClick={e => this.showDeleteModal(category.id, 'category')}>
                        <FontAwesomeIcon icon='trash-alt' />
                      </Button>
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
  render() {
    const { isLoading, errorMessage, deleteModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        {this.renderCategories()}
        <PromptDeleteModal
          show={deleteModal.show}
          title='Delete category'
          errorMessage={deleteModal.errorMessage}
          onHide={this.hideDeleteModal}
          onDelete={this.handleDelete}
          >
          {
            deleteModal.data && (
              <>
                <b className='d-block'>Are you sure you want to delete the category?</b>
                <Alert variant='light'>
                  <div>{deleteModal.data.name}</div>
                </Alert>
              </>
            )
          }
        </PromptDeleteModal>
      </>
    );
  }
}