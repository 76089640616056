import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../actions';
import { Alert, Button, Card, Col, Dropdown, Form, Modal, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import axios from 'axios';
import Validator from 'validatorjs';
import EditModal from '../../../modals/EditModal/EditModal';
import PromptDeleteModal from '../../../modals/PromptDeleteModal/PromptDeleteModal';
import Comment from '../../../common/Comment/Comment';
import NameLink from '../../../common/NameLink/NameLink';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../common/RichTextEditor/RichTextEditor';
import PostImages from '../../../common/PostImages/PostImages';

class Discussion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: {
        data: []
      },
      message: '',
      isLoading: true,
      errorMessage: '',
      isFormLoading: false,
      formError: '',
      fileNames: [],
      loadingFiles: false,
      image: '',
      isNextPageLoading: false,
      nextPageError: '',
      editModal: {
        show: false,
        data: {
          id: '',
          message: '',
          attachedFiles: [],
          attachedLinks: []
        },
        isLoading: false,
        errorMessage: '',
        loadingFiles: false
      },
      deleteModal: {
        show: false,
        data: {
          id: '',
          message: '',
          attachedFiles: []
        },
        isLoading: false,
        errorMessage: ''
      },
      removeAttachmentModal: {
        show: false,
        data: {
          id: '',
          postID: '',
          fileName: '',
          type: ''
        },
        callback: () => {},
        errorMessage: ''
      },
      linkModal: {
        show: false,
        data: {
          title: '',
          url: ''
        },
        errorMessage: '',
        type: ''
      },
      links: [],
      editCommentModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        comment: null,
        message: ''
      },
      deleteCommentModal: {
        show: false,
        comment: null,
        message: '',
        isLoading: false,
        errorMessage: ''
      },
      comments: [],
      loadingLikes: []
    };

    this.files = [];
    this.editFiles = [];
  }
  componentDidMount() {
    const { classID } = this.props.match.params;
    axiosRequest('get', `class/${classID}/posts`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        posts: {...data},
        isLoading: false,
        errorMessage: ''
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { posts } = this.state;

      const path = posts.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          posts: {
            ...data,
            data: [
              ...this.state.posts.data,
              ...data.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showDeleteCommentModal = comment => {
    this.setState({
      ...this.state,
      deleteCommentModal: {
        show: true,
        isLoading: false,
        errorMessage: '',
        comment,
        message: comment.message
      }
    });
  }
  hideDeleteCommentModal = () => {
    this.setState({
      ...this.state,
      deleteCommentModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        comment: null,
        message: ''
      }
    });
  }
  handleDeleteComment = () => {
    this.setState({
      ...this.state,
      deleteCommentModal: {
        ...this.state.deleteCommentModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { classID } = this.props.match.params;
      const { deleteCommentModal } = this.state;

      axiosRequest('delete', `class/${classID}/posts/${deleteCommentModal.comment.post_id}/comment/${deleteCommentModal.comment.id}`, null, ({ data: { message }}) => {
        let post = [...this.state.posts.data].find(p => p.id === deleteCommentModal.comment.post_id);
        post.comments = post.comments.filter(comment => comment.id !== deleteCommentModal.comment.id);
        const newPosts = [...this.state.posts.data].map(p => {
          if (p.id === deleteCommentModal.comment.post_id) {
            return post;
          }

          return p;
        })
        this.setState({
          ...this.state,
          deleteCommentModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            comment: null,
            message: ''
          },
          posts: {
            ...this.state.posts,
            data: newPosts
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          deleteCommentModal: {
            ...this.state.deleteCommentModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showEditCommentModal = comment => {
    this.setState({
      ...this.state,
      editCommentModal: {
        show: true,
        isLoading: false,
        errorMessage: '',
        comment,
        message: comment.message
      }
    });
  }
  hideEditCommentModal = () => {
    this.setState({
      ...this.state,
      editCommentModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        comment: null,
        message: ''
      }
    });
  }
  handleEditCommentInputChange = event => {
    this.setState({
      ...this.state,
      editCommentModal: {
        ...this.state.editCommentModal,
        message: event.target.value
      }
    });
  }
  handleEditComment = event => {
    event.preventDefault();
    const { editCommentModal } = this.state;

    this.setState({
      ...this.state,
      editCommentModal: {
        ...editCommentModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      let validator = new Validator({
        comment: editCommentModal.message
      }, {
        comment: 'required'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          editCommentModal: {
            ...editCommentModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      const { match: { params: { classID } }, currentUser } = this.props;
      axiosRequest('patch', `class/${classID}/posts/${editCommentModal.comment.post_id}/comment/${editCommentModal.comment.id}`, {
        message: editCommentModal.message
      }, ({ data: { data }}) => {
        data.profile = currentUser.profile;
        let post = [...this.state.posts.data].find(p => p.id === editCommentModal.comment.post_id);
        post.comments = post.comments.map(comment => {
          if (comment.id === data.id) {
            return data;
          }

          return comment;
        });
        const newPosts = [...this.state.posts.data].map(p => {
          if (p.id === editCommentModal.comment.post_id) {
            return post;
          }

          return p;
        })
        this.setState({
          ...this.state,
          editCommentModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            comment: null,
            message: ''
          },
          posts: {
            ...this.state.posts,
            data: newPosts
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          editCommentModal: {
            ...this.state.editCommentModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showLinkModal = (event, type = 'add') => {
    this.setState({
      ...this.state,
      linkModal: {
        show: true,
        data: {
          title: '',
          url: ''
        },
        errorMessage: '',
        type
      }
    });
  }
  hideLinkModal = () => {
    this.setState({
      ...this.state,
      linkModal: {
        show: false,
        data: {
          title: '',
          url: '',
          type: ''
        },
        errorMessage: ''
      }
    });
  }
  handleLinkInputChange = event => {
    const { linkModal } = this.state;
    this.setState({
      ...this.state,
      linkModal: {
        ...linkModal,
        data: {
          ...linkModal.data,
          [event.target.name]: event.target.value
        }
      }
    });
  }
  attachLink = event => {
    event.preventDefault();

    const { linkModal } = this.state;

    this.setState({
      ...this.state,
      linkModal: {
        ...linkModal,
        errorMessage: ''
      }
    }, () => {
      let validator = new Validator(linkModal.data, {
        title: 'required|min:3',
        url: 'required|url'
      });
  
      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          linkModal: {
            ...linkModal,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      if (linkModal.type === 'add') {
        this.setState({
          ...this.state,
          linkModal: {
            show: false,
            errorMessage: '',
            data: {
              title: '',
              url: ''
            }
          },
          links: [
            ...this.state.links,
            {
              ...linkModal.data,
              isLoading: false,
              isError: false,
              isSuccess: false
            }
          ]
        });
      } else if (linkModal.type === 'edit') {
        this.setState({
          ...this.state,
          linkModal: {
            show: false,
            errorMessage: '',
            data: {
              title: '',
              url: ''
            }
          },
          editModal: {
            ...this.state.editModal,
            data: {
              ...this.state.editModal.data,
              attachedLinks: [
                ...this.state.editModal.data.attachedLinks,
                {
                  ...linkModal.data,
                  isLoading: false,
                  isError: false,
                  isSuccess: false
                }
              ]
            }
          }
        });
      }
    });
  }
  handleRemoveLinkUpload = id => {
    const { links } = this.state;

    const newLinks = links.filter((link, index) => index !== id);

    this.setState({
      ...this.state,
      links: newLinks
    });
  }
  handleInputChange = message => {
    this.setState({
      ...this.state,
      message,
    });
  }
  handleFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.files = [...this.files, ...files];
      this.setState({
        ...this.state,
        fileNames: [
          ...this.state.fileNames,
          ...filesState
        ]
      });
    }
  }
  handleRemoveUpload = id => {
    const { fileNames } = this.state;

    const newFileNames = fileNames.filter((fileName, index) => {
      return index !== id;
    });

    this.setState({
      ...this.state,
      fileNames: newFileNames
    }, () => {
      this.files = [...this.files].filter((file, index) => {
        return index !== id;
      });
    });
  }
  handlePost = event => {
    event.preventDefault();
    
    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: '',
      loadingFiles: true
    }, () => {
      const { match: { params: { classID } }, currentUser } = this.props;
      const { message, links } = this.state;
      
      axiosRequest('post', `class/${classID}/posts`, {
        message
      }, ({ data: { data }}) => {
        data = {
          ...data,
          profile: currentUser.profile
        };
        if (this.files.length > 0 || links.length > 0) {
          let fileRequests = [];
          for (let i = 0; i < this.files.length; i++) {
            fileRequests.push(this.uploadFile(i, classID, data.id));
          }
          for (let i = 0; i < links.length; i++) {
            fileRequests.push(this.uploadLink(i, classID, data.id));
          }
          Promise.all(fileRequests).then(result => {
            data.links = result.filter(res => res.link);
            data.files = result.filter(res => !res.link);
            this.setState({
              ...this.state,
              posts: {
                ...this.state.posts,
                data: [
                  data,
                  ...this.state.posts.data
                ]
              },
              isFormLoading: false,
              formError: '',
              loadingFiles: false,
              fileNames: [],
              links: [],
              message: ''
            }, () => {
              this.files = [];
            });
          }).catch(error => {
            this.setState({
              ...this.state,
              isFormLoading: false,
              formError: error,
              loadingFiles: false
            });
          });
        } else {
          this.setState({
            ...this.state,
            posts: {
              ...this.state.posts,
              data: [
                data,
                ...this.state.posts.data
              ]
            },
            isFormLoading: false,
            formError: '',
            loadingFiles: false,
            fileNames: [],
            links: [],
            message: ''
          });
        }
      }, (error) => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          loadingFiles: false
        });
      }, this.props.history);
    });
  }
  showDeleteModal = id => {
    const { posts } = this.state;

    const post = [...posts.data].filter(post => {
      return post.id === id;
    });

    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        data: {
          id,
          message: post[0].message,
          attachedFiles: [...post[0].files, ...post[0].links]
        },
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        data: {
          id: '',
          message: '',
          attachedFiles: []
        },
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleDelete = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { classID } = this.props.match.params;
      const { deleteModal } = this.state;

      axiosRequest('delete', `class/${classID}/posts/${deleteModal.data.id}`, null, ({ data: { message }}) => {
        const posts = [...this.state.posts.data].filter(post => {
          return post.id !== deleteModal.data.id
        });

        this.setState({
          ...this.state,
          posts: {
            ...this.state.posts,
            data: posts
          },
          deleteModal: {
            show: false,
            data: {
              id: '',
              message: '',
              attachedFiles: []
            },
            isLoading: false,
            errorMessage: ''
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showRemoveAttachmentModal = (postID, id, isLink = false, callback = () => {}) => {
    const post = [...this.state.posts.data].filter(post => {
      return post.id === postID;
    });

    if (isLink) {
      const link = post[0].links.filter(link => {
        return link.id === id;
      });
      
      this.setState({
        ...this.state,
        removeAttachmentModal: {
          show: true,
          data: {
            id,
            postID,
            fileName: link[0].title,
            type: 'link'
          },
          callback,
          errorMessage: ''
        }
      });
    } else {
      const file = post[0].files.filter(file => {
        return file.id === id;
      });
      
      this.setState({
        ...this.state,
        removeAttachmentModal: {
          show: true,
          data: {
            id,
            postID,
            fileName: file[0].file_title,
            type: 'file'
          },
          callback,
          errorMessage: ''
        }
      });
    }
    
  }
  hideRemoveAttachmentModal = () => {
    this.setState({
      ...this.state,
      removeAttachmentModal: {
        show: false,
        data: {
          id: '',
          postID: '',
          fileName: '',
          type: 'file'
        },
        callback: () => {},
        errorMessage: ''
      }
    });
  }
  handleAttachmentDelete = () => {
    const { classID } = this.props.match.params;
    const { removeAttachmentModal } = this.state;

    axiosRequest('delete', `class/${classID}/posts/${removeAttachmentModal.data.postID}/${removeAttachmentModal.data.type}/${removeAttachmentModal.data.id}`, null, ({ data: { message }}) => {
      const post = [...this.state.posts.data].filter(post => {
        return post.id === removeAttachmentModal.data.postID
      });

      if (removeAttachmentModal.data.type === 'file') {
        let files = [...post[0].files].filter(file => {
          return file.id !== removeAttachmentModal.data.id
        });
        post[0].files = files;
      } else if (removeAttachmentModal.data.type === 'link') {
        let links = [...post[0].links].filter(link => {
          return link.id !== removeAttachmentModal.data.id
        });
        post[0].links = links;
      }

      const posts = [...this.state.posts.data].map(p => {
        if (p.id === removeAttachmentModal.data.postID) {
          return post[0];
        }

        return p;
      });

      this.setState({
        ...this.state,
        posts: {
          ...this.state.posts,
          data: posts
        },
        removeAttachmentModal: {
          ...this.state.removeAttachmentModal,
          show: false,
          data: {
            id: '',
            postID: '',
            fileName: '',
            type: ''
          },
          errorMessage: ''
        }
      });
    }, (error) => {
      this.setState({
        ...this.state,
        removeAttachmentModal: {
          ...this.state.removeAttachmentModal,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleEditInputChange = message => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        data: {
          ...this.state.editModal.data,
          message
        }
      }
    });
  }
  handleEditFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.editFiles = [...this.editFiles, ...files];
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          data: {
            ...this.state.editModal.data,
            attachedFiles: [
              ...this.state.editModal.data.attachedFiles,
              ...filesState
            ]
          }
        }
      });
    }
  }
  handleEditRemoveUpload = id => {
    const { editModal } = this.state;

    const newFileNames = editModal.data.attachedFiles.filter((fileName, index) => {
      return index !== id;
    });

    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        data: {
          ...this.state.editModal.data,
          attachedFiles: newFileNames
        }
      }
    }, () => {
      this.editFiles = [...this.editFiles].filter((file, index) => {
        return index !== id;
      });
    });
  }
  handleEditRemoveLinkUpload = id => {
    const { editModal } = this.state;

    const newLinks = editModal.data.attachedLinks.filter((link, index) => {
      return index !== id;
    });

    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        data: {
          ...this.state.editModal.data,
          attachedLinks: newLinks
        }
      }
    });
  }
  showEditModal = id => {
    const { posts } = this.state;

    const post = [...posts.data].filter(post => {
      return post.id === id;
    });

    this.setState({
      ...this.state,
      editModal: {
        show: true,
        data: {
          id,
          message: post[0].message,
          attachedFiles: [],
          attachedLinks: []
        },
        isLoading: false,
        errorMessage: '',
        loadingFiles: false
      }
    });
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        data: {
          id: '',
          message: '',
          attachedFiles: [],
          attachedLinks: []
        },
        isLoading: false,
        errorMessage: '',
        loadingFiles: false
      }
    }, () => {
      this.editFiles = [];
    });
  }
  handleEdit = () => {
    const editModal = {...this.state.editModal};

    editModal.isLoading = true;
    editModal.errorMessage = '';
    editModal.loadingFiles = true;

    this.setState({
      ...this.state,
      editModal
    }, () => {
      const { classID } = this.props.match.params;
      const { editModal } = this.state;
      
      axiosRequest('patch', `class/${classID}/posts/${editModal.data.id}`, {
        message: editModal.data.message
      }, ({ data: { data }}) => {

        if (this.editFiles.length > 0 || editModal.data.attachedLinks.length > 0) {
          let fileRequests = [];
          for (let i = 0; i < this.editFiles.length; i++) {
            fileRequests.push(this.uploadEditFile(i, classID, data.id));
          }
          for (let i = 0; i < editModal.data.attachedLinks.length; i++) {
            fileRequests.push(this.uploadEditLink(i, classID, data.id));
          }
          Promise.all(fileRequests).then(result => {
            const post = [...this.state.posts.data].filter(post => {
              return post.id === data.id;
            });
            post[0].message = data.message;
            let links = result.filter(res => res.link);
            let files = result.filter(res => !res.link);

            post[0].links = [
              ...post[0].links,
              ...links
            ];
            post[0].files = [
              ...post[0].files,
              ...files
            ];
            const posts = [...this.state.posts.data].map(p => {
              if (p.id === data.id) {
                return post[0];
              }

              return p;
            });

            this.setState({
              ...this.state,
              posts: {
                ...this.state.posts,
                data: posts
              },
              editModal: {
                show: false,
                data: {
                  id: '',
                  message: '',
                  attachedFiles: [],
                  attachedLinks: []
                },
                isLoading: false,
                errorMessage: '',
                loadingFiles: false
              }
            }, () => {
              this.editFiles = [];
            });
          }).catch(error => {
            this.setState({
              ...this.state,
              editModal: {
                ...this.state.editModal,
                isLoading: false,
                errorMessage: error,
                loadingFiles: false
              }
            })
          });
        } else {
          const post = [...this.state.posts.data].filter(post => {
            return post.id === data.id;
          });
          post[0].message = data.message;
          const posts = [...this.state.posts.data].map(p => {
            if (p.id === data.id) {
              return post[0];
            }

            return p;
          });
          this.setState({
            ...this.state,
            posts: {
              ...this.state.posts,
              data: posts
            },
            editModal: {
              show: false,
              data: {
                id: '',
                message: '',
                attachedFiles: [],
                attachedLinks: []
              },
              isLoading: false,
              errorMessage: '',
              loadingFiles: false
            }
          });
        }
      }, (error) => {
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
            loadingFiles: false
          }
        });
      }, this.props.history);
    });
  }
  handleLike = id => {
    const { currentUser } = this.props;
    this.setState({
      ...this.state,
      loadingLikes: [
        ...this.state.loadingLikes,
        id
      ]
    }, () => {
      const { classID } = this.props.match.params;
      axiosRequest('post', `class/${classID}/posts/like`, {
        postID: id
      }, ({ data: { data }}) => {
        const post = [...this.state.posts.data].filter(post => {
          return post.id === id;
        });

        if (data) {
          post[0].likes = [
            ...post[0].likes,
            data
          ];
        } else {
          post[0].likes = post[0].likes.filter(like => like.account_id !== currentUser.id);
        }

        const posts = [...this.state.posts.data].map(p => {
          if (p.id === id) {
            return post[0];
          }

          return p;
        });

        let loadingLikes = [...this.state.loadingLikes];
        loadingLikes.splice(loadingLikes.indexOf(id), 1);
        
        this.setState({
          ...this.state,
          posts: {
            ...this.state.posts,
            data: posts
          },
          loadingLikes
        });
      }, (error) => {
        // console.log(error);
      }, this.props.history);
    });
  }
  handleComment = (id, comment, callback) => {
    const { match: { params: { classID } }, currentUser } = this.props;

    axiosRequest('post', `class/${classID}/posts/${id}/comment`, {
      message: comment
    }, ({ data: { data }}) => {
      data.profile = currentUser.profile;
      const post = [...this.state.posts.data].find(p => p.id === id);

      post.comments = [
        ...post.comments,
        data
      ];

      const posts = [...this.state.posts.data].map(p => {
        if (p.id === id) {
          return post;
        }

        return p;
      });

      this.setState({
        ...this.state,
        posts: {
          ...this.state.posts,
          data: posts
        }
      }, callback);
    }, (error) => {
      callback(error);
    }, this.props.history);
  }
  uploadFile = (i, classID, postID) => {
    const formData = new window.FormData();
    formData.append('file', this.files[i], this.files[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/posts/${postID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { fileNames } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          fileNames
        });
      }
    }).then(({ data: { data }}) => {
      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        fileNames
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        fileNames
      });

      throw fileNames[i].isError;
    });
  }
  uploadLink = (i, classID, postID) => {
    const { links } = this.state;

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/posts/${postID}/link`, {
      title: links[i].title,
      url: links[i].url
    }, {
      withCredentials: true,
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        links[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          links
        });
      }
    }).then(({ data: { data }}) => {
      links[i].isLoading = false;
      links[i].isSuccess = true;
      this.setState({
        ...this.state,
        links
      });
      return { ...data, link: true };
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      links[i].isLoading = false;
      links[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        links
      });

      throw links[i].isError;
    });
  }
  uploadEditFile = (i, classID, postID) => {
    const formData = new window.FormData();
    formData.append('file', this.editFiles[i], this.editFiles[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/posts/${postID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const editModal = {...this.state.editModal};
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        editModal.data.attachedFiles[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          editModal
        });
      }
    }).then(({ data: { data }}) => {
      const editModal = {...this.state.editModal};
      editModal.data.attachedFiles[i].isLoading = false;
      editModal.data.attachedFiles[i].isSuccess = true;
      this.setState({
        ...this.state,
        editModal
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const editModal = {...this.state.editModal};
      editModal.data.attachedFiles[i].isLoading = false;
      editModal.data.attachedFiles[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        editModal
      });
      throw editModal.data.attachedFiles[i].isError;
    });
  }
  uploadEditLink = (i, classID, postID) => {
    const { editModal: { data: { attachedLinks } } } = this.state;

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/posts/${postID}/link`, {
      title: attachedLinks[i].title,
      url: attachedLinks[i].url
    }, {
      withCredentials: true,
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        attachedLinks[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            data: {
              ...this.state.editModal.data,
              attachedLinks
            }
          }
        });
      }
    }).then(({ data: { data }}) => {
      attachedLinks[i].isLoading = false;
      attachedLinks[i].isSuccess = true;
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          data: {
            ...this.state.editModal.data,
            attachedLinks
          }
        }
      });
      return { ...data, link: true };
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      attachedLinks[i].isLoading = false;
      attachedLinks[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          data: {
            ...this.state.editModal.data,
            attachedLinks
          }
        }
      });

      throw attachedLinks[i].isError;
    });
  }
  handleFileDownload = (post, fileID) => {
    axiosRequest('get', `class/${post.faculty_load_id}/posts/${post.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handleShowImagePreview = (e, post, images, activeIndex) => {
    e.preventDefault();
    images = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/class/${post.faculty_load_id}/posts/${post.id}/image/${image.id}`
    }));
    this.props.showImagePreview(images, activeIndex);
  }
  handleDeleteImage = (postID, imageID, callback = () => {}) => {
    this.showRemoveAttachmentModal(postID, imageID, false, callback);
  }
  renderPosts = () => {
    const { posts, loadingLikes, isLoading, errorMessage, isNextPageLoading, nextPageError } = this.state;
    const { currentUser } = this.props;
    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (posts.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }
    
    return (
      <div className='posts-container mx-n4 px-4 mt-n4 pt-4 mb-n4 pb-4'>
      {
        posts.data.map(post => {
          const images = post.files.filter(file => {
            return !!file.is_image;
          });

          const files = post.files.filter(file => {
            return !file.is_image;
          });

          const activeLike = post.likes.filter(l=>l.account_id===currentUser.id).length > 0;
          const loadingLike = loadingLikes.indexOf(post.id) !== -1;

          let likeDisplay = '';
          if (activeLike) {
            const others = post.likes.filter(l=>l.account_id!==currentUser.id);
            likeDisplay = `You${others.length > 0 ? (` and ${others.length > 1 ? `${others.length} others` : others[0].profile.name}`) : ''} liked this`;
          } else if(post.likes.length > 0) {
            likeDisplay = `${post.likes.length > 1 ? post.likes.length : post.likes[0].profile.name} liked this`;
          }

          return (
            <Card key={post.id} className='mb-3 shadow-sm'>
              <Card.Body>
                <div className='post-header'>
                  <div>
                    <b className='d-block'>
                      <NameLink
                        id={post.profile.id}
                        name={post.profile.name}
                        image={post.profile.image}
                        learningPlatform={post.profile.student_learning_platform ? post.profile.student_learning_platform.platform : null}
                        rank={post.profile.rank} />
                    </b>
                  </div>
                  {
                    (currentUser && (currentUser.id === post.account_id || currentUser.account_type === 2)) && (
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle variant='link' id='postMenu' size='sm'/>

                          <Dropdown.Menu>
                            {
                              (currentUser.account_type === 2 && currentUser.id === post.account_id) && (
                                <Dropdown.Item onClick={e => this.showEditModal(post.id)}>Edit</Dropdown.Item>
                              )
                            }
                            <Dropdown.Item onClick={e => this.showDeleteModal(post.id)}>Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )
                  }
                </div>
                <div className='dropdown-divider'></div>
                <small className='d-block text-right font-italic' style={{ color: '#6c757d' }}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {moment(post.created_at).format('MMM D, YYYY hh:mm A')}
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <FontAwesomeIcon icon='clock' />
                  </OverlayTrigger> {moment(post.created_at).fromNow()}
                </small>
                <div className='mb-1'>
                  <RichTextEditor.Viewer body={post.message} />
                </div>
                {/* <div className='mb-1' style={{ whiteSpace: 'pre-line' }}>
                  {post.message}
                </div> */}
                {
                  post.links.length > 0 && (
                    <div className='p-1'>
                      {
                        post.links.map(link => (
                          <small key={link.id} className='d-flex mb-2'>
                            <div className='mr-2'>
                              <FontAwesomeIcon icon='link' className='text-primary' />
                            </div>
                            <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                              <a href={link.url} target='_blank' rel='noopener noreferrer'>
                                {link.title} <FontAwesomeIcon icon='external-link-alt' />
                              </a>
                            </div>
                            {
                              (currentUser && (currentUser.id === post.account_id || currentUser.account_type === 2)) && (
                                <div className='ml-2'>
                                  <div className='delete-attachment' title='Remove attachment' onClick={() => this.showRemoveAttachmentModal(post.id, link.id, true)}>
                                    <FontAwesomeIcon icon='times' />
                                  </div>
                                </div>
                              )
                            }
                          </small>
                        ))
                      }
                    </div>
                  )
                }
                {/* {
                  images.length > 0 && (
                    <Row noGutters>
                    {
                      images.map(image => (
                        <Col key={image.id} xs={4} className='image-container'>
                          {
                            (currentUser && (currentUser.id === post.account_id || currentUser.account_type === 2)) && (
                              <div className='image-delete-icon' title='Remove image' onClick={() => this.showRemoveAttachmentModal(post.id, image.id)}>
                                <FontAwesomeIcon icon='times' size='sm' />
                              </div>
                            )
                          }
                          <a href={`${process.env['REACT_APP_API_BASE_URL']}/class/${post.faculty_load_id}/posts/${post.id}/image/${image.id}`} target='_blank' rel='noopener noreferrer'>
                            <Image className='post-image' src={`${process.env['REACT_APP_API_BASE_URL']}/class/${post.faculty_load_id}/posts/${post.id}/image/${image.id}`} thumbnail />
                          </a>
                        </Col>
                      ))
                    }
                    </Row>
                  )
                } */}
                {
                  files.length > 0 && (
                    <div className='p-1'>
                      {
                        files.map(file => (
                          <small key={file.id} className='d-flex mb-2'>
                            <div className='mr-2'>
                              <FontAwesomeIcon icon='file' className='text-primary' />
                            </div>
                            <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                              <span className='btn-link' onClick={() => this.handleFileDownload(post, file.id)}>
                                {file.file_title}
                              </span>
                            </div>
                            {
                              (currentUser && (currentUser.id === post.account_id || currentUser.account_type === 2)) && (
                                <div className='ml-2'>
                                  <div className='delete-attachment' title='Remove attachment' onClick={() => this.showRemoveAttachmentModal(post.id, file.id)}>
                                    <FontAwesomeIcon icon='times' />
                                  </div>
                                </div>
                              )
                            }
                          </small>
                        ))
                      }
                    </div>
                  )
                }
                {
                  images.length > 0 && (
                    <PostImages
                      images={images.map(image => ({
                        id: image.id,
                        src: `${process.env['REACT_APP_API_BASE_URL']}/class/${post.faculty_load_id}/posts/${post.id}/image/${image.id}`
                      }))}
                      onDelete={(imageID, callback) => this.handleDeleteImage(post.id, imageID, callback)}
                      deletable={(currentUser && (currentUser.id === post.account_id || currentUser.account_type === 2))} />
                  )
                }
                {/* {
                  images.length > 0 && (
                    <Row noGutters>
                    {
                      images.map((image, i) => (
                        <Col key={image.id} xs={4} className='image-container'>
                          {
                            (currentUser && (currentUser.id === post.account_id || currentUser.account_type === 2)) && (
                              <div className='image-delete-icon' title='Remove image' onClick={() => this.showRemoveAttachmentModal(post.id, image.id)}>
                                <FontAwesomeIcon icon='times' size='sm' />
                              </div>
                            )
                          }
                          <a href='#' onClick={e => this.handleShowImagePreview(e, post, images, i) }>
                            <Image className='post-image' src={`${process.env['REACT_APP_API_BASE_URL']}/class/${post.faculty_load_id}/posts/${post.id}/image/${image.id}`} thumbnail />
                          </a>
                        </Col>
                      ))
                    }
                    </Row>
                  )
                } */}
              </Card.Body>
              <Card.Footer className='px-3 py-1 text-right'>
                {
                  likeDisplay && (
                    <small className='mr-2 font-italic'>
                      {likeDisplay}
                    </small>
                  )
                }
                <Button
                  className='like-button'
                  variant='light'
                  size='sm'
                  onClick={e => this.handleLike(post.id)}
                  active={activeLike}
                  disabled={loadingLike}>
                  <FontAwesomeIcon icon={[(activeLike ? 'fa' : 'far'), 'smile-beam']} className='mr-1' />
                  Like
                </Button>
              </Card.Footer>
              <div className='border-top comments-section'>
                {
                  post.comments.map(comment => (
                    <div key={comment.id} className='border-bottom py-1 px-3'>
                      <div>
                        <div className='d-flex justify-content-between'>
                          <b className='flex-fill'>
                            <NameLink
                              id={comment.account_id}
                              name={comment.profile.name}
                              image={comment.profile.image}
                              learningPlatform={comment.profile.student_learning_platform ? comment.profile.student_learning_platform.platform : null}
                              rank={comment.profile.rank} />
                          </b>
                          <div className='ml-2 align-self-center'>
                            <small className='d-block text-right font-italic' style={{ color: '#6c757d' }}>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip>
                                    {moment(comment.created_at).format('MMM D, YYYY hh:mm A')}
                                  </Tooltip>
                                }
                                trigger={['hover', 'focus']}>
                                <FontAwesomeIcon icon='clock' />
                              </OverlayTrigger> {moment(comment.created_at).fromNow()}
                            </small>
                          </div>
                          {
                            (currentUser && currentUser.id === comment.account_id) && (
                              <div className='ml-2 align-self-center'>
                                <Dropdown>
                                  <Dropdown.Toggle variant='link' id='postMenu' size='sm'/>

                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={e => this.showEditCommentModal(comment)}>Edit</Dropdown.Item>
                                    <Dropdown.Item onClick={e => this.showDeleteCommentModal(comment)}>Delete</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )
                          }
                        </div>
                        <div className='ml-5' style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}>
                          {comment.message}
                        </div>
                      </div>
                      {/* <div className='text-right'>
                        <Button
                          className='like-button'
                          variant='light'
                          size='sm'
                          title='Like comment'
                          // onClick={e => this.handleLike(post.id)}
                          // active={activeLike}
                          // disabled={loadingLike}
                          >
                          <FontAwesomeIcon icon={['far', 'smile-beam']} />
                        </Button>
                      </div> */}
                    </div>
                  ))
                }
                <div className='p-2'>
                  <Comment
                    postID={post.id}
                    onComment={this.handleComment}
                  />
                </div>
              </div>
            </Card>
          );
        })
      }
      {
        posts.next_page_url ? (
          <div className='text-center'>
            {
              isNextPageLoading && (
                <LoadingIcon className='mr-2' sm />
              )
            }
            <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
              View more posts
              <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
            </span>
            {
              nextPageError && (
                <Alert variant='danger'>
                  {nextPageError}
                </Alert>
              )
            }
          </div>
        ) : (
          <Alert variant='light' className='text-center'>
            No more posts.
          </Alert>
        )
      }
      </div>
    );
  }
  renderFileUpload = () => {
    const { fileNames, loadingFiles, links } = this.state;
    if (fileNames.length > 0 || links.length > 0) {
      return (
        <small className='file-upload-display d-block'>
          {
            fileNames.map((fileName, index) => (
              <div key={index} className='file-name d-flex'>
                <div>
                  {
                    fileName.isLoading || (loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                      <LoadingIcon />
                    ) : fileName.isError ? (
                      <FontAwesomeIcon icon='times-circle' className='text-danger' />
                    ) : fileName.isSuccess ? (
                      <FontAwesomeIcon icon='check-circle' className='text-green' />
                    ) : (
                      <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                    )
                  }
                </div>
                <div className='ml-2 flex-fill'>
                  <div>
                    {fileName.name}
                  </div>
                  {
                    fileName.isLoading && (
                      <div className='mt-1'>
                        <ProgressBar now={fileName.isLoading} />
                      </div>
                    )
                  }
                </div>
                {
                  (!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !loadingFiles) && (
                    <div className='ml-2 align-items-center d-flex'>
                      <Button
                        variant='link'
                        className='text-danger'
                        size='sm'
                        title='Remove'
                        onClick={(e) => this.handleRemoveUpload(index)}>
                        <FontAwesomeIcon icon='times' size='sm' />
                      </Button>
                    </div>
                  )
                }
              </div>
            ))
          }
          {
            links.map((link, index) => (
              <div key={index} className='file-name d-flex'>
                <div>
                  {
                    link.isLoading || (loadingFiles && (!link.isError && !link.isSuccess)) ? (
                      <LoadingIcon />
                    ) : link.isError ? (
                      <FontAwesomeIcon icon='times-circle' className='text-danger' />
                    ) : link.isSuccess ? (
                      <FontAwesomeIcon icon='check-circle' className='text-green' />
                    ) : (
                      <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                    )
                  }
                </div>
                <div className='ml-2 flex-fill'>
                  <div>
                    <a href={link.url} target='_blank' rel='noopener noreferrer'>{link.title} <FontAwesomeIcon icon='external-link-alt' /></a>
                  </div>
                  {
                    link.isLoading && (
                      <div className='mt-1'>
                        <ProgressBar now={link.isLoading} />
                      </div>
                    )
                  }
                </div>
                {
                  (!link.isLoading && !link.isError && !link.isSuccess && !loadingFiles) && (
                    <div className='ml-2 align-items-center d-flex'>
                      <Button
                        variant='link'
                        className='text-danger'
                        size='sm'
                        title='Remove'
                        onClick={(e) => this.handleRemoveLinkUpload(index)}>
                        <FontAwesomeIcon icon='times' size='sm' />
                      </Button>
                    </div>
                  )
                }
              </div>
            ))
          }
        </small>
      );
    }
  }
  render() {
    const {
      message,
      isFormLoading,
      formError,
      editModal,
      deleteModal,
      removeAttachmentModal,
      linkModal,
      editCommentModal,
      deleteCommentModal
    } = this.state;
    const { currentUser } = this.props;
    return (
      <>
        {
          formError && (
            <Alert variant='danger'>
              {formError}
            </Alert>
          )
        }
        {
          currentUser && currentUser.account_type === 2 && (
            <>
              <Form onSubmit={this.handlePost} className='rich-text-editor-create-post'>
                <RichTextEditor.Editor
                  onChange={this.handleInputChange}
                  value={message}
                  readOnly={isFormLoading}
                  placeholder='Something to discuss...' />
                {/* <Form.Control
                  as='textarea'
                  rows='4'
                  style={{ resize: 'none' }}
                  className={fileNames.length > 0 ? 'has-file-upload' : ''}
                  placeholder='Something to discuss...'
                  name='message'
                  value={message}
                  onChange={this.handleInputChange}
                  readOnly={isFormLoading} /> */}
                { this.renderFileUpload() }
                <Row className='mt-1'>
                  <Col>
                    <div className='d-flex'>
                      <div className='border-right border-left'>
                        <label className={`btn btn-light btn-sm m-0 ${isFormLoading ? 'disabled' : ''}`} htmlFor='add-file' title='File'>
                          <FontAwesomeIcon icon='file-upload' />
                        </label>
                        <Form.File className='d-none' id='add-file' onChange={this.handleFileUpload} multiple disabled={isFormLoading} />
                      </div>
                      {/* <div className='border-right'>
                        <Button variant='light' size='sm' disabled={isFormLoading} title='Link' onClick={this.showLinkModal}>
                          <FontAwesomeIcon icon='link' />
                        </Button>
                      </div> */}
                    </div>
                  </Col>
                  <Col xs={3}>
                    <Button type='submit' variant='green' size='sm' block disabled={isFormLoading}>
                      Post
                    </Button>
                  </Col>
                </Row>
              </Form>
              <div className='dropdown-divider' style={{ marginBottom: '2rem' }}></div>
            </>
          )
        }
        { this.renderPosts() }
        <EditModal
          {...editModal}
          title='Edit post'
          onHide={this.hideEditModal}
          onEdit={this.handleEdit}>
          <div className='rich-text-editor-create-post'>
            <RichTextEditor.Editor
              onChange={this.handleEditInputChange}
              value={editModal.data.message}
              readOnly={editModal.isLoading}
              placeholder='Something to discuss...' />
          </div>
          {/* <Form.Control
            as='textarea'
            rows='4'
            style={{ resize: 'none' }}
            className={editModal.data.attachedFiles.length > 0 ? 'has-file-upload' : ''}
            placeholder='Something to discuss...'
            value={editModal.data.message}
            onChange={this.handleEditInputChange}
            readOnly={editModal.isLoading}
            /> */}
            {
              editModal.data.attachedFiles.length > 0 && (
                <small className='file-upload-display d-block'>
                {
                  editModal.data.attachedFiles.map((fileName, index) => (
                    <div key={index} className='file-name d-flex'>
                      <div>
                        {
                          fileName.isLoading || (editModal.loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                            <LoadingIcon />
                          ) : fileName.isError ? (
                            <FontAwesomeIcon icon='times-circle' className='text-danger' />
                          ) : fileName.isSuccess ? (
                            <FontAwesomeIcon icon='check-circle' className='text-green' />
                          ) : (
                            <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                          )
                        }
                      </div>
                      <div className='ml-2 flex-fill'>
                        <div>
                          {fileName.name}
                        </div>
                        {
                          fileName.isLoading && (
                            <div className='mt-1'>
                              <ProgressBar now={fileName.isLoading} />
                            </div>
                          )
                        }
                      </div>
                      {
                        (!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !editModal.loadingFiles) && (
                          <div className='ml-2 align-items-center d-flex'>
                            <Button
                              variant='link'
                              className='text-danger'
                              size='sm'
                              title='Remove'
                              onClick={(e) => this.handleEditRemoveUpload(index)}>
                              <FontAwesomeIcon icon='times' size='sm' />
                            </Button>
                          </div>
                        )
                      }
                    </div>
                  ))
                }
                </small>
              )
            }
            {
              editModal.data.attachedLinks.length > 0 && (
                <small className='file-upload-display d-block'>
                {
                  editModal.data.attachedLinks.map((link, index) => (
                    <div key={index} className='file-name d-flex'>
                      <div>
                        {
                          link.isLoading || (editModal.loadingFiles && (!link.isError && !link.isSuccess)) ? (
                            <LoadingIcon />
                          ) : link.isError ? (
                            <FontAwesomeIcon icon='times-circle' className='text-danger' />
                          ) : link.isSuccess ? (
                            <FontAwesomeIcon icon='check-circle' className='text-green' />
                          ) : (
                            <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                          )
                        }
                      </div>
                      <div className='ml-2 flex-fill'>
                        <div>
                          <a href={link.url} target='_blank' rel='noopener noreferrer'>{link.title} <FontAwesomeIcon icon='external-link-alt' /></a>
                        </div>
                        {
                          link.isLoading && (
                            <div className='mt-1'>
                              <ProgressBar now={link.isLoading} />
                            </div>
                          )
                        }
                      </div>
                      {
                        (!link.isLoading && !link.isError && !link.isSuccess && !editModal.loadingFiles) && (
                          <div className='ml-2 align-items-center d-flex'>
                            <Button
                              variant='link'
                              className='text-danger'
                              size='sm'
                              title='Remove'
                              onClick={(e) => this.handleEditRemoveLinkUpload(index)}>
                              <FontAwesomeIcon icon='times' size='sm' />
                            </Button>
                          </div>
                        )
                      }
                    </div>
                  ))
                }
                </small>
              )
            }
            <div className='d-flex mt-1'>
              <div className='border-right border-left'>
                <label className={`btn btn-light btn-sm m-0 ${editModal.isLoading ? 'disabled' : ''}`} htmlFor='add-file-edit' title='File'>
                  <FontAwesomeIcon icon='file-upload' />
                </label>
                <Form.File className='d-none' id='add-file-edit' multiple onChange={this.handleEditFileUpload} disabled={editModal.isLoading} />
              </div>
              {/* <div className='border-right'>
                <Button variant='light' size='sm' disabled={isFormLoading} title='Link' onClick={e => this.showLinkModal(e, 'edit')}>
                  <FontAwesomeIcon icon='link' />
                </Button>
              </div> */}
            </div>
        </EditModal>
        <PromptDeleteModal
          {...deleteModal}
          title='Delete post'
          onHide={this.hideDeleteModal}
          onDelete={this.handleDelete}
          >
          <Card.Subtitle>Are you sure you want to delete the post?</Card.Subtitle>
          <Alert variant='light'>
            <div>
              <RichTextEditor.Viewer body={deleteModal.data.message} />
            </div>
            {
              deleteModal.data.attachedFiles.length > 0 && (
                <div className='mt-1'>
                  <b>Attached files:</b>
                  <div className='ml-3'>
                  {
                    deleteModal.data.attachedFiles.map(file => (
                      <div className='d-flex'>
                        <div className='mr-1'>-</div>
                        <div style={{ wordBreak: 'break-all' }}>
                          {file.file_title || file.title}
                        </div>
                      </div>
                    ))
                  }
                  </div>
                </div>
              )
            }
          </Alert>
        </PromptDeleteModal>
        <PromptDeleteModal
          {...removeAttachmentModal}
          title='Delete attachment'
          onHide={this.hideRemoveAttachmentModal}
          onDelete={this.handleAttachmentDelete}
          onExited={removeAttachmentModal.callback}
          >
          <Card.Subtitle>Are you sure you want to delete the attachment?</Card.Subtitle>
          <Alert variant='light'>
            <div style={{ wordBreak: 'break-all' }}>{removeAttachmentModal.data.fileName}</div>
          </Alert>
        </PromptDeleteModal>
        <Modal show={linkModal.show} onHide={this.hideLinkModal}>
          <Modal.Header closeButton>
            <Modal.Title>Attach a link</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.attachLink}>
            <Modal.Body>
              {
                linkModal.errorMessage && (
                  <Alert variant='danger'>
                    {linkModal.errorMessage}
                  </Alert>
                )
              }
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control type='text' name='title' value={linkModal.data.title} onChange={this.handleLinkInputChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>URL</Form.Label>
                <Form.Control type='text' name='url' value={linkModal.data.url} onChange={this.handleLinkInputChange} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' className='mr-2' onClick={this.hideLinkModal}>
                Cancel
              </Button>
              <Button type='submit' variant='green'>
                Attach
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal show={editCommentModal.show} backdrop='static' onHide={this.hideEditCommentModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit comment</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleEditComment}>
            <Modal.Body>
              {
                editCommentModal.errorMessage && (
                  <Alert variant='danger'>
                    {editCommentModal.errorMessage}
                  </Alert>
                )
              }
              <Form.Group>
                <Form.Label>Comment</Form.Label>
                <Form.Control as='textarea' style={{ resize: 'none' }} rows={3} value={editCommentModal.message} onChange={this.handleEditCommentInputChange} />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' className='mr-2' onClick={this.hideEditCommentModal} disabled={editCommentModal.isLoading}>
                Cancel
              </Button>
              <Button variant='info' type='submit' disabled={editCommentModal.isLoading}>
                Edit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <PromptDeleteModal
          {...deleteCommentModal}
          title='Delete comment'
          onHide={this.hideDeleteCommentModal}
          onDelete={this.handleDeleteComment}
          >
          <Card.Subtitle>Are you sure you want to delete the comment?</Card.Subtitle>
          <Alert variant='light'>
            <div>{deleteCommentModal.message}</div>
          </Alert>
        </PromptDeleteModal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0) => dispatch(showImagePreview(images, activeIndex))
});

export default connect(mapStateToProps, mapDispatchToProps)(Discussion);