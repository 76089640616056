import React, { Component } from 'react';
import { Alert, Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import durationFormat from '../../../../../util/helpers/durationFormat';
import moment from 'moment';
import './style.scss';
import NameLink from '../../../../common/NameLink/NameLink';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

class Submissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      submissions: [],
      viewModal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        submissionData: null,
        selectedSubmission: '',
        submissions: []
      }
    };
  }
  componentDidMount() {
    const { assignment, match: { params: { classID } } } = this.props;

    axiosRequest('get', `school-admin/class/${classID}/material/assignment/${assignment.id}/submission`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        submissions: data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showViewModal = submission => {
    this.setState({
      ...this.state,
      viewModal: {
        ...this.state.viewModal,
        show: true,
        isLoading: true,
        errorMessage: '',
        submissionData: submission,
        selectedSubmission: submission.id
      }
    }, () => {
      const { assignment, match: { params: { classID } } } = this.props;

      axiosRequest('get', `school-admin/class/${classID}/material/assignment/${assignment.id}/submission/student/${submission.student_id}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          viewModal: {
            ...this.state.viewModal,
            ...data,
            isLoading: false,
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          viewModal: {
            ...this.state.viewModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  hideViewModal = () => {
    this.setState({
      ...this.state,
      viewModal: {
        show: false,
        isLoading: true,
        errorMessage: '',
        submissionData: null,
        selectedSubmission: '',
        submissions: []
      }
    });
  }
  handleSelectSubmission = event => {
    this.setState({
      ...this.state,
      viewModal: {
        ...this.state.viewModal,
        selectedSubmission: event.target.value
      }
    })
  }
  handleFileDownload = fileID => {
    const { viewModal: { selectedSubmission } } = this.state;
    const { assignment, match: { params: { classID } } } = this.props;

    axiosRequest('get', `school-admin/class/${classID}/material/assignment/${assignment.id}/submission/${selectedSubmission}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handlePreviewImages = (assignment, submission, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/school-admin/class/${classID}/material/assignment/${assignment.id}/submission/${submission.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  renderViewModal = () => {
    const {
      viewModal: {
        isLoading,
        errorMessage,
        submissions,
        selectedSubmission
      }
    } = this.state;
    const { assignment, match: { params: { classID } } } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (submissions.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let submission = submissions.find(submission => submission.id === +selectedSubmission);
    let revisionIndex = submissions.length;

    const images = submission.files ? submission.files.filter(file => {
      return !!file.is_image;
    }) : [];

    const files = submission.files ? submission.files.filter(file => {
      return !file.is_image;
    }) : [];

    return (
      <>
        {
          submissions.length > 1 && (
            <Form.Control as='select' value={selectedSubmission} onChange={this.handleSelectSubmission}>
              <option disabled hidden value=''>Select revision...</option>
              {
                submissions.map((submission, index) => (
                  <option key={index} value={submission.id}>Revision {revisionIndex--}</option>
                ))
              }
            </Form.Control>
          )
        }
        <div className='p-1 p-md-3' style={{ whiteSpace: 'pre-line' }}>
          {submission.description}
        </div>
        {
          files.length > 0 && (
            <div className='p-1'>
              {
                files.map(file => (
                  <small key={file.id} className='d-flex mb-2'>
                    <div className='mr-2'>
                      <FontAwesomeIcon icon='file' className='text-primary' />
                    </div>
                    <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                      <span className='btn-link' onClick={() => this.handleFileDownload(file.id)}>
                        {file.file_title}
                      </span>
                    </div>
                  </small>
                ))
              }
            </div>
          )
        }
        {
          images.length > 0 && (
            <Row noGutters>
            {
              images.map((image, index) => (
                <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(assignment, submission, images, index)}>
                  <Image className='post-image' src={`${process.env['REACT_APP_API_BASE_URL']}/school-admin/class/${classID}/material/assignment/${assignment.id}/submission/${submission.id}/image/${image.id}`} thumbnail />
                </Col>
              ))
            }
            </Row>
          )
        }
      </>
    );
  }
  renderGraded = submission => {
    const { assignment } = this.props;

    let gradeData = assignment.grades.find((grade) => grade.student_id === submission.student_id);

    if (gradeData) {
      return (
        <div className='text-muted'>
          Grade: {gradeData.grade} / {assignment.points}
        </div>
      );
    }

    return (
      <div className='text-muted'>
        (Not yet graded)
      </div>
    );
  }
  renderSubmissionRemark = submission => {
    const { assignment } = this.props;

    let start = moment(assignment.faculty_load_id ? assignment.until : assignment.audience.until);
    let end = moment(submission.created_at);

    if (end.isAfter(start)) {
      let duration = moment.duration(end.diff(start));
      let humanized = duration.humanize();
      // humanized = humanized.split(' ').map(h => '' + h.charAt(0).toUpperCase() + h.slice(1)).join(' ');
      humanized = humanized.charAt(0).toUpperCase() + humanized.slice(1);
      return (
        <div className='text-danger font-weight-bold'>
          {humanized} late <span className='text-muted font-italic font-weight-normal'>({durationFormat(duration)})</span>
        </div>
      );
    }

    return (
      <div className='text-green font-weight-bold'>
        On time
      </div>
    );
  }
  render() {
    const { isLoading, errorMessage, submissions, viewModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (submissions.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='submission-list'>
          {
            submissions.map(submission => (
              <div key={submission.id} className='py-3 p-md-3 py-md-0'>
                <div className='d-flex'>
                  <div className='flex-fill'>
                    <div>
                      <NameLink
                        id={submission.profile.id}
                        name={submission.profile.name}
                        image={submission.profile.image}
                        rank={submission.profile.rank} />
                    </div>
                    {this.renderSubmissionRemark(submission)}
                    {this.renderGraded(submission)}
                  </div>
                  <div>
                    <Button variant='warning' size='sm' title='View submission' className='mr-2' onClick={() => this.showViewModal(submission)}>
                      <FontAwesomeIcon icon='eye' />
                    </Button>
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        <Modal show={viewModal.show} size='lg' onHide={this.hideViewModal}>
          <Modal.Header closeButton>
            <Modal.Title>{viewModal.submissionData ? viewModal.submissionData.profile.name : ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.renderViewModal()}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' className='mr-2' onClick={this.hideViewModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Submissions);