import React from 'react';

export default function Classroom(props) {
  function upcomingDescription() {
    switch(props.accountType) {
      case 5:
      case 2:
        return ' Clicking an event will allow you to view the related content.';
      default:
        return '';
    }
  }
  function discussionDescription() {
    switch(props.accountType) {
      case 5:
      case 2:
        return ' Members of the Classroom may also post a discussion and comment on each post.';
      default:
        return '';
    }
  }
  function materialDescription() {
    switch(props.accountType) {
      case 2:
        return 'The Materials area of the classroom is used to create and view assignments, quizzes and exams. This is also used to upload file collections and links.';
      default:
        return 'The Materials area of the classroom is used to view assignments, quizzes, exams, files, and links created or uploaded by the faculty.';
    }
  }
  function schedule() {
    if (props.accountType === 2) {
      return (
        <li>
          <div className='font-weight-bold'>Schedule</div>
          <div>
            The Schedule area is used to set the weekly schedule of the classroom. The faculty may also set a schedule for a specific date.
          </div>
        </li>
      );
    }
  }
  function attendanceDescription() {
    switch(props.accountType) {
      case 5:
        return 'The Attendance area of the classroom is used for viewing your recorded attendance.';
      case 4:
        return 'The Attendance area of the classroom is used for viewing your student\'s recorded attendance.';
      case 2:
        return 'The Attendance area of the classroom is used for recording the attendance of the students.';
      default:
        return 'The Attendance area of the classroom is used for viewing the recorded attendance of the students.';
    }
  }
  function gradeDescription() {
    switch(props.accountType) {
      case 5:
        return 'The Grades area of the classroom is used for viewing your recorded grades on every gradeable material.';
      case 4:
        return 'The Grades area of the classroom is used for viewing your student\'s recorded grades on every gradeable material.';
      case 2:
        return 'The Grades area of the classroom is used for viewing the recorded grades of the students on every gradeable material. The faculty may also export the grades of the students.';
      default:
        return 'The Grades area of the classroom is used for viewing the recorded grades of the students on every gradeable material.';
    }
  }
  function badgeDescription() {
    switch(props.accountType) {
      case 5:
        return 'The Badges area of the classroom is used for viewing your earned badges in the classroom and the monthly ranking of earned badges.';
      case 4:
        return 'The Badges area of the classroom is used for viewing your student\'s earned badges in the classroom.';
      default:
        return 'The Badges area of the classroom is used for viewing students\' current badges and ranking.';
    }
  }
  return (
    <>
      <div className='mb-3'>
        <div className='h4 text-primary'>Classroom</div>
        <div>
          The Classroom is composed of three parts:
          <ul>
            <li><b>Classroom Information</b> &mdash; holds the information of the classroom.</li>
            <li><b>Events</b> &mdash; displays the upcoming dues of gradeable materials in chronological order.</li>
            <li><b>Content</b> &mdash; tabbed component for the modules and tools.</li>
          </ul>
        </div>
      </div>
      <div className='mb-3'>
        <div className='h5 text-primary'>Classroom Information</div>
        <div>
          <ol>
            <li><b>Course</b> &mdash; course description and course code.</li>
            <li><b>Program</b> &mdash; program description and program acronym.</li>
            <li><b>Year Level / Grade Level</b> &mdash; description and acronym.</li>
            <li><b>Section</b></li>
            <li><b>Faculty</b> &mdash; full name of the assigned faculty.</li>
          </ol>
        </div>
      </div>
      {
        props.accountType === 3 ? (
          <div className='mb-3'>
            <div className='h5 text-primary'>Content</div>
            <div className='font-weight-bold'>Attendance</div>
            <div>
              {attendanceDescription()}
            </div>
          </div>
        ) : (
          <>
            <div className='mb-3'>
              <div className='h5 text-primary'>Events</div>
              <div>
                Displays upcoming dues of gradeable materials in chronological order.
                Gradeable materials are assignments, quizzes and exams.
                {upcomingDescription()}
              </div>
            </div>
            <div className='mb-3'>
              <div className='h5 text-primary'>Content</div>
              <div>
                <ol>
                  <li>
                    <div className='font-weight-bold'>Discussion</div>
                    <div>
                      The Discussion area of the classroom is used by the faculty to create announcements and brief messages for the course.
                      Posts will appear in chronological order.
                      {discussionDescription()}
                    </div>
                  </li>
                  <li>
                    <div className='font-weight-bold'>Materials</div>
                    <div>
                      {materialDescription()}
                    </div>
                  </li>
                  {schedule()}
                  <li>
                    <div className='font-weight-bold'>Attendance</div>
                    <div>
                      {attendanceDescription()}
                    </div>
                  </li>
                  <li>
                    <div className='font-weight-bold'>Grades</div>
                    <div>
                      {gradeDescription()}
                    </div>
                  </li>
                  <li>
                    <div className='font-weight-bold'>Students</div>
                    <div>
                      The Students area of the classroom is used for viewing the list of the students in the classroom.
                    </div>
                  </li>
                  <li>
                    <div className='font-weight-bold'>Badges</div>
                    <div>
                      {badgeDescription()}
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </>
        )
      }
    </>
  );
}