import React, { Component } from 'react';
import { Alert, Button, Col, Form, InputGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Validator from 'validatorjs';
import { loadRule } from '../../../../util';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../common/RichTextEditor/RichTextEditor';

export default class Exam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      formInputs: {
        title: '',
        description: '',
        from: '',
        until: '',
        gradeCategory: '',
        examCategory: '',
        isAsync: false,
        examPermit: false
      },
      isFormLoading: false,
      formError: '',
      formSuccess: '',
      asyncDate: null
    };
  }
  componentDidMount() {
    const { materialData, asyncSchedules } = this.props;

    let custom = {};
    if (asyncSchedules && asyncSchedules.length > 0) {
      let asyncDate = asyncSchedules.find(as => moment().isSameOrBefore(as.date));
      asyncDate = asyncDate ? {...asyncDate} : null;
      custom = {
        asyncDate
      };
    }

    if (materialData) {
      if (materialData.material_async_schedule) {
        let asyncDate = {
          date: materialData.material_async_schedule.schedule_date,
          start_time: materialData.material_async_schedule.start_time,
          end_time: materialData.material_async_schedule.end_time,
          schedule_from: materialData.material_async_schedule.schedule_from
        };
        this.setState({
          ...this.state,
          formInputs: {
            title: materialData.title,
            description: materialData.description,
            from: moment(materialData.from).format('YYYY-MM-DDTHH:mm'),
            until: moment(materialData.until).format('YYYY-MM-DDTHH:mm'),
            gradeCategory: materialData.grade_category_id,
            examCategory: materialData.exam_category_id ? materialData.exam_category_id : '',
            isAsync: true,
            examPermit: false
          },
          isLoading: false,
          asyncDate
        }, () => {
          this.setAsyncSchedule(asyncDate);
        });
      } else {
        this.setState({
          ...this.state,
          formInputs: {
            title: materialData.title,
            description: materialData.description,
            from: moment(materialData.from).format('YYYY-MM-DDTHH:mm'),
            until: moment(materialData.until).format('YYYY-MM-DDTHH:mm'),
            gradeCategory: materialData.grade_category_id,
            examCategory: materialData.exam_category_id ? materialData.exam_category_id : '',
            isAsync: false,
            examPermit: false
          },
          isLoading: false,
          ...custom
        });
      }
    } else {
      this.setState({
        ...this.state,
        formInputs: {
          ...this.state.formInputs,
          from: moment().format('YYYY-MM-DDTHH:mm')
        },
        isLoading: false,
        ...custom
      });
    }

    loadRule(['date', 'after_or_equal'], {
      after_or_equal: 'The :attribute date must be equal or after the current date and time.'
    });
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [event.target.name]: event.target.value
      }
    });
  }
  handleDescriptionInputChange = description => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        description
      }
    });
  }
  handleExamPermitChange = event => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        examPermit: event.target.checked
      }
    });
  }
  setAsyncSchedule = (currentAsyncDate = null) => {
    this.setState({
      ...this.state,
      formError: '',
      isFormLoading: true,
      formInputs: {
        ...this.state.formInputs,
        isAsync: true
      }
    }, () => {
      const { classID } = this.props.match.params;
      axiosRequest('get', `faculty/class/${classID}/material/async-schedules${currentAsyncDate ? `/${currentAsyncDate.date}` : ''}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isFormLoading: false
        }, () => {
          this.props.setAsyncSchedules(data, currentAsyncDate, (isSuccess, returnData) => {
            if (isSuccess) {
              this.setState({
                ...this.state,
                asyncDate: returnData,
                formInputs: {
                  ...this.state.formInputs,
                  from: moment(`${returnData.date} ${returnData.start_time}`, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DDTHH:mm'),
                  until: moment(`${returnData.date} ${returnData.end_time}`, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DDTHH:mm'),
                }
              });
            } else {
              this.setState({
                ...this.state,
                formInputs: {
                  ...this.state.formInputs,
                  isAsync: false
                },
                formError: 'There are no schedules available.'
              });
            }
          });
        });
      }, error => {
        this.setState({
          ...this.state,
          formError: (error.response && error.response.data.message) ? error.response.data.message : error.message ? error.message : error,
          isFormLoading: false,
          formInputs: {
            ...this.state.formInputs,
            isAsync: false
          }
        });
      }, this.props.history);
    });
  }
  handleAsyncInputChange = event => {
    const isAsync = event.target.checked;
    if (isAsync && this.props.asyncSchedules.length === 0) {
      this.setAsyncSchedule();
      return;
    }

    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        isAsync
      }
    });
  }
  handlePrevAsync = () => {
    const { asyncDate } = this.state;
    const { asyncSchedules } = this.props;
    
    if (asyncDate) {
      this.setState({
        ...this.state,
        isFormLoading: true
      }, () => {
        let index = asyncSchedules.findIndex(as1 => as1.date === asyncDate.date && as1.start_time === asyncDate.start_time && as1.end_time === asyncDate.end_time && as1.schedule_from === asyncDate.schedule_from);
        if (index !== -1) {
          if (index === 0) {
            this.props.onPrevAsync(asyncDate, newAsyncDate => {
              this.setState({
                ...this.state,
                asyncDate: newAsyncDate,
                formInputs: {
                  ...this.state.formInputs,
                  from: moment(`${newAsyncDate.date} ${newAsyncDate.start_time}`, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DDTHH:mm'),
                  until: moment(`${newAsyncDate.date} ${newAsyncDate.end_time}`, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DDTHH:mm'),
                },
                isFormLoading: false
              });
            });
          } else {
            this.setState({
              ...this.state,
              asyncDate: {...asyncSchedules[index-1]},
              formInputs: {
                ...this.state.formInputs,
                from: moment(`${{...asyncSchedules[index-1]}.date} ${{...asyncSchedules[index-1]}.start_time}`, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DDTHH:mm'),
                until: moment(`${{...asyncSchedules[index-1]}.date} ${{...asyncSchedules[index-1]}.end_time}`, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DDTHH:mm'),
              },
              isFormLoading: false
            });
          }
        }
      });
    }
  }
  handleNextAsync = () => {
    const { asyncDate } = this.state;
    const { asyncSchedules } = this.props;
    
    if (asyncDate) {
      this.setState({
        ...this.state,
        isFormLoading: true
      }, () => {
        let index = asyncSchedules.findIndex(as1 => as1.date === asyncDate.date && as1.start_time === asyncDate.start_time && as1.end_time === asyncDate.end_time && as1.schedule_from === asyncDate.schedule_from);
        console.log(index)
        if (index !== -1) {
          if (index === asyncSchedules.length-1) {
            this.props.onNextAsync(asyncDate, newAsyncDate => {
              this.setState({
                ...this.state,
                asyncDate: newAsyncDate,
                formInputs: {
                  ...this.state.formInputs,
                  from: moment(`${newAsyncDate.date} ${newAsyncDate.start_time}`, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DDTHH:mm'),
                  until: moment(`${newAsyncDate.date} ${newAsyncDate.end_time}`, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DDTHH:mm'),
                },
                isFormLoading: false
              });
            });
          } else {
            this.setState({
              ...this.state,
              asyncDate: {...asyncSchedules[index+1]},
              formInputs: {
                ...this.state.formInputs,
                from: moment(`${{...asyncSchedules[index+1]}.date} ${{...asyncSchedules[index+1]}.start_time}`, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DDTHH:mm'),
                until: moment(`${{...asyncSchedules[index+1]}.date} ${{...asyncSchedules[index+1]}.end_time}`, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DDTHH:mm'),
              },
              isFormLoading: false
            });
          }
        }
      });
    }
  }
  handleCreate = inputs => {
    const { classID } = this.props.match.params;

    axiosRequest('post', `faculty/class/${classID}/material/exam`, inputs, ({ data: { data, message }}) => {
      this.setState({
        ...this.state,
        formSuccess: message
      }, () => {
        this.props.onSave(data);
      });
    }, (error) => {
      this.setState({
        ...this.state,
        formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        isFormLoading: false
      });
    }, this.props.history);
  }
  handleUpdate = inputs => {
    const { classID } = this.props.match.params;

    axiosRequest('patch', `faculty/class/${classID}/material/exam/${this.props.materialData.id}`, inputs, ({ data: { data, message }}) => {
      this.setState({
        ...this.state,
        formSuccess: message
      }, () => {
        this.props.onUpdate(data);
      });
    }, (error) => {
      this.setState({
        ...this.state,
        formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
        isFormLoading: false
      });
    }, this.props.history);
  }
  handleSave = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: ''
    }, () => {
      const { formInputs, asyncDate } = this.state;
      
      let validator = new Validator(formInputs, {
        title: 'required|min:3',
        description: 'required',
        from: `required|date|after_or_equal:${moment().format('YYYY-MM-DDTHH:mm')}`,
        until: 'required|date|after_or_equal:from',
        gradeCategory: 'required|min:1',
        examCategory: 'required|min:1',
        isAsync: 'required|boolean',
        examPermit: 'required|boolean'
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          formError: validator.errors.errors[firstKey][0],
          isFormLoading: false
        });
        return;
      }

      let custom = {};
      if (formInputs.isAsync) {
        validator = {
          passes: true,
          error: ''
        };

        if (asyncDate === null) {
          validator.passes = false;
          validator.error = 'Asynchronous schedule is required.';
        } else {
          let asyncEnd = moment(`${asyncDate.date} ${asyncDate.end_time}`, 'YYYY-MM-DD HH:mm');
          if (moment(formInputs.from).isSameOrAfter(asyncEnd)) {
            validator.passes = false;
            validator.error = 'The "from" field must be before the end of the asynchronous schedule.';
          }
        }

        if (!validator.passes) {
          this.setState({
            ...this.state,
            formError: validator.error,
            isFormLoading: false
          });
          return;
        }

        custom = {
          asyncDate: asyncDate.date,
          asyncStartTime: asyncDate.start_time,
          asyncEndTime: asyncDate.end_time,
          asyncFrom: asyncDate.schedule_from
        };
      }

      if (this.props.materialData) {
        this.handleUpdate({...formInputs, ...custom});
      } else {
        this.handleCreate({...formInputs, ...custom});
      }
    });
  }
  render() {
    const { isLoading, errorMessage, formInputs, formError, formSuccess, isFormLoading, asyncDate } = this.state;
    const { materialData, gradeCategories, examCategories, asyncSchedules } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon className='m-3' />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger' className='m-3'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>
            {
              materialData ? 'Update exam' : 'Create an exam'
            }
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={this.handleSave}>
          <Modal.Body>
            {
              formError ? (
                <Alert variant='danger'>
                  {formError}
                </Alert>
              ) : formSuccess ? (
                <Alert variant='success'>
                  {formSuccess}
                </Alert>
              ) : null
            }
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type='text' name='title' value={formInputs.title} onChange={this.handleInputChange} disabled={isFormLoading} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <RichTextEditor.Editor
                value={formInputs.description}
                onChange={this.handleDescriptionInputChange}
                readOnly={isFormLoading} />
              {/* <Form.Control as='textarea' rows='4' style={{ resize: 'none' }} name='description' value={formInputs.description} onChange={this.handleInputChange} disabled={isFormLoading} /> */}
            </Form.Group>
            <Form.Group>
              <Form.Check id='isAsync' type='switch' label='Asynchronous/Exam' checked={formInputs.isAsync} onChange={this.handleAsyncInputChange} disabled={isFormLoading} />
              {
                (formInputs.isAsync && asyncSchedules && asyncSchedules.length > 0) && (
                  <div className='px-3 pt-2'>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <Button className='border' variant='light' onClick={this.handlePrevAsync} disabled={isFormLoading}>
                          <FontAwesomeIcon icon='chevron-left' />
                        </Button>
                      </InputGroup.Prepend>
                        <Form.Control
                          className='text-center'
                          value={asyncDate ? 
                            `${moment(asyncDate.date).format('MMMM D, YYYY (ddd)')} ${moment(asyncDate.start_time, 'HH:mm').format('hh:mm A')}-${moment(asyncDate.end_time, 'HH:mm').format('hh:mm A')}` : 
                            null}
                          type='text'
                          disabled={isFormLoading}
                          readOnly />
                      <InputGroup.Append>
                        <Button className='border' variant='light' onClick={this.handleNextAsync} disabled={isFormLoading}>
                          <FontAwesomeIcon icon='chevron-right' />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Text className='text-muted text-center'>Asynchronous schedule to which the material will be tagged to.</Form.Text>
                  </div>
                )
              }
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>From</Form.Label>
                <Form.Control type='datetime-local' name='from' min={moment().format('YYYY-MM-DDTHH:mm')} max={formInputs.until} value={formInputs.from} onChange={this.handleInputChange} disabled={isFormLoading} />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>Until</Form.Label>
                <Form.Control type='datetime-local' name='until' min={formInputs.from} value={formInputs.until} onChange={this.handleInputChange} disabled={isFormLoading} />
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Grade Category</Form.Label>
              <Form.Control as='select' name='gradeCategory' value={formInputs.gradeCategory} onChange={this.handleInputChange} disabled={isFormLoading}>
                <option hidden disabled value=''>Select grade category...</option>
                {
                  gradeCategories.map(category => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Exam Category</Form.Label>
              <Form.Control
                as='select'
                name='examCategory'
                value={formInputs.examCategory}
                onChange={this.handleInputChange}
                disabled={isFormLoading || examCategories.length === 0}>
                <option value='' className='text-muted font-italic'>(None)</option>
                {
                  examCategories.map(category => (
                    <option key={category.id} value={category.id}>{category.title}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {
              formSuccess ? (
                <Button variant='light' onClick={this.props.onCancel}>
                  Close
                </Button>
              ) : (
                <>
                  <Button variant='danger mr-2' onClick={this.props.onCancel} disabled={isFormLoading}>
                    Cancel
                  </Button>
                  <Button variant='green' type='submit' disabled={isFormLoading}>
                    Save
                  </Button>
                </>
              )
            }
          </Modal.Footer>
        </Form>
      </>
    );
  }
}