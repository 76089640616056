import React, { Component } from 'react';
import './style.scss';

export default class LoadingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingAnimationEnd: false
    }
  }
  componentDidUpdate() {
    const { loadingAnimationEnd } = this.state;

    if (loadingAnimationEnd) {
      setTimeout(() => {
        this.props.onClose();
      }, 500);
    }
  }
  onAnimationIteration = e => {
    const { closeLoading } = this.props;
    if (e.animationName === 'loading-logo' && closeLoading) {
      this.setState({
        ...this.state,
        loadingAnimationEnd: true
      });
    }
  }
  render() {
    const { loadingAnimationEnd } = this.state;
    return (
      <div className='loading-page-container'>
        <div>
          <svg
            onAnimationIteration={this.onAnimationIteration}
            className={`loading-logo ${loadingAnimationEnd ? 'close-loading-logo' : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="147.67891682785299806576402321083"
            viewBox="0 0 1034 1018">
            <g
              transform="translate(0.000000,1018.000000) scale(0.100000,-0.100000)"
              fill="#014509"
              stroke="none">
              <path d="M4735 9738 c-116 -19 -108 -14 -101 -61 15 -94 47 -355 48 -391 0
              -33 -5 -45 -36 -73 -66 -61 -163 -38 -185 45 -6 20 -20 123 -32 227 -12 105
              -26 194 -30 200 -8 8 -196 -9 -207 -20 -6 -6 36 -365 50 -430 14 -66 39 -113
              80 -151 63 -60 92 -69 213 -68 156 1 212 19 284 92 61 61 81 107 81 183 0 43
              -23 241 -44 379 -13 86 -12 85 -121 68z"/>
              <path d="M5027 9673 c-3 -43 -2 -82 2 -86 4 -4 50 -9 102 -12 52 -2 98 -4 103
              -4 5 -1 6 -103 2 -250 -4 -137 -3 -252 1 -254 13 -8 201 -13 208 -5 3 3 8 113
              11 244 3 132 7 245 10 253 3 11 24 13 99 9 70 -4 98 -2 105 7 6 8 10 45 8 87
              l-3 73 -135 6 c-74 4 -219 7 -321 8 l-186 1 -6 -77z"/>
              <path d="M5895 9678 c-67 -299 -134 -633 -127 -640 9 -9 54 -19 357 -82 66
              -14 135 -30 153 -37 24 -8 37 -8 46 1 14 11 42 137 33 146 -5 5 -150 38 -247
              55 -90 16 -103 24 -97 62 3 18 10 45 16 61 9 23 16 27 34 22 12 -4 72 -18 132
              -31 61 -13 118 -26 127 -30 24 -10 49 35 51 91 l2 48 -85 18 c-173 37 -214 48
              -221 60 -7 11 7 66 21 81 4 5 36 2 71 -7 106 -25 223 -49 248 -50 22 -1 26 5
              39 63 7 35 10 67 5 72 -8 8 -511 119 -539 119 -8 0 -16 -10 -19 -22z"/>
              <path d="M3610 9494 c-91 -30 -170 -54 -176 -54 -6 0 -17 -7 -24 -15 -9 -11
              -5 -35 23 -117 19 -57 65 -197 103 -312 37 -114 74 -210 81 -213 19 -8 184 48
              190 64 5 14 -22 109 -52 181 -19 44 -12 53 65 77 180 58 249 154 205 287 -22
              67 -47 103 -96 134 -61 38 -123 32 -319 -32z m184 -126 c37 -34 41 -61 12 -94
              -29 -36 -93 -61 -109 -45 -15 15 -47 113 -41 123 4 7 85 35 106 37 4 1 18 -9
              32 -21z"/>
              <path d="M6687 9493 c-3 -4 -21 -51 -42 -103 -20 -52 -41 -106 -47 -120 -5
              -14 -37 -95 -69 -180 -33 -85 -64 -165 -71 -177 -16 -33 -6 -46 58 -71 33 -13
              67 -28 76 -33 51 -27 62 -12 123 154 30 83 46 94 80 57 13 -15 23 -57 36 -153
              10 -72 18 -135 19 -140 0 -14 217 -104 226 -94 4 3 -1 65 -9 137 -11 94 -22
              140 -36 164 -30 48 -27 56 22 56 55 0 118 42 147 99 24 47 27 129 6 169 -32
              61 -70 83 -302 173 -167 64 -208 76 -217 62z m241 -227 c68 -31 84 -57 64
              -101 -16 -34 -52 -40 -114 -20 -81 26 -84 32 -54 99 14 32 29 56 33 54 5 -1
              36 -16 71 -32z"/>
              <path d="M3095 9295 c-10 -8 -51 -31 -90 -51 -110 -56 -109 -56 -86 -144 35
              -136 51 -194 67 -243 9 -26 14 -52 12 -58 -3 -10 -58 44 -226 221 l-61 65 -33
              -19 c-18 -10 -71 -39 -117 -65 -46 -25 -86 -51 -88 -57 -2 -7 25 -63 60 -125
              72 -128 94 -168 179 -321 32 -59 67 -117 77 -129 l18 -22 67 38 c37 21 70 43
              72 50 4 11 -161 310 -191 345 -7 8 -16 26 -20 39 -12 40 15 16 167 -148 79
              -85 151 -159 160 -165 13 -8 24 -7 39 3 12 7 38 22 58 33 55 30 56 32 38 95
              -10 32 -30 105 -46 163 -16 58 -37 129 -46 159 -11 35 -13 57 -7 63 11 11 12
              9 67 -97 68 -131 157 -285 166 -285 12 0 138 70 144 81 3 4 -23 59 -59 121
              -35 62 -109 192 -163 288 -103 182 -116 197 -158 165z"/>
              <path d="M4834 8508 c33 -174 7 -295 -124 -573 -50 -106 -65 -139 -100 -220
              -49 -114 -78 -252 -86 -416 -11 -209 16 -267 48 -103 9 49 31 125 48 169 36
              96 83 190 209 415 135 240 163 398 102 579 -32 93 -77 181 -93 181 -6 0 -8
              -13 -4 -32z"/>
              <path d="M5070 8269 c0 -6 26 -38 59 -72 67 -71 149 -184 184 -254 54 -107 70
              -173 71 -278 0 -93 -2 -105 -33 -173 -52 -110 -24 -107 63 8 93 122 126 201
              126 302 0 82 -27 151 -90 223 -45 53 -164 147 -227 180 -21 11 -60 32 -86 47
              -52 30 -67 34 -67 17z"/>
              <path d="M5694 7646 c-4 -10 -2 -60 5 -110 64 -475 54 -681 -44 -836 -45 -71
              -33 -75 76 -28 274 118 320 264 177 553 -19 39 -64 127 -100 197 -36 70 -68
              144 -71 165 -4 21 -13 47 -21 58 -14 19 -15 19 -22 1z"/>
              <path d="M4240 7309 c0 -6 8 -23 19 -37 30 -44 51 -121 51 -188 0 -81 -24
              -179 -96 -389 -58 -168 -59 -171 -59 -285 0 -128 11 -160 82 -248 62 -76 248
              -191 395 -243 51 -18 61 -2 18 29 -57 42 -173 166 -224 240 -26 38 -59 100
              -72 136 -41 114 -31 194 49 386 83 200 73 315 -41 480 -69 100 -122 152 -122
              119z"/>
              <path d="M6106 6938 c-15 -192 -78 -371 -168 -471 -24 -27 -95 -83 -158 -125
              -174 -117 -203 -169 -184 -325 11 -87 29 -95 53 -22 20 64 108 154 211 214
              262 154 330 249 330 461 0 96 -34 317 -54 348 -16 27 -24 7 -30 -80z"/>
              <path d="M4274 5837 c-3 -8 -4 -47 -2 -88 l3 -74 890 0 890 0 0 85 0 85 -888
              3 c-737 2 -888 0 -893 -11z"/>
              <path d="M4329 5516 c-13 -14 -11 -150 3 -164 7 -7 27 -9 54 -5 54 7 55 6 83
              -177 12 -74 44 -268 72 -430 27 -162 55 -324 60 -360 5 -36 13 -79 18 -96 10
              -37 5 -49 -29 -59 -71 -22 -120 -87 -120 -159 0 -168 213 -239 311 -103 36 49
              40 126 10 185 -13 25 -21 70 -26 142 -3 58 -11 143 -16 190 -4 47 -13 137 -19
              200 -5 63 -21 236 -35 384 -13 148 -22 273 -19 277 7 11 135 12 151 1 14 -9
              14 -11 38 -392 8 -129 20 -302 26 -385 10 -146 13 -714 4 -723 -4 -4 -451 -6
              -502 -3 -10 1 -13 63 -15 259 l-3 257 -267 3 -268 2 0 -247 c0 -137 -4 -254
              -9 -262 -8 -12 -49 -14 -238 -12 -125 1 -236 -1 -246 -4 -10 -4 -29 -28 -44
              -54 -15 -25 -82 -140 -150 -255 -77 -129 -123 -217 -121 -230 l3 -21 2133 -3
              c1813 -2 2132 0 2132 12 0 7 -17 40 -38 72 -22 33 -88 149 -147 259 -59 110
              -115 206 -124 213 -12 10 -72 13 -259 11 -133 -1 -246 2 -250 6 -4 3 -9 113
              -12 243 l-5 237 -269 3 c-148 1 -274 -2 -279 -7 -4 -4 -8 -111 -7 -236 0 -130
              -3 -232 -9 -239 -10 -11 -455 -15 -467 -4 -3 4 -5 107 -5 230 1 122 -3 229 -7
              235 -6 9 -57 13 -177 15 l-170 3 0 65 c0 71 -5 67 67 53 96 -19 198 69 198
              169 0 47 -35 110 -77 138 -32 22 -47 25 -111 24 l-75 -2 7 281 c3 154 9 284
              12 288 3 5 111 9 240 9 208 0 235 -2 241 -16 5 -15 -6 -317 -38 -954 -7 -135
              -16 -233 -26 -262 -53 -169 152 -301 280 -180 16 15 35 39 43 54 18 35 18 111
              -1 148 -19 36 -70 76 -110 86 -52 12 -55 18 -38 87 8 34 25 112 38 172 48 223
              175 801 183 830 7 27 13 31 52 35 l45 5 3 74 c2 41 -1 78 -6 83 -12 12 -1660
              16 -1673 4z m-506 -1708 c10 -8 16 -26 16 -43 -1 -16 -3 -117 -5 -224 -2 -145
              -6 -196 -16 -202 -22 -14 -442 -11 -456 3 -14 14 -17 434 -4 455 6 9 66 13
              222 16 118 1 218 3 221 5 3 1 13 -4 22 -10z m1368 2 c217 0 220 -1 177 -56
              -21 -26 -79 -132 -165 -301 -21 -40 -42 -73 -48 -73 -10 0 -89 155 -141 278
              -12 29 -34 68 -48 87 -14 18 -26 40 -26 48 0 17 19 27 38 21 8 -2 103 -4 213
              -4z m1779 -21 c15 -27 13 -432 -2 -447 -14 -14 -455 -17 -477 -3 -12 8 -14 40
              -12 173 1 90 -1 176 -4 191 -16 63 -25 4 -25 -167 0 -128 -4 -186 -12 -194
              -14 -14 -184 -17 -192 -3 -3 5 24 69 60 143 37 73 77 158 89 188 14 33 32 60
              46 67 14 7 26 24 29 40 l5 28 230 5 c127 3 236 3 243 1 7 -2 17 -12 22 -22z
              m-995 -81 c47 -87 145 -260 177 -310 16 -26 27 -52 23 -58 -7 -11 -214 -14
              -231 -2 -16 10 -24 422 -8 422 6 0 24 -24 39 -52z m-1615 -162 c0 -177 -2
              -205 -16 -210 -27 -10 -182 -7 -189 4 -4 6 21 68 56 138 34 70 77 160 95 200
              19 39 38 72 44 72 6 0 10 -74 10 -204z m-430 102 c25 -50 69 -135 98 -187 73
              -133 74 -131 -47 -131 -69 0 -102 4 -109 13 -7 8 -12 87 -14 205 -4 232 3 242
              72 100z m1478 -103 c2 -130 -1 -192 -9 -201 -17 -21 -207 -20 -215 1 -4 8 -1
              21 5 29 10 12 151 266 151 272 0 1 9 23 20 49 36 84 45 57 48 -150z m-429 122
              c22 -60 126 -274 142 -294 6 -7 9 -20 5 -28 -5 -13 -22 -15 -98 -13 l-93 3 -6
              190 c-3 105 -4 193 -2 197 9 18 34 -9 52 -55z"/>
              <path d="M4021 4762 c-19 -9 -48 -34 -64 -54 -26 -33 -30 -46 -30 -99 1 -52 5
              -66 30 -97 80 -100 218 -95 286 11 46 70 34 148 -30 209 -53 50 -130 62 -192
              30z"/>
              <path d="M6145 4765 c-99 -46 -133 -150 -80 -241 76 -130 276 -101 315 47 35
              130 -112 252 -235 194z"/>
              <path d="M3524 4211 c-41 -25 -80 -77 -89 -118 -15 -70 29 -152 99 -184 82
              -37 154 -16 214 60 40 53 44 115 9 176 -44 77 -160 110 -233 66z"/>
              <path d="M6650 4222 c-63 -29 -110 -97 -110 -160 0 -66 50 -138 113 -161 97
              -37 210 29 232 136 13 63 -32 145 -97 179 -28 14 -111 18 -138 6z"/>
              <path d="M2980 3195 c-10 -12 -10 -21 -2 -40 l12 -25 837 0 c461 0 846 0 855
              0 9 0 49 -37 89 -82 40 -46 98 -112 129 -148 32 -36 75 -87 97 -115 54 -69
              121 -135 136 -135 20 0 67 52 262 289 20 24 71 82 136 154 l34 38 886 -1 c960
              0 915 -3 907 52 l-3 23 -895 3 c-492 1 -905 0 -917 -3 -19 -4 -141 -136 -223
              -241 -68 -87 -174 -206 -187 -211 -8 -3 -20 3 -28 13 -7 11 -59 73 -115 139
              -55 66 -131 157 -168 201 -37 45 -77 87 -90 93 -16 8 -275 11 -881 11 -758 0
              -860 -2 -871 -15z"/>
              <path d="M2926 3091 c-18 -29 34 -31 879 -33 l858 -3 81 -90 c45 -50 131 -146
              191 -214 61 -68 112 -127 113 -132 2 -5 -5 -9 -16 -9 -13 0 -83 68 -203 200
              -101 111 -193 203 -204 206 -11 2 -407 3 -880 2 -757 -3 -860 -5 -860 -18 0
              -13 104 -15 865 -18 l864 -2 29 -28 c58 -55 297 -321 297 -332 0 -28 -37 -1
              -117 87 -48 53 -117 128 -152 165 l-64 68 -881 -2 c-775 -3 -881 -5 -881 -18
              0 -13 106 -16 877 -20 l878 -5 23 -25 c89 -93 217 -240 217 -249 0 -6 -10 -11
              -22 -11 -16 1 -45 27 -95 88 -40 48 -88 105 -107 127 l-34 40 -890 2 c-867 2
              -891 1 -897 -17 -3 -11 -1 -28 5 -40 11 -20 13 -20 863 -20 469 0 859 0 869 0
              16 0 148 -150 148 -169 0 -5 -12 -11 -27 -13 l-28 -3 -1 -85 c0 -47 4 -91 9
              -97 13 -17 1022 -19 1033 -2 5 9 9 132 5 177 -1 7 -16 12 -41 12 -22 0 -40 5
              -40 10 0 16 130 170 143 170 7 0 415 1 907 3 l895 2 0 35 0 35 -920 1 -920 1
              -37 -38 c-20 -21 -69 -79 -109 -129 -54 -67 -78 -90 -96 -90 -25 0 -32 24 -10
              37 7 4 60 63 116 131 l103 122 914 0 c907 0 914 0 914 20 0 20 -7 20 -918 20
              l-918 0 -40 -42 c-23 -24 -87 -98 -144 -165 -93 -112 -130 -143 -130 -112 0 6
              15 28 33 49 17 20 64 75 102 121 39 46 92 107 119 136 l49 53 901 2 c793 3
              901 5 901 18 0 13 -107 15 -895 18 -492 1 -905 0 -916 -3 -12 -3 -68 -60 -125
              -127 -197 -231 -241 -278 -260 -278 -30 0 -23 17 29 73 27 29 75 84 107 122
              33 39 92 109 133 158 41 48 75 88 76 89 0 2 408 5 906 8 795 4 905 7 905 20 0
              13 -108 15 -903 18 -497 1 -909 -1 -916 -5 -15 -9 -112 -120 -295 -336 -105
              -124 -128 -147 -152 -147 -30 0 -72 43 -299 305 -62 72 -126 142 -141 158
              l-29 27 -870 0 c-494 0 -871 -4 -874 -9z m2667 -570 c28 -28 -12 -31 -443 -31
              -294 0 -441 3 -445 10 -20 33 10 35 446 33 258 -1 436 -6 442 -12z"/>
              <path d="M4435 2166 c-28 -29 -69 -63 -93 -75 -41 -21 -42 -23 -42 -71 0 -33
              4 -50 13 -50 6 0 37 15 69 34 31 19 59 32 62 29 3 -4 6 -95 6 -204 0 -227 -7
              -215 110 -200 7 1 9 101 8 294 l-3 292 -40 2 c-37 3 -44 -1 -90 -51z"/>
              <path d="M4861 2209 c-79 -32 -123 -99 -123 -189 0 -99 51 -166 144 -191 38
              -11 48 -9 85 10 52 26 53 26 53 -1 -1 -38 -35 -102 -63 -117 -33 -17 -68 -3
              -86 35 -8 19 -18 24 -49 24 -21 0 -47 -6 -57 -14 -19 -13 -19 -14 0 -56 28
              -62 75 -90 152 -90 76 0 101 9 152 55 60 54 76 107 76 245 0 123 -10 166 -51
              221 -49 67 -157 98 -233 68z m115 -113 c28 -28 34 -42 34 -76 0 -94 -69 -142
              -125 -85 -21 20 -25 34 -25 83 0 51 4 63 26 86 35 34 49 33 90 -8z"/>
              <path d="M5319 2197 c-62 -32 -97 -101 -80 -157 6 -22 25 -52 42 -68 l31 -28
              -35 -27 c-91 -69 -67 -221 43 -277 54 -27 156 -27 210 0 113 58 135 212 40
              280 l-31 22 31 34 c16 19 33 46 36 60 17 66 -21 135 -91 169 -50 23 -143 20
              -196 -8z m148 -89 c32 -36 35 -46 22 -70 -28 -53 -76 -62 -114 -23 -14 13 -25
              34 -25 45 0 28 43 70 73 70 13 0 33 -10 44 -22z m-1 -226 c33 -26 47 -60 40
              -98 -8 -37 -47 -74 -81 -74 -56 0 -101 80 -75 131 16 32 51 59 75 59 11 0 30
              -8 41 -18z"/>
              <path d="M5704 2196 c-3 -8 -4 -31 -2 -52 l3 -39 120 -1 c78 -1 120 -5 123
              -12 2 -6 -8 -26 -21 -44 -62 -82 -117 -215 -138 -332 -11 -62 -10 -70 5 -82
              21 -15 82 -16 94 0 5 6 15 44 21 84 24 136 69 242 148 347 44 58 53 76 51 105
              l-3 35 -198 3 c-163 2 -198 0 -203 -12z"/>
              <path d="M2176 8649 c-110 -17 -248 -125 -303 -237 -39 -81 -39 -174 2 -257
              37 -77 134 -173 211 -210 83 -39 176 -40 256 -2 78 38 187 141 221 210 37 77
              38 175 2 251 -38 81 -134 180 -208 215 -71 34 -113 41 -181 30z m80 -217 c61
              -45 121 -116 134 -159 10 -32 9 -46 -3 -75 -16 -39 -67 -83 -108 -93 -78 -19
              -239 133 -239 226 0 34 34 86 72 109 47 29 97 26 144 -8z"/>
              <path d="M8074 8621 c-58 -15 -136 -65 -189 -123 -70 -75 -107 -154 -113 -240
              -4 -59 -1 -74 24 -125 39 -80 148 -186 220 -213 30 -11 77 -20 104 -20 55 0
              159 35 167 55 5 14 -70 150 -89 161 -6 4 -32 -4 -58 -16 -43 -20 -53 -21 -79
              -10 -17 7 -47 30 -67 52 -31 34 -35 46 -31 74 12 70 125 194 195 214 49 13
              111 -15 141 -64 22 -35 23 -58 6 -98 -5 -12 4 -23 37 -44 24 -15 55 -35 70
              -46 62 -42 88 -9 88 111 0 88 -30 149 -107 222 -77 74 -146 109 -226 114 -34
              2 -76 1 -93 -4z"/>
              <path d="M1558 8030 c-84 -21 -174 -105 -231 -215 -28 -53 -32 -70 -32 -140 0
              -97 21 -144 101 -221 114 -111 270 -159 386 -118 72 26 173 126 218 217 30 61
              32 73 28 134 -5 70 -30 134 -65 165 -18 17 -22 15 -90 -35 -73 -52 -86 -74
              -58 -97 8 -7 20 -29 26 -49 10 -32 8 -43 -10 -78 -20 -41 -74 -83 -105 -83
              -51 0 -191 95 -227 153 -25 41 -24 70 3 118 26 48 75 72 123 63 31 -6 36 -4
              45 22 6 16 22 50 36 77 27 55 30 73 11 80 -30 11 -124 15 -159 7z"/>
              <path d="M8551 7984 c-94 -47 -173 -120 -207 -192 -22 -46 -27 -71 -26 -127 0
              -79 15 -122 73 -207 77 -113 167 -166 281 -167 75 -1 125 15 205 65 229 145
              245 374 41 572 -66 65 -131 92 -221 92 -65 0 -83 -4 -146 -36z m227 -169 c64
              -26 103 -98 88 -160 -14 -56 -131 -151 -208 -169 -76 -18 -169 77 -154 157 9
              46 82 118 158 156 71 36 66 35 116 16z"/>
              <path d="M9245 7376 c-25 -16 -122 -68 -330 -176 -88 -46 -177 -92 -198 -103
              -44 -22 -52 -33 -39 -50 5 -7 31 -52 58 -102 26 -49 78 -146 115 -215 37 -68
              73 -126 79 -128 20 -7 140 61 140 79 0 9 -32 74 -70 144 -39 69 -68 130 -65
              135 3 5 18 15 33 23 15 7 70 36 122 64 52 28 137 72 189 99 51 27 95 53 97 59
              5 13 -87 177 -102 182 -5 2 -18 -3 -29 -11z"/>
              <path d="M806 6703 c-99 -34 -186 -196 -186 -349 0 -150 139 -242 266 -176 40
              21 105 89 154 162 36 54 82 100 100 100 9 0 26 -10 38 -23 56 -59 0 -167 -87
              -167 -46 0 -44 3 -81 -114 -15 -50 -18 -72 -10 -80 6 -6 39 -11 73 -10 107 1
              173 49 226 164 42 89 65 184 57 237 -16 117 -111 205 -221 205 -83 0 -146 -51
              -243 -194 -27 -40 -57 -79 -65 -87 -24 -19 -50 -9 -61 22 -16 45 17 97 74 117
              44 15 45 16 74 100 27 80 27 84 10 97 -23 17 -57 16 -118 -4z"/>
              <path d="M9510 6665 c-41 -13 -181 -53 -310 -89 -129 -37 -239 -71 -243 -75
              -5 -5 0 -35 11 -67 11 -33 33 -102 48 -154 70 -247 85 -290 100 -290 27 0 128
              31 141 44 9 8 2 44 -32 155 -24 80 -42 148 -39 152 2 4 28 14 57 22 28 9 135
              39 237 67 102 29 188 57 191 62 4 6 -1 36 -11 67 -10 31 -22 72 -27 91 -11 44
              -25 45 -123 15z"/>
              <path d="M533 6008 c-6 -7 -18 -69 -27 -138 -16 -116 -26 -188 -57 -393 -8
              -52 -9 -81 -2 -88 12 -12 151 -34 160 -26 9 9 22 87 23 133 0 78 -5 77 265 38
              132 -19 245 -32 251 -30 13 5 39 158 33 190 -4 21 14 17 -379 72 -63 8 -118
              19 -123 23 -4 4 -3 30 2 57 6 27 12 71 13 99 l3 49 -45 8 c-25 4 -59 10 -76
              13 -19 3 -35 1 -41 -7z"/>
              <path d="M9725 5979 c-27 -5 -99 -15 -160 -23 -257 -34 -421 -59 -429 -66 -4
              -5 -1 -50 7 -102 9 -51 23 -142 32 -203 27 -185 38 -250 44 -257 8 -9 132 10
              144 21 7 7 -27 294 -39 325 -7 19 15 33 68 40 60 9 61 7 83 -169 9 -71 20
              -134 25 -138 9 -10 110 1 130 13 11 7 11 25 -3 107 -37 221 -36 223 34 223
              l36 0 16 -102 c9 -57 19 -129 23 -160 3 -32 12 -62 19 -68 13 -11 113 -4 135
              10 10 6 11 21 2 67 -6 32 -23 150 -38 263 -15 113 -32 211 -37 218 -11 13 -20
              14 -92 1z"/>
              <path d="M902 5218 c-5 -7 -20 -46 -32 -86 -24 -82 -20 -96 30 -107 76 -17
              108 -130 54 -194 -33 -40 -89 -54 -191 -49 -69 4 -87 8 -116 31 -72 55 -70
              138 6 201 37 31 40 37 30 58 -6 13 -16 49 -22 80 -7 32 -18 59 -27 62 -24 9
              -119 -58 -152 -107 -42 -61 -56 -121 -50 -225 11 -187 112 -294 299 -317 77
              -10 192 10 252 43 60 32 133 111 148 160 6 20 13 72 16 115 11 155 -45 266
              -162 321 -61 30 -69 31 -83 14z"/>
              <path d="M9386 5186 c-97 -41 -153 -107 -180 -214 -20 -76 -20 -212 -1 -277
              24 -81 65 -155 89 -160 39 -8 259 -23 271 -19 16 6 37 304 23 322 -7 7 -35 12
              -74 12 l-63 0 -6 -57 c-9 -79 -12 -85 -37 -81 -51 7 -79 139 -45 205 9 17 31
              42 49 55 29 20 44 23 123 22 72 0 98 -5 133 -23 54 -27 82 -72 82 -130 0 -38
              -6 -49 -46 -89 -32 -33 -44 -52 -40 -67 3 -11 8 -51 12 -88 5 -48 12 -71 22
              -75 22 -8 120 46 148 83 45 59 57 110 58 245 1 117 -1 128 -26 175 -34 65
              -100 128 -163 157 -40 18 -72 23 -160 25 -99 3 -116 1 -169 -21z"/>
              <path d="M1020 4462 c-64 -42 -197 -130 -298 -196 -100 -65 -182 -124 -182
              -131 0 -16 48 -207 54 -212 5 -5 103 -19 449 -63 131 -17 245 -34 253 -37 24
              -9 27 24 9 100 -27 116 -27 117 -84 117 -27 0 -53 5 -58 13 -9 12 -53 185 -53
              210 0 7 20 23 45 37 24 13 46 29 49 35 2 6 -4 42 -14 80 -9 39 -20 82 -24 98
              -3 15 -12 27 -19 27 -6 -1 -64 -36 -127 -78z m-41 -335 c8 -31 12 -60 8 -63
              -4 -4 -33 -6 -65 -4 -31 2 -75 4 -97 4 -58 1 -52 24 16 61 30 16 66 38 80 48
              13 9 28 16 34 14 5 -2 17 -29 24 -60z"/>
              <path d="M9142 4384 c-5 -22 -32 -145 -60 -274 -40 -183 -49 -237 -39 -246 7
              -6 38 -16 70 -23 64 -14 87 -6 87 31 0 30 61 292 70 301 10 11 92 -11 106 -29
              8 -9 8 -26 2 -52 -5 -20 -19 -83 -32 -140 l-23 -103 22 -15 c12 -8 45 -17 72
              -20 l49 -6 32 148 c18 82 37 153 42 158 12 12 80 -2 86 -19 3 -8 -9 -82 -26
              -164 -18 -82 -30 -156 -27 -163 7 -19 128 -43 137 -28 4 6 15 46 24 88 9 42
              34 158 56 256 22 99 40 183 40 187 0 10 -46 23 -200 53 -69 14 -190 39 -270
              57 -220 48 -207 48 -218 3z"/>
              <path d="M990 3524 c-18 -47 -12 -145 14 -199 25 -54 116 -145 146 -145 36 0
              40 111 8 197 -22 60 -117 173 -145 173 -7 0 -18 -12 -23 -26z"/>
              <path d="M9262 3501 c-28 -27 -62 -74 -76 -104 -23 -47 -27 -64 -24 -133 3
              -72 5 -79 25 -82 15 -2 39 14 81 56 77 74 100 139 86 242 -11 83 -26 87 -92
              21z"/>
              <path d="M1263 3278 c-20 -27 -53 -118 -53 -147 0 -35 -17 -40 -54 -16 -30 20
              -125 55 -148 55 -26 0 -23 -41 8 -103 52 -105 140 -167 242 -170 l55 -2 26 49
              c30 59 40 163 21 222 -17 50 -62 124 -76 124 -6 0 -16 -6 -21 -12z"/>
              <path d="M9012 3238 c-57 -101 -61 -191 -13 -289 22 -46 30 -54 57 -57 41 -4
              136 30 181 64 63 48 131 180 105 206 -14 14 -104 -11 -156 -43 -45 -27 -53
              -24 -59 26 -8 55 -52 145 -71 145 -8 0 -28 -24 -44 -52z"/>
              <path d="M1405 2968 c-28 -76 -35 -107 -35 -144 0 -48 -13 -56 -53 -30 -32 21
              -147 40 -160 27 -19 -19 33 -109 91 -161 57 -50 104 -69 194 -77 l57 -6 16 39
              c25 56 31 169 12 222 -32 93 -107 172 -122 130z"/>
              <path d="M8855 2926 c-66 -96 -77 -225 -28 -319 15 -29 16 -30 77 -24 117 11
              208 72 260 175 34 68 25 78 -55 63 -35 -7 -77 -20 -92 -28 -38 -19 -59 -11
              -51 22 3 14 -3 54 -15 95 -27 88 -44 91 -96 16z"/>
              <path d="M1608 2663 c-6 -16 -12 -63 -13 -106 -2 -43 -5 -80 -7 -83 -3 -2 -21
              3 -41 11 -41 17 -162 20 -172 5 -9 -14 47 -92 97 -134 50 -43 120 -66 198 -66
              83 0 95 16 95 134 0 76 -4 98 -23 134 -25 47 -98 132 -114 132 -4 0 -14 -12
              -20 -27z"/>
              <path d="M8659 2648 c-72 -77 -84 -109 -84 -226 0 -122 7 -132 89 -132 114 1
              198 42 261 128 53 72 49 82 -35 82 -45 0 -82 -6 -101 -16 -17 -8 -34 -13 -37
              -9 -3 3 -6 36 -7 73 -1 69 -19 142 -36 142 -5 0 -28 -19 -50 -42z"/>
              <path d="M1853 2374 c-7 -19 -9 -139 -2 -169 4 -19 -1 -20 -101 -20 -85 0
              -105 -3 -107 -15 -5 -24 80 -113 133 -140 89 -44 244 -37 258 13 25 93 -11
              214 -88 294 -53 54 -82 66 -93 37z"/>
              <path d="M8398 2343 c-79 -81 -110 -167 -99 -268 6 -48 8 -51 46 -63 53 -18
              159 -11 210 13 44 21 145 120 145 141 0 21 -44 27 -137 21 l-85 -6 8 37 c8 39
              7 124 -2 155 -9 29 -38 19 -86 -30z"/>
              <path d="M2107 2153 c-12 -12 -7 -120 7 -161 8 -22 12 -43 9 -45 -3 -3 -27 -3
              -53 0 -50 6 -146 -12 -156 -29 -10 -15 71 -92 124 -119 34 -17 63 -23 107 -23
              66 1 141 16 157 32 6 6 9 43 7 89 -3 67 -9 87 -34 128 -17 27 -55 68 -85 92
              -55 42 -71 49 -83 36z"/>
              <path d="M8149 2118 c-84 -68 -109 -112 -122 -207 -15 -112 -10 -117 143 -137
              64 -8 147 25 212 85 28 26 49 52 45 57 -11 18 -114 37 -166 31 -28 -3 -51 -4
              -51 -1 0 3 7 27 16 53 20 62 16 155 -7 159 -10 2 -41 -17 -70 -40z"/>
              <path d="M2360 1892 c0 -26 4 -63 10 -81 5 -19 8 -37 5 -39 -4 -5 -19 -6 -113
              -10 -28 -1 -45 -7 -49 -18 -8 -22 41 -70 104 -101 60 -29 118 -29 187 1 l39
              16 -7 62 c-7 74 -40 133 -100 182 -61 50 -76 48 -76 -12z"/>
              <path d="M7896 1899 c-64 -57 -98 -125 -94 -192 3 -46 6 -53 33 -66 41 -21
              142 -20 185 1 19 9 53 32 74 51 57 49 47 63 -53 71 l-81 7 5 37 c3 20 6 57 8
              82 3 60 -16 62 -77 9z"/>
              <path d="M2770 1673 c-24 -9 -61 -46 -77 -76 -19 -38 -10 -74 41 -169 40 -75
              41 -76 22 -97 -38 -42 -99 -17 -125 52 -12 32 -39 35 -72 8 -20 -16 -21 -21
              -10 -48 28 -68 114 -123 191 -123 34 0 48 7 82 39 38 35 42 43 42 87 0 40 -8
              63 -43 122 -42 70 -43 73 -27 97 10 15 26 25 39 25 24 0 59 -31 70 -62 10 -30
              87 -16 87 15 0 26 -52 88 -89 107 -42 21 -107 33 -131 23z"/>
              <path d="M7607 1572 c-15 -9 -30 -25 -33 -34 -3 -11 -15 -18 -30 -18 -32 0
              -73 -21 -104 -55 -38 -40 -36 -118 5 -176 17 -24 46 -54 64 -66 61 -41 145
              -24 187 39 11 15 25 28 32 28 24 0 72 30 72 46 0 15 -143 238 -158 248 -4 2
              -20 -3 -35 -12z m32 -147 c17 -20 31 -47 31 -61 0 -27 -40 -74 -64 -74 -44 0
              -96 53 -96 98 0 10 9 31 21 46 30 38 69 35 108 -9z"/>
              <path d="M3039 1445 c-15 -24 -28 -35 -45 -35 -14 0 -30 -10 -40 -25 -15 -24
              -15 -27 0 -44 15 -17 15 -20 -9 -55 -14 -20 -25 -39 -25 -42 0 -4 -13 -26 -30
              -51 -16 -24 -30 -48 -30 -53 0 -12 50 -50 66 -50 7 0 37 41 66 90 39 66 60 92
              78 96 34 9 50 23 50 44 0 10 -6 20 -12 23 -10 3 -7 16 11 47 l23 43 -28 23
              c-39 32 -51 30 -75 -11z"/>
              <path d="M7222 1349 c-23 -12 -49 -34 -57 -49 -12 -23 -21 -28 -49 -27 -35 1
              -66 -16 -66 -37 0 -25 132 -256 146 -256 21 0 64 30 64 45 0 7 -16 41 -36 76
              -47 83 -51 108 -24 142 25 31 60 35 82 10 8 -10 34 -53 57 -95 23 -43 47 -78
              53 -78 19 0 68 34 68 48 0 8 -8 26 -18 40 -10 15 -29 49 -43 75 -32 64 -78
              115 -109 122 -15 3 -42 -3 -68 -16z"/>
              <path d="M3180 1269 c-62 -25 -120 -116 -120 -189 0 -29 8 -47 38 -80 32 -36
              44 -43 87 -47 32 -4 60 -15 79 -30 16 -13 36 -23 44 -21 18 3 18 4 92 156 49
              102 49 103 30 122 -11 11 -33 20 -51 20 -21 0 -37 8 -50 24 -21 26 -78 56
              -106 55 -10 0 -29 -5 -43 -10z m109 -105 c37 -31 39 -59 8 -105 -29 -44 -77
              -51 -113 -18 -35 33 -32 73 10 115 41 41 55 42 95 8z"/>
              <path d="M6922 1190 c-36 -22 -38 -8 17 -137 27 -61 25 -79 -11 -106 -48 -36
              -76 -17 -117 80 -46 105 -50 109 -89 86 -31 -18 -42 -48 -24 -69 5 -5 21 -39
              36 -76 51 -124 114 -155 220 -108 107 48 126 108 71 235 -7 17 -21 49 -31 73
              -20 48 -26 50 -72 22z"/>
              <path d="M4025 1111 c-73 -18 -136 -69 -166 -136 -22 -48 -25 -142 -6 -188 36
              -85 128 -146 232 -154 51 -5 63 -2 78 15 24 26 29 89 7 83 -90 -22 -174 2
              -210 61 -56 92 24 238 131 238 50 0 79 -12 123 -51 42 -36 54 -32 63 24 5 34
              3 37 -42 66 -67 43 -144 58 -210 42z"/>
              <path d="M6554 1050 c-12 -4 -24 -15 -27 -23 -4 -11 -17 -13 -51 -10 -81 10
              -146 -51 -146 -135 0 -53 44 -136 84 -162 54 -33 133 -22 167 22 22 28 49 17
              49 -20 0 -17 -9 -37 -23 -50 -24 -23 -71 -30 -82 -12 -4 7 -12 7 -25 0 -10 -5
              -28 -10 -39 -10 -40 0 -37 -39 5 -59 78 -35 230 26 258 103 6 17 -33 143 -96
              314 -20 54 -31 60 -74 42z m4 -127 c27 -38 31 -63 16 -94 -15 -31 -60 -50 -90
              -38 -52 19 -72 101 -34 139 30 30 84 27 108 -7z"/>
              <path d="M5641 1001 c-14 -9 -11 -72 9 -201 4 -30 14 -97 21 -148 7 -54 17
              -96 24 -99 21 -8 229 27 244 41 11 12 11 18 -1 42 -15 31 -24 33 -110 19 -55
              -9 -48 -23 -74 155 -29 209 -25 195 -64 198 -19 1 -41 -2 -49 -7z"/>
              <path d="M6179 941 c-21 -16 -36 -19 -80 -14 -46 4 -59 1 -89 -19 -50 -34 -62
              -62 -57 -135 10 -145 129 -217 230 -140 23 17 41 23 62 20 17 -2 38 2 49 10
              18 13 17 18 -16 153 -37 153 -48 166 -99 125z m-2 -120 c30 -44 29 -76 -2
              -106 -43 -44 -104 -25 -125 40 -8 24 -8 40 -1 54 34 62 91 68 128 12z"/>
              <path d="M3436 918 c-21 -30 -20 -51 3 -72 28 -25 72 -17 90 16 15 25 14 29
              -8 52 -30 32 -65 33 -85 4z"/>
              <path d="M4317 903 c-3 -5 -12 -44 -22 -88 -9 -44 -23 -105 -30 -135 -15 -61
              -13 -66 33 -79 36 -11 62 6 62 39 0 35 21 118 35 136 10 14 26 19 62 19 43 0
              49 3 62 29 26 54 -19 82 -80 50 -23 -12 -34 -13 -37 -5 -11 32 -72 56 -85 34z"/>
              <path d="M4576 858 c-12 -40 -26 -139 -26 -184 0 -45 5 -59 30 -87 50 -58 161
              -75 221 -34 46 31 61 65 73 171 9 82 8 90 -9 102 -10 8 -29 14 -42 14 -25 0
              -24 2 -47 -124 -8 -43 -20 -82 -28 -87 -23 -15 -67 -10 -88 11 -18 18 -20 29
              -14 73 3 29 10 70 16 91 8 34 7 42 -8 53 -23 17 -73 17 -78 1z"/>
              <path d="M4941 826 c-7 -8 -11 -25 -9 -38 3 -21 9 -23 61 -26 37 -2 57 -7 57
              -16 0 -6 -31 -53 -70 -103 -38 -50 -70 -99 -70 -107 0 -15 17 -16 143 -14
              l142 3 3 33 c2 21 -1 33 -10 34 -26 3 -54 5 -90 7 -21 0 -38 6 -38 12 0 11
              114 164 130 174 6 3 10 15 10 26 0 18 -8 19 -102 19 -57 0 -112 3 -124 6 -12
              3 -26 -1 -33 -10z"/>
              <path d="M5313 612 c-16 -10 -66 -170 -59 -188 7 -18 40 -18 55 0 22 27 91
              178 85 187 -6 11 -64 12 -81 1z"/>
            </g>
            <path
              className="logo-lines logo-lines-1"
              d="M517,137.5c210.7,0,381.505,168.567,381.505,376.5S727.7,890.5,517,890.5,135.5,721.937,135.5,514,306.3,137.5,517,137.5Z"/>
            <path
              className="logo-lines logo-lines-2"
              d="M517,9.487c279.74,0,506.51,223.64,506.51,499.513S796.74,1008.51,517,1008.51,10.485,784.873,10.485,509,237.26,9.487,517,9.487Z"/>
          </svg>
        </div>
      </div>
    );
  }
}