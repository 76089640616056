import React, { Component } from 'react';
import { Alert, Form } from 'react-bootstrap';
import moment from 'moment';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

export default class Student extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      classes: [],
      students: [],
      exportBy: {
        option: 'class',
        subOption: ''
      },
      exportDate: {
        option: 'all',
        from: '',
        until: '',
        date: ''
      }
    };
  }
  componentDidMount() {
    axiosRequest('get', `staff/attendance-summary`, null, ({ data: { data }}) => {
      data.classes.sort((a, b) => {
        if (a.program.code < b.program.code) {
          return -1;
        } else if (a.program.code > b.program.code) {
          return 1;
        }

        if (a.year_level.code < b.year_level.code) {
          return -1;
        } else if (a.year_level.code > b.year_level.code) {
          return 1;
        }

        if (a.section.code < b.section.code) {
          return -1;
        } else if (a.section.code > b.section.code) {
          return 1;
        }

        return 0;
      });

      let currentDate = moment().format('YYYY-MM-DD');
      this.setState({
        ...this.state,
        ...data,
        isLoading: false,
        exportDate: {
          ...this.state.exportDate,
          until: currentDate,
          date: currentDate
        }
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleExportByOptions = event => {
    if (event.target.checked) {
      this.setState({
        ...this.state,
        exportBy: {
          option: event.target.value,
          subOption: ''
        }
      });
    }
  }
  handleExportBySubOptions = event => {
    this.setState({
      ...this.state,
      exportBy: {
        ...this.state.exportBy,
        subOption: event.target.value
      }
    });
  }
  handleExportDateOptions = event => {
    if (event.target.checked) {
      this.setState({
        ...this.state,
        exportDate: {
          ...this.state.exportDate,
          option: event.target.value
        }
      });
    }
  }
  handleExportDateSubOptions = event => {
    this.setState({
      ...this.state,
      exportDate: {
        ...this.state.exportDate,
        [event.target.name]: event.target.value
      }
    });
  }
  renderExportBySubOption = () => {
    const { exportBy, classes, students } = this.state;

    if (exportBy.option === 'student') {
      return (
        <Form.Control as='select' value={exportBy.subOption} onChange={this.handleExportBySubOptions}>
          <option value='' disabled hidden>Select student...</option>
          {
            students.map(student => (
              <option value={student.id}>{student.name}</option>
            ))
          }
        </Form.Control>
      );
    }

    return (
      <Form.Control as='select' value={exportBy.subOption} onChange={this.handleExportBySubOptions}>
        <option value='' disabled hidden>Select class...</option>
        {
          classes.map(cl => (
            <option value={cl.id}>{`${cl.program.code} ${cl.year_level.code} - ${cl.section.code} `}&mdash;{` ${cl.program.description} ${cl.year_level.description} - ${cl.section.code}`}</option>
          ))
        }
      </Form.Control>
    );
  }
  renderExportDateSubOption = () => {
    const { exportDate } = this.state;

    switch (exportDate.option) {
      case 'range':
        return (
          <>
            <Form.Group>
              <Form.Label>From</Form.Label>
              <Form.Control type='date' name='from' value={exportDate.from} onChange={this.handleExportDateSubOptions} max={moment(exportDate.until).subtract(1, 'days').format('YYYY-MM-DD')} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Until</Form.Label>
              <Form.Control type='date' name='until' value={exportDate.until} onChange={this.handleExportDateSubOptions} min={moment(exportDate.from).add(1, 'days').format('YYYY-MM-DD')} max={moment().format('YYYY-MM-DD')} />
            </Form.Group>
          </>
        );
      case 'date':
        return (
          <Form.Group>
            <Form.Label>Date</Form.Label>
            <Form.Control type='date' name='date' onChange={this.handleExportDateSubOptions} value={exportDate.date} max={moment().format('YYYY-MM-DD')} />
          </Form.Group>
        );
      default:
        return null;
    }
  }
  renderExportButton = () => {
    const { exportBy, exportDate } = this.state;

    let url = `${process.env['REACT_APP_API_BASE_URL']}/staff/attendance-summary`;
    let disabled = true;

    if (exportBy.option && exportBy.subOption) {
      url += `/${exportBy.option}/${exportBy.subOption}`;

      if (exportDate.option === 'range') {
        if (exportDate.from && exportDate.until && moment(exportDate.until).isSameOrBefore(moment(), 'day') && moment(exportDate.from).isBefore(exportDate.until, 'day')) {
          url += `/range/${exportDate.from}/${exportDate.until}`;
          disabled = false;
        }
      } else if (exportDate.option === 'date' && exportDate.date && moment(exportDate.date).isSameOrBefore(moment(), 'day')) {
        url += `/date/${exportDate.date}`;
        disabled = false;
      } else {
        url += '/all';
        disabled = false;
      }
    }

    return (
      <div className='text-right px-1 px-md-3'>
        <a href={url} className={`btn btn-green ${disabled ? 'disabled' : ''}`}>
          Export
        </a>
      </div>
    );
  }
  render() {
    const { isLoading, errorMessage, exportBy, exportDate } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        <Form.Group>
          <Form.Label>
            Export by
          </Form.Label>
          <div className='px-1 px-md-3'>
            <div className='form-inline mb-0 mb-md-3'>
              <Form.Group controlId='ebClass' className='mr-2'>
                <Form.Check type='radio' name='exportBy' label='Class' value='class' onChange={this.handleExportByOptions} checked={exportBy.option === 'class'} />
              </Form.Group>
              <Form.Group controlId='ebStudent'>
                <Form.Check type='radio' name='exportBy' label='Student' value='student' onChange={this.handleExportByOptions} checked={exportBy.option === 'student'} />
              </Form.Group>
            </div>
            <Form.Group>
              {this.renderExportBySubOption()}
            </Form.Group>
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Date Inclusion
          </Form.Label>
          <div className='px-1 px-md-3'>
            <div className='form-inline mb-0 mb-md-3'>
              <Form.Group controlId='edAll' className='mr-2'>
                <Form.Check type='radio' name='exportDate' label='All' value='all' onChange={this.handleExportDateOptions} checked={exportDate.option === 'all'} />
              </Form.Group>
              <Form.Group controlId='edRange' className='mr-2'>
                <Form.Check type='radio' name='exportDate' label='Range' value='range' onChange={this.handleExportDateOptions} checked={exportDate.option === 'range'} />
              </Form.Group>
              <Form.Group controlId='edDate'>
                <Form.Check type='radio' name='exportDate' label='Date' value='date' onChange={this.handleExportDateOptions} checked={exportDate.option === 'date'} />
              </Form.Group>
            </div>
            {this.renderExportDateSubOption()}
          </div>
        </Form.Group>
        {this.renderExportButton()}
      </div>
    );
  }
}