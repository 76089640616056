import React, { Component } from 'react';
import { Alert, Button, Col, Collapse, Form, Image, InputGroup, Modal, ProgressBar, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import PromptDeleteModal from '../../../../modals/PromptDeleteModal/PromptDeleteModal';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import durationFormat from '../../../../../util/helpers/durationFormat';
import Validator from 'validatorjs';
import NameLink from '../../../../common/NameLink/NameLink';
import moment from 'moment';
import axios from 'axios';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

class Submission extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      submissions: [],
      comments: [],
      selectedSubmission: 0,
      gradeModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        grade: ''
      },
      revisionModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      },
      comment: {
        messages: [],
        input: '',
        isLoading: false,
        errorMessage: ''
      },
      editModal: {
        show: false,
        data: null,
        input: '',
        isLoading: false,
        errorMessage: '',
        fileNames: [],
        loadingFiles: false,
        showAttachments: false,
        deleteFiles: [],
        loadingDeleteFiles: []
      },
      deleteModal: {
        show: false,
        data: null,
        isLoading: false,
        errorMessage: ''
      },
      deleteAllModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      },
      fileNames: [],
      loadingFiles: false
    };

    this.files = [];
    this.editFiles = [];
  }
  componentDidMount() {
    const { assignment, submission, match: { params: { classID } } } = this.props;

    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: '',
      selectedSubmission: submission.id
    }, () => {
      axiosRequest('get', `faculty/class/${classID}/material/assignment/${assignment.id}/submission/student/${submission.student_id}`, null, ({ data: { data }}) => {
        let messages = [];

        if (data.comments.length > 0) {
          messages = data.comments.map(c => ({
            data: c,
            revision: '',
            message: c.description,
            type: c.from === 'faculty' ? 'right' : 'left',
            created_at: c.created_at
          }));
        }

        for (let i = 0; i < data.submissions.length; i++) {
          messages.push({
            data: null,
            revision: 'Revision ' + (data.submissions.length-i),
            message: 'Submitted an answer',
            type: 'left',
            created_at: data.submissions[i].created_at
          });

          if (data.submissions[i].created_at !== data.submissions[i].updated_at && data.submissions[i].revise) {
            messages.push({
              data: null,
              revision: 'Revision ' + (data.submissions.length-i),
              message: 'Authorized revision',
              type: 'right',
              created_at: data.submissions[i].updated_at
            });
          }
        }

        let gradeData = assignment.grades.find(grade => grade.student_id === submission.student_id);
        if (gradeData) {
          messages.push({
            data: null,
            revision: '',
            message: 'Submitted a grade',
            type: 'right',
            created_at: gradeData.updated_at
          });
        }
        messages.sort((a, b) => { // SORT DESCENDING
          let momentA = moment(a.created_at);
          let momentB = moment(b.created_at);

          if (momentA.isAfter(momentB)) {
            return -1;
          } else if (momentA.isBefore(momentB)) {
            return 1;
          }

          return 0;
        });
        this.setState({
          ...this.state,
          isLoading: false,
          submissions: data.submissions,
          comments: data.comments,
          comment: {
            ...this.state.comment,
            messages
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  refreshMessages = () => {
    const { submissions, comments } = this.state;
    const { assignment, submission } = this.props;

    let messages = [];

    if (comments.length > 0) {
      messages = comments.map(c => ({
        data: c,
        attempt: '',
        message: c.description,
        type: c.from === 'faculty' ? 'right' : 'left',
        created_at: c.created_at
      }));
    }

    for (let i = 0; i < submissions.length; i++) {
      messages.push({
        data: null,
        revision: 'Revision ' + (submissions.length-i),
        message: 'Submitted an answer',
        type: 'left',
        created_at: submissions[i].created_at
      });

      if (submissions[i].created_at !== submissions[i].updated_at && submissions[i].revise) {
        messages.push({
          data: null,
          revision: 'Revision ' + (submissions.length-i),
          message: 'Authorized revision',
          type: 'right',
          created_at: submissions[i].updated_at
        });
      }
    }

    let gradeData = assignment.grades.find(grade => grade.student_id === submission.student_id);
    if (gradeData) {
      messages.push({
        data: null,
        revision: '',
        message: 'Submitted a grade',
        type: 'right',
        created_at: gradeData.updated_at
      });
    }

    messages.sort((a, b) => { // SORT DESCENDING
      let momentA = moment(a.created_at);
      let momentB = moment(b.created_at);

      if (momentA.isAfter(momentB)) {
        return -1;
      } else if (momentA.isBefore(momentB)) {
        return 1;
      }

      return 0;
    });

    this.setState({
      ...this.state,
      comment: {
        ...this.state.comment,
        messages
      }
    });
  }
  handleSelectSubmission = event => {
    this.setState({
      ...this.state,
      selectedSubmission: event.target.value
    });
  }
  handleFileDownload = fileID => {
    const { selectedSubmission } = this.state;
    const { assignment, match: { params: { classID } } } = this.props;

    axiosRequest('get', `faculty/class/${classID}/material/assignment/${assignment.id}/submission/${selectedSubmission}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handleCommentFileDownload = (comment, fileID) => {
    const { assignment, submission, match: { params: { classID } } } = this.props;

    axiosRequest('get', `faculty/class/${classID}/material/comment/assignment/${assignment.id}/student/${submission.student_id}/${comment.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  showGradeModal = () => {
    const { assignment, submission } = this.props;

    const gradeData = assignment.grades.find(grade => grade.student_id === submission.student_id);
    const grade = gradeData ? gradeData.grade : '';

    this.setState({
      ...this.state,
      gradeModal: {
        ...this.state.gradeModal,
        show: true,
        grade
      }
    });
  }
  hideGradeModal = () => {
    this.setState({
      ...this.state,
      gradeModal: {
        show: false,
        isLoading: false,
        errorMessage: '',
        grade: ''
      }
    });
  }
  handleGrade = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      gradeModal: {
        ...this.state.gradeModal,
        isLoading: true,
        errorMessage: false
      }
    }, () => {
      const { gradeModal } = this.state;
      const { assignment, submission, match: { params: { classID } } } = this.props;

      let validator = new Validator({
        grade: gradeModal.grade
      }, {
        grade: `required|numeric|min:0|max:${assignment.points}`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          gradeModal: {
            ...this.state.gradeModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', `faculty/class/${classID}/material/assignment/${assignment.id}/grade/${submission.student_id}`, {
        grade: gradeModal.grade
      }, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          gradeModal: {
            show: false,
            isLoading: false,
            errorMessage: '',
            grade: ''
          }
        }, () => this.props.onGrade(data, this.refreshMessages));
      }, (error) => {
        this.setState({
          ...this.state,
          gradeModal: {
            ...this.state.gradeModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleGradeInputChange = event => {
    this.setState({
      ...this.state,
      gradeModal: {
        ...this.state.gradeModal,
        grade: event.target.value
      }
    });
  }
  showRevisionModal = () => {
    this.setState({
      ...this.state,
      revisionModal: {
        show: true,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideRevisionModal = () => {
    this.setState({
      ...this.state,
      revisionModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleRevision = () => {
    this.setState({
      ...this.state,
      revisionModal: {
        ...this.state.revisionModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { assignment, submission, match: { params: { classID } } } = this.props;

      axiosRequest('patch', `faculty/class/${classID}/material/assignment/${assignment.id}/revision/${submission.student_id}`, {
        revise: true
      }, ({ data: { data }}) => {
        let submissions = [...this.state.submissions].map(s => {
          if (s.id === submission.id) {
            return {
              ...s,
              revise: true,
              updated_at: data.updated_at
            };
          }

          return s;
        });
        this.setState({
          ...this.state,
          submissions,
          revisionModal: {
            show: false,
            isLoading: false,
            errorMessage: ''
          }
        }, this.refreshMessages);
      }, (error) => {
        this.setState({
          ...this.state,
          revisionModal: {
            ...this.state.revisionModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleCommentInputChange = event => {
    this.setState({
      ...this.state,
      comment: {
        ...this.state.comment,
        input: event.target.value
      }
    });
  }
  handleComment = () => {
    this.setState({
      ...this.state,
      comment: {
        ...this.state.comment,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { comment } = this.state;
      const { assignment, submission, match: { params: { classID } } } = this.props;

      let validator = new Validator({
        comment: comment.input
      }, {
        comment: `required`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          comment: {
            ...this.state.comment,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', `faculty/class/${classID}/material/comment/assignment/${assignment.id}/student/${submission.student_id}`, {
        comment: comment.input
      }, ({ data: { data }}) => {
        if (this.files.length > 0) {
          let fileRequests = [];
          for (let i = 0; i < this.files.length; i++) {
            fileRequests.push(this.uploadFile(i, classID, assignment.id, submission.student_id, data.id));
          }
          Promise.all(fileRequests).then(result => {
            data.files = result;
            this.setState({
              ...this.state,
              comments: [
                ...this.state.comments,
                data
              ],
              comment: {
                ...this.state.comment,
                input: '',
                isLoading: false
              },
              fileNames: [],
              loadingFiles: false
            }, () => {
              this.files = [];
              this.refreshMessages();
            });
          }).catch((error) => {
            this.setState({
              ...this.state,
              comment: {
                ...this.state.comment,
                isLoading: false,
                errorMessage: error
              },
              loadingFiles: false
            });
          });
        } else {
          data.files = [];
          this.setState({
            ...this.state,
            comments: [
              ...this.state.comments,
              data
            ],
            comment: {
              ...this.state.comment,
              input: '',
              isLoading: false
            },
            fileNames: [],
            loadingFiles: false
          }, () => {
            this.files = [];
            this.refreshMessages();
          });
        }
      }, error => {
        this.setState({
          ...this.state,
          comment: {
            ...this.state.comment,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showEditModal = data => {
    this.setState({
      ...this.state,
      editModal: {
        show: true,
        data,
        input: data.description,
        isLoading: false,
        errorMessage: '',
        fileNames: [],
        loadingFiles: false,
        showAttachments: false,
        deleteFiles: [],
        loadingDeleteFiles: []
      }
    });
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        data: null,
        input: '',
        isLoading: false,
        errorMessage: '',
        fileNames: [],
        loadingFiles: false,
        showAttachments: false,
        deleteFiles: [],
        loadingDeleteFiles: []
      }
    });
  }
  handleEditInputChange = event => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        input: event.target.value
      }
    });
  }
  handleEditComment = () => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { editModal } = this.state;
      const { assignment, submission, match: { params: { classID } } } = this.props;

      let validator = new Validator({
        comment: editModal.input
      }, {
        comment: `required`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('patch', `faculty/class/${classID}/material/comment/assignment/${assignment.id}/student/${submission.student_id}/${editModal.data.id}`, {
        comment: editModal.input
      }, ({ data: { data }}) => {
        let comment = [...this.state.comments].find(c => c.id === data.id);
        if (comment) {
          comment = {...comment, ...data};
        } else {
          comment = {...data};
        }

        let uploadRequests = [];
        let deleteRequests = [];

        if (this.editFiles.length > 0) {
          for (let i = 0; i < this.editFiles.length; i++) {
            uploadRequests.push(this.uploadEditFile(i, classID, assignment.id, submission.student_id, data.id));
          }
        }

        if (editModal.deleteFiles.length > 0) {
          for (let i = 0; i < editModal.deleteFiles.length; i++) {
            deleteRequests.push(this.deleteFile(editModal.deleteFiles[i], classID, assignment.id, submission.student_id, data.id));
          }
        }

        if (uploadRequests.length && deleteRequests.length) {
          Promise.all([Promise.all(uploadRequests), Promise.all(deleteRequests)]).then(result => {
            comment.files = [...result[0], ...comment.files];
            comment.files = comment.files.filter(file => result[1].indexOf(file.id) === -1);
            
            let comments = [...this.state.comments].map(c => {
              if (c.id === data.id) {
                return comment;
              }
    
              return c;
            });
            this.setState({
              ...this.state,
              comments,
              editModal: {
                show: false,
                data: null,
                input: '',
                isLoading: false,
                errorMessage: '',
                fileNames: [],
                loadingFiles: false,
                showAttachments: false,
                deleteFiles: [],
                loadingDeleteFiles: []
              }
            }, () => {
              this.editFiles = [];
              this.refreshMessages();
            });

          }).catch((error) => {
            this.setState({
              ...this.state,
              editModal: {
                ...this.state.editModal,
                formError: error,
                isFormLoading: false
              }
            });
          });
        } else if (uploadRequests.length) {
          Promise.all(uploadRequests).then(result => {
            comment.files = [...result, ...comment.files];
            
            let comments = [...this.state.comments].map(c => {
              if (c.id === data.id) {
                return comment;
              }
    
              return c;
            });
            this.setState({
              ...this.state,
              comments,
              editModal: {
                show: false,
                data: null,
                input: '',
                isLoading: false,
                errorMessage: '',
                fileNames: [],
                loadingFiles: false,
                showAttachments: false,
                deleteFiles: [],
                loadingDeleteFiles: []
              }
            }, () => {
              this.editFiles = [];
              this.refreshMessages();
            });
          }).catch((error) => {
            this.setState({
              ...this.state,
              editModal: {
                ...this.state.editModal,
                formError: error,
                isFormLoading: false
              }
            });
          });
        } else if (deleteRequests.length) {
          Promise.all(deleteRequests).then(result => {
            comment.files = comment.files.filter(file => result.indexOf(file.id) === -1);
            
            let comments = [...this.state.comments].map(c => {
              if (c.id === data.id) {
                return comment;
              }
    
              return c;
            });
            this.setState({
              ...this.state,
              comments,
              editModal: {
                show: false,
                data: null,
                input: '',
                isLoading: false,
                errorMessage: '',
                fileNames: [],
                loadingFiles: false,
                showAttachments: false,
                deleteFiles: [],
                loadingDeleteFiles: []
              }
            }, () => {
              this.editFiles = [];
              this.refreshMessages();
            });
          });
        } else {
          let comments = [...this.state.comments].map(c => {
            if (c.id === data.id) {
              return comment;
            }
  
            return c;
          });
          this.setState({
            ...this.state,
            comments,
            editModal: {
              show: false,
              data: null,
              input: '',
              isLoading: false,
              errorMessage: '',
              fileNames: [],
              loadingFiles: false,
              showAttachments: false,
              deleteFiles: [],
              loadingDeleteFiles: []
            }
          }, () => {
            this.editFiles = [];
            this.refreshMessages();
          });
        }
      }, error => {
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showDeleteModal = data => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        data,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        data: null,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleDeleteComment = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { deleteModal } = this.state;
      const { assignment, submission, match: { params: { classID } } } = this.props;

      axiosRequest('delete', `faculty/class/${classID}/material/comment/assignment/${assignment.id}/student/${submission.student_id}/${deleteModal.data.id}`, null, ({ data: { data }}) => {
        let comments = [...this.state.comments].filter(c => c.id !== deleteModal.data.id);
        this.setState({
          ...this.state,
          comments,
          deleteModal: {
            show: false,
            data: null,
            isLoading: false,
            errorMessage: ''
          }
        }, this.refreshMessages);
      }, error => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.files = [...this.files, ...files];
      this.setState({
        ...this.state,
        fileNames: [
          ...this.state.fileNames,
          ...filesState
        ]
      });
    }
  }
  handleEditFileUpload = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false
        };
      });
      this.editFiles = [...this.editFiles, ...files];
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          fileNames: [
            ...this.state.editModal.fileNames,
            ...filesState
          ]
        }
      });
    }
  }
  handleRemoveUpload = id => {
    let newFileNames = [...this.state.fileNames];
    newFileNames.splice(id, 1);

    this.setState({
      ...this.state,
      fileNames: newFileNames
    }, () => {
      this.files.splice(id, 1);
    });
  }
  handleEditRemoveUpload = id => {
    let newFileNames = [...this.state.editModal.fileNames];
    newFileNames.splice(id, 1);

    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        fileNames: newFileNames
      }
    }, () => {
      this.editFiles.splice(id, 1);
    });
  }
  uploadFile = (i, classID, assignmentID, studentID, commentID) => {
    const formData = new window.FormData();
    formData.append('file', this.files[i], this.files[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/comment/assignment/${assignmentID}/student/${studentID}/${commentID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { fileNames } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          fileNames
        });
      }
    }).then(({ data: { data }}) => {
      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        fileNames
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { fileNames } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        fileNames
      });
      throw fileNames[i].isError;
    });
  }
  uploadEditFile = (i, classID, assignmentID, studentID, commentID) => {
    const formData = new window.FormData();
    formData.append('file', this.editFiles[i], this.editFiles[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/comment/assignment/${assignmentID}/student/${studentID}/${commentID}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { editModal: { fileNames } } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            fileNames
          }
        });
      }
    }).then(({ data: { data }}) => {
      const { editModal: { fileNames } } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          fileNames
        }
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { editModal: { fileNames } } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          fileNames
        }
      });
      throw fileNames[i].isError;
    });
  }
  deleteFile = (id, classID, assignmentID, studentID, commentID) => {
    return axios.delete(`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/comment/assignment/${assignmentID}/student/${studentID}/${commentID}/file/${id}`, {
      withCredentials: true,
    }).then(({ data: { data }}) => {
      let newLoadingDeleteFiles = [...this.state.editModal.loadingDeleteFiles].filter(fileID => fileID !== id);
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          loadingDeleteFiles: newLoadingDeleteFiles
        }
      });
      return id;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }
    });
  }
  handleCancelDeleteFile = id => {
    let newDeleteFiles = [...this.state.editModal.deleteFiles].filter(fileID => fileID !== id);
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        deleteFiles: newDeleteFiles
      }
    });
  }
  handleDeleteFile = id => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        deleteFiles: [...this.state.editModal.deleteFiles, id]
      }
    });
  }
  handleShowAttachments = () => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        showAttachments: !this.state.editModal.showAttachments
      }
    });
  }
  showDeleteAllModal = () => {
    this.setState({
      ...this.state,
      deleteAllModal: {
        show: true,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteAllModal = () => {
    this.setState({
      ...this.state,
      deleteAllModal: {
        show: false,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleDeleteAllCommentFiles = () => {
    this.setState({
      ...this.state,
      deleteAllModal: {
        ...this.state.deleteAllModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { assignment, submission, match: { params: { classID } } } = this.props;
      axiosRequest('delete', `faculty/class/${classID}/material/comment/assignment/${assignment.id}/student/${submission.student_id}`, null, ({ data: { message }}) => {
        let newComments = [...this.state.comments].map(c => {
          return {
            ...c,
            files: []
          };
        });
        this.setState({
          ...this.state,
          comments: newComments,
          deleteAllModal: {
            show: false,
            isLoading: false,
            errorMessage: ''
          }
        }, this.refreshMessages);
      }, error => {
        this.setState({
          ...this.state,
          deleteAllModal: {
            ...this.state.deleteAllModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handlePreviewImages = (assignment, submissionData, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/assignment/${assignment.id}/submission/${submissionData.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  handlePreviewCommentImages = (assignment, submission, comment, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/comment/assignment/${assignment.id}/student/${submission.student_id}/${comment.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  renderAttachedFiles = () => {
    const { editModal: { data: comment, showAttachments, deleteFiles, loadingDeleteFiles } } = this.state;

    if (comment && comment.files && comment.files.length > 0) {
      return (
        <>
          <div className={`text-right border rounded-top ${showAttachments ? 'border-bottom-0' : 'rounded-bottom'}`}>
            <Button variant='link' onClick={this.handleShowAttachments} size='sm' block>
              <FontAwesomeIcon icon='paperclip' /> Show {comment.files.length} attachment(s)
            </Button>
          </div>
          <Collapse in={showAttachments}>
            <div className='file-upload-display small'>
            {
              comment.files.map(file => (
                <div className={`file-name d-flex ${deleteFiles.indexOf(file.id) !== -1 ? 'alert-light' : ''}`}>
                  {
                    loadingDeleteFiles.indexOf(file.id) !== -1 && (
                      <LoadingIcon />
                    )
                  }
                  <div className='ml-2 flex-fill'>
                    <div>
                      {file.file_title}
                    </div>
                    {
                      deleteFiles.indexOf(file.id) !== -1 && (
                        <div className='font-italic'>
                          To be deleted
                        </div>
                      )
                    }
                  </div>
                  <div className='ml-2 align-items-center d-flex'>
                    {
                      deleteFiles.indexOf(file.id) !== -1 ? (
                        <Button
                          variant='link'
                          className='text-green'
                          size='sm'
                          title='Cancel delete'
                          onClick={() => this.handleCancelDeleteFile(file.id)}>
                          <FontAwesomeIcon icon='ban' size='sm' />
                        </Button>
                      ) : (
                        <Button
                          variant='link'
                          className='text-danger'
                          size='sm'
                          title='Delete'
                          onClick={() => this.handleDeleteFile(file.id)}>
                          <FontAwesomeIcon icon='trash-alt' size='sm' />
                        </Button>
                      )
                    }
                  </div>
                </div>
              ))
            }
            </div>
          </Collapse>
        </>
      );
    }
  }
  renderEditFileUpload = () => {
    const { editModal: { fileNames, loadingFiles } } = this.state;
    if (fileNames.length > 0) {
      return (
        <Form.Group>
          <Form.Label>File(s) to be uploaded</Form.Label>
          <small className='file-upload-display d-block rounded-top'>
          {
            fileNames.map((fileName, index) => (
              <div key={index} className='file-name d-flex px-2 py-1 align-items-center'>
                <div>
                  {
                    fileName.isLoading || (loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                      <LoadingIcon />
                    ) : fileName.isError ? (
                      <FontAwesomeIcon icon='times-circle' className='text-danger' />
                    ) : fileName.isSuccess ? (
                      <FontAwesomeIcon icon='check-circle' className='text-green' />
                    ) : (
                      <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                    )
                  }
                </div>
                <div className='ml-2 flex-fill' style={{ minWidth: 0 }}>
                  <div className='text-truncate'>
                    {fileName.name}
                  </div>
                  {
                    fileName.isLoading && (
                      <div className='mt-1'>
                        <ProgressBar now={fileName.isLoading} />
                      </div>
                    )
                  }
                </div>
                {
                  ((!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !loadingFiles) || fileName.isError) && (
                    <div className='align-items-center d-flex'>
                      <Button
                        variant='link'
                        className='text-danger'
                        size='sm'
                        title='Remove'
                        onClick={(e) => this.handleEditRemoveUpload(index)}>
                        <FontAwesomeIcon icon='times' size='sm' />
                      </Button>
                    </div>
                  )
                }
              </div>
            ))
          }
          </small>
        </Form.Group>
      );
    }
  }
  renderFileUpload = () => {
    const { fileNames, loadingFiles } = this.state;
    if (fileNames.length > 0) {
      return (
        <small className='file-upload-display d-block rounded-top'>
        {
          fileNames.map((fileName, index) => (
            <div key={index} className='file-name d-flex px-2 py-1 align-items-center'>
              <div>
                {
                  fileName.isLoading || (loadingFiles && (!fileName.isError && !fileName.isSuccess)) ? (
                    <LoadingIcon />
                  ) : fileName.isError ? (
                    <FontAwesomeIcon icon='times-circle' className='text-danger' />
                  ) : fileName.isSuccess ? (
                    <FontAwesomeIcon icon='check-circle' className='text-green' />
                  ) : (
                    <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                  )
                }
              </div>
              <div className='ml-2 flex-fill' style={{ minWidth: 0 }}>
                <div className='text-truncate'>
                  {fileName.name}
                </div>
                {
                  fileName.isLoading && (
                    <div className='mt-1'>
                      <ProgressBar now={fileName.isLoading} />
                    </div>
                  )
                }
              </div>
              {
                ((!fileName.isLoading && !fileName.isError && !fileName.isSuccess && !loadingFiles) || fileName.isError) && (
                  <div className='align-items-center d-flex'>
                    <Button
                      variant='link'
                      className='text-danger'
                      size='sm'
                      title='Remove'
                      onClick={(e) => this.handleRemoveUpload(index)}>
                      <FontAwesomeIcon icon='times' size='sm' />
                    </Button>
                  </div>
                )
              }
            </div>
          ))
        }
        </small>
      );
    }
  }
  renderFiles = comment => {
    const { assignment, match: { params: { classID } }, submission } = this.props;

    if (comment && comment.files && comment.files.length > 0) {
      const images = comment.files.filter(file => {
        return !!file.is_image;
      });
  
      const files = comment.files.filter(file => {
        return !file.is_image;
      });

      return (
        <div>
          {
            images.length > 0 && (
              <Row className='flex-row-reverse' noGutters>
              {
                images.map((image, index) => (
                  <Col key={image.id} md={6} className='question-image-container' style={{ height: '4rem' }} onClick={e => this.handlePreviewCommentImages(assignment, submission, comment, images, index)}>
                    <Image className='submission-comment-image' src={`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/comment/assignment/${assignment.id}/student/${submission.student_id}/${comment.id}/image/${image.id}`} thumbnail />
                  </Col>
                ))
              }
              </Row>
            )
          }
          {
            files.length > 0 && (
              <div className='p-1'>
                {
                  files.map(file => (
                    <small key={file.id} className='d-flex mb-2'>
                      <div className='mr-2'>
                        <FontAwesomeIcon icon='file' className='text-primary' />
                      </div>
                      <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                        <span className='btn-link' onClick={() => this.handleCommentFileDownload(comment, file.id)}>
                          {file.file_title}
                        </span>
                      </div>
                    </small>
                  ))
                }
              </div>
            )
          }
        </div>
      );
    }

    return null;
  }
  renderGraded = submission => {
    const { assignment } = this.props;

    let gradeData = assignment.grades.find((grade) => grade.student_id === submission.student_id);

    if (gradeData) {
      return (
        <div className='text-muted'>
          Grade: {gradeData.grade} / {assignment.points}
        </div>
      );
    }

    return (
      <div className='text-muted'>
        (Not yet graded)
      </div>
    );
  }
  renderSubmissionRemark = submission => {
    const { assignment } = this.props;

    let start = moment(assignment.faculty_load_id ? assignment.until : assignment.audience.until);
    let end = moment(submission.created_at);

    if (end.isAfter(start)) {
      let duration = moment.duration(end.diff(start));
      let humanized = duration.humanize();
      // humanized = humanized.split(' ').map(h => '' + h.charAt(0).toUpperCase() + h.slice(1)).join(' ');
      humanized = humanized.charAt(0).toUpperCase() + humanized.slice(1);
      return (
        <div className='text-danger font-weight-bold px-3'>
          {humanized} late <span className='text-muted font-italic font-weight-normal'>({durationFormat(duration)})</span>
        </div>
      );
    }

    return (
      <div className='text-green font-weight-bold px-3'>
        On time
      </div>
    );
  }
  renderViewSubmission = () => {
    const { errorMessage, submissions, selectedSubmission, gradeModal, revisionModal } = this.state;
    const { submission, assignment, match: { params: { classID } } } = this.props;

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (submissions.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let submissionData = submissions.find(attempt => attempt.id === +selectedSubmission);
    let revisionIndex = submissions.length;

    if (!submissionData) {
      return null;
    }

    const images = submissionData.files ? submissionData.files.filter(file => {
      return !!file.is_image;
    }) : [];

    const files = submissionData.files ? submissionData.files.filter(file => {
      return !file.is_image;
    }) : [];

    return (
      <>
        <div>
          <div className='d-flex justify-content-between'>
            <NameLink
              id={submission.profile.id}
              name={submission.profile.name}
              image={submission.profile.image}
              learningPlatform={submission.profile.student_learning_platform ? submission.profile.student_learning_platform.platform : null}
              rank={submission.profile.rank} />
            <div>
              <Button variant='info' size='sm' title={submissions[0].revise ? 'Already authorized for revision' : 'Authorize revision'} className='mr-2' disabled={submissions[0].revise} onClick={this.showRevisionModal}>
                <FontAwesomeIcon icon='redo' />
              </Button>
              <Button variant='green' size='sm' title='Override grade' onClick={this.showGradeModal}>
                <FontAwesomeIcon icon='pencil-alt' />
              </Button>
            </div>
          </div>
          <div className='text-muted'>
            Revisions: {submissions.length}
          </div>
          {this.renderGraded(submission)}
        </div>
        <div className='dropdown-divider'></div>
        {
          submissions.length > 1 && (
            <Form.Control as='select' value={selectedSubmission} onChange={this.handleSelectSubmission}>
              <option disabled hidden value=''>Select revision...</option>
              {
                submissions.map((s, index) => (
                  <option key={index} value={s.id}>Revision {revisionIndex--}</option>
                ))
              }
            </Form.Control>
          )
        }
        {this.renderSubmissionRemark(submissionData)}
        <div className='text-muted px-3'>
          Submitted on: {moment(submissionData.created_at).format('MMMM D, YYYY hh:mm:ss A')}
        </div>
        <div className='dropdown-divider'></div>
        <div className='p-1 p-md-3' style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
          {submissionData.description}
        </div>
        {
          files.length > 0 && (
            <div className='p-1'>
              {
                files.map(file => (
                  <small key={file.id} className='d-flex mb-2'>
                    <div className='mr-2'>
                      <FontAwesomeIcon icon='file' className='text-primary' />
                    </div>
                    <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                      <span className='btn-link' onClick={() => this.handleFileDownload(file.id)}>
                        {file.file_title}
                      </span>
                    </div>
                  </small>
                ))
              }
            </div>
          )
        }
        {
          images.length > 0 && (
            <Row noGutters>
            {
              images.map((image, index) => (
                <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(assignment, submissionData, images, index)}>
                  <Image className='post-image' src={`${process.env['REACT_APP_API_BASE_URL']}/faculty/class/${classID}/material/assignment/${assignment.id}/submission/${submissionData.id}/image/${image.id}`} thumbnail />
                </Col>
              ))
            }
            </Row>
          )
        }

        <Modal show={gradeModal.show} backdrop='static' onHide={this.hideGradeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{submission.profile.name}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.handleGrade}>
            <Modal.Body>
              {
                gradeModal.errorMessage ? (
                  <Alert variant='danger'>
                    {gradeModal.errorMessage}
                  </Alert>
                ) : (
                  <Alert variant='warning'>
                    Proceeding will <b>delete</b> all the attached files from all the <b>student's submissions</b> and all the <b>feedback</b> files
                  </Alert>
                )
              }
              <div className='h5'>
                {assignment.title}
              </div>
              <Form.Group>
                <Form.Label>Category</Form.Label>
                {
                  assignment.faculty_load_id ? (
                    <Form.Control value={assignment.grade_category.name} disabled />
                  ) : (
                    <Form.Control value={assignment.audience.grade_category.name} disabled />
                  )
                }
              </Form.Group>
              <Form.Group>
                <Form.Label>Grade</Form.Label>
                <InputGroup>
                  <Form.Control type='number' onChange={this.handleInputChange} value={gradeModal.grade} min='0' max={assignment.points} />
                  <InputGroup.Append>
                    <InputGroup.Text>/ {assignment.points}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' className='mr-2' onClick={this.hideGradeModal} disabled={gradeModal.isLoading}>
                Cancel
              </Button>
              <Button variant='green' type='submit' disabled={gradeModal.isLoading}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal show={revisionModal.show} onHide={this.hideRevisionModal}>
          <Modal.Header closeButton>
            <Modal.Title>Authorize Revision</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              revisionModal.errorMessage && (
                <Alert variant='danger'>
                  {revisionModal.errorMessage}
                </Alert>
              )
            }
            <div className='font-weight-bold'>
              Are you sure you want to authorize the student for revision of submission?
            </div>
            <Alert variant='light'>
              {submission.profile.name}
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={this.hideRevisionModal} disabled={revisionModal.isLoading}>
              Cancel
            </Button>
            <Button variant='info' onClick={this.handleRevision} disabled={revisionModal.isLoading}>
              Authorize
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  render() {
    const { isLoading, comment, editModal, deleteModal, deleteAllModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    return (
      <div>
        <Row>
          <Col lg={9}>
            {this.renderViewSubmission()}
          </Col>
          <Col lg={3}>
            <div className='sticky-top pt-2'>
              <div className='rounded border comment-panel'>
                <div className='bg-light p-2 rounded-top border-bottom d-flex align-items-center'>
                  <div className='flex-fill'>
                    <h6 className='m-0'>Feedback</h6>
                  </div>
                  <div className='small'>
                    <Button variant='danger' className='small' size='sm' title='Delete all feedback files' onClick={this.showDeleteAllModal}>
                      <div className='fa-layers'>
                        <FontAwesomeIcon icon={['far', 'file']} />
                        <FontAwesomeIcon icon='trash' inverse transform='shrink-4 down-5 right-5' />
                      </div>
                    </Button>
                  </div>
                </div>
                <div className='comment-text px-2 py-1'>
                  {
                    comment.messages.length > 0 && (
                      <>
                        {
                          comment.messages.map((cm, i) => (
                            <div key={i} className={`d-flex my-1 small ${cm.type === 'left' ? 'justify-content-start' : 'justify-content-end'}`}>
                              <div className={`rounded border text-${cm.type}`} style={{ maxWidth: '85%', minWidth: '70%', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                <div className={`px-2 py-1 text-${cm.type}`} style={{ whiteSpace: 'pre-wrap' }}>
                                  {
                                    cm.revision && (
                                      <div className='font-weight-bold'>{cm.revision}</div>
                                    )
                                  }
                                  <div>{cm.message}</div>
                                  {this.renderFiles(cm.data)}
                                  <div className='font-italic text-muted'>
                                    <div>
                                      {moment(cm.created_at).format('hh:mm:ss A')}
                                    </div>
                                    <div>
                                      {moment(cm.created_at).format('MMMM D, YYYY')}
                                    </div>
                                  </div>
                                </div>
                                {
                                  (cm.data && cm.data.from === 'faculty') && (
                                    <div className='flex-fill d-flex border-top'>
                                      <div className='flex-fill border-right'>
                                        <Button variant='outline-info border-0' size='sm' block onClick={() => this.showEditModal(cm.data)}>
                                          <FontAwesomeIcon icon='pencil-alt' />
                                        </Button>
                                      </div>
                                      <div className='flex-fill'>
                                        <Button variant='outline-danger border-0' size='sm' block onClick={() => this.showDeleteModal(cm.data)}>
                                          <FontAwesomeIcon icon='trash-alt' />
                                        </Button>
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          ))
                        }
                      </>
                    )
                  }
                </div>
                <div className='comment-box p-2 border-top bg-light'>
                  {
                    comment.errorMessage && (
                      <Alert variant='danger' className='small'>
                        {comment.errorMessage}
                      </Alert>
                    )
                  }
                  <InputGroup size='sm'>
                    <InputGroup.Prepend className='border rounded-left'>
                      <label className={`m-0 btn btn-light btn-sm ${comment.isLoading ? 'disabled' : ''}`} htmlFor='add-file' title='File upload'>
                        <FontAwesomeIcon icon='file-upload' />
                      </label>
                      <Form.File className='d-none' id='add-file' onChange={this.handleFileUpload} multiple disabled={comment.isLoading} />
                    </InputGroup.Prepend>
                    <Form.Control as='textarea' rows={1} value={comment.input} onChange={this.handleCommentInputChange} disabled={comment.isLoading} />
                    <InputGroup.Append>
                      <Button variant='green' disabled={comment.isLoading} onClick={this.handleComment}>
                        Publish
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  {this.renderFileUpload()}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Modal show={editModal.show} size='sm' onHide={this.hideEditModal} backdrop='static'>
          <Modal.Header closeButton className='font-weight-bold'>
            <h6 className='m-0'>Edit feedback</h6>
          </Modal.Header>
          <Modal.Body>
            {
              editModal.errorMessage && (
                <Alert variant='danger' className='small'>
                  {editModal.errorMessage}
                </Alert>
              )
            }
            <Form.Group>
              <Form.Control as='textarea' rows={4} value={editModal.input} disabled={editModal.isLoading} onChange={this.handleEditInputChange} />
            </Form.Group>
            {this.renderEditFileUpload()}
            {this.renderAttachedFiles()}
          </Modal.Body>
          <Modal.Footer>
            <div className='mr-auto'>
              <label className={`m-0 btn btn-light btn-sm ${editModal.isLoading ? 'disabled' : ''}`} htmlFor='add-edit-file' title='File upload'>
                <FontAwesomeIcon icon='file-upload' />
              </label>
              <Form.File className='d-none' id='add-edit-file' onChange={this.handleEditFileUpload} multiple disabled={editModal.isLoading} />
            </div>
            <Button variant='danger' size='sm' disabled={editModal.isLoading} onClick={this.hideEditModal}>
              Cancel
            </Button>
            <Button variant='info' size='sm' disabled={editModal.isLoading} onClick={this.handleEditComment}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={deleteModal.show} size='sm' onHide={this.hideDeleteModal}>
          <Modal.Header closeButton className='font-weight-bold'>
            <h6 className='m-0'>Delete feedback</h6>
          </Modal.Header>
          <Modal.Body>
            {
              deleteModal.errorMessage && (
                <Alert variant='danger' className='small'>
                  {deleteModal.errorMessage}
                </Alert>
              )
            }
            {
              deleteModal.data && (
                <>
                  <div className='h6 font-weight-bold small'>
                    Are you sure you want to delete the feedback?
                  </div>
                  <Alert variant='light' className='small'>
                    <div>
                      {deleteModal.data.description}
                    </div>
                    <div className='font-italic'>
                      {moment(deleteModal.data.created_at).format('MMMM D, YYYY hh:mm:ss A')}
                    </div>
                  </Alert>
                </>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='green' size='sm' disabled={deleteModal.isLoading} onClick={this.hideDeleteModal}>
              Cancel
            </Button>
            <Button variant='danger' size='sm' disabled={deleteModal.isLoading} onClick={this.handleDeleteComment}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <PromptDeleteModal
          show={deleteAllModal.show}
          title='Delete all feedback files'
          isLoading={deleteAllModal.isLoading}
          errorMessage={deleteAllModal.errorMessage}
          onHide={this.hideDeleteAllModal}
          onDelete={this.handleDeleteAllCommentFiles}
        >
          <div className='h6 font-weight-bold'>
            Are you sure you want to delete all feedback files?
          </div>
        </PromptDeleteModal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Submission);