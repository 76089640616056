import React, { Component } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import PromptDeleteModal from '../../../modals/PromptDeleteModal/PromptDeleteModal';
import Validator from 'validatorjs';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

const deletables = [
  {
    tableName: 'activityLog',
    title: 'Activity Logs',
    warning: 'Activity logs of all the users of ACTS-VLE, including Web Administrators, School Administrators, Staff, Faculty, Parents, and Students'
  },
  {
    tableName: 'announcements',
    title: 'Announcements',
    warning: 'Announcements created by the School Administrators and Faculty'
  },
  {
    tableName: 'singleMaterials',
    title: 'Single Upload Materials',
    warning: 'Single upload materials including Assignments, Quizzes, Exams, Files, and Links uploaded by the faculty for different classrooms'
  },
  {
    tableName: 'notifications',
    title: 'Notifications',
    warning: 'Notifications (seen or not seen) of all the users of ACTS-VLE, including Web Administrators, School Administrators, Staff, Faculty, Parents, and Students'
  },
  {
    tableName: 'messages',
    title: 'Chat Messages',
    warning: 'Chat messages from all the users of ACTS-VLE, including Web Administrators, School Administrators, Staff, Faculty, Parents, and Students'
  }
];

export default class ClearData extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      count: {},
      deleteModal: {
        show: false,
        title: '',
        isLoading: false,
        errorMessage: '',
        tableName: ''
      }
    };
  }
  componentDidMount() {
    axiosRequest('get', 'admin/transition/sizes', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        count: {
          ...data
        },
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showModal = tableName => {
    const deletable = deletables.find(d => d.tableName === tableName);

    if (deletable) {
      this.setState({
        ...this.state,
        deleteModal: {
          show: true,
          title: 'Delete ' + deletable.title,
          isLoading: false,
          errorMessage: '',
          warning: deletable.warning.toLowerCase(),
          tableName
        }
      });
    }
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        title: '',
        isLoading: false,
        errorMessage: '',
        warning: '',
        tableName: ''
      }
    });
  }
  handleDelete = () => {
    const { deleteModal: { tableName } } = this.state;

    let validator = new Validator({
      table: tableName
    }, {
      table: 'required|string|in:activityLog,announcements,singleMaterials,notifications,messages'
    });

    if (validator.fails()) {
      const firstKey = Object.keys(validator.errors.errors)[0];
      this.setState({
        ...this.state,
        deleteModal: {
          ...this.state.deleteModal,
          errorMessage: validator.errors.errors[firstKey][0]
        }
      });
      return;
    }

    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      axiosRequest('delete', `admin/transition/clear/${tableName}`, null, ({ data: { message }}) => {
        this.setState({
          ...this.state,
          count: {
            ...this.state.count,
            [tableName]: 0
          },
          deleteModal: {
            show: false,
            title: '',
            isLoading: false,
            errorMessage: '',
            warning: '',
            tableName: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      });
    });
  }
  renderContent = () => {
    const { isLoading, errorMessage, count } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        {
          deletables.map(deletable => (
            <div key={deletable.tableName}>
              <div>
                <h5>
                {deletable.title} {count[deletable.tableName] !== undefined ? (
                    <i className='font-weight-normal text-muted'>&mdash;{count[deletable.tableName]} row{count[deletable.tableName] === 1 ? '' : 's'}</i>
                  ) : null}
                </h5>
                <Alert variant='warning'>
                  {deletable.warning}.
                </Alert>
                <Button variant='danger' size='sm' className='ml-3' onClick={e => this.showModal(deletable.tableName)}>
                  <FontAwesomeIcon icon='trash-alt' /> Delete {deletable.title}
                </Button>
              </div>
              <div className='dropdown-divider'></div>
            </div>
          ))
        }
      </>
    );
  }
  render() {
    const { deleteModal } = this.state;

    return (
      <Card>
        <Card.Body>
          { this.renderContent() }
          <PromptDeleteModal {...deleteModal} onHide={this.hideModal} onDelete={this.handleDelete}>
            Are you sure you want to <b>permanently delete</b> all the {deleteModal.warning}?
          </PromptDeleteModal>
        </Card.Body>
      </Card>
    );
  }
}