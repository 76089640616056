import React from 'react';

export default function Students(props) {
  return (
    <>
      <div className='mb-3'>
        The Students area of the classroom is used for viewing the list of the students in the classroom.
      </div>
    </>
  );
}