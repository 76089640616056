import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import axiosRequest from '../../../../util/helpers/axiosRequest';

const inputs = [
  {
    key: 'schoolAdmin',
    title: 'School Administrators'
  },
  {
    key: 'faculty',
    title: 'Faculty'
  },
  {
    key: 'staff',
    title: 'Staff'
  },
  {
    key: 'parent',
    title: 'Parents'
  },
  {
    key: 'student',
    title: 'Students'
  },
];

const inputMap = {
  schoolAdmin: 'School Administrators',
  faculty: 'Faculty',
  staff: 'Staff',
  parent: 'Parents',
  student: 'Students'
};

const formInputMap = {
  allow: 'Allow',
  block: 'Block'
};

export default class AccountType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errorMessage: '',
      successMessage: '',
      formInputs: {
        schoolAdmin: '',
        faculty: '',
        staff: '',
        parent: '',
        student: '',
      },
      showModal: false
    };
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [event.target.name]: event.target.value
      }
    });
  }
  handleReset = () => {
    this.setState({
      ...this.state,
      formInputs: {
        schoolAdmin: '',
        faculty: '',
        staff: '',
        parent: '',
        student: '',
      }
    });
  }
  showModal = () => {
    this.setState({
      ...this.state,
      showModal: true
    });
  }
  hideModal = () => {
    const { successMessage } = this.state;

    if (successMessage) {
      this.setState({
        ...this.state,
        showModal: false,
        formInputs: {
          schoolAdmin: '',
          faculty: '',
          staff: '',
          parent: '',
          student: '',
        },
        successMessage: ''
      });
    } else {
      this.setState({
        ...this.state,
        showModal: false,
        errorMessage: ''
      });
    }
  }
  handleSubmit = () => {
    this.setState({
      ...this.state,
      isLoading: true,
      successMessage: '',
      errorMessage: ''
    }, () => {
      const { formInputs } = this.state;

      axiosRequest('post', 'admin/block-users/account-types', formInputs, ({ data: { message }}) => {
        this.setState({
          ...this.state,
          isLoading: false,
          successMessage: message
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        })
      }, this.props.history);
    });
  }
  renderModalMessage = () => {
    const { formInputs } = this.state;

    let withChanges = Object.keys(formInputs).filter(f => formInputs[f] !== '');

    return (
      <Alert variant='light'>
        {
          withChanges.map((w, i) => (
            (
              <Row key={i}>
                <Col xs={6}>
                  {inputMap[w]}
                </Col>
                <Col xs={6}>
                  {formInputMap[formInputs[w]]}
                </Col>
              </Row>
            )
          ))
        }
      </Alert>
    );
  }
  render() {
    const { isLoading, errorMessage, successMessage, formInputs, showModal } = this.state;

    let withChanges = Object.keys(formInputs).filter(f => formInputs[f] !== '');

    return (
      <Card>
        <Card.Body>
          <Alert variant='warning'>
            All the users with the account type of student, parent, staff, faculty or school administrator
            will be blocked or will be allowed access from/to VLE, respectively.
          </Alert>
          {
            inputs.map((input, i) => (
              <Form.Group key={i}>
                <Form.Label>{input.title}</Form.Label>
                <Form.Control
                  name={input.key}
                  value={formInputs[input.key]}
                  as='select'
                  onChange={this.handleInputChange}>
                  <option value='' className='text-muted font-italic'>(Retain)</option>
                  <option value='allow'>Allow</option>
                  <option value='block'>Block</option>
                </Form.Control>
              </Form.Group>
            ))
          }
          <div className='text-right'>
            <Button variant='danger' onClick={this.handleReset} className='mr-2' disabled={isLoading}>
              Reset
            </Button>
            <Button variant='green' onClick={this.showModal} disabled={isLoading || withChanges.length === 0}>
              Save
            </Button>
          </div>
          <Modal show={showModal} onHide={this.hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>Allow or Block Account Types</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                errorMessage ? (
                  <Alert variant='danger'>
                    {errorMessage}
                  </Alert>
                ) : successMessage ? (
                  <Alert variant='success'>
                    {successMessage}
                  </Alert>
                ) : null
              }
              <Card.Subtitle>
                Changes to these account types will override their active status:
              </Card.Subtitle>
              {this.renderModalMessage()}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' onClick={this.hideModal} className='mr-2' disabled={isLoading}>
                {successMessage ? 'Close' : 'Cancel'}
              </Button>
              <Button variant='green' onClick={this.handleSubmit} disabled={isLoading || successMessage}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      </Card>
    );
  }
}