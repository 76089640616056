import React, { Component } from 'react';
import { connect } from 'react-redux';
import { seenBirthday } from '../../../actions';
import { Alert, Button, Card, Carousel, Col, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Confetti from 'react-confetti';
import axiosRequest from '../../../util/helpers/axiosRequest';
import profileDefault from '../../../resources/image/profile-default.png';
import birthdayCake from '../../../resources/image/birthday-cake.png';
import moment from 'moment';
import './style.scss';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

class Events extends Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      events: [],
      birthdays: [],
      birthdayContainer: {
        width: 0,
        height: 0
      },
      showConfetti: true
    };

    this.birthdayContainer = null;
  }
  componentDidMount() {
    axiosRequest('get', 'events', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false,
      }, () => {
        this.updateBirthdayContainer();
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
    window.addEventListener('resize', () => this.updateBirthdayContainer());
  }
  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateBirthdayContainer());
  }
  updateBirthdayContainer = () => {
    if (this.birthdayContainer) {
      this.setState({
        ...this.state,
        birthdayContainer: {
          width: this.birthdayContainer.getBoundingClientRect().width,
          height: this.birthdayContainer.getBoundingClientRect().height,
        }
      });
    }
  }
  handleManage = () => {
    const { history, match: { params: { userType } } } = this.props;

    history.push(`/${userType}/events`);
  }
  renderStart = (start, end) => {
    let momentStart = moment(start);

    if (start === end) {
      if (moment().isSame(momentStart, 'day')) {
        return (
          <span>Today</span>
        );
      } else if (moment().add(1, 'day').isSame(momentStart, 'day')) {
        return (
          <span>Tomorrow</span>
        );
      }

      return (
        <span>{momentStart.format('ddd MMM D, YYYY')}</span>
      );
    }

    if (moment().subtract(1, 'day').isSame(momentStart, 'day')) {
      return (
        <span>Started yesterday</span>
      );
    } else if (moment().add(1, 'day').isSame(momentStart, 'day')) {
      return (
        <span>Starts tomorrow</span>
      );
    } else if (moment().isAfter(momentStart, 'day')) {
      return (
        <span>Started from {momentStart.format('ddd MMM D, YYYY')}</span>
      );
    } else if (moment().isSame(momentStart, 'day')) {
      return (
        <span>Starts today</span>
      );
    }

    return (
      <span>Starts on {momentStart.format('ddd MMM D, YYYY')}</span>
    );
  }
  renderEnd = (start, end) => {
    let momentEnd = moment(end);

    if (start === end) {
      return null;
    }

    if (moment().add(1, 'day').isSame(momentEnd, 'day')) {
      return (
        <span>Ends tomorrrow</span>
      );
    } else if (moment().isBefore(momentEnd, 'day')) {
      return (
        <span>Ends on {momentEnd.format('ddd MMM D, YYYY')}</span>
      );
    } else if (moment().isSame(momentEnd, 'day')) {
      return (
        <span>Ends today</span>
      );
    }

    return null;
  }
  renderEvents = () => {
    const { events } = this.state;

    if (events.length === 0) {
      return null;
    }

    let today = events.filter(e => moment().isBetween(moment(e.start), moment(e.end), 'day', '[]'));
    let other = events.filter(e => [...today.map(s => s.id)].indexOf(e.id) === -1);
    other.sort((a, b) => {
      let momentA = moment(a.start);
      let momentB = moment(b.start);

      if (momentA.isBefore(momentB, 'day')) {
        return -1;
      } else if (momentA.isAfter(momentB, 'day')) {
        return 1;
      }

      return 0;
    });

    return (
      <Carousel.Item>
        <div className='upcoming-container' style={{ fontSize: '.875rem', maxHeight: '16rem', overflowY: 'auto' }}>
          {
            (today && today.length > 0) && (
              <div className='mb-3'>
                <div className='font-weight-bold text-muted px-2'>Today <span className='font-weight-normal font-italic'>({moment().format('ddd MMM D, YYYY')})</span></div>
                <div className='border-bottom'></div>
                <div className='upcoming-event-list'>
                  {
                    today.map(e => (
                      <div key={e.id} className='py-1 px-3' title={e.description}>
                        <div className='font-weight-bold'>
                          {e.title}
                        </div>
                        <div className='small font-text-muted'>
                          {this.renderStart(e.start, e.end)}
                        </div>
                        <div className='small font-text-muted'>
                          {this.renderEnd(e.start, e.end)}
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            )
          }
          {
            (other && other.length > 0) && (
              <div className='mb-3'>
                <div className='font-weight-bold text-muted px-2'>Upcoming this week</div>
                <div className='border-bottom'></div>
                <div className='upcoming-event-list'>
                  {
                    other.map(e => (
                      <div key={e.id} className='py-1 px-3' title={e.description}>
                        <div className='font-weight-bold'>
                          {e.title}
                        </div>
                        <div className='small font-text-muted'>
                          {this.renderStart(e.start, e.end)}
                        </div>
                        <div className='small font-text-muted'>
                          {this.renderEnd(e.start, e.end)}
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            )
          }
        </div>
      </Carousel.Item>
    );
  }
  renderBirthdays = () => {
    const { birthdays } = this.state;

    return birthdays.map(b => (
      <Carousel.Item key={`birthday-${b.id}`}>
        {this.renderBirthdayCard(b)}
      </Carousel.Item>
    ));
  }
  renderBirthdayCard = celebrant => {
    const { birthdayContainer } = this.state;

    return (
      <div className='p-2 rounded birthday-container position-relative'>
        <div className='h4 sticky-top bg-white' style={{ zIndex: 1 }}>
          <div className='d-flex'>
            <div className='balloon mr-2'></div>
            <div className='flex-fill'>
              <div className='text-left wish-title'>
                <span>H</span>
                <span>a</span>
                <span>p</span>
                <span>p</span>
                <span>y</span>
                <span className='ml-1'>B</span>
                <span>i</span>
                <span>r</span>
                <span>t</span>
                <span>h</span>
                <span>d</span>
                <span>a</span>
                <span>y</span>
              </div>
              <div className='text-right wish-title'>
                <span>a</span>
                <span>n</span>
                <span>d</span>
                <span className='ml-1'>G</span>
                <span>O</span>
                <span>D</span>
                <span className='ml-1'>b</span>
                <span>l</span>
                <span>e</span>
                <span>s</span>
                <span>s</span>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ maxHeight: '14rem', overflowY: 'auto' }}>
          <Row className='d-flex' noGutters>
            <Col xs={5}>
              <div>
                <Image
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                  }}
                  src={celebrant.image ? `${process.env['REACT_APP_API_BASE_URL']}/profile/${celebrant.id}/${celebrant.image}` : profileDefault}
                  onLoad={this.updateBirthdayContainer}
                  rounded
                  thumbnail />
              </div>
            </Col>
            <Col>
              <div className='pl-2 h5 d-flex flex-column'>
                <div>
                  {celebrant.name}
                  {
                    celebrant.position && (
                      <div className='text-muted font-italic font-weight-normal' style={{ fontSize: '1rem'}}>
                        {celebrant.position}
                      </div>
                    )
                  }
                </div>
                <div className='flex-fill'>
                  <div className='text-center pt-3 h-100'>
                    <Image
                      style={{
                        width: '50%',
                        height: '50%',
                        objectFit: 'contain'
                      }}
                      className='birthday-cake'
                      src={birthdayCake} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='h6 text-right text-primary position-relative mb-3'>
          <span>&mdash;</span>
          <span>F</span>
          <span>r</span>
          <span>o</span>
          <span>m</span>
          <span className='ml-1'>y</span>
          <span>o</span>
          <span>u</span>
          <span>r</span>
          <span className='ml-1'>A</span>
          <span>C</span>
          <span>T</span>
          <span>S</span>
          <span className='ml-1'>K</span>
          <span>a</span>
          <span>p</span>
          <span>a</span>
          <span>m</span>
          <span>i</span>
          <span>l</span>
          <span>y</span>
          <span>a</span>
        </div>
        <Confetti
          width={birthdayContainer.width}
          height={birthdayContainer.height}
          numberOfPieces={50} />
      </div>
    );
  }
  renderContent = () => {
    const { isLoading, errorMessage, events, birthdays, showConfetti } = this.state;
    const { currentUser, birthdaySeen } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (events.length === 0 && birthdays.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    let userCelebrant = birthdays.length > 0 && birthdays.find(b => b.id === currentUser.id);

    return (
      <>
        <div className='events-content' ref={ref => {
          if (ref) {
            this.birthdayContainer = ref;
          }
        }}>
          <Carousel
            prevIcon={<FontAwesomeIcon icon='chevron-left' className='text-primary' />}
            nextIcon={<FontAwesomeIcon icon='chevron-right' className='text-primary' />}
            interval={4000}
            onSlid={this.updateBirthdayContainer}
            defaultActiveIndex={userCelebrant && !birthdaySeen && showConfetti && events.length > 0 ? 1 : 0}
          >
            {this.renderEvents()}
            {this.renderBirthdays()}
          </Carousel>
        </div>
        {
          (userCelebrant && !birthdaySeen && showConfetti) && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1
              }}
              onClick={() => {
                this.setState({
                  ...this.state,
                  showConfetti: false
                }, this.props.seenBirthday);
              }}>
              <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                confettiSource={{
                  x: window.innerWidth/2 - window.innerWidth/4,
                  y: window.innerHeight - window.innerWidth/4,
                  w: window.innerWidth/2,
                  h: window.innerHeight/2
                }}
                initialVelocityY={{
                  min: 0,
                  max: -20
                }}
                numberOfPieces={500}
                tweenDuration={1000}
                recycle={false}
                onConfettiComplete={() => {
                  this.setState({
                    ...this.state,
                    showConfetti: false
                  }, this.props.seenBirthday);
                }} />
            </div>
          )
        }
      </>
    );
  }
  render() {
    const { userType } = this.props.match.params;
    const { isLoading, errorMessage } = this.state;

    return (
      <>
        <Card className='mt-3'>
          <Card.Body>
            <div className='events'>
              <div className='d-flex align-items-center'>
                <div className='h5 mb-0'>
                  <span className='d-none d-md-inline-block'><FontAwesomeIcon icon='calendar-week' /></span> Events
                </div>
                {
                  (userType && (userType === 'school-admin' || userType === 'faculty' || userType === 'staff')) && (
                    <div className='ml-auto'>
                      <Button variant='green' onClick={this.handleManage} disabled={isLoading || errorMessage} size='sm'>
                        <FontAwesomeIcon icon='cog' />
                        <span className='ml-1 d-none d-md-inline-block'>Manage</span>
                      </Button>
                    </div>
                  )
                }
              </div>
              <div className='dropdown-divider'></div>
              {this.renderContent()}
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => ({
  birthdaySeen: state.birthdaySeen,
  currentUser: state.currentUser
});

const mapDispatchToProps = dispatch => ({
  seenBirthday: () => dispatch(seenBirthday())
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);