import React, { Component } from 'react';
import { Alert, Breadcrumb, Button, Form, ListGroup, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../util/helpers/axiosRequest';
import NameLink from '../../common/NameLink/NameLink';
import moment from 'moment';
import './style.scss';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

export default class DayQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      isFormLoading: false,
      formError: '',
      question: null,
      answer: '',
      ownScore: 0,
      ranking: [],
      showModal: false,
      view: 'question',
      isRankLoading: false,
      rankError: '',
      answers: {
        data: []
      },
      questions: [],
      viewAnswer: null,
      isAnswerLoading: false,
      answerError: '',
      isNextPageLoading: false,
      nextPageError: ''
    };
  }
  componentDidMount() {
    const { currentUser } = this.props;
    axiosRequest('get', 'day-question', null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false,
        showModal: currentUser.account_type === 5 && data.question && !data.question.answer
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  retryFetch = () => {
    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: ''
    }, () => {
      const { currentUser } = this.props;
      axiosRequest('get', 'day-question', null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          ...data,
          isLoading: false,
          showModal: currentUser.account_type === 5 && data.question && !data.question.answer
        });
      }, error => {
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  refreshAnswers = () => {
    this.setState({
      ...this.state,
      isAnswerLoading: true,
      answerError: ''
    }, () => {
      axiosRequest('get', 'day-question', null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          ...data,
          isAnswerLoading: false,
        });
      }, error => {
        this.setState({
          ...this.state,
          isAnswerLoading: false,
          answerError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  fetchNextAnswers = () => {
    if (this.state.isNextPageLoading) {
      return false;
    }

    this.setState({
      ...this.state,
      isNextPageLoading: true,
      nextPageError: ''
    }, () => {
      const { answers } = this.state;

      const path = answers.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');

      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          answers: {
            ...data.answers,
            data: [
              ...this.state.answers.data,
              ...data.answers.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, error => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showModal = () => {
    this.setState({
      ...this.state,
      showModal: true
    });
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      showModal: false
    });
  }
  changeView = (view, answer = null) => {
    if (view === 'ranking') {
      this.setState({
        ...this.state,
        view,
        isRankLoading: true,
        rankError: ''
      }, () => {
        axiosRequest('get', 'day-question/ranking', null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            ...data,
            isRankLoading: false
          });
        }, error => {
          this.setState({
            ...this.state,
            isRankLoading: false,
            rankError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    } else if (view === 'answer') {
      this.setState({
        ...this.state,
        view,
        viewAnswer: answer
      });
    } else {
      this.setState({
        ...this.state,
        view
      });
    }
  }
  handleInputChange = e => {
    this.setState({
      ...this.state,
      answer: e.target.value
    });
  }
  handleAnswer = e => {
    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: ''
    }, () => {
      const { question, answer } = this.state;

      if (!answer || !answer.trim()) {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: 'Answer is required.'
        });
        return;
      }

      axiosRequest('post', `day-question/${question.id}`, { answer }, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          question: {
            ...this.state.question,
            ...data
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  renderRanking = () => {
    const { isRankLoading, rankError, ranking, ownScore } = this.state;
    const { currentUser } = this.props;

    if (isRankLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (rankError) {
      return (
        <Alert variant='danger'>
          {rankError}
        </Alert>
      );
    }

    return (
      <>
        <h4>
          <FontAwesomeIcon icon='ol-list' /> Ranking
        </h4>
        {
          currentUser.account_type === 5 && (
            <div className='mb-1 font-italic'>
              Current score: <b>{ownScore}</b>
            </div>
          )
        }
        {
          ranking.length === 0 ? (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          ) : (
            <Table size='sm' responsive striped hover className='day-question-rank-table'>
              {ranking.map((st, i) => (
                <tr key={st.student_id} className={i === 0 ? 'gold' : i === 1 ? 'silver' : i === 2 ? 'bronze' : 'green'}>
                  <td className='align-middle'>
                    <FontAwesomeIcon icon={i <= 2 ? 'crown' : 'medal'} />
                  </td>
                  <td className='w-100'>
                    <div className='d-flex'>
                      <NameLink id={st.profile.id} name={st.profile.name} image={st.profile.image} />
                    </div>
                  </td>
                  <td className='align-middle'>
                    {st.score}
                  </td>
                </tr>
              ))}
            </Table>
          )
        }
      </>
    );
  }
  renderModalBody = () => {
    const {
      question,
      answer,
      view,
      isFormLoading,
      formError,
      questions,
      answers,
      viewAnswer,
      isAnswerLoading,
      answerError,
      isNextPageLoading,
      nextPageError
    } = this.state;
    const { currentUser } = this.props;

    if (view === 'ranking') {
      return this.renderRanking();
    } else if (view === 'answer') {
      let question = questions.find(q => q.id === viewAnswer.day_question_id);
      if (!question) {
        return (
          <Alert variant='light'>
            Nothing to show.
          </Alert>
        );
      }
      let choice = question.choices.find(c => c.id === viewAnswer.day_question_choice_id);
      if (!choice) {
        return (
          <Alert variant='light'>
            Nothing to show.
          </Alert>
        );
      }

      return (
        <>
          <Breadcrumb>
            <Breadcrumb.Item onClick={e => this.changeView('question')}>Answers</Breadcrumb.Item>
            <Breadcrumb.Item active>{viewAnswer ? viewAnswer.profile.name : 'Student'}</Breadcrumb.Item>
          </Breadcrumb>
          <RichTextEditor.Viewer body={question.question} />
          {/* <div className='font-weight-bold'>
            {question.question}
          </div> */}
          <div className='font-italic text-muted'>
            Student's answer: <b>{choice.choice}</b>
          </div>
          <ul>
            {
              question.choices.map(c => {
                if (c.is_correct) {
                  return (
                    <li key={c.id}>
                      {c.choice} <FontAwesomeIcon icon='check' className='text-green' />
                    </li>
                  );
                }

                if (!viewAnswer.is_correct) {
                  return (
                    <li key={c.id}>
                      {c.choice} <FontAwesomeIcon icon='times' className='text-danger' />
                    </li>
                  );
                }

                return (
                  <li key={c.id}>
                    {c.choice}
                  </li>
                );
              })
            }
          </ul>
        </>
      );
    }

    if (currentUser.account_type === 4) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    if (!question) {
      return (
        <Alert variant='light'>
          No question for today.
        </Alert>
      );
    }

    switch(currentUser.account_type) {
      case 1:
      case 2:
      case 3:
        return (
          <>
            {
              questions.length === 1 ? (
                <Alert variant='warning'>
                  <RichTextEditor.Viewer body={questions[0].question} />
                  {/* <div className='font-weight-bold'>
                    {
                      questions[0].question
                    }
                  </div> */}
                  <ul>
                    {
                      questions[0].choices.map(c => (
                        <li key={c.id}>
                          {c.choice}
                          {
                            c.is_correct && (
                              <FontAwesomeIcon icon='check' className='text-green ml-1' />
                            )
                          }
                        </li>
                      ))
                    }
                  </ul>
                </Alert>
              ) : questions.length > 1 ? (
                <Alert variant='warning'>
                  <b>{questions.length} questions</b> are randomized
                </Alert>
              ) : null
            }
            <h5>
              Answers
              <Button variant='light' className='ml-1' title='Refresh' size='sm' onClick={this.refreshAnswers} disabled={isAnswerLoading}>
                <FontAwesomeIcon icon='sync-alt' spin={isAnswerLoading} />
              </Button>
            </h5>
            <div className='dropdown-divider'></div>
            {
              answerError && (
                <Alert variant='danger'>
                  {answerError}
                </Alert>
              )
            }
            {
              answers.data.length === 0 ? (
                <Alert variant='light'>
                  No answers yet.
                </Alert>
              ) : (
                <ListGroup className='day-question-answers'>
                  {
                    answers.data.map(answer => {
                      return (
                        <ListGroup.Item key={answer.id} className='day-question-answer' title='View answer' onClick={e => this.changeView('answer', answer)}>
                          <div className='d-flex'>
                            <div className='flex-fill'>
                              <div>
                                <NameLink id={answer.profile.id} name={answer.profile.name} image={answer.profile.image} rank={answer.profile.rank} disableLink />
                              </div>
                              {
                                answer.is_correct ? (
                                  <div className='text-success'>
                                    <FontAwesomeIcon icon='check' /> Correct
                                  </div>
                                ) : (
                                  <div className='text-danger'>
                                    <FontAwesomeIcon icon='times' /> Incorrect
                                  </div>
                                )
                              }
                            </div>
                            <div className='pl-2 d-flex flex-column justify-content-between'>
                              <div className='text-muted font-italic text-right small mb-2'>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {moment(answer.created_at).format('MMM D, YYYY hh:mm A')}
                                    </Tooltip>
                                  }
                                  trigger={['hover', 'focus']}>
                                  <FontAwesomeIcon icon='clock' />
                                </OverlayTrigger> {moment(answer.created_at).fromNow()}
                              </div>
                              <div className='text-right text-green'>
                                <FontAwesomeIcon icon='chevron-right' />
                              </div>
                            </div>
                          </div>
                        </ListGroup.Item>
                      )
                    })
                  }
                  {
                    answers.next_page_url && (
                      <ListGroup.Item className='day-question-answer' onClick={this.fetchNextAnswers}>
                        <div className='text-center text-primary'>
                          Show more...
                          {
                            isNextPageLoading && (
                              <LoadingIcon className='ml-1' />
                            )
                          }
                        </div>
                        {
                          nextPageError && (
                            <Alert variant='danger'>
                              {nextPageError}
                            </Alert>
                          )
                        }
                      </ListGroup.Item>
                    )
                  }
                </ListGroup>
              )
            }
          </>
        );
      case 5:
        return (
          <div>
            {
              isFormLoading ? (
                <Alert variant='warning'>
                  <LoadingIcon /> Submitting answer...
                </Alert>
              ) : formError ? (
                <Alert variant='danger'>
                  {formError}
                </Alert>
              ) : null
            }
            <RichTextEditor.Viewer body={question.question} className='mb-1' />
            {/* <div className='font-weight-bold mb-1'>
              {question.question}
            </div> */}
            {
              question.answer ? (
                <>
                  {
                    question.answer.is_correct ? (
                      <Alert variant='success'>
                        Your answer is <b>correct</b>: {question.answer.choice.choice}
                      </Alert>
                    ) : (
                      <Alert variant='danger'>
                        <div>
                          Your answer is <b>incorrect</b>: {question.answer.choice.choice}
                        </div>
                        <div>
                          Correct answer: <b>{question.correct_answer.choice}</b>
                        </div>
                      </Alert>
                    )
                  }
                </>
              ) : (
                <div className='px-2'>
                  {
                    question.choices.map(choice => (
                      <Form.Check
                        type='radio'
                        name='choices'
                        id={`choice-${choice.id}`}
                        value={choice.id}
                        label={choice.choice}
                        onChange={this.handleInputChange}
                        checked={choice.id === +answer} />
                    ))
                  }
                </div>
              )
            }
          </div>
        );
      default:
        return null;
    }
  }
  renderButton = () => {
    const { question } = this.state;
    const { currentUser } = this.props;

    return (
      <Button variant='green' className='day-question-float shadow-lg' title='Question of the Day' onClick={this.showModal}>
        <span className='fa-layers fa-fw'>
          <FontAwesomeIcon icon='sun' transform='grow-5' enableBackground />
          <FontAwesomeIcon icon='circle' transform='shrink-4' />
          <FontAwesomeIcon icon='question' transform='shrink-5' className='text-green' />
          {
            (currentUser.account_type === 5 && question && !question.answer) && (
              <span className='fa-layers-counter fa-fw fa-2x bg-danger' style={{ transformOrigin: '1.875em -0.2875em'}}>
                !
              </span>
            )
          }
        </span>
      </Button>
    );
  }
  render() {
    const { isLoading, errorMessage, showModal, view, question, isFormLoading } = this.state;
    const { currentUser } = this.props;

    if (isLoading) {
      return (
        <Button variant='light' className='day-question-float shadow-lg' disabled>
          <LoadingIcon /> <span className='fa-layers fa-fw'><FontAwesomeIcon icon='sun' transform='grow-5' enableBackground /><FontAwesomeIcon icon='circle' transform='shrink-4' /><FontAwesomeIcon icon='question' transform='shrink-5' inverse /></span>
        </Button>
      );
    }

    if (errorMessage) {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip>
              {errorMessage}
            </Tooltip>
          }
          trigger={['hover', 'focus']}>
          <Button variant='danger' className='day-question-float shadow-lg error-button' onClick={this.retryFetch}>
            <span className='notif-icon'><FontAwesomeIcon icon='exclamation' /> <span className='fa-layers fa-fw'><FontAwesomeIcon icon='sun' transform='grow-5' enableBackground /><FontAwesomeIcon icon='circle' transform='shrink-4' /><FontAwesomeIcon icon='question' transform='shrink-5' className='text-danger' /></span></span> <span className='refresh-icon'><FontAwesomeIcon icon='redo' /> Retry</span>
          </Button>
        </OverlayTrigger>
      );
    }

    return (
      <>
        {this.renderButton()}
        <Modal show={showModal} onHide={this.hideModal} backdrop={isFormLoading ? 'static' : true}>
          <Modal.Header closeButton>
            <Modal.Title>Question of the Day</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderModalBody()}
          </Modal.Body>
          <Modal.Footer>
            {
              currentUser.account_type === 3 && (
                <a href='staff/question-of-the-day' className='btn btn-green' title='Manage'>
                  <FontAwesomeIcon icon='cog' /> <span className='d-none d-lg-inline-block'>Manage</span>
                </a>
              )
            }
            {
              view === 'question' ? (
                <Button variant='info' title='Ranking' onClick={() => this.changeView('ranking')} disabled={isFormLoading}>
                  <FontAwesomeIcon icon='list-ol' />
                </Button>
              ) : view === 'ranking' ? (
                <Button variant='info' title='Question' onClick={() => this.changeView('question')} disabled={isFormLoading}>
                  <span className='fa-layers fa-fw'>
                    <FontAwesomeIcon icon='sun' transform='grow-5' enableBackground />
                    <FontAwesomeIcon icon='circle' transform='shrink-4' />
                    <FontAwesomeIcon icon='question' transform='shrink-5' className='text-info' />
                  </span>
                </Button>
              ) : null
            }
            <Button variant='light' onClick={this.hideModal} className='ml-auto' disabled={isFormLoading}>
              Close
            </Button>
            {
              (question && !question.answer && view === 'question' && currentUser.account_type === 5) && (
                <Button variant='green' onClick={this.handleAnswer} disabled={isFormLoading}>
                  Submit
                </Button>
              )
            }
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}