import React, { Component } from 'react';
import { Alert, Button, Col, Image, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import './style.scss';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../../common/RichTextEditor/RichTextEditor';

const questionTypeMap = {
  trueFalse: 'True/False',
  multipleChoice: 'Multiple Choice',
  ordering: 'Ordering',
  essay: 'Essay/Problem Solving',
  matching: 'Matching',
  identification: 'Identification',
  enumeration: 'Enumeration'
};

const questionRelationTypeMap = {
  trueFalse: 'question_boolean',
  multipleChoice: 'question_multiple_choice',
  ordering: 'question_ordering',
  essay: 'question_essay',
  matching: 'question_matching',
  identification: 'question_identification',
  enumeration: 'question_enumeration'
};

class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: {
        data: []
      },
      isLoading: true,
      errorMessage: '',
      isNextPageLoading: false,
      nextPageError: '',
      viewModal: {
        show: false,
        index: 0,
        question: null
      }
    };
  }
  componentDidMount() {
    const { classID } = this.props.match.params;
    const { data, type } = this.props;

    axiosRequest('get', `school-admin/class/${classID}/material/${type}/${data.id}/questions`, null, ({ data: { data }}) => {
      data.data = data.data.map(q => {
        q.question = q[questionRelationTypeMap[q.type]];

        return q;
      });
      this.setState({
        ...this.state,
        questions: data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { questions } = this.state;

      const path = questions.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        let newQuestions = data.data.map(q => {
          q.question = q[questionRelationTypeMap[q.type]];

          return q;
        });
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: '',
          questions: {
            ...data,
            data: [
              ...this.state.questions.data,
              ...newQuestions
            ]
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleFileDownload = fileID => {
    const { viewModal: { question } } = this.state;
    const { data: materialData, match: { params: { classID } } } = this.props;

    axiosRequest('get', `school-admin/class/${classID}/material/${materialData.type}/${materialData.id}/questions/${question.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  showViewModal = (question, index) => {
    this.setState({
      ...this.state,
      viewModal: {
        show: true,
        index,
        question
      }
    });
  }
  hideViewModal = () => {
    this.setState({
      ...this.state,
      viewModal: {
        show: false,
        index: 0,
        question: null
      }
    });
  }
  handlePreviewImages = (materialData, question, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/school-admin/class/${classID}/material/${materialData.type}/${materialData.id}/questions/${question.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  renderAnswer = () => {
    const { viewModal: { question } } = this.state;

    switch (question.type) {
      case 'trueFalse':
        return (
          <div>
            <div className='d-flex'>
              <div className='fa-fw mr-1'>
                {
                  question.question.correct_answer && (
                    <FontAwesomeIcon icon='check' className='text-green' />
                  )
                }
              </div>
              <div>
                True
              </div>
            </div>
            <div className='d-flex'>
              <div className='fa-fw mr-1'>
                {
                  !question.question.correct_answer && (
                    <FontAwesomeIcon icon='check' className='text-green' />
                  )
                }
              </div>
              <div>
                False
              </div>
            </div>
          </div>
        );
      case 'multipleChoice':
        return (
          <div>
            {
              question.question.choices.map((choice, i) => (
                <div key={i} className='d-flex'>
                  <div className='mr-2 font-weight-bold'>
                    {i+1}
                  </div>
                  <div className='fa-fw mr-1'>
                    {
                      choice.is_correct && (
                        <FontAwesomeIcon icon='check' className='text-green' />
                      )
                    }
                  </div>
                  <div>
                    {choice.description}
                  </div>
                </div>
              ))
            }
          </div>
        );
      case 'ordering':
        let items = question.question.items.sort((a,b) => a.order - b.order);
        return (
          <div>
            {
              items.map((item, i) => (
                <div key={i} className='d-flex'>
                  <div className='mr-2 font-weight-bold'>
                    {i+1}
                  </div>
                  <div>
                    {item.description}
                  </div>
                </div>
              ))
            }
          </div>
        );
      case 'matching':
        return (
          <div>
            {
              question.question.items.map((item, i) => (
                <div key={i} className='d-flex'>
                  <div className='mr-2 font-weight-bold'>
                    {i+1}
                  </div>
                  <div className='flex-fill'>
                    <div>
                      {item.description}
                    </div>
                    <div className='pl-1 d-flex'>
                      <div className='mr-1'>
                        <FontAwesomeIcon icon='long-arrow-alt-right' />
                      </div>
                      <div className='flex-fill'>
                        {item.answer}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        );
      case 'identification':
        return (
          <div>
            {
              question.question.items.map((item, i) => (
                <div key={i} className='d-flex'>
                  <div className='mr-2 font-weight-bold'>
                    {i+1}
                  </div>
                  <div className='flex-fill'>
                    <div>
                      {item.answer}
                    </div>
                    <div className='pl-1 d-flex'>
                      <div className='mr-1'>
                        <FontAwesomeIcon icon='long-arrow-alt-right' />
                      </div>
                      <div className='flex-fill'>
                        {item.description}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        );
      case 'enumeration':
        return (
          <div>
            {
              question.question.items.map((item, i) => (
                <div key={i} className='d-flex'>
                  <div className='mr-2 font-weight-bold'>
                    {i+1}
                  </div>
                  <div>
                    {item.description}
                  </div>
                </div>
              ))
            }
          </div>
        );
      default:
        return null;
    }
  }
  renderQuestionView = () => {
    const { viewModal: { question } } = this.state;
    const { data: materialData, match: { params: { classID } } } = this.props;

    if (!question) {
      return null;
    }

    let images = question.files && question.files.length > 0 ? question.files.filter(file => {
      return !!file.is_image;
    }) : [];

    let files = question.files && question.files.length > 0 ? question.files.filter(file => {
      return !file.is_image;
    }) : [];

    return (
      <div>
        <RichTextEditor.Viewer body={question.question.description} className='mb-1' />
        {/* <div className='mb-1'>{question.question.description}</div> */}
        {
          question.files.length > 0 && (
            <div>
              {
                images.length > 0 && (
                  <Col>
                    <Row>
                    {
                      images.map((image, index) => (
                        <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(materialData, question, images, index)}>
                          <Image src={`${process.env['REACT_APP_API_BASE_URL']}/school-admin/class/${classID}/material/${materialData.type}/${materialData.id}/questions/${question.id}/image/${image.id}`} thumbnail />
                        </Col>
                      ))
                    }
                    </Row>
                  </Col>
                )
              }
              {
                files.length > 0 && (
                  <div className='p-1'>
                    {
                      files.map(file => (
                        <small key={file.id} className='d-flex mb-2'>
                          <div className='mr-2'>
                            <FontAwesomeIcon icon='file' className='text-primary' />
                          </div>
                          <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                            <span className='btn-link' onClick={() => this.handleFileDownload(file.id)}>
                              {file.file_title}
                            </span>
                          </div>
                        </small>
                      ))
                    }
                  </div>
                )
              }
            </div>
          )
        }
        {
          question.question.is_case_sensitive && (
            <div className='text-muted'>
              <FontAwesomeIcon icon='exclamation' /> Case-sensitive
            </div>
          )
        }
        {
          question.question.is_in_order && (
            <div className='text-muted'>
              <FontAwesomeIcon icon='exclamation' /> Sequential order
            </div>
          )
        }
        <div className='text-muted'>
          ({question.points} point{question.points > 1 ? 's' : ''})
        </div>
        <div className='text-muted'>
          Question Type: {questionTypeMap[question.type]}
        </div>
        {
          question.type !== 'essay' && (
            <>
              <div className='mt-2 mb-1'>
                Answer:
              </div>
              <div className='px-2'>
                {this.renderAnswer()}
              </div>
            </>
          )
        }
      </div>
    );
  }
  render() {
    const { isLoading, errorMessage, questions, isNextPageLoading, nextPageError, viewModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (questions.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='question-list'>
          {
            questions.data.map((question, index) => (
              <div key={index} className='d-flex'>
                <div className='pl-1 pr-3 h5'>{index+1}</div>
                <div className='flex-fill'>
                  <RichTextEditor.Viewer body={question.question.description} />
                  {/* <div style={{ whiteSpace: 'pre-wrap' }}>{question.question.description}</div> */}
                  <div className='text-muted'>{questionTypeMap[question.type]}</div>
                </div>
                <div>
                  <Button variant='warning' size='sm' className='mr-2' onClick={() => this.showViewModal(question, index+1)}>
                    <FontAwesomeIcon icon='eye' />
                  </Button>
                </div>
              </div>
            ))
          }
        </div>
        {
          questions.next_page_url && (
            <div className='text-center mt-3'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                View more questions
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          )
        }
        <Modal show={viewModal.show} onHide={this.hideViewModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Question {viewModal.index}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.renderQuestionView()}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={this.hideViewModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Questions);