import React, { Component } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../actions';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import './style.scss';

class PostImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingIDs: props.images.map(image => image.id),
      loadedIDs: []
    };
  }
  componentDidUpdate(prevProps) {
    const { loadedIDs } = this.state;
    const { images } = this.props;

    let newLoadingIDs = [];
    for(let i = 0; i < images.length; i++) {
      if (loadedIDs.indexOf(images[i].id) === -1) {
        newLoadingIDs = [...newLoadingIDs, images[i].id];
      }
    }
    if (prevProps.images !== this.props.images && newLoadingIDs.length > 0) {
      this.setState({
        ...this.state,
        loadingIDs: newLoadingIDs
      });
    }
  }
  handleClick = index => {
    const { images, onDelete, deletable } = this.props;

    this.props.showImagePreview(images, index, deletable, onDelete);
  }
  handleLoaded = (e, imageID) => {
    let newLoadingIDs = [...this.state.loadingIDs].filter(lid => lid !== imageID);
    let newLoadedIDs = [...this.state.loadedIDs, imageID];

    this.setState({
      ...this.state,
      loadingIDs: newLoadingIDs,
      loadedIDs: newLoadedIDs
    });
  }
  render() {
    const { loadingIDs } = this.state;
    const { images } = this.props;

    return (
      <div className='post-images'>
        <Row noGutters>
          {
            images.map((image, index) => {
              if (index < 2) {
                return (
                  <Col key={image.id} onClick={e => this.handleClick(index)}>
                    {
                      loadingIDs.indexOf(image.id) !== -1 && (
                        <div className='post-images_cover'>
                          <LoadingIcon className='text-white' />
                        </div>
                      )
                    }
                    <div className='post-images_image-container'>
                      <Image src={image.src} fluid onLoad={e => this.handleLoaded(e, image.id)} />
                    </div>
                  </Col>
                );
              }

              return null;
            })
          }
        </Row>
        {
          images.length > 2 && (
            <Row noGutters>
              {
                images.map((image, index) => {
                  if (index < 2 || index >= 5) {
                    return null;
                  }

                  if (index === 4 && images.length > 5) {
                    return (
                      <Col key={image.id} onClick={e => this.handleClick(index)}>
                        {
                          loadingIDs.indexOf(image.id) !== -1 ? (
                            <div className='post-images_cover'>
                              <LoadingIcon className='text-white' />
                            </div>
                          ) : (
                            <div className='post-images_cover'>
                              {`+${images.length - 4}`}
                            </div>
                          )
                        }
                        <div className='post-images_image-container'>
                          <Image src={image.src} fluid onLoad={e => this.handleLoaded(e, image.id)} />
                        </div>
                      </Col>
                    )
                  } 
                  
                  return (
                    <Col key={image.id} onClick={e => this.handleClick(index)}>
                      {
                        loadingIDs.indexOf(image.id) !== -1 && (
                          <div className='post-images_cover'>
                            <LoadingIcon className='text-white' />
                          </div>
                        )
                      }
                      <div className='post-images_image-container'>
                        <Image src={image.src} fluid onLoad={e => this.handleLoaded(e, image.id)} />
                      </div>
                    </Col>
                  );
                })
              }
            </Row>
          )
        }
      </div>
    );
  }
}

PostImages.defaultProps = {
  images: [],
  onDelete: () => {},
  deletable: false
};

// const mapStateToProps = state => ({
//   currentUser: state.currentUser
// });

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(PostImages);