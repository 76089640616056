import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './components/views/Login/Login';
import PrivateRoute from './PrivateRoute';
import ForgotPassword from './components/views/ForgotPassword/ForgotPassword';
import VerifyForgotPassword from './components/views/ForgotPassword/Verify';

export default function AppRouter () {
  return (
    <Switch>
      <Route exact path='/login' component={Login} />
      <Route exact path='/forgot-password' component={ForgotPassword} />
      <Route exact path='/forgot-password/verify/:accountID' component={VerifyForgotPassword} />
      <PrivateRoute path='/' />
    </Switch>
  );
}