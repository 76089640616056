import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../../common/Header/Header';
import SchoolClasses from '../../units/SchoolClasses/SchoolClasses';
import ParentClassroom from '../../units/ParentClassroom/ParentClassroom';
// import Material from './Material/Material';
// import Questions from '../../common/Questions/View/Questions';
import { Redirect, Route, Switch } from 'react-router-dom';

export default class Parent extends Component {
  render() {
    return (
      <>
        <Header />
        <Container>
          <Switch>
            <Route exact path='/:userType' component={SchoolClasses} />
            <Route path='/parent/class/:classID' render={props => (
              <Switch>
                {/* <Route exact path='/parent/class/:classID/:materialType/:materialID' component={Material} /> */}
                {/* <Route exact path='/parent/class/:classID/:materialType(quiz|exam)/:materialID/answer' component={Questions} /> */}
                <Route path={`/parent/class/:classID/:menu`} component={ParentClassroom} />
                <Redirect to={{ pathname: '/parent/class/:classID/discussions' }} />
              </Switch>
            )} />
            <Redirect to={{ pathname: '/parent' }} />
          </Switch>
        </Container>
      </>
    );
  }
}