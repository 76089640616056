import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Form, Image as RBImage, Modal, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Validator from 'validatorjs';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

const titleMap = {
  students: 'Bulk import of students',
  parents: 'Bulk import of parents',
  staff: 'Bulk import of staff',
  faculty: 'Bulk import of faculty',
  'school-admins': 'Bulk import of school administrators',
  'web-admins': 'Bulk import of web administrators'
};

export default class UserImportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {
        isLoading: false,
        errorMessage: ''
      },
      image: {
        isLoading: false,
        errorMessage: '',
        removeErrorFiles: false,
        fileNames: [],
        byColumn: 'username'
      },
      emergencyContact: {
        isLoading: false,
        errorMessage: ''
      }
    };

    this.importFile = [];
    this.images = [];
    this.emergencyContactFile = [];
  }
  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && !this.props.show) {
      this.setState({
        ...this.state,
        file: {
          isLoading: false,
          errorMessage: ''
        },
        image: {
          isLoading: false,
          errorMessage: '',
          removeErrorFiles: false,
          fileNames: [],
          byColumn: 'username'
        },
        emergencyContact: {
          isLoading: false,
          errorMessage: ''
        }
      }, () => {
        this.importFile = [];
        this.images = [];
        this.emergencyContactFile = [];
      });
    }
  }
  handleFileSelect = event => {
    this.importFile = event.target.files;
  }
  handleEmergencyContactSelect = event => {
    this.emergencyContactFile = event.target.files;
  }
  handleUpload = event => {
    event.preventDefault();

    if (this.importFile.length > 0) {
      this.setState({
        ...this.state,
        file: {
          isLoading: false,
          errorMessage: ''
        }
      }, () => {
        const formData = new window.FormData();
        formData.append('file', this.importFile[0], this.importFile[0].name);
        
        axios.post(`${process.env['REACT_APP_API_BASE_URL']}/admin/account/import/${this.props.type}`, formData, {
          withCredentials: true,
          header: {
            'content-type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.setState({
              ...this.state,
              file: {
                ...this.state.file,
                isLoading: percentCompleted
              }
            });
          }
        }).then(({ data: { data }}) => {
          this.props.onSuccess(data);
        }).catch((error) => {
          if (error.response && error.response.status === 403) {
            this.props.history.push('/login');
          }
          this.setState({
            ...this.state,
            file: {
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        });
      });
    } else {
      this.setState({
        ...this.state,
        file: {
          isLoading: false,
          errorMessage: 'The import file is required.'
        }
      });
    }
  }
  handleEmergencyContactUpload = event => {
    event.preventDefault();

    if (this.emergencyContactFile.length > 0) {
      this.setState({
        ...this.state,
        emergencyContact: {
          isLoading: false,
          errorMessage: ''
        }
      }, () => {
        const formData = new window.FormData();
        formData.append('file', this.emergencyContactFile[0], this.emergencyContactFile[0].name);
        
        axios.post(`${process.env['REACT_APP_API_BASE_URL']}/admin/account/import/${this.props.type}/emergency-contact`, formData, {
          withCredentials: true,
          header: {
            'content-type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.setState({
              ...this.state,
              emergencyContact: {
                ...this.state.emergencyContact,
                isLoading: percentCompleted
              }
            });
          }
        }).then(({ data: { data }}) => {
          this.props.onEmergencyContactSuccess(data);
        }).catch((error) => {
          if (error.response && error.response.status === 403) {
            this.props.history.push('/login');
          }
          this.setState({
            ...this.state,
            emergencyContact: {
              isLoading: false,
              errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
            }
          });
        });
      });
    } else {
      this.setState({
        ...this.state,
        emergencyContact: {
          isLoading: false,
          errorMessage: 'The import file is required.'
        }
      });
    }
  }
  handleImageSelect = event => {
    const files = [...event.target.files];

    if (files.length > 0) {
      const filesState = files.map(file => {
        return {
          name: file.name,
          isLoading: false,
          isError: false,
          isSuccess: false,
          previewSrc: null,
          isPreviewLoading: true,
          previewError: false
        };
      });
      this.setState({
        ...this.state,
        image: {
          ...this.state.image,
          fileNames: [
            ...this.state.image.fileNames,
            ...filesState
          ]
        }
      }, () => {
        const fileToBase64 = file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
              const img = new Image();
              img.addEventListener('load', () => resolve(reader.result));
              img.addEventListener('error', error => reject(error));
              img.src = reader.result;
            });
            reader.addEventListener('error', error => reject(error));
            reader.readAsDataURL(file);
          });
        };
        let processPreview = [];
        let errorPreview = [];
        for (let i = 0; i < files.length; i++) {
          let image = files[i];
          let validator = {
            passed: true,
            error: null
          };
  
          if (typeof image.name !== 'string') {
            validator.passed = false;
            validator.error = 'Invalid file.';
          } else if (image.type !== 'image/jpeg' && image.type !== 'image/png') {
            validator.passed = false;
            validator.error = 'Invalid image format.';
          }
          let index = this.images.length+i;

          if (validator.passed) {
            processPreview.push(fileToBase64(image).then(imageSrc => {
                return {
                  index,
                  data: {
                    previewSrc: imageSrc,
                    isPreviewLoading: false
                  }
                };
              }).catch(() => {
                return {
                  index,
                  data: {
                    previewError: 'Failed to read image.',
                    previewSrc: null,
                    isPreviewLoading: false
                  }
                };
              })
            );
          } else {
            errorPreview.push({
              index,
              data: {
                previewError: validator.error,
                previewSrc: null,
                isPreviewLoading: false
              }
            });
          }
        }
        Promise.all(processPreview).then(previews => {
          let processedPreviews = [...errorPreview, ...previews];
          let newFilesState = [...this.state.image.fileNames].map((fileName, i) => {
            let index = processedPreviews.find(ind => ind.index === i);
            if (index) {
              return {
                ...fileName,
                ...index.data
              };
            }
      
            return fileName;
          });
          this.setState({
            ...this.state,
            image: {
              ...this.state.image,
              fileNames: newFilesState
            }
          });
        });
        this.images = [...this.images, ...files];
      });
    }
  }
  handleRemoveUpload = id => {
    const { image: { fileNames } } = this.state;

    const newFileNames = fileNames.filter((fileName, index) => {
      return index !== id;
    });

    this.setState({
      ...this.state,
      image: {
        ...this.state.image,
        fileNames: newFileNames
      }
    }, () => {
      this.images = [...this.images].filter((file, index) => {
        return index !== id;
      });
    });
  }
  handleRemoveInvalid = event => {
    let removeIndexes = [];
    let newFilesState = [...this.state.image.fileNames].filter((fileName, index) => {
      let toSave = fileName.previewSrc && !fileName.isPreviewLoading && !fileName.previewError;
      if (!toSave) {
        removeIndexes.push(index);
      }
      return toSave;
    });
    let newImages = this.images.filter((img, i) => {
      return removeIndexes.indexOf(i) === -1;
    });

    this.setState({
      ...this.state,
      image: {
        ...this.state.image,
        errorMessage: '',
        removeErrorFiles: false,
        fileNames: newFilesState
      }
    }, () => {
      this.images = newImages;
    });
  }
  handleByColumnChange = event => {
    this.setState({
      ...this.state,
      image: {
        ...this.state.image,
        byColumn: event.target.value
      }
    });
  }
  uploadFile = (i) => {
    const { image } = this.state;
    const formData = new window.FormData();
    formData.append('file', this.images[i], this.images[i].name);

    return axios.post(`${process.env['REACT_APP_API_BASE_URL']}/admin/account/import/${this.props.type}/image/${image.byColumn === 'username' ? 'username' : 'student-number'}`, formData, {
      withCredentials: true,
      header: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { image: { fileNames } } = this.state;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        fileNames[i].isLoading = percentCompleted;
        this.setState({
          ...this.state,
          image: {
            ...this.state.image,
            fileNames
          }
        });
      }
    }).then(({ data: { data }}) => {
      const { image: { fileNames } } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isSuccess = true;
      this.setState({
        ...this.state,
        image: {
          ...this.state.image,
          fileNames
        }
      });
      return data;
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.props.history.push('/login');
      }

      const { image: { fileNames } } = this.state;
      fileNames[i].isLoading = false;
      fileNames[i].isError = error.response && error.response.data ? error.response.data.message : error.message ? error.message : error;
      this.setState({
        ...this.state,
        image: {
          ...this.state.image,
          fileNames
        }
      });

      throw fileNames[i].isError;
    });
  }
  handleImageUpload = event => {
    event.preventDefault();
    
    const { image } = this.state;

    let validator = new Validator({
      byColumn: image.byColumn
    }, {
      byColumn: 'required|in:username,studentNumber'
    });
    validator.setAttributeNames({
      byColumn: 'by column'
    });

    if (validator.fails()) {
      const firstKey = Object.keys(validator.errors.errors)[0];
      this.setState({
        ...this.state,
        image: {
          ...this.state.image,
          errorMessage: validator.errors.errors[firstKey][0]
        }
      });
      return;
    }

    if (this.images.length > 0) {
      let errors = [...this.state.image.fileNames].filter(fileName => {
        return !fileName.previewSrc || fileName.isPreviewLoading || fileName.previewError;
      });
      if (errors.length > 0) {
        this.setState({
          ...this.state,
          image: {
            ...this.state.image,
            errorMessage: `${errors.length} ${errors.length > 1 ? 'files are invalid.' : 'file is invalid.'}`,
            removeErrorFiles: true
          }
        });
      } else {
        this.setState({
          ...this.state,
          image: {
            ...this.state.image,
            isLoading: true,
            errorMessage: '',
            removeErrorFiles: false
          }
        }, () => {
          let fileRequests = [];
          for (let i = 0; i < this.images.length; i++) {
            fileRequests.push(this.uploadFile(i));
          }
          Promise.all(fileRequests).then(result => {
            this.props.onImageSuccess(result);
          }).catch(error => {
            this.setState({
              ...this.state,
              image: {
                ...this.state.image,
                isLoading: false,
                errorMessage: error
              }
            });
          });
        });
      }
    } else {
      this.setState({
        ...this.state,
        image: {
          ...this.state.image,
          errorMessage: 'The image(s) is/are required.'
        }
      });
    }
  }
  renderImages = () => {
    const { image: { isLoading, fileNames } } = this.state;
    if (fileNames.length > 0) {
      return (
        <Form.Group>
          <Form.Label>Image(s) to be uploaded</Form.Label>
          <small className='file-upload-display d-block rounded-top'>
            {
              fileNames.map((fileName, index) => (
                <div key={index} className='file-name d-flex'>
                  <div>
                    {
                      fileName.isLoading || (isLoading && (!fileName.isError && !fileName.isSuccess)) ? (
                        <LoadingIcon />
                      ) : fileName.isError ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              {fileName.isError}
                            </Tooltip>
                          }
                          trigger={['hover', 'focus']}>
                          <FontAwesomeIcon icon='times-circle' className='text-danger' />
                        </OverlayTrigger>
                      ) : fileName.isSuccess ? (
                        <FontAwesomeIcon icon='check-circle' className='text-green' />
                      ) : fileName.isPreviewLoading ? (
                        <div className='border d-flex align-items-center justify-content-center' style={{ height: '2rem', width: '2rem' }}>
                          <LoadingIcon />
                        </div>
                      ) : fileName.previewSrc ? (
                        <div style={{ height: '2rem', width: '2rem' }}>
                          <RBImage src={fileName.previewSrc} style={{ objectFit: 'cover', height: '100%' }} fluid />
                        </div>
                      ) : fileName.previewError ? (
                        <div className='border d-flex align-items-center justify-content-center' style={{ height: '2rem', width: '2rem' }}>
                          <FontAwesomeIcon icon='times-circle' className='text-danger' />
                        </div>
                      ) : (
                        <FontAwesomeIcon icon='minus-circle' className='text-black-50' />
                      )
                    }
                  </div>
                  <div className='ml-2 flex-fill'>
                    <div>
                      {fileName.name}
                    </div>
                    {
                      fileName.isLoading && (
                        <div className='mt-1'>
                          <ProgressBar now={fileName.isLoading} />
                        </div>
                      )
                    }
                  </div>
                  {
                    (!fileName.isLoading && !fileName.isSuccess && !isLoading) && (
                      <div className='ml-2 align-items-center d-flex'>
                        <Button
                          variant='link'
                          className='text-danger'
                          size='sm'
                          title='Remove'
                          onClick={(e) => this.handleRemoveUpload(index)}>
                          <FontAwesomeIcon icon='times' size='sm' />
                        </Button>
                      </div>
                    )
                  }
                </div>
              ))
            }
          </small>
        </Form.Group>
      );
    }
  }
  render() {
    const { show, onHide, type } = this.props;
    const { file, image, emergencyContact } = this.state;
    return (
      <Modal show={show} onHide={onHide} backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>{titleMap[type]}</Modal.Title>
        </Modal.Header>
          <Modal.Body>
            <div className='h5'>
              User Details
            </div>
            <div className='px-2 px-md-3 mb-3'>
              <Form onSubmit={this.handleUpload}>
                {
                  file.errorMessage && (
                    <Alert variant='danger'>{file.errorMessage}</Alert>
                  )
                }
                <Form.Group>
                  <Form.File label='Import user details' accept='.xlsx, .xls' onChange={this.handleFileSelect} />
                </Form.Group>
                {
                  file.isLoading && (
                    <div className='mb-3'>
                      <ProgressBar now={file.isLoading} label='Uploading...' />
                    </div>
                  )
                }
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <a className='d-none d-md-block' href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/template/${type}`}>
                      <FontAwesomeIcon icon='file-download' /> Download template
                    </a>
                    <a className='d-md-none' href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/template/${type}`} title='Download template'>
                      <FontAwesomeIcon icon='file-download' />
                    </a>
                  </div>
                  <div>
                    <Button className='ml-auto' variant='green' type='submit' disabled={file.isLoading}>Upload</Button>
                  </div>
                </div>
              </Form>
            </div>
            <div className='dropdown-divider'></div>
            <div className='h5'>
              Profile Pictures
            </div>
            <div className='px-2 px-md-3'>
              <Form onSubmit={this.handleImageUpload}>
                {
                  image.errorMessage ? (
                    <Alert variant='danger'>
                      <div>
                        {image.errorMessage}
                      </div>
                      {
                        image.removeErrorFiles && (
                          <div className='mt-2'>
                            <ul className='small'>
                              {
                                image.fileNames.map((img, i) => {
                                  if (!img.previewSrc || img.isPreviewLoading || img.previewError) {
                                    return (
                                      <li key={i}>
                                        <div className='text-truncate'>
                                          {img.name}
                                        </div>
                                        {
                                          img.previewError && (
                                            <div className='font-italic'>
                                              {img.previewError}
                                            </div>
                                          )
                                        }
                                      </li>
                                    )
                                  }
                                  return null;
                                })
                              }
                            </ul>
                            <div>
                              <Button variant='outline-danger' size='sm' onClick={this.handleRemoveInvalid}>
                                Remove invalid file(s)
                              </Button>
                            </div>
                          </div>
                        )
                      }
                    </Alert>
                  ) : (
                    <Alert variant='warning'>
                      Name the images with the user's <b>{image.byColumn === 'username' ? 'username' : 'student number'}</b>, respectively.
                    </Alert>
                  )
                }
                {
                  type === 'students' && (
                    <Form.Group>
                      <div>
                        <Form.Label>
                          By Column
                        </Form.Label>
                      </div>
                      <div className='form-check-inline'>
                        <Form.Group controlId='byColumnUsername' className='mr-3 mb-0'>
                          <Form.Check type='radio' name='byColumn' label='Username' value='username' onChange={this.handleByColumnChange} checked={image.byColumn === 'username'} disabled={image.isLoading} />
                        </Form.Group>
                        <Form.Group controlId='byColumnStudentNumber' className='mb-0'>
                          <Form.Check type='radio' name='byColumn' label='Student Number' value='studentNumber' onChange={this.handleByColumnChange} checked={image.byColumn === 'studentNumber'} disabled={image.isLoading} />
                        </Form.Group>
                      </div>
                    </Form.Group>
                  )
                }
                <Form.Group>
                  <Form.Label>Import profile pictures</Form.Label>
                  <Form.File 
                    id='add-image'
                    label={image.fileNames.length > 0 ? (image.fileNames.length + ` file${image.fileNames.length > 1 ? 's' : ''} chosen`) : 'No file chosen'}
                    accept='image/png,image/jpeg'
                    custom
                    onChange={this.handleImageSelect}
                    disabled={image.isLoading}
                    multiple
                  />
                </Form.Group>
                {this.renderImages()}
                <div className='text-right'>
                  <Button variant='green' type='submit' disabled={image.isLoading}>Upload</Button>
                </div>
              </Form>
            </div>
            {
              type === 'students' && (
                <>
                  <div className='dropdown-divider'></div>
                  <div className='h5'>
                    Emergency Contacts
                  </div>
                  <div className='px-2 px-md-3 mb-3'>
                    <Form onSubmit={this.handleEmergencyContactUpload}>
                      {
                        emergencyContact.errorMessage && (
                          <Alert variant='danger'>{emergencyContact.errorMessage}</Alert>
                        )
                      }
                      <Form.Group>
                        <Form.File label='Import emergency contacts' accept='.xlsx, .xls' onChange={this.handleEmergencyContactSelect} />
                      </Form.Group>
                      {
                        emergencyContact.isLoading && (
                          <div className='mb-3'>
                            <ProgressBar now={emergencyContact.isLoading} label='Uploading...' />
                          </div>
                        )
                      }
                      <div className='d-flex justify-content-between align-items-center'>
                        <div>
                          <a className='d-none d-md-block' href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/template/${type}/emergency-contact`}>
                            <FontAwesomeIcon icon='file-download' /> Download template
                          </a>
                          <a className='d-md-none' href={`${process.env['REACT_APP_API_BASE_URL']}/admin/account/template/${type}/emergency-contact`} title='Download template'>
                            <FontAwesomeIcon icon='file-download' />
                          </a>
                        </div>
                        <div>
                          <Button className='ml-auto' variant='green' type='submit' disabled={emergencyContact.isLoading}>Upload</Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={onHide}>Close</Button>
          </Modal.Footer>
      </Modal>
    );
  }
}

UserImportModal.propTypes = {
  show: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onImageSuccess: PropTypes.func.isRequired,
  onEmergencyContactSuccess: PropTypes.func.isRequired
};

UserImportModal.defaultProps = {
  show: false,
  type: 'students',
  onHide: () => {},
  onDelete: () => {},
  onImageSuccess: () => {},
  onEmergencyContactSuccess: () => {}
};