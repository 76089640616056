import React, { Component } from 'react';
import { Alert, Button, Form, InputGroup, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NameLink from '../../../../common/NameLink/NameLink';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import moment from 'moment';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

const iconMap = {
  Present: <FontAwesomeIcon icon='check-circle' className='text-green' />,
  Absent: <FontAwesomeIcon icon={['far', 'circle']} className='text-danger' />,
  Late: <FontAwesomeIcon icon='dot-circle' className='text-danger' />,
  Excused: <FontAwesomeIcon icon='circle' className='text-green' />
};
const iconMapMuted = {
  Present: <FontAwesomeIcon icon='check-circle' className='text-muted' />,
  Absent: <FontAwesomeIcon icon={['far', 'circle']} className='text-muted' />,
  Late: <FontAwesomeIcon icon='dot-circle' className='text-muted' />,
  Excused: <FontAwesomeIcon icon='circle' className='text-muted' />
};
const learningPlatforms = {
  'Digital': (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <div className='font-weight-bold'>Learning Platform</div>
          <div>Digital</div>
        </Tooltip>
      }
      trigger={['hover', 'focus']}>
      <FontAwesomeIcon icon='laptop' className='fa-fw fa-sm text-primary' />
    </OverlayTrigger>
  ),
  'Digital-Modular': (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <div className='font-weight-bold'>Learning Platform</div>
          <div>Digital-Modular</div>
        </Tooltip>
      }
      trigger={['hover', 'focus']}>
      <span className='fa-layers fa-fw fa-sm text-primary'>
        <FontAwesomeIcon icon='laptop' transform='shrink-3 left-5 up-2' />
        <FontAwesomeIcon icon='book-open' transform='shrink-3 right-3 down-5' />
      </span>
    </OverlayTrigger>
  ),
  'Modular-Printed': (
    <OverlayTrigger
      overlay={
        <Tooltip>
          <div className='font-weight-bold'>Learning Platform</div>
          <div>Modular-Printed</div>
        </Tooltip>
      }
      trigger={['hover', 'focus']}>
      <FontAwesomeIcon icon='book-open' className='fa-fw fa-sm text-primary' />
    </OverlayTrigger>
  )
};

export default class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      weekdays: [],
      isAttendanceLoading: false,
      attendanceError: '',
      loadedWeeks: [],
      attendance: [],
      attendanceDisplay: [],
      overallCounter: {
        Synchronous: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        },
        Asynchronous: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        },
        Exam: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        },
        Empty: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        }
      }
    };
  }
  componentDidMount() {
    const { schoolClass: { id } } = this.props;

    axiosRequest('get', `faculty/class-advisory/${id}/attendance`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        attendance: data,
        loadedWeeks: [
          moment().isoWeek()
        ]
      }, () => {
        this.getWeek()
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  getWeek = weekStart => {
    if(!weekStart) {
      weekStart = moment().startOf('isoWeek');
    }
    let days = [];

    for (let i = 0; i < 7; i++) {
      let temp = moment(weekStart).add(i, 'days');

      days.push({
        date: temp.format('YYYY-MM-DD'),
        weekdayFull: temp.format('dddd'),
        dateDisplayLg: temp.format('dddd, MMM D'),
        dateDisplaySm: temp.format('MMM D')
      });
    }

    this.setState({
      ...this.state,
      weekdays: days
    }, this.getAttendance);
  }
  getAttendance = () => {
    const { weekdays, attendance } = this.state;
    const { schoolClass: { students, facultyLoads } } = this.props;
    
    let overallCounter = {
      Synchronous: {
        Present: 0,
        Absent: 0,
        Late: 0,
        Excused: 0
      },
      Asynchronous: {
        Present: 0,
        Absent: 0,
        Late: 0,
        Excused: 0
      },
      Exam: {
        Present: 0,
        Absent: 0,
        Late: 0,
        Excused: 0
      },
      Empty: {
        Present: 0,
        Absent: 0,
        Late: 0,
        Excused: 0
      },
      Total: {
        Present: 0,
        Absent: 0,
        Late: 0,
        Excused: 0
      }
    };
    let attendanceDisplay = [];
    for (let i = 0; i < facultyLoads.length; i++) {
      let facultyLoad = facultyLoads[i];
      let studentData = [];
      let counter = {
        Synchronous: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        },
        Asynchronous: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        },
        Exam: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        },
        Empty: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        },
        Total: {
          Present: 0,
          Absent: 0,
          Late: 0,
          Excused: 0
        }
      };
      for (let j = 0; j < students.length; j++) {
        let student = students[j];
        let learningPlatform = student.student_learning_platforms.find(slp => slp.faculty_load_id === facultyLoad.id);
        let data = [];
        for (let k = 0; k < weekdays.length; k++) {
          let weekday = weekdays[k];
          let schedule = facultyLoad.date_schedule.find(ds => ds.schedule_date === weekday.date);
          if (!schedule) {
            schedule = facultyLoad.weekly_schedule.find(ws => ws.day === weekday.weekdayFull);
          }

          let temp = attendance.find(a => a.faculty_load_id === facultyLoad.id && a.attendance_date === weekday.date && a.account_id === student.id);
          if (temp) {
            counter['Total'][temp.mark]++;
            overallCounter['Total'][temp.mark]++;
            if (schedule && schedule.type) {
              if (schedule.type === 'Synchronous' && learningPlatform && learningPlatform.platform === 'Digital') {
                overallCounter[schedule.type][temp.mark]++;
                counter[schedule.type][temp.mark]++;
                data.push(
                  <span className='mr-1' style={{ cursor: 'pointer' }}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          <div>
                            {weekday.dateDisplayLg}
                          </div>
                          <div>
                            {schedule.type}
                          </div>
                          <div className='font-weight-bold'>
                            {temp.mark}
                          </div>
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <span className='fa-lg'>
                        {iconMap[temp.mark]}
                      </span>
                    </OverlayTrigger>
                  </span>
                );
              } else if (schedule.type === 'Asynchronous' && learningPlatform && (learningPlatform.platform === 'Digital' || learningPlatform.platform === 'Digital-Modular')) {
                overallCounter[schedule.type][temp.mark]++;
                counter[schedule.type][temp.mark]++;
                data.push(
                  <span className='mr-1' style={{ cursor: 'pointer' }}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          <div>
                            {weekday.dateDisplayLg}
                          </div>
                          <div>
                            {schedule.type}
                          </div>
                          <div className='font-weight-bold'>
                            {temp.mark}
                          </div>
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <span className='fa-lg'>
                        {iconMap[temp.mark]}
                      </span>
                    </OverlayTrigger>
                  </span>
                );
              } else if (!learningPlatform || schedule.type === 'Exam') {
                overallCounter[schedule.type][temp.mark]++;
                counter[schedule.type][temp.mark]++;
                data.push(
                  <span className='mr-1' style={{ cursor: 'pointer' }}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          <div>
                            {weekday.dateDisplayLg}
                          </div>
                          <div>
                            {schedule.type}
                          </div>
                          <div className='font-weight-bold'>
                            {temp.mark}
                          </div>
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <span className='fa-lg'>
                        {iconMap[temp.mark]}
                      </span>
                    </OverlayTrigger>
                  </span>
                );
              } else {
                data.push(
                  <span className='mr-1' style={{ cursor: 'pointer' }}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          <div>
                            {weekday.dateDisplayLg}
                          </div>
                          <div>
                            {schedule.type}
                          </div>
                          <div className='font-weight-bold'>
                            {temp.mark}
                          </div>
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <span className='fa-lg'>
                        {iconMapMuted[temp.mark]}
                      </span>
                    </OverlayTrigger>
                  </span>
                );
              }
            } else {
              overallCounter['Empty'][temp.mark]++;
              counter['Empty'][temp.mark]++;
              data.push(
                <span className='mr-1' style={{ cursor: 'pointer' }}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          {weekday.dateDisplayLg}
                        </div>
                        <div className='font-italic'>
                          No schedule
                        </div>
                        <div className='font-weight-bold'>
                          {temp.mark}
                        </div>
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <span className='fa-lg'>
                      {iconMapMuted[temp.mark]}
                    </span>
                  </OverlayTrigger>
                </span>
              );
            }
          } else if (schedule && schedule.type) {
            if (schedule.type === 'Synchronous' && learningPlatform && learningPlatform.platform === 'Digital') {
              data.push(
                <span className='mr-1' style={{ cursor: 'pointer' }}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          {weekday.dateDisplayLg}
                        </div>
                        <div>
                          {schedule.type}
                        </div>
                        <div className='font-italic font-weight-bold'>
                          No attendance
                        </div>
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <span className='fa-lg'>
                      <FontAwesomeIcon icon='exclamation-circle' className='text-muted' />
                    </span>
                  </OverlayTrigger>
                </span>
              );
            } else if (schedule.type === 'Asynchronous' && learningPlatform && (learningPlatform.platform === 'Digital' || learningPlatform.platform === 'Digital-Modular')) {
              data.push(
                <span className='mr-1' style={{ cursor: 'pointer' }}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          {weekday.dateDisplayLg}
                        </div>
                        <div>
                          {schedule.type}
                        </div>
                        <div className='font-italic font-weight-bold'>
                          No attendance
                        </div>
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <span className='fa-lg'>
                      <FontAwesomeIcon icon='exclamation-circle' className='text-muted' />
                    </span>
                  </OverlayTrigger>
                </span>
              );
            } else if (!learningPlatform || schedule.type === 'Exam') {
              data.push(
                <span className='mr-1' style={{ cursor: 'pointer' }}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          {weekday.dateDisplayLg}
                        </div>
                        <div>
                          {schedule.type}
                        </div>
                        <div className='font-italic font-weight-bold'>
                          No attendance
                        </div>
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <span className='fa-lg'>
                      <FontAwesomeIcon icon='exclamation-circle' className='text-muted' />
                    </span>
                  </OverlayTrigger>
                </span>
              );
            }
          }
        }
        studentData.push({
          studentID: student.id,
          data,
          learningPlatform
        });
      }
      attendanceDisplay.push({
        facultyLoadID: facultyLoad.id,
        studentData,
        counter
      });
    }

    this.setState({
      ...this.state,
      isLoading: false,
      attendanceDisplay,
      overallCounter
    });
  }
  getWeekAttendance = weekStart => {
    const { loadedWeeks } = this.state;
    const { schoolClass: { id } } = this.props;
    const isoWeek = moment(weekStart).isoWeek();

    if (loadedWeeks.indexOf(isoWeek) === -1) {
      this.setState({
        ...this.state,
        isAttendanceLoading: true,
        attendanceError: ''
      }, () => {
        axiosRequest('get', `faculty/class-advisory/${id}/attendance/${weekStart.format('YYYY-MM-DD')}`, null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            isAttendanceLoading: false,
            attendance: [
              ...this.state.attendance,
              ...data
            ],
            loadedWeeks: [
              ...this.state.loadedWeeks,
              isoWeek
            ]
          }, () => {
            this.getWeek(weekStart);
          });
        }, error => {
          this.setState({
            ...this.state,
            isAttendanceLoading: false,
            attendanceError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    } else {
      this.getWeek(weekStart);
    }
  }
  handleNextWeek = () => {
    const { weekdays } = this.state;

    this.getWeekAttendance(moment(weekdays[0].date).add(1, 'weeks'));
  }
  handlePrevWeek = () => {
    const { weekdays } = this.state;

    this.getWeekAttendance(moment(weekdays[0].date).subtract(1, 'weeks'));
  }
  renderAttendance = (facultyLoad, studentID) => {
    const { attendanceDisplay } = this.state;

    let display = attendanceDisplay.find(ad => ad.facultyLoadID === facultyLoad.id);
    
    if (display) {
      let student = display.studentData.find(sd => sd.studentID === studentID);
      if (student) {
        return (
          <div>
            <div className='text-right'>
              {
                (student.learningPlatform && student.learningPlatform.platform) && (
                  <span style={{ cursor: 'pointer' }}>
                    {learningPlatforms[student.learningPlatform.platform]}
                  </span>
                )
              }
            </div>
            <div>
              {
                student.data.length === 0 ? (
                  <span style={{ cursor: 'pointer' }}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          No schedule
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <span className='fa-lg'>
                        <FontAwesomeIcon icon='ban' className='text-muted' />
                      </span>
                    </OverlayTrigger>
                  </span>
                ) : student.data.map(d => d)
              }
            </div>
          </div>
        )
      }
    }

    return null;
  }
  renderSubtotal = facultyLoadID => {
    const { attendanceDisplay } = this.state;

    let display = attendanceDisplay.find(ad => ad.facultyLoadID === facultyLoadID);
    if (display) {
      let scheduleTypes = ['Synchronous', 'Asynchronous', 'Exam', 'Empty'];
      let marks = ['Present', 'Late', 'Absent', 'Excused'];
      return (
        <>
          {
            scheduleTypes.map((scheduleType, i) => {
              if (marks.find(m => display.counter[scheduleType][m] !== 0)) {
                return (
                  <div key={i}>
                    {
                      scheduleType === 'Empty' ? (
                        <div className='font-weight-bold font-italic text-muted'>
                          No schedule
                        </div>
                      ) : (
                        <div className='font-weight-bold'>
                          {scheduleType}
                        </div>
                      )
                    }
                    <div className='pl-1'>
                      <div className='d-flex'>
                        {
                          marks.slice(0, 2).map((mark, i) => (
                            <div key={i} className='flex-fill'>
                              {iconMap[mark]} {display.counter[scheduleType][mark]}
                            </div>
                          ))
                        }
                      </div>
                      <div className='d-flex'>
                        {
                          marks.slice(-2).map((mark, i) => (
                            <div key={i} className='flex-fill'>
                              {iconMap[mark]} {display.counter[scheduleType][mark]}
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                );
              }

              return null;
            })
          }
          <div className='dropdown-divider border-top'></div>
          <div>
            <div className='font-weight-bold'>
              Total
            </div>
            <div className='pl-1'>
              <div className='d-flex'>
                {
                  marks.slice(0, 2).map((mark, i) => (
                    <div key={i} className='flex-fill'>
                      {iconMap[mark]} {display.counter['Total'][mark]}
                    </div>
                  ))
                }
              </div>
              <div className='d-flex'>
                {
                  marks.slice(-2).map((mark, i) => (
                    <div key={i} className='flex-fill'>
                      {iconMap[mark]} {display.counter['Total'][mark]}
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </>
      );
    }

    return null;
  }
  renderTotal = () => {
    const { overallCounter } = this.state;

    let scheduleTypes = ['Synchronous', 'Asynchronous', 'Exam', 'Empty'];
    let marks = ['Present', 'Late', 'Absent', 'Excused'];
    return (
      <>
        {
          scheduleTypes.map((scheduleType, i) => {
            if (marks.find(m => overallCounter[scheduleType][m] !== 0)) {
              return (
                <div key={i}>
                  {
                    scheduleType === 'Empty' ? (
                      <div className='font-weight-bold font-italic text-muted'>
                        No schedule
                      </div>
                    ) : (
                      <div className='font-weight-bold'>
                        {scheduleType}
                      </div>
                    )
                  }
                  <div className='pl-1'>
                    <div className='d-flex'>
                      {
                        marks.slice(0, 2).map((mark, i) => (
                          <div key={i} className={i === 0 ? 'mr-3' : ''}>
                            {iconMap[mark]} {overallCounter[scheduleType][mark]}
                          </div>
                        ))
                      }
                    </div>
                    <div className='d-flex'>
                      {
                        marks.slice(-2).map((mark, i) => (
                          <div key={i} className={i === 0 ? 'mr-3' : ''}>
                            {iconMap[mark]} {overallCounter[scheduleType][mark]}
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              );
            }

            return null;
          })
        }
        <div className='dropdown-divider border-top'></div>
        <div>
          <div className='font-weight-bold'>
            Total
          </div>
          <div className='pl-1'>
            <div className='d-flex'>
              {
                marks.slice(0, 2).map((mark, i) => (
                  <div key={i} className={i === 0 ? 'mr-3' : ''}>
                    {iconMap[mark]} {overallCounter['Total'][mark]}
                  </div>
                ))
              }
            </div>
            <div className='d-flex'>
              {
                marks.slice(-2).map((mark, i) => (
                  <div key={i} className={i === 0 ? 'mr-3' : ''}>
                    {iconMap[mark]} {overallCounter['Total'][mark]}
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </>
    );
  }
  renderTable = () => {
    const { isAttendanceLoading, attendanceError } = this.state;
    const { schoolClass: { students, facultyLoads } } = this.props;

    if (isAttendanceLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (attendanceError) {
      return (
        <Alert variant='danger'>
          {attendanceError}
        </Alert>
      );
    }

    let male = students.filter(s => s.gender === 'Male');
    let female = students.filter(s => s.gender === 'Female');

    return (
      <Table bordered hover responsive className='class-advisory-attendance'>
        <thead>
          <tr>
            <th></th>
            {
              facultyLoads.map(facultyLoad => (
                <th key={facultyLoad.id} style={{ width: '7.5rem', whiteSpace: 'nowrap' }}>
                  <span className='mr-1' style={{ cursor: 'pointer' }}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          <div>
                            {facultyLoad.class_course.course.description}
                          </div>
                          <div>
                            {facultyLoad.profile.name}
                          </div>
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <FontAwesomeIcon icon='info-circle' className='text-info' />
                    </OverlayTrigger>
                  </span>
                  {facultyLoad.class_course.course.code}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            male.length > 0 && (
              <>
                <tr className='text-white bg-green'>
                  <th className='py-1 text-center'>
                    <div className='mb-0'>Male</div>
                    <small className='font-italic'>{male.length} student{male.length !== 1 ? 's' : ''}</small>
                  </th>
                  <td colspan={facultyLoads.length}></td>
                </tr>
                {
                  male.map(student => (
                    <tr key={student.id}>
                      <th>
                        <NameLink id={student.id} name={student.formal_name} image={student.image} />
                      </th>
                      {
                        facultyLoads.map(facultyLoad => (
                          <th key={facultyLoad.id} style={{ fontSize: '1.1rem' }} className='px-2 pt-1'>
                            {this.renderAttendance(facultyLoad, student.id)}
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </>
            )
          }
          {
            female.length > 0 && (
              <>
                <tr className='text-white bg-green'>
                  <th className='py-1 text-center'>
                    <div className='mb-0'>Female</div>
                    <small className='font-italic'>{female.length} student{female.length !== 1 ? 's' : ''}</small>
                  </th>
                  <td colspan={facultyLoads.length}></td>
                </tr>
                {
                  female.map(student => (
                    <tr key={student.id}>
                      <th>
                        <NameLink id={student.id} name={student.formal_name} image={student.image} />
                      </th>
                      {
                        facultyLoads.map(facultyLoad => (
                          <th key={facultyLoad.id} style={{ fontSize: '1.1rem' }} className='px-2'>
                            {this.renderAttendance(facultyLoad, student.id)}
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </>
            )
          }
          <tr>
            <td>
              {this.renderTotal()}
            </td>
            {
              facultyLoads.map(facultyLoad => (
                <td key={facultyLoad.id}>
                  {this.renderSubtotal(facultyLoad.id)}
                </td>
              ))
            }
          </tr>
        </tbody>
      </Table>
    );
  }
  render() {
    const { isLoading, errorMessage, weekdays } = this.state;
    const { schoolClass: { students, facultyLoads } } = this.props;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (students.length === 0 || facultyLoads.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        {/* <div className='text-right mb-2'>
          <a href={`${process.env['REACT_APP_API_BASE_URL']}/faculty/class-advisory/grades/export`} className='btn btn-green btn-sm'>
            <FontAwesomeIcon icon='file-export' /> <span className='d-none d-md-inline-block'>Export attendance</span>
          </a>
        </div>
        <div className='dropdown-divider'></div> */}
        <div className='d-none d-md-block'>
          <InputGroup className='mb-3'>
            <InputGroup.Prepend>
              <Button onClick={this.handlePrevWeek}>
                <FontAwesomeIcon icon='chevron-left' />
              </Button>
            </InputGroup.Prepend>
            <Form.Control className='text-center' type='text' value={`${weekdays[0].dateDisplayLg} - ${weekdays[6].dateDisplayLg}`} disabled />
            <InputGroup.Append>
              <Button onClick={this.handleNextWeek}>
                <FontAwesomeIcon icon='chevron-right' />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
        <div className='d-md-none'>
          <InputGroup className='mb-3' size='sm'>
            <InputGroup.Prepend>
              <Button onClick={this.handlePrevWeek}>
                <FontAwesomeIcon icon='chevron-left' />
              </Button>
            </InputGroup.Prepend>
            <Form.Control className='text-center' type='text' value={`${weekdays[0].dateDisplaySm} - ${weekdays[6].dateDisplaySm}`} disabled />
            <InputGroup.Append>
              <Button onClick={this.handleNextWeek}>
                <FontAwesomeIcon icon='chevron-right' />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
        {this.renderTable()}
      </>
    );
  }
}