import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosRequest from '../../../util/helpers/axiosRequest';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

export default class FacultyLoad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      facultyID: '',
      faculty: [],
      class_courses: [],
      isClassCourseLoading: false,
      facultyLoad: [],
      loadingClassCourses: [],
      errorMessage: '',
      classCourseError: '',
      formError: '',
      searchQuery: ''
    };
  }
  componentDidMount() {
    axiosRequest('get', 'admin/faculty-load/options', null, ({ data: { data }}) => {
      data.faculty.sort((a, b) => {
        if (a.last_name < b.last_name) {
          return -1;
        } else if (a.last_name > b.last_name) {
          return 1;
        }

        return 0;
      });
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  removeFromLoading = (id) => {
    const loadingClassCourses = [...this.state.loadingClassCourses].filter(loadingClassCourse => {
      return loadingClassCourse !== id;
    });
    this.setState({
      ...this.state,
      loadingClassCourses
    });
  }
  handleAssign = (id) => {
    const { facultyID } = this.state;
    this.setState({
      ...this.state,
      loadingClassCourses: [
        ...this.state.loadingClassCourses,
        id
      ]
    }, () => {
      axiosRequest('post', 'admin/faculty-load', {
        facultyID,
        classCourseID: id
      }, ({ data: { data } }) => {
        const { class_courses } = this.state;
        let faculty_load = {...data};
        let newClassCourses = [...class_courses].map(classCourse => {
          if (classCourse.id === data.class_course_id) {
            classCourse.faculty_load = faculty_load;
          }

          return classCourse;
        });

        this.setState({
          ...this.state,
          class_courses: newClassCourses,
          facultyLoad: [
            ...this.state.facultyLoad,
            data
          ]
        }, () => {
          this.removeFromLoading(id);
        });
      }, (error) => {
        this.setState({
          ...this.state,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }, () => {
          this.removeFromLoading(id);
        });
      }, this.props.history);
    });
  }
  handleUnassign = (id, classCourseID) => {
    this.setState({
      ...this.state,
      loadingClassCourses: [
        ...this.state.loadingClassCourses,
        classCourseID
      ]
    }, () => {
      axiosRequest('delete', `admin/faculty-load/${id}`, null, ({ data: { data } }) => {
        const faculty_load = [...this.state.facultyLoad].filter(item => {
          return item.id !== id;
        });
        let newClassCourses = [...this.state.class_courses].map(classCourse => {
          if (classCourse.faculty_load && classCourse.faculty_load.id === id) {
            classCourse.faculty_load = null;
          }

          return classCourse;
        })
        this.setState({
          ...this.state,
          class_courses: newClassCourses,
          facultyLoad: [
            ...faculty_load
          ]
        }, () => {
          this.removeFromLoading(classCourseID);
        });
      }, (error) => {
        this.setState({
          ...this.state,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }, () => {
          this.removeFromLoading(classCourseID);
        });
      }, this.props.history);
    });
  }
  handleFacultyChange = event => {
    const facultyID = event.target.value;
    this.setState({
      isClassCourseLoading: true
    }, () => {
      axiosRequest('get', `admin/faculty-load/${facultyID}`, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          facultyID,
          facultyLoad: [...data],
          isClassCourseLoading: false
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isClassCourseLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleSearchInputChange = event => {
    this.setState({
      ...this.state,
      searchQuery: event.target.value
    });
  }
  renderAssignedTo = classCourse => {
    const { faculty } = this.state;
    
    let assignedTo = faculty.find(f => f.id === classCourse.faculty_load.faculty_id);
    if (assignedTo) {
      return (
        <div className='font-italic text-right'>
          The load is already assigned to {assignedTo.name} - {assignedTo.position}.
        </div>
      );
    }

    return null;
  }
  renderClassCourses = () => {
    const {
      class_courses,
      facultyID,
      isClassCourseLoading,
      facultyLoad,
      loadingClassCourses,
      classCourseError,
      formError,
      searchQuery,
      faculty
    } = this.state;

    if (isClassCourseLoading) {
      return (
        <LoadingIcon />
      );
    }
    
    if (classCourseError) {
      return (
        <Alert variant='danger'>
          {classCourseError}
        </Alert>
      );
    }

    if (!facultyID) {
      return;
    }

    let searchFilteredClassCourses = [...class_courses];
    let searchFilteredFacultyLoads = [...facultyLoad];

    if (searchQuery.toString()) {
      searchFilteredClassCourses = [...class_courses].filter(f => (
        f.course.code.indexOf(searchQuery) > -1 ||
        f.course.description.indexOf(searchQuery) > -1 ||
        f.school_class.program.code.indexOf(searchQuery) > -1 ||
        f.school_class.program.description.indexOf(searchQuery) > -1 ||
        f.school_class.year_level.code.indexOf(searchQuery) > -1 ||
        f.school_class.year_level.description.indexOf(searchQuery) > -1 ||
        f.school_class.section.code.indexOf(searchQuery) > -1
      ));
      searchFilteredFacultyLoads = [...facultyLoad].filter(f => (
        f.class_course.course.code.indexOf(searchQuery) > -1 ||
        f.class_course.course.description.indexOf(searchQuery) > -1 ||
        f.class_course.school_class.program.code.indexOf(searchQuery) > -1 ||
        f.class_course.school_class.program.description.indexOf(searchQuery) > -1 ||
        f.class_course.school_class.year_level.code.indexOf(searchQuery) > -1 ||
        f.class_course.school_class.year_level.description.indexOf(searchQuery) > -1 ||
        f.class_course.school_class.section.code.indexOf(searchQuery) > -1
      ));
    }
    
    let assignedLoads = [];
    for (let i = 0; i < facultyLoad.length; i++) {
      assignedLoads.push(facultyLoad[i].class_course.id);
    }

    let filteredClassCourses = [...searchFilteredClassCourses].filter((class_course) => {
      return assignedLoads.indexOf(class_course.id) === -1;
    });

    let alreadyAssignedClassCourses = [...filteredClassCourses].filter((filteredClassCourse) => {
      return filteredClassCourse.faculty_load !== null;
    });

    let selectedFaculty = faculty.find(f => f.id === +facultyID);

    return (
      <>
        {
          selectedFaculty && (
            <Card.Title>
              <div>{`${selectedFaculty.name}`}</div>
              <div>{`${selectedFaculty.position}`}</div>
            </Card.Title>
          )
        }
        {
          formError && (
            <Alert variant='danger'>
              {formError}
            </Alert>
          )
        }
        <Row>
          <Col lg={6} className='ml-auto'>
            <div className='mb-2 mt-1'>
              <Form.Control type='search' placeholder='Search classrooms...' value={searchQuery} onChange={this.handleSearchInputChange} />
            </div>
          </Col>
        </Row>
        {
          searchFilteredClassCourses.length > 0 || searchFilteredFacultyLoads.length > 0 ? (
            <ListGroup>
              {
                searchFilteredFacultyLoads.map((load) => (
                  <ListGroup.Item key={load.class_course.id} variant='green'>
                    <div className='d-flex'>
                      <div className='pr-2'>
                        {
                          loadingClassCourses.indexOf(load.class_course.id) !== -1 ? (
                            <LoadingIcon />
                          ) : (
                            <FontAwesomeIcon icon='check-circle' />
                          )
                        }
                      </div>
                      <div className='flex-fill'>
                        <b>{load.class_course.course.code}</b>
                        <div>{load.class_course.course.description}</div>
                        <b>{`${load.class_course.school_class.program.description} (${load.class_course.school_class.program.code})`}</b>
                        <div className='font-weight-bold'>
                          {`${load.class_course.school_class.year_level.description}
                          (${load.class_course.school_class.year_level.code}) - ${load.class_course.school_class.section.code}`}
                        </div>
                      </div>
                      <div className='d-flex align-items-center'>
                        <Button
                          size='sm'
                          variant='danger'
                          onClick={() => this.handleUnassign(load.id, load.class_course.id)}
                          disabled={loadingClassCourses.indexOf(load.class_course.id) !== -1}
                          >
                            Unassign <FontAwesomeIcon icon='angle-double-right' />
                        </Button>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))
              }
              {
                filteredClassCourses.map((classCourse) => {
                  return classCourse.faculty_load === null ? (
                    <ListGroup.Item key={classCourse.id}>
                      <div className='d-flex'>
                        {
                          loadingClassCourses.indexOf(classCourse.id) !== -1 ? (
                            <div className='pr-2'>
                              <LoadingIcon />
                            </div>
                          ) : (
                            <div className='pr-4'></div>
                          )
                        }
                        <div className='flex-fill'>
                          <b>{classCourse.course.code}</b>
                          <div>{classCourse.course.description}</div>
                          <b>{`${classCourse.school_class.program.description} (${classCourse.school_class.program.code})`}</b>
                          <div className='font-weight-bold'>
                            {`${classCourse.school_class.year_level.description}
                            (${classCourse.school_class.year_level.code}) - ${classCourse.school_class.section.code}`}
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <Button
                            size='sm'
                            variant='green'
                            onClick={() => this.handleAssign(classCourse.id)}
                            disabled={loadingClassCourses.indexOf(classCourse.id) !== -1}>
                            Assign <FontAwesomeIcon icon='angle-double-right' /></Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ) : null;
                })
              }
              {
                alreadyAssignedClassCourses.map(classCourse => (
                  <ListGroup.Item key={classCourse.id} className='bg-light' style={{ opacity: '.9' }}>
                    <div className='d-flex'>
                      {
                        loadingClassCourses.indexOf(classCourse.id) !== -1 ? (
                          <div className='pr-2'>
                            <LoadingIcon />
                          </div>
                        ) : (
                          <div className='pr-4'></div>
                        )
                      }
                      <div className='flex-fill'>
                        <b>{classCourse.course.code}</b>
                        <div>{classCourse.course.description}</div>
                        <b>{`${classCourse.school_class.program.description} (${classCourse.school_class.program.code})`}</b>
                        <div className='font-weight-bold'>
                          {`${classCourse.school_class.year_level.description}
                          (${classCourse.school_class.year_level.code}) - ${classCourse.school_class.section.code}`}
                        </div>
                        { this.renderAssignedTo(classCourse) }
                      </div>
                    </div>
                  </ListGroup.Item>
                ))
              }
            </ListGroup>
          ) : searchQuery ? (
            <Alert variant='light'>
              Nothing to show.
            </Alert>
          ) : (
            <Alert variant='warning'>
              Nothing to show. Try enrolling a <b>class to a course</b> first.
            </Alert>
          )
        }
        
      </>
    );
  }
  renderContent = () => {
    const { isLoading, faculty, errorMessage } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    
    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (faculty.length === 0) {
      return (
        <Alert variant='warning'>
          Nothing to show. Try adding a <b>faculty</b> first.
        </Alert>
      );
    }

    return (
      <>
        <Form.Group>
          <Form.Label>Faculty</Form.Label>
          <Form.Control as='select' onChange={this.handleFacultyChange}>
            <option disabled hidden selected>Select a faculty to assign...</option>
            {
              faculty.map((f, index) => (
                <option key={index} value={f.id}>{`${f.name} - ${f.position}`}</option>
              ))
            }
          </Form.Control>
        </Form.Group>
        <div className='dropdown-divider'></div>
        { this.renderClassCourses() }
      </>
    );
  }
  render() {
    return (
      <Card>
        <Card.Body>
          { this.renderContent() }
        </Card.Body>
      </Card>
    );
  }
}