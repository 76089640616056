import React, { Component } from 'react';
import { Alert, Button, Col, Dropdown, Image, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../actions';
import './style.scss';
import { Assignment, Quiz, Exam, File, Link } from './parts';
import axiosRequest from '../../../util/helpers/axiosRequest';
import moment from 'moment';
import PromptDeleteModal from '../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../common/RichTextEditor/RichTextEditor';

const materialTypes = [
  'assignment',
  'quiz',
  'exam',
  'file',
  'link'
];

const questionableTypes = [
  'quiz',
  'exam'
];

const viewableTypes = [
  'assignment'
];

const gradeableTypes = [
  'assignment',
  'quiz',
  'exam'
];

const typeTitleMap = {
  assignment: 'Assignment',
  quiz: 'Quiz',
  exam: 'Exam',
  file: 'File',
  link: 'Link'
};

class Materials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materials: {
        data: []
      },
      gradeCategories: [],
      examCategories: [],
      student_count: 0,
      isLoading: true,
      errorMessage: '',
      isNextPageLoading: false,
      nextPageError: '',
      materialNav: '',
      showModal: false,
      modalData: null,
      deleteModal: {
        show: false,
        title: '',
        data: null,
        type: '',
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      },
      fileModal: {
        show: false,
        data: null
      },
      asyncSchedules: [],
      asyncSchedulesRaw: {
        dateSchedules: [],
        weeklySchedules: [],
        asyncSchedules: []
      }
    };

    this.modalTimer = null;
  }
  componentDidMount() {
    const { classID } = this.props.match.params;

    axiosRequest('get', `faculty/class/${classID}/material`, null, ({ data: { data }}) => {
      data.materials.data = data.materials.data.map(material => {
        if (material.type === 'assignment') {
          material.submissions = material.submissions.filter((submission, index, self) => {
            return self.findIndex(s => submission.student_id === s.student_id) === index;
          });
        } else if (material.type === 'quiz') {
          material.attempts = material.attempts.filter((attempt, index, self) => {
            return self.findIndex(a => attempt.student_id === a.student_id) === index;
          });
        }
        return material;
      });
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  showModal = key => {
    if (materialTypes.indexOf(key) !== -1) {
      this.setState({
        ...this.state,
        materialNav: key,
        showModal: true
      });
    }
  }
  hideModal = () => {
    this.setState({
      ...this.state,
      showModal: false,
      modalData: null,
      asyncSchedules: [],
      asyncSchedulesRaw: {
        dateSchedules: [],
        weeklySchedules: [],
        asyncSchedules: []
      }
    });
  }
  showFileModal = (event, material) => {
    this.setState({
      ...this.state,
      fileModal: {
        show: true,
        data: material
      }
    });
  }
  hideFileModal = () => {
    this.setState({
      ...this.state,
      fileModal: {
        show: false,
        data: null
      }
    });
  }
  handleEdit = (data, type) => {
    this.setState({
      ...this.state,
      modalData: data
    }, () => {
      this.showModal(type);
    });
  }
  showDeleteModal = (data, type) => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        title: `Delete ${type === 'assignment' ? 'assignment/activity' : type}`,
        type,
        data,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        title: '',
        type: '',
        data: null,
        isLoading: false,
        errorMessage: '',
        successMessage: ''
      }
    });
  }
  setAsyncSchedules = (data, currentAsyncDate = null, cb = () => {}) => { //TODO
    const { materialNav } = this.state;

    let schedules = [];
    let minDate = null;
    let maxDate = null;
    let baseDate = currentAsyncDate ? moment(currentAsyncDate.date) : moment();
    for (let i = 0; i < data.weeklySchedules.length; i++) {
      let startDate = baseDate.clone().day(data.weeklySchedules[i].day).subtract(21, 'days');
      let tempSchedules = [];
      while (tempSchedules.length < 5 || (minDate && maxDate)) {
        startDate.add(7, 'days');
        if (minDate && maxDate && !startDate.isBetween(minDate, maxDate, 'day', '[]')) {
          break;
        }
        if (data.asyncSchedules.length > 0) {
          let tempDate = data.asyncSchedules.find(das => {
            return das.schedule_date === startDate.format('YYYY-MM-DD') &&
                    das.start_time === data.weeklySchedules[i].start_time &&
                    das.end_time === data.weeklySchedules[i].end_time &&
                    das.schedule_from === 'weekly_schedule';
          });
          if (!tempDate) {
            tempSchedules = [
              ...tempSchedules,
              {
                date: startDate.format('YYYY-MM-DD'),
                start_time: data.weeklySchedules[i].start_time,
                end_time: data.weeklySchedules[i].end_time,
                schedule_from: 'weekly_schedule'
              }
            ];
          }
        } else {
          tempSchedules = [
            ...tempSchedules,
            {
              date: startDate.format('YYYY-MM-DD'),
              start_time: data.weeklySchedules[i].start_time,
              end_time: data.weeklySchedules[i].end_time,
              schedule_from: 'weekly_schedule'
            }
          ];
        }
      }
      if (!minDate && !maxDate) {
        minDate = tempSchedules[0].date;
        maxDate = tempSchedules[tempSchedules.length-1].date;
      }
      schedules = [...schedules, ...tempSchedules];
    }
    schedules.sort((a, b) => {
      let aDate = moment(a.date);
      let bDate = moment(b.date);

      if (aDate.isAfter(bDate)) {
        return 1;
      } else if (aDate.isBefore(bDate)) {
        return -1;
      }
      return 0;
    });

    let currentAsyncDateSet = false;
    if (schedules.length > 0) {
      let startSchedule = schedules[0];
      let endSchedule = schedules[schedules.length-1];
      let filteredDateSchedules = [];
      for (let i = 0; i < data.dateSchedules.length; i++) {
        if (materialNav !== 'exam' && data.dateSchedules[i].type === 'Exam') {
          continue;
        }
        if (moment(data.dateSchedules[i].schedule_date).isBetween(startSchedule.date, endSchedule.date, 'day', '[]')) {
          let tempDsIndex = schedules.findIndex(s => data.dateSchedules[i].schedule_date === s.date);
          if (tempDsIndex !== -1) {
            if (
                currentAsyncDate && 
                currentAsyncDate.date === data.dateSchedules[i].schedule_date &&
                currentAsyncDate.start_time === data.dateSchedules[i].start_time &&
                currentAsyncDate.end_time === data.dateSchedules[i].end_time &&
                currentAsyncDate.schedule_from === 'date_schedule'
              ) {
                currentAsyncDateSet = true;
                schedules[tempDsIndex] = {
                  date: data.dateSchedules[i].schedule_date,
                  start_time: data.dateSchedules[i].start_time,
                  end_time: data.dateSchedules[i].end_time,
                  schedule_from: 'date_schedule'
                };
            } else {
              schedules[tempDsIndex] = {
                date: data.dateSchedules[i].schedule_date,
                start_time: data.dateSchedules[i].start_time,
                end_time: data.dateSchedules[i].end_time,
                schedule_from: 'date_schedule'
              };
            }
          } else {
            if (data.asyncSchedules.length > 0) {
              let tempDate = data.asyncSchedules.find(das => {
                return das.schedule_date === data.dateSchedules[i].schedule_date &&
                        das.start_time === data.dateSchedules[i].start_time &&
                        das.end_time === data.dateSchedules[i].end_time &&
                        das.schedule_from === 'date_schedule';
              });
              if (!tempDate) {
                filteredDateSchedules = [
                  ...filteredDateSchedules,
                  {
                    date: data.dateSchedules[i].schedule_date,
                    start_time: data.dateSchedules[i].start_time,
                    end_time: data.dateSchedules[i].end_time,
                    schedule_from: 'date_schedule'
                  }
                ];
              }
            } else {
              filteredDateSchedules = [
                ...filteredDateSchedules,
                {
                  date: data.dateSchedules[i].schedule_date,
                  start_time: data.dateSchedules[i].start_time,
                  end_time: data.dateSchedules[i].end_time,
                  schedule_from: 'date_schedule'
                }
              ];
            }
          }
        } else if (moment(data.dateSchedules[i].schedule_date).isBetween(moment(startSchedule.date).subtract(7, 'days'), moment(endSchedule.date).add(7, 'days'), 'day', '()')) {
          filteredDateSchedules = [
            ...filteredDateSchedules,
            {
              date: data.dateSchedules[i].schedule_date,
              start_time: data.dateSchedules[i].start_time,
              end_time: data.dateSchedules[i].end_time,
              schedule_from: 'date_schedule'
            }
          ]
        }
      }
      schedules = [
        ...schedules,
        ...filteredDateSchedules
      ];
    } else {
      for (let i = 0; i < data.dateSchedules.length; i++) {
        if (!(materialNav !== 'exam' && data.dateSchedules[i].type === 'Exam')) {
          schedules.push({
            date: data.dateSchedules[i].schedule_date,
            start_time: data.dateSchedules[i].start_time,
            end_time: data.dateSchedules[i].end_time,
            schedule_from: 'date_schedule'
          });
        }
      }
    }

    if (currentAsyncDate && !currentAsyncDateSet) {
      schedules = [...schedules, currentAsyncDate];
    }
    schedules.sort((a, b) => {
      let aDate = moment(a.date);
      let bDate = moment(b.date);

      if (aDate.isAfter(bDate)) {
        return 1;
      } else if (aDate.isBefore(bDate)) {
        return -1;
      }
      return 0;
    });

    if (schedules.length > 0) {
      if (moment(schedules[schedules.length-1].date).isAfter(baseDate.clone().add(14, 'days'))) {
        const { classID } = this.props.match.params;
        axiosRequest('get', `faculty/class/${classID}/material/async-schedules/material/between/${schedules[0].date}/${schedules[schedules.length-1].date}`, null, ({ data: { data: newData }}) => {
          schedules = schedules.filter(s => {
            return !newData.find(nd => {
              if (currentAsyncDate) {
                return (
                  nd.schedule_date !== currentAsyncDate.date &&
                  nd.start_time !== currentAsyncDate.start_time &&
                  nd.end_time !== currentAsyncDate.end_time &&
                  nd.schedule_from !== currentAsyncDate.schedule_from
                );
              }

              return (
                nd.schedule_date === s.date &&
                nd.start_time === s.start_time &&
                nd.end_time === s.end_time &&
                nd.schedule_from === s.schedule_from
              );
            });
          });

          let asyncDate = currentAsyncDate;
          if (!currentAsyncDate) {
            asyncDate = schedules.find(as => moment().isSameOrBefore(as.date));
          }

          asyncDate = asyncDate ? {...asyncDate} : null;
          data.asyncSchedules = [...data.asyncSchedules, ...newData];
          this.setState({
            ...this.state,
            asyncSchedulesRaw: data,
            asyncSchedules: schedules
          }, () => {
            cb(true, asyncDate);
          });
        }, error => {
          cb(false);
        }, this.props.history);
      } else {
        let asyncDate = currentAsyncDate ? currentAsyncDate : schedules.find(as => moment().isSameOrBefore(as.date));
        asyncDate = asyncDate ? {...asyncDate} : null;
        this.setState({
          ...this.state,
          asyncSchedulesRaw: data,
          asyncSchedules: schedules
        }, () => {
          cb(true, asyncDate);
        });
      }
    } else {
      cb(false);
    }
  }
  handlePrevAsync = (asyncDate, cb = () => {}) => {
    const { materialNav, asyncSchedules, asyncSchedulesRaw: { dateSchedules, weeklySchedules, asyncSchedules: materialAsyncSchedules } } = this.state;
    const { classID } = this.props.match.params;

    let newAsyncDate = null;
    let newAsyncSchedules = [...asyncSchedules].map(as => ({...as}));
    if (asyncDate) {
      let index = asyncSchedules.findIndex(as1 => as1.date === asyncDate.date && as1.start_time === asyncDate.start_time && as1.end_time === asyncDate.end_time && as1.schedule_from === asyncDate.schedule_from);
      if (index === 0) {
        if (weeklySchedules.length > 0) {
          let firstDate = moment(asyncDate.date).startOf('week').format('YYYY-MM-DD');
          axiosRequest('get', `faculty/class/${classID}/material/async-schedules/material/prev/${firstDate}`, null, ({ data: { data }}) => {
            let tempAsyncSchedules = [];
            let minDate = null;
            for (let i = 0; i < weeklySchedules.length; i++) {
              let firstSchedule = asyncSchedules.find(as2 => moment(as2.date).format('dddd') === weeklySchedules[i].day);
              let tempDate = moment(asyncSchedules[0].date).day(weeklySchedules[i].day);

              if (firstSchedule) {
                tempDate = moment(firstSchedule.date);
              }

              let tempSchedules = [];
              while (tempSchedules.length < 5 || minDate) {
                tempDate.subtract(7, 'days');
                if (minDate && tempDate.isSameOrBefore(minDate, 'day')) {
                  break;
                }
                if (data && data.length > 0) {
                  let temp = data.find(das => {
                    return das.schedule_date === tempDate.format('YYYY-MM-DD') &&
                            das.start_time === weeklySchedules[i].start_time &&
                            das.end_time === weeklySchedules[i].end_time &&
                            das.schedule_from === 'weekly_schedule';
                  });
                  if (!temp) {
                    tempSchedules = [
                      {
                        date: tempDate.format('YYYY-MM-DD'),
                        start_time: weeklySchedules[i].start_time,
                        end_time: weeklySchedules[i].end_time,
                        schedule_from: 'weekly_schedule'
                      },
                      ...tempSchedules
                    ];
                  }
                } else {
                  tempSchedules = [
                    {
                      date: tempDate.format('YYYY-MM-DD'),
                      start_time: weeklySchedules[i].start_time,
                      end_time: weeklySchedules[i].end_time,
                      schedule_from: 'weekly_schedule'
                    },
                    ...tempSchedules
                  ];
                }
              }
              if (!minDate) {
                minDate = tempSchedules[0].date;
              }
              tempAsyncSchedules = [...tempSchedules, ...tempAsyncSchedules];
            }

            tempAsyncSchedules.sort((a, b) => {
              let aDate = moment(a.date);
              let bDate = moment(b.date);
        
              if (aDate.isAfter(bDate)) {
                return 1;
              } else if (aDate.isBefore(bDate)) {
                return -1;
              }
              return 0;
            });

            if (tempAsyncSchedules.length > 0) {
              let startSchedule = tempAsyncSchedules[0];
              let endSchedule = tempAsyncSchedules[tempAsyncSchedules.length-1];
              for (let i = 0; i < dateSchedules.length; i++) {
                if (materialNav !== 'exam' && dateSchedules[i].type === 'Exam') {
                  continue;
                }
                if (moment(dateSchedules[i].schedule_date).isBetween(startSchedule.date, endSchedule.date, 'day', '[]')) {
                  let tempDsIndex = tempAsyncSchedules.findIndex(s => dateSchedules[i].schedule_date === s.date);
                  if (tempDsIndex !== -1) {
                    tempAsyncSchedules[tempDsIndex] = {
                      date: dateSchedules[i].schedule_date,
                      start_time: dateSchedules[i].start_time,
                      end_time: dateSchedules[i].end_time,
                      schedule_from: 'date_schedule'
                    };
                  } else {
                    if (data && data.length > 0) {
                      let temp = data.find(das => {
                        return das.schedule_date === dateSchedules[i].schedule_date &&
                                das.start_time === dateSchedules[i].start_time &&
                                das.end_time === dateSchedules[i].end_time &&
                                das.schedule_from === 'date_schedule';
                      });
                      if (!temp) {
                        tempAsyncSchedules = [
                          ...tempAsyncSchedules,
                          {
                            date: dateSchedules[i].schedule_date,
                            start_time: dateSchedules[i].start_time,
                            end_time: dateSchedules[i].end_time,
                            schedule_from: 'date_schedule'
                          }
                        ];
                      }
                    } else {
                      tempAsyncSchedules = [
                        ...tempAsyncSchedules,
                        {
                          date: dateSchedules[i].schedule_date,
                          start_time: dateSchedules[i].start_time,
                          end_time: dateSchedules[i].end_time,
                          schedule_from: 'date_schedule'
                        }
                      ];
                    }
                  }
                } else if (moment(dateSchedules[i].schedule_date).isBetween(moment(startSchedule.date).subtract(7, 'days'), moment(endSchedule.date).add(7, 'days'), 'day', '()')) {
                  tempAsyncSchedules = [
                    ...tempAsyncSchedules,
                    {
                      date: dateSchedules[i].schedule_date,
                      start_time: dateSchedules[i].start_time,
                      end_time: dateSchedules[i].end_time,
                      schedule_from: 'date_schedule'
                    }
                  ];
                }
              }
            }

            if (tempAsyncSchedules.length > 0) {
              tempAsyncSchedules.sort((a, b) => {
                let aDate = moment(a.date);
                let bDate = moment(b.date);

                if (aDate.isAfter(bDate)) {
                  return 1;
                } else if (aDate.isBefore(bDate)) {
                  return -1;
                }
                return 0;
              });

              if (moment(tempAsyncSchedules[0].date).isBefore(moment(asyncDate.date).subtract(35, 'days'))) {
                axiosRequest('get', `faculty/class/${classID}/material/async-schedules/material/between/${tempAsyncSchedules[0].date}/${tempAsyncSchedules[tempAsyncSchedules.length-1].date}`, null, ({ data: { data: newData }}) => {
                  tempAsyncSchedules = tempAsyncSchedules.filter(tas => {
                    return !newData.find(nd => {
                      return (
                        nd.schedule_date === tas.date &&
                        nd.start_time === tas.start_time &&
                        nd.end_time === tas.end_time &&
                        nd.schedule_from === tas.schedule_from
                      );
                    });
                  });
                  
                  newAsyncDate = {...tempAsyncSchedules[tempAsyncSchedules.length-1]};
                  newAsyncSchedules = [...tempAsyncSchedules, ...newAsyncSchedules];

                  this.setState({
                    ...this.state,
                    asyncSchedules: newAsyncSchedules,
                    asyncSchedulesRaw: {
                      ...this.state.asyncSchedulesRaw,
                      asyncSchedules: [...materialAsyncSchedules, ...data]
                    }
                  }, () => {
                    cb(newAsyncDate);
                  });
                }, error => {}, this.props.history);
              } else {
                newAsyncDate = {...tempAsyncSchedules[tempAsyncSchedules.length-1]};
                newAsyncSchedules = [...tempAsyncSchedules, ...newAsyncSchedules];
  
                this.setState({
                  ...this.state,
                  asyncSchedules: newAsyncSchedules,
                  asyncSchedulesRaw: {
                    ...this.state.asyncSchedulesRaw,
                    asyncSchedules: [...materialAsyncSchedules, ...data]
                  }
                }, () => {
                  cb(newAsyncDate);
                });
              }
            }
          }, error => {
            this.setState({
              ...this.state,
              asyncSchedules: newAsyncSchedules
            }, () => {
              cb(newAsyncDate);
            });
          }, this.props.history);
        } else {
          this.setState({
            ...this.state,
            asyncSchedules: newAsyncSchedules
          }, () => {
            cb(newAsyncDate);
          });
        }
      } else {
        this.setState({
          ...this.state,
          asyncSchedules: newAsyncSchedules
        }, () => {
          cb({...newAsyncSchedules[index-1]});
        });
      }
    } else {
      this.setState({
        ...this.state,
        asyncSchedules: newAsyncSchedules
      }, () => {
        cb(newAsyncDate);
      });
    }
  }
  handleNextAsync = (asyncDate, cb = () => {}) => {
    const { materialNav, asyncSchedules, asyncSchedulesRaw: { dateSchedules, weeklySchedules, asyncSchedules: materialAsyncSchedules } } = this.state;
    const { classID } = this.props.match.params;

    let newAsyncDate = null;
    let newAsyncSchedules = [...asyncSchedules].map(as => ({...as}));
    if (asyncDate) {
      let index = asyncSchedules.findIndex(as1 => as1.date === asyncDate.date && as1.start_time === asyncDate.start_time && as1.end_time === asyncDate.end_time && as1.schedule_from === asyncDate.schedule_from);
      if (index === asyncSchedules.length-1) {
        if (weeklySchedules.length > 0) {
          let lastDate = moment(asyncDate.date).startOf('week').format('YYYY-MM-DD');
          axiosRequest('get', `faculty/class/${classID}/material/async-schedules/material/next/${lastDate}`, null, ({ data: { data }}) => {
            let tempAsyncSchedules = [];
            let maxDate = null;
            let reversedAsyncSchedules = [...asyncSchedules].reverse();
            for (let i = 0; i < weeklySchedules.length; i++) {
              let lastSchedule = reversedAsyncSchedules.find(as2 => moment(as2.date).format('dddd') === weeklySchedules[i].day);
              let tempDate = moment(reversedAsyncSchedules[0].date).day(weeklySchedules[i].day);

              if (lastSchedule) {
                tempDate = moment(lastSchedule.date);
              }

              let tempSchedules = [];
              while (tempSchedules.length < 5 || maxDate) {
                tempDate.add(7, 'days');
                if (maxDate && tempDate.isSameOrAfter(maxDate)) {
                  break;
                }
                if (data && data.length > 0) {
                  let temp = data.find(das => {
                    return das.schedule_date === tempDate.format('YYYY-MM-DD') &&
                            das.start_time === weeklySchedules[i].start_time &&
                            das.end_time === weeklySchedules[i].end_time &&
                            das.schedule_from === 'weekly_schedule';
                  });
                  if (!temp) {
                    tempSchedules = [
                      ...tempSchedules,
                      {
                        date: tempDate.format('YYYY-MM-DD'),
                        start_time: weeklySchedules[i].start_time,
                        end_time: weeklySchedules[i].end_time,
                        schedule_from: 'weekly_schedule'
                      }
                    ];
                  }
                } else {
                  tempSchedules = [
                    ...tempSchedules,
                    {
                      date: tempDate.format('YYYY-MM-DD'),
                      start_time: weeklySchedules[i].start_time,
                      end_time: weeklySchedules[i].end_time,
                      schedule_from: 'weekly_schedule'
                    }
                  ];
                }
              }
              if (!maxDate) {
                maxDate = tempSchedules[tempSchedules.length-1].date;
              }
              tempAsyncSchedules = [...tempAsyncSchedules, ...tempSchedules];
            }

            tempAsyncSchedules.sort((a, b) => {
              let aDate = moment(a.date);
              let bDate = moment(b.date);

              if (aDate.isAfter(bDate)) {
                return 1;
              } else if (aDate.isBefore(bDate)) {
                return -1;
              }
              return 0;
            });

            if (tempAsyncSchedules.length > 0) {
              let startSchedule = tempAsyncSchedules[0];
              let endSchedule = tempAsyncSchedules[tempAsyncSchedules.length-1];
              for (let i = 0; i < dateSchedules.length; i++) {
                if (materialNav !== 'exam' && dateSchedules[i].type === 'Exam') {
                  continue;
                }
                if (moment(dateSchedules[i].schedule_date).isBetween(startSchedule.date, endSchedule.date, 'day', '[]')) {
                  let tempDsIndex = tempAsyncSchedules.findIndex(s => dateSchedules[i].schedule_date === s.date);
                  if (tempDsIndex !== -1) {
                    tempAsyncSchedules[tempDsIndex] = {
                      date: dateSchedules[i].schedule_date,
                      start_time: dateSchedules[i].start_time,
                      end_time: dateSchedules[i].end_time,
                      schedule_from: 'date_schedule'
                    };
                  } else {
                    if (data && data.length > 0) {
                      let temp = data.find(das => {
                        return das.schedule_date === dateSchedules[i].schedule_date &&
                                das.start_time === dateSchedules[i].start_time &&
                                das.end_time === dateSchedules[i].end_time &&
                                das.schedule_from === 'date_schedule';
                      });
                      if (!temp) {
                        tempAsyncSchedules = [
                          {
                            date: dateSchedules[i].schedule_date,
                            start_time: dateSchedules[i].start_time,
                            end_time: dateSchedules[i].end_time,
                            schedule_from: 'date_schedule'
                          },
                          ...tempAsyncSchedules
                        ];
                      }
                    } else {
                      tempAsyncSchedules = [
                        {
                          date: dateSchedules[i].schedule_date,
                          start_time: dateSchedules[i].start_time,
                          end_time: dateSchedules[i].end_time,
                          schedule_from: 'date_schedule'
                        },
                        ...tempAsyncSchedules
                      ];
                    }
                  }
                } else if (moment(dateSchedules[i].schedule_date).isBetween(moment(startSchedule.date).subtract(7, 'days'), moment(endSchedule.date).add(7, 'days'), 'day', '()')) {
                  tempAsyncSchedules = [
                    ...tempAsyncSchedules,
                    {
                      date: dateSchedules[i].schedule_date,
                      start_time: dateSchedules[i].start_time,
                      end_time: dateSchedules[i].end_time,
                      schedule_from: 'date_schedule'
                    }
                  ];
                }
              }
            }

            if (tempAsyncSchedules.length > 0) {
              tempAsyncSchedules.sort((a, b) => {
                let aDate = moment(a.date);
                let bDate = moment(b.date);

                if (aDate.isAfter(bDate)) {
                  return 1;
                } else if (aDate.isBefore(bDate)) {
                  return -1;
                }
                return 0;
              });

              if (moment(tempAsyncSchedules[tempAsyncSchedules.length-1].date).isAfter(moment(asyncDate.date).add(35, 'days'))) {
                axiosRequest('get', `faculty/class/${classID}/material/async-schedules/material/between/${tempAsyncSchedules[0].date}/${tempAsyncSchedules[tempAsyncSchedules.length-1].date}`, null, ({ data: { data: newData }}) => {
                  tempAsyncSchedules = tempAsyncSchedules.filter(tas => {
                    return !newData.find(nd => {
                      return (
                        nd.schedule_date === tas.date &&
                        nd.start_time === tas.start_time &&
                        nd.end_time === tas.end_time &&
                        nd.schedule_from === tas.schedule_from
                      );
                    });
                  });
                  
                  newAsyncDate = {...tempAsyncSchedules[0]};
                  newAsyncSchedules = [...newAsyncSchedules, ...tempAsyncSchedules];
                  
                  this.setState({
                    ...this.state,
                    asyncSchedules: newAsyncSchedules,
                    asyncSchedulesRaw: {
                      ...this.state.asyncSchedulesRaw,
                      asyncSchedules: [...materialAsyncSchedules, ...data]
                    }
                  }, () => {
                    cb(newAsyncDate);
                  });
                }, error => {}, this.props.history);
              } else {
                newAsyncDate = {...tempAsyncSchedules[0]};
                newAsyncSchedules = [...newAsyncSchedules, ...tempAsyncSchedules];
                
                this.setState({
                  ...this.state,
                  asyncSchedules: newAsyncSchedules,
                  asyncSchedulesRaw: {
                    ...this.state.asyncSchedulesRaw,
                    asyncSchedules: [...materialAsyncSchedules, ...data]
                  }
                }, () => {
                  cb(newAsyncDate);
                });
              }
            }
          }, error => {}, this.props.history);
        } else {
          this.setState({
            ...this.state,
            asyncSchedules: newAsyncSchedules
          }, () => {
            cb(newAsyncDate);
          });
        }
      } else {
        this.setState({
          ...this.state,
          asyncSchedules: newAsyncSchedules
        }, () => {
          cb({...newAsyncSchedules[index+1]});
        });
      }
    } else {
      this.setState({
        ...this.state,
        asyncSchedules: newAsyncSchedules
      }, () => {
        cb(newAsyncDate);
      });
    }
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { materials } = this.state;

      const path = materials.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data: { materials: data } }}) => {
        data.data = data.data.map(material => {
          if (material.type === 'assignment') {
            material.submissions = material.submissions.filter((submission, index, self) => {
              return self.findIndex(s => submission.student_id === s.student_id) === index;
            });
          } else if (material.type === 'quiz') {
            material.attempts = material.attempts.filter((attempt, index, self) => {
              return self.findIndex(a => attempt.student_id === a.student_id) === index;
            });
          }
          return material;
        });
        this.setState({
          ...this.state,
          materials: {
            ...data,
            data: [
              ...this.state.materials.data,
              ...data.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleViewQuestionable = (event, material, nav = 'preview') => {
    event.preventDefault();
    const { classID } = this.props.match.params;
    this.props.history.push(`/faculty/class/${classID}/${material.type}/${material.id}/${nav}`);
  }
  handleView = (event, material) => {
    event.preventDefault();
    const { classID } = this.props.match.params;
    this.props.history.push(`/faculty/class/${classID}/${material.type}/${material.id}`);
  }
  handleDelete = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { classID } = this.props.match.params;
      const { deleteModal } = this.state;
      axiosRequest('delete', `faculty/class/${classID}/material/${deleteModal.type}/${deleteModal.data.id}`, null, ({ data: { message }}) => {
        const newMaterialsData = [...this.state.materials.data].filter(material => !(material.id === deleteModal.data.id && material.type === deleteModal.type));

        this.setState({
          ...this.state,
          materials: {
            ...this.state.materials,
            data: newMaterialsData
          },
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            successMessage: message
          }
        });
      }, (error) => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleSave = (data) => {
    this.setState({
      ...this.state,
      materials: {
        ...this.state.materials,
        data: [
          data,
          ...this.state.materials.data
        ]
      },
      asyncSchedules: [],
      asyncSchedulesRaw: {
        dateSchedules: [],
        weeklySchedules: [],
        asyncSchedules: []
      }
    });
  }
  handleUpdate = data => {
    const newMaterialsData = [...this.state.materials.data].map(material => {
      if (material.id === data.id && material.type === data.type) {
        return data;
      }

      return material;
    });
    this.setState({
      ...this.state,
      materials: {
        ...this.state.materials,
        data: newMaterialsData
      },
      asyncSchedules: [],
      asyncSchedulesRaw: {
        dateSchedules: [],
        weeklySchedules: [],
        asyncSchedules: []
      }
    });
  }
  renderButton = () => {
    return (
      <div className='text-right'>
        <Dropdown onSelect={this.showModal}>
          <Dropdown.Toggle variant='green' size='sm'>
            <FontAwesomeIcon icon='plus' /> Add Material
          </Dropdown.Toggle>

          <Dropdown.Menu className='material-menu'>
            <Dropdown.Item eventKey='assignment'>
              <span className='fa-layers fa-fw mr-2'>
                <FontAwesomeIcon icon='file' size='lg' />
                <FontAwesomeIcon icon='pencil-alt' inverse transform='shrink-4 right-2 down-4' />
              </span>
              Assignment/Activity
            </Dropdown.Item>
            <Dropdown.Item eventKey='quiz'>
              <span className='fa-layers fa-fw mr-2'>
                <FontAwesomeIcon icon='file-alt' size='lg' />
                <FontAwesomeIcon icon='pencil-alt' inverse transform='shrink-4 right-2 down-4' />
              </span>
              Quiz
            </Dropdown.Item>
            <Dropdown.Item eventKey='exam'>
              <span className='fa-layers fa-fw mr-2'>
                <FontAwesomeIcon icon='clipboard-list' size='lg' />
                <FontAwesomeIcon icon='pencil-alt' inverse transform='shrink-4 right-2 down-4' />
              </span>
              Exam
            </Dropdown.Item>
            <Dropdown.Item eventKey='file'>
              <FontAwesomeIcon icon='file-upload' size='lg' className='ml-1 mr-2' />
              File
            </Dropdown.Item>
            <Dropdown.Item eventKey='link'>
              <FontAwesomeIcon icon='link' size='lg' className='mr-2' />
              Link
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
  renderModal = () => {
    const { materialNav, showModal, modalData, gradeCategories, examCategories, asyncSchedules } = this.state;

    if (materialTypes.indexOf(materialNav) !== -1) {
      let modalBody = () => {
        switch(materialNav) {
          case 'assignment':
            return (
              <Assignment
                {...this.props}
                categories={gradeCategories}
                asyncSchedules={asyncSchedules}
                onSave={this.handleSave}
                onUpdate={this.handleUpdate}
                onCancel={this.hideModal}
                setAsyncSchedules={this.setAsyncSchedules}
                onPrevAsync={this.handlePrevAsync}
                onNextAsync={this.handleNextAsync}
                materialData={modalData} />
            );
          case 'quiz':
            return (
              <Quiz
                {...this.props}
                categories={gradeCategories}
                asyncSchedules={asyncSchedules}
                onSave={this.handleSave}
                onUpdate={this.handleUpdate}
                onCancel={this.hideModal}
                setAsyncSchedules={this.setAsyncSchedules}
                onPrevAsync={this.handlePrevAsync}
                onNextAsync={this.handleNextAsync}
                materialData={modalData} />
            );
          case 'exam':
            return (
              <Exam
                {...this.props}
                gradeCategories={gradeCategories}
                examCategories={examCategories}
                asyncSchedules={asyncSchedules}
                onSave={this.handleSave}
                onUpdate={this.handleUpdate}
                onCancel={this.hideModal}
                setAsyncSchedules={this.setAsyncSchedules}
                onPrevAsync={this.handlePrevAsync}
                onNextAsync={this.handleNextAsync}
                materialData={modalData} />
            );
          case 'file':
            return (
              <File
                {...this.props}
                onSave={this.handleSave}
                onUpdate={this.handleUpdate}
                onCancel={this.hideModal}
                materialData={modalData} />
            );
          case 'link':
            return (
              <Link
                {...this.props}
                onSave={this.handleSave}
                onUpdate={this.handleUpdate}
                onCancel={this.hideModal}
                materialData={modalData} />
            );
          default:
            return null;
        }
      };

      return (
        <Modal show={showModal} onHide={this.hideModal} backdrop='static'>
          {modalBody()}
        </Modal>
      );
    }

    return null;
  }
  renderFileTitle = material => {
    let from = material.faculty_load_id ? material.from : material.audience.from;
    let until = material.faculty_load_id ? material.until : material.audience.until;

    if (from && until && !moment().isBetween(from, until)) {
      return (
        <div className='btn-link font-weight-bold' onClick={e => this.showFileModal(e, material)}>{material.title} <span className='text-muted font-weight-normal font-italic'>(Unavailable)</span></div>
      );
    } else if (from && moment().isBefore(from)) {
      return (
        <div className='btn-link font-weight-bold' onClick={e => this.showFileModal(e, material)}>{material.title} <span className='text-muted font-weight-normal font-italic'>(Unavailable)</span></div>
      );
    } else if (until && moment().isAfter(until)) {
      return (
        <div className='btn-link font-weight-bold' onClick={e => this.showFileModal(e, material)}>{material.title} <span className='text-muted font-weight-normal font-italic'>(Unavailable)</span></div>
      );
    }

    return (
      <div className='btn-link font-weight-bold' onClick={e => this.showFileModal(e, material)}>{material.title}</div>
    );
  }
  handleFileDownload = fileID => {
    const { classID } = this.props.match.params;
    const { fileModal: { data } } = this.state;

    axiosRequest('get', `class/${classID}/material/${data.type}/${data.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handleViewSubmissions = (event, material) => {
    event.preventDefault();
    event.stopPropagation();

    const { classID } = this.props.match.params;
    if (material.type === 'assignment') {
      this.props.history.push(`/faculty/class/${classID}/${material.type}/${material.id}`, { nav: 'submissions' });
    } else {
      this.props.history.push(`/faculty/class/${classID}/${material.type}/${material.id}/submissions`);
    }
  }
  handlePreviewImages = (data, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/material/${data.type}/${data.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  renderFileModal = () => {
    const { fileModal: { data } } = this.state;
    const { classID } = this.props.match.params;

    if (data) {
      const images = data.files.filter(file => {
        return !!file.is_image;
      });

      const files = data.files.filter(file => {
        return !file.is_image;
      });

      if (data.files.length > 0) {
        return (
          <div className='p-1'>
            {
              images.length > 0 && (
                <Row>
                {
                  images.map((image, index) => (
                    <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(data, images, index)}>
                      <Image src={`${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/material/${data.type}/${data.id}/image/${image.id}`} thumbnail />
                    </Col>
                  ))
                }
                </Row>
              )
            }
            {
              files.length > 0 && (
                <div className='p-1'>
                  {
                    files.map(file => (
                      <div key={file.id} className='d-flex mb-2'>
                        <div className='mr-2'>
                          <FontAwesomeIcon icon='file' className='text-primary' />
                        </div>
                        <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                          <span className='btn-link' onClick={() => this.handleFileDownload(file.id)}>
                            {file.file_title}
                          </span>
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            }
          </div>
        );
      }
    }
  }
  renderMaterialIcon = type => {
    switch (type) {
      case 'assignment':
        return (
          <div className='fa-3x'>
            <div className='fa-layers'>
              <FontAwesomeIcon icon='file' size='lg' />
              <FontAwesomeIcon icon='pencil-alt' inverse transform='shrink-4 right-2 down-4' />
            </div>
          </div>
        );
      case 'quiz':
        return (
          <div className='fa-3x'>
            <div className='fa-layers'>
              <FontAwesomeIcon icon='file-alt' size='lg' />
              <FontAwesomeIcon icon='pencil-alt' inverse transform='shrink-4 right-2 down-4' />
            </div>
          </div>
        );
      case 'exam':
        return (
          <div className='fa-3x'>
            <div className='fa-layers'>
              <FontAwesomeIcon icon='clipboard-list' size='lg' />
              <FontAwesomeIcon icon='pencil-alt' inverse transform='shrink-4 right-2 down-4' />
            </div>
          </div>
        );
      case 'file':
        return (
          <div className='fa-3x'>
            <FontAwesomeIcon icon='file-download' size='lg' />
          </div>
        );
      case 'link':
        return (
          <div className='fa-3x'>
            <FontAwesomeIcon icon='link' />
          </div>
        );
      default:
        return null;
    }
  }
  renderExamCategory = material => {
    const { examCategories } = this.state;

    let examCategoryID = material.faculty_load_id ? material.exam_category_id : material.audience.exam_category_id;

    if (!examCategoryID) {
      return null;
    }

    let examCategory = examCategories.find(c => c.id === examCategoryID);

    if (examCategory) {
      return (
        <div className='font-italic'>
          {examCategory.title}
        </div>
      );
    }

    return null;
  }
  renderList = () => {
    const { materials, isLoading, errorMessage, isNextPageLoading, nextPageError, student_count } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (materials.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }
    
    return (
      <>
        <div className='material-list'>
          {
            materials.data.map(material => (
              <div key={`${material.type}-${material.id}`} className='d-flex' title={typeTitleMap[material.type]}>
                <div className='mr-2'>
                  {this.renderMaterialIcon(material.type)}
                </div>
                <div className='flex-fill'>
                  {
                    questionableTypes.indexOf(material.type) !== -1 ? (
                      <div className='btn-link font-weight-bold' onClick={e => this.handleViewQuestionable(e, material)}>
                        <div>{material.title}</div>
                        {this.renderExamCategory(material)}
                      </div>
                    ) : viewableTypes.indexOf(material.type) !== -1 ? (
                      <div className='btn-link font-weight-bold' onClick={e => this.handleView(e, material)}>
                        {material.title}
                        {
                          moment().isBefore(material.faculty_load_id ? material.from : material.audience.from) && (
                            <span className='text-muted font-weight-normal font-italic'> (Not yet available)</span>
                          )
                        }
                      </div>
                    ) : material.type === 'file' ? this.renderFileTitle(material) :
                      material.type === 'link' ? (
                        <div className='font-weight-bold'>
                          <a href={material.url} target='_blank' rel='noopener noreferrer'>
                            {material.title} <FontAwesomeIcon icon='external-link-alt' />
                          </a>
                        </div>
                    ) : (
                      <div className='font-weight-bold'>{material.title}</div>
                    )
                  }
                  <RichTextEditor.Viewer body={material.description} className='text-muted' />
                  {/* <div className='text-muted'>{material.description}</div> */}
                  {
                    material.type === 'file' ? (
                      <div className='text-muted font-italic d-flex'>
                        <div className='mr-1'>
                          Available
                        </div>
                        {
                          material.faculty_load_id ? (
                            <div>
                              {
                                material.from && material.until ? (
                                  <>
                                    <div>
                                      From: {moment(material.from).format('ddd, MMMM D, YYYY hh:mm A')}
                                    </div>
                                    <div>
                                      Until: {moment(material.until).format('ddd, MMMM D, YYYY hh:mm A')}
                                    </div>
                                  </>
                                ) : material.from ? (
                                  <div>
                                    From: {moment(material.from).format('ddd, MMMM D, YYYY hh:mm A')}
                                  </div>
                                ) : material.until ? (
                                  <div>
                                    Until: {moment(material.until).format('ddd, MMMM D, YYYY hh:mm A')}
                                  </div>
                                ) : ''
                              }
                            </div>
                          ) : (
                            <div>
                              {
                                material.audience.from && material.audience.until ? (
                                  <>
                                    <div>
                                      From: {moment(material.audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
                                    </div>
                                    <div>
                                      Until: {moment(material.audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
                                    </div>
                                  </>
                                ) : material.audience.from ? (
                                  <div>
                                    From: {moment(material.audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
                                  </div>
                                ) : material.audience.until ? (
                                  <div>
                                    Until: {moment(material.audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
                                  </div>
                                ) : ''
                              }
                            </div>
                          )
                        }
                      </div>
                    ) : material.type === 'link' ? '' : (
                      <div className='text-muted font-italic d-flex'>
                        <div className='mr-1'>
                          Available
                        </div>
                        {
                          material.faculty_load_id ? (
                            <div>
                              <div>
                                From: {moment(material.from).format('ddd, MMMM D, YYYY hh:mm A')}
                              </div>
                              <div>
                                Until: {moment(material.until).format('ddd, MMMM D, YYYY hh:mm A')}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div>
                                From: {moment(material.audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
                              </div>
                              <div>
                                Until: {moment(material.audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
                              </div>
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                  {
                    gradeableTypes.indexOf(material.type) !== -1 && (
                      <>
                        {
                          material.material_async_schedule && (
                            <div className='text-muted font-italic d-flex'>
                              <div className='mr-1'>
                                {`${material.type === 'exam' ? 'Asynchronous/exam schedule:' : 'Asynchronous schedule:'}`}
                              </div>
                              <div>
                                <div>
                                  {moment(material.material_async_schedule.schedule_date).format('ddd, MMMM D, YYYY')}
                                </div>
                                <div>
                                  {`${moment(material.material_async_schedule.start_time, 'HH:mm').format('hh:mm A')} - ${moment(material.material_async_schedule.end_time, 'HH:mm').format('hh:mm A')}`}
                                </div>
                              </div>
                            </div>
                          )
                        }
                        <div>
                          <span className='text-primary font-italic btn-link' title='View submissions' onClick={e => this.handleViewSubmissions(e, material)}>
                            Submissions: {material.type === 'assignment' ? (material.submissions ? material.submissions.length : 0) : (material.attempts ? material.attempts.length : 0)}/{material.type === 'exam' ? student_count.all : student_count.digital}
                          </span>
                        </div>
                      </>
                    )
                  }
                </div>
                <div className='d-flex d-md-none'>
                  <div className='mr-2'>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          <div>
                            {moment(material.created_at).format('MMM D, YYYY hh:mm A')}
                          </div>
                          <div>
                            {moment(material.created_at).fromNow()}
                          </div>
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <FontAwesomeIcon icon='clock' className='text-muted' />
                    </OverlayTrigger>
                  </div>
                  {
                    material.faculty_load_id && (
                      <Dropdown>
                        <Dropdown.Toggle as='span' className='text-green'>
                          <FontAwesomeIcon icon='cog' />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {
                            questionableTypes.indexOf(material.type) !== -1 && (
                              <Dropdown.Item onClick={e => this.handleViewQuestionable(e, material, 'questions')}>
                                <span className='fa-layers fa-fw'>
                                  <FontAwesomeIcon icon='question' transform='right-2' />
                                  <FontAwesomeIcon icon='plus' transform='shrink-6 left-6 down-4' />
                                </span> Questions
                              </Dropdown.Item>
                            )
                          }
                          <Dropdown.Item onClick={() => this.handleEdit(material, material.type)}>
                            <FontAwesomeIcon icon='pencil-alt' /> Edit
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => this.showDeleteModal(material, material.type)}>
                            <FontAwesomeIcon icon='trash-alt' /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )
                  }
                </div>
                <div className='font-small text-muted font-italic pr-3 d-none d-md-block'>
                  &mdash; <OverlayTrigger
                            overlay={
                              <Tooltip>
                                {moment(material.created_at).format('MMM D, YYYY hh:mm A')}
                              </Tooltip>
                            }
                            trigger={['hover', 'focus']}>
                            <FontAwesomeIcon icon='clock' />
                          </OverlayTrigger> {moment(material.created_at).fromNow()}
                </div>
                {
                  material.faculty_load_id && (
                    <div className='d-none d-md-block' style={{ whiteSpace: 'nowrap' }}>
                      {
                        questionableTypes.indexOf(material.type) !== -1 && (
                          <Button variant='primary' size='sm' className='mr-1' title='Questions' onClick={e => this.handleViewQuestionable(e, material, 'questions')}>
                            <span className='fa-layers fa-fw'>
                              <FontAwesomeIcon icon='question' transform='right-2' />
                              <FontAwesomeIcon icon='plus' transform='shrink-6 left-6 down-4' />
                            </span>
                          </Button>
                        )
                      }
                      <Button variant='info' size='sm' className='mr-1' onClick={() => this.handleEdit(material, material.type)}>
                        <FontAwesomeIcon icon='pencil-alt' />
                      </Button>
                      <Button variant='danger' size='sm' onClick={() => this.showDeleteModal(material, material.type)}>
                        <FontAwesomeIcon icon='trash-alt' />
                      </Button>
                    </div>
                  )
                }
              </div>
            ))
          }
        </div>
        {
          materials.next_page_url && (
            <div className='text-center mt-3'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                View more materials
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          )
        }
      </>
    );
  }
  render() {
    const { deleteModal, fileModal } = this.state;
    return (
      <>
        { this.renderButton() }
        <div className='mt-3'>
          { this.renderList() }
        </div>
        { this.renderModal() }
        <PromptDeleteModal {...deleteModal} onHide={this.hideDeleteModal} onDelete={this.handleDelete}>
          {
            !deleteModal.successMessage && (
              <div className='font-weight-bold'>
                <div>Are you sure you want to delete the {deleteModal.type === 'assignment' ? 'assignment/activity' : deleteModal.type}?</div>
                {
                  (deleteModal.type === 'assignment' || deleteModal.type === 'quiz' || deleteModal.type === 'exam') && (
                    <div>
                      Students' submissions will also be deleted.
                    </div>
                  )
                }
              </div>
            )
          }
          {
            deleteModal.data && (
              <Alert variant='light'>
                <div className='font-weight-bold'>{deleteModal.data.title}</div>
                <RichTextEditor.Viewer body={deleteModal.data.description} />
                {/* <div style={{ whiteSpace: 'pre-line' }}>{deleteModal.data.description}</div> */}
                <div>
                  {
                    (deleteModal.data.faculty_load_id && deleteModal.data.from) ? (
                      <div>From: {moment(deleteModal.data.from).format('MMMM D, YYYY hh:mm A')}</div>
                    ) : (!deleteModal.data.faculty_load_id && deleteModal.data.audience.from) ? (
                      <div>From: {moment(deleteModal.data.audience.from).format('MMMM D, YYYY hh:mm A')}</div>
                    ) : null
                  }
                  {
                    (deleteModal.data.faculty_load_id && deleteModal.data.until) ? (
                      <div>Until: {moment(deleteModal.data.until).format('MMMM D, YYYY hh:mm A')}</div>
                    ) : (!deleteModal.data.faculty_load_id && deleteModal.data.audience.until) ? (
                      <div>Until: {moment(deleteModal.data.audience.until).format('MMMM D, YYYY hh:mm A')}</div>
                    ) : null
                  }
                </div>
              </Alert>
            )
          }
        </PromptDeleteModal>
        <Modal show={fileModal.show} size='lg' onHide={this.hideFileModal}>
          <Modal.Header closeButton>
            <Modal.Title>{fileModal.data ? fileModal.data.title : 'File collection'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { this.renderFileModal() }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={this.hideFileModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Materials);