import React, { Component } from 'react';
import { Alert, Button, Col, Image, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../actions';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import moment from 'moment';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';
import RichTextEditor from '../../../common/RichTextEditor/RichTextEditor';

const typeTitleMap = {
  assignment: 'Assignment',
  quiz: 'Quiz',
  exam: 'Exam',
  file: 'File',
  link: 'Link'
};

class Materials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      materials: {
        data: []
      },
      isNextPageLoading: false,
      nextPageError: '',
      fileModal: {
        show: false,
        data: null
      }
    };
  }
  componentDidMount() {
    const { classID } = this.props.match.params;

    axiosRequest('get', `class/${classID}/material`, null, ({ data: { data }}) => {
      this.setState({
        ...this.state,
        materials: data,
        isLoading: false
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleNextPage = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isNextPageLoading: true
    }, () => {
      const { materials } = this.state;

      const path = materials.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
      
      axiosRequest('get', path, null, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          materials: {
            ...data,
            data: [
              ...this.state.materials.data,
              ...data.data
            ]
          },
          isNextPageLoading: false,
          nextPageError: ''
        });
      }, (error) => {
        this.setState({
          ...this.state,
          isNextPageLoading: false,
          nextPageError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  showFileModal = material => {
    this.setState({
      ...this.state,
      fileModal: {
        show: true,
        data: material
      }
    });
  }
  hideFileModal = () => {
    this.setState({
      ...this.state,
      fileModal: {
        show: false,
        data: null
      }
    });
  }
  handleView = (material) => {
    const { classID } = this.props.match.params;
    this.props.history.push(`/student/class/${classID}/${material.type}/${material.id}`);
  }
  renderFileTitle = material => {
    let from = material.faculty_load_id ? material.from : material.audience.from;
    let until = material.faculty_load_id ? material.until : material.audience.until;

    if (from && until && !moment().isBetween(from, until)) {
      return (
        <div className='font-weight-bold'>{material.title} <span className='text-muted font-weight-normal font-italic'>(Unavailable)</span></div>
      );
    } else if (from && moment().isBefore(from)) {
      return (
        <div className='font-weight-bold'>{material.title} <span className='text-muted font-weight-normal font-italic'>(Unavailable)</span></div>
      );
    } else if (until && moment().isAfter(until)) {
      return (
        <div className='font-weight-bold'>{material.title} <span className='text-muted font-weight-normal font-italic'>(Unavailable)</span></div>
      );
    }

    return (
      <div className='btn-link font-weight-bold' onClick={() => this.showFileModal(material)}>{material.title}</div>
    );
  }
  handleFileDownload = fileID => {
    const { fileModal: { data } } = this.state;
    const { classID } = this.props.match.params;

    axiosRequest('get', `class/${classID}/material/${data.type}/${data.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handlePreviewImages = (data, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/material/${data.type}/${data.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  renderFileModal = () => {
    const { fileModal: { data } } = this.state;
    const { classID } = this.props.match.params;
    
    if (data && data.files && data.files.length > 0) {
      const images = data.files.filter(file => {
        return !!file.is_image;
      });

      const files = data.files.filter(file => {
        return !file.is_image;
      });

      return (
        <div className='p-1'>
          {
            images.length > 0 && (
              <Row>
              {
                images.map((image, index) => (
                  <Col key={image.id} md={6} className='question-image-container' onClick={e => this.handlePreviewImages(data, images, index)}>
                    <Image src={`${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/material/${data.type}/${data.id}/image/${image.id}`}  thumbnail />
                  </Col>
                ))
              }
              </Row>
            )
          }
          {
            files.length > 0 && (
              <div className='p-1'>
                {
                  files.map(file => (
                    <div key={file.id} className='d-flex mb-2'>
                      <div className='mr-2'>
                        <FontAwesomeIcon icon='file' className='text-primary' />
                      </div>
                      <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                        <span className='btn-link' onClick={() => this.handleFileDownload(file.id)}>
                          {file.file_title}
                        </span>
                      </div>
                    </div>
                  ))
                }
              </div>
            )
          }
        </div>
      );
    }
  }
  renderMaterialIcon = type => {
    switch (type) {
      case 'assignment':
        return (
          <div className='fa-3x'>
            <div className='fa-layers'>
              <FontAwesomeIcon icon='file' size='lg' />
              <FontAwesomeIcon icon='pencil-alt' inverse transform='shrink-4 right-2 down-4' />
            </div>
          </div>
        );
      case 'quiz':
        return (
          <div className='fa-3x'>
            <div className='fa-layers'>
              <FontAwesomeIcon icon='file-alt' size='lg' />
              <FontAwesomeIcon icon='pencil-alt' inverse transform='shrink-4 right-2 down-4' />
            </div>
          </div>
        );
      case 'exam':
        return (
          <div className='fa-3x'>
            <div className='fa-layers'>
              <FontAwesomeIcon icon='clipboard-list' size='lg' />
              <FontAwesomeIcon icon='pencil-alt' inverse transform='shrink-4 right-2 down-4' />
            </div>
          </div>
        );
      case 'file':
        return (
          <div className='fa-3x'>
            <FontAwesomeIcon icon='file-download' size='lg' />
          </div>
        );
      case 'link':
        return (
          <div className='fa-3x'>
            <FontAwesomeIcon icon='link' />
          </div>
        );
      default:
        return null;
    }
  }
  renderExamCategory = material => {
    if (material.type !== 'exam') {
      return null;
    }

    let examCategory = material.faculty_load_id ? material.exam_category : material.audience.exam_category;

    if (examCategory) {
      return (
        <div className='font-italic'>
          {examCategory.title}
        </div>
      );
    }

    return null;
  }
  render() {
    const { materials, isLoading, errorMessage, isNextPageLoading, nextPageError, fileModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (materials.data.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <>
        <div className='material-list'>
          {
            materials.data.map(material => (
              <div key={`${material.type}-${material.id}`} className='d-flex' title={typeTitleMap[material.type]}>
                <div className='mr-2'>
                  {this.renderMaterialIcon(material.type)}
                </div>
                <div className='flex-fill'>
                  {
                    material.type === 'file' ? this.renderFileTitle(material) :
                      material.type === 'link' ? (
                        <div className='font-weight-bold'>
                          <a href={material.url} target='_blank' rel='noopener noreferrer'>
                            {material.title} <FontAwesomeIcon icon='external-link-alt' />
                          </a>
                        </div>
                      ) : material.type === 'assignment' ? (
                        <div>
                          {
                            moment().isBefore(material.faculty_load_id ? material.from : material.audience.from) ? (
                              <div className='font-weight-bold'>{material.title} <span className='text-muted font-weight-normal font-italic'>(Not yet available)</span></div>
                            ) : (
                              <div className='btn-link font-weight-bold' onClick={() => this.handleView(material)}>{material.title}</div>
                            )
                          }
                        </div>
                      ) : (
                      <div className='btn-link font-weight-bold' onClick={() => this.handleView(material)}>
                        <div>{material.title}</div>
                        {this.renderExamCategory(material)}
                      </div>
                    )
                  }
                  <RichTextEditor.Viewer body={material.description} />
                  {/* <div className='text-muted'>{material.description}</div> */}
                  {
                    material.type === 'file' ? (
                      <div className='text-muted font-italic d-flex'>
                        <div className='mr-1'>
                          Available
                        </div>
                        <div>
                          {
                            material.faculty_load_id ? (
                              material.from && material.until ? (
                                <>
                                  <div>
                                    From: {moment(material.from).format('ddd, MMMM D, YYYY hh:mm A')}
                                  </div>
                                  <div>
                                    Until: {moment(material.until).format('ddd, MMMM D, YYYY hh:mm A')}
                                  </div>
                                </>
                              ) : material.from ? (
                                <div>
                                  From: {moment(material.from).format('ddd, MMMM D, YYYY hh:mm A')}
                                </div>
                              ) : material.until ? (
                                <div>
                                  Until: {moment(material.until).format('ddd, MMMM D, YYYY hh:mm A')}
                                </div>
                              ) : ''
                            ) : (
                              material.audience.from && material.audience.until ? (
                                <>
                                  <div>
                                    From: {moment(material.audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
                                  </div>
                                  <div>
                                    Until: {moment(material.audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
                                  </div>
                                </>
                              ) : material.audience.from ? (
                                <div>
                                  From: {moment(material.audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
                                </div>
                              ) : material.audience.until ? (
                                <div>
                                  Until: {moment(material.audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
                                </div>
                              ) : ''
                            )
                          }
                        </div>
                      </div>
                    ) : material.type === 'link' ? '' : (
                      <div className='text-muted font-italic d-flex'>
                        <div className='mr-1'>
                          Available
                        </div>
                        {
                          material.faculty_load_id ? (
                            <div>
                              <div>
                                From: {moment(material.from).format('ddd, MMMM D, YYYY hh:mm A')}
                              </div>
                              <div>
                                Until: {moment(material.until).format('ddd, MMMM D, YYYY hh:mm A')}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div>
                                From: {moment(material.audience.from).format('ddd, MMMM D, YYYY hh:mm A')}
                              </div>
                              <div>
                                Until: {moment(material.audience.until).format('ddd, MMMM D, YYYY hh:mm A')}
                              </div>
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                </div>
                <div className='d-md-none'>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          {moment(material.created_at).format('MMM D, YYYY hh:mm A')}
                        </div>
                        <div>
                          {moment(material.created_at).fromNow()}
                        </div>
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <FontAwesomeIcon icon='clock' className='text-muted' />
                  </OverlayTrigger>
                </div>
                <div className='font-small text-muted font-italic pr-3 d-none d-md-block'>
                  &mdash; <OverlayTrigger
                            overlay={
                              <Tooltip>
                                {moment(material.created_at).format('MMM D, YYYY hh:mm A')}
                              </Tooltip>
                            }
                            trigger={['hover', 'focus']}>
                            <FontAwesomeIcon icon='clock' />
                          </OverlayTrigger> {moment(material.created_at).fromNow()}
                </div>
              </div>
            ))
          }
        </div>
        {
          materials.next_page_url && (
            <div className='text-center mt-3'>
              {
                isNextPageLoading && (
                  <LoadingIcon className='mr-2' sm />
                )
              }
              <span onClick={this.handleNextPage} className={`view-more ${isNextPageLoading ? 'disabled' : ''}`}>
                View more materials
                <FontAwesomeIcon icon='chevron-down' size='sm' className='ml-2' />
              </span>
              {
                nextPageError && (
                  <Alert variant='danger'>
                    {nextPageError}
                  </Alert>
                )
              }
            </div>
          )
        }
        <Modal show={fileModal.show} size='lg' onHide={this.hideFileModal}>
          <Modal.Header closeButton>
            <Modal.Title>{fileModal.data ? fileModal.data.title : 'File collection'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { this.renderFileModal() }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={this.hideFileModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Materials);