import React, { Component } from 'react';
import { Alert, Button, Col, Form, Image, InputGroup, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { showImagePreview } from '../../../../../actions';
import axiosRequest from '../../../../../util/helpers/axiosRequest';
import durationFormat from '../../../../../util/helpers/durationFormat';
import moment from 'moment';
import Validator from 'validatorjs';
import './style.scss';
import LoadingIcon from '../../../../common/LoadingIcon/LoadingIcon';

class Submissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      submissions: [],
      comments: [],
      comment: {
        input: '',
        isLoading: false,
        errorMessage: ''
      },
      editModal: {
        show: false,
        data: null,
        input: '',
        isLoading: false,
        errorMessage: ''
      },
      deleteModal: {
        show: false,
        data: null,
        isLoading: false,
        errorMessage: ''
      }
    };
  }
  componentDidMount() {
    const { material, match: { params: { classID } } } = this.props;

    axiosRequest('get', `class/${classID}/material/${material.type}/${material.id}/submission`, null, ({ data: { data }}) => {
      if (data.comments.length > 0) {
        data.comments.sort((a, b) => {
          let momentA = moment(a.updated_at);
          let momentB = moment(b.updated_at);

          if (momentA.isAfter(momentB)) {
            return -1;
          } else if (momentA.isBefore(momentB)) {
            return 1;
          }

          return 0;
        })
      }
      this.setState({
        ...this.state,
        isLoading: false,
        submissions: data.submissions,
        comments: data.comments
      });
    }, (error) => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleCommentInputChange = event => {
    this.setState({
      ...this.state,
      comment: {
        ...this.state.comment,
        input: event.target.value
      }
    });
  }
  handleComment = () => {
    this.setState({
      ...this.state,
      comment: {
        ...this.state.comment,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { comment } = this.state;
      const { material, match: { params: { classID } } } = this.props;

      let validator = new Validator({
        comment: comment.input
      }, {
        comment: `required`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          comment: {
            ...this.state.comment,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('post', `class/${classID}/material/${material.type}/${material.id}/comment`, {
        comment: comment.input
      }, ({ data: { data }}) => {
        data.files = [];
        this.setState({
          ...this.state,
          comments: [
            data,
            ...this.state.comments
          ],
          comment: {
            ...this.state.comment,
            input: '',
            isLoading: false
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          comment: {
            ...this.state.comment,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showEditModal = data => {
    this.setState({
      ...this.state,
      editModal: {
        show: true,
        data,
        input: data.description,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        data: null,
        input: '',
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleEditInputChange = event => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        input: event.target.value
      }
    });
  }
  handleEditComment = () => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { editModal } = this.state;
      const { material, match: { params: { classID } } } = this.props;

      let validator = new Validator({
        comment: editModal.input
      }, {
        comment: `required`
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            isLoading: false,
            errorMessage: validator.errors.errors[firstKey][0]
          }
        });
        return;
      }

      axiosRequest('patch', `class/${classID}/material/${material.type}/${material.id}/comment/${editModal.data.id}`, {
        comment: editModal.input
      }, ({ data: { data }}) => {
        let comments = [...this.state.comments].map(c => {
          if (c.id === data.id) {
            return {
              ...c,
              ...data
            };
          }

          return c;
        });
        this.setState({
          ...this.state,
          comments,
          editModal: {
            show: false,
            data: null,
            input: '',
            isLoading: false,
            errorMessage: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          editModal: {
            ...this.state.editModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  showDeleteModal = data => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        data,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        data: null,
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleDeleteComment = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        ...this.state.deleteModal,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {
      const { deleteModal } = this.state;
      const { material, match: { params: { classID } } } = this.props;

      axiosRequest('delete', `class/${classID}/material/${material.type}/${material.id}/comment/${deleteModal.data.id}`, null, ({ data: { data }}) => {
        let comments = [...this.state.comments].filter(c => c.id !== deleteModal.data.id);
        this.setState({
          ...this.state,
          comments,
          deleteModal: {
            show: false,
            data: null,
            isLoading: false,
            errorMessage: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          deleteModal: {
            ...this.state.deleteModal,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  handleFileDownload = (submissionID, fileID) => {
    const { material, match: { params: { classID } } } = this.props;

    axiosRequest('get', `class/${classID}/material/${material.type}/${material.id}/submission/${submissionID}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handleCommentFileDownload = (comment, fileID) => {
    const { material, match: { params: { classID } } } = this.props;

    axiosRequest('get', `class/${classID}/material/${material.type}/${material.id}/comment/${comment.id}/file/${fileID}`, null, ({ data: { data }}) => {
      window.open(data.url, '_blank');
    }, error => {}, this.props.history);
  }
  handlePreviewImages = (material, submission, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/material/${material.type}/${material.id}/submission/${submission.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  handlePreviewCommentImages = (material, comment, images, index) => {
    const { classID } = this.props.match.params;
    let newImages = images.map(image => ({
      id: image.id,
      src: `${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/material/${material.type}/${material.id}/comment/${comment.id}/image/${image.id}`
    }));
    this.props.showImagePreview(newImages, index);
  }
  renderSubmissionRemark = submission => {
    const { material } = this.props;

    let start = moment(material.faculty_load_id ? material.until : material.audience.until);
    let end = moment(material.type === 'assignment' ? submission.created_at : submission.latest_update);

    if (end.isAfter(start)) {
      let duration = moment.duration(end.diff(start));
      let humanized = duration.humanize();
      // humanized = humanized.split(' ').map(h => '' + h.charAt(0).toUpperCase() + h.slice(1)).join(' ');
      humanized = humanized.charAt(0).toUpperCase() + humanized.slice(1);
      // let detailed = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
      // let detailed = duration.months()+'mo ' + duration.days()+'d ' + duration.hours()+'h ' + duration.minutes()+'m ' + duration.seconds()+'s';
      return (
        <div className='text-danger font-weight-bold'>
          {humanized} late <span className='text-muted font-italic font-weight-normal'>({durationFormat(duration)})</span>
        </div>
      );
    }

    return (
      <div className='text-green font-weight-bold'>
        On time
      </div>
    );
  }
  renderAssignment = () => {
    const { submissions } = this.state;
    const { material, match: { params: { classID } } } = this.props;

    return (
      <div className='submission-list'>
        {
          submissions.map((submission, index) => {
            const images = submission.files ? submission.files.filter(file => {
              return !!file.is_image;
            }) : [];
  
            const files = submission.files ? submission.files.filter(file => {
              return !file.is_image;
            }) : [];

            return (
              <div key={index} className='py-3 p-md-3 py-md-0'>
                <div className='d-flex'>
                  <div className='flex-fill'>
                    <div className='h5'>Revision {index+1}</div>
                  </div>
                  <div className='d-md-none'>
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          <div>
                            {moment(submission.created_at).format('ddd, MMM D, YYYY hh:mm A')}
                          </div>
                          {this.renderSubmissionRemark(submission)}
                        </Tooltip>
                      }
                      trigger={['hover', 'focus']}>
                      <FontAwesomeIcon icon='clock' className='info-icon text-primary' />
                    </OverlayTrigger>
                  </div>
                  <div className='d-none d-md-block'>
                    <small className='d-block font-italic text-muted'>
                      <FontAwesomeIcon icon='clock' className='mr-1' />
                      {moment(submission.created_at).format('ddd, MMM D, YYYY hh:mm A')}
                    </small>
                    {this.renderSubmissionRemark(submission)}
                  </div>
                </div>
                <div className='text-muted' style={{ wordBreak: 'break-all' }}>
                  {submission.description}
                </div>
                  {
                    files.length > 0 && (
                      <div className='p-1'>
                        {
                          files.map(file => (
                            <small key={file.id} className='d-flex mb-2'>
                              <div className='mr-2'>
                                <FontAwesomeIcon icon='file' className='text-primary' />
                              </div>
                              <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                                <span className='btn-link' onClick={() => this.handleFileDownload(submission.id, file.id)}>
                                  {file.file_title}
                                </span>
                              </div>
                            </small>
                          ))
                        }
                      </div>
                    )
                  }
                  {
                    images.length > 0 && (
                      <Row noGutters>
                      {
                        images.map((image, index) => (
                          <Col key={image.id} xs={4} className='question-image-container' onClick={e => this.handlePreviewImages(material, submission, images, index)}>
                            <Image className='post-image' src={`${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/material/${material.type}/${material.id}/submission/${submission.id}/image/${image.id}`} thumbnail />
                          </Col>
                        ))
                      }
                      </Row>
                    )
                  }
              </div>
            );
          })
        }
      </div>
    );
  }
  renderQuiz = () => {
    const { submissions } = this.state;
    const { material } = this.props;

    return (
      <div className='submission-list'>
        {
          submissions.map((submission, index) => (
            <div key={index} className='py-3 p-md-3 py-md-0'>
              <div className='d-flex'>
                <div className='flex-fill'>
                  <div className='h5'>Submission {index+1}</div>
                  <div className='pl-2 text-muted'>
                    {submission.points} / {material.points}
                  </div>
                </div>
                <div className='d-md-none'>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          {moment(submission.latest_update).format('ddd, MMM D, YYYY hh:mm A')}
                        </div>
                        {this.renderSubmissionRemark(submission)}
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <FontAwesomeIcon icon='clock' className='info-icon text-primary' />
                  </OverlayTrigger>
                </div>
                <div className='d-none d-md-block'>
                  <small className='d-block font-italic text-muted'>
                    <FontAwesomeIcon icon='clock' className='mr-1' />
                    {moment(submission.latest_update).format('ddd, MMM D, YYYY hh:mm A')}
                  </small>
                  {this.renderSubmissionRemark(submission)}
                </div>
              </div>
              <div className='text-muted' style={{ wordBreak: 'break-all' }}>
                {submission.description}
              </div>
            </div>
          ))
        }
      </div>
    );
  }
  renderExam = () => {
    const { submissions } = this.state;
    const { material } = this.props;

    return (
      <div className='submission-list'>
        {
          submissions.map((submission, index) => (
            <div key={index} className='py-3 p-md-3 py-md-0'>
              <div className='d-flex'>
                <div className='flex-fill'>
                  <div className='h5'>Submission</div>
                  <div className='pl-2 text-muted'>
                    {submission.points} / {material.points}
                  </div>
                </div>
                <div className='d-md-none'>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        <div>
                          {moment(submission.latest_update).format('ddd, MMM D, YYYY hh:mm A')}
                        </div>
                      </Tooltip>
                    }
                    trigger={['hover', 'focus']}>
                    <FontAwesomeIcon icon='clock' className='info-icon text-primary' />
                  </OverlayTrigger>
                </div>
                <div className='d-none d-md-block'>
                  <small className='d-block font-italic text-muted'>
                    <FontAwesomeIcon icon='clock' className='mr-1' />
                    {moment(submission.latest_update).format('ddd, MMM D, YYYY hh:mm A')}
                  </small>
                </div>
              </div>
              <div className='text-muted' style={{ wordBreak: 'break-all' }}>
                {submission.description}
              </div>
            </div>
          ))
        }
      </div>
    );
  }
  renderFiles = comment => {
    const { material, match: { params: { classID } } } = this.props;

    if (comment && comment.files && comment.files.length > 0) {
      const images = comment.files.filter(file => {
        return !!file.is_image;
      });
  
      const files = comment.files.filter(file => {
        return !file.is_image;
      });

      return (
        <div>
          {
            images.length > 0 && (
              <Row className='flex-row-reverse' noGutters>
              {
                images.map((image, index) => (
                  <Col key={image.id} md={6} className='question-image-container' style={{ height: '4rem' }} onClick={e => this.handlePreviewCommentImages(material, comment, images, index)}>
                    <Image className='submission-comment-image' src={`${process.env['REACT_APP_API_BASE_URL']}/class/${classID}/material/${material.type}/${material.id}/comment/${comment.id}/image/${image.id}`} thumbnail />
                  </Col>
                ))
              }
              </Row>
            )
          }
          {
            files.length > 0 && (
              <div className='p-1'>
                {
                  files.map(file => (
                    <small key={file.id} className='d-flex mb-2'>
                      <div className='mr-2'>
                        <FontAwesomeIcon icon='file' className='text-primary' />
                      </div>
                      <div className='flex-fill' style={{ wordBreak: 'break-all' }}>
                        <span className='btn-link' onClick={() => this.handleCommentFileDownload(comment, file.id)}>
                          {file.file_title}
                        </span>
                      </div>
                    </small>
                  ))
                }
              </div>
            )
          }
        </div>
      );
    }

    return null;
  }
  renderComments = () => {
    const { comments, comment, editModal, deleteModal } = this.state;

    return (
      <div className='sticky-top pt-2'>
        <div className='rounded border comment-panel'>
          <div className='bg-light p-2 rounded-top border-bottom'>
            <h6 className='m-0'>Feedback</h6>
          </div>
          <div className='comment-text px-2 py-1'>
            {
              comments.length > 0 ? (
                <>
                  {
                    comments.map((cm, i) => (
                      <div key={i} className={`d-flex my-1 small ${cm.from === 'faculty' ? 'justify-content-start' : 'justify-content-end'}`}>
                        <div className={`rounded border text-${cm.from === 'faculty' ? 'left' : 'right'}`} style={{ maxWidth: '85%', minWidth: '70%', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                          <div className={`px-2 py-1 text-${cm.from === 'faculty' ? 'left' : 'right'}`} style={{ whiteSpace: 'pre-wrap' }}>
                            <div>{cm.description}</div>
                            {this.renderFiles(cm)}
                            <div className='font-italic text-muted'>
                              <div>
                                {moment(cm.updated_at).format('hh:mm:ss A')}
                              </div>
                              <div>
                                {moment(cm.updated_at).format('MMMM D, YYYY')}
                              </div>
                            </div>
                          </div>
                          {
                            cm.from === 'student' && (
                              <div className='flex-fill d-flex border-top'>
                                <div className='flex-fill border-right'>
                                  <Button variant='outline-info border-0' size='sm' block onClick={() => this.showEditModal(cm)}>
                                    <FontAwesomeIcon icon='pencil-alt' />
                                  </Button>
                                </div>
                                <div className='flex-fill'>
                                  <Button variant='outline-danger border-0' size='sm' block onClick={() => this.showDeleteModal(cm)}>
                                    <FontAwesomeIcon icon='trash-alt' />
                                  </Button>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    ))
                  }
                </>
              ) : (
                <Alert variant='light' className='text-center'>
                  Nothing to show.
                </Alert>
              )
            }
          </div>
          <div className='comment-box p-2 border-top bg-light'>
            {
              comment.errorMessage && (
                <Alert variant='danger' className='small'>
                  {comment.errorMessage}
                </Alert>
              )
            }
            <InputGroup size='sm'>
              <Form.Control as='textarea' rows={1} value={comment.input} onChange={this.handleCommentInputChange} disabled={comment.isLoading} />
              <InputGroup.Append>
                <Button variant='green' disabled={comment.isLoading} onClick={this.handleComment}>
                  Publish
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>

        <Modal show={editModal.show} size='sm' onHide={this.hideEditModal} backdrop='static'>
          <Modal.Header closeButton className='font-weight-bold'>
            <h6 className='m-0'>Edit feedback</h6>
          </Modal.Header>
          <Modal.Body>
            {
              editModal.errorMessage && (
                <Alert variant='danger' className='small'>
                  {editModal.errorMessage}
                </Alert>
              )
            }
            <Form.Group>
              <Form.Control as='textarea' rows={4} value={editModal.input} disabled={editModal.isLoading} onChange={this.handleEditInputChange} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' size='sm' disabled={editModal.isLoading} onClick={this.hideEditModal}>
              Cancel
            </Button>
            <Button variant='info' size='sm' disabled={editModal.isLoading} onClick={this.handleEditComment}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={deleteModal.show} size='sm' onHide={this.hideDeleteModal}>
          <Modal.Header closeButton className='font-weight-bold'>
            <h6 className='m-0'>Delete feedback</h6>
          </Modal.Header>
          <Modal.Body>
            {
              deleteModal.errorMessage && (
                <Alert variant='danger' className='small'>
                  {deleteModal.errorMessage}
                </Alert>
              )
            }
            {
              deleteModal.data && (
                <>
                  <div className='h6 font-weight-bold small'>
                    Are you sure you want to delete the feedback?
                  </div>
                  <Alert variant='light' className='small'>
                    <div>
                      {deleteModal.data.description}
                    </div>
                    <div className='font-italic'>
                      {moment(deleteModal.data.updated_at).format('MMMM D, YYYY hh:mm:ss A')}
                    </div>
                  </Alert>
                </>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='green' size='sm' disabled={deleteModal.isLoading} onClick={this.hideDeleteModal}>
              Cancel
            </Button>
            <Button variant='danger' size='sm' disabled={deleteModal.isLoading} onClick={this.handleDeleteComment}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  renderMaterial = () => {
    const { materialType } = this.props.match.params;

    switch (materialType) {
      case 'assignment':
        return this.renderAssignment();
      case 'quiz':
        return this.renderQuiz();
      case 'exam':
        return this.renderExam();
      default:
        return null;
    }
  }
  renderContent = () => {
    const { isLoading, errorMessage, submissions } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (submissions.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      )
    }

    return (
      <Row>
        <Col lg={9}>
          {this.renderMaterial()}
        </Col>
        <Col lg={3}>
          {this.renderComments()}
        </Col>
      </Row>
    );
  }
  render() {
    return (
      <div>
        {this.renderContent()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showImagePreview: (images, activeIndex = 0, deletable = false, onDelete = () => {}) => dispatch(showImagePreview(images, activeIndex, deletable, onDelete))
});

export default connect(null, mapDispatchToProps)(Submissions);