import React, { Component } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Validator from 'validatorjs';

export default class FillBlank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formInputs: {
        question: '',
        blanks: []
      },
      errorMessage: ''
    };
  }
  handleRemove = (blankIndex, answerIndex) => {
    let blanks = [...this.state.formInputs.blanks];
    
    if (blanks[blankIndex].answers.length > 1) {
      blanks[blankIndex].answers.splice(answerIndex, 1);
    
      this.setState({
        ...this.state,
        formInputs: {
          ...this.state.formInputs,
          blanks
        }
      });
    }
  }
  handleAddAnswer = (index) => {
    let blanks = [...this.state.formInputs.blanks];
    blanks[index].answers = [
      ...blanks[index].answers,
      {
        description: ''
      }
    ];

    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        blanks
      }
    });
  }
  handleInputChange = event => {
    let currentBlanks = [...this.state.formInputs.blanks];
    let blankCount = (event.target.value.match(/_/g) || []).length;
    const blanks = Array.from({ length: blankCount }, (b, index) => {
      if (currentBlanks[index]) {
        return currentBlanks[index];
      }

      return {
        answers: [
          {
            description: ''
          }
        ]
      }
    });
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [event.target.name]: event.target.value,
        blanks
      }
    });
  }
  handleAnswerInputChange = (event, blankIndex, answerIndex) => {
    let blanks = [...this.state.formInputs.blanks];

    blanks[blankIndex].answers[answerIndex].description = event.target.value;
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        blanks
      }
    });
  }
  handleSave = event => {
    event.preventDefault();
    const { formInputs } = this.state;

    let validator = new Validator(formInputs, {
      // question: 'required|min:3',
      // correctAnswer: 'required|in:t,f'
    });

    if (validator.fails()) {
      const firstKey = Object.keys(validator.errors.errors)[0];
      this.setState({
        ...this.state,
        errorMessage: validator.errors.errors[firstKey][0],
      });
      return;
    }

    this.props.onSave({
      type: 'fillBlank',
      ...formInputs
    });
  }
  render() {
    const { formInputs, errorMessage } = this.state;
    return (
      <>
        {
          errorMessage && (
            <Alert variant='danger'>
              {errorMessage}
            </Alert>
          )
        }
        <Form onSubmit={this.handleSave}>
          <Form.Group>
            <Form.Label>Question</Form.Label>
            <Form.Control
              as='textarea'
              rows='4'
              style={{ resize: 'none' }}
              name='question'
              value={formInputs.question}
              onChange={this.handleInputChange} />
            <Form.Text className='text-muted'>
              For words that you want to be blank, enter an underscore (_).
            </Form.Text>
          </Form.Group>
          {
            formInputs.blanks.length > 0 && (
              <div className='px-3 choice-list'>
                {
                  formInputs.blanks.map((blank, i) => (
                    <div key={i}>
                      <div className='h6'>Blank {i+1}</div>
                      <div className='px-3 mb-2'>
                        {
                          blank.answers.map((answer, j) => (
                            <div className='d-flex'>
                              <div className='flex-fill'>
                                <Form.Group key={j}>
                                  <Form.Label>Answer {j+1}</Form.Label>
                                  <Form.Control type='text' name='description' value={answer.description} onChange={e => this.handleAnswerInputChange(e, i, j)} />
                                </Form.Group>
                              </div>
                              {
                                blank.answers.length > 1 && (
                                  <div className='pl-3'>
                                    <small className='close' title='Remove answer' onClick={() => this.handleRemove(i, j)}>
                                      <FontAwesomeIcon icon='times' size='xs' />
                                    </small>
                                  </div>
                                )
                              }
                            </div>
                          ))
                        }
                        <Button variant='info' size='sm' onClick={() => this.handleAddAnswer(i)}>
                          Add another answer
                        </Button>
                      </div>
                    </div>
                  ))
                }
              </div>
            )
          }
          <div className='text-right'>
            <Button variant='danger' className='mr-2' size='sm' onClick={this.props.onCancel}>
              Cancel
            </Button>
            <Button variant='green' type='submit' size='sm'>
              Save question
            </Button>
          </div>
        </Form>
      </>
    );
  }
}

FillBlank.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

FillBlank.defaultProps = {
  onCancel: () => {},
  onSave: () => {}
}