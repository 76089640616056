import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import axiosRequest from '../../../util/helpers/axiosRequest';
import moment from 'moment';
import './style.scss';
import LoadingIcon from '../../common/LoadingIcon/LoadingIcon';

const accountTypeMap = {
  0: 'admin',
  1: 'school-admin',
  2: 'faculty',
  3: 'staff',
  4: 'parent',
  5: 'student'
};

class Upcoming extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      events: null,
      dates: [],
      ongoing: [],
      today: []
    };
  }
  componentDidMount() {
    const { currentUser, match: { params: { classID} } } = this.props;
    let accountType = '';
    if (currentUser.account_type === 4 || currentUser.account_type === 1) {
      accountType = `${accountTypeMap[currentUser.account_type]}/`;
    }
    axiosRequest('get', `${accountType}class/${classID}/upcoming`, null, ({ data: { data }}) => {
      let ongoingDates = Object.keys(data).filter(date => {
        return moment().isSameOrAfter(date);
      });

      let ongoing = [];
      let today = [];
      for (let i = 0; i < ongoingDates.length; i++) {
        if (moment().isAfter(ongoingDates[i], 'day')) {
          ongoing = [...ongoing, ...data[ongoingDates[i]]];
        } else {
          for (let j = 0; j < data[ongoingDates[i]].length; j++) {
            if (moment().isSameOrAfter(data[ongoingDates[i]][j].faculty_load_id ? data[ongoingDates[i]][j].upcoming_date : data[ongoingDates[i]][j].audience.from)) {
              ongoing = [...ongoing, data[ongoingDates[i]][j]];
            } else {
              today = [...today, data[ongoingDates[i]][j]];
            }
          }
        }
      }

      ongoing.sort((a, b) => {
        if (moment(a.grade_date).isBefore(b.grade_date)) {
          return -1;
        } else if (moment(a.grade_date).isAfter(b.grade_date)) {
          return 1;
        }
        
        return 0;
      });

      this.setState({
        ...this.state,
        events: data,
        dates: Object.keys(data),
        ongoing,
        today,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleClick = event => {
    const { currentUser, match: { params: { classID} } } = this.props;
    switch(currentUser.account_type) {
      case 2:
      case 5:
        this.props.history.push(`/${accountTypeMap[currentUser.account_type]}/class/${classID}/${event.type}/${event.id}`);
        break;
      default:
        this.props.history.push(`/${accountTypeMap[currentUser.account_type]}/class/${classID}/materials`);
        break;
    }
  }
  renderOngoing = () => {
    const { ongoing } = this.state;

    if (ongoing.length === 0) {
      return null;
    }

    return (
      <div className='mb-3'>
        <div className='font-weight-bold text-muted px-2'>Ongoing</div>
        <div className='border-bottom'></div>
        <div className='upcoming-event-list'>
          {
            ongoing.map(event => (
              <div key={event.id} className='py-1 px-3'>
                <div className='btn-link font-weight-bold' onClick={() => this.handleClick(event)}>{event.title}</div>
                <small className='d-block text-muted'>Until {moment(event.faculty_load_id ? event.until : event.audience.until).format('MMMM D, YYYY hh:mm A')}</small>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
  renderToday = () => {
    const { today } = this.state;

    if (today.length === 0) {
      return null;
    }

    return (
      <div className='mb-3'>
        <div className='font-weight-bold text-muted px-2'>Today</div>
        <div className='border-bottom'></div>
        <div className='upcoming-event-list'>
          {
            today.map(event => (
              <div key={event.id} className='py-1 px-3'>
                <div className='btn-link font-weight-bold' onClick={() => this.handleClick(event)}>{event.title}</div>
                <small className='d-block text-muted'>Starts at {moment(event.faculty_load_id ? event.upcoming_date : event.audience.from).format('hh:mm A')}</small>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
  renderRow = date => {
    const { events } = this.state;

    if (moment().isSameOrAfter(date)) {
      return null;
    }

    return (
      <div key={date}>
        <div className='font-weight-bold text-muted px-2'>{moment(date).format('MMMM D, YYYY')}</div>
        <div className='border-bottom'></div>
        <div className='upcoming-event-list'>
          {
            events[date].map(event => (
              <div key={event.id} className='py-1 px-3'>
                <div className='btn-link font-weight-bold' onClick={() => this.handleClick(event)}>{event.title}</div>
                <small className='d-block text-muted'>Starts at {moment(event.faculty_load_id ? event.upcoming_date : event.audience.from).format('hh:mm A')}</small>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
  render() {
    const { isLoading, errorMessage, dates } = this.state;

    if (isLoading) {
      return (
        <div className='px-3 pb-2'>
          <LoadingIcon />
        </div>
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger mx-2'>
          {errorMessage}
        </Alert>
      );
    }

    if (dates.length === 0) {
      return (
        <Alert variant='light'>
          Nothing to show.
        </Alert>
      );
    }

    return (
      <div className='upcoming-container' style={{ fontSize: '.875rem' }}>
        {this.renderOngoing()}
        {this.renderToday()}
        {
          dates.map(date => this.renderRow(date))
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, null)(Upcoming);