import React, { Component } from 'react';
import { Alert, Button, Card, Col, Form, InputGroup, Pagination, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Validator from 'validatorjs';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import { loadRule } from '../../../../util';

import AdminSettingsModal from '../../../modals/AdminSettingsModal/AdminSettingsModal';
import PromptDeleteModal from '../../../modals/PromptDeleteModal/PromptDeleteModal';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class TermList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formInputs: {
        schoolLevel: '',
        title: '',
        startDate: '',
        endDate: ''
      },
      terms: {
        data: []
      },
      schoolLevels: [],
      isLoading: true,
      errorMessage: '',
      isFormLoading: false,
      formError: '',
      editModal: {
        show: false,
        data: {
          id: '',
          schoolLevel: '',
          title: '',
          startDate: '',
          endDate: ''
        },
        isLoading: false,
        errorMessage: ''
      },
      deleteModal: {
        show: false,
        data: {
          id: '',
          schoolLevel: '',
          title: '',
          startDate: '',
          endDate: ''
        },
        isLoading: false,
        errorMessage: ''
      },
      search: {
        isLoading: false,
        errorMessage: '',
        query: '',
        isSearch: false
      }
    };
  }
  componentDidMount() {
    axiosRequest('get', 'admin/settings/term', null, ({ data: { data } }) => {
      this.setState({
        ...this.state,
        ...data,
        isLoading: false
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);

    loadRule(['date', 'after_or_equal']);
  }
  showEditModal = term => {
    this.setState({
      ...this.state,
      editModal: {
        show: true,
        data: {
          ...term,
          schoolLevel: term.school_level ? term.school_level.id : '',
          startDate: term.start_date ? term.start_date : '',
          endDate: term.end_date ? term.end_date : ''
        },
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideEditModal = () => {
    this.setState({
      ...this.state,
      editModal: {
        show: false,
        data: {
          id: '',
          schoolLevel: '',
          title: '',
          startDate: '',
          endDate: ''
        },
        isLoading: false,
        errorMessage: ''
      }
    })
  }
  handleEditInputChange = event => {
    this.setState({
      ...this.state,
      editModal: {
        ...this.state.editModal,
        data: {
          ...this.state.editModal.data,
          [event.target.name]: event.target.value
        }
      }
    });
  }
  handleEdit = event => {
    event.preventDefault();
    const editModal = {...this.state.editModal};

    let rules = {
      id: 'required|integer',
      schoolLevel: 'required|integer',
      title: 'required|max:255',
      startDate: 'required|date',
      endDate: 'required|date|after_or_equal:startDate'
    };

    let validation = new Validator(editModal.data, rules);

    if (validation.fails()) {
      const firstKey = Object.keys(validation.errors.errors)[0];
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          isLoading: false,
          errorMessage: validation.errors.errors[firstKey][0]
        }
      });
      return;
    }

    axiosRequest('patch', `admin/settings/term/${editModal.data.id}`, {
      schoolLevel: editModal.data.schoolLevel,
      title: editModal.data.title,
      startDate: editModal.data.startDate,
      endDate: editModal.data.endDate
    }, ({ data: { data } }) => {
      const newTerms = [...this.state.terms.data].map((p) => {
        if (p.id === editModal.data.id) {
          return data;
        }
        
        return {...p};
      });
      this.setState({
        ...this.state,
        terms: {
          ...this.state.terms,
          data: [
            ...newTerms
          ]
        },
        editModal: {
          show: false,
          data: {
            id: '',
            schoolLevel: '',
            title: '',
            startDate: '',
            endDate: ''
          },
          isLoading: false,
          errorMessage: ''
        }
      });
    }, error => {
      this.setState({
        ...this.state,
        editModal: {
          ...this.state.editModal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  showDeleteModal = term => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: true,
        data: {
          ...term,
          schoolLevel: term.school_level ? term.school_level.title : '',
          startDate: term.start_date ? term.start_date : '',
          endDate: term.end_date ? term.end_date : ''
        },
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  hideDeleteModal = () => {
    this.setState({
      ...this.state,
      deleteModal: {
        show: false,
        data: {
          id: '',
          schoolLevel: '',
          title: '',
          startDate: '',
          endDate: ''
        },
        isLoading: false,
        errorMessage: ''
      }
    });
  }
  handleDelete = () => {
    const deleteModal = {...this.state.deleteModal};

    axiosRequest('delete', `admin/settings/term/${deleteModal.data.id}`, null, (res) => {
      const newTerms = [...this.state.terms.data].filter(p => {
        return p.id !== deleteModal.data.id;
      });
      this.setState({
        ...this.state,
        terms: {
          ...this.state.terms,
          data: [
            ...newTerms
          ]
        },
        deleteModal: {
          show: false,
          data: {
            id: '',
            schoolLevel: '',
            title: '',
            startDate: '',
            endDate: ''
          },
          isLoading: false,
          errorMessage: ''
        }
      });
    }, error => {
      this.setState({
        ...this.state,
        deleteModal: {
          ...this.state.deleteModal,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        }
      });
    }, this.props.history);
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [event.target.name]: event.target.value
      }
    });
  }
  handleSubmit = event => {
    event.preventDefault();
    const { formInputs } = this.state;

    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: ''
    }, () => {
      let rules = {
        schoolLevel: 'required|integer',
        title: 'required|max:255',
        startDate: 'required|date',
        endDate: 'required|date|after_or_equal:startDate'
      };
  
      let validation = new Validator(formInputs, rules);
  
      if (validation.fails()) {
        const firstKey = Object.keys(validation.errors.errors)[0];
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: validation.errors.errors[firstKey][0]
        });
        return;
      }
  
      axiosRequest('post', 'admin/settings/term', {
        ...formInputs
      }, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          terms: {
            ...this.state.terms,
            data: [
              ...this.state.terms.data,
              {...data}
            ]
          },
          formInputs: {
            schoolLevel: '',
            title: '',
            startDate: '',
            endDate: ''
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handlePagination = url => {
    if (url) {
      this.setState({
        ...this.state,
        isLoading: true
      }, () => {
        const path = url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '');
        
        axiosRequest('get', path, null, ({ data: { data }}) => {
          this.setState({
            ...this.state,
            terms: data.terms,
            isLoading: false,
            errorMessage: ''
          });
        }, error => {
          this.setState({
            ...this.state,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    }
  }
  handleSearchInputChange = event => {
    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        query: event.target.value
      }
    });
  }
  handleSearch = event => {
    event.preventDefault();

    const { search } = this.state;

    if (!search.query && !search.isSearch) {
      return;
    }

    this.setState({
      ...this.state,
      search: {
        ...search,
        isLoading: true,
        errorMessage: ''
      }
    }, () => {

      axiosRequest('get', `admin/settings/term/search/${search.query}`, null, ({ data: { data } }) => {
        this.setState({
          ...this.state,
          terms: data.terms,
          search: {
            ...search,
            isLoading: false,
            errorMessage: '',
            isSearch: !!search.query
          }
        });
      }, error => {
        this.setState({
          ...this.state,
          search: {
            ...search,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          }
        });
      }, this.props.history);
    });
  }
  renderTable = () => {
    const { terms, search } = this.state;
    
    return (
      <>
        {
          search.errorMessage && (
            <Alert variant='danger'>
              {search.errorMessage}
            </Alert>
          )
        }
        <Row>
          <Col lg={6} className='ml-auto'>
            <div className='mb-2 mt-1'>
              <Form onSubmit={this.handleSearch}>
                <InputGroup>
                  <Form.Control type='search' placeholder='Search terms...' value={search.query} onChange={this.handleSearchInputChange} />
                  <InputGroup.Append>
                    <Button variant='primary' type='submit' disabled={search.isLoading}>
                      {
                        search.isLoading ? (
                          <LoadingIcon />
                        ) : (
                          <FontAwesomeIcon icon='search' />
                        )
                      }
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </div>
          </Col>
        </Row>
        {
          terms.data.length > 0 ? (
            <>
              <Table striped bordered hover responsive size='sm'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>School Level</th>
                    <th>Title</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    terms.data.map(term => (
                      <tr key={term.id}>
                        <td>{term.id}</td>
                        <td>{term.school_level ? term.school_level.title : '-'}</td>
                        <td>{term.title}</td>
                        <td>{term.start_date ? term.start_date : '-'}</td>
                        <td>{term.end_date ? term.end_date : '-'}</td>
                        <td className='text-center'>
                          <Button
                            variant='primary'
                            size='sm'
                            title='Edit'
                            className='mx-1'
                            onClick={e => this.showEditModal(term)}>
                            <FontAwesomeIcon icon='pencil-alt' />
                          </Button>
                          <Button variant='danger' size='sm' title='Delete' className='mx-1' onClick={e => this.showDeleteModal(term)}>
                            <FontAwesomeIcon icon='trash-alt' />
                          </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <Pagination className='justify-content-end'>
                <Pagination.First onClick={() => this.handlePagination(terms.first_page_url)} disabled={terms.current_page === 1} />
                <Pagination.Prev onClick={() => this.handlePagination(terms.prev_page_url)} disabled={terms.current_page === 1} />
                <Pagination.Next onClick={() => this.handlePagination(terms.next_page_url)} disabled={terms.current_page === terms.last_page} />
                <Pagination.Last onClick={() => this.handlePagination(terms.last_page_url)} disabled={terms.current_page === terms.last_page} />
              </Pagination>
            </>
          ) : (
            <Alert variant='light'>
              Nothing to show
            </Alert>
          )
        }
      </>
    );
  }
  renderContent = () => {
    const { isLoading, errorMessage, schoolLevels, formInputs, isFormLoading, formError, editModal, deleteModal } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    if (schoolLevels.length === 0) {
      return (
        <Alert variant='warning'>
          Unable to add a term with incomplete settings.
          Try adding a <b>school level</b> first.
        </Alert>
      );
    }

    return (
      <>
        {
          formError && (
            <Alert variant='danger'>{formError}</Alert>
          )
        }
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} lg={9}>
              <Form.Label>School Level</Form.Label>
              <Form.Control as='select' name='schoolLevel' value={formInputs.schoolLevel} onChange={this.handleInputChange} disabled={isFormLoading}>
                <option hidden value=''>Select school level...</option>
                {
                  schoolLevels.map(schoolLevel => (
                    <option key={schoolLevel.id} value={schoolLevel.id}>{schoolLevel.title}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={3}>
              <Form.Label>Term Title</Form.Label>
              <Form.Control type='text' placeholder='e.g. Prelim' name='title' value={formInputs.title} onChange={this.handleInputChange} disabled={isFormLoading} />
            </Form.Group>
            <Form.Group as={Col} lg={3}>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type='date'
                name='startDate'
                value={formInputs.startDate}
                onChange={this.handleInputChange} />
            </Form.Group>
            <Form.Group as={Col} lg={3}>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type='date'
                name='endDate'
                value={formInputs.endDate}
                onChange={this.handleInputChange} />
            </Form.Group>
            <Form.Group as={Col} lg={3} className='d-flex align-items-end'>
              <Button type='submit' variant='green' block disabled={isFormLoading}>Add term</Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <div className='dropdown-divider'></div>
        { this.renderTable() }

        <AdminSettingsModal
          {...editModal}
          title='Edit term'
          onHide={this.hideEditModal}
          onSubmit={this.handleEdit}>
            <Form.Group>
              <Form.Label>School Level</Form.Label>
              <Form.Control as='select' name='schoolLevel' value={editModal.data.schoolLevel} onChange={this.handleEditInputChange}>
                <option hidden value=''>Select school level...</option>
                {
                  schoolLevels.map(schoolLevel => (
                    <option key={schoolLevel.id} value={schoolLevel.id}>{schoolLevel.title}</option>
                  ))
                }
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Term Title</Form.Label>
              <Form.Control
                type='text'
                placeholder='e.g. Prelim'
                name='title'
                value={editModal.data.title}
                onChange={this.handleEditInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type='date'
                name='startDate'
                value={editModal.data.startDate}
                onChange={this.handleEditInputChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type='date'
                name='endDate'
                value={editModal.data.endDate}
                onChange={this.handleEditInputChange} />
            </Form.Group>
        </AdminSettingsModal>
        <PromptDeleteModal
          {...deleteModal}
          title='Delete term'
          onHide={this.hideDeleteModal}
          onDelete={this.handleDelete}>
            <Card.Subtitle>Are you sure you want to delete the term?</Card.Subtitle>
            <Alert variant='light'>
              <div>{deleteModal.data.schoolLevel}</div>
              <div>{deleteModal.data.title}</div>
              <div>{deleteModal.data.startDate}</div>
              <div>{deleteModal.data.endDate}</div>
            </Alert>
        </PromptDeleteModal>
      </>
    );
  }
  render() {
    return (
      <Card>
        <Card.Body>
          {this.renderContent()}
        </Card.Body>
      </Card>
    );
  }
}