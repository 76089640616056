import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt } from 'react-router-dom';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import moment from 'moment';
import './style.scss';
import { TrueFalse, MultipleChoice, Ordering, Essay, Matching, Identification, Enumeration } from './parts';
import LoadingIcon from '../../LoadingIcon/LoadingIcon';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';

const questionRelationTypeMap = {
  trueFalse: 'question_boolean',
  multipleChoice: 'question_multiple_choice',
  ordering: 'question_ordering',
  essay: 'question_essay',
  matching: 'question_matching',
  identification: 'question_identification',
  enumeration: 'question_enumeration'
};

export default class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: true,
      errorMessage: '',
      block: true,
      current: 0,
      alreadyReviewed: false,
      timer: false,
      toSave: {
        inputs: null,
        isLoading: false,
        errorMessage: '',
        saved: false,
        disableButtons: false
      }
    };

    this.timerInterval = null;
    this.changeTimeout = null;
  }
  componentDidMount() {
    const { classID, materialType, materialID, attempt } = this.props.match.params;
    axiosRequest('get', `class/${classID}/material/${materialType}/${materialID}/questionable/${attempt}`, null, ({ data: { data }}) => {
      data.questions.data = data.questions.data.map(q => {
        q.question = q[questionRelationTypeMap[q.type]];
        q.answer = q.question_answer;
        return q;
      });
      this.setState({
        ...this.state,
        data: data,
        isLoading: false,
        timer: data.time_limit,
        block: false
      }, () => {
        if (attempt === 'start') {
          this.props.history.push('resume');
        }
        this.startTimer();
        this.setState({
          ...this.state,
          block: true
        });
      });
    }, (error) => {
      if (error.response.status && error.response.status === 401) {
        this.setState({
          block: false
        }, () => {
          this.props.history.push(`/student/class/${classID}/${materialType}/${materialID}`);
        });
      }
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  componentDidUpdate = () => {
    if (this.state.block) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }
  startTimer = () => {
    const { data } = this.state;

    if (data.time_limit) {
      this.timerInterval = setInterval(() => {
        const { timer } = this.state;
        let currentTime = timer-1;

        if (currentTime < 0) {
          this.setState({
            block: false
          }, () => {
            const { classID, materialType, materialID } = this.props.match.params;

            clearInterval(this.timerInterval);
            this.props.history.push(`/student/class/${classID}/${materialType}/${materialID}`);
          });
        } else {
          this.setState({
            ...this.state,
            timer: timer-1
          });
        }
      }, 60000);
    }
  }
  handleAnswer = (inputs, immediate = false, callback = () => {}) => {
    this.setState({
      ...this.state,
      toSave: {
        ...this.state.toSave,
        inputs,
        saved: false,
        disableButtons: immediate
      }
    }, () => {
      if (immediate) {
        this.setState({
          ...this.state,
          toSave: {
            ...this.state.toSave,
            isLoading: true,
            errorMessage: ''
          }
        }, () => {
          this.handleAnswerSubmit({}, callback);
        });
      }
    });
    // this.setState({
    //   ...this.state,
    //   toSave: {
    //     inputs,
    //     isLoading: true,
    //     errorMessage: '',
    //     saved: false,
    //     disableButtons: immediate
    //   }
    // }, () => {
    //   if (this.changeTimeout) {
    //     clearTimeout(this.changeTimeout);
    //   }

    //   if (immediate) {
    //     this.handleAnswerSubmit({}, callback);
    //   } else {
    //     this.changeTimeout = setTimeout(() => {
    //       this.handleAnswerSubmit({}, callback);
    //     }, 3000);
    //   }
    // });
  }
  handleEnableButtons = () => {
    this.setState({
      ...this.state,
      toSave: {
        ...this.state.toSave,
        disableButtons: false
      }
    });
  }
  handleAnswerSubmit = (custom = {}, callback = () => {}) => {
    const { toSave: { inputs } } = this.state;
    const { classID, materialType, materialID } = this.props.match.params;
    axiosRequest('post', `class/${classID}/material/${materialType}/${materialID}/questionable/answer`, inputs, ({ data: { data }}) => {
      const newQuestionsData = [...this.state.data.questions.data].map(question => {
        if (question.id === inputs.questionID) {
          question.answer = data;
        }

        return question;
      });

      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          questions: {
            ...this.state.data.questions,
            data: newQuestionsData
          }
        },
        toSave: {
          ...this.state.toSave,
          inputs: null,
          isLoading: false,
          errorMessage: '',
          saved: Object.keys(custom).length > 0 ? false : data.updated_at
        },
        isLoading: false,
        ...custom
      }, () => {
        callback(data.id, classID, materialType, materialID);
      });
    }, (error) => {
      if (error.response.status && error.response.status === 401) {
        this.setState({
          block: false
        }, () => {
          this.props.history.goBack();
        });
      }
      this.setState({
        ...this.state,
        toSave: {
          ...this.state.toSave,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        },
        isLoading: false
      });
    }, this.props.history);
  }
  addEssayAttachment = (file, id) => {
    const newQuestionsData = [...this.state.data.questions.data].map(question => {
      if (question.id === id) {
        return {
          ...question,
          answer: {
            ...question.answer,
            files: question.answer.files ? [...question.answer.files, file] : [file]
          }
        };
      }

      return {
        ...question
      };
    });

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        questions: {
          ...this.state.data.questions,
          data: newQuestionsData
        }
      }
    });
  }
  removeEssayAttachment = (fileID, id) => {
    const newQuestionsData = [...this.state.data.questions.data].map((question, i) => {
      if (question.id === id) {
        question.answer.files = [...question.answer.files].filter(f => {
          return f.id !== fileID;
        });
      }

      return question;
    });

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        questions: {
          ...this.state.data.questions,
          data: newQuestionsData
        }
      }
    });
  }
  handleNext = () => {
    const { toSave } = this.state;

    if (toSave.inputs) {
      this.setState({
        ...this.state,
        isLoading: true
      }, () => {
        if (this.changeTimeout) {
          clearTimeout(this.changeTimeout);
        }
        this.handleAnswerSubmit({
          current: this.state.current+1
        }, this.handleLoadMore);
      });
    } else {
      this.setState({
        ...this.state,
        current: this.state.current+1,
        toSave: {
          inputs: null,
          isLoading: false,
          errorMessage: '',
          saved: false,
          disableButtons: false
        }
      }, this.handleLoadMore);
    }
  }
  handleLoadMore = () => {
    const { current, data } = this.state;
    if (current === data.questions.data.length && data.questions.next_page_url) {
      this.setState({
        ...this.state,
        isLoading: true,
        errorMessage: ''
      }, () => {
        const path = data.questions.next_page_url.replace(`${process.env['REACT_APP_API_BASE_URL']}/`, '').replace('start', 'resume');

        axiosRequest('get', path, null, ({ data: { data }}) => {
          data.questions.data = data.questions.data.map(q => {
            q.question = q[questionRelationTypeMap[q.type]];
            q.answer = q.question_answer;
            return q;
          });
          this.setState({
            ...this.state,
            data: {
              ...this.state.data,
              questions: {
                ...data.questions,
                data: [
                  ...this.state.data.questions.data,
                  ...data.questions.data
                ]
              }
            },
            isLoading: false
          });
        }, (error) => {
          if (error.response.status && error.response.status === 401) {
            this.setState({
              block: false
            }, () => {
              const { classID, materialType, materialID } = this.props.match.params;
              this.props.history.push(`/student/class/${classID}/${materialType}/${materialID}`);
            });
          }
          this.setState({
            ...this.state,
            isLoading: false,
            errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
          });
        }, this.props.history);
      });
    }
  }
  handlePrev = () => {
    const { toSave } = this.state;

    if (toSave.inputs) {
      this.setState({
        ...this.state,
        isLoading: true
      }, () => {
        if (this.changeTimeout) {
          clearTimeout(this.changeTimeout);
        }
        this.handleAnswerSubmit({
          current: this.state.current-1
        });
      });
    } else {
      this.setState({
        ...this.state,
        current: this.state.current-1,
        toSave: {
          inputs: null,
          isLoading: false,
          errorMessage: '',
          saved: false,
          disableButtons: false
        }
      });
    }
  }
  handleReview = () => {
    const { toSave } = this.state;

    if (toSave.inputs) {
      this.setState({
        ...this.state,
        isLoading: true
      }, () => {
        if (this.changeTimeout) {
          clearTimeout(this.changeTimeout);
        }
        this.handleAnswerSubmit({
          current: 'review',
          alreadyReviewed: true
        });
      });
    } else {
      this.setState({
        ...this.state,
        current: 'review',
        toSave: {
          inputs: null,
          isLoading: false,
          errorMessage: '',
          saved: false,
          disableButtons: false
        },
        alreadyReviewed: true
      });
    }
  }
  handleJump = index => {
    this.setState({
      ...this.state,
      current: index
    });
  }
  handleFinish = () => {
    this.setState({
      ...this.state,
      isLoading: true,
      errorMessage: ''
    }, () => {
      const { classID, materialType, materialID } = this.props.match.params;
      axiosRequest('get', `class/${classID}/material/${materialType}/${materialID}/questionable/finish`, null, ({ data: { data }}) => {
        this.setState({
          block: false
        }, () => {
          this.props.history.push(`/student/class/${classID}/${materialType}/${materialID}`);
        });
      }, (error) => {
        if (error.response.status && error.response.status === 401) {
          this.setState({
            block: false
          }, () => {
            this.props.history.goBack();
          });
        }
        this.setState({
          ...this.state,
          isLoading: false,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  renderTimer = () => {
    const { data } = this.state;

    if (data.time_limit) {
      const { timer } = this.state;
      let duration = moment.duration(timer, 'minutes');
      let hours = duration.hours()+'';
      let minutes = duration.minutes()+'';
      return (
        <div className={`text-right font-weight-bold mb-3 mb-md-0 ${timer <= 10 ? 'text-danger' : ''}`}>
          <FontAwesomeIcon icon='clock' className={`timer ${timer > 10 ? '' : (timer <= 5 ? 'shake fast' : 'shake slow')}`} /> Time remaining: {`${hours.length > 1 ? hours : (`0${hours}`)}:${minutes.length > 1 ? minutes : (`0${minutes}`)}`}
        </div>
      );
    }
  }
  renderQuestion = () => {
    const { data, current } = this.state;
    const { classID } = this.props.match.params;
    
    const question = data.questions.data[current];

    if (!question) {
      return null;
    }

    let questionProps = {
      ...question,
      url: `class/${classID}/material/question/${data.type}/${data.id}`,
      answerUrl: `class/${classID}/material/${data.type}/${data.id}`,
      onChange: this.handleAnswer,
      history: this.props.history
    };

    switch (question.type) {
      case 'trueFalse':
        return <TrueFalse {...questionProps} />;
      case 'multipleChoice':
        return <MultipleChoice {...questionProps} />;
      case 'ordering':
        return <Ordering {...questionProps} />;
      case 'essay':
        return <Essay {...questionProps} onAddAttachments={this.addEssayAttachment} onRemoveAttachment={this.removeEssayAttachment} enableButtons={this.handleEnableButtons} />;
      case 'matching':
        return <Matching {...questionProps} />;
      case 'identification':
        return <Identification {...questionProps} />;
      case 'enumeration':
        return <Enumeration {...questionProps} />;
      default:
        return null;
    }
  }
  renderAnswer = (question, answer, type) => {
    switch(type) {
      case 'trueFalse':
        return answer.answer_boolean ? (answer.answer_boolean.answer ? 'True' : 'False') : (
          <>
            <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
          </>
        );
      case 'multipleChoice':
        if (!answer.answer_multiple_choice || answer.answer_multiple_choice.length === 0) {
          return (
            <>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </>
          );
        }

        if (question.radio) {
          return (question.choices.find(choice => choice.id === +answer.answer_multiple_choice[0].question_multiple_choice_choice_id))?.description;
        } else {
          return answer.answer_multiple_choice.map((answer, index) => (
            <div key={index}>{(question.choices.find(choice => choice.id === answer.question_multiple_choice_choice_id))?.description}</div>
          ))
        }
      case 'ordering':
        if (!answer.answer_ordering || answer.answer_ordering.length === 0) {
          return (
            <>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </>
          );
        }

        return answer.answer_ordering.map((answer, index) => (
          <div key={index}>{(question.items.find(item => item.id === answer.question_ordering_item_id))?.description}</div>
        ));
      case 'essay':
        return (
          <>
            {
              answer.files && answer.files.length > 0 && (!answer.answer_essay || !answer.answer_essay.answer) ? null : (answer.answer_essay && answer.answer_essay.answer) ? answer.answer_essay.answer : (
                <div>
                  <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
                </div>
              )
            }
            {
              (answer.files && answer.files.length > 0) && (
                <div>
                  <FontAwesomeIcon icon='paperclip' /> {answer.files.length} attachment{answer.files.length !== 1 ? 's' : ''}
                </div>
              )
            }
          </>
        );
      case 'matching':
        if (!answer.answer_matching || answer.answer_matching.length === 0) {
          return (
            <>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </>
          );
        }

        return answer.answer_matching.map(({question_matching_item_id, answer}, index) => (
          <div key={index}>
            <div>{(question.items.find(item => item.id === question_matching_item_id))?.description}</div>
            <div className='pl-1 d-flex'>
              <div className='mr-1'>
                <FontAwesomeIcon icon='long-arrow-alt-right' />
              </div>
              <div className='flex-fill'>
                {answer}
              </div>
            </div>
          </div>
        ));
      
      case 'identification':
        if (!answer.answer_identification || answer.answer_identification.length === 0) {
          return (
            <>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </>
          );
        }

        return answer.answer_identification.map(({question_identification_item_id, answer}, index) => (
          <div key={index}>
            <div>{answer}</div>
            <div className='pl-1 d-flex'>
              <div className='mr-1'>&mdash;</div>
              <div className='flex-fill'>
                {(question.items.find(item => item.id === question_identification_item_id))?.description}
              </div>  
            </div>
          </div>
        ));
      case 'enumeration':
        if (!answer.answer_enumeration || answer.answer_enumeration.length === 0 || !answer.answer_enumeration.find(a => a.answer !== '')) {
          return (
            <>
              <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
            </>
          );
        }

        return answer.answer_enumeration.map(({answer}, index) => (
          <div key={index}>
            {answer}
          </div>
        ));
      default:
        return null;
    }
  }
  renderReview = () => {
    const { data: { questions } } = this.state;

    return (
      <div className='p-1 p-md-3'>
        {this.renderTimer()}
        <div className='review-answers'>
        {
          questions.data.map(({question, answer, type}, index) => (
            <div key={index} className='d-flex py-3'>
              <div className='h5 pr-1 pr-md-2'>{index+1}</div>
              <div className='flex-fill'>
                <RichTextEditor.Viewer body={question?.description} />
                {/* <div>{question?.description}</div> */}
                <div className='p-1 p-md-2 alert-light'>
                {
                  answer ? this.renderAnswer(question, answer, type) : (
                    <>
                      <FontAwesomeIcon icon='exclamation' className='text-danger' /> No answer was provided.
                    </>
                  )
                }
                </div>
              </div>
              <div className='pl-1 pl-md-2'>
                <Button variant='info' size='sm' onClick={() => this.handleJump(index)} style={{ whiteSpace: 'nowrap' }}>
                  <FontAwesomeIcon icon='redo' />
                  <span className='d-none d-md-inline-block ml-1'>Return to page</span>
                </Button>
              </div>
            </div>
          ))
        }
        </div>
        <div className='text-right'>
          <Button variant='green' onClick={this.handleFinish}>
            Finish
          </Button>
        </div>
      </div>
    );
  }
  renderContainer = () => {
    const { isLoading, errorMessage, current, data, alreadyReviewed, toSave } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <div>
        {
          current === 'review' ? this.renderReview() : (
            <>
              <div className='p-md-5'>
                {this.renderTimer()}
                <div className='d-flex'>
                  <div className='h4'>
                    {current+1}
                  </div>
                  <div className='flex-fill'>
                    {this.renderQuestion()}
                  </div>
                </div>
                <div className='d-none d-md-block text-right'>
                  {
                    toSave.inputs ? (
                      <span className='mr-2 text-muted'>
                        {
                          toSave.isLoading ? (
                            <LoadingIcon />
                          ) : toSave.errorMessage ? (
                            <small className='text-danger'>
                              {toSave.errorMessage}
                            </small>
                          ) : ''
                        }
                      </span>
                    ) : toSave.saved ? (
                      <small className={`mr-2 text-muted ${toSave.disableButtons ? 'd-none' : ''}`}>
                        Saved at {moment(toSave.saved).format('hh:mm A')}
                      </small>
                    ) : ''
                  }
                  {
                    current !== 0 && (
                      <Button variant='green' className='mr-2' onClick={this.handlePrev} disabled={toSave.disableButtons}>
                        Previous
                      </Button>
                    )
                  }
                  {
                    (current !== data.questions.data.length -1 || data.questions.next_page_url || (alreadyReviewed && (current !== data.questions.data.length - 1 || data.questions.next_page_url))) && (
                      <Button variant='green' className='mr-2' onClick={this.handleNext} disabled={toSave.disableButtons}>
                        Next
                      </Button>
                    )
                  }
                  {
                    ((current === data.questions.data.length - 1 && !data.questions.next_page_url) || alreadyReviewed) && (
                      <Button variant='green' onClick={this.handleReview} disabled={toSave.disableButtons}>
                        Review answers
                      </Button>
                    )
                  }
                </div>
                <div className='d-md-none text-right'>
                  <div className='mb-1'>
                    {
                      toSave.inputs ? (
                        <span className='mr-2 text-muted'>
                          {
                            toSave.isLoading ? (
                              <LoadingIcon />
                            ) : toSave.errorMessage ? (
                              <small className='text-danger'>
                                {toSave.errorMessage}
                              </small>
                            ) : ''
                          }
                        </span>
                      ) : toSave.saved ? (
                        <small className={`mr-2 text-muted ${toSave.disableButtons ? 'd-none' : ''}`}>
                          Saved at {moment(toSave.saved).format('hh:mm A')}
                        </small>
                      ) : ''
                    }
                  </div>
                  <div className='d-flex mb-1 justify-content-end'>
                  {
                    current !== 0 && (
                      <div className='w-50 px-1'>
                        <Button variant='green' className='mr-2' onClick={this.handlePrev} disabled={toSave.disableButtons} title='Previous' block>
                          <FontAwesomeIcon icon='chevron-left' />
                        </Button>
                      </div>
                    )
                  }
                  {
                    (current !== data.questions.data.length -1 || data.questions.next_page_url || (alreadyReviewed && (current !== data.questions.data.length - 1 || data.questions.next_page_url))) && (
                      <div className='w-50 px-1'>
                        <Button variant='green' className='mr-2' onClick={this.handleNext} disabled={toSave.disableButtons} title='Next' block>
                          <FontAwesomeIcon icon='chevron-right' />
                        </Button>
                      </div>
                    )
                  }
                  </div>
                  <div className='px-1'>
                    {
                      ((current === data.questions.data.length - 1 && !data.questions.next_page_url) || alreadyReviewed) && (
                        <Button variant='green' onClick={this.handleReview} disabled={toSave.disableButtons} block>
                          Review answers
                        </Button>
                      )
                    }
                  </div>
                </div>
              </div>
            </>
          )
        }
      </div>
    )
  }
  render() {
    return (
      <div className='border rounded p-4 my-3'>
        <Prompt when={this.state.block} message='Once you leave the page, you cannot resume this attempt. Are you sure you want to leave this page?' />
        {this.renderContainer()}
      </div>
    );
  }
}