import React, { Component } from 'react';
import { Alert, Card, Col, Form, InputGroup, Nav, Button } from 'react-bootstrap';
import Validator from 'validatorjs';
import axiosRequest from '../../../../util/helpers/axiosRequest';
import LoadingIcon from '../../../common/LoadingIcon/LoadingIcon';

export default class UploadLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: '',
      uploadLimit: {},
      activeKey: 'student',
      formInputs: {
        perFile: '',
        perUser: ''
      },
      isFormLoading: false,
      formError: ''
    };
  }
  componentDidMount() {
    axiosRequest('get', `admin/settings/upload-limit`, null, ({ data: { data }}) => {
      let formInputs = {...this.state.formInputs};
      let uploadLimit = {};
      if (data) {
        uploadLimit = data;
        if (data.student) {
          formInputs = data.student;
        }
      }
      this.setState({
        ...this.state,
        isLoading: false,
        formInputs,
        uploadLimit
      });
    }, error => {
      this.setState({
        ...this.state,
        isLoading: false,
        errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
      });
    }, this.props.history);
  }
  handleSelect = key => {
    const { uploadLimit } = this.state;

    let formInputs = {...this.state.formInputs};
    if (uploadLimit[key]) {
      formInputs = uploadLimit[key];
    } else {
      formInputs = {
        perFile: '',
        perUser: ''
      };
    }

    this.setState({
      ...this.state,
      activeKey: key,
      formInputs
    });
  }
  handleInputChange = event => {
    this.setState({
      ...this.state,
      formInputs: {
        ...this.state.formInputs,
        [event.target.name]: event.target.value
      }
    });
  }
  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      ...this.state,
      isFormLoading: true,
      formError: ''
    }, () => {
      const { formInputs, activeKey } = this.state;

      let validator = new Validator(formInputs, {
        perFile: 'required|numeric|min:1',
        perUser: 'required|numeric|min:'+formInputs.perFile
      });

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: validator.errors.errors[firstKey][0]
        });
        return;
      }

      axiosRequest('post', `admin/settings/upload-limit/${activeKey}`, formInputs, ({ data: { data }}) => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          uploadLimit: {
            ...this.state.uploadLimit,
            [activeKey]: data
          },
          formError: ''
        });
      }, error => {
        this.setState({
          ...this.state,
          isFormLoading: false,
          formError: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error
        });
      }, this.props.history);
    });
  }
  handleReset = () => {
    const { activeKey, uploadLimit } = this.state;

    let formInputs = {...this.state.formInputs};
    if (uploadLimit[activeKey]) {
      formInputs = uploadLimit[activeKey];
    } else {
      formInputs = {
        perFile: '',
        perUser: ''
      };
    }
    
    this.setState({
      ...this.state,
      formInputs
    });
  }
  renderContent = () => {
    const { isLoading, errorMessage, activeKey, formInputs, isFormLoading, formError } = this.state;

    if (isLoading) {
      return (
        <LoadingIcon lg />
      );
    }

    if (errorMessage) {
      return (
        <Alert variant='danger'>
          {errorMessage}
        </Alert>
      );
    }

    return (
      <>
        <Nav variant='tabs' onSelect={this.handleSelect} className='m-0 border-bottom'>
          <Nav.Item>
            <Nav.Link eventKey='student' active={activeKey === 'student'}>Student</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='faculty' active={activeKey === 'faculty'}>Faculty</Nav.Link>
          </Nav.Item>
        </Nav>
        <div className='border border-top-0 rounded-bottom p-3'>
          <Form onSubmit={this.handleSubmit}>
            {
              formError && (
                <Alert variant='danger'>
                  {formError}
                </Alert>
              )
            }
            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>Per File</Form.Label>
                <InputGroup>
                  <Form.Control type='number' name='perFile' value={formInputs.perFile} onChange={this.handleInputChange} />
                  <InputGroup.Append>
                    <InputGroup.Text>MB</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>Per User</Form.Label>
                <InputGroup>
                  <Form.Control type='number' name='perUser' value={formInputs.perUser} onChange={this.handleInputChange} />
                  <InputGroup.Append>
                    <InputGroup.Text>MB</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <div className='text-right'>
              <Button variant='danger' className='mr-2' onClick={this.handleReset} disabled={isFormLoading}>
                Reset
              </Button>
              <Button type='submit' variant='green' disabled={isFormLoading}>
                Save
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
  render() {
    return (
      <Card>
        <Card.Body>
          { this.renderContent() }
        </Card.Body>
      </Card>
    );
  }
}