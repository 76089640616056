import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginUser, logoutUser } from '../../../actions';
import { Alert, Button, Col, Collapse, Form, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Validator from 'validatorjs';
import axios from 'axios';
import actsccLogo from '../../../resources/image/actscc-logo.png';
import school from '../../../resources/image/school.png';
import './style.scss';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formInputs: {
        username: '',
        password: ''
      },
      isLoading: false,
      errorMessage: '',
      successMessage: '',
      helpToggle: false,
    };
  }
  componentDidMount() {
    this.props.logoutUser();
    if (this.props.location.state && this.props.location.state.successMessage) {
      this.setState({
        ...this.state,
        successMessage: this.props.location.state.successMessage
      }, () => {
        this.props.history.replace({ ...this.props.history.location, state: {} });
      });
    }
  }
  handleHelp = () => {
    this.setState({
      ...this.state,
      helpToggle: !this.state.helpToggle
    });
  }
  handleInputChange = event => {
    const { formInputs } = this.state;

    formInputs[event.target.name] = event.target.value;

    this.setState({
      ...this.state,
      formInputs
    });
  }
  handleForgotPassword = () => {
    this.props.history.push('/forgot-password');
  }
  handleLogin = event => {
    event.preventDefault();
    this.setState({
      ...this.state,
      errorMessage: '',
      isLoading: true
    }, () => {
      const { formInputs } = this.state;
      let rules = {
        username: 'required|min:5',
        password: 'required|min:8'
      };
      let validator = new Validator(formInputs, rules);

      if (validator.fails()) {
        const firstKey = Object.keys(validator.errors.errors)[0];
        this.setState({
          ...this.state,
          errorMessage: validator.errors.errors[firstKey][0],
          isLoading: false
        });
        return;
      }

      axios.post(`${process.env['REACT_APP_API_BASE_URL']}/login`, {
        // username: '00000', //WEB-ADMIN
        // password: 'HzBi1Bvw'
        // username: '00001', //SCHOOL-ADMIN
        // password: 'Mle9VBMr'
        // username: '00002', //FACULTY 1
        // password: 'qBd8GggZ'
        // username: '00006', //FACULTY 2
        // password: 'cvJFQcGN'
        // username: '00003', //STAFF
        // password: 'SHsreFmI'
        // username: '00004', //STUDENT 1
        // password: 'DUbhQhT3'
        // username: '00004P', //PARENT 1
        // password: 'cPBiWu60'
        // username: '00005', //STUDENT 2
        // password: 'REJUkXIR'
        // username: '00005P', //PARENT 2
        // password: 's6HRJkOG'
        ...formInputs
      }, { withCredentials: true }).then(({ data }) => {
        this.props.loginUser(data.account);
        this.setState({
          formInputs: {
            username: '',
            password: ''
          },
          errorMessage: ''
        }, () => {
          let route = ''
          switch (data.account.account_type) {
            case 0:
              route = '/admin';
              break;
            case 1:
              route = '/school-admin';
              break;
            case 2:
              route = '/faculty';
              break;
            case 3:
              route = '/staff';
              break;
            case 4:
              route = '/parent';
              break;
            default:
              route = '/student';
              break;
          }
          this.props.history.push(route);
        });
      }).catch((error) => {
        this.setState({
          ...this.state,
          errorMessage: error.response && error.response.data ? error.response.data.message : error.message ? error.message : error,
          isLoading: false
        })
      });
    });
  }
  render() {
    const { formInputs, isLoading, errorMessage, helpToggle, successMessage } = this.state;
    return (
      <div className='bg-secondary d-flex align-items-center' style={{ minHeight: '100vh' }}>
        <Col className='bg-secondary d-flex flex-column'>
          <Row className='mx-0 mx-lg-4'>
            <Col>
              <div className='login-title text-white my-3'>
                ACTS Virtual Learning Environment
              </div>
            </Col>
          </Row>
          <Row className='flex-fill mx-0 ml-lg-4' noGutters>
            <Col xs={12} xl={7}>
              <div className='rounded bg-white p-3 px-lg-4'>
                <Row>
                  <Col xs={9} sm={5} className='d-flex flex-column justify-content-center align-items-center py-1 py-md-2 mx-auto mx-md-0'>
                    <div className='w-100'>
                      <Image src={actsccLogo} fluid />
                    </div>
                  </Col>
                  <Col xs={12} md={7} className=''>
                    <div className='py-2 flex-fill'>
                      <div className='text-center text-primary h2 mb-2'>{process.env['REACT_APP_NAME']}</div>
                      {
                        errorMessage ? (
                          <Alert variant='danger'>
                            {errorMessage}
                          </Alert>
                        ) : successMessage ? (
                          <Alert variant='success'>
                            {successMessage}
                          </Alert>
                        ) : null
                      }
                      <Form onSubmit={this.handleLogin}>
                        <Form.Group>
                          <Form.Label>Username</Form.Label>
                          <Form.Control
                            type='text'
                            name='username'
                            value={formInputs.username}
                            onChange={this.handleInputChange}
                            disabled={isLoading}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type='password'
                            name='password'
                            value={formInputs.password}
                            onChange={this.handleInputChange}
                            disabled={isLoading}
                          />
                        </Form.Group>
                        <Button type='submit' variant='green' block disabled={isLoading}>
                          Login
                        </Button>
                        <div className='text-right mt-2'>
                          <Button variant='link' disabled={isLoading} onClick={this.handleForgotPassword}>
                            Forgot username or password?
                          </Button>
                        </div>
                        <div className='dropdown-divider'></div>
                        <div className='text-center'>
                          <Button variant='link' onClick={this.handleHelp}>
                            Didn't receive an email? <FontAwesomeIcon icon={helpToggle ? 'chevron-down' : 'chevron-right'} size='sm' />
                          </Button>
                        </div>
                        <Collapse in={helpToggle}>
                          <div>
                            <Alert className='mt-3' variant='warning'>
                              <ul>
                                <li>
                                  <div>
                                    <b>Check your spam folder</b>
                                  </div>
                                  <div>
                                    Emails from ACTS-VLE will be sent from actsvle@actscc.edu.ph &mdash; please ensure this email address is trusted and listed as a safe recipient to ensure they're not regarded as spam
                                  </div>
                                </li>
                                <li>
                                  <b>Contact the system administrator</b>
                                </li>
                              </ul>
                            </Alert>
                          </div>
                        </Collapse>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='login-footer text-center text-white my-3 font-weight-light'>
                An interactive educational platform, engaging social networking medium, and personal organizer right at your fingertip.
              </div>
            </Col>
            <Col xs={12} xl={5} className='d-flex flex-column justify-content-end position-relative'>
              <div className='login-quote text-white'>
                <div>
                  <div>
                    &ldquo;Your EDUCATION is your
                  </div>
                  <div>
                    LIFE, Guard it well.&rdquo;
                  </div>
                </div>
                <div>
                  &mdash; Proverbs 4:13b
                </div>
              </div>
              <div className='w-100 pt-4 pt-md-3'>
                <Image src={school} fluid />
              </div>
            </Col>
          </Row>
        </Col>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  loginUser: user => dispatch(loginUser(user)),
  logoutUser: () => dispatch(logoutUser())
});

export default connect(null, mapDispatchToProps)(Login);